<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row>
          <v-col sm="5" md="5">
            <v-list-item>
              <v-list-item-content class="row-position">
                <Label label="作業指揮者" :editable="editable">
                  <Select
                    class="select-position"
                    name="conductor_worker_id"
                    :values="item"
                    item_value="user_id"
                    :items="COMPANY_PARTNER_USER_ARRAY"
                    :editable="editable"
                    :label="item.conductor_worker_name"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col sm="5" md="5">
            <v-list-item>
              <v-list-item-content class="row-position">
                <Label label="誘導者" :editable="editable">
                  <Select
                    class="select-position"
                    name="leading_worker_id"
                    :values="item"
                    item_value="user_id"
                    :items="COMPANY_PARTNER_USER_ARRAY"
                    :editable="editable"
                    :label="item.leading_worker_name"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col v-if="index > 0 && editable" cols="12" sm="2" md="2" class="pt-0 pb-0">
            <v-btn class="mt-8" icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn class="ml-4" v-if="editable" color="primary" @click="addEmpty" depressed small
      >追加</v-btn
    >
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "@/components/forms/elements/Label";
import Select from "@/components/forms/elements/Select";
import { Store } from "@/store/Store.js";

//初期値を設定します
const INITIAL_VALUES = {
  conductor_worker_id: null,
  leading_worker_id: null,
};

export default {
  data: () => {
    return {
      items: [],
      COMPANY_PARTNER_USER_ARRAY: [],
    };
  },
  components: {
    Label,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    field_tree_id: {
      type: Number,
    },
  },
  mounted() {
    this.$watch(
      () => this.field_tree_id,
      (newValue) => {
        this.getListUsers(newValue);
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        this.items = this.items == null ? [] : this.items;
        if (this.items?.length == 0) {
          this.items = [];
          this.items.push({ ...INITIAL_VALUES });
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    async getListUsers(field_tree_id) {
      if (!field_tree_id) {
        this.COMPANY_PARTNER_USER_ARRAY = [{"user_id" : null , "name" : ""}];
      } else {
        const params = {
          id: field_tree_id,
          state_handling_flag: 0
        };
        let rs = await Store.dispatch(
          "PortalChart/getListCompanyPartnerUser",
          params
        );
        
        this.COMPANY_PARTNER_USER_ARRAY = [...rs.data.contents.entries];
        this.COMPANY_PARTNER_USER_ARRAY.forEach((element) => {
          element.name = element.user_name_sei + " " + element.user_name_mei;
        });
        this.COMPANY_PARTNER_USER_ARRAY.unshift({user_id : null ,name : ""});
      }
    },
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      const items = [...this.items];
      items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: items });
    },
  },
};
</script>
<style scoped>
.row-position{
  margin-top: -50px;
  height: 180px;
  position: relative;
  z-index: 2;
}

.select-position{
  width: 100%;
  position: absolute;
}
</style>