import Vue from "vue";
import Vuex from "vuex";
import { entranceHistory } from "@/api/modules/entranceHistory";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = entranceHistory;

export const EntranceHistory = {
  namespaced: true,

  state: {
    data: [],
    pagination: {},
    historyDetail: {},
    admissionStatus: [],
    listUserPanel: [],
    listEntrancePanel: {}
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_CHILD_DATA(state, payload) {
      let parent = [];
      let children = [];
      let data = [];
      parent = state.data.find(
        (item) => item.field_tree_id === payload.field_tree_id
      );
      children = [...parent.children, ...payload.entries];
      data = state.data.map((item) => {
        if (item.field_tree_id === payload.field_tree_id) {
          const _item = { ...item };
          _item.children = children;
          return _item;
        }
        return item;
      });
      state.data = data;
    },
    SET_DETAIL(state, payload) {
      state.historyDetail = payload;
    },
    SET_ADMISSION_STATUS(state, payload) {
      state.admissionStatus = payload;
    },
    SET_LIST_USER_PANEL(state, payload) {
      state.listUserPanel = payload;
    },
    SET_LIST_ENTRANCE_PANEL(state, payload) {
      state.listEntrancePanel = payload;
    },
  },

  actions: {
    //機材予定一覧
    async getListHistory({ commit }, payload) {
      const response = await ENTITY.getListHistory(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;
      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },

    async loadChildren({ commit }, payload) {
      const response = await ENTITY.loadChildren(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_CHILD_DATA", {
        entries,
        field_tree_id: payload.params.field_tree_id,
      });
      return response;
    },
    async getDetail({ commit }, payload) {
      const response = await ENTITY.getDetail(payload);
      if (response.hasError) {
        return response;
      }
      const { entries }= response.data.contents;
      commit("SET_DETAIL", entries);
      return response;
    },
    async post(_context, payload) {
      return await ENTITY.post(payload);
    },
    async update(_context, payload) {
      return await ENTITY.update(payload);
    },
    updateAdmissionStatus({ commit }, payload){
      commit("SET_ADMISSION_STATUS", payload);
    },
    async getListUserPanel({ commit }, payload) {
      const response = await ENTITY.getListUserPanel(payload);
      if (response.hasError) {
        return response;
      }
      const { entries }= response.data.contents;
      commit("SET_LIST_USER_PANEL", entries);
      return response;
    },
    async updateEntrancePanel(_context, payload) {
      return await ENTITY.updateEntrancePanel(payload);
    },
    async getListEntrancePanel({ commit }, payload) {
      const response = await ENTITY.getListEntrancePanel(payload);
      if (response.hasError) {
        return response;
      }
      const { entries }= response.data.contents;
      commit("SET_LIST_ENTRANCE_PANEL", entries);
      return response;
    }
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getDetail: (state) => {
      return state.historyDetail;
    },
    getAdmissionStatus: (state) => {
      return state.admissionStatus;
    },
    getListUserPanel: (state) => {
      return state.listUserPanel;
    },
    getListEntrancePanel: (state) => {
      return state.listEntrancePanel;
    },
  },
};
