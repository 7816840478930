<template>
  <div v-if="!isNoData">
    <div class="title">{{ title }}</div>
    <div class="chart">
      <div class="chartLeft" :style="`width:${ukeoi}%`">{{ values[0] }}</div>
      <div class="chartRight" :style="`width:${jyouyou}%`">{{ values[1] }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    values: {
      type: Array,
    },
    isNoData: {
      type: Boolean,
    },
  },
  computed: {
    ukeoi() {
      const total = this.values.reduce((a, b) => {
        return a + b;
      });
      return Math.floor((this.values[0] / total) * 100);
    },
    jyouyou() {
      return 100 - this.ukeoi;
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  padding: 0 8px;
  font-size: 12px;
}
.chart {
  width: 100%;
  display: flex;
  color: white;
  margin: 8px;
  .chartLeft {
    height: 32px;
    width: 0;
    line-height: 32px;
    text-align: center;
    background-color: #1b9c4f;
    transition: all 1s ease;
    transition-delay: 0.5s;
  }
  .chartRight {
    height: 32px;
    width: 0;
    line-height: 32px;
    text-align: center;
    background-color: #ff7b52;
    transition: all 1s ease;
    transition-delay: 0.5s;
  }
}
</style>
