<template>
  <div class="submenu my-2">
    <!-- SpriteSVG選択で表示するSVGメニュー -->
    <SVGEditMenu v-if="isSVGSprite" @svg-color="updateSvgColor" />
    <!-- SpriteText選択で表示するテキストメニュー -->
    <TextEditMenu
      v-if="isTextSprite"
      @text-align="updateTextAlign"
      @text-size="updateTextSize"
      @text-color="updateTextColor"
    />
  </div>
</template>
<script>
import SVGEditMenu from "@/views/oekaki/components/components/SVGEditMenu/index.vue";
import TextEditMenu from "@/views/oekaki/components/components/TextEditMenu/index.vue";
import {
  TEXT_ALIGN,
  TEXT_SIZE,
  TEXT_COLOR,
} from "@/views/oekaki/components/constants/SPRITE_TEXT.js";
import { Store } from "@/store/Store.js";

const getKey = (obj, val) => {
  return Object.keys(obj).find((key) => {
    return obj[key] === val;
  });
};

export default {
  components: {
    SVGEditMenu,
    TextEditMenu,
  },
  computed: {
    // svgが選択されているか
    isSVGSprite() {
      const item = this.items.find((_item) => {
        return (
          _item.id === this.selectedId &&
          "image_svg" in _item &&
          _item.image_svg
        );
      });
      return item ? true : false;
    },
    isTextSprite() {
      const item = this.items.find((_item) => {
        return _item.id === this.selectedId && "text" in _item && _item.text;
      });
      return item ? true : false;
    },
    selectedId() {
      return Store.getters["Oekaki/getSelectedItemId"];
    },
  },
  props: {
    items: {
      type: Array,
    },
    // selectedId: {
    //   type: Number,
    // },
  },
  methods: {
    updateSvgColor(COL) {
      let item = this.items.find((_item) => {
        return _item.id === this.selectedId;
      });
      item.image_svg = COL.key;
      // this.$emit("update-svg", item);
      this.onUpdateSvg(item);
    },
    updateTextAlign(id) {
      let item = this.items.find((_item) => {
        return _item.id === this.selectedId;
      });
      item.text_align = TEXT_ALIGN[getKey(TEXT_ALIGN, id)];
      // this.$emit("update-text", item);
      this.onUpdateText(item);
    },
    updateTextSize(id) {
      let item = this.items.find((_item) => {
        return _item.id === this.selectedId;
      });
      item.text_size = TEXT_SIZE[getKey(TEXT_SIZE, id)];
      // this.$emit("update-text", item);
      this.onUpdateText(item);
    },
    updateTextColor(id) {
      let item = this.items.find((_item) => {
        return _item.id === this.selectedId;
      });
      item.text_color = TEXT_COLOR[getKey(TEXT_COLOR, id)];
      // this.$emit("update-text", item);
      this.onUpdateText(item);
    },
    onUpdateText(updatedItem) {
      let items = [...this.items];
      items = items.map((item) => {
        if (item && item.id === updatedItem.id) {
          return updatedItem;
        }
        return item;
      });
      this.$emit("on-edit-items", items);
    },
    onUpdateSvg(updatedItem) {
      let items = [...this.items];
      items = items.map((item) => {
        if (item && item.id === updatedItem.id) {
          return updatedItem;
        }
        return item;
      });
      this.$emit("on-edit-items", items);
    },
  },
};
</script>
<style lang="scss" scoped>
.submenu {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 48px;
  left: calc(50% - 128px);
  z-index: 999;
}

.fieldset ::v-deep .fieldset {
  height: 33px;
}
</style>
