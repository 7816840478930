<template>
  <v-card class="dialog">
    <v-card-title>
      <div class="title">見積報告</div>
    </v-card-title>
    <v-card-text>
      <div>見積依頼に回答したことを通知します。</div>
      <div>
        <Label label="有効期限" width="250px">
          <InputDatepicker
            name="estimate_expiration_date"
            :editable="true"
            :values="formValues"
            :flagNull="true"
            @onInput="onInput"
          />
        </Label>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="primary"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn depressed class="btn" color="primary" @click.once="onSubmitSendMachineEstimateReport" :key="flag"> 報告 </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Label from "@/components/forms/elements/Label";
import InputDatepicker from "@/components/forms/elements/InputDatepicker";
export default {
  data() {
    return {
      formValues: {
        estimate_expiration_date: null,
      },
    };
  },
  components: {
    Label,
    InputDatepicker,
  },
  props: {
    flag: {
      type: Boolean,
    },
  },
  methods: {
    onInput({ name, value }) {
      this.formValues[name] = value;
    },
    onSubmitSendMachineEstimateReport(){
      this.$emit("onSubmitSendMachineEstimateReport",this.formValues);
    }
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .title {
    font-size: 12px;
    color: #1b9c4f;
  }
}
</style>
