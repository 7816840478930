import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const Tooltip = {
  namespaced: true,

  state: {
    message: "",
    rect: null,
    isActive: false,
  },

  mutations: {
    SET_TOOLTIP(state, payload) {
      state.message = payload.message;
      state.rect = payload.rect;
    },
    ACTIVE(state) {
      state.isActive = true;
    },
    DEACTIVE(state) {
      state.message = "";
      state.isActive = false;
      state.rect = null;
    },
  },

  actions: {
    show({ commit }, payload) {
      console.log("==", payload.rect);
      commit("SET_TOOLTIP", payload);
      commit("ACTIVE");
    },
    destroy({ commit }) {
      commit("DEACTIVE");
    },
  },

  getters: {
    getTooltip: (state) => {
      return state;
    },
  },
};
