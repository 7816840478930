<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row no-gutters>
          <v-col cols="12" sm="10" md="10">
            <v-list-item>
              <v-list-item-content>
                <Label label="特別教育名" :editable="editable">
                  <SelectSearch
                    name="cmn_mst_sp_training_id"
                    :values="item"
                    item_text="training_name"
                    :items="DEVICE_SP_TRAININGS"
                    :editable="editable"
                    title="特別教育名選択"
                    title_search="特別教育名"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col
            v-if="index > 0 && editable"
            cols="12"
            sm="2"
            md="2"
            class="pt-0 pb-0"
          >
            <v-btn class="mt-8" icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn v-if="editable" class="mt-5" color="primary" @click="addEmpty">追加</v-btn>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "../../elements/Label.vue";
import Select from "../../elements/Select.vue";
import { Store } from "@/store/Store.js";
import SelectSearch from "@/components/forms/elements/SelectSearch";

//初期値を設定します
const INITIAL_VALUES = {
  id: null,
  cmn_mst_sp_training_id: null,
};

export default {
  data: () => {
    return {
      items: [],
      DEVICE_SP_TRAININGS: [{ id: null, training_name: "" },...Store.state.CmnMst.constants.entries.cmn_mst_sp_trainings]
    };
  },
  components: {
    SelectSearch,
    Label,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    selectItems: {
      type: Array,
    },
    row: {
      type: Number,
      default: 1,
    },
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (this.items?.length == 0) {
          this.addEmpty();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      const items = [...this.items];
      items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: items });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
