<template>
  <FormDialog>
    <template #header>
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title class="title-form" color="primary">
          {{ formTitle }}
        </v-toolbar-title>
        <div v-if="formValues.safety_diaries.patrol_record_name" class="shiteki-title-center" v-model="formValues.safety_diaries.patrol_record_name"> {{ formValues.safety_diaries.patrol_record_name }}</div>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editable"
          class="mr-5"
          depressed
          small
          outlined
          color="primary"
          @click="isShowConfirmDialog = true"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable && tab > 0 && isNewItem"
          depressed
          small
          :disabled="!valid || isSubmitted"
          color="primary"
          @click="onSubmit"
          :key="flagSubmit"
        >
          保存
        </v-btn>
        <v-btn
          v-if="editable && !isNewItem"
          depressed
          small
          :disabled="!valid || isSubmitted"
          color="primary"
          @click="onSubmit"
          :key="flagSubmit"
        >
          保存
        </v-btn>
        <v-btn
          v-if="editable && tab === 0 && isNewItem"
          depressed
          small
          color="primary"
          @click="onNextTab"
        >
          次へ
        </v-btn>
        <v-btn
          v-if="!editable && !isNewItem && isShowBtnEdit"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn icon @click="onClickBtnClose" data-testid="btn-close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs class="tabs-bar" v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab
              v-for="(form, key) in FORMS"
              :key="key"
            >
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <ValidationObserver ref="observer" v-slot="observer">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <ShitekiFormTab1
              :editable="editable"
              :isNewItem="isNewItem"
              :item="formValues"
              :valid="valid"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
              @updateWorkPlace="updateWorkPlace"
              @showLoading="showLoading"
            />
          </v-tab-item>
          <v-tab-item>
            <ShitekiFormTab2
              :editable="editable"
              :isNewItem="isNewItem"
              :item="formValues"
              :valid="valid"
              :mainHeight="params.mainHeight"
              :workPlace="workPlace"
              :recordId="recordId"
              :childId="childId"
              :backPreview="backPreview"
              :isShowMove="isShowMove"
              @formUpdate="formUpdate"
              @onRecovery="openConfirmRecovery"
              @onChangeStatusCode="onChangeStatusCode"
              @actionSuccess="actionSuccess"
              @loading="setLoading"
            />
          </v-tab-item>
        </v-tabs-items>
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>
      <Popup width="480px" :dialog="isShowConfirmRecoveryDialog">
        <ConfirmDialog
          :dialogText="DIALOG_RECOVERY_TEXT"
          :isApproved="false"
          @close="isShowConfirmRecoveryDialog = false"
          @yes="onRecovery"
          :warning="false"
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import ConfirmDialog from "@/views/schedule/patrols/components/ConfirmDialog.vue";
import ShitekiFormTab1 from "@/components/forms/schedule/patrols/PaltrolForm/ShitekiForm/ShitekiFormTab1.vue";
import ShitekiFormTab2 from "@/components/forms/schedule/patrols/PaltrolForm/ShitekiForm/ShitekiFormTab2.vue";
import { INITIAL_ITEM_SHITEKI_FORM , FORM_SHITEKI_TABS , DIALOG_RECOVERY_TEXT} from "@/constants/PATROL_RECORD";
import { Store } from "@/store/Store";
import _ from "lodash";
import { format } from "date-fns";

const TITLE = "指摘事項";
const STORE = "PatrolRecord";

export default {
  data: () => {
    return {
      DIALOG_RECOVERY_TEXT,
      FORMS: FORM_SHITEKI_TABS,
      valid: false,
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      isShowConfirmRecoveryDialog : false,
      isClickCloseBtn: false,
      beforeUpdateItem: null,
      formValues: _.cloneDeep(INITIAL_ITEM_SHITEKI_FORM),
      isShowBtnEdit : false,
      flagSubmit : false,
      workPlace: [],
      backPreview: false,
      isSubmitted: false,
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ShitekiFormTab1,
    ShitekiFormTab2,
    ConfirmCloseDialog,
    ConfirmDialog
  },
  props: {
    isNewItem: Boolean,
    construction_id : Number,
    recordId : Number,
    isParent : Boolean,
    childId : Number,
    isShowMove: Boolean,
  },
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    if (!this.isNewItem) {
      this.tab = 1;
    }
    this.$watch(
      () => this.isNewItem,
      (data) => {
        if (data) {
          this.editable = true;
          const _formValues = { ...this.formValues };
          _formValues.safety_diaries.field_construction_id = this.construction_id;
          this.formValues = { ..._formValues };
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters[`${STORE}/getDetail`],
      (data) => {
        this.beforeUpdateItem = _.cloneDeep(this.formValues);
        if (this.recordId) {
          this.formValues = _.cloneDeep(data);
          this.beforeUpdateItem = _.cloneDeep(this.formValues);
          this.isShowBtnEdit = !(data.safety_diary_comment_corrections[0].status_code === 1 &&
            data.safety_diary_comment_corrections[0].recovery_flag === 1);
        }
      },
      {
        immediate : true,
        deep: true
      }
    );
  },
  computed: {
    formTitle() {
      return TITLE;
    },
    USER_LOGIN(){
      return Store.getters["Login/getUser"];
    }
  },
  methods: {

    async getItems(id) {
      if (id) {
        await Store.dispatch(`${STORE}/getDetail`, id);
      }
    },

    formUpdate(params) {
      this.formValues = { ...this.formValues, ...params };
    },

    onEditable() {
      this.editable = true;
      this.backPreview = false;
    },

    async onSubmit() {
      this.isSubmitted = true;
      const hasId = "id" in this.formValues.safety_diaries;
      let _formValues = _.cloneDeep(this.formValues);
      _formValues.safety_diaries["safety_form"] = 1;
      _formValues.safety_diary_comment_corrections.forEach(e => {
        e.safety_diary_images.forEach(e1 => {
          delete e1.check;
        });
      });
      delete _formValues.safety_diary_comment_corrections[0].recovery_date;
      delete _formValues.safety_diary_comment_corrections[0].recovery_user_id;
      const result = await Store.dispatch(hasId ? `PatrolRecord/update` : `PatrolRecord/post`, _formValues);
      if (result.hasError) {
        this.flagSubmit = !this.flagSubmit;
        this.isSubmitted = false
        return;
      } else {
        if (hasId) {
          this.editable = false;
          this.getItems(this.recordId);
          this.$emit("actionSuccess",false);
        } else {
          this.$emit("actionSuccess",true);
        }
        Store.dispatch("Toast/show", {
          status: 200,
          message: hasId ? "更新しました" : "登録しました",
        });
      }
      this.isSubmitted = false;
    },

    updateValidate({ valid }) {
      this.valid = valid;
    },

    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.backPreview = true;
        this.isShowConfirmDialog = false;
        this.formValues = _.cloneDeep(this.beforeUpdateItem);

        this.editable = false;
      } else {
        this.backPreview = true;
        this.$emit("cancel");
      }
    },
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
    openConfirmRecovery() {
      this.isShowConfirmRecoveryDialog = true;
    },
    async onRecovery() {
      let _formValues = _.cloneDeep(this.formValues);
      _formValues.safety_diary_comment_corrections[0].recovery_flag = 1;
      _formValues.safety_diary_comment_corrections[0].recovery_user_id = this.USER_LOGIN.id;
      let user_name = `${this.USER_LOGIN.name_sei} ${this.USER_LOGIN.name_mei}`;
      _formValues.safety_diary_comment_corrections[0].recovery_user_name = user_name;
      _formValues.safety_diary_comment_corrections[0].recovery_date = format(new Date(), "yyyy/MM/dd");
      let temp = {
        report_flag: 1,
        safety_diary_id : this.recordId,
        safety_diary_comment_corrections : _.cloneDeep(_formValues.safety_diary_comment_corrections)
      };
      delete temp.safety_diary_comment_corrections[0].status_code;
      let rs = await Store.dispatch(`PatrolRecord/report`, temp);
      if (!rs.hasError) {
        this.isShowConfirmRecoveryDialog = false;
        Store.dispatch("Toast/show", {
          status: 200,
          message: "是正しました。"
        });
        this.editable = false;
        await this.getItems(this.recordId);
        this.$emit("actionSuccess", false);
      }
    },
    async onChangeStatusCode(flag) {
      let temp = {
        safety_diary_ids: [this.recordId],
        safety_diary_comment_correction_ids: [this.childId]
      };
      let status = flag ? 1 : 0;
      let params = { ...temp, approve_flag: status };
      const result = await Store.dispatch(`PatrolRecord/updateStatus`, params);
      if (!result.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: flag ? "承認しました。" : "承認解除しました。"
        });
        if (flag) {
          this.isShowBtnEdit = false;
        } else {
          this.isShowBtnEdit = true;
        }
        this.editable = false;
        await this.getItems(this.recordId);
        this.$emit("actionSuccess",false);
        this.formValues.safety_diary_comment_corrections[0].status_code = status;
      }
    },
    async updateStatusRejectAfterRecovery(message) {
      let temp = {
        safety_diary_ids: [this.recordId],
        safety_diary_comment_correction_ids: [this.childId],
        message_reject: message
      };
      let params = { ...temp};
      const result = await Store.dispatch(`PatrolRecord/updateStatusReject`, params);
      if (!result.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "否認しました。"
        });
        this.formValues.safety_diary_comment_corrections[0].status_code = 1;
        this.$emit("actionSuccess",false);
      }
    },
    onNextTab() {
      this.tab = this.tab + 1;
    },
    updateWorkPlace(workPlace) {
      this.workPlace = workPlace;
    },
    actionSuccess(flag) {
      this.$emit("actionSuccess",flag);
    },
    setLoading(flg) {
      this.editable = false;
      this.$emit('loading', flg);
    },
    showLoading(flg) {
      this.$emit('showLoading', flg);
    }
  },
};
</script>

<style lang="css" scoped>
.from-close-btn {
  float: right
}
.shiteki-title-center {
  width: calc(100% - 120px - 216px);
  text-align: center;
  font-size: 20px;
  color: #1b9c4f;
  overflow: hidden;
  text-indent: 4px;
  letter-spacing: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.title-form {
  width: 120px;
}

::v-deep .v-tabs-bar .theme--light.v-tabs>.v-tabs-bar .v-tab--disabled, .theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active),
::v-deep .v-tabs-bar .theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-btn, .theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon {
  color: #1b9c4f !important;
}

</style>
