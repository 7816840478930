var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormDialog',{scopedSlots:_vm._u([{key:"header",fn:function(){return [(!_vm.$sp)?_c('FormWorkNavi',{attrs:{"tab":_vm.tab,"editable":_vm.editable,"workApprovalFlag":_vm.workApprovalFlag,"stateHandlingFlag":_vm.stateHandlingFlag,"isTablesGroup":_vm.isTablesGroup,"isNewItem":_vm.isNewItem,"isShowButtonCopy":_vm.isShowButtonCopy,"isShowConfirmDialog":_vm.isShowConfirmDialog,"isShowScheduleDialog":_vm.isShowScheduleDialog,"formtitle":_vm.formtitle,"FORMS":_vm.FORMS,"valid":_vm.valid,"onCopy":_vm.onCopy,"onEditable":_vm.onEditable,"onSubmit":_vm.onSubmit,"onClickBtnClose":_vm.onClickBtnClose,"statusEditable":_vm.statusEditable},on:{"onShowScheduleDialog":function () {
            _vm.isShowScheduleDialog = true;
          },"onCloseConfirmDialog":function () {
            _vm.isShowConfirmDialog = true;
          }},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}}):_vm._e(),(_vm.$sp)?_c('SpFormWorkNavi',{attrs:{"tab":_vm.tab,"editable":_vm.editable,"workApprovalFlag":_vm.workApprovalFlag,"stateHandlingFlag":_vm.stateHandlingFlag,"isTablesGroup":_vm.isTablesGroup,"isNewItem":_vm.isNewItem,"isShowButtonCopy":_vm.isShowButtonCopy,"isShowConfirmDialog":_vm.isShowConfirmDialog,"isShowScheduleDialog":_vm.isShowScheduleDialog,"formtitle":_vm.formtitle,"FORMS":_vm.FORMS,"valid":_vm.valid,"onCopy":_vm.onCopy,"onEditable":_vm.onEditable,"onSubmit":_vm.onSubmit,"onClickBtnClose":_vm.onClickBtnClose},on:{"onShowScheduleDialog":function () {
            _vm.isShowScheduleDialog = true;
          },"onCloseConfirmDialog":function () {
            _vm.isShowConfirmDialog = true;
          }},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}}):_vm._e()]},proxy:true},{key:"main",fn:function(ref){
          var params = ref.params;
return [_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(observer){return [_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('FormScheduleWorkTab1',{attrs:{"editable":_vm.editable,"item":_vm.formValues,"isNewItem":_vm.isNewItem,"resetFormImage":_vm.resetFormImage,"disableData":_vm.disableData,"mainHeight":params.mainHeight,"roleUser":_vm.roleUser,"errors":_vm.errors,"isBackData":_vm.isBackData,"copyObject":_vm.copyObject,"arrLevel1":_vm.arrLevel1,"isParentEditable":_vm.isParentEditable},on:{"initialObjectCopy":_vm.initialObjectCopy,"formUpdate":_vm.formUpdate}})],1),_c('v-tab-item',[_c('FormScheduleWorkTab2',{attrs:{"editable":_vm.editable,"item":_vm.formValues,"mainHeight":params.mainHeight},on:{"formUpdate":_vm.formUpdate}})],1),_c('v-tab-item',[_c('FormChangeHistory',{attrs:{"editable":_vm.editable,"item":_vm.formValues,"mainHeight":params.mainHeight},on:{"formUpdate":_vm.formUpdate}})],1)],1),_c('ValidationCallback',{attrs:{"observer":observer},on:{"callback":_vm.updateValidate}})]}}],null,true)}),_c('Popup',{attrs:{"width":"480px","dialog":_vm.isShowConfirmDialog}},[_c('ConfirmCloseDialog',{attrs:{"title":"フォームを閉じる確認","text1":"フォームを閉じますがよろしいですか？\n入力内容は保存されません。","text2":"このページから移動してもよろしいですか？","warning":""},on:{"close":_vm.onClickBtnCancelForm,"yes":_vm.closeForm}})],1),_c('Popup',{attrs:{"width":"480px","dialog":_vm.isShowScheduleDialog}},[_c('ConfirmScheduleDialog',{attrs:{"title":_vm.getTitle(),"text":_vm.getText(),"textBtn":_vm.getTextBtn(),"warning":""},on:{"close":function($event){_vm.isShowScheduleDialog = false},"yes":_vm.updateStateHandling}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }