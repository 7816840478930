import Api, { Mock } from "../api";
import entrance_history_list from "./mock/entrance_history_list.json";
import entrance_history_load_children from "./mock/entrance_history_load_children.json";
import entrance_history_detail from "./mock/entrance_history_detail.json";
import entrance_history_list_user_panel from "./mock/entrance_history_list_user_panel.json";
import entrance_history_list_entrance_panel from "./mock/entrance_history_list_entrance_panel.json";

const ENTRANCE_HISTORY_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/entrance_histories`;
const GET_LIST_URL = `${ENTRANCE_HISTORY_BASE_URL}/list`;
const GET_CHILDREN_URL = `${ENTRANCE_HISTORY_BASE_URL}/list_by_company`;
const GET_DETAIL_URL = `${ENTRANCE_HISTORY_BASE_URL}/detail`;
const CREATE_URL = `${ENTRANCE_HISTORY_BASE_URL}/create`;
const UPDATE_URL = `${ENTRANCE_HISTORY_BASE_URL}/update`;
const GET_LIST_USER_PANEL_URL = `${ENTRANCE_HISTORY_BASE_URL}/list_user_panel`;
const UPDATE_ENTRANCE_PANEL_URL = `${ENTRANCE_HISTORY_BASE_URL}/update_entrance_panel`;
const GET_LIST_ENTRANCE_PANEL_URL = `${ENTRANCE_HISTORY_BASE_URL}/list_entrance_panel`;

if (Mock) {
  Mock.onGet(GET_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: entrance_history_list,
  });

  Mock.onGet(GET_CHILDREN_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: entrance_history_load_children,
  });
  Mock.onGet(new RegExp(`${GET_DETAIL_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: entrance_history_detail,
  });
  Mock.onPost(CREATE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(UPDATE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(GET_LIST_USER_PANEL_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: entrance_history_list_user_panel,
  });
  Mock.onPost(UPDATE_ENTRANCE_PANEL_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(GET_LIST_ENTRANCE_PANEL_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: entrance_history_list_entrance_panel,
  });
}

export const entranceHistory = {
  getListHistory: (params) => {
    return Api.get(GET_LIST_URL, params);
  },
  loadChildren: (params) => {
    return Api.get(GET_CHILDREN_URL, params);
  },
  getDetail: (params) => {
    return Api.get(`${GET_DETAIL_URL}/${params}`);
  },
  post: async (params) => {
    return Api.post(CREATE_URL, params);
  },
  update: async (params) => {
    return Api.post(UPDATE_URL, params);
  },
  getListUserPanel: (params) => {
    return Api.get(GET_LIST_USER_PANEL_URL, params);
  },
  updateEntrancePanel: async (params) => {
    return Api.post(UPDATE_ENTRANCE_PANEL_URL, params);
  },
  getListEntrancePanel: async (params) => {
    return Api.get(GET_LIST_ENTRANCE_PANEL_URL, params);
  }
};
