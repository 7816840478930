import Api, { Mock } from "../api";
import mock_form_print from "./mock/safety_form_print.json";

const FORM_PRINT_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}`;
const GET_LIST_URL = `${FORM_PRINT_BASE_URL}/am_reports/get_all_am_reports`;
const GET_USER_ROLE = `${FORM_PRINT_BASE_URL}/users/get_user_role`;
const GET_COMPANY_PARNER_FOR_PRINT = `${FORM_PRINT_BASE_URL}/field_trees/get_company_partner_for_print`;


// .envのVUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  Mock.onGet(GET_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_form_print,
  });
  Mock.onGet(GET_USER_ROLE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: {
        role: 1
      },
    },
  });
  Mock.onGet(GET_COMPANY_PARNER_FOR_PRINT).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        {
          field_tree_id: 1,
          company_name: "清和工業株式会社xxx株式会社",
          address: "2市区"
        },
      ]
    },
  });
}

export const report = {
  get: (params) => {
    return Api.get(GET_LIST_URL, params);
  },
  getUserRole: (params) => {
    return Api.get(`${GET_USER_ROLE}/${params}`);
  },
  getCompanyPartnerForPrint: (params) => {
    return Api.get(GET_COMPANY_PARNER_FOR_PRINT, params);
  },
  getUserRolePrime: (value) => {
    return Api.get(`${GET_USER_ROLE}/${value.id}`,{ params: value.value});
  },
};
