<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="機材種別" :editable="editable">
                        <InputText
                          name="machine_company_type_item_tree_name"
                          :values="formValues.machine_companies"
                          :editable="false"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="機材名" :editable="editable">
                        <InputText
                          name="machine_name"
                          :values="formValues.machine_companies"
                          :editable="false"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="現場機材種別" :editable="editable">
                        <span style="cursor: pointer" @click="editable ? openFormMachineCompanyType() : null" >
                          <v-text-field
                            v-model="machineType"
                            dense
                            readonly
                            :disabled="!editable"
                            :filled="editable"
                            color="primary"
                            :append-icon="editable ? 'mdi-menu-down':''"
                          ></v-text-field>
                          </span>
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="管理名（呼名）" :editable="editable">
                        <InputText
                          name="control_name"
                          :values="formValues"
                          :editable="editable"
                          validation_label="管理名（呼名）"
                          validation_rules="max:125"
                          @onInput="onInput"
                          testid="input-control_name"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-row>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="持込機械受理番号" :editable="editable">
                            <InputText
                              name="control_number"
                              :values="formValues"
                              :editable="editable"
                              validation_label="持込機械受理番号"
                              validation_rules="number-char-symbol|max:20"
                              @onInput="onInput"
                              testid="input-control_number"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="シリアル番号" :editable="editable">
                            <InputText
                              name="serial_number"
                              :values="formValues"
                              :editable="editable"
                              validation_label="シリアル番号"
                              validation_rules="number-char-symbol|max:125"
                              @onInput="onInput"
                              testid="input-serial_number"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label
                            label="搬入予定日"
                            :editable="editable"
                          >
                            <InputDatepicker
                              name="delivery_date"
                              :editable="editable"
                              :values="formValues"
                              :flagNull="true"
                              validation_label="搬入予定日"
                              validation_rules="compare-to-carry-out-date:@搬出予定日"
                              @onInput="onInput"
                              testid="input-delivery_date"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>

                    <v-col sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label
                            label="搬出予定日"
                            :editable="editable"
                          >
                            <InputDatepicker
                              name="carry_out_date"
                              :editable="editable"
                              :values="formValues"
                              :flagNull="true"
                              validation_label="搬出予定日"
                              validation_rules="compare-to-delivery-date:@搬入予定日"
                              @onInput="onInput"
                              testid="input-carry_out_date"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <Label
                        label="現場機材画像"
                        :editable="editable"
                      >
                        <MachineFieldImagesInput
                          name="machine_company_field_images"
                          :values="formValues"
                          :editable="editable"
                          @onInput="onInput"
                          :errors="errors"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label
                        label="備考"
                        :editable="editable"
                      >
                        <InputTextArea
                          name="remarks"
                          :editable="editable"
                          :values="formValues"
                          validation_label="備考"
                          validation_rules="max:512"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
      <Popup :dialog="isShowMachineCompanyType">
        <MachineCompanyTypeForm
          @cancel="closeMachineCompanyForm"
          @changeMachineCompanyType="changeMachineCompanyType"
          :machine_company_field_type_item_tree_id="formValues.machine_company_field_type_item_tree_ids"
          :machine_company_type_tree="MACHINE_COMPANY_FIELD_TYPE_ARRAYS"
        />
      </Popup>
    </div>
  </div>
</template>
<script>
import Label from "@/components/forms/elements/Label.vue";
import InputText from "@/components/forms/elements/InputText.vue";
import Select from "@/components/forms/elements/Select.vue";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import InputTextArea from "@/components/forms/elements/InputTextArea.vue";
import MachineFieldImagesInput from "./components/MachineFieldImagesInput.vue";
import MachineCompanyTypeForm from "@/components/forms/companyMachines/components/MachineCompanyTypeForm.vue";
import Popup from "@/components/common/Popup.vue";
import _ from  "lodash";
import { Store } from "@/store/Store";
const STORE = "MachineFieldInspection";

export default {
  data: () => {
    return {
      machineType:null,
      formValues: {},
      isShowMachineCompanyType: false,
      MACHINE_COMPANY_FIELD_TYPE_ARRAYS: [],
      flagChange : false
    };
  },
  components: {
    MachineFieldImagesInput,
    InputTextArea,
    Label,
    InputText,
    Select,
    InputDatepicker,
    MachineCompanyTypeForm,
    Popup
  },
  props: {
    item: {
      type: Object,
      default: {}
    },
    editable: {
      type: Boolean,
      default: false
    },
    mainHeight: {
      type: Number,
      default: 0
    },
    formUpdate: Function,
    errors: {
      type: Object,
      default: {}
    },
    backData : Boolean,
    initialIds : Array,
    initialInspectionItems : Object
  },
  async mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
      },
      {
        deep: true
      }
    );
    await this.getListMachineCompanyType();
    this.$watch(
      () => Store.getters[`CompanyMachines/getMachineCompanyType`],
      (data) => {
        if (data) {
          this.MACHINE_COMPANY_FIELD_TYPE_ARRAYS = [...data];
          this.machineType = this.getMachineCompanyFieldType(this.formValues.machine_company_field_type_item_tree_ids);
        }
      },
      {
        immediate : true,
        deep: true
      }
    );

    this.$watch(
      () => this.formValues?.machine_company_field_type_item_tree_ids,
      (newValue, oldValue) => {
        if (newValue !== oldValue && Array.isArray(newValue)) {
          if (newValue.length > 0) {
            this.getMachineCompanyInspecList(newValue);
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => this.backData,
      (newValue) => {
        if (newValue) {
          this.machineType = this.getMachineCompanyFieldType(this.item.machine_company_field_type_item_tree_ids);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },
  methods: {
    async getListMachineCompanyType(){
      await Store.dispatch(`CompanyMachines/getMachineCompanyType`, {
        company_id: this.CURRENT_SITE.company_id,
        company_branch_id: this.CURRENT_SITE.company_branch_id ? this.CURRENT_SITE.company_branch_id : ""
      });
    },
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    openFormMachineCompanyType() {
      this.isShowMachineCompanyType = true;
    },
    closeMachineCompanyForm() {
      this.isShowMachineCompanyType = false;
    },
    changeMachineCompanyType(value) {
      this.isShowMachineCompanyType = false;
      const _formValues = { ...this.formValues };
      _formValues.machine_company_field_type_item_tree_ids = value;
      this.flagChange = true;
      this.machineType = this.getMachineCompanyFieldType(value);
      this.formValues = {..._formValues};
      this.$emit("formUpdate", _formValues);
    },
    getMachineCompanyFieldType(ids) {
        let arr = _.cloneDeep(ids);
        let machine_type_array = _.cloneDeep(this.MACHINE_COMPANY_FIELD_TYPE_ARRAYS);
        let rs = "";
        if (arr.length > 0 && machine_type_array.length > 0) {
          arr.forEach((e, index) => {
            let a1 = machine_type_array.find(e1 => e1.id == e);
            if (a1) {
              rs = index == 0 ? rs + a1.type : rs + "/" + a1.type;
              machine_type_array = a1.childrens;
            }
          });
        }
        rs = rs == "" ? "" : rs;
        return rs;
    },
    async getMachineCompanyInspecList(array) {
      let resultDetail = await Store.dispatch(`${STORE}/getMachineCompanyInspecList`, array.at(-1));
      let rs = _.cloneDeep(resultDetail.data.contents.entries);
      let _formValues = { ...this.formValues };
      _formValues.dataInspectionDaily = rs.machine_company_inspection_lists ? [ ...rs.machine_company_inspection_lists] : [];
      _formValues.dataInspectionMonthly = rs.machine_company_monthly_inspection_lists ? [...rs.machine_company_monthly_inspection_lists] : [];
      if (JSON.stringify(array) == JSON.stringify(this.initialIds)) {
        _formValues.machine_company_field_inspection_items = this.initialInspectionItems.daily;
        _formValues.machine_company_field_monthly_inspection_items = this.initialInspectionItems.monthly;
      } else {
        _formValues.machine_company_field_inspection_items = this.flagChange ? this.convertDataInspectionList(rs?.machine_company_inspection_lists) : this.formValues.machine_company_field_inspection_items;
        _formValues.machine_company_field_monthly_inspection_items = this.flagChange ? this.convertDataInspectionList(rs?.machine_company_monthly_inspection_lists) : this.formValues.machine_company_field_monthly_inspection_items;
        this.flagChange = false;
      }
      this.formValues = _formValues;
      this.$emit("formUpdate", _formValues);
    },
    convertDataInspectionList(arr) {
      let rs = [];
      if (arr) {
        arr.forEach(e => {
          rs.push({
            machine_company_inspection_list_id: e.id,
            inspection_item: e.inspection_item,
            main_point: e.main_point
          });
        });
      }
      return rs;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";

.text-btn {
  text-transform: none !important;
}

.machine-type {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 35vw;
  white-space: nowrap;
  text-align: left
}
</style>
