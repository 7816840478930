import Vue from "vue";
import Vuex from "vuex";
import { deviceCarryOns } from "../../../api/modules/deviceCarryOns";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = deviceCarryOns;

export const DeviceCarryOns = {
  namespaced: true,

  state: {
    data: [],
    pagination: {},
    machineField: {},
    machineElectricalTool: [],
    listOwnerCompanyUser : [],
    userDriverInfo : []
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_MACHINE_FIELD(state, payload){
      state.machineField = payload;
    },
    SET_MACHINE_ELECTRICAL_TOOL(state, payload){
      state.machineElectricalTool = payload;
    },
    SET_LIST_OWNER_COMPANY_USER(state, payload){
      state.listOwnerCompanyUser = payload;
    },
    SET_DATA_TO_DRIVER_INFO(state, payload) {
      let data = state.userDriverInfo.filter(e => e.driver_user_id != payload.driver_user_id);
      state.userDriverInfo = [...data];
      state.userDriverInfo.push({
        driver_user_id: payload.driver_user_id,
        driver_licences: payload.data.driver_licences,
        driver_sp_trainings: payload.data.driver_sp_trainings
      });
    }
  },

  actions: {
    async get({ commit }, payload) {
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;
      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },

    async getReport({ commit }, payload) {
      const response = await ENTITY.getReport(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;
      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },
    
    async post(_context, payload) {
      return await ENTITY.post(payload);
    },

    async update(_context, payload) {
      return await ENTITY.update(payload);
    },

    async delete(_context, payload) {
      return await ENTITY.delete(payload);
    },

    async createMachineFieldReport(_context, payload) {
      return await ENTITY.createMachineFieldReport(payload);
    },

    async updateStatus(_context, payload) {
      return await ENTITY.updateStatus(payload);
    },
    
    async getMachineFieldDetail({ commit }, payload){
      const response = await ENTITY.getMachineFieldDetail(payload);
      const entries =  response.data.contents.entries;
      commit("SET_MACHINE_FIELD", entries);
      return response
    },

    async getListMachineElectricalTools({ commit }, payload){
      const response = await ENTITY.getListMachineElectricalTools(payload);
      const entries =  response.data.contents.entries;
      commit("SET_MACHINE_ELECTRICAL_TOOL", entries);
      return response
    },

    async getListOwnerCompanyUser({ commit }, payload){
      const response = await ENTITY.getListOwnerCompanyUser(payload);
      if (response.hasError) {
        return response;
      }
      const entries =  response.data.contents.entries;
      commit("SET_LIST_OWNER_COMPANY_USER", entries);
      return response
    },

    async getDriverInfo({ commit }, payload) {
      const response = await ENTITY.getDriverInfo(payload);
      if (response.hasError) {
        return response;
      }
      const entries = response.data.contents.entries;
      let params = {
        driver_user_id: payload,
        data: entries
      };
      commit("SET_DATA_TO_DRIVER_INFO", params);
      return response;
    }
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getMachineFieldDetail: (state) => {
      return state.machineField;
    },
    getListMachineElectricalTools: (state) => {
      return state.machineElectricalTool;
    },
    getListOwnerCompanyUser: (state) => {
      return state.listOwnerCompanyUser;
    },
    getDriverInfo: (state) => {
      return state.userDriverInfo;
    },
  },
};

