const SCHEDULE_GATE_INITIAL_ITEM = {
  passage_date_from: null,
  passage_date_to: null,
  field_construction_id: null,
  group_id: null,
  field_tree_id: null,
  foreman_user_id: null,
  work_hour_zone: null,
  gate_id: null,
  truck_name: "",
  truck_count: null,
  luggage: "",
  work_details: "",
  expect_danger: "",
  status_code: 1,
  driver_company_name: "",
  driver_name: "",
  approval_user_name_sei: null,
  approval_user_name_mei: null,
  approval_date: null,
  owner_flag: 1,
  device_work_classifications: [
    {
      cmn_mst_work_classification_id: 1,
    }
  ],
  device_safety_instructions: [
    {
      cmn_mst_safety_instruction_id: null,
    },
  ],
  device_usage_times: [
    {
      usage_start_time: null,
      usage_end_time: null,
    },
  ],
  device_users: [
    {
      field_director_id: null,
    },
  ],
};

const SCHEDULE_GATE_INITIAL_ITEM_OBAYASHI = {
  passage_date_from: null,
  passage_date_to: null,
  field_construction_id: null,
  field_tree_id: null,
  field_tree_id_1 : null,
  job_id : null,
  field_tree_user_id : null,
  field_tree_user_tel : '',
  main_user_id : null,
  cmn_mst_vehicle_type_id : null,
  shipping_company : '',
  shipping_company_tel : null,
  // crane_uses : {
  //   crane_id: null,
  //   suspended_load: '',
  //   field_item_tree_id: null,
  //   remarks: '',
  // },
  crane_id: null,
  crane_suspended_load: '',
  crane_field_item_tree_id: null,
  crane_remarks: '',
  commodity_type: null,
  work_hour_zone: null,
  gate_id: null,
  truck_name: "",
  truck_count: null,
  luggage: "",
  work_details: "",
  expect_danger: "",
  status_code: 1,
  driver_company_name: "",
  driver_name: "",
  approval_user_name_sei: null,
  approval_user_name_mei: null,
  approval_date: null,
  owner_flag: 1,
  device_work_classifications: [
    {
      cmn_mst_work_classification_id: 1,
    }
  ],
  device_safety_instructions: [
    {
      cmn_mst_safety_instruction_id: null,
    },
  ],
  device_usage_times: [
    {
      usage_start_time: null,
      usage_end_time: null,
    },
  ],
  device_users: [
    {
      field_director_id: null,
    },
  ],
  company_spot: '',
  is_company_spot: false
};

const STATUS_CODE = [
  { name: "搬入", value: 1 },
  { name: "搬出", value: 2 },
  { name: "占有", value: 3 },
];
/**
 * (共通)
 * タイトル
 */
const TITLE_FORM_CREATE_EDIT = {
  NEW: "ゲート予定情報",
  EDIT: "ゲート予定情報",
};

/**
 * フォームとタブの設定
 */
const FORMS_CREATE_EDIT = {
  ScheduleGateFormPage1: { id: 1, title: "基本情報" },
  ScheduleGateFormPage2: { id: 2, title: "作業内容" },
  ScheduleGateFormPage3: { id: 3, title: "登録・変更履歴" },
};

const FORMS_CREATE_EDIT_OBAYASHI = {
  ScheduleGateFormPage1: { id: 1, title: "基本情報" },
  ScheduleGateFormPage2: { id: 2, title: "登録・変更履歴" },
};

const MINS_ON_HOUR = ["00", "15", "30", "45"];
const getProhibitTimes = (isAll, isEnd) => {
  let prohibitTimes = [];
  let i = 0;
  while (i <= 47) {
    for (let m of MINS_ON_HOUR) {
      let hour = i < 10 ? "0" + i : i;
      let element = {
        id: hour + ":" + m,
        name: ((i % 24) < 10 ? (i == 24 && m == '00' ? 24 : ("0" + (i % 24))) : (i % 24)) + ":" + m,
      };
      prohibitTimes.push(element);
    }
    i++;
  }
  if (isEnd || isAll) {
    prohibitTimes.push({ id: "48:00", name: "24:00" });
  }
  return prohibitTimes;
};

const PROHIBIT_TIME_START = getProhibitTimes(false, false);
const PROHIBIT_TIME_END = getProhibitTimes(false, true);

const getCounts = () => {
  let i = 0;
  return [...new Array(200)].map(() => {
    i++;
    return {
      id: "" + i,
      name: "" + i,
    };
  });
};

const COUNT_ARRAY = getCounts();

const SCHDULE_GATE_STATUS = {
  CARRYIN: { id: 1, name: " 搬入" },
  CARRYOUT: { id: 2, name: "搬出" },
  OCUPATION: { id: 3, name: "占有" },
};

const DIALOG_TEXT = {
  REJECT_TITLE: "承認解除確認",
  REJECT_TEXT1: "選択した予定の承認を解除します。",
  REJECT_TEXT2: "承認を解除してもよろしいですか。",
  REJECT_ERROR_TEXT1: "選択したデータは全て承認されていないため、承認解除出来ません。",
  REJECT_BTN_SUBMIT: "解除",
  APPROVE_TITLE: "承認確認",
  APPROVE_TEXT1: "選択した予定を承認します。",
  APPROVE_TEXT2: "承認してもよろしいですか。",
  APPROVE_BTN_SUBMIT: "承認",
  ALL_APPROVE_TTTLE: "承認確認",
  ALL_APPROVE_TEXT: "選択された作業予定は全て承認済みです。",
  BTN_OK: "OK",
};

const DIALOG_REMOVE_TEXT = {
  TITLE: "削除確認",
  TEXT_ALL_APPROVE_1: "選択された作業予定は全て承認済みです。",
  TEXT_ALL_APPROVE_2: "削除できません。",
  BTN_OK: "OK",
  TEXT_1: "選択したデータを削除します。",
  TEXT_2: "未承認のデータのみ削除できます。",
  TEXT_3: "この処理を元に戻すことはできません。",
  TEXT_4: "本当に削除してもよろしいですか？",
  BTN_SUBMIT: "削除",
};

const SCHDULE_APPROVE = {
  APPROVED: { id: 1, name: "承認済" },
  REJECT: { id: 0, name: "未承認" },
};

const DEVICE_EMPTY = [
  {
    device_id: null,
    device_name: null,
    device_description: [],
    total_children_item: null,
    schedule_summary: [],
    children: []
  }
];

const HISTORY_TYPE = {
  COMMODITY_TYPE: 0,
  SUSPENDED_LOAD: 1
};

const SETTING_PLAN = {
  ScheduleGate: { id: 1, title: "ゲート" },
  ScheduleCrane: { id: 2, title: "クレーン" },
};

export {
  TITLE_FORM_CREATE_EDIT,
  FORMS_CREATE_EDIT,
  SCHEDULE_GATE_INITIAL_ITEM,
  SCHEDULE_GATE_INITIAL_ITEM_OBAYASHI,
  STATUS_CODE,
  PROHIBIT_TIME_START,
  PROHIBIT_TIME_END,
  COUNT_ARRAY,
  SCHDULE_GATE_STATUS,
  DIALOG_TEXT,
  DIALOG_REMOVE_TEXT,
  SCHDULE_APPROVE,
  DEVICE_EMPTY,
  FORMS_CREATE_EDIT_OBAYASHI,
  HISTORY_TYPE,
  SETTING_PLAN
};
