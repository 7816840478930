import Api, { Mock } from "../api";
import mock_company from "./mock/company_detail.json";
import mock_company_create from "./mock/company_create.json";

const GET_URL = `${process.env.VUE_APP_API_BASE_URL}/company/detail`;
const UPDATE_URL = `${process.env.VUE_APP_API_BASE_URL}/company/update`;

// TODO api 初回自社情報の登録
const CREATE_URL = `${process.env.VUE_APP_API_BASE_URL}/company/create`;
const GET_LIST_COMPANY_BY_FIELD_URL = `${process.env.VUE_APP_API_BASE_URL}/company/list_by_field`;
const GET_LIST_COMPANY_ALL = `${process.env.VUE_APP_API_BASE_URL}/company/list_by_name`;
const GET_ADD_USER_TO_COMPANY = `${process.env.VUE_APP_API_BASE_URL}/company/add_user_to_company`;

// .envのVUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  console.log("init Mock");
  Mock.onGet(new RegExp(`${GET_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_company,
  });

  Mock.onPost(CREATE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_company_create,
  });

  Mock.onPost(UPDATE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });

  Mock.onGet(new RegExp(`${GET_LIST_COMPANY_BY_FIELD_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_company,
  });
  Mock.onGet(GET_LIST_COMPANY_ALL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_company,
  });
}

export const companyPortal = {
  get: (params) => {
    return Api.get(`${GET_URL}/${params["id"]}`);
  },
  // TODO api 初回自社情報の登録
  create: (params) => {
    console.log("create:", params);
    return Api.post(CREATE_URL, params);
  },
  post: (params) => {
    console.log("post:", params);
    return Api.post(UPDATE_URL, params);
  },
  getListCompanyByField: (requestParams) => {
    if (typeof requestParams === 'object') {
      let params = { state_handling_flag: requestParams["state_handling_flag"] };
      return Api.get(`${GET_LIST_COMPANY_BY_FIELD_URL}/${requestParams["field_id"]}`, { params });
    }
    return Api.get(`${GET_LIST_COMPANY_BY_FIELD_URL}/${requestParams}`);
  },
  getListCompanyAll: (params) => {
    return Api.get(`${GET_LIST_COMPANY_ALL}`, {params});
  },
  getAddUserToCompany: (companyId) => {
    return Api.get(`${GET_ADD_USER_TO_COMPANY}/${companyId}`);
  },
};
