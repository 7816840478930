<template>
  <Sprite
    :item="item"
    :selected="selected"
    :onUpdate="onUpdate"
    :mainCanvasRect="mainCanvasRect"
    :acceptRatio="false"
    :isMoveable="isMoveable"
    @onselect="onSelect"
  >
    <svg preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect v-if="getImageShape() == 'Rectangle'" width="100%" height="100%" :fill="getImageColor()"/>
      <circle v-if="getImageShape() == 'Circle'" cx="50" cy="50" r="50" :fill="getImageColor()"/>
    </svg>
  </Sprite>
</template>
<script>
import Sprite from "./_Sprite.vue";
export default {
  components: {
    Sprite,
  },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    onUpdate: {
      type: Function,
      default: () => {},
    },
    mainCanvasRect: {
      type: Object,
      default: () => {
        return { x: 0, y: 0, height: 100, width: 100, scale: 1.0 };
      },
    },
    isMoveable: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    onSelect(id) {
      this.$emit("onselect", id);
    },

    getImageShape() {
      let key;

      if (this.item.image_svg && typeof this.item.image_svg === "object" && "key" in this.item.image_svg) {
          key = this.item.image_svg.key;
      } else {
        key = this.item.image_svg;
      }
      
      const shape = key.match(/(Rectangle|Circle)/);
      const color =  key.match(/(white|red|blue|green|black)/);
      if (color) {
        this.item.color = `${color[1]}`;
        this.item.transform.fill = `${color[1]}`;
      }
      return `${shape[1]}` ? `${shape[1]}` : "Rectangle";
    },

    getImageColor() {
      let color = this.item.color;
      let fill = this.item.transform.fill;
      return color ? color : fill;
    },
  },
};
</script>
<style lang="scss" scoped>
.sprite {
  border: none;
}
.svgWrapper {
  width: 100%;
  height: 100%;
}
.svg {
  width: 100%;
  height: 100%;
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}
</style>
