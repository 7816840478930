var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-form',{attrs:{"autocomplete":"off"}},[_c('v-container',[_c('v-data-table',{staticClass:"elevation-0 v-data-table__wrapper",attrs:{"headers":_vm.TABLE_LABELS,"items":_vm.item.machine_company_field_histories,"items-per-page":_vm.item.machine_company_field_histories.length,"height":_vm.mainHeight-16,"fixed-header":"","hide-default-footer":"","disable-sort":"","sort-by":"updatedAt","noDataText":_vm.NO_DATA_MESSAGE},scopedSlots:_vm._u([{key:"item.used_company_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"long-text"},[_vm._v(_vm._s(item.used_company_name))])]}},{key:"item.foreman_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"long-text"},[_vm._v(_vm._s(item.foreman_name))])]}},{key:"item.apply_user_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"long-text"},[_vm._v(_vm._s(item.apply_user_name))]),_c('div',{staticClass:"long-text"},[_vm._v(_vm._s(item.approve_apply_user_name))])]}},{key:"item.return_user_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"long-text"},[_vm._v(_vm._s(item.return_user_name))]),_c('div',{staticClass:"long-text"},[_vm._v(_vm._s(item.approve_return_user_name))])]}},{key:"item.inspection_results",fn:function(ref){
var item = ref.item;
return [(item.has_inspection_result_flag == _vm.HAS_INSPECTION_RESULT)?_c('v-btn',{staticClass:"primary btn-inspec",on:{"click":function($event){return _vm.openInspecResultForm(item)}}},[_vm._v(_vm._s(_vm.TITLE_BTN_INSPEC_RESULT))]):_vm._e()]}}],null,true)}),_c('Popup',{attrs:{"dialog":_vm.isClickOpenFormInspec}},[_c('InspectionResultForm',{attrs:{"isMoveFromViewDetail":true,"titleMoveDetail":_vm.titleDetail,"machineCompanyFieldHistoryId":_vm.machineCompanyFieldHistoryId},on:{"cancel":_vm.closeInspecResultForm}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }