import Vue from "vue";
import Vuex from "vuex";
import { machines } from "../../../api/modules/machines";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = machines;

export const Machines = {
  namespaced: true,

  state: {
    data: [],
    pagination: {},
    machines: {}
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_MACHINE(state, payload) {
      state.machines = payload;
    }
  },

  actions: {
    async get({ commit }, payload) {
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;
      
      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },

    async post(_context, payload) {
      return await ENTITY.post(payload);
    },

    async update(_context, payload) {
      return await ENTITY.update(payload);
    },

    async delete(_context, payload) {
      return await ENTITY.delete(payload);
    },
    async getDetail({ commit }, payload) {
      const response = await ENTITY.getDetail(payload);
      const entries =  response.data.contents.entries;
      commit("SET_MACHINE", entries);
      return response;
    },
    async getListMachineByType(_context, payload) {
      return await ENTITY.getListMachineByType(payload);
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getMachines: (state) => {
      return state.machines;
    }
  },
};
