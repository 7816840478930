<template>
  <div class="attachFile" :class="large ? 'Large' : ''">
    <button
      v-if="isRemove"
      class="attachFile__delete"
      @click="$emit('removeAttachFile', { id: file.id })"
    >
      <v-icon>mdi-close</v-icon>
    </button>
    <img
      v-if="isImage && 'data' in file"
      @click="$emit('showAttachFile', { id: file.id })"
      :src="file.data"
    />
    <div
      v-if="!isImage && 'data' in file"
      @click="$emit('showAttachFile', { id: file.id })"
      class="pdfImage"
    >
      PDF
    </div>
  </div>
</template>
<script>
export default {
  mounted() {},
  props: {
    large: {
      type: Boolean,
      defalut: false,
    },
    file: {
      type: Object,
    },
    isRemove: {
      type: Boolean,
      deafault: false,
    },
  },
  computed: {
    isImage() {
      const imgTypes = ["jpg", "png", "image"];
      return imgTypes.includes(this.file.type);
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.attachFile {
  width: 48px;
  height: 48px;
  border: 1px solid black;
  position: relative;
  margin-right: 8px;
  border-radius: 4px;
  &.Large {
    width: 64px;
    height: 64px;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }

  .attachFile__delete {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    position: absolute;
    top: -1px;
    right: -1px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  .pdfImage {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    background-color: #ccc;
  }
}
</style>
