<template>
  <FormDialog>
    <template #header>
      <!-- 
        (共通)
        ヘッダーは全て共通
       -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title style="color: #1b9c4f">
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editable"
          class="mr-5"
          depressed
          small
          outlined
          color="primary"
          @click="isShowConfirmDialog = true"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable && isNewItem"
          class="mr-5"
          depressed
          small
          :disabled="!valid"
          color="primary"
          @click="onSubmit(true)"
        >
          続けて登録
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          small
          :disabled="!valid"
          color="primary"
          :key="flagError"
          @click.once="onSubmit(false)"
        >
          保存
        </v-btn>
        <v-btn
          v-if="showBtnEdit"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!-- 
        (共通)
        全てのフォームのバリデーションの監視を行う
       -->
      <ValidationObserver ref="observer" v-slot="observer">
        <!-- フォームtab -->
        <v-tabs-items v-model="tab">
          <!-- 基本情報 -->
          <v-tab-item>
            <MachineApplicationsTab1
              :editable="editable"
              :isNewItem="isNewItem"
              :item="editedItem"
              :roleUser="roleUser"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <!-- 使用会社 -->
          <v-tab-item>
            <MachineApplicationsTab2
              :editable="editable"
              :item="editedItem"
              :isBackData="isBackData"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
              @updateBackData="updateBackData"
            />
          </v-tab-item>
          <!-- 写真 -->
          <v-tab-item>
            <MachineApplicationsTab3
              :editable="editable"
              :item="editedItem"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <!-- 見積・発注履歴 -->
          <v-tab-item>
          <MachineApplicationsTab4
            :editable="editable"
            :item="editedItem"
            :mainHeight="params.mainHeight"
            @formUpdate="formUpdate"
          />
        </v-tab-item>
        </v-tabs-items>
        <!-- 
          (共通)
          ValidationObserverのスロットプロパティを受け取って
          updateValidateメソッドを実行して、
          バリデーション結果をスクリプト側に渡してます
        -->
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
          入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import _ from "lodash";
import { Store } from "@/store/Store.js";
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/forms/device/coordinator/applications/components/ConfirmCloseDialog.vue";
import MachineApplicationsTab1 from "@/components/forms/device/coordinator/applications/MachineApplicationsTab1.vue";
import MachineApplicationsTab2 from "@/components/forms/device/coordinator/applications/MachineApplicationsTab2.vue";
import MachineApplicationsTab3 from "@/components/forms/device/coordinator/applications/MachineApplicationsTab3.vue";
import MachineApplicationsTab4 from "@/components/forms/device/coordinator/applications/MachineApplicationsTab4.vue";
import {
  ROLE_USER,
  TITLE_FORMS,
  FORMS_CREATE,
  FORMS_UPDATE,
  INIT_DATA_FORM,
  STORE,
} from "@/constants/DEVICE_COORDINATOR";

export default {
  data: () => {
    return {
      FORMS: FORMS_CREATE,
      TITLE_FORMS,
      valid: false,
      tab: null,
      editable: false,
      editedItem: {},
      beforeUpdateItem: {},
      isClickCloseBtn: false,
      isShowConfirmDialog: false,
      isBackData: false,
      ROLE_USER,
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    MachineApplicationsTab1,
    MachineApplicationsTab2,
    MachineApplicationsTab3,
    MachineApplicationsTab4
  },
  props: {
    items: Object,
    isNewItem: {
      type: Boolean,
      default: false
    },
    roleUser: Number,
    flagError: Boolean,
    isEmitted: Boolean,
    createdCompanyId: Number,
  },
  mounted() {
    if (!this.isNewItem) {
      this.FORMS = FORMS_UPDATE;
    }

    /**
     * Catch after submitted
     */
    this.$watch(
      () => this.isEmitted,
      (flag) => {
        if (flag && !this.isNewItem) {
          this.editable = false;
          this.$emit('resetFlagEmit')
        }
      },
      {
        immediate: true
      }
    );

    /**
     * (共通)
     * 新規作成を監視
     */
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        //新規の場合は最初から編集モード
        if (flag) this.editable = true;
        this.editedItem = _.cloneDeep(INIT_DATA_FORM);
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters[`${STORE}/getDetailMachineApplication`],
      (data) => {
        if (!this.isNewItem) {
          this.editedItem = _.cloneDeep(data);
          this.beforeUpdateItem = _.cloneDeep(data);
          this.$emit("formUpdate", this.editedItem);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    formtitle() {
      return this.isNewItem ? TITLE_FORMS.NEW : TITLE_FORMS.EDIT;
    },
    showBtnEdit() {
      if (this.editable) return false;
      else if (this.roleUser == ROLE_USER[3]) return true;
      else if (this.roleUser == ROLE_USER[1] || this.roleUser == ROLE_USER[2]) {
        if (this.createdCompanyId != this.USER_COMPANY_ID) return false;
        else return true;
      } else return false;
    },
    USER_COMPANY_ID() { 
      return JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user.company_id;
    },
  },
  methods: {
    updateBackData() {
      this.isBackData = false;
    },

    /**
     * (共通)
     * フォームの変更を送信
     */
    formUpdate(params) {
      this.editedItem = {...params};
      this.$emit("formUpdate", params);
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      this.editable = true;
    },

    async onSubmit(isContinueConfirm) {
      this.$emit('submitForm', isContinueConfirm);
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid }) {
      this.valid = valid;
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        const item = _.cloneDeep(this.beforeUpdateItem);
        this.formUpdate(item);
        this.editable = false;
        this.isBackData = true;
      } else {
        this.$emit("cancel");
      }
    },
    /**
     * (共通)
     * click button Close (X)
     */
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },

    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
