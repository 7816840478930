<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <!--  (共通) 会社ヘッダー -->
        <SitePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout :layoutParams="layoutParams">
          <template #tableHeader="{ updateHeader }">
            <TableHeader
              ref="tableHeader"
              :isDelete="false"
              :isAddNew="false"
              :isSearch="false"
              :pageTitle="PAGE_TITLE"
              :updateHeader="updateHeader"
            >
            </TableHeader>
          </template>
          <!-- 
            (共通)テーブル
            v-data-table自体は共通ですが、
            カラムによって変更をしたい場合はそれぞれ実装してください。
          -->
          <template #tableBody="{ tableHeight }">
            <v-data-table
              :headers="TABLE_LABELS"
              :items="items"
              :items-per-page="items.length"
              :height="tableHeight"
              fixed-header
              hide-default-footer
              disable-sort
              class="elevation-0 v-data-table__wrapper"
              item-key="id"
            >
              <template v-slot:[`item.type`]="{ item }">
                {{ convertType(item.type) }}
              </template>
              <template v-slot:[`item.icon`]="{ item }">
                <MarkExcel
                  class="mr-4"
                  @openItemForm="openItemForm"
                  :name_type="item.report_name_en"
                  :name_title="item.report_name_jp"
                />
                <MarkPdf
                  @openItemForm="openItemForm"
                  :name_type="item.report_name_en"
                  :name_title="item.report_name_jp"
                />
              </template>
            </v-data-table>
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>

    <Popup :dialog="popups.isShowItemForm" width="540px">
      <FormPrintingDialog :statusFlag=statusFlag @onSubmit="submitForm" :report_name_jp=report_name_jp :nameFile=nameFile @onCancel="closeItemForm" />
    </Popup>
  </div>
</template>

<script>
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import FormPrintingDialog from "./components/FormPrintingDialog.vue";
import { Store } from "@/store/Store.js";
import {
  SAFETY_TABLE_LABELS,
  LIST_TYPE_EXPORT,
  TEXT_LABEL,
  SAFETY_DOCUMENT_TYPE,
  ARR_GATE_CRANE_REPORT
} from "@/constants/SAFETY_FORM_PRINT";
import MarkExcel from "./components/MarkExcel.vue";
import MarkPdf from "./components/MarkPdf.vue";
import Popup from "@/components/common/Popup.vue";
import { format } from "date-fns";
import { HEADER_MENU_ITEMS_SITE } from "@/constants/GLOBALHEADER";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
import { USER_ROLE } from "@/constants/SAFETY_FORM_PRINT";
//タイトル
const PAGE_TITLE = "帳票印刷";
// テーブル設定
const TABLE_LABELS = SAFETY_TABLE_LABELS;
const URL_HEADER = `${process.env.VUE_APP_API_BASE_URL}/reports`;
//ストア
const STORE = "Report";
export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      PAGE_TITLE,
      TABLE_LABELS,
      /**
       * (共通)
       * 一覧データ
       */
      items: [],
      report_name_en: null,
      report_name_jp:null,
      type: null,
      work_date: null,
      to_date: null,
      file_name_pdf: "",
      file_name_excel: "",
      url: "",
      /**
       * (共通)
       * ポップアップの状態管理
       */
      popups: {
        // 追加/編集フォーム
        isShowItemForm: false,
      },
      ENV_CLIENT,
      nameFile:"",
      statusFlag : false,
      fieldId: null,
    };
  },
  components: {
    DefaultLayout,
    TableLayout,
    TableHeader,
    MarkExcel,
    MarkPdf,
    Popup,
    SitePortalHeader,
    FormPrintingDialog,
  },
  computed: {
    /**
     * (スケジュール)
     * 現在の現場を取得
     */
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },
  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    if (ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT) {
      await Store.dispatch("GlobalHeader/setSiteMenu", {
        menuId: HEADER_MENU_ITEMS_SITE.SAFETY_REPORTS.id,
      });
    }
    else{
      await Store.dispatch("GlobalHeader/setSiteMenu", {
        menuId: HEADER_MENU_ITEMS_SITE.SCHEDULE.id,
      });
    }

    this.getItems();
    
    this.$watch(
      () => this.CURRENT_SITE,
      (data) => {
        if (!this.fieldId && data.field_id) {
          this.fieldId = data.field_id;
          Store.dispatch(`${STORE}/getUserRole`, data.field_id);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [Store.getters[`${STORE}/getData`], Store.getters["Report/getUserRole"]],
      (data) => {
        /**
         *  (共通)
         * 一覧リストの格納
         */
        if (data[1].role == USER_ROLE.PARTNERCOMPANY.id) {
          this.items = data[0].filter(
            (item) => item.report_name_en != "Grn_All_Adjust_Work_and_Safety" && item.report_name_en != "Grn_All_Result_Work" && item.report_name_en != "Grn_Diary" && item.report_name_en != "Grn_Monthly_Work_Summary"
          );
        } else {
          this.items = data[0];
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => [Store.getters["File/getData"], Store.getters["File/getTypeDoc"]],
      (data) => {
        if (data && data[0] && data[1]) {
          const url = window.URL.createObjectURL(new Blob([data[0]]));
          const link = document.createElement("a");
          let toDate = '';
          if (this.to_date) {
            toDate = '_' + format(this.to_date, "yyyyMMdd");
          }
          link.href = url;
          link.setAttribute(
            "download",
            `${
              data[1] == SAFETY_DOCUMENT_TYPE.PDF
                ? this.file_name_pdf
                : this.file_name_excel
            }_${format(this.work_date, "yyyyMMdd")}${toDate}.${
              data[1] == SAFETY_DOCUMENT_TYPE.PDF ? "pdf" : "xlsx"
            }`
          );
          document.body.appendChild(link);
          link.click();
          Store.dispatch("File/clearData");
          this.to_date = null;
        }
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    async openItemForm(type, name,name_title) {
      if (ARR_GATE_CRANE_REPORT.includes(name)) {
        this.statusFlag = true;
      }
      this.nameFile = name;
      this.type = type;
      this.report_name_en = name;
      this.report_name_jp = name_title;
      Store.dispatch(`${STORE}/getUserRole`, this.CURRENT_SITE.field_id);
      this.popups.isShowItemForm = true;
      
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.statusFlag = false;
      this.nameFile = "";
    },
    async submitForm(params) {
      this.work_date = new Date(params.work_date);
      if (params.to_date) {
        this.to_date = new Date(params.to_date);
      }
      if (params.gate_ids) {
        this.gate_ids = params.gate_ids;
      }
      if (params.cranes_ids) {
        this.cranes_ids = params.cranes_ids;
      }
      this.url = LIST_TYPE_EXPORT.find(
        (items) => items.name == this.report_name_en
      ).url;
      this.file_name_pdf = LIST_TYPE_EXPORT.find(
        (items) => items.name == this.report_name_en
      ).name_pdf;
      this.file_name_excel = LIST_TYPE_EXPORT.find(
        (items) => items.name == this.report_name_en
      ).name_excel;
      params["file_code"] = this.type;
      params['is_obay'] = 0;
      if (ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT) {
        params['is_obay'] = 1;
      }
      this.downloadDocument(params);
      this.closeItemForm();
    },
    /**
     * データ取得
     */
    async getItems() {
      Store.dispatch(`${STORE}/get`);
    },
    convertType(type) {
      let textResult =
        type == TEXT_LABEL.DAILY.id
          ? TEXT_LABEL.DAILY.text
          : TEXT_LABEL.MONTHLY.text;
      return textResult;
    },

    async downloadDocument(params) {
      const configParams = {
        url: URL_HEADER + this.url,
        params: params,
      }
      await Store.dispatch("File/download", configParams);
    },
  },
};
</script>
