<template>
  <div>
    <v-app-bar class="portalHeader" dense>
      <!-- 自社ポータルグローバルヘッダー -->
      <!-- タイトル -->
      <div
        class="portalHeader-title font-weight-bold primary--text"
        mx-3
        @click="close"
      >
        <span class="cursor-title" @click="goToHomePage">{{ title }}</span>
      </div>

      <!-- 現場選択ダイアログ -->
      <v-btn
        dense
        filled
        depressed
        color="grey lighten-2"
        class="site_select_btn"
        @click="openSearchDialog"
      >
        <!-- 現場名 -->
        <div class="sites_name_wrapper">
          {{ CurrentSite ? CurrentSite.field_name : "-" }}
        </div>
        <v-spacer></v-spacer>
        <v-icon dark right> mdi-menu-down </v-icon>
      </v-btn>

      <!-- 日付 -->
      <span class="portalHeader-date primary--text mx-4" @click="close">
        {{ getFormattedDate() }}
      </span>

      <v-spacer></v-spacer>

      <!-- 自社ポータル タブメニュー -->
      <v-tabs portalHeader-tabmenu right v-model="active_tab">
        <v-tab
          v-for="val in headerMenuItems"
          v-show="val.visibility"
          :key="val.id"
          @change="(menu = val), onChange(val.id)"
          >{{ val.name }}</v-tab
        >
      </v-tabs>

      <!-- 通知 -->
      <v-btn icon @click="openNotification">
        <v-icon v-if="isUpdated" class="buruburu">mdi-bell</v-icon>
        <v-icon v-if="!isUpdated">mdi-bell-outline</v-icon>
      </v-btn>

      <!-- drawer -->
      <v-btn icon @click="openDrawer" v-if="!IS_OBAYASHI">
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
      </v-btn>
      <!-- drawer -->
      <v-btn icon @click="openDrawer" v-if="IS_OBAYASHI">
        <v-app-bar-nav-icon>
          <v-icon color="primary">mdi-account-circle-outline</v-icon>
        </v-app-bar-nav-icon>
      </v-btn>

    </v-app-bar>
    <Popup width="480px" :dialog="isShowSearchDialog">
      <FieldsSelectDialog
        title="現場選択"
        :formValues="formValues"
        @formUpdate="formUpdate"
        @onSearch="searchSite"
        @close="isShowSearchDialog = false"
      />
    </Popup>
  </div>
</template>

<script>
import { Store } from "@/store/Store.js";
import { getDateJp } from "@/utils/timeUtil"; //日付フォーマット変換で使用
import {
  HEADER_MENU_ITEMS_INHOUSE,
  HEADER_MENU_ITEMS_INHOUSE_WITHOUT_OFFICE,
  PORTAL_LIST,
} from "@/constants/GLOBALHEADER";
import { getEnvConfigValue, ENV_CONFIG_LABELS } from "@/constants/ENV_CLIENT";
import FieldsSelectDialog from "./components/FieldsSelectDialog";
import Popup from "@/components/common/Popup.vue";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";

// ヘッダーメニューの表示非表示の制御
const HEADR_MENU = () => {
  const menuConfig = getEnvConfigValue(
    ENV_CONFIG_LABELS.HEADER_MENU_ITEMS_INHOUSE
  );
  return Object.keys(HEADER_MENU_ITEMS_INHOUSE).map((key) => {
    return { ...HEADER_MENU_ITEMS_INHOUSE[key], ...menuConfig[key] };
  });
};

// ヘッダーメニューの表示非表示の制御
const HEADR_MENU_WITHOUT_OFFICE = () => {
  const menuConfigWithoutOffice = getEnvConfigValue(
    ENV_CONFIG_LABELS.HEADER_MENU_ITEMS_INHOUSE_WITHOUT_OFFICE
  );
  return Object.keys(HEADER_MENU_ITEMS_INHOUSE_WITHOUT_OFFICE).map((key) => {
    return {
      ...HEADER_MENU_ITEMS_INHOUSE_WITHOUT_OFFICE[key],
      ...menuConfigWithoutOffice[key],
    };
  });
};

export default {
  data() {
    return {
      title: "GREEN",

      // タブメニュー表示項目
      headerMenuItems: [],

      // 選択中のタブメニューid
      active_tab: 0,

      // 検索データ
      formValues: {
        // 検索items
        items: [],
        // 検索ワード
        keyword: "",
        // 選択項目id
        selected: null,
      },

      // 検索ダイアログ表示
      isShowSearchDialog: false,

      // 更新があるか
      isUpdated: false,
    };
  },

  components: {
    FieldsSelectDialog,
    Popup,
  },

  props: {
    companyId: Number,
  },

  async mounted() {
    // do not show 支店 if user is belong with a Office

    const companyUser = JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login
      .company_user;
    if (companyUser?.company_branch_id) {
      this.headerMenuItems = HEADR_MENU_WITHOUT_OFFICE();
    } else {
      this.headerMenuItems = HEADR_MENU();
    }
    // console.log("==", this.headerMenuItems);

    /**
     * 更新のチェック
     */
    this.$watch(
      () => Store.getters[`Notification/getNewNoticeStatus`],
      (value) => {
        if (value != null) {
          this.isUpdated = value === 1;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    /**
     * 選択しているメニューを取得
     */
    this.$watch(
      () => Store.getters[`GlobalHeader/getInHouseMenuId`],
      (menuId) => {
        this.active_tab = menuId;
      },
      {
        immediate: true,
      }
    );

    /**
     * 現場一覧の取得
     */
    this.$watch(
      () => Store.getters[`GlobalHeader/getSiteList`],
      (data) => {
        const siteList = {
          ...data,
        };
        this.formValues.items = siteList;
      },
      {
        immidiate: true,
        deep: true,
      }
    );
  },

  computed: {
    /**
     * 現在の現場を取得
     */
    CurrentSite() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    IS_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
  },

  methods: {
    /**
     * タブメニューの変更
     */
    onChange(menuId) {
      this.close();
      const selectedMenu = HEADR_MENU().find((item) => {
        return item.id === menuId;
      });
      this.$router.push(selectedMenu.path);
    },

    // ドロワー
    openDrawer() {
      this.close();
      Store.dispatch("Drawer/show", {
        type: PORTAL_LIST.INHOUSE_PORTAL.id,
      });
    },

    // 通知
    async openNotification() {
      this.close();
      const result = await Store.dispatch("Notification/show");
      if (result.hasError) {
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.message,
        });
        return;
      }
    },

    /**
     * 現場選択ダイアログを開く
     */
    async openSearchDialog() {
      this.close();
      const result = await Store.dispatch("GlobalHeader/searchSites", {
        keyword: "",
      });
      const { entries } = result.data.contents;
      if (result.hasError || entries.length === 0) {
        Store.dispatch("Error/show", {
          status: 200,
          message: "現場情報がありません",
        });
        return;
      }
      this.isShowSearchDialog = true;
    },

    /**
     * 現場検索
     */
    async searchSite() {
      const { keyword } = this.formValues;
      const result = await Store.dispatch("GlobalHeader/searchSites", {
        keyword,
      });
      if (result.hasError) {
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.message,
        });
      }
    },

    goToHomePage() {
      Store.dispatch("GlobalHeader/setSite", {
        field_id: null,
      });
      this.$router.push("/portal");
    },

    // フォームの変更を受け取る
    formUpdate(values) {
      this.formValues = { ...values };

      /**
       * 現場選択
       */
      if (this.formValues.selected) {
        // 現場idが選択されたらset
        Store.dispatch("GlobalHeader/setSite", {
          field_id: this.formValues.selected,
        });

        // ダイアログclose
        this.isShowSearchDialog = false;

        //リセット
        const formValues = {
          items: [],
          keyword: "",
          selected: null,
        };
        this.formValues = formValues;

        // 現場ポータルに遷移
        this.$router.push("/portal/dashboard");
      }
    },

    /**
     * 日付変換
     * @param date:yyyy/mm/dd（day）
     */
    getFormattedDate() {
      return getDateJp(new Date()).date;
    },

    close() {
      Store.dispatch("Drawer/close");
      Store.dispatch("Notification/close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
@import "@/assets/scss/responsive.scss";

.portalHeader {
  .portalHeader-title {
    font-size: 16px;
    width: 128px;
    margin-right: 8px;
  }
  .portalHeader-siteSelect {
    width: 224px;
  }
  .portalHeader-date {
    width: 240px;
    white-space: nowrap;
  }
}

.v-badge__badge {
  font-size: 9px !important;
}
.theme--light.v-badge .v-badge__badge:after {
  border-color: none !important;
}

.site_select_btn {
  width: 220px;
  min-width: 180px !important;
  overflow: hidden;
  @media (max-width: $sp) {
    width: 140px;
    min-width: 140px !important;
  }
}
.site_select_btn.v-btn.v-size--default {
  padding: 4px !important;
}
.sites_name_wrapper {
  width: auto;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  text-indent: 4px;
  text-transform: capitalize;
  font-size: 12px;
  letter-spacing: 0;
}

.cursor-title {
  cursor: pointer;
}

.buruburu {
  display: inline-block;
  animation: hurueru 1s infinite;
  transition-timing-function: ease-in-out;
  &::before {
    color: $color_util_1;
  }
}

@keyframes hurueru {
  0% {
    transform: rotateZ(10deg);
  }
  50% {
    transform: rotateZ(-10deg);
  }
  100% {
    transform: rotateZ(10deg);
  }
}
</style>
