<template>
  <div ref="tooltip" v-if="isActive" class="tooltip" :style="getStyle()">
    {{ text }}
  </div>
</template>

<script>
import { Store } from "@/store/Store.js";

export default {
  data() {
    return {
      isActive: false,
      status: null,
      text: "",
      wrapperRect: null,
      center: null,
    };
  },
  computed: {},
  mounted() {
    this.$watch(
      () => Store.getters["Tooltip/getTooltip"],
      (data) => {
        const { message, isActive, rect } = data;

        this.isActive = isActive;
        this.text = message;
        this.$nextTick(() => {
          this.wrapperRect = rect;
        });
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    getStyle() {
      // ラッパーのrect
      if (
        this.wrapperRect &&
        "x" in this.wrapperRect &&
        "y" in this.wrapperRect &&
        "width" in this.wrapperRect &&
        "height" in this.wrapperRect
      ) {
        const { x, y, width, height } = this.wrapperRect;

        // ツールチップのサイズ
        const tipRect = this.$refs.tooltip.getBoundingClientRect();

        // windowサイズ
        const wh = window.innerHeight;
        const ww = window.innerWidth;

        // ツールチップの座標
        const center = {
          x: x + width / 2 - tipRect.width / 2,
          y: y,
        };

        // 下判定
        const isBottom = center.y >= wh * 0.2;
        let _style = "";
        if (isBottom) {
          _style = `top:${center.y - tipRect.height}px;bottom:auto;`;
          console.log("bottom", center.y, wh / 2);
        } else {
          _style = `top:${center.y + height}px;bottom:auto;`;
          console.log("top", center.y, wh / 2);
        }

        //左が見切れる場合
        const outLeft = center.x - tipRect.width / 2 <= 0;
        const outRight = center.x + tipRect.width / 2 >= ww;
        if (outLeft) {
          _style = `${_style}left:${32}px;right:auto;`;
          console.log("left");
        } else if (outRight) {
          _style = `${_style}right:${32}px;left:auto;`;
          console.log("right");
        } else {
          _style = `${_style}left:${center.x}px;`;
          console.log("center");
        }

        console.log("_style:", _style);

        return _style;
      }
      return "";
    },
  },
};
</script>
<style lang="scss" scoped>
.tooltip {
  position: fixed;
  display: inline-block;
  top: 8px;
  left: 8px;
  width: auto;
  max-width: 256px;
  height: auto;
  background-color: black;
  color: white;
  padding: 8px;
  border-radius: 4px;
  z-index: 999;
  pointer-events: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
</style>
