<template>
  <!-- parent table -->
  <v-data-table
      :headers="tableLabels"
      :items="list"
      :items-per-page="itemsPerPage"
      :height="tableHeight"
      fixed-header
      hide-default-footer
      disable-sort
      :show-select="true"
      class="elevation-0 v-data-table__wrapper"
      sort-by="updatedAt"
  >
    <!-- parent table template -->
    <template v-slot:body="{ items }">
      <template v-if="list && list.length > 0">
        <tbody v-for="item in list" :key="item.safety_diaries_id">
        <tr>
          <td class="start">
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <!-- toggle icon -->
                <v-icon @click.stop="toggleShowChildren(item.safety_diaries_id)"
                        v-if="item.children.length > 0 && item.safety_form == 2">
                  {{
                    isShowChildren[item.safety_diaries_id]
                        ? "mdi-chevron-down"
                        : "mdi-chevron-right"
                  }}
                </v-icon>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <!-- checkbox -->
                <v-simple-checkbox
                    v-if="ROLE_DELETE_APPROVE_CHECKBOX(item)"
                    :value="isSelectedParent(item.safety_diaries_id)"
                    @input="updateCheckbox(item.status,item, $event, true)"
                ></v-simple-checkbox>
              </v-col>
            </v-row>
          </td>
          <td class="col-status" @click="$emit('openItemForm', item)">
            <v-chip
                v-if="item.status"
                :color="getStatusCode(item.status).color"
                small
                dark>{{ getStatusCode(item.status).status_code || "" }}
            </v-chip>
          </td>
          <td @click="$emit('openItemForm', item)">
            {{ item.patrol_record_date }}
          </td>
          <td @click="$emit('openItemForm', item)">
            <div>
              {{ item.detect_user.substring(0, item.detect_user.indexOf('(')) }}
            </div>
            <div>
              {{ item.detect_user.substring(item.detect_user.indexOf('(')) }}
            </div>
          </td>
          <td @click="$emit('openItemForm', item)">
            <v-chip
                v-if="item.urgency || item.urgency == 0"
                class="ma-2"
                small
                text-color="white"
                :color="getUrgency(item.urgency).color"
            >
              {{ getUrgency(item.urgency).name || "" }}
            </v-chip>
          </td>
          <td @click="$emit('openItemForm', item)">
            <div class="data-long">
              <TooltipWrapper inline :text="item.patrol_record_name">
                {{ item.patrol_record_name }}
              </TooltipWrapper>
            </div>
          </td>
          <td @click="$emit('openItemForm', item)">
            <span v-if="item.safety_form !== 2">
              {{ item.field_items || "" }}
            </span>
          </td>
          <td @click="$emit('openItemForm', item)">
            <span v-if="item.safety_form !== 2">
              {{ item.recovery_user || "" }}
            </span>
          </td>
          <td @click="$emit('openItemForm', item)">
            <span v-if="item.safety_form !== 2">
              {{ item.recovery_date || "" }}
            </span>
          </td>
          <td @click="$emit('openItemForm', item)">
            <div class="data-long" v-if="item.safety_form !== 2">
              <TooltipWrapper inline :text="item.recovery_content">
                {{ item.recovery_content || "" }}
              </TooltipWrapper>
            </div>
          </td>
          <td @click="$emit('openItemForm', item)">
            <v-icon v-if="item.image_flag === 1 && item.safety_form !== 2" style="color: black">mdi-paperclip</v-icon>
          </td>
        </tr>
        <!-- child table template -->
        <template v-for="itemChild in item.children" v-if="item.children.length > 0 && item.safety_form == 2">
          <tr
              v-show="isShowChildren[item.safety_diaries_id]"
              class="child-table"
              :key="itemChild.safety_diary_comment_correction_id"
          >
            <td class="start">
              <v-row>
                <v-col cols="12" sm="6" md="6"></v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-simple-checkbox
                      v-if="ROLE_DELETE_APPROVE_CHECKBOX(item)"
                      :value="isSelected(itemChild.safety_diary_comment_correction_id)"
                      @input="updateCheckbox(itemChild.status,itemChild, $event, null, item)"
                  ></v-simple-checkbox>
                </v-col>
              </v-row>
            </td>
            <td class="col-status" @click="$emit('openItemForm', { ...item, itemChild: itemChild })">
              <v-chip
                  v-if="itemChild.status"
                  :color="getStatusCode(itemChild.status).color"
                  small
                  dark>{{ getStatusCode(itemChild.status).status_code || "" }}
              </v-chip>
            </td>
            <td @click="$emit('openItemForm', { ...item, itemChild: itemChild })">
            </td>
            <td @click="$emit('openItemForm', { ...item, itemChild: itemChild })">
            </td>
            <td @click="$emit('openItemForm', { ...item, itemChild: itemChild })"></td>
            <td @click="$emit('openItemForm', { ...item, itemChild: itemChild })">
              <div class="data-long">
                <TooltipWrapper inline :text="itemChild.pointing_out_detail">
                  {{ itemChild.pointing_out_detail }}
                </TooltipWrapper>
              </div>
            </td>
            <td @click="$emit('openItemForm', { ...item, itemChild: itemChild })">
              {{ itemChild.field_items }}
            </td>
            <td @click="$emit('openItemForm', { ...item, itemChild: itemChild })">
              {{ itemChild.recovery_user }}
            </td>
            <td @click="$emit('openItemForm', { ...item, itemChild: itemChild })">
              {{ itemChild.recovery_date }}
            </td>
            <td @click="$emit('openItemForm', { ...item, itemChild: itemChild })">
              <div class="data-long">
                <TooltipWrapper inline :text="itemChild.recovery_content">
                  {{ itemChild.recovery_content }}
                </TooltipWrapper>
              </div>
            </td>
            <td @click="$emit('openItemForm', { ...item, itemChild: itemChild })">
              <v-icon v-if="itemChild.image_flag === 1" style="color: black">mdi-paperclip</v-icon>
            </td>
          </tr>
        </template>
        <tr
            v-show="
              isShowChildren[item.safety_diaries_id] &&
              (childTableDisplayMoreNumber(item.total_children_item,item.children.length) > 0 )
            "
        >
          <td colspan="11" style="border-bottom: 0">
            <div class="d-flex justify-center">
              <v-btn
                  x-small
                  depressed
                  :disabled="isDisable"
                  class="mr-4 display-more"
                  color="#E5E5E5"
                  v-if="childTableDisplayMoreNumber(item.total_children_item,item.children.length) > 0"
                  @click="getChildren(item)"
              >さらに表示する
                <v-badge
                    class="display-more-number"
                    inline
                    color="#767676"
                    :content="
                      childTableDisplayMoreNumber(
                        item.total_children_item,
                        item.children.length
                      )
                    "
                />
              </v-btn>
            </div>
          </td>
        </tr>
        </tbody>
        <tbody>
        <tr class="disable-hover">
          <td colspan="12">
            <infinite-loading @infinite="infiniteHandler" spinner="waveDots">
              <div slot="no-more"></div>
              <div slot="no-results"></div>
            </infinite-loading>
          </td>
        </tr>
        </tbody>
      </template>
      <div v-else-if="flagNotData" class="schedule_nodata mt-4">
        <span>{{ NO_DATA_MESSAGE }}</span>
      </div>
    </template>
  </v-data-table>
</template>
<script>
import {URGENCY_CLASSIFICATION_SELECT, STATUS_OBJECT} from "@/constants/PATROL_RECORD";
import TooltipWrapper from "@/components/tooltip/TooltipWrapper.vue";
import {Store} from "@/store/Store";
import InfiniteLoading from "vue-infinite-loading";
import _ from "lodash";

export default {
  data() {
    return {
      isDisable: false,
      time_out: null,
      page: 1,
      list: [],
      total_page: 1,
      isShowChildren: {},
      isOpenAllChildren: {},
      IDRecordUpdate: [],
      IDDelete: [],
    };
  },
  props: {
    tableLabels: Array,
    itemsPerPage: Number,
    tableHeight: Number,
    isTableTypeAll: Boolean,
    items: Array,
    isSelected: Function,
    isSelectedParent: Function,
    updateCheckbox: Function,
    // toggleShowChildren: Function,
    childTableDisplayMoreNumber: Function,
    // isShowChildren: Object,
    NO_DATA_MESSAGE: String,
    flagNotData: Boolean,
    paramsGetList: Object,
    itemSelectedScroll: Array,
    listDeleteScroll: Array,
  },
  components: {
    TooltipWrapper,
    InfiniteLoading
  },
  computed: {
    STATUS_OBJECT() {
      return Object.keys(STATUS_OBJECT).map((key) => {
        return STATUS_OBJECT[key];
      });
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    params() {
      const result = {
        ...this.paramsGetList,
      };
      if (result) {
        result.page_number = this.page;
        result.record_size = 50;
      }
      return {
        ...result,
      };
    },
    checkLoadData() {
      return {
        getFetch: Store.getters["PatrolRecord/getFetch"],
        itemSelectedScroll: [...this.itemSelectedScroll],
        listDeleteScroll: [...this.listDeleteScroll],
      };
    },
  },
  mounted() {
    this.getUserRole();
    this.$watch(
        () => this.checkLoadData,
        (newValue) => {
          if (!newValue) {
            return
          }
          if (newValue.itemSelectedScroll && newValue.itemSelectedScroll.length) {
            this.IDRecordUpdate = _.cloneDeep(newValue.itemSelectedScroll);
          }

          if (newValue.listDeleteScroll && newValue.listDeleteScroll.length) {
            this.IDDelete = _.cloneDeep(newValue.listDeleteScroll);
          }

          if (newValue.getFetch == "view") {
            this.infiniteHandler();
          }
          if (newValue.getFetch == "update") {
            this.loadRecordLastUpdate();
          }

          if (newValue.getFetch == "onSearch") {
            this.page = 1;
            this.list = [];
            this.infiniteHandler();
          }
          if (newValue.getFetch == "updateApprove") {
            this.params.patrol_record_name = null
            this.params.field_item_tree_id = null
            this.params.type = null
            this.params.urgency = null
            this.params.detect_user = null
            this.params.recovery_user = null
            this.params.status_codes = []
            this.loadRecordLastUpdate();
          }
          if (newValue.getFetch == "remove") {
            // Delete record
            if (
                this.IDDelete &&
                this.IDDelete.length
            ) {
              this.deleteRecord(this.IDDelete);
            }
            // Update record
            this.IDRecordUpdate = newValue.itemSelectedScroll.map((item) => {
              return item.delete ? item.update : item;
            });
            this.loadRecordLastUpdate();
          }
        },
        {
          immediate: true,
          deep: true,
        }
    );
    this.$watch(
        () => this.list,
        (value) => {
          this.getUserRole()
          this.$emit("updateItems", value);
        },
        {
          immediate: true,
          deep: true,
        }
    );
    this.$watch(
        () => this.isShowChildren,
        (newValue, oldValue) => {
          if (newValue == oldValue) this.$forceUpdate();
        },
        {
          immediate: true,
          deep: true,
        }
    );
  },
  methods: {
    async getUserRole() {
      await Store.dispatch(`Report/getUserRole`, this.CURRENT_SITE.field_id);
    },
    getChildren(item) {
      this.IDRecordUpdate = [item.safety_diaries_id];

      this.isOpenAllChildren[item.safety_diaries_id] = true;
      this.isDisable = true;
      // Re-enable after submit
      this.time_out = setTimeout(() => {
        this.isDisable = false;
      }, 1000);
      this.getChildItems(item.safety_diaries_id, item.status_code_flag_2,
          Math.floor((item.children.length - 3) / 3) + 2)
    },
    async getChildItems(safety_diaries_id, flag, pageNumber) {
      let _apiParams = _.cloneDeep(this.paramsGetList);
      _apiParams["safety_diary_id"] = safety_diaries_id;
      _apiParams["page_number"] = pageNumber;
      if (flag) {
        _apiParams["status_code_flag_2"] = true;
      }
      let rs = await Store.dispatch(`PatrolRecord/loadChildrenScroll`, {params: _apiParams});
      if (rs.data.status_code === 200) {
        let child = _.cloneDeep(rs.data.contents.entries);
        const index = this.list.findIndex((ele) => {
          return safety_diaries_id == ele.safety_diaries_id;
        });
        child.forEach(e => {
          if (e.status_code == 2) {
            e.status = STATUS_OBJECT.EX7.status
          } else if (e.status_code == 1) {
            e.status = STATUS_OBJECT.EX4.status
          } else if (e.status_code == 0 && e.recovery_flag == 1) {
            e.status = STATUS_OBJECT.EX3.status;
          } else {
            e.status = STATUS_OBJECT.EX1.status;
          }
        });
        if (index !== -1) {
          this.list[index].children = [
            ...this.list[index].children,
            ...child,
          ];
        }
      }
    },
    getUrgency(type) {
      return URGENCY_CLASSIFICATION_SELECT.find(e => e.value === type);
    },
    getStatusCode(status) {
      return this.STATUS_OBJECT.find(e => e.status == status);
    },
    ROLE_DELETE_APPROVE_CHECKBOX(parentItem) {
      let userRole = Store.getters["Report/getUserRole"];
      let userLogin = Store.getters["Login/getUser"];
      let companyUser = JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user?.company_id;

      let fieldTreeCompanyId = parentItem.field_tree_company_id;
      //連絡ITEM
      if (parentItem.safety_form == 3) {
        if (Object.values(userRole?.nest_no).indexOf(0) > -1) return true;
        return (userLogin.id === parentItem.detect_user_id);
      }
      //指摘ITEM
      else {
        if (userRole?.role === 1) return true;
        return (fieldTreeCompanyId === companyUser);
      }
    },
    async infiniteHandler($state) {
      if (this.page > this.total_page) {
        if ($state) $state.complete();
        return;
      }
      if (this.params) {
        this.params.is_oba = 1;
        const res = await Store.dispatch(
            `PatrolRecord/get`,
            {params: this.params}
        );
        if (res.data.status_code === 200) {
          this.page += 1;
          this.total_page = res.data.contents.pagination.total
              ? res.data.contents.pagination.total
              : 1;
          this.initIsShowChildren(res.data.contents.entries);
          let data = [];
          data = this.addStatusToItems(res.data.contents.entries);
          this.list.push(...data);
          if ($state) $state.loaded();
        } else {
          if ($state) $state.complete();
        }
        Store.commit("PatrolRecord/SET_FETCH", null);
      }
    },
    initIsShowChildren(items) {
      let _isShowChildren = {};
      items.forEach((item) => {
        _isShowChildren[item.safety_diaries_id] = true;
      });
      this.isShowChildren = {
        ...this.isShowChildren,
        ..._isShowChildren,
      };
    },
    toggleShowChildren(id) {
      this.isShowChildren[id] = !this.isShowChildren[id];
    },

    addStatusToItems(arr) {
      let _arr = this.changeListBySafeForm(_.cloneDeep(arr));
      _arr.forEach(itemParent => {
        itemParent["status"] = this.getStatusParentItem(itemParent, itemParent.children || []);
        itemParent.children.forEach(itemChild => {
          if (itemParent.safety_form == 3) {
            itemChild["status"] = itemParent["status"];
          } else {
            if (itemChild.status_code == 2) {
              itemChild["status"] = STATUS_OBJECT.EX7.status;
            } else if (itemChild.status_code == 1) {
              itemChild["status"] = STATUS_OBJECT.EX4.status;
            } else if (itemChild.status_code == 0 && itemChild.recovery_flag == 1) {
              itemChild["status"] = STATUS_OBJECT.EX3.status;
            } else if (itemChild.status_code == 0 && itemChild.recovery_flag == 0) {
              itemChild["status"] = STATUS_OBJECT.EX1.status;
            }
          }
        });
      });
      return _arr;
    },

    changeListBySafeForm(arr) {
      let rs = [];
      arr.forEach(e => {
        if (e.safety_form !== 2) {
          let children = e.children[0];
          rs.push({
            recovery_flag: children.recovery_flag,
            status_code: children.status_code,
            patrol_record_date: e.patrol_record_date,
            detect_user: e.detect_user,
            patrol_record_name: children.pointing_out_detail,
            type: e.type,
            urgency: e.urgency,
            total_children_item: 0,
            field_items: children.field_items,
            recovery_user: children.recovery_user,
            recovery_date: children.recovery_date,
            recovery_content: children.recovery_content,
            image_flag: children.image_flag,
            safety_form: e.safety_form,
            safety_diaries_id: e.safety_diaries_id,
            children: e.children,
            field_tree_company_id: e.field_tree_company_id,
            detect_user_id: e.detect_user_id
          });
        } else {
          rs.push(e);
        }
      });
      return rs;
    },

    getStatusParentItem(parent, childArr) {
      if (parent.safety_form == 3) {
        return parent.type == 2 ? STATUS_OBJECT.EX5.status : STATUS_OBJECT.EX6.status;
      } else {
        if (parent.status_code_flag_2) {
          return STATUS_OBJECT.EX2.status;
        } else {
          if (parent.status_code_flag_3) {
            return STATUS_OBJECT.EX3.status;
          } else {
            if (childArr.length > 0) {
              let lengthChildUnRecovery = childArr.filter(e => e.recovery_flag == 0).length;
              if (lengthChildUnRecovery == childArr.length) {
                return STATUS_OBJECT.EX1.status;
              } else if (lengthChildUnRecovery > 0 && lengthChildUnRecovery < childArr.length) {
                return STATUS_OBJECT.EX2.status;
              } else {
                if (childArr.filter(e => e.status_code == 2 && e.recovery_flag == 1).length == childArr.length) {
                  return STATUS_OBJECT.EX7.status;
                } else {
                  return childArr.filter(e => e.status_code == 1).length == childArr.length ? STATUS_OBJECT.EX4.status : STATUS_OBJECT.EX3.status;
                }
              }
            } else {
              if (parent.status_code == 2) {
                return STATUS_OBJECT.EX7.status;
              } else if (parent.status_code == 1) {
                return STATUS_OBJECT.EX4.status;
              } else if (parent.status_code == 0 && parent.recovery_flag == 1) {
                return STATUS_OBJECT.EX3.status;
              } else if (parent.status_code == 0 && parent.recovery_flag == 0) {
                return STATUS_OBJECT.EX1.status;
              }
            }
          }
        }
      }
    },
    async loadRecordLastUpdate() {
      if (
          this.params &&
          this.IDRecordUpdate &&
          this.IDRecordUpdate.length
      ) {
        const paramsUpdate = _.cloneDeep(this.params);
        paramsUpdate.page_number = 1;
        paramsUpdate.ids = this.IDRecordUpdate;
        paramsUpdate.is_oba = 1;

        const res = await Store.dispatch(
            `PatrolRecord/get`,
            {params: paramsUpdate}
        );
        if (res.data.status_code === 200) {
          this.updateRecord(res.data.contents.entries);

          this.$forceUpdate();
        }
      }
      this.time_out = setTimeout(() => {
        Store.commit("PatrolRecord/SET_FETCH", null);
      }, 1000);
    },
    updateRecord(data) {
      data?.forEach((recordUpdate) => {
        const indexCheckList = this.list.findIndex((item) => {
          return item.safety_diaries_id === recordUpdate.safety_diaries_id;
        });
        // data = this.addStatusToItems(res.data.contents.entries);
        let data = this.addStatusToItems([recordUpdate]);
        if (indexCheckList != -1) {
          this.list.splice(indexCheckList, 1, data[0]);
        }
        this.isShowChildren[recordUpdate.safety_diaries_id] = true;

        if (this.isOpenAllChildren[recordUpdate.safety_diaries_id]) {
          this.getChildren(recordUpdate);
        }
      });

      this.IDRecordUpdate = [];
    },

    deleteRecord(data) {
      data?.forEach((IDDelete) => {
        const indexCheckList = this.list.findIndex((item) => {
          return String(item.safety_diaries_id) == String(IDDelete);
        });

        if (indexCheckList != -1) {
          this.list.splice(indexCheckList, 1);
        }
        this.$emit("updateItems", this.list);
      });

      this.IDDelete = [];
      this.$forceUpdate();
    },
  },

  /**
   * Important: clear timeout
   */
  beforeDestroy() {
    // clear the timeout before the component is destroyed
    clearTimeout(this.time_out);
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
// ヘッダーとテーブルコンテンツのたて位置を合わせるために
// show-selectオプションをONにした状態でcheckboxを非表示
::v-deep .v-data-table-header {
  .v-input--selection-controls__input {
    display: none !important;
  }
}

td.start div.row {
  width: 75px !important;
}

td.col-status {
  width: 110px !important;
}

.child-table {
  td.start {
    padding-right: 0;
  }
}

.schedule_nodata {
  position: absolute;
  left: 50%;
}

button.add-new-child,
button.display-more {
  height: 20px !important;
}

.display-more-number ::v-deep .v-badge__badge {
  font-size: 10px;
}

.display-more-number ::v-deep .v-badge__badge {
  padding: 1px;
  height: 14px;
}

div.circle-red {
  background-color: $color_warning_dark_red;
}

div.circle-blue {
  background-color: $color_warning_blue;
}

div.warning-icon {
  display: inline-flex;
  justify-content: center;
  border-radius: 50%;
  font-size: 10px;
  color: white;
  width: 25px;
  height: 25px;
  padding-top: 4px;
  margin: 3px;
}

.data-long {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-height: 1.2em; /* fallback */
  max-height: 4.5em;
  align-self: center;
  white-space: pre-line;
  word-break: break-all;
}

.disable-hover:hover {
  background-color: transparent !important;
}
</style>
