<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle" style="overflow-y: auto;">
      <v-container>
        <div class="pa-10">
          <div v-if="item.machine_company_field_inspection_result_images.length > 0">
            <div
              v-for="(image, index) in item.machine_company_field_inspection_result_images" :key="image.id">
              <v-img
                contain
                :src="(image.image_url ? sourceImages[index]: '/images/noimage.png')"
                max-width="30vw"
                max-height="20vw"
                class="mb-5"
              />
            </div>
          </div>
          <div v-else-if="isNoDataMessage" class="no-data">{{NO_DATA_MESSAGE}}</div>
        </div>
      </v-container>
    </div>
  </div>
</template>
<script>
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import { getParamsOfUrlReadFile } from "@/utils/viewSourceAuth";
import { Store } from "@/store/Store";

export default {
  data: () => {
    return {
      NO_DATA_MESSAGE,
      sourceImages: [],
    };
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        if (Array.isArray(newValue.machine_company_field_inspection_result_images)) {
          this.initiateImageSource();
        }
      },
      {
        immediate : true,
        deep: true,
      }
    );
  },
  props: {
    item: Object,
    mainHeight: {
      type: Number,
      default: 0,
    },
    isNoDataMessage: Boolean,
  },
  methods:{
    async initiateImageSource() {
      this.sourceImages = [];
      for (const item of this.item.machine_company_field_inspection_result_images) {
        if (item.image_url) {
          await this.createObjectUrlForImage(item);
        }
      }
    },

    async createObjectUrlForImage(item) {
      this.sourceImages.push(await this.fetchImageSource(item.image_url));
    },

    async fetchImageSource(sourceUrl) {
      let image = "";
      if (sourceUrl) {
        const params = getParamsOfUrlReadFile(sourceUrl);
        const response = await Store.dispatch("File/readFile", params);
        if(!response.hasError) {
          image = window.URL.createObjectURL(new Blob([response.data], { type: response.headers["content-type"]}));
        }
      }
      return image;
    },
  }
};
</script>
<style lang="scss" scoped>
.no-data {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
</style>
