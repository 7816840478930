import Vue from "vue";
import Vuex from "vuex";
import { registerFace } from "@/api/modules/registerFace";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = registerFace;

export const RegisterFace = {
  namespaced: true,

  state: {
    data: [],
    pagination: {},
    userResImages: {},
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_CHILD_DATA(state, payload) {
      let parent = [];
      let children = [];
      let data = [];
      parent = state.data.find(
        (item) => item.field_tree_id === payload.field_tree_id
      );
      children = [...parent.children, ...payload.entries];
      data = state.data.map((item) => {
        if (item.field_tree_id === payload.field_tree_id) {
          const _item = { ...item };
          _item.children = children;
          return _item;
        }
        return item;
      });
      state.data = data;
    },
    SET_DETAIL(state, payload) {
      state.userResImages = payload;
    },
  },

  actions: {
    //機材予定一覧
    async getListUserResImages({ commit }, payload) {
      const response = await ENTITY.getListUserResImages(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;
      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },

    async loadChildren({ commit }, payload) {
      const response = await ENTITY.loadChildren(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_CHILD_DATA", {
        entries,
        field_tree_id: payload.params.field_tree_id,
      });
      return response;
    },
    async update(_context, payload) {
      return await ENTITY.update(payload);
    },
    async updateActivities(_context, payload) {
      return await ENTITY.updateActivities(payload);
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getDetail: (state) => {
      return state.userResImages;
    },
  },
};
