<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row no-gutters>
          <!-- 大分類 -->
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <Label label="大分類" :editable="editable">
                  <Select
                    name="catergory_id"
                    :values="item"
                    :items="CATEGORY_ARRAY[index]"
                    :editable="editable"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <!-- 免許・資格名 -->
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <Label label="中分類" :editable="editable">
                  <SelectSearch
                    name="sub_catergory_id"
                    :values="item"
                    :items="filterSubCatergory(item)"
                    :editable="editable"
                    title="中分類選択"
                    title_search="中分類名"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <!-- 表彰名 -->
        <v-row no-gutters>
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <Label label="表彰名" :editable="editable">
                  <Select
                    name="cmn_mst_awards_id"
                    :values="item"
                    :items="filterAwardsName(item)"
                    item_text="award_name"
                    :editable="editable"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <!-- 表彰年月日 -->
          <v-col cols="12" sm="6" md="6">
            <v-list-item>
              <v-list-item-content>
                <Label label="表彰年月日" :editable="editable">
                  <InputDatepicker
                    name="award_date"
                    :values="item"
                    :editable="editable"
                    placeholder=""
                    :max="max"
                    validation_label="表彰年月日"
                    :flagNull="true"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <!-- 表彰確認書類 -->
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <Label label="表彰確認書類" :editable="editable">
                  <span class="file"
                    v-if="!editable && item['document_name']"
                    @click="viewSourceWithAuth(item['document_name_url'])"
                  >
                    {{ item["document_name"] }}
                  </span>
                  <FileInput
                    :style="getFileInputStyle(index, 'award_document_name', 'document_name')"
                    :resetValue="resetValue[`${index}_award_document_name`]"
                    v-if="editable"
                    name="document_name"
                    :values="item"
                    :editable="editable"
                    placeholder=""
                    accept="image/jpg, image/png, image/jpeg, application/pdf"
                    v-bind:validation_label="`${index}_award_document_name`"
                    validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf"
                    @onInput="onInput({ value: $event, index })"
                  />
                  <span class="file" v-if="judgeDisplayFileInput(index, 'award_document_name', 'document_name')"
                    >{{ item["document_name"] }}
                    <v-btn
                      small
                      icon
                      text
                      @click="
                        onRemoveFile({
                          index: index,
                          name: 'document_name',
                        })
                      "
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </span>
                </Label>
                <span v-if="editable"
                  >記載内容が鮮明に判読できる画像を添付してください。</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="ml-4">
            <v-btn
              v-if="index > 0 && editable"
              class="mt-7"
              icon
              @click="remove(index)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn v-if="editable" depressed small color="primary" @click="addEmpty"
      >追加</v-btn
    >
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */
import { Store } from "@/store/Store.js";
import Label from "../../elements/Label.vue";
import Select from "../../elements/Select.vue";
import InputDatepicker from "../../elements/InputDatepicker.vue";
import FileInput from "../../elements/FileInput.vue";
import _ from "lodash";
import { viewSourceWithAuth } from "@/utils/viewSourceAuth";
import SelectSearch from "@/components/forms/elements/SelectSearch";

const INITIAL_VALUES = {
  catergory_id: null,
  sub_catergory_id: null,
  cmn_mst_awards_id: null,
  award_date: null,
  document_name: null,
};

export default {
  data: () => {
    return {
      items: [],
      AWARDS_NAMES: Store.state.CmnMst.constants.entries.cmn_mst_awards,
      CATEGORY_ARRAY: [],
      fileValid: {},
      resetValue: {},
      viewSourceWithAuth: viewSourceWithAuth,
      max: new Date().toISOString().slice(0, 10),
      dataMst : [],
      dataCatergory:[],
    };
  },
  components: {
    SelectSearch,
    Label,
    Select,
    InputDatepicker,
    FileInput,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    errors: {
      type: Object,
      default: {},
    },
  },
  mounted() {
    this.initData();
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (this.items?.length == 0) {
          this.addEmpty();
        }
        if (Array.isArray(this.items)) {
          this.getDataArray();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    // check input file error
    this.$watch(
      () => [this.values, this.name, this.errors],
      (data) => {
        const errors = {...data[2]};
        // the validation_label list of the FileInput element
        const awardDocumentName = "award_document_name";
        const fileValid = { ...this.fileValid};
        const _items =  _.cloneDeep(data[0][data[1]]);
        if (errors) {
          let index = 0;
          for(let name in errors) {
            if (name?.includes(awardDocumentName)){ 
              if(errors[`${index}_${awardDocumentName}`].length > 0){
                delete _items[index]['document_name'];
                delete _items[index]['document_name_data'];
                fileValid[name] = false;
              } else {
                fileValid[name] = true;
              }
              index++;
            }
          }
        }
        this.items = _items;
        this.fileValid = fileValid;
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    initData(){
      this.dataMst = [...this.AWARDS_NAMES];
      this.dataCatergory = this.getDataAllCatergory();
    },
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      if(value.name == "catergory_id"){
        if(!value.value) {
          _items[index].sub_catergory_id = null;
        }
        if(_items[index].sub_catergory_id){
          let sub_name = this.dataMst.find(e => e.id == _items[index].sub_catergory_id)?.sub_catergory;
          _items[index].sub_catergory_id = this.dataMst.find(e => e.sub_catergory == sub_name && e.catergory_id == value.value)?.id;
        }
      }
      _items[index].cmn_mst_awards_id = null;
      if(value.name == "sub_catergory_id"){
        if(value.value) {
          let sub_name = this.dataMst.find(e => e.id == value.value)?.sub_catergory;
          let temp = this.dataMst.filter(e => e.sub_catergory == sub_name);
          _items[index].catergory_id = temp.find(e => e.id == value.value)?.catergory_id;
        }
        _items[index].cmn_mst_awards_id = null;
      }
      if (value.fileName) {
        _items[index][`${value.name}_data`] = value.value;
        _items[index][value.name] = value.fileName;
        if (!value.value) {
          delete _items[index][`${value.name}_deleted`];
        }
      } else {
        _items[index][value.name] = value.value;
      }
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.resetValue[`${_index}_award_document_name`] = !this.resetValue[`${_index}_award_document_name`];
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      let _items = [...this.items];
      _items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    onRemoveFile({ index, name }) {
      let _items = [...this.items];
      delete _items[index][name];
      delete _items[index][`${name}_data`];
      if(_items[index][`${name}_url`]) _items[index][`${name}_deleted`] = 1;
      this.items = _items;
      this.resetValue[`${index}_award_document_name`] = !this.resetValue[`${index}_award_document_name`];
      this.$emit("onInput", { name: this.name, value: _items });
    },
    filterSubCatergory(item) {
      let subCatergories = [{ id: null, name: "" }];
      if (item && item.catergory_id) {
        this.groupData(item).forEach(element => {
          if (element.length > 0) {
            subCatergories.push({ id: element[0].id, name: element[0].sub_catergory });
          }
        });
        subCatergories = this.getUniqueData(subCatergories);
        if (item.sub_catergory_id) {
          let sub_name = this.dataMst.find(e => e.id == item.sub_catergory_id)?.sub_catergory;
          subCatergories.forEach(e => {
            if (e.name === sub_name) {
              e.id = item.sub_catergory_id;
            }
          });
        }
      } else {
        this.dataMst.forEach(e => {
          subCatergories.push({ id: e.id, name: e.sub_catergory });
        });
        subCatergories = this.getUniqueData(subCatergories);
      }
      return subCatergories;
    },
    getUniqueData(arr){
      let rs = [];
      let temp = [];
      arr.forEach(e => {
        if(!temp.includes(e.name)){
          rs.push(e);
          temp.push((e.name));
        }
      })
      return rs;
    },
    filterAwardsName(item) {
      let award_array = [];
      if (item && item.catergory_id && item.sub_catergory_id) {
        let sub_catergory = this.dataMst.find(e => e.id == item.sub_catergory_id)?.sub_catergory_id;
        award_array = this.groupData(item)[sub_catergory];
      }
      award_array.unshift({ id: null, award_name: "" });
      return award_array;
    },
    groupData(item) {
      let _selectItems = [...this.AWARDS_NAMES[item.catergory_id]];
      return _selectItems.reduce((results, item) => {
         (results[item.sub_catergory_id] = results[item.sub_catergory_id] || []).push(item);
         return results;
      },[]);
    },
    getDataAllCatergory(){
      let rs = [{ id: null, name: "" }];
      let results = this.AWARDS_NAMES.reduce((results, item) => {
        (results[item.catergory_id] = results[item.catergory_id] || []).push(item);
        return results;
      },[])
      this.AWARDS_NAMES = results;
      results.forEach(element => {
        if(element.length > 0) {
          rs.push({id: element[0].catergory_id, name: element[0].catergory});
        }
      })
      return rs;
    },
    getDataArray() {
      this.CATEGORY_ARRAY = [];
      this.items.forEach((item, index) => {
        if (item.sub_catergory_id) {
          let sub_name = this.dataMst.find(e => e.id == item.sub_catergory_id)?.sub_catergory;
          let temp = this.dataMst.filter(e => e.sub_catergory == sub_name);
          this.CATEGORY_ARRAY[index] = [{ id: null, name: "" }];
          temp.forEach(e => {
            this.CATEGORY_ARRAY[index].push({ id: e.catergory_id, name: e.catergory });
          });
        } else {
          this.CATEGORY_ARRAY[index] = [...this.dataCatergory];
        }
      });
    },
    getFileInputStyle(index, subField, parentField) {
      return (this.fileValid[`${index}_${subField}`] && this.items[index][parentField]) ? 'display:none;' : '';
    },
    judgeDisplayFileInput(index,subField, parentField) {
      return this.editable && this.fileValid[`${index}_${subField}`] && this.items[index][parentField];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
</style>
