<template>
  <ul class="editmenu">
    <li>
      <button class="editmenu_item" @click.stop="align('left')">
        <v-icon>
          mdi-format-align-left
        </v-icon>
      </button>
    </li>
    <li>
      <button class="editmenu_item" @click.stop="align('center')">
        <v-icon>
          mdi-format-align-center
        </v-icon>
      </button>
    </li>
    <li>
      <button class="editmenu_item" @click.stop="align('right')">
        <v-icon>
          mdi-format-align-right
        </v-icon>
      </button>
    </li>
    <li>
      <button class="editmenu_item" @click.stop="size(12)">
        <v-icon x-small>
          mdi-format-text-variant
        </v-icon>
      </button>
    </li>
    <li>
      <button class="editmenu_item" @click.stop="size(16)">
        <v-icon small>
          mdi-format-text-variant
        </v-icon>
      </button>
    </li>
    <li>
      <button class="editmenu_item" @click.stop="size(20)">
        <v-icon dense>
          mdi-format-text-variant
        </v-icon>
      </button>
    </li>
    <li>
      <button class="editmenu_item" @click.stop="color('white')">
        <v-icon>mdi-square-outline</v-icon>
      </button>
    </li>
    <li>
      <button class="editmenu_item" @click.stop="color('black')">
        <v-icon color="black">
          mdi-square
        </v-icon>
      </button>
    </li>
    <li>
      <button class="editmenu_item" @click.stop="color('red')">
        <v-icon color="red">
          mdi-square
        </v-icon>
      </button>
    </li>
    <li>
      <button class="editmenu_item" @click.stop="color('blue')">
        <v-icon color="indigo accent-4">
          mdi-square
        </v-icon>
      </button>
    </li>
    <li>
      <button class="editmenu_item" @click.stop="color('green')">
        <v-icon color="light-green accent-3">
          mdi-square
        </v-icon>
      </button>
    </li>
  </ul>
</template>
<script>
import {
  TEXT_ALIGN,
  TEXT_SIZE,
  TEXT_COLOR,
} from "@/views/oekaki/components/constants/SPRITE_TEXT.js";
export default {
  data: () => {
    return {
      TEXT_ALIGN,
      TEXT_SIZE,
      TEXT_COLOR,
    };
  },
  methods: {
    align(id) {
      this.$emit("text-align", id);
    },
    size(id) {
      this.$emit("text-size", id);
    },
    color(id) {
      this.$emit("text-color", id);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/views/oekaki/components/assets/scss/editmenu.scss";
</style>
