import Vue from "vue";
import Vuex from "vuex";
import { deviceStocks } from "../../../api/modules/deviceStocks";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = deviceStocks;

export const DeviceStocks = {
  namespaced: true,

  state: {
    data: [],
    pagination: [],
    listMachineOwnerCompanies: [],
    listMachineUseCompanies: [],
    usageData: {},
    numberRecordTab1: 0,
    numberRecordTab2: 0,
    numberRecordTab3: 0,
    numberRecordTab4: 0,
    numberRecordTab5: 0,
    machineField: {},
  },

  mutations: {
    SET_DATA(state, payload) {
      let _data = [...state.data];
      _data = _data.filter(e => e.tab != payload.tab);
      _data.push({
        tab : payload.tab,
        data : payload.data
      })
      state.data = [..._data];
    },
    SET_PAGINATION(state, payload) {
      let _pagination = [...state.pagination];
      _pagination = _pagination.filter(e => e.tab != payload.tab);
      _pagination.push({
        tab : payload.tab,
        pagination : payload.pagination
      })
      state.pagination = [..._pagination];
    },
    SET_LIST_MACHINE_OWNER_COMPANIES(state, payload) {
      state.listMachineOwnerCompanies = payload;
    },
    SET_DATA_USAGE(state, payload) {
      state.usageData = payload;
    },
    SET_MACHINE_USE_COMPANIES(state, payload) {
      state.listMachineUseCompanies = payload;
    },
    SET_TOTAL_RECORD_TAB1(state, payload) {
      state.numberRecordTab1 = payload;
    },
    SET_TOTAL_RECORD_TAB2(state, payload) {
      state.numberRecordTab2 = payload;
    },
    SET_TOTAL_RECORD_TAB3(state, payload) {
      state.numberRecordTab3 = payload;
    },
    SET_TOTAL_RECORD_TAB4(state, payload) {
      state.numberRecordTab4 = payload;
    },
    SET_TOTAL_RECORD_TAB5(state, payload) {
      state.numberRecordTab5 = payload;
    },
    SET_MACHINE_FIELD_DETAIL(state, payload) {
      state.machineField = payload;
    },
  },

  actions: {
    async get({ commit }, payload) {
      const tab = payload.params.tab;
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;
      commit("SET_DATA", { tab: tab, data: entries });
      commit("SET_PAGINATION", { tab: tab, pagination: pagination });
      switch (tab) {
        case 1:
          commit("SET_TOTAL_RECORD_TAB1", pagination.total_specific_item);
          break;
        case 2:
          commit("SET_TOTAL_RECORD_TAB2", pagination.total_specific_item);
          break;
        case 3:
          commit("SET_TOTAL_RECORD_TAB3", pagination.total_specific_item);
          break;
        case 4:
          commit("SET_TOTAL_RECORD_TAB4", pagination.total_specific_item);
          break;
        case 5:
          commit("SET_TOTAL_RECORD_TAB5", pagination.total_specific_item);
          break;
      }
      return response;
    },
    
    async post(_context, payload) {
      return await ENTITY.post(payload);
    },

    async update(_context, payload) {
      return await ENTITY.update(payload);
    },

    async delete(_context, payload) {
      return await ENTITY.delete(payload);
    },

    async confirmDelivery(_context, payload) {
      return await ENTITY.confirmDelivery(payload);
    },

    async updateApprovalFlag(_context, payload) {
      return await ENTITY.updateApprovalFlag(payload);
    },

    async updateMachineInventoryStartUse(_context, payload) {
      return await ENTITY.updateMachineInventoryStartUse(payload);
    },

    async updateMachineInventoryStopUse(_context, payload) {
      return await ENTITY.updateMachineInventoryStopUse(payload);
    },

    async updateRegistorReturn(_context, payload) {
      return await ENTITY.updateRegistorReturn(payload);
    },

    async updateMachineInventoryConfirmReturn(_context, payload) {
      return await ENTITY.updateMachineInventoryConfirmReturn(payload);
    },

    async updateMachineInventoryRegister(_context, payload) {
      return await ENTITY.updateMachineInventoryRegister(payload);
    },

    async getListMachineOwnerCompanies({ commit }, payload) {
      const response = await ENTITY.getListMachineOwnerCompanies(payload);
      if (response.hasError) {
        return response;
      }
      const entries = response.data.contents.entries;

      commit("SET_LIST_MACHINE_OWNER_COMPANIES", entries);
      return response;
    },

    async getListMachineUseCompanies({ commit }, payload) {
      const response = await ENTITY.getListMachineUseCompanies(payload);
      if (response.hasError) {
        return response;
      }
      const entries = response.data.contents.entries;

      commit("SET_MACHINE_USE_COMPANIES", entries);
      return response;
    },

    async getUsage({ commit }, payload) {
      const response = await ENTITY.getUsage(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      
      commit("SET_DATA_USAGE", entries);
      return response;
    },
    async getMachineFieldDetail({ commit }, payload) {
      const response = await ENTITY.getMachineFieldDetail(payload);
      const entries =  response.data.contents.entries;
      commit("SET_MACHINE_FIELD_DETAIL", entries);
      return response;
    },
    async updateMachineInventories(_context, payload) {
      return await ENTITY.updateMachineInventories(payload);
    },
    async updateMachineInventoryProhibitStatus(_context, payload) {
      return await ENTITY.updateMachineInventoryProhibitStatus(payload);
    },
    async updateMachineInventoryUsage(_context, payload) {
      return await ENTITY.updateMachineInventoryUsage(payload);
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getListMachineOwnerCompanies: (state) => {
      return state.listMachineOwnerCompanies;
    },
    getUsage: (state) => {
      return state.usageData;
    },
    getListMachineUseCompanies: (state) => {
      return state.listMachineUseCompanies;
    },
    getNumberRecordTab1: (state) => {
      return state.numberRecordTab1;
    },
    getNumberRecordTab2: (state) => {
      return state.numberRecordTab2;
    },
    getNumberRecordTab3: (state) => {
      return state.numberRecordTab3;
    },
    getNumberRecordTab4: (state) => {
      return state.numberRecordTab4;
    },
    getNumberRecordTab5: (state) => {
      return state.numberRecordTab5;
    },
    getMachineFieldDetail: (state) => {
      return state.machineField;
    },
  },
};

