<template>
  <div>
    <NotificationDrawer width="320px">
      <v-list>
        <v-subheader>
          <div class="NotificationDrawer__title">通知</div>
        </v-subheader>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="item in items"
            :key="item.id"
            disabled
            class="NotificationItem"
          >
            <v-list-item-content>
              <v-list-item-subtitle class="NotificationItem__wrapper">
                <div>
                  <span
                    class="NotificationItem__label"
                    :class="statusClass(getTypeMessage(item.message_type_id).tag_color)" >
                    {{ getNameMessage(getTypeMessage(item.message_type_id), item.cmn_mst_safety_diary_type_id) }}
                  </span>
                  <span
                    v-if="item.urgency != null"
                    class="NotificationItem__urgency ml-2"
                    :class="statusClass(getTypeUrgency(item.urgency).tag_color)" >
                    {{ getTypeUrgency(item.urgency).name }}
                  </span>
                </div>
                <div class="NotificationItem__date">
                  {{ getNameSystem(item.sys_id) }}・{{ item.period }}
                </div>
              </v-list-item-subtitle>
              <v-list-item-title class="NotificationItem__wrapper">
                <div>
                  <div class="NotificationItem__message">
                    {{ convertStr(item.content)[0] }}
                  </div>
                  <div v-if="convertStr(item.content).length > 1" class="None__static__message">
                    {{ convertStr(item.content)[1] }}
                  </div>
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </NotificationDrawer>
  </div>
</template>

<script>
import NotificationDrawer from "@/components/common/NotificationDrawer";
import { Store } from "@/store/Store";
import { NOTIFICATION_COLOR , NOTIFICATION_MESSAGE_TYPE , NOTIFICATION_SYS, NOTIFICATION_URGENCY } from "@/constants/NOTIFICATION.js";

/**
 * ポータル共通 初期値
 */

export default {
  data: () => {
    return {
      items: [],
      NOTIFICATION_MESSAGE_TYPE,
      NOTIFICATION_SYS,
      NOTIFICATION_URGENCY,
      cmn_mst_safety_diary_types: [],
    };
  },
  mounted() {
    this.$watch(
      () => Store.getters["CmnMst/get"],
      (data) => {
        if (data && data.entries) {
          this.cmn_mst_safety_diary_types = [...data.entries.cmn_mst_safety_diary_types];
          
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["Notification/getItems"],
      (newValue, oldValue) => {
        if(this.cmn_mst_safety_diary_types.length > 0 && JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.items = [...newValue];
          this.updateNoticeDisplayed();
        }
      },
      {
        deep: true,
      }
    );
  },
  components: {
    NotificationDrawer,
  },
  methods: {
    statusClass(tag_color) {
      const key = Object.keys(NOTIFICATION_COLOR).find((key) => {
        return NOTIFICATION_COLOR[key].id === tag_color;
      });
      return `status_${NOTIFICATION_COLOR[key].key}`;
    },
    getTypeMessage(messageId) {
      return this.NOTIFICATION_MESSAGE_TYPE.find((item) => {
        return item.id == messageId;
      })
    },
    getTypeUrgency(urgency) {
      return this.NOTIFICATION_URGENCY.find((item) => {
        return item.id == urgency;
      })
    },
    getNameMessage(message, safety_diary_id) {
      let name = message. name;
      if(message.id == 1) {
        name = this.cmn_mst_safety_diary_types.find((item) => item.id == safety_diary_id).type;
      }
      return name;
    },
    getNameSystem(sysId) {
      return this.NOTIFICATION_SYS.find((item) => {
        return item.id == sysId;
      }).name;
    },
    async updateNoticeDisplayed() {
      let ids = [];
      this.items.forEach(
        (item) => ids.push(item.id)
      );
      if(ids.length > 0) {
        await Store.dispatch("Notification/updateNoticeDisplayed", { ids })
      }
    },
    convertStr(str) {
      let data = "";
      let rs = str.split("\n");
      if (rs.length > 2) {
        rs.forEach((e, index) => {
          if (index > 0) {
            data += e + "";
          }
        });
        rs = [rs[0], data];
      }
      return rs;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.NotificationItem {
  border-bottom: 1px solid #ddd;
}
.NotificationDrawer__title {
  font-weight: bold;
}
.NotificationItem__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.NotificationItem__message {
  color: black;
  white-space: normal;
}
.None__static__message{
  color: black;
  text-overflow:ellipsis;
  overflow:hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.NotificationItem__date {
  font-size: 10px;
}
.NotificationItem__label {
  font-size: 9px;
  color: white;
  padding: 0 6px;
  height: 18px;
  border-radius: 4px;
  line-height: 18px;
  &.status_positive {
    background-color: $color_approved;
  }
  &.status_negative {
    background-color: $color_rejected;
  }
  &.status_normal {
    background-color: $color_created;
  }
  &.status_orange_light {
    background-color: $color_register;
  }
}
.NotificationItem__urgency {
  font-size: 9px;
  color: white;
  padding: 0 6px;
  height: 8px;
  border-radius: 4px;
  line-height: 18px;
  &.status_positive {
    background-color: $color_approved;
  }
  &.status_negative {
    background-color: $color_rejected;
  }
  &.status_normal {
    background-color: $color_created;
  }
  &.status_blue {
    background-color: $color_warning_blue;
  }
  &.status_red {
    background-color: $color_warning_dark_red;
  }
}

.NotificationItem__buttons {
  width: 48px;
  display: flex;
  justify-content: space-between;
}
.NotificationItem__btn {
  display: flex;
  align-items: center;
  margin-left: 4px;
  pointer-events: all;
  &.none {
    display: none;
    cursor: default;
  }
}
</style>
