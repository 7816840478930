<template>
  <div
    class="schedulePlan"
    v-if="item.start_time && item.end_time"
    :style="style"
  >
    <div class="time"></div>
  </div>
</template>
<script>
import { differenceInMinutes, format } from "date-fns";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
export default {
  data: () => {
    return {
      style: "",
    };
  },
  props: {
    isUsage: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
    index: {
      type: Number,
    },
    //1時間の分割数
    hourDivide: {
      type: Number,
    },
    //cellRect.widthが1時間の幅ピクセル
    cellRect: {
      type: Object,
    },
    //今日の日付
    today: {
      type: String,
    },
    //今日の開始ピクセル
    todayStartX: {
      type: Number,
    },
    //上部のマージン
    marginTop: {
      type: Number,
    },
  },
  methods: {},
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        const { start_time, end_time } = newValue;

        const twentyOne = 21;
        const six = 6;
        const zero = 0;
        const today = new Date(this.today);
        const start = new Date(start_time);
        let end = new Date(end_time);

        if(this.IS_OBAYASHI && start.getHours() < six) {
          start.setHours(six);
          start.setMinutes(zero);
        }

        if(this.IS_OBAYASHI && end.getHours() < six) {
          end.setHours(six);
          end.setMinutes(zero);
        }

        if(this.IS_OBAYASHI && start.getHours() >= twentyOne) {
          start.setHours(twentyOne);
          start.setMinutes(zero);
        }

        if(this.IS_OBAYASHI && end.getHours() >= twentyOne) {
          end.setHours(twentyOne);
          end.setMinutes(zero);
        }

        if(start_time && end_time) {
          if(this.IS_OBAYASHI && format(new Date(start), "yyyy/MM/dd") < format(new Date(end), "yyyy/MM/dd")) {
            end = new Date(format(new Date(start), "yyyy/MM/dd"));
            end.setHours(twentyOne);
            end.setMinutes(zero);
          }
        }

        const rect = {
          x: null,
          width: null,
        };

        // 継続時間
        const diffMinContinuous = differenceInMinutes(end, start);
        rect.width =
          (diffMinContinuous * (this.cellRect.width * this.hourDivide)) / 60;

        // 開始位置
        const timeDiff = this.IS_OBAYASHI && this.isUsage ? today.setHours(today.getHours() + 6) : today;
        const diffMinStart = differenceInMinutes(start, timeDiff);

        rect.x =
          this.todayStartX +
          diffMinStart * ((this.cellRect.width * this.hourDivide) / 60);

        rect.top = this.index * this.cellRect.height + this.marginTop + 6;

        this.style = `width:${rect?.width}px;height:100%;left:${rect?.x}px;top:${rect?.top}px;`;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    IS_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.schedulePlan {
  position: absolute;
  z-index: 1;
  border-radius: 0;
  overflow: hidden;
}
.time {
  font-size: 8px;
  color: black;
  &:before {
    content: "";
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(119deg,#ffffff, #ffffff 10px, #9e9e9e 10px, #9e9e9e 20.06px);
    opacity: 0.5;
  }
}
</style>
