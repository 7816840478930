<template>
  <v-container>
    <!-- parent table -->
    <v-data-table
      :headers="tableLabels"
      :items="items"
      :items-per-page="itemsPerPage"
      :height="tableHeight"
      fixed-header
      hide-default-footer
      disable-sort
      :show-select="!isTableTypeAll"
      class="elevation-0 v-data-table__wrapper"
      sort-by="updatedAt"
      :noDataText="NO_DATA_MESSAGE"
    >
      <!-- parent table template -->
      <template v-slot:body="{ items }">
        <tbody v-for="item in items" :key="item.schedule_id">
          <tr @click="$emit('openItemForm', item)">
            <td class="start">
              <v-row>
                <v-col cols="12" sm="6">
                  <!-- toggle icon -->
                  <v-icon @click.stop="toggleShowChildren(item.schedule_id)">
                    {{
                      isShowChildren[item.schedule_id]
                        ? "mdi-chevron-down"
                        : "mdi-chevron-right"
                    }}
                  </v-icon>
                </v-col>
                <v-col cols="12" sm="6">
                  <!-- checkbox -->
                  <v-simple-checkbox
                    v-if="!isTableTypeAll"
                    :value="isSelected(item.schedule_id)"
                    @input="updateCheckbox(item, $event)"
                  ></v-simple-checkbox>
                </v-col>
              </v-row>
            </td>
            <td>{{ item.approve_name }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.job_name }}</td>
            <td>
              {{ item.children_description.join(", ") }}
            </td>
            <td>{{ item.description }}</td>
            <td>{{ item.attendance_name }}</td>
            <td>{{ item.work_time }}</td>
          </tr>
          <tr v-show="isShowChildren[item.schedule_id]">
            <td colspan="8">
              <!-- child table -->
              <v-data-table
                :items="item.children"
                hide-default-header
                hide-default-footer
                disable-sort
                class="elevation-0 v-data-table__wrapper"
                sort-by="updatedAt"
                :show-select="!isTableTypeAll"
                :noDataText="NO_DATA_MESSAGE"
                disable-pagination
              >
                <!-- child table template -->
                <template v-slot:body="{ items }">
                  <tbody v-for="item in items" :key="item.schedule_id">
                    <tr
                      @click="$emit('openItemForm', item)"
                      class="child-table"
                    >
                      <td class="start">
                        <v-simple-checkbox
                          v-if="!isTableTypeAll"
                          :value="isSelected(item.schedule_id)"
                          @input="updateCheckbox(item, $event)"
                        ></v-simple-checkbox>
                      </td>
                      <td>{{ item.approve_name }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.job_name }}</td>
                      <td>
                        {{ item.children_description.join(", ") }}
                      </td>
                      <td>{{ item.description }}</td>
                      <td>
                        {{ item.attendance_name }}
                      </td>
                      <td>{{ item.work_time }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </td>
          </tr>
          <tr v-show="isShowChildren[item.schedule_id]">
            <td colspan="8">
              <div class="d-flex justify-center">
                <v-btn
                  x-small
                  depressed
                  class="mr-4 display-more"
                  color="#E5E5E5"
                  @click="$emit('getChildItems', item.schedule_parent_id)"
                  >さらに表示する
                  <v-badge
                    class="display-more-number"
                    inline
                    color="#767676"
                    :content="
                      childTableDisplayMoreNumber(
                        item.total_children_item,
                        item.children.length
                      )
                    "
                  />
                </v-btn>
                <v-btn
                  class="add-new-child"
                  depressed
                  x-small
                  @click="$emit('addNewChild', item.schedule_parent_id)"
                  color="primary"
                  >作業を新規追加</v-btn
                >
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
export default {
  data: () => {
    return {
      NO_DATA_MESSAGE,
      // child table 表示ステート
      isShowChildren: {},

      // items props受け取りステート
      hasItems: false,
    };
  },

  props: {
    // ヘッダー
    tableLabels: Array,

    // parent テーブルの高さ
    tableHeight: Number,

    // テーブル表示item
    items: Array,

    // 1ページあたりのitem数
    itemsPerPage: Number,

    // checkboxの更新
    update: Function,

    // checkboxの値
    isSelected: Function,

    // 作業予定データを開く
    openItemForm: Function,

    // child テーブルのデータをAPI取得
    getChildItems: Function,

    // 親にchild table追加
    addNewChild: Function,

    // テーブル全体判定
    isTableTypeAll: Boolean,
  },

  async mounted() {
    this.$watch(
      // items propsを1度だけwatchして、child tableの表示ステートをinit
      () => this.items,
      (data) => {
        if (!this.hasItems) {
          const items = data;
          this.initIsShowChildren(items);
          this.hasItems = true;
        }
      },
      {
        immidiate: true,
        deep: true,
      }
    );
  },

  methods: {
    // さらに表示できるchild table数
    childTableDisplayMoreNumber(totalNum, displayNum) {
      // 親が持つすべてのchild table数から表示されている数をひく
      const _number = totalNum - displayNum;
      return _number > 0 ? _number : "0";
    },

    updateCheckbox(item, isChecked) {
      // parentがchecked
      if (item.children && item.children.length) {
        // childrenにもcheck入れる
        const childrendIds = item.children.map((child) => child.schedule_id);
        const ids = [item.schedule_id, ...childrendIds];
        ids.forEach((id) => {
          this.$emit("update", id, isChecked);
        });
      } else {
        // childがchecked
        // 自身のみにcheckを入れる
        this.$emit("update", item.schedule_id, isChecked);
      }
    },

    // parent table ごとの child tableの表示ステートをinit
    initIsShowChildren(items) {
      let _isShowChildren = {};
      items.forEach((item) => {
        _isShowChildren[item.schedule_id] = false;
      });
      this.isShowChildren = _isShowChildren;
    },

    // child tableの表示切り替え
    toggleShowChildren(id) {
      this.isShowChildren[id] = !this.isShowChildren[id];
    },
  },
};
</script>

<style lang="scss" scoped>
// ヘッダーとテーブルコンテンツのたて位置を合わせるために
// show-selectオプションをONにした状態でcheckboxを非表示
::v-deep .v-data-table-header {
  .v-input--selection-controls__input {
    display: none !important;
  }
}

.child-table {
  td.start {
    padding-right: 0;
  }
}

button.add-new-child,
button.show-more {
  height: 20px !important;
}

.display-more-number ::v-deep .v-badge__badge {
  font-size: 10px;
}

.display-more-number ::v-deep .v-badge__badge {
  padding: 1px;
  height: 14px;
}
</style>
