import Vue from "vue";
import Vuex from "vuex";
import { textChat } from "../../../api/modules/textChat";

Vue.use(Vuex);

/**
 * メッセージ 添付ファイル
 */
export const TYPE_ATTACH_FILE = {
  type: null, // jpg,png,pdf
  name: "", //ファイル名
  data: null, //base64
};

/**
 * メッセージ POST パラメータ
 */
export const TYPE_MESSAGE_POST_PARAMS = {
  chat_room_id: 1,
  user_id: null,
  message: "",
  status_flg: 0,
  // files: [TYPE_ATTACH_FILE],
};

/**
 * ファイル POST パラメータ
 */
export const TYPE_FILE_POST_PARAMS = {
  chat_room_id: 1,
  message_id: 0,
  filename: "",
  type: "",
  data: "",
};

/**
 * apiを指定
 */
const ENTITY = textChat;

/**
 * メッセージ一覧取得パラメータ
 */
const GET_MESSAGES_PARAMS = {
  chat_room_id: 1,
  start_message_id: null,
  get_old: true,
  limit: 10,
};

/**
 * ROOMキー
 */
const ROOM = {
  room_id: null,
  updated_at: null,
  messages: [],
};

// 既読の遅延実行
const ReadDelayController = () => {
  // 遅延時間
  const readDeplayTime = 300;

  // メッセージid
  let message_ids = [];

  // 遅延実行関数
  let readDelayFunction = null;

  const setMessageId = ({ user_id, message_id, chat_room_id }) => {
    // 追加
    message_ids.push(message_id);

    // タイマーリセット
    clearTimeout(readDelayFunction);

    // 遅延実行
    readDelayFunction = setTimeout(async () => {
      // ユニークに変換
      let set = new Set(message_ids);
      let _message_ids = Array.from(set);
      await textChat.read({
        user_id,
        message_ids: _message_ids,
        chat_room_id,
      });
      message_ids = [];
    }, readDeplayTime);
  };

  return {
    setMessageId,
  };
};

const readDelay = ReadDelayController();

export const TextChat = {
  namespaced: true,

  state: {
    rooms: [],
    room: {},
    siteUsers: [],
  },

  mutations: {
    SET_ROOMS(state, data) {
      state.rooms = data.entries;
    },
    SET_SITE_USERS(state, data) {
      state.siteUsers = data.users;
    },
    SET_READ_USERS(state, { chat_room_id, message_id, users }) {
      let room = { ...state.room };
      const key = `${chat_room_id}`;
      if (chat_room_id in state.room) {
        const messages = room[key].messages.map((message) => {
          if (message.message_id === message_id) {
            return {
              ...message,
              reads: users,
            };
          } else {
            return message;
          }
        });
        room[key].messages = messages;
        state.room = room;
      }
    },
    // メッセージを追加
    // Setで重複を削除しているのでどんどん追加してOK
    SET_MESSAGES(state, { chat_room_id, entries }) {
      let room = { ...state.room };
      const key = `${chat_room_id}`;

      if (chat_room_id in state.room) {
        // 追加
        let _messges = room[key].messages.concat(entries);
        _messges = _messges.reverse();

        // 重複を削除
        let concatmessages = [];
        // for (let i = 0; i < _messges.length; i++) {
        //   if (
        //     _messges[i] &&
        //     !concatmessages.find(
        //       (_item) => _item?.message_id === _messges[i].message_id
        //     )
        //   ) {
        //     //無ければ追加
        //     concatmessages.push(_messges[i]);
        //   } else {
        //     //あれば置き換え
        //     // concatmessages.splice(i, 1, _messges[i]);
        //   }
        // }
        _messges.forEach((item) => {
          //無ければ追加
          if (
            item &&
            !concatmessages.find(
              (_item) => _item?.message_id === item.message_id
            )
          ) {
            concatmessages.push(item);
          }
        });

        // idでソート
        concatmessages.sort((a, b) => {
          if (a.id < b.id) return -1;
          if (a.priidce > b.id) return 1;
          return 0;
        });
        // room[key].updated_at = updated_at;
        room[key].messages = concatmessages;
      } else {
        // 新規
        room[key] = { ...ROOM };
        room[key].room_id = key;
        // room[key].updated_at = updated_at;
        room[key].messages = entries;
      }
      state.room = room;
    },
  },

  actions: {
    // ルーム一覧取得
    async rooms({ commit }, payload) {
      // console.log("dispatch rooms", payload);
      const response = await ENTITY.rooms(payload);
      if (!response.hasError) {
        const data = { ...response.data.contents };
        // console.log("dispatch rooms response", data);
        commit("SET_ROOMS", data);
      }
      return response;
    },

    // メッセージ取得
    async get({ commit }, payload) {
      // console.log("dispatch get", payload);
      const params = Object.assign(GET_MESSAGES_PARAMS, payload);
      let response;
      if (payload.get_old) {
        params.get_old = "1";
        // delete params.get_old;
        response = await ENTITY.getOld(params);
      } else {
        delete params.get_old;
        response = await ENTITY.get(params);
      }
      if (!response.hasError) {
        let data = { ...response.data.contents };
        data.chat_room_id = params.chat_room_id;
        // console.log("dispatch get response", data);
        commit("SET_MESSAGES", data);
      }
      return response;
    },

    //メッセージ送信
    async postMessage(context, payload) {
      // console.log("POST MESSAGE", payload);
      const params = { ...TYPE_MESSAGE_POST_PARAMS, ...payload };
      return await ENTITY.postMessage(params);
    },

    //ファイル送信(配列)
    async postFiles(context, { chat_room_id, message_id, files }) {
      // console.log("POST FILES", files);
      return await Promise.all(
        files.map(async (fileParam) => {
          const _fileParam = {
            ...TYPE_FILE_POST_PARAMS,
            ...fileParam,
            chat_room_id,
            message_id,
          };
          delete _fileParam.id;
          delete _fileParam.size;
          const result = await ENTITY.postFile(_fileParam);
          // console.log("POST FILE ", result);
          return result;
        })
      );
      // const params = { ...TYPE_FILE_POST_PARAMS, ...payload };
      // return await ENTITY.postFile(params);
    },

    async siteUsers({ commit }, payload) {
      // console.log("dispatch siteusers");
      const response = await ENTITY.getSiteUsers(payload);
      // console.log("dispatch siteusers response", response);
      if (!response.hasError) {
        const data = { ...response.data.contents };
        commit("SET_SITE_USERS", data);
      }
      return response;
    },

    async readUsers(context, payload) {
      const message_id = payload.message_id;
      const chat_room_id = payload.chat_room_id;
      const response = await ENTITY.getReadUsers(payload);
      console.log("read users", chat_room_id, message_id, response);
      //TODO 既読ユーザ登録
      // commit("SET_READ_USERS", { message_id, chat_room_id });
    },

    // 既読
    async read(context, { user_id, message_id, chat_room_id }) {
      readDelay.setMessageId({ user_id, message_id, chat_room_id });
    },
    async readPdf(_context, payload) {
      return await ENTITY.readPdf(payload);
    },
  },

  getters: {
    getRooms: (state) => {
      return state.rooms;
    },
    getMessages: (state) => (room_id) => {
      // console.log("getMessages", room_id, state);
      return state.room[`${room_id}`];
    },
    getSiteUsers: (state) => {
      return state.siteUsers;
    },
    getRoomUsers: (state) => (room_id) => {
      if (state.rooms.length == 0) return [];
      const room = state.rooms.chatrooms.find((room) => room.id === room_id);
      return room ? room.users : [];
    },
  },
};
