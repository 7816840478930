import Vue from "vue";
import Vuex from "vuex";
import { schedule } from "../../../api/modules/schedule";

Vue.use(Vuex);

/**
 * 予定の共通ストア
 * 共通で使用するapiとデータを管理します
 */

/**
 * apiを指定
 */
const ENTITY = schedule;

export const Schedule = {
  namespaced: true,

  state: {
    partnerCompanies: [],
    locations: [],
    timeframes: [],
    listQualification: [],
    listSearchQualification: []
  },

  mutations: {
    SET_PARTNER_COMPANIES(state, payload) {
      state.partnerCompanies = payload;
    },
    SET_LOCATIONS(state, payload) {
      state.locations = payload;
    },
    SET_TIMEFRAMES(state, payload) {
      state.timeframes = payload;
    },
    SET_CHILD_DATA(state, payload) {
      const parent = state.data.find(
        (item) => item.schedule_id === payload.schedule_parent_id
      );
      const children = [...parent.children, ...payload.entries];
      const data = state.data.map((item) => {
        if (item.schedule_id === payload.schedule_parent_id) {
          const _item = { ...item };
          _item.children = children;
          return _item;
        }
        return item;
      });
      state.data = data;
    },
    SET_LIST_QUALIFICATION(state, payload){
      state.listQualification = payload
    },
    SET_LIST_SEARCH_QUALIFICATION(state, payload){
      state.listSearchQualification = payload
    },
  },

  actions: {
    /**
     * 協力会社検索
     */
    async getPartnerCompanies({ commit }, { keyword }) {
      const response = await ENTITY.getPartnerCompanies({ keyword });
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_PARTNER_COMPANIES", entries);
      return response;
    },

    //検索結果をクリア
    clearPartnerCompanies({ commit }) {
      commit("SET_PARTNER_COMPANIES", []);
    },

    /**
     * 作業用場所を検索
     */
    async getLocations({ commit }, { site_id }) {
      const response = await ENTITY.getLocations({ site_id });
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_LOCATIONS", entries);
      return response;
    },

    //検索結果をクリア
    clearLocations({ commit }) {
      commit("SET_LOCATIONS", []);
    },

    async getTimeframes({ commit }, { site_id }) {
      const response = await ENTITY.getTimeframes({ site_id });
      if (response.hasError) {
        return response;
      }
      const timeframes = response.data.contents;
      commit("SET_TIMEFRAMES", timeframes);
      return response;
    },

    async loadChildren({ commit }, payload) {
      const response = await ENTITY.getScheduleChildren(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_CHILD_DATA", {
        entries,
        schedule_parent_id: payload.schedule_parent_id,
      });
      return response;
    },
    async getListQualification({ commit }, payload) {
      const response = await ENTITY.getListQualification(payload);
      commit("SET_LIST_QUALIFICATION", response.data.contents.entries);
      return response;
    },
    async getListSearchQualification({ commit }, payload) {
      const response = await ENTITY.getListQualification(payload);
      commit("SET_LIST_SEARCH_QUALIFICATION", response.data.contents.entries);
      return response;
    },
    async getListHistoryQualificationWorkPlans(_context, payload) {
      const response = await ENTITY.getListHistoryQualificationWorkPlans(payload);
      return response
    },
    async getListHistoryQualificationWorkResults(_context, payload) {
      const response = await ENTITY.getListHistoryQualificationWorkResults(payload);
      return response
    }
  },

  getters: {
    getPartnerCompanies: (state) => {
      return state.partnerCompanies;
    },
    getLocations: (state) => {
      return state.locations;
    },
    getTimeframes: (state) => {
      return state.timeframes;
    },
    getListQualification: (state) => {
      return state.listQualification;
    },
    getListSearchQualification: (state) => {
      return state.listSearchQualification;
    },
  },
};
