<template>
  <v-card class="dialog">
    <v-card-title>
      <div :style="getTitleColorStyle" class="title">{{ dialogText.title }}</div>
    </v-card-title>
    <v-card-text v-if="dialogText.flg != CONFIRM_DIALOG_STATUS.DELETE" class="body">
      <div class="text-wrap">
        <div class="text">{{ dialogText.text1 }}</div>
      </div>
      <div class="text-wrap">
        <div class="text">{{ dialogText.text2 }}</div>
      </div>
    </v-card-text>
    <v-card-text v-if="dialogText.flg == CONFIRM_DIALOG_STATUS.DELETE" class="body">
      <div class="text-wrap">
        <div class="text left-text-confirm">{{ errorText1 }}</div>
      </div>
    </v-card-text>
    <v-card-text v-if="dialogText.flg == CONFIRM_DIALOG_STATUS.DELETE" class="body">
      <div class="text-wrap">
        <div class="text left-text-confirm">{{ errorText2 }}</div>
      </div>
    </v-card-text>
    <v-card-text v-if="dialogText.flg == CONFIRM_DIALOG_STATUS.DELETE" class="body">
      <div class="text-wrap">
        <div class="text left-text-confirm">{{ errorText3 }}</div>
      </div>
    </v-card-text>
    <v-card-text v-if="dialogText.flg == CONFIRM_DIALOG_STATUS.DELETE" class="body">
      <div class="text-wrap">
        <div class="text left-text-confirm">{{ errorText4 }}</div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!dialogText.isError"
        depressed
        outlined
        :color="getColor"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn v-if="!dialogText.isError" depressed class="btn" :color="getColor" :disabled="isDisable" @click="onSubmit">
        {{ dialogText.btnSubmitText }}
      </v-btn>
      <v-btn v-if="dialogText.isError" depressed class="btn" :color="getColor" @click="$emit('close')">
        {{ dialogText.btnSubmitText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CONFIRM_DIALOG_STATUS
} from "@/constants/SCHEDULE_CRANE";
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON";
export default {
  data() {
    return {
      errorText1: "",
      errorText2: "",
      errorText3: "",
      errorText4: "",
      CONFIRM_DIALOG_STATUS,
      isDisable: false,
      time_out: null,
    }
  },
  components: {},
  props: {
    dialogText: Object,
    warning: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    this.$watch(
      () => this.dialogText,
      (newValue) => {
        if (newValue.flg == this.CONFIRM_DIALOG_STATUS.DELETE) {
          this.errorText1 = newValue.text1.split("/")[0];
          this.errorText2 = newValue.text1.split("/")[1];
          this.errorText3 = newValue.text2.split("/")[0];
          this.errorText4 = newValue.text2.split("/")[1];
        }
        console.log("this.dialogText", this.dialogText)
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getTitleColorStyle() {
      return this.warning ? "color:red;" : "";
    },
    getColor() {
      return this.warning ? "warning" : "primary";
    },
  },
  methods: {
    onSubmit() {
      this.isDisable = true;
      // Re-enable after submit
      this.time_out = setTimeout(() => {
        this.isDisable = false;
      }, SUBMIT_DELAY_TIMEOUT);
      this.$emit('yes');
    }
  },
  /**
   * Important: clear timeout
   */
  beforeDestroy() {
    // clear the timeout before the component is destroyed
    clearTimeout(this.time_out);
  },
};
</script>
