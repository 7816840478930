<template>
  <div>
    <v-app-bar class="portalHeader" dense>
      <!-- 現場ポータルグローバルヘッダー -->
      <!-- タイトル -->
      <div class="portalHeader-title font-weight-bold primary--text" mx-3  @click="close">
        <span class="cursor-title" @click="goToHomePage">{{ title }}</span>
      </div>
      <!-- 現場選択ダイアログ -->
      <v-btn
        dense
        filled
        depressed
        color="grey lighten-2"
        class="site_select_btn"
        @click="openSearchDialog"
      >
        <!-- 現場名 -->
        <div class="sites_name_wrapper">
          {{ CurrentSite ? CurrentSite.field_name : "-" }}
        </div>
        <v-spacer></v-spacer>
        <v-icon dark right> mdi-menu-down </v-icon>
      </v-btn>

      <!-- 日付 -->
      <span class="portalHeader-date primary--text mx-4" @click="close">
        {{ getFormattedDate() }}
      </span>

      <v-spacer></v-spacer>

      <!-- 現場ポータル タブメニュー -->
      <v-tabs portalHeader-tabmenu right v-model="active_tab" @click="close">
        <v-tab
          v-for="val in menuSites"
          v-show="val.visibility"
          :key="val.id"
          @change="(menu = val), onChange(val.id)"
        >
          <!-- ドロップダウンメニュー -->
          <DropdownList
            v-if="
              HEADER_MENU_DROPDOWN_OPTION.find((item) => item.id === val.id)
            "
            :label="val.name"
            :menu="getDropdownMenu(val.id)"
            @showForm="showForm"
          />
          <!-- 通常メニュー -->
          <span
            v-if="
              !HEADER_MENU_DROPDOWN_OPTION.find((item) => item.id === val.id)
            "
            >{{ val.name }}</span
          ></v-tab
        >
      </v-tabs>

      <!-- 通知 -->
      <v-btn icon @click="openNotification">
        <v-icon v-if="isUpdated" class="buruburu">mdi-bell</v-icon>
        <v-icon v-if="!isUpdated">mdi-bell-outline</v-icon>
      </v-btn>

      <!-- drawer -->
      <v-btn icon @click="openDrawer" v-if="!IS_OBAYASHI">
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
      </v-btn>
      <!-- drawer -->
      <v-btn icon @click="openDrawer" v-if="IS_OBAYASHI">
        <v-app-bar-nav-icon>
          <v-icon color="primary">mdi-account-circle-outline</v-icon>
        </v-app-bar-nav-icon>
      </v-btn>

    </v-app-bar>

    <Popup width="480px" :dialog="isShowSearchDialog">
      <FieldsSelectDialog
        title="現場選択"
        :formValues="formValues"
        @formUpdate="formUpdate"
        @onSearch="searchSite"
        @close="isShowSearchDialog = false"
      />
    </Popup>
    <DrawingSelectDialog
      :isShow="isShowDrawingDialog"
      @onClose="isShowDrawingDialog = false"
    />
    <Popup :dialog="isShowFieldDocumentForm">
      <DocumentForm
        @cancel="closeSettingForm"
      />
    </Popup>
  </div>
</template>

<script>
import {
  HEADER_MENU_ITEMS_SITE_ARRAY,
  HEADER_MENU_ITEMS_SITE,
  PORTAL_LIST,
  HEADER_MENU_DROPDOWN,
  MENU_ENTRANCE_ITEMS_MAP,
  HEADER_MENU_ITEMS_SITE_ARRAY_USER_RENTAL_COMPANY,
  HEADER_MENU_DROPDOWN_FOR_USER_RENTAL_COMPANY,
} from "@/constants/GLOBALHEADER";
import {
  getEnvConfigValue,
  ENV_CONFIG_LABELS,
  ENV_CONFIG_RENTAL,
} from "@/constants/ENV_CLIENT";
import { FACE_RECOGNITION_FLAG } from "@/constants/REGISTER_FACE";
import { Store } from "@/store/Store.js";
import { getDateJp } from "@/utils/timeUtil"; //日付フォーマット変換で使用
import FieldsSelectDialog from "./components/FieldsSelectDialog";
import Popup from "@/components/common/Popup.vue";
import DrawingSelectDialog from "@/components/drawingSelect/index.vue";
import DropdownList from "./components/DropdownList.vue";
import {
  ROLE_USER_RENTAL_COMPANY,
  ROLE_SUPERVISOR,
  ROLE_FOREMAN,
} from "@/constants/COMMON";
import DocumentForm from "@/components/forms/document/DocumentSettingForm";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";

const HEADR_MENU = () => {
  const menuConfig = getEnvConfigValue(
    ENV_CONFIG_LABELS.HEADER_MENU_ITEMS_SITE
  );
  return Object.keys(HEADER_MENU_ITEMS_SITE).map((key) => {
    return { ...HEADER_MENU_ITEMS_SITE[key], ...menuConfig[key] };
  });
};
const HEADR_MENU_RENTAL = () => {
  return Object.keys(HEADER_MENU_ITEMS_SITE).map((key) => {
    return {
      ...HEADER_MENU_ITEMS_SITE[key],
      ...ENV_CONFIG_RENTAL.HEADER_MENU_ITEMS_SITE[key],
    };
  });
};

export default {
  data() {
    return {
      title: "GREEN",
      ROLE_USER_RENTAL_COMPANY,
      // タブメニュー表示項目
      HEADER_MENU_ITEMS_SITE_ARRAY,
      HEADER_MENU_ITEMS_SITE,
      HEADER_MENU_DROPDOWN,
      HEADER_MENU_ITEMS_SITE_ARRAY_USER_RENTAL_COMPANY,
      HEADER_MENU_DROPDOWN_FOR_USER_RENTAL_COMPANY,

      // 選択中のタブメニューid
      active_tab: 0,

      // 検索データ
      formValues: {
        // 検索items
        items: [],
        // 検索ワード
        keyword: "",
        // 選択項目id
        selected: null,
      },

      // 検索ダイアログ表示
      isShowSearchDialog: false,

      //開智計画図面ダイアログ表示
      isShowDrawingDialog: false,

      // 更新があるか
      isUpdated: false,

      // 現場id
      siteId: null,
      menuSites: [],
      isShowFieldDocumentForm: false
    };
  },

  components: {
    FieldsSelectDialog,
    Popup,
    DrawingSelectDialog,
    DropdownList,
    DocumentForm
  },

  async mounted() {
    /**
     * 更新のチェック
     */
    this.$watch(
      () => Store.getters[`Notification/getNewNoticeStatus`],
      (value) => {
        if (value != null) {
          this.isUpdated = value === 1;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    /**
     * 選択しているメニューを取得
     */
    this.$watch(
      () => [Store.getters[`GlobalHeader/getSiteMenuId`], this.menuSites],
      (data) => {
        const menuId = data[0];
        const menu = data[1];
        const selected = menu.findIndex((item) => {
          return item.id === menuId;
        });
        this.active_tab = selected;
      },
      {
        immediate: true,
      }
    );

    /**
     * 現場一覧を取得
     */
    this.$watch(
      () => Store.getters[`GlobalHeader/getSiteList`],
      (data) => {
        const siteList = {
          ...data,
        };
        this.formValues.items = siteList;
      },
      {
        immidiate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters[`GlobalHeader/getCurrentSite`],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          if (newValue.field_id) {
            const field_id = newValue.field_id;
            const paramGetRole = {
              field_id,
              target_role: [],
            };
            Store.dispatch("Sites/getRole", paramGetRole);
          }
        }
      },
      {
        immediate: true,
      }
    );

    this.$watch(
      () => Store.getters["Sites/getRole"],
      (data) => {
        this.checkRole(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  props: {},

  computed: {
    /**
     * 現在の現場を取得
     */
    CurrentSite() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },

    IsRentalCompany() {
      return Store.getters["Sites/getRole"];
    },
    HEADER_MENU_DROPDOWN_OPTION() {
      return this.IsRentalCompany == ROLE_USER_RENTAL_COMPANY
        ? HEADER_MENU_DROPDOWN_FOR_USER_RENTAL_COMPANY
        : HEADER_MENU_DROPDOWN;
    },
    IS_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },

  methods: {
    checkRole(data) {
      if (data) {
        if (data == ROLE_USER_RENTAL_COMPANY) {
          if (this.active_tab == 0) {
            this.active_tab = null;
          }
          this.menuSites = HEADR_MENU_RENTAL().filter(
            (item) => item.visibility
          );
        } else {
          // this.menuSites = HEADER_MENU_ITEMS_SITE_ARRAY;
          this.menuSites = HEADR_MENU().filter((item) => item.visibility);
        }
      } else {
        this.active_tab = null;
        this.menuSites = [];
      }
    },
    /**
     * タブメニューの変更
     */
    onChange(menuId) {
      this.close();
      const selectedMenu = this.menuSites.find((item) => {
        return item.id === menuId;
      });

      //ドロップダウンメニュー
      if (this.HEADER_MENU_DROPDOWN_OPTION.some((item) => item.id === menuId))
        return;

      //配置計画はダイアログを表示し、選択してから遷移する
      if (menuId === HEADER_MENU_ITEMS_SITE.PLACEMENT.id) {
        this.isShowDrawingDialog = true;
        return;
      }
      this.$router.push(selectedMenu.path);
    },

    // ドロワー
    openDrawer() {
      Store.dispatch("Drawer/show", {
        type: PORTAL_LIST.SITE_PORTAL.id,
      });
    },

    // 通知
    async openNotification() {
      const result = await Store.dispatch("Notification/show");
      if (result.hasError) {
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.message,
        });
        return;
      }
    },

    goToHomePage() {
      Store.dispatch("GlobalHeader/setSite", {
        field_id: null,
      });
      Store.dispatch("SitePortal/resetData");
      Store.dispatch("Weather/resetWeather");
      this.$router.push("/portal");
      this.close();
    },

    // 検索ダイアログ
    async openSearchDialog() {
      const result = await Store.dispatch("GlobalHeader/searchSites", {
        keyword: "",
      });
      if (result.hasError) {
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.message,
        });
      }
      this.isShowSearchDialog = true;
      this.close();
    },

    /**
     * 現場検索
     */
    async searchSite() {
      const { keyword } = this.formValues;
      const result = await Store.dispatch("GlobalHeader/searchSites", {
        keyword,
      });
      if (result.hasError) {
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.message,
        });
      }
    },

    // フォームの変更を受け取る
    formUpdate(values) {
      this.formValues = { ...values };

      /**
       * 現場選択
       */
      if (this.formValues.selected) {
        // 現場idが選択されたらset
        Store.dispatch("GlobalHeader/setSite", {
          field_id: this.formValues.selected,
        });
        Store.dispatch("PortalChart/getWorkTypes", this.formValues.selected);
        // ダイアログclose
        this.isShowSearchDialog = false;

        //リセット
        const formValues = {
          items: [],
          keyword: "",
          selected: null,
        };
        this.formValues = formValues;

        // 現場ポータルに遷移
        this.$router.push("/portal/dashboard");
      }
    },

    /**
     * 日付変換
     * @param date:yyyy/mm/dd（day）
     */
    getFormattedDate() {
      return getDateJp(new Date()).date;
    },

    /**
     * ドロップダウンメニューを取得
     */
    getDropdownMenu(id) {
      this.close();
      const dropdown = this.HEADER_MENU_DROPDOWN_OPTION.find(
        (item) => item.id === id
      );
      if (
        this.IsRentalCompany !== ROLE_SUPERVISOR &&
        this.IsRentalCompany !== ROLE_FOREMAN &&
        id == HEADER_MENU_ITEMS_SITE.ENTRANCE.id
      ) {
        return dropdown.menu.filter(
          (item) => item.id == MENU_ENTRANCE_ITEMS_MAP.NEW_WORKER.id
        );
      }
      if (
        this.CurrentSite.face_recognition_flag ==
          FACE_RECOGNITION_FLAG.NO_FACE.id &&
        id == HEADER_MENU_ITEMS_SITE.ENTRANCE.id
      ) {
        return dropdown.menu.filter(
          (item) => item.id != MENU_ENTRANCE_ITEMS_MAP.REGISTER_FACE.id
        );
      }

      return dropdown.menu;
    },

    close() {
      Store.dispatch("Drawer/close");
      Store.dispatch("Notification/close");

    },

    async showForm(url) {
      if (!url) {
        const field_id = this.CURRENT_SITE.field_id;
        const params = {
          field_id,
          target_role: []
        };
        await Store.dispatch("Sites/getRole", params);
        this.isShowFieldDocumentForm = true;
      } else {
        this.$router.push(url);
      }
    },

    closeSettingForm() {
      this.isShowFieldDocumentForm = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
@import "@/assets/scss/responsive.scss";

.portalHeader {
  .portalHeader-title {
    font-size: 16px;
    width: 128px;
    margin-right: 8px;
  }
  .portalHeader-siteSelect {
    width: 224px;
  }
  .portalHeader-date {
    width: 240px;
    white-space: nowrap;
  }
}

.v-badge__badge {
  font-size: 9px !important;
}
.theme--light.v-badge .v-badge__badge:after {
  border-color: none !important;
}

.site_select_btn {
  width: 220px;
  min-width: 180px !important;
  overflow: hidden;
  @media (max-width: $sp) {
    width: 140px;
    min-width: 140px !important;
  }
}
.site_select_btn.v-btn.v-size--default {
  padding: 4px !important;
}
.sites_name_wrapper {
  width: auto;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  text-indent: 4px;
  text-transform: capitalize;
  font-size: 12px;
  letter-spacing: 0;
}

.cursor-title {
  cursor: pointer;
}

.buruburu {
  display: inline-block;
  animation: hurueru 1s infinite;
  transition-timing-function: ease-in-out;
  &::before {
    color: $color_util_1;
  }
}

@keyframes hurueru {
  0% {
    transform: rotateZ(10deg);
  }
  50% {
    transform: rotateZ(-10deg);
  }
  100% {
    transform: rotateZ(10deg);
  }
}
</style>
