import Api, { Mock } from "../api";
import schedule_gates from "./mock/schedule_gates.json";
import schedule_gates_children from "./mock/schedule_gates_children.json";
import schedule_gate_detail from "./mock/schedule_gate_detail.json";
import gate_passage_last_time from "./mock/gate_passage_last_time.json";
import { ENV_CLIENT } from "../../constants/ENV_CLIENT";
/**
 * 共通
 */
const SCHEDULE_GATE_URL = `${process.env.VUE_APP_API_BASE_URL}/gate_passages`;

const GET_DETAIL_SCHEDULE_GATE_URL = `${SCHEDULE_GATE_URL}/detail`;
const CREATE_SCHEDULE_GATE_URL = `${SCHEDULE_GATE_URL}/create`;
const UPDATE_SCHEDULE_GATE_URL = `${SCHEDULE_GATE_URL}/update`;
const DELETE_SCHEDULE_GATE_URL = `${SCHEDULE_GATE_URL}/delete`;

const GET_GATES_LIST_URL = `${SCHEDULE_GATE_URL}/list`;
const GET_GATES_CHILDREN_SCHEDULE = `${SCHEDULE_GATE_URL}/get_passages_by_gate`;
const POST_GATES_APPROVE = `${SCHEDULE_GATE_URL}/approve`;
const POST_GATES_REJECT = `${SCHEDULE_GATE_URL}/disapprove`;
const GET_GATE_PASSAGE_LASTTIME_URL = `${SCHEDULE_GATE_URL}/get_gate_passage_last_time`;
const GET_LIST_GATE_PASSAGE_FIELD_TREE_USER_HISTORY_URL = `${SCHEDULE_GATE_URL}/list_field_tree_user_history`;
const GET_LIST_GATE_PASSAGE_MANAGER_HISTORY_URL = `${SCHEDULE_GATE_URL}/list_manager_history`;
const GET_LIST_GATE_HISTORY_URL = `${SCHEDULE_GATE_URL}/list_history`;
const GET_LIST_SPOT_HISTORY_URL = `${SCHEDULE_GATE_URL}/list_spot_company_history`;
// CHECK IS OBAYASHI
const IS_OBAYASHI = process.env.VUE_APP_CLIENT === ENV_CLIENT.OBAYASHI;

// .envのVUE_APP_NODE_ENV = local の場合はモックを返す

if (Mock) {
  Mock.onGet(new RegExp(`${GET_DETAIL_SCHEDULE_GATE_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: schedule_gate_detail,
  });

  Mock.onGet(GET_GATES_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: schedule_gates,
  });

  Mock.onPost(CREATE_SCHEDULE_GATE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(UPDATE_SCHEDULE_GATE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });

  Mock.onGet(GET_GATES_CHILDREN_SCHEDULE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: schedule_gates_children,
  });

  Mock.onPost(POST_GATES_APPROVE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });

  Mock.onPost(POST_GATES_REJECT).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(DELETE_SCHEDULE_GATE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(new RegExp(`${GET_GATE_PASSAGE_LASTTIME_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: gate_passage_last_time,
  });
}

export const scheduleGates = {
  getGates: (params) => {
    return Api.get(GET_GATES_LIST_URL, params);
  },
  getDetail: async (params) => {
    return Api.get(GET_DETAIL_SCHEDULE_GATE_URL, params);
  },
  post: (params) => {
    console.log("post:", params);
    return Api.post(CREATE_SCHEDULE_GATE_URL, params);
  },
  update: async (params) => {
    return Api.post(UPDATE_SCHEDULE_GATE_URL, params);
  },
  getScheduleGatesChildren: (params) => {
    return Api.get(GET_GATES_CHILDREN_SCHEDULE, params);
  },
  postScheduleGatesApprove: (params) => {
    return Api.post(POST_GATES_APPROVE, params);
  },
  postScheduleGatesReject: (params) => {
    return Api.post(POST_GATES_REJECT, params);
  },
  delete: async (params) => {
    return Api.post(DELETE_SCHEDULE_GATE_URL, params);
  },
  getGatePassageLastTime: async (params) => {
    return Api.get(GET_GATE_PASSAGE_LASTTIME_URL, params);
  },
  getListGatePassageFieldTreeUserHistory: async (params) => {
    return Api.get(GET_LIST_GATE_PASSAGE_FIELD_TREE_USER_HISTORY_URL, params);
  },
  getListGatePassageManagerHistory: async (params) => {
    return Api.get(GET_LIST_GATE_PASSAGE_MANAGER_HISTORY_URL, params);
  },
  getListGatePassageHistorys: async (params) => {
    params.params.is_oba = IS_OBAYASHI ? 1 : null;
    return Api.get(GET_LIST_GATE_HISTORY_URL, params);
  },
  getListSpotCompanyHistory: async (params) => {
    return Api.get(GET_LIST_SPOT_HISTORY_URL, params);
  },
};
