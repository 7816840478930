import Api, { Mock } from "../api";
import mock from "./mock/portalchart.json";
import mock_field_tree from "./mock/field_tree.json";
import mock_detail_user from "./mock/detail_user.json";
import related_company from "./mock/related_company.json";

const URL_CHART = `${process.env.VUE_APP_API_BASE_URL}/field_trees/get_tree`; //体制図の取得
const URL_WORK_TYPES = `${process.env.VUE_APP_API_BASE_URL}/fields/get_list_field_constructions`; //工事一覧の取得
const INVITE = `${process.env.VUE_APP_API_BASE_URL}/field_trees/invite`; //招待

const URL_GET_DETAIL_USER_PARTNER = `${process.env.VUE_APP_API_BASE_URL}/field_trees/get_detail_user_partner`;
const URL_UPDATE_DETAIL_USER_PARTNER = `${process.env.VUE_APP_API_BASE_URL}/field_trees/update_detail_user_partner`;
const URL_GET_RELATED_COMPANY = `${process.env.VUE_APP_API_BASE_URL}/field_trees/get_detail_field_tree_partner`;
const URL_UPDATE_RELATED_COMPANY = `${process.env.VUE_APP_API_BASE_URL}/field_trees/update_detail_field_tree_partner`;
const URL_GET_OWNER_COMPANY = `${process.env.VUE_APP_API_BASE_URL}/field_trees/get_detail_field_tree_prime`;
const URL_UPDATE_OWNER_COMPANY = `${process.env.VUE_APP_API_BASE_URL}/field_trees/update_detail_field_tree_prime`;
const URL_DELETE_FIELD_TREE_PARTNER = `${process.env.VUE_APP_API_BASE_URL}/field_trees/delete_field_tree_partner`;
const URL_GET_LIST_GROUP = `${process.env.VUE_APP_API_BASE_URL}/field_trees/list_group`;
const URL_GET_LIST_COMPANY_PARTNER = `${process.env.VUE_APP_API_BASE_URL}/field_trees/list_company_partner`;
const URL_GET_LIST_COMPANY_PARTNER_USER = `${process.env.VUE_APP_API_BASE_URL}/field_trees/list_company_partner_user`;
const URL_GET_FIELD_DIRECTORS = `${process.env.VUE_APP_API_BASE_URL}/field_trees/list_field_director`;
const URL_GET_FIELD_TIME_ZONES = `${process.env.VUE_APP_API_BASE_URL}/fields/list_time_zones`;
const URL_LIST_ITEM_TREES = `${process.env.VUE_APP_API_BASE_URL}/fields/list_item_trees`;
const URL_GET_LIST_COMPANY_PARTNER_USER_FROM_USER = `${process.env.VUE_APP_API_BASE_URL}/field_trees/list_company_partner_user_from_user`;
const URL_CONFIRM_WORK = `${process.env.VUE_APP_API_BASE_URL}/field_trees/update_state_handling_flag`;
const URL_UPDATE_FIELD_TREE_GROUP = `${process.env.VUE_APP_API_BASE_URL}/field_trees/update_group`;
const URL_UPDATE_FIELD_TREE_MANAGERS = `${process.env.VUE_APP_API_BASE_URL}/field_trees/update_manager`;
const URL_GET_LIST_COMPANY_PARTNER_LEVEL_1 = `${process.env.VUE_APP_API_BASE_URL}/field_trees/list_company_partner_level_1`;
const URL_GET_ALL_LIST_COMPANY = `${process.env.VUE_APP_API_BASE_URL}/field_trees/list_company_prime_partner`;
const URL_GET_LIST_USER_FROM_COMPANY_PARTNER_LEVEL_1 = `${process.env.VUE_APP_API_BASE_URL}/field_trees/list_user_from_company_partner_level_1`;
const URL_GET_LIST_SKILLS = `${process.env.VUE_APP_API_BASE_URL}/field_trees/list_skills`;
const URL_GET_LIST_SKILL_AND_CMN_MST_SKILL_IDS = `${process.env.VUE_APP_API_BASE_URL}/field_trees/list_skill_and_cmn_mst_skill_ids`;
const URL_FIELD_TREE_CSV = `${process.env.VUE_APP_API_BASE_URL}/field_trees/get_field_tree_csv`;
const URL_UPDATE_LEVEL_APPROVAL = `${process.env.VUE_APP_API_BASE_URL}/field_trees/update_type_approval`;
const URL_UPDATE_PLAN_MANAGERS = `${process.env.VUE_APP_API_BASE_URL}/field_trees/update_plan_manager`;
const URL_UPDATE_POSITION_SAFETY = `${process.env.VUE_APP_API_BASE_URL}/field_trees/update_position_safety`;
const URL_GET_FIELD_TREES_FOREMAN = `${process.env.VUE_APP_API_BASE_URL}/field_trees/get_foreman`;
if (Mock) {
  //体制図
  //TODO 初期値は work_type_id を nullなのでリクエスト -> nullの時は一番新しい工事を返す

  Mock.onGet(new RegExp(`${URL_CHART}/[\\d]+`)).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: mock,
  });

  //工事一覧
  Mock.onGet(new RegExp(`${URL_WORK_TYPES}/[\\d]+`)).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {
      entries: [
        { id: 1, name: "解体工事" },
        { id: 2, name: "水道工事" },
      ],
    },
  });

  Mock.onGet(new RegExp(`${URL_GET_OWNER_COMPANY}/[\\d]+`)).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: mock_field_tree,
  });

  Mock.onGet(new RegExp(`${URL_GET_RELATED_COMPANY}/[\\d]+`)).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: related_company,
  });

  Mock.onGet(URL_UPDATE_OWNER_COMPANY).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: mock_field_tree,
  });

  Mock.onGet(new RegExp(`${URL_GET_DETAIL_USER_PARTNER}/[\\d]+`)).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: mock_detail_user,
  });

  //工事一覧
  Mock.onPost(INVITE).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: "",
  });
  // DELETE
  Mock.onPost(URL_DELETE_FIELD_TREE_PARTNER).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: "",
  });

  Mock.onGet(new RegExp(`${URL_GET_LIST_GROUP}/[\\d]+`)).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {
      entries: [
        {
          id: 1,
          group_name: "Aグループ",
          field_tree_id_1: 1,
        },
        {
          id: 2,
          group_name: "Bグループ",
          field_tree_id_1: 1,
        },
      ],
    },
  });

  Mock.onGet(new RegExp(`${URL_GET_LIST_COMPANY_PARTNER_USER}/[\\d]+`)).reply(
    200,
    {
      status_code: 200,
      result: true,
      message: "",
      contents: {
        entries: [
          {
            user_id: 1,
            user_name_sei: "田中",
            user_name_mei: "太郎",
            user_experiences: [
              {
                cmn_mst_skills_id: 1,
                experience: 3,
              },
              {
                cmn_mst_skills_id: 2,
                experience: 5,
              },
            ],
          },
          {
            user_id: 2,
            user_name_sei: "大谷",
            user_name_mei: "慎治",
            user_experiences: [
              {
                cmn_mst_skills_id: 1,
                experience: 2,
              },
              {
                cmn_mst_skills_id: 2,
                experience: 6,
              },
            ],
          },
        ],
      },
    }
  );

  Mock.onGet(URL_GET_LIST_COMPANY_PARTNER).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {
      entries: [
        {
          field_tree_id: 1,
          company_name: "清和工業株式会社（xxx株式会社）",
        },
        {
          field_tree_id: 2,
          company_name: "清和工業株式会社（xxx株式会社）",
        },
      ],
    },
  });

  Mock.onGet(new RegExp(`${URL_GET_FIELD_TIME_ZONES}/[\\d]+`)).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {
      entries: [
        {
          id: 1,
          name: "昼間",
          start_time: "12:00",
          end_time: "18:00",
        },
        {
          id: 2,
          name: "夜",
          start_time: "18:00",
          end_time: "24:00",
        },
        {
          id: 3,
          name: "深夜",
          start_time: "00:00",
          end_time: "12:00",
        },
      ],
    },
  });

  Mock.onGet(new RegExp(`${URL_GET_FIELD_DIRECTORS}/[\\d]+`)).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {
      entries: [
        {
          id: 1,
          user_name_sei: "田中",
          user_name_mei: "太郎",
        },
        {
          id: 2,
          user_name_sei: "大谷",
          user_name_mei: "慎治",
        },
      ],
    },
  });
  Mock.onGet(new RegExp(`${URL_LIST_ITEM_TREES}/[\\d]+`)).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {
      entries: [
        {
          id: 1,
          a_field_item_name: "本館",
          b_field_item_name: "B1階",
          c_field_item_name: "Aエリア",
          d_field_item_name: "A工区",
        },
        {
          id: 2,
          a_field_item_name: "本館",
          b_field_item_name: "B2階",
          c_field_item_name: "Aエリア",
          d_field_item_name: "A工区",
        },
      ],
    },
  });
  Mock.onPost(URL_UPDATE_FIELD_TREE_GROUP).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {},
  });
  Mock.onPost(URL_UPDATE_FIELD_TREE_MANAGERS).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {},
  });
  Mock.onPost(URL_UPDATE_LEVEL_APPROVAL).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {},
  });
}

export const chart = {
  /**
   *
   * 体制図取得
   * @param {
   *   site_id:"現場id",
   *   work_type_id:"工事id"
   * }
   */
  get: (param) => {
    if (param?.field_tree_date) {
      let params = {
        field_tree_date: param["field_tree_date"],
        company_name: param["company_name"],
        company_name_kana: param["company_name_kana"],
      };
      if (param.is_oba) params.is_oba = param.is_oba;
      if (param.prime_flag) {
        params["prime_flag"] = param.prime_flag;
      }
      return Api.get(`${URL_CHART}/${param["work_type_id"]}`, { params });
    }
    return Api.get(`${URL_CHART}/${param["work_type_id"]}`);
  },

  /**
   * TODO integrate BE
   * getRelatedCompany
   * @param {
   *  company_id,
   *  field_tree_id
   * }
   */
  getRelatedCompany: (params) => {
    return Api.get(`${URL_GET_RELATED_COMPANY}/${params}`);
  },

  /**
   * TODO integrate BE
   * updateRelatedCompany
   * @param {
   *  field_tree
   * }
   */
  updateRelatedCompany: (params) => {
    return Api.post(`${URL_UPDATE_RELATED_COMPANY}`, params);
  },

  /**
   * TODO integrate BE
   * getOwnerCompany
   * @param {
   *  company_id,
   *  field_tree_id
   * }
   */
  getOwnerCompany: (params) => {
    return Api.get(`${URL_GET_OWNER_COMPANY}`, params);
  },

  /**
   * TODO integrate BE
   * updateOwnerCompany
   * @param {
   *  field_tree
   * }
   */
  updateOwnerCompany: (params) => {
    return Api.post(`${URL_UPDATE_OWNER_COMPANY}`, params);
  },
  /**
   * getDetailUserPartner
   * @param {*} params
   * @returns
   */
  getDetailUserPartner: (params) => {
    return Api.get(`${URL_GET_DETAIL_USER_PARTNER}/${params}`);
  },
  /**
   * updateDetailUserPartner
   * @param {*} params
   * @returns
   */
  updateDetailUserPartner: (params) => {
    return Api.post(URL_UPDATE_DETAIL_USER_PARTNER, params);
  },

  /**
   *
   * 工事一覧取得
   * @param {
   *  site_id:"現場id"
   * }
   */
  getWorkTypes: (params) => {
    if (params?.tree_flag) {
      const apiParams = {
        tree_flag: params.tree_flag,
      };
      return Api.get(`${URL_WORK_TYPES}/${params.field_id}`, {
        params: apiParams,
      });
    }
    if (params?.force_all_construction_flag) {
      const apiParams = {
        force_all_construction_flag: params.force_all_construction_flag,
      };
      return Api.get(`${URL_WORK_TYPES}/${params.field_id}`, {
        params: apiParams,
      });
    }
    return Api.get(`${URL_WORK_TYPES}/${params}`);
  },

  /**
   *
   * 招待 ここでパラメータをapiにあわせて
   * //TODO groupNameが存在する場合は元請け
   * @param { email, name_sei, name_mei, group_name, message, field_tree_id}
   */
  sendInvite: (params) => {
    return Api.post(INVITE, params);
  },

  /**
   * delete chart
   * @param { field_tree_id } params
   */
  deleteFieldTreePartner: async (params) => {
    return Api.post(URL_DELETE_FIELD_TREE_PARTNER, params);
  },
  getListGroups: (requestParams) => {
    if (
      (requestParams && requestParams.all_group_flag) ||
      requestParams?.all_group_flag == 0
    ) {
      const field_construction_id = requestParams.field_construction_id;
      const params = { all_group_flag: requestParams.all_group_flag };
      if (requestParams.group_name || requestParams.group_name === "")
        params.group_name = requestParams.group_name;
      return Api.get(`${URL_GET_LIST_GROUP}/${field_construction_id}`, {
        params,
      });
    }
    if (requestParams && requestParams.user_has_foreman) {
      const field_construction_id = requestParams.field_construction_id;
      const params = { user_has_foreman: requestParams.user_has_foreman };
      return Api.get(`${URL_GET_LIST_GROUP}/${field_construction_id}`, {
        params,
      });
    }
    if (requestParams && requestParams.force_all_group_flag) {
      const field_construction_id = requestParams.field_construction_id;
      const params = {
        force_all_group_flag: requestParams.force_all_group_flag,
      };
      if (requestParams.group_name || requestParams.group_name === "")
        params.group_name = requestParams.group_name;
      return Api.get(`${URL_GET_LIST_GROUP}/${field_construction_id}`, {
        params,
      });
    }
    if (
      requestParams &&
      (requestParams.group_name || requestParams.group_name === "")
    ) {
      const field_construction_id = requestParams.field_construction_id;
      const params = { group_name: requestParams.group_name };
      if (requestParams.force_all_group_flag == 1)
        params.force_all_group_flag = 1;
      return Api.get(`${URL_GET_LIST_GROUP}/${field_construction_id}`, {
        params,
      });
    }
    return Api.get(`${URL_GET_LIST_GROUP}/${requestParams}`);
  },
  getListCompanyPartner: (params) => {
    return Api.get(URL_GET_LIST_COMPANY_PARTNER, params);
  },
  getListCompanyPartnerUser: (requestParams) => {
    const id = requestParams.id;
    const params = {};
    if ("foreman_flag" in requestParams) {
      params["foreman_flag"] = requestParams.foreman_flag;
    }
    if ("state_handling_flag" in requestParams) {
      params["state_handling_flag"] = requestParams.state_handling_flag;
    }
    if ("user_name" in requestParams) {
      params["user_name"] = requestParams.user_name;
    }
    return Api.get(`${URL_GET_LIST_COMPANY_PARTNER_USER}/${id}`, { params });
  },

  getFieldTimeZones: (params) => {
    return Api.get(`${URL_GET_FIELD_TIME_ZONES}/${params}`);
  },

  getFieldDirectors: (id) => {
    const params = { state_handling_flag: 0 };
    return Api.get(`${URL_GET_FIELD_DIRECTORS}/${id}`, { params });
  },
  getFieldDirectorsChatManage: (id) => {
    const params = { group_id: id, state_handling_flag: 0 };
    return Api.get(`${URL_GET_FIELD_DIRECTORS}`, { params });
  },
  getFieldDirectorsChatManageFieldConstructionId: (field_construction_id) => {
    const params = { field_construction_id, state_handling_flag: 0 };
    return Api.get(`${URL_GET_FIELD_DIRECTORS}`, { params });
  },
  getFieldDirectorsObayashi: (params) => {
    return Api.get(URL_GET_FIELD_DIRECTORS, { params });
  },
  getListItemTrees: async (params) => {
    return Api.get(URL_LIST_ITEM_TREES, params);
  },
  getListCompanyPartnerUserFromUser: async (params) => {
    return Api.get(`${URL_GET_LIST_COMPANY_PARTNER_USER_FROM_USER}/${params}`);
  },
  updateStatHandling: async (params) => {
    return Api.post(URL_CONFIRM_WORK, params);
  },
  updateFieldTreeGroup: async (params) => {
    return Api.post(URL_UPDATE_FIELD_TREE_GROUP, params);
  },
  updateFieldTreeManagers: async (params) => {
    return Api.post(URL_UPDATE_FIELD_TREE_MANAGERS, params);
  },
  getListCompanyPartnerLevel1: async (params) => {
    return Api.get(URL_GET_LIST_COMPANY_PARTNER_LEVEL_1, params);
  },
  getAllListCompany: async (params) => {
    return Api.get(URL_GET_ALL_LIST_COMPANY, params);
  },
  getListUserFromCompanyPartnerLevel1: async (params) => {
    return Api.get(URL_GET_LIST_USER_FROM_COMPANY_PARTNER_LEVEL_1, params);
  },
  getListSkills: async (params) => {
    return Api.get(URL_GET_LIST_SKILLS, params);
  },
  getListSkillAndCmnMstSkillIds: async (params) => {
    return Api.get(URL_GET_LIST_SKILL_AND_CMN_MST_SKILL_IDS, params);
  },
  getFieldTreeCSV: (params) => {
    return Api.get(`${URL_FIELD_TREE_CSV}`, { params });
  },
  updateUpdateLevelApproval: async (params) => {
    return Api.post(URL_UPDATE_LEVEL_APPROVAL, params);
  },
  updatePlanManager: async (params) => {
    return Api.post(URL_UPDATE_PLAN_MANAGERS, params);
  },
  updatePositionSafety: async (params) => {
    return Api.post(URL_UPDATE_POSITION_SAFETY, params);
  },
  getFieldTreeForeman: (params) => {
    return Api.get(`${URL_GET_FIELD_TREES_FOREMAN}/${params}`);
  }
};
