<template>
  <v-app-bar class="portalHeader" dense>
    <!-- タイトル -->
    <v-toolbar-title
      class="portalHeader-title font-weight-bold primary--text"
      mx-3
      >{{ title }}</v-toolbar-title
    >
    <v-spacer></v-spacer>
    <!-- 現場ポータル -->
    <!-- <v-btn text @click="$emit('onReload')">リロード </v-btn>
    <v-btn text @click="$emit('onSave')"> 保存 </v-btn>-->
    <!-- プリント -->
    <v-btn icon @click="$emit('onPrint')">
      <v-icon color="primary">mdi-printer</v-icon>
    </v-btn>
    <!-- チャット -->
    <v-btn :disabled="!chat_room_id" icon @click="$emit('openChat')">
      <v-icon color="primary">mdi-forum</v-icon>
    </v-btn>
    <div v-if="!chat_room_id" class="createChatRoomPopup">
      <button @click="$emit('onCreateChatRoom')">
        チャットを作成 {{ chat_room_id }}
      </button>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      title: "GREEN",
    };
  },
  props: {
    chat_room_id: {
      type: Number,
      default: 0,
    },
  },
  components: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.portalHeader {
  position: sticky;
  top: 0;
  z-index: 999;
  .portalHeader-title {
    font-size: 16px;
    width: 128px;
  }
  .portalHeader-siteSelect {
    width: 224px;
  }
  .portalHeader-date {
    width: 240px;
  }
}
.createChatRoomPopup {
  position: absolute;
  bottom: -24px;
  right: 8px;
  width: 96px;
  height: 32px;
  background: white;
  border: 1px solid #f8f8f8;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);

  button {
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 32px;
    font-size: 10px;
  }
  &:hover {
    background: #fafafa;
  }
}
</style>
