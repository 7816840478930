<template>
  <v-card class="dialog">
    <div class="title">役職設定</div>
    <div class="px-10">
      <v-radio-group v-model="valueSelected">
        <v-radio
          v-for="n in SETTING_PLAN_MANAGER"
          :key="n.id"
          :label="n.value"
          :value="n.id"
        ></v-radio>
      </v-radio-group>
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="primary"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn
        depressed
        class="btn"
        color="primary"
        :key="flagError"
        @click.once="onSubmit()"
      >
        保存
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { SETTING_PLAN_MANAGER } from "@/constants/PORTAL_CHART";
export default {
  data() {
    return {
      SETTING_PLAN_MANAGER,
      valueSelected: SETTING_PLAN_MANAGER[1].id,
    }
  },
  props: {
    flagError: Boolean,
    isManager: {
      type: Number,
      default: 1,
    }
  },
  mounted() {
    this.$watch(
      () => this.isManager,
      (newVal) => {
        if(newVal) {
          this.valueSelected = newVal;
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  methods: {
    async onSubmit() {
      this.$emit('submit', this.valueSelected);
    }
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 10px;
  .title {
    font-size: 12px;
    font-weight: bold;
    color: #1b9c4f !important;
    padding-left: 25px;
    padding-top: 20px;
  }
}
</style>
