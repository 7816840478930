<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <!--  (共通) 会社ヘッダー -->
        <CompanyHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout
          :layoutParams="layoutParams"
          :hideFooter="searchParams.pageCount >= searchParams.total_item"
        >
          <template #tableHeader="{ updateHeader }">
            <!--  
              (共通) テーブルヘッダー 
              このなかに、新規作成ボタン、検索ボタン、一括削除ボタンが実装されてます
              @openRemoveDialog : 一括削除の確認ダイアログを開く
              @openItemForm : 新規作成フォームを開く
            -->
            <TableHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :multiRemoveStatus="disableRemoveBtn"
              :updateHeader="updateHeader"
              @openRemoveDialog="openRemoveDialog"
              @openItemForm="openNewItemForm"
            >
              <SearchFormWrapper>
                <!-- 文字入力 -->
                <Label label="現場名">
                  <InputText
                    name="word"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <!-- ロール -->
                <Label label="支店" width="350px">
                  <Select
                    name="companyBranchId"
                    :items="companyBranches.company_branches"
                    :editable="editSelectOffices"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <!--  (共通) 検索ボタン -->
                <v-spacer></v-spacer>
                <v-btn class="mr-6" color="primary" depressed @click="onSearch">
                  検索
                </v-btn>
              </SearchFormWrapper>
            </TableHeader>

            <!-- 
              (共通)
              ソートのレイアウトを調整するラッパーコンポーネントです
            -->
            <TableSortWrapper>
              <!--  
                (共通) ソート 
                ソート項目、ソート順、表示件数の選択、総件数の表示
              -->
              <TableSort
                :values="searchParams"
                :sort_items="SORT_ITEMS"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                @onInput="onChangeSortParams"
              />
            </TableSortWrapper>
          </template>
          <!-- 
            (共通)テーブル
            v-data-table自体は共通ですが、
            カラムによって変更をしたい場合はそれぞれ実装してください。
          -->
          <template #tableBody="{ tableHeight }">
            <v-data-table
              item-key="field_tree_id"
              v-model="selectedItems"
              :headers="TABLE_LABELS"
              :items="items"
              :items-per-page="searchParams.pageCount"
              :height="tableHeight"
              fixed-header
              hide-default-footer
              disable-sort
              class="elevation-1 v-data-table__wrapper"
              sort-by="updatedAt"
              show-select
              :noDataText="NO_DATA_MESSAGE"
              @item-selected="updateSelectedItems"
              @toggle-select-all="selectAllItems"
              @click:row="openItemForm"
            >
              <template v-slot:[`item.company_name`]="{ item }">
                <div
                  style="
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 280px;
                  "
                >
                  {{ convertName(item.company_name, 40) }}
                </div>
              </template>
              <template v-slot:[`item.field_name`]="{ item }">
                <div
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 380px;
                  "
                >
                  {{ convertName(item.field_name, 50) }}
                </div>
              </template>
              <template v-slot:[`item.full_address`]="{ item }">
                <div
                  style="
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 470px;
                  "
                >
                  {{ convertName(item.full_address, 65) }}
                </div>
              </template>
              <template v-slot:[`item.construction_details`]="{ item }">
                <div
                  style="
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 380px;
                  "
                >
                  {{ convertName(item.construction_details, 50) }}
                </div>
              </template>
            </v-data-table>
          </template>
          <template #tableFooter>
            <!-- (共通) ページネーション -->
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>

    <!--  (ユーザー用) 追加/編集ダイアログ -->
    <Popup :dialog="popups.isShowItemForm">
      <SiteForm
        :flag="true"
        :item="editedItem"
        :isNewItem="isNewItem"
        :isErrorSubmit="isErrorSubmit"
        :isEmitted="isEmitted"
        :companyBranches="companyBranches"
        @formUpdate="formUpdate"
        @submit="submitForm"
        @cancel="closeItemForm"
        :key="isResetForm"
      />
    </Popup>

    <!--  (共通) 削除ダイアログ / 文言変えてもok -->
    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :items="selectedItemsRemoved"
        warning
      />
    </Popup>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import ConfirmRemoveDialog from "./components/ConfirmRemoveDialog"; //削除確認ダイアログ
import CompanyHeader from "@/components/companyHeader/CompanyHeader";
import {
  TABLES_PER_PAGE,
  TABLE_SORT_ORDERS,
  NO_DATA_MESSAGE,
} from "@/constants/COMMON"; //絞り込みフォームで使用

import SiteForm from "@/components/forms/sites/SiteForm";
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import {
  SITE_TABLE_LABELS,
  SITE_SORT_ITEMS,
  SITE_INITIAL_ITEM,
} from "@/constants/SITES"; //絞り込みフォームで使用
import { HEADER_MENU_ITEMS_INHOUSE } from "@/constants/GLOBALHEADER"; //グローバルヘッダーメニュー
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";

import _ from "lodash";

/**
 * 定数
 * この一覧ページで使用する固有の定数を定義します。
 * 共通に使用する定数は基本的に@/constants/で定義します。
 * - 定数は大文字で定義します
 * - 定数は基本的にはdataに代入しないで直接参照します
 */

//タイトル
const PAGE_TITLE = "現場";
const RENTAL_COMPANY = "レンタル";

//１ページあたりのテーブル件数
const PAGE_COUNT = 25;

//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;

//ストア
const STORE = "Sites";

//テーブルヘッダーラベル
const TABLE_LABELS = SITE_TABLE_LABELS;

//ソート要素
const SORT_ITEMS = SITE_SORT_ITEMS;

//フォーム初期値
const INITIAL_ITEM = SITE_INITIAL_ITEM;

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      PAGE_TITLE,
      TABLE_LABELS,
      SORT_ITEMS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      NO_DATA_MESSAGE,
      /**
       * (共通)
       * 一覧データ
       */
      items: [],

      /**
       * (共通)
       * checkbox選択item
       */
      selectedItems: [],
      selectedItemsRemoved: [],

      /**
       * (共通)
       * 編集アイテム
       * 定数から初期値を代入
       */
      editedItem: {},

      companyBranches: {},

      /**
       * (共通)
       * 新規フラグ
       */
      isNewItem: false,

      // 検索パラメータ
      searchParams: {
        word: "",
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: null, //sortする項目
        asc: true,
        companyBranchId: null,
      },

      /**
       * (共通)
       * ポップアップの状態管理
       */
      popups: {
        // 追加/編集フォーム
        isShowItemForm: false,
        // 削除確認ダイアログ表示
        isShowRemoveDialog: false,
      },
      isResetForm: false,
      editSelectOffices: false,
      isErrorSubmit: false,
      isEmitted: false,
    };
  },

  components: {
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    CompanyHeader,
    Popup,
    ConfirmRemoveDialog,
    SiteForm,
    InputText,
    Select,
    Label,
  },

  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    /**
     * グローバルヘッダーメニューを更新
     */
    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_INHOUSE.SITES.id,
    });

    this.$watch(
      () => Store.getters["PortalChart/getCompanyBranchId"],
      (data) => {
        this.searchParams.companyBranchId = !data ? null : data;
        if (this.COMPANY_USER.company_branch_id)
          this.searchParams.companyBranchId =
            this.COMPANY_USER.company_branch_id;
        if (
          !this.COMPANY_USER.company_branch_id &&
          !this.searchParams.companyBranchId
        )
          this.editSelectOffices = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    /**
     * (共通)
     * ここからapiにリクエストします
     */
    this.getItems();

    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
        Store.getters["Offices/getCompanyBranches"],
      ],
      (data) => {
        /**
         *  (共通)
         * 一覧リストの格納
         */
        let _items = [...data[0]];
        this.items = _items.map((x) => ({
          ...x,
          isSelectable: x.nest_no !== RENTAL_COMPANY,
        }));
        if (data[2]) {
          this.companyBranches = _.cloneDeep(data[2]);
          this.companyBranches.company_branches?.unshift({
            id: null,
            name: "",
          });
        }

        /**
         *  (共通)
         * ページネーション更新
         */
        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */
  computed: {
    /**
     * (共通)
     * 削除ボタンの活性・非活性
     * selectedItems.length === 0
     * 自体はtemplate側で記述できますが、
     * ここに明示的に状態を定義して可読性を担保してます
     */
    disableRemoveBtn() {
      return this.selectedItems.length === 0;
    },
    selectBranchItems() {
      // Todo
    },
    /**
     * API Param
     */
    apiParams() {
      const companyUser = JSON.parse(sessionStorage.getItem("COMPANY_USER"))
        .Login.company_user;
      return {
        location_flag: 1, // get list of site that is using for list screen
        company_id: companyUser.company_id,
        company_branch_id: this.searchParams.companyBranchId
          ? this.searchParams.companyBranchId
          : companyUser.company_branch_id,
        field_name: this.searchParams.word,
        order: this.searchParams.sort,
        asc: this.searchParams.asc ? 1 : 0,
        current_page: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
      };
    },
    COMPANY_USER() {
      return JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login
        .company_user;
    },
    ENV_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
  },

  methods: {
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 検索
     */
    onSearch() {
      this.searchParams["currentPage"] = 1;
      this.getItems();
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
    },

    /**
     * (共通)
     * 並び替えパラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      name == "pageCount" ? (searchParams["currentPage"] = 1) : "";
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 作成/編集フォーム
     * INITIAL_ITEM で定義した値がitemに入ります
     * @param INITIAL_ITEMで定義している値
     */
    openNewItemForm() {
      let editedItem = INITIAL_ITEM.entries;
      this.editedItem = _.cloneDeep(editedItem);
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },
    async openItemForm(item) {
      let params = { id: item.field_id}
      this.ENV_OBAYASHI ? params.is_oba = 1 : '';
      if (item.nest_no !== RENTAL_COMPANY) {
        const siteInfo = await Store.dispatch(
          `${STORE}/getDetail`,
          {params}
        );
        // Show Detail Form
        if (!siteInfo.hasError) {
          this.editedItem = _.cloneDeep(siteInfo.data.contents.entries);
          this.isNewItem = false;
          this.popups.isShowItemForm = true;
        }
      }
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.$nextTick(() => {
        //選択を初期化
        this.editedItem = { ...INITIAL_ITEM.entries };
      });
    },

    /**
     *  (共通)
     * 確認ダイアログ
     * 基本的に変更しないでいいはず
     */

    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
        this.selectedItemsRemoved = [];
      });
    },

    /**
     * (共通)
     * フォームの変更を受け取る
     */
    formUpdate(params) {
      this.editedItem = { ...params };
    },

    /**
     *  (共通)
     * ストア / api
     * ストア実装ルールにしたがっている場合は、
     */

    // データ取得
    async getItems() {
      const companyUser = JSON.parse(sessionStorage.getItem("COMPANY_USER"))
        .Login.company_user;
      await Store.dispatch(`${STORE}/get`, { params: this.apiParams });
      await Store.dispatch(`Offices/getByCompanyId`, companyUser.company_id);
    },

    // 削除
    async removeItems() {
      const field_ids = this.selectedItems.map((items) => items.field_id);
      const result = await Store.dispatch(`${STORE}/delete`, { field_ids });

      if (!result.hasError) {
        this.selectedItemsRemoved = [];
        this.searchParams["currentPage"] = 1;
        //ダイアログ閉じる
        this.closeRemoveDialog();
        //成功したら値を更新
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
      }
    },

    /**
     * (共通)
     * 新規登録 / 更新
     * - idがある場合は更新
     */
    async submitForm() {
      const companyUser = JSON.parse(sessionStorage.getItem("COMPANY_USER"))
        .Login.company_user;
      const hasId = "id" in this.editedItem.fields;
      const editedItem = _.cloneDeep(this.editedItem);
      editedItem.field_technicians.forEach((element) => {
        delete element["exam_category"];
      });
      editedItem.company_fields.company_id = companyUser.company_id;
      if (
        companyUser.company_branch_id &&
        !editedItem.company_fields.company_branch_id
      ) {
        editedItem.company_fields.company_branch_id =
          companyUser.company_branch_id;
      }
      delete editedItem['field_approvals'];
      const result = await Store.dispatch(
        //idがある場合は更新、ない場合は新規
        hasId ? `${STORE}/edit` : `${STORE}/add`,
        editedItem
      );
      //レスポンスエラーがある場合はフォームを閉じない
      //エラーメッセージは、api.jsが表示
      if (result.hasError) {
        this.isErrorSubmit = true;
        return;
      } else if (hasId) {
        this.isResetForm = !this.isResetForm;
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        let params = { id: this.editedItem.fields.id}
        this.ENV_OBAYASHI ? params.is_oba = 1 : '';
        const resultDetail = await Store.dispatch(
          `${STORE}/getDetail`,
          {params}
        );
        this.editedItem = _.cloneDeep(resultDetail.data.contents.entries);
        this.isErrorSubmit = false;
      } else {
        //成功したら値を更新
        this.closeItemForm();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
      this.getItems();
      this.isEmitted = !this.isEmitted;
    },

    updateSelectedItems(value) {
      let currentItems = value.value
        ? this.items.filter(
            (element) => element.field_id === value.item.field_id
          )
        : [];
      if (currentItems.length > 0) {
        this.selectedItemsRemoved.push(currentItems[0]);
        this.selectedItems.push(...currentItems);
      } else {
        this.selectedItemsRemoved = this.selectedItemsRemoved.filter(
          (item) => item.field_id !== value.item.field_id
        );
        this.selectedItems = this.selectedItems.filter(
          (item) => item.field_id !== value.item.field_id
        );
      }
    },

    selectAllItems(value) {
      this.selectedItemsRemoved = [];
      let siteIdSet = new Set();
      if (value.value) {
        value.items.forEach((item) => {
          if (!siteIdSet.has(item.field_id)) {
            this.selectedItemsRemoved.push(item);
            siteIdSet.add(item.field_id);
          }
        });
      }
    },

    convertName(name, length) {
      let result = "";
      if (name) {
        result = name;
        if (name.length > length) {
          result = name.substring(0, length) + "•••";
        }
      }
      return result;
    },
  },
};
</script>
