// 承認
const TEXTCHAT_APPROVAL_TYPE = {
  REJECT: { label: "未承認", id: 0, key: "reject" },
  APPROVE: { label: "承認", id: 1, key: "approve" },
};

// ルーム設定
const TEXTCHAT_ROOM_INITAL_ITEM = {
  id: null,
  name: "",
  approval_flag: null,
  updated_at: "",
  created_at: "",
  deleted_at: "",
  users: [{ user_id: null, name: "", profile_image_name: "", entrance: false }],
  chat: "",
};

// テーブル設定
const TEXTCHAT_ROOM_TABLE_LABELS = [
  {
    text: "グループ名",
    value: "name",
    align: "left",
  },
  {
    text: "状態",
    value: "approval_flag",
    align: "left",
  },
  {
    text: "チャット",
    value: "id",
    align: "left",
  },
  {
    text: "更新",
    value: "updated_at",
    align: "left",
  },
  {
    text: "ユーザー",
    value: "users",
    align: "left",
  },
];

const TEXTCHAT_ROOM_MEMBER_TABLE_LABELS = [
  {
    text: "名前",
    value: "name",
    align: "left",
  },
  {
    text: "会社名",
    value: "company_name",
    align: "left",
  },
  // {
  //   text: "作業名",
  //   value: "work_name",
  //   align: "left",
  // },
];

export {
  TEXTCHAT_APPROVAL_TYPE,
  TEXTCHAT_ROOM_INITAL_ITEM,
  TEXTCHAT_ROOM_TABLE_LABELS,
  TEXTCHAT_ROOM_MEMBER_TABLE_LABELS,
};
