<template>
  <v-card class="dialog">
    <v-card-title>
      <div class="title">施工体制台帳作成</div>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" lazy-validation autocomplete="off">
        <v-list-item>
          <v-list-item-content class="pa-0">
            <Label label="一次協力会社名 " :editable="true" required>
              <Select
                name="field_tree_id_1"
                :items="dataSelect"
                :editable="true"
                :values="formValues"
                item_text="company_name"
                item_value="field_tree_id_1"
                @onInput="onInput"
              >
              </Select>
            </Label>
          </v-list-item-content>
        </v-list-item>
      </v-form>
      <div></div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="primary"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn
        depressed
        class="btn"
        color="primary"
        @click.once="onSubmitCreateReport"
        :key="flagError"
      >
        作成
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Select from "@/components/forms/elements/Select";
import Label from "@/components/forms/elements/Label";
export default {
  data() {
    return {
      formValues: {
        field_tree_id_1: null
      },
    };
  },
  components: {
    Select,
    Label,
  },
  props: {
    title: {
      type: String,
    },
    flagError: {
      type: Boolean,
    },
    dataSelect: {
      type: Array,
    },
  },
  async mounted() {
     this.$watch(
      () => this.dataSelect,
      (newValue) => {
        if (newValue) {
          this.formValues.field_tree_id_1 = [...newValue][0]?.field_tree_id_1;
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  methods: {
    onInput({ name, value }) {
      this.formValues[name] = value;
    },
    onSubmitCreateReport() {
      let item = this.dataSelect.find(e => e.field_tree_id_1 == this.formValues.field_tree_id_1);
      this.$emit("createReportOwnerSystemNote", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .title {
    font-size: 12px;
    color: #1b9c4f;
  }
}
</style>
