<template>
  <v-card class="dialog">
    <v-card-title>
      <div class="title">{{ title }}</div>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div class="text">
          {{ text1 }}
        </div>
        <div class="text">
          {{ text2 }}
        </div>
      </div>
    </v-card-text>
    <v-card-text> </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed
        outlined
        color="primary"
        class="btn"
        @click="$emit('closeForm')">
        やめる
      </v-btn>
      <v-btn depressed
        class="btn"
        color="primary"
        @click="$emit('nextForm')">
          {{ titleButton }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  props: {
      text1: {
      type: String,
      default: "",
    },
    text2: {
      type: String,
      default: "",
    },
    title: {
      type: String,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    titleButton:{
      type: String,
      default: "",
    }
  },
  computed: {
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .title {
    font-size: 12px;
    color: #1B9C4F;
  }
  .body {
    .text-wrap {
      .text {
        text-align: center;
        margin: auto;
        width: 80%;
      }
    }
  }
}
</style>
