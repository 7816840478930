<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <ValidationObserver>
            <v-form autocomplete="off">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <SectionLabel label="発注者" class="ml-4" />
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="発注者名" :editable="editable">
                          <InputText
                            name="order_party_name"
                            :values="fieldValues"
                            :editable="editable"
                            placeholder="xx株式会社"
                            validation_label="発注者名"
                            validation_rules="max:255"
                            @onInput="onInput(FIELDS_TITLE, $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>

                    <v-row>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="郵便番号" :editable="editable">
                              <InputText
                                name="order_party_postal_code"
                                :values="fieldValues"
                                :editable="editable"
                                placeholder="100-0001"
                                validation_label="郵便番号"
                                validation_rules="max:60|postcode"
                                @onInput="onChangePostalCode"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="都道府県" :editable="editable">
                              <Select
                                name="order_party_field_prefectures_id"
                                :values="fieldValues"
                                :items="cmn_mst_prefectures"
                                item_text="prefecture"
                                :editable="editable"
                                @onInput="onInput(FIELDS_TITLE, $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>

                    <v-list-item>
                      <v-list-item-content>
                        <Label label="市区町村" :editable="editable">
                          <InputText
                            name="order_party_city"
                            :values="fieldValues"
                            :editable="editable"
                            placeholder="千代田区千代田"
                            validation_label="市区町村"
                            validation_rules="max:60"
                            @onInput="onInput(FIELDS_TITLE, $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <Label label="番地・建物名" :editable="editable">
                          <InputText
                            name="order_party_address"
                            :values="fieldValues"
                            :editable="editable"
                            placeholder="１−２"
                            validation_label="番地・建物名"
                            validation_rules="max:120"
                            @onInput="onInput(FIELDS_TITLE, $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <SectionLabel label="請負契約" class="ml-4"/>
                    <v-list-item>
                      <v-list-item-content>
                        <v-row >
                          <v-col cols="12" sm="6" md="6">
                            <Label label="元請契約" :editable="editable">
                              <InputText
                                name="name"
                                :values="company_name"
                                :editable="false"
                              />
                            </Label>
                          </v-col>
                          <v-col cols="6" sm="6" md="6" class="row-position">
                              <Label label="下請契約" :editable="editable">
                              <Select
                                class="select-position"
                                name="company_branch_id"
                                :values="formValues.company_fields"
                                :items="company_branches"
                                :editable="editable && !COMPANY_USER.company_branch_id"
                                @onInput="onInput(COMPANY_FIELDS_TITLE, $event)"
                              />
                            </Label>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                    <SectionLabel
                      label="発注者の監督員名・権限及び意見申出方法"
                      class="ml-4 mt-3"
                    />

                    <v-list-item>
                      <v-list-item-content>
                        <Label label="発注者の監督員名" :editable="editable">
                          <InputText
                            name="order_supervisor_name"
                            :values="fieldValues"
                            :editable="editable"
                            placeholder="吉田設計事務所 吉田 忠夫"
                            validation_label="発注者の監督員名"
                            validation_rules="max:255"
                            @onInput="onInput(FIELDS_TITLE, $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label
                          label="権限及び意見申出方法"
                          :editable="editable"
                        >
                          <InputText
                            name="order_supervisor_authority_and_request_method"
                            :values="fieldValues"
                            :editable="editable"
                            placeholder="請負契約第◯◯️条記載のとおり"
                            validation_label="権限及び意見申出方法"
                            validation_rules="max:512"
                            @onInput="onInput(FIELDS_TITLE, $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>

                    <SectionLabel
                      label="監督員名・権限及び意見申出方法"
                      class="ml-4"
                    />
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="監督員名" :editable="editable">
                          <InputText
                            name="supervisor_name"
                            :values="fieldValues"
                            :editable="editable"
                            placeholder="上田 正"
                            validation_label="監督員名"
                            validation_rules="max:255"
                            @onInput="onInput(FIELDS_TITLE, $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label
                          label="権限及び意見申出方法"
                          :editable="editable"
                        >
                          <InputText
                            name="authority_and_request_method"
                            :values="fieldValues"
                            :editable="editable"
                            placeholder="下請負契約第️◯条記載のとおり"
                            validation_label="権限及び意見申出方法"
                            validation_rules="max:512"
                            @onInput="onInput(FIELDS_TITLE, $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>

                    <SectionLabel
                      label="現場代理人名・権限及び意見申し出方法"
                      class="ml-4"
                    />
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="現場代理人名" :editable="editable">
                          <InputText
                            name="site_agent_name"
                            :values="fieldValues"
                            :editable="editable"
                            placeholder="夏川  二郎"
                            validation_label="現場代理人名"
                            validation_rules="max:255"
                            @onInput="onInput(FIELDS_TITLE, $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label
                          label="権限及び意見申出方法"
                          :editable="editable"
                        >
                          <InputText
                            name="site_agent_authority_and_request_method"
                            :values="fieldValues"
                            :editable="editable"
                            placeholder="請負契約書第️◯️条記載のとおり"
                            validation_label="権限及び意見申出方法"
                            validation_rules="max:512"
                            @onInput="onInput(FIELDS_TITLE, $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import { Store } from "@/store/Store.js";
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import Select from "@/components/forms/elements/Select.vue";
import { postalCodeSearch } from "@/utils/postalCodeSearch";
import {
  FIELDS_TITLE,
  COMPANY_FIELDS_TITLE,
} from "@/constants/SITES";

export default {
  data: () => {
    return {
      fieldValues: {},
      formValues: {},
      company_name: {},
      company_branches: [],
      cmn_mst_prefectures: [
        { id: null, prefecture: "" },
        ...Store.state.CmnMst.constants.entries.cmn_mst_prefectures,
      ],
      FIELDS_TITLE,
      COMPANY_FIELDS_TITLE,
    };
  },
  components: {
    ValidationObserver,
    Label,
    SectionLabel,
    InputText,
    InputDatepicker,
    Select,
  },
  props: {
    item: Object,
    companyBranches: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => [this.item, this.companyBranches],
      (data) => {
        this.initData(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    /**
     * (共通)
     * 定数をapi/ストアからまとめて取得します
     *
     * this.CONSTANTS.roll
     * こうやって個々の定数を取得します
     */
    CONSTANTS() {
      return Store.getters["Constants/get"];
    },
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    COMPANY_USER() {
      return JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user;
    }
  },
  methods: {
    initData(data) {
      this.company_name = data[1].companies;
      this.company_branches = [{ id: null, name: ""},...data[1].company_branches];
      // get form value by this.item
      const formValues = data[0];
      if(this.COMPANY_USER.company_branch_id) {
        formValues.company_fields.company_branch_id = this.COMPANY_USER.company_branch_id;
      }
      // get fieldValues
      this.fieldValues = formValues.fields;
      this.formValues = { ...formValues };
    },
    onInput(parent_name, { name, value }) {
      const formValues = { ...this.formValues };
      formValues[parent_name][name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },

    /**
     * 郵便番号から都道府県、市町村名を検索
     */
    async onChangePostalCode({ value }) {
      const formValues = { ...this.formValues };
      formValues.fields.order_party_postal_code = value;
      if (value == "") {
        formValues.fields.order_party_field_prefectures_id = "";
        formValues.fields.order_party_city = "";
      } else if (value.match(/^\d{3}-?\d{4}$/g)) {
        const result = await postalCodeSearch.search({ postal_code: value });
        if (result) {
          formValues.fields.order_party_field_prefectures_id = result.prefecture.id;
          formValues.fields.order_party_city = result.city;
        }
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },

  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
.row-position{
  position: relative;
}

.select-position{
  position: absolute;
  width: 94%;
}
</style>
