<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    :name="validation_label"
    :rules="validation_rules"
  >
    <v-select
      v-model="val"
      dense
      @click="getListUser"
      @blur="setListUser"
      :items="listUser"
      :item-text="(item) => item.name_sei + ' ' + item.name_mei"
      :item-value="(item) => item.id"
      :disabled="!editable"
      :success="valid"
      :filled="editable"
      :append-icon="!editable ? '' : '$dropdown'"
      multiple
      chips
      :error-messages="errors"
      return-object
      :placeholder='editable ? "" : ""'
      @input="$emit('onInput', { name, value: val, index })"
    >
      <!-- チップのスタイル -->
      <template #selection="{ item }">
        <v-chip small :color="chipColor">{{ item.name_sei }} {{ item.name_mei }}</v-chip>
      </template>
    </v-select>
  </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
export default {
  components: {
    ValidationProvider,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    values: {
      type: Array,
    },
    items: {
      type: Array,
    },
    validation_rules: {
      type: String,
      default: "",
    },
    validation_label: {
      type: String,
      default: "",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    chipColor: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
    },
  },
  data: () => {
    return {
      val: "",
      listUser: [],
      isClick: false,
    };
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name, this.index],
      (newValue) => {
        // フォームに表示されるデータを更新
        const formValues = newValue[0];
        const name = newValue[1];
        const index = newValue[2];
        if (formValues && name && typeof index === "number") {
          this.val = formValues[index][name];
        }
      },
      { immediate: true, deep: true }
    );
    this.$watch(
      () => this.items,
      (newValue) => {
        this.listUser = newValue;
        if(this.isClick) {
          this.listUser = newValue.filter(item => item.state_handling_flag == 0);
        }
      },
      { immediate: true, deep: true }
    );

  },
  methods: {
    getListUser(){
      this.listUser = this.items.filter(item => item.state_handling_flag == 0);
      this.isClick = true;
    },
    setListUser(){
      setTimeout(() => {
        this.listUser = this.items;
      }, 200)
      this.isClick = false;
    }
  },
};
</script>
