<template>
  <v-card class="dialog">
    <v-card-title>
      <div style="color: green" class="title">種別設定</div>
    </v-card-title>
    <v-card-text class="body">
      <v-radio-group v-model="valueSelected" class="ml-5">
        <v-radio
          v-for="n in RADIO_CHECK"
          :key="n.id"
          :label="n.value"
          :value="n.id"
        ></v-radio> 
      </v-radio-group>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
        <v-btn
          depressed
          outlined
          color="green"
          class="btn"
          @click="$emit('close')"
        >やめる</v-btn>
        <v-btn color="primary" @click="$emit('chooseType', valueSelected)">保存</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { RADIO_CHECK } from "@/constants/PORTAL_CHART.js";

export default {
  data() {
    return {
      RADIO_CHECK,
      valueSelected: 1,
    }
  },

  props: {
    nameSelected: {
      type: String,
      default: '',
    },
  },
  async mounted() {
    this.$watch(
      () => this.nameSelected,
      (newVal) => {
        this.valueSelected = this.RADIO_CHECK.find(element => element.value == newVal)?.id?? 6;
      },
      { immediate: true, deep: true }
    );
  },
};
</script>
