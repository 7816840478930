<template>
  <v-list-item class="room" @click="$emit('onSelectRoom', item.id)">
    <v-list-item-content>
      <v-list-item-title>{{ item.name }}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <v-list-item-action-text
        v-text="dateFormat(item.updated_at)"
      ></v-list-item-action-text>
      <span class="room__unread"> {{ item.unread }} </span>
      <v-list-item-action-text
        v-text="dateFormatDistance(item.updated_at)"
      ></v-list-item-action-text>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import { formatDistanceToNow, format } from "date-fns";
import { ja } from "date-fns/locale";
export default {
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    getTime() {
      return this.item.date.split("T")[1];
    },
  },
  methods: {
    dateFormatDistance(d) {
      return formatDistanceToNow(new Date(d), { locale: ja });
    },
    dateFormat(d) {
      return format(new Date(d), "MM/dd HH:mm");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.room__unread {
}
</style>
