<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-row v-if="isNewItem">
                    <v-col cols="12" sm="12" md="12">
                      <v-switch
                      v-model="isHideEndDate"
                      label="繰り返し予定を入力する"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <!-- 日付 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="日付（開始）" :editable="editable" required>
                            <InputDatepicker
                              name="passage_date_from"
                              :values="formValues"
                              :editable="editable"
                              validation_label="日付（開始）"
                              :validation_rules="getStartDateRules()"
                              @onInput="onInput"
                              :flagNull="true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <!-- 日付 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="日付（終了）" :editable="editable" required v-if="isHideEndDate">
                            <InputDatepicker
                              name="passage_date_to"
                              :values="formValues"
                              :editable="editable"
                              validation_label="日付（終了）"
                              :validation_rules="getEndDateRules()"
                              @onInput="onInput"
                              :flagNull="true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <!-- 日付 -->
                          <Label label="日付" :editable="editable" required>
                            <InputDatepicker
                              name="passage_date"
                              :values="formValues"
                              :editable="editable"
                              placeholder="yyyy/mm/dd"
                              validation_label="日付"
                              validation_rules="required"
                              @onInput="onInput"
                              :flagNull="true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <!-- 工事 -->
                  <v-list-item>
                    <v-list-item-content class="row-position">
                      <Label label="工事" :editable="editable" required>
                        <Select
                          name="field_construction_id"
                          :values="formValues"
                          :items="FIELD_CONSTRUCTION_ARRAY"
                          :editable="editable"
                          @onInput="onInput"
                          validation_label="工事"
                          validation_rules="required"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 作業グループ -->
                  <v-list-item v-if="checkShow('field_tree_group_id')">
                    <v-list-item-content class="row-position">
                      <Label label="作業グループ" :editable="editable" :required="checkRequired('field_tree_group_id')">
                        <Select
                          name="group_id"
                          :values="formValues"
                          :items="FIELD_TREE_GROUP_ARRAY"
                          item_text="group_name"
                          :editable="editable"
                          :label="formValues.group_name || ''"
                          @onInput="onInput"
                          validation_label="作業グループ"
                          :validation_rules="checkRequired('field_tree_group_id')?'required':''"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 車輌手配業者 -->
                  <v-list-item>
                    <v-list-item-content class="row-position">
                      <Label label="車輌手配業者" :editable="editable" required>
                        <Select
                          name="field_tree_id"
                          :values="formValues"
                          :items="COMPANY_PARTNER_ARRAY"
                          item_text="company_name"
                          item_value="field_tree_id"
                          :editable="editable"
                          :label="formValues.field_tree_conpany_name"
                          @onInput="onInput"
                          validation_label="車輌手配業者"
                          validation_rules="required"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 職長 -->
                  <v-list-item>
                    <v-list-item-content class="row-position" v-if="editable">
                      <Label label="職長" :editable="editable" required>
                        <Select
                          name="foreman_user_id"
                          :values="formValues"
                          :items="foremanList"
                          :editable="editable"
                          :label="formValues.foreman_user_name"
                          @onInput="onInput"
                          validation_label="職長"
                          validation_rules="required"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-row v-if="!editable">
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="職長" :editable="editable" required>
                            <Select
                              name="foreman_user_id"
                              :values="formValues"
                              :items="FOREMAN_ARRAY"
                              :editable="editable"
                              @onInput="onInput"
                              validation_label="職長"
                              validation_rules="required"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="職長電話番号">
                            <InputText
                              name="tel"
                              :values="foremanPhoneNumber"
                              :editable="false"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <!-- 職種 -->
                          <Label label="職種" :editable="editable" />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <!-- 経験年数 -->
                          <Label label="経験年数" :editable="editable" />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row
                    no-gutters
                    v-for="item in userExperience"
                    :key="item.id"
                  >
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <InputText name="cmn_mst_skills_id" :values="item" />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <InputText
                            name="experience"
                            :values="item"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <!-- 作業時間帯 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label 
                            label="作業時間帯"
                            :editable="editable"
                            required
                          >
                            <Select
                              name="work_hour_zone"
                              :values="formValues"
                              :items="WORK_TIMEZONE_ARRAY"
                              :editable="editable"
                              validation_label="作業時間帯"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel label="使用ゲート" :editable="editable" />
                    </v-list-item-content>
                  </v-list-item>
                  <!-- ゲート -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="ゲート" :editable="editable" required>
                        <Select
                          name="gate_id"
                          :values="formValues"
                          :items="GATE_ARRAY"
                          :editable="editable"
                          validation_label="ゲート"
                          validation_rules="required"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <!-- 幅 -->
                          <Label label="幅" :editable="editable">
                            <InputText
                              name="width"
                              :values="gateDetail"
                              suffix="m"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <!-- 高さ -->
                          <Label label="高さ" :editable="editable">
                            <InputText
                              name="height"
                              :values="gateDetail"
                              suffix="m"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-list-item v-if="checkShow('truck_name')">
                    <v-list-item-content>
                      <!-- 車輌 -->
                      <Label label="車輌" :editable="editable" :required="checkRequired('truck_name')">
                        <InputText
                          name="truck_name"
                          :values="formValues"
                          :editable="editable"
                          placeholder="3tトラック"
                          validation_label="車輌"
                          :validation_rules="checkRequired('truck_name')?'required|max:255':'max:255'"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-row v-if="checkShow('truck_count')">
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <!-- 台数 -->
                          <Label label="台数" :editable="editable" :required="checkRequired('truck_count')">
                            <Select
                              name="truck_count"
                              :values="formValues"
                              :items="COUNT_ARRAY"
                              :editable="editable"
                              validation_label="台数"
                              :validation_rules="checkRequired('truck_count')?'required':''"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <!-- 荷種 -->
                  <v-list-item v-if="checkShow('luggage')">
                    <v-list-item-content>
                      <Label label="荷種" :editable="editable" :required="checkRequired('luggage')">
                        <InputTextArea
                          name="luggage"
                          :values="formValues"
                          :editable="editable"
                          validation_label="荷種"
                          :validation_rules="checkRequired('luggage')?'required|max:512':'max:512'"
                          @onInput="onInput"
                          placeholder="ここに荷種がはいります。"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import InputDatepicker from "@/components/forms/elements/InputDatepicker";
import Select from "@/components/forms/elements/Select";
import InputTextArea from "@/components/forms/elements/InputTextArea";
import { COUNT_ARRAY } from "@/constants/SCHEDULE_GATE";
import {
  OWNER_FLAG,
} from "@/constants/SCHEDULE_CRANE";
const IS_CUSTOMIZE = 1;

export default {
  data: () => {
    return {
      formValues: {},
      WORK_TIMEZONE_ARRAY: [],
      FIELD_CONSTRUCTION_ARRAY: [],
      FIELD_TREE_GROUP_ARRAY: [],
      COMPANY_PARTNER_ARRAY: [],
      FOREMAN_ARRAY: [{ id: null, name: "" }],
      GATE_ARRAY: [],
      COUNT_ARRAY: [{ id: null, name: "" },...COUNT_ARRAY],
      userExperience: [],
      gateDetail: {},
      foremanPhoneNumber: {
        tel: null
      },
      cmn_mst_skills: Store.state.CmnMst.constants.entries.cmn_mst_skills,
      isHideEndDate: false,
    };
  },
  components: {
    Label,
    SectionLabel,
    InputText,
    Select,
    InputDatepicker,
    InputTextArea,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    gatePassagesCustomize: Object,
    formUpdate: Function,
    isNewItem: Boolean,
  },
  mounted() {
    this.getListConstruction();

    this.$watch(
      () => Store.getters["PortalChart/getWorkTypes"],
      (data) => {
        this.FIELD_CONSTRUCTION_ARRAY = [this.getDefaultArrays("id", "name"),...data,];
        if (this.FIELD_CONSTRUCTION_ARRAY.length > 0) {
          if (!this.formValues["field_construction_id"]) {
            this.formValues["field_construction_id"] = this.FIELD_CONSTRUCTION_ARRAY[0].id;
          }
          if (this.formValues["owner_flag"] == OWNER_FLAG.OWNER) {
            if (!this.formValues["field_construction_id"]) {
              this.FIELD_TREE_GROUP_ARRAY = [this.getDefaultArrays("id", "group_name")];
              this.COMPANY_PARTNER_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name")];
              this.FOREMAN_ARRAY = [this.getDefaultArrays("id", "name")];
              this.WORK_TIMEZONE_ARRAY = [this.getDefaultArrays("id", "name")];
            } else {
              this.getListGroups();
              this.getWorkTimeZones();
              this.getFieldDirectors();
              this.getCompanyPartners();
            }
          } else {
            this.handleData();
          }
        }
      },
      {
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["Gates/getListGateByField"],
      (data) => {
        this.GATE_ARRAY = [...data];
        this.GATE_ARRAY.unshift(this.getDefaultArrays("id", "name"));
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getListGroup"],
      (data) => {
        this.FIELD_TREE_GROUP_ARRAY = [this.getDefaultArrays("id", "group_name"),...data];
        this.getCompanyPartners();
        this.getFieldDirectors();
      },
      {
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["PortalChart/getListCompanyPartner"],
      (data) => {
        this.COMPANY_PARTNER_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name"),...data];
        this.getListFieldTree();
      },
      {
        deep: true
      }
    );
    this.$watch(
      () => Store.getters["PortalChart/getListCompanyPartnerUser"],
      (data) => {
        const foremans = [this.getDefaultArrays("id", "name")];
        data.forEach((item) => {
          foremans.push({
            id: item.user_id,
            name: item.user_name_sei + " " + item.user_name_mei,
            user_experiences: item.user_experiences,
            tel: item.tel,
            state_handling_flag: item.state_handling_flag
          });
        });
        this.FOREMAN_ARRAY = foremans;
        if(this.formValues['foreman_user_id']) this.getUserExperiences();
      },
      {
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["PortalChart/getFieldTimeZones"],
      (data) => {
        if (this.formValues["field_construction_id"]) {
          this.WORK_TIMEZONE_ARRAY = [this.getDefaultArrays("id", "name"),...data];
          if (this.WORK_TIMEZONE_ARRAY.length > 0 && !this.formValues["work_hour_zone"]) {
            this.formValues["work_hour_zone"] = this.WORK_TIMEZONE_ARRAY[0].id;
          }
          this.handleTimeZone();
        }
      },
      {
        deep: true
      }
    );
    this.$watch(
      () => this.item,
      (data) => {
        this.formValues = { ...data };
        if(this.formValues.passage_date_from && !this.isHideEndDate) {
            this.formValues.passage_date_to = this.formValues.passage_date_from;
          }
        if (this.formValues["gate_id"]) {
          this.getGateDetail();
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => this.isHideEndDate,
      (data) => {
        if(!data){
          this.formValues.passage_date_to = this.formValues.passage_date_from
        }
      },
      {
        deep: true
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    foremanList() {
      return this.FOREMAN_ARRAY.filter(e => e.state_handling_flag === 0 || e.id === null);
    }
  },
  methods: {
    onInput({ name, value }) {
      if (name === 'passage_date_from' && !this.isHideEndDate) {
        this.formValues.passage_date_to = value;
      }
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      this.getDataByName(name);
      this.$emit("formUpdate", formValues);
    },
    getDataByName(name) {
      switch (name) {
        case "field_construction_id": {
          this.formValues.group_id = null;
          this.formValues.work_hour_zone = null;
          this.formValues.field_tree_id = null;
          this.formValues.foreman_user_id = null;
          this.userExperience = [];
          this.FIELD_TREE_GROUP_ARRAY = [this.getDefaultArrays("id", "group_name")];
          this.WORK_TIMEZONE_ARRAY = [this.getDefaultArrays("id", "name")];
          this.COMPANY_PARTNER_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name")];
          this.FOREMAN_ARRAY  = [this.getDefaultArrays("id", "name")];
          this.formValues.device_users = [
            {
              field_director_id: null,
            },
          ];
          if(this.formValues[name]) {
            this.getListGroups();
            this.getWorkTimeZones();
            this.getFieldDirectors();
            this.getCompanyPartners();
          }
          break;
        }
        case "group_id": {
          this.formValues.field_tree_id = null;
          this.formValues.foreman_user_id = null;
          this.userExperience = [];
          this.formValues.device_users = [{field_director_id: null}];
          this.COMPANY_PARTNER_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name")];
          this.FOREMAN_ARRAY  = [this.getDefaultArrays("id", "name")];
          this.getCompanyPartners();
          this.getFieldDirectors();
          break;
        }
        case "field_tree_id": {
          if (!this.formValues['group_id'] && this.formValues['field_tree_id']) {
            let groupId = this.getGroupdIdByFieldTree(this.formValues['field_tree_id']);
            let hasGroup = this.hasGroupById(groupId);
            if (hasGroup) {
              this.formValues['group_id'] = groupId;
              this.getCompanyPartners();
              this.getFieldDirectors();
            }
          }
          this.formValues.foreman_user_id = null;
          this.userExperience = [];
          this.FOREMAN_ARRAY  = [this.getDefaultArrays("id", "name")];
          if(this.formValues[name]) {
            this.getListFieldTree();
          }
          break;
        }
        case "foreman_user_id": {
          this.userExperience = [];
          if(this.formValues[name]) {
            this.getUserExperiences();
          }
          break;
        }
        case "gate_id": {
          this.getGateDetail();
          break;
        }
        case "work_hour_zone": {
          this.handleTimeZone(true);
          break;
        }
        default:
          break;
      }
    },
    async getListConstruction() {
      await Store.dispatch("PortalChart/getWorkTypes", this.CURRENT_SITE.field_id);
    },
    async getWorkTimeZones() {
      await Store.dispatch(
        "PortalChart/getFieldTimeZones",
        this.formValues["field_construction_id"]
      );
    },
    async getFieldDirectors() {
      let params = {state_handling_flag: 0};
      if (this.formValues["group_id"]) params.group_id = this.formValues["group_id"];
      else if (this.formValues["field_construction_id"]) params.field_construction_id = this.formValues["field_construction_id"];
      else return;
      await Store.dispatch("PortalChart/getFieldDirectorsObayashi", params);
    },
    async getListGroups() {
      await Store.dispatch(
        "PortalChart/getListGroups",
        this.formValues["field_construction_id"]
      );
    },
    async getCompanyPartners() {
      let params = {
        state_handling_flag: 0,
        display_branch_name_flag: 1,
      };
      if (this.formValues["group_id"]) params.field_tree_group_id = this.formValues?.group_id;
      else if (this.formValues["field_construction_id"]) params.field_construction_id = this.formValues.field_construction_id;
      else return;
      await Store.dispatch("PortalChart/getListCompanyPartner", {params});
    },
    async getListFieldTree() {
      if (this.formValues["field_tree_id"]) {
        const params = {
          id: this.formValues["field_tree_id"],
          foreman_flag: 1,
        };
        await Store.dispatch("PortalChart/getListCompanyPartnerUser", params);
      }
    },
    getUserExperiences() {
      let experiences = [];
      const foremanArray = this.FOREMAN_ARRAY.find((item) => {
        return item.id == this.formValues["foreman_user_id"];
      });
      experiences = foremanArray?.user_experiences;
      this.foremanPhoneNumber.tel = foremanArray?.tel;
      let array = [];
      experiences?.forEach((experience) => {
        let skill = this.cmn_mst_skills.find((element) => {
          return element?.id == experience.cmn_mst_skills_id;
        });
        if (skill) {
          array.push({
            cmn_mst_skills_id: skill.catergory + " " + skill.sub_catergory,
            experience: experience.experience,
          });
        }
      });
      this.userExperience = [...array];
    },
    getGateDetail() {
      if (!this.editable) {
        this.gateDetail = {
          width: this.formValues["gate_width"],
          height: this.formValues["gate_height"],
        };
      } else {
        const array = this.GATE_ARRAY.filter((item) => {
          return item.id == this.formValues["gate_id"];
        });
        this.gateDetail = array[0];
      }
    },
    handleTimeZone(isChange) {
      const array = this.WORK_TIMEZONE_ARRAY.find((item) => {
        return item.id == this.formValues["work_hour_zone"];
      });
      this.$emit("onChooseTimeZone", array, isChange);
    },
    getDefaultArrays(id, value) {
      let results = {};
      results[id] = null;
      results[value] = "";
      return results;
    },
    handleData() {
      this.FIELD_TREE_GROUP_ARRAY = [{id: this.formValues.group_id, group_name: this.formValues.group_name || ""}];
      this.COMPANY_PARTNER_ARRAY = [{field_tree_id: this.formValues.field_tree_id, company_name: this.formValues.field_tree_conpany_name}];
      this.getListFieldTree();
      this.getUserExperiences();
    },
    getStartDateRules() {
      return this.formValues?.passage_date_to && this.isHideEndDate
        ? "required|" +
            `compare-end-date:${String(this.formValues?.passage_date_to).replaceAll(
              "-",
              "/"
            )}`
        : "";
    },
    getEndDateRules() {
      return this.formValues?.passage_date_from
        ? "required|" +
            `compare-start-date:${String(this.formValues?.passage_date_from).replaceAll(
              "-",
              "/"
            )}`
        : "";
    },
    getGroupdIdByFieldTree(fieldTreeId) {
      return this.COMPANY_PARTNER_ARRAY.find(e => e.field_tree_id === fieldTreeId)?.field_tree_group_id || null;
    },
    hasGroupById(groupId) {
      if (!groupId) return false;
      let group = this.FIELD_TREE_GROUP_ARRAY.find(e => e.id === groupId);
      if (group) return true;
      return false;
    },
    /**
     * Check item requred
     */
    checkRequired(name) {
      return this.gatePassagesCustomize[name]?.required === IS_CUSTOMIZE;
    },
    
    /**
     * Check item show or hidden
     */
    checkShow(name) {
      return this.gatePassagesCustomize[name]?.form_view === IS_CUSTOMIZE;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
.FormDialog .container>.row>*, .FormDialog .container>.row>*>.row>* {
  padding: 12px!important;
}
</style>
