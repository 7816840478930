<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    :name="validation_label"
    :rules="validation_rules"
  >
    <v-textarea
      v-model="val"
      dense
      color="primary"
      :readonly="!editable"
      :filled="editable"
      :error-messages="errors"
      :success="valid"
      :placeholder="placeholder"
      :height="height"
      :auto-grow="flgCounter"
      @input="$emit('onInput', { name, value: val })" 
    >
      <template v-slot:append v-if="flgCounter">
        <span class="counter">
          {{ counter }}
        </span>
      </template>
    </v-textarea>
  </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
export default {
  components: {
    ValidationProvider,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    validation_rules: {
      type: String,
    },
    validation_label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default:""
    },
    flgCounter : {
      type : Boolean,
      default : false
    },
    counter : {
      type : String,
      default : null
    }
  },
  data: () => {
    return {
      val: "",
    };
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        const formValues = newValue[0];
        const name = newValue[1];
        if (formValues && name) this.val = formValues[name];
      },
      { immediate: true, deep: true }
    );
  },
};
</script>
<style lang="scss" scoped>
.counter {
  margin-top: 110px;
  color: #000000
}
::v-deep.v-textarea textarea {
  resize: none;
}
::v-deep .theme--light.v-text-field.v-input--is-readonly .v-input__slot:before {
  border-image: repeating-linear-gradient(90deg,rgba(0,0,0,.38) 0,rgba(0,0,0,.38) 2px,transparent 0,transparent 4px) 1 repeat;
}
::v-deep  .v-text-field.v-input--is-readonly>.v-input__control>.v-input__slot:after {
  background-color: unset !important;
  border-color: unset !important;
  border-style: unset !important;
  border-width: unset !important;
}
</style>
