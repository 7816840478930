<template>
  <div>
    <SingleLayout>
      <!-- <div class="login-header">入場/退場</div>
      <div class="headerSubtext">枠の中に顔が入るようにしてください</div> -->
      <v-form ref="form" autocomplete="off">
        <v-list-item>
          <v-list-item-content>
            <div id="frame" class="source">
              <div class="videoFrame">
                <video
                  ref="srcVideo"
                  id="srcVideo"
                  class="video"
                  playsinline
                  muted
                  autoplay
                />
              </div>
              <!-- 動画描画用 -->
              <div class="canvasFrame">
                <canvas id="video_shadow" class="video_shadow"> </canvas>
              </div>
              <!-- base64作成用 -->
              <div class="canvasFrame" style="visibility: hidden">
                <canvas id="canvas_shadow" class="video_shadow"> </canvas>
              </div>
              <div v-if="preview.base64" class="preview">
                <img :src="`${preview.base64}`" @click="resetPreview" />
              </div>
              <div v-if="isVideo" class="wireFrame"></div>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <div class="timeAndLocation">
              <span class="curretnTime">{{ currentTime }}</span>
              <v-icon
                color="primary"
                class="marker"
                :class="isLocation ? 'isLocation' : ''"
                >mdi-map-marker</v-icon
              >
            </div>
          </v-list-item-content>
        </v-list-item>
        <div class="footer">
          <v-btn @click="$router.go(-1)" text>やめる</v-btn>
          <div>
            <v-btn
              @click="enter(ENTRANCE_STATUS.ENTER.id)"
              color="primary"
              :disabled="!isVideo || !isLocation"
              text
              >入場</v-btn
            >
            <v-btn
              @click="enter(ENTRANCE_STATUS.LEAVE.id)"
              color="primary"
              :disabled="!isVideo || !isLocation"
              text
              >退場</v-btn
            >
          </div>
        </div>
      </v-form>
    </SingleLayout>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import SingleLayout from "@/components/layout/SingleLayout";
import { videoStream } from "./videoStream/index.js";
import { format } from "date-fns";

/**
 *
 *
[顔認証ログインapi]
エンドポイント:
/face_recognition/authorize
パラメータ:
params.put(“group_id”, group_id);  ?
params.put(“picture”, imageEncoded);顔画像 base64
params.put(“latitude”, lat);緯度
params.put(“longitude”, lon);経度
*
*
[入場退場api]
エンドポイント:
/face_recognition/authentication_logs
パラメータ:
params.put(“group_id”, group_id); ?
params.put(“person_id”, person_id); 顔認証ログインから取得したユーザid
params.put(“device_id”, device_id); ? 現場情報から取得したid
params.put(“entrance_status”, entrance_status);入場:1,退場:0
params.put(“entrance_picture”, entrance_picture);顔画像base64
params.put(“temperature”, temperature); 体温
*
*
 */

const ENTRANCE_STATUS = {
  ENTER: { id: 1, label: "入場" },
  LEAVE: { id: 2, label: "退場" },
};

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: "入場/退場" };
    },
  },
  components: {
    SingleLayout,
  },
  data: () => {
    return {
      preview: {
        base64: null,
      },
      passwordShow: false,
      //初回自社情報登録フォーム
      isShowCompanyInitDialog: false,
      //カメラON
      isVideo: false,
      //現在時刻
      currentTime: "0:00",
      //タイマー
      intervalId: null,

      //状態
      ENTRANCE_STATUS,

      //パラメータ
      params: {
        person_id: null,
        device_id: null,
        group_id: null,
        picture: null,
        latitude: null,
        longitude: null,
        entrance_status: null,
        entrance_picture: null,
        temperature: null,
      },
    };
  },
  async mounted() {
    this.getGeolocation();
    this.initVideo();
    this.intervalId = setInterval(() => {
      this.setCurrentTime();
    }, 1000 * 60);
    this.setCurrentTime();

    // [deplicated]
    // 顔認証にadminでログイン -> apiラッパーができたので廃止
    // const result = await Store.dispatch("FaceRecognition/init");
    // if (result.hasError) {
    //   Store.dispatch("Error/show", {
    //     status: 200,
    //     message: "顔認証の初期化に失敗しました",
    //   });
    // }

    this.$watch(
      () => [this.getFields, this.currentSite],
      (newValue) => {
        // console.log("field:", newValue[0], newValue[1]);
        if (newValue[0] && newValue[1]) {
          const { field_id } = newValue[1];
          const field = newValue[0].find((item) => {
            return item.field_id === field_id;
          });
          if (field && "person_id" in field) {
            const { person_id, field_id } = field;
            if (person_id) {
              let params = { ...this.params };
              params.person_id = person_id;
              params.group_id = field_id;
              this.params = params;
              console.log("person_id ", { ...this.params });
            }
          }
        }
      },
      { immediate: true, deep: true }
    );
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  computed: {
    user() {
      return Store.getters["Login/getUser"];
    },
    isLocation() {
      return this.params.latitude && this.params.longitude;
    },
    currentSite() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    getFields() {
      return Store.getters["Login/getFields"];
    },
  },
  methods: {
    initVideo() {
      videoStream({
        frameId: "frame",
        videoId: "srcVideo",
        canvasId: "video_shadow",
        detectScale: 1,

        //カメラ起動完了でコール
        readyCallback: async (video_info) => {
          this.video_info = video_info;
          this.isVideo = true;
        },
      });
    },

    setCurrentTime() {
      const date = new Date();
      this.currentTime = format(date, "HH:mm");
      // console.log("date", format(date, "HH:mm"));
    },

    getGeolocation() {
      //テストでダミーの緯度軽度を返す
      //三田３丁目11
      // const params = {
      //   ...this.params,
      //   ...{ latitude: "35.64112046427156", longitude: "139.74144039361778" },
      // };
      // this.params = params;

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const { latitude, longitude } = position.coords;
          const params = {
            ...this.params,
            ...{ latitude, longitude },
          };
          this.params = params;
        });
      } else {
        // エラー処理を書く
        // console.log("位置情報が取得できません");
        Store.dispatch("Error/show", {
          status: 200,
          message: "位置情報が取得できません",
        });
      }
    },

    getBase64FromCanvas() {
      const canvas = document.getElementById("video_shadow");
      const ctx = canvas.getContext("2d");
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

      const canvasShadow = document.getElementById("canvas_shadow");
      canvasShadow.width = canvas.width;
      canvasShadow.height = canvas.height;
      const ctxs = canvasShadow.getContext("2d");
      // ctx.save();
      // ctxs.translate(canvasShadow.width, 0);
      // ctxs.scale(-1, 1);
      ctxs.putImageData(imageData, 0, 0);
      // ctx.restore();

      console.log("size", canvas.width, canvasShadow.width);

      const mime_type = "image/jpeg";
      const data_url = canvasShadow.toDataURL(mime_type); // canvasからデータURLスキームを取得する。
      return data_url;
    },

    getBlobFromCanvas() {
      return new Promise((resolved) => {
        const canvas = document.getElementById("video_shadow");
        canvas.toBlob(function (result) {
          resolved(result);
        });
      });
    },

    resetPreview() {
      this.preview = { base64: null };
    },

    async enter(value) {
      const user = Store.state.Login.user;
      const selectedSite = Store.state.GlobalHeader.selectedSite;
      this.isVideo = false;
      // const blob = await this.getBlobFromCanvas();
      const base64 = this.getBase64FromCanvas();
      const _base64 = base64.split(",");
      this.preview = { base64 };
      const params = {
        ...this.params,
        ...{
          group_id: selectedSite.field_id, // "9191919"
          picture: _base64[1],
          temperature: "36.5",
          device_id: "sp",
          entrance_status: value,
          user_id: user.id,
        },
      };
      this.params = params;

      const res = await Store.dispatch("FaceRecognition/enter", params);
      if (res.hasError || "error" in res) {
        if ("error" in res) {
          let message = "";
          switch (res.error.type) {
            case "enter":
              message = `入場できませんでした`;
              break;
            case "leave":
              message = `退場できませんでした`;
              break;
            case "face":
              message = `顔認証できませんでした。顔のはっきり写っている顔を選択してください。`;
              break;
            case "location":
              message = `位置情報が不正です`;
              break;
          }
          Store.dispatch("Error/show", {
            status: 200,
            message,
          });
          setTimeout(() => {
            this.resetPreview();
            this.isVideo = true;
          }, 1000);
        }
      } else {
        const KEY = Object.keys(this.ENTRANCE_STATUS).find((key) => {
          return this.ENTRANCE_STATUS[key].id === value;
        });
        Store.dispatch("Toast/show", {
          status: 200,
          message: `${this.ENTRANCE_STATUS[KEY].label}しました`,
        });
        this.$router.push("/sp/schedule/works");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.login-header {
  font-size: 24px;
  font-weight: bold;
  padding: 16px;
  text-align: center;
  color: green;
}

.links {
  padding: 8px;
  text-align: center;
}

.source {
  position: relative;
  width: 275px;
  height: 325px;
}

.video {
  position: relative;
  left: 0;
  top: 0;
  z-index: 1;
  transform: scale(-1, 1);
  width: 100%;
  height: 100%;
  // visibility: hidden;
}

.headerSubtext {
  text-align: center;
  font-size: 14px;
  line-height: 16px;
}

.wireFrame {
  position: absolute;
  border: 1px solid black;
  width: 50%;
  height: 50%;
  left: 25%;
  top: 25%;
  z-index: 9;
}

.videoFrame {
  position: absolute;
  width: 100%;
  height: 100%;
  // visibility: hidden;
}

.canvasFrame {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  // visibility: hidden;
}

.video_shadow {
  position: absolute;
  left: 0;
  top: 0;
  width: 275px;
  z-index: 2;
  box-sizing: border-box;
}

.preview {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.timeAndLocation {
  display: flex;
  justify-content: center;
  align-items: center;
}

.curretnTime {
  font-size: 36px;
  width: 120px;
  display: block;
}
.marker {
  font-size: 36px !important;
  opacity: 0.3;
  filter: grayscale(1);
  &.isLocation {
    opacity: 1;
    filter: grayscale(0);
  }
}

.footer {
  width: 100%;
  height: 48px;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  box-sizing: border-box;
  border-top: 1px solid #efefef;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
