<template>
  <div class="approval">
    <div v-for="item in items" :key="item.id">
      <div v-if="item.id === value" :class="`bullet ${item.class}`">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
import { SCHDULE_APPROVE } from "@/constants/SCHEDULE_WORKS";

/**
 * css クラス名を定義
 */
const CLASSES = [
  { id: 0, class: "reject" },
  { id: 1, class: "approved" }
];
export default {
  props: {
    value: {
      type: Number,
      default: SCHDULE_APPROVE.REJECT.id
    }
  },
  computed: {
    items() {
      const items = Object.keys(SCHDULE_APPROVE).map((key) => {
        const className = CLASSES.find(
          (item) => item.id === SCHDULE_APPROVE[key].id
        );
        return { ...SCHDULE_APPROVE[key], ...className };
      });
      return items;
    },
    getClass() {
      return CLASSES.find((item) => item.id === this.value).class;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.approval {
  width: 48px;
  text-align: center;
}
.bullet {
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2px;
  color: white;
  transform: scale(0.8);
  padding: 0 2px;
}
.approved {
  background-color: $color_primary;
}
.reject {
  background-color: $color_warning;
}
</style>
