<template>
  <div ref="self">
    <v-toolbar flat>
      <v-toolbar-title class="worksHeader-title primary--text">
        {{pageTitle}}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="mx-2" small icon @click="openSearch">
        <v-icon> mdi-magnify </v-icon>
      </v-btn>
      <v-btn icon @click="$emit('openComfirmPrint')">
        <v-icon color="primary">mdi-printer-outline</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider class="mx-5 mb-3"></v-divider>
    <div v-show="isShowSearch">
      <slot :openSearch="openSearch" />
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
export default {
  data: () => {
    return {
      isShowSearch: false,
    };
  },
  props: {
    pageTitle: {
      type: String,
    },
    updateHeader: {
      type: Object,
    },
    //検索パラメータ
    searchParams: {
      type: Object,
      defauly: null,
    },
  },

  mounted() {
    setTimeout(() => {
      this.updateHeaderHeight();
    }, 200);

    //検索フォームの開閉
    this.$watch(
      () => Store.getters["ScheduleSearch/getActive"],
      (value) => {
        this.isShowSearch = value;
        this.$nextTick(() => {
          this.updateHeaderHeight();
        });
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  methods: {
    //検索パラメータを更新
    onChangeSearchParams({ name, value }) {
      this.$emit("onInput", { name, value });
    },
    openSearch() {
      //検索フォームを開く
      Store.dispatch("ScheduleSearch/show", !this.isShowSearch);
      this.$nextTick(() => {
        this.updateHeaderHeight();
      });
    },
    updateHeaderHeight() {
      const height = this.$refs.self.getBoundingClientRect().height;
      this.updateHeader.setHeaderHeight(height);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.v-application--is-ltr .v-btn__content .v-icon--right,
.v-application--is-rtl .v-btn__content .v-icon--left {
  margin-left: 0px;
  margin-right: 0px;
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 56px;
  padding: 0 8px;
  box-sizing: border-box;
}

.footer__btn {
  .footer__btn-icon {
    fill: $color_warning;
  }
  &.enable {
    .footer__btn-icon {
      fill: rgba(0, 0, 0, 0.26);
    }
  }
}

.label {
  font-size: 8px;
}

.v-toolbar__title.worksHeader-title {
  font-size: 24px !important;
  font-weight: 600 !important;
}
</style>
