<template>
  <FormDialog>
    <template #header>
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title style="color: #1b9c4f">
          持込機械等使用届（電動工具・電気溶接機等）
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in TABLE_TAB_NAME_4" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <InformationMachineForm1 :mainHeight="params.mainHeight" :items="items" />
        </v-tab-item>
      </v-tabs-items>
    </template>
  </FormDialog>
</template>

<script>
import { Store } from "@/store/Store.js";
import FormDialog from "@/components/dialog/FormDialog.vue";
import InformationMachineForm1 from "./InformationMachineForm1.vue";
import { TABLE_TAB_NAME_4 } from "@/constants/DEVICE_CARRY_ON";
import _ from "lodash";

const STORE = "DeviceCarryOns";

export default {
  data: () => {
    return {
      tab: null,
      TABLE_TAB_NAME_4,
      items: [],
    };
  },
  components: {
    FormDialog,
    InformationMachineForm1,
  },
  mounted() {
    this.$watch(
      () => Store.getters[`${STORE}/getListMachineElectricalTools`],
      (data) => {
        this.items = _.cloneDeep(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onClickBtnClose() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
