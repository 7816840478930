<template>
  <v-card class="dialog">
    <div class="header sticky">
      <v-card-title small>
        <div class="title" style="font-size: 20px !important">{{ title }}</div>
        <v-spacer></v-spacer>
        <v-btn small icon text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
    </div>
    <!-- 検索コンポーネント -->
    <SearchList
      :formValues="formValues"
      @search="search"
      @formUpdate="formUpdate"
      :validate-rule="validateRule"
      searchLabel="会社名"
    >
      <v-list
        two-line
        style="min-height: 300px; max-height: 550px; overflow-y: auto"
        id="infinite-login-company-list"
      >
        <div v-if="formValues.items.length > 0">
          <template v-for="(item, index) in formValues.items">
            <v-list-item :key="index" ripple>
              <v-radio-group v-model="itemSelected">
                <v-radio
                  :key="item.company_id"
                  :value="item.company_id"
                   @click="setSelected(item.company_id)"
                ></v-radio>
              </v-radio-group>
              <v-list-item-content>
                <v-list-item-title class="company_name">
                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ item.address }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </div>
        <div v-else-if="isNoDataMessage" style="text-align: center; padding-top: 20px">
          <span class="no_data_message">{{ NO_DATA_MESSAGE }}</span>
        </div>
      </v-list>
      <v-divider></v-divider>
      <transition name="fade">
        <div class="button-group">
          <v-row no-gutters>
            <v-col cols="3" sm="2" class="pt-5 pl-5">
              <v-btn color="primary" @click="$emit('close', true)">新規作成</v-btn>
            </v-col>
            <v-col cols="6" sm="8" class="pa-5"></v-col>
            <v-col cols="3" sm="2" class="pt-5 pr-5 text-right">
              <v-btn color="primary" @click="onClick()" v-if="this.itemSelected">確定</v-btn>
            </v-col>
          </v-row>
        </div>
      </transition>
      <transition name="fade">
        <div class="loading" v-show="loading">
          <span class="fa fa-spinner fa-spin"></span>Loading
        </div>
      </transition>
    </SearchList>
  </v-card>
</template>

<script>
import SearchList from "@/components/forms/elements/SearchList.vue";
import { NO_DATA_MESSAGE } from "@/constants/COMMON.js";
import { Store } from "@/store/Store.js";

export default {
  components: {
    SearchList,
  },

  props: {
    title: String,
    formValues: {
      type: Object,
      default: {
        // 検索ワード
        keyword: "",
        // 表示するリスト
        items: [],
        // 選択項目id
        selected: null,
      },
    },
    onSearch: Function,
    close: Function,
    isNoDataMessage: Boolean,
  },

  data: () => {
    return {
      keyword: "",
      NO_DATA_MESSAGE,
      isReset: false,
      loading: false,
      validateRule: "",
      itemSelected: null
    };
  },
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    this.$watch(
      () => Store.getters["CompanyPortal/getListCompanyAll"],
      (newValue) => {
        if (newValue?.length == 0) {
          this.isReset = true;
        } else {
          this.isReset = false;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.checkScroll();
  },
  computed: {
    isCallAPI() {
      const paging = Store.getters["CompanyPortal/getPaginationCompany"];
      return (
        this.formValues.current_page < paging.total && paging.total_item != 0
      );
    },
  },
  methods: {
    // 検索(propsで渡すだけ)
    search() {
      this.validateRule = "search-value-require";
      this.$emit("onSearch", { keyword: this.keyword });
    },
    formUpdate(values) {
      this.keyword = values.keyword;
    },
    // idの更新
    onClick() {
      this.$emit("formUpdate", this.itemSelected);
    },
    setSelected(id) {
      this.itemSelected = id;
    },
    checkScroll() {
      const listElm = document.getElementById("infinite-login-company-list");
      listElm.addEventListener("scroll", () => {
        if ((listElm.scrollTop + listElm.clientHeight >= (listElm.scrollHeight - 1)
          && this.isCallAPI && !this.isReset)) {
          this.loadMore();
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        }
      });
    },
    loadMore() {
      this.$emit("onSearch", { isLoadMore: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .header {
    height: 65px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    .header-title-area {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      max-width: 100%;
    }
  }
}

.title {
  font-weight: bold !important;
  color: #1b9c4f;
}

.company_name {
  font-size: 16px !important;
  font-weight: bold !important;
}
.no_data_message {
  font-size: 16px !important;
}
.loading {
  text-align: center;
  position: absolute;
  color: #fff;
  z-index: 9;
  background: gray;
  padding: 8px 18px;
  border-radius: 5px;
  left: calc(50% - 45px);
  top: calc(50% - 18px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
