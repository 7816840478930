<!-- eslint no-irregular-whitespace: "error" -->
<template>
  <div class="license">
    <div class="license_main">資格・記号一覧</div>
    <div class="license_main--sub">主な資格の種類と記号</div>
    <table border="1" style="border-collapse: collapse; border-color: rgba(0,0,0,.12);">
      <thead class="lcs-data-table__header-cell">
        <tr class="lcs-data-table__row">
          <th>記号</th>
          <th>資格の種類</th>
        </tr>
      </thead>
      <tbody>
        <tr class="lcs-data-table__row">
          <th class="tag">〈足〉</th>
          <td class="lcs-data-table__cell">足場組立等作業主任者</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">足</th>
          <td class="lcs-data-table__cell">足場組立等作業 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈型〉</th>
          <td class="lcs-data-table__cell">型枠支保工組立等作業主任者</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈鉄〉</th>
          <td class="lcs-data-table__cell">建築物等鉄骨組立等作業主任者</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈地〉</th>
          <td class="lcs-data-table__cell">地山の掘削作業主任者</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈土〉</th>
          <td class="lcs-data-table__cell">土止め支保工作業主任者</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈掘〉</th>
          <td class="lcs-data-table__cell">ずい道等掘削作業主任者</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈覆〉</th>
          <td class="lcs-data-table__cell">ずい道等履工作業主任者</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">ず</th>
          <td class="lcs-data-table__cell">ずい道内作業 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈コ〉</th>
          <td class="lcs-data-table__cell">コンクリート造工作物解体等 作業主任者</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈架〉</th>
          <td class="lcs-data-table__cell">鋼橋架設等作業主任者</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈は〉</th>
          <td class="lcs-data-table__cell">はい作業主任者</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">粉</th>
          <td class="lcs-data-table__cell">特定粉じん作業 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">【ガ】</th>
          <td class="lcs-data-table__cell">ガス溶接作業主任者</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈ガ〉</th>
          <td class="lcs-data-table__cell">ガス溶接 技能教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">ア</th>
          <td class="lcs-data-table__cell">アーク溶接 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈車〉</th>
          <td class="lcs-data-table__cell">車両系建設機械（整地・運搬・積込み 用及び掘削用）3t以上技能講習</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">車</th>
          <td class="lcs-data-table__cell">車両系建設機械 3t未満 特別教育（整地・運搬・積込み 用及び掘削用）</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈基〉</th>
          <td class="lcs-data-table__cell">車両系建設機械（基礎工事用）3t以上 技能講習</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">基</th>
          <td class="lcs-data-table__cell">車両系建設機械 3t未満 特別教育（基礎工事用）</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">操</th>
          <td class="lcs-data-table__cell">基礎工事用機械装置 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">ポ</th>
          <td class="lcs-data-table__cell">コンクリートポンプ車 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈解〉</th>
          <td class="lcs-data-table__cell">解体用機械 3t以上 技能講習</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">解</th>
          <td class="lcs-data-table__cell">解体用機械 3t未満 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">締</th>
          <td class="lcs-data-table__cell">締固め用機械 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈不〉</th>
          <td class="lcs-data-table__cell">不整地運搬車1t以上 技能講習</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">不</th>
          <td class="lcs-data-table__cell">不整地運搬車1t未満 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈高〉</th>
          <td class="lcs-data-table__cell">高所作業車10ｍ以上 技能講習</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">高</th>
          <td class="lcs-data-table__cell">高所作業車10ｍ未満 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">【ク】</th>
          <td class="lcs-data-table__cell">クレーン運転士</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">ク</th>
          <td class="lcs-data-table__cell">クレーン運転士 5t未満 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">【移】</th>
          <td class="lcs-data-table__cell">移動式クレーン運転士 5t以上</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈移〉</th>
          <td class="lcs-data-table__cell">移動式クレーン運転士 1t以上5t未満 技能講習</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">移</th>
          <td class="lcs-data-table__cell">移動式クレーン運転士 1t未満 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈床〉</th>
          <td class="lcs-data-table__cell">床上操作式クレーン 5t以上 技能講習</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈フ〉</th>
          <td class="lcs-data-table__cell">フォークリフト 1t以上 技能講習</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈シ〉</th>
          <td class="lcs-data-table__cell">ショベルローダー 1t以上 技能講習</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈玉〉</th>
          <td class="lcs-data-table__cell">玉掛 技能講習</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">巻</th>
          <td class="lcs-data-table__cell">動力巻上機 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">軌</th>
          <td class="lcs-data-table__cell">軌道装置動力車 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">研</th>
          <td class="lcs-data-table__cell">研削といし 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">丸</th>
          <td class="lcs-data-table__cell">丸のこ等取扱作業従事者教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">高電</th>
          <td class="lcs-data-table__cell">高圧電気取扱 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">低電</th>
          <td class="lcs-data-table__cell">低圧電気取扱 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">【発】</th>
          <td class="lcs-data-table__cell">発破技士</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">伐</th>
          <td class="lcs-data-table__cell">立木の伐採 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">ロ</th>
          <td class="lcs-data-table__cell">ロープ高所作業 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">【圧】</th>
          <td class="lcs-data-table__cell">高圧室内作業主任者</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">圧</th>
          <td class="lcs-data-table__cell">高気圧業務 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">空</th>
          <td class="lcs-data-table__cell">空気圧縮機 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">加</th>
          <td class="lcs-data-table__cell">加圧・減圧 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">送</th>
          <td class="lcs-data-table__cell">送気・排気調節 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">再</th>
          <td class="lcs-data-table__cell">再圧室操作 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">【潜】</th>
          <td class="lcs-data-table__cell">潜水士</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">潜送</th>
          <td class="lcs-data-table__cell">潜水作業者への送気 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈酸〉</th>
          <td class="lcs-data-table__cell">酸素欠乏危険作業主任者</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈酸・硫〉</th>
          <td class="lcs-data-table__cell">酸素欠乏・硫化水素危険作業主任者</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">酸</th>
          <td class="lcs-data-table__cell">酸素欠乏危険作業 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">酸・硫</th>
          <td class="lcs-data-table__cell">酸素欠乏・硫化水素危険作業 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈有〉</th>
          <td class="lcs-data-table__cell">有機溶剤作業主任者</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈特〉</th>
          <td class="lcs-data-table__cell">特定化学物質等作業主任者</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈石〉</th>
          <td class="lcs-data-table__cell">石綿作業主任者 技能講習</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">石</th>
          <td class="lcs-data-table__cell">石綿作業 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">除指</th>
          <td class="lcs-data-table__cell">除染等業務作業指揮者 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">除</th>
          <td class="lcs-data-table__cell">除染等業務作業 特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">〈鉛〉</th>
          <td class="lcs-data-table__cell">鉛作業主任者 技能講習</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">フル</th>
          <td class="lcs-data-table__cell">フルハーネス型安全帯使用作業特別教育</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">【電一】</th>
          <td class="lcs-data-table__cell">第一種電気工事士</td>
        </tr>
        <tr class="lcs-data-table__row">
          <th class="tag">【電二】</th>
          <td class="lcs-data-table__cell">第二種電気工事士</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: "資格・記号一覧" };
    },
  },
}
</script>
<style scoped>
body {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
  background-color: white;
  color: black;
}

.license {
  max-width: 1200px;
  margin: 48px auto;
}

.license_main {
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: 70px;
  letter-spacing: 0em;
  text-align: left;
  color: #1b9c4f;
  margin-bottom: 64px;
}

.lcs-data-table__header-cell {
  color: rgba(0, 0, 0, .6);
}

.lcs-data-table__row {
  height: 48px;
}

.tag {
  text-align: center;
  padding: 8px 16px;
}

.lcs-data-table__cell {
  padding: 8px 16px;
}

.license_main--sub {
  color: black;
  font-size: 22px;
  font-weight: 600;
}

@media (max-width: 414px) {
  .license {
    max-width: 414px;
    padding: 20px;
  }

  .license_main {
    font-size: 48px;
  }
}
</style>