import Api, { Mock } from "../api";
import list_machine_field from "./mock/list_machine_field.json";
import list_report from "./mock/list_report.json";
import list_machine_detail from "./mock/list_detail_machine.json";
import list_electrial_tools from "./mock/list_electrial_tools.json";

const DEVICE_CARRY_ON_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/machine_field_reports`;
const GET_LIST_URL = `${DEVICE_CARRY_ON_BASE_URL}/list`;
const GET_LIST_REPORT_URL = `${DEVICE_CARRY_ON_BASE_URL}/list_report`;
const CREATE_MACHINE_FIELD_REPORT_URL = `${DEVICE_CARRY_ON_BASE_URL}/create_reports`;
const UPDATE_APROVAL_URL = `${DEVICE_CARRY_ON_BASE_URL}/update_status`;
const DELETE_REPORT_URL = `${DEVICE_CARRY_ON_BASE_URL}/delete_report`;
const GET_MACHINE_FIELD = `${DEVICE_CARRY_ON_BASE_URL}/detail`;
const GET_MACHINE_ELECTRICAL_TOOL = `${DEVICE_CARRY_ON_BASE_URL}/list_electrical_tools`;
const UPDATE_MACHINE_FIELD = `${DEVICE_CARRY_ON_BASE_URL}/update`;
const GET_LIST_OWNER_COMPANY_USER_URL = `${DEVICE_CARRY_ON_BASE_URL}/list_owner_company_user`;
const GET_DRIVER_INFO_URL = `${DEVICE_CARRY_ON_BASE_URL}/driver_info`;


if (Mock) {
  Mock.onGet(GET_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: list_machine_field,
  });
  Mock.onGet(GET_LIST_REPORT_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: list_report,
  });
  Mock.onGet(new RegExp(`${GET_MACHINE_FIELD}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: list_machine_detail,
  });
  Mock.onGet(new RegExp(`${GET_MACHINE_ELECTRICAL_TOOL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: list_electrial_tools,
  });
  Mock.onPost(CREATE_MACHINE_FIELD_REPORT_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(UPDATE_APROVAL_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(UPDATE_MACHINE_FIELD).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(GET_LIST_OWNER_COMPANY_USER_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries : [
        {
          user_id: 1000,
          name_sei: "美羽 ",
          name_mei: "林",
        },
        {
          user_id: 2000,
          name_sei: "小林",
          name_mei: "松本",
        },
      ]
    },
  });
}

export const deviceCarryOns = {
  get: (params) => {
    return Api.get(GET_LIST_URL, params);
  },
  getMachineFieldDetail: (params) => {
    return Api.get(`${GET_MACHINE_FIELD}/${params}`);
  },
  getListMachineElectricalTools: (params) => {
    return Api.get(`${GET_MACHINE_ELECTRICAL_TOOL}/${params}`);
  },
  getReport: (params) => {
    return Api.get(GET_LIST_REPORT_URL, params);
  },
  createMachineFieldReport: async (params) => {
    return Api.post(CREATE_MACHINE_FIELD_REPORT_URL, params);
  },
  delete: async (params) => {
    return Api.post(DELETE_REPORT_URL, params);
  },
  updateStatus: async (params) => {
    return Api.post(UPDATE_APROVAL_URL, params);
  },
  update: async (params) => {
    return Api.post(UPDATE_MACHINE_FIELD, params);
  },
  getListOwnerCompanyUser: async (params) => {
    return Api.get(GET_LIST_OWNER_COMPANY_USER_URL, params);
  },
  getDriverInfo: async (params) => {
    return Api.get(`${GET_DRIVER_INFO_URL}/${params}`);
  },
};
