<template>
  <div>
    <div id="header" :class="{'mh-90': ENV_OBAYASHI}">
      <v-row class="align-center mh-108">
        <v-col cols="6" sm="6" md="6">
          <div class="d-flex align-center">
            <span id="title" :class="isNewItem ? 'title-item-add' : 'title-item-edit'">{{ title }}</span>
            <span :class="!isNewItem ? 'btn-action' : ''" v-if="APPROVED && !editable && (ROLE_MAIN_USER_EDIT || ROLE_SUBCONSTRACTOR_USER_EDIT_SHITEKI)">
              <v-list-item>
                <v-list-item-content>
                  <v-btn
                    color="warning"
                    depressed
                    width="90px"
                    @click="onRejectAfterApprove"
                    small
                  >
                    承認解除
                  </v-btn>
                </v-list-item-content>
              </v-list-item>
            </span>
            <span :class="!isNewItem ? 'btn-action' : ''" v-if="REJECTED && !editable && (ROLE_MAIN_USER_EDIT || ROLE_SUBCONSTRACTOR_USER_EDIT_SHITEKI)">
              <v-list-item>
                <v-list-item-content>
                  <v-btn
                    color="warning"
                    depressed
                    width="150px"
                    @click="onEditMessage"
                    small
                  >
                    否認通知の修正
                  </v-btn>
                </v-list-item-content>
              </v-list-item>
            </span>
            <span :class="!isNewItem ? 'btn-action' : ''" v-if="!editable && CORRECTED && (ROLE_MAIN_USER_EDIT || ROLE_SUBCONSTRACTOR_USER_EDIT_SHITEKI)">
              <v-list-item>
                <v-list-item-content>
                  <v-btn
                    color="primary"
                    depressed
                    width="60px"
                    @click="onApprove(safetyDiaryCommentCorrectionsValues.recovery_flag)"
                    small
                  >
                    承認
                  </v-btn>
                </v-list-item-content>
              </v-list-item>
            </span>
            <span :class="!isNewItem ? 'btn-action' : ''" v-if="!editable && CORRECTED && (ROLE_MAIN_USER_EDIT || ROLE_SUBCONSTRACTOR_USER_EDIT_SHITEKI)">
              <v-list-item>
                <v-list-item-content>
                  <v-btn
                    color="warning"
                    depressed
                    width="60px"
                    @click="onRejectAfterRecovery"
                    small
                  >
                    否認
                  </v-btn>
                </v-list-item-content>
              </v-list-item>
            </span>
            <span :class="!isNewItem ? 'btn-action' : ''" v-if="editable && !isNewItem">
              <v-list-item>
                <v-list-item-content>
                  <v-btn
                    :disabled="!RECOVERY"
                    color="primary"
                    depressed
                    width="60px"
                    @click="onRecovery"
                    small
                  >
                    報告
                  </v-btn>
                </v-list-item-content>
              </v-list-item>
            </span>
          </div>
        </v-col>
        <v-col v-if="!isNewItem" cols="5" sm="5" md="5" class="ml-6" :class="{'padding-obayashi':ENV_OBAYASHI}" max-height="108px">
          <v-list-item>
            <v-list-item-content>
              <Label label="是正者" :editable="editable">
                <InputText
                  name="recovery_user_name"
                  :values="safetyDiaryCommentCorrectionsValues"
                  :editable="false"
                />
              </Label>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-row :class="{ 'min-screen': ENV_OBAYASHI }">
        <v-col>
          <div class="ps-a status-recovery" v-if="!isNewItem">
            <span>
              <v-chip :color="getColor(safetyDiaryCommentCorrectionsValues.recovery_flag, safetyDiaryCommentCorrectionsValues.status_code)">
                <span style="color:white;font-size: 11px">{{
                    getText(safetyDiaryCommentCorrectionsValues.recovery_flag, safetyDiaryCommentCorrectionsValues.status_code)
                  }}</span>
              </v-chip>
            </span>
          </div>
          <div class="ps-a show-message" v-if="!isNewItem && safetyDiaryCommentCorrectionsValues.message_reject">
            <v-btn @click="openMessageReject" depressed icon>
              <v-icon>mdi-message-reply-text-outline</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col sm="8" :class="{'size-image-obayashi': ENV_OBAYASHI}">
          <v-img
            v-if="!isNewItem"
            :aspect-ratio="41/30"
            :src="mainImage.src"
            contain
            @click="openShowImageDialog"
          >
          </v-img>
          <v-img
            :aspect-ratio="41/30"
            :src="noImage"
            contain
            v-else
          >
          </v-img>
        </v-col>
        <v-col sm="2" class="image-action">
          <div class="scrollbar mb-3" v-if="!isNewItem" :class="{ 'aspect-ratio-obayashi': ENV_OBAYASHI }">
            <div
              v-for="(image, index) in dataItems" :key="index" class="mb-3 image-box"
            >
              <v-checkbox
                v-if="(editable && isNewItem) || (editable && !APPROVED && (ROLE_MAIN_USER_EDIT || ROLE_SUBCONSTRACTOR_USER_EDIT))"
                color="green"
                :value="checkValue(index)"
                background-color="#fff"
                hide-details
                :class="index == indexOfSelectedImages ? 'checkbox checkbox-image-active' : 'checkbox checkbox-image'"
                @change="updateCheckbox(index,$event)"
              ></v-checkbox>
              <v-img
                :class="{'image-selected': index == indexOfSelectedImages}"
                :aspect-ratio="1/1"
                :src="image.image_data? image.image_data: (image.image_thumb_web_url? getUrl(image.id): '')"
                contain
                @click="selectImage(image.image_data? image.image_data: (image.image_url? getUrlMain(image.id): ''), index)"
              >
                <div class="fill-height bottom-gradient"></div>
              </v-img>
            </div>
          </div>
          <div id="action" :class="!this.editable ? 'action-preview' : 'action-edit'" v-if="!isNewItem">
            <div class="add-image text-center">
              <p
              v-if="(editable && isNewItem) || (editable && !APPROVED && (ROLE_MAIN_USER_EDIT || ROLE_SUBCONSTRACTOR_USER_EDIT))"
                class="mb-0"
              >
                写真追加
              </p>
              <v-btn 
              v-if="(editable && isNewItem) || (editable && !APPROVED && (ROLE_MAIN_USER_EDIT || ROLE_SUBCONSTRACTOR_USER_EDIT))"
              :disabled="disable(editable, APPROVED, ROLE_MAIN_USER_EDIT, ROLE_SUBCONSTRACTOR_USER_EDIT)" 
              depressed 
              icon
              :class="{'mb-5': !ENV_OBAYASHI, 'mb-obayashi': ENV_OBAYASHI }"
              @click="popups.isShowUploadImageForm = true">
                <v-icon>mdi-image-plus-outline</v-icon>
              </v-btn>
            </div>
            <div class="delete-image text-center" v-if="editable">
              <p
              v-if="(editable && isNewItem) || (editable && !APPROVED && (ROLE_MAIN_USER_EDIT || ROLE_SUBCONSTRACTOR_USER_EDIT))"
                class="mb-0"
              >
                削除
              </p>
              <v-btn
              v-if="(editable && isNewItem) || (editable && !APPROVED && (ROLE_MAIN_USER_EDIT || ROLE_SUBCONSTRACTOR_USER_EDIT))"
                depressed
                icon
                :disabled="arrIndexImageChoose.length === 0 || disable(editable, APPROVED, ROLE_MAIN_USER_EDIT, ROLE_SUBCONSTRACTOR_USER_EDIT)"
                @click="removeImage"
              >
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col sm="1" v-if="!isNewItem"></v-col>
      </v-row>
    </div>
    <div class="comment-right">
      <v-row>
        <v-col
          sm="11"
          :class="[(!isNewItem && editable) ? 'pt-0 pb-0' : '', (ENV_OBAYASHI && (isNewItem || !editable)) ? 'custom-obayashi-new' : '' ]"
        >
          <Label v-if="!isNewItem" label="内容" :class="!this.editable ? 'textarea-preview' : 'textarea-edit'">
            <InputTextArea
              name="recovery_content"
              :values="safetyDiaryCommentCorrectionsValues"
              :editable="editable && !APPROVED && (ROLE_MAIN_USER_EDIT || ROLE_SUBCONSTRACTOR_USER_EDIT)"
              @onInput="onInput"
              :height="!ENV_OBAYASHI ? (screen.width <= 1600 ? '80' : '') : (screen.width <= 1600 ? '153px' : '220px')"
              :customizeFontSize="ENV_OBAYASHI ? true : false"
              :countRow="ENV_OBAYASHI ? 7 : 5"
            />
          </Label>
          <Label class="textAreaDisabled"  :class="{'custom-obayashi-new': ENV_OBAYASHI}" v-else label="">
            <v-textarea
              value=""
              :disabled="true"
              auto-grow
              outlined
              :height="!ENV_OBAYASHI ? (screen.width <= 1600 ? '80px' : '146px') : (screen.width <= 1600 ? '153px' : '220px')"
              color="primary"
              :rows="ENV_OBAYASHI ? 7 : 5"
            ></v-textarea>
          </Label>
        </v-col>
      </v-row>
    </div>
    <Popup width="480px" :dialog="popups.isShowUploadImageForm">
      <UploadImageDialog
        :countImage="countImage"
        @close="closeUploadImageDialog"
        @yes="submitUploadImage"
      />
    </Popup>
    <Popup width="60vw" :dialog="popups.isShowImageDialog">
      <ShowImageDialog
        @closeShowImageDialog="closeShowImageDialog"
        :indexOfSelectedImages="indexOfSelectedImages"
        :listUrlImage="listUrlImage"
      />
    </Popup>
  </div>
</template>

<script>
import Select from "@/components/forms/elements/Select";
import InputTextArea from "@/views/schedule/patrols/components/InputTextArea";
import InputText from "@/components/forms/elements/InputText";
import UploadImageDialog from "@/components/forms/schedule/patrols/components/UploadImageDialog.vue";
import Popup from "@/components/common/Popup";
import Label from "@/components/forms/elements/Label.vue";
import {getParamsOfUrlReadFile} from "@/utils/viewSourceAuth";
import {Store} from "@/store/Store";
import {NO_IMAGE} from "@/constants/PATROL_RECORD";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
import ShowImageDialog from "@/views/schedule/patrols/components/ShowImageDialog";

export default {
  data: () => {
    return {
      arrIndexImageChoose: [],
      mainImage: {
        src: null,
      },
      indexOfSelectedImages: null,
      popups: {
        isShowUploadImageForm: false,
        isShowImageDialog: false
      },
      safetyDiaryCommentCorrectionsValues: {},
      dataItems: [],
      sourceImages: [],
      sourceImagesMain: [],
      noImage: NO_IMAGE,
      recoveryUser: [],
      countImage: 0,
      isLoadingImage: true,
      screen: {},
      ENV_CLIENT,
      sourceOriginImages: [],
      listUrlImage: []
    }
  },
  props: {
    title: String,
    lableSelect: {
      type: Object
    },
    valueSelect: {
      type: Array
    },
    content: {
      type: Object
    },
    comment: {
      type: Object
    },
    editable: Boolean,
    isNewItem: Boolean,
    formValues: Object,
    numberImageAuto: {
      type: Boolean,
      default: false,
    },
    backPreview: Boolean,
    type: Number,
    recordId: Number,
  },
  components: {
    Select,
    InputTextArea,
    UploadImageDialog,
    Popup,
    Label,
    InputText,
    ShowImageDialog
  },
  async mounted() {
    this.screen = screen;
    if (this.mainImage.src == null) {
      this.indexOfSelectedImages = 0;
    }
    this.$watch(
      () => this.formValues,
      (data) => {
        if (Array.isArray(data.safety_diary_comment_corrections)) {
          this.safetyDiaryCommentCorrectionsValues = data.safety_diary_comment_corrections[0];
          if (!this.APPROVED && (this.ROLE_MAIN_USER_EDIT || this.ROLE_SUBCONSTRACTOR_USER_EDIT)) {
            this.recoveryUser = [
              {
                id: `${this.USER_LOGIN.name_sei} ${this.USER_LOGIN.name_mei}`,
                item_name: `${this.USER_LOGIN.name_sei} ${this.USER_LOGIN.name_mei}`
              }
            ];
          }
          if (this.safetyDiaryCommentCorrectionsValues.recovery_flag === 0) {
            this.safetyDiaryCommentCorrectionsValues.recovery_user_name = null;
          }
          this.dataItems = this.safetyDiaryCommentCorrectionsValues.safety_diary_images.filter(e => e.type == this.type);
          this.initiateImageSource();
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => this.dataItems,
      (data) => {
        this.countImage = data.length;
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => this.backPreview,
      (data) => {
        if (data) {
          this.mainImage.src = this.dataItems[0]?.image_data ? this.dataItems[0]?.image_data : (this.dataItems[0]?.image_url ? this.getUrlMain(this.dataItems[0]?.id) : NO_IMAGE);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => [this.countImage, this.recordId],
      (data) => {
        if (!data[0]) {
          this.mainImage.src = this.noImage;
        } else {
          this.mainImage.src = this.dataItems[0]?.image_data ? this.dataItems[0]?.image_data : (this.dataItems[0]?.image_url ? this.getUrlMain(this.dataItems[0]?.id) : "");
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  computed: {
    APPROVED() {
      return this.safetyDiaryCommentCorrectionsValues.status_code === 1 &&
        this.safetyDiaryCommentCorrectionsValues.recovery_flag === 1 ;
    },
    RECOVERY() {
      return (
        (this.ROLE_MAIN_USER_EDIT || this.ROLE_SUBCONSTRACTOR_USER_EDIT) &&
        (this.safetyDiaryCommentCorrectionsValues.status_code === 0 &&
          this.safetyDiaryCommentCorrectionsValues.recovery_flag === 0) ||
        (this.safetyDiaryCommentCorrectionsValues.status_code === 2 &&
          this.safetyDiaryCommentCorrectionsValues.recovery_flag === 1)
      );
    },
    CORRECTED() {
      return (this.safetyDiaryCommentCorrectionsValues.status_code === 0 &&
        this.safetyDiaryCommentCorrectionsValues.recovery_flag === 1)
    },
    REJECTED() {
      return (this.safetyDiaryCommentCorrectionsValues.status_code === 2 &&
        this.safetyDiaryCommentCorrectionsValues.recovery_flag === 1)
    },
    ROLE_MAIN_USER_EDIT() {
      let userRole = Store.getters["Report/getUserRole"];
      return (!this.isNewItem && userRole?.role === 1);
    },
    ROLE_SUBCONSTRACTOR_USER_EDIT_SHITEKI() {
      let userRole = Store.getters["Report/getUserRole"];
      let companyUser = JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user?.company_id;
      let fieldTreeCompanyId = this.formValues?.safety_diaries?.field_tree_company_id;
      return (
        !this.isNewItem &&
        userRole?.role !== 1 &&
        fieldTreeCompanyId === companyUser
      );
    },    
    ROLE_SUBCONSTRACTOR_USER_EDIT() {
      let userLogin = Store.getters["Login/getUser"];
      let userRole = Store.getters["Report/getUserRole"];
      return (
        !this.isNewItem &&
        (
          userRole?.role !== 1 &&
          this.safetyDiaryCommentCorrectionsValues.recovery_user_id === userLogin.id
        ) ||
        (
          userRole?.role !== 1 &&
          this.safetyDiaryCommentCorrectionsValues.status_code === 0 &&
          this.safetyDiaryCommentCorrectionsValues.recovery_flag === 0
        ) ||
        (
          userRole?.role !== 1 &&
          this.safetyDiaryCommentCorrectionsValues.status_code === 2
        )
      );
    },
    USER_LOGIN(){
      return Store.getters["Login/getUser"];
    },
    ENV_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
  },
  methods: {
    checkValue(index) {
      return this.arrIndexImageChoose.includes(index);
    },
    getUrl(id) {
      return this.sourceImages.find(e => e.id === id)?.url;
    },
    getUrlMain(id) {
      return this.sourceImagesMain.find(e => e.id === id)?.url;
    },
    selectImage(image, index) {
      if (this.isLoadingImage) {
        return;
      }
      this.indexOfSelectedImages = index;
      this.mainImage.src = image
    },
    closeUploadImageDialog() {
      this.popups.isShowUploadImageForm = false;
    },
    async initiateImageSource() {
      this.isLoadingImage = true;
      for (let i = 0; i < this.dataItems.length; i++) {
        const item = this.dataItems[i];
        if (item.image_thumb_web_url && !this.sourceImages.find(e => e.id == item.id)) {
          await this.createObjectUrlForImage(item);
        }
        if (item.image_origin && !this.sourceImages.find(e => e.id == item.id)) {
          await this.createObjectUrlForImage(item);
        }
        if (item.image_url && !this.sourceImagesMain.find(e => e.id == item.id)) {
          await this.createObjectUrlForImage(item);
        }
      }
      this.isLoadingImage = false;
    },
    submitUploadImage(param) {
      let _formValues = [...this.safetyDiaryCommentCorrectionsValues.safety_diary_images];
      param.forEach(e => {
        _formValues.push({
          image: e.fileName,
          image_data: e.data,
          type: this.type
        });
      });
      this.closeUploadImageDialog();
      this.onInput({
        name: "safety_diary_images",
        value: _formValues
      });
      let imageType = _formValues = _formValues.filter(e => e.type === this.type);
      this.mainImage.src = imageType[0].image_data ? imageType[0].image_data : (imageType[0].image_url ? this.getUrlMain(imageType[0].id) : "");
      this.indexOfSelectedImages = 0;
    },
    async createObjectUrlForImage(item) {
      this.sourceImages.push({id: item.id, url: await this.fetchImageSource(item.image_thumb_web_url)});
      this.sourceOriginImages.push({id: item.id, url: await this.fetchImageSource(item.image_origin)});
      this.sourceImagesMain.push({id: item.id, url: await this.fetchImageSource(item.image_url)});
      this.mainImage.src = this.dataItems[0]?.image_data ? this.dataItems[0]?.image_data : (this.dataItems[0]?.image_url ? this.getUrlMain(this.dataItems[0]?.id) : "");
    },
    async fetchImageSource(sourceUrl) {
      let image = "";
      if (sourceUrl) {
        const params = getParamsOfUrlReadFile(sourceUrl);
        const response = await Store.dispatch("File/readFile", params);
        if (!response.hasError) {
          image = window.URL.createObjectURL(new Blob([response.data], {type: response.headers["content-type"]}));
        }
      }
      return image;
    },
    updateCheckbox(index, isChecked) {
      if (isChecked) {
        this.arrIndexImageChoose.push(index);
      } else {
        const _index = this.arrIndexImageChoose.indexOf(index);
        if (_index > -1) {
          this.arrIndexImageChoose.splice(_index, 1);
        }
      }
    },
    removeImage() {
      let arr = [...this.dataItems];
      for (let index = this.dataItems.length - 1; index >= 0; index--) {
        if (this.arrIndexImageChoose.includes(index)) {
          arr.splice(index, 1);
        }
      }
      this.arrIndexImageChoose = [];
      let temp = this.safetyDiaryCommentCorrectionsValues.safety_diary_images.filter(e => e.type !== this.type);
      arr = [...arr, ...temp];
      this.dataItems = arr;
      this.onInput({
        name: "safety_diary_images",
        value: arr
      });
      this.mainImage.src = this.dataItems[0]?.image_data ? this.dataItems[0]?.image_data : (this.dataItems[0]?.image_url ? this.getUrlMain(this.dataItems[0]?.id) : "");
      this.indexOfSelectedImages = 0;
    },
    onInput({name, value}) {
      const formValues = {...this.formValues};
      if (name == "recovery_content") {
        formValues["safety_diary_comment_corrections"][0][name] = value;
      } else if (name === "safety_diary_images") {
        formValues["safety_diary_comment_corrections"][0]["safety_diary_images"] = value;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    getColor(recovery, status) {
      let color;
      if (status === 2) {
        color = "warning";
      } else if (status === 1) {
        color = "primary";
      } else if (status === 0 && recovery === 1) {
        color = "rgb(229, 149, 29)";
      } else if (status === 0 && recovery === 0) {
        color = "rgb(176, 0, 32)";
      }
      return color;
    },
    getText(recovery, status) {
      let text;
      if (status === 2) {
        text = "差戻し";
      } else if (status === 1) {
        text = "承認済";
      } else if (status === 0 && recovery === 1) {
        text = "是正済";
      } else if (status === 0 && recovery === 0) {
        text = "未是正";
      }
      return text;
    },
    onRecovery() {
      this.$emit("onRecovery");
    },
    onApprove(statusRecovery){
      this.$emit("onApprove", statusRecovery);
    },
    onRejectAfterApprove(){
      this.$emit("onRejectAfterApprove");
    },
    onRejectAfterRecovery() {
      this.$emit("onRejectAfterRecovery");
    },
    onEditMessage() {
      this.$emit("onEditMessage");
    },
    openMessageReject() {
      this.$emit("openMessageReject");
    },
    disable(editable, isApprove, roleMain, roleSub) {
      return !editable || (editable && isApprove) || (editable && !roleMain && !roleSub);
    },
    closeShowImageDialog() {
      this.popups.isShowImageDialog = false;
      this.listUrlImage = [];
    },
    openShowImageDialog() {
      if (this.ENV_OBAYASHI && this.mainImage.src != NO_IMAGE && this.mainImage.src) {
        this.popups.isShowImageDialog = true;
        this.dataItems.forEach(item => {
          let dataItem = item.image_data ? item.image_data : (item.image_origin ? this.getUrlOrigin(item.id) : '')
          this.listUrlImage.push(dataItem)
        })
      }
    },
    getUrlOrigin(id) {
      return this.sourceOriginImages.find(e => e.id === id)?.url;
    }
  }
}
</script>
<style type="text/css" scoped>
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #767676;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
} */
#title {
  font-size: 20px;
  color: #1B9C4F;
}

.scrollbar {
  aspect-ratio: 3 / 6.5;
  max-height: 32.82336578581363vh;
  overflow-y: auto;
  padding-left: 22px;
  padding-right: 10px;
}

.scrollbar:hover {
  z-index: 10;
}
.image-box {
  position: relative;
}

.checkbox {
  position: absolute;
  z-index: 1;
  top: -20px;
  width: 25px;
}

.add-image {
  top: 260px;
}

.image-action {
  position: relative;
}
.image-selected {
  outline: 5px solid #32C36C;
  margin-top: 5px;
}

.ps-a {
  position: absolute;
}

.status-recovery {
  left: 1.5%;
}

.text-message {
  top: 20%;
  left: 2.5%;
}

.show-message {
  top: 24%;
  left: 3.5%;
}

.mh-108 {
  max-height: 108px;
  min-height: 108px;
}

::v-deep .comment-right .textAreaDisabled .v-input--is-disabled .v-input__slot {
  background: #ededed !important;
}
::v-deep .comment-right .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 15px !important;
}
::v-deep .comment-right .theme--light.v-text-field.v-input--is-readonly .v-input__slot:before {
  border-image: repeating-linear-gradient(90deg,rgba(0,0,0,.38) 0,rgba(0,0,0,.38) 2px,transparent 0,transparent 4px) 1 repeat;
}
::v-deep  .comment-right .v-text-field.v-input--is-readonly>.v-input__control>.v-input__slot:after {
  background-color: unset !important;
  border-color: unset !important;
  border-style: unset !important;
  border-width: unset !important;
}
::v-deep .v-input--dense>.v-input__control>.v-input__slot {
  margin-bottom: 13px;
}
::v-deep .checkbox-image .v-icon.v-icon{
  color: #1B9C4F !important;
  left: -1px !important;
}
::v-deep .checkbox-image-active .v-icon.v-icon {
  color: #1B9C4F !important;
}
::v-deep .checkbox-image .v-input__slot{
  border: 0.5px solid;
  border-color: rgba(0, 0, 0, 0.6) !important;
}
.title-item-add {
  padding-top: 15px;
}
.title-item-edit {
  padding-bottom: 9px;
}
.btn-action {
  padding-bottom: 9px;
}
.action-edit {
  min-height: 14vh;
}
@media (max-width:1600px) {
  .status-recovery{
    left: 0 !important;
  }
  .show-message{
    left: 2% !important;
    top: 30% !important;
  }
  .text-message{
    top: 25% !important;
    left: 1.5% !important;
  }
  .control-mac{
    max-width: 3% !important;
  }
}
@media (max-width:1450px) {
  .status-recovery{
    left: 0 !important;
  }
  .show-message{
    left: 2% !important;
    top: 30% !important;
  }
  .text-message{
    top: 25% !important;
    left: 1.5% !important;
  }
  .control-mac{
    max-width: 3% !important;
  }
}
.size-image-obayashi {
  flex: 0 0 58% !important;
  max-width: 58% !important;
}
.aspect-ratio-obayashi {
  aspect-ratio: 5.2/9 !important
}
.mb-obayashi {
  margin-bottom: 20px;
}
</style>

<style type="text/css" scoped>
@media (max-width:1600px) {
  ::v-deep .textarea-edit textarea{
    height: calc(100% - 10px) !important;
  }
  ::v-deep .textarea-preview textarea {
    height: 80px !important;
  }
  ::v-deep textarea {
    padding: 0 4px !important;
  }
  .textAreaDisabled {
    padding-top: 23px !important;
  }
  ::v-deep .textarea-edit .v-input--is-readonly textarea {
    height: 80px !important;
  }
  .v-input--is-readonly.v-input--dense>.v-input__control>.v-input__slot {
    margin-bottom: 13px;
  }
  .action-edit {
    min-height: 17vh;
  }
}
@media (max-width:1450px) {
  ::v-deep .textarea-edit textarea{
    height: calc(100% - 10px) !important;
  }
  ::v-deep .textarea-preview textarea {
    height: 153px !important;
  }
  ::v-deep textarea {
    padding: 0 4px !important;
  }
  .textAreaDisabled {
    padding-top: 25px !important;
  }
  .aspect-ratio-obayashi {
    aspect-ratio: 8.99/9 !important
  }
  .size-image-obayashi {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  ::v-deep .v-textarea textarea {
    line-height: 1.3em !important;
  }
  .mb-obayashi {
    margin-bottom: 0px !important;
  }
  .mh-90 {
    max-height: 90px !important;
  }
  .padding-obayashi {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .image-action {
    position: relative;
    padding-bottom: 0px !important;
  }
  .custom-obayashi-new {
    padding-top: 0px !important;
  }
  .min-screen {
    height: 195px;
  }
}

</style>