<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-btn depressed color="primary" @click="onUploadPdfFile" :disabled="roleUser == ROLE_USER[3]">
                  アップロード
                </v-btn>
                <v-btn
                  :disabled="dataReports.length == 0"
                  depressed
                  class="mx-2"
                  color="warning"
                  @click="onShowAddComment"
                >
                  コメント
                </v-btn>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <DocumentViewer
                    :items="dataReports"
                    :mainHeight="mainHeight"
                    @showMessage="showMessage"
                  />
                </v-col>
              </v-row>
              <form ref="form">
                <input
                  style="display: none"
                  ref="input_drawing_file"
                  type="file"
                  accept="application/pdf"
                  @change="onSelectPdf()"
                />
              </form>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
      <Popup width="480px" :dialog="isShowAddCommentDialog">
        <AddCommentDialog
          :items="formCommentValue"
          :flagHandleConfirm="flagHandleConfirm"
          :editable="editable"
          @close="handleCloseDialog"
          @yes="handleAddComment"
        />
      </Popup>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import { ROLE_USER } from "@/constants/DEVICE_COORDINATOR";
import Label from "@/components/forms/elements/Label.vue";
import InputText from "@/components/forms/elements/InputText.vue";
import Select from "@/components/forms/elements/Select.vue";
import DocumentViewer from "./components/DocumentViewer.vue";
import AddCommentDialog from "./components/AddCommentDialog.vue";
import Popup from "@/components/common/Popup.vue";
import _ from "lodash";

const STORE = "DeviceCoordinator";

export default {
  data: () => {
    return {
      isShowAddCommentDialog: false,
      formCommentValue: {},
      editable: true,
      dataReports: [],
      flagHandleConfirm: false,
      ROLE_USER
    };
  },
  components: {
    Label,
    InputText,
    Select,
    DocumentViewer,
    AddCommentDialog,
    Popup
  },
  props: {
    item: Object,
    mainHeight: {
      type: Number,
      default: 0
    },
    roleUser : Number
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        if (newValue?.machine_company_field_estimate_order_reports) {
          this.dataReports = [...newValue?.machine_company_field_estimate_order_reports];
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    }
  },
  methods: {
    onUploadPdfFile() {
      this.$refs.input_drawing_file.click();
    },
    onSelectPdf() {
      const file = this.$refs.input_drawing_file.files[0];
      if (!file) {
        return;
      }
      this.$refs.form.reset();
      this.setFile(file);
    },
    setFile(file) {
      if (file.name.toLocaleLowerCase().match(/\.(pdf)$/)) {
        if (file.size >= 10 * 1024 * 1024) {
          Store.dispatch(
            "Error/show",
            {
              status: "400",
              message: "アップロードのファイルは、最大10MBまでです。"
            },
            { root: true }
          );
          return;
        }
      } else {
        Store.dispatch(
          "Error/show",
          { status: "400", message: "PDFファイルをアップロードしてください。" },
          { root: true }
        );
        return;
      }
      this.getBase64(file).then((dataBase64) => {
        const fileTemp = {
          report: file.name,
          report_url: dataBase64
        };
        this.$emit("createReport", fileTemp);
      });
    },
    async handleAddComment(message) {
      const params = {
        id: this.dataReports[0].id,
        comment: message.comment
      };
      const rs = await Store.dispatch(`${STORE}/createReportComment`, params);
      if (!rs.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました"
        });
        this.$emit("updateDetailMachine");
        this.handleCloseDialog();
      } else {
        this.flagHandleConfirm = !this.flagHandleConfirm;
      }
    },
    onShowAddComment() {
      this.editable = true;
      this.isShowAddCommentDialog = true;
    },
    handleCloseDialog() {
      this.formCommentValue = {};
      this.isShowAddCommentDialog = false;
    },
    async showMessage(obj) {
      const rs = await Store.dispatch(`${STORE}/getReportComment`, obj.comment_id);
      let comment = _.cloneDeep(rs.data.contents.entries.comment);
      if (!rs.hasError) {
        this.formCommentValue = { comment };
        this.editable = false;
        this.isShowAddCommentDialog = true;
      }
    },
    // Base64変換
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";

.row-position {
  margin-top: -20px;
  height: 150px;
  position: relative;
}

.select-position {
  width: 100%;
  position: absolute;
}
</style>
