<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <!--  (共通) 会社ヘッダー -->
        <InhousePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout :layoutParams="layoutParams" :hideFooter="searchParams.totalPage == 1">
          <template #tableHeader="{ updateHeader }">
            <!--  
              (共通) テーブルヘッダー 
              このなかに、新規作成ボタン、検索ボタン、一括削除ボタンが実装されてます
              @openRemoveDialog : 一括削除の確認ダイアログを開く
              @openItemForm : 新規作成フォームを開く
            -->
            <TableHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :multiRemoveStatus="disableRemoveBtn"
              :updateHeader="updateHeader"
              :isClassify="true"
              @openRemoveDialog="openRemoveDialog"
              @openItemForm="openNewItemForm"
              @openClassifyDialog="openClassifyDialog"
            >
                <SearchFormWrapper>
                  <Label label="現場" width="350px">
                    <Select
                      name="field_id"
                      :items="Onsite"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>
                  <Label label="種別" width="350px">
                    <span @click="openFormMachineCompanyType" class="select">
                      <span class="select_wrap">
                        <Select
                          name="machineCompanyType"
                          :items="machineCompanyType"
                          :editable="true"
                          :values="searchParams"
                          @onInput="onChangeSearchParams"
                        />
                      </span>
                    </span>
                  </Label>
                  <Label label="機材">
                    <InputText
                      name="word"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>
                  <v-spacer></v-spacer>
                  <v-btn class="mr-6" color="primary" depressed @click="onSearch">
                    検索
                  </v-btn>
                </SearchFormWrapper>
            </TableHeader>

            <TableSortWrapper>
              <TableSort
                :values="searchParams"
                :sort_items="SORT_ITEMS"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                @onInput="onChangeSortParams"
              />
            </TableSortWrapper>
          </template>

          <template #tableBody="{ tableHeight }">
            <MachineTable
              :items="items"
              :isNoDataMessage="isNoDataMessage"
              :tableLabels="TABLE_LABELS"
              id="index"
              :tableHeight="tableHeight"
              :itemsPerPage="searchParams.pageCount"
              :isSelected="isSelected"
              :checkSelectedParents="checkSelectedParents"
              :isSelectedParent="isSelectedParent"
              :isCheckedAll="isCheckedAll"
              :updateCheckbox="updateCheckbox"
              :updateAllCheckbox="updateAllCheckbox"
              :openItemForm="openItemForm"
              :refreshSelectChildren="refreshSelectChildren"
              @getChildItems="getChildItems"
            />
          </template>

          <template #tableFooter>
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>

    <Popup :dialog="popups.isShowItemForm">
      <MachineForm
        :item="editedItem"
        :isNewItem="isNewItem"
        :isErrorSubmit="isErrorSubmit"
        :isEmitted="isEmitted"
        :beforeUpdateItem="beforeUpdateItem"
        :machinesOnsite="machinesOnsite"
        :flagAdd="flagAdd"
        @formUpdate="formUpdate"
        @submit="submitForm"
        @cancel="closeItemForm"
      />
    </Popup>

    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :items="selectedItems"
        warning
      />
    </Popup>

    <Popup :dialog="popups.isShowMachineCompanyType">
      <MachineCompanyTypeForm
        @cancel="closeMachineCompanyForm"
        @changeMachineCompanyType="changeMachineCompanyType"
        :machine_company_type_tree="listMachinesCompanyType"
      />
    </Popup>

    <Popup :dialog="popups.isShowClassifyDialog">
      <MachineClassifyForm
        @cancel="closeClassifyForm"
      />
    </Popup>

  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import ConfirmRemoveDialog from "./components/ConfirmRemoveDialog";
import InhousePortalHeader from "@/components/globalHeader/InhousePortalHeader";
import { TABLES_PER_PAGE, TABLE_SORT_ORDERS } from "@/constants/COMMON"; //絞り込みフォームで使用
import MachineForm from "@/components/forms/companyMachines/MachineForm"; //ユーザー登録編集フォーム
import MachineClassifyForm from "./components/MachineClassify/MachineClassifyForm";
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import InputText from "@/components/forms/elements/InputText";
import MachineTable from './components/MachineTable';
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import MachineCompanyTypeForm from "@/components/forms/companyMachines/components/MachineCompanyTypeForm.vue"
import {
  MACHINE_TABLE_LABELS,
  MACHINE_SORT_ITEMS,
  MACHINE_INITAL_ITEM,
} from "@/constants/COMPANY_MACHINES"; //絞り込みフォームで使用
import _ from "lodash";
import { HEADER_MENU_ITEMS_INHOUSE } from "@/constants/GLOBALHEADER";
import { convertCurrentcy } from "@/utils/currentcy";

/**
 * 定数
 * この一覧ページで使用する固有の定数を定義します。
 * 共通に使用する定数は基本的に@/constants/で定義します。
 * - 定数は大文字で定義します
 * - 定数は基本的にはdataに代入しないで直接参照します
 */

//タイトル
const PAGE_TITLE = "機材";

//１ページあたりのテーブル件数
const PAGE_COUNT = 25;

//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;

//ストア
const STORE = "CompanyMachines";

//テーブルヘッダーラベル
const TABLE_LABELS = MACHINE_TABLE_LABELS;

//ソート要素
const SORT_ITEMS = MACHINE_SORT_ITEMS;

//フォーム初期値
const INITIAL_ITEM = MACHINE_INITAL_ITEM;

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: PAGE_TITLE };
    },
  },
  name: 'Machines',
  data() {
    return {
      HEADER_MENU_ITEMS_INHOUSE,
      expanded: [],
      singleExpand: false,
      PAGE_TITLE,
      TABLE_LABELS,
      SORT_ITEMS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      
      items: [],
      isNoDataMessage: false,
      machineCompanyType: {},
      listMachinesCompanyType: {},

      selectedParent: [],
      selectedChildren: [],
      selectedItems: [],
      editedItem: { ...INITIAL_ITEM },
      beforeUpdateItem: {},

      isNewItem: false,

      isClassify: false,

      machinesOnsite: null,
      searchParams: {
        word: "",
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: SORT_ITEMS[0].id, 
        asc: true,
        field_id: null,
        machineCompanyType: null,
      },

      popups: {
        isShowItemForm: false,
        isShowRemoveDialog: false,
        isShowClassifyDialog: false,
        isShowMachineCompanyType: false,
      },
      isErrorSubmit: false,
      isEmitted: false,
      Onsite:null,
      flagAdd : true,
    };
  },

  components: {
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    InhousePortalHeader,
    Popup,
    ConfirmRemoveDialog,
    MachineForm,
    InputText,
    Label,
    MachineClassifyForm,
    MachineTable,
    Select,
    MachineCompanyTypeForm
  },

  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    this.initDataSelect();
    this.getItems();
    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_INHOUSE.COMPANY_MACHINES.id,
    });
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
        Store.getters[`${STORE}/getMachineCompanyType`],
        Store.getters[`Sites/getData`]
      ],
      (data, oldData) => {
        if (data[0] != oldData[0]) this.updateIndexToList(data[0]);
        this.refreshPage();
        if (data[2]) {
          this.listMachinesCompanyType =  _.cloneDeep(data[2]);
          const machineType = this.converMachineType(data[2]);
          this.machineCompanyType = _.cloneDeep(machineType);
          this.machineCompanyType?.unshift({
            id: null,
            name: "",
          });
        }
        if (data[3]) {
          const Onsite = this.convertListMachineOnsite(data[3]);
          this.Onsite = _.cloneDeep(Onsite);
          this.Onsite?.unshift({
            id: null,
            name: "",
          });
        }
        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        deep: true,
      }
    );
  },

  computed: {
    disableRemoveBtn() {
      return this.selectedParent.length === 0 && this.selectedChildren.length === 0;
    },

    apiParams() {
      return {
        field_id: this.searchParams.field_id,
        machine_company_name: this.searchParams.word,
        sort_value: this.searchParams.sort,
        sort_by: this.searchParams.asc ? 1 : 0,
        page_number: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
        machine_company_type_item_tree_id: this.searchParams.machineCompanyType,
      };
    },

    COMPANY_USER() {
      return JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user;
    }
  },

  methods: {
    async initDataSelect() {
      const companyUser = this.COMPANY_USER;
      let params = {
        company_id: companyUser.company_id, company_branch_id: companyUser.company_branch_id
      };
      await Store.dispatch(`Sites/get`, { params });
      await Store.dispatch(`${STORE}/getMachineCompanyType`, {
        company_id: companyUser.company_id,
        company_branch_id: companyUser.company_branch_id ? companyUser.company_branch_id : ""
      });
    },

    updateIndexToList(items) {
      let _items = _.cloneDeep(items);
      _items.forEach(e => {
        if(e.childrens && Array.isArray(e.childrens)){
          e.childrens.forEach((e1, index) => {
            e1["index"] = index;
          });
        }
      });
      this.items = [..._items];
      this.isNoDataMessage = this.items.length == 0;
    },

    isSelected(id, index) {
      return Boolean(this.selectedChildren.find(element => element.id == id && element.index == index));
    },

    checkSelectedParents(itemParent) {
      if(itemParent.childrens && Array.isArray(itemParent.childrens)){
        for (let i = 0; i < itemParent.childrens.length; i++) {
          if (!this.isSelected(itemParent.id, itemParent.childrens[i].index)) {
            return false;
          }
        }
      }
      return true;
    },

    updateSelectedItems(isChecked, id, index, machine_company_field_id, item) {
      let _selectedItems = [];      

      if (isChecked && !this.isSelected(id, index)) {
        _selectedItems = [...this.selectedChildren, {
          "id": id,
          "machine_company_field_id": machine_company_field_id,
          "index": index,
          "name": typeof item.name != 'undefined' ? item.name : '',
        }];
      } else if (isChecked && this.isSelected(id, index)) {
        _selectedItems = [...this.selectedChildren];
      } else {
        _selectedItems = this.selectedChildren.filter((item) => {
          return item.id !== id || item.index !== index;
        });
        this.selectedParent = this.selectedParent.filter((item) => {return item !== id} );
      }
      this.selectedChildren = [...new Set(_selectedItems)];
    },

    isSelectedParent(id) {
      return this.selectedParent.includes(id);
    },

    isCheckedAll() {
      return this.selectedParent.length == this.items.length && this.items.length > 0;
    },

    updateCheckbox(item, isChecked, isParent) {
      if (isParent) {
        this.updateSelectedParentItems(item.id, isChecked);
        if(item.childrens && Array.isArray(item.childrens)){
          item.childrens.forEach((user) => {
            this.updateSelectedItems(isChecked, user.id, user.index , user.machine_company_field_id, user);
          });
        }
      } else {
        this.updateSelectedItems(isChecked, item.id, item.index, item.machine_company_field_id, item);
        const checkSelected = this.selectedChildren.filter((i) => i.id === item.id);
        const parentItem = this.items.filter((i) => i.id === item.id);
        if(checkSelected.length  === parentItem[0].total_children_item){
          this.updateSelectedParentItems(item.id, isChecked);
        } 
      }
    },

    updateAllCheckbox(isChecked) {
      let allData = _.cloneDeep(this.items);
      allData.forEach(parent => {
        this.updateSelectedParentItems(parent.id, isChecked);
        if(parent.childrens && Array.isArray(parent.childrens)){
          parent.childrens.forEach((user) => {
            this.updateSelectedItems(isChecked, user.id, user.index , user.machine_company_field_id,user);
          });
        }
      });
    },

    updateSelectedParentItems(id, isChecked) {
      let _selectedItems = [];
      if (isChecked) {
        _selectedItems = [...this.selectedParent, id];
      } else {
        _selectedItems = this.selectedParent.filter((item) => {
          return item != id;
        });
      }
      this.selectedParent = [...new Set(_selectedItems)];
    },

    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },


    onSearch() {
      this.searchParams["currentPage"] = 1;
      this.getItems();
    },

    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
    },

    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      name == "pageCount" ? (searchParams["currentPage"] = 1) : "";
      this.searchParams = searchParams;
      this.getItems();
    },

    openNewItemForm() {
      this.editedItem = _.cloneDeep(INITIAL_ITEM);
      this.beforeUpdateItem = _.cloneDeep(INITIAL_ITEM);
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },

    openClassifyDialog() {
      this.popups.isShowClassifyDialog = true;
    },

    async openItemForm(item) {
      const result = await Store.dispatch(`${STORE}/getDetail`, item.id);
      if(item.childrens && Array.isArray(item.childrens)) {
        const machinesOnsite = item.quantity - item.remaining;
        this.machinesOnsite = machinesOnsite;
      }
      if (!result.hasError) {
        let rs = _.cloneDeep(result.data.contents.entries);
        rs.interpersonal = convertCurrentcy(rs.interpersonal);
        rs.objective = convertCurrentcy(rs.objective);
        rs.passenger = convertCurrentcy(rs.passenger);
        rs.others = convertCurrentcy(rs.others);
        this.editedItem = _.cloneDeep(rs);
        this.beforeUpdateItem = _.cloneDeep(rs);
        this.isNewItem = false;
        this.popups.isShowItemForm = true;
      }
    },

    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.machinesOnsite = null;
      this.$nextTick(() => {
        this.editedItem = { ...INITIAL_ITEM };
      });
    },

    closeClassifyForm() {
      this.popups.isShowClassifyDialog = false;
    },

    openRemoveDialog() {
      const validItemChildren = this.selectedChildren.filter(x => !this.selectedParent.includes(x.id)).map((item)=>{
        return { machine_company_field_id: item.machine_company_field_id, id:item.id, name:item.name}
      });
      const ids = this.selectedParent.map((item) => ({id: item, name: this.items.filter((machine) =>{ return machine.id === item})[0].name })).concat(validItemChildren);
      this.selectedItems = ids;
      this.popups.isShowRemoveDialog = true;
    },

    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
    },

    formUpdate(params) {
      this.editedItem = { ...params };
    },

    async submitForm(saveDraft) {
      const hasId = "id" in this.editedItem;
      const companyUser = this.COMPANY_USER;
      let editedItem = _.cloneDeep(this.editedItem);
      if(editedItem.interpersonal) editedItem.interpersonal = editedItem.interpersonal.replaceAll(",", "");
      if(editedItem.objective) editedItem.objective = editedItem.objective.replaceAll(",", "");
      if(editedItem.passenger) editedItem.passenger = editedItem.passenger.replaceAll(",", "");
      if(editedItem.others) editedItem.others = editedItem.others.replaceAll(",", "");
      if (editedItem.machine_company_images) {
        editedItem.machine_company_images = editedItem.machine_company_images.filter(image => image.image);
      }
      if (!hasId) {
        editedItem['company_id'] = companyUser.company_id;
        editedItem['company_branch_id'] = companyUser.company_branch_id;
        editedItem.machine_company_licenses.forEach((item) =>{
          delete item.id
        });
        editedItem.machine_company_sp_trainings.forEach((item) =>{
          delete item.id
        });
      }
      editedItem.machine_company_licenses.forEach((item) =>{
        delete item.catergory_id
      });
      const machine_company_type_item_tree_ids = editedItem.machine_company_type_item_tree_ids;
      editedItem.machine_company_type_item_tree_id = machine_company_type_item_tree_ids.slice(-1)[0];
      delete editedItem.machine_company_type_item_tree_ids;
      const result = await Store.dispatch(
        hasId ? `${STORE}/update` : `${STORE}/create`,
        editedItem
      );
      if (result.hasError) {
        this.isErrorSubmit = true;
        this.flagAdd = !this.flagAdd;
        return;
      } else if (hasId) {
        const detailResult = await Store.dispatch(
          `${STORE}/getDetail`,
          this.editedItem.id
        );
        if (!detailResult.hasError) {
          let rs = _.cloneDeep(detailResult.data.contents.entries);
          rs.interpersonal = convertCurrentcy(rs.interpersonal);
          rs.objective = convertCurrentcy(rs.objective);
          rs.passenger = convertCurrentcy(rs.passenger);
          rs.others = convertCurrentcy(rs.others);
          this.editedItem = _.cloneDeep(rs);
          this.isErrorSubmit = false;
        }
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        this.isEmitted = !this.isEmitted;
      } else {
        if(!saveDraft) {
          this.closeItemForm();
        }
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
      this.getItems();
    },

    async getItems() {
      await Store.dispatch(`${STORE}/get`, { params: this.apiParams });
    },

    async removeItems() {
      const result = await Store.dispatch(`${STORE}/delete`, { deleted: this.selectedItems });
      if (!result.hasError) {
        this.searchParams["currentPage"] = 1;
        this.closeRemoveDialog();
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
        this.selectedParent= [];
      }
    },    

    async getChildItems(id, pageNumber) {
      let searchParams = { ...this.searchParams };
      searchParams["machine_company_id"] = id;
      searchParams["page_number"] = pageNumber;
      delete searchParams.asc;
      delete searchParams.currentPage;
      delete searchParams.machineCompanyType;
      delete searchParams.pageCount;
      delete searchParams.sort;
      delete searchParams.totalPage;
      delete searchParams.word;
      delete searchParams.total_item;
      const params = {
        params: searchParams,
      };
      await Store.dispatch(`${STORE}/loadChildren`, params);
    },

    converMachineType(data) {
      const tempArray = []
      const recursiveFunc = function (machine, type) {
        if(machine && Array.isArray(machine)){
            machine.forEach(i => {
            const {id, type: childType, childrens} = i
            const newType = type ? `${type}/${childType}` : childType
            tempArray.push({
              id, name: newType
            })
            if (childrens && Array.isArray(childrens)) recursiveFunc(childrens, newType)
          })
        }
      }

      recursiveFunc(data, '')
      return tempArray;
    },

    convertListMachineOnsite(data) {
      let arr = data.map((item)=>{
        return {id: item.field_id, name: item.field_name}
      })
      return arr;
    },

    refreshSelectChildren() {
      this.selectedChildren = [];
    },

    closeMachineCompanyForm() {
      this.popups.isShowMachineCompanyType = false;
    },
    
    openFormMachineCompanyType() {
      this.popups.isShowMachineCompanyType = true;
    },

    changeMachineCompanyType(value) {
      this.popups.isShowMachineCompanyType = false;
      const name = 'machineCompanyType';
      if(value && Array.isArray(value) && value.length > 0){
        const id = value[value.length - 1];
        this.onChangeSearchParams({ name, value : id });
      }else{
        this.onChangeSearchParams({ name, value : null });
      }
    },

    refreshPage() {
      this.selectedParent = [];
      this.selectedChildren = [];
      this.selectedItems = [];
    }
  },
};
</script>
<style scoped>
.select{
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.select_wrap{
  width: 100%;
  pointer-events: none;
}
</style>
