<template>
  <v-card class="dialog">
    <div class="header sticky" style="height: 65px;">
      <v-card-title small>
        <div class="title" style="font-size: 20px !important">{{ title }}</div>
        <v-spacer></v-spacer>
        <v-btn small icon text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
    </div>
    <!-- 検索コンポーネント -->
    <SearchSelectList
      :searchConditions="searchConditions"
      @onSearch="onSearch"
      @formUpdate="formUpdate"
      class="header"
    >
      <v-list three-line class="scroll-list">
        <template v-for="(item, index) in items" >
          <v-list-item :key="item.work_plan_id" ripple>
            <v-list-item-content @click="onClick(item)">
              <v-list-item-title class="cst-line-1">
                {{ getNameOfCompaniesAndJob(item)}}
              </v-list-item-title>
              <v-list-item-title class="cst-content">
                {{ item.contents }}
              </v-list-item-title>
              <v-list-item-title class="cst-location-name">
                {{item.location_name}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="`${index}-${item.work_plan_id}`"></v-divider>
        </template>
      </v-list>
      <div style="display: flex;justify-content: center" v-if="searchConditions.total_item == 0">
        {{ NO_DATA_MESSAGE }}
      </div>
    </SearchSelectList>
      <Pagination
        v-if="searchConditions.total_item > ITEM_PER_PAGE"
        :current="searchConditions.page_number"
        :total="searchConditions.totalPage"
        @pageUpdate="pageUpdate"
      />
  </v-card>
</template>

<script>
import SearchSelectList from "@/components/forms/device/coordinator/applications/components/SearchSelectList.vue";
import Pagination from "@/components/masterTable/elements/Pagination";
import { Store } from "@/store/Store";
import { STORE } from "@/constants/DEVICE_COORDINATOR";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
const ITEM_PER_PAGE = 25;

export default {
  data: () => {
    return {
      items: [],
      NO_DATA_MESSAGE,
      searchConditions: {
        work_date: null,
        work_hour_zone: null,
        field_construction_id: null,
        field_tree_id: null,
        page_number: 1,
      },
      innitialFlag: true,
      ITEM_PER_PAGE,
    };
  },
  components: {
    SearchSelectList,
    Pagination,
  },
  props: {
    title: String,
  },
  mounted() {
    this.$watch(
      () => [this.searchConditions.field_construction_id, this.searchConditions.work_hour_zone],
      (newValue) => {
        if(newValue[0] && newValue[1] && this.innitialFlag){
          this.innitialFlag = false;
          this.getListWorkPlan();
        }
      },
      { immediate: true, deep: true }
    );
    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getWorkPlanList`],
        Store.getters[`${STORE}/getPaginationWorkPlan`],
      ],
      (data) => {
        /**
         *  (共通)
         * 一覧リストの格納
         */
        this.items = data[0];
        let searchConditions = { ...this.searchConditions };
        searchConditions.totalPage = data[1].total;
        searchConditions.page_number = parseInt(data[1].current);
        searchConditions.total_item = data[1].all_record;
        this.searchConditions = searchConditions;
      },
      {
        deep: true,
      }
    );
  },
  computed: {
    apiParams() {
      return {
        work_date: this.searchConditions.work_date,
        work_hour_zone: this.searchConditions.work_hour_zone,
        field_construction_id: this.searchConditions.field_construction_id,
        field_tree_id: this.searchConditions.field_tree_id,
        page_number: this.searchConditions.page_number,
      };
    },
  },
  methods: {
    // Call function get list work plan
    async onSearch () {
      this.getListWorkPlan();
    },

    // idの更新
    onClick(item) {
      const itemWorkPlan = {};
      itemWorkPlan.work_plan_id = item?.work_plan_id;
      itemWorkPlan.work_date = item?.work_date;
      itemWorkPlan.time_zone_name = item?.work_hour_zone_name;
      itemWorkPlan.field_construction_name = item?.field_construction_name;
      itemWorkPlan.field_tree_company_name = item?.full_company_name;
      itemWorkPlan.forman_name = item?.foreman_user_name;
      itemWorkPlan.forman_tel = item?.foreman_user_tel;
      itemWorkPlan.field_tree_item_name = item?.location_name;
      itemWorkPlan.work_plan_contents = item?.contents;
      this.$emit("selectedWork", itemWorkPlan);
    },

    getNameOfCompaniesAndJob(item) {
      return `${item?.company_name} / ${item?.foreman_user_name}`;
    },

    pageUpdate(n) {
      let searchConditions = { ...this.searchConditions };
      searchConditions.page_number = n;
      this.searchConditions = searchConditions;
      this.getListWorkPlan();
    },

    async getListWorkPlan() {
      if (this.searchConditions.work_date && this.searchConditions.field_construction_id) {
        await Store.dispatch(`${STORE}/getWorkPlanList`, { params: this.apiParams });
      }
    },

    formUpdate(params) {
      const total_item = this.searchConditions?.total_item;
      const totalPage = this.searchConditions?.totalPage;
      const searchConditions = { ...params };
      searchConditions.total_item = total_item;
      searchConditions.totalPage = totalPage;
      this.searchConditions = searchConditions;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #1B9C4F;
}
.dialog {
  padding-bottom: 20px;
  max-height: 700px;
}
.cst-content {
  font-size: 16px !important;
}
.cst-line-1 {
  font-size: 11px;
  color: #181515 !important;
}
.scroll-list {
  max-height: 450px;
  overflow-y: auto;
}
.cst-location-name {
  font-size: 14px !important;
  color: rgba(0,0,0,.6) !important;
}
</style>
