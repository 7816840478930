<template>
  <Moveable
    ref="spriteParent"
    class="moveable"
    v-bind="isMoveable ? moveable : ''"
    @drag="handleDrag"
    @resize="handleResize"
    @scale="handleScale"
    @rotate="handleRotate"
    @warp="handleWarp"
  >
    <button
      :disabled="!isMoveable"
      ref="sprite"
      class="sprite"
      @click.stop="$emit('onselect', item.id)"
      :style="`width: 100%; height: 100%; background:rgba(0,0,0,0)`"
    >
      <slot></slot>
    </button>
  </Moveable>
</template>
<script>
/**
 *
 * moveable
 * https://daybrush.com/moveable/release/latest/doc/index.html
 *
 * サンプル
 * https://daybrush.com/moveable/
 */
import Moveable from "./Moveable.vue";
// import { BACKGROUND_IMAGE_SHIFT } from "../constants/POSITION";

export default {
  data() {
    return {
      active: true,
      rotatable: true,
      draggable: true,
      resizable: true,
      parent: false,
      moveable: {
        draggable: true,
        throttleDrag: 1,
        resizable: true,
        throttleResize: 1,
        keepRatio: true,
        scalable: false,
        throttleScale: 0.01,
        rotatable: true,
        throttleRotate: 0.2,
        pinchable: false,
        origin: true,
      },
    };
  },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    onUpdate: {
      type: Function,
      default: () => {},
    },
    mainCanvasRect: {
      type: Object,
      default: () => {
        return { x: 0, y: 0, height: 100, width: 100, scale: 1.0 };
      },
    },
    isRotatable: {
      type: Boolean,
      default: true,
    },
    acceptRatio: {
      type: Boolean,
      default: true,
    },
    isMoveable: {
      type: Boolean,
      default: true,
    }
  },
  mounted() {
    // セル情報とキャンバスサイズが変更されたらデータを正規化
    this.$watch(
      () => this.item,
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          const item = newValue;
          this.setInitialTransform(item);
          //zindex変更
          this.setZindex(item.transform?.z_index);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => [this.isRotatable, this.acceptRatio, this.selected],
      (values) => {
        let moveable = { ...this.moveable };
        moveable.rotatable = values[0];
        moveable.keepRatio = values[1];
        moveable.origin = values[0];
        this.moveable = moveable;
        this.isActive(values[2]);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    isActive(v) {
      const moveable = {
        ...this.moveable,
        draggable: v,
        scalable: v,
        rotatable: this.isRotatable ? v : false,
      };
      this.moveable = moveable;
    },
    setInitialTransform(item) {
      console.log({ ...item.transform });
      this.$refs.spriteParent.$el.style.width = `${item.transform.width}px`;
      this.$refs.spriteParent.$el.style.height = `${item.transform.height}px`;
      this.$refs.spriteParent.$el.style.left = `0px`;
      this.$refs.spriteParent.$el.style.top = `0px`;

      // const shift_x = item.transform.width / 2;
      // this.$refs.spriteParent.$el.style.transform = `translate(${
      //   item.transform.x -
      //   item.transform.width +
      //   shift_x +
      //   BACKGROUND_IMAGE_SHIFT.x
      // }px, ${
      //   item.transform.y - item.transform.height + BACKGROUND_IMAGE_SHIFT.y
      // }px) rotate(${item.transform.rotation}deg)`;
      this.$refs.spriteParent.$el.style.transform = `translate(${item.transform.x}px, ${item.transform.y}px) rotate(${item.transform.rotation}deg)`;
    },
    setZindex(n) {
      const z = n || this.item.transform?.z_index || 1;
      this.$refs.spriteParent.$el.style.zIndex = z;
    },
    // onDragEnd(event, transform) {
    //   this.onUpdate(this.item.id, transform);
    // },
    // onResizeEnd(event, transform) {
    //   this.onUpdate(this.item.id, transform);
    // },
    // onRotateEnd(event, transform) {
    //   this.onUpdate(this.item.id, transform);
    // },
    handleDrag({ target, transform }) {
      const translate = transform.match(/translate\(([0-9]+)px, ([0-9]+)px\)/); // 座標を取得
      target.style.transform = transform;
      let _transform = { ...this.item.transform };
      _transform = { ..._transform, x: translate[1], y: translate[2] };
      this.onUpdate(this.item.id, _transform);
    },
    handleResize({ target, width, height }) {
      target.style.width = `${width}px`;
      target.style.height = `${height}px`;
      let _transform = { ...this.item.transform };
      _transform = { ..._transform, width, height };
      this.onUpdate(this.item.id, _transform);
    },
    handleScale({ target, transform }) {
      target.style.transform = transform;
    },
    handleRotate({ target, transform }) {
      const translate = transform.match(
        /rotate\(([-+]?[0-9]+(\.[0-9]+)?)deg\)/
      );
      target.style.transform = transform;
      let _transform = { ...this.item.transform };
      _transform = { ..._transform, rotation: translate[1] };
      this.onUpdate(this.item.id, _transform);
    },
    handleWarp({ target, transform }) {
      target.style.transform = transform;
    },
  },
  components: {
    Moveable,
  },
};
</script>
<style lang="scss" scoped>
.sprite {
  border: none;
  pointer-events: all;
}
.image {
  width: 100%;
  pointer-events: none;
}
</style>
<style lang="scss">
.moveable-control-box:not([data-able-draggable]) {
  opacity: 0;
}
.moveable {
  top: 0;
  left: 0;
  position: absolute;
  pointer-events: all;
}
</style>
