<template>
  <div ref="self">
    <v-toolbar flat>
      <v-toolbar-title class="worksHeader-title primary--text">
        {{ pageTitle }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        class="ma-2"
        color="primary"
        @click="$emit('openDisplaySettingPanelForm')"
      >
        表示設定
      </v-btn>
      <v-btn
        depressed
        class="ma-2"
        color="primary"
        @click="$emit('zoomOut')"
      >
        サイネージ表示
      </v-btn>
    </v-toolbar>
    <v-divider class="mx-5 mb-3"></v-divider>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
export default {
  data: () => {
    return {
      isShowSearch: false,
    };
  },
  props: {
    pageTitle: {
      type: String,
    },
    updateHeader: {
      type: Object,
    },
  },

  mounted() {
    setTimeout(() => {
      this.updateHeaderHeight();
    }, 200);

    //検索フォームの開閉
    this.$watch(
      () => Store.getters["ScheduleSearch/getActive"],
      (value) => {
        this.isShowSearch = value;
        this.$nextTick(() => {
          this.updateHeaderHeight();
        });
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  methods: {
    updateHeaderHeight() {
      const height = this.$refs.self.getBoundingClientRect().height;
      this.updateHeader.setHeaderHeight(height);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.v-application--is-ltr .v-btn__content .v-icon--right,
.v-application--is-rtl .v-btn__content .v-icon--left {
  margin-left: 0px;
  margin-right: 0px;
}

.label {
  font-size: 8px;
}

.v-toolbar__title.worksHeader-title {
  font-size: 24px !important;
  font-weight: 600 !important;
}
</style>
