<template>
  <div class="wrapper">
    <wrapperWeb :back="back" :title="`ユーザー`">
      <div class="users">
        <div v-for="user in room.users" :key="user.id" class="user">
          <!-- <img :src="user.user_image_url" :alt="user.name" /> -->
          <div class="userImageWrapper">
            <UserImage :imageUrl="user.user_image_url" />
          </div>
          <div class="name">{{ user.name }}</div>
        </div>
      </div>
    </wrapperWeb>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import wrapperWeb from "../wrapper/components/web/index.vue";
import UserImage from "../chat/components/UserImage.vue";
export default {
  data: () => {
    return {
      chat_room_id: null,
      room: {},
    };
  },
  components: {
    wrapperWeb,
    UserImage,
  },
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    this.chat_room_id = this.$route.params.id;
    // ルーム取得
    this.$watch(
      () => Store.getters["TextChat/getRooms"],
      (newValue) => {
        if ("chatrooms" in newValue && newValue.chatrooms.length > 0) {
          const rooms = newValue.chatrooms;
          this.room = rooms.find((room) => {
            return room.id == this.chat_room_id;
          });
        }
      },
      { immediate: true, deep: true }
    );
  },
  methods: {
    back() {
      this.$router.push(`/chat/${this.chat_room_id}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  position: relative;
  height: 100%;
}
.users {
  .user {
    display: inline-block;
    width: 96px;
    height: 128px;
    padding: 8px;
    img {
      width: 72px;
      height: 72px;
      border-radius: 48px;
      border: 1px solid black;
    }
  }
  .name {
    text-align: center;
    font-size: 11px;
  }
}
.userImageWrapper {
  width: 72px;
  height: 72px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
