<template>
  <FormDialog>
    <template #header>
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title class="title-form" color="primary">
          {{ formTitle }}
        </v-toolbar-title>
        <div v-if="formValues.safety_diaries.patrol_record_name" class="shiteki-title-center" v-model="formValues.safety_diaries.patrol_record_name">
          {{ formValues.safety_diaries.patrol_record_name }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editable"
          class="mr-5"
          depressed
          small
          outlined
          color="primary"
          @click="isShowConfirmDialog = true"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable && isNewItem && tab > 0"
          depressed
          small
          :disabled="!valid || isSubmitted"
          color="primary"
          @click="onSubmit"
          :key="flagSubmit"
        >
          保存
        </v-btn>
        <v-btn
          v-if="editable && !isNewItem"
          depressed
          small
          :disabled="!valid || isSubmitted"
          color="primary"
          @click="onSubmit"
          :key="flagSubmit"
        >
          保存
        </v-btn>
        <v-btn
          v-if="editable && isNewItem && tab === 0"
          depressed
          small
          color="primary"
          @click="onNextTab"
        >
          次へ
        </v-btn>
        <v-btn
          v-if="!editable && isShowBtnEdit"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn
          class="ml-5"
          v-if="!isNewItem && editable && ATLEAST_ONE_NOT_CORRECTED"
          depressed
          small
          color="primary"
          @click="recoveryAll"
        >
          一括報告
        </v-btn>
        <v-btn
          class="ml-5"
          v-if="!isNewItem && !editable && ATLEAST_ONE_CORRECTED && (ROLE_MAIN_USER_EDIT || ROLE_SUBCONSTRACTOR_USER_EDIT_SHITEKI)"
          depressed
          small
          color="primary"
          @click="approveAll"
        >
          一括承認
        </v-btn>
        <v-btn icon @click="onClickBtnClose" data-testid="btn-close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab
              v-for="(form, key) in FORMS"
              :key="key"
            >
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <ValidationObserver ref="observer" v-slot="observer">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <MultipleShitekiFormTab1
              :editable="editable"
              :isNewItem="isNewItem"
              :item="formValues"
              :mainHeight="params.mainHeight"
              :APPROVED="APPROVED"
              @formUpdate="formUpdate"
              @showLoading="showLoading"
            />
          </v-tab-item>
          <v-tab-item>
            <MultipleShitekiFormTab2
              :editable="editable"
              :isNewItem="isNewItem"
              :item="formValues"
              :mainHeight="params.mainHeight"
              :APPROVED="APPROVED"
              :valid="valid"
              :backPreview="backPreview"
              :isShowMove="isShowMove"
              :disabledDoubleNext="disabledDoubleNext"
              :disabledDoublePrev="disabledDoublePrev"
              :recordId="recordId"
              @formUpdate="formUpdate"
              @onRecovery="onRecovery"
              @onChangeStatusCode="onChangeStatusCode"
              @actionSuccess="actionSuccess"
              @loading="setLoading"
            />
          </v-tab-item>
        </v-tabs-items>
        <ValidationCallback :observer="observer" @callback="updateValidate"/>
      </ValidationObserver>
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>
      <Popup width="480px" :dialog="isShowConfirmActionDialog">
        <ConfirmDialog
          :dialogText="dialogText"
          :isApproved="dialogText.flg"
          @close="isShowConfirmActionDialog = false"
          @yes="handleAction"
          :warning="false"
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import {ValidationObserver} from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import ConfirmDialog from "@/views/schedule/patrols/components/ConfirmDialog.vue";
import MultipleShitekiFormTab1
  from "@/components/forms/schedule/patrols/PaltrolForm/MultipleShitekiForm/MultipleShitekiFormTab1.vue";
import MultipleShitekiFormTab2
  from "@/components/forms/schedule/patrols/PaltrolForm/MultipleShitekiForm/MultipleShitekiFormTab2.vue";
import {
  INITIAL_ITEM_MULTIPLE_SHITEKI_FORM,
  DIALOG_APPROVE_TEXT_ALL,
  DIALOG_APPROVE_TEXT_OK,
  DIALOG_RECOVERY_TEXT,
  DIALOG_APPROVE_TEXT_ALL_ERROR
} from "@/constants/PATROL_RECORD";
import {Store} from "@/store/Store";
import _ from "lodash";
import {format} from "date-fns";

const TITLE = "複数指摘";
const STORE = "PatrolRecord";
const FORMS_CREATE = {
  MultipleShitekiFormTab1: {id: 1, title: "基本情報"},
  MultipleShitekiFormTab2: {id: 2, title: "指摘記録"}
};

const FORMS_UPDATE = {
  MultipleShitekiFormTab1: {id: 1, title: "基本情報"},
  MultipleShitekiFormTab2: {id: 2, title: "指摘記録"},
};

const STATUS_UNCORRECTED = 0;
const RECOVERY_UNCORRECTED = 0;
const STATUS_CORRECTED = 0;
const RECOVERY_CORRECTED = 1;
const STATUE_REJECTED = 2;
const RECOVERY_REJECTED = 1;
const STATUS_APPROVED = 1;
const RECOVERY_APPROVED = 1;


export default {
  data: () => {
    return {
      activeTab2: false,
      FORMS: FORMS_CREATE,
      valid: false,
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      isShowConfirmActionDialog: false,
      isClickCloseBtn: false,
      beforeUpdateItem: null,
      formValues: _.cloneDeep(INITIAL_ITEM_MULTIPLE_SHITEKI_FORM),
      isShowBtnEdit: false,
      dialogText: {},
      flagSubmit: false,
      backPreview: false,
      isSubmitted: false,
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    MultipleShitekiFormTab1,
    MultipleShitekiFormTab2,
    ConfirmCloseDialog,
    ConfirmDialog,
  },
  props: {
    isNewItem: Boolean,
    recordId: Number,
    construction_id: Number,
    isShowMove: Boolean,
    disabledDoubleNext: Boolean,
    disabledDoublePrev: Boolean,
  },
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
    if (!this.isNewItem) {
      this.tab = 1;
    }
    this.$watch(
      () => this.isNewItem,
      (data) => {
        if (data) {
          this.editable = true;
          const _formValues = {...this.formValues};
          _formValues.safety_diaries.field_construction_id = this.construction_id;
          this.formValues = {..._formValues};
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    
    this.$watch(
      () => this.tab,
      (data) => {
        if (data == 1) {
          this.activeTab2 = true;
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    
    this.$watch(
      () => Store.getters[`${STORE}/getDetail`],
      (data) => {
        if (this.recordId) {
          if (this.isShowTab3) {
            this.FORMS = FORMS_UPDATE;
          } else {
            this.FORMS = FORMS_CREATE;
          }
          this.formValues = _.cloneDeep(data);
          this.beforeUpdateItem = _.cloneDeep(this.formValues);
          this.isShowBtnEdit =
            (data.safety_diary_comment_corrections
                .filter(e => e.status_code === STATUS_UNCORRECTED && e.recovery_flag === RECOVERY_UNCORRECTED).length > 0 ||
              data.safety_diary_comment_corrections
                .filter(e => e.status_code === STATUS_CORRECTED && e.recovery_flag === RECOVERY_CORRECTED).length > 0 ||
              data.safety_diary_comment_corrections
                .filter(e => e.status_code === STATUE_REJECTED && e.recovery_flag === RECOVERY_REJECTED).length > 0);
        }
      },
      {
        immediate: true,
        deep: true
      },
    );
  },
  computed: {
    formTitle() {
      return TITLE;
    },
    checkFirstUpdate() {
      return this.formValues.first_update == 0
    },
    USER_LOGIN() {
      return Store.getters["Login/getUser"];
    },
    ROLE_MAIN_USER_EDIT() {
      let userRole = Store.getters["Report/getUserRole"];
      return (!this.isNewItem && userRole?.role === 1);
    },
    ROLE_SUBCONSTRACTOR_USER_EDIT_SHITEKI() {
      let userRole = Store.getters["Report/getUserRole"];
      let companyUser = JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user?.company_id;
      let fieldTreeCompanyId = this.formValues?.safety_diaries?.field_tree_company_id;
      return (
        !this.isNewItem &&
        userRole?.role !== 1 &&
        fieldTreeCompanyId === companyUser
      );
    },    
    APPROVED() {
      let countApprove = this.formValues.safety_diary_comment_corrections
        .filter(e => e.status_code === STATUS_APPROVED && e.recovery_flag === RECOVERY_APPROVED).length;
      let countComent = this.formValues.safety_diary_comment_corrections.length;
      return (!this.isNewItem && countApprove === countComent);
    },
    ATLEAST_ONE_CORRECTED() {
      let count =  this.formValues.safety_diary_comment_corrections
        .filter(e => e.status_code === STATUS_CORRECTED && e.recovery_flag === RECOVERY_CORRECTED).length;
      return count > 0;
    },
    ATLEAST_ONE_NOT_CORRECTED() {
      let count =  this.formValues.safety_diary_comment_corrections
        .filter(e => e.status_code === STATUE_REJECTED || (e.status_code === STATUS_UNCORRECTED && e.recovery_flag === RECOVERY_UNCORRECTED)).length;
      return count > 0;
    },
  },
  methods: {
    async getItems(id) {
      if (id) {
        await Store.dispatch(`${STORE}/getDetail`, id);
      }
    },
    formUpdate(params) {
      this.formValues = {...this.formValues, ...params};
    },
    setLoading(flg) {
      this.editable = false;
      this.$emit('loading', flg);
    },
    onEditable() {
      this.backPreview = false;
      this.FORMS = FORMS_UPDATE;
      this.editable = true;
    },
    onDetail() {
      this.editable = false;
    },
    showLoading(flg) {
      this.$emit('showLoading', flg);
    },
    
    async onSubmit() {
      this.isSubmitted = true;
      const hasId = "id" in this.formValues.safety_diaries;
      let _formValues = _.cloneDeep(this.formValues);
      _formValues.safety_diaries["safety_form"] = 2;
      _formValues.safety_diary_comment_corrections = _formValues.safety_diary_comment_corrections.filter(e => e.pointing_out_detail || e.field_item_tree_id || e.form_output_flag == 1);
      _formValues.safety_diary_comment_corrections.forEach(e => {
        delete e.recovery_date;
        delete e.recovery_user_id;
        e.safety_diary_images.forEach(e1 => {
          delete e1.check;
        });
      });
      const result = await Store.dispatch(hasId ? `PatrolRecord/update` : `PatrolRecord/post`, _formValues);
      if (result.hasError) {
        this.flagSubmit = !this.flagSubmit;
        this.isSubmitted = false;
        return;
      } else {
        if (hasId) {
          Store.dispatch("Toast/show", {
            status: 200,
            message: "更新しました"
          });
          await this.getItems(this.recordId);
          this.editable = false;
          this.$emit("actionSuccess", false);
        } else {
          Store.dispatch("Toast/show", {
            status: 200,
            message: "登録しました"
          });
          this.$emit("actionSuccess", true);
        }
      }
      this.isSubmitted = false;
    },
    
    updateValidate({valid}) {
      this.valid = valid;
    },
    
    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        if (this.beforeUpdateItem.first_update == 1) {
          this.FORMS = FORMS_CREATE;
        }
        this.isShowConfirmDialog = false;
        this.formValues = _.cloneDeep(this.beforeUpdateItem);
        this.backPreview = true;
        this.editable = false;
      } else {
        this.backPreview = true;
        this.$emit("cancel");
      }
    },
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
    recoveryAll() {
      this.dialogText = DIALOG_RECOVERY_TEXT;
      this.dialogText.action = 1;
      this.dialogText.flg = false;
      this.isShowConfirmActionDialog = true;
    },
    async actionRecoveryAll() {
      let _formValues = _.cloneDeep(this.formValues);
      _formValues.safety_diary_comment_corrections = _formValues.safety_diary_comment_corrections
        .filter(e => e.status_code === 2 || (e.status_code === 0 && e.recovery_flag === 0));
      _formValues.safety_diary_comment_corrections.forEach(e => {
        e.recovery_flag = 1;
        e.recovery_user_id = this.USER_LOGIN.id;
        let user_name = `${this.USER_LOGIN.name_sei} ${this.USER_LOGIN.name_mei}`;
        e.recovery_user_name = user_name;
        e.recovery_date = format(new Date(), "yyyy/MM/dd");
      });
      let temp = {
        report_flag: 0,
        safety_diary_id: this.recordId,
        safety_diary_comment_corrections: _.cloneDeep(_formValues.safety_diary_comment_corrections)
    }
      ;
      temp.safety_diary_comment_corrections.forEach(e => {
        delete e.status_code;
      });
      let rs = await Store.dispatch(`PatrolRecord/report`, temp);
      if (!rs.hasError) {
        this.isShowConfirmActionDialog = false;
        await this.getItems(this.recordId);
        Store.dispatch("Toast/show", {
          status: 200,
          message: "是正しました。"
        });
        this.editable = false;
        this.$emit("actionSuccess", false);
      }
    },
    handleAction() {
      if (this.dialogText.action == 1) {
        this.actionRecoveryAll();
      } else {
        this.actionApproveAll();
      }
    },
    approveAll() {
      if (this.formValues.safety_diary_comment_corrections.filter(e => e.recovery_flag == 1).length == 0) {
        this.dialogText = DIALOG_APPROVE_TEXT_ALL;
        this.dialogText.action = 2;
      } else {
        if (this.formValues.safety_diary_comment_corrections.filter(e => e.status_code == 0).length == 0) {
          this.dialogText = DIALOG_APPROVE_TEXT_ALL_ERROR;
        } else {
          this.dialogText = DIALOG_APPROVE_TEXT_OK;
        }
      }
      this.isShowConfirmActionDialog = true;
    },
    async actionApproveAll() {
      let temp = {
        safety_diary_ids: [this.recordId],
        safety_diary_comment_correction_ids: []
      };
      let params = {...temp, approve_flag: 1};
      const result = await Store.dispatch(`${STORE}/updateStatus`, params);
      if (!result.hasError) {
        this.$emit("actionSuccess", false);
        await this.getItems(this.recordId);
        Store.dispatch("Toast/show", {
          status: 200,
          message: "承認しました。"
        });
        this.editable = false;
        this.isShowConfirmActionDialog = false;
      }
    },
    async onRecovery(index) {
      let _formValues = _.cloneDeep(this.formValues);
      _formValues.safety_diary_comment_corrections[index].recovery_flag = 1;
      _formValues.safety_diary_comment_corrections[index].recovery_user_id = this.USER_LOGIN.id;
      let user_name = `${this.USER_LOGIN.name_sei} ${this.USER_LOGIN.name_mei}`;
      _formValues.safety_diary_comment_corrections[index].recovery_user_name = user_name;
      _formValues.safety_diary_comment_corrections[index].recovery_date = format(new Date(), "yyyy/MM/dd");
      let temp = {
        report_flag: 1,
        safety_diary_id: this.recordId,
        safety_diary_comment_corrections: _.cloneDeep([_formValues.safety_diary_comment_corrections[index]])
      };
      temp.safety_diary_comment_corrections.forEach(e => {
        delete e.status_code;
      });
      let rs = await Store.dispatch(`PatrolRecord/report`, temp);
      if (!rs.hasError) {
        this.formValues.safety_diary_comment_corrections = _formValues.safety_diary_comment_corrections;
        if (this.beforeUpdateItem.safety_diary_comment_corrections.length < index + 1) {
          this.beforeUpdateItem.safety_diary_comment_corrections.push({});
        }
        this.beforeUpdateItem.safety_diary_comment_corrections[index] = {...this.formValues.safety_diary_comment_corrections[index]};
        this.beforeUpdateItem.first_update = 0;
        let imageType1 = this.beforeUpdateItem.safety_diary_comment_corrections[index].safety_diary_images.filter(e => e.type == 1);
        let imageType2 = _formValues.safety_diary_comment_corrections[index].safety_diary_images.filter(e => e.type == 2);
        this.beforeUpdateItem.safety_diary_comment_corrections[index].safety_diary_images = [...imageType1, ...imageType2];
        await this.getItems(this.recordId);
        this.editable = false;
        Store.dispatch("Toast/show", {
          status: 200,
          message: "是正しました。"
        });
        this.$emit("actionSuccess", false);
      }
    },
    async onChangeStatusCode(flag, index, id) {
      let temp = {
        safety_diary_ids: [],
        safety_diary_comment_correction_ids: [id]
      };
      let status = flag ? 1 : 0;
      let params = {...temp, approve_flag: status};
      const result = await Store.dispatch(`${STORE}/updateStatus`, params);
      if (!result.hasError) {
        await this.getItems(this.recordId);
        Store.dispatch("Toast/show", {
          status: 200,
          message: flag ? "承認しました。" : "承認解除しました。"
        });
        this.$emit("actionSuccess", false);
        if (flag) {
          this.beforeUpdateItem.safety_diary_comment_corrections[index].status_code = 1;
          this.formValues.safety_diary_comment_corrections[index] = _.cloneDeep(this.beforeUpdateItem.safety_diary_comment_corrections[index]);
          let countComment = this.formValues.safety_diary_comment_corrections.length;
          let countCommentApprove = this.formValues.safety_diary_comment_corrections
            .filter(e => e.status_code === 1 && e.recovery_flag === 1).length;
          if (countComment === countCommentApprove) {
            this.isShowBtnEdit = false;
          }
        } else {
          this.formValues.safety_diary_comment_corrections[index].status_code = status;
          let countComment = this.formValues.safety_diary_comment_corrections.length;
          let countCommentApprove = this.formValues.safety_diary_comment_corrections
            .filter(e => e.status_code === 1 && e.recovery_flag === 1).length;
          if (countComment !== countCommentApprove) {
            this.isShowBtnEdit = true;
          }
        }
      }
    },
    openConfirmRecovery() {
      this.isShowConfirmRecoveryDialog = true;
    },
    onNextTab() {
      this.tab = this.tab + 1;
    },
    actionSuccess(flag) {
      this.$emit("actionSuccess", flag);
    }
  }
};
</script>

<style lang="scss" scoped>
.from-close-btn {
  float: right
}

.shiteki-title-center {
  width: calc(100% - 120px - 320px);
  text-align: center;
  font-size: 20px;
  color: #1b9c4f;
  overflow: hidden;
  text-indent: 4px;
  letter-spacing: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.title-form {
  width: 120px;
}
</style>
