<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <!-- col 1 -->
                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters v-if="isNewItem">
                    <v-col cols="12" sm="6">
                      <!-- 日付 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="日付（開始）" :editable="editable" required>
                            <InputDatepicker
                              name="use_date_from"
                              :values="formValues"
                              :editable="editable"
                              validation_label="日付（開始）"
                              :validation_rules="getStartDateRules()"
                              @onInput="onInput"
                              :flagNull="true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <!-- 日付 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="日付（終了）" :editable="editable" required>
                            <InputDatepicker
                              name="use_date_to"
                              :values="formValues"
                              :editable="editable"
                              validation_label="日付（終了）"
                              :validation_rules="getEndDateRules()"
                              @onInput="onInput"
                              :flagNull="true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-else>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="日付" :editable="editable" required>
                            <InputDatepicker
                              name="use_date"
                              :editable="editable"
                              :values="formValues"
                              validation_label="日付"
                              validation_rules="required"
                              @onInput="onInput"
                              :flagNull="true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col></v-col>
                  </v-row>

                  <v-list-item>
                    <v-list-item-content class="row-position">
                      <Label label="工事" :editable="editable" required>
                        <Select
                          class="select-position"
                          name="field_construction_id"
                          :editable="editable"
                          :values="formValues"
                          :items="FIELD_CONSTRUCTION_ARRAY"
                          validation_label="工事"
                          validation_rules="required"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content class="row-position">
                      <Label label="作業グループ" :editable="editable">
                        <Select
                          class="select-position"
                          name="group_id"
                          :values="formValues"
                          :items="Company_Items"
                          item_text="group_name"
                          :editable="editable"
                          validation_label="作業グループ"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="row-position">
                      <Label label="協力会社" :editable="editable" required>
                        <Select
                          class="select-position"
                          name="field_tree_id"
                          :values="formValues"
                          :items="PartnerCompanyItems"
                          item_text="company_name"
                          item_value="field_tree_id"
                          :editable="editable"
                          :label="formValues.field_tree_conpany_name"
                          validation_label="協力会社"
                          validation_rules="required"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="row-position">
                      <Label label="職長" :editable="editable" required>
                        <Select
                          class="select-position"
                          name="foreman_user_id"
                          :values="formValues"
                          :items="foremanList"
                          item_value="user_id"
                          :editable="editable"
                          :label="formValues.foreman_user_name"
                          validation_label="職長"
                          validation_rules="required"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>

                  <v-row no-gutters>
                    <v-col>
                      <Label label="職種" class="ml-4"></Label>
                    </v-col>
                    <v-col>
                      <Label label="経験年数" class="ml-4"></Label>
                    </v-col>
                  </v-row>
                  <v-main
                    v-for="(element, index) in UserExperience"
                    :key="index"
                  >
                    <v-row no-gutters>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <InputText
                              name="cmn_mst_skills_id"
                              :values="element"
                              :editable="false"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <InputText
                              name="experience"
                              :values="element"
                              :editable="false"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-main>
                </v-col>
                <!-- col 2 -->
                <v-col cols="12" sm="6" md="6">
                  <v-row class="row-position">
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label
                            label="作業時間帯"
                            :editable="editable"
                            required
                          >
                            <Select
                              class="select-position"
                              name="work_hour_zone"
                              :values="formValues"
                              :items="WORK_TIMEZONE_ARRAY"
                              item_text="name"
                              :editable="editable"
                              validation_label="作業時間帯"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col> </v-col>
                  </v-row>

                  <SectionLabel label="使用機材" class="ml-4 mb-4" />
                  <v-row class="row-position">
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="種別" width="100%" required :editable="editable">
                              <span @click="editable ? openFormMachineCompanyType() : null" style="cursor: pointer" class="select">
                                <span class="select_wrap">
                                  <Select
                                    name="machineType"
                                    :values="dataMachineType"
                                    :items="TYPE_DATA"
                                    :editable="editable"
                                    validation_label="種別"
                                    validation_rules="required"
                                  />
                                </span>
                              </span>
                            </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col> </v-col>
                  </v-row>

                  <v-list-item>
                    <v-list-item-content class="row-position" style="margin-top: -25px;">
                      <Label label="機材" :editable="editable" required>
                        <Select
                          class="select-position"
                          name="machine_id"
                          :values="formValues"
                          :items="MACHINE_ARRAYS_OF_TYPE"
                          :editable="editable"
                          item_value="machine_company_field_id"
                          validation_label="機材"
                          validation_rules="required"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>

                  <Label label="管理会社" class="ml-4" />
                  <v-list-item>
                    <v-list-item-content>
                      <InputText
                        name="company_name"
                        :values="formValues"
                        :editable="false"
                      />
                    </v-list-item-content>
                  </v-list-item>

                  <SectionLabel label="作業場所" class="ml-4" />
                  <v-list-item>
                    <v-list-item-content class="row-position" style="margin-top: -25px;">
                      <Label label="作業場所" :editable="editable">
                        <Select
                          class="select-position"
                          name="field_item_tree_id"
                          :values="formValues"
                          :items="ITEMS_TREE_ARRAY"
                          item_text="item_name"
                          :editable="editable"
                          validation_label="作業場所"
                          validation_rules=""
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
      <Popup v-if="isShowMachineCompanyType" :dialog="isShowMachineCompanyType">
        <MachineCompanyTypeForm
          @cancel="closeMachineCompanyTypeForm"
          @changeMachineCompanyType="changeMachineCompanyType"
          :machine_company_field_type_item_tree_id="formValues.machine_company_type_item_tree_ids"
          :machine_company_type_tree="MACHINE_COMPANY_FIELD_TYPE_ARRAYS"
        />
      </Popup>
    </div>
  </div>
</template>
<script>
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import Select from "@/components/forms/elements/Select.vue";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import { Store } from "@/store/Store.js";
import {
  OWNER_FLAG,
} from "@/constants/SCHEDULE_CRANE";
import MachineCompanyTypeForm from "@/components/forms/companyMachines/components/MachineCompanyTypeForm.vue";
import Popup from "@/components/common/Popup";
import _ from "lodash";
export default {
  data: () => {
    return {
      FIELD_CONSTRUCTION_ARRAY: [],
      formValues: {},
      WORK_TIMEZONE_ARRAY: [],
      Company_Items: [{ id: null, group_name: "" }],
      ITEMS_TREE_ARRAY: [],
      PartnerCompanyItems: [{ field_tree_id: null, company_name: "" }],
      UserItems: [{ user_id: null, name: "" }],
      MACHINE_ARRAYS_OF_TYPE: [{ machine_company_field_id: null, name: "" }],
      UserExperience: [],
      cmn_mst_skills: Store.state.CmnMst.constants.entries.cmn_mst_skills,
      dataMachine: [
        "maximum_work_floor_height",
        "movable_load",
        "body_length",
        "body_width",
        "body_height",
        "vehicle_weight",
        "outrigger_overhang_dimensions",
        "bucket_volume",
        "device_licences",
        "device_sp_trainings",
        "company_name",
        "prevention_measures",
        "characteristics"
      ],
      isShowMachineCompanyType: false,
      MACHINE_COMPANY_FIELD_TYPE_ARRAYS: [],
      TYPE_DATA: [{ id: null, name: "" }],
      machine_company_type_item_tree_id: null,
      dataMachineType: { machineType: null },
      machineType: null,
    };
  },
  components: {
    Label,
    SectionLabel,
    InputText,
    Select,
    InputDatepicker,
    MachineCompanyTypeForm,
    Popup,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    isNewItem: Boolean,
  },
  async mounted() {
    this.getWorkTypes();
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
        if(this.item['owner_flag'] == OWNER_FLAG.OWNER) {
          this.getListItemTrees(this.item.field_construction_id);
        } else {
          this.handleData();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => [
        this.formValues.field_construction_id,
        this.formValues.group_id,
        this.formValues.field_tree_id,
        this.formValues.machine_company_type_item_tree_ids,
        this.formValues.foreman_user_id,
      ],

      (newVal, oldVal) => {
        if(this.item['owner_flag'] == OWNER_FLAG.OWNER) {
          if (oldVal == undefined) {
            this.getListGroup(newVal[0]);
            this.getListWorkTimeZones(newVal[0]);
            this.getListCompanyPartner();
            if (newVal[1]) {
              this.getListUsers(newVal[2]);
              this.getListMachineByType(newVal[3]);
            }
          }
          if (oldVal != undefined) {
            if (newVal[0] != oldVal[0]) {
              this.getListGroup(newVal[0]);
              this.getListWorkTimeZones(newVal[0]);
              this.getListItemTrees(newVal[0]);
              this.ITEMS_TREE_ARRAY = [this.getDefaultArrays("id", "item_name")];
              this.PartnerCompanyItems = [this.getDefaultArrays("field_tree_id", "company_name")];
              this.UserItems = [this.getDefaultArrays("user_id", "name")];
              this.UserExperience = [];
              this.getListCompanyPartner();
            }
            if (newVal[1] != oldVal[1]) {
              this.getListCompanyPartner();
              this.UserItems = [this.getDefaultArrays("user_id", "name")];
              this.UserExperience = [];
            }
            if (newVal[2] != oldVal[2] && newVal[2]) {
              this.getListUsers(newVal[2]);
            }
            if (newVal[3] != oldVal[3]) {
              this.getListMachineByType(newVal[3]);
            }
            if(newVal[4] != oldVal[4] && newVal[4]) {
              this.getUserExperiences(newVal[4])
            }
          }
        } else {
          this.getListWorkTimeZones(newVal[0]);
          this.getListMachineByType(newVal[3]);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters[`CompanyMachines/getMachineCompanyByType`],
      (data) => {
        if (data) {
          let defaultArray = this.getDefaultArrays("machine_company_field_id", "name");
          this.MACHINE_ARRAYS_OF_TYPE = [defaultArray,...data];
        }
      },
      {
        deep: true
      }
    );

    await this.getMachineCompanyType();
    this.$watch(
      () => Store.getters['CompanyMachines/getMachineCompanyType'],
      (newValue) => {
        if(Array.isArray(newValue)){
          this.MACHINE_COMPANY_FIELD_TYPE_ARRAYS = _.cloneDeep(newValue);
          if(this.item.machine_company_type_item_tree_ids){
            this.getMachineCompanyFieldType(this.item.machine_company_type_item_tree_ids);
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    foremanList() {
      return this.UserItems.filter(e => e.state_handling_flag === 0 || e.user_id === null);
    },
  },
  methods: {
    getUserExperiences(foreman_user_id) {
      let experiences = this.UserItems.find(
        (element) => element.user_id == foreman_user_id
      )?.user_experiences;
      let array = [];
      experiences?.forEach((experience) => {
        let skill = this.cmn_mst_skills.find((element) => {
          return element?.id == experience.cmn_mst_skills_id;
        });
        if (skill) {
          array.push({
            cmn_mst_skills_id: skill.catergory + " " + skill.sub_catergory,
            experience: experience.experience,
          });
        }
      });
      this.UserExperience = [...array];
    },
    async getWorkTypes() {
      await Store.dispatch( "PortalChart/getWorkTypes", this.CURRENT_SITE.field_id);
      this.FIELD_CONSTRUCTION_ARRAY = [
        this.getDefaultArrays("id", "name"),
        ...Store.getters["PortalChart/getWorkTypes"],
      ];
    },
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      if (name == "field_construction_id") {
        formValues.work_hour_zone = null;
        formValues.group_id = null;
        formValues.field_tree_id = null;
        formValues.foreman_user_id = null;
        formValues.field_item_tree_id = null;
        formValues.device_users = [{field_director_id: null}];
      }
      if (name == "group_id") {
        if (!value && !formValues.group_id) return;
        formValues.field_tree_id = null;
        formValues.foreman_user_id = null;
        this.PartnerCompanyItems = [this.getDefaultArrays("field_tree_id", "company_name")];
        this.UserItems = [this.getDefaultArrays("user_id", "name")];
        this.UserExperience = [];
        formValues.device_users = [{field_director_id: null,},];
      }
      if (name == "field_tree_id") {
        if (!this.formValues['group_id'] && value) {
          let groupId = this.getGroupdIdByFieldTree(value);
          let hasGroup = this.hasGroupById(groupId);
          if (hasGroup) {
            formValues['group_id'] = groupId;
            this.getListCompanyPartner();
          }
        }
        formValues.foreman_user_id = null;
        this.UserItems = [this.getDefaultArrays("user_id", "name")];
        this.UserExperience = [];
      }
      if (name == "foreman_user_id") {
        this.UserExperience = [];
      }
      if (name == "machine_id") {
        let id = this.MACHINE_ARRAYS_OF_TYPE.find(e => e.machine_company_field_id == value)?.id;
        if (id) {
          this.getMachineInfoByMachineId(id);
        } else {
          this.dataMachine.forEach((e) => {
            formValues[e] = null;
          });
        }
      }
      formValues[name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },

    async getListGroup(field_construction_id) {
      if (field_construction_id) {
        let rs = await Store.dispatch(
          "PortalChart/getListGroups",
          field_construction_id
        );
        this.Company_Items = [this.getDefaultArrays("id", "group_name"),...rs.data.contents.entries];
      } else {
        this.Company_Items = [this.getDefaultArrays("id", "group_name")];
      }
    },

    async getListCompanyPartner() {
      if (!this.formValues.field_construction_id) return;
      let params = {state_handling_flag: 0 , display_branch_name_flag: 1};
      if (this.formValues["group_id"]) params.field_tree_group_id = this.formValues?.group_id;
      else if (this.formValues["field_construction_id"]) params.field_construction_id = this.formValues.field_construction_id;
      else this.PartnerCompanyItems = [this.getDefaultArrays("field_tree_id", "company_name")];
      const rs = await Store.dispatch("PortalChart/getListCompanyPartner", {params});
      this.PartnerCompanyItems = [this.getDefaultArrays("field_tree_id", "company_name"),...rs.data.contents.entries];
    },

    async getListWorkTimeZones(field_construction_id) {
      if (field_construction_id) {
        let rs = await Store.dispatch("PortalChart/getFieldTimeZones", field_construction_id);
        this.WORK_TIMEZONE_ARRAY = [
          this.getDefaultArrays("id", "name"),
          ...rs.data.contents.entries,
        ];
      } else {
        this.WORK_TIMEZONE_ARRAY = [ this.getDefaultArrays("id", "name")];
      }
      Store.dispatch(
        "ScheduleMachines/setWorkHouseZone",
        this.WORK_TIMEZONE_ARRAY[0].id
      );
    },

    async getMachineInfoByMachineId(machine_id) {
      let rs = await Store.dispatch("CompanyMachines/getDetail", machine_id);
      let machineinfor = { ...rs.data.contents.entries };
      const _formValues = { ...this.formValues };
      this.dataMachine.forEach((e) => {
        _formValues[e] = machineinfor[e];
        if( e == 'device_licences' ){
          _formValues['device_licences'] = machineinfor['machine_company_licenses'];
        }
         if( e == 'device_sp_trainings'){
           _formValues['device_sp_trainings'] = machineinfor['machine_company_sp_trainings'];
        }
      });
      this.$emit("formUpdate", _formValues);
    },

    async getListUsers(field_tree_id) {
      const params = { 
        id: field_tree_id,
        foreman_flag: 1
        };
      let rs = await Store.dispatch(
        "PortalChart/getListCompanyPartnerUser",
        params
      );
      this.UserItems = [...rs.data.contents.entries];
      this.UserItems.forEach((element) => {
        element.name = element.user_name_sei + " " + element.user_name_mei;
      });
      this.UserItems.unshift(this.getDefaultArrays("user_id", "name"));
      if (this.formValues.foreman_user_id) {
        this.getUserExperiences(this.formValues.foreman_user_id);
      }
    },

    async getListItemTrees(val) {
      if (val) {
        let params = {
          field_id: this.CURRENT_SITE.field_id,
          field_construction_id: val
        };
        let rs = await Store.dispatch("PortalChart/getListItemTrees", { params });
        let array = [...rs.data.contents.entries];
        array.unshift(this.getDefaultArrays("id", "item_name"));
        this.ITEMS_TREE_ARRAY = [...array];
      }
    },
    getDefaultArrays(id, value) {
      let results = {};
      results[id] = null;
      results[value] = "";
      return results;
    },
    handleData() {
      this.Company_Items = [{id: this.item.group_id, group_name: this.item.group_name || ""}];
      this.PartnerCompanyItems = [{field_tree_id: this.item.field_tree_id, company_name: this.item.field_tree_conpany_name}];
      this.getListUsers(this.item.field_tree_id);
    },

    async getMachineCompanyType(){
      const companyUser = this.CURRENT_SITE;
      await Store.dispatch(`CompanyMachines/getMachineCompanyType`, {
        company_id: companyUser.company_id,
        company_branch_id: companyUser.company_branch_id ? companyUser.company_branch_id : ""
      });
    },

    async openFormMachineCompanyType() {
      this.isShowMachineCompanyType = true;
    },

    getMachineCompanyFieldType(ids) {
      let arr = _.cloneDeep(ids);
      let machine_type_array = _.cloneDeep(this.MACHINE_COMPANY_FIELD_TYPE_ARRAYS);
      let rs = "";
      if (arr && arr.length > 0) {
        arr.forEach((e, index) => {
          let a1 = machine_type_array.find(e1 => e1.id == e);
          rs = index == 0 ? rs + a1.type : rs + "/" + a1.type;
          machine_type_array = a1.childrens;
        });
        this.dataMachineType.machineType = 1
        this.TYPE_DATA = [{ id: 1, name: rs }];
      } else {
        rs = "";
        this.dataMachineType.machineType = null
        this.TYPE_DATA = [{ id: null, name: rs }];
      }
    },
    changeMachineCompanyType(value){
      this.isShowMachineCompanyType = false;
      const formValues = { ...this.formValues };
      if(JSON.stringify(value) != JSON.stringify(formValues.machine_company_type_item_tree_ids)){
        formValues.machine_id = null;
        this.dataMachine.forEach((e) => {
          formValues[e] = null;
        });
        formValues.machine_company_type_item_tree_ids = value;
        if(value?.length > 0){
          this.machine_company_type_item_tree_id = value[value.length - 1];
        }
        this.getMachineCompanyFieldType(value);
        this.formValues = formValues;
        this.$emit("formUpdate", formValues);
      }
    },
    closeMachineCompanyTypeForm(){
      this.isShowMachineCompanyType = false;
    },
    async getMachineCompanyByType() {
      const params = {
        field_id: this.CURRENT_SITE.field_id,
        machine_company_type_item_tree_id: this.machine_company_type_item_tree_id
      }
      await Store.dispatch(`CompanyMachines/getMachineCompanyByType`, params);
    },

    async getListMachineByType(array){
      if(array||[].length > 0){
        const params = {
          field_id: this.CURRENT_SITE.field_id,
          machine_company_type_item_tree_id: array[array.length - 1]
        }
        await Store.dispatch(`CompanyMachines/getMachineCompanyByType`, params);
      }
    },
    getStartDateRules() {
      return this.formValues?.use_date_to
        ? "required|" +
            `compare-end-date:${String(this.formValues?.use_date_to).replaceAll(
              "-",
              "/"
            )}`
        : "";
    },
    getEndDateRules() {
      return this.formValues?.use_date_from
        ? "required|" +
            `compare-start-date:${String(this.formValues?.use_date_from).replaceAll(
              "-",
              "/"
            )}`
        : "";
    },
    getGroupdIdByFieldTree(fieldTreeId) {
      return this.PartnerCompanyItems.find(e => e.field_tree_id === fieldTreeId)?.field_tree_group_id || null;
    },
    hasGroupById(groupId) {
      if (!groupId) return false;
      let group = this.Company_Items.find(e => e.id === groupId);
      if (group) return true;
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
.row-position{
  margin-top: -15px;
  height: 150px;
  position: relative;
}
.select{
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.select_wrap{
  width: 100%;
  pointer-events: none;
}
.select-position{
  width: 100%;
  position: absolute;
}
</style>