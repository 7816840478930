import Vue from "vue";
import Vuex from "vuex";
import { scheduleMachines } from "../../../api/modules/scheduleMachines";
import { DEVICE_EMPTY } from "@/constants/SCHEDULE_MACHINE";

Vue.use(Vuex);

/**
 * apiを指定
 * 共通のapiは、Store.ScheduleWorksを参照する
 */
const ENTITY = scheduleMachines;

export const ScheduleMachines = {
  namespaced: true,

  state: {
    data: {},
    pagination: {},
    workHoursZone: null,
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_CHILD_DATA(state, payload) {
      const parent = state.data.devices.find(
        (item) => item.device_id == Number(payload.entries[0].device_id)
      );
      const children = [...parent.children, ...payload.entries];
      const data = { ...state.data };
      const devices = data.devices.map((item) => {
        if (item.device_id == Number(payload.entries[0].device_id)) {
          const _item = { ...item };
          _item.children = children;
          return _item;
        }
        return item;
      });
      data.devices = devices;
      state.data = data;
    },
    SET_WORK_HOURS_ZONE(state, payload) {
      state.workHoursZone = payload;
    },
    RESET_DATA(state) {
      state.data = {}
      state.pagination = {}
    }
  },

  actions: {
    //機材予定一覧
    async get({ commit }, payload) {
      const response = await ENTITY.getMachines(payload);
      if (response.hasError) {
        return response;
      }
      let { entries, pagination } = response.data.contents;
      if(!entries.devices) {
        entries = {devices: DEVICE_EMPTY, ...entries}
      }
      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },

    async loadChildren({ commit }, payload) {
      const response = await ENTITY.getScheduleMachinesChildren(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_CHILD_DATA", {
        entries,
        device_id: payload.device_id,
      });
      return response;
    },

    async approve(_context, payload) {
      const response = await ENTITY.postScheduleMachinesApprove(payload);
      return response;
    },

    //承認解除
    async disapprove(_context, payload) {
      const response = await ENTITY.postScheduleMachinesReject(payload);
      return response;
    },

    async getTimeframes({ dispatch }, { site_id }) {
      return dispatch("Schedule/getTimeframes", { site_id });
    },

    setWorkHouseZone({ commit }, payload) {
      commit("SET_WORK_HOURS_ZONE", payload);
    },

    async edit(_context, payload) {
      return await ENTITY.edit(payload);
    },

    async add(_context, payload) {
      return await ENTITY.add(payload);
    },
    async delete(_context, payload) {
      return await ENTITY.delete(payload);
    },
    async getDetail(_context, payload) {
      return await ENTITY.getDetail(payload);
    },
    async getMachineUseLastTime(_context, payload) {
      return await ENTITY.getMachineUseLastTime(payload);
    },
    resetData({ commit }) {
      commit("RESET_DATA");
    }
  },

  getters: {
    getData: (state) => {
      console.log("getData", state.data)
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getPartnerCompanies: (getters) => {
      return getters.scheduleMachines.partnerCompanies;
    },
    getLocations: (getters) => {
      return getters.scheduleMachines.locations;
    },
    getTimeframes: (getters) => {
      return getters.scheduleMachines.timeframes;
    },
    getWorkHouseZone: (state) => {
      return state.workHoursZone;
    },
  },
};
