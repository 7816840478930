import Vue from "vue";
import Vuex from "vuex";
import { sites } from "../../../api/modules/sites";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = sites;

export const Sites = {
  namespaced: true,

  state: {
    data: [],
    pagination: {},
    site: {},
    list_company_partner:[],
    list_company_rental: [],
    enterDayTime: null,
    userEnteringField:{},
    role: null,
    roleUser: null,
    document: {}
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_SITE(state, payload) {
      state.site = payload;
    },
    SET_LIST_COMPANY_PARTNER(state,payload){
      state.list_company_partner = payload;
    },
    SET_LIST_COMPANY_RENTAL(state,payload){
      state.list_company_rental = payload;
    },
    SET_ENTER_DAY_TIME(state, payload) {
      state.enterDayTime = payload;
    },
    SET_USER_ENTERING_FIELD(state, payload) {
      state.userEnteringField = payload;
    },
    SET_USER_ROLE(state, payload){
      state.roleUser = payload;
    },
    SET_ROLE(state, payload) {
      state.role = payload;
    },
    SET_DOCUMENTS(state, payload) {
      state.document = payload;
    }
  },

  actions: {
    async get({ commit }, payload) {
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;
      entries.forEach((element) => {

        element.field_tree_id = element.field_tree_id ? element.field_tree_id : element.field_id + "_" + element.field_tree_id;

        switch (element.nest_no) {
          case null:
            element.nest_no = "レンタル";
            break;
          case 0:
            element.nest_no = "元請";
            break;
          case 1:
            element.nest_no = "一次";
            break;
          case 2:
            element.nest_no = "二次";
            break;
          case 3:
            element.nest_no = "三次";
            break;
          case 4:
            element.nest_no = "四次";
            break;
          case 5:
            element.nest_no = "五次";
            break;
          default:
            element.nest_no = "";
        }
      });
      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },

    async getDetail({ commit }, payload) {
      const response = await ENTITY.getDetail(payload);
      const entries = response.data.contents.entries;
      commit("SET_SITE", entries);
      return response;
    },

    async edit(_context, payload) {
      return await ENTITY.edit(payload);
    },

    async add(_context, payload) {
      return await ENTITY.add(payload);
    },

    async delete(_context, payload) {
      return await ENTITY.delete(payload);
    },

    async getListCompanyPartner({ commit }, payload) {
      const response = await ENTITY.getListCompanyPartner(payload);
      const entries = response.data.contents.entries;
      commit("SET_LIST_COMPANY_PARTNER", entries);
      return response;
    },

    async getListCompanyRental({ commit }, payload) {
      const response = await ENTITY.getListCompanyRental(payload);
      const entries = response.data.contents.entries;
      commit("SET_LIST_COMPANY_RENTAL", entries);
      return response;
    },

    async getEnterDayTime({ commit }, payload) {
      const response = await ENTITY.getEnterDayTime(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_ENTER_DAY_TIME", entries);
      return response;
    },

    async getUserEnteringField({ commit }, payload){
      const response = await ENTITY.getUserEnteringField(payload);
      const entries = response.data;
      commit("SET_USER_ENTERING_FIELD", entries);
      return response;
    },

    async inviteMachineRentalCompany(_context, payload){
      return await ENTITY.inviteMachineRentalCompany(payload);
    },

    async deleteMachineRentalCompany(_context, payload){
      return await ENTITY.deleteMachineRentalCompany(payload);
    },

    async checkUserRole({ commit }, payload) {
      const result =await ENTITY.checkUserRole(payload);
      const entries= result.data.contents.entries;
      commit("SET_USER_ROLE", entries.role);
    },

    async getRole({ commit }, payload){
      const response = await ENTITY.getRole(payload);
      const entries = response.data.contents.entries.role;
      commit("SET_USER_ROLE", entries);
      commit("SET_ROLE", entries);
      return response;
    },

    async getDocument({ commit }, payload) {
      const response = await ENTITY.getDocument(payload);
      if (response.hasError) {
        return response;
      }
      const  document  = response.data.contents.entries;
      commit("SET_DOCUMENTS", document);
      return response;
    },
    async updateFieldDocument(_context, payload) {
      return await ENTITY.updateFieldDocument(payload);
    },
    async getFieldRuleDocument(_context, payload) {
      return await ENTITY.getFieldRuleDocument(payload);
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getSite: (state) => {
      return state.site;
    },
    getLocations: (state) => {
      return state.site.locations;
    },
    getListCompanyPartner:(state) => {
      return state.list_company_partner;
    },
    getListCompanyRental:(state) => {
      return state.list_company_rental;
    },
    getEnterDayTime: (state) => {
      return state.enterDayTime;
    },
    getUserEnteringField: (state) => {
      return state.userEnteringField;
    },
    getUserRole: (state) => {
      return state.roleUser;
    },
    getRole: (state) => {
      return state.role;
    },
    getDocument: (state) => {
      return state.document;
    },
  },
};
