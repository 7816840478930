<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <ValidationObserver>
            <v-form autocomplete="off">
              <v-container>
                <!-- master -->
                <v-row v-if="flag">
                  <v-col cols="12" sm="6" md="6">
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="現場名" :editable="editable" required>
                          <InputText
                            name="name"
                            :values="fieldValues"
                            :editable="editable"
                            placeholder="(仮称)オルガノ開発センター研究施設計画"
                            validation_label="現場名"
                            validation_rules="required|max:100"
                            @onInput="onInput"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="CCUS現場ID" :editable="editable">
                              <InputText
                                name="ccus_site_id"
                                :values="fieldValues"
                                :editable="editable"
                                placeholder=""
                                validation_label="CCUS現場ID"
                                validation_rules="digit|max:255"
                                @onInput="onInput"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    
                    <v-list-item v-if="!editable">
                      <v-list-item-content>
                        <Label label="工期" :editable="editable">
                          <v-text-field
                            v-model="timeComplete"
                            dense
                            color="primary"
                            :disabled="!editable"
                            :filled="editable"
                          ></v-text-field>
                        </Label>
                      </v-list-item-content>
                    </v-list-item>

                    <v-row v-if="editable">
                      <v-col sm="6" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="工期（着工）"
                              :editable="editable"
                              :required="isRequireStartDate"
                            >
                              <InputDatepicker
                                name="groundbreaking_date"
                                :editable="editable"
                                :values="fieldValues"
                                validation_label="工期（着工)"
                                id="groundbreaking_date"
                                :validation_rules="getStartDateRules"
                                :flagNull="true"
                                @onInput="onInput"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                      <v-col sm="6" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="工期（竣工）"
                              :editable="editable"
                              :required="isRequireEndDate"
                            >
                              <InputDatepicker
                                name="complete_schedule_date"
                                :editable="editable"
                                :values="fieldValues"
                                validation_label="工期（着工)"
                                id="complete_schedule_date"
                                :validation_rules="getEndDateRules"
                                :flagNull="true"
                                @onInput="onInput"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col sm="6" md="6" class="d-flex flex-row">
                        <v-list-item v-if="!editable">
                          <v-list-item-content>
                            <Label label="契約日">
                              <v-text-field
                                v-model="contractDate"
                                dense
                                color="primary"
                                :disabled="!editable"
                                :filled="editable"
                              ></v-text-field>
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col sm="6" md="6"> </v-col>
                    </v-row>

                    <v-row v-if="editable">
                      <v-col sm="6" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="契約日" :editable="editable">
                              <InputDatepicker
                                name="contract_due_date"
                                :editable="true"
                                :values="fieldValues"
                                :flagNull="true"
                                @onInput="onInput"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                      <v-col> </v-col>
                    </v-row>

                    <v-row>
                      <v-col sm="6" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <SectionLabel
                              label="送り出し教育"
                              v-if="!editable"
                            />
                            <Label
                              label="新規入場時教育実施報告書"
                              :editable="editable"
                            >
                              <span class="file"
                                v-if="
                                  !editable &&
                                  fieldValues.new_admission_education_implementation_report_url
                                "
                                @click="
                                  viewSourceWithAuth(fieldValues.new_admission_education_implementation_report_url)
                                "
                              >
                                {{
                                  fieldValues[
                                    "new_admission_education_implementation_report"
                                  ]
                                }}
                              </span>
                              <FileInput
                                :style="
                                  getFileInputStyle(
                                    'new_admission_education_implementation_report'
                                  )
                                "
                                v-if="editable"
                                :resetValue="
                                  resetValue[
                                    'new_admission_education_implementation_report'
                                  ]
                                "
                                name="new_admission_education_implementation_report"
                                :values="fieldValues"
                                :editable="editable"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                                placeholder="format.xlsx"
                                validation_label="new_admission_education_implementation_report"
                                validation_rules="maxsize:10|file-type:xlsx"
                                @onInput="onInput"
                              />
                              <span
                                class="file"
                                v-if="
                                  judgeDisplayFileInput(
                                    'new_admission_education_implementation_report'
                                  )
                                "
                              >
                                {{
                                  fieldValues[
                                    "new_admission_education_implementation_report"
                                  ]
                                }}

                                <v-btn
                                  small
                                  icon
                                  text
                                  @click="
                                    onRemoveFile({
                                      name: 'new_admission_education_implementation_report',
                                    })
                                  "
                                >
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </span>
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col sm="6" md="6"> </v-col>
                    </v-row>
                  </v-col>
                  <!-- col 2 -->
                  <v-col cols="12" sm="6" md="6" class="mt-3">
                    <v-row>
                      <v-col sm="6" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="郵便番号"
                              :editable="editable"
                              required
                            >
                              <InputText
                                name="postal_code"
                                :values="fieldValues"
                                :editable="editable"
                                placeholder="100-0001"
                                validation_label="郵便番号"
                                validation_rules="required|postcode"
                                @onInput="onChangePostalCode"
                              />
                            </Label>
                          </v-list-item-content> </v-list-item
                      ></v-col>
                      <v-col sm="6" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="都道府県"
                              :editable="editable"
                              required
                            >
                              <Select
                                name="field_prefectures_id"
                                :values="fieldValues"
                                item_text="prefecture"
                                :items="cmn_mst_prefectures"
                                :editable="editable"
                                @onInput="onInput"
                                validation_label="都道府県"
                                validation_rules="required"
                              />
                            </Label>
                          </v-list-item-content> </v-list-item
                      ></v-col>
                    </v-row>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="市区町村" :editable="editable" required>
                          <InputText
                            name="city"
                            :values="fieldValues"
                            :editable="editable"
                            placeholder="千代田区千代田"
                            validation_label="市区町村"
                            validation_rules="required|max:60"
                            @onInput="onInput"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label
                          label="番地・建物名"
                          :editable="editable">
                          <InputText
                            name="address"
                            :values="fieldValues"
                            :editable="editable"
                            placeholder="１−２"
                            validation_label="番地・建物名"
                            validation_rules="max:120"
                            @onInput="onInput"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                    <v-row class="mt-1">
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="北側座標境界緯度"
                              :editable="editable"
                              :isDisplay = false
                            >
                              <InputText
                                name="northern_boundary_latitude"
                                :values="fieldValues"
                                :editable="editable"
                                placeholder=""
                                validation_label="北側座標境界緯度"
                                :validation_rules="getRuleCoordinates(fieldValues.northern_boundary_latitude)"
                                @onInput="onInput"
                              />
                            </Label>
                            <Label
                              label="西側座標境界経度"
                              :editable="editable"
                              :isDisplay = false
                            >
                              <InputText
                                name="western_boundary_longitude"
                                :values="fieldValues"
                                :editable="editable"
                                placeholder=""
                                validation_label="西側座標境界経度"
                                :validation_rules="getRuleCoordinates(fieldValues.western_boundary_longitude)"
                                @onInput="onInput"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="南側座標境界緯度"
                              :editable="editable"
                              :isDisplay = false
                            >
                              <InputText
                                name="southern_boundary_latitude"
                                :values="fieldValues"
                                :editable="editable"
                                placeholder=""
                                validation_label="南側座標境界緯度"
                                :validation_rules="getRuleCoordinates(fieldValues.southern_boundary_latitude)"
                                @onInput="onInput"
                              />
                            </Label>
                            <Label
                              label="東側座標境界経度"
                              :editable="editable"
                              :isDisplay = false
                            >
                              <InputText
                                name="eastern_boundary_longitude"
                                :values="fieldValues"
                                :editable="editable"
                                placeholder=""
                                validation_label="東側座標境界経度"
                                :validation_rules="getRuleCoordinates(fieldValues.eastern_boundary_longitude)"
                                @onInput="onInput"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="中心緯度"
                              :editable="editable"
                              :isDisplay = false
                            >
                              <InputText
                                name="center_latitude"
                                :values="fieldValues"
                                :editable="editable"
                                validation_label="中心緯度"
                                :validation_rules="getRuleCoordinates(fieldValues.center_latitude)"
                                @onInput="onInput"
                              />
                            </Label>
                            <Label
                              label="中心経度"
                              :editable="editable"
                              :isDisplay = false
                            >
                              <InputText
                                name="center_longitude"
                                :values="fieldValues"
                                :editable="editable"
                                validation_label="中心経度"
                                :validation_rules="getRuleCoordinates(fieldValues.center_longitude)"
                                @onInput="onInput"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <Label
                      v-if="editable"
                      label="※ 境界座標の参考サイト:"
                      :editable="editable"
                    >
                      <a
                        class="file"
                        target="_blank"
                        href="https://psgsv.gsi.go.jp/koukyou/rect/"
                        >国土地理院
                        測量成果電子納品「業務管理項目」境界座標入力支援サービス</a
                      >
                    </Label>
                    <v-row class="mt-1">
                      <v-col sm="6" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="現場案内図（PDF）"
                              :editable="editable"
                            >
                              <span class="file"
                                v-if="
                                  !editable && fieldValues.field_map_file_url
                                "
                                @click="viewSourceWithAuth(fieldValues.field_map_file_url)"
                              >
                                {{ fieldValues["field_map_file"] }}
                              </span>
                              <FileInput
                                :style="getFileInputStyle('field_map_file')"
                                :resetValue="resetValue['field_map_file']"
                                v-if="editable"
                                name="field_map_file"
                                :values="fieldValues"
                                :editable="editable"
                                accept="application/pdf"
                                placeholder="map.pdf"
                                validation_label="field_map_file"
                                validation_rules="maxsize:10|file-type:pdf"
                                @onInput="onInput"
                              />
                              <span
                                class="file"
                                v-if="judgeDisplayFileInput('field_map_file')"
                              >
                                {{ fieldValues["field_map_file"] }}

                                <v-btn
                                  small
                                  icon
                                  text
                                  @click="
                                    onRemoveFile({
                                      name: 'field_map_file',
                                    })
                                  "
                                >
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </span>
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col sm="6" md="6"> </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- dashboard -->
                <v-row v-else>
                  <v-col cols="12" sm="6" md="6">
                    <SectionLabel label="作業時間帯" class="ml-4 mb-3" />
                    <v-list-item>
                      <v-list-item-content>
                        <WorkingTimeZone
                          name="field_time_zones"
                          :values="formValues"
                          :editable="editable"
                          @onInput="onInput"
                        />
                      </v-list-item-content>
                    </v-list-item>
                    <v-row class="mt-1">
                      <v-col cols="12" sm="5" md="5" no>
                        <Label label="1日の労働時間" class="ml-4"/>
                        <v-list-item>
                          <v-list-item-content>
                            <Select
                              name="working_time"
                              :values="fieldValues"
                              :items="WORKING_TIME_ARRAY"
                              :editable="editable"
                              @onInput="onInput"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" sm="5" md="5" no>
                        <Label label="日またぎの時間" class="ml-4"/>
                        <v-list-item>
                          <v-list-item-content>
                            <Select
                              name="enter_day_time"
                              :values="fieldValues"
                              :items="ENTER_DAY_TIME_ARRAY"
                              :editable="editable"
                              @onInput="onInput"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <SectionLabel label="工事" class="ml-4 mb-3" />
                    <v-list-item>
                      <v-list-item-content>
                        <SiteConstructions
                          name="field_constructions"
                          :values="formValues"
                          :editable="editable"
                          @onInput="onInput"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import { Store } from "@/store/Store.js";
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import Select from "@/components/forms/elements/Select.vue";
import FileInput from "@/components/forms/elements/FileInput";
import SiteConstructions from "./components/SiteConstructions.vue";
import WorkingTimeZone from "./components/WorkingTimeZone.vue";
import { fmtSlashDate } from "@/utils/timeUtil";
import _ from "lodash";
import { postalCodeSearch } from "@/utils/postalCodeSearch";
import { WORKING_TIME_ARRAY } from "@/constants/SITES";
import { ENTER_DAY_TIME_ARRAY } from "@/constants/SITES";
import { viewSourceWithAuth } from "@/utils/viewSourceAuth";
import SwitchInput from "@/components/forms/elements/SwitchInput";

export default {
  data: () => {
    return {
      cmn_mst_prefectures: [
        { id: null, prefecture: "" },
        ...Store.state.CmnMst.constants.entries.cmn_mst_prefectures,
      ],
      fieldValues: {},
      fileValid: {},
      formValues: {},
      timeComplete: "",
      contractDate: "",
      resetValue: {},
      isRequireStartDate: true,
      isRequireEndDate: false,
      WORKING_TIME_ARRAY,
      ENTER_DAY_TIME_ARRAY,
      viewSourceWithAuth: viewSourceWithAuth,
    };
  },
  components: {
    SiteConstructions,
    ValidationObserver,
    SectionLabel,
    Label,
    InputText,
    InputDatepicker,
    Select,
    FileInput,
    WorkingTimeZone,
    SwitchInput,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    errors: {
      type: Object,
      default: {},
    },
    flag: Boolean
  },
  mounted() {
    this.$watch(
      () => this.item,
      (data) => {
        this.initData(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );

    // check input file error
    this.$watch(
      () => [this.item, this.errors],
      (data) => {
        this.formValues = { ...data[0] };
        const errors = { ...data[1] };
        // the validation_label list of the FileInput element
        const names = [
          "new_admission_education_implementation_report",
          "field_map_file",
        ];
        const fileValid = { ...this.fileValid };
        const formValues = _.cloneDeep(this.formValues);
        if (errors) {
          for (let name in errors) {
            if (names.some((item) => item === name)) {
              if (errors[name].length > 0) {
                delete formValues.fields[name];
                delete formValues.fields[`${name}_data`];
                fileValid[name] = false;
              } else {
                fileValid[name] = true;
              }
            }
          }
        }
        this.formValues = formValues;
        this.fileValid = fileValid;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    /**
     * (共通)
     * 定数をapi/ストアからまとめて取得します
     *
     * this.CONSTANTS.roll
     * こうやって個々の定数を取得します
     */
    CONSTANTS() {
      return Store.getters["Constants/get"];
    },
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },

    getStartDateRules() {
      return "required";
    },
    getEndDateRules() {
      return this.formValues.fields.complete_schedule_date && this.formValues.fields.groundbreaking_date
        ? `compare-to-start-date:${this.formValues.fields.groundbreaking_date.replaceAll(
              "-",
              "/"
            )}`
        : "";
    },
  },
  methods: {
    initDataTime(formValues) {
      const startDate = formValues["groundbreaking_date"]
        ? fmtSlashDate(formValues["groundbreaking_date"]).date
        : "";
      const endDate = formValues["complete_schedule_date"]
        ? fmtSlashDate(formValues["complete_schedule_date"]).date
        : "";
      this.contractDate = formValues["contract_due_date"]
        ? `${fmtSlashDate(formValues["contract_due_date"]).date}`
        : "";
      if (startDate || endDate) {
        this.timeComplete = `${startDate}〜${endDate}`;
      } else {
        this.timeComplete = "";
      }
    },

    initData(data) {
      const formValues = data;
      this.fieldValues = formValues.fields;
      this.formValues = { ...formValues };
      this.initDataTime(this.fieldValues);
    },
    onRemoveFile({ name }) {
      const formValues = { ...this.formValues };
      delete formValues.fields[name];
      delete formValues.fields[`${name}_data`];
      if (formValues.fields[`${name}_url`])
        formValues.fields[`${name}_deleted`] = 1;
      this.formValues = formValues;
      this.resetValue[name] = !this.resetValue[name];
      this.$emit("formUpdate", formValues);
    },

    /**
     * 郵便番号から都道府県、市町村名を検索
     */
    async onChangePostalCode({ value }) {
      const formValues = { ...this.formValues };
      formValues.fields.postal_code = value;
      if (value == "") {
        formValues.fields.field_prefectures_id = "";
        formValues.fields.city = "";
      } else if (value.match(/^\d{3}-?\d{4}$/g)) {
        const result = await postalCodeSearch.search({ postal_code: value });
        if (result) {
          formValues.fields.field_prefectures_id = result.prefecture.id;
          formValues.fields.city = result.city;
        }
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },

    onInput({ name, value, fileName }) {
      const formValues = { ...this.formValues };
      if (fileName) {
        formValues["fields"][`${name}_data`] = value;
        formValues["fields"][name] = fileName;
      } else {
        if (name == "field_constructions") {
          formValues["field_constructions"] = value;
        } else if (name == "field_time_zones") {
          formValues["field_time_zones"] = value;
        } else {
          formValues["fields"][name] = value;
        }
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    getFileInputStyle(fieldName) {
      return this.fileValid[fieldName] && this.fieldValues[fieldName]
        ? "display:none;"
        : "";
    },
    judgeDisplayFileInput(fieldName) {
      return (
        this.editable &&
        this.fileValid[fieldName] &&
        this.fieldValues[fieldName]
      );
    },
    getRuleCoordinates(value) {
      if (!String(value).match(/^[-+]?[0-9]+\.[0-9]+$/)) {
        return 'digit|max:16';
      } else {
        return 'type-number-coordinates|max:16';
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
</style>
