<template>
  <FormDialog>
    <template #header>
      <!--
        (共通)
        PC ヘッダーは全て共通
       -->
      <FormWorkNavi
        v-if="!$sp"
        v-model="tab"
        :tab="tab"
        :editable="editable"
        :workApprovalFlag="workApprovalFlag"
        :stateHandlingFlag="stateHandlingFlag"
        :isTablesGroup="isTablesGroup"
        :isNewItem="isNewItem"
        :isShowButtonCopy="isShowButtonCopy"
        :isShowConfirmDialog="isShowConfirmDialog"
        :isShowScheduleDialog="isShowScheduleDialog"
        :formtitle="formtitle"
        :FORMS="FORMS"
        :valid="valid"
        :onCopy="onCopy"
        :onEditable="onEditable"
        :onSubmit="onSubmit"
        :onClickBtnClose="onClickBtnClose"
        :statusEditable="statusEditable"
        @onShowScheduleDialog="
          () => {
            isShowScheduleDialog = true;
          }
        "
        @onCloseConfirmDialog="
          () => {
            isShowConfirmDialog = true;
          }
        "
      />
      <!--
        SP ナビゲーション
      -->
      <SpFormWorkNavi
        v-if="$sp"
        v-model="tab"
        :tab="tab"
        :editable="editable"
        :workApprovalFlag="workApprovalFlag"
        :stateHandlingFlag="stateHandlingFlag"
        :isTablesGroup="isTablesGroup"
        :isNewItem="isNewItem"
        :isShowButtonCopy="isShowButtonCopy"
        :isShowConfirmDialog="isShowConfirmDialog"
        :isShowScheduleDialog="isShowScheduleDialog"
        :formtitle="formtitle"
        :FORMS="FORMS"
        :valid="valid"
        :onCopy="onCopy"
        :onEditable="onEditable"
        :onSubmit="onSubmit"
        :onClickBtnClose="onClickBtnClose"
        @onShowScheduleDialog="
          () => {
            isShowScheduleDialog = true;
          }
        "
        @onCloseConfirmDialog="
          () => {
            isShowConfirmDialog = true;
          }
        "
      />
    </template>
    <template #main="{ params }">
      <ValidationObserver ref="observer" v-slot="observer">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <FormScheduleWorkTab1
              :editable="editable"
              :item="formValues"
              :isNewItem="isNewItem"
              :resetFormImage="resetFormImage"
              :disableData="disableData"
              :mainHeight="params.mainHeight"
              :roleUser="roleUser"
              :errors="errors"
              :isBackData="isBackData"
              :copyObject="copyObject"
              :arrLevel1="arrLevel1"
              @initialObjectCopy="initialObjectCopy"
              @formUpdate="formUpdate"
              :isParentEditable="isParentEditable"
            />
          </v-tab-item>
          <v-tab-item>
            <FormScheduleWorkTab2
              :editable="editable"
              :item="formValues"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <FormChangeHistory
              :editable="editable"
              :item="formValues"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
        </v-tabs-items>
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>
      <Popup width="480px" :dialog="isShowScheduleDialog">
        <ConfirmScheduleDialog
          @close="isShowScheduleDialog = false"
          @yes="updateStateHandling"
          :title="getTitle()"
          :text="getText()"
          :textBtn="getTextBtn()"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import ConfirmScheduleDialog from "../components/ConfirmScheduleDialog.vue";
import FormScheduleWorkTab1 from "./FormScheduleWorkTab1.vue";
import FormScheduleWorkTab2 from "./FormScheduleWorkTab2.vue";
import FormChangeHistory from "../FormChangeHistory";
import FormWorkNavi from "../FormWorkNavi.vue";
import SpFormWorkNavi from "../SpFormWorkNavi.vue";
import { Store } from "@/store/Store.js";
import { SCHEDULE_WORKS_INITIAL_ITEM_OBAYASHI, USER_TYPE } from "@/constants/SCHEDULE_WORKS";
import _ from "lodash";
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON";
import {ENV_CLIENT} from "@/constants/ENV_CLIENT";

/**
 * (共通)
 * タイトル
 */
const TITLE = {
  SCHEDULE_WORK: "作業予定情報",
  RECORD_WORK: "作業実績情報",
};

/**
 * フォームとタブの設定
 */
const FORMS = {
  FormScheduleWorkTab1: { id: 1, title: "基本情報" },
  FormScheduleWorkTab2: { id: 2, title: "その他帳票" },
  FormScheduleWorkTab3: { id: 3, title: "登録・変更履歴" },
};
const IS_OBAYASHI = process.env.VUE_APP_CLIENT === ENV_CLIENT.OBAYASHI;

export default {
  data: () => {
    return {
      FORMS,
      valid: false, //バリデーション結果
      errors: {},
      // tab初期化
      tab: 0,
      editable: false,
      isShowConfirmDialog: false,
      isShowScheduleDialog: false,
      isClickCloseBtn: false,
      formValues: {},
      editedItem: {},
      beforeUpdateItem: {},
      resetFormImage: false,
      isShowButtonCopy: false,
      stateHandlingFlag: 0,
      workApprovalFlag: false,
      disableData: false,
      isCompleteEdit: false,
      isSubmitted: false,
      timeout: null,
      statusEditable : 1,
      roleUser : null,
      isBackData : false,
      copyObject : {
        status : false,
        flag : true,
        changeConstruction : false
      },
      arrLevel1 : []
    };
  },
  components: {
    FormChangeHistory,
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    FormScheduleWorkTab1,
    FormScheduleWorkTab2,
    ConfirmScheduleDialog,
    SpFormWorkNavi,
    FormWorkNavi
  },
  props: {
    isNewItem: Boolean,
    workGroupPlans: Object,
    scheduleFlag: Number,
    isWorkPlan: Boolean,
    isTablesGroup: Boolean,
    isParent: Boolean,
    isMyWork: Boolean,
    isParentEditable: Number,
  },
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    this.checkRoleUser(this.CURRENT_SITE.field_id);
    /**
     * (共通)
     * 新規作成を監視
     */
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        //新規の場合は最初から編集モード
        if (flag) {
          this.editable = true;
          this.isShowButtonCopy = true;
          this.formValues = _.cloneDeep(SCHEDULE_WORKS_INITIAL_ITEM_OBAYASHI);
          this.formValues.work_group_plans.field_construction_id = this.workGroupPlans.field_construction_id;
          this.formValues.work_group_plans.work_hour_zone = this.workGroupPlans.work_hour_zone;
          this.formValues.work_group_plans.work_date_start = this.workGroupPlans.start_date;
          this.formValues.work_group_plans.work_date_end = this.workGroupPlans.end_date;
          this.formValues.work_plans.addChild = this.workGroupPlans.addChild;
          if(this.workGroupPlans.addChild){
            this.formValues.work_plans.job_id = this.workGroupPlans.job_id;
            this.formValues.work_plans.cmn_mst_skill_id = this.workGroupPlans.cmn_mst_skill_id;
            this.formValues.work_plans.field_tree_id = this.workGroupPlans.field_tree_id;
            this.formValues.work_plans.field_tree_id_1 = this.workGroupPlans.field_tree_id_1;

          }
          if (this.workGroupPlans.work_group_plan_id) {
            this.formValues.work_group_plans.id = this.workGroupPlans.work_group_plan_id;
            this.disableData = true;
          } else {
            this.disableData = false;
          }
        } else {
          this.disableData = !this.isParent ? true : false;
          this.initData(this.workGroupPlans.id);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Sites/getRole"],
      (data) => {
        this.roleUser = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    // 新規or編集でタイトル変更
    formtitle() {
      return this.scheduleFlag == 0 ? TITLE.RECORD_WORK : TITLE.SCHEDULE_WORK;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },
  methods: {
    initialObjectCopy(statusAddChild) {
      this.copyObject = {
        status: false,
        flag: true,
        changeConstruction: false,
        statusAddChild: statusAddChild
      };
    },
    async checkRoleUser(field_id){
      const params = {
        field_id,
        target_role: []
      };
      await Store.dispatch("Sites/getRole", params);
    },
    /**
     * (共通)
     * フォームの変更を送信
     */
    formUpdate(params) {
      this.formValues = { ...params };
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      this.isBackData = false;
      this.editable = true;
    },
    /**
     * (共通)
     * 状態を詳細に変更
     */
    onDetail() {
      this.editable = false;
    },

    /**
     * (共通)
     * 登録
     */
    async onSubmit() {
      this.isSubmitted = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isSubmitted = false;
      }, SUBMIT_DELAY_TIMEOUT);

      const hasId = "id" in this.formValues.work_plans;
      const editedItem = _.cloneDeep(this.formValues);
      editedItem['is_oba'] = 1;
      delete editedItem.editable;
      editedItem.work_classification_plans.forEach((item) => {
        delete item["occupation"];
      });
      this.editedItem = editedItem;
      const result = await Store.dispatch(
        //idがある場合は更新、ない場合は新規
        hasId ? `ScheduleWorks/update` : `ScheduleWorks/post`,
        this.editedItem
      );
      //レスポンスエラーがある場合はフォームを閉じない
      //エラーメッセージは、api.jsが表示
      if (result.hasError) {
        return;
      } else {
        if (hasId) {
          Store.commit(`ScheduleWorks/SET_FETCH`, 'update')
          this.getItems(this.formValues.work_plans.id);
          this.onDetail();
          this.isCompleteEdit = true;
          this.$emit("cancel");
        } else {
          Store.commit(`ScheduleWorks/SET_FETCH`, 'onSearch')
          this.$emit("cancel");
        }
        //成功したら値を更新
        Store.dispatch("Toast/show", {
          status: 200,
          message: hasId ? "更新しました" : "登録しました",
        });
      }
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid, errors }) {
      this.valid = valid;
      this.errors = errors;
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        this.formValues = _.cloneDeep(this.beforeUpdateItem);
        this.isBackData = true;
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },

    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel", this.isCompleteEdit);
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },

    async getItems(id) {
      let params = {
        id: id,
        is_oba: 1
      };
      const result = await Store.dispatch(`ScheduleWorks/getDetailObayashi`, { params });
      let data = {...result.data.contents.entries};
      if (data.work_user_plans[USER_TYPE.TYPE_1].length > 0) {
        data["work_plans"]["work_user_plans_manager_id"] = data.work_user_plans[USER_TYPE.TYPE_1][0].user_id;
      } else {
        data["work_plans"]["work_user_plans_manager_id"] = null;
      }
      if (data["work_keyplan_image_plans"].length == 0) {
        data["work_keyplan_image_plans"] = [{ image: null }];
      }
      this.formValues = _.cloneDeep(data);
      this.beforeUpdateItem = _.cloneDeep(data);
      this.stateHandlingFlag = this.formValues.work_plans.state_handling_flag;
      this.workApprovalFlag = this.formValues.work_plans.work_approval_flag == 0  && this.isMyWork==1? true : false;
    },

    async initData(id) {
      await this.getItems(id);
      if (
        this.isWorkPlan &&
        this.workApprovalFlag &&
        this.stateHandlingFlag == 0 &&
        this.isTablesGroup
      ) {
        this.tab = null;
        this.editable = true;
      }
    },

    async onCopy() {
      const params = { field_id: this.CURRENT_SITE.field_id, is_oba: 1 };
      const result = await Store.dispatch(`ScheduleWorks/getWorkLastTime`, { params });
      if (!result.hasError && result.data.contents.entries) {
        let data = _.cloneDeep(result.data.contents.entries);
        let work_group_plans_id = this.formValues.work_group_plans.id;
        data = { ...this.formValues, ...data };
        data.work_group_plans.id = work_group_plans_id;
        //save data date end work keyplan image plans in this
        data["work_group_plans"]["work_date_start"] = this.formValues["work_group_plans"]["work_date_start"];
        data["work_group_plans"]["work_date_end"] = this.formValues["work_group_plans"]["work_date_end"];
        data["work_keyplan_image_plans"] = this.formValues["work_keyplan_image_plans"];
        this.copyObject.status = true;
        //if disable data vs construction_id change => save construction id and data
        if(this.disableData){
          data.work_plans.field_tree_id_1 = this.formValues.work_plans.field_tree_id_1;
          data.work_plans.field_tree_id = this.formValues.work_plans.field_tree_id;
          data.work_plans.job_id = this.formValues.work_plans.job_id;
          data.work_plans.cmn_mst_skill_id = this.formValues.work_plans.cmn_mst_skill_id;
        }
        if (this.disableData && data.work_group_plans.field_construction_id !== this.formValues.work_group_plans.field_construction_id) {
          this.copyObject.flag = false;
          data.work_group_plans.field_construction_id = this.formValues.work_group_plans.field_construction_id;
          data.work_plans.field_tree_user_id = this.formValues.work_plans.field_tree_user_id;
          data.work_plans.field_tree_id = this.formValues.work_plans.field_item_tree_id;
          data.work_user_plans[USER_TYPE.TYPE_1] = this.formValues.work_user_plans[USER_TYPE.TYPE_1];
        }
        if (!this.disableData && data.work_group_plans.field_construction_id !== this.formValues.work_group_plans.field_construction_id) {
          this.copyObject.changeConstruction = true;
        }
        data.work_plans.work_user_plans_manager_id = data.work_user_plans[USER_TYPE.TYPE_1][0].user_id;
        this.formValues = _.cloneDeep(data);
      } else {
        this.initDataNewItem();
      }
      this.resetFormImage = !this.resetFormImage;
    },

    async updateStateHandling() {
      const apiParams = {
        work_plan_id: this.formValues?.work_plans.id,
        state_handling_flag: this.stateHandlingFlag == 0 ? 1 : 0,
        is_oba: IS_OBAYASHI ? 1 : null
      };
      await Store.dispatch(`ScheduleWorks/updateStateHandling`, apiParams);
      this.isShowScheduleDialog = false;
      this.$emit("cancel");

      Store.commit(`ScheduleWorks/SET_FETCH`, 'update')
    },
    getTitle() {
      return this.stateHandlingFlag == 0 ? "予定の中止" : "中止取消の確認";
    },
    getText() {
      return this.stateHandlingFlag == 0
        ? "作業予定を中止します"
        : "中止した作業予定を解除してもよろしいですか。";
    },
    getTextBtn() {
      return this.stateHandlingFlag == 0 ? "中止" : "解除";
    },
    async initDataNewItem() {
      const data = _.cloneDeep(SCHEDULE_WORKS_INITIAL_ITEM_OBAYASHI);
      if (this.workGroupPlans.field_construction_id == this.formValues.work_group_plans.field_construction_id) {
        let listCompanyPartnerLevel1 = [...Store.getters["PortalChart/getListCompanyPartnerLevel1"]];
        data.work_plans.field_tree_id_1 = listCompanyPartnerLevel1[0].field_tree_id;
        let params = { field_tree_id_1: data.work_plans.field_tree_id_1 };
        await Store.dispatch("PortalChart/getListSkills", { params });
      } else {
        const params = { field_construction_id: this.workGroupPlans.field_construction_id };
        let rs = await Store.dispatch("PortalChart/getListCompanyPartnerLevel1", { params });
        let arr = _.cloneDeep(rs.data.contents.entries);
        this.arrLevel1 = [...arr];
        data.work_plans.field_tree_id_1 = arr[0].field_tree_id || null;
      }
      data.work_group_plans.field_construction_id = this.workGroupPlans.field_construction_id;
      data.work_group_plans.work_hour_zone = this.workGroupPlans.work_hour_zone;
      data.work_group_plans.work_date_start = this.workGroupPlans.start_date;
      data.work_group_plans.work_date_end = this.workGroupPlans.end_date;
      if(this.disableData) {
        data.work_group_plans = this.formValues.work_group_plans;
      }
      this.formValues = _.cloneDeep(data);
    },
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
  },

  /**
   * Important: clear timeout
   */
  beforeDestroy() {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout);
  },
};
</script>

<style lang="scss" scoped>
</style>
