<template>
  <v-container>
    <NewWorkerDataTable
      :tableLabels="tableLabels"
      :items="items"
      :isNoDataMessage="isNoDataMessage"
      :items-per-page="itemsPerPage"
      :tableHeight="tableHeight"
      :isShowChildren="isShowChildren"
      :toggleShowChildren="toggleShowChildren"
      :childTableDisplayMoreNumber="childTableDisplayMoreNumber"
      :NO_DATA_MESSAGE="NO_DATA_MESSAGE"
      @getChildItems="getChildItems"
    />
  </v-container>
</template>
<script>
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import NewWorkerDataTable from "./NewWorkerDataTable.vue";


export default {
  data: () => {
    return {
      // child table 表示ステート
      isShowChildren: {},
      selectedParent: [],
      NO_DATA_MESSAGE,
    };
  },
  components: {
    NewWorkerDataTable,
  },

  props: {

    // ヘッダー
    tableLabels: Array,

    // parent テーブルの高さ
    tableHeight: Number,

    // テーブル表示item
    items: Array,

    // 1ページあたりのitem数
    itemsPerPage: Number,

    // checkboxの更新
    update: Function,

    // child テーブルのデータをAPI取得
    getChildItems: Function,

    isNoDataMessage: Boolean,

  },

  async mounted() {
    this.$watch(
      () => this.items,
      (newValue, oldValue) => {
        if(!oldValue || (newValue && newValue && newValue[0]?.field_tree_id != oldValue[0]?.field_tree_id)) {
          const items = newValue;
          this.initIsShowChildren(items);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  methods: {

    childTableDisplayMoreNumber(totalNum, displayNum) {
      const _number = totalNum - displayNum;
      return _number > 0 ? _number : "0";
    },

    initIsShowChildren(items) {
      let _isShowChildren = {};
      items.forEach((item) => {
        _isShowChildren[item.field_tree_id] = true;
      });
      this.isShowChildren = _isShowChildren;
    },

    toggleShowChildren(id) {
      this.isShowChildren[id] = !this.isShowChildren[id];
    },
  },
};
</script>

