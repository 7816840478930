<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <FormOccupation
                        name="field_tree_user_experiences"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput('field_tree_user_experiences', $event)"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row v-if="editable">
                        <v-col>
                          <Label label="受入教育実施日" :editable="editable">
                            <InputDatepicker
                              name="education_acceptance_date"
                              :values="fieldTreeUserDetails"
                              :editable="editable"
                              placeholder="yyyy/mm/dd"
                              validation_label="受入教育実施日"
                              validation_rules=""
                              :flagNull="true"
                              @onInput="
                                onInput(
                                  USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                                  $event
                                )
                              "
                            />
                          </Label>
                        </v-col>
                        <v-col>
                          <Label label="送り出し教育実施日" :editable="editable">
                            <InputDatepicker
                              name="education_implementation_date"
                              :values="fieldTreeUserDetails"
                              :editable="editable"
                              placeholder="日付を選択"
                              validation_label="送り出し教育実施日"
                              validation_rules=""
                              :flagNull="true"
                              @onInput="
                                onInput(
                                  USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                                  $event
                                )
                              "
                            />
                          </Label>
                        </v-col>
                      </v-row>
                      <v-row v-if="!editable">
                        <v-col>
                          <Label label="受入教育実施日" :editable="editable">
                            <InputText
                              name="education_acceptance_date"
                              :values="fieldTreeUserDetails"
                            />
                          </Label>
                        </v-col>
                        <v-col>
                          <Label label="送り出し教育実施日" :editable="editable">
                            <InputText
                              name="education_implementation_date"
                              :values="fieldTreeUserDetails"
                            />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row v-if="editable">
                        <v-col>
                          <Label
                            label="現場入場期間（入場）"
                            :editable="editable"
                          >
                            <InputDatepicker
                              name="site_admission_time"
                              :values="fieldTreeUserDetails"
                              :editable="editable"
                              placeholder="yyyy/mm/dd"
                              validation_label="現場入場期間（入場）"
                              :flagNull="true"
                              :validation_rules="getStartTimeRules(fieldTreeUserDetails)"
                              @onInput="
                                onInput(
                                  USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                                  $event
                                )
                              "
                            />
                          </Label>
                        </v-col>
                        <v-col>
                          <Label
                            label="現場入場期間（退場）"
                            :editable="editable"
                          >
                            <InputDatepicker
                              name="site_exit_time"
                              :values="fieldTreeUserDetails"
                              :editable="editable"
                              placeholder="2023/01/08"
                              validation_label="現場入場期間（退場）"
                              :flagNull="true"
                              :validation_rules="getEndTimeRules(fieldTreeUserDetails)"
                              @onInput="
                                onInput(
                                  USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                                  $event
                                )
                              "
                            />
                          </Label>
                        </v-col>
                      </v-row>
                      <v-row v-if="!editable">
                        <v-col>
                          <Label label="現場入場期間" :editable="editable">
                            <InputText name="site_time" :values="siteTime" />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="従事させる業務" :editable="editable">
                        <InputText
                          name="work_details"
                          :values="fieldTreeUserDetails"
                          :editable="editable"
                          placeholder="基礎型枠工事（型枠工事作業）"
                          validation_label="従事させる業務"
                          validation_rules="max:512"
                          @onInput="
                            onInput(USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS, $event)
                          "
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item>
                    <v-list-item-content>
                      <SwitchInput
                        name="site_agent_flag"
                        :label="`現場代理人`"
                        :values="fieldTreeUserDetails"
                        :editable="editable"
                        @onInput="
                          onInput(USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS, $event)
                        "
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <SwitchInput
                        name="work_chief_flag"
                        :label="`作業主任者`"
                        :values="fieldTreeUserDetails"
                        :editable="editable"
                        @onInput="
                          onInput(USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS, $event)
                        "
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <div :class="{changeOpacityToggle:editable}">
                          <SwitchInput
                            name="female_worker_flag"
                            :label="`女性作業員`"
                            :values="fieldTreeUserDetails"
                            :editable="false"
                          />
                          </div>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <div :class="{changeOpacityToggle:editable}">
                          <SwitchInput
                            name="age_under_18_flag"
                            :label="`18歳未満の作業員`"
                            :values="fieldTreeUserDetails"
                            :editable="false"
                          />
                          </div>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <SwitchInput
                            name="lead_engineer_flag"
                            :label="`主任技術者`"
                            :values="fieldTreeUserDetails"
                            :editable="editable"
                            @onInput="
                              onInput(
                                USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                                $event
                              )
                            "
                          />
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <SwitchInput
                            name="foreman_flag"
                            :label="`職長`"
                            :values="fieldTreeUserDetails"
                            :editable="editable"
                            @onInput="
                              onInput(
                                USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                                $event
                              )
                            "
                          />
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <SwitchInput
                            name="health_safety_manager_flag"
                            :label="`安全衛生責任者`"
                            :values="fieldTreeUserDetails"
                            :editable="editable"
                            @onInput="
                              onInput(
                                USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                                $event
                              )
                            "
                          />
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <SwitchInput
                            name="ability_improvement_education_flag"
                            :label="`能力向上教育`"
                            :values="fieldTreeUserDetails"
                            :editable="editable"
                            @onInput="
                              onInput(
                                USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                                $event
                              )
                            "
                          />
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <SwitchInput
                            name="risk_recurrence_prevention_education_flag"
                            :label="`危険有害業務 再発防止教育`"
                            :values="fieldTreeUserDetails"
                            :editable="editable"
                            @onInput="
                              onInput(
                                USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                                $event
                              )
                            "
                          />
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <SwitchInput
                            name="foreigner_technical_intern_flag"
                            :label="`外国人技能実習生`"
                            :values="fieldTreeUserDetails"
                            :editable="editable"
                            @onInput="
                              onInput(
                                USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                                $event
                              )
                            "
                          />
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <SwitchInput
                            name="foreigner_worker_flag"
                            :label="`外国人建設就労者`"
                            :values="fieldTreeUserDetails"
                            :editable="editable"
                            @onInput="
                              onInput(
                                USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                                $event
                              )
                            "
                          />
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <SwitchInput
                            name="foreigner_best_specific_skill_flag"
                            :label="`1号特定技能外国人`"
                            :values="fieldTreeUserDetails"
                            :editable="editable"
                            @onInput="
                              onInput(
                                USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                                $event
                              )
                            "
                          />
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "@/components/forms/elements/Label";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import Select from "@/components/forms/elements/Select";
import InputText from "@/components/forms/elements/InputText";
import RadioGroup from "@/components/forms/elements/RadioGroup";
import FormOccupation from "../../../components/FormOccupation.vue";
import SwitchInput from "@/components/forms/elements/SwitchInput";

const USER_EVENT_TARGET = {
  FIELD_TREE_USER_DETAILS: "field_tree_user_details",
};

export default {
  data: () => {
    return {
      formValues: {},
      USER_EVENT_TARGET,
      fieldTreeUserDetails: {},
      siteTime: {
        site_time: null,
      },
    };
  },
  components: {
    Label,
    InputDatepicker,
    Select,
    InputText,
    RadioGroup,
    FormOccupation,
    SwitchInput,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
        this.fieldTreeUserDetails = {
          ...this.formValues.field_tree_user_details,
        };
        if (!this.editable) {
          let admission_time = this.fieldTreeUserDetails.site_admission_time ? this.fieldTreeUserDetails.site_admission_time : "";
          let exit_time = this.fieldTreeUserDetails.site_exit_time ? this.fieldTreeUserDetails.site_exit_time : "";
          this.siteTime.site_time = (admission_time || exit_time) ? `${admission_time}～${exit_time}` : "";
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    emit(key, value) {
      const objNew = {};
      objNew[key] = value;
      this.$emit("formUpdate", objNew);
    },
    onInput(parents_name, { name, value }) {
      if (parents_name == USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS) {
        const formValues = { ...this.formValues[parents_name] };
        formValues[name] = value;
        this.emit(parents_name, formValues);
      } else {
        this.emit(name, value);
      }
    },
    getStartTimeRules(time) {
      return time.site_exit_time ? `compare-to-end-date:${time.site_exit_time.replaceAll("-", "/")}` : "";
    },
    getEndTimeRules(time) {
      return time.site_admission_time ? `compare-to-start-date:${time.site_admission_time.replaceAll("-", "/")}` : "";
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
.changeOpacityToggle{
  opacity: 0.6;
}
</style>
