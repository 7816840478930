<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row no-gutters>
          <v-col cols="12" sm="10" md="10">
            <v-list-item>
              <v-list-item-content class="row-position">
                <Label label="安全指示事項" :editable="editable">
                  <Select
                    class="select-position"
                    name="cmn_mst_safety_instruction_id"
                    :values="item"
                    :editable="editable"
                    :items="WORK_SAFETY_INSTRUCTIONS"
                    item_text="safety_instruction"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="2" md="2">
            <v-btn
              v-if="index >= row && editable"
              class="mt-16"
              icon
              @click="remove(index)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn v-if="editable" depressed small color="primary" @click="addEmpty"
      >追加</v-btn>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "../../../elements/Label.vue";
import Select from "../../../elements/Select.vue";
import { Store } from "@/store/Store.js";

const INITIAL_VALUES = {
  cmn_mst_safety_instruction_id: null,
};

export default {
  data: () => {
    return {
      items: [],
      WORK_SAFETY_INSTRUCTIONS: [],
    };
  },
  components: {
    Label,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    row: {
      type: Number,
      default: 1,
    },
  },
  mounted() {
    this.$watch(
      () => Store.getters["CmnMst/get"],
      (data) => {
        this.WORK_SAFETY_INSTRUCTIONS = [...data.entries.cmn_mst_safety_instructions];
        this.WORK_SAFETY_INSTRUCTIONS.unshift({id: null, safety_instruction: ""})
      },
      {
        immediate: true,
        deep: true,
      }
    );

    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (this.items?.length == 0) {
          this.addEmpty();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      let _items = [...this.items];
      _items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: _items });
    },
  },
};
</script>
<style lang="scss" scoped>

.row-position {
  margin-top: -20px;
  height: 150px;
  position: relative;
}

.select-position {
  width: 100%;
  position: absolute;
}
</style>