<template>
  <v-card class="dialog">
    <v-card-title>
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <div class="text-header-title">招待</div>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider style="left: 0px" color="primary"></v-tabs-slider>
            <v-tab
              style="margin-left: 0px"
              v-for="(form, key) in INVITE_TAB_FORM"
              :key="key"
            >
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </v-card-title>
    <v-divider class="line-height" />
    <v-card-text class="height-card-text">
      <ValidationObserver ref="observer" v-slot="observer">
        <!-- フォームtab -->
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <InviteCompanyOtherForm1
              v-if="tab == 0"
              :editable="editable"
              :item="formValue.tab1"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <InviteCompanyOtherForm2
              v-if="tab == 1"
              :editable="editable"
              :item="formValue.tab2"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
        </v-tabs-items>
        <!-- 
          (共通)
          ValidationObserverのスロットプロパティを受け取って
          updateValidateメソッドを実行して、
          バリデーション結果をスクリプト側に渡してます
        -->
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="isShowConfirmDialog = false"
          @yes="closeForm"
          warning
        />
      </Popup>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="primary"
        class="mx-1 px-4"
        @click="onCancel"
      >
        やめる
      </v-btn>
      <v-btn
        depressed
        :disabled="!valid"
        color="primary"
        class="mx-1 px-5"
        key="submit"
        @click.once="onSubmit"
      >
        招待
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import InviteCompanyOtherForm1 from "./components/InviteCompanyOtherForm/InviteCompanyOtherForm1.vue";
import InviteCompanyOtherForm2 from "./components/InviteCompanyOtherForm/InviteCompanyOtherForm2.vue";
import { INVITE_TAB_FORM } from "@/constants/PORTAL_CHART";
//ストア

export default {
  data: () => {
    return {
      valid: false,
      errors: {},
      tab: null,
      formValue: {
        tab1: {
          message: null,
          user_name: null,
          company_name: null,
          company_branch_id: null,
          company_id: null,
          user_id: null,
          email: null,
          name_sei: null,
          name_mei: null,
        },
        tab2: {
          email: null,
          name_sei: null,
          name_mei: null,
          message: null,
        },
      },
      editable: false,
      isShowConfirmDialog: false,
      INVITE_TAB_FORM,
      params: {
        mainHeight: 700,
        headerHeight: 10,
      },
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    InviteCompanyOtherForm1,
    InviteCompanyOtherForm2,
  },
  props: {},
  computed: {},
  methods: {
    /**
     * フォームを閉じる
     */
    onCancel() {
      this.isShowConfirmDialog = true;
    },

    closeForm() {
      this.$emit("onCancel");
    },
    /**
     * フォームを送信
     */
    async onSubmit() {
      const formValue =
        this.tab == 0 ? { ...this.formValue.tab1 } : { ...this.formValue.tab2 };
        this.$emit("onSubmit",formValue);
    },
    formUpdate(params) {
      this.formValue = { ...this.formValue, ...params };
    },
    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid, errors }) {
      this.valid = valid;
      this.errors = errors;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/dialog.scss";
.dialog {
  overflow-y: hidden !important;
}
.line-height {
  margin-top: -17px;
}
.height-card-text {
  height: 490px;
}
.text-header-title {
  color: green;
  font-size: 17px;
}
</style>
