<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters v-if="isNewItem">
                    <v-col cols="12" sm="12" md="12">
                      <v-switch
                      v-model="isHideEndDate"
                      label="繰り返し予定を入力する"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <!-- 日付 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="日付（開始）" :editable="editable" required>
                            <InputDatepicker
                              name="use_date_from"
                              :values="formValues"
                              :editable="editable"
                              validation_label="日付（開始）"
                              :validation_rules="getStartDateRules()"
                              @onInput="onInput"
                              :flagNull="true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <!-- 日付 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="日付（終了）" :editable="editable" required v-if="isHideEndDate">
                            <InputDatepicker
                              name="use_date_to"
                              :values="formValues"
                              :editable="editable"
                              validation_label="日付（終了）"
                              :validation_rules="getEndDateRules()"
                              @onInput="onInput"
                              :flagNull="true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-else>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-row>
                            <v-col>
                              <Label label="日付" :editable="editable" required>
                                <InputDatepicker
                                  name="use_date"
                                  :values="formValues"
                                  :editable="editable"
                                  placeholder=""
                                  validation_label="日付"
                                  validation_rules="required"
                                  @onInput="onInput"
                                  :flagNull="true"
                                />
                              </Label>
                            </v-col>
                            <v-col><Label/></v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row> 
                  <v-list-item>
                    <v-list-item-content class="row-position">
                      <Label label="工事" :editable="editable" required>
                        <Select
                          class="select-position"
                          name="field_construction_id"
                          :values="formValues"
                          :editable="editable"
                          :items="work_types"
                          validation_label="工事"
                          validation_rules="required"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="checkShow('field_tree_group_id')">
                    <v-list-item-content class="row-position">
                      <Label label="作業グループ" :editable="editable" :required="checkRequired('field_tree_group_id')">
                        <Select
                          class="select-position"
                          name="group_id"
                          :values="formValues"
                          :editable="editable"
                          item_text="group_name"
                          :items="working_group_select"
                          :label="formValues.group_name || ''"
                          validation_label="作業グループ"
                          :validation_rules="checkRequired('field_tree_group_id')?'required':''"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="row-position">
                      <Label label="協力会社" :editable="editable" required>
                        <Select
                          class="select-position"
                          name="field_tree_id"
                          :values="formValues"
                          :editable="editable"
                          item_text="company_name"
                          item_value="field_tree_id"
                          :items="listCompanyPartner"
                          :label="formValues.field_tree_conpany_name"
                          validation_label="協力会社"
                          validation_rules="required"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="row-position" v-if="editable">
                      <Label label="職長" :editable="editable" required>
                        <Select
                          class="select-position"
                          name="foreman_user_id"
                          :values="formValues"
                          :editable="editable"
                          item_value="user_id"
                          :label="formValues.foreman_user_name"
                          :items="foremanList"
                          validation_label="職長"
                          validation_rules="required"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-row v-if="!editable">
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="職長" :editable="editable" required>
                            <Select
                              name="foreman_user_id"
                              :values="formValues"
                              :editable="editable"
                              item_value="user_id"
                              :items="listCompanyPartnerUser"
                              validation_label="職長"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="職長電話番号">
                            <InputText
                              name="tel"
                              :values="foremanPhoneNumber"
                              :editable="false"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <Label label="職種" :editable="editable">
                            <InputText
                              v-for="(item, index) in user_experiences"
                              :key="index"
                              name="skill_name"
                              :values="item"
                              :editable="isReadonly"
                            />
                          </Label>
                        </v-col>
                        <v-col>
                          <Label label="経験年数" :editable="editable">
                            <InputText
                              v-for="(item, index) in user_experiences"
                              :key="index"
                              name="experience"
                              :values="item"
                              :editable="isReadonly"
                              placeholder="20"
                            />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters  class="row-position">
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="作業時間帯" :editable="editable" required>
                            <Select
                              class="select-position"
                              name="work_hour_zone"
                              :values="formValues"
                              :editable="editable"
                              :items="working_time_zone"
                              validation_label="作業時間帯"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <SectionLabel label="使用揚重機" :editable="editable"/>
                  <v-list-item>
                    <v-list-item-content class="row-position" style="margin-top: -25px;">
                      <Label label="揚重機" :editable="editable" required>
                        <Select
                          class="select-position"
                          name="crane_id"
                          :values="formValues"
                          :editable="editable"
                          item_text="crane_name"
                          :items="cranes"
                          validation_label="揚重機"
                          validation_rules="required"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="管理会社" :editable="editable">
                        <v-text-field
                          v-model="company_name"
                          dense
                          color="primary"
                          :disabled="true"
                        ></v-text-field>
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <SectionLabel label="作業場所" :editable="editable" v-if="checkShow('field_item_tree_id')"/>
                  <v-list-item v-if="checkShow('field_item_tree_id')">
                    <v-list-item-content class="row-position" style="margin-top: -25px;">
                      <Label label="作業場所" :editable="editable" :required="checkRequired('field_item_tree_id')">
                        <Select
                          class="select-position"
                          name="field_item_tree_id"
                          :values="formValues"
                          :editable="editable"
                          :items="work_place"
                          item_text="item_name"
                          validation_label="作業場所"
                          :validation_rules="checkRequired('field_item_tree_id')?'required':''"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "../../elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "../../elements/InputText";
import Select from "../../elements/Select.vue";
import InputDatepicker from "../../elements/InputDatepicker.vue";
import { STATE } from "@/constants/COMMON";
import { Store } from "@/store/Store.js";
import {
  OWNER_FLAG,
} from "@/constants/SCHEDULE_CRANE";
import _ from "lodash";
const IS_CUSTOMIZE = 1;

const LIFTINGSCHEDULE_EVENT_TARGET = {
  field_construction_id: "field_construction_id",
  group_id: "group_id",
  field_tree_id: "field_tree_id",
  foreman_user_id: "foreman_user_id",
  crane_id: "crane_id",
  work_hour_zone: "work_hour_zone",
}

const INIT_SLINGER_SIGNALLERS = [{
  slinging_worker_id: null,
  signal_worker_id: null,
}];

const INIT_DIVICE_USER = [{
  field_director_id: null
}]

export default {
  data: () => {
    return {
      formValues: {},
      STATE,
      work_types: [],
      working_group_select: [],
      listCompanyPartner: [{field_tree_id: null, company_name: ""}],
      listCompanyPartnerUser: [{user_id: null, name: ""}],
      user_experiences: [],
      working_time_zone: [],
      cranes: [],
      work_place: [],
      cmn_mst_skills: Store.state.CmnMst.constants.entries.cmn_mst_skills,
      isReadonly: false,
      company_name: "",
      LIFTINGSCHEDULE_EVENT_TARGET,
      listCompanyByField: [],
      foremanPhoneNumber: {
        tel: null
      },
      isHideEndDate: false,
    };
  },
  components: {
    Label,
    SectionLabel,
    InputText,
    Select,
    InputDatepicker,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    isNewItem: Boolean,
    cranePassagesCustomize: Object,
  },
  mounted () {
    this.$watch(
      () => Store.getters['CompanyPortal/getListCompanyByField'],
      (newValue) => {
        this.listCompanyByField = [...newValue];
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.item,
      async (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.formValues = _.cloneDeep(newValue);
          if(this.formValues['owner_flag'] == OWNER_FLAG.OWNER) {
            if(newValue['field_construction_id'] != oldValue['field_construction_id']) {
              await this.getListGroup(newValue['field_construction_id']);
              await this.getWorkingTimeZone(newValue['field_construction_id']);
              await this.getListItemTrees(newValue['field_construction_id']);
              await this.getListCompanyPartner();
            }
            if(newValue['group_id'] != oldValue['group_id']) {
              await this.getListCompanyPartner();
            }
            if(newValue['field_tree_id'] != oldValue['field_tree_id']) {
              await this.getListCompanyPartnerUser(newValue['field_tree_id']);
            }
            if(newValue['foreman_user_id'] != oldValue['foreman_user_id']) {
              this.getUserExperiences(newValue['foreman_user_id']);
            }
            if (newValue['crane_id']) {
              this.getCompanyName(newValue['crane_id']);
            }
          } else if (this.formValues['owner_flag'] == OWNER_FLAG.NONE) {
            this.handleData();
          }
        }
      },
      {
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters['PortalChart/getWorkTypes'],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.work_types = [this.getDefaultArrays("id", "name"), ...newValue];
          if (!this.formValues["field_construction_id"]) {
            this.formValues["field_construction_id"] = this.work_types[0].id;
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters['Cranes/getListCraneByField'],
      (newValue) => {
        this.cranes = [this.getDefaultArrays("id", "crane_name"), ...newValue];
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters['PortalChart/getListItemTrees'],
      (newValue) => {
        const array = [...newValue];
        array.unshift(this.getDefaultArrays("id", "item_name"));
        this.work_place = [...array];
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.isHideEndDate,
      (data) => {
        if(!data){
          this.formValues.use_date_to = this.formValues.use_date_from
        }
      },
      {
        deep: true
      }
    );
  },
  computed: {
    getBodyStyle () {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    foremanList() {
      return this.listCompanyPartnerUser.filter(e => e.state_handling_flag === 0 || e.user_id === null);
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },
  methods: {
    onInput ({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      if (name === 'use_date_from' && !this.isHideEndDate) {
        this.formValues.use_date_to = value;
      }
      if(name == LIFTINGSCHEDULE_EVENT_TARGET.field_construction_id) {
        this.formValues['group_id'] = null;
        this.formValues['field_tree_id'] = null;
        this.formValues['foreman_user_id'] = null;
        this.formValues['work_hour_zone'] = null;
        this.formValues['field_item_tree_id'] = null;
        this.formValues['slinger_signallers'] = INIT_SLINGER_SIGNALLERS;
        this.formValues['device_users'] = INIT_DIVICE_USER;
        this.user_experiences = [];
        this.working_group_select = [this.getDefaultArrays("id", "group_name")];
        this.listCompanyPartner = [this.getDefaultArrays("field_tree_id", "company_name")];
        this.listCompanyPartnerUser = [this.getDefaultArrays("user_id", "name")];
        this.work_place = [this.getDefaultArrays("id", "item_name")];
      }
      if(name == LIFTINGSCHEDULE_EVENT_TARGET.group_id) {
        this.formValues['field_tree_id'] = null;
        this.formValues['foreman_user_id'] = null;
        this.formValues['slinger_signallers'] = INIT_SLINGER_SIGNALLERS;
        this.formValues['device_users'] = INIT_DIVICE_USER;
        this.user_experiences = [];
        this.listCompanyPartner = [this.getDefaultArrays("field_tree_id", "company_name")];
        this.listCompanyPartnerUser = [this.getDefaultArrays("user_id", "name")];
      }
      if(name == LIFTINGSCHEDULE_EVENT_TARGET.field_tree_id) {
        if (!this.formValues['group_id'] && this.formValues['field_tree_id']) {
          let groupId = this.getGroupdIdByFieldTree(this.formValues['field_tree_id']);
          let hasGroup = this.hasGroupById(groupId);
          if (hasGroup) {
            this.formValues['group_id'] = groupId;
            this.getListCompanyPartner();
          }
        }
        this.formValues['foreman_user_id'] = null;
        this.formValues['slinger_signallers'] = INIT_SLINGER_SIGNALLERS;
        this.user_experiences = [];
        this.listCompanyPartnerUser = [this.getDefaultArrays("user_id", "name")];
      }
      if(name == LIFTINGSCHEDULE_EVENT_TARGET.work_hour_zone) {
        if(this.isNewItem){
          formValues['device_usage_times'] = [{usage_start_time: null , usage_end_time: null}];
        }
      }
      this.$emit("formUpdate", formValues);
    },
    /**
     * 作業グループ
     */
    async getListGroup(field_construction_id) {
      if(field_construction_id) {
        const rs = await Store.dispatch("PortalChart/getListGroups", field_construction_id);
        if (!rs.hasError) {
          const { entries } = rs.data.contents;
          this.working_group_select = entries.length > 0 ? [this.getDefaultArrays("id", "group_name"), ...entries] : [this.getDefaultArrays("id", "group_name")];
        }
      }
    },

    /**
     * 協力会社
     */
    async getListCompanyPartner() {
      if (!this.formValues.field_construction_id) return;
      this.getFieldDirectors();
      let group_id = this.formValues.group_id;
      let params = { state_handling_flag: 0, display_branch_name_flag: 1 };
      if (group_id) params.field_tree_group_id = group_id;
      else if (this.formValues["field_construction_id"]) params.field_construction_id = this.formValues.field_construction_id;
      else return;
      const rs = await Store.dispatch("PortalChart/getListCompanyPartner", {params});
      if(!rs.hasError) {
        const { entries } = rs.data.contents;
        this.listCompanyPartner = entries.length > 0 ? [this.getDefaultArrays("field_tree_id", "company_name"), ...entries] : [this.getDefaultArrays("field_tree_id", "company_name")];
      }
    },

    /**
     * 元請担当者
     */
    async getFieldDirectors() {
      let params = {state_handling_flag: 0};
      if (this.formValues["group_id"]) params.group_id = this.formValues["group_id"];
      else if (this.formValues["field_construction_id"]) params.field_construction_id = this.formValues["field_construction_id"];
      else return;
      await Store.dispatch("PortalChart/getFieldDirectorsObayashi", params);
    },

    /**
     * 職長
     */
    async getListCompanyPartnerUser(field_tree_id) {
      if(field_tree_id) {
        const params = {
          id: field_tree_id,
          foreman_flag: 1
        }
        const rs = await Store.dispatch(`PortalChart/getListCompanyPartnerUser`, params);
        if(!rs.hasError) {
          const { entries } = rs.data.contents;
          const _listCompanyPartnerUser = [...entries];
          _listCompanyPartnerUser.forEach(item => {
            item['name'] = `${item.user_name_sei} ${item.user_name_mei}`
          });
          this.listCompanyPartnerUser = _listCompanyPartnerUser.length > 0 ? [this.getDefaultArrays("user_id", "name"), ..._listCompanyPartnerUser] : [this.getDefaultArrays("user_id", "name")];
        }
      } else {
        this.listCompanyPartnerUser = [
          this.getDefaultArrays("user_id", "name"),
        ];
        this.user_experiences = [];
      }
      
    },
    /**
     * 作業時間帯
     */
    async getWorkingTimeZone(field_construction_id) {
      if(field_construction_id) {
        const rs = await Store.dispatch("PortalChart/getFieldTimeZones", field_construction_id);
        if(!rs.hasError) {
          const { entries } = rs.data.contents;
          this.working_time_zone = entries?.length > 0 ? [this.getDefaultArrays("id", "name"), ...entries] : [];
          if(this.isNewItem) {
            let timeZone = [
              {
                usage_start_time: this.working_time_zone[1]?.start_time,
                usage_end_time: null
              }
            ]
            this.formValues['device_usage_times'] = timeZone
            this.$emit("updateTimeZone", timeZone);
          }
          if (!this.formValues["work_hour_zone"]) {
            this.formValues["work_hour_zone"] = this.working_time_zone[0].id;
            Store.dispatch("ScheduleCrane/setWorkHouseZone", this.working_time_zone[0].id);
          }
        }
      } else {
        this.working_time_zone = [this.getDefaultArrays("id", "name")];
      }
    },
    /**
     * 職種
     * 経験年数
     */
    getUserExperiences(foreman_user_id) {
      this.user_experiences = this.listCompanyPartnerUser.find(item => item.user_id == foreman_user_id)['user_experiences'];
      this.foremanPhoneNumber.tel = this.listCompanyPartnerUser.find(item => item.user_id == foreman_user_id)['tel'];
      this.user_experiences?.forEach(item => {
        const skill_name = this.cmn_mst_skills.find(skill => skill.id == item.cmn_mst_skills_id);
        item['skill_name'] = `${skill_name['catergory']} ${skill_name['sub_catergory']}`;
      })
    },

    /**
     * 管理会社
     */
    async getCompanyName(crane_id) {
      this.$emit('chooseCrane', crane_id);
      if(crane_id) {
        const cranes_rs = await Store.dispatch("Cranes/getDetail", crane_id);
        if (!cranes_rs.hasError) {
          const { entries } = cranes_rs.data.contents;
          const company = this.listCompanyByField.find(item => item.id == entries.company_id);
          this.company_name = company ? company.name : "";
        }
      } else {
        this.company_name = "";
      }
    },

    getDefaultArrays(id, value) {
      let results = {};
      results[id] = null;
      results[value] = "";
      return results;
    },

    async handleData() {
      this.working_group_select = [{id: this.formValues.group_id, group_name: this.formValues.group_name || ""}];
      this.listCompanyPartner = [{field_tree_id: this.formValues.field_tree_id, company_name: this.formValues.field_tree_conpany_name}];
      await this.getWorkingTimeZone(this.formValues.field_construction_id);
      await this.getListCompanyPartnerUser(this.formValues.field_tree_id);
      await this.getUserExperiences(this.formValues.foreman_user_id);
    },

    async getListItemTrees(val){
      if (val) {
        let params = {
          field_id: this.CURRENT_SITE.field_id,
          field_construction_id: val
        };
        await Store.dispatch("PortalChart/getListItemTrees", { params });
      }
    },

    getStartDateRules() {
      return this.formValues?.use_date_to && this.isHideEndDate
        ? "required|" +
            `compare-end-date:${String(this.formValues?.use_date_to).replaceAll(
              "-",
              "/"
            )}`
        : "";
    },
    getEndDateRules() {
      return this.formValues?.use_date_from
        ? "required|" +
            `compare-start-date:${String(this.formValues?.use_date_from).replaceAll(
              "-",
              "/"
            )}`
        : "";
    },

    getGroupdIdByFieldTree(fieldTreeId) {
      return this.listCompanyPartner.find(e => e.field_tree_id === fieldTreeId)?.field_tree_group_id || null;
    },

    hasGroupById(groupId) {
      if (!groupId) return false;
      let group = this.working_group_select.find(e => e.id === groupId);
      if (group) return true;
      return false;
    },

    /**
     * Check item requred
     */
    checkRequired(name) {
      return this.cranePassagesCustomize[name]?.required === IS_CUSTOMIZE;
    },

    /**
     * Check item show or hidden
     */
    checkShow(name) {
      return this.cranePassagesCustomize[name]?.form_view === IS_CUSTOMIZE;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
.row-position{
  margin-top: -15px;
  height: 150px;
  position: relative;
}

.select-position{
  width: 100%;
  position: absolute;
}
</style>
