export const INITIAL_PARAM = {
  color: null,
  image_svg: null,
  image_url: null,
  image_base64: null,
  image_side_url: null,
  machine_id: null,
  text: null,
  text_align: null,
  text_size: null,
  text_color: null,
  text_bold: null,
  transform: null,
};
