<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                      <v-list-item>
                        <v-list-item-content>
                          <SectionLabel label="使用会社" :editable="editable" />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="12" md="12">
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label label="工事" :editable="editable" required>
                            <Select
                              class="select-position"
                              name="used_field_construction_id"
                              :values="formValues"
                              :items="FIELD_CONSTRUCTION_ARRAY"
                              :editable="editable && !isReturn"
                              @onInput="onInput"
                              validation_rules="required"
                              validation_label="工事"
                              testid="input-used_field_construction_id"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="12" md="12">
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label
                            label="作業グループ"
                            :editable="editable"
                          >
                            <Select
                              class="select-position"
                              name="used_field_tree_group_id"
                              :values="formValues"
                              :items="FIELD_TREE_GROUP_ARRAY"
                              item_text="group_name"
                              :editable="editable && !isReturn"
                              @onInput="onInput"
                              validation_label="作業グループ"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="12" md="12">
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label label="協力会社" :editable="editable" required>
                            <Select
                              class="select-position"
                              name="used_field_tree_id"
                              :values="formValues"
                              :items="COMPANY_PARTNER_ARRAY"
                              item_text="company_name"
                              item_value="field_tree_id"
                              :editable="editable && !isReturn"
                              :label="formValues.used_field_tree_company_name"
                              @onInput="onInput"
                              validation_rules="required"
                              validation_label="協力会社"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="12" md="12">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="職長" :editable="editable" required>
                            <Select
                              name="used_foreman_user_id"
                              :values="formValues"
                              :items="FOREMAN_ARRAY"
                              :editable="editable && !isReturn"
                              validation_rules="required"
                              :label="formValues.used_foreman_user_name"
                              validation_label="職長"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="12" md="12">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="使用場所" :editable="editable" required>
                            <Select
                              name="used_field_item_tree_id"
                              :values="formValues"
                              :items="WORK_PLACE"
                              item_text="item_name"
                              :editable="editable && !isReturn"
                              validation_rules="required"
                              validation_label="使用場所"
                              :label="formValues.user_name"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label
                            label="使用開始日"
                            :editable="editable"
                            required
                          >
                            <InputDatepicker
                              name="used_start_date"
                              :editable="editable && !isReturn"
                              :values="formValues"
                              validation_rules="required|compare-machine-used-end-date:@使用終了日"
                              validation_label="使用開始日"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item :class="!requiredEndDate && editable ? 'mt-2' : ''">
                        <v-list-item-content>
                          <Label label="使用終了日" :editable="editable" :required="requiredEndDate">
                            <InputDatepicker
                              name="used_end_date"
                              :editable="editable"
                              :values="formValues"
                              :flagNull="!requiredEndDate"
                              :validation_rules="requiredEndDate ? 'required|compare-machine-used-start-date:@使用開始日' : 'compare-machine-used-start-date:@使用開始日'"
                              validation_label="使用終了日"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                      <v-list-item>
                        <v-list-item-content>
                          <SectionLabel
                            label="運転者（取扱者）"
                            :editable="editable"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                    <DriverForm
                      name="machine_company_field_drivers"
                      :values="formValues"
                      :editable="editable && !isReturn"
                      :listUser="listUser"
                      @onInput="onInput"
                    />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "@/components/forms/elements/Label.vue";
import InputText from "@/components/forms/elements/InputText.vue";
import Select from "@/components/forms/elements/Select.vue";
import DriverForm from "./DriverForm.vue";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import SectionLabel from "@/components/forms/elements/SectionLabel.vue";

export default {
  data: () => {
    return {
      formValues: {},
      machineSelectArray: [],
      FIELD_CONSTRUCTION_ARRAY: [],
      FIELD_TREE_GROUP_ARRAY: [],
      COMPANY_PARTNER_ARRAY: [],
      FOREMAN_ARRAY: [],
      WORK_PLACE: [],
      enterDayTime: "",
      listUser: [],
    };
  },
  components: {
    Label,
    InputText,
    Select,
    InputDatepicker,
    SectionLabel,
    DriverForm,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    isBackData: Boolean,
    requiredEndDate : Boolean,
    isReturn: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.isBackData,
      (flg) => {
        if(flg) {
          this.getWorkTypes();
          this.$emit("updateBackData");
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue) && newValue) {
            this.getDataSelect();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );


    this.$watch(
      () => Store.getters["PortalChart/getWorkTypes"],
      (data) => {
        this.FIELD_CONSTRUCTION_ARRAY = [
          this.getDefaultArrays("id", "name"),
          ...data,
        ];
        if (!this.formValues["used_field_construction_id"]) {
          this.formValues["used_field_construction_id"] =
            this.FIELD_CONSTRUCTION_ARRAY[1].id;
          this.FIELD_TREE_GROUP_ARRAY = [
            this.getDefaultArrays("id", "group_name"),
          ];
          this.COMPANY_PARTNER_ARRAY = [
            this.getDefaultArrays("field_tree_id", "company_name"),
          ];
          this.FOREMAN_ARRAY = [this.getDefaultArrays("id", "name")];
          this.listUser = [this.getDefaultArrays("id", "name")];
        }
        this.getListGroups();
        this.getCompanyPartners();
        this.getWorkPlace(this.formValues["used_field_construction_id"])
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getListGroup"],
      (data) => {
        this.FIELD_TREE_GROUP_ARRAY = [
          this.getDefaultArrays("id", "group_name"),
          ...data,
        ];
        this.getCompanyPartners();
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getListCompanyPartner"],
      (data) => {
        this.COMPANY_PARTNER_ARRAY = [
          this.getDefaultArrays("field_tree_id", "company_name"),
          ...data,
        ];
        this.getListFieldTree();
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getListItemTrees"],
      (data) => {
        const array = [...data];
        array.unshift(this.getDefaultArrays("id", "item_name"));
        this.WORK_PLACE = [...array];
      },
      {
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },
  methods: {
    async getDataSelect() {
      await this.getWorkTypes();
      if (this.isReturn) {
        await this.getWorkPlace(this.item.used_field_construction_id);
        await this.getListGroups();
        await this.getCompanyPartners();
        this.getListFieldTree();
      }
    },
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      this.getDataByName(name);
      if(name == "used_field_tree_id" || name == "used_field_construction_id" || name == "used_field_tree_group_id") {
        formValues.machine_company_field_drivers = [{
          id: null,
          driver_user_id: null,
          machine_company_field_sp_trainings: [{ user_sp_training_id: null }],
          machine_company_field_licences: [{ user_licence_id: null }],
        }];
      }
      this.$emit("formUpdate", formValues);
    },

    async getWorkTypes() {
      if (this.CURRENT_SITE.field_id) {
        const params = {
          field_id: this.CURRENT_SITE.field_id,
          force_all_construction_flag: 1
        };
        await Store.dispatch("PortalChart/getWorkTypes", params);
      }
    },

    getDefaultArrays(id, value) {
      let results = {};
      results[id] = null;
      results[value] = "";
      return results;
    },

    async getListGroups() {
      const params = {
        field_construction_id: this.formValues["used_field_construction_id"],
        force_all_group_flag: 1
      };
      await Store.dispatch("PortalChart/getListGroups", params);
    },

    async getCompanyPartners() {
      if (!this.formValues.used_field_construction_id) return;
      const params = {
        company_for_user_flag : 1,
        state_handling_flag: 0,
        display_branch_name_flag: 1,
      };
      if (this.formValues.used_field_tree_group_id) params.field_tree_group_id = this.formValues.used_field_tree_group_id;
      else if (this.formValues.used_field_construction_id) params.field_construction_id = this.formValues.used_field_construction_id;
      await Store.dispatch("PortalChart/getListCompanyPartner", {params});
    },

    async getListFieldTree() {
      if (this.COMPANY_PARTNER_ARRAY.length > 0) {
        const params = {
          id: this.formValues["used_field_tree_id"],
          foreman_flag: 1,
          state_handling_flag: 0,
        };
        const rs = await Store.dispatch("PortalChart/getListCompanyPartnerUser", params);
        if(!rs.hasError) {
          this.FOREMAN_ARRAY = this.convertArray([...rs.data.contents.entries]);
          delete params['foreman_flag'];
          const result = await Store.dispatch("PortalChart/getListCompanyPartnerUser", params);
          if(!result.hasError) {
            this.listUser = this.convertArray([...result.data.contents.entries]);
          }
        }
      }
    },

    convertArray(data) {
      const foremans = [this.getDefaultArrays("id", "name")];
        data.forEach((item) => {
          foremans.push({
            id: item.user_id,
            name: item.user_name_sei + " " + item.user_name_mei,
            user_experiences: item.user_experiences,
          });
        });
        return foremans;
    },

    getDataByName(name) {
      switch (name) {
        case "used_field_construction_id": {
          this.formValues.used_field_tree_group_id = null;
          this.formValues.used_field_tree_id = null;
          this.formValues.used_foreman_user_id = null;
          this.formValues.used_field_item_tree_id = null;
          this.FIELD_TREE_GROUP_ARRAY = [this.getDefaultArrays("id", "group_name")];
          this.WORK_PLACE = [this.getDefaultArrays("id", "item_name")];
          this.COMPANY_PARTNER_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name")];
          this.FOREMAN_ARRAY = [this.getDefaultArrays("id", "name")];
          this.listUser = [this.getDefaultArrays("id", "name")];
          if (this.formValues[name]) {
            this.getListGroups();
            this.getWorkPlace(this.formValues.used_field_construction_id);
          }
          this.getCompanyPartners();
          break;
        }
        case "used_field_tree_group_id": {
          this.formValues.used_field_tree_id = null;
          this.formValues.used_foreman_user_id = null;
          this.COMPANY_PARTNER_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name")];
          this.FOREMAN_ARRAY = [this.getDefaultArrays("id", "name")];
          this.listUser = [this.getDefaultArrays("id", "name")];
          this.getCompanyPartners();
          break;
        }
        case "used_field_tree_id": {
          if (!this.formValues['used_field_tree_group_id'] && this.formValues['used_field_tree_id']) {
            let groupId = this.getGroupdIdByFieldTree(this.formValues['used_field_tree_id']);
            let hasGroup = this.hasGroupById(groupId);
            if (hasGroup) {
              this.formValues['used_field_tree_group_id'] = groupId;
              this.getCompanyPartners();
            }
          }
          this.formValues.used_foreman_user_id = null;
          this.FOREMAN_ARRAY = [this.getDefaultArrays("id", "name")];
          this.listUser = [this.getDefaultArrays("id", "name")];
          if (this.formValues[name]) {
            this.getListFieldTree();
          }
          break;
        }
        default:
          break;
      }
    },

    getGroupdIdByFieldTree(fieldTreeId) {
      return this.COMPANY_PARTNER_ARRAY.find(e => e.field_tree_id === fieldTreeId)?.field_tree_group_id || null;
    },
  
    hasGroupById(groupId) {
      if (!groupId) return false;
      let group = this.FIELD_TREE_GROUP_ARRAY.find(e => e.id === groupId);
      if (group) return true;
      return false;
    },

    async getWorkPlace(val) {
      if (val) {
        let params = {
          field_id: this.CURRENT_SITE.field_id,
          field_construction_id: val
        };
        await Store.dispatch("PortalChart/getListItemTrees", { params });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
.row-position {
  margin-top: -20px;
  height: 150px;
  position: relative;
}

.select-position {
  width: 100%;
  position: absolute;
}
</style>
