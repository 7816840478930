<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row no-gutters>
          <v-col cols="12" sm="10" md="10">
            <v-list-item>
              <v-list-item-content class="row-position">
                <Label label="元請担当者" :editable="editable">
                  <Select
                      class="select-position"
                      name="user_id"
                      :values="item"
                      :items="PERSON_IN_CHARGE[index]"
                      :editable="editable"
                      :label="item.user_name"
                      @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="2" md="2">
            <v-btn
              v-if="index > 0 && editable"
              class="mt-16"
              icon
              @click="remove(index)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn v-if="editable" depressed small color="primary" @click="addEmpty"
      >追加</v-btn>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "../../../elements/Label.vue";
import Select from "../../../elements/Select.vue";
import { Store } from "@/store/Store.js";
import _ from "lodash"
//初期値を設定します
const INITIAL_VALUES = {
  user_id: null,
  used_type: 1,
};

export default {
  data: () => {
    return {
      items: [],
      PERSON_IN_CHARGE: [],
      EMPLOYEE_ARRAY: [],
    };
  },
  components: {
    Label,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    selectItems: {
      type: Array,
    },
    row: {
      type: Number,
      default: 1,
    },
    flag_name : {
      type : String
    }
  },
  mounted() {
    this.$watch(
      () => Store.getters["PortalChart/getListDirectors"],
      (data) => {
        let directors = [];
        data.forEach((item) => {
          directors.push({
            id: item.id,
            name: item.user_name_sei + " " + item.user_name_mei,
          });
        });
        this.EMPLOYEE_ARRAY = directors;
        this.initDataArray();
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        if(newValue[0][`${this.flag_name}`]) {
          this.items = _.cloneDeep(newValue[0][`${this.flag_name}`][newValue[1]]);
          if(this.items?.length == 0){
            this.addEmpty();
          }
          this.initDataArray();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      let _items = [...this.items];
      _items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    initDataArray() {
      this.PERSON_IN_CHARGE = [];
      this.items?.forEach(() => {
        let employee = [];
        employee = _.cloneDeep(this.EMPLOYEE_ARRAY);
        employee?.unshift({id: null, name: ""});
        this.PERSON_IN_CHARGE.push(employee);
      });
      this.items?.forEach((items, index) => {
        if (items.user_id) {
          this.convertDataArray(index, items.user_id);
        }
      });
    },
    convertDataArray(index, id) {
      this.PERSON_IN_CHARGE.forEach((_items, i) => {
        if (index != i) {
          _items.forEach((_item, index) => {
            if (_item?.id == id) _items.splice(index, 1);
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>

.row-position {
  margin-top: -20px;
  height: 150px;
  position: relative;
}

.select-position {
  width: 100%;
  position: absolute;
}
</style>
