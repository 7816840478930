<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row no-gutters>
          <v-list-item>
            <v-list-item-content>
              <Label label="登録基幹技能者資格の有無" :editable="editable" />
              <RadioGroup
                v-if="editable"
                name="engineer_job_status"
                :values="item"
                :items="INSURANCE_STATUS_2"
                :editable="editable"
                @onInput="onInput({ value: $event, index })"
              />
              <span v-if="!editable">{{
                getRadioName(INSURANCE_STATUS_2, item.engineer_job_status)
              }}</span>
            </v-list-item-content>
          </v-list-item>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <!-- 市区町村 -->
            <v-list-item>
              <v-list-item-content>
                <Label label="登録基幹技能者名" :editable="editable">
                  <SelectSearch
                    name="cmn_mst_engineer_jobs_id"
                    :values="item"
                    :items="EMPLOYEE_JOB_NAME_ARRAY"
                    item_text="sub_catergory"
                    :editable="editable"
                    title="登録基幹技能者名選択"
                    title_search="登録基幹技能者名"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <Label label="修了年月日" :editable="editable">
                  <InputDatepicker
                    name="complete_date"
                    :values="item"
                    :editable="editable"
                    placeholder=""
                    :max="max"
                    :validation_label="`修了年月日_${index}`"
                    :validation_rules="`compare-to-expiration-date:@有効期限年月日_${index}`"
                    :flagNull="true"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <Label label="有効期限年月日" :editable="editable">
                  <InputDatepicker
                    name="expiration_date"
                    :values="item"
                    :editable="editable"
                    placeholder=""
                    :min="max"
                    :validation_label="`有効期限年月日_${index}`"
                    :validation_rules="`compare-to-complete-date:@修了年月日_${index}`"
                    :flagNull="true"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <!-- 電話番号 -->
        <v-row no-gutters>
          <v-col cols="12" sm="6" md="6">
            <v-list-item>
              <v-list-item-content>
                <Label label="登録基幹技能者確認書類" :editable="editable">
                  <span
                    class="file"
                    v-if="!editable && item['document_name']"
                    @click=" viewSourceWithAuth(item['document_name_url'])"
                  >
                    {{ item["document_name"] }}
                  </span>
                  <FileInput
                    :style="getFileInputStyle(index, 'engineer_jobs_document_name', 'document_name')"
                    :resetValue="resetValue[`${index}_engineer_jobs_document_name`]"
                    v-if="editable"
                    name="document_name"
                    :values="item"
                    :editable="editable"
                    placeholder=""
                    accept="image/jpg, image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                    v-bind:validation_label="`${index}_engineer_jobs_document_name`"
                    validation_rules="maxsize:10|file-type:jpg, png, pdf, jpeg, xls, xlsx"
                    @onInput="onInput({ value: $event, index })"
                  />
                  <span class="file" v-if="judgeDisplayFileInput(index, 'engineer_jobs_document_name', 'document_name')"
                    >{{ item["document_name"] }}
                    <v-btn
                      small
                      icon
                      text
                      @click="
                        onRemoveFile({
                          index: index,
                          name: 'document_name',
                        })
                      "
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </span>
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="ml-4">
            <v-btn
              v-if="index > 0 && editable"
              class="mt-7"
              icon
              @click="remove(index)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn v-if="editable" depressed small color="primary" @click="addEmpty"
      >追加</v-btn
    >
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */
import { Store } from "@/store/Store.js";
import Label from "../../elements/Label.vue";
import InputDatepicker from "../../elements/InputDatepicker.vue";
import Select from "../../elements/Select.vue";
import FileInput from "../../elements/FileInput.vue";
import RadioGroup from "../../elements/RadioGroup.vue";
import { viewSourceWithAuth } from "@/utils/viewSourceAuth";
import {
  INSURANCE_STATUS_2,
} from "@/constants/EMPLOYEE";
import _ from "lodash";
import SelectSearch from "@/components/forms/elements/SelectSearch";

const INITIAL_VALUES = {
  engineer_job_status: 1,
  cmn_mst_engineer_jobs_id: null,
  complete_date: "",
  expiration_date: "",
  document_name: "",
};

export default {
  data: () => {
    return {
      items: [],
      INSURANCE_STATUS_2,
      EMPLOYEE_JOB_NAME_ARRAY: [{ id: null, sub_catergory: ""}, ...Store.state.CmnMst.constants.entries.cmn_mst_engineer_jobs],
      fileValid: {},
      resetValue: {},
      viewSourceWithAuth: viewSourceWithAuth,
      max: new Date().toISOString().slice(0, 10)
    };
  },
  components: {
    SelectSearch,
    Label,
    InputDatepicker,
    Select,
    FileInput,
    RadioGroup,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    errors: {
      type: Object,
      default: {},
    },
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (this.items?.length == 0) {
          this.addEmpty();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    // check input file error
    this.$watch(
      () => [this.values, this.name, this.errors],
      (data) => {
        const errors = {...data[2]};

        // the validation_label list of the FileInput element
        const engineerJobsDocumentName = "engineer_jobs_document_name";
        const fileValid = { ...this.fileValid};
        const _items =  _.cloneDeep(data[0][data[1]]);
        if (errors) {
          let index = 0;
          for(let name in errors) {
            if (name?.includes(engineerJobsDocumentName)){ 
              if(errors[`${index}_${engineerJobsDocumentName}`].length > 0) {
                delete _items[index]['document_name'];
                delete _items[index]['document_name_data'];
                fileValid[name] = false;
              } else {
                fileValid[name] = true;
              }
              index++;
            }
          }
        }
        this.items = _items;
        this.fileValid = fileValid;
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      if (value.fileName) {
        _items[index][`${value.name}_data`] = value.value;
        _items[index][value.name] = value.fileName;
        if (!value.value) {
          delete _items[index][`${value.name}_deleted`];
        }
      } else {
        _items[index][value.name] = value.value;
      }
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.resetValue[`${_index}_engineer_jobs_document_name`] = !this.resetValue[`${_index}_engineer_jobs_document_name`];
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      let _items = [...this.items];
      _items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    onRemoveFile({ index, name }) {
      let _items = [...this.items];
      delete _items[index][name];
      delete _items[index][`${name}_data`];
      if(_items[index][`${name}_url`]) _items[index][`${name}_deleted`] = 1;
      this.items = _items;
      this.resetValue[`${index}_engineer_jobs_document_name`] = !this.resetValue[`${index}_engineer_jobs_document_name`];
      this.$emit("onInput", { name: this.name, value: _items });
    },
    getRadioName(items, value) {
      return items.find((item) => item.value == value)?.name;
    },
    getFileInputStyle(index, subField, parentField) {
      return (this.fileValid[`${index}_${subField}`] && this.items[index][parentField]) ? 'display:none;' : '';
    },
    judgeDisplayFileInput(index,subField, parentField) {
      return this.editable && this.fileValid[`${index}_${subField}`] && this.items[index][parentField];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
</style>
