<template>
  <v-container :style="`height:${mainHeight}px;overflow-x: hidden;overflow-y: auto;`">
    <v-row no-gutters class="pt-8">
      <!-- ヘッダー -->
      <v-col cols="12" sm="3" class="primary--text">作業グループ名</v-col>
      <v-col cols="12" sm="4" class="primary--text"
      >現場監督
      </v-col>
      <v-col cols="12" sm="4" class="primary--text">{{ editable ? '作業グループ長' : 'グループ長' }}</v-col>
      <!-- テーブル -->
      <v-container v-for="(item, index) in workGroups" :key="item.id">
        <v-row class="pt-3">
          <!-- 作業グループ名 -->
          <v-col cols="12" :sm="3">
            <FormGroupInputText
              name="group_name"
              :values="workGroups"
              :index="index"
              :editable="editable"
              :validation_label="getElementLabel('作業グループ名', index)"
              validation_rules="required|max:255"
              @onInput="onInput"
            />
          </v-col>
          <!-- 現場監督 -->
          <v-col cols="12" sm="4">
            <FormGroupSelect
              name="field_directors"
              :index="index"
              :values="workGroups"
              :items="siteManagers"
              :editable="editable"
              :validation_label="getElementLabel('現場監督', index)"
              validation_rules=""
              chipColor="primary"
              @onInput="onInput"
            />
          </v-col>
          <!-- グループ長 -->
          <v-col cols="12" sm="4">
            <FormGroupSelect
              name="field_director_leaders"
              :index="index"
              :values="workGroups"
              :items="groupChiefItems(index)"
              :editable="editable"
              :validation_label="getElementLabel('グループ長', index)"
              validation_rules=""
              chipColor="#29ABE2"
              @onInput="onInput"
            />
          </v-col>
          <v-col cols="12" sm="1" md="1" class="pa-3" v-if="editable && item.removable == 1">
            <v-btn
              class="mt-3" icon @click="remove(index)"
            ><v-icon>mdi-close</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-btn
        style="border-radius: 2vw;" class="mb-2 width-5" v-if="editable" color="primary"
        @click="addGroup"
      > 追加
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
/**
 * ポータルで使用するコンポーネント、関数
 */
import FormGroupInputText from "../../components/FormGroupInputText";
import FormGroupSelect from "../../components/FormGroupSelect";

const INITIAL_VALUES = {
  field_director_leaders: [],
  field_directors: [],
  group_name: "",
  removable: 1
};

/**
 * 定数
 */

export default {
  data() {
    return {
      // テーブルデータ
      // 作業グループ
      workGroups: [],
      siteManagers: [],
    };
  },

  components: {
    FormGroupInputText,
    FormGroupSelect,
  },

  props: {
    // 体制図の中のユニークid
    chart_id: {
      type: Number,
    },
    // 会社id
    company_id: {
      type: Number,
    },
    // 現場id
    site_id: {
      type: Number,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    // 編集可 or not
    editable: {
      type: Boolean,
      default: false,
    },
    formUpdate: Function,
    directors: Array,
    item: Object,
  },

  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.workGroups = [...newValue.working_group?.field_tree_groups];
        this.siteManagers = newValue.site_director?.field_tree_users.filter(user => {
            return user.id > 0;
          }
        );
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  computed: {},

  methods: {
    emit(key, value, parentKey) {
      const objNew = {};
      objNew[key] = value;
      this.$emit("formUpdate", objNew, parentKey);
    },

    // formコンポーネントからの更新通知を受け取ってdataを更新
    onInput({ name, value, index }) {
      const formValues = [...this.workGroups];
      const itemEdited = {...this.workGroups[index]};

      itemEdited[name] = value;
      if (itemEdited["field_director_leaders"]) {
        const fieldDirectorIds = itemEdited["field_directors"].map(e => e.id);
        itemEdited["field_director_leaders"] = itemEdited["field_director_leaders"]
          .filter(e => fieldDirectorIds.includes(e.id));
      }

      formValues[index] = itemEdited;
      this.emit("field_tree_groups", formValues, 'working_group');
    },

    // 作業グループ長の選択リスト
    groupChiefItems(index) {
      return this.filterArray({
        from: this.workGroups[index].field_directors || [],
        remove: this.siteManagers,
      });
    },

    // filter out users not in the group
    filterArray(arr) {
      return [...arr.from].filter((from) => {
        // Leave only workgroup users with unique ids
        return [...arr.remove].some((remove) => {
          return remove.id === from.id;
        });
      });
    },
    getElementLabel(name, index) {
      return `${name}${index + 1}`;
    },
    addGroup() {
      const items = [...this.workGroups];
      items.push({ ...INITIAL_VALUES });
      this.emit("field_tree_groups", items, 'working_group');
    },
    remove(_index) {
      const _items = this.workGroups.filter((_item, index) => {
        return index != _index;
      });
      this.emit("field_tree_groups", _items, 'working_group');
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select {
  border-radius: 4px;
}
</style>
