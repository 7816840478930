<template>
  <div class="wrapper">
    <Select
      :name="name"
      :values="values"
      :items="items"
      item_value="id"
      item_text="name"
      :editable="true"
      @onInput="onInput"
    />
  </div>
</template>
<script>
import Select from "@/components/forms/elements/Select.vue";
import { Store } from "@/store/Store.js";

export default {
  data: () => {
    return {
      items: [],
    };
  },
  props: {
    name: {
      type: String,
    },
    constructionId: {
      type: Number,
    },
    values: {
      type: Object,
    },
  },
  components: {
    Select,
  },
  mounted() {
    /**
     * 時間帯一覧
     */
    this.$watch(
      () => Store.getters["PortalChart/getFieldTimeZones"],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.items = [...newValue];
          if (this.items.length > 0) {
            this.values.work_hour_zone = this.items[0]?.id;
            this.onInput({ name: this.name, value: this.items[0]?.id });
          } else {
            this.values.work_hour_zone = null;
          }
        }
      },
      { immediate: true, deep: true }
    );

    /**
     * 現場idから時間帯一覧を取得
     */
    this.$watch(
      () => this.constructionId,
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          if (newValue) {
            Store.dispatch("PortalChart/getFieldTimeZones", newValue);
          }
        }
      },
      { immediate: true, deep: true }
    );
  },
  methods: {
    onInput({ name, value }) {
      this.$emit("onInput", { name, value });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  height: 40px;
}
</style>
