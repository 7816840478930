<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <template v-if="editable">
            <button class="buttonAdd" @click="showAddSpecForm()">
              <v-icon color="green darken-2">mdi-plus</v-icon> フリー入力
            </button>
            <v-btn
              class="mx-2"
              small
              icon
              :disabled="!selectedItems.length > 0"
              @click="openRemoveDialog()"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
          <template>
            <v-data-table
              v-model="selectedItems"
              show-select
              :headers="MACHINE_TYPE_IN_SPEC_TABLE_LABELS"
              :items="items"
              hide-default-footer
              class="elevation-0 v-data-table__wrapper"
              :noDataText="NO_DATA_MESSAGE"
              item-key="id"
              disable-sort
              :height="getTableHeight"
              :single-select="true"
              fixed-header
              :items-per-page="items.length"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td>
                      <v-checkbox
                        v-if="editable"
                        v-model="selectedItems"
                        :value="item"
                        hide-details
                        style="margin: 0"
                        color="gray"
                      />
                    </td>
                    <td>{{ item.inspection_item }}</td>
                    <td>
                      {{ item.main_point }}
                    </td>
                  </tr>
                  <template v-if="items.length === 0">
                    <td colspan="9">
                      <div class="d-flex justify-center">
                        {{ NO_DATA_MESSAGE }}
                      </div>
                    </td>
                  </template>
                </tbody>
              </template>
            </v-data-table>
          </template>
        </v-card-text>
      </v-card>
      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowRemoveDialog">
        <ConfirmRemoveDialog
          @close="closeRemoveDialog()"
          @yes="confirmRemoveItems()"
          title="選択項目の削除"
          text="以下を削除してもよろしいですか？"
          :items="selectedItems"
          warning
        />
      </Popup>
      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowAddSpecForm">
        <AddMachineSpecForm
          :newItem="newItem"
          @formUpdate="formUpdate"
          @close="closeAddSpecForm"
          @yes="submitAddSpecForm"
        />
      </Popup>
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import Popup from "@/components/common/Popup.vue";
import ConfirmRemoveDialog from "./../../ConfirmRemoveDialog.vue";
import {
  MACHINE_TYPE_IN_SPEC_TABLE_LABELS,
  MACHINE_TYPE_IN_SPEC,
} from "@/constants/COMPANY_MACHINES";
import { NO_DATA_MESSAGE } from "@/constants/COMMON"; //絞り込みフォームで使用
import AddMachineSpecForm from "./AddMachineSpecForm.vue";
import _ from "lodash";

export default {
  data: () => {
    return {
      items: [],
      MACHINE_TYPE_IN_SPEC_TABLE_LABELS,
      MACHINE_TYPE_IN_SPEC,
      NO_DATA_MESSAGE,
      selectedItems: [],
      selectedItemsBeforeUpdate: [],
      isShowRemoveDialog: false,
      isShowAddSpecForm: false,
      newItem: null,
    };
  },
  components: {
    Popup,
    ConfirmRemoveDialog,
    AddMachineSpecForm,
  },
  props: {
    item: {
      type: Array,
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    selectedItemsForm1: {
      type: Array,
      default: 0,
    },
  },
  mounted() {
    this.$watch(
      () => [this.item, this.selectedItemsForm1],
      (newValue) => {
        this.items = _.cloneDeep(newValue[0]);
        this.selectedItems = _.cloneDeep(newValue[1]);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    getTableHeight() {
      return this.mainHeight - 100 + "px";
    },
  },
  methods: {
    generateDefaultItem() {
      return {
        id: uuidv4(),
        newItem: true,
        inspection_item: "",
        main_point: "",
      };
    },
    closeRemoveDialog() {
      this.isShowRemoveDialog = false;
    },
    openRemoveDialog() {
      this.isShowRemoveDialog = true;
    },
    confirmRemoveItems() {
      this.$emit("removeItems", {
        value: this.selectedItems,
        type: MACHINE_TYPE_IN_SPEC[1].id,
      });
      this.selectedItems = [];
      this.isShowRemoveDialog = false;
    },
    submitAddSpecForm() {
      const updateItemBefore = [...this.selectedItems];
      this.updateItemBefore = updateItemBefore;
      this.$emit("changeValue", {
        value: this.newItem,
        type: MACHINE_TYPE_IN_SPEC[1].id,
        selectedItems: this.selectedItems,
      });
      this.isShowAddSpecForm = false;
      this.newItem = this.generateDefaultItem();
    },
    showAddSpecForm() {
      this.newItem = this.generateDefaultItem();
      this.isShowAddSpecForm = true;
    },
    formUpdate(params) {
      this.newItem = { ...params };
    },
    closeAddSpecForm() {
      this.isShowAddSpecForm = false;
      this.newItem = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.buttonAdd {
  border: 1px solid #1b9c4f;
  box-sizing: border-box;
  border-radius: 4px;
  color: #1b9c4f;
  padding: 5px;
  margin-right: 10px;
}
::v-deep .v-data-table .v-data-table__wrapper table {
  table-layout: fixed;
}
::v-deep .v-data-table .v-data-table__wrapper table tbody tr td {
  word-break: break-all;
}
</style>
