const WORKER_INITAL_ITEM = {
  sei: "",
  mei: "",
  kana_sei: "",
  kana_mei: "",
  mail: "",
  birthday: "",
  gender: "",
  postcode: "",
  state: "",
  city: "",
  address: "",
  phone: "",
  id: "",
  items: [{ sei: "yamada", mei: "taro" }],
};

// テーブル設定
const WORKER_TABLE_LABELS = [
  {
    text: "姓",
    value: "sei",
    align: "left",
  },
  {
    text: "名",
    value: "mei",
    align: "left",
  },
  {
    text: "セイ",
    value: "kana_sei",
    align: "left",
  },
  {
    text: "メイ",
    value: "kana_mei",
    align: "left",
  },
  {
    text: "メールアドレス",
    value: "mail",
    align: "left",
  },
  { text: "権限", value: "roll", align: "left" },
  { text: "登録日", value: "created_at", align: "left" },
];

// ソート項目
const WORKER_SORT_ITEMS = [
  { id: "sei", name: "姓" },
  { id: "mei", name: "名" },
  { id: "kana_sei", name: "セイ" },
  { id: "kana_mei", name: "メイ" },
  { id: "mail", name: "メールアドレス" },
  { id: "roll", name: "権限" },
  { id: "created_at", name: "登録日" },
];

/**
 * ロール
 * @returns Object
 */
const WORKER_ROLES = {
  ADMIN_HQ: { id: 1, name: "本社管理者" },
  ADMIN_BRANCH: { id: 2, name: "支店管理者" },
  GROUP_MANAGER: { id: 3, name: "作業グループ長" },
  SITE_MANAGER: { id: 4, name: "現場監督" },
  CLERK: { id: 5, name: "番頭" },
  CHIEF: { id: 6, name: "職長" },
  WORKER: { id: 7, name: "作業員" },
};

/**
 * ロール Select用
 * @returns Array
 */
const WORKER_ROLES_ARRAY = Object.entries(WORKER_ROLES).map((item) => item[1]);

/**
 * 性別
 * @returns Object
 */
const WORKER_GENDER = {
  MALE: { id: 1, name: "男" },
  FEMALE: { id: 2, name: "女" },
  OTHER: { id: 3, name: "そのほか" },
};

/**
 * 性別 Select用
 * @returns Array
 */
const WORKER_GENDER_ARRAY = Object.entries(WORKER_GENDER).map(
  (item) => item[1]
);

/**
 * 身分証明書
 */
const WORKER_ID_ARRAY = [
  { id: 1, name: "運転免許証" },
  { id: 2, name: "個人番号（マイナンバー）カード" },
  { id: 3, name: "パスポート" },
];

export {
  WORKER_INITAL_ITEM,
  WORKER_TABLE_LABELS,
  WORKER_SORT_ITEMS,
  WORKER_ROLES,
  WORKER_ROLES_ARRAY,
  WORKER_GENDER,
  WORKER_GENDER_ARRAY,
  WORKER_ID_ARRAY,
};
