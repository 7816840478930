<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row no-gutters>
          <v-col cols="12" sm="11" md="11">
            <v-list-item>
              <v-list-item-content class="row-position">
                <Label label="火気使用責任者" :editable="editable">
                  <Select
                      class="select-position"
                      name="user_id"
                      :values="item"
                      :editable="flag && editable"
                      :items="FIRE_USE_MANAGER[index]"
                      :label="item.user_name"
                      @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="1" md="1" class="pt-0 pb-0">
            <v-btn
              v-if="index >= row && editable"
              class="mt-16"
              icon
              @click="remove(index)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn :disabled="!flag" v-if="editable" depressed small color="primary" @click="addEmpty"
      >追加</v-btn
    >
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "../../../elements/Label.vue";
import InputText from "../../../elements/InputText.vue";
import Select from "../../../elements/Select.vue";
import _ from "lodash"
//初期値を設定します
const INITIAL_VALUES = {
  user_id: null,
  used_type: 3,
};

export default {
  data: () => {
    return {
      items: [],
      formValues: {},
      FIRE_USE_MANAGER: [],
      flag: false,
    };
  },
  components: {
    Label,
    InputText,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    selectItems: {
      type: Array,
    },
    row: {
      type: Number,
      default: 1,
    },
    flag_name: {
      type: String,
    },
    EMPLOYEE_ARRAY: {
      type: Array,
      default: [],
    }
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        if (newValue[0][`${this.flag_name}`]) {
          this.items = _.cloneDeep(newValue[0][`${this.flag_name}`][newValue[1]]);
          if(this.items?.length == 0){
            this.addEmpty();
          }
          if (this.flag_name == "work_user_plans"){
            this.flag = newValue[0].work_plans.hot_work_flag == 0 ? false : true;
          } else {
            this.flag = newValue[0].work_results.hot_work_flag == 0 ? false : true;
          }
          this.initDataArray();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.EMPLOYEE_ARRAY,
      () => {
        this.initDataArray();
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      let _items = [...this.items];
      _items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    initDataArray() {
      this.FIRE_USE_MANAGER = [];
      this.items?.forEach(() => {
        const employee = _.cloneDeep(this.EMPLOYEE_ARRAY);
        employee.unshift({id: null, name: ""});
        this.FIRE_USE_MANAGER.push(employee);
      });
      this.items?.forEach((items, index) => {
        if (items.user_id) {
          this.convertDataArray(index, items.user_id);
        }
      });
    },
    convertDataArray(index, id) {
      this.FIRE_USE_MANAGER.forEach((_items, i) => {
        if (index != i) {
          _items.forEach((_item, index) => {
            if (_item.id == id) _items.splice(index, 1);
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>

.row-position {
  margin-top: -50px;
  height: 180px;
  position: relative;
}

.select-position {
  width: 100%;
  position: absolute;
}
</style>
