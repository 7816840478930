<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row no-gutters>
                <v-col cols="12" sm="6" md="6">
                  <SectionLabel label="使用会社" :editable="editable" class="ml-4"/>
                  <v-list-item>
                    <v-list-item-content class="row-position">
                      <Label label="工事" :editable="editable" >
                        <Select
                          class="select-position"
                          name="used_field_construction_id"
                          :values="reportsDetailValue"
                          :items="FIELD_CONSTRUCTION_ARRAY"
                          :editable="editable"
                          validation_label="工事"
                          @onInput="onInput(PARENT_REPORT_DETAIL, $event)"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="row-position">
                      <Label label="作業グループ" :editable="editable" >
                        <Select
                          class="select-position"
                          name="used_field_tree_group_id"
                          :values="reportsDetailValue"
                          :items="FIELD_TREE_GROUP_ARRAY"
                          item_text="group_name"
                          :editable="editable"
                          validation_label="作業グループ"
                          @onInput="onInput(PARENT_REPORT_DETAIL, $event)"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="row-position">
                      <Label label="協力会社" :editable="editable">
                        <Select
                          class="select-position"
                          name="used_field_tree_id"
                          :values="reportsDetailValue"
                          :items="COMPANY_PARTNER_ARRAY"
                          item_text="company_name"
                          item_value="field_tree_id"
                          :editable="editable"
                          validation_label="協力会社"
                          :label="reportsDetailValue.used_field_tree_company_name"
                          @onInput="onInput(PARENT_REPORT_DETAIL, $event)"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="row-position">
                      <Label label="使用場所" :editable="editable" >
                        <Select
                          class="select-position"
                          name="used_field_item_tree_id"
                          :values="reportsDetailValue"
                          :items="WORK_PLACE"
                          item_text="item_name"
                          :editable="editable"
                          validation_label="使用場所"
                          @onInput="onInput(PARENT_REPORT_DETAIL, $event)"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="自社・リースの区別">
                        <RadioGroup
                          class="ml-2"
                          style="width: 100px"
                          v-if="editable"
                          name="private_flag"
                          :values="reportsDetailValue"
                          :items="RADIO_MACHINE_COMPANY_FIELD_REPORT_DETAILS"
                          :editable="editable"
                          @onInput="onInput(PARENT_REPORT_DETAIL, $event)"
                        />
                      </Label>
                      <span class="ml-2"
                            v-if="!editable">{{ getRadioName(RADIO_MACHINE_COMPANY_FIELD_REPORT_DETAILS, reportsDetailValue["private_flag"])
                        }}</span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-row>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="使用開始日" :editable="editable" >
                            <InputDatepicker
                              name="used_start_date"
                              :editable="editable"
                              :values="reportsDetailValue"
                              :flagNull="true"
                              validation_label="使用開始日"
                              validation_rules="compare-with-end-date:@使用終了日"
                              @onInput="onInput(PARENT_REPORT_DETAIL, $event)"
                              testid="input-used_start_date"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="使用終了日" :editable="editable">
                            <InputDatepicker
                              name="used_end_date"
                              :editable="editable"
                              :values="reportsDetailValue"
                              :flagNull="true"
                              validation_label="使用終了日"
                              validation_rules="compare-with-start-date:@使用開始日"
                              @onInput="onInput(PARENT_REPORT_DETAIL, $event)"
                              testid="input-used_end_date"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <SectionLabel
                    label="運転者（取扱者）"
                    :editable="editable"
                    class="ml-4"
                  />
                  <v-list-item>
                    <v-list-item-content>
                      <ReportDrivers
                        name="machine_company_field_report_drivers"
                        :values="formValues"
                        :editable="editable"
                        :dataUser="FOREMAN_ARRAY"
                        @onInput="onInput(null, $event)"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "@/components/forms/elements/Label.vue";
import InputText from "@/components/forms/elements/InputText.vue";
import Select from "@/components/forms/elements/Select.vue";
import ReportDrivers from "./components/ReportDrivers.vue";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import SectionLabel from "@/components/forms/elements/SectionLabel.vue";
import RadioGroup from "@/components/forms/elements/RadioGroup.vue";
import { RADIO_MACHINE_COMPANY_FIELD_REPORT_DETAILS } from "@/constants/DEVICE_CARRY_ON";
import _ from "lodash";


export default {
  data: () => {
    return {
      beforeArrayValues: {},
      formValues: {},
      reportsDetailValue: {},
      FIELD_CONSTRUCTION_ARRAY: [{ id: null, name: "" }],
      FIELD_TREE_GROUP_ARRAY: [{ id: null, group_name: "" }],
      COMPANY_PARTNER_ARRAY: [{ field_tree_id: null, company_name: "" }],
      WORK_PLACE: [{ id: null, item_name: "" }],
      FOREMAN_ARRAY: [{ user_id: null, name: "" }],
      RADIO_MACHINE_COMPANY_FIELD_REPORT_DETAILS,
      PARENT_REPORT_DETAIL : "machine_company_field_report_details",
    };
  },
  components: {
    Label,
    InputText,
    Select,
    InputDatepicker,
    SectionLabel,
    ReportDrivers,
    RadioGroup
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false
    },
    mainHeight: {
      type: Number,
      default: 0
    },
    formUpdate: Function,
    isBackData :Boolean
  },
  mounted() {
    this.initData(this.item);
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
        this.reportsDetailValue = { ...newValue.machine_company_field_report_details };
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => this.isBackData,
      (newValue) => {
        if (newValue) {
          this.FIELD_TREE_GROUP_ARRAY = this.beforeArrayValues.groupArrays;
          this.COMPANY_PARTNER_ARRAY = this.beforeArrayValues.companyPartnerArrays;
          this.FOREMAN_ARRAY = this.beforeArrayValues.foreManArrays;
          this.WORK_PLACE = this.beforeArrayValues.workPlace;
        }
      },
      {
        deep: true
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getWorkTypes"],
      (data) => {
        this.FIELD_CONSTRUCTION_ARRAY = [this.getDefaultArrays("id", "name"), ...data];
      },
      {
        deep: true
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getListGroup"],
      (data) => {
        this.FIELD_TREE_GROUP_ARRAY = [this.getDefaultArrays("id", "group_name"), ...data];
      },
      {
        deep: true
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getListCompanyPartner"],
      (data) => {
        this.COMPANY_PARTNER_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name"), ...data];
      },
      {
        deep: true
      }
    );
    this.$watch(
      () => Store.getters["PortalChart/getListCompanyPartnerUser"],
      (data) => {
        let _data = [...data];
        const foremans = this.convertForemanArray([this.getDefaultArrays("user_id", "name")],_data);
        this.FOREMAN_ARRAY = foremans;
      },
      {
        deep: true
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getListItemTrees"],
      (data) => {
        this.WORK_PLACE = [this.getDefaultArrays("id", "item_name"), ...data];
      },
      {
        deep: true
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    }
  },
  methods: {
    /**
     * init data
     */
    initData(data){
      this.getWorkTypes();
      let reportDetail = _.cloneDeep(data.machine_company_field_report_details);
      this.getListFieldTree(reportDetail.used_field_tree_id);
      this.getDataGroupAndCompanyPartNer(reportDetail);
    },
    /**
     * get data group and company partner
     */
    async getDataGroupAndCompanyPartNer(dataDetail){
      await  this.getWorkPlace(dataDetail.used_field_construction_id);
      await this.getListGroups(dataDetail.used_field_construction_id);
      await this.getCompanyPartners(dataDetail.used_field_tree_group_id);
      this.beforeArrayValues['groupArrays'] = this.FIELD_TREE_GROUP_ARRAY;
      this.beforeArrayValues['companyPartnerArrays'] = this.COMPANY_PARTNER_ARRAY;
      this.beforeArrayValues['foreManArrays'] = this.FOREMAN_ARRAY;
      this.beforeArrayValues['workPlace'] = this.WORK_PLACE;
    },
    /**
     * event oninput
     * @param parents_name : parent name to update form values
     * @param name : name input
     * @param value : value input
     */
    onInput(parents_name , { name, value }) {
      let _formValues = { ...this.formValues };
      if (parents_name) {
        _formValues[parents_name][name] = value;
      } else {
        _formValues[name] = value;
      }
      if(name == "used_field_tree_id" || name == "used_field_construction_id" || name == "used_field_tree_group_id") {
        _formValues.machine_company_field_report_drivers = [];
      }
      _formValues = this.getDataByName(_formValues,value,name);
      this.formValues = {..._formValues};
      this.$emit("formUpdate", _formValues);
    },
    convertForemanArray(foremanArray, data) {
      data.forEach((item) => {
        item.name = item.user_name_sei + " " + item.user_name_mei;
        foremanArray.push(item);
      });
      return foremanArray;
    },
    /**
     * action when choose select box
     */
    getDataByName(data,value,name) {
      switch (name) {
        case "used_field_construction_id": {
          this.getListGroups(value);
          this.getWorkPlace(value);
          data.machine_company_field_report_details.used_field_item_tree_id = null;
          data.machine_company_field_report_details.used_field_tree_group_id = null;
          data.machine_company_field_report_details.used_field_tree_id = null;
          this.COMPANY_PARTNER_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name")];
          this.FOREMAN_ARRAY = [this.getDefaultArrays("user_id", "name")];
          this.getCompanyPartners();
          break;
        }
        case "used_field_tree_group_id": {
          data.machine_company_field_report_details.used_field_tree_id = null;
          this.getCompanyPartners(value);
          this.FOREMAN_ARRAY = [this.getDefaultArrays("user_id", "name")];
          break;
        }
        case "used_field_tree_id": {
          if (!data['machine_company_field_report_details']['used_field_tree_group_id'] && data['machine_company_field_report_details']['used_field_tree_id']) {
            let groupId = this.getGroupdIdByFieldTree(data['machine_company_field_report_details']['used_field_tree_id']);
            let hasGroup = this.hasGroupById(groupId);
            if (hasGroup) {
              data['machine_company_field_report_details']['used_field_tree_group_id'] = groupId;
              this.getCompanyPartners(groupId);
            }
          }
          this.FOREMAN_ARRAY = [this.getDefaultArrays("user_id", "name")];
          this.getListFieldTree(value);
          break;
        }
        default:
          break;
      }
      return data;
    },
    /**
     * get list construction
     */
    async getWorkTypes() {
      if (this.CURRENT_SITE.field_id) {
        await Store.dispatch(
          "PortalChart/getWorkTypes",
          this.CURRENT_SITE.field_id
        );
      }
    },
    /**
     * add please choose to select box
     */
    getDefaultArrays(id, value) {
      let results = {};
      results[id] = null;
      results[value] = "";
      return results;
    },

    /**
     * get list group by construction_id
     * @param construction_id : id construction
     */
    async getListGroups(construction_id) {
      if (construction_id) {
        await Store.dispatch("PortalChart/getListGroups", {
          field_construction_id: construction_id,
          user_has_foreman: 1
        });
      }
    },
    /**
     * get list company partner by group
     * @param used_field_tree_group_id : id group
     */
    async getCompanyPartners(used_field_tree_group_id) {
      if (!this.formValues['machine_company_field_report_details'].used_field_construction_id) return;
      const params = {
        company_for_user_flag: 1,
        user_has_foreman: 1,
        state_handling_flag: 0
      };
      if (used_field_tree_group_id) params.field_tree_group_id = used_field_tree_group_id;
      else if (this.formValues['machine_company_field_report_details'].used_field_construction_id) {
        params.field_construction_id = this.formValues['machine_company_field_report_details'].used_field_construction_id;
      }
      await Store.dispatch("PortalChart/getListCompanyPartner", {params});
    },

    /**
     * get list forman array by company
     * @param used_field_tree_id : id company
     */
    async getListFieldTree(used_field_tree_id) {
      if (used_field_tree_id) {
        const params = {
          id: used_field_tree_id,
          state_handling_flag: 0
        };
        await Store.dispatch("PortalChart/getListCompanyPartnerUser", params);
      }
    },
    /**
     * get workplace array by field_id
     */
    async getWorkPlace(val) {
      if (val) {
        let params = {
          field_id: this.CURRENT_SITE.field_id,
          field_construction_id: val
        };
        await Store.dispatch("PortalChart/getListItemTrees", { params });
      } else {
        this.WORK_PLACE = [this.getDefaultArrays("id", "item_name")];
      }
    },
    /**
     * get radio label
     */
    getRadioName(items, value) {
      return items.find((item) => item.value == value)?.name;
    },
    
    getGroupdIdByFieldTree(fieldTreeId) {
      return this.COMPANY_PARTNER_ARRAY.find(e => e.field_tree_id === fieldTreeId)?.field_tree_group_id || null;
    },
  
    hasGroupById(groupId) {
      if (!groupId) return false;
      let group = this.FIELD_TREE_GROUP_ARRAY.find(e => e.id === groupId);
      if (group) return true;
      return false;
    },
  }
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";

.row-position{
  margin-top: -25px;
  height: 140px;
  position: relative;
}

.select-position {
  width: 100%;
  position: absolute;
}
</style>
