<template>
  <v-card class="mx-auto my-12" max-width="374" outlined>
    <v-list-item>
      <v-list-item-content>
        <Label label="メール" :editable="editable" required>
          <InputText
            name="login_id"
            :values="formValue"
            :editable="editable"
            @onInput="onInput"
          />
        </Label>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-btn color="primary" depressed :disabled="invalid" @click="Submit()"
          >Submit</v-btn
        >
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>
<script>
import Label from "../../../components/forms/elements/Label";
import InputText from "../../../components/forms/elements/InputText";

export default {
  components: {
    Label,
    InputText,
  },
  data: () => {
    return {
      formValue: {
        login_id: "hoge@hoge.com",
      },
      editable: true,
    };
  },
  methods: {
    onInput({ name, value }) {
      const formValue = { ...this.formValue };
      formValue[name] = value;
      this.formValue = formValue;
    },
    async Submit() {
      console.log("Form", { ...this.formValue });
    },
  },
};
</script>
