<template>
  <v-card class="dialog">
    <v-card-title>
      <div :style="getTitleColorStyle" class="title">否認通知</div>
    </v-card-title>
    <v-card-text class="body mess-reject" >
      <v-textarea
        :readonly="true"
        :value="message"
        outlined
      ></v-textarea>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!isApproved"
        depressed
        outlined
        :color="getColor"
        class="btn"
        @click="$emit('close')"
      >
        閉じる
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
import
<script>
export default {
  data() {
    return {
    }
  },
  props: {
    message: String,
  },
  computed: {
    getTitleColorStyle() {
      return "color:red;";
    },
    getColor() {
      return "warning";
    },
  },
};
</script>
<style lang="css" scoped>
::v-deep .mess-reject .v-input {
  color: rgba(0, 0, 0, 0.6) !important;
  caret-color: rgba(0, 0, 0, 0.6) !important;
}
::v-deep .mess-reject textarea {
  margin-bottom: 10px !important;
  margin-right: 8px !important;
}

::v-deep .mess-reject .v-text-field--outlined.v-input--has-state fieldset, ::v-deep .mess-reject .v-text-field--outlined.v-input--is-focused fieldset {
  border: 1px #9e9e9e solid !important;
  transition-duration: unset !important;
  transition-property: unset !important;
  transition-timing-function: unset !important;
}

::v-deep .mess-reject .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled)>.v-input__control>.v-input__slot:hover fieldset {
  color: #9e9e9e !important;
}
</style>
