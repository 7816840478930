var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-item',[_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.TABLE_LABELS,"items":_vm.items,"height":_vm.tableHeight,"items-per-page":25,"noDataText":_vm.NO_DATA_MESSAGE,"hide-default-header":"","hide-default-footer":"","item-class":_vm.rowClass},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.icon))])]}},{key:"item.word",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.convertName(item.name_sei + " " + item.name_mei)))])]}},{key:"item.gender",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-chip-group',[_c('v-chip',{class:[
                item.cmn_mst_gender_id === 1
                  ? 'grey is-Active gender'
                  : 'grey lighten-4 is-inActive gender' ],attrs:{"outlined":"","small":""}},[_vm._v(" 男 ")]),_c('v-chip',{class:[
                item.cmn_mst_gender_id === 2
                  ? 'grey is-Active gender'
                  : 'grey lighten-4 is-inActive gender' ],attrs:{"outlined":"","small":""}},[_vm._v(" 女 ")])],1)],1)]}},{key:"item.roll",fn:function(ref){
              var item = ref.item;
return [(item.skills_sub_catergory)?_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":"amber darken-2","dark":""}},[_vm._v(_vm._s(item.skills_sub_catergory))]):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }