<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <!-- 種別 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="種別" width="100%" required :editable="editable">
                        <span @click="openFormMachineCompanyType" style="cursor: pointer" class="select">
                          <span class="select_wrap">
                            <Select
                              name="machineType"
                              :values="dataMachineType"
                              :items="TYPE_DATA"
                              :editable="editable"
                              validation_label="種別"
                              validation_rules="required"
                            />
                          </span>
                        </span>
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 機材名 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="機材名" :editable="editable" required>
                        <InputText
                          name="name"
                          :values="formValues"
                          :editable="editable"
                          validation_label="機材名"
                          validation_rules="required|max:255"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- フリガナ -->
                  <v-list-item>
                    <v-list-item-content class="row-position">
                      <Label
                        label="フリガナ"
                        :editable="editable"
                        required
                      >
                        <InputText
                          name="furigana_name"
                          :values="formValues"
                          :editable="editable"
                          validation_label="フリガナ"
                          validation_rules="required|kana|max:255"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 機番（管理番号）+ シリアル番号 -->
                  <v-row>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label
                            label="機番（管理番号)"
                            :editable="editable"
                          >
                            <InputText
                              name="machine_number"
                              :values="formValues"
                              :editable="editable"
                              validation_label="機番（管理番号)"
                              validation_rules="halfsize|max:50"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label
                            label="シリアル番号"
                            :editable="editable"
                          >
                            <InputText
                              name="serial_number"
                              :values="formValues"
                              :editable="editable"
                              validation_label="シリアル番号"
                              validation_rules="halfsize|max:50"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                  <v-list-item>
                    <v-list-item-content class="row-position">
                      <Label
                        label="メーカー"
                        :editable="editable"
                      >
                        <InputText
                          name="manufacturer"
                          :values="formValues"
                          :editable="editable"
                          validation_label="メーカー"
                          validation_rules="max:255"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>

                  <v-row no-gutters v-if="!editable">
                    <v-col sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="製造年月日" :editable="editable">
                            <v-text-field
                              v-model="formValues.manufacture_date"
                              dense
                              color="primary"
                              :disabled="!editable"
                              :filled="editable"
                            ></v-text-field>
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="editable">
                    <v-col sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label
                            label="製造年月日"
                            :editable="editable"
                          >
                            <InputDatepicker
                              name="manufacture_date"
                              :editable="editable"
                              :values="formValues"
                              :max="max"
                              :flagNull="true"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters>
                    <v-col sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="管理区分" :editable="editable" required>
                            <div v-if="!editable" class="text-style mt-3 mb-3">
                              {{ this.formValues.management_section === MANAGEMENT_SECTION[0].value ? MANAGEMENT_SECTION[0].name : MANAGEMENT_SECTION[1].name }}
                            </div>
                            <RadioGroup
                              v-if="editable"
                              name="management_section"
                              :values="formValues"
                              :items="MANAGEMENT_SECTION"
                              :editable="editable"
                              @onInput="onInput"
                              :disableRadio="checkRadioValue"
                              validation_rules="required"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="数量" :editable="editable">
                            <InputText
                              name="quantity"
                              :values="formValues"
                              :editable="editable && editQuantity"
                              validation_label="数量"
                              :validation_rules="getQuantityRules"
                              @onInput="onInput"
                              suffix="個"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="機材画像" :editable="!editable" />
                      <KeyPlanImagesInput
                        name="machine_company_images"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput"
                        :errors="errors"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="備考" :editable="editable">
                        <InputTextArea
                          name="remarks"
                          :values="formValues"
                          :editable="editable"
                          validation_label="備考"
                          @onInput="onInput"
                          validation_rules="max:512"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
      <Popup :dialog="isShowMachineCompanyType">
        <MachineCompanyTypeForm
          @cancel="closeMachineCompanyForm"
          @changeMachineCompanyType="changeMachineCompanyType"
          :machine_company_field_type_item_tree_id="formValues.machine_company_type_item_tree_ids"
          :machine_company_type_tree="MACHINE_COMPANY_FIELD_TYPE_ARRAYS"
        />
      </Popup>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "../elements/Label.vue";
import InputText from "../elements/InputText.vue";
import Select from "../elements/Select.vue";
import KeyPlanImagesInput from "./components/KeyPlanImagesInput.vue";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import RadioGroup from "../elements/RadioGroup.vue";
import InputTextArea from "../elements/InputTextArea.vue";
import MachineCompanyTypeForm from "./components/MachineCompanyTypeForm.vue";
import Popup from "@/components/common/Popup.vue";
import _ from "lodash";

export default {
  data: () => {
    return {
      TYPE_DATA: [{ id: null, name: "" }],
      dataMachineType: { machineType: null },
      machineType: null,
      formValues: {},
      isFileValid: false,
      editQuantity: false,
      MACHINE_COMPANY_FIELD_TYPE_ARRAYS: [],
      isShowMachineCompanyType: false,
      max: new Date().toISOString().slice(0, 10)
    };
  },
  components: {
    Label,
    InputText,
    Select,
    KeyPlanImagesInput,
    InputDatepicker,
    RadioGroup,
    InputTextArea,
    Popup,
    MachineCompanyTypeForm,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    errors: {
      type: Object,
      default: {},
    },
    formUpdate: Function,
    MANAGEMENT_SECTION: Array,
    machinesOnsite: Number,
  },
  mounted() {
    this.$watch(
      () => [
        this.item,
        Store.getters['CompanyMachines/getMachineCompanyType']
      ],
      (newValue) => {
        this.formValues = { ...newValue[0] };
        if(newValue[1]){
          this.MACHINE_COMPANY_FIELD_TYPE_ARRAYS = _.cloneDeep(newValue[1]);
        }
        this.getMachineCompanyFieldType(newValue[0]);
        if(this.formValues['management_section'] == this.MANAGEMENT_SECTION[0].value) {
          this.editQuantity = false;
          this.formValues.quantity = 1;
        } else {
          this.editQuantity = true;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    getQuantityRules() {
      if(this.machinesOnsite) return `required|max:9|check-quantity-machine:${this.machinesOnsite}`;
      return 'required|max:9|check-quantity';
    },
    checkRadioValue() {
      if(this.machinesOnsite > 1) return this.MANAGEMENT_SECTION[0].value;
      return null;
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      if( name === 'management_section' &&  value === this.MANAGEMENT_SECTION[0].value) {
        formValues['quantity'] = 1;
      }
      if(name == "quantity") {
        value = value.trim();
      }
      formValues[name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },

    openFormMachineCompanyType() {
      if(this.editable){
        this.isShowMachineCompanyType = true;
      }
    },
    closeMachineCompanyForm() {
      this.isShowMachineCompanyType = false;
    },
    getMachineCompanyFieldType(data) {
      let arr = _.cloneDeep(data.machine_company_type_item_tree_ids);
      let machine_type_array = _.cloneDeep(this.MACHINE_COMPANY_FIELD_TYPE_ARRAYS);
      let rs = "";
      if (arr && arr.length > 0) {
        arr.forEach((e, index) => {
          let a1 = machine_type_array.find(e1 => e1.id == e);
          rs = index == 0 ? rs + a1.type : rs + "/" + a1.type;
          machine_type_array = a1.childrens;
        });
        this.dataMachineType.machineType = 1
        this.TYPE_DATA = [{ id: 1, name: rs }];
      } else {
        rs = "";
        this.dataMachineType.machineType = null
        this.TYPE_DATA = [{ id: null, name: rs }];
      }

    },
    changeMachineCompanyType(value) {
      this.isShowMachineCompanyType = false;
      const formValues = { ...this.formValues };
      formValues.machine_company_type_item_tree_ids = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";
</style>
<style lang="scss" scoped>
.select{
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.select_wrap{
  width: 100%;
  pointer-events: none;
}
.row-position {
  margin-top: -20px;
  height: 150px;
  position: relative;
}

.select-position {
  width: 100%;
  position: absolute;
}

.text-style {
  font-size: 16px;
}
</style>