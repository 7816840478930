import Vue from "vue";
import Vuex from "vuex";
import { employees } from "@/api/modules/employees";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = employees;

export const Employees = {
  namespaced: true,

  state: {
    data: [],
    pagination: {},
    employeeDetail: {},
    userExperiences: [],
    isDirector: false,
    isPlanManager: false,
    isForeman: false,
    is_field_director_or_foreman: false,
    allUser: [],
    pagination_user: {},
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_EMPLOYEE_DETAIL(state, payload) {
      state.employeeDetail = payload;
    },
    SET_IS_DIRECTOR(state, payload) {
      state.isDirector = payload;
    },
    SET_IS_PLAN_MANAGER(state, payload) {
      state.isPlanManager = payload;
    },
    SET_IS_FOREMAN(state, payload) {
      state.isForeman = payload;
    },
    SET_IS_FIELD_DIRECTOR(state, payload) {
      state.is_field_director_or_foreman = payload;
    },
    SET_ALL_USER(state, payload) {
      if (payload.length > 0 && !state.allUser.find(e => e.id === payload[0].id)) {
        state.allUser = state.allUser.concat(payload);
      }
    },
    SET_PAGINATION_USER(state, payload) {
      state.pagination_user = payload;
    },
    CLEAR_ALL_USER(state) {
      state.allUser = [];
    },
    RESET_DATA(state) {
      state.isDirector = false
      state.isPlanManager = false
    }
  },

  actions: {
    async get({ commit }, payload) {
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;
      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },

    async getDetail({ commit }, payload) {
      const response = await ENTITY.getDetail(payload);
      for(let key in response.data.contents.entries) {
        if(!response.data.contents.entries[key]) {
          response.data.contents.entries[key] = {};
        }
      }
      const entries =  response.data.contents.entries;
      commit("SET_EMPLOYEE_DETAIL", entries);
      return response;
    },

    async post(_context, payload) {
      return await ENTITY.post(payload);
    },

    async update(_context, payload) {
      return await ENTITY.update(payload);
    },

    async delete(_context, payload) {
      return await ENTITY.delete(payload);
    },

    async inviteEmployee(_context, payload) {
      return await ENTITY.inviteEmployee(payload);
    },

    async getUserExperiences(_context, payload) {
      return await ENTITY.getUserExperiences(payload);
    },

    async checkIsDirector({ commit }, payload) {
      const result =await ENTITY.checkIsDirector(payload);
      const entries = result.data.contents.entries;
      commit("SET_IS_DIRECTOR", entries.is_field_director);
      commit("SET_IS_PLAN_MANAGER", entries.is_plan_manager);
      return result;
    },

    async checkIsForeman({ commit } , payload) {
      const result = await ENTITY.checkIsForeman(payload);
      const entries= result.data.contents.entries;
      commit("SET_IS_FOREMAN", entries.is_field_foreman)
    },
    async checkIsFieldDirector({ commit } , payload) {
      const result = await ENTITY.checkIsFieldDirector(payload);
      const { is_field_director_or_foreman } = result.data.contents.entries;
      commit("SET_IS_FIELD_DIRECTOR", is_field_director_or_foreman)
    },
    async updateStopUser(_context, payload) {
      return await ENTITY.updateStopUser(payload);
    },
    async addUserInfo(_context, payload) {
      return await ENTITY.addUserInfo(payload);
    },
    async getAllUser({ commit }, payload) {
      const response = await ENTITY.getAllUser(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents.entries;
      commit("SET_ALL_USER", entries);
      commit("SET_PAGINATION_USER", pagination);
      return response;
    },
    clearListUser({commit}) {
      commit("CLEAR_ALL_USER");
    },
    resetData({ commit }) {
      commit("RESET_DATA");
    }
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getEmployeeDetail: (state) => {
      return state.employeeDetail;
    },
    getIsDirector: (state) => {
      return state.isDirector;
    },
    getIsPlanManager: (state) => {
      return state.isPlanManager;
    },
    getIsForeman: (state) => {
      return state.isForeman;
    },
    getIsFieldDirector: (state) => {
      return state.is_field_director_or_foreman;
    },
    getAllUser: (state) => {
      return state.allUser;
    },
    getPaginationUser: (state) => {
      return state.pagination_user;
    },
  },
};
