<template>
  <!-- parent table -->
  <v-data-table
    :headers="tableLabels"
    :items="items"
    :items-per-page="itemsPerPage"
    :height="tableHeight"
    fixed-header
    hide-default-footer
    disable-sort
    :show-select="true"
    class="elevation-0 v-data-table__wrapper"
    sort-by="updatedAt"
  >
    <!-- parent table template -->
    <template v-slot:body="{ items }">
      <template v-if="items && items.length > 0">
        <tbody v-for="item in items" :key="item.field_tree_id">
          <tr style="background-color: #DCDCDC;">
            <td class="start">
              <v-row>
                <v-col cols="12" sm="12">
                  <!-- toggle icon -->
                  <v-icon @click.stop="toggleShowChildren(item.field_tree_id)">
                    {{
                      isShowChildren[item.field_tree_id]
                        ? "mdi-chevron-down"
                        : "mdi-chevron-right"
                    }}
                  </v-icon>
                </v-col>
              </v-row>
            </td>
            <td class="col-status">
              <div>
                {{ convertLongText(item.company_name, 22)}}
              </div>
            </td>
            <td>{{displayData(item.total_company_time_length)}}</td>
            <td v-for="(time,index) in item.company_time_length" :key="index">{{ displayData(time.time) }}</td>
          </tr>
          <!-- child table template -->
          <template v-for="itemChild in item.children">
            <tr
              v-show="isShowChildren[item.field_tree_id]"
              class="child-table"
              :key="itemChild.field_tree_user_id"
            >
              <td class="start">
              </td>
              <td>{{ convertLongText(itemChild.user_name, 22)}}</td>
              <td>
                {{displayData(itemChild.total_user_time_length)}}
              </td>
              <td v-for="(time,index) in itemChild.user_time_length" :key="index">{{ displayData(time.time) }}</td>
            </tr>
          </template>
          <tr
            v-show="
              isShowChildren[item.field_tree_id] &&
              (childTableDisplayMoreNumber(
                item.total_children_item,
                item.children.length
              ) > 0)
            "
          >
            <td colspan="10">
              <div class="d-flex justify-center">
                <v-btn
                  x-small
                  depressed
                  :disabled="isDisable"
                  class="mr-4 display-more"
                  color="#E5E5E5"
                  v-if="
                    childTableDisplayMoreNumber(
                      item.total_children_item,
                      item.children.length
                    ) > 0
                  "
                  @click="getChildren(item)"
                  >さらに表示する
                  <v-badge
                    class="display-more-number"
                    inline
                    color="#767676"
                    :content="
                      childTableDisplayMoreNumber(
                        item.total_children_item,
                        item.children.length
                      )
                    "
                  />
                </v-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
      <div v-else-if="isNoDataMessage" class="schedule_nodata mt-4">
        <span>{{ NO_DATA_MESSAGE }}</span>
      </div>
    </template>
  </v-data-table>
</template>
<script>
export default {
  data() { 
    return { 
      isDisable: false,
      time_out: null,
    }
  },
  props: {
    tableLabels: Array,
    itemsPerPage: Number,
    tableHeight: Number,
    items: Array,
    updateCheckbox: Function,
    toggleShowChildren: Function,
    childTableDisplayMoreNumber: Function,
    isShowChildren: Object,
    NO_DATA_MESSAGE: String,
    isNoDataMessage: Boolean,
  },
  methods: {
    getChildren(item) {
      this.isDisable = true;
      // Re-enable after submit
      this.time_out = setTimeout(() => {
        this.isDisable = false;
      }, 1000);
      this.$emit('getChildItems', item.field_tree_id, 
        Math.floor((item.children.length - 3) / 5) + 2);
    },
    displayData(value){
      return parseFloat(Number(value).toFixed(2)) + 'h';
    },
    convertLongText(name, lengthText) {
      let result = "";
      if (name) {
        result = name;
        if (name.length > lengthText) {
          result = name.substring(0, lengthText) + "•••";
        }
      }
      return result;
    },
  },
  /**
   * Important: clear timeout
   */
  beforeDestroy() {
    // clear the timeout before the component is destroyed
    clearTimeout(this.time_out);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
// ヘッダーとテーブルコンテンツのたて位置を合わせるために
// show-selectオプションをONにした状態でcheckboxを非表示
::v-deep .v-data-table-header {
  .v-input--selection-controls__input {
    display: none !important;
  }
}

td.start div.row {
  width: 75px !important;
}
td.col-status {
  width: 110px !important;
}
.child-table {
  td.start {
    padding-right: 0;
  }
}

.schedule_nodata {
  position: absolute;
  left: 50%;
}

button.display-more {
  height: 20px !important;
}

.display-more-number ::v-deep .v-badge__badge {
  font-size: 10px;
}

.display-more-number ::v-deep .v-badge__badge {
  padding: 1px;
  height: 14px;
}
</style>
