<template>
  <div class="attachFile" :class="large ? 'Large' : ''">
    <button
      v-if="isRemove"
      class="attachFile__delete"
      @click="$emit('removeAttachFile', { id: file.id })"
    >
      <v-icon>mdi-close</v-icon>
    </button>
    <img
      v-if="!isPdf && 'image_url' in file && file.image_url"
      @click="$emit('showAttachFile', { id: file.id })"
      :src="fileSrc"
    />
    <div
      v-if="isPdf"
      @click="$emit('showAttachFile', { id: file.id })"
      class="file__pdf"
    >
      PDF
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import { getParamsOfUrlReadFile } from "@/utils/viewSourceAuth";

export default {
  data: () => {
    return {
      fileSrc: null,
    };
  },
  computed: {
    isPdf() {
      return this.file.type == "pdf";
    },
  },
  mounted() {
    this.$watch(
      () => this.file,
      async (newValue) => {
        if ("image_url" in newValue) {
          const sourceUrl = newValue.image_url;
          if (!sourceUrl) return;
          const params = getParamsOfUrlReadFile(sourceUrl);
          const response = await Store.dispatch("File/readFileOfChat", params);
          if (!response.hasError) {
            this.fileSrc = window.URL.createObjectURL(
              new Blob([response.data], {
                type: response.headers["content-type"],
              })
            );
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  props: {
    large: {
      type: Boolean,
      defalut: false,
    },
    file: {
      type: Object,
    },
    isRemove: {
      type: Boolean,
      deafault: false,
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.attachFile {
  width: 48px;
  height: 48px;
  border: 1px solid black;
  position: relative;
  margin-right: 8px;
  border-radius: 4px;
  &.Large {
    width: 64px;
    height: 64px;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }

  .attachFile__delete {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: #ccc;
    z-index: 1;
  }

  .file__pdf {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    background-color: #ccc;
  }
}
</style>
