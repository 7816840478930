<template>
  <v-card class="dialog">
    <v-card-title>
      <div class="title">{{ title }}</div>
    </v-card-title>
    <v-card-text>
      <ValidationObserver ref="observer" v-slot="observer">
        <v-form ref="form" lazy-validation autocomplete="off">
          <v-list-item>
            <v-list-item-content>
              <div>機械特有の点検項目を追加します</div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <Label label="点検項目" :editable="true" required>
                <InputText
                  name="inspection_item"
                  :editable="true"
                  :values="formValues"
                  validation_label="点検項目"
                  validation_rules="required|max:255"
                  @onInput="onInput"
                />
              </Label>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <Label label="点検主眼点" :editable="true" required>
                <InputTextArea
                  name="main_point"
                  :editable="true"
                  :values="formValues"
                  validation_label="点検主眼点"
                  validation_rules="required|max:512"
                  @onInput="onInput"
                />
              </Label>
            </v-list-item-content>
          </v-list-item>
        </v-form>
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>
      <div></div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="primary"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn
        depressed
        class="btn"
        color="primary"
        @click="addItems"
        :disabled="!valid"
      >
        追加
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback";
import Label from "@/components/forms/elements/Label";
import InputText from "@/components/forms/elements/InputText.vue";
import InputTextArea from "@/components/forms/elements/InputTextArea.vue";
export default {
  data() {
    return {
      formValues: {
        inspection_item: "",
        main_point: "",
      },
      valid: false,
    };
  },
  components: {
    Label,
    InputText,
    InputTextArea,
    ValidationCallback,
    ValidationObserver,
  },
  props: {
    title: {
      type: String,
    },
  },
  methods: {
    onInput({ name, value }) {
      let _formValue = {...this.formValues};
      _formValue[name] = value;
      this.formValues = _formValue;
    },
    updateValidate({ valid }) {
      this.valid = valid;
    },
    addItems() {
      this.$emit('addInspec', this.formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .title {
    font-size: 12px;
    color: #1b9c4f;
  }
}
</style>
