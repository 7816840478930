<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row no-gutters>
          <v-col cols="12" sm="11" md="11">
            <v-list-item>
              <v-list-item-content>
                <Label label="酸素欠乏危険作業主任者" :editable="editable">
                  <Select
                    name="user_id"
                    :values="item"
                    :items="ANOXIA_DANGEROUS_WORK_CHIEF[index]"
                    :editable="editable"
                    :label="item.user_name"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="1" md="1" class="pt-0 pb-0">
            <v-btn
              v-if="index >= row && editable"
              class="mt-8"
              icon
              @click="remove(index)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn class="ml-4" v-if="editable" depressed small color="primary" @click="addEmpty(1)"
      >追加</v-btn
    >
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "../../../elements/Label.vue";
import Select from "../../../elements/Select.vue";
import { Store } from "@/store/Store.js";
import _ from "lodash";
//初期値を設定します
const INITIAL_VALUES = {
  user_id: null,
  used_type: 4,
};

export default {
  data: () => {
    return {
      items: [],
      formValues: {},
      ANOXIA_DANGEROUS_WORK_CHIEF: [],
      EMPLOYEE_ARRAY: [],
    };
  },
  components: {
    Label,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    row: {
      type: Number,
      default: 1,
    },
  },
  mounted() {
    this.$watch(
      () => Store.getters["PortalChart/getListCompanyPartnerUser"],
      (data) => {
        this.EMPLOYEE_ARRAY = _.cloneDeep(data).filter(e => e.state_handling_flag === 0 || e.id === null);
        this.EMPLOYEE_ARRAY.forEach((element) => {
          element.name = element.user_name_sei + " " + element.user_name_mei;
          element.id = element.user_id;
        });
        this.initDataArray();
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        if (newValue[0]["work_company_user_results"]) {
          this.items = _.cloneDeep(newValue[0]["work_company_user_results"][newValue[1]]);
          if (this.items?.length == 0) {
            this.addEmpty(2);
          }
          this.initDataArray();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty(number) {
      let _items = [...this.items];
      for (let i = 1; i <= number; i++) {
        _items.push({ ...INITIAL_VALUES });
      }
      this.$emit("onInput", { name: this.name, value: _items });
    },
    initDataArray() {
      this.ANOXIA_DANGEROUS_WORK_CHIEF = [];
      this.items?.forEach(() => {
        const employee = _.cloneDeep(this.EMPLOYEE_ARRAY);
        employee.unshift({ id: null, name: "" });
        this.ANOXIA_DANGEROUS_WORK_CHIEF.push(employee);
      });
      this.items?.forEach((items, index) => {
        if (items.user_id) {
          this.convertDataArray(index, items.user_id);
        }
      });
    },
    convertDataArray(index, id) {
      this.ANOXIA_DANGEROUS_WORK_CHIEF.forEach((_items, i) => {
        if (index != i) {
          _items.forEach((_item, index) => {
            if (_item.id == id) _items.splice(index, 1);
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.row-position{
  position: relative;
  height: 150px;
  margin-top: -20px;
}

.select-position{
  position: absolute;
  width: 100%;
}
</style>