const MACHINE_INITAL_ITEM = {
  name: null,
  machine_company_type_item_tree_id: null,
  furigana_name: null,
  machine_number: null,
  serial_number: null,
  manufacturer: null,
  manufacture_date: null,
  management_section: 1,
  quantity: 1,
  remarks: null,
  maximum_work_floor_height: null,
  movable_load: null,
  body_length: null,
  body_width: null,
  body_height: null,
  vehicle_weight: null,
  outrigger_overhang_dimensions: null,
  bucket_volume: null,
  prevention_measures: null,
  characteristics: null,
  annual: null,
  monthly: null,
  identification: null,
  performance_test: null,
  vehicle_inspection: null,
  interpersonal: null,
  objective: null,
  passenger: null,
  others: null,
  insurance_remarks: null,
  machine_company_images: [
    {
      image: null,
      image_data: null,
    },
  ],
  machine_company_licenses: [
    {
      id: null,
      catergory_id: null,
      cmn_mst_licence_id: null,
    },
  ],
  machine_company_sp_trainings: [
    {
      id: null,
      cmn_mst_sp_training_id: null,
    },
  ],
};

/**
 * (共通)
 * タイトル
 */
const TITLE_FORM_CREATE_EDIT = {
  NEW: "機材情報",
  EDIT: "機材情報",
};

const TITLE_FORM_MACHINE_CLASSIFY = "機材分類情報";
const TITLE_FORM_MACHINE_CHECKLIST = "点検項目";

/**
 * フォームとタブの設定
 */
const FORMS_CREATE_EDIT = {
  MachineFormPage1: { id: 1, title: "基本情報" },
  MachineFormPage2: { id: 2, title: "性能" },
  MachineFormPage3: { id: 3, title: "検査・保険" },
};

const FORMS_CHECK_LIST = {
  MachineFormCheckList1: { id: 1, title: "作業前点検" },
  MachineFormCheckList2: { id: 2, title: "月例点検" },
};

const TITLE_FORM_CREATE_MACHINE_TYPE = {
  MachineFormPage1: { id: 1, title: "基本情報" },
};

// テーブル設定
const MACHINE_TABLE_LABELS = [
  {
    text: "",
    align: "left",
    value: "",
  },
  {
    text: "種別",
    value: "type",
    align: "left",
  },
  {
    text: "機材",
    value: "name",
    align: "left",
  },
  {
    text: "在庫",
    value: "remaining",
    align: "left",
  },
  {
    text: "数量",
    value: "quantity",
    align: "left",
  },
  {
    text: "機番",
    value: "machine_number",
    align: "left",
  },
  {
    text: "現場 / 搬入日",
    value: "delivery_date",
    align: "left",
  },
  {
    text: "画像",
    value: "image_flag",
    align: "right",
  },
];

const MACHINE_TYPE_IN_SPEC_TABLE_LABELS = [
  {
    text: "点検項目",
    value: "inspection_item",
    align: "left",
    width: "30%",
  },
  {
    text: "点検主眼点",
    value: "main_point",
    align: "left",
    width: "70%",
  },
];

const MACHINE_SORT_ITEMS = [
  { id: "type_name", name: "種別" },
  { id: "name", name: "機材名" },
  { id: "remaining", name: "在庫" },
  { id: "quantity", name: "数量" },
  { id: "machine_number", name: "機番" },
];

const MACHINE_TYPE_ARRAY = [
  { id: 1, name: "車輌系建設機械" },
  { id: 2, name: "フォークリフト" },
  { id: 3, name: "高所作業車" },
  { id: 4, name: "その他" },
];

const MANAGEMENT_SECTION = [
  { id: 1, name: "個体管理", value: 1 },
  { id: 2, name: "数量管理", value: 0 },
];

const MACHINE_TYPE_IN_SPEC = [
  { id: 1, value: "machine_company_inspection_lists" },
  { id: 2, value: "machine_company_monthly_inspection_lists" },
];

export {
  MACHINE_INITAL_ITEM,
  MACHINE_TABLE_LABELS,
  MACHINE_SORT_ITEMS,
  MACHINE_TYPE_ARRAY,
  TITLE_FORM_CREATE_EDIT,
  FORMS_CREATE_EDIT,
  MANAGEMENT_SECTION,
  TITLE_FORM_CREATE_MACHINE_TYPE,
  TITLE_FORM_MACHINE_CLASSIFY,
  TITLE_FORM_MACHINE_CHECKLIST,
  FORMS_CHECK_LIST,
  MACHINE_TYPE_IN_SPEC_TABLE_LABELS,
  MACHINE_TYPE_IN_SPEC,
};
