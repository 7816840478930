export const SVG_COLOR = {
  rectangle_white: {
    key: "Rectangle_white",
    src: "/images/placementSplite/svg/Rectangle_white.svg",
  },
  rectangle_red: {
    key: "Rectangle_red",
    src: "/images/placementSplite/svg/Rectangle_red.svg",
  },
  rectangle_blue: {
    key: "Rectangle_blue",
    src: "/images/placementSplite/svg/Rectangle_blue.svg",
  },
  rectangle_green: {
    key: "Rectangle_green",
    src: "/images/placementSplite/svg/Rectangle_green.svg",
  },
  rectangle_black: {
    key: "Rectangle_black",
    src: "/images/placementSplite/svg/Rectangle_black.svg",
  },
  circle_white: {
    key: "Circle_white",
    src: "/images/placementSplite/svg/Circle_white.svg",
  },
  circle_red: {
    key: "Circle_red",
    src: "/images/placementSplite/svg/Circle_red.svg",
  },
  circle_blue: {
    key: "Circle_blue",
    src: "/images/placementSplite/svg/Circle_blue.svg",
  },
  circle_green: {
    key: "Circle_green",
    src: "/images/placementSplite/svg/Circle_green.svg",
  },
  circle_black: {
    key: "Circle_black",
    src: "/images/placementSplite/svg/Circle_black.svg",
  },
};
