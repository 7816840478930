<template>
  <v-card class="dialog">
    <v-card-title>
      <div :style="getTitleColorStyle" class="title">エラー</div>
    </v-card-title>
    <v-card-text class="body">
      <div class="text">ファイルの読み込みに失敗しました。</div>
    </v-card-text>
    <v-card-text class="body">
      <div class="text" v-for="(textErr, index) in text" :key="index">
        {{ textErr }}
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="warning"
        class="btn"
        @click="$emit('close')"
      >
        閉じる
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data(){
      return{
        isDisable: false,
        timeout: null,
      }
  },
  components: {},
  props: {
    text: {
      type: Array,
    },
  },
  computed: {
    getTitleColorStyle() {
      return "color:red;";
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .title {
    font-size: 12px;
  }
  .body {
    .text {
      margin: auto;
      width: 90%;
      margin-left: 30px;
    }
  }
  .item {
    &:after {
      content: ",";
    }
    &:last-child {
      &:after {
        content: "";
      }
    }
  }
}
</style>
