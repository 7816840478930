<template>
  <div>
    <v-treeview
      v-if="items.length > 0"
      hoverable
      dense
      :items="items"
      return-object
      item-children="childrens"
      expand-icon="mdi-chevron-down"
    >
      <template v-slot:label="{ item }">
        <div v-if="item.childrens && item.category" class="row_flex">
          <span class="category">{{ item.category }}</span>
          <v-spacer></v-spacer>
          <span>{{ item.total }}人</span>
        </div>
        <div
          v-if="item.childrens && !item.category && !item.sub_category"
          class="row_flex"
        >
          <span>登録なし</span>
          <v-spacer></v-spacer>
          <span>{{ item.total }}人</span>
        </div>
        <div v-if="item.childrens && item.sub_category" class="row_flex">
          <span class="sub_category">{{ item.sub_category }}</span>
          <v-spacer></v-spacer>
          <span>{{ item.sub_total }}人</span>
        </div>
        <div v-else class="information">
          <div class="name_infor">
            {{ item.user_name }}
          </div>
          <span class="ml-2"></span>
          <div class="name_infor">
            {{ item.company_name }}
          </div>
        </div>
      </template>
    </v-treeview>
    <div v-else class="mt-10" style="text-align: center">
      <span class="nodata">{{ NO_DATA_MESSAGE }}</span>
    </div>
  </div>
</template>

<script>
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
export default {
  data: () => {
    return {
      NO_DATA_MESSAGE,
    };
  },
  props: {
    items: {
      type: Array,
    },
  },
};
</script>
<style lang="scss" scoped>
.row_flex {
  display: flex;
}
.nodata {
  color: rgba(162, 162, 162, 255);
}
.information {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.name_infor {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 9vw;
  overflow: hidden;
}
.category {
  text-overflow: ellipsis;
  max-width: 80%;
  overflow: hidden;
}
.sub_category {
  text-overflow: ellipsis;
  max-width: 80%;
  overflow: hidden;
}
</style>
