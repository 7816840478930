<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row no-gutters>
          <v-col cols="12" sm="10" md="10">
            <v-list-item>
              <v-list-item-content>
                <Label
                  label="作業安全指示書用画像（キープラン）欄"
                  :editable="editable"
                >
                  <span class="file"
                    v-if="!editable && item['image']"
                    @click="viewSourceWithAuth(item['image_url'])"
                    >{{ item["image"] }}
                  </span>
                  <FileInput
                    :style="getFileInputStyle(index, 'image', 'image')"
                    :resetValue="resetValue[`${index}_image`]"
                    v-if="editable"
                    name="image"
                    :values="item"
                    :editable="editable"
                    placeholder="device_image.jpg"
                    accept="image/jpg, image/png, image/jpeg, application/pdf"
                    v-bind:validation_label="`${index}_image`"
                    validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf"
                    @onInput="onInput({ value: $event, index })"
                  />
                  <span class="file" v-if="judgeDisplayFileInput(index, 'image', 'image')"
                    >{{ item["image"] }}
                    <v-btn
                      small
                      icon
                      text
                      @click="onRemoveFile(index, 'image')"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </span>
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col
            v-if="index > 0 && editable"
            cols="12"
            sm="2"
            md="2"
            class="pt-0 pb-0"
          >
            <v-btn class="mt-8" icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn class="ml-4" v-if="editable" color="primary" @click="addEmpty"
      >追加</v-btn
    >
  </div>
</template>

<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "../../elements/Label.vue";
import Select from "../../elements/Select.vue";
import FileInput from "../../elements/FileInput.vue";
import _ from "lodash";
import { viewSourceWithAuth } from "@/utils/viewSourceAuth";

//初期値を設定します
const INITIAL_VALUES = {
  image: null,
  image_data: null,
};

export default {
  data: () => {
    return {
      items: [],
      fileValid: {},
      resetValue: {},
      viewSourceWithAuth: viewSourceWithAuth,
    };
  },
  components: {
    Label,
    Select,
    FileInput,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    errors: {
      type: Object,
      default: {},
    },
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (this.items?.length == 0) {
          this.addEmpty();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    // check input file errors
    this.$watch(
      () => [this.values, this.name, this.errors],
      (data) => {
        const errors = {...data[2]};

        // the validation_label list of the FileInput element
        const imageName = "_image";
        const fileValid = { ...this.fileValid};
        const _items = _.cloneDeep(data[0][data[1]]);
        if (errors) {
          let index = 0;
          for(let name in errors) {
            if (name?.includes(imageName)){ 
              if(errors[`${index}_image`].length > 0){
                delete _items[index]['image'];
                delete _items[index]['image_data'];
                fileValid[name] = false;
              } else {
                fileValid[name] = true;
              }
              index++;
            }
          }
        }
        this.items = _items;
        this.fileValid = fileValid;
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      let _items = [...this.items];
      _items[index][`${value.name}_data`] = value.value;
      _items[index][value.name] = value.fileName;
      if (!value.value) {
        delete _items[index][`${value.name}_deleted`];
      }
      this.$emit("onInput", {
        name: this.name,
        value: _items,
      });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.resetValue[`${_index}_image`] = !this.resetValue[`${_index}_image`];
      this.$emit("onInput", {
        name: this.name,
        value: _items,
      });
    },
    addEmpty() {
      const _items = [...this.items];
      _items.push({
        ...INITIAL_VALUES,
      });
      this.$emit("onInput", {
        name: this.name,
        value: _items,
      });
    },
    onRemoveFile(index, name) {
      let _items = [...this.items];
      delete _items[index][name];
      delete _items[index][`${name}_data`];
      if(_items[index][`${name}_url`]) _items[index][`${name}_deleted`] = 1;
      this.items = _items;
      this.resetValue[`${index}_image`] = !this.resetValue[`${index}_image`];
      this.$emit("onInput", {
        name: this.name,
        value: _items,
      });
    },
    getFileInputStyle(index, subField, parentField) {
      return (this.fileValid[`${index}_${subField}`] && this.items[index][parentField]) ? 'display:none;' : '';
    },
    judgeDisplayFileInput(index,subField, parentField) {
      return this.editable && this.fileValid[`${index}_${subField}`] && this.items[index][parentField];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../style/forms.scss";
</style>
