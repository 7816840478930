const _UndoRedo = () => {
  let history = [];
  let layoutBase = [];
  let currentIndex = 0; //初期化状態 1を現在とする
  const callbacks = [];
  const statusCallbacks = [];

  const currentInit = () => {
    currentIndex = 0;
  };

  const canUndo = () => {
    return currentIndex <= history.length - 1 && currentIndex >= 0;
  };

  const canRedo = () => {
    return currentIndex > 0;
  };

  const undo = () => {
    if (canUndo()) {
      currentIndex++;
      deliver(true);
      deliverStatus();
    }
  };

  const redo = () => {
    if (canRedo()) {
      currentIndex = currentIndex - 1;
      deliver(false);
      deliverStatus();
    }
  };

  const setHistory = (v) => {
    //現在が過去の場合は、それより新しいものは削除して追加する
    if (JSON.stringify(layoutBase) != JSON.stringify(v)) {
      if (currentIndex > 1) {
        history.splice(0, currentIndex);
      }
      
      let oldObj;
      let curObj;
      let id;
      let sameIds = [];
      v.some(item => {
        let baseItem = layoutBase.filter(o1 => (o1.id == item.id));
        if (baseItem.length == 0) {
          id = item.id;
          curObj = JSON.stringify(item);
          oldObj = "{}";
          return true;
        } else if (JSON.stringify(baseItem[0]) !== JSON.stringify(item)) {
          id = item.id;
          curObj = JSON.stringify(item);
          oldObj = JSON.stringify(baseItem[0]);
          return true;
        } else {
          sameIds.push(baseItem[0].id);
        }
      });
      
      if (oldObj || curObj) {
        history.unshift([id, oldObj, curObj]);
        //history = history.filter((v) => v);
      } else {
        let oldObj = layoutBase.find(item => (!sameIds.includes(item.id)));
        history.unshift([oldObj.id, JSON.stringify(oldObj), "{}"]);
      }

      currentInit();
      deliverStatus();
    }
  };
  
  const syncHistory = (v) => {
    layoutBase = JSON.stringify(v);
    layoutBase = JSON.parse(layoutBase);
  };

  /* isUndo (true if undo, false: if redo  */
  const deliver = (isUndo) => {
    callbacks.forEach((callback) => {
      callback({
        currentIndex,
        canUndo: canUndo(),
        canRedo: canRedo(),
        items: buildItems(isUndo),
      });
    });
  };

  const buildItems = (isUndo) => {
    let oldItem = null;
    if (isUndo) {
      oldItem = history.length ? history[currentIndex - 1] : null;
    } else {
      oldItem = history.length ? history[currentIndex] : null;
    }
    if (oldItem != null) {
      let index = layoutBase.findIndex(item => (item.id == oldItem[0]));
      let data = isUndo ? oldItem[1] : oldItem[2];
      if (data == "{}") {
        layoutBase.splice(index, 1);
      } else if (index >= 0) {
        layoutBase.splice(index, 1, JSON.parse(data));
      } else if (index == -1) {
        layoutBase.unshift(JSON.parse(data));
      }
      return layoutBase;
    }
    return layoutBase;
  }

  const deliverStatus = () => {
    statusCallbacks.forEach((callback) => {
      callback({
        canUndo: canUndo(),
        canRedo: canRedo(),
      });
    });
  };

  const setCallback = (callback) => {
    callbacks.push(callback);
  };

  const setStatusCallback = (callback) => {
    statusCallbacks.push(callback);
  };

  const clearHistory = () => {
    history = [];
    layoutBase = [];
    currentIndex = 0;
    deliverStatus();
  }

  currentInit();

  return {
    undo,
    redo,
    setCallback,
    setHistory,
    syncHistory,
    setStatusCallback,
    clearHistory,
  };
};

export const UndoRedo = _UndoRedo();
