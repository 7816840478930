<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <!-- 技能講習名 -->
        <v-row no-gutters>
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <Label label="技能講習名" :editable="editable">
                  <SelectSearch
                    name="cmn_mst_skill_trainings_id"
                    :values="item"
                    :items="SKILLS_TRAINING"
                    item_text="licence_name"
                    :editable="editable"
                    title="技能講習名選択"
                    title_search="技能講習名"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <!-- 日付の種類 -->
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <Label label="日付の種類" :editable="editable">
                  <Select
                    name="cmn_mst_date_types_id"
                    :values="item"
                    :items="TYPE_DATE_ARRAY"
                    item_text="type"
                    :editable="editable"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <!-- 日付 -->
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <Label label="日付" :editable="editable">
                  <InputDatepicker
                    name="skill_training_date"
                    :values="item"
                    :editable="editable"
                    placeholder=""
                    :max="item.cmn_mst_date_types_id == TYPE_EXPIRATION_DATE ? null : max"
                    validation_label="日付"
                    :flagNull="true"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <!-- 技能講習修了証明書 -->
        <v-row no-gutters>
          <v-col cols="12" sm="6" md="6">
            <v-list-item>
              <v-list-item-content>
                <Label label="技能講習修了証明書" :editable="editable">
                  <span class="file"
                    v-if="!editable && item['document_name']"
                    @click="viewSourceWithAuth(item['document_name_url'])"
                  >
                    {{ item["document_name"] }}
                  </span>
                  <FileInput
                    :style="getFileInputStyle(index, 'skill_trainings_document_name', 'document_name')"
                    :resetValue="resetValue[`${index}_skill_trainings_document_name`]"
                    v-if="editable"
                    name="document_name"
                    :values="item"
                    :editable="editable"
                    placeholder=""
                    accept="image/jpg, image/png, image/jpeg, application/pdf"
                    v-bind:validation_label="`${index}_skill_trainings_document_name`"
                    validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf"
                    @onInput="onInput({ value: $event, index })"
                  />
                  <span class="file" v-if="judgeDisplayFileInput(index, 'skill_trainings_document_name', 'document_name')"
                    >{{ item["document_name"] }}
                    <v-btn
                      small
                      icon
                      text
                      @click="
                        onRemoveFile({
                          index: index,
                          name: 'document_name',
                        })
                      "
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </span>
                </Label>
                <span v-if="editable"
                  >記載内容が鮮明に判読できる画像を添付してください。</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="ml-4">
            <v-btn
              v-if="index > 0 && editable"
              class="mt-7"
              icon
              @click="remove(index)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn v-if="editable" depressed small color="primary" @click="addEmpty"
      >追加</v-btn
    >
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */
import { Store } from "@/store/Store.js";
import Label from "../../elements/Label.vue";
import Select from "../../elements/Select.vue";
import InputDatepicker from "../../elements/InputDatepicker.vue";
import FileInput from "../../elements/FileInput.vue";
import _ from "lodash";
import { viewSourceWithAuth } from "@/utils/viewSourceAuth";
import { TYPE_EXPIRATION_DATE } from "@/constants/EMPLOYEE";
import SelectSearch from "@/components/forms/elements/SelectSearch";

const INITIAL_VALUES = {
  cmn_mst_skill_trainings_id: null,
  cmn_mst_date_types_id: null,
  skill_training_date: null,
  document_name: null,
};

export default {
  data: () => {
    return {
      items: [],
      SKILLS_TRAINING: [{ id: null, licence_name: "" },...Store.state.CmnMst.constants.entries.cmn_mst_skills_trainings],
      TYPE_DATE_ARRAY: [{ id: null, type: "" },...Store.state.CmnMst.constants.entries.cmn_mst_date_types],
      fileValid: {},
      resetValue: {},
      viewSourceWithAuth: viewSourceWithAuth,
      max: new Date().toISOString().slice(0, 10),
      TYPE_EXPIRATION_DATE
    };
  },
  components: {
    SelectSearch,
    Label,
    Select,
    InputDatepicker,
    FileInput,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    errors: {
      type: Object,
      default: {},
    },
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (this.items?.length == 0) {
          this.addEmpty();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    // check input file error
    this.$watch(
      () => [this.values, this.name, this.errors],
      (data) => {
        const errors = {...data[2]};

        // the validation_label list of the FileInput element
        const skillTrainingsDocumentName = "skill_trainings_document_name";
        const fileValid = { ...this.fileValid};
        const _items = _.cloneDeep(data[0][data[1]]);
        if (errors) {
          let index = 0;
          for(let name in errors) {
            if (name?.includes(skillTrainingsDocumentName)){
              if(errors[`${index}_${skillTrainingsDocumentName}`].length > 0){
                delete _items[index]['document_name'];
                delete _items[index]['document_name_data'];
                fileValid[name] = false;
              } else {
                fileValid[name] = true;
              }
              index++;
            }
          }
        }
        this.items = _items;
        this.fileValid = fileValid;
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      if (value.name == "cmn_mst_date_types_id") {
        _items[index].skill_training_date = null;
      }
      if (value.fileName) {
        _items[index][`${value.name}_data`] = value.value;
        _items[index][value.name] = value.fileName;
        if (!value.value) {
          delete _items[index][`${value.name}_deleted`];
        }
      } else {
        _items[index][value.name] = value.value;
      }
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.resetValue[`${_index}_skill_trainings_document_name`] = !this.resetValue[`${_index}_skill_trainings_document_name`];
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      let _items = [...this.items];
      _items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    onRemoveFile({ index, name }) {
      let _items = [...this.items];
      delete _items[index][name];
      delete _items[index][`${name}_data`];
      if(_items[index][`${name}_url`]) _items[index][`${name}_deleted`] = 1;
      this.items = _items;
      this.resetValue[`${index}_skill_trainings_document_name`] = !this.resetValue[`${index}_skill_trainings_document_name`];
      this.$emit("onInput", { name: this.name, value: _items });
    },
    getFileInputStyle(index, subField, parentField) {
      return (this.fileValid[`${index}_${subField}`] && this.items[index][parentField]) ? 'display:none;' : '';
    },
    judgeDisplayFileInput(index,subField, parentField) {
      return this.editable && this.fileValid[`${index}_${subField}`] && this.items[index][parentField];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
</style>
