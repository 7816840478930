<template>
  <div class="area">
    <Ui
      :item="item"
      :level="level"
      :parentId="parentId"
      :areaIndex="areaIndex"
      :checkFieldTreeDate="checkFieldTreeDate"
      @onClickBox="onClickBox"
      @onClickMembers="onClickMembers"
      @onClickDropdownMenu="onClickDropdownMenu"
    />
    <div class="children">
      <Company
        v-for="(child, index) in item.field_tree_children"
        :parentId="item.id"
        :areaIndex="index"
        :item="child"
        :key="child.id"
        :level="level + 1"
        :checkFieldTreeDate="checkFieldTreeDate"
      />
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store";
import Company from "./index.vue";
import Popup from "@/components/common/Popup";
import Ui from "./ui.vue";
import {
  MENU_ITEMS,
  COMPANY_LEVEL,
  OWNER_FORMS,
  RELATED_FORMS,
} from "@/constants/PORTAL_CHART.js";

export default {
  name: "Company",
  data() {
    return {
      items: {},
      popups: {
        isShowRemoveDialog: false,
      },
      isStoppable: true
    }
  },
  props: {
    item: {
      type: Object,
    },
    // 親id
    parentId: {
      type: Number,
    },
    //同じエリアの兄弟を識別する
    areaIndex: {
      type: Number,
    },
    level: {
      type: Number,
    },
    checkFieldTreeDate: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Company,
    Ui,
    Popup,
  },
  computed: {
    // 元請け
    isOwner() {
      return this.level === COMPANY_LEVEL.OWNER;
    },
  },
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    this.$watch(
      () => this.level,
      (newValue) => {
        if(newValue == 1) {
          Store.dispatch("PortalChart/setExcludeCompanyId", this.item.company_id);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    /**
     * @description 協力会社フォームを開く
     */
    onClickBox({ companyId, companyName, chartId, state_handling_flag, item }) {
      let dataDetail = {};
      if(this.checkFieldTreeDate) {
        if(this.level === COMPANY_LEVEL.OWNER) {
          dataDetail['site_director'] = item['site_director'];
          dataDetail['working_group'] = item['working_group'];
        } else {
          dataDetail['field_tree_users'] = item['field_tree_users'];
        }
      }
      const storePath =
        this.level === COMPANY_LEVEL.OWNER
          ? "PortalChart/openOwnerCompany"
          : "PortalChart/openRelatedCompany";
      Store.dispatch(storePath, { companyId, companyName, chartId, state_handling_flag, dataDetail, level: this.level });
    },

    /**
     * @description メンバー一覧を開く
     */
    onClickMembers({ companyId, companyName, chartId, state_handling_flag, item }) {
      let dataDetail = {};
      if(this.checkFieldTreeDate) {
        if(this.level === COMPANY_LEVEL.OWNER) {
          dataDetail['site_director'] = item['site_director'];
          dataDetail['working_group'] = item['working_group'];
        } else {
          dataDetail['field_tree_users'] = item['field_tree_users'];
          dataDetail['field_tree_children'] = item['field_tree_children'];
        }
      }
      const storePath =
        this.level === COMPANY_LEVEL.OWNER
          ? "PortalChart/openOwnerCompany"
          : "PortalChart/openRelatedCompany";
      const formTab =
        this.level === COMPANY_LEVEL.OWNER
          ? OWNER_FORMS.FormManagers
          : RELATED_FORMS.FormWorkers;

      Store.dispatch(storePath, { companyId, companyName, chartId, formTab, state_handling_flag, dataDetail });
    },

    /**
     * @description ドロップダウンメニューのアクション
     */
    onClickDropdownMenu(params) {
      if (MENU_ITEMS.INVITE.id === params.menu_id) {
        /**
         * @description 招待フォームを開く
         * TODO:招待ダイアログ作成
         */
        const action = this.isOwner
          ? "PortalChart/inviteFromOwner"
          : "PortalChart/inviteFromRelated";
        Store.dispatch(action, {
          chartId: this.item.id
        });
      }
      if (MENU_ITEMS.REMOVE.id === params.menu_id) {
        Store.dispatch("PortalChart/deleteChart", {
          inviteStatus : params.current_company.invite_status,
          isDelete: params.current_company.field_tree_children?.length > 0 ? true : false,
          chartId: this.item.id
        });
      }
      if (MENU_ITEMS.END_WORK.id === params.menu_id) {
        this.judgeStoppable(params.current_company);
        Store.dispatch("PortalChart/endWorkChart", {
          isEndWork: true,
          isActive: this.isStoppable,
          chartId: this.item.id
        });
      }
      if (MENU_ITEMS.RESTART_WORK.id === params.menu_id) {
        Store.dispatch("PortalChart/restartWorkChart", {
          isRestartWork: true,
          isActive: true,
          chartId: this.item.id
        });
      }
      if (MENU_ITEMS.GROUP_WORK?.id === params.menu_id) {
        Store.dispatch("PortalChart/isChangeGroupWork", {
          chartId: this.item.id
        });
      }
      if (MENU_ITEMS.MANAGE_USER?.id === params.menu_id) {
        Store.dispatch("PortalChart/isSetManageUser", {
          chartId: this.item.id
        });
      }
    },

    judgeStoppable(currentCompany) {
      if (this.isStoppable) {
        if (currentCompany.field_tree_children && currentCompany.field_tree_children.length > 0) {
          this.isStoppable = currentCompany.field_tree_children.every(child => child.state_handling_flag === 1);
          currentCompany.field_tree_children.forEach(child => this.judgeStoppable(child, this.isStoppable));
        }
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.area {
  display: flex;
  padding-left: 32px;
}
</style>
