<template>
  <div>
    <v-treeview
      v-if="over_times.length > 0"
      hoverable
      dense
      :items="over_times"
      item-children="childrens"
      expand-icon="mdi-chevron-down"
    >
      <template v-slot:label="{ item }">
        <div v-if="item.childrens"  class="row_flex">
          <div class="location_name">
            残業予定 <span>{{ item.date }}</span>
          </div>
          <v-spacer></v-spacer>
          <span>{{ item.total }}件</span>
        </div>
        <div v-else class="infor_fire_use">
          <div class="infor_company_and_foreman_name">
            <div class="infor_company_name">
              {{ item.company_name }}
            </div>
            <span class="ml-2"></span>
            <div class="infor_foreman_name">
              {{ item.foreman_name }}
            </div>
          </div>
          <div>
            {{ item.start_time }}〜{{ item.end_time }}
          </div>
        </div>
      </template>
    </v-treeview>
    <v-treeview
      v-if="early_times.length > 0"
      hoverable
      dense
      :items="early_times"
      item-children="childrens"
      expand-icon="mdi-chevron-down"
    >
      <template v-slot:label="{ item }">
        <div v-if="item.childrens"  class="row_flex">
          <div class="location_name">
            早出予定 <span>{{ item.date }}</span>
          </div>
          <v-spacer></v-spacer>
          <span>{{ item.total }}件</span>
        </div>
        <div v-else class="infor_fire_use">
          <div class="infor_company_and_foreman_name">
            <div class="infor_company_name">
              {{ item.company_name }}
            </div>
            <span class="ml-2"></span>
            <div class="infor_foreman_name">
              {{ item.foreman_name }}
            </div>
          </div>
          <div>
            {{ item.start_time }}〜{{ item.end_time }}
          </div>
        </div>
      </template>
    </v-treeview>
    <div v-if="isNoDataMessage" class="mt-10" style="text-align: center">
      <span class="nodata">{{ NO_DATA_MESSAGE }}</span>
    </div>
  </div>
</template>
<script>
import { NO_DATA_MESSAGE } from "@/constants/COMMON";

export default {
  data: () => {
    return {
      NO_DATA_MESSAGE,
      early_times: [],
      over_times: [],
    };
  },
  props: {
    items: {
      type: Object
    },
    isNoDataMessage: Boolean,
  },
  mounted() {
    this.$watch(
      () => this.items,
      (data) => {
        this.initData(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    initData(data) {
      this.early_times = data.early_times && Object.keys(data.early_times).length !== 0 ? [data.early_times] : [];
      this.over_times = data.over_times && Object.keys(data.over_times).length !== 0 ? [data.over_times] : [];
    }
  }
};
</script>
<style lang="scss" scoped>
.location_name {
  text-overflow: ellipsis;
  max-width: 20vw;
  overflow: hidden;
}
.infor_fire_use {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.infor_company_name {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 9vw;
  overflow: hidden;
}
.infor_foreman_name {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 5vw;
  overflow: hidden;
}
.infor_company_and_foreman_name {
  display: flex;
  flex-direction: row;
  width: 61%;
}
.nodata {
  color: rgba(162, 162, 162, 255);
}
.row_flex {
  display: flex;
}
</style>
