<template>
  <FormDialog>
    <template #header>
      <!-- 
        (共通)
        ヘッダーは全て共通
       -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editable"
          class="mr-5"
          depressed
          small
          outlined
          color="primary"
          @click="isShowConfirmDialog = true"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          small
          :disabled="!valid || isSubmitted"
          color="primary"
          @click="onSubmit"
        >
          保存
        </v-btn>
        <v-btn
          class="mr-5"
          v-if="!isStopUser && !editable"
          depressed
          small
          outlined
          color="warning"
          @click="onUpdateStatus(0)"
        >
          停止
        </v-btn>
        <v-btn
          class="mr-5"
          v-else-if="!editable && isStopUser"
          depressed
          small
          outlined
          color="primary"
          @click="onUpdateStatus(1)"
        >
          再開
        </v-btn>
        <v-btn
          v-if="!editable && !isStopUser"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!-- 
        (共通)
        全てのフォームのバリデーションの監視を行う
       -->
      <ValidationObserver ref="observer" v-slot="observer">
        <!-- フォームtab -->
        <v-tabs-items v-model="tab">
          <!-- 基本情報 -->
          <v-tab-item>
            <EmployeeFormPage1
              :editable="editable"
              :item="item"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <!-- 建設業の許可 -->
          <v-tab-item>
            <EmployeeFormPage2
              :editable="editable"
              :item="item"
              :mainHeight="params.mainHeight"
              :errors="errors"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <EmployeeFormPage3
              :editable="editable"
              :item="item"
              :mainHeight="params.mainHeight"
              :errors="errors"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <EmployeeFormPage4
              :editable="editable"
              :item="item"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <EmployeeFormPage5
              :editable="editable"
              :item="item"
              :mainHeight="params.mainHeight"
              :errors="errors"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <EmployeeFormPage6
              :editable="editable"
              :item="item"
              :mainHeight="params.mainHeight"
              :errors="errors"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
        </v-tabs-items>
        <!-- 
          (共通)
          ValidationObserverのスロットプロパティを受け取って
          updateValidateメソッドを実行して、
          バリデーション結果をスクリプト側に渡してます
        -->
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>
      <Popup width="480px" :dialog="isShowConfirmDialogStopUser">
        <ConfirmStopUserDialog
          title= "アカウント停止"
          text1="停止しようとしています。
アカウントを停止すると、ログインできなくなります。"
          text2="実行しますか？"
          @close="closeFormStatus"
          @yes="updateFormStatus"
          :isStopUser="isStopUser"
          warning
        />
      </Popup>
      <Popup width="480px" :dialog="isShowConfirmDialogUnStopUser">
        <ConfirmUnStopUserDialog
          title= "アカウント再開"
          text1="このアカウントを再び有効化します。"
          @close="closeFormStatus"
          @yes="updateFormStatus"
          :isStopUser="isStopUser"
          primary
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "../elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import ConfirmStopUserDialog from "./components/ConfirmDialogStopUser.vue";
import ConfirmUnStopUserDialog from "./components/ConfirmDialogStopUser.vue";
import EmployeeFormPage1 from "@/components/forms/employees/EmployeeFormPage1.vue";
import EmployeeFormPage2 from "@/components/forms/employees/EmployeeFormPage2.vue";
import EmployeeFormPage3 from "@/components/forms/employees/EmployeeFormPage3.vue";
import EmployeeFormPage4 from "@/components/forms/employees/EmployeeFormPage4.vue";
import EmployeeFormPage5 from "@/components/forms/employees/EmployeeFormPage5.vue";
import EmployeeFormPage6 from "@/components/forms/employees/EmployeeFormPage6.vue";
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON.js";
import _ from "lodash";
import { Store } from "@/store/Store.js";
import{ STOP_USER } from "@/constants/EMPLOYEE";
/**
 * (共通)
 * タイトル
 */
const TITLE = {
  NEW: "社員情報",
};

const STORE = "Employees";

/**
 * フォームとタブの設定
 */
const FORMS = {
  EmployeeFormPage1: { id: 1, title: "基本情報" },
  EmployeeFormPage2: { id: 2, title: "本人確認書類・家族連絡先" },
  EmployeeFormPage3: { id: 3, title: "保険・退職金共済" },
  EmployeeFormPage4: { id: 4, title: "健康診断" },
  EmployeeFormPage5: { id: 5, title: "職種・資格・研修" },
  EmployeeFormPage6: { id: 6, title: "外国人に関する事項" },
};

export default {
  data: () => {
    return {
      FORMS,
      valid: false, //バリデーション結果
      errors: {},
      // tab初期化
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      isShowConfirmDialogStopUser: false,
      isShowConfirmDialogUnStopUser: false,
      isClickCloseBtn: false,
      beforeUpdateItem: {},
      isSubmitted: false,
      timeout: null,
      SUBMIT_DELAY_TIMEOUT,
      STOP_USER,
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    EmployeeFormPage1,
    EmployeeFormPage2,
    EmployeeFormPage3,
    EmployeeFormPage4,
    EmployeeFormPage5,
    EmployeeFormPage6,
    ConfirmStopUserDialog,
    ConfirmUnStopUserDialog
  },
  props: {
    item: Object,
    isNewItem: Boolean,
    isErrorSubmit: Boolean,
    isEmitted: Boolean,
    isStopUser: Boolean,
  },
  mounted() {
    /**
     * (共通)
     * 新規作成を監視
     */
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        //新規の場合は最初から編集モード
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * (共通)
     * 新規作成を監視
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getEmployeeDetail`],
        this.editable,
      ],
      (data) => {
        if (!this.isNewItem) {
          let temp = _.cloneDeep(data[0]);
          temp.user_awards.forEach(e => {
            if (e.cmn_mst_awards_id) {
              e.sub_catergory_id = e.cmn_mst_awards_id;
            }
          });
          this.beforeUpdateItem = { ...temp };
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    /**
     * Catch after submitted
     */
    this.$watch(
        () => this.isEmitted,
        () => {
          if (!this.isErrorSubmit) {
            this.editable = false;
          }
        }
    );
  },
  computed: {
    // 新規or編集でタイトル変更
    formtitle() {
      return TITLE.NEW;
    },
  },
  methods: {
    /**
     * (共通)
     * フォームの変更を送信
     */
    formUpdate(params) {
      this.$emit("formUpdate", params);
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      this.editable = true;
    },

    onUpdateStatus(type){
      if(type == 0){
        this.isShowConfirmDialogStopUser = true;
      }else {
        this.isShowConfirmDialogUnStopUser = true;
      }
    },

    /**
     * (共通)
     * 状態を詳細に変更
     */
    onDetail() {
      this.editable = false;
    },

    /**
     * (共通)
     * 登録
     */
    onSubmit() {
      this.isSubmitted = true;
      this.$emit("submit");
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isSubmitted = false
      }, SUBMIT_DELAY_TIMEOUT);
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid, errors }) {
      this.valid = valid;
      this.errors = errors;
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        const item = _.cloneDeep(this.beforeUpdateItem);
        this.$emit("formUpdate", item);
        this.editable = false;
        // cancel form
      } else {
        this.$emit("cancel");
      }
    },

    closePopup() {
      this.isShowConfirmDialog = false;
    },
    /**
     * (共通)
     * click button Close (X)
     */
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
    closeFormStatus(){
      if(this.isStopUser){
        this.isShowConfirmDialogUnStopUser = false;
      }else {
        this.isShowConfirmDialogStopUser = false;
      }
    },
    async updateFormStatus(type){
      const params = {
        "user_id" : this.item.users.id,
      };
      if(type == 0){
        this.isShowConfirmDialogStopUser = false;
        params["state_handling_flag"] = STOP_USER.STOP;
      }else {
        this.isShowConfirmDialogUnStopUser = false;
        params["state_handling_flag"] = STOP_USER.NOSTOP;
      }
      await Store.dispatch(`${STORE}/updateStopUser`, params);
      this.$emit("cancel", true);
    }
  },

  /**
   * Important: clear timeout
   */
  beforeDestroy () {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout)
  }
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
