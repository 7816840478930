<template>
  <FormDialog>
    <template #header>
      <!-- 
        (共通)
        ヘッダーは全て共通
       -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          {{ companyName }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!editable && !checkFieldTreeDate"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn v-if="editable" class="mr-5"
          depressed
          small
          outlined
          color="primary" @click="onReadonly">
          やめる
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          small
          :disabled="!valid || isSubmitted"
          color="primary"
          @click="onSubmit"
        >
          保存
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title @change="onChangeTab">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!-- 
        (共通)
        全てのフォームのバリデーションの監視を行う
       -->
      <ValidationObserver ref="observer" v-slot="observer">
        <!-- フォームtab -->
        <v-tabs-items v-model="tab">
          <v-tab-item v-if="isShow.FormInfo">
            <!-- 建設業の許可 -->
            <FormInfo
              :item="formValues"
              :editable="editable"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item v-if="isShow.FormManagers">
            <!-- 現場監督(READONLY) -->
            <FormManagers
              v-if="!editable"
              :editable="false"
              :mainHeight="params.mainHeight"
              :item="formValues"
              :field_construction_id="field_construction_id"
              :checkFieldTreeDate="checkFieldTreeDate"
            />
            <!-- 現場監督:編集-->
            <FormManagersEdit
              v-if="editable"
              :editable="true"
              :mainHeight="params.mainHeight"
              :item="formValues"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item v-if="isShow.FormGroups">
            <!-- グループ一覧(READONLY) -->
            <FormGroups
              :item="formValues"
              :editable="editable"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
        </v-tabs-items>
        <!-- 
          (共通)
          ValidationObserverのスロットプロパティを受け取って
          updateValidateメソッドを実行して、
          バリデーション結果をスクリプト側に渡してます
        -->
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="isShowConfirmDialog = false"
          @yes="closeForm"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import FormInfo from "./FormInfo.vue";
import FormManagers from "./FormManagers.vue";
import FormManagersEdit from "./FormManagersEdit.vue";
import FormGroups from "./FormGroups.vue";
import { OWNER_FORMS, } from "@/constants/PORTAL_CHART.js";
import { Store } from "@/store/Store.js";
import _ from "lodash";
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";

export default {
  name: "OwnerCompanyForm",
  data: () => {
    return {
      FORMS: OWNER_FORMS,
      valid: false, //バリデーション結果
      // tab初期化
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      isClickCloseBtn: false,
      formValues: {},
      companyName: null,
      isSubmitted: false,
      timeout: null,
      OWNER_FORMS,
      isShow: {
        FormInfo: true,
        FormGroups: true,
        FormManagers: true,
      }
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    FormInfo,
    FormManagers,
    FormManagersEdit,
    FormGroups,
  },
  props: {
    isNewItem: Boolean,
    companyId: Number,
    chartId: Number,
    formTab: Object,
    checkFieldTreeDate: Boolean,
    state_handling_flag: Number,
    dataDetail: Object,
    field_construction_id: Number,
  },

  computed: {
    IS_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
  },

  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    /**
     * (共通)
     * 新規作成を監視
     */
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        //新規の場合は最初から編集モード
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.checkFieldTreeDate,
      (flag) => {
        if (flag) {
          let form = {...OWNER_FORMS};
          delete form['FormInfo'];
          this.isShow = {
            FormInfo: false,
            FormManagers: true,
            FormGroups: true
          }
          const index = Object.keys(form)
            .map((key) => form[key])
            .findIndex((item) => item === OWNER_FORMS.FormManagers);
          //nextTickだと描画されない
          setTimeout(() => {
            this.tab = index;
          }, 500);
          this.FORMS = form;
          if(this.dataDetail) {
            let formValues = {...this.formValues};
            formValues['site_director'] = this.dataDetail['site_director'];
            formValues['working_group'] = this.dataDetail['working_group'];
            this.formValues = formValues;
            this.companyName = Store.getters["PortalChart/getSelectedOwnerCompany"].companyName;
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    /**
     * 現場監督一覧を開く
     */
    this.$watch(
      () => this.formTab,
      (newVal) => {
        if (
          JSON.stringify(newVal) === JSON.stringify(OWNER_FORMS.FormManagers)
        ) {
          if(this.checkFieldTreeDate) {
            let form = {...OWNER_FORMS};
            delete form['FormInfo'];
            const index = Object.keys(form)
              .map((key) => form[key])
              .findIndex((item) => item === OWNER_FORMS.FormManagers);
            //nextTickだと描画されない
            setTimeout(() => {
              this.tab = index;
            }, 500);
          } else {
            const index = Object.keys(OWNER_FORMS)
              .map((key) => OWNER_FORMS[key])
              .findIndex((item) => item === newVal);
            //nextTickだと描画されない
            setTimeout(() => {
              this.tab = index;
            }, 500);
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * getOwnerCompany
     */
    this.$watch(
      () => [
        Store.getters["PortalChart/getOwnerCompany"],
        this.editable
      ],
      (data) => {
        if(!this.checkFieldTreeDate) {
          let _formValues = {...this.formValues};
          if (data[0]) _formValues = data[0];
          this.formValues = _.cloneDeep(_formValues);
          this.companyName = Store.getters["PortalChart/getSelectedOwnerCompany"].companyName;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onChangeTab(tab) {
      console.log("--- tab", tab);
    },
    /**
     * (共通)
     * フォームの変更を送信
     */
    formUpdate(params, parent_name) {
      if(parent_name) {
        const formValues = {...this.formValues};
        formValues[parent_name] = {... formValues[parent_name], ...params};
        this.formValues = {...formValues};
      } else {
        this.formValues = {...this.formValues, ...params};
      }
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      this.editable = true;
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onReadonly() {
      this.isShowConfirmDialog = true;
    },

    /**
     * (共通)
     * 状態を詳細に変更
     */
    onDetail() {
      this.editable = false;
    },

    /**
     * (共通)
     * 登録
     */
    async onSubmit() {
      this.isSubmitted = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isSubmitted = false
      }, SUBMIT_DELAY_TIMEOUT);

      let userIds = [];
      if (this.formValues?.site_director.field_tree_users) {
        this.formValues?.site_director.field_tree_users.forEach(item => {
          userIds.push(item.user_id);
        });
      }

      let params = {};
      params["field_tree_id"] = this.formValues?.field_tree.id;
      params["construction_permit"] = this.formValues?.construction_permit;
      params["site_director"] = {};
      params["site_director"]["user_id_of_field_tree_users"] = userIds;
      params["working_group"] = {};

      let fieldTreeGroups = []
      if (this.formValues?.working_group?.field_tree_groups) {
        this.formValues?.working_group?.field_tree_groups.forEach(group => {

          let directorUsers = []
          let leaderUsers = []
          if (group.field_directors) {
            group.field_directors = this.filterArray({
                  from: group["field_directors"],
                  remove: this.formValues?.site_director.field_tree_users
                })
            group.field_directors.forEach(user => {
              directorUsers.push(user.id);
            });
          }
          if (group.field_director_leaders) {
            group.field_director_leaders = this.filterArray({
              from: group["field_director_leaders"],
              remove: group.field_directors
            });
            group.field_director_leaders.forEach(user => {
              leaderUsers.push(user.id);
            });
          }

          fieldTreeGroups.push({
            id: group.id,
            group_name: group.group_name,
            field_directors: directorUsers,
            field_director_leaders: leaderUsers,
          });
        });
      }
      params["working_group"]["field_tree_groups"] = fieldTreeGroups;

      // sort field_tree_construction_permits by asc
      if (params["construction_permit"] && params["construction_permit"]["field_tree_construction_permits"]) {
        params["construction_permit"]["field_tree_construction_permits"].forEach(element => {
          element["cmn_mst_construction_permit_ids"] = element["cmn_mst_construction_permit_ids"]
              .sort((first, second) => first - second);
        });
      }
      this.IS_OBAYASHI? params.is_oba = 1 :'';
      const result = await Store.dispatch("PortalChart/updateOwnerCompany", params);
      //レスポンスエラーがある場合はフォームを閉じない
      //エラーメッセージは、api.jsが表示
      if (result.hasError) {
        return;
      } else {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        let chartId = this.formValues?.field_tree.id;
        let params = { field_tree_id: chartId };
        this.IS_OBAYASHI ? params.is_oba = 1 : "";
        await Store.dispatch("PortalChart/getOwnerCompany", {params});
        this.editable = !this.editable;
        this.$emit("onChange");
      }
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid }) {
      this.valid = valid;
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      if(this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        this.editable = false;
      } else {
        this.isShowConfirmDialog = false;
        this.$emit("cancel");
      }
    },

    // filter out users not in the group
    filterArray(arr) {
      return [...arr.from].filter((from) => {
        // Leave only workgroup users with unique ids
        return [...arr.remove].some((remove) => {
          return remove.id === from.id;
        });
      });
    },
    /**
     * (共通)
     * click button Close (X)
     */
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },
  },

  /**
   * Important: clear timeout
   */
  beforeDestroy () {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout)
  }
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
