import Api, { Mock } from "../api";
import messages from "./mock/textchat/messages.json";
import rooms from "./mock/textchat/rooms.json";
// Mock用のダミー
// TODO 削除する
import messages2 from "./mock/textchat/messages2.json";
// import siteUsers from "./mock/textchat/site_users.json";

//chat api
// https://shogatsu.backlog.com/git/GRN/green_web/blob/development/core/laravel_api/resources/docs/groups/chatmessage.md

// socket
// https://shogatsu.backlog.com/view/GRN-3754

// ルーム一覧
export const TYPE_ROOM_LIST = {
  field_id: 0,
};

// ユーザ一覧
const URL_ROOMS = `${process.env.VUE_APP_API_BASE_URL}/chat/user_rooms`;

// メッセージ一覧
const URL_MESSAGES = `${process.env.VUE_APP_API_BASE_URL}/chat/message_list`;

// メッセージ送信
const URL_POST_MESSAGE = `${process.env.VUE_APP_API_BASE_URL}/chat/message_post`;

// ファイル送信
const URL_POST_FILE = `${process.env.VUE_APP_API_BASE_URL}/chat/file_post`;

// 既読
const URL_READ = `${process.env.VUE_APP_API_BASE_URL}/chat/read`;

// 既読ユーザー
const URL_READ_USERS = `${process.env.VUE_APP_API_BASE_URL}/chat/reader_list`;

// Mock用のダミー
// TODO 削除する
const URL_MESSAGES_OLD = `${process.env.VUE_APP_API_BASE_URL}/messages_old`;

//管理
const URL_SITE_USERS = `${process.env.VUE_APP_API_BASE_URL}/siteUsers`;

// VUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  Mock.onGet(URL_MESSAGES).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: messages2,
  });
  Mock.onGet(URL_ROOMS).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: rooms,
  });
  // Mock用のダミー
  // TODO 削除する
  Mock.onGet(URL_MESSAGES_OLD).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: messages,
  });
  //TODO 削除する
  Mock.onGet(URL_SITE_USERS).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: [],
  });
}

export const textChat = {
  get: (params) => {
    return Api.get(URL_MESSAGES, { params });
  },

  rooms: (params) => {
    return Api.get(URL_ROOMS, { params });
  },

  postMessage: (params) => {
    return Api.post(URL_POST_MESSAGE, params);
  },

  postFile: (params) => {
    return Api.post(URL_POST_FILE, params);
  },

  read: (params) => {
    return Api.post(URL_READ, params);
  },

  /**
   *
   * @param {*} params
   * chat_message_id=3&chat_room_id=1
   * @returns
   */
  getReadUsers: (params) => {
    return Api.get(URL_READ_USERS, { params });
  },

  // Mock用のダミー
  // TODO 削除する
  getOld: (params) => {
    console.log("■■■■■ getOld", URL_MESSAGES, { ...params });
    // eslint-disable-next-line no-debugger
    // debugger;
    return Api.get(URL_MESSAGES, { params });
  },

  getSiteUsers: (params) => {
    return Api.get(URL_SITE_USERS, params);
  },

  // チャット用PDF
  readPdf: (params) => {
    return Api.get(params.url, { params: params.objectParams });
  },
};
