<template>
  <div>
    <div id="header" :class="{'mh-90': ENV_OBAYASHI}">
      <v-row class="align-center mh-108">
        <v-col cols="6" sm="6" md="6" :class="{ resetCssTitle: editable }">
          <span id="title">{{ title + (indexImage + 1) + '(' + countItem + ')' }}</span>
        </v-col>
        <v-col cols="5" sm="5" md="5" class="ml-6" :class="{'padding-obayashi':ENV_OBAYASHI}">
          <v-list-item>
            <v-list-item-content>
              <Label label="場所">
                <Select
                  name="field_item_tree_id"
                  :values="safetyDiaryCommentCorrectionsValues"
                  :items="workPlace"
                  item_text="item_name"
                  :editable="(editable && isNewItem) || (editable && !APPROVED && (ROLE_MAIN_USER_EDIT || ROLE_SUBCONSTRACTOR_USER_EDIT))"
                  @onInput="onInput"
                />
              </Label>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-row>
        <v-col>
          <div class="checkbox-left">
            <span class="d-flex flex-column align-center">
              <span style="font-size: 12px;">帳票出力</span>
                <v-simple-checkbox
                  :disabled="!isNewItem ? disable(editable, APPROVED, ROLE_MAIN_USER_EDIT, ROLE_SUBCONSTRACTOR_USER_EDIT) : false"
                  :value="formValues.form_output_flag === 1"
                  @input="updateCheckboxLeft(indexImage, $event)"
                ></v-simple-checkbox>
                <v-btn
                  v-if="indexImage > 0 && editable && !APPROVED"
                  class="mt-8"
                  style="margin-left: -5px"
                  icon
                  @click="removeItemLeft(indexImage)"
                >
                  <v-icon v-if="(editable && isNewItem) || (editable && (ROLE_MAIN_USER_EDIT || ROLE_SUBCONSTRACTOR_USER_EDIT))">mdi-close</v-icon>
                </v-btn>
            </span>
          </div>
        </v-col>
        <v-col sm="8" :class="{'size-image-obayashi': ENV_OBAYASHI}">
          <v-img
            class="shrink"
            :aspect-ratio="41/30"
            :src="mainImage.src"
            contain
            @click="openShowImageDialog"
          ></v-img>
        </v-col>
        <v-col
            sm="2"
            class="image-action"
        >
          <div class="scrollbar scrollbar-img mb-3" :class="{ 'aspect-ratio-obayashi': ENV_OBAYASHI }">
            <div
              v-for="(image, index) in dataItems" :key="index" class="mb-3 image-box"
            >
              <v-checkbox
                v-if="(editable && isNewItem) || (editable && (ROLE_MAIN_USER_EDIT || ROLE_SUBCONSTRACTOR_USER_EDIT))"
                :value="checkValue(index)"
                color="green"
                background-color="#fff"
                hide-details
                :class="index == indexOfSelectedImages ? 'checkbox checkbox-image-active' : 'checkbox checkbox-image'"
                @change="updateCheckbox(index,$event)"
              ></v-checkbox>
              <v-img
                :class="{'image-selected': index == indexOfSelectedImages}"
                :aspect-ratio="1/1"
                :src="image.image_data? image.image_data: (image.image_thumb_web_url? getUrl(image.id): '')"
                @click="selectImage(image.image_data? image.image_data: (image.image_url? getUrlMain(image.id): ''), index)"
                contain
              >
                <div class="fill-height bottom-gradient"></div> 
              </v-img>
            </div>              
          </div>
          <div id="action" :class="!this.editable ? 'action-preview' : 'action-edit'">
            <div class="add-image text-center">
                <p
                v-if="(editable && isNewItem) || (editable && (ROLE_MAIN_USER_EDIT || ROLE_SUBCONSTRACTOR_USER_EDIT))"
                  class="mb-0"
                >
                写真追加
                </p>
                <v-btn v-if="(editable && isNewItem) || (editable && (ROLE_MAIN_USER_EDIT || ROLE_SUBCONSTRACTOR_USER_EDIT))" 
                :disabled="!isNewItem ? disable(editable, APPROVED, ROLE_MAIN_USER_EDIT, ROLE_SUBCONSTRACTOR_USER_EDIT) : false" depressed icon class="mb-5" @click="popups.isShowUploadImageForm = true">
                  <v-icon>mdi-image-plus-outline</v-icon>
                </v-btn>
            </div>
            <div class="delete-image text-center">
                <p
                v-if="(editable && isNewItem) || (editable && (ROLE_MAIN_USER_EDIT || ROLE_SUBCONSTRACTOR_USER_EDIT))"
                  class="mb-0"
                >
                削除
                </p>
                <v-btn
                v-if="(editable && isNewItem) || (editable && (ROLE_MAIN_USER_EDIT || ROLE_SUBCONSTRACTOR_USER_EDIT))"
                  :disabled="disableRemoveImage(editable, APPROVED, ROLE_MAIN_USER_EDIT, ROLE_SUBCONSTRACTOR_USER_EDIT)"
                  @click="removeImage"
                  depressed icon
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
            </div>
          </div>
          </v-col>
          <v-col class="control-mac" sm="1"></v-col>
        </v-row>
    </div>
    <div id="comment" class="comment">
      <v-row :class="indexImage === 0 ? 'comment-first' : 'comment-other'">
        <v-col
          sm="11"
          :class="(editable) ? 'pt-0 pb-0' : ''"
        >
          <Label label="内容" :editable="editable" :required="indexImage === 0" :class="!this.editable ? 'textarea-preview' : 'textarea-edit'">
            <InputTextArea
              :height="!ENV_OBAYASHI ?  (screen.width <= 1600 ? '80' : '') : (screen.width <= 1600  ? '153px' : '220px')"
              name="pointing_out_detail"
              :values="safetyDiaryCommentCorrectionsValues"
              :editable="(editable && isNewItem) || (editable && !APPROVED && (ROLE_MAIN_USER_EDIT || ROLE_SUBCONSTRACTOR_USER_EDIT))"
              :validation_label="`内容 [${indexImage}]`"
              :validation_rules="indexImage == 0 ? 'required-pointing-out-detail':''"
              @onInput="onInput"
              :customizeFontSize="ENV_OBAYASHI ? true : false"
              :countRow="ENV_OBAYASHI ? 7 : 5"
            />
          </Label>
        </v-col>
      </v-row>
    </div>
    <Popup width="480px" :dialog="popups.isShowUploadImageForm">
      <UploadImageDialog
        :countImage="countImage"
        @close="closeUploadImageDialog"
        @yes="submitUploadImage"
      />
    </Popup>
    <Popup width="60vw" :dialog="popups.isShowImageDialog">
      <ShowImageDialog
        @closeShowImageDialog="closeShowImageDialog"
        :indexOfSelectedImages="indexOfSelectedImages"
        :listUrlImage="listUrlImage"
      />
    </Popup>
  </div>
</template>

<script>
import Select from "@/components/forms/elements/Select";
import InputTextArea from "@/views/schedule/patrols/components/InputTextArea";
import UploadImageDialog from "@/components/forms/schedule/patrols/components/UploadImageDialog.vue";
import Popup from "@/components/common/Popup";
import Label from "@/components/forms/elements/Label.vue";
import { getParamsOfUrlReadFile } from "@/utils/viewSourceAuth";
import { Store } from "@/store/Store";
import {NO_IMAGE} from "@/constants/PATROL_RECORD";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
import ShowImageDialog from "@/views/schedule/patrols/components/ShowImageDialog";

export default {
  data: () => {
    return {
      arrIndexImageChoose: [],
      mainImage: {
        src: null,
      },
      indexOfSelectedImages: null,
      popups: {
        isShowUploadImageForm: false,
        isShowImageDialog: false
      },
      safetyDiaryCommentCorrectionsValues: {},
      dataItems: [],
      sourceImages: [],
      sourceImagesMain: [],
      countImage: 0,
      isLoadingImage: true,
      screen: {},
      ENV_CLIENT,
      sourceOriginImages: [],
      listUrlImage: []
    }
  },
  props: {
    title: String,
    lableSelect: {
      type: Object
    },
    valueSelect: {
      type: Array
    },
    content: {
      type: Object
    },
    comment: {
      type: Object
    },
    editable: Boolean,
    isNewItem: Boolean,
    formValues: Object,
    workPlace: Array,
    type: Number,
    indexImage: Number,
    allData: Object,
    countItem: Number,
    backPreview: Boolean,
    recordId: Number,
  },
  components: {
    Select,
    InputTextArea,
    UploadImageDialog,
    Popup,
    Label,
    ShowImageDialog
  },
  async mounted() {
    this.screen = screen;
    document.querySelectorAll('.scrollbar-img').forEach((item) => {
      item.addEventListener('mousewheel', function(e) {
        e.stopPropagation();
      });
    })
    document.querySelectorAll('textarea').forEach((item) => {
      item.addEventListener('mousewheel', function(e) {
        e.stopPropagation();
      });
    })

    if (!this.mainImage.src || !this.dataItems) {
      this.mainImage.src = NO_IMAGE;
      this.indexOfSelectedImages = 0;
    }
    this.$watch(
      () => this.formValues,
      (data) => {
        if (data) {
          this.safetyDiaryCommentCorrectionsValues = data;
          this.dataItems = data.safety_diary_images.filter(e => e.type == this.type);
          this.initiateImageSource();
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => this.dataItems,
      (data) => {
        this.countImage = data.length;
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => this.backPreview,
      (data) => {
        if (data) {
          this.mainImage.src = this.dataItems[0]?.image_data ? this.dataItems[0]?.image_data : (this.dataItems[0]?.image_url ? this.getUrlMain(this.dataItems[0]?.id) : NO_IMAGE);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => [this.countImage, this.recordId],
      (data) => {
        if (!data[0]) {
          this.mainImage.src = NO_IMAGE;
        } else {
          this.mainImage.src = this.dataItems[0]?.image_data ? this.dataItems[0]?.image_data : (this.dataItems[0]?.image_url ? this.getUrlMain(this.dataItems[0]?.id) : "");
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  computed: {
    APPROVED() {
      return this.safetyDiaryCommentCorrectionsValues.status_code === 1 &&
        this.safetyDiaryCommentCorrectionsValues.recovery_flag === 1;
    },
    ROLE_MAIN_USER_EDIT() {
      let userRole = Store.getters["Report/getUserRole"];
      return (!this.isNewItem && userRole?.role === 1)
    },
    ROLE_SUBCONSTRACTOR_USER_EDIT() {
      let userRole = Store.getters["Report/getUserRole"];
      let companyUser = JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user?.company_id;
      let fieldTreeCompanyId = this.allData?.safety_diaries?.field_tree_company_id;
      return (
        !this.isNewItem &&
        userRole?.role !== 1 &&
        fieldTreeCompanyId === companyUser
      );
    },
    ENV_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
  },
  methods: {
    checkValue(index) {
      return this.arrIndexImageChoose.includes(index);
    },
    getUrl(id) {
      return this.sourceImages.find(e => e.id === id)?.url;
    },
    getUrlMain(id) {
      return this.sourceImagesMain.find(e => e.id === id)?.url;
    },
    selectImage(image, index) {
      if (this.isLoadingImage) {
        return;
      }
      this.indexOfSelectedImages = index;
      this.mainImage.src = image
    },
    closeUploadImageDialog() {
      this.popups.isShowUploadImageForm = false;
    },
    onInput({name, value}) {
      const formValues = {...this.allData};
      if (name === "field_item_tree_id" || name === "pointing_out_detail" || name === "form_output_flag") {
        formValues["safety_diary_comment_corrections"][this.indexImage][name] = value;
      } else if (name === "safety_diary_images") {
        formValues["safety_diary_comment_corrections"][this.indexImage]["safety_diary_images"] = value;
      } else if (name === "safety_diary_comment_corrections") {
        formValues[name] = value;
      }
      this.$emit("formUpdate", formValues);
    },
    submitUploadImage(param) {
      let _formValues = [...this.safetyDiaryCommentCorrectionsValues.safety_diary_images];
      param.forEach(e => {
        _formValues.push({
          image: e.fileName,
          image_data: e.data,
          type: this.type
        });
      });
      this.closeUploadImageDialog();
      this.onInput({
        name: "safety_diary_images",
        value: _formValues
      });
      let imageType = _formValues = _formValues.filter(e => e.type === this.type);
      this.mainImage.src = imageType[0].image_data ? imageType[0].image_data : (imageType[0].image_url ? this.getUrlMain(imageType[0].id) : "");
      this.indexOfSelectedImages = 0;
    },
    async initiateImageSource() {
      this.isLoadingImage = true;
      for (let i = 0; i < this.dataItems.length; i++) {
        const item = this.dataItems[i];
        if (item.image_thumb_web_url && !this.sourceImages.find(e => e.id == item.id)) {
          await this.createObjectUrlForImage(item);
        }
        if (item.image_origin && !this.sourceImages.find(e => e.id == item.id)) {
          await this.createObjectUrlForImage(item);
        }
        if (item.image_url && !this.sourceImagesMain.find(e => e.id == item.id)) {
          await this.createObjectUrlForImage(item);
        }
      }
      this.isLoadingImage = false;
    },
    async createObjectUrlForImage(item) {
      this.sourceImages.push({id: item.id, url: await this.fetchImageSource(item.image_thumb_web_url)});
      this.sourceOriginImages.push({id: item.id, url: await this.fetchImageSource(item.image_origin)});
      this.sourceImagesMain.push({id: item.id, url: await this.fetchImageSource(item.image_url)});
      this.mainImage.src = this.dataItems[0]?.image_data ? this.dataItems[0]?.image_data : (this.dataItems[0]?.image_url ? this.getUrlMain(this.dataItems[0]?.id) : "");
    },
    async fetchImageSource(sourceUrl) {
      let image = "";
      if (sourceUrl) {
        const params = getParamsOfUrlReadFile(sourceUrl);
        const response = await Store.dispatch("File/readFile", params);
        if (!response.hasError) {
          image = window.URL.createObjectURL(new Blob([response.data], {type: response.headers["content-type"]}));
        }
      }
      return image;
    },
    updateCheckbox(index, isChecked) {
      if (isChecked) {
        this.arrIndexImageChoose.push(index);
      } else {
        const _index = this.arrIndexImageChoose.indexOf(index);
        if (_index > -1) {
          this.arrIndexImageChoose.splice(_index, 1);
        }
      }
    },
    removeImage() {
      let arr = [...this.dataItems];
      for (let index = this.dataItems.length - 1; index >= 0; index--) {
        if (this.arrIndexImageChoose.includes(index)) {
          arr.splice(index, 1);
        }
      }
      this.arrIndexImageChoose = [];
      let temp = this.safetyDiaryCommentCorrectionsValues.safety_diary_images.filter(e => e.type !== this.type);
      arr = [...arr, ...temp];
      this.dataItems = arr;
      this.onInput({
        name: "safety_diary_images",
        value: arr
      });
      this.mainImage.src = this.dataItems[0]?.image_data ? this.dataItems[0]?.image_data : (this.dataItems[0]?.image_url ? this.getUrlMain(this.dataItems[0]?.id) : "");
      this.indexOfSelectedImages = 0;
    },
    removeItemLeft(_index) {
      let _items;
      if (this.allData?.safety_diary_comment_corrections) {
        _items = this.allData?.safety_diary_comment_corrections.filter((_item, index) => {
          return index !== _index;
        });
      } else {
        _items = this.dataItems.filter((_item, index) => {
          return index !== _index;
        });
      }
      this.onInput({
        name: "safety_diary_comment_corrections",
        value: _items
      });
      this.$emit("updateDataItems", _items);
    },
    isApprove(index) {
      return this.dataItems[index].status_code === 1;
    },
    updateCheckboxLeft(index, isChecked) {
      let checked = isChecked ? 1 : 0;
      this.form_output_flag = checked;
      this.onInput({
        name: "form_output_flag",
        value: checked
      });
    },
    disable(editable, isApprove, roleMain, roleSub) {
      return !editable || !(editable && !isApprove && (roleMain || roleSub));
    },
    disableRemoveImage(editable, isApprove, roleMain, roleSub) {
      if (!this.isNewItem) {
        return !editable || !(this.arrIndexImageChoose.length > 0 && editable && !isApprove && (roleMain || roleSub));
      } else {
        return this.arrIndexImageChoose.length === 0;
      }
    },
    closeShowImageDialog() {
      this.popups.isShowImageDialog = false;
      this.listUrlImage = [];
    },
    openShowImageDialog() {
      if (this.ENV_OBAYASHI && this.mainImage.src != NO_IMAGE && this.mainImage.src) {
        this.popups.isShowImageDialog = true;
        this.dataItems.forEach(item => {
          let dataItem = item.image_data ? item.image_data : (item.image_origin ? this.getUrlOrigin(item.id) : '')
          this.listUrlImage.push(dataItem)
        })
      }
    },
    getUrlOrigin(id) {
      return this.sourceOriginImages.find(e => e.id === id)?.url;
    }
  }
  
}
</script>
<style scoped>
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: 767676; 
  border-radius: 10px;
  background: #767676; 
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
} */
#title {
  font-size: 20px;
  color: #1B9C4F;
}

.resetCssTitle {
  margin-top: -16px;
}
.scrollbar {
  aspect-ratio: 3 / 6.5;
  max-height: 32.82336578581363vh;
  overflow-y: auto;
  padding-left: 22px;
  padding-right: 10px;
}
.scrollbar:hover {
  z-index: 10;
}
.image-box {
  position: relative;
}
.checkbox {
  position: absolute;
  z-index: 1;
  top: -20px;
  width: 25px;
}
.add-image {
  top: 260px;
}
.image-action {
  position: relative;
}
.image-selected {
  outline: 5px solid #32C36C;
  margin-top:5px;
}
#message-btn {
  position: absolute;
}
.mh-108{
  max-height: 108px;
}

.checkbox-left {
  position: absolute;
}
::v-deep .comment .comment-first .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 24px !important;
}
::v-deep .comment .theme--light.v-text-field.v-input--is-readonly .v-input__slot:before {
  border-image: repeating-linear-gradient(90deg,rgba(0,0,0,.38) 0,rgba(0,0,0,.38) 2px,transparent 0,transparent 4px) 1 repeat;
}
::v-deep  .comment .v-text-field.v-input--is-readonly>.v-input__control>.v-input__slot:after {
  background-color: unset !important;
  border-color: unset !important;
  border-style: unset !important;
  border-width: unset !important;
}
::v-deep .comment-other .v-input--dense>.v-input__control>.v-input__slot {
  margin-bottom: 13px;
}
::v-deep .checkbox-image .v-icon.v-icon{
  color: #1B9C4F !important;
}
::v-deep .checkbox-image-active .v-icon.v-icon{
  color: #1B9C4F !important;
}
::v-deep .checkbox-image .v-input__slot{
  border: 0.5px solid;
  border-color: rgba(0, 0, 0, 0.6) !important;
}
.action-edit {
  min-height: 14vh;
}
::v-deep .v-input--is-readonly.v-input--dense>.v-input__control>.v-input__slot {
  margin-bottom: 13px;
}
</style>

<style scoped>
@media (max-width:1600px){
  ::v-deep .textarea-preview textarea {
    height: 80px !important;
  }
  ::v-deep textarea {
    padding: 0 4px !important;
  }
  ::v-deep .textarea-edit textarea{
    height: calc(100% - 10px) !important;
  }
  ::v-deep .textarea-edit .v-input--is-readonly textarea {
    height: 80px !important;
  }
  ::v-deep .v-input--is-readonly.v-input--dense>.v-input__control>.v-input__slot {
    margin-bottom: 13px;
  }
  .action-edit {
    min-height: 17vh;
  }
  ::v-deep .textarea-edit .v-input--is-readonly.v-text-field>.v-input__control>.v-input__slot:before {
    bottom: -1px !important;
  }
}
@media (max-width:1450px) {
  ::v-deep .textarea-preview textarea {
    height: 153px !important;
  }
  ::v-deep textarea {
    padding: 0 4px !important;
  }
  ::v-deep .textarea-edit textarea{
    height: calc(100% - 10px) !important;
  }
}
.size-image-obayashi {
  flex: 0 0 58% !important;
  max-width: 58% !important;
}
.aspect-ratio-obayashi {
  aspect-ratio: 5.2/9 !important
}
@media (max-width:1450px) {
  .aspect-ratio-obayashi {
    aspect-ratio: 8.99/9 !important
  }
  .size-image-obayashi {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  ::v-deep .v-textarea textarea {
    line-height: 1.3em !important;
  }
  .mh-90 {
    max-height: 90px !important;
  }
  .padding-obayashi {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .image-action {
    position: relative;
    padding-bottom: 0px !important;
  }
}

</style>
