import Api, { Mock } from "../api";
import list_setting_items from "./mock/list_setting_items.json";

const CUSTOMIZE_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/plan_item_settings`;
const GET_LIST_URL = `${CUSTOMIZE_BASE_URL}/list_item`;
const UPDATE_LIST_URL = `${CUSTOMIZE_BASE_URL}/update_item`;
// .envのVUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  Mock.onGet(GET_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {list_setting_items},
  });
}

export const customizes = {
  get: (params) => {
    return Api.get(GET_LIST_URL, params);
  },
  updateItem: (params) => {
    return Api.post(UPDATE_LIST_URL, params);
  },
};
