<template>
  <div>
    <v-container>
      <form ref="form">
        <input
          style="display: none"
          ref="input_drawing_file"
          type="file"
          accept="image/jpeg, image/jpg, image/png"
          @change="onImageInput(indexItem,id)"
        />
      </form>
      <v-main v-for="(item, index) in items" :key="item.id">
        <v-row v-if="item['image_deleted'] != 1" :class="index===0?'':'mt-3'">
          <v-col cols="12" sm="10" md="10">
            <v-list-item>
              <v-list-item-content>
                <v-img
                  contain
                  style="cursor: pointer"
                  :src="
                    item.image_data
                      ? item.image_data
                      : (item.image_url
                      ? sourceImages[index]
                      : '/images/noimage.png')
                  "
                  max-width="30vw"
                  max-height="20vw"
                  @click="openInput(index,item.id)"
                />
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col
            v-if="index > 0 && editable"
            cols="12" sm="2" md="2"
            class="pt-0 pb-0"
          >
            <v-btn class="mt-8" icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn
      class="ml-4 mt-4"
      v-if="editable && items.filter(e => e['image_deleted'] != 1).length < 4"
      color="primary"
      @click="addEmpty"
    >追加</v-btn
    >
  </div>
</template>
<script>
import _ from "lodash";
import { Store } from "@/store/Store";
import { getParamsOfUrlReadFile } from "@/utils/viewSourceAuth";

/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

export default {
  data: () => {
    return {
      items: [],
      indexItem: 0,
      id : 0,
      sourceImages: [],
      firstLoad: true,
    };
  },
  components: {},
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    INITIAL_VALUES() {
      return {
        image: null,
        image_data: null,
      };
    },
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue, oldValue) => {
        if (this.items?.length !== newValue[0][newValue[1]]?.length || JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.items = _.cloneDeep(newValue[0][newValue[1]]);
          if (!this.items || this.items.length == 0) {
            this.addEmpty();
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => [this.items, this.editable],
      (newValue) => {
        if ((newValue[0] && !newValue[1]) || (newValue[0] && this.firstLoad)) {
          this.firstLoad = false;
          this.initiateImageSource();
        }
      },
      {
        deep: true,
      }
    );

  },
  methods: {
    async initiateImageSource() {
      this.sourceImages = [];
      for (const item of this.items) {
        if (item.image_url) {
          await this.createObjectUrlForImage(item);
        }
      }
    },

    async createObjectUrlForImage(item) {
      this.sourceImages.push(await this.fetchImageSource(item.image_url));
    },

    async fetchImageSource(sourceUrl) {
      let image = "";
      if (sourceUrl) {
        const params = getParamsOfUrlReadFile(sourceUrl);
        const response = await Store.dispatch("File/readFile", params);
        if(!response.hasError) {
          image = window.URL.createObjectURL(new Blob([response.data], { type: response.headers["content-type"]}));
        }
      }
      return image;
    },

    onInput({ index, value }) {
      // 必ずコピーして変更をします
      const items = [...this.items];
      items[index] = value;
      this.$emit("onInput", { name: this.name, value: items });
    },
    remove(index) {
      let items = [...this.items];
      if (!items[index].id) {
        items = this.items.filter((item, i) => {
          return index != i;
        });
      } else {
        items[index].image_deleted = 1;
      }
      this.$emit("onInput", { name: this.name, value: items });
    },
    addEmpty() {
      const items = [...this.items];
      items.push({ ...this.INITIAL_VALUES });
      this.items = items;
    },
    openInput(index, id) {
      if (this.editable) {
        this.indexItem = index;
        this.id = id;
        this.$refs.input_drawing_file.click();
      }
    },
    onImageInput(index, id) {
      const file = this.$refs.input_drawing_file.files[0];
      if (!file) {
        return;
      }
      this.$refs.form.reset();
      if(file.name.toLowerCase().match(/\.(jpg|jpeg|png)$/)) {
        if(file.size >= 10 * 1024 * 1024) {
          Store.dispatch(
            "Error/show",
            { status: "400", message: "アップロードのファイルは、最大10MBまでです。" },
            { root: true }
          );
          return;
        }
      } else {
        Store.dispatch(
          "Error/show",
          { status: "400", message: "jpg, png, jpegファイルのみアップロード可能です。" },
          { root: true }
        );
        return;
      }
      this.getBase64(file).then((data64) => {
        let params = {
          file_name: file.name,
          file_type: file.type,
          file: data64,
        };
        let value = id ? {
          id : id,
          image: params.file_name,
          image_data: params.file,
          image_deleted : 0
        } : {
          image: params.file_name,
          image_data: params.file,
        };
        this.onInput({ index: index, value: value });
      });
    },
    // Base64変換
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>
