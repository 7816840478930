<template>
  <!-- テーブルヘッダー -->
  <div ref="self">
    <v-toolbar flat>
      <v-toolbar-title class="worksHeader-title primary--text" v-if="isTitle">
        {{ pageTitle }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="isSearch" class="mx-2" small icon @click="openSearch">
        <v-icon> mdi-magnify </v-icon>
      </v-btn>
      <v-btn
        v-if="isDelete"
        class="mx-2"
        small
        icon
        @click="$emit('openRemoveDialog')"
        :disabled="multiSelectStatus"
      >
        <v-icon> mdi-delete </v-icon>
      </v-btn>
      <v-btn
        v-if="isApproval"
        depressed
        class="mx-2"
        color="warning"
        @click="$emit('reject')"
        :disabled="multiSelectStatus"
      >
        承認解除
      </v-btn>
      <v-btn
        v-if="isApproval"
        depressed
        class="mx-2"
        color="primary"
        :disabled="multiSelectStatus"
        @click="$emit('approve')"
      >
        承認
      </v-btn>
      <v-btn
        v-if="isAddNew"
        depressed
        class="mx-2"
        color="primary"
        @click="$emit('openItemForm')"
        data-testid="add-new-btn"
      >
        新規作成
      </v-btn>
    </v-toolbar>
    <div v-if="isShowSearch">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      isShowSearch: false,
    };
  },
  props: {
    pageTitle: {
      type: String,
    },
    multiSelectStatus: {
      type: Boolean,
      default: false,
    },
    updateHeader: {
      type: Object,
    },
    // タイトルを表示
    isTitle: {
      type: Boolean,
      default: true,
    },
    // 削除ボタンを表示
    isDelete: {
      type: Boolean,
      default: true,
    },
    // 新規追加ボタンを表示
    isAddNew: {
      type: Boolean,
      default: true,
    },
    // 承認ボタンを表示
    isApproval: {
      type: Boolean,
      default: false,
    },
    isFormInfo: {
      type: Boolean,
      default: false,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    setTimeout(() => {
      this.updateHeaderHeight();
    }, 200);
  },
  methods: {
    openSearch() {
      this.isShowSearch = !this.isShowSearch;
      this.$nextTick(() => {
        this.updateHeaderHeight();
      });
    },
    updateHeaderHeight() {
      const height = this.$refs.self.getBoundingClientRect().height;
      this.updateHeader.setHeaderHeight(height);
    },
  },
};
</script>
<style lang="scss" scoped>
.v-toolbar__title.worksHeader-title {
  font-size: 24px !important;
  font-weight: 600 !important;
}
</style>
