<template>
  <div class="wrapperWeb">
    <div class="header">
      <button v-if="back" class="btnBack" @click.prevent="back()">
        <ChevronLeft />
      </button>
      <h1>{{ title }} (id:{{ chat_room_id }})</h1>
      <button v-if="goUsers" class="btnUsers" @click.prevent="goUsers()">
        <Users />
      </button>
    </div>
    <div class="frame"><slot></slot></div>
  </div>
</template>
<script>
import ChevronLeft from "@/components/icon/ChevronLeft.vue";
import Users from "@/components/icon/Users.vue";
export default {
  props: {
    title: {
      type: String,
    },
    back: {
      type: Function,
    },
    goUsers: {
      type: Function,
    },
    chat_room_id: {
      type: Number,
    },
  },
  components: {
    ChevronLeft,
    Users,
  },
};
</script>
<style lang="scss" scoped>
.wrapperWeb {
  height: 100%;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  height: 48px;
  width: 100%;
  z-index: 1;
  background-color: white;
  border-bottom: 1px solid #efefef;
  display: flex;
  align-items: center;

  h1 {
    font-size: 16px;
    flex: 1;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
  }

  .btnBack,
  .btnUsers {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
}
.frame {
  position: absolute;
  top: 48px;
  width: 100%;
  height: calc(100% - 48px);
}
</style>
