<template>
  <Sprite
    :item="item"
    :selected="selected"
    :onUpdate="onUpdate"
    :mainCanvasRect="mainCanvasRect"
    :isRotatable="false"
    :acceptRatio="false"
    :isMoveable="isMoveable"
    @onselect="onSelect"
  >
    <div
      ref="innerText"
      class="innerText"
      style="innerText"
      :class="getClass"
      :style="getStyle"
      :contenteditable="isMoveable"
      v-text="innerContent"
      @input="sync"
      @blur="onBlur"
    ></div>
  </Sprite>
</template>
<script>
import Sprite from "./_Sprite.vue";

export default {
  data: () => {
    return {
      content: "",
      innerContent: "",
      updateTimer: null,
    };
  },
  mounted() {
    this.innerContent = this.item.text;
    this.content = this.item.text;

    this.$watch(
      () => this.selected,
      (newVal, oldVal) => {
        if (newVal) {
          this.onFocus();
        }
        // 選択から外れたら更新
        if (!newVal && newVal !== oldVal) {
          this.updateValue();
        }
      }
    );

    this.$watch(
      () => this.item,
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.innerContent = newValue.text;
          this.content = newValue.text;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  components: {
    Sprite,
  },
  computed: {
    getClass() {
      let _class = ``;
      if (this.selected) _class += "selected";
      return _class;
    },
    getStyle() {
      const { text_size, text_align, text_color } = this.item;
      let _style = ``;
      if (text_size) _style += `font-size: ${text_size}px; `;
      if (text_align) _style += `text-align: ${text_align}; `;
      if (text_color) _style += `color: ${text_color}; `;
      return _style;
    },
  },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    onUpdate: {
      type: Function,
      default: () => {},
    },
    mainCanvasRect: {
      type: Object,
      default: () => {
        return { x: 0, y: 0, height: 100, width: 100, scale: 1.0 };
      },
    },
    isMoveable: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    onFocus() {
      this.$refs.innerText.focus();
    },
    sync(e) {
      clearTimeout(this.updateTimer);
      let text = e.target.innerHTML;
      text = text.replace(/<br>/gi, "\n");
      text = text.replace(/(<\/([^>]+)>)/gi, "");
      text = text.replace(/(<([^>]+)>)/gi, "\n");
      this.content = text;
      this.updateTimer = setTimeout(() => {
        //this.updateValue();
      }, 0);
    },
    updateValue() {
      const item = this.item;
      item.text = `${this.content}`;
      this.$emit("update-text", item);
    },
    onSelect(id) {
      this.onFocus();
      this.$emit("onselect", id);
    },
    onBlur(){
      this.updateValue();
    }
  },
};
</script>
<style lang="scss" scoped>
.sprite {
  border: none;
}
.innerText {
  width: 100%;
  word-break: break-all;
  white-space: pre-wrap;
  user-select: none;
  pointer-events: all;

  &.selected {
    user-select: text;
  }
}
.size {
  &_small {
    font-size: 12px;
  }
  &_middle {
    font-size: 16px;
  }
  &_big {
    font-size: 20px;
  }
}
.align {
  &_left {
    text-align: left;
  }
  &_center {
    text-align: center;
  }
  &_right {
    text-align: right;
  }
}
.color {
  &_white {
    color: white;
  }
  &_black {
    color: black;
  }
  &_red {
    color: red;
  }
  &_blue {
    color: blue;
  }
  &_green {
    color: green;
  }
}
</style>
