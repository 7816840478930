<template>
  <div class="wrapper" :class="$sp ? 'sp' : ''">
    <div v-if="$sp" class="wrapper__col">
      <div class="label">日付</div>
      <div class="element">
        <button @click="openSearch">{{ values.date }}</button>
      </div>
    </div>
    <div class="wrapper__col">
      <div class="label">作業時間帯</div>
      <div class="element__select">
        <Select
          :name="name"
          :values="values"
          :items="items"
          :editable="true"
          @onInput="onInput"
        />
      </div>
    </div>
    <div class="wrapper__col">
      <div class="label">総件数:</div>
      <div class="element">
        {{
          isTableTypeAll
            ? values.all_record_schedule_all
            : values.all_record_schedule_group
        }}件
      </div>
    </div>
  </div>
</template>
<script>
import Select from "@/components/forms/elements/Select.vue";
import { Store } from "@/store/Store.js";

export default {
  data: () => {
    return {
      items: [],
    };
  },
  props: {
    name: {
      type: String,
    },
    constructionId: {
      type: Number,
    },
    values: {
      type: Object,
    },
    // テーブル全体判定
    isTableTypeAll: Boolean,
  },
  components: {
    Select,
  },
  mounted() {
    /**
     * 時間帯一覧
     */
    this.$watch(
      () => Store.getters["PortalChart/getFieldTimeZones"],
      (newValue) => {
        this.items = [...newValue];
        if (newValue.length > 0 && !this.values[this.name]) {
          const item = newValue[0];
          this.onInput({ name: this.name, value: item.id });
        }
      },
      { deep: true }
    );
    /**
     * 現場idから時間帯一覧を取得
     */
    this.$watch(
      () => this.constructionId,
      (newValue) => {
        if (newValue > 0) {
          Store.dispatch("PortalChart/getFieldTimeZones", newValue);
        } else {
          this.items = [];
        }
      },
      { deep: true }
    );
  },
  methods: {
    onInput({ name, value }) {
      this.$emit("onInput", { name, value });
    },
    openSearch() {
      Store.dispatch("ScheduleSearch/show", true);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  height: 40px;
  min-width: 320px;
  padding: 0 16px;

  .label {
    font-size: 12px;
    // height: 40px;
    margin-right: 8px;
  }

  .element {
    max-width: 100px;
  }

  .element__select {
    max-width: 168px;
    padding-top: 16px;
  }

  .wrapper__col {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    min-width: 100px;
  }

  &.sp {
    justify-content: space-around;
    padding: 0 8px;

    .element {
      padding-top: 8px;
    }

    .element__select {
      padding-top: 0;
    }

    .wrapper__col {
      flex-direction: column;
      justify-content: flex-start;
      min-width: auto;
      text-align: center;
    }
  }
}
</style>
