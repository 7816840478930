<template>
 <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-container>
        <v-data-table
          :headers="TABLE_LABELS_INSPECTION"
          :items="items"
          :items-per-page="items.length"
          :height="mainHeight"
          fixed-header
          hide-default-footer
          disable-sort
          class="elevation-1 v-data-table__wrapper"
          sort-by="updatedAt"
          :noDataText="NO_DATA_MESSAGE"
          item-key="id"
        >
        </v-data-table>
      </v-container>
    </div>
   </div>
</template>
<script>

import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import { TABLE_LABELS_INSPECTION } from "@/constants/MACHINE_FIELD";

export default {
  data: () => {
    return {
      NO_DATA_MESSAGE,
      TABLE_LABELS_INSPECTION,
      items: [],
    };
  },
  props: {
    item: Object,
    mainHeight: {
      type: Number,
      default: 0
    }
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  mounted() {
     this.$watch(
      () => this.item,
      (newValue) => {
        this.items = [ ...newValue?.machine_company_field_monthly_inspection_items ];
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
};
</script>
<style lang="scss" scoped>
</style>
