import Api, { Mock } from "../api";
import schedule_records from "./mock/schedule_records.json";
import schedule_children_records from "./mock/schedule_children_records.json";
import record_schedule_information from "./mock/record_schedule_information.json";
import get_work_place from "./mock/work_place.json";
import work_last_time from "./mock/work_last_time.json";
import { ENV_CLIENT } from "../../constants/ENV_CLIENT";

/**
 * 予定 works
 */
const SCHEDULE_WORK_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/work_results`;
const GET_LIST_URL = `${SCHEDULE_WORK_BASE_URL}/list`; // 予定一覧取得
const COPY_RESULT_PLANS = `${SCHEDULE_WORK_BASE_URL}/copies`;
const GET_CHILDREN_SCHEDULE_URL = `${SCHEDULE_WORK_BASE_URL}/list_work_by_group`; // 子供の予定一覧を取得
const POST_WORKS_DELETE_URL = `${SCHEDULE_WORK_BASE_URL}/delete`; // 解除
const POST_WORKS_UPDATE_APPROVE_URL = `${SCHEDULE_WORK_BASE_URL}/update_approval`; // 承認/承認解除


const GET_DETAIL_URL = `${SCHEDULE_WORK_BASE_URL}/detail`;
const GET_WORK_PLACE = `${SCHEDULE_WORK_BASE_URL}/get_work_place`;

const CREATE_WORK = `${SCHEDULE_WORK_BASE_URL}/create`;
const UPDATE_WORK_INFOR = `${SCHEDULE_WORK_BASE_URL}/update`;
const GET_WORK_LAST_TIME = `${SCHEDULE_WORK_BASE_URL}/get_work_last_time`;
const GET_WORK_DETAIL_COMPANY_RESULT = `${SCHEDULE_WORK_BASE_URL}/detail_work_results`;
const UPDATE_STATE_HANDLING = `${SCHEDULE_WORK_BASE_URL}/update_state_handling`;
const GET_DAY_WORKER_INFO = `${SCHEDULE_WORK_BASE_URL}/dayworker`;
const GET_DATA_HISTORY_TEXT = `${SCHEDULE_WORK_BASE_URL}/list_history`;
const GET_LIST_FIELD_TREE_USER_HISTORY_URL = `${SCHEDULE_WORK_BASE_URL}/list_field_tree_user_history`;
const GET_LIST_MANAGER_HISTORY_URL = `${SCHEDULE_WORK_BASE_URL}/list_manager_history`;
const GET_LIST_STATUS_APPROVAL = `${SCHEDULE_WORK_BASE_URL}/list_status_approval`;
// CHECK IS OBAYASHI
const IS_OBAYASHI = process.env.VUE_APP_CLIENT === ENV_CLIENT.OBAYASHI;
// .envのVUE_APP_NODE_ENV = local の場合はモックを返す

if (Mock) {
  Mock.onGet(GET_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: schedule_records,
  });

  Mock.onGet(GET_CHILDREN_SCHEDULE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: schedule_children_records,
  });

  Mock.onGet(new RegExp(`${GET_DETAIL_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: record_schedule_information,
  });
  Mock.onGet(GET_WORK_PLACE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: get_work_place,
  });
  Mock.onPost(CREATE_WORK).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(UPDATE_WORK_INFOR).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(new RegExp(`${GET_WORK_LAST_TIME}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: work_last_time,
  });

  Mock.onGet(new RegExp(`${GET_WORK_DETAIL_COMPANY_RESULT}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: {
        work_results: {
          work_result_id: 1,
          field_construction_id: 1,
          field_construction_name: "Contruction result 1",
          work_date: "2021/1/22",
          field_tree_name: "Field tree result 1",
          field_tree_id: 1,
          field_tree_group_id: 1,
          field_tree_group_name: "Group result 1",
          work_hour_zone: 1,
          work_hour_zone_name: "Ca result 1",
          work_hour_zone_start_time: "06:00",
          work_hour_zone_end_time: "12:00",
        },
      },
    },
  });

  Mock.onPost(POST_WORKS_DELETE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });

  Mock.onPost(POST_WORKS_UPDATE_APPROVE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });

  Mock.onGet(GET_DAY_WORKER_INFO).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: {
        work_date: "2021/10/19",
        field_construction_id: 1,
        schedule: {
          total_ukeoi: "30",
          total_tsunei: "40",
          total: "70",
          data: [
            {
              name: "Group A",
              ukeoi: "15",
              tsunei: "20"
            },
            {
              name: "Group B",
              ukeoi: "18",
              tsunei: "25"
            }
          ]
        },
        result: {
          total_ukeoi: "30",
          total_tsunei: "40",
          total: "70",
          data: [
            {
              name: "Group C",
              ukeoi: "15",
              tsunei: "20"
            },
            {
              name: "Group D",
              ukeoi: "18",
              tsunei: "25"
            }
          ]
        }
      },
    },
  });
  Mock.onGet(GET_DATA_HISTORY_TEXT).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        "content1",
        "content2"
      ]
    },
  });
}

export const scheduleRecords = {
  /**
   * 予定 works
   */
  getListWorks: (params) => {
    return Api.get(GET_LIST_URL, { params });
  },
  getScheduleChildren: (params) => {
    return Api.get(GET_CHILDREN_SCHEDULE_URL, { params });
  },
  updateApproveWorks: (params) => {
    return Api.post(POST_WORKS_UPDATE_APPROVE_URL, params);
  },
  deleteWorks: async (params) => {
    return Api.post(POST_WORKS_DELETE_URL, params);
  },
  copyWorkPlans: (params) => {
    return Api.post(COPY_RESULT_PLANS, params);
  },

  /**
   * 機材予定
   */
  getDetail: async (params) => {
    return Api.get(GET_DETAIL_URL, params);
  },
  getDetailObayashi: async (params) => {
    return Api.get(GET_DETAIL_URL, params);
  },
  getWorkPlace: (params) => {
    return Api.get(GET_WORK_PLACE, params);
  },
  post: async (params) => {
    return Api.post(CREATE_WORK, params);
  },
  update: async (params) => {
    return Api.post(UPDATE_WORK_INFOR, params);
  },
  getWorkLastTime: async (params) => {
    return Api.get(GET_WORK_LAST_TIME, params);
  },
  getWorkDetailCompanyResult: async (params) => {
    return Api.get(`${GET_WORK_DETAIL_COMPANY_RESULT}/${params}`);
  },
  updateStateHandling: async (params) => {
    return Api.post(UPDATE_STATE_HANDLING, params);
  },
  getDayWorkerInfo: async (params) => {
    return Api.get(GET_DAY_WORKER_INFO, { params });
  },
  getDataHistory: async (params) => {
    params.params.is_oba = IS_OBAYASHI ? 1 : null;
    return Api.get(GET_DATA_HISTORY_TEXT, params);
  },
  getListFieldTreeUserHistory: async (params) => {
    return Api.get(GET_LIST_FIELD_TREE_USER_HISTORY_URL, params);
  },
  getListManagerHistory: async (params) => {
    return Api.get(GET_LIST_MANAGER_HISTORY_URL, params);
  },
  getListStatusApproval: (params) => {
    return Api.get(`${GET_LIST_STATUS_APPROVAL}/${params}`);
  },
};
