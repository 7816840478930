<template>
  <v-card class="dialog">
    <v-card-title color="warning" class="dialog-header sticky">
      <div class="dialog-title text-left">差戻し</div>
    </v-card-title>
    <v-form ref="form" lazy-validation autocomplete="off">
      <v-list-item>
        <v-list-item-content>
          <div v-if="!isShowMessages" class="body">
            <div class="text-wrap">
              <div class="text">
                修正すべき項目と修正内容を可能な限り具体的に記載し、「差戻す」を押してください。
              </div>
            </div>
          </div>
          <Label v-if="!isShowMessages" label="メッセージ" warning />
          <v-textarea
            v-model="formValue.message"
            dense
            :disabled="isShowMessages"
            color="warning"
            :filled="true"
            :placeholder="''"
          ></v-textarea>
        </v-list-item-content>
      </v-list-item>
    </v-form>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="isShowMessages"
        depressed
        small
        outlined
        color="warning"
        class="mx-1 px-4"
        @click="onCancel"
      >
        閉じる
      </v-btn>
      <v-btn
        v-else
        depressed
        small
        outlined
        color="warning"
        class="mx-1 px-4"
        @click="onCancel"
      >
        やめる
      </v-btn>
      <v-btn
        v-if="!isShowMessages"
        depressed
        small
        color="warning"
        class="mx-1 px-5"
        @click="onSubmit"
        :disabled="disabled || submitDisable"
      >
        差戻す
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import Label from "@/components/forms/elements/Label.vue";

export default {
  components: {
    Label,
  },
  props: {
    chartId: {
      type: Number,
    },
    isShowMessages: {
      type: Boolean,
      default: false,
    },
    messages: {
      type: String,
    },
    disabled : Boolean
  },
  data: () => {
    return {
      formValue: {
        message: "",
      },
    };
  },
  mounted() {
    this.$watch(
      () => this.messages,
      (data) => {
        if (data) {
          this.formValue.message = data;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  computed: {
    submitDisable() {
      return this.formValue.message.length === 0;
    },
  },
  methods: {
    /**
     * フォームを閉じる
     */
    onCancel() {
      this.$emit("onCancel");
    },

    /**
     * フォームを送信
     */
    onSubmit() {
      const params = {
        field_tree_id: this.chartId,
        message: this.formValue.message,
      };
      this.$emit("onSubmit", params);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
@import "@/assets/scss/dialog.scss";
.dialog-title {
  color: $color_warning;
}
.body {
  .text-wrap {
    padding: 16px 0;
    position: relative;
    .text {
      text-align: left !important;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 80%;
    }
  }
}
</style>
