import Api, { Mock } from "../api";
import schedule_companys from "./mock/schedule_companys.json";
import schedule_companys_result from "./mock/schedule_companys_result.json";
import schedule_company_detail from "./mock/schedule_company_detail.json";
import schedule_company_detail_result from "./mock/schedule_company_detail_result.json";
import schedule_company_children from "./mock/schedule_company_children.json";
import schedule_company_children_result from "./mock/schedule_company_children_result.json";
import work_company_last_time from "./mock/work_company_last_time.json";
/**
 * 共通
 */
const SCHEDULE_COMPANY_PLAN_URL = `${process.env.VUE_APP_API_BASE_URL}/work_company_plans`;

const GET_DETAIL_SCHEDULE_COMPANY_PLAN_URL = `${SCHEDULE_COMPANY_PLAN_URL}/detail`;
const CREATE_SCHEDULE_COMPANY_PLAN_URL = `${SCHEDULE_COMPANY_PLAN_URL}/create`;
const UPDATE_SCHEDULE_COMPANY_PLAN_URL = `${SCHEDULE_COMPANY_PLAN_URL}/update`;
const DELETE_SCHEDULE_COMPANY_PLAN_URL = `${SCHEDULE_COMPANY_PLAN_URL}/delete`;
const GET_COMPANY_CHILDREN_PLAN_SCHEDULE = `${SCHEDULE_COMPANY_PLAN_URL}/list_group`;

const GET_COMPANYS_LIST_PLAN_URL = `${SCHEDULE_COMPANY_PLAN_URL}/list`;
const URL_UPDATE_APPROVE_WORK_COMPANY_PLANS = `${SCHEDULE_COMPANY_PLAN_URL}/update_approval`;
const URL_APDATE_STATE_HANDING_PLANS = `${SCHEDULE_COMPANY_PLAN_URL}/update_state_handling`;
const GET_WORK_COMPANY_PLAN_LAST_TIME = `${SCHEDULE_COMPANY_PLAN_URL}/get_work_company_last_time`;

const SCHEDULE_COMPANY_RESULT_URL = `${process.env.VUE_APP_API_BASE_URL}/work_company_results`;
const GET_COMPANYS_LIST_RESULT_URL = `${SCHEDULE_COMPANY_RESULT_URL}/list`;
const GET_COMPANY_CHILDREN_RESULT_SCHEDULE = `${SCHEDULE_COMPANY_RESULT_URL}/list_group`;
const GET_DETAIL_SCHEDULE_COMPANY_RESULT_URL = `${SCHEDULE_COMPANY_RESULT_URL}/detail`;
const DELETE_SCHEDULE_COMPANY_RESULT_URL = `${SCHEDULE_COMPANY_RESULT_URL}/delete`;
const URL_UPDATE_APPROVE_WORK_COMPANY_RESULTS = `${SCHEDULE_COMPANY_RESULT_URL}/update_approval`;
const CREATE_SCHEDULE_COMPANY_RESULTS_URL = `${SCHEDULE_COMPANY_RESULT_URL}/create`;
const UPDATE_SCHEDULE_COMPANY_RESULTS_URL = `${SCHEDULE_COMPANY_RESULT_URL}/update`;
const URL_APDATE_STATE_HANDING_RESULTS = `${SCHEDULE_COMPANY_RESULT_URL}/update_state_handling`;
const GET_WORK_COMPANY_RESULT_LAST_TIME = `${SCHEDULE_COMPANY_RESULT_URL}/get_work_company_last_time`;
const GET_DATA_HISTORY_TEXT_PLAN = `${SCHEDULE_COMPANY_PLAN_URL}/list_history`;
const GET_DATA_HISTORY_TEXT_RESULT = `${SCHEDULE_COMPANY_RESULT_URL}/list_history`;

// .envのVUE_APP_NODE_ENV = local の場合はモックを返す

if (Mock) {
  Mock.onGet(new RegExp(`${GET_DETAIL_SCHEDULE_COMPANY_PLAN_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: schedule_company_detail,
  });

  Mock.onGet(new RegExp(`${GET_DETAIL_SCHEDULE_COMPANY_RESULT_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: schedule_company_detail_result,
  });

  Mock.onGet(GET_COMPANYS_LIST_PLAN_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: schedule_companys,
  });

  Mock.onGet(GET_COMPANYS_LIST_RESULT_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: schedule_companys_result,
  });

  Mock.onPost(CREATE_SCHEDULE_COMPANY_PLAN_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });

  Mock.onPost(CREATE_SCHEDULE_COMPANY_RESULTS_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });

  Mock.onGet(GET_COMPANY_CHILDREN_PLAN_SCHEDULE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: schedule_company_children,
  });

  Mock.onGet(GET_COMPANY_CHILDREN_RESULT_SCHEDULE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: schedule_company_children_result,
  });

  Mock.onPost(UPDATE_SCHEDULE_COMPANY_PLAN_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });

  Mock.onPost(UPDATE_SCHEDULE_COMPANY_RESULTS_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });

  Mock.onPost(URL_UPDATE_APPROVE_WORK_COMPANY_PLANS).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });

  Mock.onPost(URL_UPDATE_APPROVE_WORK_COMPANY_RESULTS).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(DELETE_SCHEDULE_COMPANY_PLAN_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });

  Mock.onPost(DELETE_SCHEDULE_COMPANY_RESULT_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });

  Mock.onGet(new RegExp(`${GET_WORK_COMPANY_PLAN_LAST_TIME}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: work_company_last_time,
  });

  Mock.onGet(new RegExp(`${GET_WORK_COMPANY_RESULT_LAST_TIME}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: work_company_last_time,
  });

  Mock.onGet(GET_DATA_HISTORY_TEXT_PLAN).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        "content1",
        "content2"
      ]
    },
  });
  Mock.onGet(GET_DATA_HISTORY_TEXT_RESULT).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        "content1",
        "content2"
      ]
    },
  });
}

export const scheduleCompany = {
  getListWorkCompanyPlans: (params) => {
    return Api.get(GET_COMPANYS_LIST_PLAN_URL, { params });
  },
  getListWorkCompanyResults: (params) => {
    return Api.get(GET_COMPANYS_LIST_RESULT_URL, { params });
  },
  getDetailPlans: async (params) => {
    return Api.get(`${GET_DETAIL_SCHEDULE_COMPANY_PLAN_URL}/${params}`);
  },
  getDetailResults: async (params) => {
    return Api.get(`${GET_DETAIL_SCHEDULE_COMPANY_RESULT_URL}/${params}`);
  },
  postPlans: (params) => {
    return Api.post(CREATE_SCHEDULE_COMPANY_PLAN_URL, params);
  },
  updatePlans: async (params) => {
    return Api.post(UPDATE_SCHEDULE_COMPANY_PLAN_URL, params);
  },
  postResults: async (params) => {
    return Api.post(CREATE_SCHEDULE_COMPANY_RESULTS_URL, params);
  },
  updateResults: async (params) => {
    return Api.post(UPDATE_SCHEDULE_COMPANY_RESULTS_URL, params);
  },
  getScheduleCompanyPlanChildren: (params) => {
    return Api.get(GET_COMPANY_CHILDREN_PLAN_SCHEDULE, { params });
  },
  getScheduleCompanyResultChildren: (params) => {
    return Api.get(GET_COMPANY_CHILDREN_RESULT_SCHEDULE, { params });
  },
  updateApprovalWorkCompanyPlans: (params) => {
    return Api.post(URL_UPDATE_APPROVE_WORK_COMPANY_PLANS, params);
  },
  updateApprovalWorkCompanyResults: (params) => {
    return Api.post(URL_UPDATE_APPROVE_WORK_COMPANY_RESULTS, params);
  },
  deletePlans: async (params) => {
    return Api.post(DELETE_SCHEDULE_COMPANY_PLAN_URL, params);
  },
  deleteResults: async (params) => {
    return Api.post(DELETE_SCHEDULE_COMPANY_RESULT_URL, params);
  },
  getWorkCompanyLastTimePlans: async (params) => {
    return Api.get(GET_WORK_COMPANY_PLAN_LAST_TIME, { params });
  },
  getWorkCompanyLastTimeResults: async (params) => {
    return Api.get(GET_WORK_COMPANY_RESULT_LAST_TIME, { params });
  },
  updateStateHandlingPlans: async (params) => {
    return Api.post(URL_APDATE_STATE_HANDING_PLANS, params);
  },
  updateStateHandlingResults: async (params) => {
    return Api.post(URL_APDATE_STATE_HANDING_RESULTS, params);
  },
  getDataHistoryPlan: async (params) => {
    return Api.get(GET_DATA_HISTORY_TEXT_PLAN, params);
  },
  getDataHistoryResult: async (params) => {
    return Api.get(GET_DATA_HISTORY_TEXT_RESULT, params);
  }
};
