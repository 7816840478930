<template>
  <div>
    <div>
      <SearchSelectList
        :formValues="formValues"
        @search="search"
        @formUpdate="formUpdate"
      >
        <v-list two-line>
          <template v-for="item in formValues.items">
            <v-list-item :key="item.field_id" ripple>
              <v-list-item-content @click="onClick(item.field_id)">
                <v-list-item-title class="field_name">{{
                  item.field_name
                }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  item.full_address
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </SearchSelectList>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import SearchSelectList from "@/components/forms/elements/SearchSelectList";
export default {
  data: () => {
    return {
      title: "",
      formValues: {
        // 検索ワード
        search: "",
        // 表示するリスト
        items: [],
        // 選択項目id
        selected: null,
      },
    };
  },
  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    /**
     * 選択しているメニューを取得
     */
    this.$watch(
      () => Store.getters[`GlobalHeader/getSiteMenuId`],
      (menuId) => {
        this.active_tab = menuId;
      },
      {
        immediate: true,
      }
    );

    /**
     * 現場一覧を取得
     */
    this.$watch(
      () => Store.getters[`GlobalHeader/getSiteList`],
      (data) => {
        const siteList = {
          ...data,
        };
        console.log("getSiteList", siteList);
        this.formValues.items = siteList;
      },
      {
        immidiate: true,
        deep: true,
      }
    );

    // 現場一覧
    await Store.dispatch("GlobalHeader/searchSites");
  },
  components: {
    SearchSelectList,
  },
  methods: {
    // 検索(propsで渡すだけ)
    search() {
      this.searchSite(this.formValues);
    },

    // formデータ更新(propsで渡すだけ)
    formUpdate(values) {
      this.formValues = values;
    },

    // idの更新
    onClick(id) {
      const values = { ...this.formValues };
      values.selected = id;
      this.formValues = values;

      // 現場idが選択されたらset
      Store.dispatch("GlobalHeader/setSite", {
        field_id: this.formValues.selected,
      });

      //リセット
      const formValues = {
        items: [],
        keyword: "",
        selected: null,
      };
      this.formValues = formValues;

      const path = this.$route.query.fallback ?? "/sp/schedule/works";
      console.log("-------", this.$route.query, path);
      this.$router.push(path);
    },
    /**
     * 現場検索
     */
    async searchSite({ keyword }) {
      const result = await Store.dispatch("GlobalHeader/searchSites", {
        keyword,
      });
      if (result.hasError) {
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.message,
        });
      }
    },
  },
};
</script>
