<template>
  <v-data-table
    :headers="TABLE_LABELS"
    height="300px"
    style="overflow:auto"
    fixed-header
    :items="items"
    :items-per-page="items.length"
    hide-default-footer
    disable-sort
    class="elevation-0 v-data-table__wrapper"
    sort-by="updatedAt"
    :no-data-text="NO_DATA_MESSAGE"
  >
  </v-data-table>
</template>
<script>
import { NO_DATA_MESSAGE } from "@/constants/COMMON.js";
const TABLE_LABELS = [
  {
    text: "時刻",
    value: "hour",
    align: "center",
  },
  {
    text: "平均",
    value: "icon",
    align: "center",
  },
  {
    text: "最大",
    value: "temp",
    align: "center",
  },
  {
    text: "極まれ",
    value: "rain",
    align: "center",
  },
];
export default {
  data: () => ({
    TABLE_LABELS,
    NO_DATA_MESSAGE,
  }),
  props: {
    items: {
      type: Array,
    },
  },
};
</script>
