<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="種別" :editable="editable" required>
                        <Select
                          name="cmn_mst_crane_type_id"
                          :values="formValues"
                          :items="cmn_mst_crane_types"
                          item_text="type"
                          :editable="editable"
                          @onInput="onInput"
                          validation_label="種別"
                          validation_rules="required"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="揚重機名" :editable="editable" required>
                        <InputText
                          name="name"
                          :values="formValues"
                          :editable="editable"
                          validation_label="揚重機名"
                          validation_rules="required|max:255"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="row-position">
                      <Label label="管理会社名" :editable="editable" required>
                        <Select
                          class="select-position"
                          name="company_id"
                          :values="formValues"
                          :items="COMPANY_NAME_ARRAY"
                          :editable="editable"
                          :label="formValues.management_company_name"
                          validation_label="管理会社名"
                          validation_rules="required"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <DeviceKeyPlanImagesInput
                    name="device_keyplan_images"
                    :values="formValues"
                    :editable="editable"
                    @onInput="onInput"
                    :errors="errors"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "../elements/Label";
import InputText from "../elements/InputText";
import Select from "../elements/Select.vue";
import DeviceKeyPlanImagesInput from "./components/DeviceKeyPlanImagesInput.vue";

export default {
  data: () => {
    return {
      formValues: {},
      cmn_mst_crane_types: [
        { id: null, type: "" },
        ...Store.state.CmnMst.constants.entries.cmn_mst_crane_types,
      ],
      COMPANY_NAME_ARRAY: [
        { id: null, name: "" },
        ...Store.state.CompanyPortal.company_by_field,
      ],
    };
  },
  components: {
    Label,
    InputText,
    Select,
    DeviceKeyPlanImagesInput,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    errors: {
      type: Object,
      default: {},
    },
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss" scoped>

.row-position {
  margin-top: -20px;
  height: 150px;
  position: relative;
}

.select-position {
  width: 100%;
  position: absolute;
}
</style>
