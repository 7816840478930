<template>
  <div class="FormMain">
    <div ref="mainBody" class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-container class="mt-8">
          <v-row no-gutters>
            <v-col sm="3" md="3" :style="`overflow: auto;height:${mainHeight - 100}px`">
              <!-- 履歴 -->
              <div class=" mt-3" v-for="(item, index) in histories" :key="index">
                <div class="historyItem mt-3">
                  <div class="col_name" style="cursor: pointer" @click="clickFilePdf(item)">
                    <span
                      :style="`color:blue;font-size:18px;${item.id == currentPdf.id ? 'text-decoration: underline': ''}`"
                          class="historyItem__date">
                      {{ item.report }}
                    </span>
                  </div>
                  <div class="col_date">
                    <span class="historyItem__date">{{ item.created_at }}</span>
                  </div>
                  <div class="col_buttons ml-2" @click="downloadDocument(item)" style="cursor: pointer">
                    <v-icon :disabled="isSubmitted">mdi-download</v-icon>
                  </div>
                </div>
                <div class="mt-3"
                     v-for="(element, index) in item.machine_company_field_estimate_order_report_comments || []" :key="index">
                    <span class="historyItem__date ml-4">{{ element.created_at }}</span>
                    <v-icon class="ml-5" @click="$emit('showMessage', {comment_id: element.id})">mdi-message-text-outline</v-icon>
                </div>
              </div>
            </v-col>
            <v-col sm="9" md="9" class="pl-4">
              <div
                ref="pdfNavigation"
                class="pdfNavigation"
                v-if="items && items.length > 0"
              >
                <div class="pdfNavigation__pages">
                  {{ pdfPage.currentPage }} / {{ pdfPage.pageCount }}
                </div>
                <div class="pdfNavigation__navi">
                  <v-btn
                    small
                    icon
                    :disabled="pdfPage.currentPage === 1"
                    @click="pdfNaviPrev"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn
                    small
                    icon
                    :disabled="pdfPage.currentPage === pdfPage.pageCount"
                    @click="pdfNaviNext"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </div>
              <div
                ref="pdfNavigation"
                class="pdfNavigation"
                v-if="!items || items.length == 0"
              >
                {{ NO_DATA_MESSAGE }}
              </div>
              <div ref="pdfViewer" class="pdfViewer" :style="pdfPage.style">
                <v-card outlined tile v-if="url_view">
                  <!-- PDF -->
                  <pdf
                    :src="url_view"
                    :page="pdfPage.selectPage"
                    @num-pages="pdfPage.pageCount = $event"
                    @page-loaded="pdfPage.currentPage = $event"
                  ></pdf>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
  </div>
</template>
<script>
import pdf from "vue-pdf";
import { SAFETY_DOCUMENT_TYPE } from "@/constants/SAFETY_CHART";
import { NO_DATA_MESSAGE } from "@/constants/COMMON.js";
import { Store } from "@/store/Store.js";
import { getParamsOfUrlReadFile } from "@/utils/viewSourceAuth";
import _ from "lodash";


export default {
  data: () => {
    return {
      SAFETY_DOCUMENT_TYPE,
      NO_DATA_MESSAGE,
      pdfPage: {
        pageCount: 0,
        currentPage: 1,
        selectPage: 1,
        style: ""
      },
      isSubmitted: false,
      timeout: null,
      histories: [],
      currentPdf: {},
      url_view : null
    };
  },
  components: {
    pdf
  },
  props: {
    mainHeight: {
      type: Number
    },
    items: {
      type: Array
    }
  },
  mounted() {
    this.$watch(
      () => this.mainHeight,
      () => {
        this.updatePdfViewerHeight();
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => this.items,
      async (data) => {
        if (Array.isArray(data)) {
          this.histories = [...data];
          if (this.histories.length > 0) {
            this.currentPdf = this.histories[0];
            this.url_view = "";
            await this.getUrlView(this.currentPdf);
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight - 70}px;`;
      return style;
    }
  },
  methods: {
    async clickFilePdf(item) {
      this.pdfPage.currentPage = 1;
      this.pdfPage.selectPage = 1;
      this.currentPdf = item;
      await this.getUrlView(this.currentPdf);
    },
    updatePdfViewerHeight() {
      const mainBody = this.$refs.mainBody;
      const pdfViewer = this.$refs.pdfViewer;
      if (pdfViewer && mainBody) {
        const mainBodyTop = mainBody.getBoundingClientRect().top;
        const pdfViewerTop = pdfViewer.getBoundingClientRect().top;
        const marginTop = mainBodyTop - pdfViewerTop;
        const pdfPage = { ...this.pdfPage };
        pdfPage.style = `height:${this.mainHeight + marginTop - 70}px;`;
        this.pdfPage = pdfPage;
      }
    },
    pdfNaviPrev() {
      const pdfPage = { ...this.pdfPage };
      pdfPage.selectPage = pdfPage.selectPage > 1 ? pdfPage.selectPage - 1 : 1;
      this.pdfPage = pdfPage;
    },
    pdfNaviNext() {
      const pdfPage = { ...this.pdfPage };
      pdfPage.selectPage =
        pdfPage.selectPage < pdfPage.pageCount
          ? pdfPage.selectPage + 1
          : pdfPage.pageCount;
      this.pdfPage = pdfPage;
    },

    async getUrlView(item){
      let _url = _.cloneDeep(item.report_url);
      if(_url) {
        const params = getParamsOfUrlReadFile(_url);
        const response = await Store.dispatch("File/readFile", params);
        if(!response.hasError) {
          this.url_view = window.URL.createObjectURL(new Blob([response.data], { type: response.headers["content-type"]}));
        }
      }
    },

    async downloadDocument(item) {
      this.isSubmitted = true;
      this.timeout = setTimeout(() => {
        this.isSubmitted = false;
      }, 1000);
      let _item = _.cloneDeep(item);
      const params = getParamsOfUrlReadFile(_item.report_url);
      const response = await Store.dispatch("File/readFile", params);
      const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers["content-type"] }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${item.report}`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
@import "@/components/forms/style/forms.scss";

.FormMainBody {
  overflow: hidden;
}

.pdfViewer {
  overflow: auto;
}

.pdfNavigation {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
}

.historyItem {
  height: 24px;
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.col_name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 300px;
}

.col_date {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
}

.col_buttons {
  width: 40px;
  display: flex;
  justify-content: space-between;
}

.historyItem__date {
  // font-size: 12px;
  margin: 0 4px;
}
</style>
