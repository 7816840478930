<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel label="機材" />
                      <Label label="種別" :editable="editable">
                        <span style="cursor: pointer" @click="editable ? openFormMachineCompanyType() : null" >
                          <v-text-field
                            v-model="machineType"
                            dense
                            readonly
                            :disabled="!editable"
                            :filled="editable"
                            color="primary"
                            :append-icon="editable ? 'mdi-menu-down':''"
                          ></v-text-field>
                        </span>
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="機材名" :editable="editable">
                        <Select
                          name="machine_company_id"
                          :values="formValues.machine_company_field_applications"
                          :editable="editable"
                          :items="MACHINE_COMPANY_ARRAY"
                          validation_label="使用場所"
                          validation_rules=""
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-row>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="その他機材" :editable="editable">
                            <InputText
                              name="other_equipment"
                              :values="formValues.machine_company_field_applications"
                              :editable="editable"
                              validation_label="持込機械受理番号"
                              validation_rules="max:512"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="性能規格" :editable="editable">
                            <InputText
                              name="standard_capacity"
                              :values="formValues.machine_company_field_applications"
                              :editable="editable"
                              validation_label="性能規格"
                              validation_rules="max:127"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="要望・特記仕様" :editable="editable">
                        <InputTextArea
                          name="special_specification"
                          :editable="editable"
                          :values="formValues.machine_company_field_applications"
                          validation_label="要望・特記仕様"
                          validation_rules="max:512"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="数量" :editable="editable">
                            <InputNumber
                              name="quantity"
                              :values="formValues.machine_company_field_applications"
                              :editable="editable"
                              validation_label="数量"
                              validation_rules="min-quantity-value|digit|estimate-order-amount"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item v-if="roleUser === ROLE_USER[3]">
                        <v-list-item-content>
                          <Label label="見積依頼数量" :editable="editable">
                            <InputNumber
                              name="request_estimate_quantity"
                              :editable="editable"
                              :values="formValues.machine_company_field_applications"
                              validation_label="見積依頼数量"
                              validation_rules="min-quantity-value|digit|estimate-order-amount"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="使用場所" :editable="editable">
                        <Select
                          name="field_item_tree_id"
                          :values="formValues.machine_company_field_applications"
                          :editable="editable"
                          :items="WORK_PLACE"
                          item_text="item_name"
                          validation_label="使用場所"
                          validation_rules=""
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-row>
                    <v-col sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="使用開始日" :editable="editable">
                            <InputDatepicker
                              name="use_start_date"
                              :editable="editable"
                              :values="formValues.machine_company_field_applications"
                              :flagNull="true"
                              validation_label="使用開始日"
                              validation_rules="compare-to-use-end-date:@使用終了日"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="使用終了日" :editable="editable">
                            <InputDatepicker
                              name="use_end_date"
                              :editable="editable"
                              :values="formValues.machine_company_field_applications"
                              :flagNull="true"
                              validation_label="使用終了日"
                              validation_rules="compare-to-use-start-date:@使用開始日"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="使用用途・申請理由" :editable="editable">
                        <InputTextArea
                          name="application_reason"
                          :editable="editable"
                          :values="formValues.machine_company_field_applications"
                          validation_label="使用用途・申請理由"
                          validation_rules="max:512"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <RelateWorkSchedule
                    name="machine_company_field_application_work_plans"
                    :values="formValues"
                    :editable="editable"
                    @onInput="onInput"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
      <Popup :dialog="isShowMachineCompanyType">
        <MachineCompanyTypeForm
          @cancel="closeMachineCompanyForm"
          @changeMachineCompanyType="changeMachineCompanyType"
          :machine_company_field_type_item_tree_id="[]"
          :machine_company_type_tree="MACHINE_COMPANY_FIELD_TYPE_ARRAYS"
        />
      </Popup>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store";
import Label from "@/components/forms/elements/Label.vue";
import InputText from "@/components/forms/elements/InputText.vue";
import Select from "@/components/forms/elements/Select.vue";
import SectionLabel from "@/components/forms/elements/SectionLabel.vue";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import InputTextArea from "@/components/forms/elements/InputTextArea";
import InputNumber from "@/components/forms/elements/InputNumber";
import MachineCompanyTypeForm from "@/components/forms/companyMachines/components/MachineCompanyTypeForm.vue";
import RelateWorkSchedule from "./components/RelateWorkSchedule";
import Popup from "@/components/common/Popup.vue";
import { ROLE_USER } from "@/constants/DEVICE_COORDINATOR";
import _ from  "lodash";

export default {
  data: () => {
    return {
      formValues: {},
      machineType: "",
      isShowMachineCompanyType: false,
      WORK_PLACE: [],
      MACHINE_COMPANY_FIELD_TYPE_ARRAYS: [],
      MACHINE_COMPANY_ARRAY: [],
      ROLE_USER,
    };
  },
  components: {
    InputTextArea,
    Label,
    InputText,
    Select,
    InputDatepicker,
    MachineCompanyTypeForm,
    Popup,
    SectionLabel,
    InputNumber,
    RelateWorkSchedule,
  },
  props: {
    item: {
      type: Object,
      default: {}
    },
    editable: {
      type: Boolean,
      default: false
    },
    mainHeight: {
      type: Number,
      default: 0
    },
    roleUser: Number,
    isNewItem: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
      },
      {
        deep: true
      }
    );
    this.getListMachineCompanyType();
    this.getWorkPlace();
  
    this.$watch(
      () => Store.getters["PortalChart/getListItemTrees"],
      (data) => {
        this.WORK_PLACE = [this.getDefaultArrays("id", "item_name"), ...data];
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters[`CompanyMachines/getMachineCompanyType`],
      (data) => {
        if (data) {
          this.MACHINE_COMPANY_FIELD_TYPE_ARRAYS = [...data];
          const machineCompanyFieldTypeItemTreeId = this.machineCompanyFieldTypeItemTreeId || [];
          if (machineCompanyFieldTypeItemTreeId.length > 0) {
            this.machineType = this.getMachineCompanyFieldType(machineCompanyFieldTypeItemTreeId);
            this.getListMachineCompanyByType(machineCompanyFieldTypeItemTreeId.at(-1));// at(-1) is get last id in array
          }
        }
      },
      {
        immediate : true,
        deep: true
      }
    );

  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    machineCompanyFieldTypeItemTreeId() {
      return this.formValues?.machine_company_field_applications?.machine_company_type_item_tree_ids;
    },
  },
  methods: {
    // Call API set data store for [機材名]
    async getListMachineCompanyByType(machine_company_type_item_tree_id) {
      let result = await Store.dispatch(`CompanyMachines/getMachineCompanyByType`, {
        machine_company_type_item_tree_id
      });
      let rs = _.cloneDeep(result.data.contents.entries);
      if (rs && rs?.length > 0 && this.machineCompanyFieldTypeItemTreeId?.length > 0) {
        this.MACHINE_COMPANY_ARRAY = [{ id: null, name: "" }, ...rs];
      } else {
        this.MACHINE_COMPANY_ARRAY = [{ id: null, name: "" }];
      }
      if (this.formValues?.machine_company_field_applications?.machine_company_id) {
        if (!this.MACHINE_COMPANY_ARRAY.find(e => e.id == this.formValues.machine_company_field_applications.machine_company_id)) {
          let _formValues = { ...this.formValues };
          _formValues.machine_company_field_applications.machine_company_id = null;
          this.formValues = _formValues;
          this.$emit("formUpdate", _formValues);
        }
      }
    },
    // Call API set data store for [使用場所]
    async getWorkPlace() {
      let params = {
        field_id : this.CURRENT_SITE.field_id
      }
      await Store.dispatch("PortalChart/getListItemTrees", { params });
    },
    // Call API set data store for [種別]
    async getListMachineCompanyType(){
      await Store.dispatch(`CompanyMachines/getMachineCompanyType`, {
        company_id: this.CURRENT_SITE.company_id,
        company_branch_id: this.CURRENT_SITE.company_branch_id ? this.CURRENT_SITE.company_branch_id : ""
      });
    },
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      if (name == "machine_company_field_application_work_plans") {
        formValues[name] = value;
      } else {
        formValues['machine_company_field_applications'][name] = value;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    openFormMachineCompanyType() {
      this.isShowMachineCompanyType = true;
    },
    closeMachineCompanyForm() {
      this.isShowMachineCompanyType = false;
    },
    changeMachineCompanyType(value) {
      this.isShowMachineCompanyType = false;
      const _formValues = { ...this.formValues };
      _formValues['machine_company_field_applications']['machine_company_type_item_tree_ids'] = value;
      _formValues['machine_company_field_applications']['machine_company_id'] = null;
      if (value?.length > 0) {
        const machine_company_type_item_tree_id = value.at(-1);
        _formValues['machine_company_field_applications']['machine_company_type_item_tree_id'] = machine_company_type_item_tree_id;
        this.getListMachineCompanyByType(machine_company_type_item_tree_id);
      } else {
        _formValues['machine_company_field_applications']['machine_company_type_item_tree_id'] = null;
        this.MACHINE_COMPANY_ARRAY = [{id: null, name: ''}];
      }
      this.machineType = this.getMachineCompanyFieldType(value);
      this.formValues = {..._formValues};
      this.$emit("formUpdate", _formValues);
    },
    getMachineCompanyFieldType(ids) {
      let arr = _.cloneDeep(ids);
      let machine_type_array = _.cloneDeep(this.MACHINE_COMPANY_FIELD_TYPE_ARRAYS);
      let rs = "";
      if (arr.length > 0 && machine_type_array.length > 0) {
        arr.forEach((e, index) => {
          let a1 = machine_type_array.find(e1 => e1.id == e);
          rs = index == 0 ? rs + a1.type : rs + "/" + a1.type;
          machine_type_array = a1.childrens;
        });
      } else {
        rs = "";
      }
      return rs;
    },
    getDefaultArrays(id, value) {
      let results = {};
      results[id] = null;
      results[value] = "";
      return results;
    },
  }
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
.text-btn {
  text-transform: none !important;
}
.machine-type {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 35vw;
  white-space: nowrap;
  text-align: left
}
.cst-header {
  margin-top: -10px;
  margin-bottom: 13px;
}
</style>
