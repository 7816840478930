<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row no-gutters>
                <v-col cols="6" sm="6" md="6">
                  <SectionLabel label="物流ルール" />
                  <Label label="搬入出予定ルール" :editable="editable">
                    <span class="file"
                          v-if="!editable"
                          style="cursor: pointer"
                          @click="viewSourceWithAuth(fieldValueType1.document_url)"
                    > {{ fieldValueType1 ? fieldValueType1.name : '' }}
                    </span>
                      <FileInput
                          :style="getFileInputStyle('0_field_rule_documents')"
                          :resetValue="resetValue[`0_field_rule_documents`]"
                          name="field_rule_documents"
                          v-if="editable"
                          :editable="editable"
                          :placeholder="fieldValueType1 ? fieldValueType1.name : ''"
                          accept="application/pdf"
                          v-bind:validation_label="`0_field_rule_documents`"
                          validation_rules="maxsize:10|file-type:pdf"
                          @onInput="onInput({ value: $event, type: FIELD_RULE_DOCUMENT_1 })"

                      />
                      <span
                          class="file"
                          v-if="judgeDisplayFileInput('0_field_rule_documents') && fieldValueType1.name"
                      >{{ fieldValueType1 ? fieldValueType1.name : '' }}
                        <v-btn
                            small
                            icon
                            text
                            @click="
                            onRemoveFile({
                              name: '0_field_rule_documents',
                              type: FIELD_RULE_DOCUMENT_1,
                            })
                          "
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </span>
                      <div class="label__item" v-if="editable">
                        <span class="label__text" style="padding-left: 10px; font-size: 11px">搬入出予定フォームに表示します</span>
                      </div>
                  </Label>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6" sm="6" md="6">
                  <Label label="クレーン使用ルール" :editable="editable">
                    <span class="file"
                          v-if="!editable"
                          style="cursor: pointer"
                          @click="viewSourceWithAuth(fieldValueType2.document_url)"
                    >{{ fieldValueType2 ? fieldValueType2.name : ''}}
                    </span>
                      <FileInput
                          :style="getFileInputStyle('1_field_rule_documents')"
                          :resetValue="resetValue[`1_field_rule_documents`]"
                          name="1_field_rule_documents"
                          v-if="editable"
                          :editable="editable"
                          :placeholder="fieldValueType2 ? fieldValueType2.name : ''"
                          accept="application/pdf"
                          v-bind:validation_label="`1_field_rule_documents`"
                          validation_rules="maxsize:10|file-type:pdf"
                          @onInput="onInput({ value: $event, type: FIELD_RULE_DOCUMENT_2 })"
                      />
                      <span
                          class="file"
                          v-if="judgeDisplayFileInput('1_field_rule_documents') && fieldValueType2.name"
                      >{{ fieldValueType2 ? fieldValueType2.name : ''}}
                        <v-btn
                            small
                            icon
                            text
                            @click="
                            onRemoveFile({
                              name: '1_field_rule_documents',
                              type: FIELD_RULE_DOCUMENT_2,
                            })
                          "
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </span>
                    <div class="label__item" v-if="editable">
                      <span class="label__text" style="padding-left: 10px; font-size: 11px">揚重予定フォームに表示します</span>
                    </div>
                  </Label>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import FileInput from "@/components/forms/elements/FileInput.vue";
import {viewSourceWithAuth} from "@/utils/viewSourceAuth";
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import {FIELD_RULE_DOCUMENT_1, FIELD_RULE_DOCUMENT_2} from "@/constants/FIELD_RULE_DOCUMENT";

export default {
  data: () => {
    return {
      formValues: {},
      fieldValues: {},
      fieldValueType1: {},
      fieldValueType2: {},
      viewSourceWithAuth: viewSourceWithAuth,
      resetValue: {},
      fileValid: {},
      FIELD_RULE_DOCUMENT_1,
      FIELD_RULE_DOCUMENT_2,
    };
  },
  components: {
    FileInput,
    Label,
    SectionLabel,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    errors: {
      type: Object,
      default: {},
    },
  },

  mounted() {
    this.$watch(
      () => [this.item, this.errors],
      (data) => {
        this.initData(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    initData(data) {
      const formValues =  { ...data[0] };
      const errors = { ...data[1] };
      this.fieldValues = formValues.field_rule_documents;

      this.fieldValueType1 = this.fieldValues.find(item => item.type === FIELD_RULE_DOCUMENT_1);
      this.fieldValueType2 = this.fieldValues.find(item => item.type === FIELD_RULE_DOCUMENT_2);
      this.fieldValues['1_field_rule_documents'] = this.fieldValues.find(item => item.type === FIELD_RULE_DOCUMENT_2);
      this.fieldValues['0_field_rule_documents'] = this.fieldValues.find(item => item.type === FIELD_RULE_DOCUMENT_1);
      this.formValues = { ...formValues };

      const names = [
        "1_field_rule_documents",
        "0_field_rule_documents",
      ];
      const fileValid = { ...this.fileValid };

      if (errors) {
        for (let name in errors) {
          if (names.some((item) => item === name)) {
            if (errors[name].length > 0) {
              delete formValues.fields[name];
              delete formValues.fields[`${name}_data`];
              fileValid[name] = false;
            } else {
              fileValid[name] = true;
            }
          }
        }
      }
      this.fileValid = fileValid;
    },
    onInput({ value, type }) {
      const formValues = { ...this.formValues };
      // 必ずコピーして変更をします
      const itemEdited = {};
      let itemSelected = formValues["field_rule_documents"].find(item => item.type === type);
      let indexSelected = formValues["field_rule_documents"].findIndex(item => item.type === type);
      if (value.fileName) {
        if (itemSelected) {
          itemEdited['id'] = itemSelected.id;
          itemEdited['document_deleted'] = 0;
        }
        itemEdited['document_data'] = value.value;
        itemEdited['name'] = value.fileName;
        itemEdited['type'] = type;
        if (!value.value) {
          delete itemEdited['document_deleted'];
        }
      } else {
        itemEdited['id'] = itemSelected.id;
        itemEdited['document_deleted'] = 1;
      }
      if (indexSelected >= 0) {
        formValues["field_rule_documents"][indexSelected] = itemEdited;
      } else {
        formValues["field_rule_documents"].push(itemEdited);
      }
      this.$emit("formUpdate", formValues);
    },

    judgeDisplayFileInput(fieldName) {
      return (
          this.editable &&
          this.fileValid[fieldName] &&
          this.fieldValues[fieldName]
      );
    },

    getFileInputStyle(fieldName) {
      this.fieldCheck = {};
      if (fieldName === '0_field_rule_documents') {
        this.fieldCheck = {...this.fieldValueType1};
      }
      if (fieldName === '1_field_rule_documents') {
        this.fieldCheck = {...this.fieldValueType2};
      }
      return (this.fileValid[fieldName] && this.fieldValues[fieldName] && this.fieldCheck.name)
          ? "display:none;"
          : "";
    },

    onRemoveFile({ name, type }) {
      const formValues = { ...this.formValues };
      const index = formValues["field_rule_documents"].findIndex(item => item.type === type);

      delete formValues["field_rule_documents"][index]['name'];
      delete formValues["field_rule_documents"][index]['document_data'];
      formValues["field_rule_documents"][index]['document_deleted'] = 1;
      this.resetValue[name] = !this.resetValue[name];
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/components/forms/style/forms.scss";
</style>
