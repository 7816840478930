<template>
  <v-card class="dialog">
    <v-card-title>
      <div :style="getTitleColorStyle" class="title">{{ dialogText.title }}</div>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div class="text">{{ dialogText.text }}</div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        :color="getColor"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn :key="flagHandleConfirm" depressed class="btn" :color="getColor" @click.once="handleConfirm">
        確定
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  data() {
    return {};
  },
  components: {},
  props: {
    dialogText: Object,
    flagHandleConfirm : Boolean
  },
  computed: {
    getTitleColorStyle() {
      return this.dialogText.warning ? "color:red;" : "color:green;";
    },
    getColor() {
      return this.dialogText.warning ? "warning" : "primary";
    }
  },
  methods: {
    handleConfirm() {
      this.$emit("yes");
    }
  }
};
</script>
