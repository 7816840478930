<template>
  <div class="labelWrapper" :style="style">
    <div
        class="label content"
        :class="[warning ? 'text__warning' : '' , blackColor ? '' : 'primaryColor']"
        :style="isDatepicker ? 'margin-left: 40px' : ''"
    >
      <div>
        {{ label }}
        <div v-if="required && editable" :class="RerquiredStyle">必須</div>
        <div v-else class="none-required" :class="[isDisplay ? '' : 'isNotVisible']">必須</div>
      </div>
      <div>
        <v-switch
            v-model="switch_check"
            :label="labelSwitch"
            :disabled="!editable"
            @change="$emit('changeSwitch', switch_check)"
        ></v-switch>
      </div>
    </div>
    <div><slot /></div>
  </div>
</template>
<script>
import { ENV_CLIENT } from "@/constants/ENV_CLIENT";
export default {
  data: () => {
    return {
      switch_check: false
    }
  },
  props: {
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "auto",
    },
    warning: {
      type: Boolean,
      default: false,
    },
    isDatepicker: {
      type: Boolean,
      default: false,
    },
    blackColor: {
      type: Boolean,
      default: false,
    },
    isDisplay: {
      type : Boolean,
      default: true
    },
    switchOn: {
      type : Boolean,
      default: false
    },
    labelSwitch: {
      type: String,
      default: "",
    }
  },
  created () {
    this.$watch(
        () => this.switchOn,
        (data) => {
          this.switch_check = data
        },
        {
          immediate: true,
          deep: true,
        }
    )
  },
  computed: {
    style() {
      return `width:${this.width}`;
    },
    RerquiredStyle() {
      if (process.env.VUE_APP_CLIENT === ENV_CLIENT.OBAYASHI)
        return "required-obayashi";
      return "required";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.labelWrapper {
  padding: 0;
}
.label {
  font-size: 12px;
  padding: 4px;
  &.text__warning {
    color: $color_warning;
  };
  &.primaryColor {
    color : $color_primary
  }

}
.required {
  font-size: 8px;
  font-weight: bold;
  color: white;
  background-color: rgb(100, 0, 200);
  display: inline-block;
  padding: 4px;
  border-radius: 4px;
  transform: scale(0.8);
}
.required-obayashi {
  font-size: 12px;
  font-weight: normal;
  color: #ff5252;
  background-color: none;
  display: inline-block;
  padding: 0px 4px;
  border-radius: 4px;
}
.none-required {
  opacity: 0;
  font-size: 12px;
  font-weight: normal;
  color: #ff5252;
  background-color: none;
  display: inline-block;
  padding: 4px;
  border-radius: 4px;
  &.isNotVisible {
    display: none;
  }
}
.content {
  display: flex;
  justify-content: space-between;
  height: 42px
}
</style>
