import { format } from "date-fns";

export const ENTERING_STATUS = {
  ENTERING: { id: 1, name: "入場中" },
  EXITING: { id: 2, name: "退場中" },
};
export const TABLE_HEADER_LABELS = [
  {
    text: "",
    value: "",
    align: "left",
    width: "29%",
  },
  {
    text: "職種",
    value: "skill",
    align: "left",
    width: "20%",
  },
  {
    text: "入場開始日",
    align: "left",
    width: "15%",
  },
  {
    text: "",
    align: "center",
    width: "5%",
  },
  {
    text: "",
    align: "center",
    width: "5%",
  },
  {
    text: "入場時間",
    align: "left",
    width: "18%",
  },
  {
    text: "退場時間",
    align: "left",
    width: "18%",
  },
];

export const ENTRANCE_HISTORY_TABLE_TAB_ITEMS =
{
  LIST: { id: 1, name: "一覧" },
  PANEL: { id: 2, name: "パネル" },
};

export const ROW_COL_LIST = [
  {id : 4 , name : 4},
  {id : 5 , name : 5},
  {id : 6 , name : 6},
  {id : 7 , name : 7},
  {id : 8 , name : 8},
  {id : 9 , name : 9},
  {id : 10 , name : 10},
]

export const INITIAL_ITEM = {
  admission_date: format(new Date(), "yyyy-MM-dd"),
  field_construction_id: null,
  field_tree_id: null,
  user_ids: [],
  field_entrance_times: [],
  group_id: null,
};

export const TAB_NAME = {
  HistoryForm1: { id: 1, title: "基本情報" },
};

export const TITLE_FORM = {
  NEW: "入退場記録",
  EDIT: "入退場記録",
};

const getCounts = (max) => {
  let i = 0;
  return [...new Array(max)].map(() => {
    let el = {
      id: i < 10 ? "0" + i : "" + i,
      name: i < 10 ? "0" + i : "" + i,
    };
    i++;
    return el;
  });
}

export const TIME_HOUR_ARRAY = getCounts(48);

export const TIME_MINUTE_ARRAY = getCounts(60);

export const USER_PANEL_LABELS = [{
  text: "氏名",
  value: "user_name",
  align: "left"
}];