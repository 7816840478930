<template>
  <div style="width: 100%">
    <!-- ヘッダー -->
    <v-app-bar class="header">
      <v-toolbar-title style="width: 100%; text-align: center" color="primary">
        {{ formtitle }}
      </v-toolbar-title>
      <v-btn icon @click="onClickBtnClose" data-testid="btn-close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <template v-slot:extension>
        <v-tabs v-model="nTab" show-arrows grow>
          <v-tab
            v-for="(form, key) in FORMS"
            :key="key"
            :data-testid="`workerForm-tab-${key}`"
          >
            {{ form.title }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <!-- ボトムナビ -->
    <div class="footer">
      <v-btn
        v-if="
          !editable &&
          stateHandlingFlag == 0 &&
          workApprovalFlag &&
          isTablesGroup
        "
        depressed
        small
        color="warning"
        @click="$emit('onShowScheduleDialog')"
      >
        中止
      </v-btn>
      <v-btn
        v-if="
          !editable &&
          stateHandlingFlag == 1 &&
          workApprovalFlag &&
          isTablesGroup
        "
        depressed
        text
        small
        color="warning"
        @click="$emit('onShowScheduleDialog')"
      >
        中止取消
      </v-btn>
      <v-btn
        v-if="
          !editable &&
          stateHandlingFlag == 0 &&
          workApprovalFlag &&
          isTablesGroup
        "
        depressed
        text
        small
        color="primary"
        @click="onEditable"
      >
        編集
      </v-btn>
      <v-btn
        v-if="(editable && isTablesGroup) || isNewItem"
        depressed
        text
        small
        color="primary"
        @click="$emit('onCloseConfirmDialog')"
      >
        やめる
      </v-btn>
      <v-btn
        v-if="(editable && isShowButtonCopy && isTablesGroup) || isNewItem"
        depressed
        text
        small
        color="primary"
        @click="onCopy"
      >
        前回コピー
      </v-btn>
      <v-btn
        v-if="(editable && isTablesGroup) || isNewItem"
        depressed
        text
        small
        :disabled="!valid"
        color="primary"
        @click="onSubmit"
      >
        保存
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      nTab: 0,
    };
  },
  mounted() {
    this.$watch(
      () => this.tab,
      (newValue) => {
        this.nTab = newValue;
      }
    );
    this.$watch(
      () => this.nTab,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.$emit("change", newValue);
        }
      }
    );
  },
  // v-model
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    tab: {
      type: Number,
      default: 0,
    },
    editable: {
      type: Boolean,
    },
    workApprovalFlag: {
      type: Boolean,
    },
    stateHandlingFlag: {
      type: Number,
    },
    isTablesGroup: {
      type: Boolean,
    },
    isNewItem: {
      type: Boolean,
    },
    isShowButtonCopy: {
      type: Boolean,
    },
    isShowConfirmDialog: {
      type: Boolean,
    },
    isShowScheduleDialog: {
      type: Boolean,
    },
    formtitle: {
      type: String,
    },
    FORMS: {
      type: Object,
    },
    valid: {
      type: Boolean,
    },
    onCopy: {
      type: Function,
    },
    onEditable: {
      type: Function,
    },
    onSubmit: {
      type: Function,
    },
    onClickBtnClose: {
      type: Function,
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 100% !important;
}
.footer {
  width: 100%;
  height: 48px;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  box-sizing: border-box;
  border-top: 1px solid #efefef;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
