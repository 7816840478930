import { render, staticRenderFns } from "./SetPlanManager.vue?vue&type=template&id=52afcbad&scoped=true&"
import script from "./SetPlanManager.vue?vue&type=script&lang=js&"
export * from "./SetPlanManager.vue?vue&type=script&lang=js&"
import style0 from "./SetPlanManager.vue?vue&type=style&index=0&id=52afcbad&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52afcbad",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VRadio,VRadioGroup,VSpacer})
