<template>
  <DefaultLayout>
    <template #mainHeader>
      <!--  (共通) 会社ヘッダー -->
      <SitePortalHeader />
    </template>
    <template #page>
      <div class="chart" id="portal-chart">
        <ValidationObserver ref="observer" v-slot="observer">
          <v-container class="mt-8 ml-8">
            <v-row v-if="!checkObayashi">
              <v-col cols="12" sm="3">
                <Select
                  name="work_type_id"
                  :values="formValue"
                  :items="workTypes"
                  :editable="true"
                  @onInput="onChangeSearchInput"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <InputDatepicker
                  name="field_tree_date"
                  :values="formValue"
                  :min="min"
                  :max="max"
                  :editable="true"
                  :disabledDate="isDisableDate"
                  @onInput="onChangeSearchInput"
                />
              </v-col>
            </v-row>
            <v-row v-if="checkObayashi">
              <v-col cols="12" sm="2">
                <Label label="工事" v-if="roleUser">
                  <Select
                    name="work_type_id"
                    :values="formValue"
                    :items="workTypes"
                    :editable="true"
                    @onInput="onChangeSearchInput"
                  />
                </Label>
              </v-col>
              <v-col cols="12" sm="2" v-if="isRoleMain">
                <Label label="日付">
                  <InputDatepicker
                    name="field_tree_date"
                    :values="formValue"
                    :min="min"
                    :max="max"
                    :editable="true"
                    :disabledDate="isDisableDate"
                    @onInput="onChangeSearchInput"
                  />
                </Label>
              </v-col>
              <v-col cols="12" sm="2" v-if="isRoleMain">
                <Label label="一次企業名">
                  <InputText
                    name="company_name"
                    :values="formValue"
                    :editable="true"
                    validation_label="一次企業名"
                    @onBlur="loadCompanies"
                    @onInput="setDataInput"
                  />
                </Label>
              </v-col>
              <v-col cols="12" sm="2" v-if="isRoleMain">
                <Label label="一次企業名カナ">
                  <InputText
                    name="company_name_kana"
                    :values="formValue"
                    :editable="true"
                    validation_label="一次企業名カナ"
                    validation_rules="kana"
                    @onBlur="loadCompanies"
                    @onInput="setDataInput"
                  />
                </Label>
              </v-col>
              <v-col class="mt-8" v-if="isRoleMain">
                <v-btn @click="exportDl.isShow = true" width="100px" color="primary" :disabled="!formValue.work_type_id">出力</v-btn>
              </v-col>
            </v-row>
          </v-container>
        <ValidationCallback :observer="observer" @callback="updateValidate"/>
      </ValidationObserver>
        <div v-if="companies.length > 0">
          <!-- 体制図 -->
          <Company
            v-for="item in companies"
            :item="item"
            :key="item.id"
            :level="1"
            :checkFieldTreeDate="checkFieldTreeDate"
          />
        </div>
        <div v-if="companies.length == 0 && !flagInit" style="text-align: center;">
          {{ NO_DATA_MESSAGE }}
        </div>
        <!-- 元請けフォーム -->
        <Popup :dialog="ownerCompanyPopup.isShow">
          <OwnerCompanyForm
            :checkFieldTreeDate="checkFieldTreeDate"
            @cancel="closeOwnerForm"
            v-bind="ownerCompanyPopup"
            @onChange="onChange"
            :field_construction_id="formValue.work_type_id"
          />
        </Popup>
        <!-- 協力会社フォーム -->
        <Popup :dialog="relatedCompanyPopup.isShow">
          <RelatedCompanyForm
            :checkFieldTreeDate="checkFieldTreeDate"
            :fieldContructionId="formValue.work_type_id"
            :fieldTreeDate="formValue.field_tree_date"
            @cancel="closeRelatedForm"
            v-bind="relatedCompanyPopup"
            :companyId="field_tree_id"
            :isObayashi="checkObayashi"
            @onChange="onChange"
          />
        </Popup>
        <!-- １次請け招待フォーム -->
        <Popup :dialog="inviteFromOwnerCompany.isShow" width="480px">
          <Invite1stRelated
            :inviteFromOwnerCompany="inviteFromOwnerCompany"
            @onCancel="inviteCancel"
            @onSubmit="inviteSubmit"
          />
        </Popup>
        <!-- ２次請け以下招待フォーム -->
        <Popup :dialog="inviteFromRelatedCompany.isShow" width="480px">
          <InviteOtherRelated
            :inviteFromRelatedCompany="inviteFromRelatedCompany"
            @onCancel="inviteCancel"
            @onSubmit="inviteSubmit"
          />
        </Popup>
        <!--  (共通) 削除ダイアログ / 文言変えてもok -->
        <Popup width="480px" :dialog="deleteFormChart.isShow">
          <ConfirmRemoveDialog
            :isDelete="deleteFormChart.isDelete"
            :title="deleteFormChart.title"
            :text1="deleteFormChart.text1"
            :text2="deleteFormChart.text2"
            :titleBtn="deleteFormChart.titleBtn"
            @close="closeRemoveDialog()"
            @yes="removeItems()"
            :isDisable="isRemoveSubmitted"
            warning
          />
        </Popup>
        <Popup width="480px" :dialog="confirmWorkChart.isShow">
          <ConfirmWorkDialog
            :confirmWorkChart="confirmWorkChart"
            @close="closeRemoveDialog"
            @yes="handleWorkChart"
            warning
          />
        </Popup>
        <!-- 作業グループ -->
        <Popup :dialog="changeGroupWorkPopup.isShow" width="480px">
          <ChooseGroupArray
            :flag="flagError"
            @close="changeGroupWorkPopup.isShow=false"
            @onUpdateFieldTreeGroup="onUpdateFieldTreeGroup"
          />
        </Popup>
        <!-- 作業グループ -->
        <Popup :dialog="setManageUserPopup.isShow" width="480px">
          <ChooseManageUser
            :flag="flagError"
            @close="setManageUserPopup.isShow=false"
            @onUpdateFieldTreeManagers="onUpdateFieldTreeManagers"
          />
        </Popup>
        <Popup width="480px" :dialog="exportDl.isShow">
          <ExportDialog
            @close="closeExportDialogForm"
            @export="exportCSV"
          />
        </Popup>
      </div>
    </template>
  </DefaultLayout>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import Company from "./components/Company/index.vue";
import Select from "@/components/forms/elements/Select.vue";
import InputText from "@/components/forms/elements/InputText";
import Label from "@/components/forms/elements/Label";
import Popup from "@/components/common/Popup.vue";
import { Store } from "@/store/Store.js";
import OwnerCompanyForm from "@/components/forms/portal/chart/OwnerCompanyForm/index.vue";
import RelatedCompanyForm from "@/components/forms/portal/chart/RelatedCompanyForm/index.vue";
import Invite1stRelated from "./components/InviteForm/Invite1stRelated.vue";
import InviteOtherRelated from "./components/InviteForm/InviteOtherRelated.vue";
import ConfirmRemoveDialog from "./components/ConfirmRemoveDialog/ConfirmRemoveDialog.vue";
import ConfirmWorkDialog from "./components/ConfirmRemoveDialog/ConfirmWorkDialog.vue";
import ChooseGroupArray from "@/views/portal/chart/components/ChangeGroupAndUser/ChooseGroupArray.vue";
import ChooseManageUser from "@/views/portal/chart/components/ChangeGroupAndUser/ChooseManageUser.vue";
import ExportDialog from "./components/ExportDialog.vue";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import {
  TEXT_DIALOG,
  END_WORK_MESSAGE_DIALOG,
  RESTART_WORK_MESSAGE_DIALOG,
  ERROR_WORK_MESSAGE_DIALOG,
  STATE_HANDLING_FLAG,
} from "@/constants/PORTAL_CHART.js";
import { HEADER_MENU_ITEMS_SITE } from "@/constants/GLOBALHEADER";
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON"; //絞り込みフォームで使用
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import { format, parseISO } from "date-fns";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";

const FLAG_COMPANY_PARTNERS = 0;
const FILE_NAME = {
  COMPANY_PARTNERS: "事業者一覧",
  EMPLOYEES: "技能者一覧"
};
const URL = `${process.env.VUE_APP_API_BASE_URL}`;
const DOWNLOAD_URL = `${URL}/field_trees/get_field_tree_csv`;
const ROLE_MAIN = 3;
// 分割して表示
const chunkCompanies = (companies, callback) => {
  const req = (children) => {
    if (!children || children.length === 0) return;
    setTimeout(() => {
      const child = children.shift();
      callback(child);
      req(children);
    }, 10);
  };
  req(JSON.parse(JSON.stringify(companies)));
};

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: "体制管理" };
    },
  },
  components: {
    SitePortalHeader,
    Company,
    Select,
    InputText,
    Label,
    Popup,
    OwnerCompanyForm,
    RelatedCompanyForm,
    Invite1stRelated,
    InviteOtherRelated,
    DefaultLayout,
    ConfirmRemoveDialog,
    InputDatepicker,
    ConfirmWorkDialog,
    ChooseGroupArray,
    ChooseManageUser,
    ExportDialog,
    ValidationObserver,
    ValidationCallback
  },
  data: () => {
    return {
      valid: true,
      NO_DATA_MESSAGE,
      field_tree_id: null,
      companies: [],
      workTypes: [], //工事選択一覧
      currentWorkType: null,
      formValue: {
        site_id: JSON.parse(sessionStorage.getItem("GRN_HEADERS")).site,
        work_type_id: null,
        field_tree_date: format(parseISO((new Date()).toISOString().slice(0,10)), "yyyy/MM/dd"),
        company_name: null,
        company_name_kana: null,
      },
      // 元請けフォーム
      ownerCompanyPopup: {
        companyId: null,
        companyName: null,
        chartId: null,
        formTab: null,
        isShow: false,
        state_handling_flag: null,
      },
      // 協力会社フォーム
      relatedCompanyPopup: {
        companyId: null,
        companyName: null,
        chartId: null,
        formTab: null,
        isShow: false,
        state_handling_flag: null,
      },
      //１次協力会社を招待
      inviteFromOwnerCompany: {
        chartId: null,
        isShow: false,
      },
      //2次以下を招待
      inviteFromRelatedCompany: {
        chartId: null,
        isShow: false,
      },
      deleteFormChart: {
        isDelete: true,
        title: "",
        text1: "",
        text2: "",
        isShow: false,
        chartId: null,
        titleBtn:""
      },
      TEXT_DIALOG,
      isChange: false,
      isRemoveSubmitted: false,
      max: null,
      min: null,
      isDisableDate: false,
      confirmWorkChart: {
        chartId: null,
        isShow: false,
        message: null,
      },
      changeGroupWorkPopup: {
        isShow: false,
        chartId: null,
      },
      setManageUserPopup: {
        isShow: false,
        chartId: null,
      },
      flagError: false,
      exportDl: {
        isShow: false,
      },
      roleUser: null,
      ENV_CLIENT,
      flagInit: true,
      csv_flag : null,
      work_date : null
    };
  },
  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    Store.dispatch("GlobalHeader/setSiteMenu", {
      menuId: HEADER_MENU_ITEMS_SITE.CHART.id,
    });

    /**
     * 選択した協力会社のポップアップを開く
     */
    this.$watch(
      () => [
        Store.getters["PortalChart/getSelectedOwnerCompany"],
        Store.getters["PortalChart/getSelectedRelatedCompany"],
      ],
      (newVal) => {
        if (newVal) {
          console.log("popup", [...newVal]);
          const active = newVal.findIndex((item) => item.companyId);

          //companyIdがみつからない場合
          if (active === -1) return;

          const { companyId, companyName, chartId, formTab, state_handling_flag, dataDetail } = newVal[active];
          console.log("popup", active, companyId);
          if (active === 0) {
            //元請け
            const ownerCompanyPopup = { ...this.ownerCompanyPopup };
            ownerCompanyPopup.isShow = true;
            ownerCompanyPopup.companyId = companyId;
            ownerCompanyPopup.companyName = companyName;
            ownerCompanyPopup.chartId = chartId;
            ownerCompanyPopup.formTab = formTab;
            ownerCompanyPopup.state_handling_flag = state_handling_flag;
            ownerCompanyPopup.dataDetail = dataDetail;
            this.ownerCompanyPopup = ownerCompanyPopup;
            if(!this.checkFieldTreeDate) {
              let params = { field_tree_id: chartId };
              this.checkObayashi ? params.is_oba = 1 : "";
              Store.dispatch("PortalChart/getOwnerCompany", { params });
            }
          } else {
            //協力会社
            const relatedCompanyPopup = { ...this.relatedCompanyPopup };
            relatedCompanyPopup.isShow = true;
            relatedCompanyPopup.companyId = companyId;
            relatedCompanyPopup.companyName = companyName;
            relatedCompanyPopup.chartId = chartId;
            relatedCompanyPopup.formTab = formTab;
            relatedCompanyPopup.state_handling_flag = state_handling_flag;
            relatedCompanyPopup.dataDetail = dataDetail;
            this.relatedCompanyPopup = relatedCompanyPopup;
            if(!this.checkFieldTreeDate) {
              Store.dispatch("PortalChart/getRelatedCompany", chartId);
            }
          }
        }
      },
      {
        deep: true,
      }
    );

    /**
     * 体制図を取得
     */
    this.$watch(
      () => [Store.getters["PortalChart/getCompanies"]],
      (newVal) => {
        if (newVal && newVal[0]) {
          this.companies = [];
          // 分割代入して描画する
          let _companies = JSON.parse(JSON.stringify(newVal[0]));
          if (!_companies.field_tree_children || _companies.field_tree_children.length === 0) {
            this.companies = [_companies];
          } else {
            _companies.field_tree_children = [];
            chunkCompanies(
              JSON.parse(JSON.stringify(newVal[0].field_tree_children)),
              (children) => {
                _companies.field_tree_children.push(children);
                this.companies = [_companies];
              }
            );
          }
          this.flagInit = false;
        } else {
          this.companies = [];
          this.flagInit = false;
        }
      },
      {
        deep: true,
      }
    );

    /**
     * 工事一覧を取得
     */
    this.$watch(
      () => Store.getters["PortalChart/getWorkTypes"],
      (data, oldData) => {
        if (JSON.stringify(data) !== JSON.stringify(oldData)) {
          this.workTypes = [{ id: null, name: "" }, ...data];
          if (this.workTypes.length > 1) {
            this.currentWorkType = this.workTypes[1].id;
            this.getMaxMinDate();
          } else {
            this.formValue.work_type_id = null;
            return;
          }
        }
        this.formValue.work_type_id = this.currentWorkType;
        this.callApiCheckIsDirector(this.formValue.work_type_id);
      },
      {
        immediate: true,
        deep: true,
      }
    );

    /**
     * 1次請けを招待
     */
    this.$watch(
      () => Store.getters["PortalChart/isInviteFromOwner"],
      (newVal) => {
        if (newVal.chartId) {
          const inviteFromOwnerCompany = {
            ...this.inviteFromOwnerCompany,
            ...newVal,
          };
          this.getListGroup();
          inviteFromOwnerCompany.isShow = true;
          this.inviteFromOwnerCompany = inviteFromOwnerCompany;
        }
      }
    );
    /**
     * 1次請けを招待
     */
    this.$watch(
      () => Store.getters["PortalChart/isInviteFromRelated"],
      (newVal) => {
        if (newVal.chartId) {
          const inviteFromRelatedCompany = {
            ...this.inviteFromRelatedCompany,
            ...newVal,
          };
          inviteFromRelatedCompany.isShow = true;
          this.inviteFromRelatedCompany = inviteFromRelatedCompany;
        }
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/isDeleteChart"],
      (newVal) => {
        const deleteFormChart = { ...this.deleteFormChart };
        const {
          ERROR_TITLE,
          ERROR_TEXT_1,
          ERROR_TEXT_2,
          CONFIRM_TITLE,
          CONFIRM_TEXT_1,
          CONFIRM_TEXT_2,
          CONFIRM_TITLE_INVITEING,
          CONFIRM_TEXT_1_INVITEING,
          CONFIRM_TEXT_2_INVITEING,
          TITLE_BTN,
          TITLE_BTN_INVITEING
        } = TEXT_DIALOG;
        if (newVal.inviteStatus !== 0) {
          deleteFormChart["title"] = newVal["isDelete"]
            ? ERROR_TITLE
            : CONFIRM_TITLE;
          deleteFormChart["text1"] = newVal["isDelete"]
            ? ERROR_TEXT_1
            : CONFIRM_TEXT_1;
          deleteFormChart["text2"] = newVal["isDelete"]
            ? ERROR_TEXT_2
            : CONFIRM_TEXT_2;
          deleteFormChart["titleBtn"] = TITLE_BTN;
        } else {
          deleteFormChart["title"] =  CONFIRM_TITLE_INVITEING;
          deleteFormChart["text1"] = CONFIRM_TEXT_1_INVITEING;
          deleteFormChart["text2"] = CONFIRM_TEXT_2_INVITEING;
          deleteFormChart["titleBtn"] = TITLE_BTN_INVITEING;
        }
        deleteFormChart["isDelete"] = !newVal["isDelete"];
        if (!newVal["isDelete"]) {
          deleteFormChart["chartId"] = newVal["chartId"];
        }
        deleteFormChart.isShow = true;
        this.deleteFormChart = deleteFormChart;
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/isEndWorkChart"],
      (newVal) => {
        if (newVal.chartId) {
          const confirmWorkChart = {
            ...this.confirmWorkChart,
            ...newVal,
          };
          confirmWorkChart['message'] = {...ERROR_WORK_MESSAGE_DIALOG};
          if(newVal.isActive) {
            confirmWorkChart['message'] = {...END_WORK_MESSAGE_DIALOG};
          }
          confirmWorkChart.isShow = true;
          this.confirmWorkChart = confirmWorkChart;
        }
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/isRestartWorkChart"],
      (newVal) => {
        if (newVal.chartId) {
          const confirmWorkChart = {
            ...this.confirmWorkChart,
            ...newVal,
          };
          confirmWorkChart['message'] = {...ERROR_WORK_MESSAGE_DIALOG};
          if(newVal.isActive) {
            confirmWorkChart['message'] = {...RESTART_WORK_MESSAGE_DIALOG};
          }
          confirmWorkChart.isShow = true;
          this.confirmWorkChart = confirmWorkChart;
        }
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/isChangeGroupWork"],
      (newVal) => {
        if (newVal.chartId) {
          Store.dispatch("PortalChart/getListGroups", {
            field_construction_id: this.formValue.work_type_id,
            all_group_flag: 1,
          });
          this.changeGroupWorkPopup.isShow = true;
          this.changeGroupWorkPopup.chartId = newVal.chartId;
        }
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/isSetManageUser"],
      (newVal) => {
        if (newVal.chartId) {
          let params = {
            field_construction_id: this.formValue.work_type_id,
            state_handling_flag: 0
          };
          Store.dispatch("PortalChart/getFieldDirectorsObayashi", params);
          this.setManageUserPopup.isShow = true;
          this.setManageUserPopup.chartId = newVal.chartId;
        }
      }
    );

    this.$watch(
      () => Store.getters["Sites/getRole"],
      async (data) => {
        if (data) {
          this.roleUser = data;
          await this.loadWorkType();
          await this.loadCompanies();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["File/getData"],
      (data) => {
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${
              this.csv_flag == FLAG_COMPANY_PARTNERS
                ? FILE_NAME.COMPANY_PARTNERS
                : FILE_NAME.EMPLOYEES
            }_${format(this.work_date, "yyyyMMdd")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          Store.dispatch("File/clearData");
        }
      },
      {
        deep: true,
      }
    );


  },
  computed: {
    checkFieldTreeDate() {
      if(this.formValue.field_tree_date) {
        return format(parseISO((new Date()).toISOString().slice(0,10)), "yyyy/MM/dd") > this.formValue.field_tree_date;
      }
      return false;
    },
    checkObayashi(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
    isRoleMain(){
      return this.roleUser == ROLE_MAIN;
    }
  },
  methods: {
    updateValidate({ valid }) {
      this.valid = valid;
    },
    async onChange() {
      //工事一覧を取得
      await this.loadWorkType();

      //体制図を取得
      await this.loadCompanies();
    },

    /**
     * @description 工事一覧を取得
     * @param
     * {
     *  site_id:現場id
     * }
     */
    async loadWorkType() {
      const { site_id } = { ...this.formValue };
      const apiParams = {
        field_id: site_id,
        tree_flag: 1,
      };
      await Store.dispatch("PortalChart/getWorkTypes", apiParams);
    },

    /**
     * @description 体制図のロード
     */
    async loadCompanies() {
      if (this.valid) {
        let params = { ...this.formValue };
        if (this.checkObayashi) {
          params["is_oba"] = 1;
          if (this.isRoleMain) {
            params.prime_flag = 1;
          }
        }
        if (params.work_type_id > 0) {
          await Store.dispatch("PortalChart/getCompanies", params);
        } else {
          this.companies = [];
        }
      }
    },

    /**
     * 会社ポップアプを閉じる
     */
    closeOwnerForm() {
      this.ownerCompanyPopup = {
        companyId: null,
        chartId: null,
        formTab: null,
        isShow: false,
        state_handling_flag: null,
        dataDetail: null,
      };
      Store.dispatch("PortalChart/openOwnerCompany", {
        companyId: null,
        chartId: null,
        formTab: null,
        state_handling_flag: null,
        dataDetail: null,
      });
    },

    /**
     * 会社ポップアプを閉じる
     */
    closeRelatedForm() {
      this.relatedCompanyPopup = {
        companyId: null,
        chartId: null,
        formTab: null,
        isShow: false,
        state_handling_flag: null,
      };
      Store.dispatch("PortalChart/openRelatedCompany", {
        companyId: null,
        chartId: null,
        formTab: null,
        state_handling_flag: null,
      });
    },

    /**
     * closeRemoveDialog
     */
    closeRemoveDialog() {
      this.deleteFormChart = {
        isDelete: true,
        title: "",
        text1: "",
        text2: "",
        isShow: false,
        chartId: null,
        titleBtn: ""
      };
      this.confirmWorkChart = {
        isShow: false,
        chartId: null,
        message: null,
      }
    },

    /**
     * removeItems
     */
    async removeItems() {
      this.isRemoveSubmitted = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isRemoveSubmitted = false;
      }, SUBMIT_DELAY_TIMEOUT);

      const apiParams = {
        field_tree_id: this.deleteFormChart.chartId,
      };
      const result = await Store.dispatch(
        "PortalChart/deleteFieldTreePartner",
        apiParams
      );
      if (!result.hasError) {
        this.closeRemoveDialog();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });

        await this.onChange();
      }
    },

    async handleWorkChart() {
      const params = {
        field_tree_id: this.confirmWorkChart.chartId,
        state_handling_flag: this.confirmWorkChart.isEndWork ? STATE_HANDLING_FLAG.FINISHED : STATE_HANDLING_FLAG.WORKING
      }
      const result = await Store.dispatch("PortalChart/updateStatHandling", params);
      if(!result.hasError) {
        this.closeRemoveDialog();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
         await this.onChange();
      }
    },

    /**
     * onChangeSearchInput
     */
    onChangeSearchInput({ name, value }) {
      const formValue = { ...this.formValue };
      formValue[name] = value;
      if(name == "work_type_id") {
        this.currentWorkType = value;
        this.getMaxMinDate();
        this.callApiCheckIsDirector(value);
      }
      this.formValue = formValue;
      this.loadCompanies();
    },
    getMaxMinDate() {
      const currentSite = this.workTypes.find(item => item.id === this.currentWorkType);
      const currentDate = (new Date()).toISOString().slice(0,10);
      this.isDisableDate = false;
      if (currentSite.construction_groundbreaking_date == null || currentDate < currentSite.construction_groundbreaking_date) {
        this.isDisableDate = true;
      }
      if (currentDate >= currentSite.construction_groundbreaking_date && currentDate <= currentSite.construction_complete_schedule_date) {
        this.max = (new Date(currentDate)).toISOString().slice(0,10);
        this.min = (new Date(currentSite.construction_groundbreaking_date)).toISOString().slice(0,10);
      }
      if (currentDate > currentSite.construction_complete_schedule_date) {
        this.max = (new Date(currentSite.construction_complete_schedule_date)).toISOString().slice(0,10);
        this.min = (new Date(currentSite.construction_groundbreaking_date)).toISOString().slice(0,10);
      }
    },

    /**
     * 招待フォームを送信
     */
    async inviteSubmit({
      email,
      name_sei,
      name_mei,
      group_name,
      group_id,
      message,
      field_tree_id,
    }) {
      let params = {
        email,
        name_sei,
        name_mei,
        group_name,
        group_id,
        message,
        field_tree_id,
      };

      //空の場合は協力会社->削除
      if (!params.group_name) delete params.group_name;
      if (!params.group_id) delete params.group_id;

      console.log("params", params);

      //招待を送る
      const response = await Store.dispatch("PortalChart/sendInvite", params);

      if (response.hasError) {
        this.inviteCancel();
      } else {
        Store.dispatch("Toast/show", {
          status: 200,
          message: `${name_sei} ${name_mei}さんを招待しました。`,
        });
        this.inviteCancel();

        await this.onChange();
      }
    },

    //閉じる
    inviteCancel() {
      this.inviteFromOwnerCompany = {
        chartId: null,
        isShow: false,
      };
      this.inviteFromRelatedCompany = {
        chartId: null,
        isShow: false,
      };
    },

    async onUpdateFieldTreeGroup(fieldTreeGroupId) {
      const params = {
        field_tree_id_1: this.changeGroupWorkPopup.chartId,
        field_tree_group_id: fieldTreeGroupId
      }
      const res = await Store.dispatch('PortalChart/updateFieldTreeGroup', params);
      if (res.hasError) {
        this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 200,
          message: res.data.response.message,
        });
      } else {
        this.changeGroupWorkPopup.isShow = false;
        this.companies = [];
        await this.onChange();
      }
    },
    async onUpdateFieldTreeManagers(userId) {
      const params = {
        field_tree_id_1: this.setManageUserPopup.chartId,
        user_id: userId
      }
      const res = await Store.dispatch('PortalChart/updateFieldTreeManagers', params);
      if (res.hasError) {
        this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 200,
          message: res.data.response.message,
        });
      } else {
        this.setManageUserPopup.isShow = false;
        this.companies = [];
        await this.onChange();
      }
    },
    async getListGroup() {
      await Store.dispatch("PortalChart/getListGroups", {
        field_construction_id: this.formValue.work_type_id,
        all_group_flag: 1,
      });
    },
    setDataInput({ name, value}){
      this.formValue[name] = value;
    },

    exportCSV(value){
      this.csv_flag = value;
      this.work_date = new Date(this.formValue.field_tree_date);
      let params = {
        field_construction_id: this.formValue.work_type_id,
        field_tree_date: this.formValue.field_tree_date,
        company_name: this.formValue.company_name??'',
        company_name_kana: this.formValue.company_name_kana??'',
        csv_flag: value,
      }
      this.downloadDocument(params);
      this.exportDl.isShow = false;
    },
    async downloadDocument(params) {
      const configParams = {
        url: DOWNLOAD_URL,
        params: params,
      }
      await Store.dispatch("File/download", configParams);
    },
    closeExportDialogForm(){
      this.csv_flag = null;
      this.work_date = null;
      this.exportDl.isShow = false;
    },
    // API check user is drirector
    async callApiCheckIsDirector(field_construction_id) {
      if (!field_construction_id) return;
      const params = {
        user_id: JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user.user_id,
        field_construction_id
      };
      await Store.dispatch("Employees/checkIsDirector", { params });
    },
  },

  /**
   * Important: clear timeout
   */
  beforeDestroy() {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout);
  },
};
</script>
<style lang="scss" scoped>
.chart {
  margin-top: 48px;
  padding-bottom: 64px;
  height: 100%;
  overflow: auto;
  position: relative;
}
</style>
