<template>
  <v-card class="dialog">
    <div class="header sticky">
      <v-card-title small>
        <div class="title">元請担当者選択</div>
        <v-spacer></v-spacer>
        <v-btn small icon text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
    </div>
    <SearchSelectList
      placeHolder="氏名"
      @search="searchData"
      @formUpdate="formUpdate"
    >
      <v-list>
        <template v-for="(item, index) in items" v-if="items.length > 0">
          <v-list-item :key="index" ripple>
            <v-list-item-content class="text-break" style="cursor: pointer" @click="onClick(item)">
              {{ item.user_name }}
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <div style="display: flex;justify-content: center" v-if="isNoDataMessage">
        {{ NO_DATA_MESSAGE }}
      </div>
    </SearchSelectList>
  </v-card>
</template>

<script>
import SearchSelectList from "./SearchSelectList.vue";
import { Store } from "@/store/Store.js";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";

export default {
  components: {
    SearchSelectList
  },

  props: {
    field_construction_id: Number,
    job_id: Number,
    storeName : String
  },

  data: () => {
    return {
      keyword: "",
      NO_DATA_MESSAGE,
      items: [],
      isNoDataMessage: false
    };
  },
  async mounted() {
    await this.getDataItems();
    this.$watch(
      () => Store.getters[`PortalChart/getListDirectorsObayashi`],
      (data) => {
        if (data) {
          let rs = [];
          data.forEach(e => {
            rs.push({
              id: e.id,
              user_name: e.user_name_sei + " " + e.user_name_mei
            });
          });
          this.items = [...rs];
          this.isNoDataMessage = this.items?.length == 0;
        }
      },
      {
        deep: true
      }
    );
  },
  methods: {
    formUpdate(values) {
      this.keyword = values.keyword;
    },
    async getDataItems() {
      if (this.field_construction_id && this.job_id) {
        let params = {
          field_construction_id: this.field_construction_id,
          job_id: this.job_id
        };
        let rs = await Store.dispatch(`${this.storeName}/getListManagerHistory`, { params });
        let _items = [...rs.data.contents.entries];
        _items.forEach(e => {
          e["id"] = e.user_id;
        });
        this.items = [..._items];
        this.isNoDataMessage = this.items.length == 0;
      } else {
        this.items = [];
        this.isNoDataMessage = true;
      }
    },
    async searchData() {
      if (this.field_construction_id) {
        let params = {
          field_construction_id: this.field_construction_id,
          state_handling_flag : 0,
          user_name: this.keyword
        };
        await Store.dispatch("PortalChart/getFieldDirectorsObayashi",  params );
      } else {
        this.items = [];
        this.isNoDataMessage = true;
      }
    },
    onClick(item) {
      this.$emit("updateData", item, this.items);
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  color: #1B9C4F;
}

.dialog {
  padding-bottom: 20px;

  .header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }
}
</style>
