<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row no-gutters>
          <v-col>
            <Label label="大分類" :editable="editable">
              <SelectSearch
                name="catergory_id"
                :values="item"
                :items="CATEGORY_EXPERIENCES_ARRAY[index]"
                :editable="editable"
                title="大分類選択"
                title_search="大分類名"
                @onInput="onInput({ value: $event, index })"
              />
            </Label>
          </v-col>
          <v-col class="ml-5">
            <Label label="小分類" :editable="editable">
              <SelectSearch
                name="cmn_mst_skills_id"
                :values="item"
                :items="filterSubCatergory(USER_EXPERIENCES_ARRAY, item)"
                item_text="sub_catergory"
                :editable="editable"
                title="小分類選択"
                title_search="小分類名"
                @onInput="onInput({ value: $event, index })"
              />
            </Label>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="4" md="4">
            <Label label="経験開始年月" :editable="editable">
              <InputMonthpicker
                name="experience_start_date"
                :values="item"
                :editable="editable"
                :max="max"
                placeholder="yyyy/mm"
                :validation_label="`経験開始年月_${index}`"
                @onInput="onInput({ value: $event, index })"
              />
            </Label>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="ml-5">
            <Label label="ブランク期間" :editable="editable" :class="item.experience_start_date && editable ? '' : 'mt-2'">
              <InputText
                name="experience_blank_period"
                :values="item"
                :editable="item.experience_start_date && editable ? true : false"
                placeholder=""
                validation_label="経験年数"
                suffix="ヶ月"
                :validation_rules="`compare-to-experience-start-date:@経験開始年月_${index}`"
                @onInput="onInput({ value: $event, index })"
              />
            </Label>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="ml-5">
            <Label label="経験年数" :editable="false" class="mt-2">
              <InputText
                name="experience"
                :values="item"
                :editable="false"
              />
            </Label>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="ml-4">
            <v-btn
              v-if="index > 0 && editable"
              class="mt-7"
              icon
              @click="remove(index)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn v-if="editable" depressed small color="primary" @click="addEmpty"
      >追加</v-btn
    >
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */
import { Store } from "@/store/Store.js";
import Label from "../../elements/Label.vue";
import Select from "../../elements/Select.vue";
import InputNumber from "../../elements/InputNumber.vue";
import InputText from "../../elements/InputText";
import InputMonthpicker from "../../elements/InputMonthpicker.vue";
import SelectSearch from "@/components/forms/elements/SelectSearch";

//
const INITIAL_VALUES = {
  catergory_id: null,
  sub_catergory_id: null,
  cmn_mst_skills_id: null,
  experience_start_date : null,
  experience_blank_period : 0,
  experience : null
};

export default {
  data: () => {
    return {
      items: [],
      USER_EXPERIENCES_ARRAY: Store.state.CmnMst.constants.entries.cmn_mst_skills,
      CATEGORY_EXPERIENCES_ARRAY: [],
      dataCmnmst:[],
      dataCatergory : [],
      max: new Date().toISOString().slice(0, 10),
    };
  },
  components: {
    SelectSearch,
    Label,
    Select,
    InputNumber,
    InputMonthpicker,
    InputText
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
  },
  mounted() {
    this.initData();
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (Array.isArray(this.items)) {
          this.getDataArray();
        }
        if (this.items?.length == 0) {
          this.addEmpty();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    initData(){
      this.dataCmnmst = [...this.USER_EXPERIENCES_ARRAY];
      this.dataCatergory = this.getDataAllCatergory();
    },
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      if (value.name == "catergory_id") {
        if (!value.value) {
          this.CATEGORY_EXPERIENCES_ARRAY[index] = [...this.dataCatergory];
          _items[index].cmn_mst_skills_id = null;
        }
        if(_items[index].cmn_mst_skills_id){
          let sub_name = this.dataCmnmst.find(e => e.id == _items[index].cmn_mst_skills_id)?.sub_catergory;
          _items[index].cmn_mst_skills_id = this.dataCmnmst.find(e => e.sub_catergory == sub_name && e.catergory_id == value.value)?.id;
        }
      }
      if (value.name == "cmn_mst_skills_id" && value.value) {
        let sub_name = this.dataCmnmst.find(e => e.id == value.value)?.sub_catergory;
        let temp = this.dataCmnmst.filter(e => e.sub_catergory == sub_name);
        _items[index].catergory_id = temp.find(e => e.id == value.value)?.catergory_id;
      }
      _items[index][value.name] = value.value;
      if (value.name == "experience_start_date" && value.value == null) {
        _items[index].experience_blank_period = 0;
      }
      if(value.name == "experience_start_date" || value.name == "experience_blank_period"){
        _items[index].experience = this.getExperiencesMonth(_items[index].experience_start_date, _items[index].experience_blank_period || 0);
      }
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      this.items.push({ ...INITIAL_VALUES });
    },
    filterSubCatergory(selectItems, item) {
      let result = [{ id: null, sub_catergory: "" }];
      if (item.catergory_id) {
        result = [...result, ...selectItems.find(e => e[0].catergory_id == item.catergory_id)];
      } else {
        selectItems.forEach(catergory => {
          catergory.forEach(sub_catergory => {
            result.push(sub_catergory);
          });
        });
      }
      result = this.getUniqueData(result);
      return result;
    },
    getUniqueData(arr) {
      let rs = [];
      let temp = [];
      arr.forEach(e => {
        if (!temp.includes(e.sub_catergory)) {
          rs.push(e);
          temp.push((e.sub_catergory));
        }
      })
      return rs;
    },
    getDataAllCatergory(){
      let rs = [{ id: null, name: "" }];
      let results = [];
      let catergoryId = null;
      this.USER_EXPERIENCES_ARRAY.forEach((e) => {
        let rs = [];
        if (catergoryId !== e.catergory_id) {
          catergoryId = e.catergory_id;
          rs = this.USER_EXPERIENCES_ARRAY.filter(i => (i.catergory_id == e.catergory_id));
          results.push(rs);
        }
      });
      this.USER_EXPERIENCES_ARRAY = results;
      results.forEach(element => {
        if(element.length > 0) {
          rs.push({id: element[0].catergory_id, name: element[0].catergory});
        }
      });
      return rs ;
    },
    getExperiencesMonth(startMonth, blankPeriod) {
      // if startMonth null or blankPeriod not decimal then not show experience
      if (!startMonth || !this.isDecimal(blankPeriod)) return null;
      let startMonthArr = startMonth.split('/');
      let totalMonthCompareCurrent = (new Date().getFullYear() - startMonthArr[0]) * 12 - startMonthArr[1] + new Date().getMonth() + 1;
      // if error validate experience blank Period then not show
      if (totalMonthCompareCurrent < blankPeriod) return null;
      let experienceMonth = this.converExperienceMonth(totalMonthCompareCurrent - blankPeriod);
      return experienceMonth;
    },
    converExperienceMonth(totalMonth) {
      let totalYearExp = Math.floor(totalMonth / 12);
      let totalMonthExp = totalMonth % 12;
      return `${totalYearExp}年 ${totalMonthExp}ヶ月`;
    },
    getDataArray() {
      this.CATEGORY_EXPERIENCES_ARRAY = [];
      this.items.forEach((item, index) => {
        if (item.cmn_mst_skills_id) {
          let sub_name = this.dataCmnmst.find(e => e.id == item.cmn_mst_skills_id)?.sub_catergory;
          let temp = this.dataCmnmst.filter(e => e.sub_catergory == sub_name);
          this.CATEGORY_EXPERIENCES_ARRAY[index] = [{ id: null, name: "" }];
          temp.forEach(e => {
            this.CATEGORY_EXPERIENCES_ARRAY[index].push({ id: e.catergory_id, name: e.catergory });
          });
        } else {
          this.CATEGORY_EXPERIENCES_ARRAY[index] = [...this.dataCatergory];
        }
      });
    },
    isDecimal(value) {
      return String(value).match(/^[0-9]*$/);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
</style>
