<template>
  <v-card class="dialog">
    <div class="header sticky">
      <v-card-title small>
        <div class="title">履歴から選択</div>
        <v-spacer></v-spacer>
        <v-btn small icon text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
    </div>
    <SearchSelectList
      title_search="作業詳細"
      @search="getDataHistory"
      @formUpdate="formUpdate"
    >
      <v-list v-if="items.length > 0">
        <template v-for="(item, index) in items">
          <v-list-item :key="index" ripple>
            <v-list-item-content class="text-break" style="white-space: pre-wrap;cursor: pointer" @click="onClick(item)">
              {{item}}
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <div style="display: flex;justify-content: center" v-else-if="isNoDataMessage">
        {{ NO_DATA_MESSAGE }}
      </div>
    </SearchSelectList>
  </v-card>
</template>

<script>
import SearchSelectList from "@/components/forms/schedule/components/SearchSelectList.vue";
import { Store } from "@/store/Store.js";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
export default {
  components: {
    SearchSelectList,
  },

  props: {
    close: Function,
    historyType : Number,
    field_tree_id: {
      type: Number,
      default: null
    },
  },

  data: () => {
    return {
      keyword: "",
      items: [],
      NO_DATA_MESSAGE,
      isNoDataMessage: false,
    };
  },
  computed: {
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },
  async mounted() {
    await this.getDataHistory();
  },
  methods: {
    formUpdate(values) {
      this.keyword = values.keyword;
    },

    onClick(item) {
      this.$emit("updateDataHistory", item);
    },
    async getDataHistory(){
      const params = {
        field_id: this.CURRENT_SITE.field_id,
        field_tree_id: this.field_tree_id,
        history_type : this.historyType,
        content_search: this.keyword
      };
      let rs = await Store.dispatch(`ScheduleCrane/getListCranUseHistory`, { params });
      this.items = [...rs.data.contents.entries];
      this.isNoDataMessage = this.items.length == 0;
    }
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #1B9C4F;
}
.dialog {
  padding-bottom: 20px;
  .header {
    height: 65px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }
  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;
      .text {
        text-align: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 80%;
      }
    }
  }
  .btn {
    margin-right: 12px;
  }
}
</style>
