<template>
  <div>
    <v-treeview
      v-if="items.length > 0"
      hoverable
      dense
      :items="items"
      return-object
      item-children="childrens"
      expand-icon="mdi-chevron-down"
    >
      <template v-slot:label="{ item }">
        <div v-if="item.childrens && item.location_name" class="row_flex">
          <div class="location_name">
            {{ item.location_name }}
          </div>
          <v-spacer></v-spacer>
          <span>{{ item.total }}件</span>
        </div>
        <div v-else class="infor_fire_use">
          <div class="infor_company_and_foreman_name">
            <div class="infor_company_name">
              {{ item.company_name }}
            </div>
            <span class="ml-2"></span>
            <div class="infor_foreman_name">
              {{ item.foreman_name }}
            </div>
          </div>
          <div>
            {{ item.work_hot_use_time }}
          </div>
        </div>
      </template>
    </v-treeview>
    <div v-else-if="isNoDataFireUse" class="mt-10" style="text-align: center">
      <span class="nodata">{{ NO_DATA_MESSAGE }}</span>
    </div>
  </div>
</template>
<script>
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
export default {
  data: () => {
    return {
      NO_DATA_MESSAGE,
    };
  },
  props: {
    items: {
      type: Array,
    },
    isNoDataFireUse: Boolean,
  },
};
</script>
<style lang="scss" scoped>
.location_name {
  text-overflow: ellipsis;
  max-width: 20vw;
  overflow: hidden;
}
.infor_fire_use {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.infor_company_name {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 9vw;
  overflow: hidden;
}
.infor_foreman_name {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 5vw;
  overflow: hidden;
}
.infor_company_and_foreman_name {
  display: flex;
  flex-direction: row;
  width: 61%;
  justify-content: space-between;
}
.nodata {
  color: rgba(162, 162, 162, 255);
}
.row_flex {
  display: flex;
}
</style>
