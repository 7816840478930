<template>
  <v-form autocomplete="off">
    <v-container>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-row>
            <v-list-item>
              <v-list-item-content>
                <p class="ml-2">
                  招待メールの送信先を入力してください。
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-row>
          <v-row style="margin-top: -30px">
            <v-col cols="12" sm="12" md="12">
              <v-list-item>
                <v-list-item-content class="pa-1">
                  <SwitchInput
                    name="select_group"
                    :label="`作業グループに追加する`"
                    :values="formValue"
                    :editable="true"
                    @onInput="onInput"
                    class="ml-2"
                  />
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row style="margin-top: -50px" v-if="formValue['select_group'] == SELECTED_GROUP.ON && !isTypeGroup">
            <v-col cols="12" sm="12" md="12">
              <v-list-item>
                <v-list-item-content>
                  <Label label="作業グループ" editable required>
                    <Select
                      name="groupId"
                      :items="groupArray"
                      :values="formValue"
                      :editable="true"
                      item_text="groupName"
                      item_value="groupId"
                      validation_label="作業グループ"
                      validation_rules="required|max:255"
                      @onInput="onInput"
                    />
                  </Label>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row style="margin-top: -50px" v-if="isTypeGroup">
            <v-col cols="12" sm="12" md="12">
              <v-list-item>
                <v-list-item-content>
                  <Label label="作業グループ" editable required>
                    <InputText
                      name="groupName"
                      :values="formValue"
                      :editable="true"
                      validation_label="作業グループ"
                      validation_rules="required|max:255"
                      @onInput="onInput"
                    />
                  </Label>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row style="margin-top: -40px">
            <v-col cols="12" md="12">
              <v-list-item>
                <v-list-item-content>
                  <Label label="メールアドレス" required editable>
                    <InputText
                      name="email"
                      :values="formValue"
                      :editable="true"
                      validation_label="メールアドレス"
                      validation_rules="required|max:255|email"
                      @onInput="onchangeEmail"
                    />
                  </Label>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row style="margin-top: -30px">
            <v-list-item>
              <v-list-item-content>
                <v-col class="width-row">
                  <Label label="姓" required editable>
                    <InputText
                      name="sei"
                      :values="formValue"
                      :editable="true && !disable"
                      validation_label="姓"
                      validation_rules="required|max:64"
                      @onInput="onInput"
                    />
                  </Label>
                </v-col>
                <v-col class="width-row">
                  <Label label="名" required editable>
                    <InputText
                      name="mei"
                      :values="formValue"
                      :editable="true && !disable"
                      validation_label="名"
                      validation_rules="required|max:64"
                      @onInput="onInput"
                    />
                  </Label>
                </v-col>
              </v-list-item-content>
            </v-list-item>
          </v-row>
          <v-row style="margin-top: -30px">
            <v-col cols="12" md="12">
              <v-list-item>
                <v-list-item-content>
                  <Label label="メッセージ">
                    <InputTextArea
                      height="120"
                      name="message"
                      :values="formValue"
                      :editable="true"
                      validation_label="メッセージ"
                      validation_rules="max:512"
                      @onInput="onInput"
                    />
                  </Label>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import Label from "@/components/forms/elements/Label";
import InputText from "@/components/forms/elements/InputText";
import InputTextArea from "@/components/forms/elements/InputTextArea.vue";
import SwitchInput from "@/components/forms/elements/SwitchInput.vue";
import { emailSearch } from "@/utils/emailSearch";
import Select from "@/components/forms/elements/Select.vue";
import {
  SELECTED_GROUP,
} from "@/constants/PORTAL_CHART.js";
export default {
  data: () => {
    return {
      formValue: {
        select_group: SELECTED_GROUP.OFF,
        groupName: "",
        email: "",
        sei: "",
        mei: "",
        message: "",
      },
      disable: false,
      SELECTED_GROUP,
      isTypeGroup: false,
    };
  },
  components: {
    Label,
    InputText,
    InputTextArea,
    SwitchInput,
    Select,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    groupArray: {
      type: Array
    },
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValue = { ...newValue };
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValue };
      if (name == "select_group") {
        if (value == SELECTED_GROUP.OFF) {
          this.isTypeGroup = false;
          formValues["groupName"] = null;
          formValues["groupId"] = null;
        }
      }
      if (name == "groupId") {
        if (value !== 0) {
          formValues["groupName"] = this.getGroupNameById(value);
        } else {
          // if choose value 作業グループを新規作成 in dropdown then show input text
          this.isTypeGroup = true;
          formValues["groupName"] = null;
        }
      }
      formValues[name] = value;
      this.formValue = formValues;
      this.$emit("formUpdate", {"tab2": formValues});
    },
    async onchangeEmail({ value }) {
      const formValue = { ...this.formValue };
      formValue.email = value;
      if (value == "") {
        formValue.sei = "";
        formValue.mei = "";
        this.disable = false;
      } else if (
        value.match(/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,6})+$/)
      ) {
        const result = await emailSearch.search({ email: value });
        if (result.name_sei != "" && result.name_mei != "") {
          formValue.sei = result.name_sei;
          formValue.mei = result.name_mei;
          this.disable = true;
        } else {
          formValue.sei = "";
          formValue.mei = "";
          this.disable = false;
        }
      }
      this.formValue = formValue;
      this.$emit("formUpdate", {"tab2": formValue});
    },
    getGroupNameById(id) {
      return this.groupArray?.find(e => e.groupId == id)?.groupName;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
.width-row {
  max-width: 50%;
}
::v-deep .v-input--selection-controls .v-input__control {
  height: 37px !important;
}
</style>
