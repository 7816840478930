import Vue from "vue";
import Vuex from "vuex";
import { companyMachines } from "../../../api/modules/companyMachines";
Vue.use(Vuex);
/**
 * apiを指定
 */
const ENTITY = companyMachines;
export const CompanyMachines = {
  namespaced: true,

  state: {
    data: [],
    pagination: {},
    machine_type: {},
    machine: {},
    machineCompanyType: null,
    machineCompanyByType: [],
    machineCompanyTypeInSpec: null,
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_CHILD_DATA(state, payload) {
      let parent = [];
      let childrens = [];
      let data = [];
      parent = state.data.find((item) => item.id === payload.id);
      childrens = [...parent.childrens, ...payload.entries];
      data = state.data.map((item) => {
        if (item.id === payload.id) {
          const _item = { ...item };
          _item.childrens = childrens;
          return _item;
        }
        return item;
      });
      state.data = data;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_MACHINE_TYPE(state, payload) {
      state.machine_type = payload;
    },
    SET_MACHINE(state, payload) {
      state.machine = payload;
    },
    SET_MACHINE_COMPANY_TYPE(state, payload) {
      state.machineCompanyType = payload;
    },
    SET_MACHINE_COMPANY_TYPE_IN_SPEC(state, payload) {
      state.machineCompanyTypeInSpec = payload;
    },
    SET_MACHINE_COMPANY_BY_TYPE(state, payload) {
      state.machineCompanyByType = payload;
    },
  },

  actions: {
    async get({ commit }, payload) {
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;
      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },

    async getMachineByType({ commit }, payload) {
      const response = await ENTITY.getListMachineType(payload);
      const { entries } = response.data.contents;
      commit("SET_MACHINE_TYPE", entries);
      return response;
    },

    async getDetail({ commit }, payload) {
      const response = await ENTITY.getMachineDetail(payload);
      const { entries } = response.data.contents;
      commit("SET_MACHINE", entries);
      return response;
    },

    async getMachineCompanyType({ commit }, payload) {
      const response = await ENTITY.getMachineCompanyType(payload);
      if (response.hasError) {
        return response;
      }
      commit("SET_MACHINE_COMPANY_TYPE", [...response.data.contents.entries]);
    },

    async updateMachineCompanyType(_context, payload) {
      const response = await ENTITY.updateMachineCompanyType({
        machine_company_type_items: payload,
      });
      if (response.hasError) {
        return response;
      }
      return response;
    },

    async create(_context, payload) {
      const response = await ENTITY.createMachineCompany(payload);
      if (response.hasError) {
        return response;
      }
      return response;
    },

    async update(_context, payload) {
      const response = await ENTITY.updateMachineCompany(payload);
      if (response.hasError) {
        return response;
      }
      return response;
    },

    async delete(_context, payload) {
      const response = await ENTITY.deleteMachineCompany(payload);
      if (response.hasError) {
        return response;
      }
      return response;
    },

    async loadChildren({ commit }, payload) {
      const response = await ENTITY.loadChildren(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_CHILD_DATA", {
        entries,
        id: payload.params.machine_company_id,
      });
      return response;
    },

    async sortMachineCompanyType(_context, payload) {
      const response = await ENTITY.sortMachineCompanyType(payload);
      if (response.hasError) {
        return response;
      }
      return response;
    },

    async GetMachineCompanyTypeInSpec({ commit }, payload) {
      const response = await ENTITY.getMachineCompanyTypeInSpec(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_MACHINE_COMPANY_TYPE_IN_SPEC", entries);
      return response;
    },

    async UpdateMachineCompanyTypeInSpec(_context, payload) {
      const response = await ENTITY.updateCompanyTypeInSpec(payload);
      if (response.hasError) {
        return response;
      }
      return response;
    },

    async getMachineCompanyByType({ commit }, payload) {
      const response = await ENTITY.getMachineCompanyByType(payload);
      if (response.hasError) {
        return response;
      }
      commit("SET_MACHINE_COMPANY_BY_TYPE", [...response.data.contents.entries]);
      return response;
    },

  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getMachineType: (state) => {
      return state.machine_type;
    },
    getMachine: (state) => {
      return state.machine;
    },
    getMachineCompanyType: (state) => {
      return state.machineCompanyType;
    },
    getMachineCompanyTypeInSpec: (state) => {
      return state.machineCompanyTypeInSpec;
    },
    getSortItem: (state) => {
      return state.sortItem;
    },
    getMachineCompanyByType: (state) => {
      return state.machineCompanyByType;
    },
  },
};
