import Vue from "vue";
import Vuex from "vuex";
import { sitemanagers } from "../../../api/modules/siteManagers";

Vue.use(Vuex);

/**
 * 施工体制図/現場監督APIを管理するstore
 */

// api名を指定
const ENTITY = sitemanagers;

export const SiteManagers = {
  namespaced: true,

  state: {
    // 現場監督リスト
    data: [],
    // ペジネーション
    pagination: {},
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
  },

  actions: {
    // 現場作業員リスト取得
    async get({ commit }, payload) {
      console.log("getting....");
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;
      // stateにset
      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },

    async post({ state }) {
      const payload = state.data;
      return await ENTITY.post(payload);
    },

    async update(_context, payload) {
      return await ENTITY.put(payload);
    },

    async delete(_context, payload) {
      return await ENTITY.delete(payload);
    },
  },

  getters: {
    // dataを返す
    getData: (state) => {
      return state.data;
    },
    // ペジネーションを返す
    getPagination: (state) => {
      return state.pagination;
    },
  },
};
