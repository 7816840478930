<template>
  <div class="roomview">
    <DefaultLayout>
      <template #mainHeader>
        <DrawingAppHeader />
        <!-- <DrawingAppHeader v-if="isDrawingApp" />
        <SpHeader
          v-if="$sp && !isDrawingApp"
          fallbackPath="/room"
          :PAGE_TITLE="PAGE_TITLE"
        /> -->
      </template>
      <template #page>
        <!-- メッセージ -->
        <v-list ref="roomTable" id="roomTable" class="roomview__list" two-line>
          <v-list-item-group>
            <RoomItem
              v-for="(item, index) in rooms"
              :item="item"
              :key="index"
              @onSelectRoom="onSelectRoom"
            />
          </v-list-item-group>
        </v-list>
      </template>
    </DefaultLayout>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import RoomItem from "./components/RoomItem.vue";
import DefaultLayout from "@/components/layout/DefaultLayout";
import SpHeader from "@/components/globalHeader/SpHeader.vue";
import DrawingAppHeader from "./components/DrawingHeader.vue";

export default {
  computed: {
    isDrawingApp() {
      return Store.state.Login.isDrawingApp;
    },
  },
  data: () => {
    return {
      PAGE_TITLE: "チャット",
      user_id: null,
      rooms: [],
    };
  },
  components: {
    RoomItem,
    DefaultLayout,
    SpHeader,
    DrawingAppHeader,
  },
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };

    // 自分のidを取得
    this.$watch(
      () => Store.getters["Login/getUser"],
      (newValue) => {
        // console.log("User", newValue);
        if (newValue && "id" in newValue) {
          this.user_id = newValue.id;
          // 初期メッセージ取得
          this.init();
        }
      },
      {
        immediate: true,
      }
    );

    // メッセージ更新
    this.$watch(
      () => Store.getters["TextChat/getRooms"],
      (newValue) => {
        // console.log("getRooms", newValue);
        this.rooms = newValue.chatrooms;
      },
      { immediate: true, deep: true }
    );
  },
  methods: {
    async init() {
      const params = {
        user_id: this.user_id || 1,
      };
      await Store.dispatch("TextChat/rooms", params);
    },
    async post() {},
    onSelectRoom(room_id) {
      //TODO ユーザーの画像をロード
      // console.log(room_id);
      this.$router.push(`/chat/${room_id}/`);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.roomview__list {
  overflow: auto;
  height: 100%;
}
.chatList {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    text-align: left;
  }
}

.chatview {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.chatview__list {
  overflow-y: auto;
  flex: 1;
}
</style>
