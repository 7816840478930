<template>
  <div class="searchHeader">
    <div class="inputWord">
      <v-text-field
        v-model="searchParams.word"
        dense
        color="primary"
        :filled="true"
        placeholder="検索"
        @input="onChangeSearchParams"
      ></v-text-field>
    </div>
    <div class="checkBoxs">
      <v-layout wrap>
        <v-checkbox
          id="isName"
          label="名前"
          v-model="searchParams.isName"
          @change="onChangeCheckbox"
        ></v-checkbox>
      </v-layout>
      <v-layout wrap>
        <v-checkbox
          id="isCompanyName"
          label="会社名"
          v-model="searchParams.isCompanyName"
          @change="onChangeCheckbox"
        ></v-checkbox>
      </v-layout>
      <v-layout wrap>
        <v-checkbox
          id="isWorkName"
          label="作業名"
          v-model="searchParams.isWorkName"
          @change="onChangeCheckbox"
        ></v-checkbox>
      </v-layout>
      <v-btn class="mx-2" color="primary" depressed @click="onSearch">
        検索
      </v-btn>
    </div>
  </div>
</template>
<script>
import InputText from "../../elements/InputText.vue";
import Checkbox from "../../elements/Checkbox.vue";
export default {
  data: () => {
    return {
      searchParams: {
        word: "",
        isName: false,
        isCompanyName: false,
        isWorkName: false,
      },
    };
  },
  components: {
    InputText,
    Checkbox,
  },
  methods: {
    onSearch() {},
    onChangeCheckbox() {},
    onChangeSearchParams() {
      console.log("param", this.searchParams.word);
    },
  },
};
</script>
<style lang="scss" scoped>
.searchHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.inputWord {
  width: 160px;
  height: 48px;
}
.checkBoxs {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 48px;
}
</style>
