import Vue from "vue";
import Vuex from "vuex";
import { siteportal } from "../../../api/modules/siteportal";
Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = siteportal;

export const SitePortal = {
  namespaced: true,

  state: {
    data: [],
    displayConfig: [],
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },

    SET_DISPLAYCONFIG(state, payload) {
      state.displayConfig = payload;
    },

    RESET_DATA(state) {
      state.data = [];
    },
  },

  actions: {
    async get({ commit }, payload) {
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_DATA", entries);
      return response;
    },

    async post(_context, payload) {
      return await ENTITY.post(payload);
    },

    async update(_context, payload) {
      return await ENTITY.put(payload);
    },

    async delete(_context, payload) {
      return await ENTITY.delete(payload);
    },

    async resetData({ commit }) {
      commit("RESET_DATA");
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },

    getDisplayConfig: (state) => {
      return state.displayConfig;
    },
  },
};
