<template>
  <div ref="self" class="box">
    <!-- ログイン済み -->
    <!--    v-if="item.invite_status === INVITE_STATUS.LOGINED.id"-->
    <v-card
      :class="getClassBox"
      :id="`box_${item.id}`"
      @click="
        $emit('onClickBox', {
          companyId: item.company_id,
          companyName: item.company_name,
          chartId: item.id,
          state_handling_flag: item.state_handling_flag,
          item: item,
        })
      "
    >

      <header class="box-header">
        <div>
          <p class="work_group_name">
            {{ item.field_tree_administrator_name || item.field_tree_group_name }}
          </p>
          <!-- 会社名 -->
          <p class="company_name" v-if="item.invite_status != 0">{{ convertCompanyName(item) }}</p>
          <p class="company_name" v-if="item.invite_status == 0">{{ item.invite_email }}</p>
        </div>
        <!-- 3点リーダー -->
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-show="!checkFieldTreeDate" color="primary" icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <!-- ドロップダウンメニュー -->
          <v-list>
            <v-list-item
              v-for="(menu, index) in menuItems"
              :key="index"
              @click.prevent="
                $emit('onClickDropdownMenu', {
                  menu_id: menu.id,
                  current_company: item,
                })
              "
            >
              <v-list-item-title>{{ menu.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </header>
      <div class="box-invite-0" v-if="item.invite_status == 0">招 待 中</div>
      <div class="box-body" v-if="item.invite_status != 0">
        <div>
          <!-- 期間 -->
          <p v-if="!isOwner" class="period">
            <span
              >{{ item.groundbreaking_date }}
              {{
                item.groundbreaking_date && item.complete_schedule_date
                  ? "～"
                  : ""
              }}
              {{ item.complete_schedule_date }}</span
            >
          </p>
          <!-- 統括安全衛生責任者 -->
          <div v-if="isOwner" class="label">統括安全衛生責任者</div>
          <p class="sub-content" v-if="isOwner">
            {{ item.chairman_name ? item.chairman_name : " " }}
          </p>
          <!-- 監督員名 -->
          <div v-if="isOwner" class="label">監督員</div>
          <p class="sub-content" v-if="isOwner">
            {{ item.supervisor_name ? item.supervisor_name : " " }}
          </p>
          <!-- 安全衛生責任者名-->
          <div v-if="!isOwner" class="label">安全衛生責任者</div>
          <p class="sub-content" v-if="!isOwner">
            {{
              item.health_safety_manager_name
                ? item.health_safety_manager_name
                : " "
            }}
          </p>
          <!-- 監理技術者・主任技術者 -->
          <div v-if="isOwner" class="label">監理技術者・主任技術者</div>
          <div v-if="!isOwner" class="label">主任技術者</div>
          <p class="sub-content">
            {{ item.chief_engineer_name ? item.chief_engineer_name : " " }}
          </p>
          <!-- 専門技術者名 -->
          <div v-if="isOwner" class="label">専門技術者</div>
          <p class="sub-content" v-if="isOwner">
            {{ item.technician_name ? item.technician_name : " " }}
          </p>
        </div>
        <!-- 作業員/現場監督 -->
        <div class="members">
          <v-btn
            color="primary"
            icon
            @click.prevent="
              $emit('onClickMembers', {
                companyId: item.company_id,
                companyName: item.company_name,
                chartId: item.id,
                state_handling_flag: item.state_handling_flag,
                item: item,
              })
            "
          >
            <v-icon>mdi-account</v-icon>
            <p>{{ item.member_count }}</p>
          </v-btn>
        </div>
      </div>
    </v-card>
    <div class="line" :style="lineStyle"></div>
  </div>
</template>
<script>
import {
  INVITE_STATUS,
  MENU_ITEMS,
  COMPANY_LEVEL,
  STATE_HANDLING_FLAG,
  MENU_ITEMS_INVITEING
} from "@/constants/PORTAL_CHART.js";

export default {
  data: () => {
    return {
      lineRect: { x: null, y: null, width: null, height: null },
      scrollY: 0
    };
  },
  props: {
    item: {
      type: Object,
    },
    level: {
      type: Number,
      default: 0,
    },
    parentId: {
      type: Number,
    },
    // same area index
    areaIndex: {
      type: Number,
    },
    checkFieldTreeDate: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.scrollY = document.getElementById("portal-chart").scrollTop;
    this.$watch(
      () => [this.parentId, this.areaIndex],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.setLine();
        }
      },
      {
        immediate: true,
      }
    );
  },
  computed: {
    INVITE_STATUS() {
      return INVITE_STATUS;
    },
    lineStyle() {
      const rect = { ...this.lineRect };
      return `left:${rect.x}px;top:${rect.y + this.scrollY}px;width:${
        rect.width
      }px;height:${rect.height}px`;
    },
    menuItems() {
      let _MENU_ITEMS = { ...MENU_ITEMS };
      //元請けには解除は表示しない
      // TODO ログインユーザーの所属する現場会社の直下の会社だけ解除(MENU_ITEMS.REMOVE)を表示する
      if (this.item.invite_status == 0) {
        _MENU_ITEMS = { ...MENU_ITEMS_INVITEING };
      } else {
        if (this.item.state_handling_flag == STATE_HANDLING_FLAG.WORKING || this.level === COMPANY_LEVEL.OWNER) {
          delete _MENU_ITEMS.RESTART_WORK;
          if (this.level === COMPANY_LEVEL.OWNER) {
            delete _MENU_ITEMS.REMOVE;
            delete _MENU_ITEMS.END_WORK;
          } else if (this.level === COMPANY_LEVEL.LATEST) {
            delete _MENU_ITEMS.INVITE;
          }
        } else {
          delete _MENU_ITEMS.INVITE;
          delete _MENU_ITEMS.REMOVE;
          delete _MENU_ITEMS.END_WORK;
        }
        if (this.level !== COMPANY_LEVEL.RELATED) {
          delete _MENU_ITEMS?.MANAGE_USER;
          delete _MENU_ITEMS?.GROUP_WORK;
        }
      }
      return Object.keys(_MENU_ITEMS).map((key) => _MENU_ITEMS[key]);
    },

    /**
     * 元請け判定
     */
    isOwner() {
      return this.level === COMPANY_LEVEL.OWNER;
    },
    getClassBox() {
      if(this.item.state_handling_flag == STATE_HANDLING_FLAG.WORKING || this.level === COMPANY_LEVEL.OWNER) {
        return "inner";
      } else {
        return "inner back-ground-color-box";
      }
    }
  },
  methods: {
    /**
     * 線を氷河
     */
    setLine() {
      const self = this.$refs.self;
      const parent = document.getElementById(`box_${this.parentId}`);
      if (self && parent) {
        const selfRect = self.getBoundingClientRect();
        const parentRect = parent.getBoundingClientRect();
        const lineRect = {
          x: parentRect.x + parentRect.width + 16,
          y: parentRect.y + parentRect.height / 2 - 24,  // reduce margin
          width: selfRect.x - (parentRect.x + parentRect.width) - 16,
          height: selfRect.y - parentRect.y,
        };
        if (this.areaIndex === 0) {
          lineRect.x = lineRect.x - 16;
          lineRect.width = lineRect.width + 16;
        }
        this.lineRect = lineRect;
      }
    },

    convertCompanyName(item) {
      let rs = item.company_name;
      if (item.company_branch_name) {
        rs += "（" + item.company_branch_name + "）";
      }
      return rs;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.box {
  .inner {
    height: auto;
    width: 220px;
    margin-top: 24px;
    background-color: white;
    color: black;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  }
  .line {
    position: absolute;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
  }
  p {
    white-space: pre;
    padding: 2px 0;
    margin: 0;
  }

  .company_name {
    font-size: 14px;
    color: $color_text_main;
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .work_group_name {
    font-size: 9px;
    color: $color_text_sub;
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sub-content {
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.box-header {
  display: flex;
  justify-content: space-between;
}

.box-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-left: 4px solid $color_primary_sub;
  padding-left: 8px;

  .period {
    font-size: 10px;
    color: $color_text_sub;
  }

  .label {
    font-size: 8px;
    color: $color_primary;
  }
  .value {
    font-size: 10px;
    color: $color_text_main;
  }
}
.box-invite-0 {
  min-height: 100px;
  line-height: 75px;
  text-align: center;
  color: #1b9c4f;
}
.back-ground-color-box {
  background-color: rgba(0, 0, 0, 0.12) !important;
}
</style>
