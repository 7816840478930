const MINS_ON_HOUR = ["00", "15", "30", "45"];
const getProhibitTimes = (isEnd) => {
  let prohibitTimes = [];
  let i = 0;
  while (i <= 47) {
    for (let m of MINS_ON_HOUR) {
      let hour = i < 10 ? "0" + i : i;
      let element = {
        id: hour + ":" + m,
        name: ((i % 24) < 10 ? (i == 24 && m == '00' ? 24 : ("0" + (i % 24))) : (i % 24)) + ":" + m,
      };
      prohibitTimes.push(element);
    }
    i++;
  }
  if (isEnd) {
    prohibitTimes.push({ id: "48:00", name: "24:00" });
  }
  return prohibitTimes;
};

export const cmn_mst_time = getProhibitTimes(true);

/**
 * 占有/スポット
 */
export const SCHEDULE_OCCUPANCY = {
  OCCUPANCY: { id: 1, name: "占有" },
  SPOT: { id: 2, name: "スポット" },
};

/**
 * 承認済/未承認
 */
export const SCHDULE_APPROVE = {
  APPROVED: { id: 1, name: "承認済" },
  REJECT: { id: 0, name: "未承認" },
};

export const DIALOG_TEXT = {
  REJECT_TITLE: "承認解除確認",
  REJECT_TEXT1: "選択した予定の承認を解除します。",
  REJECT_TEXT2: "承認を解除してもよろしいですか。",
  REJECT_BTN_SUBMIT: "解除",
  REJECT_ERROR_BTN_SUBMIT: "OK",
  REJECT_ERROR_TEXT1: "選択したデータは全て承認されていないため、承認解除出来ません。",
  APPROVE_TITLE: "承認確認",
  APPROVE_TEXT1: "選択した予定を承認します。",
  APPROVE_TEXT2: "承認してもよろしいですか。",
  APPROVE_BTN_SUBMIT: "承認",
  APPROVE_ERROR_TEXT1: "選択された作業予定は全て承認済みです。",
  DELETE_TITLE: "削除確認",
  DELETE_ERROR_TEXT1: "選択された作業予定は全て承認済みです。",
  DELETE_ERROR_TEXT2: "削除できません。",
  DELETE_TEXT1:
    "選択したデータを削除します。/未承認のデータのみ削除できます。",
  DELETE_TEXT2:
    "この処理を元に戻すことはできません。/本当に削除してもよろしいですか？",
  DELETE_BTN_SUBMIT: "削除",
  ERROR_BTN: "OK",
};

/**
 * テーブルのタブ
 */
export const SCHEDULE_TABLE_TAB_ITEMS = {
  ALL: { id: 1, name: "全体" },
  WORK_GROUP: { id: 2, name: "作業グループ" },
};

export const CONFIRM_DIALOG_STATUS = {
  APPROVED: 1,
  REJECT: 0,
  DELETE: 3,
  DELETE_ERROR: 4,
};

export const INIT_DATA = {
    use_date_from: null,
    use_date_to: null,
    field_construction_id: null,
    field_tree_id: null,
    foreman_user_id: null,
    work_hour_zone: null,
    crane_id: null,
    group_id: null,
    field_item_tree_id: null,
    work_details: null,
    expected_danger: null,
    status_code: 1,
    work_content_id: null,
    skill_test_occupation: null,
    approval_user_name_sei: null,
    approval_user_name_mei: null,
    approval_date: null,
    owner_flag: 1,
    commodity_type: null,
    device_work_classifications: [
      {
        cmn_mst_work_classification_id: 1,
      },
    ],
    device_safety_instructions: [
      {
        cmn_mst_safety_instruction_id: null,
      },
    ],
    device_usage_times: [
      {
        usage_start_time: null,
        usage_end_time: null,
      },
    ],
    slinger_signallers: [
      {
        slinging_worker_id: null,
        signal_worker_id: null,
      },
    ],
    device_users: [
      {
        field_director_id: null,
      }
    ],
    device_licences: [
      {
        id: null,
        cmn_mst_licence_id: null,
      },
    ],
    device_sp_trainings: [
      {
        id: null,
        cmn_mst_sp_training_id: null,
      }
    ],
};

export const INIT_DATA_OBAYASHI = {
  use_date_from: null,
  use_date_to: null,
  field_construction_id: null,
  field_tree_id_1: null,
  job_id: null,
  field_tree_user_id: null,
  field_tree_user_tel: null,
  suspended_load: null,
  // gate_passages: {
  //   gate_id: null,
  //   cmn_mst_vehicle_type_id: null,
  //   truck_count: null,
  //   gate_status_code: 1,
  //   shipping_company: null,
  //   shipping_company_tel: null,
  //   remarks: null,
  // },
  commodity_type: null,
  gate_id: null,
  gate_cmn_mst_vehicle_type_id: null,
  gate_truck_count: null,
  gate_status_code: null,
  gate_shipping_company: null,
  gate_shipping_company_tel: null,
  gate_remarks: null,
  crane_id: null,
  field_item_tree_id: null,
  foreman_user_id: null,
  work_details: null,
  expected_danger: null,
  status_code: 1,
  owner_flag: 1,
  device_work_classifications: [
    {
      cmn_mst_work_classification_id: null,
    },
  ],
  device_safety_instructions: [
    {
      cmn_mst_safety_instruction_id: null,
    },
  ],
  device_usage_times: [
    {
      usage_start_time: null,
      usage_end_time: null,
    },
  ],
  slinger_signallers: [
    {
      slinging_worker_id: null,
      signal_worker_id: null,
    },
  ],
  device_users: [
    {
      field_director_id: null,
    }
  ],
  company_spot: '',
  is_company_spot: null
};

export const DEVICE_EMPTY = [
  {
    device_id: null,
    device_name: null,
    device_description: [],
    total_children_item: null,
    schedule_summary: [],
    children: []
  }
];

export const OWNER_FLAG = {
  OWNER: 1,
  NONE: 0,
};

export const STATUS_CODE_ARRAY = [
  { id: 1, name: "搬入" },
  { id: 2, name: "搬出" },
  { id: 3, name: "占有" },
];

export const HISTORY_TYPE = {
  SUSPENDED_LOAD: 0,
  WORK_DETAILS: 1,
  COMMODITY_TYPE: 2
};