<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="3" md="3">
              <Label label="工事" :editable="editable">
                <Select
                  name="construction_name"
                  :values="dataConstructions"
                  :items="CONSTRUCTION_ARRAY"
                  :editable="true"
                  @onInput="onChangeConstruction"
                />
              </Label>
            </v-col>
          </v-row>
          <!-- readonly -->
          <div v-if="!editable">
            <LocationTree
              v-if="locations && locations.length"
              :editable="false"
              name="location"
              :locations="locations"
              :depth="0"
            />
          </div>
          <!-- 編集用 -->
          <div v-if="editable">
            <ValidationObserver>
              <LocationTree
                v-if="locations && locations.length"
                :editable="editable"
                name="location"
                :locations="locations"
                :depth="0"
                :push="push"
                :update="update"
                :remove="remove"
              />
            </ValidationObserver>
          </div>
          <v-btn  v-if="editable" @click="addRootLocation" color="primary" :disabled="!editable || CONSTRUCTION_ARRAY.length == 0"
          >追加
            <v-icon>mdi-plus</v-icon>
          </v-btn
          >
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import LocationTree from "./components/LocationTree.vue";
import _ from "lodash";
import Select from "@/components/forms/elements/Select.vue";
import Label from "@/components/forms/elements/Label";

// init data
// 作業場所
const LOCATIONS = [];

export default {
  data: () => {
    return {
      CONSTRUCTION_ARRAY: [],
      locations: LOCATIONS,
      formValues: {},
      dataConstructions: {
        construction_name: null
      },
      constructId: null,
    };
  },

  components: {
    ValidationObserver,
    LocationTree,
    Label,
    Select,
  },

  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    locationSelect: Array,
    idSelect : Number,
  },

  async mounted() {
    // propで渡されるformデータをwatch
    //「locationsに変更があるときだけ実行する」とすると、他のタブで変更があった場合、その変更内容がformValuesに反映されずに、
    // locationsに変更があると、他のタブの変更内容が消えてしまうため、変更を比較するコードを削除する。
    this.$watch(
      () => this.item,
      (newValue) => {
        this.initData(newValue);
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.item.field_constructions,
      (newValue) => {
        if (Array.isArray(newValue) && newValue.length > 0) {
          let _selectedRollItems = [];
          newValue?.forEach(item => {
            if (item.construction_name) _selectedRollItems.push({
              id: item.id ? item.id : item.construction_name,
              name: item.construction_name,
              key: item.key
            });
          });
          this.CONSTRUCTION_ARRAY = [..._selectedRollItems];
          if (this.CONSTRUCTION_ARRAY.length > 0 && !this.dataConstructions["construction_name"]) {
            let dataConstructions = { ...this.dataConstructions };
            dataConstructions["construction_name"] = this.CONSTRUCTION_ARRAY[0].id;
            this.dataConstructions = dataConstructions;
            this.locations = this.getDataLocation(newValue);
            if (!this.idSelect) {
              this.onChangeConstruction({
                name: 'construction_name',
                value: this.CONSTRUCTION_ARRAY[0].id
              });
              this.$emit('filter-select', this.locations, this.CONSTRUCTION_ARRAY[0].id);
              this.constructId = this.CONSTRUCTION_ARRAY[0].id;
            }
          }
        }
      },
      {
        immediate: true,
        deep: true,
      },
    );

    this.$watch(
      () => this.idSelect,
      (value) => {
        let searchParams = { ...this.dataConstructions };
        let result = this.CONSTRUCTION_ARRAY.find(item => item.id == value);
        searchParams['construction_name'] = value;
        searchParams['rollName'] = result ? result.name : '';
        searchParams['key'] = result ? result.key : '';
        this.dataConstructions =searchParams;
        this.constructId = this.idSelect;
      },
      {
        immediate: true,
        deep: true,
      },
    );

    this.$watch(
        () => this.locationSelect,
        (value) => {
          this.locations = value;
        },
        {
          immediate: true,
          deep: true,
        },
    );
  },

  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    // propsで渡されるformデータをcomponent用にset
    initData(data) {
      const formValues = data;
      this.formValues = _.cloneDeep(formValues);
      this.locations = this.getDataLocation(data.field_constructions);
    },

    // 最上層の場所を追加
    addRootLocation() {
      const locations = _.cloneDeep(this.locations);
      locations.push({
        item_name: "",
        children: [],
        field_images: [],
      });
      this.locations = _.cloneDeep(locations);
    },

    // formデータ更新
    formUpdate(newValue) {
      const formValues = _.cloneDeep(this.formValues);
      formValues.field_constructions.forEach(element => {
        if (element.id) {
          if (element.id == this.dataConstructions["construction_name"]) {
            element["field_item_trees"] = newValue;
          }
        } else {
          if (element.construction_name == this.dataConstructions["construction_name"]) {
            element["field_item_trees"] = newValue;
          }
        }
      });
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    /**
     * 更新
     */
    update({ path, name }) {
      const pathArray = path.split("_");
      const req = (arr, locations) => {
        const tindex = arr.shift();
        if (arr.length) {
          return req(arr, locations[+tindex].children);
        } else {
          locations[+tindex].item_name = name;
          return true;
        }
      };
      if (req(pathArray, this.locations)) {
        this.formUpdate(this.locations);
        if (this.constructId) {
          this.$emit('filter-select', this.locations, this.constructId);
        }
      }
    },
    /**
     * 追加
     */
    push({ path }) {
      const pathArray = path.split("_");
      const req = (arr, locations) => {
        const tindex = arr.shift();
        if (arr.length) {
          return req(arr, locations[+tindex].children);
        } else {
          locations[+tindex].children = locations[+tindex].children ? locations[+tindex].children : [];
          locations[+tindex].children.push({
            item_name: "",
            children: [],
            field_images: [],
          });
          return true;
        }
      };
      let _temp = _.cloneDeep(this.locations);
      if (req(pathArray, _temp)) {
        this.formUpdate(_temp);
      }
    },
    /**
     *
     */
    remove({ path, index }) {
      const pathArray = path.split("_");
      const req = (arr, locations) => {
        const tindex = arr.shift();
        if (arr.length) {
          return req(arr, locations[+tindex].children);
        } else {
          locations.splice(index, 1);
          return true;
        }
      };
      if (req(pathArray, this.locations)) {
        this.formUpdate(this.locations);
      }
    },
    onChangeConstruction({ name, value }){
      this.checkEmptyLocations();
      this.dataConstructions[name] = value;
      this.locations = this.getDataLocation(this.formValues.field_constructions);
      this.$emit('filter-select', this.locations, value);
      this.constructId = value;
    },
    getDataLocation(construction){
      let rs = [];
      construction.forEach(e => {
        if (e.id) {
          if (e.id == this.dataConstructions["construction_name"]) {
            rs = e.field_item_trees || [];
            return rs;
          }
        } else {
          if (e.construction_name == this.dataConstructions["construction_name"]) {
            rs = e.field_item_trees || [];
            return rs;
          }
        }
      });
      return rs;
    },
    checkEmptyLocations() {
      const req = (locations) => {
        for (let i = 0; i < locations.length; i++) {
          if (locations[i].item_name == "") {
            locations.splice(i, 1);
            i--;
          } else {
            if (locations[i].children && locations[i].children.length > 0) {
              req(locations[i].children);
            }
          }
        }
      };
      const formValues = _.cloneDeep(this.formValues);
      formValues.field_constructions.forEach(element => {
        if (element.id) {
          if (element.id == this.dataConstructions["construction_name"] && Array.isArray(element.field_item_trees)) {
            req(element.field_item_trees);
          }
        } else {
          if (element.construction_name == this.dataConstructions["construction_name"] && Array.isArray(element.field_item_trees)) {
            req(element.field_item_trees);
          }
        }
      });
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
</style>
