<template>
  <v-card class="dialog">
    <v-card-title>
      <div style="color:red" class="title">{{ dialogText.title }}</div>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div class="text">{{ dialogText.text1 }}</div>
      </div>
    </v-card-text>
    <v-card-text v-if="dialogText.text2" class="body">
      <div class="text-wrap">
        <div class="text left-text-confirm">{{ dialogText.text2 }}</div>
      </div>
    </v-card-text>
    <v-card-text v-if="dialogText.text3" class="body">
      <div class="text-wrap">
        <div class="text left-text-confirm">{{ dialogText.text3 }}</div>
      </div>
    </v-card-text>
    <v-card-text v-if="dialogText.text4" class="body">
      <div class="text-wrap">
        <div class="text left-text-confirm">{{ dialogText.text4 }}</div>
      </div>
    </v-card-text>
    <v-card-text class="mess-reject">
      <Label label="メッセージ" :editable="true">
        <InputTextArea
          name="message_reject"
          :editable="true"
          :values="valueMessage"
          @onInput="onInput"
          validation_label="メッセージ"
          validation_rules="required|max:500"
        />
      </Label>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="warning"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn depressed :disabled="!valid || disabled" class="btn" color="warning" @click="handleConfirm()">
        {{ dialogText.btnSubmitText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON";
import InputTextArea from "@/components/forms/elements/InputTextArea";
import Label from "@/components/forms/elements/Label";
export default {
  data() {
    return {
      disabled: false,
      timeout: null,
      message: null,
      valueMessage: {
        message_reject: "",
      },
      flgEditMessage: 0,
    }
  },
  components: {InputTextArea, Label},
  props: {
    dialogText: Object,
    warning: {
      type: Boolean,
      default: false,
    },
    valid: Boolean,
    messageReject: String,
    statusCodeReject: Number,
  },
  mounted() {
    if (this.statusCodeReject === 2) {
      this.valueMessage['message_reject'] = this.messageReject;
      this.flgEditMessage = 1;
    }
    this.$watch(
      () => this.valueMessage,
      (message) => {
        this.message = message?.message_reject;
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  methods: {
    handleConfirm() {
      this.disabled = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.disabled = false
      }, SUBMIT_DELAY_TIMEOUT);
      this.$emit('yes', this.message, this.flgEditMessage);
    },
    onInput({name, value}) {
      let valueMessage = {...this.valueMessage};
      valueMessage[name] = value;
      this.valueMessage = valueMessage;
    },
  },
  /**
   * Important: clear timeout
   */
  beforeDestroy () {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout)
  }
};
</script>
<style lang="css" scoped>
::v-deep .label {
  color: rgba(0, 0, 0, 0.6) !important;
}
::v-deep .mess-reject .v-input {
  color: rgba(0, 0, 0, 0.6) !important;
  caret-color: rgba(0, 0, 0, 0.6) !important;
}
</style>
