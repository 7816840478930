<template>
  <FormDialog>
    <template #header>
      <!-- 
        (共通)
        ヘッダーは全て共通
       -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!editable"
          depressed
          small
          :disabled="!isLoadedData"
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          small
          outlined
          color="primary"
          class="mr-5"
          @click="onStopEdit"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          small
          :disabled="!valid || isSubmitted"
          color="primary"
          @click="onSubmit"
        >
          保存
        </v-btn>
        <v-btn icon @click="onClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in COMPANY_INTO_TAB" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!-- 
        (共通)
        全てのフォームのバリデーションの監視を行う
       -->
      <ValidationObserver ref="observer" v-slot="observer">
        <!-- フォームtab -->
        <v-tabs-items v-model="tab">
          <!-- 基本情報 -->
          <v-tab-item>
            <CompanyFormPage1
              :editable="editable"
              :item="editedItem.company"
              :mainHeight="params.mainHeight"
              :errors="errors"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <!-- 安全ルール -->
          <v-tab-item>
            <SafetyRulesForm
              :editable="editable"
              :item="editedItem"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <!-- 保険・退職金共済 -->
          <v-tab-item>
            <CompanyFormPage2
              :editable="editable"
              :item="editedItem.company_branch_insurances"
              :mainHeight="params.mainHeight"
              :errors="errors"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <!-- 外国人受入企業に関する事項 -->
          <v-tab-item>
            <CompanyFormPage3
              :editable="editable"
              :item="editedItem.company_branch_foreigners"
              :mainHeight="params.mainHeight"
              :errors="errors"
              @formUpdate="formUpdate"
            />
          </v-tab-item>

          <!-- 女性及び年少者の就業制限  -->
          <v-tab-item>
            <CompanyFormPage4
              :editable="editable"
              :item="editedItem.company_employment_restrictions"
              :mainHeight="params.mainHeight"
              :errors="errors"
              @formUpdate="formUpdate"
            />
          </v-tab-item>

          <!-- 協力会社提出書類  -->
          <v-tab-item>
            <CompanyFormPage5
              :editable="editable"
              :item="editedItem"
              :mainHeight="params.mainHeight"
              :errors="errors"
              @formUpdate="formUpdate"
            />
          </v-tab-item>

        </v-tabs-items>
        <!-- 
          (共通)
          ValidationObserverのスロットプロパティを受け取って
          updateValidateメソッドを実行して、
          バリデーション結果をスクリプト側に渡してます
        -->
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="closePopup"
          @yes="clickClosePopup"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { Store } from "@/store/Store.js";
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "../elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import CompanyFormPage1 from "@/components/forms/company/CompanyFormPage1.vue";
import CompanyFormPage2 from "@/components/forms/company/CompanyFormPage2.vue";
import CompanyFormPage3 from "@/components/forms/company/CompanyFormPage3.vue";
import CompanyFormPage4 from "@/components/forms/company/CompanyFormPage4.vue";
import CompanyFormPage5 from "@/components/forms/company/CompanyFormPage5.vue";
import SafetyRulesForm from "@/components/forms/company/SafetyRulesForm.vue";
import { COMPANY_INTO_TAB, COMPANY_INITAL_ITEM } from "@/constants/COMPANY_PORTAL";
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON.js";

/**
 * (共通)
 * タイトル
 */
const FORM_TITLE = "自社情報";

//ストア
const STORE_COMPANY = "CompanyPortal";

export default {
  data: () => {
    return {
      COMPANY_INTO_TAB,
      valid: false, //バリデーション結果
      // tab初期化
      tab: null,
      editable: false,
      isBack: false,
      isLoadedData: false,
      isShowConfirmDialog: false,
      editedItem: {...COMPANY_INITAL_ITEM},
      beforeUpdateItem: {},
      errors: {},
      isSubmitted: false,
      timeout: null
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    CompanyFormPage1,
    CompanyFormPage2,
    CompanyFormPage3,
    CompanyFormPage4,
    CompanyFormPage5,
    SafetyRulesForm,
  },
  props: {
    companyId: Number,
  },
  mounted() {
    
    this.getItems();

    /**
     * (共通)
     * 新規作成を監視
     */
    this.$watch(
      () => [
        Store.getters[`${STORE_COMPANY}/getCompany`],
        Store.getters[`${STORE_COMPANY}/getCompanyBranchInsurances`],
        Store.getters[`${STORE_COMPANY}/getCompanyBranchForeigners`],
        Store.getters[`${STORE_COMPANY}/getCompanyEmploymentRestrictions`],
        Store.getters[`${STORE_COMPANY}/getCompanySafetyRules`],
        Store.getters[`${STORE_COMPANY}/getCompanyDocuments`],
      ],
      (data) => {
        // mode view detail
        this.editable = false;
        // company info
        let editedItem = {...this.editedItem};
        editedItem['company'] = data[0];
        editedItem['company_branch_insurances'] = data[1];
        editedItem['company_branch_foreigners'] = data[2];
        editedItem['company_employment_restrictions'] = data[3];
        editedItem['company_safety_rules'] = data[4];
        editedItem['company_documents'] = data[5];
        this.editedItem = {...editedItem};
        this.beforeUpdateItem = {...editedItem};
        if (data[0].name) {
          this.isLoadedData = true;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    // 新規or編集でタイトル変更
    formtitle() {
      return FORM_TITLE;
    },
  },
  methods: {

    // データ取得
    async getItems() {
      await Store.dispatch(`${STORE_COMPANY}/get`, { id: this.companyId });
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      if (this.editable) {
        this.isShowConfirmDialog = true;
      } else {
        this.editable = true;
      }
    },
    onStopEdit() {
      this.isShowConfirmDialog = true;
      this.isBack = true;
    },


    /**
     * (共通)
     * フォームの変更を受け取る
     */
    formUpdate(params) {
      this.editedItem = { ...this.editedItem, ...params };
    },

    /**
     * (共通)
     * 新規登録 / 更新
     * - idがある場合は更新
     */
    async onSubmit() {
      this.isSubmitted = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isSubmitted = false
      }, SUBMIT_DELAY_TIMEOUT);
      const editedItem  = {...this.editedItem};
      editedItem.company.id = JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user.company_id;
      this.editedItem = editedItem;
      const result = await Store.dispatch(`${STORE_COMPANY}/update`, this.editedItem);
      if (result.hasError) {
        return;
      } else {
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid, errors }) {
      this.valid = valid;
      this.errors = errors;
    },
    closePopup() {
      this.isShowConfirmDialog = false
      this.isBack = false;
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    clickClosePopup() {
      if (this.isBack) {
        this.editable = false;
        this.editedItem = {...this.beforeUpdateItem};
      } else {
        this.$emit("cancel");
      }
      this.isBack = false;
      this.isShowConfirmDialog = false;
    },
    onClose() {
      if (this.editable) {
        this.isShowConfirmDialog = true;
        this.isBack = false;
      } else {
        this.clickClosePopup();
      }
    },
  },
  /**
   * Important: clear timeout
   */
  beforeDestroy () {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout)
  }
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
