<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row no-gutters>
          <v-col cols="6" v-if="isShowOccupation">
              <v-list-item>
              <v-list-item-content>
                <!-- 作業分類職種 -->
                <Label label="作業分類職種" :editable="editable" :required="isRequiredOccupation">
                  <Select
                    name="occupation"
                    :values="item"
                    :items="WORK_CONTENT_TYPE"
                    :editable="editable"
                    validation_label="作業分類職種"
                    :validation_rules="isRequiredOccupation?'required':''"
                    @onInput="onInput({ value: $event, index})"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="10" md="10" v-if="isShowClassification">
            <v-list-item>
              <v-list-item-content>
                <!-- 作業内容 -->
                <Label :label="editable ? '作業内容' : '定義・作業内容'" :editable="editable" :required="isRequiredClassification">
                  <Select
                    name="cmn_mst_work_classification_id"
                    :values="item"
                    :editable="editable"
                    :items="filterSubCatergory(item)"
                    item_text="work_content"
                    validation_label="作業内容"
                    :validation_rules="isRequiredClassification?'required':''"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col
            v-if="index > 0 && editable && isShowClassification"
            cols="12"
            sm="2"
            md="2"
            class="pt-0 pb-0"
          >
            <v-btn class="mt-8" icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn v-if="editable && isShowClassification" class="ml-4" color="primary" @click="addEmpty" depressed small>追加</v-btn>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "@/components/forms/elements/Label";
import Select from "@/components/forms/elements/Select";
import { Store } from "@/store/Store.js";

//初期値を設定します
const INITIAL_VALUES = {
  cmn_mst_work_classification_id: 1,
};

export default {
  data: () => {
    return {
      items: [],
      itemsWorkClassification: [],
      WORK_CLASSIFICATION : Store.state.CmnMst.constants.entries.cmn_mst_work_classifications,
      WORK_CLASSIFICATION_OCCUPATION : Store.state.CmnMst.constants.entries.cmn_mst_work_classifications,
      WORK_CONTENT_TYPE : [],
    };
  },
  components: {
    Label,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    selectArrays: {
      type: Array,
    },
    isShowOccupation: {
      type: Boolean,
      default: true
    },
    isRequiredOccupation: {
      type: Boolean,
      default: false
    },
    isShowClassification: {
      type: Boolean,
      default: true
    },
    isRequiredClassification: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    this.getDataArray();

    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];  
        if (this.items?.length == 0) {
          this.addEmpty();
        }
        this.items?.forEach((_item) => {
            this.WORK_CLASSIFICATION.forEach(
              (element) => {
                if (_item.cmn_mst_work_classification_id == element.id) {
                  _item["occupation"] = element.occupation;
                }
              }
            );
          });
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      const itemEdited = { ...this.items[index] };
      const items = [...this.items];
      itemEdited[value.name] = value.value;
      if(value.name == "occupation") {
        itemEdited['cmn_mst_work_classification_id'] = this.filterSubCatergory(itemEdited)[0].id;
      }
      delete itemEdited['occupation'];
      items[index] = itemEdited;
      this.$emit("onInput", { name: this.name, value: items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      let _items = [...this.items];
      _items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    getDataArray() {
      let results = this.WORK_CLASSIFICATION_OCCUPATION.reduce((results, item) => {
        (results[item.occupation] = results[item.occupation] || []).push(item);
        return results;
      },[])
      this.WORK_CLASSIFICATION_OCCUPATION = results;
      for(let item in results) {
        this.WORK_CONTENT_TYPE.push({id: item, name: item});
      }
    },
    filterSubCatergory(item) {
      return this.WORK_CLASSIFICATION_OCCUPATION[item.occupation];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/forms.scss";
</style>