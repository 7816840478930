<template>
  <FormDialog>
    <template #header>
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          {{ formTitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editable"
          class="mr-5"
          depressed
          small
          outlined
          color="primary"
          @click="isShowConfirmDialog = true"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable && tab > 0 && isNewItem"
          depressed
          small
          :disabled="!valid || isSubmitted"
          color="primary"
          @click="onSubmit"
          :key="flagSubmit"
        >
          保存
        </v-btn>
        <v-btn
          v-if="editable && !isNewItem"
          depressed
          small
          :disabled="!valid || isSubmitted"
          color="primary"
          @click="onSubmit"
          :key="flagSubmit"
        >
          保存
        </v-btn>
        <v-btn
          v-if="editable && tab === 0 && isNewItem"
          depressed
          small
          color="primary"
          @click="onNextTab"
        >
          次へ
        </v-btn>
        <v-btn
          v-if="!editable && isShowBtnEdit && PERMISSION_EDIT"
          depressed
          small
          color="primary"
          @click="onEditable"
          data-testid="btn-cancel"
        >
          編集
        </v-btn>
        <v-btn icon @click="onClickBtnClose" data-testid="btn-close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab
              v-for="(form, key) in FORMS"
              :key="key"
            >
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <ValidationObserver ref="observer" v-slot="observer">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <ContactFormTab1
              :editable="editable"
              :item="formValues"
              :isNewItem="isNewItem"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
              @updateWorkPlace="updateWorkPlace"
              @updateTitlePage2="updateTitlePage2"
              @showLoading="showLoading"
            />
          </v-tab-item>
          <v-tab-item>
            <ContactFormTab2
              :editable="editable"
              :item="formValues"
              :isNewItem="isNewItem"
              :mainHeight="params.mainHeight"
              :workPlace="workPlace"
              :isShowMove="isShowMove"
              :disabledDoubleNext="disabledDoubleNext"
              :disabledDoublePrev="disabledDoublePrev"
              :recordId="recordId"
              :titlePage2="titlePage2"
              @formUpdate="formUpdate"
              @loading="setLoading"
            />
          </v-tab-item>
        </v-tabs-items>
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import { INITIAL_ITEM_CONTACT_FORM , FORM_CONTACT_TABS} from "@/constants/PATROL_RECORD";
import { Store } from "@/store/Store";
import _ from "lodash";
import ContactFormTab1 from "@/components/forms/schedule/patrols/PaltrolForm/ContactForm/ContactFormTab1";
import ContactFormTab2 from "@/components/forms/schedule/patrols/PaltrolForm/ContactForm/ContactFormTab2";

const TITLE = "連絡事項";
const STORE = "PatrolRecord";

export default {
  data: () => {
    return {
      FORMS: FORM_CONTACT_TABS,
      valid: false,
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      isClickCloseBtn: false,
      beforeUpdateItem: null,
      formValues: _.cloneDeep(INITIAL_ITEM_CONTACT_FORM),
      isShowBtnEdit : false,
      flagSubmit : false,
      workPlace: [],
      titlePage2: '共通連絡',
      PATROL_TYPE: [{ id: 2, type: "共通連絡" }, { id: 3, type: "安全巡視" }],
      isSubmitted: false,
    };
  },
  components: {
    ContactFormTab1,
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    ContactFormTab2,
  },
  props: {
    isNewItem: Boolean,
    construction_id : Number,
    recordId : Number,
    isShowMove: Boolean,
    disabledDoubleNext: Boolean,
    disabledDoublePrev: Boolean,
  },
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    if (!this.isNewItem) {
      this.tab = 1;
    }
    this.$watch(
      () => this.isNewItem,
      (data) => {
        if (data) {
          this.editable = true;
          const _formValues = { ...this.formValues };
          _formValues.safety_diaries.field_construction_id = this.construction_id;
          this.formValues = { ..._formValues };
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters[`${STORE}/getDetail`],
      (data) => {
        if (this.recordId) {
          this.formValues = _.cloneDeep(data);
          this.beforeUpdateItem = _.cloneDeep(this.formValues);
          this.isShowBtnEdit = data.safety_diary_comment_corrections[0].status_code !== 1 ;
        }
      },
      {
        immediate : true,
        deep: true
      }
    );
    this.$watch(
      () => this.formValues,
      (data) => {
        if (this.recordId) {
          let valueTypeId = data.safety_diaries.cmn_mst_safety_diary_type_id;
          this.titlePage2 = this.PATROL_TYPE.filter(e => e.id === valueTypeId)[0]?.type;
        }
      },
      {
        immediate : true,
        deep: true
      }
    );
  },
  computed: {
    formTitle() {
      return TITLE;
    },
    PERMISSION_EDIT() {
      let userLogin = Store.getters["Login/getUser"];
      let userRole = Store.getters["Report/getUserRole"];
      let detectUserId = this.formValues.safety_diaries.detect_user_id;
      if (Object.values(userRole?.nest_no).indexOf(0) > -1) return true;
      return (!this.isNewItem && userLogin.id == detectUserId);
    }
  },
  methods: {

    async getItems(id) {
      if (id) {
        await Store.dispatch(`${STORE}/getDetail`, id);
      }
    },

    formUpdate(params) {
      this.formValues = { ...this.formValues, ...params };
    },
    setLoading(flg) {
      this.editable = false;
      this.$emit('loading', flg);
    },
    onEditable() {
      this.editable = true;
    },
    showLoading(flg) {
      this.$emit('showLoading', flg);
    },

    async onSubmit() {
      this.isSubmitted = true;
      const hasId = "id" in this.formValues.safety_diaries;
      let _formValues = _.cloneDeep(this.formValues);
      _formValues.safety_diaries["safety_form"] = 3;
      _formValues.safety_diary_comment_corrections.forEach(e => {
        e.safety_diary_images.forEach(e1 => {
          delete e1.check;
        });
      });
      const result = await Store.dispatch(hasId ? `PatrolRecord/update` : `PatrolRecord/post`, _formValues);
      if (result.hasError) {
        this.flagSubmit = !this.flagSubmit;
        this.isSubmitted = false;
        return;
      } else {
        if (hasId) {
          this.editable = false;
          this.getItems(this.recordId);
          this.$emit("actionSuccess", false);
        } else {
          this.$emit("actionSuccess", true);
        }
        Store.dispatch("Toast/show", {
          status: 200,
          message: hasId ? "更新しました" : "登録しました"
        });
      }
      this.isSubmitted = false;
    },

    updateValidate({ valid }) {
      this.valid = valid;
    },

    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        this.formValues = _.cloneDeep(this.beforeUpdateItem);
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
    onNextTab() {
      this.tab = this.tab + 1;
    },
    updateWorkPlace(workPlace) {
      this.workPlace = workPlace;
    },
    updateTitlePage2(title) {
      this.titlePage2 = title;
    },
  },
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
