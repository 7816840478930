<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent :width="width" :max-width="maxWidth" content-class="vdialognew">
      <div v-if="dialog">
        <slot />
      </div>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean,
    },
    width: {
      type: String,
      defaultValue: "100%",
    },
    maxWidth: {
      type: String,
      defaultValue: "100%",
    },
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
::v-deep .v-dialog {
  overflow-y: hidden;
}
.vdialognew {
  overflow: hidden;
}
</style>