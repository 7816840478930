import Api, { Mock } from "../api";
import mock_gates from "./mock/gates.json";
//TODO: mock call api selectbox
import mock_gate_detail from "./mock/schedule_gate_detail.json";

const PATH = "gates";

const URL = `${process.env.VUE_APP_API_BASE_URL}/${PATH}`;
const GET_LIST_URL = `${URL}/list`;
const DELETE_URL = `${URL}/delete`;
const GET_DETAIL_GATE_URL = `${URL}/detail`;
const CREATE_GATE_URL = `${URL}/create`;
const UPDATE_GATE_URL = `${URL}/update`;
const GET_LIST_GATE_BY_FIELD_URL = `${URL}/list_gate_by_field`;

// .envのVUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  console.log("init Mock");
  Mock.onGet(GET_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_gates,
  });

  Mock.onGet(new RegExp(`${GET_DETAIL_GATE_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_gate_detail,
  });

  Mock.onPost(CREATE_GATE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(UPDATE_GATE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPut(URL).reply(200, {
    status_code: 200,
    message: "",
    contents: {},
  });
  Mock.onPost(DELETE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });

  Mock.onGet(new RegExp(`${GET_LIST_GATE_BY_FIELD_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {
      entries: [
        {
          id: 1,
          name: "北ゲート",
          height: 3,
          width: 12,
        },
        {
          id: 2,
          name: "南ゲート",
          height: 5,
          width: 10,
        },
      ],
    },
  });
}

export const gates = {
  get: (params) => {
    return Api.get(GET_LIST_URL, params);
  },

  getDetail: async (params) => {
    return Api.get(`${GET_DETAIL_GATE_URL}/${params}`);
  },
  post: (params) => {
    console.log("post:", params);
    return Api.post(CREATE_GATE_URL, params);
  },
  update: async (params) => {
    return Api.post(UPDATE_GATE_URL, params);
  },
  put: async (params) => {
    console.log("put:", params);
    return Api.put(URL, params);
  },
  delete: async (params) => {
    console.log("delete:", params);
    return Api.post(DELETE_URL, params);
  },
  getListGateByField: async (fieldId) => {
    return Api.get(`${GET_LIST_GATE_BY_FIELD_URL}/${fieldId}`);
  },
};
