import Api, { Mock } from "../api";

const PATH = "drawing";
const URL_LAYOUT = `${process.env.VUE_APP_API_BASE_URL}/${PATH}/layout`;
const URL_LAYOUT_UPDATE = `${process.env.VUE_APP_API_BASE_URL}/${PATH}/layout_update`;
const URL_LAYOUT_GET_IMAGE = `${process.env.VUE_APP_API_BASE_URL}/${PATH}/field_image`;
const URL_LIST_HISTORY_DATE = `${process.env.VUE_APP_API_BASE_URL}/${PATH}/get_list_history_date`;
const URL_SAVE_EXPORTED_FILE = `${process.env.VUE_APP_API_BASE_URL}/${PATH}/save_exported_file`;
// const URL_LAYOUT_GET_COMMUNICATION_IDENTITY = `${process.env.VUE_APP_API_BASE_URL}/${PATH}/communication_identity_get`;

// .envのVUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  console.log("init Mock");
  Mock.onGet(URL_LAYOUT).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });

  Mock.onPost(URL_LAYOUT_UPDATE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });

  Mock.onGet(URL_LAYOUT_GET_IMAGE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });

  // Mock.onGet(URL_LAYOUT_GET_COMMUNICATION_IDENTITY).reply(200, {
  //   status_code: 200,
  //   message: "",
  //   result: true,
  //   contents: {},
  // });
}

// api レスポンス
// https://shogatsu-works.slack.com/archives/C01UHLXG61Z/p1632215468058200?thread_ts=1629862654.001000&cid=C01UHLXG61Z

export const drawing = {
  get: (params) => {
    //!!drawing/layoutのリクエストにはparamsにobjectを入れる
    console.log("Api get ", { params: params });
    return Api.get(URL_LAYOUT, { params: params });
  },
  post: (params) => {
    console.log("post:", params);
    return Api.post(URL_LAYOUT_UPDATE, params);
  },
  getImage: (params) => {
    console.log("Api getImage ", { params: params });
    return Api.get(URL_LAYOUT_GET_IMAGE, { params: params });
  },
  getHistoryDates: (params) => {
    console.log("Api getHistoryDates ", { params: params });
    return Api.get(URL_LIST_HISTORY_DATE, { params: params });
  },
  saveExportedFile: (params) => {
    console.log("Api saveExportedFile ", { params: params });
    return Api.post(URL_SAVE_EXPORTED_FILE, { params: params });
  },
  // getCommunicationIdentity: (params) => {
  //   console.log("Api getCommunicationIdentity ", { params: params });
  //   return Api.get(URL_LAYOUT_GET_COMMUNICATION_IDENTITY, { params: params });
  // },
};
