<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row>
          <v-col cols="12" sm="10" md="10">
            <v-list-item>
              <v-list-item-content>
                <Label label="予想される危険" :editable="editable">
                  <InputText
                    name="predicted_risk"
                    :values="item"
                    :editable="editable"
                    placeholder=""
                    validation_label="予想される危険"
                    validation_rules="max:64"
                    @onInput="onInput({ index, value: $event })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="7" md="7">
            <v-list-item>
              <v-list-item-content>
                <Label label="危険の大きさ" :editable="editable">
                  <Select
                    name="cmn_mst_predicted_risk_magnitude_id"
                    :values="item"
                    item_text="type"
                    :items="CMN_MST_PREDICTED_RISK_MAGNITUDE"
                    :editable="editable && Boolean(item.predicted_risk)"
                    @onInput="onInput({ index, value: $event })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-list-item>
              <v-list-item-content>
                <InputText
                  name="severity"
                  :values="getSeverity(index)"
                  :editable="false"
                  class="mt-8"
                />
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="7" md="7">
            <v-list-item>
              <v-list-item-content>
                <Label label="発生の可能性" :editable="editable">
                  <Select
                    name="cmn_mst_predicted_risk_frequency_id"
                    :values="item"
                    item_text="type"
                    :items="CMN_MST_PREDICTED_RISK_FREQUENCY"
                    :editable="editable && Boolean(item.predicted_risk)"
                    @onInput="onInput({ index, value: $event })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-list-item>
              <v-list-item-content>
                <InputText
                  name="frequency"
                  :values="getFrequency(index)"
                  :editable="false"
                  class="mt-8"
                />
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <v-list-item>
              <v-list-item-content>
                <InputText
                  name="degree_risk_number"
                  :values="getDegreeRickNumber(index)"
                  :editable="false"
                  class="mt-8"
                />
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-list-item>
              <v-list-item-content>
                <InputText
                  name="degree_risk"
                  :values="getDegreeRisk(index)"
                  :editable="false"
                  class="mt-8"
                />
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-list-item>
              <v-list-item-content>
                <InputText
                  name="evaluation"
                  :values="getDegreeRisk(index)"
                  :editable="false"
                  class="mt-8"
                />
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col v-if="index > 0 && editable" cols="12" sm="2" md="2">
            <v-btn class="mt-10" icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn v-if="editable" class="mt-8" color="primary" depressed small @click="addEmpty">追加</v-btn>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "../../../elements/Label.vue";
import InputText from "../../../elements/InputText.vue";
import Select from "../../../elements/Select.vue";
import { Store } from "@/store/Store.js";

//初期値を設定します
const INITIAL_VALUES = {
  predicted_risk: null,
  cmn_mst_predicted_risk_magnitude_id: null,
  cmn_mst_predicted_risk_frequency_id: null,
};

export default {
  data: () => {
    return {
      items: [],
      CMN_MST_PREDICTED_RISK_MAGNITUDE: [{id: null, type: ""},...Store.state.CmnMst.constants.entries.cmn_mst_predicted_risk_magnitudes],
      CMN_MST_PREDICTED_RISK_FREQUENCY: [{id: null, type: ""},...Store.state.CmnMst.constants.entries.cmn_mst_predicted_risk_frequencies],
      CMN_MST_PREDICTED_RISK_EVALUATION: Store.state.CmnMst.constants.entries.cmn_mst_predicted_risk_evaluations,
    };
  },
  components: {
    Label,
    Select,
    InputText,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (this.items?.length == 0) {
          this.addEmpty();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      if(value.name == "predicted_risk" && !value.value) {
        _items[index]["cmn_mst_predicted_risk_magnitude_id"] = null;
        _items[index]["cmn_mst_predicted_risk_frequency_id"] = null;
      }
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      this.items.push({ ...INITIAL_VALUES });
    },
    getSeverity(index) {
      let severity = { severity: null };
      const cmn_mst_predicted_risk_magnitude_id =
        this.items[index]["cmn_mst_predicted_risk_magnitude_id"];
      if (cmn_mst_predicted_risk_magnitude_id) {
        this.CMN_MST_PREDICTED_RISK_MAGNITUDE.forEach((item) => {
          if (item.id == cmn_mst_predicted_risk_magnitude_id) {
            severity = { severity: item.severity };
          }
        });
      }
      return severity;
    },
    getFrequency(index) {
      let frequency = { frequency: null };
      const cmn_mst_predicted_risk_frequency_id =
        this.items[index]["cmn_mst_predicted_risk_frequency_id"];
      if (cmn_mst_predicted_risk_frequency_id) {
        this.CMN_MST_PREDICTED_RISK_FREQUENCY.forEach((item) => {
          if (item.id == cmn_mst_predicted_risk_frequency_id) {
            frequency = { frequency: item.frequency };
          }
        });
      }
      return frequency;
    },
    getDegreeRickNumber(index) {
      const severity = this.getSeverity(index);
      const frequency = this.getFrequency(index);
      let degreeRickNumber = { degree_risk_number: null };
      if (severity.severity && frequency.frequency) {
        degreeRickNumber = { degree_risk_number: severity.severity * frequency.frequency };
      }
      return degreeRickNumber;
    },
    getDegreeRisk(index) {
      const degreeRickNumber = this.getDegreeRickNumber(index);
      let degreeRick = {
        degree_risk: null,
        evaluation: null,
      };
      if (degreeRickNumber.degree_risk_number) {
        this.CMN_MST_PREDICTED_RISK_EVALUATION.forEach((item) => {
          if (item.score == degreeRickNumber.degree_risk_number) {
            degreeRick = {
              degree_risk: item.degree_risk,
              evaluation: item.evaluation,
            };
          }
        });
      }
      return degreeRick;
    },
  },
};
</script>
