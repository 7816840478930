<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form ref="form" lazy-validation autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="5" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <Label  label="権限" :editable="editable"/>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-list-item>
                    <v-list-item-content>
                    <Label  label="押印個数" :editable="editable"/>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="5" md="4">
                  <v-list-item>
                    <v-list-item-content>
                    <Label label="レベル" :editable="editable"/>
                     </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-row v-for="(item, index) in formValues" :key="index">
                <v-col cols="12" sm="5" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <InputText
                        :class="index == 0? 'mt-2':'mt-5'"
                        :disabled="true"
                        name="type"
                        :values="item"
                        @onInput="onInput($event, index)"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-list-item>
                    <v-list-item-content>
                      <Select
                        :class="index == 0? 'mt-2':'mt-5'"
                        item_value="value"
                        name="count"
                        :values="item"
                        :items="ARR_NUMBERS_OF_STAMPS"
                        :editable="editable"
                        @onInput="onInput($event, index)"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="5" md="4">
                  <v-list-item>
                    <v-list-item-content>
                      <RadioGroup
                        :class="index == 0? 'mt-2':'mt-5'"
                        v-if="editable"
                        style="margin-top: -10px"
                        :checkRow="true"
                        name="level"
                        :values="item"
                        :items="ARR_LEVEL"
                        :editable="editable"
                        validation_label="法人格付与位置"
                        validation_rules="required"
                        @onInput="onInput($event, index)"
                      />
                      <div v-if="!editable" :class=" index == 0 ? 'mt-1':'mt-5'">{{leverList[index]}}</div>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <div class="ml-4 mt-4" style="color: #000000">レベルA【高】＞レベルD【低】同一又は下位の権限者の承認のみ解除可能です。（上位の権限者の承認解除は不可）</div>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "@/components/forms/elements/Label";
import InputText from "@/components/forms/elements/InputText";
import RadioGroup from "@/components/forms/elements/RadioGroup";
import Select from "@/components/forms/elements/Select.vue";
import {  ARR_NUMBERS_OF_STAMPS , ARR_LEVEL } from "@/constants/SITES";
import _ from "lodash";

export default {
  data: () => {
    return {
      ARR_NUMBERS_OF_STAMPS,
      ARR_LEVEL,
      formValues: [],
    };
  },
  components: {
    Label,
    InputText,
    RadioGroup,
    Select
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  computed: {
    getBodyStyle() {
      const headerHeight = 48;
      let style = `height:${this.mainHeight - headerHeight}px;`;
      return style;
    },
    leverList(){
      let params = [];
      let listTemp =  _.cloneDeep(ARR_LEVEL);
      this.formValues.forEach(element => {
        params.push(listTemp.find( e => e.value == element.level).name)
      });
      return params;
    }
  },

  mounted() {
    this.$watch(
      () => this.item,
      (data) => {
        if(data){
          this.formValues = data.field_approvals;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    )
  },
  methods: {
    onInput({ name, value }, index) {
      let _formValues = { ...this.item };
      _formValues["field_approvals"][index][name] = value;
      this.$emit("formUpdate", _formValues);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";
</style>