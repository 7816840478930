// テーブル設定
import { format } from "date-fns";
const STOCK_TABLE_LABELS_1 = [
  {
    text: "",
    align: "left",
    value: "used_apply_status",
  },
  {
    text: "管理番号",
    value: "control_number",
    align: "left",
  },
  {
    text: "種別 / 機械名",
    value: "machine_type_extral_machine_name",
    align: "left",
  },
  {
    text: "管理名（呼名）",
    value: "control_name",
    align: "left",
  },
  {
    text: "持込会社 / 機番",
    value: "delivery_company_name_extral_machine_number",
    align: "left",
  },
  {
    text: "使用会社 / 職長",
    value: "used_company_name_extral_foreman_name",
    align: "left",
  },
  {
    text: "使用開始日 / 返却予定日",
    value: "used_start_date_extral_used_end_date",
    align: "left",
  },
  {
    text: "",
    value: "inspection_results",
    align: "left",
  },
];

const STOCK_TABLE_LABELS_2 = [
  {
    text: "",
    align: "left",
    value: "used_apply_status",
  },
  {
    text: "管理番号",
    value: "control_number",
    align: "left",
  },
  {
    text: "種別 / 機械名",
    value: "machine_type_extral_machine_name",
    align: "left",
  },
  {
    text: "管理名（呼名）",
    value: "control_name",
    align: "left",
  },
  {
    text: "持込会社 / 機番",
    value: "delivery_company_name_extral_machine_number",
    align: "left",
  },
  {
    text: "搬入予定日",
    value: "delivery_date",
    align: "left",
  },
];

const STOCK_TABLE_LABELS_3 = [
  {
    text: "",
    align: "left",
    value: "used_approval_flag",
  },
  {
    text: "管理番号",
    value: "control_number",
    align: "left",
  },
  {
    text: "種別 / 機械名",
    value: "machine_type_extral_machine_name",
    align: "left",
  },
  {
    text: "管理名（呼名）",
    value: "control_name",
    align: "left",
  },
  {
    text: "持込会社 / 機番",
    value: "delivery_company_name_extral_machine_number",
    align: "left",
  },
  {
    text: "使用会社 / 職長",
    value: "used_company_name_extral_foreman_name",
    align: "left",
  },
  {
    text: "使用開始日 / 返却予定日",
    value: "used_start_date_extral_used_end_date",
    align: "left",
  },
];

const STOCK_TABLE_LABELS_4 = [
  {
    text: "",
    align: "left",
    value: "used_approval_flag",
  },
  {
    text: "管理番号",
    value: "control_number",
    align: "left",
  },
  {
    text: "種別 / 機械名",
    value: "machine_type_extral_machine_name",
    align: "left",
  },
  {
    text: "管理名（呼名）",
    value: "control_name",
    align: "left",
  },
  {
    text: "持込会社 / 機番",
    value: "delivery_company_name_extral_machine_number",
    align: "left",
  },
  {
    text: "使用会社 / 職長",
    value: "used_company_name_extral_foreman_name",
    align: "left",
  },
  {
    text: "使用開始日 / 返却予定日",
    value: "used_start_date_extral_used_end_date",
    align: "left",
  },
];

const STOCK_TABLE_LABELS_5 = [
  {
    text: "",
    align: "left",
    value: "used_apply_status",
  },
  {
    text: "管理番号",
    value: "control_number",
    align: "left",
  },
  {
    text: "種別 / 機械名",
    value: "machine_type_extral_machine_name",
    align: "left",
  },
  {
    text: "管理名（呼名）",
    value: "control_name",
    align: "left",
  },
  {
    text: "持込会社 / 機番",
    value: "delivery_company_name_extral_machine_number",
    align: "left",
  },
  {
    text: "搬出予定日",
    value: "carry_out_date",
    align: "left",
  },
];

const TARGET_ROLE = [1, 2, 3, 4];

const STOCK_SORT_ITEMS_1 = [
  { id: "control_number", name: "管理番号" },
  { id: "delivery_company_name", name: "持込会社" },
  { id: "machine_name", name: "機械名" },
  { id: "machine_number", name: "機番" },
];

const STOCK_SORT_ITEMS_2 = [
  { id: "control_number", name: "管理番号" },
  { id: "delivery_company_name", name: "持込会社" },
  { id: "machine_name", name: "機械名" },
  { id: "machine_number", name: "機番" },
  { id: "delivery_date", name: " 搬入予定日" },
];

const STOCK_SORT_ITEMS_3 = [
  { id: "control_number", name: "管理番号" },
  { id: "delivery_company_name", name: "持込会社" },
  { id: "used_company_name", name: "使用会社" },
  { id: "machine_name", name: "機械名" },
  { id: "machine_number", name: "機番" },
  { id: "used_start_date", name: "使用開始日" },
  { id: "used_end_date", name: "返却予定日" },
];

const STOCK_SORT_ITEMS_4 = [
  { id: "control_number", name: "管理番号" },
  { id: "delivery_company_name", name: "持込会社" },
  { id: "used_company_name", name: "使用会社" },
  { id: "machine_name", name: "機械名" },
  { id: "machine_number", name: "機番" },
  { id: "used_start_date", name: "使用開始日" },
  { id: "used_end_date", name: "返却予定日" },
];

const STOCK_SORT_ITEMS_5 = [
  { id: "control_number", name: "管理番号" },
  { id: "delivery_company_name", name: "持込会社" },
  { id: "machine_name", name: "機械名" },
  { id: "machine_number", name: "機番" },
];

const FORMS = {
  TABLE1: { id: 1, name: "全体" },
  TABLE2: { id: 2, name: "搬入予定" },
  TABLE3: { id: 3, name: "使用" },
  TABLE4: { id: 4, name: "返却" },
  TABLE5: { id: 5, name: "リース返却" },
};

const USING_STATUS = {
  USING: { id: 4, name: "使用中" },
  UNUSED: { id: 2, name: "未使用" },
};

const APPROVE_STATUS = {
  APPROVE: { id: 1, name: "承認済" },
  UNAPPROVE: { id: 0, name: "未承認" },
};

const UNUSED_RESERVED_STATUS = {
  UNUSED: { id: 2, name: "未使用" },
  RESERVED: { id: 6, name: "予約済" },
};

const INSURANCE_STATUS_2 = [
  { id: 1, name: "無", value: 1 },
  { id: 2, name: "有", value: 2 },
];

const STATUS_COLORS = [
  { color: "#00000099", value: "使用不可" },
  { color: "#e0e0e0", value: "搬入予定" },
  { color: "#E5951D", value: "未使用" },
  { color: "#29ABE2", value: "予約済" },
  { color: "#32C36C", value: "使用中" },
  { color: "#e0e0e0", value: "返却済" },
  { color: "#29ABE2", value: "予約済" },
];

const APPROVAL_COLORS = [
  { color: "#E5951D", value: "未承認" },
  { color: "#32C36C", value: "承認済" },
];

const USED_APPLY_STATUS = {
  UNUSABLE_STATUS: { status: 0 },
  SCHEDULED_STATUS: { status: 1 },
  UNUSE_STATUS: { status: 2 },
  BOOKED_STATUS: { status: 3 },
  USING_STATUS: { status: 4 },
  RETURN_STATUS: { status: 5 },
  RETURN_BOOK_STATUS: { status: 6 },
};

const REGISTER_TYPE = {
  REGISTER_USE: { type: 0 },
  REGISTER_RETURN: { type: 1 },
};

const ROLE_USER = [0, 1, 2, 3, 4];

const STOCK_TABS = {
  TAB1: 1,
  TAB2: 2,
  TAB3: 3,
  TAB4: 4,
  TAB5: 5,
};

const INIT_DATA_FORM_REGISTER = {
  register_type: null,
  machine_company_field_type_item_tree_name: null,
  machine_name: null,
  control_name: null,
  used_field_construction_id: null,
  used_field_tree_group_id: null,
  used_field_tree_id: null,
  used_field_tree_conpany_name: null,
  used_foreman_user_id: null,
  used_foreman_user_name: null,
  used_field_item_tree_id: null,
  used_start_date: format(new Date(), "yyyy-MM-dd"),
  used_end_date: null,
  machine_company_field_drivers: [],
};

const INFO_CONFIRM_DIALOG = {
  REGISTER_MACHINE_FIELD_1: {
    title: "使用申請の確認",
    message: "これらの機械は同時に使用申請できません。"
  },
  REGISTER_MACHINE_FIELD_2: {
    title: "使用申請の確認",
    message: "この機械は使用申請できません。"
  },
  REGISTER_RETURN_1: {
    title: "返却申請の確認",
    message: "これらの機械は同時に返却申請できません。"
  },
  REGISTER_RETURN_2: {
    title: "返却申請の確認",
    message: "この機械は返却申請できません。"
  },
  REGISTER_RETURN_3: {
    title: "返却申請",
    message: "予約済の機材に対して返却申請を行うことができません。"
  },
  REGISTER_RETURN_4: {
    title: "返却申請",
    message: "機械の返却申請を建機レンタル会社へ通知します。"
  },
  CONFIRM_RETURN: {
    title: "返却確定",
    message: "返却された機械の検品を行い、返却完了とします。"
  },
  CONFIRM_APPROVE_1: {
    title: "承認確認",
    message: "選択されたデータは全て承認済みです。"
  },
  CONFIRM_APPROVE_2: {
    title: "承認確認",
    message: "選択したデータを承認します。",
    text2: "承認してもよろしいですか。",
    titleBtnOk: "承認"
  },
  CONFIRM_UNAPPROVE_1: {
    title: "承認解除確認",
    message: "選択したデータは全て承認されていないため、承認解除出来ません。"
  },
  CONFIRM_UNAPPROVE_2: {
    title: "承認解除確認",
    message: "選択したデータの承認を解除します。",
    text2: "承認を解除してもよろしいですか。",
    titleBtnOk: "解除"
  },
  CONFIRM_START_USE_1: {
    title: "使用開始",
    message: "機械の使用を開始します。",
    titleBtnOk: "確定"
  },
  CONFIRM_STOP_USE_1: {
    title: "使用終了",
    message: "機械の使用を終了し、在庫に戻します。",
    titleBtnOk: "確定"
  },
  CONFIRM_DELETE_ERROR: {
    title: "選択項目の削除",
    message: "自分が所有していない機械は削除できません。",
    titleBtnOk: "確定"
  },
  USE_ERROR:{
    title: "使用開始",
    message: "未承認のため、使用開始することができません。",
  },
  APPROVE_UNUSE: {
    title: "承認確認",
    message: "使用不可の資機材のため、使用申請ができません。"
  },
  UNAPPROVE_UNUSE: {
    title: "承認解除確認",
    message: "使用不可の資機材のため、使用申請ができません。"
  },
  APPROVE_UNUSE_TAB_4:{
    title: "承認確認",
    message: "使用不可の資機材のため、終了申請ができません。"
  },
  UNAPPROVE_UNUSE_TAB_4:{
    title: "承認解除確認",
    message: "使用不可の資機材のため、終了申請ができません。"
  },
  START_USE_UNUSE:{
    title: "使用開始",
    message: "使用不可の資機材のため、使用申請ができません。"
  },
  END_USE_UNUSE:{
    title: "使用終了",
    message: "使用不可の資機材のため、終了申請ができません。"
  },
  END_USE_ERROR_TAB_4:{
    title: "使用終了",
    message: "未承認のため、使用終了することができません。"
  }
};

const SEARCH_PARAMS_DEFAULT_TAB_1 = {
  index_delivery_company_id : null,
  delivery_company_id: null,
  delivery_company_branch_id : null,
  machine_company_type_item_tree_id: null,
  machine_name: null,
  control_number: null,
  used_apply_status: null,
  machine_rental_company_flag: null,
};
const SEARCH_PARAMS_DEFAULT_TAB_2 = {
    index_delivery_company_id : null,
    delivery_date_from: null,
    delivery_date_to: null,
    delivery_company_id: null,
    delivery_company_branch_id : null,
    machine_name: null,
    machine_rental_company_flag: null,
};
const SEARCH_PARAMS_DEFAULT_TAB_3_4 = {
  index_delivery_company_id : null,
  delivery_company_id: null,
  delivery_company_branch_id : null,
  used_field_tree_id: null,
  machine_company_type_item_tree_id: null,
  machine_name: null,
  control_number: null,
  used_approval_flag : null,
};

const SEARCH_PARAMS_DEFAULT_TAB_5 = {
  index_delivery_company_id : null,
  delivery_company_id: null,
  delivery_company_branch_id : null,
  machine_company_type_item_tree_id: null,
  machine_name: null,
  control_number: null,
  used_apply_status : null,
  returned_flag : null,
};

const INSPECTION_LEFT_TABLE_LABELS = [
  {
    text: "点検項目",
    align: "left",
    value: "inspection_item",
    sortable: true,
  },
  {
    text: "点検主眼点",
    align: "left",
    value: "main_point",
    sortable: true,
  },
];

const INSPECTION_RIGHT_TABLE_LABELS = [
  {
    text: "点検項目",
    align: "left",
    value: "inspection_item",
  },
  {
    text: "点検主眼点",
    align: "left",
    value: "main_point",
  },
];

const TYPE_ACTION = {
  ADD: "add",
  REMOVE: "remove"
};

const HAS_INSPECTION_RESULT = 1;

export {
  STOCK_TABLE_LABELS_1,
  STOCK_TABLE_LABELS_2,
  STOCK_TABLE_LABELS_3,
  STOCK_TABLE_LABELS_4,
  STOCK_TABLE_LABELS_5,
  STOCK_SORT_ITEMS_1,
  STOCK_SORT_ITEMS_2,
  STOCK_SORT_ITEMS_3,
  STOCK_SORT_ITEMS_4,
  STOCK_SORT_ITEMS_5,
  INSURANCE_STATUS_2,
  USING_STATUS,
  STATUS_COLORS,
  ROLE_USER,
  APPROVE_STATUS,
  UNUSED_RESERVED_STATUS,
  TARGET_ROLE,
  APPROVAL_COLORS,
  INIT_DATA_FORM_REGISTER,
  STOCK_TABS,
  INFO_CONFIRM_DIALOG,
  USED_APPLY_STATUS,
  REGISTER_TYPE,
  FORMS,
  SEARCH_PARAMS_DEFAULT_TAB_1,
  SEARCH_PARAMS_DEFAULT_TAB_2,
  SEARCH_PARAMS_DEFAULT_TAB_3_4,
  SEARCH_PARAMS_DEFAULT_TAB_5,
  INSPECTION_LEFT_TABLE_LABELS,
  INSPECTION_RIGHT_TABLE_LABELS,
  TYPE_ACTION,
  HAS_INSPECTION_RESULT,
};
