<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="登録者"/>
                      <InputText
                        name="create_user"
                        :values="formValues"
                        :editable="false"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="最終更新者"/>
                      <InputText
                        name="update_user"
                        :values="formValues"
                        :editable="false"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 最終承認者 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="最終承認者"/>
                      <v-text-field
                        v-model="getApprovalUser"
                        dense
                        color="primary"
                        :disabled="true"
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="登録日時"/>
                      <InputText
                        name="created_at"
                        :editable="false"
                        :values="formValues"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="最終更新日時"/>
                      <InputText
                        name="updated_at"
                        :editable="false"
                        :values="formValues"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 最終承認日時 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="最終承認日時"/>
                      <InputText
                        name="approval_date"
                        :editable="false"
                        :values="formValues"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6" md="6"> </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "@/components/forms/elements/Label";
import InputText from "@/components/forms/elements/InputText";

export default {
  data: () => {
    return {
      formValues: {},
    };
  },
  components: {
    Label,
    InputText,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.formValues = this.item;
    if(this.formValues.created_at){
      this.formValues.created_at = this.formValues.created_at.replaceAll('-', '/')
    }
    if(this.formValues.updated_at){
      this.formValues.updated_at = this.formValues.updated_at.replaceAll('-', '/')
    }
    if (this.formValues.approval_date) {
      this.formValues.approval_date = this.formValues.approval_date.replaceAll('-', '/')
    }
    const _formValues = {...this.formValues};
    let create_user_name_sei = _formValues.create_user_name_sei == null ? '' : _formValues.create_user_name_sei;
    let create_user_name_mei = _formValues.create_user_name_mei == null ? '' : _formValues.create_user_name_mei;
    let update_user_name_sei = _formValues.update_user_name_sei == null ? '' : _formValues.update_user_name_sei;
    let update_user_name_mei = _formValues.update_user_name_mei == null ? '' : _formValues.update_user_name_mei;
    let create_user = create_user_name_sei + " " + create_user_name_mei;
    let update_user = update_user_name_sei + " " + update_user_name_mei;
    _formValues.create_user = create_user ;
    _formValues.update_user = update_user ;
    this.formValues = {..._formValues};
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    getApprovalUser () {
      return `${this.formValues['approval_user_name_sei'] || ""} ${this.formValues['approval_user_name_mei'] || ""}`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
</style>
