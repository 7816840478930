<template>
  <div class="FormMain">
    <moveActionComponent
      :disabledDoubleNext="disabledDoubleNext"
      :disabledDoublePrev="disabledDoublePrev"
      v-if="isShowMove && !isNewItem"
      :multipleMove="false"
      @loading="setLoading">
    </moveActionComponent>
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <section class="fullpage item-full-page">
                <v-row no-gutters class="ml-5">
                  <v-col cols="12" sm="6" md="6">
                    <v-list-item>
                      <v-list-item-content>
                        <ImageForm
                          :title="title"
                          :editable="editable"
                          :isNewItem="isNewItem"
                          :formValues="item"
                          :workPlace="workPlace"
                          :type="TYPE.TYPE1"
                          :recordId="recordId"
                          @formUpdate="formUpdate"
                          :backPreview="backPreview"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-list-item>
                      <v-list-item-content>
                        <ImageFormRight
                          :title="titleFormRight"
                          :editable="editable"
                          :isNewItem="isNewItem"
                          :formValues="item"
                          :type="TYPE.TYPE2"
                          :backPreview="backPreview"
                          :recordId="recordId"
                          @onRecovery="onRecovery"
                          @formUpdate="formUpdate"
                          @onApprove="onApprove"
                          @onRejectAfterRecovery="onRejectAfterRecovery"
                          @onRejectAfterApprove="onRejectAfterApprove"
                          @onEditMessage="onEditMessage"
                          @openMessageReject="openMessageReject"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </section>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
      <Popup width="480px" :dialog="popups.isShowConfirmDialog">
        <ConfirmDialog
          :dialogText="DIALOG_APPROVE_TEXT"
          :isApproved="true"
          @close="popups.isShowConfirmDialog = false"
          @yes="popups.isShowConfirmDialog = false"
          :warning="false"
        />
      </Popup>
      <Popup width="480px" :dialog="popups.isShowMessageReject">
        <ShowMessageRejectDialog
          @close="popups.isShowMessageReject = false"
          :warning="false"
          :message="safetyDiaryCommentCorrectionsValues.message_reject"
        />
      </Popup>
      <Popup width="480px" :dialog="popups.isShowConfirmRejectDialog">
        <ConfirmRejectDialog
          :dialogText="DIALOG_REJECT_TEXT"
          :isApproved="true"
          :valid="valid"
          :statusCodeReject="safetyDiaryCommentCorrectionsValues.status_code"
          :messageReject="safetyDiaryCommentCorrectionsValues.message_reject"
          @close="popups.isShowConfirmRejectDialog = false"
          @yes="updateStatusRejectAfterRecovery"
          :warning="false"
        />
      </Popup>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "@/components/forms/elements/Label.vue";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import Select from "@/components/forms/elements/Select.vue";
import SwitchInput from "@/components/forms/elements/SwitchInput";
import RadioGroup from "@/components/forms/elements/RadioGroup";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import InputTextArea from "@/components/forms/elements/InputTextArea";
import Popup from "@/components/common/Popup";
import ImageForm from "@/components/forms/schedule/patrols/components/ImageForm.vue";
import ImageFormRight from "@/components/forms/schedule/patrols/components/ImageFormRight.vue";
import SearchDetectUser from "@/components/forms/schedule/patrols/components/SearchDetectUser.vue";
import { URGENCY_CLASSIFICATION_RADIO, TYPE,DIALOG_APPROVE_TEXT, DIALOG_REJECT_TEXT } from "@/constants/PATROL_RECORD";
import ConfirmDialog from "@/views/schedule/patrols/components/ConfirmDialog.vue";
import ConfirmRejectDialog from "@/views/schedule/patrols/components/ConfirmRejectDialog.vue";
import ShowMessageRejectDialog from "@/views/schedule/patrols/components/ShowMessageRejectDialog.vue";
import moveActionComponent from "@/components/forms/schedule/patrols/PaltrolForm/moveActionComponent"

export default {
  data: () => {
    return {
      DIALOG_APPROVE_TEXT,
      DIALOG_REJECT_TEXT,
      TYPE,
      formValues: {},
      safetyDiaryCommentCorrectionsValues: {},
      max: new Date().toISOString().slice(0, 10),
      FIELD_CONSTRUCTION_ARRAY: [],
      TIME_ZONE_ARRAY: [],
      COMPANY_PARTNER_ARRAY: [],
      DETECT_USER_ARRAY: [{ user_id: null, user_name: "" }],
      JOURNAL_ARRAY: [{ id: null, journal_type: "" }],
      USER_NOTIFICATIONS_ARRAY: [{ id: null, user_name: "" }],
      FIELD_TREE_NOTIFICATIONS_ARRAY: [{ field_tree_id: null, company_name: "" }],
      GROUP_NOTIFICATIONS_ARRAY: [{ id: null, group_name: "" }],
      PATROL_TYPE: [{ id: 1, type: "指摘" }],
      URGENCY_CLASSIFICATION_RADIO,
      popups: {
        isShowChooseUserNotification: false,
        isShowChooseFieldTreeNotification: false,
        isShowChooseGroupNotification: false,
        isShowSearchDetectUser : false,
        isShowConfirmDialog : false,
        isShowConfirmRejectDialog: false,
        isShowEditMessage: false,
        isShowMessageReject: false,
      },
      dataChooseUserNotification: [],
      dataChooseFieldTreeNotification: [],
      dataChooseGroupNotification: [],
      isPartnerLevel1 : false,
      title: '指摘',
      titleFormRight: '是正',
    };
  },
  components: {
    InputText,
    SectionLabel,
    RadioGroup,
    Label,
    InputDatepicker,
    Select,
    SwitchInput,
    InputTextArea,
    Popup,
    SearchDetectUser,
    ConfirmDialog,
    ImageForm,
    ImageFormRight,
    moveActionComponent,
    ConfirmRejectDialog,
    ShowMessageRejectDialog,
  },
  props: {
    item: Object,
    mainHeight: {
      type: Number,
      default: 0
    },
    isNewItem : Boolean,
    editable : Boolean,
    valid : Boolean,
    workPlace: Array,
    recordId : Number,
    childId : Number,
    backPreview: Boolean,
    isShowMove: Boolean,
    disabledDoubleNext: Boolean,
    disabledDoublePrev: Boolean,
  },
  mounted() {
    this.getUserRole();
    this.$watch(
      () => this.item,
      (data) => {
        this.formValues = { ...data };
        if (Array.isArray(data.safety_diary_comment_corrections)) {
          this.safetyDiaryCommentCorrectionsValues = data.safety_diary_comment_corrections[0];
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `overflow: hidden; height:${this.mainHeight}px;`;
      if (screen.width <= 1440) {
        style += `margin-right:12px`;
        return style;
      }
      if (screen.width >= 1440 && screen.width <= 1600) {
        style += `margin-right:32px`;
        return style;
      }
      style += `margin-right:20px`;
      return style;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    USER_ROLE(){
      return Store.getters["Sites/getRole"];
    }
  },
  methods: {
    async getUserRole() {
      await Store.dispatch(`Report/getUserRole`, this.CURRENT_SITE.field_id);
    },
    getColor(recovery) {
      return recovery === 0 ? "warning" : "primary";
    },
    getText(recovery) {
      return recovery === 0 ? "未是正" : "是正済";
    },
    onReject(){
      this.$emit("onChangeStatusCode",false);
    },
    onApprove(statusRecovery){
      if (statusRecovery == 0) {
        this.popups.isShowConfirmDialog = true;
      } else {
        this.$emit("onChangeStatusCode", true);
      }
    },
    onRejectAfterApprove(){
      this.$emit("onChangeStatusCode", false);
    },
    formUpdate(formValues) {
      this.$emit("formUpdate", formValues);
    },
    onRecovery() {
      this.$emit("onRecovery");
    },
    onRejectAfterRecovery() {
      this.popups.isShowConfirmRejectDialog = true;
    },
    onEditMessage() {
      this.popups.isShowConfirmRejectDialog = true;
    },
    async updateStatusRejectAfterRecovery(message, flgEdit) {
      let temp = {
        safety_diary_ids: [this.recordId],
        safety_diary_comment_correction_ids: [this.childId],
        message_reject: message,
        flg_edit_message: flgEdit,
      };
      let params = {...temp};
      const result = await Store.dispatch(`PatrolRecord/updateStatusReject`, params);
      if (!result.hasError) {
        await this.getItems(this.recordId);
        Store.dispatch("Toast/show", {
          status: 200,
          message: flgEdit ? "否認通知の修正をしました。" : "差戻しました。"
        });
        this.formValues.safety_diary_comment_corrections[0].status_code = 2;
        this.formValues.safety_diary_comment_corrections[0].recovery_flag = 1;
        this.formValues.safety_diary_comment_corrections[0].message_reject = message;
        this.$emit("formUpdate", this.formValues);
        this.$emit("actionSuccess", false);
        this.popups.isShowConfirmRejectDialog = false;
      }
    },
    openMessageReject() {
      this.popups.isShowMessageReject = true;
    },
    setLoading(flg) {
      this.$emit('loading', flg);
    },
    async getItems(id) {
      if (id) {
        await Store.dispatch(`PatrolRecord/getDetail`, id);
      }
    },
  }
};
</script>
<style lang="scss" scoped>
@import "../../../../style/forms.scss";

.select {
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.select_wrap {
  width: 100%;
  pointer-events: none;
}

::v-deep .v-chip .v-chip__content {
  height: 100%;
  max-width: 6vw;
  display: inline-block !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 14px;
}
::v-deep .v-text-field--filled.v-input--dense.v-text-field--single-line .v-label, .v-text-field--full-width.v-input--dense.v-text-field--single-line .v-label {
  color: black !important;
}

::v-deep .v-select.v-input--dense .v-chip {
  background: #1B9C4F;
  color: white;
}

::v-deep .labelWrapper .label {
  white-space: nowrap !important;
}
.prev-form {
  position: absolute;
  width: 100px;
  height: 100px;
  z-index: 9999;
  right: 10px;
  top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.next-form {
  position: absolute;
  width: 100px;
  height: 100px;
  z-index: 9999;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
::v-deep .FormMainBody{
  max-height: 758.296px;
}


.select {
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.select_wrap {
  width: 100%;
  pointer-events: none;
}

::v-deep .v-chip .v-chip__content {
  height: 100%;
  max-width: 6vw;
  display: inline-block !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 14px;
}
::v-deep .v-text-field--filled.v-input--dense.v-text-field--single-line .v-label, .v-text-field--full-width.v-input--dense.v-text-field--single-line .v-label {
  color: black !important;
}

::v-deep .v-select.v-input--dense .v-chip {
  background: #1B9C4F;
  color: white;
}

::v-deep .labelWrapper .label {
  white-space: nowrap !important;
}

.FormMain {
  position: relative;
}

.fullpage {
  height: 77.5vh;
  width: 100%;
}
::v-deep .FormMainBody.formAddnew {
  min-height: 756.1px;
}
::v-deep .FormMainBody{
  max-height: 758.296px;
}

</style>
