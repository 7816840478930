<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="6">
                      <!-- 姓 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="国籍" :editable="editable">
                            <SelectSearch
                              name="cmn_mst_nationality_id"
                              :values="userForeigners"
                              :items="NATIONALITY"
                              item_text="nation"
                              :editable="editable"
                              title="国籍選択"
                              title_search="国籍名"
                              @onInput="onInput(USER_EVENT_TARGET.USER_FOREIGNERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <!-- セイ -->
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="在留資格" :editable="editable">
                            <Select
                              name="cmn_mst_visa_status_id"
                              :values="userForeigners"
                              :items="VISA_STATUS"
                              :editable="editable"
                              @onInput="onInput(USER_EVENT_TARGET.USER_FOREIGNERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <!-- 在留期間満了日 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="在留期間満了日" :editable="editable">
                            <InputDatepicker
                              name="expiration_date"
                              :values="userForeigners"
                              :editable="editable"
                              placeholder="yyyy/mm/dd"
                              validation_label="在留期間満了日"
                              :flagNull="true"
                              @onInput="onInput(USER_EVENT_TARGET.USER_FOREIGNERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <!-- パスポート -->
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="パスポート" :editable="editable">
                            <FileInput
                              :style="getFileInputStyle('passport_name')"
                              :resetValue="resetValue['passport_name']"
                              v-if="editable"
                              name="passport_name"
                              :values="userForeigners"
                              :editable="editable"
                              placeholder=""
                              accept="image/jpg, image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                              validation_label="passport_name"
                              validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf, xls, xlsx"
                              @onInput="onInput(USER_EVENT_TARGET.USER_FOREIGNERS, $event)"
                            />
                            <span class="file"
                              v-if="!editable && userForeigners['passport_name']"
                              @click="viewSourceWithAuth(userForeigners['passport_name_url'])"
                            >
                              {{ userForeigners["passport_name"] }}
                            </span>
                            <span
                              class="file"
                              v-if="judgeDisplayFileInput('passport_name')"
                              >{{ userForeigners["passport_name"] }}
                              <v-btn
                                small
                                icon
                                text
                                @click="
                                  onRemoveFile({
                                    parents_name: USER_EVENT_TARGET.USER_FOREIGNERS,
                                    name: 'passport_name',
                                  })
                                "
                              >
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </span>
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <!-- 在留カード -->
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="在留カード" :editable="editable">
                            <FileInput
                              :style="getFileInputStyle('resident_card_name')"
                              :resetValue="resetValue['resident_card_name']"
                              v-if="editable"
                              name="resident_card_name"
                              :values="userForeigners"
                              :editable="editable"
                              placeholder=""
                              accept="image/jpg, image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                              validation_label="resident_card_name"
                              validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf, xls, xlsx"
                              @onInput="onInput(USER_EVENT_TARGET.USER_FOREIGNERS, $event)"
                            />
                            <span class="file"
                              v-if="
                                !editable &&
                                userForeigners['resident_card_name']"
                              @click="viewSourceWithAuth(userForeigners['resident_card_name_url'])"
                            >
                              {{ userForeigners["resident_card_name"] }}
                            </span>
                            <span
                              class="file"
                              v-if="judgeDisplayFileInput('resident_card_name')"
                              >{{ userForeigners["resident_card_name"] }}
                              <v-btn
                                small
                                icon
                                text
                                @click="onRemoveFile({
                                    parents_name: USER_EVENT_TARGET.USER_FOREIGNERS,
                                    name: 'resident_card_name',
                                  })"
                              >
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </span>
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <!-- 外国人登録証明書 -->
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="外国人登録証明書" :editable="editable">
                            <FileInput
                              :style="getFileInputStyle('registration_certificate_name')"
                              :resetValue="resetValue['registration_certificate_name']"
                              v-if="editable"
                              name="registration_certificate_name"
                              :values="userForeigners"
                              :editable="editable"
                              placeholder=""
                              accept="image/jpg, image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                              validation_label="registration_certificate_name"
                              validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf, xls, xlsx"
                              @onInput="onInput(USER_EVENT_TARGET.USER_FOREIGNERS, $event)"
                            />
                            <span class="file"
                              v-if="!editable && userForeigners['registration_certificate_name']"
                              @click="viewSourceWithAuth(userForeigners['registration_certificate_name_url'])"
                            >
                              {{ userForeigners["registration_certificate_name"] }}
                            </span>
                            <span
                              class="file"
                              v-if="judgeDisplayFileInput('registration_certificate_name')"
                              >{{ userForeigners["registration_certificate_name"] }}
                              <v-btn
                                small
                                icon
                                text
                                @click="onRemoveFile({
                                    parents_name: USER_EVENT_TARGET.USER_FOREIGNERS,
                                    name: 'registration_certificate_name',
                                  })"
                              >
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </span>
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <!-- 雇用契約書及び雇用条件書 労働条件通知書）PDF -->
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label
                            label="雇用契約書及び雇用条件書"
                            :editable="editable"
                          >
                          <Label v-if="!editable" label="（労働条件通知書）PDF" :editable="editable" />
                            <FileInput
                              :style="getFileInputStyle('employment_contract_name')"
                              :resetValue="resetValue['employment_contract_name']"
                              v-if="editable"
                              name="employment_contract_name"
                              :values="userForeigners"
                              :editable="editable"
                              placeholder=""
                              accept="image/jpg, image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                              validation_label="employment_contract_name"
                              validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf, xls, xlsx"
                              @onInput="onInput(USER_EVENT_TARGET.USER_FOREIGNERS, $event)"
                            />
                            <span class="file"
                              v-if="!editable && userForeigners['employment_contract_name']"
                              @click="viewSourceWithAuth(userForeigners['employment_contract_name_url'])"
                            >
                              {{ userForeigners["employment_contract_name"] }}
                            </span>
                            <span
                              class="file"
                              v-if="judgeDisplayFileInput('employment_contract_name')"
                              >{{ userForeigners["employment_contract_name"] }}
                              <v-btn
                                small
                                icon
                                text
                                @click="onRemoveFile({
                                    parents_name: USER_EVENT_TARGET.USER_FOREIGNERS,
                                    name: 'employment_contract_name',
                                  })"
                              >
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </span>
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "../elements/Label";
import InputDatepicker from "../elements/InputDatepicker.vue";
import FileInput from "../elements/FileInput.vue";
import Select from "../elements/Select.vue";
import { USER_EVENT_TARGET } from "@/constants/EMPLOYEE";
import _ from "lodash";
import { viewSourceWithAuth } from "@/utils/viewSourceAuth";
import SelectSearch from "@/components/forms/elements/SelectSearch";

export default {
  data: () => {
    return {
      formValues: {},
      userForeigners: {},
      NATIONALITY: [{ id: null, nation: "" },...Store.state.CmnMst.constants.entries.cmn_mst_nationalities],
      VISA_STATUS: [{ id: null, name: "" },...Store.state.CmnMst.constants.entries.cmn_mst_visa_status],
      USER_EVENT_TARGET,
      fileValid: {},
      resetValue: {},
      viewSourceWithAuth: viewSourceWithAuth,
    };
  },
  components: {
    SelectSearch,
    Label,
    Select,
    InputDatepicker,
    FileInput,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    errors: {
      type: Object,
      default: {},
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        const formValues = { ...newValue };
        this.formValues = { ...formValues };
        this.userForeigners = { ...formValues[USER_EVENT_TARGET.USER_FOREIGNERS] };
        this.userForeigners.cmn_mst_visa_status_id = this.userForeigners.cmn_mst_visa_status_id ? this.userForeigners.cmn_mst_visa_status_id : null;
        this.userForeigners.cmn_mst_nationality_id = this.userForeigners.cmn_mst_nationality_id ? this.userForeigners.cmn_mst_nationality_id : null;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    // check input file error
    this.$watch(
      () => [this.item, this.errors],
      (data) => {
        this.formValues = {...data[0]};
        const errors = {...data[1]};
        // the validation_label list of the FileInput element
        const names = [
          "passport_name",
          "resident_card_name",
          "registration_certificate_name",
          "employment_contract_name",
        ];
        const fileValid = {...this.fileValid};
        const formValues = _.cloneDeep(this.formValues);
        if (errors) {
          for(let name in errors) {
            if (names.some((item) => item === name)){ 
              if(errors[name].length >0){
                delete formValues[USER_EVENT_TARGET.USER_FOREIGNERS][name];
                delete formValues[USER_EVENT_TARGET.USER_FOREIGNERS][`${name}_data`];
                fileValid[name] = false;
              } else {
                fileValid[name] = true;
              }
            }
          }
        }
        this.formValues = formValues;
        this.fileValid = fileValid;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    onInput(parents_name, { name, value, fileName }) {
      const formValues = { ...this.formValues };
      if (fileName) {
        formValues[parents_name][`${name}_data`] = value;
        formValues[parents_name][name] = fileName;
        if (!value) {
          delete formValues[parents_name][`${name}_deleted`];
        }
      } else {
        formValues[parents_name][name] = value;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    onRemoveFile({ parents_name, name }) {
      const formValues = { ...this.formValues };
      delete formValues[parents_name][name];
      delete formValues[parents_name][`${name}_data`];
      if(formValues[parents_name][`${name}_url`]) formValues[parents_name][`${name}_deleted`] = 1;
      this.formValues = formValues;
      this.resetValue[name] = !this.resetValue[name];
      this.$emit("formUpdate", formValues);
    },
    getFileInputStyle(fieldName) {
      return (this.fileValid[fieldName] && this.userForeigners[fieldName]) ? 'display:none;' : '';
    },
    judgeDisplayFileInput(fieldName) {
      return this.editable && this.fileValid[fieldName] && this.userForeigners[fieldName];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";
</style>
