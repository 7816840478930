<template>
  <div>
    <Select
      name="work_hour_zone"
      :values="formValues"
      :items="items"
      :editable="true"
      @onInput="onInput"
    />
  </div>
</template>
<script>
import Select from "@/components/forms/elements/Select.vue";
import { Store } from "@/store/Store.js";

export default {
  data: () => {
    return {
      items: [],
      formValues: { work_hour_zone: 0 },
    };
  },
  props: {
    name: {
      type: String,
    },
    fieldId: {
      type: Number,
    },
    searchParams: {
      type: Object,
    },
  },
  components: {
    Select,
  },
  mounted() {
    /**
     * 時間帯一覧
     */
    this.$watch(
      () => Store.getters["PortalChart/getFieldTimeZones"],
      (newValue) => {
        if (this.formValues.work_hour_zone !== newValue[0].id && this.fieldId) {
          const items = [...newValue];
          if (items.length) {
            this.items = items;
            this.formValues.work_hour_zone = items[0].id;
            this.onInput({ name: this.name, value: items[0].id });
          }
        }
      },
      { immediate: true, deep: true }
    );
    /**
     * 現場idから時間帯一覧を取得
     */
    this.$watch(
      () => this.fieldId,
      async (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          if(newValue) {
            await Store.dispatch("PortalChart/getFieldTimeZones", newValue);
          }
        }
      },
      { immediate: true, deep: true }
    );
    /**
     * 選択項目を更新
     */
    this.$watch(
      () => [this.name, this.searchParams],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          const params = newValue[1];
          const name = newValue[0];
          const selected = this.items.find((item) => {
            return item.id === params[name];
          });
          if (selected) this.formValues.work_hour_zone = selected.id;
        }
      }
    );
  },
  methods: {
    onInput({ name, value }) {
      this.$emit("onInput", { name, value });
    },
  },
};
</script>
