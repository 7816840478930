<template>
  <div>
    <v-container>
      <v-row v-for="(item, index) in items" :key="index">
        <v-list-item>
          <v-list-item-content>
            <SectionLabel label="登録基幹技能者" :editable="editable" class="mt-5"/>
            <Label label="登録基幹技能者資格の有無" :editable="editable">
              <RadioGroup
                v-if="editable"
                name="qualification_status"
                :values="item"
                :items="QUALIFICATION_STATUS"
                :editable="editable"
                validation_label="登録基幹技能者資格の有無"
                validation_rules=""
                @onInput="onInput({value: $event, index})"
              />
              <span v-if="!editable">{{
                  getRadioName(
                    QUALIFICATION_STATUS,
                    item["qualification_status"]
                  )
                }}</span>
            </Label>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <Label label="登録基幹技能者名" :editable="editable">
              <SelectSearch
                name="cmn_mst_engineer_job_id"
                :values="item"
                :items="ENGINEER_JOB"
                item_text="sub_catergory"
                :editable="editable"
                validation_label=""
                validation_rules=""
                title="登録基幹技能者名選択"
                title_search="登録基幹技能者名"
                @onInput="onInput({value: $event, index})"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-row>
              <v-col>
                <Label label="修了年月日" :editable="editable">
                  <InputDatepicker
                    name="completion_date"
                    :values="item"
                    :editable="editable"
                    validation_label="2020/05/19"
                    validation_rules=""
                    :flagNull="true"
                    :max="max"
                    @onInput="onInput({value: $event, index})"
                  />
                </Label>
              </v-col>
              <v-col>
                <Label label="有効期限年月日" :editable="editable">
                  <InputDatepicker
                    name="expiration_date"
                    :values="item"
                    :editable="editable"
                    :min="max"
                    :flagNull="true"
                    validation_label="2020/05/19"
                    validation_rules=""
                    @onInput="onInput({value: $event, index})"
                  />
                </Label>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <Label label="登録基幹技能者確認書類" :editable="editable">
              <span class="file"
                v-if="!editable && item['confirmation_document_name']"
                @click="viewSourceWithAuth(item['confirmation_document_name_url'])"
              >{{ item.confirmation_document_name }}
              </span>
              <FileInput
                :style="getFileInputStyle(index, 'confirmation_document_name', 'confirmation_document_name')"
                :resetValue="resetValue[`${index}_confirmation_document_name`]"
                v-if="editable"
                name="confirmation_document_name"
                :values="item"
                :editable="editable"
                placeholder="ファイルを選択"
                accept="image/jpg, image/png, image/jpeg, application/pdf"
                v-bind:validation_label="`${index}_confirmation_document_name`"
                validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf"
                @onInput="onInput({ index, value: $event })"
              />
              <span
                class="file"
                v-if="judgeDisplayFileInput(index, 'confirmation_document_name', 'confirmation_document_name')"
              >{{ item["confirmation_document_name"] }}
                <v-btn
                  small
                  icon
                  text
                  @click="
                    onRemoveFile({
                      name: 'confirmation_document_name',
                      index: index,
                    })
                  "
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </span>
            </Label>
          </v-list-item-content>
          <v-btn
            v-if="editable && index > 0"
            class="mt-8"
            icon
            @click="remove(index)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-list-item>
      </v-row>
    </v-container>
    <v-btn v-if="editable" color="primary" @click="addEmpty">追加</v-btn>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "@/components/forms/elements/Label";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import Checkbox from "@/components/forms/elements/Checkbox";
import Select from "@/components/forms/elements/Select";
import FileInput from "@/components/forms/elements/FileInput";
import InputText from "@/components/forms/elements/InputText";
import RadioGroup from "@/components/forms/elements/RadioGroup";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import { QUALIFICATION_STATUS } from "@/constants/PORTAL_CHART.js";
import { Store } from "@/store/Store";
import { viewSourceWithAuth } from "@/utils/viewSourceAuth";
import SelectSearch from "@/components/forms/elements/SelectSearch";

const INITIAL_VALUES = {
  id: null,
  cmn_mst_engineer_job_id: null,
  field_tree_contract_id: null,
  qualification_status: 1,
  completion_date: "",
  expiration_date: "",
  confirmation_document_name: ""
};

export default {
  data: () => {
    return {
      QUALIFICATION_STATUS,
      ENGINEER_JOB: [{ id: null, sub_catergory: ""},...Store.state.CmnMst.constants.entries.cmn_mst_engineer_jobs],
      items: [],
      resetValue: {},
      fileValid: {},
      viewSourceWithAuth: viewSourceWithAuth,
      max: new Date().toISOString().slice(0, 10)
    };
  },
  components: {
    SelectSearch,
    Label,
    InputText,
    InputDatepicker,
    Select,
    Checkbox,
    FileInput,
    RadioGroup,
    SectionLabel
  },
  props: {
    field_tree_contract_id: {
      type: Number
    },
    name: {
      type: String
    },
    values: {
      type: Object
    },
    editable: {
      type: Boolean
    },
    errors: {
      type: Object,
      default: {}
    }
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue, oldValue) => {
        if(newValue[0]){
          if (this.items?.length !== newValue[0][newValue[1]]?.length
            || JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
            this.items = newValue[0][newValue[1]];
            if (this.items?.length == 0) {
              this.addEmpty();
            }
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    // check input file error
    this.$watch(
      () => [this.items, this.errors],
      (data) => {
        const errors = { ...data[1] };
        // the validation_label list of the FileInput element
        const confirmationDocument = "confirmation_document_name";
        const fileValid = { ...this.fileValid };
        if (errors) {
          let index = 0;
          for (let name in errors) {
            if (name?.includes(confirmationDocument)) {
              fileValid[`${index}_confirmation_document_name`] =
                errors[`${index}_confirmation_document_name`].length <= 0
                  ? true
                  : false;
              index++;
            }
          }
        }
        this.fileValid = fileValid;
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  methods: {
    getRadioName(items, value) {
      return items.find((item) => item.value === value)?.name;
    },
    onRemoveFile({ name, index }) {
      const formValues = [...this.items];
      delete formValues[index][name];
      delete formValues[index][`${name}_data`];
      formValues[index][`${name}_deleted`] = 1;
      this.items = formValues;
      this.resetValue[`${index}_confirmation_document_name`] = !this.resetValue[`${index}_confirmation_document_name`];
      this.$emit("onInput", { name: this.name, value: formValues });
    },
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      const items = [...this.items];
      const itemEdited = { ...this.items[index] };
      if (value.fileName) {
        itemEdited[`${value.name}_data`] = value.value;
        itemEdited[value.name] = value.fileName;
        if (!value.value) {
          delete itemEdited[`${value.name}_deleted`];
        }
      } else {
        itemEdited[value.name] = value.value;
      }
      items[index] = itemEdited;
      this.$emit("onInput", { name: this.name, value: items });
    },
    remove(index) {
      const items = this.items.filter((item, i) => {
        return index != i;
      });
      this.resetValue[`${index}_confirmation_document_name`] = !this.resetValue[`${index}_confirmation_document_name`];
      this.$emit("onInput", { name: this.name, value: items });
    },
    addEmpty() {
      const items = [...this.items];
      let new_item = { ...INITIAL_VALUES };
      // field_tree_contract_id for new item
      new_item["field_tree_contract_id"] = this.field_tree_contract_id;
      items.push(new_item);
      this.items = items;
    },
    getFileInputStyle(index, subField, parentField) {
      return (this.fileValid[`${index}_${subField}`] && this.items[index][parentField]) ? 'display:none;' : '';
    },
    judgeDisplayFileInput(index,subField, parentField) {
      return this.editable && this.fileValid[`${index}_${subField}`] && this.items[index][parentField];
    },
  }
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
.col-sm-6.col-md-6.col-12 .v-list-item:last-child .v-list-item__content .container {
    margin-bottom: 30px;
}
</style>
