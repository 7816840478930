<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="必要資格の確認" :editable="editable">
                        <RadioGroup
                          v-if="editable"
                          name="required_qualifications_confirmation"
                          :values="formValues.field_tree_user_details"
                          :items="CONFIRMATION_OF_REQUIRED_QUALIFICATIONS"
                          :editable="editable"
                          @onInput="
                            onInput(
                              USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                              $event
                            )
                          "
                        />
                      </Label>
                      <span v-if="!editable">{{
                        getRadioName(
                          CONFIRMATION_OF_REQUIRED_QUALIFICATIONS,
                          formValues
                        )
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <SwitchInput
                        name="health_check_confirmation"
                        label="健康診断の確認"
                        :values="fieldTreeUserDetails"
                        :editable="editable"
                        @onInput="
                          onInput(
                            USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                            $event
                          )
                        "
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <Label label="通勤方法" :editable="editable">
                            <Select
                              name="commute_method"
                              :values="fieldTreeUserDetails"
                              :items="COMMUTE_METHOD_ARRAY"
                              :editable="editable"
                              @onInput="
                                onInput(
                                  USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                                  $event
                                )
                              "
                            />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <SwitchInput
                        name="protective_cap_flag"
                        :label="`保護帽（全員）`"
                        :values="fieldTreeUserDetails"
                        :editable="editable"
                        @onInput="
                          onInput(
                            USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                            $event
                          )
                        "
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <SwitchInput
                        name="safety_shoes_flag"
                        :label="`安全靴`"
                        :values="fieldTreeUserDetails"
                        :editable="editable"
                        @onInput="
                          onInput(
                            USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                            $event
                          )
                        "
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <SwitchInput
                        name="safety_belt_flag"
                        label="安全帯"
                        :values="fieldTreeUserDetails"
                        :editable="editable"
                        @onInput="
                          onInput(
                            USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                            $event
                          )
                        "
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <SwitchInput
                        name="welding_helmet_flag"
                        label="溶接用遮光面"
                        :values="fieldTreeUserDetails"
                        :editable="editable"
                        @onInput="
                          onInput(
                            USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                            $event
                          )
                        "
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <SwitchInput
                        name="welding_gloves_flag"
                        label="溶接用手袋"
                        :values="fieldTreeUserDetails"
                        :editable="editable"
                        @onInput="
                          onInput(
                            USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                            $event
                          )
                        "
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <SwitchInput
                        name="protective_eyewear_flag"
                        label="保護メガネ"
                        :values="fieldTreeUserDetails"
                        :editable="editable"
                        @onInput="
                          onInput(
                            USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                            $event
                          )
                        "
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <SwitchInput
                        name="safety_and_health_manager_flag"
                        label="電動ファン付マスク"
                        :values="fieldTreeUserDetails"
                        :editable="editable"
                        @onInput="
                          onInput(
                            USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                            $event
                          )
                        "
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <SwitchInput
                        name="mask_with_electric_fan_flag"
                        label="防振手袋"
                        :values="fieldTreeUserDetails"
                        :editable="editable"
                        @onInput="
                          onInput(
                            USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                            $event
                          )
                        "
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <SwitchInput
                        name="earplug_flag"
                        label="耳栓"
                        :values="fieldTreeUserDetails"
                        :editable="editable"
                        @onInput="
                          onInput(
                            USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                            $event
                          )
                        "
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <SwitchInput
                        name="reflective_vest_flag"
                        label="反射ベスト"
                        :values="fieldTreeUserDetails"
                        :editable="editable"
                        @onInput="
                          onInput(
                            USER_EVENT_TARGET.FIELD_TREE_USER_DETAILS,
                            $event
                          )
                        "
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "@/components/forms/elements/Label";
import Select from "@/components/forms/elements/Select";
import InputText from "@/components/forms/elements/InputText";
import RadioGroup from "@/components/forms/elements/RadioGroup";
import SwitchInput from "@/components/forms/elements/SwitchInput";
import {
  CONFIRMATION_OF_REQUIRED_QUALIFICATIONS,
  COMMUTE_METHOD_ARRAY,
} from "@/constants/PORTAL_CHART.js";

const USER_EVENT_TARGET = {
  FIELD_TREE_USER_DETAILS: "field_tree_user_details",
};

export default {
  data: () => {
    return {
      formValues: {},
      USER_EVENT_TARGET,
      fieldTreeUserDetails: {},
      CONFIRMATION_OF_REQUIRED_QUALIFICATIONS,
      COMMUTE_METHOD_ARRAY,
    };
  },
  components: {
    Label,
    Select,
    InputText,
    RadioGroup,
    SwitchInput,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
        this.fieldTreeUserDetails = {
          ...this.formValues.field_tree_user_details,
        };
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    getRadioName(items, value) {
      if (value) {
        return items.find(
          (item) =>
            item.value ==
            value?.field_tree_user_details?.required_qualifications_confirmation
        )?.name;
      }
      return "";
    },
    emit(key, value) {
      const objNew = {};
      objNew[key] = value;
      this.$emit("formUpdate", objNew);
    },
    onInput(parents_name, { name, value }) {
      const formValues = { ...this.formValues[parents_name] };
      formValues[name] = value;
      this.emit(parents_name, formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
</style>
