<template>
  <div>
    <v-container>
      <v-row v-for="(item, index) in items" :key="index" class="pb-4">
        <v-col cols="12" sm="10" md="10">
          <v-row class="mt-1">
            <v-col cols="12" sm="10" md="10" class="pt-0 pb-0">
              <Label label="デバイス名" :editable="editable" :required="getRequiredRules(item,'label')">
                <InputText
                  name="device_name"
                  :vid="randomId(index,'device_name')"
                  :values="item"
                  :editable="editable"
                  validation_label="デバイス名"
                  :validation_rules="`max:255|${getRequiredRules(item)}`"
                  @onInput="onInput({ value: $event, index })"
                />
              </Label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="5" md="5" class="pt-0 pb-0">
              <Label label="ログインID" :editable="editable" :required="getRequiredRules(item,'label')">
                <InputText
                  name="device_id"
                  :vid="randomId(index,'device_id')"
                  :values="item"
                  :editable="editable"
                  validation_label="ログインID"
                  :validation_rules="`max:30|check-device-id:${item.device_id}|${getDuplicateRules(item)}|${getRequiredRules(item)}|halfsize`"
                  @onInput="onInput({ value: $event, index })"
                />
              </Label>
            </v-col>
            <v-col cols="12" sm="5" md="5" class="pt-0 pb-0">
              <Label label="パスワード" :editable="editable" :required="getRequiredRules(item,'label')">
                <InputText
                  name="password"
                  :vid="randomId(index,'password')"
                  :values="item"
                  :editable="editable"
                  validation_label="パスワード"
                  :validation_rules="`half-size-password|max:30|${getRequiredRules(item)}`"
                  @onInput="onInput({ value: $event, index })"
                  @onClick="onClick({ index })"
                />
              </Label>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="2" md="2" class="pt-0 pb-0">
          <v-btn
            v-if="index > 0 && editable"
            class="mt-8"
            icon
            @click="remove(index)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-btn @click="addEmpty" color="primary" v-if="editable" class="mt-2"
      >追加</v-btn
    >
  </div>
</template>
<script>

import Label from "../../elements/Label.vue";
import InputText from "../../elements/InputText.vue";
import RadioGroup from "../../elements/RadioGroup";
import SectionLabel from "@/components/forms/elements/SectionLabel";
//初期値を設定します
const INITIAL_VALUES = {
  device_name: null,
  device_id: null,
  password: null,
};

export default {
  data: () => {
    return {
      items: [],
      passwordShow: false,
    };
  },
  components: {
    Label,
    InputText,
    RadioGroup,
    SectionLabel,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.items = newValue[0][newValue[1]];
          if (!this.items || this.items?.length == 0) {
            this.addEmpty();
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    randomId(index,name) {
      return `${index}_${name}`;
    },
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      let _items = [...this.items];
      _items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    onClick({ index }) {
      let _items = [...this.items];
      if(_items[index]['password']?.indexOf('●') != -1) {
        _items[index]['password'] = '';
      }
      this.items = _items;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    getDuplicateRules(data) {
      if(this.items && this.items.length > 1) {
        let isDuplicate = true;
        let deviceId = data.device_id;
        let deviceFilter = this.items.filter(item => deviceId === item.device_id);
        if (deviceFilter && deviceFilter.length > 1) {
          isDuplicate = false;
        }
        return `duplicate-device-id:${isDuplicate}`;
      }
      return "";
    },
    getRequiredRules(item,options) {
      if(item.device_name || item.device_id || item.password){
        if(options == 'label') {
          return true;
        }else{
          return 'required';
        }
      }
      return;
    },
    getRadioName(items, value) {
      return items?.find((item) => item.value ==  value)?.name;
    },
  },
};
</script>
