<template>
  <div class="wrapper">
    <Select
      :name="name"
      :values="values"
      item_value="field_tree_id"
      item_text="company_name"
      :items="items"
      :editable="true"
      @onInput="onInput"
    />
  </div>
</template>
<script>
import Select from "@/components/forms/elements/Select.vue";
import { Store } from "@/store/Store.js";

export default {
  data: () => {
    return {
      items: [],
    };
  },
  props: {
    name: {
      type: String,
    },
    constructionId: {
      type: Number,
    },
    values: {
      type: Object,
    },
  },
  components: {
    Select,
  },
  mounted() {
    /**
     * 現場idから協力会社を取得
     */
    this.$watch(
      () => this.constructionId,
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          if (newValue) {
            this.getListCompanyPartner(newValue);
          } else {
            this.items = [];
          }
        }
      },
      { immediate: true, deep: true }
    );

    this.$watch(
      () => Store.getters["PortalChart/getListCompanyPartner"],
      (newValue) => {
        this.items = [{ field_tree_id: null, company_name: ""}, ...newValue];
        this.onInput({ name: this.name, value: this.items[0]?.field_tree_id });
      },
      {
        deep: true
      }
    );
  },
  methods: {
    onInput({ name, value }) {
      this.$emit("onInput", { name, value });
    },
    async getListCompanyPartner(value) {
      const params = {
        field_construction_id : value,
        display_branch_name_flag : 1
      };
      await Store.dispatch("PortalChart/getListCompanyPartner", { params });
    }
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  height: 40px;
}
</style>
