import { format } from "date-fns";

// テーブル設定
const PATROLS_TABLE_LABELS = [
  {
    text: "ステータス",
    align: "left",
    value: "status_code",
    width: "10%"
  },
  {
    text: "日付",
    value: "patrol_record_date",
    align: "left",
    width: "7%"
  },
  {
    text: "記入者",
    value: "detect_user",
    align: "left",
    width: "7%"
  },
  {
    text: "緊急度",
    value: "urgency",
    align: "left",
    width: "5%"
  },
  {
    text: "内容",
    value: "pointing_out_detail",
    align: "left",
    width: "25%"
  },
  {
    text: "場所",
    value: "field_items",
    align: "left",
    width: "10%"
  },
  {
    text: "対応者",
    value: "recovery_user",
    align: "left",
    width: "8%"
  },
  {
    text: "対応日",
    value: "recovery_user",
    align: "left",
    width: "7%"
  },
  {
    text: "対応コメント",
    value: "recovery_date",
    align: "left",
    width: "16%"
  },
  {
    text: "写真",
    value: "image_flag",
    align: "left",
    width: "5%"
  }
];
const PATROL_RECORD_STATUS = {
  UNCORRECTED: { id: 0, name: "未是正" },
  CORRECTED: { id: 1, name: "是正済" },
  CONTACT: { id: 2, name: "連絡" }
};

const STATUS_CODE = {
  REJECT: { id: 0, name: "指摘未是正" },
  APPROVE: { id: 1, name: "是正承認済" },
  OTHER: { id: 2, name: "各種連絡" }
};

const PATROL_SORT_ITEMS = [
  { id: "entries_date", name: "経過日数" },
  { id: "urgency", name: "緊急度" }
];

const DIALOG_TEXT = {
  REJECT_TITLE: "承認解除確認",
  REJECT_TEXT1: "選択した巡回記録の承認を解除します。",
  REJECT_TEXT2: "承認を解除してもよろしいですか。",
  REJECT_BTN_SUBMIT: "解除",
  APPROVE_TITLE: "承認確認",
  APPROVE_TEXT1: "選択した巡回記録を承認します。",
  APPROVE_TEXT2: "承認してもよろしいですか。",
  APPROVE_BTN_SUBMIT: "承認",
  ALL_APPROVE_TITTLE: "承認確認",
  ALL_APPROVE_TEXT:
    "選択した巡回記録が 「承認済」、「共通連絡」、「安全巡視」、「未是正」、「差戻し」になる場合は承認できません。",
  ALL_REJECT_TITTLE: "承認解除確認",
  ALL_REJECT_TEXT:
    "選択した巡回記録が全て 「承認済」、「共通連絡」、「安全巡視」、「未是正」、「差戻し」になる場合は承認解除できません。",
  BTN_OK: "OK"
};

const DIALOG_REMOVE_TEXT = {
  TITLE: "削除確認",
  TEXT_ALL_APPROVE_1: "選択された巡回記録は全て承認済みです。",
  TEXT_ALL_APPROVE_2: "削除できません。",
  BTN_OK: "OK",
  TEXT_1: "選択したデータを削除します。",
  TEXT_2: "未承認のデータのみ削除できます。",
  TEXT_3: "この処理を元に戻すことはできません。",
  TEXT_4: "本当に削除してもよろしいですか？",
  BTN_SUBMIT: "削除"
};

const INITIAL_ITEM_CONTACT_FORM = {
  safety_diaries: {
    entries_date: format(new Date(), "yyyy/MM/dd"),
    field_construction_id: null,
    work_hour_zone: null,
    field_tree_id: null,
    group_id: null,
    cmn_mst_safety_diary_journal_type_id: null,
    detect_user_id: null,
    field_item_tree_id: null,
    print_diary_flag: 1,
    cmn_mst_safety_diary_type_id: 2,
    urgency: 1,
    mail_notification_flag: 1,
    pointing_out_detail: null,
    user_notifications: [],
    field_tree_notifications: [],
    group_notifications: []
  },
  safety_diary_comment_corrections: [
    {
      field_item_tree_id: null,
      pointing_out_detail: null,
      status_code: 0,
      recovery_flag: 0,
      safety_diary_images: []
    }
  ]
};

const INITIAL_ITEM_MULTIPLE_SHITEKI_FORM = {
  safety_diaries: {
    entries_date: format(new Date(), "yyyy/MM/dd"),
    field_construction_id: null,
    work_hour_zone: null,
    field_tree_id: null,
    group_id: null,
    cmn_mst_safety_diary_journal_type_id: null,
    detect_user_id: null,
    field_item_tree_id: null,
    print_diary_flag: 1,
    cmn_mst_safety_diary_type_id: 1,
    urgency: 1,
    mail_notification_flag: 1,
    mail_reply_one_notification_flag: 1,
    mail_reply_all_notification_flag: 1,
    pointing_out_detail: null,
    user_notifications: [],
    field_tree_notifications: [],
    group_notifications: [],
    patrol_record_name: null
  },
  safety_diary_comment_corrections: [

  ]
};
const INITIAL_ITEM_SHITEKI_FORM = {
  safety_diaries: {
    entries_date: format(new Date(), "yyyy/MM/dd"),
    field_construction_id: null,
    work_hour_zone: null,
    field_tree_id: null,
    group_id: null,
    cmn_mst_safety_diary_journal_type_id: null,
    detect_user_id: null,
    field_item_tree_id: null,
    print_diary_flag: 1,
    cmn_mst_safety_diary_type_id: 1,
    urgency: 1,
    mail_notification_flag: 1,
    mail_reply_one_notification_flag: 1,
    pointing_out_detail: null,
    user_notifications: [],
    field_tree_notifications: [],
    group_notifications: [],
    patrol_record_name: null
  },
  safety_diary_comment_corrections: [
    {
      field_item_tree_id: null,
      pointing_out_detail: null,
      recovery_user_id: null,
      recovery_user_name: null,
      recovery_content: "",
      status_code: 0,
      recovery_flag: 0,
      safety_diary_images: []
    }
  ]
};

const URGENCY_CLASSIFICATION_SELECT = [
  { name: "", value: null },
  { name: "低", value: 0, color: "#29ABE2" },
  { name: "中", value: 1, color: "#1B9C4F" },
  { name: "高", value: 2, color: "#FF7B52" },
  { name: "急", value: 3, color: "#B00020" }
];

const URGENCY_CLASSIFICATION_RADIO = [
  { name: "低", value: 0 },
  { name: "中", value: 1 },
  { name: "高", value: 2 },
  { name: "緊急", value: 3 }
];

const IMAGE_TYPE = {
  IMAGE_SAFETY: 1,
  IMAGE_RECOVERY: 2
};

const MENU_FORM_PATROL = {
  SHITEKI: { id: 1, name: "指摘事項" },
  MULTIPLE_SHITEKI: { id: 2, name: "複数指摘" },
  CONTACT: { id: 3, name: "連絡事項" }
};

const FORM_SHITEKI_TABS = {
  ShitekiFormPage1: { id: 1, title: "基本情報" },
  ShitekiFormPage2: { id: 2, title: "指摘記録" }
};

const FORM_CONTACT_TABS = {
  ContactFormPage1: { id: 1, title: "基本情報" },
  ContactFormPage2: { id: 2, title: "連絡内容" }
};

const STATUS_CODE_ARRAY = [
  { name: "未是正", id: 1 },
  { name: "一部是正", id: 2 },
  { name: "是正済", id: 3 },
  { name: "承認済", id: 4 },
  { name: "差戻し", id: 7 },
  { name: "共通連絡", id: 5 },
  { name: "安全巡視", id: 6 }
];

const TYPE_ARRAY_CONTACTS = [
  { type: 2, value: "共通連絡" },
  { type: 3, value: "安全巡視" }
];

const STATUS_OBJECT = {
  EX1: { status: 1, color: "#B00020", status_code: "未是正" },
  EX2: { status: 2, color: "#FF7B52", status_code: "一部是正" },
  EX3: { status: 3, color: "#E5951D", status_code: "是正済" },
  EX4: { status: 4, color: "#1b9c4f", status_code: "承認済" },
  EX5: { status: 5, color: "rgba(0, 0, 0, 0.38)", status_code: "共通連絡" },
  EX6: { status: 6, color: "rgba(0, 0, 0, 0.38)", status_code: "安全巡視" },
  EX7: { status: 7, color: "#FF7B52", status_code: "差戻し" }
};

const MODE_ACTION = {
  DELETE: 1,
  APPROVE: 2,
  REJECT: 3
};

const PATROL_TYPE_ALL = [
  { id: null, type: "" },
  { id: 1, type: "指摘" },
  { id: 3, type: "安全巡視" },
  { id: 2, type: "共通連絡" }
];

const TYPE = {
  TYPE1: 1,
  TYPE2: 2
};

const DIALOG_APPROVE_TEXT = {
  title: "承認確認",
  text1: "是正されていないため承認できません。",
  text2: null,
  btnSubmitText: "OK",
  flg: false
};

const DIALOG_REJECT_TEXT = {
  title: "否認通知",
  text1: "修正すべき項目と修正内容を可能な限り具体的に記載し、「差戻す」を押してください。",
  text2: null,
  btnSubmitText: "差戻す",
  flg: false
};

const DIALOG_APPROVE_TEXT_ALL = {
  title: "一括承認確認",
  text1: "是正されていないため承認できません。",
  text2: null,
  btnSubmitText: "OK",
  flg: true
};

const DIALOG_APPROVE_TEXT_OK = {
  title: "一括承認確認",
  text1: "全ての指摘を承認します。",
  text2: "承認してもよろしいですか。",
  btnSubmitText: "承認",
  flg: false
};

const DIALOG_RECOVERY_TEXT = {
  title: "報告",
  text1: "巡回記録記入者に是正報告します。",
  text2: null,
  btnSubmitText: "報告",
  flg: false
};

const DIALOG_APPROVE_TEXT_ALL_ERROR = {
  title: "一括承認確認",
  text1: "承認済みまたは否認した巡回記録は承認できません。",
  text2: null,
  btnSubmitText: "OK",
  flg: true
};

const NO_IMAGE = "/images/noimage.png"

export {
  PATROLS_TABLE_LABELS,
  PATROL_SORT_ITEMS,
  PATROL_RECORD_STATUS,
  DIALOG_TEXT,
  DIALOG_REMOVE_TEXT,
  INITIAL_ITEM_SHITEKI_FORM,
  INITIAL_ITEM_CONTACT_FORM,
  URGENCY_CLASSIFICATION_SELECT,
  URGENCY_CLASSIFICATION_RADIO,
  STATUS_CODE,
  IMAGE_TYPE,
  MENU_FORM_PATROL,
  FORM_SHITEKI_TABS,
  STATUS_CODE_ARRAY,
  TYPE_ARRAY_CONTACTS,
  STATUS_OBJECT,
  MODE_ACTION,
  INITIAL_ITEM_MULTIPLE_SHITEKI_FORM,
  PATROL_TYPE_ALL,
  TYPE,
  DIALOG_APPROVE_TEXT,
  DIALOG_APPROVE_TEXT_ALL,
  DIALOG_APPROVE_TEXT_OK,
  DIALOG_RECOVERY_TEXT,
  DIALOG_APPROVE_TEXT_ALL_ERROR,
  NO_IMAGE,
  DIALOG_REJECT_TEXT,
  FORM_CONTACT_TABS,
};
