import Vue from "vue";
import Vuex from "vuex";
import { cmnMst } from "../../../api/modules/cmnMst";

Vue.use(Vuex);

export const CmnMst = {
  namespaced: true,

  state: {
    constants: {},
  },

  mutations: {
    SET_CONSTANTS(state, payload) {
      state.constants = { ...payload };
    },
  },

  actions: {
    async get({ commit }, payload) {
      const response = await cmnMst.get(payload);
      console.log("CmnMst",response);
      if( !response.hasError ) commit("SET_CONSTANTS", response.data.contents);
      return response;
    },
  },

  getters: {
    get: (state) => {
      return state.constants;
    },
  },
};
