<template>
  <div class="chatviewWrapper">
    <div class="chatview">
      <!-- 
      メッセージ 
      このリストが画面にきっちりと収まっている
    -->
      <v-list ref="chatTable" id="chatTable" class="chatview__list" two-line>
        <div v-if="isShowLoaderTop" ref="loaderTop" class="loaderTop">
          <p class="loaderTop_text">Loading...</p>
        </div>
        <MessageItem
          v-for="(item, index) in messages"
          :item="item"
          :key="index"
          :self="user"
          frameId="chatTable"
          @showMessageAttachFile="showMessageAttachFile"
          @showReadUsers="showReadUsers"
        />
      </v-list>
      <!-- フォーム -->
      <div class="chatform">
        <textarea
          v-model="message"
          class="chatform__textarea"
          rows="3"
        ></textarea>
        <div class="chatform__footer">
          <div class="footer__attachFiles">
            <div v-for="file in attachFiles" :key="file.name">
              <AttachFilePreviewCell
                :file="file"
                isRemove
                @removeAttachFile="removeAttachFile"
                @showAttachFile="showAttachFile"
              />
            </div>
          </div>
          <div class="footer__menu">
            <v-btn
              class="btnAlert"
              :class="isImportant ? 'important' : ''"
              icon
              @click="onToggleImportant"
              ><v-icon class="primary--text">mdi-alert-circle</v-icon></v-btn
            >
            <v-btn icon>
              <label for="attachFile">
                <v-icon class="primary--text">mdi-attachment</v-icon>
              </label>
              <input
                id="attachFile"
                type="file"
                style="display: none"
                ref="input_drawing_file"
                accept="image/jpeg, image/jpg, image/png, application/pdf"
                @change="onChangeFile"
              />
            </v-btn>
            <v-btn icon @click="post" :disabled="disablePostMessage">
              <v-icon class="primary--text">mdi-send-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <Popup
      :width="`${selectedAttachFile.size}`"
      :dialog="selectedAttachFile.isShow"
    >
      <ConfirmAttachFile
        :file="selectedAttachFile.file"
        v-if="selectedAttachFile.isShow"
        @close="closeConfirmAttachFile"
      />
    </Popup>
    <Popup :width="`100%`" :dialog="selectedPostedAttachedFile.isShow">
      <ConfirmPostedAttachFile
        :file="selectedPostedAttachedFile.file"
        v-if="selectedPostedAttachedFile.isShow"
        @close="closeConfirmPostedAttachFile"
      />
    </Popup>
    <Popup :width="`100%`" :dialog="popupUsers.isShow">
      <PopupReadUsers
        :users="popupUsers.users"
        :title="popupUsers.title"
        @close="closePopupReadUsers"
      />
    </Popup>
  </div>
</template>
<script>
import * as _ from "lodash";
import { Store } from "@/store/Store.js";
import { sortByDate } from "@/utils/sortByDate.js";
import MessageItem from "./components/MessageItem.vue";
import Popup from "@/components/common/Popup.vue";
import ConfirmAttachFile from "./components/ConfirmAttachFile.vue";
import ConfirmPostedAttachFile from "./components/ConfirmPostedAttachFile.vue";
import {
  GET_MESSAGES_PARAMS,
  TYPE_ATTACH_FILE,
} from "@/store/modules/api/TextChat.js";
import AttachFilePreviewCell from "./components/AttachFilePreviewCell.vue";
import PopupReadUsers from "./components/ReadUsers.vue";
import { textChat } from "@/api/modules/textChat";
import { fileCache } from "@/utils/fileCache";
import { getParamsOfUrlReadFile } from "@/utils/viewSourceAuth";

// iOS safari のバーチャルキーボード対応
// https://fintan.jp/?p=5234

const SOCKET_CALLBACKS = {
  UPDATE: "update",
  READ: "read",
  ENTRANCE: "entrance",
};

// ソケット監視
const SocketController = () => {
  // ソケットの連続リッスンを遅延実行
  let callbackTimerUpdate;
  let callbackTimerRead;
  let callbackTimerEntrance;

  const initListen = (chat_room, callback) => {
    console.log("■ SOCKET init ", chat_room);
    window.Echo.channel(chat_room).listen("MessageUpdate", (e) => {
      console.log("■ SOCKET MessageUpdate ", chat_room);
      clearTimeout(callbackTimerUpdate);
      callbackTimerUpdate = setTimeout(() => {
        callback(SOCKET_CALLBACKS.UPDATE, e.chat_room_id, e);
      }, 500);
    });

    window.Echo.channel(chat_room).listen("MessageRead", (e) => {
      console.log("■ SOCKET MessageRead ", chat_room);
      clearTimeout(callbackTimerRead);
      callbackTimerRead = setTimeout(() => {
        callback(SOCKET_CALLBACKS.READ, e.chat_room_id, e);
      }, 500);
    });

    window.Echo.channel(chat_room).listen("UserEntrance", (e) => {
      console.log("■ SOCKET UserEntrance ", chat_room);
      clearTimeout(callbackTimerEntrance);
      callbackTimerEntrance = setTimeout(() => {
        callback(SOCKET_CALLBACKS.ENTRANCE, e.chat_room_id, e);
      }, 500);
    });
  };

  const addRoomListen = (roomId, callback) => {
    initListen(roomId, callback);
  };

  return {
    addRoomListen,
  };
};

export default {
  data: () => {
    return {
      PAGE_TITLE: "チャット",
      // ログインユーザ
      user: null,

      //一番古いメッセージid
      oldestMessageId: null,

      //既読ユーザー or ルームユーザー
      popupUsers: {
        isShow: false,
        title: "",
        users: null,
      },

      //パラメータ
      // chat_room_id: null,
      messages: [],
      attachFiles: [],
      message: "",
      requestParams: TYPE_ATTACH_FILE,
      isImportant: false,

      isLoading: true, // ローディング中
      isLatest: true, // 最新メッセージ表示状態
      isGettingOld: false, // 古いメッセージを取得中
      isShowLoaderTop: false, //追加ロード領域

      isPosting: false, // 送信中

      //投稿画像の詳細表示
      selectedPostedAttachedFile: {
        isShow: false,
        file: null,
        size: null, // 未使用
      },

      //添付ファイルを表示
      selectedAttachFile: {
        isShow: false,
        file: null,
        size: 0,
      },
    };
  },

  props: {
    userId: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
      default: "",
    },
    chat_room_id: {
      type: Number,
      default: 1,
    },
  },

  components: {
    MessageItem,
    Popup,
    ConfirmAttachFile,
    ConfirmPostedAttachFile,
    AttachFilePreviewCell,
    PopupReadUsers,
  },
  computed: {
    disablePostMessage() {
      return (
        this.isPosting || (this.message === "" && this.attachFiles.length === 0)
      );
    },
  },

  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    // ルームidが変わったら初期化
    // ユーザーを取得
    this.$watch(
      () => [this.chat_room_id, Store.getters["Login/getUser"]],
      (newValue, oldValue) => {
        // id変わったらデータ削除
        if (newValue[0] && newValue[0] !== oldValue[0]) {
          this.reset();

          //ソケット追加
          const chat_room = `chat_room_${this.chat_room_id}`;
          SocketController().addRoomListen(chat_room, this.socketCallback);
        }
        // 自分を取得したらルーム一覧を取得
        if (newValue[1] && "id" in newValue[1]) {
          this.user = newValue[1];
          this.initRooms();
        }
      },
      { deep: true }
    );

    // メッセージ更新
    this.$watch(
      () => [
        this.chat_room_id,
        Store.getters["TextChat/getMessages"](this.chat_room_id),
      ],
      (newValue, oldValue) => {
        const chat_room_id = newValue[0];
        const getMessages = newValue[1];
        const getMessagesOld = oldValue[1];

        // メッセージ取得
        if (
          chat_room_id &&
          getMessages &&
          "messages" in getMessages &&
          getMessages.messages.length
        ) {
          // メッセージ数が同じ場合はリロードを停止
          if (
            getMessagesOld &&
            getMessages.messages.length === getMessagesOld.messages.length
          ) {
            this.isShowLoaderTop = false;
          }

          // メッセージ更新
          const _messages = getMessages.messages.map((item) => {
            return item;
          });
          this.messages = sortByDate(_messages).reverse();

          setTimeout(() => {
            // 過去メッセージでない場合は先頭へ
            if (!this.isGettingOld) {
              this.goLatest();
              //ローダー表示
              this.isShowLoaderTop = true;
            } else {
              // 過去メッセージある場合はスクロールを固定
              const mid = `message_${this.oldestMessageId}`;
              const rect = document.getElementById(mid).getBoundingClientRect();
              const chatTable = this.$refs.chatTable;
              const top = rect.top - 48;
              chatTable.$el.scrollTop = top;
            }
            this.isLoading = false;
            //一番古いメッセージidを保持
            this.oldestMessageId = this.messages[0].message_id;
          }, 100);
        }
      },
      { deep: true }
    );

    // ルームのユーザを取得
    // -> 画像をロード
    // -> 完了したらメッセージを表示
    this.$watch(
      () => Store.getters["TextChat/getRoomUsers"](this.chat_room_id),
      async (newValue) => {
        if (newValue && newValue.length) {
          const urls = newValue
            .map((item) => item.user_image_url)
            .filter((item) => item !== null);
          await fileCache.getFiles(urls);
          this.init();
        }
      },
      {
        deep: true,
      }
    );

    // 過去のメッセージを取得
    this.$nextTick(() => {
      const chatTable = this.$refs.chatTable;
      chatTable.$el.addEventListener("scroll", () => {
        const tableRect = chatTable.$el.getBoundingClientRect();
        if (this.$refs.loaderTop) {
          const rect = this.$refs.loaderTop.getBoundingClientRect();
          const loadeY = rect.top - tableRect.top;
          if (!this.isLoading && loadeY > 0) {
            this.getOld();
          }
        }
      });
    });
  },

  methods: {
    // 初期化
    async init() {
      this.isGettingOld = false;
      // console.log("■■■■■ init");
      this.requestParams = {
        ...GET_MESSAGES_PARAMS,
        ...{ chat_room_id: this.chat_room_id, start_message_id: null },
      };
      await Store.dispatch("TextChat/get", this.requestParams);
    },

    // ルーム一覧を取得
    async initRooms() {
      // ルームを取得
      const params = {
        user_id: this.user.id || 1,
      };
      await Store.dispatch("TextChat/rooms", params);
    },

    // 値をリセット
    reset() {
      this.isGettingOld = false;
      this.isLatest = true;
      this.isShowLoaderTop = false;
      this.messages = [];
      this.attachFiles = [];
      this.popupUsers = {
        isShow: false,
        title: "",
        users: null,
      };
      this.selectedAttachFile = {
        isShow: false,
        file: null,
        size: 0,
      };
    },

    socketCallback(TYPE, id, result) {
      if (this.chat_room_id === id) {
        // 新しいメッセージ更新
        if (TYPE === SOCKET_CALLBACKS.UPDATE) {
          this.init();
        }

        // 既読更新
        if (TYPE === SOCKET_CALLBACKS.READ) {
          const { chat_room_id, message_id } = result;
          Store.dispatch("TextChat/readUsers", { chat_room_id, message_id });
        }
      }
    },

    //最新を表示
    goLatest() {
      const chatTable = this.$refs.chatTable;
      chatTable.$el.scrollTop = chatTable.$el.scrollHeight;
    },
    //古いメッセージを取得
    async getOld() {
      this.isGettingOld = true;
      this.isLoading = true;
      this.requestParams = {
        ...GET_MESSAGES_PARAMS,
        ...{
          chat_room_id: this.chat_room_id,
          start_message_id: this.messages[this.messages.length - 1].message_id,
          get_old: true,
        },
      };
      await Store.dispatch("TextChat/get", this.requestParams);
    },
    // メッセージ送信
    async post() {
      // 送信中、空メッセージ（添付ファイルも空)では送信しない
      if (
        this.isPosting ||
        (this.message === "" && this.attachFiles.length === 0)
      )
        return;
      // 送信中
      this.isPosting = true;
      //重要
      const status_flg = this.isImportant ? 1 : 0;
      // メッセージ
      // パラメータ作成
      const params = {
        chat_room_id: this.chat_room_id,
        user_id: this.userId || 1,
        message: this.message,
        status_flg,
      };

      const resultMessage = await Store.dispatch(
        "TextChat/postMessage",
        params
      );
      // 送信終了
      this.isPosting = false;
      if (resultMessage.hasError) {
        Store.dispatch("Error/show", {
          status: 200,
          message: "送信できませんでした",
        });
        return;
      }

      // 成功したら削除
      this.message = "";
      const message_id = resultMessage.data.contents.entries.message_id;

      // ファイル送信
      if (this.attachFiles.length) {
        const resultFiles = await Store.dispatch("TextChat/postFiles", {
          chat_room_id: this.chat_room_id,
          message_id: message_id,
          files: this.attachFiles,
        });
        if (resultFiles.hasError) {
          Store.dispatch("Error/show", {
            status: 200,
            message: "送信できませんでした",
          });
          return;
        } else {
          //ファイル削除
          this.attachFiles = [];
        }
      }

      //既読
      //自分は既読
      await textChat.read({
        user_id: this.user.id,
        chat_room_id: this.chat_room_id,
        message_ids: [message_id],
      });

      // 完了
      this.message = "";
      this.isLatest = true;
      this.requestParams = {
        ...GET_MESSAGES_PARAMS,
        ...{ chat_room_id: this.chat_room_id },
      };
      await Store.dispatch("TextChat/get", this.requestParams);
    },
    // ファイル添付
    async onChangeFile(evt) {
      const file = evt.target.files[0];
      if (!file) return;

      let base64 = await this.getBase64(file);

      // 拡張子取得
      const fileType = file.name
        .toLocaleLowerCase()
        .match(/\.(jpg|jpeg|png|pdf)$/);

      // 形式を揃える
      // const type = fileType[1] === "jpeg" ? "jpg" : fileType[1];

      // type は image | pdf
      const type = ["jpeg", "jpg", "png"].includes(fileType[1])
        ? "image"
        : "pdf";

      // 画像かどうか
      const isImage = type !== "pdf";

      // プレビューサイズ
      let previewSize = { width: `100%`, height: `100%` };
      // 画像はサイズ取得
      if (isImage) {
        previewSize = await this.getImageSize(base64);
      }

      // pdfはMIMEを削除
      if (base64.match(/^data:application\/pdf;base64,.*/)) {
        const data = base64.split("base64,");
        base64 = data[1];
        console.log("■■ base64 pdf", base64);
      }

      const param = {
        ...TYPE_ATTACH_FILE,
        filename: file.name,
        type,
        data: base64,
        id: _.uniqueId(),
        size: previewSize || null,
      };

      // 配列に追加
      let attachFiles = [...this.attachFiles];
      attachFiles.push(param);
      this.attachFiles = attachFiles;

      //フォームをクリア
      evt.target.value = null;
    },
    // ファイルを削除
    removeAttachFile({ id }) {
      let attachFiles = [...this.attachFiles];
      attachFiles = attachFiles.filter((item) => {
        return item.id !== id;
      });
      this.attachFiles = attachFiles;
    },
    // 画像を確認
    showAttachFile({ id }) {
      let attachFile = this.attachFiles.find((item) => {
        return item.id === id;
      });
      console.log("--selectedAttachFile", attachFile);
      let selectedAttachFile = { ...this.selectedAttachFile };
      selectedAttachFile.isShow = true;
      selectedAttachFile.size = `${attachFile.size.width}px`;
      selectedAttachFile.file = attachFile;
      this.selectedAttachFile = selectedAttachFile;
    },

    // メッセージの添付画像
    async showMessageAttachFile({ messageId, fileId }) {
      /**
       *
       */

      const message = this.messages.find((message) => {
        return message.message_id == messageId;
      });

      const file = message.files.find((file) => file.id === fileId);
      const { type } = file;

      if ("image_url" in file) {
        const sourceUrl = file.image_url;
        const params = getParamsOfUrlReadFile(sourceUrl);

        let response;
        let data;

        // pdfはbase64で返る
        if (type === "pdf") {
          response = await Store.dispatch("TextChat/readPdf", params);
          if (!response.hasError) {
            // ヘッダーが壊れているので置き換え
            data = response.data?.contents.replace(
              "dataapplication/pdfbase64",
              "data:application/pdf;base64,"
            );
            // MIMEがなければ付与
            if (!data.match(/^data:application\/pdf;base64,.*/)) {
              data = `data:application/pdf;base64,${data}`;
            }
          }
        } else {
          response = await Store.dispatch("File/readFile", params);
          if (!response.hasError) {
            data = window.URL.createObjectURL(
              new Blob([response.data], {
                type: response.headers["content-type"],
              })
            );
          }
        }

        if (!response.hasError) {
          let selectedPostedAttachedFile = {
            ...this.selectedPostedAttachedFile,
          };
          selectedPostedAttachedFile.file = { data, type };
          selectedPostedAttachedFile.isShow = true;
          this.selectedPostedAttachedFile = selectedPostedAttachedFile;
        }
      }
    },
    // 画像ビューアを閉じる
    closeConfirmAttachFile() {
      let selectedAttachFile = { ...this.selectedAttachFile };
      selectedAttachFile.isShow = false;
      selectedAttachFile.size = null;
      selectedAttachFile.file = null;
      this.selectedAttachFile = selectedAttachFile;
    },
    closeConfirmPostedAttachFile() {
      let selectedPostedAttachedFile = {
        ...this.selectedPostedAttachedFile,
      };
      selectedPostedAttachedFile.src = null;
      selectedPostedAttachedFile.isShow = false;
      this.selectedPostedAttachedFile = selectedPostedAttachedFile;
    },
    // 既読ユーザー一覧表示
    showReadUsers({ title, messageId }) {
      const message = this.messages.find(
        (message) => message.message_id === messageId
      );
      const popupUsers = { ...this.popupUsers };
      popupUsers.users = message.reads;
      popupUsers.isShow = true;
      popupUsers.title = title;
      this.popupUsers = popupUsers;
    },
    // 既読ユーザー一覧とじる
    closePopupReadUsers() {
      this.popupUsers = { isShow: false, title: "", users: null };
    },
    // Base64変換
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    //画像サイズ
    getImageSize(base64) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () =>
          resolve({ width: img.naturalWidth, height: img.naturalHeight });
        img.onerror = (error) => reject(error);
        img.src = base64;
      });
    },

    // 重要マーク
    onToggleImportant() {
      this.isImportant = !this.isImportant;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.chatList {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    text-align: left;
  }
}

.chatviewWrapper {
  position: relative;
  height: 100%;
}

.chatview {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.chatview__list {
  overflow-y: auto;
  flex: 1;
}
.chatform {
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 4px;
}
.chatform__textarea {
  width: 100%;
  border: 1px solid $color_primary;
  padding: 4px;
}
.chatform__footer {
  display: flex;
  justify-content: space-between;
}

.chat__text {
  word-break: break-all;
  white-space: pre-wrap;
}

.loaderTop {
  height: 32px;
  width: 100%;

  .loaderTop_text {
    color: #888;
    text-align: center;
    font-size: 12px;
  }
}

.footer__menu {
  display: flex;
}
.footer__attachFiles {
  display: flex;
  overflow-x: auto;
}

.btnAlert {
  opacity: 0.5;
  filter: grayscale(100%);
  &.important {
    opacity: 1;
    filter: grayscale(0);
  }
}
</style>
