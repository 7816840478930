<template>
  <FormDialog>
    <template #header>
      <!-- 
        (共通)
        ヘッダーは全て共通
       -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!editable"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          class="mr-5"
          small
          outlined
          color="primary"
          @click="isShowConfirmDialog = true"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable"
          :disabled="!valid || isSubmitted"
          depressed
          small
          color="primary"
          @click="onSubmit"
        >
          保存
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!-- 
        (共通)
        全てのフォームのバリデーションの監視を行う
       -->
      <ValidationObserver ref="observer" v-slot="observer">
        <!-- フォームtab -->
        <v-tabs-items v-model="tab">
          <!-- 基本情報 -->
          <v-tab-item>
            <OfficeFormPage1
              :editable="editable"
              :item="formValues.company_branches"
              :mainHeight="params.mainHeight"
              :errors="errors"
              @formUpdate="formUpdate('company_branches', $event)"
            />
          </v-tab-item>
          <v-tab-item>
            <SafetyRulesForm
              :editable="editable"
              :item="formValues"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <!-- 健康保険等の加入状況 -->
          <v-tab-item>
            <OfficeFormPage2
              :editable="editable"
              :item="formValues.company_branch_insurances"
              :mainHeight="params.mainHeight"
              :errors="errors"
              @formUpdate="formUpdate('company_branch_insurances', $event)"
            />
          </v-tab-item>
          <v-tab-item>
            <OfficeFormPage3
              :editable="editable"
              :item="formValues.company_employment_restrictions"
              :mainHeight="params.mainHeight"
              :errors="errors"
              @formUpdate="formUpdate('company_employment_restrictions', $event)"
            />
          </v-tab-item>
        </v-tabs-items>
        <!-- 
          (共通)
          ValidationObserverのスロットプロパティを受け取って
          updateValidateメソッドを実行して、
          バリデーション結果をスクリプト側に渡してます
        -->
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <!-- 他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "../elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import OfficeFormPage1 from "@/components/forms/offices/OfficeFormPage1.vue";
import OfficeFormPage2 from "@/components/forms/offices/OfficeFormPage2.vue";
import OfficeFormPage3 from "@/components/forms/offices/OfficeFormPage3.vue";
import SafetyRulesForm from "@/components/forms/offices/SafetyRulesForm.vue";
import {
  OFFICE_INITAL_ITEM,
} from "@/constants/OFFICES";
import { Store } from "@/store/Store.js";
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON";

/**
 * (共通)
 * タイトル
 */
const TITLE = {
  NEW: "支店情報",
  EDIT: "支店情報",
};

/**
 * フォームとタブの設定
 */
const FORMS = {
  OfficeFormPage1: { id: 1, title: "基本情報" },
  SafetyRulesForm: { id: 2, title: "安全施策" },
  OfficeFormPage2: { id: 3, title: "保険・退職金共済" },
  OfficeFormPage3: { id: 4, title: "女性及び年少者の就業制限" },
};

export default {
  data: () => {
    return {
      FORMS,
      valid: false, //バリデーション結果
      // tab初期化
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      beforeUpdateItem: null,
      isClickCloseBtn: false,
      errors: {},
      formValues: OFFICE_INITAL_ITEM,
      isSubmitted: false,
      timeout: null
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    OfficeFormPage1,
    OfficeFormPage2,
    OfficeFormPage3,
    SafetyRulesForm,
  },
  props: {
    isNewItem: Boolean,
    companyBranchId: Number,
  },
  mounted() {
    this.getItems();
    /**
     * (共通)
     * 新規作成を監視
     */
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        //新規の場合は最初から編集モード
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters[`Offices/getOfficeDetail`],
      (data) => {
        //新規の場合は最初から編集モード
        this.beforeUpdateItem = {...this.formValues};
        if (this.companyBranchId) {
          this.formValues = data;
          this.beforeUpdateItem = data;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    // 新規or編集でタイトル変更
    formtitle() {
      return this.isNewItem ? TITLE.NEW : TITLE.EDIT;
    },
  },
  methods: {
    /**
     * (共通)
     * フォームの変更を送信
     */
    formUpdate(name, params) {
      let _formValue = {...this.formValues};
      if(!params) {
        if(name.company_safety_rules) {
           _formValue['company_safety_rules'] = [...name.company_safety_rules];
        } else {
          _formValue = {..._formValue, ...name};
        }
      } else {
        _formValue[name] = params;
      }
      this.formValues = _formValue;
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      this.editable = true;
    },
    /**
     * (共通)
     * 状態を詳細に変更
     */
    onDetail() {
      this.editable = false;
    },

    /**
     * (共通)
     * 登録
     */
    async onSubmit() {
      this.isSubmitted = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isSubmitted = false
      }, SUBMIT_DELAY_TIMEOUT);

      const hasId = "id" in this.formValues.company_branches;
      const editedItem = { ...this.formValues };
      const companyUser = JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user;
      editedItem.company_branches.company_id = companyUser.company_id;
      this.editedItem = editedItem;
      this.handleCompanyInsurances();
      const result = await Store.dispatch(
        //idがある場合は更新、ない場合は新規
        hasId ? `Offices/update` : `Offices/post`,
        this.editedItem
      );
      //レスポンスエラーがある場合はフォームを閉じない
      //エラーメッセージは、api.jsが表示
      if (result.hasError) {
        return;
      } else {
        if(hasId) {
          this.getItems();
          this.onDetail();
        } else {
          this.$emit("cancel");
        }
        //成功したら値を更新
        Store.dispatch("Toast/show", {
          status: 200,
          message: hasId ? "更新しました" : "登録しました",
        });
      }
    },

    /**
     * handle company branch insurances
     */
    handleCompanyInsurances() {
      for (var key in this.editedItem.company_branch_insurances) {
        // if has value properties in company_branch_insurances
        if (this.editedItem.company_branch_insurances[key] !== undefined) {
          return;
        }
      }
      // if all of properties in company_branch_insurances are undefined
      this.editedItem.company_branch_insurances = undefined;
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid, errors }) {
      this.valid = valid;
      this.errors = errors;
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        this.formValues = {...this.beforeUpdateItem};
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },

    /**
     * (共通)
     * click button Close (X)
     */
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
    /**
     * データ取得
     */
    async getItems() {
      if(this.companyBranchId) {
        const companyUser = JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user;
        const apiParams = {
          company_id: companyUser.company_id,
          company_branch_id: this.companyBranchId
        }
        Store.dispatch(`Offices/getDetail`, apiParams);
      }
    },
  },

  /**
   * Important: clear timeout
   */
  beforeDestroy () {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout)
  }
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
