<template>
  <div class="">
    <v-form autocomplete="off">
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-row>
              <v-list-item>
                <v-list-item-content>
                  <p class="ml-2">招待メールの送信先を入力してください。</p>
                </v-list-item-content>
              </v-list-item>
            </v-row>
            <v-row no-gutters class="no-wrap">
              <v-col style="max-width: 80%">
                <v-list-item>
                  <v-list-item-content>
                    <Label label="協力会社" required editable>
                      <InputText
                        name="company_name"
                        :values="formValues"
                        :editable="false"
                        validation_label="協力会社"
                        validation_rules="required"
                        @onInput="onInput"
                      />
                    </Label>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col style="max-width: 20%">
                <v-btn
                  class="btn-search"
                  color="primary"
                  @click="onClickSearch(false)"
                >
                  検索
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters class="no-wrap">
              <v-col style="max-width: 80%">
                <v-list-item>
                  <v-list-item-content>
                    <Label label="送信先" required editable>
                      <InputText
                        name="user_name"
                        :values="formValues"
                        :editable="false"
                        validation_label="送信先"
                        validation_rules="required"
                        @onInput="onInput"
                      />
                    </Label>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col style="max-width: 20%">
                <v-btn
                  class="btn-search"
                  color="primary"
                  :disabled="!formValues.company_id"
                  @click="onClickSearch(true)"
                >
                  検索
                </v-btn>
              </v-col>
            </v-row>
            <v-list-item>
              <v-list-item-content>
                <Label label="メッセージ">
                  <InputTextArea
                    name="message"
                    :values="formValues"
                    :editable="true"
                    validation_label="メッセージ"
                    validation_rules="max:512"
                    @onInput="onInput"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <Popup width="480px" :dialog="popup.isSearchCompanyDialog">
      <SearchCompanyDialog
        title="会社選択"
        :formValues="companySearchValue"
        :isNoDataMessage="isNoDataMessage"
        @formUpdate="formUpdateCompany"
        @onSearch="onSearchCompanyByKey"
        @close="closeSearchDialog"
      />
    </Popup>
    <Popup width="480px" :dialog="popup.isSearchMailRecipientDialog">
      <SearchMailRecipientDialog
        title="送信先選択"
        :formValues="mailRecipientValue"
        :isNoDataMessage="isNoDataMessage"
        @formUpdate="formUpdateMailRecipient"
        @onSearch="onSearchUserByKey"
        @close="closeSearchDialog"
      />
    </Popup>
  </div>
</template>
<script>
import Label from "@/components/forms/elements/Label";
import InputText from "@/components/forms/elements/InputText";
import InputTextArea from "@/components/forms/elements/InputTextArea.vue";
import SearchCompanyDialog from "../SearchCompanyDialog.vue";
import SearchMailRecipientDialog from "../SearchMailRecipientDialog.vue";
import Popup from "@/components/common/Popup.vue";
import { Store } from "@/store/Store.js";
const INIT_DATA = {
  items: [],
  keyword: "",
  selected: null,
  current_page: 1,
}
export default {
  data: () => {
    return {
      formValues: {},
      isShowSearchDialog: false,
      companySearchValue: {...INIT_DATA},
      mailRecipientValue: {...INIT_DATA},
      popup: {
        isSearchCompanyDialog: false,
        isSearchMailRecipientDialog: false,
      },
      isNoDataMessage: false,
    };
  },
  components: {
    Label,
    InputText,
    InputTextArea,
    SearchCompanyDialog,
    SearchMailRecipientDialog,
    Popup,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["CompanyPortal/getListCompanyAll"],
      (newValue) => {
        const companySearchValue = { ...this.companySearchValue };
        companySearchValue["items"] = newValue;
        this.companySearchValue = companySearchValue;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["Employees/getAllUser"],
      (newValue) => {
        const mailRecipientValue = { ...this.mailRecipientValue };
        mailRecipientValue["items"] = [...newValue];
        this.mailRecipientValue = mailRecipientValue;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    }
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValue = formValues;
      this.$emit("formUpdate", {"tab1": formValues});
    },
    async onClickSearch(flg) {
      if (flg) {
        if(this.formValues.company_id) {
          await this.getListUser();
        }
        this.popup.isSearchMailRecipientDialog = true;
      } else {
        await this.getListCompany();
        this.popup.isSearchCompanyDialog = true;
      }
    },
    formUpdateCompany(params) {
      this.closeSearchDialog();
      let formValues = {...this.formValues};
      formValues['company_id'] =  params['company_id'];
      formValues['company_name'] =  params['name'];
      formValues['company_branch_id'] =  params['company_branch_id'];
      formValues['email'] =  "";
      formValues['name_sei'] =  "";
      formValues['name_mei'] = "";
      formValues['user_name'] = "";
      this.formValues = formValues;
      this.$emit("formUpdate", {"tab1": formValues});
    },
    closeSearchDialog() {
      Store.dispatch("CompanyPortal/clearListCompany");
      Store.dispatch("Employees/clearListUser");
      this.popup = {
        isSearchMailRecipientDialog: false,
        isSearchCompanyDialog: false,
      }
      this.companySearchValue = {...INIT_DATA};
      this.mailRecipientValue = {...INIT_DATA};
    },
    formUpdateMailRecipient(params) {
      this.closeSearchDialog();
      let formValues = {...this.formValues};
      formValues['user_id'] =  params['id'];
      formValues['email'] =  params['email'];
      formValues['name_sei'] =  params['name_sei'];
      formValues['name_mei'] =  params['name_mei'];
      formValues['user_name'] =  `${formValues['name_sei'] ? formValues['name_sei'] : ""} ${formValues['name_mei'] ? formValues['name_mei'] : ""}`;
      this.formValues = formValues;
      this.$emit("formUpdate", {"tab1": formValues});
    },
    async getListCompany() {
      const companyUser = JSON.parse(sessionStorage.getItem("COMPANY_USER"))
        .Login.company_user;
      const params = {
        exclude_company_id: companyUser.company_id,
        exclude_company_branch: 1,
        company_name: this.companySearchValue.keyword,
        current_page: this.companySearchValue["current_page"]
      };
      if (params.company_name && params.company_name.trim() !== "" && params.company_name.length <= 255) {
        const rs = await Store.dispatch("CompanyPortal/getListCompanyAll", params);
        if (!rs.hasError) this.isNoDataMessage = [...rs.data.contents.entries.entries]?.length == 0;
        else this.isNoDataMessage = true;
      } else this.isNoDataMessage = true;
    },
    async onSearchCompanyByKey(params) {
      if (params?.isLoadMore) {
        this.companySearchValue["current_page"] = this.companySearchValue["current_page"] + 1;
      } else {
        if (this.companySearchValue.keyword && this.companySearchValue.keyword.trim() !== "") {
          Store.dispatch("CompanyPortal/clearListCompany");
          this.companySearchValue["current_page"] = 1;
        }
      }
      await this.getListCompany();
    },
    async getListUser() {
      const params = {
        company_id: this.formValues.company_id,
        company_brand_id: this.formValues.company_branch_id,
        user_name: this.mailRecipientValue.keyword,
        current_page: this.mailRecipientValue['current_page'],
      };
      const rs = await Store.dispatch("Employees/getAllUser", params);
      if (!rs.hasError) {
        this.isNoDataMessage = [...rs.data.contents.entries.entries]?.length == 0;
      } else {
        this.isNoDataMessage = true;
      }
    },
    async onSearchUserByKey(params) {
      if(params?.isLoadMore) {
        this.mailRecipientValue['current_page'] = this.mailRecipientValue['current_page'] + 1;
      } else {
        Store.dispatch("Employees/clearListUser");
        this.mailRecipientValue['current_page'] = 1;
      }
      this.getListUser();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
.btn-search {
  top: 9px;
}
.height-main {
  height: 585px !important;
}
.no-wrap {
  flex-wrap: nowrap;
  align-items: center;
}
</style>
