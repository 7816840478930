<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <!--  (共通) 会社ヘッダー -->
        <SitePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout :layoutParams="layoutParams" :hideFooter="searchParams.pageCount >= searchParams.total_item">
          <template #tableHeader="{ updateHeader }">
            <!--  
              (共通) テーブルヘッダー 
              このなかに、新規作成ボタン、検索ボタン、一括削除ボタンが実装されてます
              @openRemoveDialog : 一括削除の確認ダイアログを開く
              @openItemForm : 新規作成フォームを開く
            -->
            <TableHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :multiRemoveStatus="disableRemoveBtn"
              :updateHeader="updateHeader"
              @openRemoveDialog="openRemoveDialog"
              @openItemForm="openNewItemForm"
            >
              <!-- 
                検索はテーブルごとに実装します 
                @onInput 
              -->
              <SearchFormWrapper>
                <Label label="機材">
                  <InputText
                    name="word"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <v-spacer></v-spacer>
                <v-btn class="mr-6" color="primary" depressed @click="onSearch">
                  検索
                </v-btn>
              </SearchFormWrapper>
            </TableHeader>

            <!-- 
              (共通)
              ソートのレイアウトを調整するラッパーコンポーネントです
            -->
            <TableSortWrapper>
              <!--  
                (共通) ソート 
                ソート項目、ソート順、表示件数の選択、総件数の表示
              -->
              <TableSort
                :values="searchParams"
                :sort_items="SORT_ITEMS"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                @onInput="onChangeSortParams"
              />
            </TableSortWrapper>
          </template>
          <!-- 
            (共通)テーブル
            v-data-table自体は共通ですが、
            カラムによって変更をしたい場合はそれぞれ実装してください。
          -->
          <template #tableBody="{ tableHeight }">
            <v-data-table
              v-model="selectedItems"
              :headers="TABLE_LABELS"
              :items="items"
              :items-per-page="searchParams.pageCount"
              :height="tableHeight"
              fixed-header
              hide-default-footer
              disable-sort
              class="elevation-0 v-data-table__wrapper"
              sort-by="updatedAt"
              show-select
              :noDataText="NO_DATA_MESSAGE"
              @click:row="openItemForm"
            >
              <template v-slot:[`item.name`]="{ item }">
                <div>
                  {{ convertName(item.name) }}
                </div>
              </template>
              <template v-slot:[`item.maximum_work_floor_height`]="{ item }">
                <div>
                  {{ convertMachine(item.maximum_work_floor_height, "m") }}
                </div>
              </template>
              <template v-slot:[`item.movable_load`]="{ item }">
                <div>{{ convertMachine(item.movable_load, "kg") }}</div>
              </template>
              <template v-slot:[`item.body_length`]="{ item }">
                <div>
                  {{ convertMachineToThreeDecimals(item.body_length, "mm") }}
                </div>
              </template>
              <template v-slot:[`item.body_width`]="{ item }">
                <div>
                  {{ convertMachineToThreeDecimals(item.body_width, "mm") }}
                </div>
              </template>
              <template v-slot:[`item.body_height`]="{ item }">
                <div>
                  {{ convertMachineToThreeDecimals(item.body_height, "mm") }}
                </div>
              </template>
              <template v-slot:[`item.company_name`]="{ item }">
                <div>
                  {{ convertName(item.company_name) }}
                </div>
              </template>
            </v-data-table>
          </template>
          <template #tableFooter>
            <!-- (共通) ページネーション -->
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>

    <!--  (ユーザー用) 追加/編集ダイアログ -->
    <Popup :dialog="popups.isShowItemForm">
      <MachineForm
        :item="editedItem"
        :isNewItem="isNewItem"
        :isErrorSubmit="isErrorSubmit"
        :isEmitted="isEmitted"
        :beforeUpdateItem="beforeUpdateItem"
        :fieldId="fieldId"
        @formUpdate="formUpdate"
        @submit="submitForm"
        @cancel="closeItemForm"
      />
    </Popup>

    <!--  (共通) 削除ダイアログ / 文言変えてもok -->
    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :items="selectedItems"
        warning
      />
    </Popup>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import ConfirmRemoveDialog from "./components/ConfirmRemoveDialog";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import { TABLES_PER_PAGE, TABLE_SORT_ORDERS, NO_DATA_MESSAGE } from "@/constants/COMMON"; //絞り込みフォームで使用

import MachineForm from "@/components/forms/machines/MachineForm"; //ユーザー登録編集フォーム
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import InputText from "@/components/forms/elements/InputText";

import {
  MACHINE_TABLE_LABELS,
  MACHINE_SORT_ITEMS,
  MACHINE_INITAL_ITEM,
} from "@/constants/MACHINE"; //絞り込みフォームで使用
import _ from "lodash";
import { HEADER_MENU_ITEMS_SITE } from "@/constants/GLOBALHEADER";

/**
 * 定数
 * この一覧ページで使用する固有の定数を定義します。
 * 共通に使用する定数は基本的に@/constants/で定義します。
 * - 定数は大文字で定義します
 * - 定数は基本的にはdataに代入しないで直接参照します
 */

//タイトル
const PAGE_TITLE = "機材";

//１ページあたりのテーブル件数
const PAGE_COUNT = 25;

//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;

//ストア
const STORE = "Machines";

//テーブルヘッダーラベル
const TABLE_LABELS = MACHINE_TABLE_LABELS;

//ソート要素
const SORT_ITEMS = MACHINE_SORT_ITEMS;

//フォーム初期値
const INITIAL_ITEM = MACHINE_INITAL_ITEM;

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      PAGE_TITLE,
      TABLE_LABELS,
      SORT_ITEMS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      NO_DATA_MESSAGE,

      /**
       * (共通)
       * 一覧データ
       */
      items: [],

      /**
       * (共通)
       * checkbox選択item
       */
      selectedItems: [],

      /**
       * (共通)
       * 編集アイテム
       * 定数から初期値を代入
       */
      editedItem: { ...INITIAL_ITEM },

      beforeUpdateItem: {},

      /**
       * (共通)
       * 新規フラグ
       */
      isNewItem: false,

      // 検索パラメータ
      searchParams: {
        word: "",
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: SORT_ITEMS[0].id, //sortする項目
        asc: true,
      },

      /**
       * (共通)
       * ポップアップの状態管理
       */
      popups: {
        // 追加/編集フォーム
        isShowItemForm: false,
        // 削除確認ダイアログ表示
        isShowRemoveDialog: false,
      },
      fieldId: null,
      isErrorSubmit: false,
      isEmitted: false
    };
  },

  components: {
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    SitePortalHeader,
    Popup,
    ConfirmRemoveDialog,
    //ユーザー用のコンポーネント
    MachineForm,
    InputText,
    Label,
  },

  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    Store.dispatch("GlobalHeader/setSiteMenu", {
      menuId: HEADER_MENU_ITEMS_SITE.SCHEDULE.id,
    });

    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      (data, oldData) => {
        if (data && JSON.stringify(data) !== JSON.stringify(oldData)) {
          this.fieldId = data.field_id;
          this.getItems();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        /**
         *  (共通)
         * 一覧リストの格納
         */
        this.items = data[0];

        /**
         *  (共通)
         * ページネーション更新
         */
        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        deep: true,
      }
    );
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */
  computed: {
    /**
     * (共通)
     * 削除ボタンの活性・非活性
     * selectedItems.length === 0
     * 自体はtemplate側で記述できますが、
     * ここに明示的に状態を定義して可読性を担保してます
     */
    disableRemoveBtn() {
      return this.selectedItems.length === 0;
    },
    /**
     * API Param
     */
    apiParams() {
      return {
        field_id: this.fieldId,
        machine_name: this.searchParams.word,
        sort_value: this.searchParams.sort,
        sort_by: this.searchParams.asc ? 1 : 0,
        page_number: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
      };
    },
  },

  methods: {
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 検索
     */
    onSearch() {
      this.searchParams["currentPage"] = 1;
      this.getItems();
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
    },

    /**
     * (共通)
     * 並び替えパラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      name == "pageCount" ? (searchParams["currentPage"] = 1) : "";
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 作成/編集フォーム
     * INITIAL_ITEM で定義した値がitemに入ります
     * @param INITIAL_ITEMで定義している値
     */
    openNewItemForm() {
      this.editedItem = _.cloneDeep(INITIAL_ITEM);
      this.beforeUpdateItem = _.cloneDeep(INITIAL_ITEM);
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },
    async openItemForm(item) {
      const result = await Store.dispatch(`${STORE}/getDetail`, item.id);
      // hasError == False
      // Show Detail Form
      if (!result.hasError) {
        this.editedItem = _.cloneDeep(result.data.contents.entries);
        this.beforeUpdateItem = _.cloneDeep(result.data.contents.entries);
        this.isNewItem = false;
        this.popups.isShowItemForm = true;
      }
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.$nextTick(() => {
        //選択を初期化
        this.editedItem = { ...INITIAL_ITEM };
      });
    },

    /**
     *  (共通)
     * 確認ダイアログ
     * 基本的に変更しないでいいはず
     */
    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },

    /**
     * (共通)
     * フォームの変更を受け取る
     */
    formUpdate(params) {
      this.editedItem = { ...params };
    },

    /**
     *  (共通)
     * ストア / api
     * ストア実装ルールにしたがっている場合は、
     */

    // データ取得
    async getItems() {
      if (this.apiParams.field_id) {
        await Store.dispatch(`${STORE}/get`, { params: this.apiParams });

        const params = {
          field_id: this.fieldId,
          state_handling_flag: 0
        };
        await Store.dispatch(
          "CompanyPortal/getListCompanyByField",
          params
        );
      }
    },

    // 削除
    async removeItems() {
      const ids = this.selectedItems.map((items) => items.id);
      const result = await Store.dispatch(`${STORE}/delete`, { ids });

      if (!result.hasError) {
        this.searchParams["currentPage"] = 1;
        //ダイアログ閉じる
        this.closeRemoveDialog();
        //成功したら値を更新
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
      }
    },

    /**
     * (共通)
     * 新規登録 / 更新
     * - idがある場合は更新
     */
    async submitForm() {
      const hasId = "id" in this.editedItem;
      if (this.editedItem.device_keyplan_images) {
        this.editedItem.device_keyplan_images = this.editedItem.device_keyplan_images.filter(image => image.image);
      }
      if (!hasId) {
        const editedItem = { ...this.editedItem };
        editedItem.company_id = this.fieldId;
        this.editedItem = editedItem;
      }
      const result = await Store.dispatch(
        //idがある場合は更新、ない場合は新規
        hasId ? `${STORE}/update` : `${STORE}/post`,
        this.editedItem
      );
      //レスポンスエラーがある場合はフォームを閉じない
      //エラーメッセージは、api.jsが表示
      if (result.hasError) {
        this.isErrorSubmit = true;
        return;
      } else if (hasId) {
        //show deatail item update
        const detailResult = await Store.dispatch(
          `${STORE}/getDetail`,
          this.editedItem.id
        );
        if (!detailResult.hasError) {
          this.editedItem = _.cloneDeep(detailResult.data.contents.entries);
          this.isErrorSubmit = false;
        }
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
      } else {
        //成功したら値を更新
        this.closeItemForm();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
      this.getItems();
      this.isEmitted = !this.isEmitted;
    },
    convertMachineToThreeDecimals(item, measure) {
      let value = 0;
      if (item) {
        value = Number(item).toFixed(2) + measure;
      } else {
        value = "";
      }
      return value;
    },
    convertMachine(item, measure) {
      let value = "";
      if (item) {
        if (item.endsWith(".00")) {
          value = item.replace(".00", "");
        } else if (item.match("[0-9]+\\.[1-9]+0")) {
          value = item.slice(0, item.length - 1);
        } else {
          value = item;
        }
        value = value + measure;
      }
      return value;
    },
    convertName(name) {
      let result = "";
      if (name) {
        result = name;
        if (name.length > 16) {
          result = name.substring(0, 16) + "•••";
        }
      }
      return result;
    },
  },
};
</script>
