<template>
  <div :style="`height:${tableHeight}px; overflow-x: hidden; overflow-y: auto;`">
    <ScheduleRow
      v-for="(item, index) in items.devices"
      :key="item.device_id"
      :index="index"
      :work_start_time="items.work_start_time"
      :isGates="isGates"
      :item="item"
      :today="items.date"
      :flagBanMachine="flagBanMachine"
      @update="functions.updateSelectedItems"
      @openItemForm="functions.openItemForm"
      @getChildItems="functions.getChildItems"
      @addNewChild="functions.addNewChild"
      @resetList="functions.resetList"
      :isUsage="isUsage"
    />
  </div>
</template>
<script>
import ScheduleRow from "./ScheduleRow";
export default {
  components: {
    ScheduleRow,
  },
  props: {
    isUsage: {
      type: Boolean,
      default: false,
    },
    isGates: {
      type: Boolean,
    },
    items: {
      type: Object,
    },
    functions: {
      type: Object,
    },
    tableHeight: {
      type: Number,
    },
    flagBanMachine: {
      type: Boolean,
      default: false
    }
  },
};
</script>