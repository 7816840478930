<template>
  <div class="wrapper">
    <Select
      :name="name"
      :values="values"
      :items="items"
      item_text="item_name"
      :editable="true"
      @onInput="onInput"
    />
  </div>
</template>
<script>
import Select from "@/components/forms/elements/Select.vue";
import { Store } from "@/store/Store.js";

const INIT_ITEM = { id: null, item_name: "" };

export default {
  data: () => {
    return {
      items: [INIT_ITEM],
    };
  },
  props: {
    name: {
      type: String,
    },
    siteId: {
      type: Number,
    },
    values: {
      type: Object,
    },
    construction_id: {
      type: Number,
    }
  },
  components: {
    Select,
  },
  mounted() {
    /**
     * 作業場所一覧
     */
    this.$watch(
      () => Store.getters["PortalChart/getListItemTrees"],
      (newValue) => {
        if (newValue?.length > 0) {
          const array = [...newValue];
          array.unshift({id: null, item_name: ""})
          this.items = [...array];
        } else {
          this.items = [INIT_ITEM];
        }
      },
      { deep: true }
    );
    /**
     * 現場idから作業場所一覧を取得
     */
    this.$watch(
      () => [this.siteId, this.construction_id],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue) && newValue[0] && newValue[1]) {
          let params = {
            field_id: newValue[0],
            field_construction_id: newValue[1]
          };
          Store.dispatch("PortalChart/getListItemTrees", { params });
        }
      },
      { immediate: true, deep: true }
    );
  },
  methods: {
    onInput({ name, value }) {
      this.$emit("onInput", { name, value });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  height: 40px;
}
</style>
