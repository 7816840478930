<template>
  <TableUpdateLabel
    label="気象予測"
    :time="weatherUpdateTime"
    @updateTable="reloadWeather"
    :loading="weatherLoading"
    :flagUserRentalCompany="flagUserRentalCompany"
  >
    <v-card style="overflow: auto">
      <v-data-table
        :headers="TABLE_LABELS"
        :items="weather"
        :items-per-page="weather.length"
        height="300px"
        hide-default-header
        hide-default-footer
        disable-sort
        class="elevation-0 v-data-table__wrapper"
        sort-by="updatedAt"
        :no-data-text="NO_DATA_MESSAGE"
      >
        <template v-slot:[`item.icon`]="{ item }">
          <v-img width="45" height="45" :src="URL_IMG + item.icon + '.png'">
          </v-img>
        </template>
      </v-data-table>
    </v-card>
  </TableUpdateLabel>
</template>
<script>
import { Store } from "@/store/Store.js";
import TableUpdateLabel from "./TableUpdateLabel";
import { NO_DATA_MESSAGE, ANEMOTROPIC } from "@/constants/COMMON.js";
import axios from "axios";
const URL_IMG = "http://openweathermap.org/img/wn/";
const TABLE_LABELS = [
  {
    text: "",
    value: "hour",
    align: "center",
  },
  {
    text: "",
    value: "icon",
    align: "center",
  },
  {
    text: "",
    value: "temp",
    align: "center",
  },
  {
    text: "",
    value: "rain",
    align: "center",
  },
  {
    text: "",
    value: "wind_speed",
    align: "left",
  },
];
export default {
  components: {
    TableUpdateLabel
  },
  props: {
    flagUserRentalCompany:{
      type: Boolean,
      default: false,
    }    
  },
  data: () => ({
    weather: [],
    TABLE_LABELS,
    NO_DATA_MESSAGE,
    URL_IMG,
    weatherLoading: false,
    weatherUpdateTime: null,
  }),
  computed: {
    GET_GEOGRAPHICAL_COORDINATES() {
      return Store.getters["SitePortal/getData"]?.field;
    },
  },
  mounted() {
    this.$watch(
      () => Store.getters["SitePortal/getData"],
      (data, oldData) => {
        if (JSON.stringify(data) != JSON.stringify(oldData)) {
          this.getWeatherData();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Weather/getWeather"],
      (value) => {
        if (value && value.length > 0) {
          let arrWeather = [];
          value.forEach((item) => {
            const date = new Date(item.dt * 1000);
            const indexAnemotropic = Math.floor(item.wind_deg / 22.5);
            arrWeather.push({
              hour: date.getHours() + "時",
              temp: item.temp + "°C",
              rain:
                (item.rain
                  ? item.rain["1h"]
                  : item.snow
                  ? item.snow["1h"]
                  : 0) + "mm",
              wind_speed:
                this.findAnemotropic(indexAnemotropic) +
                " " +
                item.wind_speed +
                "m/s",
              icon: item.weather[0].icon,
            });
          });
          this.weather = arrWeather;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    findAnemotropic(index) {
      return ANEMOTROPIC[index];
    },

    reloadWeather() {
      this.getWeatherData();
    },

    getWeatherData() {
      if ((this.GET_GEOGRAPHICAL_COORDINATES?.center_latitude || "").length > 0 &&
        (this.GET_GEOGRAPHICAL_COORDINATES?.center_longitude || "").length > 0) {
        this.weatherLoading = true;
        const now = new Date();
        const params = {
          lat: Number(this.GET_GEOGRAPHICAL_COORDINATES.center_latitude),
          lon: Number(this.GET_GEOGRAPHICAL_COORDINATES.center_longitude),
          units: "metric",
          exclude: "current,daily,minutely",
          dt: now.getTime(),
          appid: process.env.VUE_APP_WEATHER_API_KEY
        };

        axios({
          url: process.env.VUE_APP_WEATHER_URL_API,
          method: "GET",
          params: params
        }).then((response) => {
          this.weatherLoading = false;
          this.weatherUpdateTime = ("0" + now.getHours()).slice(-2) + ":" + ("0" + now.getMinutes()).slice(-2);
          const arrHour = response.data.hourly;
          Store.dispatch("Weather/setWeather", arrHour);
        }).catch(()=>{
          this.weatherLoading = false;
          this.weather = [];
        });
      } else {
        this.weather = [];
        this.weatherUpdateTime = null;
      }
    }
  },
};
</script>
<style lang="scss" scoped></style>
