<template>
  <FormDialog>
    <template #header>
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title style="color: #1B9C4F">
          {{ form_title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editable && flagEdit"
          class="mr-5"
          depressed
          small
          outlined
          color="primary"
          @click="isShowConfirmDialog = true"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable && flagEdit"
          depressed
          small
          :disabled="!valid"
          color="primary"
          @click.once="onSubmit"
          :key="flagBtnSubmit"
          data-testid="btn-submit"
        >
          保存
        </v-btn>
        <v-btn
          v-if="!editable && flagEdit"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in TAB_NAME_FORM" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <ValidationObserver ref="observer" v-slot="observer">
        <!-- フォームtab -->
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <MachineBringForm1
              :editable="editable"
              :item="item"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
              :errors="errors"
            />
          </v-tab-item>
          <v-tab-item>
            <MachineBringForm2
              :isBackData="isBackData"
              :editable="editable"
              :item="item"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
        </v-tabs-items>
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { Store } from "@/store/Store.js";
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import MachineBringForm1 from "./MachineBringForm1.vue";
import MachineBringForm2 from "./MachineBringForm2.vue";
import { TAB_NAME_FORM } from "@/constants/DEVICE_CARRY_ON";
import _ from "lodash";

const STORE = "DeviceCarryOns";

export default {
  data: () => {
    return {
      item: {},
      beforeUpdateItem: {},
      valid: false,
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      TAB_NAME_FORM,
      isClickCloseBtn: false,
      errors:{},
      flagBtnSubmit: false,
      isBackData : false
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    MachineBringForm1,
    MachineBringForm2,
  },
  props:{
    flagEdit: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$watch(
      () => Store.getters[`${STORE}/getMachineFieldDetail`],
      (data) => {
        this.beforeUpdateItem = _.cloneDeep(data);
        this.item = _.cloneDeep(data);
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  computed: {
    form_title() {
      return this.item?.title;
    },
  },
  methods: {
    /**
     * update form
     * @param params : value to update
     */
    formUpdate(params) {
      this.item = {...params};
    },
    /**
     * click button edit
     */
    onEditable() {
      this.isBackData = false;
      this.editable = true;
    },
    /**
     * delete params to submit edit
     * @returns data deleted
     */
    deleteParams(data) {
      let _data = { ...data };
      delete _data.title;
      delete _data.machine_company_field_report_details.delivery_company_id;
      delete _data.machine_company_field_report_details.delivery_company_branch_id;
      delete _data.machine_company_field_report_details.delivery_company_name;
      _data.machine_company_field_report_checkups = _data.machine_company_field_report_checkups.filter(e => e.checkup_user_id);
      _data.machine_company_field_report_checkups.forEach(element => {
        delete element.checkup_user_name;
      });
      _data.machine_company_field_report_handles = _data.machine_company_field_report_handles.filter(e => e.handle_user_id);
      _data.machine_company_field_report_handles.forEach(element => {
        delete element.handle_user_name;
      });
      _data.machine_company_field_report_drivers = _data.machine_company_field_report_drivers.filter(e => e.driver_user_id);
      _data.machine_company_field_report_drivers.forEach(element => {
        delete element.driver_user_name;
        element.machine_company_field_report_licences = element.machine_company_field_report_licences.filter(e1 => e1.user_licence_id);
        element.machine_company_field_report_sp_trainings = element.machine_company_field_report_sp_trainings.filter(e1 => e1.user_sp_training_id);
      });
      let machine_company_field_report_checkup_documents = _data.machine_company_field_report_checkup_documents;
      machine_company_field_report_checkup_documents.forEach(e => {
        if (e?.document_deleted) delete e?.document_url;
      });
      _data.machine_company_field_report_checkup_documents = machine_company_field_report_checkup_documents.filter(e => {
        if (!e.document_name && !e.id) return false;
        else return true;
      });
      return _data;
    },
    /**
     * action edit
     */
    async onSubmit() {
      let edittedItem = _.cloneDeep(this.item);
      edittedItem = this.deleteParams(edittedItem);
      const result = await Store.dispatch(`${STORE}/update`, edittedItem);
      if (!result.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました"
        });
        let id = result.data.contents.entries.id;
        await Store.dispatch(`${STORE}/getMachineFieldDetail`, id);
        this.editable = false;
        this.$emit("resetItems");
      } else {
        this.flagBtnSubmit = !this.flagBtnSubmit;
      }
    },
    /**
     * update validate and errors
     */
    updateValidate({ valid, errors }) {
      this.valid = valid;
      this.errors = errors;
    },
    /**
     * close form
     */
    closeForm() {
      if (this.editable && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        this.item = _.cloneDeep(this.beforeUpdateItem);
        this.isBackData = true;
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },
    /**
     * click X
     */
    onClickBtnClose() {
      if (!this.editable) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
        this.$emit("closeItemForm");
      }
    },
    /**
     * cancel X
     */
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
