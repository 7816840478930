import { render, staticRenderFns } from "./RoomItem.vue?vue&type=template&id=4dd43f6e&scoped=true&"
import script from "./RoomItem.vue?vue&type=script&lang=js&"
export * from "./RoomItem.vue?vue&type=script&lang=js&"
import style0 from "./RoomItem.vue?vue&type=style&index=0&id=4dd43f6e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dd43f6e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VListItem,VListItemAction,VListItemActionText,VListItemContent,VListItemTitle})
