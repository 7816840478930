<template>
  <v-card class="dialog">
    <v-card-title>
      <div class="title">納品報告</div>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div class="text">発注された機械を納品したことを通知します。</div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="primary"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn
        depressed
        class="btn"
        :key="flagDelivery"
        color="primary"
        @click.once="onSubmit()"
      >
      報告
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    flagDelivery: Boolean,
  },
  methods: {
    async onSubmit() {
      this.$emit('yes');
    }
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .title {
    font-size: 12px;
    color: #1b9c4f;
  }
  .body {
    .text-wrap {
      .text {
        text-align: center;
        margin: auto;
        width: 80%;
      }
    }
  }
}
</style>
