import { render, staticRenderFns } from "./EmployeeFormPage1.vue?vue&type=template&id=20a5fa6f&scoped=true&"
import script from "./EmployeeFormPage1.vue?vue&type=script&lang=js&"
export * from "./EmployeeFormPage1.vue?vue&type=script&lang=js&"
import style0 from "./EmployeeFormPage1.vue?vue&type=style&index=0&id=20a5fa6f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20a5fa6f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCard,VCardText,VCol,VContainer,VForm,VListItem,VListItemContent,VRow,VSwitch})
