import { getParamsOfUrlReadFile } from "@/utils/viewSourceAuth";
import { Store } from "@/store/Store.js";

const _fileCache = () => {
  let cache = [];

  // キャッシュを検索
  const findCache = (url) => {
    const f = cache.find((item) => {
      return item.url === url;
    });
    return f;
  };

  // キャッシュに保持
  const setCache = ({ url, data }) => {
    cache.push({
      url,
      data,
    });
  };

  const getFile = (imageUrl) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      if (!imageUrl) {
        console.log("■ getFile !imageUrl");
        resolve(null);
        return;
      }
      const cached = findCache(imageUrl);
      if (cached) {
        console.log("■ getFile from cache");
        resolve(cached.data);
        return;
      }

      const params = getParamsOfUrlReadFile(imageUrl);
      const response = await Store.dispatch("File/readFileOfChat", params);
      if (!response.hasError) {
        const data = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers["content-type"],
          })
        );
        setCache({ url: imageUrl, data });
        console.log("■ getFile from api", response.data, data);
        resolve(data);
      } else {
        setCache({ url: imageUrl, data: null });
        console.log("■ getFile from api error");
        resolve(null);
      }
    });
  };

  const getFiles = async (filesArray) => {
    return await Promise.all(
      filesArray.map(async (url) => {
        const result = await getFile(url);
        return result;
      })
    );
  };

  return {
    getFile,
    getFiles,
  };
};

export const fileCache = _fileCache();
