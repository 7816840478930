<template>
  <div ref="mainBody">
    <v-card class="text-center">
      <div class="card-text">
        <v-card-title>
          <div></div>
          <v-spacer></v-spacer>
          <div>{{ convertFileName() }}</div>
          <v-spacer></v-spacer>
          <v-btn small icon @click="$emit('cancel')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <hr>
        </v-card-title>
        <v-divider></v-divider>
      </div>
      <v-card-text>
        <div style="display: flex;justify-content: center" v-if="numPages == 0">
          {{ NO_DATA_MESSAGE }}
        </div>
        <div v-else ref="pdfViewer" class="pdfViewer" :style="stylePdf">
          <pdf
            v-for="i in numPages"
            :key="i"
            :src="fieldDocument.document_url"
            :page="i"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import pdf from "vue-pdf";

export default {
  data: () => {
    return {
      NO_DATA_MESSAGE,
      stylePdf: "",
    };
  },
  props: {
    fieldDocument: Object,
    numPages: Number,
    mainHeight: Number
  },
  components: {
    pdf
  },
  mounted() {
    this.$watch(
      () => this.mainHeight,
      () => {
        this.updatePdfViewerHeight();
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  methods: {
    updatePdfViewerHeight() {
      const mainBody = this.$refs.mainBody;
      const pdfViewer = this.$refs.pdfViewer;
      if (pdfViewer && mainBody) {
        const mainBodyTop = mainBody.getBoundingClientRect().top;
        const pdfViewerTop = pdfViewer.getBoundingClientRect().top;
        const marginTop = mainBodyTop - pdfViewerTop;
        this.stylePdf = `height:${this.mainHeight + marginTop - 32}px;`;
      }
    },
    convertFileName() {
      if ((this.fieldDocument.name || "").length == 0) return "";
      let nameTemp = this.fieldDocument.name.split('/')?.at(-1);
      nameTemp = nameTemp.substring(nameTemp.indexOf('-') + 1);
      return nameTemp;
    }
  },
  errorCaptured() {
    return false;
  }
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";

.pdfViewer {
  overflow: auto;
}

.card-text {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff
}
</style>