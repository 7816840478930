<template>
  <v-card class="dialog">
    <v-card-title>
      <div class="title">
        顔画像を登録
      </div>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div class="text">作業員の顔画像データを登録します。</div>
        <div class="text">時間がかかる場合がありますので、しばらくお待ちください。</div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="primary"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn
        depressed
        :disabled="disabled"
        class="btn"
        color="primary"
        :key="isReset"
        @click.once="$emit('yes')"
      >
        保存
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      disabled: false,
      timeout: null,
    };
  },
  props: {
    dialogText: Object,
    warning: {
      type: Boolean,
      default: false,
    },
    isReset: Boolean,
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .title {
    font-size: 20px;
    color: green;
  }
  .body {
    .text-wrap {
      .text {
        margin: auto;
        width: 90%;
        font-size: 13px;
      }
    }
  }
}
</style>
