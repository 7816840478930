<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <!--  (共通) 会社ヘッダー -->
        <SitePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout :layoutParams="layoutParams">
          <template #tableHeader="{ updateHeader }">
            <!--  
              (共通) テーブルヘッダー 
              このなかに、新規作成ボタン、検索ボタン、一括削除ボタンが実装されてます
              @openRemoveDialog : 一括削除の確認ダイアログを開く
              @openItemForm : 新規作成フォームを開く
            -->
            <TableHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :updateHeader="updateHeader"
              :multiSelectStatus="multiSelectBtn"
              :isAddNew="hasEditable"
              :isDelete="hasApproval"
              :isApproval="hasApproval"
              @openRemoveDialog="openRemoveDialog"
              @openItemForm="openNewItemForm"
              @approve="openApproveRooms"
              @reject="openRejectRooms"
            >
            </TableHeader>
          </template>
          <!-- 
            (共通)テーブル
            v-data-table自体は共通ですが、
            カラムによって変更をしたい場合はそれぞれ実装してください。
          -->
          <template #tableBody="{ tableHeight }">
            <v-data-table
              v-model="selectedItems"
              :headers="TABLE_LABELS"
              :items="items"
              :items-per-page="searchParams.pageCount"
              :height="tableHeight"
              fixed-header
              hide-default-footer
              disable-sort
              class="elevation-0 v-data-table__wrapper"
              sort-by="updatedAt"
              :show-select="hasApproval"
              :noDataText="NO_DATA_MESSAGE"
              @click:row="openItemForm"
            >
              <template v-slot:[`item.approval_flag`]="{ item }">
                <div
                  class="approvalStatus"
                  :class="getApprovalClass(item.approval_flag)"
                >
                  {{ getApproval(item.approval_flag) }}
                </div>
              </template>
              <template v-slot:[`item.id`]="{ item }">
                <div>
                  <v-icon @click.stop="onChatView(item.id)">mdi-message</v-icon>
                  <!-- <button @click.stop="onChatView(item.id)">
                    {{ item.id }}
                  </button> -->
                </div>
              </template>
              <template v-slot:[`item.users`]="{ item }">
                <div v-for="user in item.users" :key="user.id">
                  {{ user.name }}
                </div>
              </template>
              <!-- 更新 -->
              <template v-slot:[`item.updated_at`]="{ item }">
                {{ getFormattedDate(item.updated_at) }}
              </template>
            </v-data-table>
          </template>
          <template #tableFooter>
            <!-- (共通) ページネーション -->
            <!-- <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            /> -->
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>

    <!--  (ユーザー用) 追加/編集ダイアログ -->
    <Popup :dialog="popups.isShowItemForm">
      <Form
        :item="editedItem"
        :isNewItem="isNewItem"
        :isApproval="hasApproval"
        :isEditable="hasEditable"
        @formUpdate="formUpdate"
        @submit="submitForm"
        @cancel="closeItemForm"
        ref="Form"
      />
    </Popup>

    <!--  (共通) 削除ダイアログ / 文言変えてもok -->
    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :items="selectedItems"
        warning
      />
    </Popup>

    <!--  承認・承認解除ダイアログ / 文言変えてもok -->
    <Popup width="480px" :dialog="popups.isShowApprovalDialog">
      <ConfirmApprovalDialog
        @close="popups.isShowApprovalDialog = false"
        @yes="updateApprove"
        title="選択項目の承認・解除"
        :text="approvalDialogText"
        :items="selectedItems"
        warning
      />
    </Popup>
    <!-- ドロワーチャット -->
    <v-navigation-drawer
      v-model="popups.isDrawerChatView"
      absolute
      temporary
      right
      width="480"
      class="drawerChatView"
    >
      <ChatView :chat_room_id="chat_room_id" :userId="user_id" />
    </v-navigation-drawer>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import { format } from "date-fns";
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import TableLayout from "@/components/layout/TableLayout.vue";
import TableHeader from "./components/TableHeader.vue";
import Pagination from "@/components/masterTable/elements/Pagination.vue";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper.vue";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper.vue";
import TableSort from "@/components/masterTable/elements/TableSort.vue";
import Popup from "@/components/common/Popup.vue"; //モーダル用のポップアップ
import ConfirmRemoveDialog from "@/components/dialog/ConfirmRemoveDialog.vue"; //削除確認ダイアログ
import ConfirmApprovalDialog from "@/components/dialog/ConfirmDialog.vue"; //削除確認ダイアログ
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import {
  TABLES_PER_PAGE,
  TABLE_SORT_ORDERS,
  NO_DATA_MESSAGE,
} from "@/constants/COMMON"; //絞り込みフォームで使用
import { HEADER_MENU_ITEMS_SITE } from "@/constants/GLOBALHEADER";

/**
 * ユーザーで使用するコンポーネント、関数
 */
import Form from "@/components/forms/chatroom/RoomForm.vue"; //登録編集フォーム
// import { fmtSlashDate } from "@/utils/timeUtil"; //日付フォーマット変換で使用
import InputText from "@/components/forms/elements/InputText.vue"; //絞り込みフォームで使用
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select.vue"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label.vue"; //絞り込みフォームで使用
import {
  TEXTCHAT_APPROVAL_TYPE,
  TEXTCHAT_ROOM_INITAL_ITEM,
  TEXTCHAT_ROOM_TABLE_LABELS,
} from "@/constants/TEXTCHAT"; //絞り込みフォームで使用

import {
  ROOM_APPROVAL_FLAG,
  CHAT_USER_ROLL,
} from "@/store/modules/api/TextChatManage";

/**
 * チャット
 */
import ChatView from "../../chat/index.vue";

/**
 * 定数
 * この一覧ページで使用する固有の定数を定義します。
 * 共通に使用する定数は基本的に@/constants/で定義します。
 * - 定数は大文字で定義します
 * - 定数は基本的にはdataに代入しないで直接参照します
 */

//タイトル
const PAGE_TITLE = "現場チャット管理";

//１ページあたりのテーブル件数
const PAGE_COUNT = 50;

//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;

//ストア
const STORE = "TextChatManage";

//テーブルヘッダーラベル
const TABLE_LABELS = TEXTCHAT_ROOM_TABLE_LABELS;

//フォーム初期値
const INITIAL_ITEM = TEXTCHAT_ROOM_INITAL_ITEM;

export default {
  name: "SiteRooms",
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      PAGE_TITLE,
      TABLE_LABELS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      NO_DATA_MESSAGE,

      //固有
      TEXTCHAT_APPROVAL_TYPE,
      ARRAY_TEXTCHAT_APPROVAL_TYPE: Object.keys(TEXTCHAT_APPROVAL_TYPE).map(
        (key) => TEXTCHAT_APPROVAL_TYPE[key]
      ),

      // ログインユーザーのロールid
      // userRollId: CHAT_USER_ROLL.DIRECTOR.id,

      user_id: null,

      // 現場id
      fieldId: null,

      // チャットid
      chat_room_id: null,

      /**
       * (共通)
       * 一覧データ
       */
      items: [],

      /**
       * (共通)
       * checkbox選択item
       */
      selectedItems: [],
      selectedItem: { name: "", id: null },

      /**
       * (共通)
       * 編集アイテム
       * 定数から初期値を代入
       * ルーム作成フォームの値
       */
      editedItem: { ...INITIAL_ITEM },

      /**
       * (共通)
       * 新規フラグ
       */
      isNewItem: false,

      // 承認フラグ false = 承認解除 , true = 承認
      isApprovalFlag: false,

      // 検索パラメータ
      searchParams: {
        word: "",
        created_at: null, //作成日
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: null, //sortする項目
        asc: false,
      },

      /**
       * (共通)
       * ポップアップの状態管理
       */
      popups: {
        // 追加/編集フォーム
        isShowItemForm: false,
        // 削除確認ダイアログ表示
        isShowRemoveDialog: false,
        // 承認・承認解除ダイアログ表示
        isShowApprovalDialog: false,
        //チャット画面
        isDrawerChatView: false,
      },
    };
  },

  components: {
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    SitePortalHeader,
    Popup,
    ConfirmRemoveDialog,
    ConfirmApprovalDialog,
    //ユーザー用のコンポーネント
    Form,
    InputText,
    InputDatepicker,
    Select,
    Label,
    ChatView,
  },

  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    /**
     * グローバルヘッダーメニューを更新
     */
    Store.dispatch("GlobalHeader/setSiteMenu", {
      menuId: HEADER_MENU_ITEMS_SITE.TEXTCHAT.id,
    });
    /**
     * 開発では、
     */
    // this.$watch(
    //   () => Store.getters[`${STORE}/getUserRoll`],
    //   (value) => {
    //     this.userRollId = value;
    //   },
    //   { immediate: true, deep: true }
    // );

    /**
     * (共通)
     * ここからapiにリクエストします
     */
    this.$watch(
      () => [
        Store.getters[`GlobalHeader/getCurrentSite`],
        Store.getters[`Login/getUser`],
      ],
      async (data) => {
        if (data[1] && data[0] && "field_id" in data[0]) {
          this.user_id = data[1].id;
          this.fieldId = data[0].field_id;
          this.getItems();
          // console.log("■■■■ getCurrentSite==", data[0]);
          // console.log("■■■■ User==", data[1]);

          //自分のロール取得
          await Store.dispatch(`${STORE}/loginUserRoll`, {
            field_id: this.fieldId,
          });
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * ログインユーザーを取得
     */
    this.$watch(
      () => Store.getters[`Login/getUser`],
      (newValue) => {
        console.log("■■■■ User==", newValue);
      },
      { immediate: true, deep: true }
    );

    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getRooms`],
        // Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        this.items = data[0]
          .map((item) => {
            return { ...TEXTCHAT_ROOM_INITAL_ITEM, ...item };
          })
          .reverse();

        /**
         *  (共通)
         * ページネーション更新
         */
        // let searchParams = { ...this.searchParams };
        // searchParams.totalPage = data[1].total;
        // searchParams.currentPage = data[1].current;
        // searchParams.total_item = data[1].total_item;
        // this.searchParams = searchParams;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */
  computed: {
    /**
     * (共通)
     * 削除ボタンの活性・非活性
     * selectedItems.length === 0
     * 自体はtemplate側で記述できますが、
     * ここに明示的に状態を定義して可読性を担保してます
     */
    multiSelectBtn() {
      return this.selectedItems.length === 0;
    },
    getCurrentSite() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    // 承認権限
    hasApproval() {
      return CHAT_USER_ROLL.DIRECTOR.id === this.userRollId;
    },
    // 編集権限
    hasEditable() {
      return (
        CHAT_USER_ROLL.DIRECTOR.id === this.userRollId ||
        CHAT_USER_ROLL.FOREMAN.id === this.userRollId
      );
    },
    // 承認・承認解除
    approvalDialogText() {
      return `選択したルームを${
        this.isApprovalFlag ? "承認" : "承認解除"
      }してもよろしいでしょうか？`;
    },
    userRollId() {
      return Store.getters[`${STORE}/getUserRoll`];
    },
  },

  methods: {
    getApproval(v) {
      const f = this.ARRAY_TEXTCHAT_APPROVAL_TYPE.find((item) => item.id === v);
      return f ? f.label : "";
    },
    getApprovalClass(v) {
      const f = this.ARRAY_TEXTCHAT_APPROVAL_TYPE.find((item) => item.id === v);
      return f ? f.key : "";
    },

    /**
     * チャットを開く
     * @param {*} Number
     */
    onChatView(id) {
      this.chat_room_id = id;
      this.popups.isDrawerChatView = true;
    },
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 作成/編集フォーム
     * INITIAL_ITEM で定義した値がitemに入ります
     * @param INITIAL_ITEMで定義している値
     */
    openNewItemForm() {
      this.editedItem = { ...INITIAL_ITEM };
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },
    openItemForm(item) {
      this.editedItem = { ...item };
      this.isNewItem = false;
      this.popups.isShowItemForm = true;
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.$nextTick(() => {
        //選択を初期化
        this.editedItem = { ...INITIAL_ITEM };
      });
    },

    /**
     *  (共通)
     * 確認ダイアログ
     * 基本的に変更しないでいいはず
     */
    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
      this.$nextTick(() => {
        this.selectedItem = { name: "", id: null };
      });
    },

    /**
     * (共通)
     * フォームの変更を受け取る
     */
    async formUpdate(params) {
      console.log("■ formUpdate", params);
      let editedItem = { ...this.editedItem };
      editedItem.name = params.name;
      editedItem.users = params.users;
      this.editedItem = editedItem;
    },

    /**
     *  (共通)
     * ストア / api
     * ストア実装ルールにしたがっている場合は、
     */

    // データ取得
    async getItems() {
      if (this.fieldId) {
        await Store.dispatch(`${STORE}/rooms`, {
          field_id: this.fieldId,
        });
      } else {
        Store.dispatch("Error/show", {
          status: 200,
          message: "ルームが取得できませんでした",
        });
      }
    },

    deleteRoom(id) {
      this.selectedItem = this.items.find((item) => item.id === id);
      this.popups.isShowRemoveDialog = true;
    },

    // 削除
    async removeItems() {
      const ids = this.selectedItems.map((item) => item.id);
      const params = {
        ids,
      };
      const result = await Store.dispatch(`${STORE}/roomDelete`, params);

      if (!result.hasError) {
        //ダイアログ閉じる
        this.closeRemoveDialog();
        //成功したら値を更新
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
      }
    },

    /**
     * (共通)
     * 新規登録 / 更新
     * - idがある場合は更新
     */
    async submitForm({ needApproval }) {
      const { id, name, users } = this.editedItem;
      console.log(needApproval, name, users);

      // 更新 or 作成
      let endpoint = id ? "roomUpdate" : "roomCreate";
      let params = {
        id,
        name,
        field_id: this.fieldId,
        approval_flag: needApproval
          ? ROOM_APPROVAL_FLAG.UNAPPROVED
          : ROOM_APPROVAL_FLAG.APPROVED,
        // users,
      };

      // ルーム 新規 / 更新
      if (!id) delete params.id;
      const result = await Store.dispatch(`${STORE}/${endpoint}`, params);

      if (result.hasError) {
        Store.dispatch("Error/show", {
          status: 200,
          message: "保存できませんでした",
        });
        return;
      }

      // ユーザーいる場合
      if (users.length > 0) {
        const user_ids = users.map((user) => {
          return user.user_id;
        });
        const chat_room_id = result.data.contents.entries[0].id;
        params = { chat_room_id, user_ids };
        console.log("User = ", params);
        const resultUser = await Store.dispatch(`${STORE}/usersUpdate`, params);

        if (resultUser.hasError) {
          Store.dispatch("Error/show", {
            status: 200,
            message: "ユーザーを保存できませんでした",
          });
          return;
        }
      }

      // 一覧を更新
      this.getItems();
      this.popups.isShowItemForm = false;
    },

    /***
     * ルーム承認ダイアログ
     */
    openApproveRooms() {
      this.isApprovalFlag = true;
      let popups = { ...this.popups };
      popups.isShowApprovalDialog = true;
      this.popups = popups;
    },
    openRejectRooms() {
      this.isApprovalFlag = false;
      let popups = { ...this.popups };
      popups.isShowApprovalDialog = true;
      this.popups = popups;
    },
    // 承認
    async updateApprove() {
      const ids = this.selectedItems.map((item) => item.id);
      let rooms = this.items.filter((item) => {
        return ids.includes(item.id);
      });

      rooms = rooms.map((room) => {
        const { id, name, field_id, company_id } = { ...room };
        return {
          id,
          name,
          field_id,
          company_id,
          approval_flag: this.isApprovalFlag,
        };
      });

      // ルーム 新規 / 更新
      const result = await Store.dispatch(`${STORE}/updateApprove`, rooms);
      if (result.hasError) {
        Store.dispatch("Error/show", {
          status: 200,
          message: "更新できませんでした",
        });
        return;
      }

      // 一覧を更新
      this.selectedItems = [];
      this.getItems();

      // 閉じる
      let popups = { ...this.popups };
      popups.isShowApprovalDialog = false;
      this.popups = popups;
    },

    /**
     *  (ユーザー用)
     * 日付変換
     * @param date:yyyy-mm-ddThh:mm:ss:z
     */
    getFormattedDate(date) {
      const t = format(new Date(date), "yyyy/MM/dd HH:mm");
      // console.log(t);
      return t;
      // return fmtSlashDate(date);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.approvalStatus {
  display: inline;
  padding: 4px 6px;
  text-align: center;
  border-radius: 6px;
  &.approve {
    color: white;
    background-color: $color_primary;
  }
  &.reject {
    color: white;
    background-color: $color_warning;
  }
}

.drawerChatView {
  z-index: 999;
}
</style>
