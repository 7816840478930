<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('openItemForm', 1, name_type, name_title)"
  >
    <path
      d="M14.9954 2.30225C15.1512 2.30225 15.2905 2.36127 15.4133 2.47933C15.5267 2.59266 15.5833 2.72961 15.5833 2.89016V14.1102C15.5833 14.2707 15.5267 14.4077 15.4133 14.521C15.2905 14.6391 15.1512 14.6981 14.9954 14.6981H5.54624C5.39041 14.6981 5.2511 14.6391 5.12832 14.521C5.01499 14.4077 4.95832 14.2707 4.95832 14.1102V12.0418H2.00457C1.84874 12.0418 1.70943 11.9852 1.58666 11.8718C1.47332 11.7491 1.41666 11.6097 1.41666 11.4539V5.54641C1.41666 5.39058 1.47332 5.25127 1.58666 5.1285C1.70943 5.01516 1.84874 4.9585 2.00457 4.9585H4.95832V2.89016C4.95832 2.72961 5.01499 2.59266 5.12832 2.47933C5.2511 2.36127 5.39041 2.30225 5.54624 2.30225H14.9954ZM4.95832 9.251L5.79416 10.8235H7.06207L5.66666 8.54266L7.03374 6.29725H5.82249L5.05041 7.721L5.02207 7.7635L5.00082 7.81308C4.87805 7.5628 4.74582 7.31016 4.60416 7.05516C4.4861 6.80488 4.36096 6.55225 4.22874 6.29725H2.94666L4.28541 8.55683L2.83332 10.8235H4.09416L4.95832 9.251ZM9.83166 13.8127V12.0418H5.84374V13.8127H9.83166ZM9.83166 11.1564V8.94641H8.49999V11.1564H9.83166ZM9.83166 8.061V5.84391H8.49999V8.061H9.83166ZM9.83166 4.9585V3.18766H5.84374V4.9585H9.83166ZM14.6979 13.8127V12.0418H10.7171V13.8127H14.6979ZM14.6979 11.1564V8.94641H10.7171V11.1564H14.6979ZM14.6979 8.061V5.84391H10.7171V8.061H14.6979ZM14.6979 4.9585V3.18766H10.7171V4.9585H14.6979Z"
      fill="black"
    />
  </svg>
</template>
<script>
export default {
  props: {
    name_type: String,
    name_title:String
  },
};
</script>