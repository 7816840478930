<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col>
                  <SectionLabel label="ATKY" class="ml-2" />
                  <DangerForecast
                    name="work_predicted_risk_results"
                    :values="formValues"
                    :editable="editable"
                    @onInput="onInput"
                  />
                </v-col>
                <v-col></v-col>
              </v-row>
              <SectionLabel label="火気使用届" class="mt-4"/>
              <v-row no-gutters>
                <v-col>
                  <v-list-item>
                    <v-list-item-content>
                      <FormPurposeOfUse
                        name="work_hot_results"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col style="margin-top: 4vh">
                  <FormFireSource
                    :name="USER_TYPE.TYPE_2"
                    flag_name="work_user_results"
                    :values="formValues"
                    :editable="editable"
                    :EMPLOYEE_ARRAY="EMPLOYEE_ARRAY"
                    @onInput="onInput"
                  />
                  <FormFireUse
                    :name="USER_TYPE.TYPE_3"
                    flag_name="work_user_results"
                    :values="formValues"
                    :editable="editable"
                    :EMPLOYEE_ARRAY="EMPLOYEE_ARRAY"
                    @onInput="onInput"
                  />
                </v-col>
              </v-row>
              <SectionLabel label="酸欠等危険作業" :class="editable ? 'mt-6':''"/>
              <v-row no-gutters>
                <v-col cols="12" sm="12" md="12">
                  <v-list-item>
                    <v-list-item-content>
                      <v-col>
                        <SwitchInput
                          class="oxygen_lack_flag_schedule"
                          name="oxygen_lack_flag"
                          :label="`酸欠等危険作業予定を作成`"
                          :values="formValues['work_results']"
                          :editable="editable"
                          @onInput="onInput"
                        />
                      </v-col>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-row>
              <v-col>
                  <FormDangerousWork
                    :name="USER_TYPE.TYPE_4"
                    flag_name="work_user_results"
                    :values="formValues"
                    :editable="editable"
                    :EMPLOYEE_ARRAY="EMPLOYEE_ARRAY"
                    @onInput="onInput"
                  />
                </v-col>
                <v-col>
                  <FormPersonCompleted
                    :name="USER_TYPE.TYPE_5"
                    flag_name="work_user_results"
                    :values="formValues"
                    :editable="editable"
                    :EMPLOYEE_ARRAY="EMPLOYEE_ARRAY"
                    @onInput="onInput"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import Select from "@/components/forms/elements/Select.vue";
import InputTextArea from "@/components/forms/elements/InputTextArea";
import SwitchInput from "@/components/forms/elements/SwitchInput";
import WorkDayWorker from "@/components/forms/schedule/works/components/WorkDayWorker.vue";
import WorkEarlyTimes from "@/components/forms/schedule/works/components/WorkEarlyTimes.vue";
import WorkOverTimes from "@/components/forms/schedule/works/components/WorkOverTimes.vue";
import DangerForecast from "@/components/forms/schedule/works/components/DangerForecast.vue";
import FormPurposeOfUse from "@/components/forms/schedule/works/components/FormPurposeOfUse.vue";
import FormFireSource from "@/components/forms/schedule/works/components/FormFireSource.vue";
import FormFireUse from "@/components/forms/schedule/works/components/FormFireUse.vue";
import FormDangerousWork from "@/components/forms/schedule/works/components/FormDangerousWork.vue";
import FormPersonCompleted from "@/components/forms/schedule/works/components/FormPersonCompleted.vue";
import { USER_TYPE } from "@/constants/SCHEDULE_WORKS";
import { Store } from "@/store/Store.js";
import _ from "lodash";

export default {
  data: () => {
    return {
      formValues: {},
      USER_TYPE,
      EMPLOYEE_ARRAY: []
    };
  },
  components: {
    Label,
    SectionLabel,
    InputText,
    Select,
    InputTextArea,
    SwitchInput,
    WorkDayWorker,
    WorkEarlyTimes,
    WorkOverTimes,
    DangerForecast,
    FormPurposeOfUse,
    FormFireUse,
    FormFireSource,
    FormPersonCompleted,
    FormDangerousWork
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue,oldValue) => {
        this.formValues = { ...newValue };
        if(JSON.stringify(newValue?.work_results?.field_tree_id) != JSON.stringify(oldValue?.work_results?.field_tree_id)) {
          this.getListUsers();
        }
        if (JSON.stringify(newValue?.work_results?.hot_work_flag) != JSON.stringify(oldValue?.work_results?.hot_work_flag) && newValue?.work_results?.hot_work_flag == 0) {
          let formValues = { ...this.formValues };
          formValues["work_user_results"][USER_TYPE.TYPE_2] = [];
          formValues["work_user_results"][USER_TYPE.TYPE_3] = [];
          formValues['work_hot_results'] = [];
          this.formValues = formValues;
          this.$emit("formUpdate", formValues);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      if (name == USER_TYPE.TYPE_2 || name == USER_TYPE.TYPE_3 || name == USER_TYPE.TYPE_4 || name == USER_TYPE.TYPE_5) {
        formValues["work_user_results"][name] = value;
      } else if (name == "oxygen_lack_flag") {
        if (value == 0) {
          formValues["work_user_results"][USER_TYPE.TYPE_4] = [];
          formValues["work_user_results"][USER_TYPE.TYPE_5] = [];
        }
        formValues["work_results"][name] = value;
      } else {
        formValues[name] = value;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    async getListUsers() {
      let field_tree_id = this.formValues.work_results?.field_tree_id;
      if(field_tree_id) {
        const params = {
          id: field_tree_id,
          state_handling_flag: 0
        };
        const rs = await Store.dispatch("PortalChart/getListCompanyPartnerUser", params);
        this.EMPLOYEE_ARRAY = _.cloneDeep(rs.data.contents.entries);
        this.EMPLOYEE_ARRAY.forEach((element) => {
          element.name = element.user_name_sei + " " + element.user_name_mei;
          element.id = element.user_id;
        });
      } else {
        this.EMPLOYEE_ARRAY = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/forms.scss";
</style>
