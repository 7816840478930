<template>
  <FormDialog>
    <template #header>
      <!-- 
        (共通)
        ヘッダーは全て共通
       -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title style="color: green"> 表示設定 </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn class="mr-5" @click="isShowConfirmDialog = true" depressed small outlined color="primary">
          やめる
        </v-btn>
        <v-btn depressed small color="primary" @click.once="onSave()" :key="flagButton"> 保存 </v-btn>
        <v-btn icon @click="isShowConfirmDialog = true">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <v-divider class="mt-3 mb-3"></v-divider>
      <v-container class="pl-10 pr-10">
        <v-row style="align-items: center">
          <Label label="工事" style="width: 10vw">
            <Select
              name="field_construction_id"
              :values="searchParams"
              :items="FIELD_CONSTRUCTION_ARRAY"
              :editable="true"
              @onInput="onChangeSearchParams"
            />
          </Label>
          <Label label="作業グループ" class="label-select" >
            <Select
              name="group_id"
              :values="searchParams"
              :items="FIELD_TREE_GROUP_ARRAY"
              item_text="group_name"
              :editable="true"
              @onInput="onChangeSearchParams"
            />
          </Label>
          <Label label="協力会社" class="label-select">
            <Select
              name="field_tree_id"
              :values="searchParams"
              :items="COMPANY_PARTNER_ARRAY"
              item_text="company_name"
              item_value="field_tree_id"
              :editable="true"
              @onInput="onChangeSearchParams"
            />
          </Label>
          <v-btn small depressed @click="onSearchLeftUser" color="primary"> 検索</v-btn>
          <v-spacer></v-spacer>
          <span>表示数</span>
          <Select
            style="width: 7vw"
            class="mt-4 mr-2 ml-2"
            name="row"
            :values="formValues"
            :items="ROW_COL_LIST"
            :editable="true"
            @onInput="onInput"
          />
          <v-icon>mdi-close</v-icon>
          <Select
            style="width: 7vw"
            class="mt-4 mr-2 ml-2"
            name="col"
            :values="formValues"
            :items="ROW_COL_LIST"
            :editable="true"
            @onInput="onInput"
          />
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="5" md="6">
            <div>
              <div class="headerOptionsLeftUser">
                <v-btn
                  :disabled="!isShowButtonRegister || checkFullRow"
                  color="primary"
                  @click="register()"
                  >登録</v-btn
                >
              </div>
              <v-radio-group v-model="leftUserChecked">
                <v-data-table
                  :headers="TABLE_LABELS"
                  :items="leftUserItems"
                  :items-per-page="leftUserItems.length"
                  hide-default-footer
                  disable-sort
                  class="elevation-0 v-data-table__wrapper"
                  :noDataText="flagSearch ? NO_DATA_MESSAGE : ''"
                  outlined
                  :style="`height:${
                    params.mainHeight - 150
                  }px;overflow-x: hidden;overflow-y: auto;`"
                >
                  <template v-slot:[`item.user_name`]="{ item }">
                    <v-radio :value="item.field_tree_user_id" :label="item.user_name" />
                  </template>
                </v-data-table>
              </v-radio-group>
            </div>
          </v-col>
          <v-col cols="12" sm="7" md="6">
            <div>
              <div class="headerOptionsRightUser">
                <v-btn
                  style="margin-left: 4vw"
                  depressed
                  outlined
                  color="primary"
                  :disabled="checkFullRow"
                  @click="addEmptyUser()"
                >
                  <v-icon>mdi-plus</v-icon>
                  <span class="ml-2">空追加</span>
                </v-btn>
                <Select
                  class="select-row-user"
                  name="row_user"
                  :values="formValues"
                  :items="ROW_USER"
                  :editable="true"
                  @onInput="onInput"
                />
                <v-btn
                  :disabled="!isShowButtonRemove"
                  color="primary"
                  @click="remove"
                  >解除</v-btn
                >
              </div>
              <v-radio-group v-model="rightUserChecked">
                <v-data-table
                  :headers="TABLE_LABELS"
                  :items="rightUserItems"
                  :items-per-page="rightUserItems.length"
                  hide-default-footer
                  disable-sort
                  class="elevation-0 v-data-table__wrapper"
                  noDataText=""
                  outlined
                  :style="`height:${
                    params.mainHeight - 150
                  }px;overflow-x: hidden;overflow-y: auto;`"
                >
                  <template v-slot:[`item.user_name`]="{ item }">
                    <v-radio :value="item.order_number" :label="item.user_name" />
                  </template>
                </v-data-table>
              </v-radio-group>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="isShowConfirmDialog = false"
          @yes="closeForm"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>
<script>
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import Popup from "@/components/common/Popup.vue";
import Label from "@/components/forms/elements/Label";
import Select from "@/components/forms/elements/Select";
import { ROW_COL_LIST, USER_PANEL_LABELS } from "@/constants/ENTRANCE_HISTORY";
import { Store } from "@/store/Store";
import _ from "lodash";

const TABLE_LABELS = USER_PANEL_LABELS;
const STORE_ENTRANCE_HISTORY = "EntranceHistory";
const STORE_PORTAL_CHART = "PortalChart";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";

export default {
  data() {
    return {
      NO_DATA_MESSAGE,
      ROW_COL_LIST,
      TABLE_LABELS,
      isShowConfirmDialog: false,
      FIELD_CONSTRUCTION_ARRAY: [],
      FIELD_TREE_GROUP_ARRAY: [],
      COMPANY_PARTNER_ARRAY: [],
      leftUserItems: [],
      allRightUserItems: [],
      ROW_USER: [],
      leftUserChecked: null,
      rightUserChecked: null,
      rightUserItems: [],
      formValues: {
        row: 0,
        col: 0,
        row_user: 1
      },
      searchParams: {
        field_construction_id: null,
        group_id: null,
        field_tree_id: null
      },
      flagButton : false,
      flagSearch : false
    };
  },
  components: {
    FormDialog,
    ConfirmCloseDialog,
    Popup,
    Label,
    Select
  },
  computed: {
    isShowButtonRegister() {
      return this.leftUserChecked;
    },

    checkFullRow() {
      return this.rightUserItems.length == this.formValues.col;
    },

    isShowButtonRemove() {
      return this.rightUserChecked;
    },

    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },

    apiParams() {
      return {
        field_construction_id: this.searchParams.field_construction_id,
        group_id: this.searchParams.group_id,
        field_tree_id: this.searchParams.field_tree_id
      };
    }
  },
  mounted() {
    // get list constructions field
    this.getWorkTypes();
    this.$watch(
      () => Store.getters[`${STORE_ENTRANCE_HISTORY}/getListEntrancePanel`],
      (data) => {
        if(data) {
          //get data user right
          this.allRightUserItems = [...data.field_entrance_history_panels];
          //set data row and col to formValues
          let _formValues = { ...this.formValues };
          _formValues.row = data.field_entrance_panel_sizes.row;
          _formValues.col = data.field_entrance_panel_sizes.column;
          this.ROW_USER = this.getListRowUser(_formValues.row);
          this.formValues = { ..._formValues };
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => this.allRightUserItems,
      (data) => {
        this.rightUserItems = this.getRightUserItemsByRow(this.formValues.col, this.formValues.row_user, data);
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => Store.getters[`${STORE_ENTRANCE_HISTORY}/getListUserPanel`],
      (data) => {
        this.leftUserItems = [...data];
      },
      {
        deep: true
      }
    );

    this.$watch(
      () => Store.getters[`${STORE_PORTAL_CHART}/getWorkTypes`],
      (data) => {
        this.FIELD_CONSTRUCTION_ARRAY = [this.getDefaultArrays("id", "name"), ...data];
        this.FIELD_TREE_GROUP_ARRAY = [this.getDefaultArrays("id", "group_name")];
        this.COMPANY_PARTNER_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name")];
      },
      {
        deep: true
      }
    );

    this.$watch(
      () => Store.getters[`${STORE_PORTAL_CHART}/getListGroup`],
      (data) => {
        this.FIELD_TREE_GROUP_ARRAY = [this.getDefaultArrays("id", "group_name"), ...data];
      },
      {
        deep: true
      }
    );

    this.$watch(
      () => Store.getters[`${STORE_PORTAL_CHART}/getListCompanyPartner`],
      (data) => {
        this.COMPANY_PARTNER_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name"), ...data];
      },
      {
        deep: true
      }
    );
  },
  methods: {
    // get list row user by row
    getListRowUser(value) {
      let rs = [];
      let i = 1;
      while (i <= value) {
        rs.push({ id: i, name: i + " 行目" });
        i++;
      }
      return rs;
    },
    //change search params
    onChangeSearchParams({ name, value }) {
      let _searchParams = { ...this.searchParams };
      _searchParams[name] = value;
      if (name == "field_construction_id") {
        _searchParams.group_id = null;
        _searchParams.field_tree_id = null;
        if (value) {
          this.getListGroups(value);
          this.getListCompanyPartners(null, value);
        } else {
          this.FIELD_TREE_GROUP_ARRAY = [this.getDefaultArrays("id", "group_name")];
          this.COMPANY_PARTNER_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name")];
        }
      }
      if (name == "group_id") {
        _searchParams.field_tree_id = null;
        if (value) {
          this.getListCompanyPartners(value, _searchParams.field_construction_id);
        } else {
          this.COMPANY_PARTNER_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name")];
        }
      }
      
      if (name == "field_tree_id" ) {
        if (!this.searchParams['group_id'] && value) {
            let groupId = this.getGroupdIdByFieldTree(value);
            let hasGroup = this.hasGroupById(groupId);
            if (hasGroup) {
              this.searchParams['group_id'] = groupId;
              this.getListCompanyPartners(groupId, _searchParams.field_construction_id);
            }
          }
      }
      this.searchParams = { ..._searchParams };
    },
    // close confirm dialog form
    closeForm() {
      this.$emit("closeForm");
    },
    // change row col and row user
    onInput({ name, value }) {
      let _formValues = { ...this.formValues };
      if (name == "row") {
        this.ROW_USER = this.getListRowUser(value);
        this.allRightUserItems = this.updateAllUseRightItemsByRowCol(this.allRightUserItems, value, _formValues.col);
      }
      if (name == "row_user") {
        this.rightUserChecked = null;
        this.rightUserItems = this.getRightUserItemsByRow(_formValues.col, value, this.allRightUserItems);
      }
      if (name == "col") {
        this.allRightUserItems = this.updateAllUseRightItemsByRowCol(this.allRightUserItems, _formValues.row, value);
        this.rightUserItems = this.getRightUserItemsByRow(value, _formValues.row_user, this.allRightUserItems);
      }
      _formValues[name] = value;
      this.formValues = { ..._formValues };
    },
    // register user left to right
    register() {
      let _allRightUserItems = _.cloneDeep(this.allRightUserItems);
      // find move item target
      let user_name = this.leftUserItems.find(
        (e) => e.field_tree_user_id === this.leftUserChecked
      ).user_name;
      let index_register = (this.formValues.row_user - 1) * this.formValues.col + this.rightUserItems.length + 1;

      // move item from left to right
      _allRightUserItems.push({
        field_tree_user_id: this.leftUserChecked,
        order_number: index_register,
        user_name: user_name
      });
      // remove item in left
      this.leftUserItems = this.leftUserItems.filter(
        (e) => e.field_tree_user_id != this.leftUserChecked
      );
      this.allRightUserItems = [..._allRightUserItems];
      this.leftUserChecked = null;
      this.flagSearch = false;
    },

    // add empty user in right
    addEmptyUser() {
      let _allRightUserItems = _.cloneDeep(this.allRightUserItems);
      let index_register = this.rightUserChecked ? this.rightUserChecked + 1 : (this.formValues.row_user - 1) * this.formValues.col + this.rightUserItems.length + 1;
      //if dont check in right
      if (!this.rightUserChecked) {
        //add to user_image
        _allRightUserItems.push({ field_tree_user_id: null, order_number: index_register, user_name: null });
      } else {
        //increase index after checked
        _allRightUserItems.forEach((e) => {
          if (e.order_number > this.rightUserChecked && e.order_number <= this.rightUserItems[this.rightUserItems.length - 1].order_number) {
            e.order_number += 1;
          }
        });
        //push to last array
        _allRightUserItems.push({ field_tree_user_id: null, order_number: index_register, user_name: null });
        //sort by order_number
        _allRightUserItems.sort((a, b) => a.order_number - b.order_number);
      }
      this.allRightUserItems = [..._allRightUserItems];
    },

    // remove user right to left
    remove() {
      let _allRightUserItems = _.cloneDeep(this.allRightUserItems);
      //get name by order_number
      let userRightChecked = this.rightUserItems.find(
        (e) => e.order_number === this.rightUserChecked
      );
      // if checked in right not null and dont stop
      if (userRightChecked.field_tree_user_id && userRightChecked.state_handling_flag != 1) {
        //add to left
        this.leftUserItems = [...this.leftUserItems, {
          field_tree_user_id: userRightChecked.field_tree_user_id,
          user_name: userRightChecked.user_name
        }];
      }
      //filter , remove this checked
      _allRightUserItems = _allRightUserItems.filter(
        (e) => e.order_number != this.rightUserChecked
      );
      _allRightUserItems.forEach((e) => {
        if (e.order_number > this.rightUserChecked && e.order_number <= this.rightUserItems[this.rightUserItems.length - 1].order_number) {
          e.order_number -= 1;
        }
      });
      this.rightUserChecked = null;
      this.allRightUserItems = [..._allRightUserItems];
    },

    // submit update
    async onSave() {
      let user_panels = this.deleteParams(this.allRightUserItems);
      let params = {
        field_id : this.CURRENT_SITE.field_id,
        row: this.formValues.row,
        column: this.formValues.col,
        user_panels: user_panels
      };
      const response = await Store.dispatch(`${STORE_ENTRANCE_HISTORY}/updateEntrancePanel`,  params );
      if (!response.hasError) {
        let params = { field_id: this.CURRENT_SITE.field_id };
        await Store.dispatch(`${STORE_ENTRANCE_HISTORY}/getListEntrancePanel`, {
          params
        });
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
        this.$emit("closeForm");
      } else {
        this.flagButton = !this.flagButton;
      }
    },
    //update right user by all right user
    getRightUserItemsByRow(col, row, arrayItems) {
      return arrayItems.filter(element => element.order_number > (row - 1) * col && element.order_number <= row * col);
    },

    //update all right user by row and col
    updateAllUseRightItemsByRowCol(array, row, col) {
      let allRightUserItems = _.cloneDeep(array)
      let total = row * col;
      let _arr = [];
      if (allRightUserItems.length > total) {
        allRightUserItems.forEach((element, index) => {
          if (index < total) {
            element.order_number = index + 1;
            _arr.push(element);
          }
        });
      } else {
        allRightUserItems.forEach((element, index) => {
          element.order_number = index + 1;
          _arr.push(element);
        });
      }
      return _arr;
    },

    //add default to select
    getDefaultArrays(id, value) {
      let results = {};
      results[id] = null;
      results[value] = "";
      return results;
    },

    //get list field construction
    async getWorkTypes() {
      if (this.CURRENT_SITE.field_id) {
        const params = {
          field_id: this.CURRENT_SITE.field_id,
          force_all_construction_flag : 1
        };
        await Store.dispatch(`${STORE_PORTAL_CHART}/getWorkTypes`, params);
      }
    },

    //get list group by field construction
    async getListGroups(field_construction_id) {
      const params = {
        field_construction_id: field_construction_id,
        force_all_group_flag : 1
      };
       await Store.dispatch(
        "PortalChart/getListGroups",
        params
      );
    },

    //get list company partner by group id
    async getListCompanyPartners(group_id, field_construction_id) {
      let params = {};
      if (group_id) {
        params = { field_tree_group_id: group_id , state_handling_flag: 0,}
      } else if (field_construction_id) {
        params = { field_construction_id: field_construction_id , state_handling_flag: 0,}
      } else return;
      await Store.dispatch(`${STORE_PORTAL_CHART}/getListCompanyPartner`, { params });
    },

    // search user in left
    async onSearchLeftUser() {
      let _apiParams = { ...this.apiParams };
      _apiParams["field_id"] = this.CURRENT_SITE.field_id;
      await Store.dispatch(`${STORE_ENTRANCE_HISTORY}/getListUserPanel`, {
        params: _apiParams
      });
      this.flagSearch = true;
    },

    // delete param when call api update
    deleteParams(array) {
      let _array = _.cloneDeep(array);
      _array.forEach(e => {
        delete e.company_name;
        delete e.person_image;
        delete e.person_image_url;
        delete e.admission_status;
        delete e.user_name;
        delete e.state_handling_flag;
      });
      return _array;
    },

    getGroupdIdByFieldTree(fieldTreeId) {
      return this.COMPANY_PARTNER_ARRAY.find(e => e.field_tree_id === fieldTreeId)?.field_tree_group_id || null;
    },
    hasGroupById(groupId) {
      if (!groupId) return false;
      let group = this.FIELD_TREE_GROUP_ARRAY.find(e => e.id === groupId);
      if (group) return true;
      return false;
    },
  }
};
</script>
<style scoped>
.headerOptionsRightUser {
  display: flex;
  justify-content: space-between;
  margin-bottom: -25px;
}

.headerOptionsLeftUser {
  display: flex;
  justify-content: flex-end;
}

.label-select {
  max-width: 10vw;
  white-space: nowrap;
  overflow: hidden
}
.select-row-user{
  width: 10vw;
  margin-top: -5px;
}
</style>
