<template>
  <FormDialog>
    <template #header>
      <!-- 
        (共通)
        ヘッダーは全て共通
       -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!editable"
          depressed
          small
          color="primary"
          @click="onEditable"
          data-testid="btn-cancel"
        >
          編集
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          small
          :disabled="!valid"
          color="primary"
          @click="onSubmit"
          data-testid="btn-submit"
        >
          登録
        </v-btn>
        <v-btn icon @click="isShowConfirmDialog = true" data-testid="btn-close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab
              v-for="(form, key) in FORMS"
              :key="key"
              :data-testid="`workerForm-tab-${key}`"
            >
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!-- 
        (共通)
        全てのフォームのバリデーションの監視を行う
       -->
      <ValidationObserver ref="observer" v-slot="observer">
        <!-- フォームtab -->
        <v-tabs-items v-model="tab">
          <!-- 基本情報 -->
          <keep-alive>
            <v-tab-item>
              <UserFormPage1
                :editable="editable"
                :item="item"
                :mainHeight="params.mainHeight"
                @formUpdate="formUpdate"
              />
            </v-tab-item>
            <!-- 建設業の許可 -->
            <v-tab-item>
              <UserFormPage2
                :editable="editable"
                :item="item"
                :mainHeight="params.mainHeight"
                @formUpdate="formUpdate"
              />
            </v-tab-item>
          </keep-alive>
        </v-tabs-items>

        <!-- 
          (共通)
          ValidationObserverのスロットプロパティを受け取って
          updateValidateメソッドを実行して、
          バリデーション結果をスクリプト側に渡してます
        -->
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmDialog
          title=""
          text="「登録」ボタンで登録していないフォームの値は消えますがよろしいですか？"
          @close="isShowConfirmDialog = false"
          @yes="closeForm"
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "../elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import UserFormPage1 from "@/components/forms/workers/WorkerFormPage1.vue";
import UserFormPage2 from "@/components/forms/workers/WorkerFormPage2.vue";

/**
 * (共通)
 * タイトル
 */
const TITLE = {
  NEW: "作業員新規作成",
  EDIT: "作業員編集",
};

/**
 * フォームとタブの設定
 */
const FORMS = {
  UserFormPage1: { id: 1, title: "基本情報" },
  UserFormPage2: { id: 2, title: "建設業の許可" },
};

export default {
  data: () => {
    return {
      FORMS,
      valid: false, //バリデーション結果
      // tab初期化
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmDialog,
    UserFormPage1,
    UserFormPage2,
  },
  props: {
    item: Object,
    isNewItem: Boolean,
  },
  mounted() {
    /**
     * (共通)
     * 新規作成を監視
     */
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        //新規の場合は最初から編集モード
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    // 新規or編集でタイトル変更
    formtitle() {
      return this.isNewItem ? TITLE.NEW : TITLE.EDIT;
    },
  },
  methods: {
    /**
     * (共通)
     * フォームの変更を送信
     */
    formUpdate(params) {
      this.$emit("formUpdate", params);
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      this.editable = true;
    },
    /**
     * (共通)
     * 状態を詳細に変更
     */
    onDetail() {
      this.editable = false;
    },

    /**
     * (共通)
     * 登録
     */
    onSubmit() {
      this.$emit("submit");
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid }) {
      console.log("valid", valid);
      this.valid = valid;
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
