<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <!-- 支店名 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="支店名" :editable="editable" required>
                        <InputText
                          name="name"
                          :values="formValues"
                          :editable="editable"
                          placeholder="支店名"
                          validation_label="支店名"
                          validation_rules="required|max:255"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 郵便番号 -->
                  <v-row>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="郵便番号" :editable="editable" required>
                            <InputText
                              name="postal_code"
                              :values="formValues"
                              :editable="editable"
                              placeholder="郵便番号"
                              validation_label="郵便番号"
                              validation_rules="required|max:8|postcode"
                              @onInput="onChangePostalCode"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <!-- 都道府県 -->
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="都道府県" :editable="editable" required>
                            <Select
                              name="cmn_mst_prefecture_id"
                              :values="formValues"
                              :items="CMN_MST_PREFECTURES"
                              item_text="prefecture"
                              :editable="editable"
                              validation_label="都道府県"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <!-- 市区町村 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="市区町村" :editable="editable" required>
                        <InputText
                          name="city_name"
                          :values="formValues"
                          :editable="editable"
                          placeholder="市区町村"
                          validation_label="市区町村"
                          validation_rules="required|max:512"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 番地・建物名 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="番地・建物名" :editable="editable">
                        <InputText
                          name="building_name"
                          :values="formValues"
                          :editable="editable"
                          placeholder="番地・建物名"
                          validation_label="番地・建物名"
                          validation_rules="max:512"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 電話番号 -->
                  <v-row>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="電話番号" :editable="editable" required>
                            <InputText
                              name="tel"
                              :values="formValues"
                              :editable="editable"
                              placeholder="電話番号"
                              validation_label="電話番号"
                              validation_rules="required|max:16|phone"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <!-- FAX -->
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="FAX" :editable="editable">
                            <div v-if="editable" style="height: 8px"></div>
                            <InputText
                              name="fax"
                              :values="formValues"
                              :editable="editable"
                              placeholder="FAX"
                              validation_label="FAX"
                              validation_rules="max:16|phone"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "../elements/Label";
import InputText from "../elements/InputText";
import Select from "../elements/Select.vue";
import { Store } from "@/store/Store.js";
import { postalCodeSearch } from "@/utils/postalCodeSearch";
export default {
  data: () => {
    return {
      formValues: {},
      CMN_MST_PREFECTURES:[{ id: null, prefecture: "" },...Store.state.CmnMst.constants.entries.cmn_mst_prefectures],
    };
  },
  components: {
    Label,
    InputText,
    Select,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    
    /**
     * 郵便番号から都道府県、市町村名を検索
     */
    async onChangePostalCode({ value }) {
      const formValues = { ...this.formValues };
      formValues.postal_code = value;
      if (value == "") {
        formValues.cmn_mst_prefecture_id = "";
        formValues.city_name = "";
      } else if (value.match(/^\d{3}-?\d{4}$/g)) {
        const result = await postalCodeSearch.search({ postal_code: value });
        if (result) {
          formValues.cmn_mst_prefecture_id = result.prefecture.id;
          formValues.city_name = result.city;
        }
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";
</style>
