<template>
  <v-card class="dialog">
    <v-card-title>
      <div :style="getTitleColorStyle" class="title">{{ confirmWorkChart.message.title }}</div>
    </v-card-title>
    <v-card-text v-if="confirmWorkChart.isRestartWork" class="body">
      <div class="text-wrap">
        {{confirmWorkChart.message.message_text1}}
      </div>
    </v-card-text>
    <v-card-text v-if="confirmWorkChart.isEndWork && confirmWorkChart.isActive" class="body">
      <div class="text-wrap">
        {{confirmWorkChart.message.message_text1}}
      </div>
      <div class="text-wrap">
        {{confirmWorkChart.message.message_text2}}
      </div>
    </v-card-text>
    <v-card-text v-if="confirmWorkChart.isEndWork && confirmWorkChart.isActive" class="body">
      <div class="text-wrap">
        {{confirmWorkChart.message.message_text3}}
      </div>
    </v-card-text>
    <v-card-text v-if="!confirmWorkChart.isActive" class="body">
      <div class="text-wrap">
        <div>
          <div class="text left-text-confirm">{{ confirmWorkChart.message.message_text1 }}</div>
        </div>
        <div class="left-text-error">
          <li>{{ confirmWorkChart.message.message_text2 }}</li>
          <li>{{ confirmWorkChart.message.message_text3 }}</li>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        :color="getColor"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn v-if="confirmWorkChart.isActive" depressed class="btn" :color="getColor" @click="$emit('yes')">
        {{ confirmWorkChart.message.title }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      isFinshed: false,
    }
  },
  components: {},
  props: {
    confirmWorkChart: Object
  },
  mounted() {
    this.$watch(
      () => this.confirmWorkChart,
      (newValue) => {
        if(newValue.isEndWork) {
          this.isFinshed = true;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getTitleColorStyle() {
      if(!this.confirmWorkChart.isActive) {
        return "color:red;"
      }
      return this.isFinshed ? "color:red;" : "color:green;";
    },
    getColor() {
      if(!this.confirmWorkChart.isActive) {
        return "warning"
      }
      return this.isFinshed ? "warning" : "primary";
    },
  },
};
</script>
