<template>
  <FormDialog>
    <template #header>
      <!--
        (共通)
        ヘッダーは全て共通
       -->
      <v-app-bar flat color="rgba(0, 0, 0, 0)">
        <v-toolbar-title style="color: #1b9c4f" class="pa-5">
          {{ formTitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="GET_ROLE_USER === ROLE_USER[3] && !isDetailInspec"
          color="warning"
          :disabled="isDisableButtonReject"
          @click="rejectConfirm"
        >
          承認解除
        </v-btn>
        <v-btn
          v-if="GET_ROLE_USER === ROLE_USER[3] && !isDetailInspec"
          color="primary"
          class="ml-4"
          @click="approveConfirm"
          :disabled="isDisableButtonApprove"
        >
          承認
        </v-btn>
        <v-btn
          v-if="GET_ROLE_USER === ROLE_USER[3] && isDetailInspec && isApproved"
          color="warning"
          @click="rejectConfirm"
        >
          承認解除
        </v-btn>
        <v-btn
          v-if="GET_ROLE_USER === ROLE_USER[3] && isDetailInspec && isUnApprove"
          color="primary"
          class="ml-4"
          @click="approveConfirm"
        >
          承認
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title class="w-50">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in GET_FORM_TABS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
          <h4 class="mr-15">{{NOTE_TEXT}}</h4>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!-- フォームtab -->
      <v-tabs-items v-model="tab" v-if="!isDetailInspec">
        <v-tab-item>
          <InspectionResultFormTab1
            :item="inspectionResult.machine_company_field_inspection_results"
            :mainHeight="params.mainHeight"
            :isResetSelected="isResetSelected1"
            @selectedItems="getSelectedItems"
            @resetFlagSelected="isResetSelected1 = false"
            @isShowFormInspecDetail="openFormInspecDetail"
          />
        </v-tab-item>
        <v-tab-item>
          <InspectionResultFormTab2
            :item="inspectionResult.machine_company_field_monthly_inspection_results"
            :mainHeight="params.mainHeight"
            :isResetSelected="isResetSelected2"
            @selectedItems="getSelectedItems"
            @resetFlagSelected="isResetSelected2 = false"
            @isShowFormInspecDetail="openFormInspecDetail"
          />
        </v-tab-item>
      </v-tabs-items>
      <v-tabs-items v-model="tab" v-else>
        <v-tab-item>
          <InspectionResultFormDetailTab1
            :item="inspectionResultDetail"
            :mainHeight="params.mainHeight"
          />
        </v-tab-item>
        <v-tab-item>
          <InspectionResultFormDetailTab2
            :item="inspectionResultDetail"
            :isNoDataMessage="isNoDataMessage"
            :mainHeight="params.mainHeight"
          />
        </v-tab-item>
      </v-tabs-items>
      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="isShowConfirmDialog = false"
          @yes="closeForm"
          warning
        />
      </Popup>
      <Popup width="480px" :dialog="popups.isShowErrorUpdateStatusDialog">
        <ConfirmDialog
          @OK="closeErrorConfirmDialog"
          :title="popups.title"
          :message="popups.message"
          :warning="!approvalFlag"
        />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowConfirmUpdateStatusDialog">
      <ConfirmUpdateStatus
        :flagError="flagError"
        @close="closeConfirmUpdateStatusDialog"
        @yes="updateStatusApproval"
        :title="popups.title"
        :text="popups.message"
        :text2="popups.text2"
        :titleBtnOk="popups.titleBtnOk"
        :flagAprrove="approvalFlag"
      />
    </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { Store } from "@/store/Store.js";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import InspectionResultFormTab1 from "./InspectionResultFormTab1.vue";
import InspectionResultFormTab2 from "./InspectionResultFormTab2.vue";
import InspectionResultFormDetailTab1 from "./InspectionResultFormDetailTab1.vue";
import InspectionResultFormDetailTab2 from "./InspectionResultFormDetailTab2.vue";
import ConfirmDialog from "@/views/device/stock/components/ConfirmDialog.vue";
import ConfirmUpdateStatus from "./components/ConfirmUpdateStatus.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import {
  MACHINE_COMPANY_INSPECTION_TABS,
  MACHINE_COMPANY_INSPECTION_DETAIL_TABS,
  INSPECTION_RESULT_CATEGORY,
  NOTE_TEXT,
  TABS,
} from "@/constants/MACHINE_FIELD";
import {
  ROLE_USER,
  APPROVE_STATUS,
  INFO_CONFIRM_DIALOG,
} from "@/constants/DEVICE_STOCK";
import _ from "lodash";
const STORE = "MachineFieldInspection";

export default {
  data: () => {
    return {
      MACHINE_COMPANY_INSPECTION_TABS,
      MACHINE_COMPANY_INSPECTION_DETAIL_TABS,
      INSPECTION_RESULT_CATEGORY,
      ROLE_USER,
      NOTE_TEXT,
      APPROVE_STATUS,
      tab: null,
      item: {},
      inspectionResultDetail: {},
      inspectionResult: {},
      selectedItems: [],
      isShowConfirmDialog: false,
      isDetailInspec: false,
      approvalFlag: false,
      isResetSelected1: false,
      isResetSelected2: false,
      flagError: false,
      tabListSelected: null,
      popups: {
        isShowErrorUpdateStatusDialog: false,
        isShowConfirmUpdateStatusDialog: false,
        title: "",
        message: "",
        text2: "",
        titleBtnOk: ""
      },
      isNoDataMessage: false,
    };
  },
  components: {
    Popup,
    FormDialog,
    InspectionResultFormTab1,
    InspectionResultFormTab2,
    InspectionResultFormDetailTab1,
    InspectionResultFormDetailTab2,
    ConfirmDialog,
    ConfirmCloseDialog,
    ConfirmUpdateStatus,
  },
  props: {
    itemInspection: Object,
    titleMoveDetail: String,
    isMoveFromViewDetail: {
      type: Boolean,
      default: false
    },
    machineCompanyFieldHistoryId: Number,
  },
  computed : {
    GET_FORM_TABS(){
      return this.isDetailInspec ? this.MACHINE_COMPANY_INSPECTION_DETAIL_TABS : this.MACHINE_COMPANY_INSPECTION_TABS;
    },
    formTitle(){
      if (this.isMoveFromViewDetail) {
        return this.titleMoveDetail;
      } else {
        let title_array = [];
        if (this.item?.machine_company_type_item_tree_last_name)
          title_array.push(this.item?.machine_company_type_item_tree_last_name);
        if (this.item?.machine_name) title_array.push(this.item?.machine_name);
        if (this.item?.control_name) title_array.push(this.item?.control_name);
        return title_array.join("/");
      }
    },
    GET_ROLE_USER() {
      return Store.getters["Sites/getRole"];
    },
    isApproved() {
      return this.selectedItems[0]?.status == APPROVE_STATUS.APPROVE.id;
    },
    isUnApprove() {
      return this.selectedItems[0]?.status == APPROVE_STATUS.UNAPPROVE.id;
    },
    isDisableButtonReject() {
      return this.selectedItems.length === 0;
    },
    isDisableButtonApprove() {
      return this.selectedItems.length === 0;
    },
  },
  mounted() {
    this.$watch(
      () => Store.getters[`${STORE}/getInspecResultDetail`],
      (data, oldData) => {
        if (JSON.stringify(data) != JSON.stringify(oldData)) {
          this.inspectionResultDetail = { ...data };
          this.isNoDataMessage = data?.machine_company_field_inspection_result_images.length == 0;
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => this.tab,
      (data, oldData) => {
        if (data != oldData && !this.isDetailInspec) {
          this.selectedItems = [];
          this.tabListSelected = data;
          this.resetSelected();
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => this.itemInspection,
      (data) => {
        this.item = _.cloneDeep(data);
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => Store.getters[`${STORE}/getInspectionResults`],
      (data, oldData) => {
        if (data && JSON.stringify(data) !== JSON.stringify(oldData)) {
          this.inspectionResult = _.cloneDeep(data);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  methods: {
    /**
     * confirm approve item
     */
    approveConfirm() {
      const inspecUnAproves = this.selectedItems.filter((e) => e.status === APPROVE_STATUS.UNAPPROVE.id) || [];
      const isAllAproved = inspecUnAproves.length == 0;
      this.approvalFlag = true;
      if (isAllAproved) {
        this.popups.title = INFO_CONFIRM_DIALOG.CONFIRM_APPROVE_1.title;
        this.popups.message = INFO_CONFIRM_DIALOG.CONFIRM_APPROVE_1.message;
        this.popups.isShowErrorUpdateStatusDialog = true;
      } else {
        this.popups.title = INFO_CONFIRM_DIALOG.CONFIRM_APPROVE_2.title;
        this.popups.message = INFO_CONFIRM_DIALOG.CONFIRM_APPROVE_2.message;
        this.popups.text2 = INFO_CONFIRM_DIALOG.CONFIRM_APPROVE_2.text2;
        this.popups.titleBtnOk = INFO_CONFIRM_DIALOG.CONFIRM_APPROVE_2.titleBtnOk;
        this.popups.isShowConfirmUpdateStatusDialog = true;
      }
    },
     /**
      * confirm unapprove item
      */
    rejectConfirm() {
      const inspecAproveds = this.selectedItems.filter((e) => e.status === APPROVE_STATUS.APPROVE.id) || [];
      const isAllUnAprove = inspecAproveds.length === 0;
      this.approvalFlag = false;
      if (isAllUnAprove) {
        this.popups.title = INFO_CONFIRM_DIALOG.CONFIRM_UNAPPROVE_1.title;
        this.popups.message = INFO_CONFIRM_DIALOG.CONFIRM_UNAPPROVE_1.message;
        this.popups.isShowErrorUpdateStatusDialog = true;
      } else {
        this.popups.title = INFO_CONFIRM_DIALOG.CONFIRM_UNAPPROVE_2.title;
        this.popups.message = INFO_CONFIRM_DIALOG.CONFIRM_UNAPPROVE_2.message;
        this.popups.text2 = INFO_CONFIRM_DIALOG.CONFIRM_UNAPPROVE_2.text2;
        this.popups.titleBtnOk = INFO_CONFIRM_DIALOG.CONFIRM_UNAPPROVE_2.titleBtnOk;
        this.popups.isShowConfirmUpdateStatusDialog = true;
      }
    },
    onClickBtnClose() {
      this.isShowConfirmDialog = true;
    },
    closeForm() {
      this.isShowConfirmDialog = false;
      if (this.isDetailInspec) {
        this.tab = this.tabListSelected;
        this.isDetailInspec = false;
      } else {
        this.$emit('cancel');
      }
    },
    getSelectedItems(selectedItems) {
      this.selectedItems = [...selectedItems];
    },
    /**
     * Close dialog update status error list inspect 
     */
    closeErrorConfirmDialog() {
      this.popups.isShowErrorUpdateStatusDialog = false;
      this.selectedItems = [];
      this.resetSelectedCloseUpdateDialog();
    },
    closeConfirmUpdateStatusDialog() {
      this.popups.isShowConfirmUpdateStatusDialog = false;
      if (!this.isDetailInspec) {
        this.selectedItems = [];
        this.resetSelectedCloseUpdateDialog();
      }
    },
    async updateStatusApproval() {
      let machine_company_field_inspection_result_ids = this.getMachineCompanyInspecRstIds(this.approvalFlag);
      if (machine_company_field_inspection_result_ids?.length == 0) return;
      const parrams = {
        machine_company_field_inspection_result_ids,
        status: this.approvalFlag ? APPROVE_STATUS.APPROVE.id : APPROVE_STATUS.UNAPPROVE.id
      };
      const result = await Store.dispatch(`${STORE}/updateApprovalStatus`, parrams);
      if (result.hasError) {
        this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.response.data.message,
        });
        return;
      } else {
        this.loadNewInspecResult();
        this.closeConfirmUpdateStatusDialog();
        if (this.isDetailInspec) {
          this.isDetailInspec = false;
          this.tab = this.tabListSelected;
        }
        Store.dispatch("Toast/show", {
          status: 200,
          message: this.approvalFlag ? "承認しました" : "承認解除しました",
        });
      }
    },
    getMachineCompanyInspecRstIds(flagAprrove) {
      let ids = this.selectedItems.filter(e => e.status != flagAprrove).map(x => x.id) || [];
      return ids;
    },
    async openFormInspecDetail(item) {
      this.tab = TABS[0];// load tab first detail
      this.isDetailInspec = true;
      let _selectedItems = [];
      _selectedItems.push(item);
      this.selectedItems = _selectedItems;
    },
    /**
     * Reset data selected when move tab in form list inspection
     */
    resetSelected() {
      if (this.tab == TABS[0]) this.isResetSelected2 = true;
      else this.isResetSelected1 = true;
    },
    /**
     * Reset data selected when close dialog update status
     */
    resetSelectedCloseUpdateDialog() {
      if (this.tab == TABS[0]) this.isResetSelected1 = true;
      else this.isResetSelected2 = true;
    },
    async loadNewInspecResult() {
      const params = {
        machine_company_field_detail_id: this.item?.machine_company_field_detail_id,
        machine_company_field_history_id: this.machineCompanyFieldHistoryId
      };
      if (this.isMoveFromViewDetail) delete params?.machine_company_field_detail_id;
      else delete params?.machine_company_field_history_id;
      await Store.dispatch(`${STORE}/getInspectionResults`, params);
    },
  },
};
</script>
<style lang="scss" scoped>
.w-50 {
  width: 50%;;
}
@media only screen and (max-width: 860px) {
  ::v-deep .v-toolbar__extension .v-tabs .v-slide-group {
    width: 245px !important;
  }
}
@media only screen and (max-width: 560px) {
  ::v-deep .v-toolbar__extension h4 {
    margin-right: 0px !important;
  }
}
</style>
