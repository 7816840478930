<template>
  <!-- parent table -->
  <v-data-table
    :headers="tableLabels"
    :items="items"
    :items-per-page="items.length"
    hide-default-footer
    hide-default-header
    fixed-header
    disable-sort
    class="elevation-0 v-data-table__wrapper"
    sort-by="updatedAt"
  >
    <template slot="header" :headers="tableLabels">
      <thead>
      <tr>
        <th style="width: 5vw;">
          <v-simple-checkbox
            class="ml-9"
            :value="isCheckedAll()"
            @input="updateAllCheckbox($event)"
          >
          </v-simple-checkbox>
        </th>
        <th v-for="labels in tableLabels" :key="labels.value">
          <div style="text-align: left;white-space: nowrap">{{ labels.text }}</div>
        </th>
      </tr>
      </thead>
    </template>
    <!-- parent table template -->
    <template v-slot:body="{ items }">
      <template v-if="items && items.length > 0">
        <tbody v-for="item in items" :key="item.machine_company_type_item_tree_id">
        <tr>
          <td class="start">
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <!-- toggle icon -->
                <v-icon @click.stop="toggleShowChildren(item.machine_company_type_item_tree_id)">
                  {{
                    isShowChildren[item.machine_company_type_item_tree_id]
                      ? "mdi-chevron-up"
                      : "mdi-chevron-down"
                  }}
                </v-icon>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-simple-checkbox
                  :value="isSelectedParent(item.machine_company_type_item_tree_id) && checkSelectedParents(item,type_id)"
                  @input="updateCheckbox(type_id,item, $event, true)"
                ></v-simple-checkbox>
              </v-col>
            </v-row>
          </td>
          <td style="word-break: break-all;">{{ item.type }}</td>
          <td></td>
        </tr>
        <!-- child table template -->
        <template>
          <tr
            v-for="itemChild in item.childrens"
            v-show="isShowChildren[item.machine_company_type_item_tree_id]"
            :key="itemChild.index"
          >
            <td class="border_bottom start">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-simple-checkbox
                    :value="isSelected(item.machine_company_type_item_tree_id,itemChild[`${type_id}`],type_id,itemChild.index)"
                    @input="updateCheckbox(type_id,itemChild, $event, null, item)"
                  ></v-simple-checkbox>
                </v-col>
              </v-row>
            </td>
            <td class="border_bottom" style="word-break: break-all;">{{ itemChild.name }}</td>
            <td class="border_bottom" style="word-break: break-all;">{{ itemChild.machine_number }}</td>
          </tr>
        </template>
        </tbody>
      </template>
      <div v-if="items.length == 0 && flagSearch" class="schedule_nodata mt-4">
        <span>{{ NO_DATA_MESSAGE }}</span>
      </div>
    </template>
  </v-data-table>
</template>
<script>
import _ from "lodash";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";

export default {
  data() {
    return {
      NO_DATA_MESSAGE,
      isShowChildren: {},
      isDisable: false,
      time_out: null,
      flagInitial: true
    };
  },
  props: {
    type_id: String,
    tableLabels: Array,
    itemsPerPage: Number,
    items: Array,
    isSelectedParent: Function,
    isSelected: Function,
    updateCheckbox: Function,
    checkSelectedParents: Function,
    updateAllCheckbox: Function,
    isCheckedAll: Function,
    flagSearch : Boolean
  },
  mounted() {
    this.$watch(
      () => this.items,
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue) && oldValue != undefined) {
          if (!this.flagInitial) {
            this.updateIsShowItems();
          } else {
            this.initIsShowChildren(newValue);
            this.flagInitial = false;
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  methods: {
    /**
     * init show childrens : auto false
     * @param items : items
     */
    initIsShowChildren(items) {
      let _items = _.cloneDeep(items);
      let _isShowChildren = {};
      _items.forEach((item) => {
        _isShowChildren[item.machine_company_type_item_tree_id] = false;
      });
      this.isShowChildren = { ..._isShowChildren };
    },
    /**
     * hide show childrens
     * @param id : id parents
     */
    toggleShowChildren(id) {
      this.isShowChildren[id] = !this.isShowChildren[id];
    },
    /**
     * update init show childrens when items update
     */
    updateIsShowItems() {
      let items = _.cloneDeep(this.items);
      let rs = {};
      items.forEach(item => {
        let temp = this.isShowChildren[item.machine_company_type_item_tree_id];
        if (temp == null) {
          rs[item.machine_company_type_item_tree_id] = false;
        } else {
          rs[item.machine_company_type_item_tree_id] = temp;
        }
      });
      this.isShowChildren = { ...rs };
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

::v-deep .v-data-table-header {
  .v-input--selection-controls__input {
    display: none !important;
  }
}

td.start div.row {
  width: 75px !important;
}

td.col-status {
  width: 110px !important;
}

.child-table {
  td.start {
    padding-right: 0;
  }
}

.schedule_nodata {
  position: absolute;
  left: 23%;
}

.border_bottom {
  border-bottom: 1px solid #E8E8E8;
}
</style>
