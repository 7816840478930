<template>
  <v-card class="dialog">
    <div class="header sticky">
      <v-card-title small>
        <div class="title font-weight-bold">検索条件を保存</div>
        <v-spacer></v-spacer>
        <v-btn small icon text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
    </div>
    <div class="pa-3">
      <ValidationObserver ref="observer" v-slot="observer">
        <ValidationProvider v-slot="{ errors, valid }" name="検索条件名" rules="required|max:50" >
          <div>
            <span class="label">検索条件名</span>
            <span :class="RerquiredStyle">必須</span>
          </div>
          <v-text-field v-model="name" autofocus dense :filled="true" :error-messages="errors"
            :success="valid">
          </v-text-field>
        </ValidationProvider>
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <div class="text-end ">
        <v-btn depressed outlined class="btn" color="primary" @click="$emit('close')">やめる</v-btn>
        <v-btn depressed :disabled="validate" class="btn ml-5" color="primary"
          @click="$emit('createSearchHistory', name)">保存</v-btn>
      </div>
    </div>
  </v-card>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import { ENV_CLIENT } from "@/constants/ENV_CLIENT";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    ValidationCallback
  },
  data: () => {
    return {
      name: '',
      validate: false
    };
  },
  computed: {
    RerquiredStyle() {
      if (process.env.VUE_APP_CLIENT === ENV_CLIENT.OBAYASHI)
        return "required-obayashi";
      return "required";
    },
  },
  methods: {
    updateValidate({ invalid }) {
      this.validate = invalid
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.title {
  color: #1B9C4F;
}

.dialog {
  padding-bottom: 20px;

  .header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }
}
.label {
  font-size: 12px;
  padding: 4px;
  color: $color_primary;
  &.text__warning {
    color: $color_warning;
  }
}
.required {
  font-size: 8px;
  font-weight: bold;
  color: white;
  background-color: rgb(100, 0, 200);
  display: inline-block;
  padding: 4px;
  border-radius: 4px;
  transform: scale(0.8);
}
.required-obayashi {
  font-size: 12px;
  font-weight: normal;
  color: #ff5252;
  background-color: none;
  display: inline-block;
  padding: 4px;
  border-radius: 4px;
}
</style>
