<template>
  <v-card class="dialog">
    <v-card-title>
      <div style="color: green" class="title">役職設定</div>
    </v-card-title>
    <v-card-text class="body">
      <v-radio-group v-model="valueSelected" class="ml-5">
        <v-radio
          v-for="n in POSITION_CHECK"
          :key="n.id"
          :label="n.value"
          :value="n.id"
        ></v-radio>
      </v-radio-group>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="green"
        class="btn"
        @click="$emit('close')"
      >やめる
      </v-btn>
      <v-btn color="primary" :key="flagUpdatePosition" @click.once="$emit('choosePosition', valueSelected)">保存</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { POSITION_CHECK } from "@/constants/PORTAL_CHART.js";

export default {
  data() {
    return {
      POSITION_CHECK,
      valueSelected: 1
    };
  },

  props: {
    positionSelected: {
      type: String,
      default: ""
    },
    flagUpdatePosition: Boolean
  },
  async mounted() {
    this.$watch(
      () => this.positionSelected,
      (newVal) => {
        this.valueSelected = this.POSITION_CHECK.find(element => element.value == newVal)?.id ?? 3;
      },
      { immediate: true, deep: true }
    );
  }
};
</script>
