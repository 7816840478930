<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <!-- 姓 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="姓" :editable="editable" required>
                        <InputText
                          name="sei"
                          :values="formValues"
                          :editable="editable"
                          placeholder="田中"
                          validation_label="姓"
                          validation_rules="required|max:32"
                          @onInput="onInput"
                          testid="input-sei"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 名 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="名" :editable="editable" required>
                        <InputText
                          name="mei"
                          :values="formValues"
                          :editable="editable"
                          placeholder="太郎"
                          validation_label="名"
                          validation_rules="required|max:32"
                          @onInput="onInput"
                          testid="input-mei"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- セイ -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="セイ" :editable="editable" required>
                        <InputText
                          name="kana_sei"
                          :values="formValues"
                          :editable="editable"
                          placeholder="タナカ"
                          validation_label="セイ"
                          validation_rules="required|max:32|kana"
                          @onInput="onInput"
                          testid="input-kana_sei"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- メイ -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="メイ" :editable="editable" required>
                        <InputText
                          name="kana_mei"
                          :values="formValues"
                          :editable="editable"
                          placeholder="タロウ"
                          validation_label="メイ"
                          validation_rules="required|max:32|kana"
                          @onInput="onInput"
                          testid="input-kana_mei"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- メールアドレス -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label
                        label="メールアドレス"
                        :editable="editable"
                        required
                      >
                        <InputText
                          name="mail"
                          :values="formValues"
                          :editable="editable"
                          placeholder="xxxx@xxx.com"
                          validation_label="メールアドレス"
                          validation_rules="required|email"
                          @onInput="onInput"
                          testid="input-mail"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 生年月日 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="生年月日" :editable="editable">
                        <InputDatepicker
                          name="birthday"
                          :values="formValues"
                          :editable="editable"
                          placeholder="yyyy/mm/dd"
                          validation_label="生年月日"
                          validation_rules=""
                          @onInput="onInput"
                          testid="input-birthday"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <!-- ロール -->
                      <Label label="性別" :editable="editable">
                        <Select
                          name="gender"
                          :values="formValues"
                          :items="WORKER_GENDER_ARRAY"
                          :editable="editable"
                          @onInput="onInput"
                          testid="input-gender"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <!-- 郵便番号 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="郵便番号" :editable="editable">
                        <InputText
                          name="postcode"
                          :values="formValues"
                          :editable="editable"
                          placeholder="100-0001"
                          validation_label="郵便番号"
                          validation_rules="postcode"
                          @onInput="onInput"
                          testid="input-postcode"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 都道府県 -->
                  <v-list-item>
                    <v-list-item-content>
                      <!-- ロール -->
                      <Label label="都道府県" :editable="editable">
                        <Select
                          name="state"
                          :values="formValues"
                          :items="STATE"
                          :editable="editable"
                          @onInput="onInput"
                          testid="input-state"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 市区町村 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="市区町村" :editable="editable">
                        <InputText
                          name="city"
                          :values="formValues"
                          :editable="editable"
                          placeholder="千代田区千代田"
                          validation_label="市区町村"
                          validation_rules="max:32"
                          @onInput="onInput"
                          testid="input-city"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 番地・建物名 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="番地・建物名" :editable="editable">
                        <InputText
                          name="address"
                          :values="formValues"
                          :editable="editable"
                          placeholder="1−1−1"
                          validation_label="番地・建物名"
                          validation_rules="max:32"
                          @onInput="onInput"
                          testid="input-address"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 電話 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="電話" :editable="editable">
                        <InputText
                          name="phone"
                          :values="formValues"
                          :editable="editable"
                          placeholder="03-2345-0281"
                          validation_label="電話"
                          validation_rules="phone"
                          @onInput="onInput"
                          testid="input-phone"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 身分証明書 -->
                  <v-list-item>
                    <v-list-item-content>
                      <!-- ロール -->
                      <Label label="身分証明書" :editable="editable">
                        <Select
                          name="id"
                          :values="formValues"
                          :items="WORKER_ID_ARRAY"
                          :editable="editable"
                          @onInput="onInput"
                          testid="input-id"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "../elements/Label.vue";
import InputText from "../elements/InputText.vue";
import InputDatepicker from "../elements/InputDatepicker.vue";
import Select from "../elements/Select.vue";
import { WORKER_ID_ARRAY, WORKER_GENDER_ARRAY } from "@/constants/WORKER"; // ユーザ権限リスト
import { STATE } from "@/constants/COMMON";

export default {
  data: () => {
    return {
      formValues: {},
      STATE,
      WORKER_ID_ARRAY,
      WORKER_GENDER_ARRAY, //TODO: apiができるまで定数で代用、apiに入ったら入れ替え
    };
  },
  components: {
    Label,
    InputText,
    InputDatepicker,
    Select,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    /**
     * (共通)
     * 定数をapi/ストアからまとめて取得します
     *
     * this.CONSTANTS.roll
     * こうやって個々の定数を取得します
     */
    CONSTANTS() {
      return Store.getters["Constants/get"];
    },
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";
</style>
