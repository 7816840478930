<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <!-- 期間 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="工事内容" :editable="editable">
                        <InputText
                          name="construction_details"
                          :values="fieldTreeContracts"
                          :editable="editable"
                          placeholder=""
                          validation_label="工事内容"
                          validation_rules="max:255"
                          @onInput="
                            onInput(
                              CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                              $event
                            )
                          "
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <WorkContents
                        name="field_tree_main_work_contents"
                        :values="formValues"
                        :editable="editable"
                        @onInput="
                          onInput(
                            CONTRACTS_EVENT_TARGET.FIELD_TREE_MAIN_WORK_CONTENTS,
                            $event
                          )
                        "
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <SafetyInstructions
                        name="field_tree_safety_instructions"
                        :values="formValues"
                        :editable="editable"
                        @onInput="
                          onInput(
                            CONTRACTS_EVENT_TARGET.FIELD_TREE_SAFETY_INSTRUCTIONS,
                            $event
                          )
                        "
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row v-if="editable">
                        <v-col>
                          <Label label="工期（着工）" :editable="editable" :required="isRequireStartDate">
                            <InputDatepicker
                              name="groundbreaking_date"
                              :values="fieldTreeContracts"
                              :editable="editable"
                              :flagNull="true"
                              validation_label="工期（着工）"
                              id="groundbreaking_date"
                              :validation_rules="getStartDateRules"
                              @onInput="
                                onInput(
                                  CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                                  $event
                                )
                              "
                            />
                          </Label>
                        </v-col>
                        <v-col>
                          <Label label="工期（竣工）" :editable="editable" :required="isRequireEndDate">
                            <InputDatepicker
                              name="complete_schedule_date"
                              :values="fieldTreeContracts"
                              :editable="editable"
                              :flagNull="true"
                              placeholder="2023/01/08"
                              validation_label="工期（竣工）"
                              id="complete_schedule_date"
                              :validation_rules="getEndDateRules"
                              @onInput="
                                onInput(
                                  CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                                  $event
                                )
                              "
                            />
                          </Label>
                        </v-col>
                      </v-row>
                      <v-row v-if="!editable">
                        <v-col>
                          <Label label="工期" :editable="editable">
                            <InputText
                              name="construction_period"
                              :values="constructionPeriod"
                            />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row v-if="editable">
                        <v-col>
                          <Label label="契約日" :editable="editable">
                            <InputDatepicker
                              name="contract_date"
                              :values="fieldTreeContracts"
                              :editable="editable"
                              :flagNull="true"
                              placeholder="2020/05/19"
                              validation_label="契約日"
                              validation_rules=""
                              :max="max"
                              @onInput="
                                onInput(
                                  CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                                  $event
                                )
                              "
                            />
                          </Label>
                        </v-col>
                      </v-row>
                      <v-row v-if="!editable">
                        <v-col>
                          <Label label="契約日" :editable="editable">
                            <InputText
                              name="contract_date"
                              :values="fieldTreeContracts"
                              :editable="editable"
                              placeholder="yyyy/mm/dd〜yyyy/mm/dd"
                              validation_label="工事内容"
                              validation_rules=""
                              @onInput="
                                onInput(
                                  CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                                  $event
                                )
                              "
                            />
                          </Label>
                        </v-col>
                        <v-col></v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="特定専門工事の有無" :editable="editable">
                        <RadioGroup
                          v-if="editable"
                          name="specific_specialty_status"
                          :values="fieldTreeContracts"
                          :items="INSURANCE_STATUS_2"
                          :editable="editable"
                          @onInput="onInput(CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS, $event)"
                        />
                      </Label>
                      <span v-if="!editable">{{
                        getRadioName(
                          INSURANCE_STATUS_2,
                          fieldTreeContracts["specific_specialty_status"]
                        )
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel label="監督員名・権限及び意見申出方法" />
                      <v-row>
                        <v-col>
                          <Label label="監督員名" :editable="editable">
                            <InputText
                              name="supervisor_name"
                              :values="fieldTreeContracts"
                              :editable="editable"
                              placeholder="上田 正"
                              validation_label="監督員名"
                              validation_rules="max:255"
                              @onInput="
                                onInput(
                                  CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                                  $event
                                )
                              "
                            />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <Label
                            label="権限及び意見申出方法"
                            :editable="editable"
                          >
                            <InputText
                              name="supervisor_authority_and_request_method"
                              :values="fieldTreeContracts"
                              :editable="editable"
                              placeholder="下請負契約第️○️条記載のとおり"
                              validation_label="権限及び意見申出方法"
                              validation_rules="max:255"
                              @onInput="
                                onInput(
                                  CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                                  $event
                                )
                              "
                            />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel  label="現場代理人名・権限及び意見申し出方法" />
                      <v-row>
                        <v-col>
                          <Label label="現場代理人名" :editable="editable">
                            <InputText
                              name="site_agent_name"
                              :values="fieldTreeContracts"
                              :editable="editable"
                              placeholder="夏川 二郎"
                              validation_label="現場代理人名"
                              validation_rules="max:255"
                              @onInput="
                                onInput(
                                  CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                                  $event
                                )
                              "
                            />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <Label
                            label="権限及び意見申出方法"
                            :editable="editable"
                          >
                            <InputText
                              name="site_agent_authority_and_request_method"
                              :values="fieldTreeContracts"
                              :editable="editable"
                              placeholder="下請負契約第️○️条記載のとおり"
                              validation_label="権限及び意見申出方法"
                              validation_rules="max:255"
                              @onInput="
                                onInput(
                                  CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                                  $event
                                )
                              "
                            />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel label="安全衛生管理" />
                      <v-row>
                        <v-col>
                          <Label label="安全衛生責任者名" :editable="editable">
                            <InputText
                              name="health_safety_manager_name"
                              :values="fieldTreeContracts"
                              :editable="editable"
                              placeholder="夏川 二郎"
                              validation_label="安全衛生責任者名"
                              validation_rules="max:255"
                              @onInput="
                                onInput(
                                  CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                                  $event
                                )
                              "
                            />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="ENV_OBAYASHI">
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <Label label="職長" :editable="editable" :required="level === 2 && editable">
                            <Select
                              name="foreman_id"
                              :values="fieldTreeContracts"
                              :items="getForemanItems()"
                              :editable="editable"
                              validation_label="職長"
                              :validation_rules="level == 2 && editable ? 'required' : ''"
                              @onInput="
                                onInput(
                                  CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                                  $event
                                )
                              "
                            />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <Label label="安全衛生推進者名" :editable="editable">
                            <InputText
                              name="health_safety_promoter_name"
                              :values="fieldTreeContracts"
                              :editable="editable"
                              placeholder="夏川 二郎"
                              validation_label="安全衛生推進者名"
                              validation_rules="max:255"
                              @onInput="
                                onInput(
                                  CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                                  $event
                                )
                              "
                            />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel label="主任技術者" :editable="editable" />
                      <Label label="主任技術者名" :editable="editable">
                        <InputText
                          name="chief_engineer_name"
                          :values="fieldTreeContracts"
                          :editable="editable"
                          placeholder="夏川 二郎"
                          validation_label="主任技術者名"
                          validation_rules="max:255"
                          @onInput="
                            onInput(
                              CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                              $event
                            )
                          "
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="専任の有無" :editable="editable">
                        <RadioGroup
                          v-if="editable"
                          name="chief_engineer_fulltime_flag"
                          :values="fieldTreeContracts"
                          :items="CHIEF_ENGINEER_FULLTIME_FLAG"
                          :editable="editable"
                          validation_label="専任の有無"
                          validation_rules=""
                          @onInput="
                            onInput(
                              CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                              $event
                            )
                          "
                        />
                        <span v-if="!editable">{{
                          getRadioName(
                            CHIEF_ENGINEER_FULLTIME_FLAG,
                            fieldTreeContracts.chief_engineer_fulltime_flag
                          )
                        }}</span>
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="資格" :editable="editable" />
                      <Label label="試験等" :editable="editable">
                        <Select
                          name="exam_id"
                          :values="fieldTreeContracts"
                          :items="getcatergoryItems()"
                          :editable="editable"
                          validation_label=""
                          validation_rules=""
                          @onInput="
                            onInput(
                              CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                              $event
                            )
                          "
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="資格区分" :editable="editable">
                        <Select
                          name="chief_engineer_exam_id"
                          :values="fieldTreeContracts"
                          :items="getSubCatergoryItems('exam_id')"
                          item_text="qualification"
                          :editable="editable"
                          validation_label=""
                          validation_rules=""
                          @onInput="
                            onInput(
                              CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                              $event
                            )
                          "
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <FormQualifications
                        name="field_tree_technicians"
                        :values="formValues"
                        :field_tree_contract_id="field_tree_contract_id"
                        :editable="editable"
                        @onInput="
                          onInput(
                            CONTRACTS_EVENT_TARGET.FIELD_TREE_TECHNICIANS,
                            $event
                          )
                        "
                      />
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <FormRegisteredCoreTech
                        name="field_tree_core_technicians"
                        :values="formValues"
                        :field_tree_contract_id="field_tree_contract_id"
                        :editable="editable"
                        :errors="errors"
                        @onInput="
                          onInput(
                            CONTRACTS_EVENT_TARGET.FIELD_TREE_CORE_TECHNICIANS,
                            $event
                          )
                        "
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "@/components/forms/elements/Label";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import Select from "@/components/forms/elements/Select";
import InputText from "@/components/forms/elements/InputText";
import RadioGroup from "@/components/forms/elements/RadioGroup";
import FormRegisteredCoreTech from "../../components/FormRegisteredCoreTech.vue";
import FormQualifications from "../../components/FormQualifications.vue";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import WorkContents from "./components/WorkContents.vue";
import SafetyInstructions from "./components/SafetyInstructions.vue";
import _ from "lodash";

import {
  FIELD_TREE_INITIAL_ITEM,
  CHIEF_ENGINEER_FULLTIME_FLAG,
  INSURANCE_STATUS_2,
} from "@/constants/PORTAL_CHART.js";
import { fmtSlashDate } from "@/utils/timeUtil";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
const CONTRACTS_EVENT_TARGET = {
  FIELD_TREE_CONTRACTS: "field_tree_contracts",
  FIELD_TREE_TECHNICIANS: "field_tree_technicians",
  FIELD_TREE_CORE_TECHNICIANS: "field_tree_core_technicians",
  FIELD_TREE_CONTRACT_INFO: "field_tree_contract_info",
  FIELD_TREE_MAIN_WORK_CONTENTS: "field_tree_main_work_contents",
  FIELD_TREE_SAFETY_INSTRUCTIONS: "field_tree_safety_instructions",
};

export default {
  data: () => {
    return {
      field_tree_contract_id:null,
      cmn_mst_qualifications_construction_industries: Store.state.CmnMst.constants.entries
        .cmn_mst_qualifications_construction_industries,
      formValues: FIELD_TREE_INITIAL_ITEM.field_tree_contract_info,
      fieldTreeContracts: {},
      CHIEF_ENGINEER_FULLTIME_FLAG,
      CONTRACTS_EVENT_TARGET,
      constructionPeriod: {
        construction_period: "",
      },
      INSURANCE_STATUS_2,
      isRequireStartDate: false,
      isRequireEndDate: false,
      max: new Date().toISOString().slice(0, 10)
    };
  },
  components: {
    Label,
    InputDatepicker,
    Select,
    InputText,
    RadioGroup,
    FormRegisteredCoreTech,
    FormQualifications,
    SectionLabel,
    WorkContents,
    SafetyInstructions,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    errors: {
      type: Object,
      default: {},
    },
    level: {
      type: Number,
      default: 0,
    }
  },
  mounted() {
    this.$watch(
      () => this.item,
      (data) => {
        this.initData(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    /**
     * (共通)
     * 定数をapi/ストアからまとめて取得します
     *
     * this.CONSTANTS.roll
     * こうやって個々の定数を取得します
     */
    CONSTANTS() {
      return Store.getters["Constants/get"];
    },
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    getStartDateRules() {
      return this.fieldTreeContracts.complete_schedule_date
        ? "required|" +
        `compare-to-end-date:${this.fieldTreeContracts.complete_schedule_date.replaceAll(
          "-",
          "/"
        )}`
        : "";
    },
    getEndDateRules() {
      return this.fieldTreeContracts.groundbreaking_date
        ? "required|" +
        `compare-to-start-date:${this.fieldTreeContracts.groundbreaking_date.replaceAll(
          "-",
          "/"
        )}`
        : "";
    },
    ENV_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
  },
  methods: {
    initData(data) {
      if (data) {
        const formValues = _.cloneDeep(data);
        if(formValues.field_tree_contracts.chief_engineer_exam_id) {
          formValues.field_tree_contracts.exam_id = this.cmn_mst_qualifications_construction_industries.find(item => item.id === formValues.field_tree_contracts.chief_engineer_exam_id).exam_id;
        }
        if(!formValues.field_tree_contracts.exam_id) {
          formValues.field_tree_contracts.exam_id = null;
        }
        this.fieldTreeContracts = {...formValues.field_tree_contracts};
        this.field_tree_contract_id = formValues.field_tree_contracts.id;
        this.formValues = _.cloneDeep(formValues);
        this.getConstructionPeriod();
      }
    },
    getcatergoryItems() {
      let temp = "";
      let rs = [{id: null, name: ""}];
      this.cmn_mst_qualifications_construction_industries.forEach((element) => {
        if (temp != element.exam_id) {
          rs.push({ id: element.exam_id, name: element.exams });
          temp = element.exam_id;
        }
      });
      return rs;
    },
    getSubCatergoryItems(name) {
      let cmn_mst_qualifications_construction_industries = this.cmn_mst_qualifications_construction_industries.filter(item => item.exam_id === this.fieldTreeContracts[name]);
      cmn_mst_qualifications_construction_industries.unshift({ id: null, qualification: ""})
      return cmn_mst_qualifications_construction_industries;
    },
    getForemanItems() {
      const list = Store.getters["PortalChart/getFieldTreeForeman"]
      if(list.length){
        let arr = []
        arr = list.map(x => {
          return { id: x.id , name: x.name }
        })
        return [...[{ id: null, name: ''}], ...arr]
      }else {
        return [{id: null , name: ''}]
      }
    },
    getRadioName(items, value) {
      return items.find((item) => item.value === value)?.name;
    },
    emit(key, value, parentKey) {
      const objNew = {};
      objNew[key] = value;
      this.$emit("formUpdate", objNew, parentKey);
    },
    onInput(parent_name, { name, value }) {
      if (parent_name === CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS) {
        const _fieldTreeContracts = {...this.fieldTreeContracts};
        _fieldTreeContracts[name] = value;

        if (name === "groundbreaking_date") {
          if (!value && _fieldTreeContracts.complete_schedule_date) this.isRequireStartDate = true;
          if (value && !_fieldTreeContracts.complete_schedule_date) this.isRequireEndDate = true;
        }
        if (name === "complete_schedule_date") {
          if (!value && _fieldTreeContracts.groundbreaking_date) this.isRequireEndDate = true;
          if (value && !_fieldTreeContracts.groundbreaking_date) this.isRequireStartDate = true;
        }
        if (name == "exam_id") {
            _fieldTreeContracts.chief_engineer_exam_id = null;
        }
        if (_fieldTreeContracts.groundbreaking_date && _fieldTreeContracts.complete_schedule_date || !_fieldTreeContracts.groundbreaking_date && !_fieldTreeContracts.complete_schedule_date) {
          this.isRequireStartDate = false;
          this.isRequireEndDate = false;
        }

        this.emit(parent_name, _fieldTreeContracts, 'construction_contract_info');
      } else {
        this.emit(parent_name, value, 'construction_contract_info');
      }
    },
    getConstructionPeriod() {
      const startDate = this.fieldTreeContracts['groundbreaking_date'] ? fmtSlashDate(this.fieldTreeContracts['groundbreaking_date']) : '';
      const endDate = this.fieldTreeContracts['complete_schedule_date'] ? fmtSlashDate(this.fieldTreeContracts['complete_schedule_date']) : '';
      if (startDate || endDate) {
        this.constructionPeriod.construction_period = `${startDate.date}〜${endDate.date}`;
      } else {
        this.constructionPeriod.construction_period = '';
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
</style>
