<template>
  <div class="FormMain">
    <moveActionComponent
      :disabledDoubleNext="disabledDoubleNext"
      :disabledDoublePrev="disabledDoublePrev"
      :multipleMove="false"
      v-if="!isNewItem && isShowMove"
      @loading="setLoading"
    >
    </moveActionComponent>
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row no-gutters class="ml-5">
                <v-col cols="3" sm="3" md="3"></v-col>
                <v-col cols="6" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <ImageForm
                        :title="titlePage2"
                        :editable="editable"
                        :isNewItem="isNewItem"
                        :formValues="item"
                        :workPlace="workPlace"
                        :type="TYPE.TYPE1"
                        :recordId="recordId"
                        @formUpdate="formUpdate"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="3" sm="3" md="3"></v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
      <Popup width="480px" :dialog="popups.isShowConfirmDialog">
        <ConfirmDialog
          :dialogText="DIALOG_APPROVE_TEXT"
          :isApproved="true"
          @close="popups.isShowConfirmDialog = false"
          @yes="popups.isShowConfirmDialog = false"
          :warning="false"
        />
      </Popup>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "@/components/forms/elements/Label.vue";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import Select from "@/components/forms/elements/Select.vue";
import SwitchInput from "@/components/forms/elements/SwitchInput";
import RadioGroup from "@/components/forms/elements/RadioGroup";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import InputTextArea from "@/components/forms/elements/InputTextArea";
import Popup from "@/components/common/Popup";
import ImageForm from "@/components/forms/schedule/patrols/components/ImageForm.vue";
import SearchDetectUser from "@/components/forms/schedule/patrols/components/SearchDetectUser.vue";
import { URGENCY_CLASSIFICATION_RADIO, TYPE,DIALOG_APPROVE_TEXT } from "@/constants/PATROL_RECORD";
import ConfirmDialog from "@/views/schedule/patrols/components/ConfirmDialog.vue";
import moveActionComponent from "@/components/forms/schedule/patrols/PaltrolForm/moveActionComponent"

export default {
  data: () => {
    return {
      DIALOG_APPROVE_TEXT,
      TYPE,
      formValues: {},
      safetyDiaryCommentCorrectionsValues: {},
      max: new Date().toISOString().slice(0, 10),
      URGENCY_CLASSIFICATION_RADIO,
      popups: {
        isShowConfirmDialog : false
      },
      isPartnerLevel1 : false,
    };
  },
  components: {
    InputText,
    SectionLabel,
    RadioGroup,
    Label,
    InputDatepicker,
    Select,
    SwitchInput,
    InputTextArea,
    Popup,
    SearchDetectUser,
    ConfirmDialog,
    ImageForm,
    moveActionComponent,
  },
  props: {
    item: Object,
    mainHeight: {
      type: Number,
      default: 0
    },
    isNewItem : Boolean,
    editable : Boolean,
    valid : Boolean,
    workPlace: Array,
    isShowMove: Boolean,
    recordId: Number,
    titlePage2: String,
    disabledDoubleNext: Boolean,
    disabledDoublePrev: Boolean,
  },
  mounted() {
    this.getUserRole();
    this.$watch(
      () => this.item,
      (data) => {
        this.formValues = { ...data };
        if (Array.isArray(data.safety_diary_comment_corrections)) {
          this.safetyDiaryCommentCorrectionsValues = data.safety_diary_comment_corrections[0];
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `overflow: hidden; height:${this.mainHeight}px;`;
      return style;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    APPROVED() {
      return this.safetyDiaryCommentCorrectionsValues.status_code == 1;
    },
    USER_ROLE(){
      return Store.getters["Sites/getRole"];
    }
  },
  methods: {
    async getUserRole() {
      await Store.dispatch(`Report/getUserRole`, this.CURRENT_SITE.field_id);
    },
    getDefaultArrays(id, value) {
      let results = {};
      results[id] = null;
      results[value] = "";
      return results;
    },
    getColor(recovery) {
      return recovery === 0 ? "warning" : "primary";
    },
    getText(recovery) {
      return recovery === 0 ? "未是正" : "是正済";
    },
    onReject(){
      this.$emit("onChangeStatusCode",false);
    },
    onRecovery(){
      this.$emit("onRecovery");
    },
    onApprove(statusRecovery){
      if (statusRecovery == 0) {
        this.popups.isShowConfirmDialog = true;
      } else {
        this.$emit("onChangeStatusCode", true);
      }
    },
    formUpdate(formValues) {
      this.$emit("formUpdate", formValues);
    },
    setLoading(flg) {
      this.$emit('loading', flg);
    },
  }
};
</script>
<style lang="scss" scoped>
@import "../../../../style/forms.scss";

.select {
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.select_wrap {
  width: 100%;
  pointer-events: none;
}

::v-deep .v-chip .v-chip__content {
  height: 100%;
  max-width: 6vw;
  display: inline-block !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 14px;
}
::v-deep .v-text-field--filled.v-input--dense.v-text-field--single-line .v-label, .v-text-field--full-width.v-input--dense.v-text-field--single-line .v-label {
  color: black !important;
}

::v-deep .v-select.v-input--dense .v-chip {
  background: #1B9C4F;
  color: white;
}

::v-deep .labelWrapper .label {
  white-space: nowrap !important;
}

::v-deep .FormMainBody{
  max-height: 758.296px;
}
</style>
