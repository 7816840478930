<template>
  <FormDialog>
    <template #header>
      <!-- 
        (共通)
        ヘッダーは全て共通
       -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary" class="title">
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editable"
          class="mr-5"
          depressed
          small
          outlined
          color="primary"
          @click="isShowConfirmDialog = true"
        >
          やめる
        </v-btn>
        <v-btn
          class="mr-5"
          v-if="editable && isNewItem"
          depressed
          :disabled="!valid"
          small
          color="primary"
          @click="onSubmit(true)"
        >
          続けて登録
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          small
          :disabled="!valid"
          color="primary"
          @click.once="onSubmit(false)"
          :key="flagAdd"
          data-testid="btn-submit"
        >
          保存
        </v-btn>
        <v-btn
          v-if="!editable"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS_CREATE_EDIT" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!-- 
        (共通)
        全てのフォームのバリデーションの監視を行う
       -->
      <ValidationObserver ref="observer" v-slot="observer">
        <!-- フォームtab -->
        <v-tabs-items v-model="tab">
          <!-- 基本情報 -->
          <v-tab-item>
            <MachineFormPage1
              :editable="editable"
              :item="item"
              :errors="errors"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
              :MANAGEMENT_SECTION="MANAGEMENT_SECTION"
              :machinesOnsite="machinesOnsite"
              :isNewItem="isNewItem"
            />
          </v-tab-item>
          <!-- 性能 -->
          <v-tab-item>
            <MachineFormPage2
              :editable="editable"
              :item="item"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <!-- 禁止時間帯 -->
          <v-tab-item>
            <MachineFormPage3
              :editable="editable"
              :item="item"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
        </v-tabs-items>
        <!-- 
          (共通)
          ValidationObserverのスロットプロパティを受け取って
          updateValidateメソッドを実行して、
          バリデーション結果をスクリプト側に渡してます
        -->
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { Store } from "@/store/Store.js";
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "../elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import MachineFormPage1 from "@/components/forms/companyMachines/MachineFormPage1.vue";
import MachineFormPage2 from "@/components/forms/companyMachines/MachineFormPage2.vue";
import MachineFormPage3 from "@/components/forms/companyMachines/MachineFormPage3.vue";
import { TITLE_FORM_CREATE_EDIT,FORMS_CREATE_EDIT, MANAGEMENT_SECTION} from "@/constants/COMPANY_MACHINES";
import { convertCurrentcy } from "@/utils/currentcy";
import _ from "lodash";

const STORE = "CompanyMachines";

export default {
  data: () => {
    return {
      valid: false,
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      TITLE_FORM_CREATE_EDIT,
      FORMS_CREATE_EDIT,
      MANAGEMENT_SECTION,
      beforeUpdateItem: {},
      isClickCloseBtn: false,
      errors: {},
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    MachineFormPage1,
    MachineFormPage2,
    MachineFormPage3,
  },
  props: {
    item: Object,
    isNewItem: Boolean,
    isErrorSubmit: Boolean,
    isEmitted: Boolean,
    machinesOnsite: Number,
    flagAdd: {
      type : Boolean,
      default : true
    },
  },
  mounted() {
    /**
     * (共通)
     * 新規作成を監視
     */
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        //新規の場合は最初から編集モード
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => [
        Store.getters[`${STORE}/getMachine`],
        this.editable,
      ],
      (data) => {
        if (!this.isNewItem) {
          let rs = _.cloneDeep(data[0]);
          rs.interpersonal = convertCurrentcy(rs.interpersonal);
          rs.objective = convertCurrentcy(rs.objective);
          rs.passenger = convertCurrentcy(rs.passenger);
          rs.others = convertCurrentcy(rs.others);
          this.beforeUpdateItem = _.cloneDeep(rs);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
     /**
     * Catch after submitted
     */
    this.$watch(
        () => this.isEmitted,
        () => {
          if (!this.isErrorSubmit) {
            this.editable = false;
          }
        }
    );
  },
  computed: {
    // 新規or編集でタイトル変更
    formtitle() {
      return this.isNewItem ? TITLE_FORM_CREATE_EDIT.NEW : TITLE_FORM_CREATE_EDIT.EDIT;
    },
  },
  methods: {
    /**
     * (共通)
     * フォームの変更を送信
     */
    formUpdate(params) {
      this.$emit("formUpdate", params);
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      this.editable = true;
    },
    /**
     * (共通)
     * 状態を詳細に変更
     */
    onDetail() {
      this.editable = false;
    },

    /**
     * (共通)
     * 登録
     */

    onSubmit(saveDraft) {
      this.$emit("submit",saveDraft);
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid, errors }) {
      this.valid = valid;
      this.errors = errors;
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        const item = _.cloneDeep(this.beforeUpdateItem);
        this.formUpdate(item);
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },
    /**
     * (共通)
     * click button Close (X)
     */
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },

    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },

  },
  /**
   * Important: clear timeout
   */
  beforeDestroy () {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout)
  }
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
.title 
  color: #1B9C4F
</style>
