<template>
  <div v-if="items.length > 0"
    :style="`height:${tableHeight}px; overflow-x: hidden; overflow-y: auto;` "
  >
    <RecordRow
      v-for="item in items"
      :key="item.field_tree_id"
      :item="item"
      :tableLabels="tableLabels"
      :items-per-page="itemsPerPage"
      :isSelected="isSelected"
      :checkSelectedParents="checkSelectedParents"
      :isSelectedParent="isSelectedParent"
      :updateCheckbox="updateCheckbox"
      @openItemForm="functions.openItemForm"
      @getChildItems="functions.getChildItems"
    />
  </div>
  <div v-else-if="isNoDataMessage">
    <v-data-table
      :headers="REMARKS_LABELS.concat(tableLabels)"
      :items="items"
      hide-default-footer
      disable-sort
      :items-per-page="itemsPerPage"
      class="elevation-0 v-data-table__wrapper"
      :noDataText="NO_DATA_MESSAGE"
      outlined
    />
  </div>
</template>
<script>
import RecordRow from "./RecordRow";
import {REMARKS_LABELS}  from "@/constants/ENTRANCE_RECORD";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
export default {
  data: () => {
    return {
      NO_DATA_MESSAGE,
      selectedParent: [],
      REMARKS_LABELS
    };
  },
  components: {
    RecordRow,
  },
  props: {
    items: {
      type: Array,
    },
    tableHeight: {
      type: Number,
    },
    tableLabels: {
      type: Array,
    },
    functions: {
      type: Object,
    },
    itemsPerPage: { type: Number },
    isSelected: Function,
    checkSelectedParents: Function,
    openItemForm: Function,
    isNoDataMessage: Boolean,
  },

  methods: {
    isSelectedParent(id) {
      return this.selectedParent.includes(id);
    },

    updateCheckbox(item, isChecked, isParent, itemParent) {
      // parentがchecked
      if (isParent && item.users) {
        this.updateSelectedParentItems(item.field_tree_id, isChecked);
        item.users.forEach((user) => {
          this.$emit("update", item.field_tree_id, user.user_id , isChecked, user.approval_flag);
        });
      } else {
        // childがchecked
        this.$emit("update",itemParent.field_tree_id, item.user_id, isChecked ,item.approval_flag);
        if (itemParent && isChecked && this.checkSelectedParents(itemParent)) {
          this.selectedParent.push(itemParent.field_tree_id);
        } else {
          this.selectedParent = this.selectedParent.filter((item) => {
            return item != itemParent.field_tree_id;
          });
        }
      }
    },

    updateSelectedParentItems(id, isChecked) {
      let _selectedItems = [];
      // checked
      if (isChecked) {
        _selectedItems = [...this.selectedParent, id];
      } else {
        // unchecked
        _selectedItems = this.selectedParent.filter((item) => {
          return item != id;
        });
      }
      // 重複削除してset
      this.selectedParent = [...new Set(_selectedItems)];
    },
  }
};
</script>
