<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <!-- 健康保険の加入状況 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="健康保険の加入状況" :editable="editable">
                        <RadioGroup
                          v-if="editable"
                          name="health_insurance_status"
                          :values="formValues.user_insurances"
                          :items="INSURANCE_STATUS_1"
                          :editable="editable"
                          @onInput="onInput(USER_EVENT_TARGET.USER_INSURANCES, $event)"
                        />
                      </Label>
                      <span v-if="!editable">{{
                        getRadioName(
                          INSURANCE_STATUS_1,
                          userInsurances["health_insurance_status"]
                        )
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 健康保険の種類 -->
                  <v-col cols="12" sm="6" md="6" class="pl-0">
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="健康保険の種類" :editable="editable">
                          <Select
                            name="cmn_mst_health_insurances_id"
                            :values="formValues.user_insurances"
                            :items="HEALTH_INSURANCES"
                            item_text="type"
                            :editable="editable"
                            @onInput="onInput(USER_EVENT_TARGET.USER_INSURANCES, $event)"
                          />
                        </Label>
                        <span v-if="editable"
                          >健康保険証の発行元を入力してください。</span
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <!-- 健康保険者名称 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="健康保険者名称" :editable="editable">
                        <InputText
                          name="health_insurance_company_name"
                          :values="formValues.user_insurances"
                          :editable="editable"
                          placeholder=""
                          validation_label="健康保険者名称"
                          validation_rules="max:255"
                          @onInput="onInput(USER_EVENT_TARGET.USER_INSURANCES, $event)"
                        />
                      </Label>
                      <span v-if="editable"
                        >記載内容が鮮明に判読できる画像を添付してください。</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 健康保険確認書類 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="健康保険確認書類" :editable="editable">
                        <span class="file"
                          v-if="!editable && userInsurances['health_insurance_document_name']"
                          @click="viewSourceWithAuth(userInsurances['health_insurance_document_name_url'])"
                        >
                          {{ userInsurances["health_insurance_document_name"] }}
                        </span>
                        <FileInput
                          :style="getFileInputStyle('health_insurance_document_name')"
                          :resetValue="resetValue['health_insurance_document_name']"
                          v-if="editable"
                          name="health_insurance_document_name"
                          :values="formValues.user_insurances"
                          :editable="editable"
                          placeholder=""
                          accept="image/jpg, image/png, image/jpeg, application/pdf"
                          validation_label="health_insurance_document_name"
                          validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf"
                          @onInput="onInput(USER_EVENT_TARGET.USER_INSURANCES, $event)"
                        /><span
                          class="file"
                          v-if="judgeDisplayFileInput('health_insurance_document_name')"
                          >{{ userInsurances["health_insurance_document_name"] }}
                          <v-btn
                            small
                            icon
                            text
                            @click="onRemoveFile({
                                parents_name: USER_EVENT_TARGET.USER_INSURANCES,
                                name: 'health_insurance_document_name',
                              })"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </span>
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 労災保険の加入状況 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="労災保険の加入状況" :editable="editable">
                        <RadioGroup
                          v-if="editable"
                          name="accident_insurance_status"
                          :values="formValues.user_insurances"
                          :items="INSURANCE_STATUS_2"
                          :editable="editable"
                          @onInput="onInput(USER_EVENT_TARGET.USER_INSURANCES, $event)"
                        />
                      </Label>
                      <span v-if="!editable">{{
                        getRadioName(
                          INSURANCE_STATUS_2,
                          userInsurances["accident_insurance_status"]
                        )
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 労災保険の種類 -->
                  <v-col cols="12" sm="6" md="6" class="pl-0">
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="労災保険の種類" :editable="editable">
                          <Select
                            name="cmn_mst_accident_insurances_id"
                            :values="formValues.user_insurances"
                            :items="ACCIDENT_INSURANCES"
                            item_text="type"
                            :editable="editable"
                            @onInput="onInput(USER_EVENT_TARGET.USER_INSURANCES, $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <!-- 労災保険特別加入確認書類 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label
                        label="労災保険特別加入確認書類"
                        :editable="editable"
                      >
                        <span class="file"
                          v-if="!editable && userInsurances['accident_insurance_document_name']"
                          @click="viewSourceWithAuth(userInsurances['accident_insurance_document_name_url'])"
                        >
                          {{ userInsurances["accident_insurance_document_name"] }}
                        </span>
                        <FileInput
                          :style="(fileValid['accident_insurance_document_name'] && userInsurances['accident_insurance_document_name']) ? 'display:none;' : ''"
                          :resetValue="resetValue['accident_insurance_document_name']"
                          v-if="editable"
                          name="accident_insurance_document_name"
                          :values="formValues.user_insurances"
                          :editable="editable"
                          placeholder=""
                          accept="image/jpg, image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                          validation_label="accident_insurance_document_name"
                          validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf, xls, xlsx"
                          @onInput="onInput(USER_EVENT_TARGET.USER_INSURANCES, $event)"
                        />
                        <span
                          class="file"
                          v-if="editable && fileValid['accident_insurance_document_name'] && userInsurances['accident_insurance_document_name']"
                          >{{ userInsurances["accident_insurance_document_name"] }}
                          <v-btn
                            small
                            icon
                            text
                            @click="onRemoveFile({
                                parents_name: USER_EVENT_TARGET.USER_INSURANCES,
                                name: 'accident_insurance_document_name',
                              })"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </span>
                      </Label>
                      <span v-if="editable"
                        >『特別加入』という文言が表記されている書類を添付してください。</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 年金保険の加入状況 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="年金保険の加入状況" :editable="editable">
                        <RadioGroup
                          v-if="editable"
                          name="pension_insurance_status"
                          :values="formValues.user_insurances"
                          :items="INSURANCE_STATUS_2"
                          :editable="editable"
                          @onInput="onInput(USER_EVENT_TARGET.USER_INSURANCES, $event)"
                        />
                      </Label>
                      <span v-if="!editable">{{
                        getRadioName(
                          INSURANCE_STATUS_1,
                          userInsurances["pension_insurance_status"]
                        )
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 年金保険の種類 -->
                  <v-col cols="12" sm="6" md="6" class="pl-0">
                    <v-list-item>
                      <v-list-item-content>
                        <!-- 年金保険の種類 -->
                        <Label label="年金保険の種類" :editable="editable">
                          <Select
                            name="cmn_mst_annuity_insurances_id"
                            :values="formValues.user_insurances"
                            :items="ANNUITY_INSURANCES"
                            item_text="type"
                            :editable="editable"
                            @onInput="onInput(USER_EVENT_TARGET.USER_INSURANCES, $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <!-- 年金保険確認書類 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="年金保険確認書類" :editable="editable">
                        <span class="file"
                          v-if="!editable && userInsurances['pension_insurance_document_name']"
                          @click="viewSourceWithAuth(userInsurances['pension_insurance_document_name_url'])"
                        >
                          {{ userInsurances["pension_insurance_document_name"] }}
                        </span>
                        <FileInput
                          :style="getFileInputStyle('pension_insurance_document_name')"
                          :resetValue="resetValue['pension_insurance_document_name']"
                          v-if="editable"
                          name="pension_insurance_document_name"
                          :values="formValues.user_insurances"
                          :editable="editable"
                          placeholder=""
                          accept="image/jpg, image/png, image/jpeg, application/pdf"
                          validation_label="pension_insurance_document_name"
                          validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf"
                          @onInput="onInput(USER_EVENT_TARGET.USER_INSURANCES, $event)"
                        />
                        <span
                          class="file"
                          v-if="judgeDisplayFileInput('pension_insurance_document_name')"
                          >{{ userInsurances["pension_insurance_document_name"] }}
                          <v-btn
                            small
                            icon
                            text
                            @click="onRemoveFile({
                                parents_name: USER_EVENT_TARGET.USER_INSURANCES,
                                name: 'pension_insurance_document_name',
                              })"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </span>
                      </Label>
                      <span v-if="editable"
                        >記載内容が鮮明に判読できる画像を添付してください。</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <!-- 雇用保険の加入状況 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="雇用保険の加入状況" :editable="editable">
                        <RadioGroup
                          v-if="editable"
                          name="employment_insurance_status"
                          :values="formValues.user_insurances"
                          :items="INSURANCE_STATUS_1"
                          :editable="editable"
                          @onInput="onInput(USER_EVENT_TARGET.USER_INSURANCES, $event)"
                        />
                      </Label>
                      <span v-if="!editable">{{
                        getRadioName(
                          INSURANCE_STATUS_1,
                          userInsurances["employment_insurance_status"]
                        )
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 雇用保険被保険者番号 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="雇用保険被保険者番号" :editable="editable">
                        <InputText
                          name="employment_insurance_number"
                          :values="formValues.user_insurances"
                          :editable="editable"
                          placeholder=""
                          validation_label="雇用保険被保険者番号"
                          validation_rules="max:20|digit|halfsize"
                          @onInput="onInput(USER_EVENT_TARGET.USER_INSURANCES, $event)"
                        />
                      </Label>
                      <span v-if="editable"
                        >記号(ハイフン(-)や中黒(・)、スラッシュ(/)等)
                        は入力せずに、詰めて入力してください。</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 雇用保険被保険者の種類 -->
                  <v-col cols="12" sm="6" md="6" class="pl-0">
                    <v-list-item>
                      <v-list-item-content>
                        <Label
                          label="雇用保険被保険者の種類"
                          :editable="editable"
                        >
                          <Select
                            name="cmn_mst_unemployment_insurances_id"
                            :values="formValues.user_insurances"
                            :items="UNEMPLOYMENT_INSURANCES"
                            item_text="type"
                            :editable="editable"
                            @onInput="onInput(USER_EVENT_TARGET.USER_INSURANCES, $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <!-- 雇用保険確認書類 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="雇用保険確認書類" :editable="editable">
                        <span
                          class="file"
                          v-if="!editable && userInsurances['employment_insurance_document_name']"
                          @click="viewSourceWithAuth(userInsurances['employment_insurance_document_name_url'])"
                        >
                          {{ userInsurances["employment_insurance_document_name"] }}
                        </span>
                        <FileInput
                          :style="getFileInputStyle('employment_insurance_document_name')"
                          :resetValue="resetValue['employment_insurance_document_name']"
                          v-if="editable"
                          name="employment_insurance_document_name"
                          :values="formValues.user_insurances"
                          :editable="editable"
                          placeholder=""
                          accept="image/jpg, image/png, image/jpeg, application/pdf"
                          validation_label="employment_insurance_document_name"
                          validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf"
                          @onInput="onInput(USER_EVENT_TARGET.USER_INSURANCES, $event)"
                        />
                        <span
                          class="file"
                          v-if="judgeDisplayFileInput('employment_insurance_document_name')"
                          >{{ userInsurances["employment_insurance_document_name"] }}
                          <v-btn
                            small
                            icon
                            text
                            @click="onRemoveFile({
                                parents_name: USER_EVENT_TARGET.USER_INSURANCES,
                                name: 'employment_insurance_document_name',
                              })"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </span>
                      </Label>
                      <span v-if="editable"
                        >記載内容が鮮明に判読できる画像を添付してください。</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 建設業退職金共済制度の加入状況 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label
                        label="建設業退職金共済制度の加入状況"
                        :editable="editable"
                      >
                        <RadioGroup
                          v-if="editable"
                          name="participation_construction_status"
                          :values="formValues.user_insurances"
                          :items="INSURANCE_STATUS_2"
                          :editable="editable"
                          @onInput="onInput(USER_EVENT_TARGET.USER_INSURANCES, $event)"
                        />
                      </Label>
                      <span v-if="!editable">{{
                        getRadioName(
                          INSURANCE_STATUS_2,
                          userInsurances["participation_construction_status"]
                        )
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 被共済者番号 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="被共済者番号" :editable="editable">
                        <InputText
                          name="participation_construction_number"
                          :values="formValues.user_insurances"
                          :editable="editable"
                          placeholder=""
                          validation_label="被共済者番号"
                          validation_rules="max:20|digit|halfsize"
                          @onInput="onInput(USER_EVENT_TARGET.USER_INSURANCES, $event)"
                        />
                      </Label>
                      <span v-if="editable"
                        >記号(ハイフン(-)や中黒(・)、スラッシュ(/)等)
                        は入力せずに、詰めて入力してください。</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 建設業退職金共済制度確認書類 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label
                        label="建設業退職金共済制度確認書類"
                        :editable="editable"
                      >
                        <span
                          class="file"
                          v-if="!editable && userInsurances['participation_construction_document_name']"
                          @click="viewSourceWithAuth(userInsurances['participation_construction_document_name_url'])"
                        >
                          {{ userInsurances["participation_construction_document_name"] }}
                        </span>
                        <FileInput
                          :style="getFileInputStyle('participation_construction_document_name')"
                          :resetValue="resetValue['participation_construction_document_name']"
                          v-if="editable"
                          name="participation_construction_document_name"
                          :values="formValues.user_insurances"
                          :editable="editable"
                          placeholder=""
                          accept="image/jpg, image/png, image/jpeg, application/pdf"
                          validation_label="participation_construction_document_name"
                          validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf"
                          @onInput="onInput(USER_EVENT_TARGET.USER_INSURANCES, $event)"
                        />
                        <span
                          class="file"
                          v-if="judgeDisplayFileInput('participation_construction_document_name')"
                          >{{ userInsurances["participation_construction_document_name"] }}
                          <v-btn
                            small
                            icon
                            text
                            @click="
                              onRemoveFile({
                                parents_name: USER_EVENT_TARGET.USER_INSURANCES,
                                name: 'participation_construction_document_name',
                              })
                            "
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </span>
                      </Label>
                      <span v-if="editable"
                        >記載内容が鮮明に判読できる画像を添付してください。</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 中小企業退職金共済制度の加入状況 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label
                        label="中小企業退職金共済制度の加入状況"
                        :editable="editable"
                      >
                        <RadioGroup
                          v-if="editable"
                          name="participation_sme_status"
                          :values="formValues.user_insurances"
                          :items="INSURANCE_STATUS_2"
                          :editable="editable"
                          @onInput="onInput(USER_EVENT_TARGET.USER_INSURANCES, $event)"
                        />
                      </Label>
                      <span v-if="!editable">{{
                        getRadioName(
                          INSURANCE_STATUS_2,
                          userInsurances["participation_sme_status"]
                        )
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 中小企業退職金共済制度確認書類 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label
                        label="中小企業退職金共済制度確認書類"
                        :editable="editable"
                      >
                        <span class="file"
                          v-if="!editable && userInsurances['participation_sme_document_name']"
                          @click="viewSourceWithAuth(userInsurances['participation_sme_document_name_url'])"
                        >
                          {{ userInsurances["participation_sme_document_name"] }}
                        </span>
                        <FileInput
                          :style="getFileInputStyle('participation_sme_document_name')"
                          :resetValue="resetValue['participation_sme_document_name']"
                          v-if="editable"
                          name="participation_sme_document_name"
                          :values="formValues.user_insurances"
                          :editable="editable"
                          placeholder=""
                          accept="image/jpg, image/png, image/jpeg, application/pdf"
                          validation_label="participation_sme_document_name"
                          validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf"
                          @onInput="onInput(USER_EVENT_TARGET.USER_INSURANCES, $event)"
                        />
                        <span
                          class="file"
                          v-if="judgeDisplayFileInput('participation_sme_document_name')"
                          >{{ userInsurances["participation_sme_document_name"] }}
                          <v-btn
                            small
                            icon
                            text
                            @click="onRemoveFile({
                                parents_name: USER_EVENT_TARGET.USER_INSURANCES,
                                name: 'participation_sme_document_name',
                              })"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </span>
                      </Label>
                      <span v-if="editable"
                        >記載内容が鮮明に判読できる画像を添付してください。</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "../elements/Label";
import InputText from "../elements/InputText";
import InputDatepicker from "../elements/InputDatepicker.vue";
import Select from "../elements/Select.vue";
import RadioGroup from "../elements/RadioGroup.vue";
import FileInput from "../elements/FileInput.vue";
import { viewSourceWithAuth } from "@/utils/viewSourceAuth";
import {
  INSURANCE_STATUS_2,
  USER_EVENT_TARGET,
} from "@/constants/EMPLOYEE";
import _ from "lodash";

export default {
  data: () => {
    return {
      formValues: {},
      userInsurances: {},
      INSURANCE_STATUS_1: Store.state.CmnMst.constants.entries.cmn_mst_insurance_status,
      INSURANCE_STATUS_2,
      HEALTH_INSURANCES: [{ id: null, type: "" },...Store.state.CmnMst.constants.entries.cmn_mst_health_insurances],
      ACCIDENT_INSURANCES: [{ id: null, type: "" },...Store.state.CmnMst.constants.entries.cmn_mst_accident_insurances],
      ANNUITY_INSURANCES: [{ id: null, type: "" },...Store.state.CmnMst.constants.entries.cmn_mst_annuity_insurances],
      UNEMPLOYMENT_INSURANCES: [{ id: null, type: "" },...Store.state.CmnMst.constants.entries.cmn_mst_unemployment_insurances],
      USER_EVENT_TARGET,
      fileValid: {},
      resetValue: {},
      viewSourceWithAuth: viewSourceWithAuth,
    };
  },
  components: {
    Label,
    InputText,
    InputDatepicker,
    Select,
    FileInput,
    RadioGroup,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    errors: {
      type: Object,
      default: {},
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        const formValues = { ...newValue };
        this.formValues = { ...formValues };
        this.userInsurances = { ...formValues[USER_EVENT_TARGET.USER_INSURANCES] };
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.convertGroup(this.INSURANCE_STATUS_1);
    // check input file error
    this.$watch(
      () => [this.item, this.errors],
      (data) => {
        this.formValues = {...data[0]};
        const errors = {...data[1]};
        // the validation_label list of the FileInput element
        const names = [
          "health_insurance_document_name",
          "accident_insurance_document_name",
          "pension_insurance_document_name",
          "employment_insurance_document_name",
          "participation_construction_document_name",
          "participation_sme_document_name",
        ];
        const fileValid = {...this.fileValid};
        const formValues = _.cloneDeep(this.formValues);
        if (errors) {
          for(let name in errors) {
            if (names.some((item) => item === name)){ 
              if(errors[name].length >0){
                delete formValues[USER_EVENT_TARGET.USER_INSURANCES][name];
                delete formValues[USER_EVENT_TARGET.USER_INSURANCES][`${name}_data`];
                fileValid[name] = false;
              } else {
                fileValid[name] = true;
              }
            }
          }
        }
        this.formValues = formValues;
        this.fileValid = fileValid;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    onInput(parents_name, { name, value, fileName }) {
      const formValues = { ...this.formValues };
      if (fileName) {
        formValues[parents_name][`${name}_data`] = value;
        formValues[parents_name][name] = fileName;
        if (!value) {
          delete formValues[parents_name][`${name}_deleted`];
        }
      } else {
        formValues[parents_name][name] = value;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    onRemoveFile({ parents_name, name }) {
      const formValues = { ...this.formValues };
      delete formValues[parents_name][name];
      delete formValues[parents_name][`${name}_data`];
      if(formValues[parents_name][`${name}_url`]) formValues[parents_name][`${name}_deleted`] = 1;
      this.formValues = formValues;
      this.resetValue[name] = !this.resetValue[name];
      this.$emit("formUpdate", formValues);
    },
    getRadioName(items, value) {
      return items.find((item) => item.value == value)?.name;
    },
    convertGroup(data) {
      return data.forEach(item => {
        item['name'] = item['status'];
        item['value'] = item['id'];
      })
    },
    getFileInputStyle(fieldName) {
      return (this.fileValid[fieldName] && this.userInsurances[fieldName]) ? 'display:none;' : '';
    },
    judgeDisplayFileInput(fieldName) {
      return this.editable && this.fileValid[fieldName] && this.userInsurances[fieldName];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";
</style>
