<template>
  <div>
    <v-container>
      <v-row v-for="(item, index) in items" :key="index">
        <v-col cols="12" sm="10" md="10" class="pt-0 pb-0 mt-1">
          <Label label="工事名称" :editable="editable">
            <InputText
              name="construction_name"
              :values="item"
              :editable="editable"
              placeholder="千代田商事丸の内ビル新築工事"
              validation_label="工事名称"
              :validation_rules="`max:255|${getDuplicateRules()}`"
              @onInput="onInput({ value: $event, index })"
            />
          </Label>

          <Label label="工事内容" :editable="editable">
            <InputText
              name="construction_content"
              :values="item"
              :editable="editable"
              placeholder="地上１階` 地下１階` 塔屋１階` 延べ床面積9,600m2"
              validation_label="工事内容"
              validation_rules="max:512"
              @onInput="onInput({ value: $event, index })"
            />
          </Label>
          <v-row no-gutters>
            <v-col>
              <Label label="工事種別" :editable="editable">
                <Select
                  name="cmn_mst_construction_type_id"
                  :values="item"
                  item_text="construction_type"
                  :items="cmn_mst_construction_types"
                  :editable="editable"
                  validation_label="工事種別"
                  validation_rules=""
                  @onInput="onInput({ value: $event, index })"
                />
              </Label>
            </v-col>
            <v-col>
              <Label label="構造" :editable="editable">
                <Select
                  name="cmn_mst_construction_id"
                  :values="item"
                  item_text="construction"
                  :items="cmn_mst_constructions"
                  :editable="editable"
                  validation_label="構造"
                  validation_rules=""
                  @onInput="onInput({ value: $event, index })"
                />
              </Label>
            </v-col>
          </v-row>

          <Label label="工期" v-if="!editable" :editable="editable">
            <v-text-field
              dense
              color="primary"
              :disabled="!editable"
              :filled="editable"
              :label="getTimeComplete(item)"
            ></v-text-field>
          </Label>
          <v-row no-gutters>
            <v-col v-if="editable">
              <Label
                label="工期（着工)"
                :editable="editable"
                :required="true"
              >
                <InputDatepicker
                  name="construction_groundbreaking_date"
                  :editable="editable"
                  :values="item"
                  validation_label="工期（着工)"
                  :id="`${index}_construction_groundbreaking_date`"
                  :flagNull="true"
                  :validation_rules="private_required"
                  @onInput="onInput({ value: $event, index })"
                />
              </Label>
            </v-col>
            <v-col v-if="editable">
              <Label
                label="工期（竣工)"
                :editable="editable"
                :required="false"
              >
                <InputDatepicker
                  name="construction_complete_schedule_date"
                  :editable="editable"
                  :values="item"
                  :id="`${index}_construction_complete_schedule_date`"
                  :flagNull="true"
                  validation_label="工期（竣工)"
                  :validation_rules="getEndDateRules(item)"
                  @onInput="onInput({ value: $event, index })"
                />
              </Label>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="2" md="2" class="pt-0 pb-0">
          <v-btn
            v-if="index > 0 && editable"
            class="mt-8"
            icon
            @click="remove(index)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-btn @click="addEmpty" color="primary" v-if="editable">追加</v-btn>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "../../elements/Label.vue";
import Select from "../../elements/Select.vue";
import InputText from "../../elements/InputText.vue";
import InputDatepicker from "../../elements/InputDatepicker.vue";
import { fmtSlashDate } from "@/utils/timeUtil";
import { Store } from "@/store/Store.js";
const INITIAL_VALUES = {
  construction_name: "",
  construction_content: "",
  cmn_mst_construction_type_id: null,
  cmn_mst_construction_id: null,
  construction_groundbreaking_date: "",
  construction_complete_schedule_date: "",
};

export default {
  data: () => {
    return {
      cmn_mst_constructions: [
        { id: null, construction: "" },
        ...Store.state.CmnMst.constants.entries.cmn_mst_constructions,
      ],
      cmn_mst_construction_types: [
        { id: null, construction_type: "" },
        ...Store.state.CmnMst.constants.entries.cmn_mst_construction_types,
      ],
      items: []
    };
  },
  components: {
    Select,
    InputDatepicker,
    Label,
    InputText,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    // prevent reference error when click button edit to make form editable
    private_required: {
      type: String,
      default: "required"
    },
  },

  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.items = newValue[0][newValue[1]];
          if (this.items?.length == 0) {
            this.addEmpty();
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    getStartDateRules() {
      return "required";
    },
    getEndDateRules(item) {
      return item.construction_complete_schedule_date && item.construction_groundbreaking_date
        ? `compare-to-start-date:${item.construction_groundbreaking_date.replaceAll(
              "-",
              "/"
            )}`
        : "";
    },
    getTimeComplete(item) {
      let timeComplete = "";
      const startDate = item["construction_groundbreaking_date"]
        ? fmtSlashDate(item["construction_groundbreaking_date"]).date
        : "";
      const endDate = item["construction_complete_schedule_date"]
        ? fmtSlashDate(item["construction_complete_schedule_date"]).date
        : "";
      if (startDate || endDate) {
        timeComplete = `${startDate}〜${endDate}`;
      } else {
        timeComplete = "";
      }
      return timeComplete;
    },
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      let initems = { ...INITIAL_VALUES};
      initems['key'] = Date.now();
      let _items = [...this.items];
      _items.push({ ...initems });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    getDuplicateRules() {
      if(this.items && this.items.length > 1) {
        let nameList = this.items.map(item => item.construction_name);
        let tempSet = [...new Set(nameList)];
        let isDuplicate = nameList.length === tempSet.length;
        return `duplicate-construction-name:${isDuplicate}`;
      }
      return "";
    },
    isRequireStartDate(index) {
      return !this.items[index]?.construction_groundbreaking_date && this.items[index]?.construction_complete_schedule_date !== null;
    },
    isRequireEndDate(index) {
      return this.items[index]?.construction_groundbreaking_date !== null && !this.items[index]?.construction_complete_schedule_date;
    }
  },
};
</script>
