var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0 v-data-table__wrapper",attrs:{"headers":_vm.tableLabels,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"height":_vm.tableHeight,"fixed-header":"","hide-default-footer":"","disable-sort":"","show-select":true,"sort-by":"updatedAt"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [(items && items.length > 0)?_vm._l((items),function(item){return _c('tbody',{key:item.field_tree_id},[_c('tr',{staticStyle:{"background-color":"#DCDCDC"}},[_c('td',{staticClass:"start"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.toggleShowChildren(item.field_tree_id)}}},[_vm._v(" "+_vm._s(_vm.isShowChildren[item.field_tree_id] ? "mdi-chevron-down" : "mdi-chevron-right")+" ")])],1)],1)],1),_c('td',{staticClass:"col-status"},[_c('div',[_vm._v(" "+_vm._s(_vm.convertLongText(item.company_name, 22))+" ")])]),_c('td',[_vm._v(_vm._s(_vm.displayData(item.total_company_time_length)))]),_vm._l((item.company_time_length),function(time,index){return _c('td',{key:index},[_vm._v(_vm._s(_vm.displayData(time.time)))])})],2),_vm._l((item.children),function(itemChild){return [_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowChildren[item.field_tree_id]),expression:"isShowChildren[item.field_tree_id]"}],key:itemChild.field_tree_user_id,staticClass:"child-table"},[_c('td',{staticClass:"start"}),_c('td',[_vm._v(_vm._s(_vm.convertLongText(itemChild.user_name, 22)))]),_c('td',[_vm._v(" "+_vm._s(_vm.displayData(itemChild.total_user_time_length))+" ")]),_vm._l((itemChild.user_time_length),function(time,index){return _c('td',{key:index},[_vm._v(_vm._s(_vm.displayData(time.time)))])})],2)]}),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.isShowChildren[item.field_tree_id] &&
            (_vm.childTableDisplayMoreNumber(
              item.total_children_item,
              item.children.length
            ) > 0)
          ),expression:"\n            isShowChildren[item.field_tree_id] &&\n            (childTableDisplayMoreNumber(\n              item.total_children_item,\n              item.children.length\n            ) > 0)\n          "}]},[_c('td',{attrs:{"colspan":"10"}},[_c('div',{staticClass:"d-flex justify-center"},[(
                  _vm.childTableDisplayMoreNumber(
                    item.total_children_item,
                    item.children.length
                  ) > 0
                )?_c('v-btn',{staticClass:"mr-4 display-more",attrs:{"x-small":"","depressed":"","disabled":_vm.isDisable,"color":"#E5E5E5"},on:{"click":function($event){return _vm.getChildren(item)}}},[_vm._v("さらに表示する "),_c('v-badge',{staticClass:"display-more-number",attrs:{"inline":"","color":"#767676","content":_vm.childTableDisplayMoreNumber(
                      item.total_children_item,
                      item.children.length
                    )}})],1):_vm._e()],1)])])],2)}):(_vm.isNoDataMessage)?_c('div',{staticClass:"schedule_nodata mt-4"},[_c('span',[_vm._v(_vm._s(_vm.NO_DATA_MESSAGE))])]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }