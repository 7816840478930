<template>
  <div ref="FormMain" class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-container>
            <v-row ref="FormTop">
              <v-col cols="12" sm="6" md="6">
                <!-- グループ名 -->
                <v-list-item>
                  <v-list-item-content>
                    <Label label="グループ名" :editable="editable" required>
                      <InputText
                        name="name"
                        :values="formValues"
                        :editable="editable"
                        placeholder="グループ"
                        validation_label="グループ"
                        validation_rules="required"
                        @onInput="onInput"
                      />
                    </Label>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6" md="6"></v-col>
            </v-row>
            <v-divider />
            <v-row>
              <!-- 現場ユーザー -->
              <v-col cols="12" sm="6" md="6" class="col">
                <!-- ヘッダー -->
                <v-list-item ref="SearchHeader" class="pa-0">
                  <v-list-item-content>
                    <div style="display: flex; justify-content: space-between">
                      <!-- <SearchHeader /> -->
                      <div>
                        <v-btn
                          :disabled="!editable"
                          @click.prevent="isShowSiteUserSelectDialog = true"
                        >
                          ユーザー選択
                        </v-btn>
                        <span style="padding: 4px" />
                        <v-btn
                          :disabled="!editable"
                          @click.prevent="onLoadDirector"
                        >
                          現場監督取得
                        </v-btn>
                      </div>
                      <v-btn :disabled="!enableAddRoom" @click.prevent="addRoom"
                        >登録</v-btn
                      >
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
                <!--テーブル -->
                <v-list-item>
                  <v-list-item-content>
                    <v-data-table
                      v-model="siteUser.selectedUsers"
                      :headers="TABLE_LABELS"
                      :items="getCleanSiteUser()"
                      :height="$vuetify.breakpoint.height - 420"
                      fixed-header
                      hide-default-footer
                      disable-sort
                      sort-by="id"
                      :show-select="editable"
                      :noDataText="NO_DATA_MESSAGE"
                      :items-per-page="-1"
                    >
                    </v-data-table>
                  </v-list-item-content>
                </v-list-item>
                <div class="divider"></div>
              </v-col>
              <!-- ルームユーザー -->
              <v-col cols="12" sm="6" md="6" class="col">
                <!-- ヘッダー -->
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <SearchHeader v-if="false" />
                    <div style="display: flex; justify-content: space-between">
                      <v-btn
                        :disabled="!enableRemoveRoom"
                        @click.prevent="removeRoom"
                        >解除</v-btn
                      >
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
                <!--テーブル -->
                <v-list-item>
                  <v-list-item-content>
                    <v-data-table
                      v-model="roomUser.selectedUsers"
                      :headers="TABLE_LABELS"
                      :items="roomUser.users"
                      :height="$vuetify.breakpoint.height - 420"
                      fixed-header
                      hide-default-footer
                      disable-sort
                      sort-by="id"
                      :show-select="editable"
                      :noDataText="NO_DATA_MESSAGE"
                    >
                    </v-data-table>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
    <Popup width="800px" :dialog="isShowSiteUserSelectDialog">
      <SiteUserSelectDialog
        @loadedUsers="onLoadedUsers"
        @close="isShowSiteUserSelectDialog = false"
      />
    </Popup>
  </div>
</template>
<script>
import * as _ from "lodash";
import { Store } from "@/store/Store.js";
import { STATE } from "@/constants/COMMON";
import Label from "../elements/Label.vue";
import InputText from "../elements/InputText.vue";
import SiteUserSelectDialog from "./components/SiteUserSelectDialog.vue";
import SearchHeader from "./components/SearchHeader.vue";
import Popup from "@/components/common/Popup.vue";
import { TEXTCHAT_ROOM_MEMBER_TABLE_LABELS } from "@/constants/TEXTCHAT"; //絞り込みフォームで使用
import { NO_DATA_MESSAGE } from "@/constants/COMMON"; //絞り込みフォームで使用

export default {
  data: () => {
    return {
      isShowSiteUserSelectDialog: false,
      formValues: { name: null, users: [] },
      siteUser: {
        users: [],
        selectedUsers: [],
      },
      roomUser: {
        users: [],
        selectedUsers: [],
      },
      tableHeight: 0,
      STATE,
      NO_DATA_MESSAGE,
      TABLE_LABELS: TEXTCHAT_ROOM_MEMBER_TABLE_LABELS,
    };
  },
  components: {
    Label,
    InputText,
    SearchHeader,
    Popup,
    SiteUserSelectDialog,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  async mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        const roomUser = { ...this.roomUser };
        roomUser.users = newValue.users;
        this.roomUser = roomUser;

        let formValues = { ...this.formValues };
        formValues.name = newValue.name;
        this.formValues = formValues;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    // テーブルサイズを調整
    // this.$nextTick(() => {
    //   const FormMainRect = this.$refs.FormMain.getBoundingClientRect();
    //   const FormTopRect = this.$refs.FormTop.getBoundingClientRect();
    //   const SearchHeaderRect =
    //     this.$refs.SearchHeader.$el.getBoundingClientRect();
    // });
  },
  computed: {
    /**
     * (共通)
     * 定数をapi/ストアからまとめて取得します
     *
     * this.CONSTANTS.roll
     * こうやって個々の定数を取得します
     */
    CONSTANTS() {
      return Store.getters["Constants/get"];
    },
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    enableAddRoom() {
      return this.siteUser.selectedUsers.length > 0;
    },
    enableRemoveRoom() {
      return this.roomUser.selectedUsers.length > 0;
    },
  },
  methods: {
    addRoom() {
      const selectedUsers = _.cloneDeep(this.siteUser.selectedUsers);
      let roomUser = { ...this.roomUser };
      roomUser.users = [...roomUser.users, ...selectedUsers];
      let result = [];
      roomUser.users.forEach((user) => {
        if (!result.find((_user) => _user.user_id === user.user_id)) {
          result.push(user);
        }
      });
      roomUser.users = result;
      this.roomUser = roomUser;

      let siteUser = { ...this.siteUser };
      siteUser.selectedUsers = [];
      this.siteUser = siteUser;

      const formValues = { ...this.formValues };
      formValues.users = _.cloneDeep(roomUser.users);
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    removeRoom() {
      const selectedUsers = _.cloneDeep(this.roomUser.selectedUsers);
      let roomUser = { ...this.roomUser };
      roomUser.users = roomUser.users.filter((user) => {
        const find = selectedUsers.find((sUser) => {
          return sUser.user_id === user.user_id;
        });
        return find ? false : true;
      });
      roomUser.selectedUsers = [];
      this.roomUser = roomUser;
      const formValues = { ...this.formValues };
      formValues.users = _.cloneDeep(roomUser.users);
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    getCleanSiteUser() {
      if (this.siteUser.users.length === 0) return [];
      const users = this.siteUser.users.filter((user) => {
        const find = this.roomUser.users.find((rUser) => {
          return rUser.user_id === user.user_id;
        });
        return find ? false : true;
      });
      return users;
    },
    async onLoadDirector() {
      // field_construction_idは作業を選択しないと取得できないので、index=0をからidを取得する
      // https://shogatsu.backlog.com/view/NEOG-2111#comment-151306830

      // 現在の現場id
      this.fieldId = Store.state.GlobalHeader.selectedSite.field_id;

      // 作業一覧取得
      let workTypes = await Store.dispatch(
        "PortalChart/getWorkTypes",
        this.fieldId
      );
      if (!workTypes.data.contents?.entries[0]) {
        Store.dispatch("Error/show", {
          status: 200,
          message: "工事IDが取得できませんでした。",
        });
        return;
      }
      const field_construction_id = workTypes.data.contents?.entries[0]?.id;

      //現場監督
      let fieldDirectors = await Store.dispatch(
        `PortalChart/getFieldDirectorsChatManageFieldConstructionId`,
        field_construction_id
      );
      const _fieldDirectors = fieldDirectors.data.contents.entries;
      const users = [
        // apiのレスポンスによってuser_idしかない場合があるので、全てにidとuser_idを付与する
        ..._fieldDirectors.map((user) => {
          return {
            ...user,
            id: user?.id || user.user_id,
            user_id: user?.user_id || user.id,
          };
        }),
      ];
      console.log("this.FOREMAN", users);
      this.onLoadedUsers(users);
    },
    onLoadedUsers(users) {
      //TODO user_idが重複するのでフィルタする

      let siteUser = { ...this.siteUser };
      const _users = users.map((user) => {
        return { ...user, name: `${user.user_name_sei} ${user.user_name_mei}` };
      });

      // 連結
      const conc = [..._users, ...siteUser.users];

      // 重複してなければ追加
      let result = [];
      conc.forEach((item) => {
        if (!result.find((_item) => _item.user_id === item.user_id)) {
          result.push(item);
        }
      });

      siteUser.users = result;
      this.siteUser = siteUser;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";
.FormMain {
  height: 100%;
}
.FormMainBody {
  overflow: hidden;
}
.col {
  position: relative;
}
.divider {
  position: absolute;
  top: 12px;
  right: 0;
  height: 100%;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.12);
}
.row {
  margin: 0 !important;
}
</style>
