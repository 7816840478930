<template>
  <v-card class="dialog">
    <div class="label">
      <div class="label__item">
        <span class="label__text">請負</span>
        <span class="label__tip" style="background-color: #1b9c4f" />
      </div>
      <div class="label__item">
        <span class="label__text">常傭</span>
        <span class="label__tip" style="background-color: #ff7b52" />
      </div>
    </div>
    <v-card-text class="body">
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div class="text-center">予定</div>
          <Doughnut :values="plan.total" :totalCenter="plan.totalCenter" :isNoData="isNoDataPlan"/>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <div class="text-center">実績</div>
          <Doughnut :values="actual.total" :totalCenter="actual.totalCenter" :isNoData="isNoDataActual"/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import Doughnut from "@/views/schedule/components/ScheduleChart/Doughnut.vue";
import Chart from "@/views/schedule/components/ScheduleChart/Chart.vue";
export default {
  components: {
    Doughnut,
    Chart,
  },
  props: {
    dataScheduleChart: Object, 
  },
  data: () => ({
    isNoDataPlan: false,
    isNoDataActual: false,
    plan: {
      total: [],
      totalCenter: 0,
    },
    actual: {
      total: [],
      totalCenter: 0,
    },
  }),
  mounted() {
    this.$watch(
      () => this.dataScheduleChart,
      (data) => {
        if(data?.schedule) {
          const dataConvert = this.convertData(data?.schedule)
          this.plan = dataConvert[0];
          this.isNoDataPlan = dataConvert[1];
        }
        if(data?.result) {
          const dataConvert = this.convertData(data?.result)
          this.actual =  dataConvert[0];
          this.isNoDataActual = dataConvert[1];
        }
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    convertData: (data) => {
      if(data?.total_ukeoi == 0 && data?.total_tsunei == 0) {
        return [{ total: [-1, 0], totalCenter: 0 }, true];
      }
      let result = { total: [], totalCenter: 0 };
      result['totalCenter'] = data.total;
      result['total'] = [data?.total_ukeoi, data?.total_tsunei];
      return [result, false];
    }
  }
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;
      .text {
        text-align: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 80%;
      }
    }
  }
}
.label {
  display: flex;
  justify-content: center;
  font-size: 12px;
  padding: 12px 24px;
}
.label__item {
  line-height: 12px;
  margin-right: 8px;
}
.label__tip {
  display: inline-block;
  width: 24px;
  height: 16px;
  vertical-align: middle;
}
.label__text {
  vertical-align: middle;
  margin-right: 4px;
}
</style>
