<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
            <v-form ref="form" lazy-validation autocomplete="off">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="健康保険の加入状況" :editable="editable">
                          <RadioGroup
                            v-if="editable"
                            name="health_insurance_status"
                            :values="formValues"
                            :items="INSURANCE_STATUS_3"
                            :editable="editable"
                            @onInput="onInput"
                          />
                          <span v-if="!editable" >{{ getRadioName(INSURANCE_STATUS_3, formValues['health_insurance_status']) }}</span>
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="事業所整理記号" :editable="editable">
                          <InputText
                            name="health_insurance_office_symbol"
                            :values="formValues"
                            :editable="editable"
                            placeholder=""
                            validation_label="事業所整理記号"
                            validation_rules="number-char-symbol|max:20"
                            @onInput="onInput"
                          />
                        </Label>
                        <span v-if="editable">記号(ハイフン(-)や中黒(・)、スラッシュ(/)等) は入力せずに、詰めて入力してください。</span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="事業所番号" :editable="editable">
                          <InputText
                            name="health_insurance_number"
                            :values="formValues"
                            :editable="editable"
                            placeholder=""
                            validation_label="事業所番号"
                            validation_rules="number-char-symbol|max:20"
                            @onInput="onInput"
                          />
                        </Label>
                        <span v-if="editable">記号(ハイフン(-)や中黒(・)、スラッシュ(/)等) は入力せずに、詰めて入力してください。</span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="健康保険組合有無" :editable="editable">
                          <RadioGroup
                            v-if="editable"
                            name="health_insurance_association_status"
                            :values="formValues"
                            :items="INSURANCE_STATUS_2"
                            :editable="editable"
                            @onInput="onInput"
                          />
                          <span v-if="!editable" >{{ getRadioName(INSURANCE_STATUS_2, formValues['health_insurance_association_status']) }}</span>
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="健康保険組合名称" :editable="editable">
                          <InputText
                            name="health_insurance_association_name"
                            :values="formValues"
                            :editable="editable"
                            placeholder=""
                            validation_label="健康保険組合名称"
                            validation_rules="max:255"
                            @onInput="onInput"
                          />
                        </Label>
                        <span v-if="editable">健康保険組合でない場合は入力しないで下さい。（特に協会けんぽ）</span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="国保組合有無" :editable="editable" >
                          <RadioGroup
                            v-if="editable"
                            name="national_health_insurance_association_status"
                            :values="formValues"
                            :items="INSURANCE_STATUS_2"
                            :editable="editable"
                            @onInput="onInput"
                          />
                          <span v-if="!editable">{{ getRadioName(INSURANCE_STATUS_2, formValues['national_health_insurance_association_status']) }}</span>
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="国保組合名称" :editable="editable">
                          <InputText
                            name="national_health_insurance_association_name"
                            :values="formValues"
                            :editable="editable"
                            placeholder=""
                            validation_label="国保組合名称"
                            validation_rules="max:255"
                            @onInput="onInput"
                          />
                        </Label>
                        <span v-if="editable">国保組合でない場合は入力しないで下さい。（特に協会けんぽ）</span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="添付書類" :editable="editable">
                          <span class="file"
                            v-if="!editable && formValues['national_health_insurance_association_document_url']"
                            @click="viewSourceWithAuth(formValues['national_health_insurance_association_document_url'])"
                          >
                            {{ formValues['national_health_insurance_association_document'] }}
                          </span>
                          <FileInput
                            :style="getFileInputStyle('national_health_insurance_association_document')"
                            :resetValue="resetValue['national_health_insurance_association_document']"
                            v-if="editable"
                            name="national_health_insurance_association_document"
                            :values="formValues"
                            :editable="editable"
                            accept="image/jpg, image/png, image/jpeg, application/pdf"
                            placeholder=""
                            validation_label="national_health_insurance_association_document"
                            validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf"
                            @onInput="onInput"
                          />
                          <span class="file"
                                v-if="judgeDisplayFileInput('national_health_insurance_association_document')"
                          >{{ formValues['national_health_insurance_association_document'] }}
                            <v-btn small icon text
                                   @click="onRemoveFile({ name:'national_health_insurance_association_document' })">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </span>
                        </Label>
                        <span v-if="editable">記載内容が鮮明に判読できる画像を添付してください。</span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="労災保険の加入状況" :editable="editable">
                          <RadioGroup
                            v-if="editable"
                            name="insurance_accident_status"
                            :values="formValues"
                            :items="INSURANCE_STATUS_2"
                            :editable="editable"
                            @onInput="onInput"
                          />
                          <span v-if="!editable">{{ getRadioName(INSURANCE_STATUS_2, formValues['insurance_accident_status']) }}</span>
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="労災保険番号" :editable="editable">
                          <InputText
                            name="insurance_accident_number"
                            :values="formValues"
                            :editable="editable"
                            placeholder=""
                            validation_label="労災保険番号"
                            validation_rules="number-char-symbol|max:20"
                            @onInput="onInput"
                          />
                        </Label>
                        <span v-if="editable">記号(ハイフン(-)や中黒(・)、スラッシュ(/)等) は入力せずに、詰めて入力してください。</span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="整理番号" :editable="editable">
                          <InputText
                            name="reference_number"
                            :values="formValues"
                            :editable="editable"
                            placeholder=""
                            validation_label="整理番号"
                            validation_rules="number-char-symbol|max:255"
                            @onInput="onInput"
                          />
                        </Label>
                        <span v-if="editable">記号(ハイフン(-)や中黒(・)、スラッシュ(/)等) は入力せずに、詰めて入力してください。</span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="添付書類" :editable="editable">
                          <span class="file"
                             v-if="!editable && formValues['insurance_accident_document_url']"
                             @click="viewSourceWithAuth(formValues['insurance_accident_document_url'])"
                          >
                            {{ formValues['insurance_accident_document'] }}
                          </span>
                          <FileInput
                            :style="getFileInputStyle('insurance_accident_document')"
                            :resetValue="resetValue['insurance_accident_document']"
                            v-if="editable"
                            name="insurance_accident_document"
                            :values="formValues"
                            :editable="editable"
                            accept="image/jpg, image/png, image/jpeg, application/pdf"
                            placeholder=""
                            validation_label="insurance_accident_document"
                            validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf"
                            @onInput="onInput"
                          />
                          <span class="file"
                                v-if="judgeDisplayFileInput('insurance_accident_document')"
                          >{{ formValues['insurance_accident_document'] }}
                            <v-btn small icon text
                                   @click="onRemoveFile({ name:'insurance_accident_document' })">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </span>
                        </Label>
                        <span v-if="editable">『特別加入』という文言が表記されている書類を添付してください。</span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="年金保険の加入状況" :editable="editable">
                          <RadioGroup
                            v-if="editable"
                            name="pension_insurance_status"
                            :values="formValues"
                            :items="INSURANCE_STATUS_3"
                            :editable="editable"
                            @onInput="onInput"
                          />
                          <span v-if="!editable" >{{ getRadioName(INSURANCE_STATUS_3, formValues['pension_insurance_status']) }}</span>
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="事業所整理記号" :editable="editable">
                          <InputText
                            name="pension_insurance_office_symbol"
                            :values="formValues"
                            :editable="editable"
                            placeholder=""
                            validation_label="事業所整理記号"
                            validation_rules="number-char-symbol|max:20"
                            @onInput="onInput"
                          />
                        </Label>
                        <span v-if="editable">記号(ハイフン(-)や中黒(・)、スラッシュ(/)等) は入力せずに、詰めて入力してください。</span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="事業所番号" :editable="editable">
                          <InputText
                            name="pension_insurance_number"
                            :values="formValues"
                            :editable="editable"
                            placeholder=""
                            validation_label="事業所番号"
                            validation_rules="number-char-symbol|max:20"
                            @onInput="onInput"
                          />
                        </Label>
                        <span v-if="editable">記号(ハイフン(-)や中黒(・)、スラッシュ(/)等) は入力せずに、詰めて入力してください。</span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="添付書類" :editable="editable">
                          <span class="file"
                             v-if="!editable && formValues['pension_insurance_document_url']"
                             @click="viewSourceWithAuth(formValues['pension_insurance_document_url'])"
                          >
                            {{ formValues['pension_insurance_document'] }}
                          </span>
                          <FileInput
                            :style="getFileInputStyle('pension_insurance_document')"
                            :resetValue="resetValue['pension_insurance_document']"
                            v-if="editable"
                            name="pension_insurance_document"
                            :values="formValues"
                            :editable="editable"
                            accept="image/jpg, image/png, image/jpeg, application/pdf"
                            placeholder=""
                            validation_label="pension_insurance_document"
                            validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf"
                            @onInput="onInput"
                          />
                          <span class="file"
                                v-if="judgeDisplayFileInput('pension_insurance_document')"
                          >{{ formValues['pension_insurance_document'] }}
                            <v-btn small icon text
                                   @click="onRemoveFile({ name:'pension_insurance_document' })">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </span>
                        </Label>
                        <span v-if="editable">記載内容が鮮明に判読できる画像を添付してください。</span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="雇用保険の加入状況" :editable="editable">
                          <RadioGroup
                            v-if="editable"
                            name="employment_insurance_status"
                            :values="formValues"
                            :items="INSURANCE_STATUS_3"
                            :editable="editable"
                            @onInput="onInput"
                          />
                          <span v-if="!editable" >{{ getRadioName(INSURANCE_STATUS_3, formValues['employment_insurance_status']) }}</span>
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="労働保険番号" :editable="editable">
                          <InputText
                            name="employment_insurance_number"
                            :values="formValues"
                            :editable="editable"
                            placeholder=""
                            validation_label="労働保険番号"
                            validation_rules="number-char-symbol|max:20"
                            @onInput="onInput"
                          />
                        </Label>
                        <span v-if="editable">記号(ハイフン(-)や中黒(・)、スラッシュ(/)等) は入力せずに、詰めて入力してください。</span>
                        <span v-if="editable">必ず14桁（枝番号含む）で入力してください。</span>
                        <span v-if="editable">11桁しかわからない方は下3桁を「000」と入力して、14桁入力してください。</span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="添付書類" :editable="editable">
                          <span class="file"
                             v-if="!editable && formValues['employment_insurance_document_url']"
                             @click="viewSourceWithAuth(formValues['employment_insurance_document_url'])"
                          >
                            {{ formValues['employment_insurance_document'] }}
                          </span>
                          <FileInput
                            :style="getFileInputStyle('employment_insurance_document')"
                            :resetValue="resetValue['employment_insurance_document']"
                            v-if="editable"
                            name="employment_insurance_document"
                            :values="formValues"
                            :editable="editable"
                            accept="image/jpg, image/png, image/jpeg, application/pdf"
                            placeholder=""
                            validation_label="employment_insurance_document"
                            validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf"
                            @onInput="onInput"
                          />
                          <span class="file"
                                v-if="judgeDisplayFileInput('employment_insurance_document')"
                          >{{ formValues['employment_insurance_document'] }}
                            <v-btn small icon text
                                   @click="onRemoveFile({ name:'employment_insurance_document' })">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </span>
                        </Label>
                        <span v-if="editable">記載内容が鮮明に判読できる画像を添付してください。</span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="建設業退職金共済制度の加入状況" :editable="editable">
                          <RadioGroup
                            v-if="editable"
                            name="participation_construction_status"
                            :values="formValues"
                            :items="INSURANCE_STATUS_2"
                            :editable="editable"
                            @onInput="onInput"
                          />
                          <span v-if="!editable" >{{ getRadioName(INSURANCE_STATUS_2, formValues['participation_construction_status']) }}</span>
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="共済契約者番号" :editable="editable">
                          <InputText
                            name="participation_construction_number"
                            :values="formValues"
                            :editable="editable"
                            placeholder=""
                            validation_label="共済契約者番号"
                            validation_rules="digit|max:20"
                            @onInput="onInput"
                          />
                        </Label>
                        <span v-if="editable">記号(ハイフン(-)や中黒(・)、スラッシュ(/)等) は入力せずに、詰めて入力してください。</span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="添付書類" :editable="editable">
                          <span class="file"
                             v-if="!editable && formValues['participation_construction_document_url']"
                             @click="viewSourceWithAuth(formValues['participation_construction_document_url'])"
                          >
                            {{ formValues['participation_construction_document'] }}
                          </span>
                          <FileInput
                            :style="getFileInputStyle('participation_construction_document')"
                            :resetValue="resetValue['participation_construction_document']"
                            v-if="editable"
                            name="participation_construction_document"
                            :values="formValues"
                            :editable="editable"
                            accept="image/jpg, image/png, image/jpeg, application/pdf"
                            placeholder=""
                            validation_label="participation_construction_document"
                            validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf"
                            @onInput="onInput"
                          />
                          <span class="file"
                                v-if="judgeDisplayFileInput('participation_construction_document')"
                          >{{ formValues['participation_construction_document'] }}
                            <v-btn small icon text
                                   @click="onRemoveFile({ name:'participation_construction_document' })">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </span>
                        </Label>
                        <span v-if="editable">記載内容が鮮明に判読できる画像を添付してください。</span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="中小企業退職金共済制度の加入状況" :editable="editable">
                          <RadioGroup
                            v-if="editable"
                            name="participation_sme_status"
                            :values="formValues"
                            :items="INSURANCE_STATUS_2"
                            :editable="editable"
                            @onInput="onInput"
                          />
                          <span v-if="!editable" >{{ getRadioName(INSURANCE_STATUS_2, formValues['participation_sme_status']) }}</span>
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="共済契約者番号" :editable="editable">
                          <InputText
                            name="participation_sme_number"
                            :values="formValues"
                            :editable="editable"
                            placeholder=""
                            validation_label="共済契約者番号"
                            validation_rules="digit|max:20"
                            @onInput="onInput"
                          />
                        </Label>
                        <span v-if="editable">記号(ハイフン(-)や中黒(・)、スラッシュ(/)等) は入力せずに、詰めて入力してください。</span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="添付書類" :editable="editable">
                          <span class="file" target="_blank"
                             v-if="!editable && formValues['participation_sme_document_url']"
                             @click="viewSourceWithAuth(formValues['participation_sme_document_url'])"
                          >
                            {{ formValues['participation_sme_document'] }}
                          </span>
                          <FileInput
                            :style="getFileInputStyle('participation_sme_document')"
                            :resetValue="resetValue['participation_sme_document']"
                            v-if="editable"
                            name="participation_sme_document"
                            :values="formValues"
                            :editable="editable"
                            accept="image/jpg, image/png, image/jpeg, application/pdf"
                            placeholder=""
                            validation_label="participation_sme_document"
                            validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf"
                            @onInput="onInput"
                          />
                          <span class="file"
                                v-if="judgeDisplayFileInput('participation_sme_document')"
                          >{{ formValues['participation_sme_document'] }}
                            <v-btn small icon text
                                   @click="onRemoveFile({ name:'participation_sme_document' })">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </span>
                        </Label>
                        <span v-if="editable">記載内容が鮮明に判読できる画像を添付してください。</span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "@/components/forms/elements/Label";
import InputText from "@/components/forms/elements/InputText";
import FileInput from "../elements/FileInput";
import RadioGroup from "../elements/RadioGroup";
import { INSURANCE_STATUS_2, INSURANCE_STATUS_3 } from "@/constants/COMPANY_PORTAL";
import { viewSourceWithAuth } from "@/utils/viewSourceAuth";

export default {
  data: () => {
    return {
      formValues: {},
      INSURANCE_STATUS_2,
      INSURANCE_STATUS_3,
      fileValid: {},
      resetValue: {},
      viewSourceWithAuth: viewSourceWithAuth,
    };
  },
  components: {
    Label,
    InputText,
    FileInput,
    RadioGroup,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    errors: {
      type: Object,
      default: {},
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => [
        this.item,
        this.editable,
      ],
      (data) => {
        this.initData(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );
    // check input file error
    this.$watch(
      () => [this.item, this.errors],
      (data) => {
        this.formValues = { ...data[0] };
        const errors = {...data[1]};
        // the validation_label list of the FileInput element
        const names = [
          "national_health_insurance_association_document",
          "insurance_accident_document",
          "pension_insurance_document",
          "employment_insurance_document",
          "participation_construction_document",
          "participation_sme_document",
        ];
        const fileValid = { ...this.fileValid};
        const formValues = { ...this.formValues};
        if (errors) {
          for(let name in errors) {
            if (names.some((item) => item === name)){ 
              if(errors[name].length > 0){
                delete formValues[name];
                delete formValues[`${name}_data`];
                fileValid[name] = false;
              } else {
                fileValid[name] = true;
              }
            }
          }
        }
        this.formValues = formValues;
        this.fileValid = fileValid;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      const headerHeight = 48;
      let style = `height:${this.mainHeight - headerHeight}px;`;
      return style;
    },
  },
  methods: {
    initData(data) {
      const formValues = data[0];
      this.formValues = { ...formValues };
    },

    getRadioName(items, value) {
      return items.find((item) => item.value ==  value)?.name;
    },

    onRemoveFile({ name }) {
      const formValues = { ...this.formValues };
      delete formValues[name];
      delete formValues[`${name}_data`];
      if(formValues[`${name}_url`]) formValues[`${name}_deleted`] = 1;
      this.formValues = formValues;
      this.resetValue[name] = !this.resetValue[name];
      this.$emit("formUpdate", {"company_branch_insurances": formValues});
    },

    onInput({ name, value, fileName }) {
      const formValues = { ...this.formValues };

      if (fileName) {
        formValues[`${name}_data`] = value;
        formValues[name] = fileName;
        if (!value) {
          delete formValues[`${name}_deleted`];
        }
      } else {
        formValues[name] = value;
      }

      this.formValues = formValues;
      this.$emit("formUpdate", {"company_branch_insurances": formValues});
    },
    getFileInputStyle(fieldName) {
      return (this.fileValid[fieldName] && this.formValues[fieldName]) ? 'display:none;' : '';
    },
    judgeDisplayFileInput(fieldName) {
      return this.editable && this.fileValid[fieldName] && this.formValues[fieldName];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";
</style>