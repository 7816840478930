<template>
  <v-card class="dialog">
    <v-card-title>
      <div class="title">ファイルアップロード</div>
    </v-card-title>
    <v-card-text>
      <ValidationObserver ref="observer" v-slot="observer">
        <v-form ref="form" lazy-validation autocomplete="off">
          <v-list-item>
            <v-list-item-content>
              <Label label="タイトル" :editable="editable" required>
                <InputText
                  name="title"
                  :values="formValues"
                  :editable="editable"
                  validation_label="タイトル"
                  validation_rules="required|max:255"
                  @onInput="onInput"
                />
              </Label>
              </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <Label label="ファイル" :editable="editable" required>
                <FileInput
                  name="file_data"
                  :values="formValues"
                  :editable="editable"
                  accept=""
                  validation_label="ファイル"
                  validation_rules="required|not_file_dynamic"
                  @onInput="onInput"
                />
              </Label>
            </v-list-item-content>
          </v-list-item>
        </v-form>
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>
      <div></div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="primary"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn
        depressed
        class="btn"
        color="primary"
        @click.once="onSubmit"
        :key="flagError"
        :disabled="!valid"
      >
        送信
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback";
import Label from "@/components/forms/elements/Label";
import InputText from "@/components/forms/elements/InputText";
import FileInput from "@/components/forms/elements/FileInput";
export default {
  data() {
    return {
      formValues: {
        file_name: null,
        file_data: null,
        title: null
      },
      valid: false,
    };
  },
  components: {
    Label,
    InputText,
    FileInput,
    ValidationCallback,
    ValidationObserver,
  },
  props: {
    flagError: {
      type: Boolean,
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onInput({ name, value, fileName }) {
      const formValues = { ...this.formValues };
      if (fileName) {
        formValues[name] = value;
        formValues.file_name = fileName;
      } else {
        formValues[name] = value;
      }
      this.formValues = formValues;
    },
    onSubmit() {
      this.$emit("yes", this.formValues);
    },
    updateValidate({ valid }) {
      this.valid = valid;
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .title {
    font-size: 12px;
    color: #1b9c4f;
  }
}
::v-deep .v-list-item__content {
  padding: 0;
}
</style>
