import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const ScheduleSearch = {
  namespaced: true,

  state: {
    //予定・実績の絞り込みフォーム
    isSeachFormOpen: false,
  },

  mutations: {
    ACTIVE(state, value) {
      state.isSeachFormOpen = value;
    },
  },

  actions: {
    show({ commit }, value) {
      commit("ACTIVE", value);
    },
  },

  getters: {
    getActive: (state) => {
      return state.isSeachFormOpen;
    },
  },
};
