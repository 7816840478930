<template>
  <FormDialog>
    <template #header>
      <!-- 
        (共通)
        ヘッダーは全て共通
       -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          各種設定
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          depressed
          class="mr-5"
          small
          @click="isShowConfirmDialog=true"
        >
          やめる
        </v-btn>
        <v-btn
          depressed
          small
          color="primary"
          @click="updateItem"
        >
          保存
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in SETTING_PLAN" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!-- 
        (共通)
        全てのフォームのバリデーションの監視を行う
       -->
        <!-- フォームtab -->
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <FormGate
              v-if="!ENV_OBAYASHI"
              :editable="true"
              :gate_passages="formValue.gate_passages"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
            <FormGateObay
              v-if="ENV_OBAYASHI"
              :editable="true"
              :gate_passages="formValue.gate_passages"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <FormCrane
              v-if="!ENV_OBAYASHI"
              :editable="true"
              :crane_uses="formValue.crane_uses"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
            <FormCraneObay
              v-if="ENV_OBAYASHI"
              :editable="true"
              :crane_uses="formValue.crane_uses"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
        </v-tabs-items>
        <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import { SETTING_PLAN } from "@/constants/SCHEDULE_GATE";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import FormGate from "./FormGate.vue";
import FormCrane from "./FormCrane.vue";
import FormCraneObay from "./FormCraneObay.vue";
import FormGateObay from "./FormGateObay.vue";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
import { Store } from "@/store/Store.js";
import _ from "lodash";

export default {
  data: () => {
    return {
      SETTING_PLAN,
      tab: null,
      isClickCloseBtn: false,
      isShowConfirmDialog: false,
      formValue: {
        gate_passages: {},
        crane_uses: {},
      },
      beforeUpdateItem: {},
    };
  },
  components: {
    FormDialog,
    FormGate,
    FormCrane,
    FormGateObay,
    FormCraneObay,
    Popup,
    ConfirmCloseDialog,
  },
  mounted() {
    this.$watch(
      () => Store.getters["Customizes/getData"],
      (data) => {
        this.formValue = _.cloneDeep(data);
        this.beforeUpdateItem = _.cloneDeep(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    ENV_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    }
  },
  methods: {
    /**
     * (共通)
     * click button Close (X)
     */
    onClickBtnClose() {
      this.isClickCloseBtn = true;
      this.isShowConfirmDialog = true;
    },

    async updateItem() {
      let params = {
        field_id: this.CURRENT_SITE.field_id,
        company_type: this.ENV_OBAYASHI ? 1: 0,
        gate_passages: this.formValue.gate_passages,
        crane_uses: this.formValue.crane_uses,
      }
      const rs = await Store.dispatch("Customizes/updateItem", params);
      if (!rs.hasError) {
        this.$emit('resetList');
        this.$emit("closeSettingPlanForm");
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
      }
    },

    formUpdate({name, value}){
      this.formValue[name] = _.cloneDeep(value);
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      if (!this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        this.formValue = _.cloneDeep(this.beforeUpdateItem);
      } else {
        this.$emit("closeSettingPlanForm");
      }
    },

    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
  }
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
