<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <FormClassificationOccupation
                    name="device_work_classifications"
                    :values="formValues"
                    :editable="editable"
                    :isShowOccupation="checkShow('occupation')"
                    :isRequiredOccupation="checkRequired('occupation')"
                    :isShowClassification="checkShow('work_content')"
                    :isRequiredClassification="checkRequired('work_content')"
                    @onInput="onInput"
                  />
                  <v-list-item v-if="checkShow('work_details')">
                    <v-list-item-content>
                      <Label label="作業詳細" :editable="editable" :required="checkRequired('work_details')">
                        <InputTextArea
                          name="work_details"
                          :values="formValues"
                          :editable="editable"
                          placeholder="ここに作業内容詳細がはいります。"
                          validation_label="作業詳細"
                          :validation_rules="checkRequired('work_details')?'required|max:512':'max:512'"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="checkShow('expected_danger')">
                    <v-list-item-content>
                      <Label label="予想される危険" :editable="editable" :required="checkRequired('expected_danger')">
                        <InputTextArea
                          name="expected_danger"
                          :values="formValues"
                          :editable="editable"
                          placeholder="ここに予測される危険がはいります。"
                          validation_label="予想される危険"
                          :validation_rules="checkRequired('expected_danger')?'required|max:512':'max:512'"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <FormSafetyInstructions
                    name="device_safety_instructions"
                    :values="formValues"
                    :editable="editable"
                    :isShowSafetyInstruction="checkShow('safety_instruction')"
                    :isRequiredSafetyInstruction="checkRequired('safety_instruction')"
                    @onInput="onInput"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-list-item v-if="checkShow('status_code')">
                    <v-list-item-content>
                      <Label label="" :editable="editable" :required="checkRequired('status_code')" class="cst-required-switch" />
                      <SwitchInput
                        name="status_code"
                        :label="`スポット作業`"
                        :values="workStatus"
                        :editable="editable"
                        validation_label="スポット作業"
                        :validation_rules="checkRequired('status_code')?'required':''"
                        @onInput="onInput"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <FormWorkingTime
                    name="device_usage_times"
                    :values="formValues"
                    :editable="editable"
                    :isShowUsageTime="checkShow('usage_time')"
                    :isRequiredUsageTime="checkRequired('usage_time')"
                    @onInput="onInput"
                  />
                  <FormPerson
                    name="slinger_signallers"
                    :values="formValues"
                    :editable="editable"
                    :isShowSignal="checkShow('signal_worker_id')"
                    :isRequiredSignal="checkRequired('signal_worker_id')"
                    :isShowSlinging="checkShow('slinging_worker_id')"
                    :isRequiredSlinging="checkRequired('slinging_worker_id')"
                    @onInput="onInput"
                  />
                  <FormPersonInCharge
                    name="device_users"
                    :values="formValues"
                    :editable="editable"
                    :isShowDeviceUsers="checkShow('field_director_id')"
                    :isRequiredDeviceUsers="checkRequired('field_director_id')"
                    @onInput="onInput"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "../../elements/Label";
import InputText from "../../elements/InputText";
import InputDatepicker from "../../elements/InputDatepicker.vue";
import InputTextArea from "@/components/forms/elements/InputTextArea";
import SwitchInput from "@/components/forms/elements/SwitchInput";
import FormClassificationOccupation from "./components/FormClassificationOccupation.vue";
import FormSafetyInstructions from "./components/FormSafetyInstructions.vue";
import FormWorkingTime from "./components/FormWorkingTime.vue";
import FormPerson from "./components/FormPerson.vue";
import FormPersonInCharge from "./components/FormPersonInCharge.vue";
import { SWITCH_VALUE } from '@/constants/COMMON';
const IS_CUSTOMIZE = 1;

const WORK_STATUS = {
  OCCUPANCY: 1,
  SPOT: 2,
}

export default {
  data: () => {
    return {
      formValues: {},
      workStatus:{ status_code: 0}
    };
  },
  components: {
    Label,
    InputText,
    InputTextArea,
    SwitchInput,
    InputDatepicker,
    FormClassificationOccupation,
    FormSafetyInstructions,
    FormWorkingTime,
    FormPerson,
    FormPersonInCharge
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    cranePassagesCustomize: Object,
  },
  mounted () {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
        this.workStatus.status_code = this.formValues["status_code"] == WORK_STATUS.OCCUPANCY ? SWITCH_VALUE.OFF : SWITCH_VALUE.ON;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle () {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    onInput ({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      if(name == "status_code") {
        this.workStatus.status_code = value;
        this.formValues["status_code"] = value == SWITCH_VALUE.ON ? WORK_STATUS.SPOT : WORK_STATUS.OCCUPANCY;
      }
      this.$emit("formUpdate", formValues);
    },

    /**
     * Check item requred
     */
    checkRequired(name) {
      return this.cranePassagesCustomize[name]?.required === IS_CUSTOMIZE;
    },
    
    /**
     * Check item show or hidden
     */
    checkShow(name) {
      return this.cranePassagesCustomize[name]?.form_view === IS_CUSTOMIZE;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
.cst-required-switch {
  margin-top: -3px;
  position: fixed;
  margin-left: 7vw;
}
</style>
