import Vue from "vue";
import Vuex from "vuex";
import { deviceCoordinator } from "../../../api/modules/deviceCoordinator";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = deviceCoordinator;

export const DeviceCoordinator = {
  namespaced: true,

  state: {
    data: [],
    pagination: [],
    totalRecord:[],
    listCreatedCompany: [],
    machineApplicationDetail: {},
    workPlanList: [],
    paginationWorkPlan: {},
    machineEstimate : {},
    machineOrder : {},
    machineEstimateDetail : {},
    machineOrderDetail :{}
  },

  mutations: {
    SET_DATA(state, payload) {
      let _data = [...state.data];
      _data = _data.filter(e => e.tab != payload.tab);
      _data.push({
        tab : payload.tab,
        data : payload.data
      })
      state.data = [..._data];
    },
    SET_PAGINATION(state, payload) {
      let _pagination = [...state.pagination];
      _pagination = _pagination.filter(e => e.tab != payload.tab);
      _pagination.push({
        tab : payload.tab,
        pagination : payload.pagination
      })
      state.pagination = [..._pagination];
    },
    SET_TOTAL_RECORD(state, payload) {
      let _totalRecord = [...state.totalRecord];
      _totalRecord = _totalRecord.filter(e => e.tab != payload.tab);
      _totalRecord.push({
        tab : payload.tab,
        total : payload.total
      })
      state.totalRecord = [..._totalRecord];
    },
    SET_LIST_CREATED_COMPANY(state, payload) {
      state.listCreatedCompany = payload;
    },
    SET_DETAIL_MACHINE_APPLICATION(state, payload) {
      state.machineApplicationDetail = payload;
    },
    SET_DATA_WORK_PLAN(state, payload) {
      state.workPlanList = payload;
    },
    SET_PAGINATION_WORK_PLAN(state, payload) {
      state.paginationWorkPlan = payload;
    },
    SET_ESTIMATE(state, payload) {
      state.machineEstimate = payload;
    },
    SET_ORDER(state, payload) {
      state.machineOrder = payload;
    },
    SET_MACHINE_ESTIMATE_DETAIL(state, payload) {
      state.machineEstimateDetail = payload;
    },
    SET_MACHINE_ORDER_DETAIL(state, payload) {
      state.machineOrderDetail = payload;
    }
  },

  actions: {
    async get({ commit }, payload) {
      const tab = payload.params.tab;
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;
      commit("SET_DATA", { tab: tab, data: entries });
      commit("SET_PAGINATION", { tab: tab, pagination: pagination });
      commit("SET_TOTAL_RECORD", { tab: tab, total: pagination.total_specific_item });
      return response;
    },
    
    async getListCreatedCompany({ commit }, payload){
      const response = await ENTITY.getListCreatedCompany(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_LIST_CREATED_COMPANY", entries);
      return response;
    },

    async post(_context, payload) {
      return await ENTITY.post(payload);
    },

    async update(_context, payload) {
      return await ENTITY.update(payload);
    },

    async createMachineEstimate(_context, payload) {
      return await ENTITY.createMachineEstimate(payload);
    },

    async createMachineOrder(_context, payload) {
      return await ENTITY.createMachineOrder(payload);
    },

    async sendOrderReport(_context, payload) {
      return await ENTITY.sendOrderReport(payload);
    },

    async sendMachineEstimateReport(_context, payload) {
      return await ENTITY.sendMachineEstimateReport(payload);
    },

    async cancelMachineEstimate(_context, payload) {
      return await ENTITY.cancelMachineEstimate(payload);
    },

    async createOrderOfEstimate(_context, payload) {
      return await ENTITY.createOrderOfEstimate(payload);
    },
    async reOrder(_context, payload) {
      return await ENTITY.reOrder(payload);
    },
    async reEstimate(_context, payload) {
      return await ENTITY.reEstimate(payload);
    },

    async delete(_context, payload) {
      return await ENTITY.delete(payload);
    },
    async getDetailMachineApplication({ commit }, payload) {
      const response = await ENTITY.getDetailMachineApplication(payload);
      const entries =  response.data.contents.entries;
      commit("SET_DETAIL_MACHINE_APPLICATION", entries);
      return response;
    },
    async getWorkPlanList ({ commit }, payload) {
      const response = await ENTITY.getWorkPlanList(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;
      
      commit("SET_DATA_WORK_PLAN", entries);
      commit("SET_PAGINATION_WORK_PLAN", pagination);
      return response;
    },
    async createMachineApplication(_context, payload) {
      return await ENTITY.createMachineApplication(payload);
    },
    async updateMachineApplication(_context, payload) {
      return await ENTITY.updateMachineApplication(payload);
    },
    async getEstimate({ commit }, payload) {
      const response = await ENTITY.getEstimate(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_ESTIMATE", entries);
      return response;
    },
    async getOrder({ commit }, payload) {
      const response = await ENTITY.getOrder(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_ORDER", entries);
      return response;
    },
    async replyEstimateResult(_context, payload) {
      return await ENTITY.replyEstimateResult(payload);
    },
    async replyOrderResult(_context, payload) {
      return await ENTITY.replyOrderResult(payload);
    },
    async downloadReport(_context, payload) {
      return await ENTITY.downloadReport(payload);
    },
    async getReportComment(_context, payload) {
      return await ENTITY.getReportComment(payload);
    },
    async getMachineEstimateDetail({ commit }, payload) {
      const response = await ENTITY.getMachineEstimateDetail(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_MACHINE_ESTIMATE_DETAIL", entries);
      return response;
    },
    async getMachineOrderDetail({ commit }, payload) {
      const response = await ENTITY.getMachineOrderDetail(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_MACHINE_ORDER_DETAIL", entries);
      return response;
    },
    async updateMachineEstimateOrder(_context, payload) {
      return await ENTITY.updateMachineEstimateOrder(payload);
    },
    async createReportComment(_context, payload) {
      return await ENTITY.createReportComment(payload);
    },
    async createReport(_context, payload) {
      return await ENTITY.createReport(payload);
    },
    async updateEstimateUnitPrice(_context, payload) {
      return await ENTITY.updateEstimateUnitPrice(payload);
    },
    async updateOrderUnitPrice(_context, payload) {
      return await ENTITY.updateOrderUnitPrice(payload);
    },
    async deleteOrderEstimate(_context, payload) {
      return await ENTITY.deleteOrderEstimate(payload);
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getTotalRecord: (state) => {
      return state.totalRecord;
    },
    getListCreatedCompany: (state) => {
      return state.listCreatedCompany;
    },
    getDetailMachineApplication: (state) => {
      return state.machineApplicationDetail;
    },
    getWorkPlanList: (state) => {
      return state.workPlanList;
    },
    getPaginationWorkPlan: (state) => {
      return state.paginationWorkPlan;
    },
    getestimate: (state) => {
      return state.machineEstimate;
    },
    getorder: (state) => {
      return state.machineOrder;
    },
    getMachineEstimateDetail: (state) => {
      return state.machineEstimateDetail;
    },
    getMachineOrderDetail: (state) => {
      return state.machineOrderDetail;
    },
  },
};

