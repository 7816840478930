var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DefaultLayout',{scopedSlots:_vm._u([{key:"mainHeader",fn:function(){return [_c('CompanyHeader')]},proxy:true},{key:"page",fn:function(ref){
var layoutParams = ref.layoutParams;
return [_c('TableLayout',{attrs:{"layoutParams":layoutParams},scopedSlots:_vm._u([{key:"tableHeader",fn:function(ref){
var updateHeader = ref.updateHeader;
return [_c('TableHeader',{ref:"tableHeader",attrs:{"pageTitle":_vm.PAGE_TITLE,"multiRemoveStatus":_vm.disableRemoveBtn,"updateHeader":updateHeader},on:{"openRemoveDialog":_vm.openRemoveDialog,"openItemForm":_vm.openNewItemForm}},[_c('SearchFormWrapper',[_c('Label',{attrs:{"label":"名前"}},[_c('InputText',{attrs:{"name":"word","editable":true,"values":_vm.searchParams},on:{"onInput":_vm.onChangeSearchParams}})],1),_c('Label',{attrs:{"label":"ロール"}},[_c('Select',{attrs:{"name":"roll","items":_vm.selectRollItems,"editable":true,"values":_vm.searchParams},on:{"onInput":_vm.onChangeSearchParams}})],1),_c('Label',{attrs:{"label":"性別"}},[_c('Select',{attrs:{"name":"gender","items":_vm.selectGenderItems,"editable":true,"values":_vm.searchParams},on:{"onInput":_vm.onChangeSearchParams}})],1),_c('Label',{attrs:{"label":"登録日"}},[_c('InputDatepicker',{attrs:{"name":"created_at","editable":true,"values":_vm.searchParams},on:{"onInput":_vm.onChangeSearchParams}})],1),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","depressed":""},on:{"click":_vm.onSearch}},[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)],1)],1),_c('TableSortWrapper',[_c('TableSort',{attrs:{"values":_vm.searchParams,"sort_items":_vm.SORT_ITEMS,"sort_item_text":"name","sort_item_value":"id","page_counts_options":_vm.PAGE_COUNT_OPTIONS,"sort_order_options":_vm.SORT_ORDERS,"total_item":_vm.searchParams.total_item},on:{"onInput":_vm.onChangeSortParams}})],1)]}},{key:"tableBody",fn:function(ref){
var tableHeight = ref.tableHeight;
return [_c('v-data-table',{staticClass:"elevation-0 v-data-table__wrapper",attrs:{"headers":_vm.TABLE_LABELS,"items":_vm.items,"items-per-page":_vm.searchParams.pageCount,"height":tableHeight,"fixed-header":"","hide-default-footer":"","disable-sort":"","sort-by":"updatedAt","show-select":"","noDataText":_vm.NO_DATA_MESSAGE},on:{"click:row":_vm.openItemForm},scopedSlots:_vm._u([{key:"item.roll",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRollLabel(item.roll))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.created_at).date)+" ")]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})]}},{key:"tableFooter",fn:function(){return [_c('Pagination',{attrs:{"current":_vm.searchParams.currentPage,"total":_vm.searchParams.totalPage},on:{"pageUpdate":_vm.pageUpdate}})]},proxy:true}],null,true)})]}}])}),_c('Popup',{attrs:{"dialog":_vm.popups.isShowItemForm}},[_c('UserForm',{ref:"UserForm",attrs:{"item":_vm.editedItem,"isNewItem":_vm.isNewItem},on:{"formUpdate":_vm.formUpdate,"submit":_vm.submitForm,"cancel":_vm.closeItemForm}})],1),_c('Popup',{attrs:{"width":"480px","dialog":_vm.popups.isShowRemoveDialog}},[_c('ConfirmRemoveDialog',{attrs:{"title":"選択項目の削除","text":"以下を削除してもよろしいですか？","items":_vm.selectedItems,"warning":""},on:{"close":function($event){return _vm.closeRemoveDialog()},"yes":function($event){return _vm.removeItems()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }