<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <Label
                        label="安全宣言（スローガン）"
                        :editable="editable"
                      >
                        <InputText
                          name="safety_declaration"
                          :values="formValues.fields"
                          :editable="editable"
                          validation_label="安全宣言（スローガン）"
                          validation_rules="max:255"
                          @onInput="onInput"
                        />
                      </Label>
                      <SafetyRules
                        name="specific_field_safety_rules"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="備考">
                        <InputTextArea
                          name="remarks"
                          :values="formValues.fields"
                          :editable="editable"
                          validation_label="備考"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "../elements/Label";
import InputTextArea from "../elements/InputTextArea";
import SafetyRules from "./components/SafetyRules.vue";
import InputText from "../elements/InputText.vue";
export default {
  data: () => {
    return {
      formValues: {},
    };
  },
  components: {
    Label,
    SafetyRules,
    InputTextArea,
    InputText,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      if (name == "remarks" || name == "safety_declaration") {
        formValues["fields"][name] = value;
      } else {
        formValues[name] = value;
      }
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
</style>
