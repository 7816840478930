<template>
  <div>
    <v-container>
      <v-row v-for="(item, index) in items" :key="index" v-if="!item.hidden">
        <v-col cols="12" sm="5" md="5" class="pt-0 pb-0">
          <v-list-item>
            <v-list-item-content>
              <Label label="タイトル" :editable="editable" required>
                <InputText
                  name="name"
                  :values="item"
                  :editable="editable"
                  validation_label="タイトル"
                  validation_rules="required|max:255"
                  @onInput="onInput({ value: $event, index })"
                />
              </Label>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="5" md="5" class="pt-0 pb-0">
          <v-list-item>
            <v-list-item-content>
              <Label label="ファイル" :editable="editable" required>
                <span
                  class="file"
                  v-if="!editable && item['document']"
                  @click="viewSourceWithAuth(item['document_url'])"
                  >{{ item["document"] }}
                </span>
                <FileInput
                  :style="getFileInputStyle(index, 'document', 'document')"
                  :resetValue="resetValue[`${index}_document`]"
                  v-if="editable"
                  name="document"
                  :values="item"
                  :editable="editable"
                  v-bind:validation_label="`${index}_document`"
                  :validation_rules="getRuleFile(item)"
                  accept="*"
                  @onInput="onInput({ value: $event, index })"
                />
                <span
                  class="file"
                  v-if="judgeDisplayFileInput(index, 'document', 'document')"
                  >{{ item["document"] }}
                  <v-btn
                    small
                    icon
                    text
                    @click="onRemoveFile(index, 'document')"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </span>
              </Label>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col cols="12" sm="2" md="2" class="pt-0 pb-0">
          <v-btn
            v-if="index >= 0 && editable"
            class="mt-12"
            icon
            @click="remove(index)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-btn @click="addEmpty" color="primary" v-if="editable" class="mt-6 ml-4"
      >追加</v-btn
    >
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "../../elements/Label.vue";
import InputText from "../../elements/InputText.vue";
import FileInput from "../../elements/FileInput.vue";
import { viewSourceWithAuth } from "@/utils/viewSourceAuth";
import _ from "lodash";
const INITIAL_VALUES = {
  name : null,
  document : null,
  document_data : null
};

export default {
  data: () => {
    return {
      items: [],
      fileValid: {},
      resetValue: {},
      viewSourceWithAuth: viewSourceWithAuth,
    };
  },
  components: {
    Label,
    InputText,
    FileInput,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    errors: {
      type: Object,
      default: {},
    },
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
      },
      {
        immediate: true,
        deep: true,
      }
    );

    // check input file errors
    this.$watch(
      () => [this.values, this.name, this.errors],
      (data) => {
        const errors = { ...data[2] };

        // the validation_label list of the FileInput element
        const document = "_document";
        const fileValid = { ...this.fileValid };
        const _items = _.cloneDeep(data[0][data[1]]);
        if (errors) {
          let index = 0;
          for (let name in errors) {
            if (name?.includes(document)) {
              if (errors[`${index}_document`].length > 0) {
                delete _items[index]["document"];
                delete _items[index]["document_data"];
                fileValid[name] = false;
              } else {
                fileValid[name] = true;
              }
              index++;
            }
          }
        }
        this.items = _items;
        this.fileValid = fileValid;
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      if (value.name == "document") {
        _items[index][`${value.name}_data`] = value.value;
        _items[index][value.name] = value.fileName;
        if (!value.value) {
          delete _items[index][`${value.name}_deleted`];
        }
      }
      this.$emit("onInput", {
        name: this.name,
        value: _items,
      });
    },
    remove(_index) {
      let _items = [...this.items];
      if (_items[_index][`document_url`]) {
        _items[_index]["hidden"] = true;
        _items[_index][`document_deleted`] = 1;
      } else {
        _items = this.items.filter((_item, index) => {
          return index != _index;
        });
      }
      this.items = _items;
      this.resetValue[`${_index}_document`] =!this.resetValue[`${_index}_document`];
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      let _items = [...this.items];
      _items.push({ ...INITIAL_VALUES });
      this.items = _items;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    onRemoveFile(index, name) {
      let _items = [...this.items];
      delete _items[index][name];
      delete _items[index][`${name}_data`];
      if (_items[index][`${name}_url`]) _items[index][`${name}_deleted`] = 0;
      this.items = _items;
      this.resetValue[`${index}_document`] = !this.resetValue[`${index}_document`];
      this.$emit("onInput", { name: this.name, value: _items });
    },
    getFileInputStyle(index, subField, parentField) {
      return this.fileValid[`${index}_${subField}`] && this.items[index][parentField] ? "display:none;" : "";
    },
    judgeDisplayFileInput(index, subField, parentField) {
      return (this.editable && this.fileValid[`${index}_${subField}`] && this.items[index][parentField]);
    },
    getRuleFile(item){
      return item.document ? 'maxsize:10' : 'required-file|maxsize:10';
    }
  },
};
</script>
<style lang="scss" scoped>
@import "./../../style/forms.scss";
</style>
