<template>
  <div>
    <FormDialog>
      <template #header>
        <!-- 
        (共通)
        ヘッダーは全て共通
       -->
        <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
          <v-toolbar-title color="primary">
            {{ companyName }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeForm">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <template v-slot:extension>
            <v-tabs v-model="tab" align-with-title>
              <v-tabs-slider color="primary"></v-tabs-slider>
              <v-tab v-for="form in FORMS_ARRAY" :key="form.id">
                {{ form.title }}
              </v-tab>
            </v-tabs>
          </template>
        </v-app-bar>
      </template>
      <template #main="{ params }">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <DocumentViewer
              :tab="0"
              :forms="FORMS_ARRAY"
              :histories="histories"
              :mainHeight="params.mainHeight"
              :userPermissions="userPermissions"
              :flagError="flagError"
              :currenTab="tab"
              @onMessage="onMessage"
              @onSendNotify="onSendNotify"
              @onCreateReport="onCreateReport"
              @onClickRejected="onClickRejected"
              @onClickApproved="onClickApproved"
            />
          </v-tab-item>
          <v-tab-item>
            <DocumentViewer
              :tab="1"
              :forms="FORMS_ARRAY"
              :histories="histories"
              :mainHeight="params.mainHeight"
              :userPermissions="userPermissions"
              :flagError="flagError"
              :currenTab="tab"
              @onMessage="onMessage"
              @onSendNotify="onSendNotify"
              @onCreateReport="onCreateReport"
              @onClickRejected="onClickRejected"
              @onClickApproved="onClickApproved"
            />
          </v-tab-item>
          <v-tab-item>
            <DocumentViewer
              :tab="2"
              :forms="FORMS_ARRAY"
              :histories="histories"
              :mainHeight="params.mainHeight"
              :userPermissions="userPermissions"
              :flagError="flagError"
              :currenTab="tab"
              @onMessage="onMessage"
              @onSendNotify="onSendNotify"
              @onCreateReport="onCreateReport"
              @onClickRejected="onClickRejected"
              @onClickApproved="onClickApproved"
            />
          </v-tab-item>
          <v-tab-item>
            <DocumentViewer
              :tab="3"
              :forms="FORMS_ARRAY"
              :histories="histories"
              :mainHeight="params.mainHeight"
              :userPermissions="userPermissions"
              :flagError="flagError"
              :currenTab="tab"
              @onMessage="onMessage"
              @onSendNotify="onSendNotify"
              @onCreateReport="onCreateReport"
              @onClickRejected="onClickRejected"
              @onClickApproved="onClickApproved"
            />
          </v-tab-item>
          <v-tab-item>
            <DocumentViewer
              :tab="4"
              :forms="FORMS_ARRAY"
              :histories="histories"
              :mainHeight="params.mainHeight"
              :userPermissions="userPermissions"
              :flagError="flagError"
              :currenTab="tab"
              @onMessage="onMessage"
              @onSendNotify="onSendNotify"
              @onCreateReport="onCreateReport"
              @onClickRejected="onClickRejected"
              @onClickApproved="onClickApproved"
            />
          </v-tab-item>
          <v-tab-item>
            <DocumentViewer
              :tab="5"
              :forms="FORMS_ARRAY"
              :histories="histories"
              :mainHeight="params.mainHeight"
              :userPermissions="userPermissions"
              :flagError="flagError"
              :currenTab="tab"
              @onMessage="onMessage"
              @onSendNotify="onSendNotify"
              @onCreateReport="onCreateReport"
              @onClickRejected="onClickRejected"
              @onClickApproved="onClickApproved"
            />
          </v-tab-item>
          <v-tab-item v-if="checkObayashi">
            <DocumentViewer
              :tab="6"
              :forms="FORMS_ARRAY"
              :histories="histories"
              :mainHeight="params.mainHeight"
              :userPermissions="userPermissions"
              :flagError="flagError"
              :currenTab="tab"
              @onMessage="onMessage"
              @onSendNotify="onSendNotify"
              @onCreateReport="onCreateReport"
              @onClickRejected="onClickRejected"
              @onClickApproved="onClickApproved"
            />
          </v-tab-item>
          <v-tab-item>
            <DocumentOther
              :tab="checkObayashi ? 7 : 6"
              :forms="FORMS_ARRAY"
              :histories="histories"
              :mainHeight="params.mainHeight"
              :flagError="flagErrorOther"
              :userPermissions="userPermissions"
              @createDocument="createPartnerDocument"
            />
          </v-tab-item>
        </v-tabs-items>
      </template>
    </FormDialog>
    <slot></slot>
  </div>
</template>

<script>
import FormDialog from "@/components/dialog/FormDialog.vue";
import DocumentViewer from "./components/DocumentViewer.vue";
import DocumentOther from "./components/DocumentOther.vue";
import {
  SAFETY_PERMISSIONS,
  APPROVED_FLAGS,
  DOCUMENT_TYPES,
  getTabFirst
} from "@/constants/SAFETY_CHART.js";
import { Store } from "@/store/Store.js";
import {ENV_CLIENT} from "@/constants/ENV_CLIENT.js";

const FORMS_ARRAY = getTabFirst();

export default {
  name: "DocumentForm",
  data: () => {
    return {
      SAFETY_PERMISSIONS,
      APPROVED_FLAGS,
      DOCUMENT_TYPES,
      FORMS_ARRAY: Object.keys(FORMS_ARRAY).map((key) => {
        return FORMS_ARRAY[key];
      }),
      tab: null,
      isClickCancelBtn: false,
      histories: null,
      userPermissions: [],
    };
  },
  components: {
    FormDialog,
    DocumentViewer,
    DocumentOther,
  },
  props: {
    companyId: Number,
    companyName: String,
    chartId: Number,
    formTab: Object,
    flagError: Boolean,
    flagErrorOther: Boolean,
    tabCurrent: Number,
  },
  mounted() {
    /**
     * get data field_tree
     */
    this.$watch(
      () => Store.getters["SafetyChart/getPartnerReports"],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.histories = newValue;
          this.userPermissions = newValue.user_permission;
          if (this.tabCurrent) this.tab = this.tabCurrent;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    /**
     * 作成
     */
    onCreateReport() {
      const chart = {
        id: this.chartId,
        tabCreateReport: this.tab
      }
      this.$emit("onCreateReport", chart);
    },
    onSendNotify(document) {
      this.$nextTick(() => {
        let _document = {...document};
        _document.chartId = this.chartId;
        _document.tabCurrent = this.tab;
        this.$emit("onSendNotify", _document);
      });
    },
    /**
     * 承認
     */
    onClickApproved() {
      const chart = {
        id: this.chartId,
        tabCurrent: this.tab
      }
      this.$emit("onClickApproved", chart);
    },

    /**
     * 承認
     */
    onClickRejected() {
      this.$nextTick(() => {
        const chart = {
          id: this.chartId,
          tabCurrent: this.tab
        }
        this.$emit("onClickRejected", chart);
      });
    },

    onMessage(message) {
      this.$emit("onMessage", message);
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      this.isClickCancelBtn = false;
      this.$emit("cancel");
    },

    createPartnerDocument(file) {
      let _file = {...file};
      _file.field_tree_id = this.chartId;
      _file.tabCurrent = this.tab;
      this.$emit('createPartnerDocument', _file);
    },
  },
  computed: {
    checkObayashi(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
  }
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
