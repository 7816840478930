<template>
  <v-item-group class="mb-4">
    <v-list>
      <v-list-item justify="space-around">
        <h2 class="font-weight-bold">{{ label }}</h2>
        <v-spacer></v-spacer>
        <div class="font-weight-bold">
          <span>更新時間</span>
          <span class="ml-1">{{time}}</span>
          <v-btn :loading="loading" :disabled="flagUserRentalCompany" icon><v-icon @click="$emit('updateTable')">mdi-cached</v-icon></v-btn>
        </div>
      </v-list-item>
    </v-list>
    <slot />
  </v-item-group>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
    },
    time: {
      type: String,
    },
    loading:{
      type: Boolean,
    },
    flagUserRentalCompany:{
      type: Boolean,
      default: false,
    },
  },
};
</script>
