<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" depressed class="ma-2" v-bind="attrs" v-on="on">
          {{ label }}
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="item in menu"
          @click="$emit('typeAdd', item.id)"
          :key="item.id"
        >
          <v-list-item-title class="hover-item">{{
            item.name
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
// import { Store } from "@/store/Store.js";
export default {
  data: () => {
    return {};
  },
  props: {
    label: {
      type: String,
    },
    menu: {
      type: Array,
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.header_dropdown_btn {
  padding: 0 !important;
  width: 100%;
  height: 100%;
  line-height: 48px;
}
.hover-item {
  cursor: pointer;
}
</style>
