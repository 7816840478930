import Api from "../api";

const BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/face_reco_api`;
// 顔ポスト
const URL_POST = `${BASE_URL}/authorize_sp`;

// 顔ポスト
const URL_CHECK_FACE = `${BASE_URL}/check`;

export const faceRecognition = {
  // 顔画像送信
  postAuth: (data) => {
    const { group_id, picture, latitude, longitude } = data;

    const params = {
      group_id,
      picture,
      latitude,
      longitude,
    };

    return Api.post(URL_POST, params);
  },

  checkFace: (data) => {
    const { worker_picture } = data;

    const params = {
      worker_picture,
    };

    return Api.post(URL_CHECK_FACE, params);
  },
};
