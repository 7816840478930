<template>
  <v-card class="dialog">
    <div class="header sticky">
      <v-card-title small>
        <div class="title">資格選択</div>
        <v-spacer></v-spacer>
        <v-btn small icon text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
    </div>
    <SearchSelectList placeHolder="資格の種類" @search="search" @formUpdate="formUpdate" />
    <div v-if="listFiller.length > 0" class="list-multiple-qualification">
      <v-list subheader two-line flat>
        <v-list-item-group v-model="listSelected" multiple>
          <v-list-item
            v-for="(item, index) in listFiller"
            :key="item.id"
            :value="item"
            @click="changeCheckBox(item)"
            :class="{'border' : index != listFiller.length - 1}"
            >
            <template #default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active" color="primary"></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
    <v-divider></v-divider>
    <div class="text-end pr-3 pt-3">
      <v-btn @click="$emit('updateData', listSelectedAll)" color="primary">決定</v-btn>
    </div>
  </v-card>
</template>

<script>
import SearchSelectList from "./SearchSelectList.vue";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import { Store } from "@/store/Store.js";
export default {
  components: {
    SearchSelectList
  },
  props: {
    workQualifications: {
      type: Array,
      default: () => []
    },
    listWorkQualifications: {
      type: Array,
      default: () => []
    }
  },
  data: () => {
    return {
      NO_DATA_MESSAGE,
      keyword: "",
      listSelected: [],
      itemSelecting: null,
      listFiller: [],
      listSelectedAll: [],
      isSearch: false,
    };
  },
  async mounted() {
    await this.getDataItems()
    await this.setDataSelected()
  },
  watch: {
    listSelected(val, oldVal) {
      if (this.isSearch) {
        this.isSearch = false
      } else if (val.length > oldVal.length) {
        this.listSelectedAll.push(this.itemSelecting)
      } else {
        const index = this.listSelectedAll.findIndex(
          (x) => x.id === this.itemSelecting.id
        )
        this.listSelectedAll.splice(index, 1)
      }
    }
  },
  methods: {
    async getDataItems() {
      this.isSearch = true
      await Store.dispatch("Schedule/getListSearchQualification", { search_name: this.keyword }).then(res => {
        this.listFiller = [...res.data.contents.entries]
        const arrSelect = []
        this.listFiller.forEach((x) => {
          if (this.listSelectedAll.some((y) => y.id === x.id)) {
            arrSelect.push(x)
          }
        })
        this.listSelected = [...arrSelect]
      })
    },
    async setDataSelected() {
      if(this.workQualifications.length > 0) {
        this.isSearch = true
        await this.listFiller.forEach(x => {
          if(this.workQualifications.some(y => y == x.id)){
            this.listSelectedAll.push(x)
          }
        })
        const arrSelect = []
        this.listFiller.forEach((x) => {
          if (this.listSelectedAll.some((y) => y.id === x.id)) {
            arrSelect.push(x)
          }
        })
        this.listSelected = [...arrSelect]
      }
    },
    async search() {
      this.isSearch = true
      await this.getDataItems();
    },
    changeCheckBox(item) {
      this.itemSelecting = item
    },
    formUpdate(val) {
      this.keyword = val.keyword
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  color: #1B9C4F;
}

.dialog {
  padding-bottom: 20px;

  .header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }
}

.list-multiple-qualification {
  border-top: 1px solid #ddd;
  max-height: 400px;
  height: 100%;
  overflow-y: auto;
}

.border {
  border-bottom: 1px solid #ddd;
}
</style>
