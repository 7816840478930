<template>
  <ul class="editmenu">
    <li>
      <button icon
        class="editmenu_item"
        @click.stop="color(SVG_COLOR.rectangle_white)"
      >
        <v-icon>mdi-square-outline</v-icon>
      </button>
    </li>
    <li>
      <button icon
        class="editmenu_item"
        @click.stop="color(SVG_COLOR.rectangle_black)"
      >
        <v-icon
        color="black">
          mdi-square
        </v-icon>
      </button>
    </li>
    <li>
      <button icon
        class="editmenu_item"
        @click.stop="color(SVG_COLOR.rectangle_red)"
      >
        <v-icon
        color="red">
          mdi-square
        </v-icon>
      </button>
    </li>
    <li>
      <button
        class="editmenu_item"
        @click.stop="color(SVG_COLOR.rectangle_blue)"
      >
        <v-icon
          color="indigo accent-4">
          mdi-square
        </v-icon>
      </button>
    </li>
    <li>
      <button
        class="editmenu_item"
        @click.stop="color(SVG_COLOR.rectangle_green)"
      >
        <v-icon
          color="light-green accent-3">
          mdi-square
        </v-icon>
      </button>
    </li>
    <li>
      <button class="editmenu_item" @click.stop="color(SVG_COLOR.circle_white)">
        <v-icon>
          mdi-circle-outline
        </v-icon>
      </button>
    </li>
    <li>
      <button class="editmenu_item" @click.stop="color(SVG_COLOR.circle_black)">
        <v-icon color="black">
          mdi-circle
        </v-icon>
      </button>
    </li>
    <li>
      <button class="editmenu_item" @click.stop="color(SVG_COLOR.circle_red)">
        <v-icon color="red">
          mdi-circle
        </v-icon>
      </button>
    </li>
    <li>
      <button class="editmenu_item" @click.stop="color(SVG_COLOR.circle_blue)">
        <v-icon color="indigo accent-4">
          mdi-circle
        </v-icon>
      </button>
    </li>
    <li>
      <button class="editmenu_item" @click.stop="color(SVG_COLOR.circle_green)">
        <v-icon color="light-green accent-3">
          mdi-circle
        </v-icon>
      </button>
    </li>
  </ul>
</template>
<script>
import { SVG_COLOR } from "@/views/oekaki/components/constants/SPRITE_SVG.js";
export default {
  data: () => {
    return {
      SVG_COLOR,
    };
  },
  methods: {
    color(src) {
      this.$emit("svg-color", src);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/views/oekaki/components/assets/scss/editmenu.scss";
</style>
