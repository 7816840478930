<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <!--  (共通) 会社ヘッダー -->
        <SitePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout :layoutParams="layoutParams" :hideFooter="searchParams.pageCount >= searchParams.total_item">
          <template #tableHeader="{ updateHeader }">
            <div>
              <ScheduleHeader
                ref="tableHeader"
                :pageTitle="PAGE_TITLE"
                :multiSelectStatus="disableApproveBtn"
                :updateHeader="updateHeader"
                :searchParams="searchParams"
                :isDirector="isDirector"
                :isShowGraphButton="false"
                @onReject="openConfirmDialog(MODE_ACTION.REJECT)"
                @openRemoveDialog="openConfirmDialog(MODE_ACTION.DELETE)"
                @onApprove="openConfirmDialog(MODE_ACTION.APPROVED)"
                @openItemForm="openNewItemForm"
                @onInput="onChangeSearchParams"
              >
                <v-row>
                  <v-col sm="11" md="11">
                    <SearchFormWrapper>
                      <Label label="工事" width="200px">
                        <!-- Select search sites -->
                        <SearchSites
                          name="field_construction_id"
                          :fieldId="CURRENT_SITE.field_id"
                          @onInput="onChangeSearchParams"
                        />
                      </Label>
                      <!-- Select search CompanyPartner -->
                      <Label label="協力会社" width="200px">
                        <SearchCompanyPartner
                          name="field_tree_id"
                          :fieldId="searchParams.field_construction_id"
                          @onInput="onChangeSearchParams"
                        />
                      </Label>
                      <Label label="種別" width="200px">
                        <span style="cursor: pointer" @click="openFormMachineCompanyType()">
                          <v-row class="cts-select" width="200px">
                            <v-col cols="12" md="10" sm="10" class="cst-machine-type">{{machineType}}</v-col>
                            <v-col cols="12" md="2" sm="2" class="cst-icon"><v-icon color="green">mdi-menu-down</v-icon></v-col>
                          </v-row>
                        </span>
                      </Label>
                      <Label label="機材" width="200px">
                        <Select
                          name="machine_id"
                          :items="MACHINE_ARRAYS_OF_TYPE"
                          :editable="true"
                          item_value="machine_company_field_id"
                          :values="searchParams"
                          @onInput="onChangeSearchParams"
                        />
                      </Label>
                      <TabSelect
                        class="mt-8"
                        name="status_code"
                        :items="SCHDULE_CONTRACT"
                        :editable="true"
                        :values="searchParams"
                        @onInput="onChangeSearchParams"
                      />
                      <TabSelect
                        class="mt-8"
                        name="approval"
                        :items="SCHDULE_APPROVE"
                        :editable="true"
                        :values="searchParams"
                        @onInput="onChangeSearchParams"
                      />
                    </SearchFormWrapper>
                  </v-col>
                  <v-col sm="1" md="1" class="text-right">
                    <!-- <v-row justify="end"> -->
                    <v-btn
                      class="mr-6"
                      depressed
                      color="primary"
                      @click="onSearch()"
                    >
                      検索
                    </v-btn>
                    <!-- </v-row> -->
                  </v-col>
                </v-row>
              </ScheduleHeader>
              <v-container class="tableSortWrapper">
                <v-spacer></v-spacer>
                <div class="sortLabel">作業時間帯:</div>
                <div class="sortElement">
                  <SelectFieldTimeZones
                    name="work_hour_zone"
                    :fieldId="searchParams.field_construction_id"
                    @onInput="onChangeSearchParams"
                  />
                </div>
                <!--時間帯選択 -->
                <div class="sortLabel">総件数:</div>
                <div class="sortElement total_item">
                  {{ searchParams.all_record }}件
                </div>
              </v-container>
            </div>
          </template>

          <!-- 
            (共通)テーブル
            v-data-table自体は共通ですが、
            カラムによって変更をしたい場合はそれぞれ実装してください。
          -->
          <template #tableBody="{ tableHeight }">
            <ScheduleGageTable
              :items="items"
              :tableHeight="tableHeight"
              :functions="functions"
              :flagBanMachine='true'
              :key="reloadList"
              :isUsage="false"
            />
          </template>

          <template #tableFooter>
            <!-- (共通) ページネーション -->
            <Pagination
              :current="searchParams.page_number"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>

    <!--  (ユーザー用) 追加/編集ダイアログ -->
    <Popup :dialog="popups.isShowItemForm">
      <MachineUseForm
        :item="editedItem"
        :isNewItem="isNewItem"
        :isErrorSubmit="isErrorSubmit"
        :isEmitted="isEmitted"
        :beforeUpdateItem="beforeUpdateItem"
        @formUpdate="formUpdate"
        @submit="submitForm"
        @cancel="closeItemForm"
      />
    </Popup>
    <!-- popup approve , reject -->
    <Popup width="480px" :dialog="popups.isShowConfirmDialog">
      <ConfirmRemoveDialog
        :dialogText="dialogText"
        @close="closeRemoveDialog()"
        @ok="confirmOkDialog()"
        @yes="handleConfirm()"
        :warning="dialogText.flg != MODE_ACTION.APPROVED"
      />
    </Popup>
    <Popup :dialog="popups.isShowMachineCompanyType">
      <MachineCompanyTypeForm
        @cancel="closeMachineCompanyForm"
        @changeMachineCompanyType="changeMachineCompanyType"
        :machine_company_field_type_item_tree_id="[]"
        :machine_company_type_tree="MACHINE_COMPANY_FIELD_TYPE_ARRAYS"
      />
    </Popup>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import { TABLES_PER_PAGE } from "@/constants/COMMON"; //絞り込みフォームで使用
import MachineUseForm from "@/components/forms/schedule/machines/MachineUseForm";
import ConfirmRemoveDialog from "./components/ConfirmRemoveDialog.vue";

import SearchSites from "../components/SearchSites.vue";
import SearchCompanyPartner from "../components/SearchCompanyPartner.vue";
/**
 * コンポーネント、関数
 */
import { fmtSlashDate } from "@/utils/timeUtil"; //日付フォーマット変換で使用
import { getRollLabelById } from "@/utils/users/index"; //ロール情報の変換で使用
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import InputDatepicker from "@/components/forms/elements/InputDatepicker"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import TabSelect from "@/components/forms/elements/TabSelect"; //絞り込みフォームで使用

/**
 * 固有のコンポーネント
 */
import ScheduleHeader from "../components/ScheduleHeader.vue";
import SearchLocation from "../components/SearchLocations.vue"; //絞り込みフォームで使用
import SelectFieldTimeZones from "../components/SelectFieldTimeZones.vue"; //絞り込みフォームで使用
import ScheduleTables from "../components/ScheduleTables.vue"; //絞り込みフォームで使用
import ScheduleGageTable from "../components/ScheduleGageTable/index.vue";
import MachineCompanyTypeForm from "@/components/forms/companyMachines/components/MachineCompanyTypeForm.vue";

import {
  SCHDULE_TABLE_LABELS,
  SCHDULE_SORT_ITEMS,
  SCHDULE_CONTRACT,
  SCHDULE_APPROVE,
  SCHEDULE_TABLE_TAB_ITEMS,
  MODE_ACTION,
  MACHINE_USE_INITIAL_ITEM,
  DIALOG_TEXT,
} from "@/constants/SCHEDULE_MACHINE";

import { format } from "date-fns";
import _ from "lodash";
import { HEADER_MENU_ITEMS_SITE } from "@/constants/GLOBALHEADER";
// import { da } from 'date-fns/locale';

/**
 * API
 */
// import { scheduleChildren } from "@/api/modules/scheduleChildren";

/**
 * 定数
 * この一覧ページで使用する固有の定数を定義します。
 * 共通に使用する定数は基本的に@/constants/で定義します。
 * - 定数は大文字で定義します
 * - 定数は基本的にはdataに代入しないで直接参照します
 */

//タイトル
const PAGE_TITLE = "機材予定";

//１ページあたりのテーブル件数
const PAGE_COUNT = 25;

//追加でロードする子の予定の数
// const CHILDREN_PAGE_COUNT = 20;

//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

//ストア
const STORE = "ScheduleMachines";

//テーブルヘッダーラベル
const TABLE_LABELS = SCHDULE_TABLE_LABELS;

//ソート要素
const SORT_ITEMS = SCHDULE_SORT_ITEMS;

//フォーム初期値
const INITIAL_ITEM = MACHINE_USE_INITIAL_ITEM;

const DATE = format(new Date().setDate(new Date().getDate() + 1), "yyyy-MM-dd");

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      PAGE_TITLE,
      TABLE_LABELS,
      SORT_ITEMS,
      PAGE_COUNT_OPTIONS,
      MODE_ACTION,
      DIALOG_TEXT,

      /**
       * (共通)
       * 一覧データ
       */
      items: {},
      reloadList: false,

      /**
       * (共通)
       * checkbox選択item
       */
      selectedItems: [],

      /**
       * (共通)
       * 編集アイテム
       * 定数から初期値を代入
       */
      editedItem: { ...INITIAL_ITEM },

      beforeUpdateItem: {},

      /**
       * (共通)
       * 新規フラグ
       */
      isNewItem: false,

      isDirector: false,

      MACHINE_COMPANY_FIELD_TYPE_ARRAYS: [],

      MACHINE_ARRAYS_OF_TYPE: [{ machine_company_field_id: null, name: "" }],

      machineType : "",

      machine_company_type_item_tree_id: null,

      searchParams: {
        date: DATE,
        field_construction_id: null,
        field_tree_id: null,
        machine_type: null,
        machine_id: null,
        status_code: [],
        approval: [],
        work_hour_zone: null,
        page_number: 1,
        pageCount: PAGE_COUNT,
        all_record: 0,
      },

      /**
       * (共通)
       * ポップアップの状態管理
       */
      popups: {
        // 追加/編集フォーム
        isShowItemForm: false,
        isShowConfirmDialog: false,
        isShowConfirmRemoveDialog: false,
        isShowMachineCompanyType: false,
      },

      /**
       * 絞り込み 協力会社検索パラメータ
       */
      searchCompanyParams: {
        keyword: "",
      },

      /**
       * childテーブルの表示数デフォルト
       */
      childTableDisplayNumber: 3,
      listWorkHourZones: [],

      /**
       * まとめてメソッドを子コンポーネントに渡す
       */
      functions: {
        updateSelectedItems: this.updateSelectedItems,
        openItemForm: this.openItemForm,
        getChildItems: this.getChildItems,
        addNewChild: this.addNewChild,
      },

      /**
       * dialogText
       */
      dialogText: {
        title: "",
        text: [],
        btnSubmitText: "",
        flg: null,
      },
      isErrorSubmit: false,
      isEmitted: false
    };
  },

  components: {
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    SitePortalHeader,
    Popup,
    ConfirmRemoveDialog,
    InputText,
    InputDatepicker,
    Select,
    Label,

    //固有のコンポーネント
    ScheduleHeader,
    TabSelect,
    SearchLocation,
    SelectFieldTimeZones,
    ScheduleTables,
    ScheduleGageTable,
    SearchSites,
    SearchCompanyPartner,
    MachineUseForm,
    MachineCompanyTypeForm,
  },

  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    await Store.dispatch("GlobalHeader/setSiteMenu", {
      menuId: HEADER_MENU_ITEMS_SITE.SCHEDULE.id,
    });

    this.$watch(
      () => Store.getters["PortalChart/getWorkTypes"],
      (data) => {
        if (data.length > 0) {
          if (this.searchParams.field_construction_id) {
            this.getWorkHourZones();
            this.callApiCheckIsDirector();
          }
        }
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Employees/getIsDirector"],
      (data) => {
        this.isDirector = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getListTimeZones"],
      (data) => {
        if (data && data.length > 0) {
          this.listWorkHourZones = [...data];
          this.searchParams.work_hour_zone = data[0].id;
          /**
           * (共通)
           * ここからapiにリクエストします
           */
          this.getItems();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        /**
         *  (共通)
         * 一覧リストの格納
         */
        this.items = data[0];

        /**
         *  (共通)
         * ページネーション更新
         */
        let searchParams = { ...this.searchParams };
        if (data[0].date && data[0].time_frame_id) {
          searchParams.date = data[0].date;
          searchParams.work_hour_zone = data[0].time_frame_id;
        }
        searchParams.totalPage = data[1].total;
        searchParams.page_number = data[1].current;
        searchParams.total_item = data[1].total_item;
        searchParams.all_record = data[1].all_record;
        this.searchParams = searchParams;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters[`CompanyMachines/getMachineCompanyType`],
      (data) => {
        if (data) {
          this.MACHINE_COMPANY_FIELD_TYPE_ARRAYS = [...data];
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => Store.getters[`CompanyMachines/getMachineCompanyByType`],
      (data) => {
        if (data) {
          let defaultArray = {machine_company_field_id: null , name: ""};
          this.MACHINE_ARRAYS_OF_TYPE = [defaultArray,...data];
        }
      },
      {
        deep: true
      }
    );
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */
  computed: {
    /**
     * 承認・承認解除ボタンの活性・非活性
     * selectedItems.length === 0
     * 自体はtemplate側で記述できますが、
     * ここに明示的に状態を定義して可読性を担保してます
     */
    disableApproveBtn() {
      return this.selectedItems.length === 0;
    },

    /**
     * (スケジュール)
     * 現在の現場を取得
     */
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },

    /**
     * (スケジュール)
     * 絞り込み:契約項目
     */
    SCHDULE_CONTRACT() {
      return Object.keys(SCHDULE_CONTRACT).map((key) => {
        return SCHDULE_CONTRACT[key];
      });
    },

    /**
     *  (スケジュール)
     * 絞り込み:承認/未承認
     */
    SCHDULE_APPROVE() {
      return Object.keys(SCHDULE_APPROVE).map((key) => {
        return SCHDULE_APPROVE[key];
      });
    },

    /**
     *  (スケジュール)
     * テーブルタブ
     */
    SCHEDULE_TABLE_TAB_ITEMS() {
      return Object.keys(SCHEDULE_TABLE_TAB_ITEMS).map((key) => {
        return SCHEDULE_TABLE_TAB_ITEMS[key];
      });
    },
  },

  methods: {
    async callApiCheckIsDirector() {
      const params = {
        user_id: JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login
          .company_user.user_id,
        field_construction_id: this.searchParams.field_construction_id,
      };
      await Store.dispatch("Employees/checkIsDirector", { params });
    },

    onSearch() {
      this.searchParams.page_number = 1;
      this.getItems();
    },

    /**
     * 1: 承認解除
     * 2: 承認
     * 3: Delete
     */
    openConfirmDialog(flg) {
      if (flg == MODE_ACTION.DELETE) {
        const rs = this.selectedItems.filter(
          (item) => item.schedule_approval == SCHDULE_APPROVE.REJECT.id
        );
        this.dialogText.title = this.DIALOG_TEXT.DELETE_TITLE;
        if (rs.length == 0) {
          this.dialogText.text[0] = this.DIALOG_TEXT.DELETE_ERROR_TEXT1;
          this.dialogText.text[1] = this.DIALOG_TEXT.DELETE_ERROR_TEXT2;
        } else {
          this.dialogText.text[0] = this.DIALOG_TEXT.DELETE_TEXT1;
          this.dialogText.text[1] = this.DIALOG_TEXT.DELETE_TEXT2;
          this.dialogText.text[2] = this.DIALOG_TEXT.DELETE_TEXT3;
          this.dialogText.text[3] = this.DIALOG_TEXT.DELETE_TEXT4;
          this.dialogText.flg = flg;
          this.dialogText.btnSubmitText = this.DIALOG_TEXT.BTN_DELETE;
        }
      } else {
        let schedule_approve =
          flg == MODE_ACTION.APPROVED
            ? SCHDULE_APPROVE.REJECT.id
            : SCHDULE_APPROVE.APPROVED.id;
        const rs = this.selectedItems.filter(
          (item) => item.schedule_approval == schedule_approve
        );
        if (rs.length == 0) {
          this.dialogText.title =
            flg == MODE_ACTION.REJECT
              ? this.DIALOG_TEXT.REJECT_TITLE
              : this.DIALOG_TEXT.APPROVE_TITLE;
          this.dialogText.text[0] =
            flg == MODE_ACTION.REJECT
              ? this.DIALOG_TEXT.REJECT_ERROR_TEXT1
              : this.DIALOG_TEXT.DELETE_ERROR_TEXT1;
        } else {
          this.dialogText.title =
            flg == MODE_ACTION.REJECT
              ? this.DIALOG_TEXT.REJECT_TITLE
              : this.DIALOG_TEXT.APPROVE_TITLE;
          this.dialogText.text[0] =
            flg == MODE_ACTION.REJECT
              ? this.DIALOG_TEXT.REJECT_TEXT1
              : this.DIALOG_TEXT.APPROVE_TEXT1;
          this.dialogText.text[1] =
            flg == MODE_ACTION.REJECT
              ? this.DIALOG_TEXT.REJECT_TEXT2
              : this.DIALOG_TEXT.APPROVE_TEXT2;
          this.dialogText.flg = flg;
          this.dialogText.btnSubmitText =
            flg == MODE_ACTION.REJECT
              ? this.DIALOG_TEXT.BTN_REJECT
              : this.DIALOG_TEXT.BTN_APPROVE;
        }
      }
      this.popups.isShowConfirmDialog = true;
    },

    /**
     * handleConfirm
     */
    handleConfirm() {
      if (this.dialogText.flg == MODE_ACTION.REJECT) {
        this.rejectItems();
      } else if (this.dialogText.flg == MODE_ACTION.APPROVED) {
        this.approveItems();
      } else {
        this.removeItems();
      }
    },

    /**
     * closeRemoveDialog
     */
    closeRemoveDialog() {
      this.popups.isShowConfirmDialog = false;
      this.selectedItems = [];
      this.reloadList = !this.reloadList;
      this.dialogText = {
        title: "",
        text: [],
        flg: "",
        btnSubmitText: "",
      };
    },

    confirmOkDialog() {
      this.popups.isShowConfirmDialog = false;
      this.reloadList = !this.reloadList;
      this.selectedItems = [];
      this.$nextTick(() => {
        this.dialogText = {
          title: "",
          text: [],
          flg: "",
          btnSubmitText: "",
        };
      });
    },

    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.page_number = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    formUpdate(params) {
      this.editedItem = { ...params };
    },

    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      if (name == "field_construction_id") {
        searchParams[name] = value;
        this.searchParams = searchParams;
        this.getWorkHourZones();
      }
      if (name == "date") {
        searchParams[name] = format(new Date(value), "yyyy-MM-dd");
        this.searchParams = searchParams;
        this.searchParams["page_number"] = 1;
        this.getItems();
      }
      if (name == "work_hour_zone") {
        searchParams[name] = value;
        this.searchParams["page_number"] = 1;
        this.searchParams = searchParams;
        this.getItems();
      }
      searchParams[name] = value;
      this.searchParams = searchParams;
    },

    /**
     * (共通)
     * 作成/編集フォーム
     * INITIAL_ITEM で定義した値がitemに入ります
     * @param INITIAL_ITEMで定義している値
     */
    openNewItemForm() {
      let initial_item = { ...INITIAL_ITEM };
      initial_item.use_date_from = this.searchParams.date;
      initial_item.use_date_to = this.searchParams.date;
      initial_item.field_construction_id =
        this.searchParams.field_construction_id;
      initial_item.work_hour_zone = this.searchParams.work_hour_zone;
      this.editedItem = _.cloneDeep(initial_item);
      this.beforeUpdateItem = _.cloneDeep(initial_item);
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },

    async openItemForm(id) {
      const machinePassageInfor = await Store.dispatch(
        `${STORE}/getDetail`,
        id
      );
      // Show Detail Form
      if (!machinePassageInfor.hasError) {
        this.editedItem = _.cloneDeep(
          machinePassageInfor.data.contents.entries
        );
        let _editedItem = { ...this.editedItem };
        this.editedItem = { ..._editedItem };
        this.beforeUpdateItem = _.cloneDeep(
          machinePassageInfor.data.contents.entries
        );
        this.isNewItem = false;
        this.popups.isShowItemForm = true;
      }
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.$nextTick(() => {
        //選択を初期化
        this.editedItem = { ...INITIAL_ITEM };
      });
    },

    /**
     * 親ステートの子テーブルを更新
     */
    // add max 5 record
    async getChildItems(deviceId, pageNumber) {
      const params = {
        use_date: this.searchParams.date,
        field_construction_id: this.searchParams.field_construction_id,
        field_tree_id: this.searchParams.field_tree_id,
        machine_id: deviceId,
        status_codes: this.searchParams.status_code,
        approvals: this.searchParams.approval,
        work_hour_zone: this.searchParams.work_hour_zone,
        page_number: pageNumber,
      };
      await Store.dispatch(`${STORE}/loadChildren`, { params: params });
    },

    async getWorkHourZones() {
      if (this.searchParams.field_construction_id) {
        Store.dispatch(
          "PortalChart/getFieldTimeZones",
          this.searchParams.field_construction_id
        );
      }
    },
    /**
     *  (共通)
     * ストア / api
     * ストア実装ルールにしたがっている場合は、
     */

    // データ取得
    async getItems() {
      if (
        this.searchParams.field_construction_id &&
        this.searchParams.work_hour_zone &&
        this.searchParams.date
      ) {
        let apiParams = {
          use_date: this.searchParams.date,
          field_construction_id: this.searchParams.field_construction_id,
          field_tree_id: this.searchParams.field_tree_id,
          machine_type: this.machine_company_type_item_tree_id,
          machine_id: this.searchParams.machine_id,
          status_codes: this.searchParams.status_code,
          approvals: this.searchParams.approval,
          work_hour_zone: this.searchParams.work_hour_zone,
          page_number: this.searchParams.page_number,
        };
        this.selectedItems = [];
        await Store.dispatch(`${STORE}/get`, { params: apiParams });
      }
    },

    // 承認
    async approveItems() {
      const approves = this.selectedItems.filter(
        (item) => item.schedule_approval == SCHDULE_APPROVE.REJECT.id
      );
      const ids = approves.map((items) => items.id);
      let params = {
        ids: ids,
        field_construction_id: this.searchParams.field_construction_id,
      };
      const result = await Store.dispatch(`${STORE}/approve`, params);

      if (!result.hasError) {
        this.closeRemoveDialog();
        //成功したら値を更新
        this.getItems();
        Store.dispatch("Notification/getNewNoticeStatus");
        Store.dispatch("Toast/show", {
          status: 200,
          message: "承認しました",
        });
      }
    },

    // 承認解除
    async rejectItems() {
      const disapproves = this.selectedItems.filter(
        (item) => item.schedule_approval == SCHDULE_APPROVE.APPROVED.id
      );
      const ids = disapproves.map((items) => items.id);
      let params = {
        ids: ids,
        field_construction_id: this.searchParams.field_construction_id,
      };
      const result = await Store.dispatch(`${STORE}/disapprove`, params);

      if (!result.hasError) {
        this.closeRemoveDialog();
        //成功したら値を更新
        this.getItems();
        Store.dispatch("Notification/getNewNoticeStatus");
        Store.dispatch("Toast/show", {
          status: 200,
          message: "承認解除しました",
        });
      }
    },
    /**
     * (共通)
     * 新規登録 / 更新
     * - idがある場合は更新
     */
    async submitForm() {
      let editedItem = { ...this.editedItem };
      const hasId = "id" in this.editedItem;
      const result = await Store.dispatch(
        hasId ? `${STORE}/edit` : `${STORE}/add`,
        editedItem
      );
      if (result.hasError) {
        this.isErrorSubmit = true;
        return;
      } else if (hasId) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        const resultDetail = await Store.dispatch(
          `${STORE}/getDetail`,
          this.editedItem.id
        );
        this.editedItem = _.cloneDeep(resultDetail.data.contents.entries);
        this.isErrorSubmit = false;
      } else {
        //成功したら値を更新
        this.closeItemForm();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
      this.getItems();
      this.isEmitted = !this.isEmitted;
    },

    /**
     *  (ユーザー用)
     * ユーザ権限名文字列取得
     * @param id:Number
     */
    getRollLabel(id) {
      return getRollLabelById(id);
    },

    /**
     *  (ユーザー用)
     * 日付変換
     * @param date:yyyy-mm-ddThh:mm:ss:z
     */
    getFormattedDate(date) {
      return fmtSlashDate(date);
    },

    /**
     * checkboxの値からselectedItemsを更新
     */
    updateSelectedItems(list) {
      list.forEach((item) => {
        this.updateSelectedItem(item);
      });
    },
    updateSelectedItem({ id, isChecked, schedule_approval }) {
      let _selectedItems = [...this.selectedItems];
      // checked
      if (isChecked) {
        const isNew = this.selectedItems.some((item) => item.id == id);
        if (!isNew)
          _selectedItems = [..._selectedItems, { id, schedule_approval }];
      } else {
        // unchecked
        _selectedItems = this.selectedItems.filter((item) => {
          return item.id != id;
        });
      }
      this.selectedItems = [...new Set(_selectedItems)];
    },

    // checkboxの ON/OFFステータス
    isSelected(id) {
      return this.selectedItems.includes(id);
    },

    // 親にchild table追加
    addNewChild(parentId) {
      // 新規追加itemに parent idをセット
      this.editedItem.schedule_parent_id = parentId;
      this.openNewItemForm();
    },

    // 削除
    async removeItems() {
      const approves = this.selectedItems.filter(
        (item) => item.schedule_approval == SCHDULE_APPROVE.REJECT.id
      );
      const ids = approves.map((items) => items.id);
      const result = await Store.dispatch(`${STORE}/delete`, { ids });

      if (!result.hasError) {
        this.searchParams.page_number = 1;
        //ダイアログ閉じる
        this.closeRemoveDialog();
        //成功したら値を更新
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
      }
    },

    closeMachineCompanyForm(){
      this.popups.isShowMachineCompanyType = false;
    },
    changeMachineCompanyType(value) {
      this.popups.isShowMachineCompanyType = false;
      this.machineType = this.getMachineCompanyFieldType(value);
      this.machine_company_type_item_tree_id = value.length > 0 ? value[value.length - 1] : null;
      if(this.machine_company_type_item_tree_id){
        this.getMachineCompanyByType();
      }
      this.searchParams.machine_id = null;
      this.MACHINE_ARRAYS_OF_TYPE = [{ machine_company_field_id: null, name: "" }];
    },
    getMachineCompanyFieldType(ids) {
      let arr = _.cloneDeep(ids);
      let machine_type_array = _.cloneDeep(this.MACHINE_COMPANY_FIELD_TYPE_ARRAYS);
      let rs = "";
      if (arr.length > 0 && machine_type_array.length > 0) {
        arr.forEach((e, index) => {
          let a1 = machine_type_array.find(e1 => e1.id == e);
          rs = index == 0 ? rs + a1.type : rs + "/" + a1.type;
          machine_type_array = a1.childrens;
        });
      } else {
        rs = "";
      }
      return rs;
    },

    async getMachineCompanyByType() {
      const params = {
        field_id: this.CURRENT_SITE.field_id,
        machine_company_type_item_tree_id: this.machine_company_type_item_tree_id
      }
      await Store.dispatch(`CompanyMachines/getMachineCompanyByType`, params);
    },

    async openFormMachineCompanyType() {
      await Store.dispatch(`CompanyMachines/getMachineCompanyType`, {
        company_id: this.CURRENT_SITE.company_id,
        company_branch_id: this.CURRENT_SITE.company_branch_id ? this.CURRENT_SITE.company_branch_id : ''
      });
      this.popups.isShowMachineCompanyType = true;
    },
  },
  beforeDestroy() {
    Store.dispatch(`${STORE}/resetData`)
  }
};
</script>

<style lang="scss" scoped>
.tableSortWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 64px;
  .sortLabel {
    font-size: 12px;
    height: 32px;
    margin-right: 16px;
  }
  .sortElement {
    max-width: 168px;
    margin-right: 24px;
  }
  .total_item {
    padding-bottom: 15px;
  }
  .button_search {
    margin-right: 28px;
  }
}
.cts-select {
  padding-right: 3px;
  margin-top: 0px;
  background-color: #f0f0f0;
  border-bottom: 1px solid;
  border-color: #4caf50;
  height: 41px;
  margin-left: 3px;
  margin-right: 3px;
}
.cst-machine-type{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0px;
}
.cst-icon {
  padding-right: 3px !important;
  padding: 0;
  padding-top: 9px;
}
</style>
