<template>
  <v-card class="dialog">
    <v-card-title>
      <div :style="getTitleColorStyle" class="title">{{ title }}</div>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div class="text left-text-confirm">{{ text1 }}</div>
      </div>
    </v-card-text>
    <v-card-text v-if="text2" class="body">
      <div class="text-wrap">
        <div class="text left-text-confirm">{{ text2 }}</div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        :color="getColor"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn
        v-if="!this.isStopUser"
        :disabled="disabled"
        depressed
        class="btn"
        color="warning"
        @click="handleConfirm(0)"
      >
        停止
      </v-btn>
      <v-btn
        v-else
        depressed
        class="btn"
        :disabled="disabled"
        color="primary"
        @click="handleConfirm(1)"
      >
        再開
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON";
export default {
  data() {
    return {
      disabled: false,
      timeout: null,
    };
  },
  components: {},
  props: {
    text1: {
      type: String,
    },
    text2: {
      type: String,
    },
    title: {
      type: String,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    isStopUser: {
      type: Boolean,
    },
  },
  computed: {
    getTitleColorStyle() {
      return this.warning ? "color:#FF7B52" : "color:#1B9C4F";
    },
    getColor() {
      return this.warning ? "warning" : "primary";
    },
  },
    methods: {
      handleConfirm(type) {
        this.disabled = true;
        // Re-enable after submit
        this.timeout = setTimeout(() => {
          this.disabled = false;
        }, SUBMIT_DELAY_TIMEOUT);
        if (type == 0) {
          this.$emit('yes', 0);
        } else {
          this.$emit('yes', 1);
        }
      },
    },
  /**
   * Important: clear timeout
   */
  beforeDestroy() {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout);
  },
};
</script>