<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters>
                    <v-col>
                      <FormDangerousWorkResult
                        :name="USER_TYPE.TYPE_4"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters>
                    <v-col>
                      <FormPersonCompletedResult
                        :name="USER_TYPE.TYPE_5"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import FormDangerousWorkResult from "./components/FormDangerousWorkResult.vue";
import FormPersonCompletedResult from "./components/FormPersonCompletedResult.vue";
import { USER_TYPE } from "@/constants/SCHEDULE_COMPANY";

export default {
  data: () => {
    return {
      formValues: {},
      USER_TYPE,
    };
  },
  components: {
    FormDangerousWorkResult,
    FormPersonCompletedResult,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      if (name == USER_TYPE.TYPE_4 || name == USER_TYPE.TYPE_5) {
        formValues["work_company_user_results"][name] = value;
      } else {
        formValues[name] = value;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
</style>
