<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row v-if="editable && isShowUsageTime">
          <v-col cols="12" sm="5" md="5">
            <v-list-item>
              <v-list-item-content>
                <Label label="開始時間" :editable="editable" :required="isRequiredUsageTime || (!item.usage_start_time && item.usage_end_time)">
                  <Select
                    name="usage_start_time"
                    :values="item"
                    :items="cmn_mst_time_start"
                    :editable="editable"
                    :validation_label="`開始時間 [${index}]`"
                    :validation_rules="getStartTimeRules(item)"
                    autoCenter
                    @onInput="onInput({ index, value: $event })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="5" md="5"> 
            <v-list-item>
              <v-list-item-content>
                <Label label="終了時間" :editable="editable" :required="isRequiredUsageTime || (item.usage_start_time && !item.usage_end_time)">
                  <Select
                    name="usage_end_time"
                    :values="item"
                    :items="cmn_mst_time_end"
                    :editable="editable"
                    :validation_label="`終了時間 [${index}]`"
                    :validation_rules="getEndTimeRules(item)"
                    autoCenter
                    @onInput="onInput({ index, value: $event })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col v-if="index > 0" cols="12" sm="2" md="2" class="pt-0 pb-0">
            <v-btn class="mt-8" icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="!editable && isShowUsageTime">
          <v-col cols="12" sm="10" md="10">
            <v-list-item>
              <v-list-item-content>
                <Label label="時間" :editable="editable">
                  <InputText
                    name="usage_times"
                    :values="getUsageTimes(item)"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col
            v-if="index > 0 && editable"
            cols="12"
            sm="2"
            md="2"
            class="pt-0 pb-0"
          >
            <v-btn class="mt-8" icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn v-if="editable && isShowUsageTime" class="ml-4" color="primary" @click="addEmpty" depressed small>追加</v-btn>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "@/components/forms/elements/Label";
import Select from "@/components/forms/elements/Select";
import InputText from "@/components/forms/elements/InputText";
import { PROHIBIT_TIME_START, PROHIBIT_TIME_END } from "@/constants/SCHEDULE_GATE";
import { cmn_mst_time_start, cmn_mst_time_end } from "@/constants/CRANES";
import { addMinutesToTime } from "@/constants/COMMON";
//初期値を設定します
const INITIAL_VALUES = {
  usage_start_time: null,
  usage_end_time: null,
};

export default {
  data: () => {
    return {
      cmn_mst_time_start,
      cmn_mst_time_end,
      items: [],
      PROHIBIT_TIME_START,
      PROHIBIT_TIME_END,
      startTimes: [],
      endTimes: [],
    };
  },
  components: {
    Label,
    Select,
    InputText,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    timeZone: {
      type: Object
    },
    isShowUsageTime: {
      type: Boolean,
      default: true
    },
    isRequiredUsageTime: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    this.$watch(
      () => this.timeZone,
      (data) => {
        this.timeZone = data;
        this.getData();
        this.startTimes.unshift({ id: null, name: "" });
        this.endTimes.unshift({ id: null, name: "" });
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (this.items?.length == 0) {
          this.addEmpty();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      if(value.name == "usage_start_time") {
        this.items[index].usage_end_time = addMinutesToTime(value.value);
      }
      // 必ずコピーして変更をします
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      this.items.push({ ...INITIAL_VALUES });
    },
    getUsageTimes(item) {
      let usageTimes = {
        usage_times: null,
      };
      if (!this.editable) {
        if (item.usage_start_time && item.usage_end_time) {
          let usage_start_time = this.cmn_mst_time_start.find(element => element.id == item.usage_start_time).name;
          let usage_end_time = this.cmn_mst_time_end.find(element => element.id == item.usage_end_time).name;
          usageTimes.usage_times = `${usage_start_time}～${usage_end_time}`;
        } else {
          usageTimes.usage_times = "";
        }
      }
      return usageTimes;
    },
    getData() {
      let arrayStart = this.PROHIBIT_TIME_START.filter((item) => {
        return item.id >= this.timeZone.start_time && item.id < this.timeZone.end_time;
      })
      this.startTimes = arrayStart;
      let arrayEnd = this.PROHIBIT_TIME_END.filter((item) => {
        return item.id > this.timeZone.start_time && item.id <= this.timeZone.end_time;
      })
      this.endTimes = arrayEnd;
    },
    getStartTimeRules(item) {
      let rule = item.usage_end_time || this.isRequiredUsageTime ? "required-usage-start-time" : "";
      if(this.items.length > 1 && item.usage_start_time && item.usage_end_time) {
        const isNotDuplicate = this.getCountOfItem(item)== 1 ? 1 : 0;
        rule = rule + `|duplicate-time:${isNotDuplicate}`;
      }
      return rule;
    },
    getEndTimeRules(item) {
      let rule = item.usage_start_time || this.isRequiredUsageTime ? "required-usage-end-time" : "";
      if(this.items.length > 1 && item.usage_start_time && item.usage_end_time) {
        const isNotDuplicate = this.getCountOfItem(item)== 1 ? 1 : 0;
        rule = rule + `|duplicate-time:${isNotDuplicate}`;
      }
      return rule;
    },
    getCountOfItem(item) {
      let index = 0;
      this.items.forEach(element => {
        if(item.usage_start_time == element.usage_start_time && item.usage_end_time == element.usage_end_time) {
          index++;
        }
      })
      return index;
    }
  },
};
</script>