<template>
  <div class="FormMain">
    <div class="FormMainBody">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row style="align-items: center">
                <Label label="種別" style="width: 15vw">
                  <span @click="openFormMachineCompanyType" style="cursor: pointer">
                  <v-text-field
                    v-model="searchParams.type"
                    dense
                    readonly
                    filled
                    color="primary"
                    append-icon="mdi-menu-down"
                  ></v-text-field>
                  </span>
                </Label>
                <Label label="機材" style="width: 10vw">
                  <InputText
                    name="machine_company_name"
                    :values="searchParams"
                    :editable="true"
                    @onInput="onChangeSearchParam"
                  />
                </Label>
                <v-btn depressed color="primary" @click="onSearch" class="mt-2"> 検索</v-btn>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="6" md="6">
                  <div class="header-title">
                    <div></div>
                    <div class="title">機材</div>
                    <v-btn color="primary" @click="register" :disabled="disabledRegister">登録</v-btn>
                  </div>
                  <RelateTable
                    class="mt-5"
                    :tableLabels="RELATE_MACHINE_FORM_LABELS_TABLE"
                    :tableHeight="mainHeight"
                    :items="itemsLeft"
                    type_id="id"
                    :isSelected="isSelected"
                    :flag="flagLeft"
                    :flagSearch="flagSearch"
                    :checkSelectedParents="checkSelectedParents"
                    @update="updateSelectedItems"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <div class="header-title">
                    <div></div>
                    <div class="title">現場機材</div>
                    <v-btn color="primary" @click="remove" :disabled="disabledRemove">解除</v-btn>
                  </div>
                  <RelateTable
                    class="mt-5"
                    :tableLabels="RELATE_MACHINE_FORM_LABELS_TABLE"
                    :tableHeight="mainHeight"
                    :items="itemsRight"
                    type_id="machine_company_id"
                    :isSelected="isSelected"
                    :checkSelectedParents="checkSelectedParents"
                    :flag="flagRight"
                    @update="updateSelectedItems"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
      <Popup v-if="isShowMachineCompanyType" :dialog="isShowMachineCompanyType">
        <MachineCompanyTypeForm
          @cancel="closeMachineCompanyTypeForm"
          @changeMachineCompanyType="changeMachineCompanyType"
          :machine_company_field_type_item_tree_id="[]"
          :machine_company_type_tree="MACHINE_COMPANY_FIELD_TYPE_ARRAYS"
        />
      </Popup>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import FormDialog from "@/components/dialog/FormDialog.vue";
import RelateTable from "./RelateTable";
import Label from "@/components/forms/elements/Label";
import Select from "@/components/forms/elements/Select";
import _ from "lodash";
import InputText from "@/components/forms/elements/InputText";
import Popup from "@/components/common/Popup.vue";
import MachineCompanyTypeForm from "@/components/forms/companyMachines/components/MachineCompanyTypeForm.vue";
import { RELATE_MACHINE_FORM_LABELS_TABLE } from "@/constants/MACHINE_FIELD";

export default {
  data() {
    return {
      RELATE_MACHINE_FORM_LABELS_TABLE,
      MACHINE_COMPANY_FIELD_TYPE_ARRAYS: [],
      isShowMachineCompanyType: false,
      flagLeft: false,
      flagRight: false,
      leftSelectedItems: [],
      rightSelectedItems: [],
      machine_companies: [],
      machine_company_fields: [],
      machine_companies_before_search: [],
      searchParams: {
        machine_company_type_item_tree_id: [],
        machine_company_name: null,
        type: "",
      },
      field_id: null,
      flagSearch: false
    };
  },
  components: {
    MachineCompanyTypeForm,
    InputText,
    RelateTable,
    FormDialog,
    Select,
    Label,
    Popup
  },
  props: {
    mainHeight: {
      type: Number,
      default: 0
    }
  },
  async mounted() {
    // get data machine_company and machine_company_field
    await this.getListMachineField();
    this.$watch(
      () => Store.getters[`MachineField/getListMachineField`],
      (newVal, oldVal) => {
        if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
          this.initData(newVal);

        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      (data, oldData) => {
        if (data && JSON.stringify(data) !== JSON.stringify(oldData) && data.field_id) {
          this.field_id = data.field_id;
          this.getListMachineField();
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => Store.getters[`CompanyMachines/getMachineCompanyType`],
      (data) => {
        if (data) {
          this.MACHINE_COMPANY_FIELD_TYPE_ARRAYS = [...data];
        }
      },
      {
        immediate : true,
        deep: true
      }
    );
  },
  computed: {
    disabledRegister() {
      return this.leftSelectedItems.length == 0;
    },
    disabledRemove() {
      return this.rightSelectedItems.length == 0;
    },
    apiParams() {
      return {
        machine_company_type_item_tree_id: this.searchParams.machine_company_type_item_tree_id,
        machine_company_name: this.searchParams.machine_company_name
      };
    },
    itemsLeft() {
      return this.machine_companies;
    },
    itemsRight() {
      let rs = _.cloneDeep(this.machine_company_fields);
      rs.forEach(element => {
        element.childrens = element.childrens.filter(child => child?.deleted_flag != 1);
      });
      rs = rs.filter(e => e.childrens.length > 0);
      return rs;
    },
    COMPANY_USER() {
      return JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user;
    },
  },
  methods: {
    /**
     * init data
     * @param data :  list machine field
     */
    initData(data){
      // set data machine_companies and machine_company_fields)
      let _machine_companies = _.cloneDeep(data?.machine_companies) || [];
      let _machine_company_fields = _.cloneDeep(data?.machine_company_fields) || [];
      // add index to machine company and machine company_field
      this.machine_companies = this.updateIndexToList(_machine_companies, );
      this.machine_company_fields = this.updateIndexToList(_machine_company_fields);
      this.machine_companies_before_search = [...this.machine_companies];
      this.$emit("updateMachineCompanyFields", this.machine_company_fields);
    },
    /**
     * get list machine field with param
     */
    async getListMachineField() {
      if (this.field_id) {
        let params = { field_id: this.field_id };
        await Store.dispatch(`MachineField/getListMachineField`, { params });
      }
    },
    /**
     * change machine company type in box search
     * @param value : return machine company type form
     */
    changeMachineCompanyType(value) {
      this.isShowMachineCompanyType = false;
      const _searchParams = { ...this.searchParams };
      if (value.length > 0) {
        let machine_type_array = _.cloneDeep(this.MACHINE_COMPANY_FIELD_TYPE_ARRAYS);
        let rs = "";
        value.forEach((e, index) => {
          let a1 = machine_type_array.find(e1 => e1.id == e);
          rs = index == 0 ? rs + a1.type : rs + "/" + a1.type;
          machine_type_array = a1.childrens;
        });
        _searchParams.machine_company_type_item_tree_id = this.getDataSearchType(machine_type_array,value);
        _searchParams.type = rs;
      }else{
        _searchParams.machine_company_type_item_tree_id = [];
        _searchParams.type = "";
      }
      this.searchParams = { ..._searchParams };
    },
    getDataSearchType(machineTypeArray,arr){
      let temp = [arr[arr.length - 1]];
      const addKey = (value) => {
        value.forEach((item) => {
          temp.push(item.id)
          if (item.childrens && item.childrens.length > 0) {
            addKey(item.childrens);
          }
        });
      };
      addKey(machineTypeArray);
      return temp;
    },
    /**
     * close machine company type form
     */
    closeMachineCompanyTypeForm() {
      this.isShowMachineCompanyType = false;
    },
    /**
     * add index to childrens in items ( childrens can same id )
     * @param items : items
     * @returns items with index childrens
     */
    updateIndexToList(items) {
      let _items = _.cloneDeep(items);
      _items.forEach(e => {
        e.childrens.forEach((e1, index) => {
          e1["index"] = index;
        });
      });
     return _items;
    },

    /**
     * check selected item have item checked
     * @param field_tree_id : machine_company_type_item_tree_id parents
     * @param id : id item checked
     * @param type : machine_company_id or id
     * @param index : index item checked
     * @returns true : have , false : don't have
     */
    isSelected(field_tree_id, id, type, index) {
      if (type == "id") {
        return Boolean(this.leftSelectedItems.find(element => element.machine_company_type_item_tree_id == field_tree_id && element.id == id && element.index == index));
      } else {
        return Boolean(this.rightSelectedItems.find(element => element.machine_company_type_item_tree_id == field_tree_id && element.machine_company_id == id && element.index == index));
      }
    },

    /**
     * check selected parents
     * @param itemParent : item parents
     * @param type : machine_comapny_id or id
     * @returns true : checked , false : unchecked
     */
    checkSelectedParents(itemParent, type) {
      for (let i = 0; i < itemParent.childrens.length; i++) {
        if (!this.isSelected(itemParent.machine_company_type_item_tree_id, itemParent.childrens[i][type], type, itemParent.childrens[i].index)) {
          return false;
        }
      }
      return true;
    },

    /**
     * add selected items to list selected
     * type_id : 'id' add to left or 'machine_company_id' add to right
     */
    updateSelectedItems(fieldTreeId, typeParent, isChecked, type_id, child) {
      let _selectedItems = type_id == "id" ? [...this.leftSelectedItems] : [...this.rightSelectedItems];
      let _itemChecked =
        {
          "machine_company_type_item_tree_id": fieldTreeId,
          "machine_company_field_id": child.machine_company_field_id ? child.machine_company_field_id : null,
          "index": child.index,
          "name": child.name,
          "machine_number": child.machine_number,
          "type": typeParent
        };
      if (type_id == "id") {
        _itemChecked["id"] = child[type_id];
      } else {
        _itemChecked["machine_company_id"] = child[type_id];
      }
      let rs = [];
      if (isChecked && !this.isSelected(fieldTreeId, child[type_id], type_id, child.index)) {
        rs = [..._selectedItems, _itemChecked];
      } else if (isChecked && this.isSelected(fieldTreeId, child[type_id], type_id, child.index)) {
        rs = [..._selectedItems];
      } else {
        rs = _selectedItems.filter((item) => {
          return item[type_id] != child[type_id] || item.machine_company_type_item_tree_id != fieldTreeId || item.index != child.index;
        });
      }
      if (type_id == "id") {
        this.leftSelectedItems = [...new Set(rs)];
      } else {
        this.rightSelectedItems = [...new Set(rs)];
      }
    },
    /**
     * add left to right
     */
    register() {
      this.flagSearch = false;
      // add to right
      this.addItemMachine(this.leftSelectedItems, true);
      // remove to left
      this.removeItemMachine(this.leftSelectedItems, true);
      this.leftSelectedItems = [];
      this.flagLeft = !this.flagLeft;
      this.machine_companies_before_search = [...this.machine_companies];
      this.$emit("updateMachineCompanyFields", this.machine_company_fields);
    },
    /**
     * remove right to left
     */
    remove() {
      // add to left
      this.addItemMachine(this.rightSelectedItems, false);
      // remove to right
      this.removeItemMachine(this.rightSelectedItems, false);
      this.rightSelectedItems = [];
      this.flagRight = !this.flagRight;
      this.machine_companies_before_search = [...this.machine_companies];
      this.$emit("updateMachineCompanyFields", this.machine_company_fields);
    },
    /**
     * add item to list
     * @param selectedItems : selected items
     * @param flag : true ( add to right ) , false ( add to left )
     */
    addItemMachine(selectedItems, flag) {
      let _selecteds = _.cloneDeep(selectedItems);
      let allItems = flag ? _.cloneDeep(this.machine_company_fields) : _.cloneDeep(this.machine_companies);
      _selecteds.forEach(selected => {
        let temp = true;
        if (allItems.find(element => element.machine_company_type_item_tree_id == selected.machine_company_type_item_tree_id)) {
          allItems.forEach(item => {
            if (item.machine_company_type_item_tree_id == selected.machine_company_type_item_tree_id) {
              if (!flag) {
                item.childrens.push(this.getChildPush(item.childrens[item.childrens.length - 1].index + 1, selected, true));
              } else {
                item.childrens = item.childrens.filter(child => child.machine_company_id != selected.id || child.deleted_flag != 1);
                if (item.childrens.length > 0) {
                  item.childrens.push(this.getChildPush(item.childrens[item.childrens.length - 1].index + 1, selected, false));
                } else {
                  temp = false;
                }
              }
            }
          });
          if (!temp) {
            allItems = allItems.filter(element => element.childrens.length > 0);
            allItems.push({
              machine_company_type_item_tree_id: selected.machine_company_type_item_tree_id,
              type: selected.type,
              childrens: [
                this.getChildPush(0, selected, false)
              ]
            });
          }
        } else {
          let temp = flag ? {
            machine_company_type_item_tree_id: selected.machine_company_type_item_tree_id,
            type: selected.type,
            childrens: [
              this.getChildPush(0, selected, false)
            ]
          } : {
            machine_company_type_item_tree_id: selected.machine_company_type_item_tree_id,
            type: selected.type,
            childrens: [
              this.getChildPush(0, selected, true)
            ]
          };
          allItems.push(temp);
        }
      });
      if (flag) {
        this.machine_company_fields = [...allItems];
      } else {
        this.machine_companies = [...allItems];
      }
    },
    /**
     * remove item to list
     * @param selectedItems : selectedItems
     * @param flag: true ( remove left ) , false ( remove right )
     */
    removeItemMachine(selectedItems, flag) {
      let _selecteds = _.cloneDeep(selectedItems);
      // remove left
      if (flag) {
        let _allItems = _.cloneDeep(this.machine_companies);
        _selecteds.forEach((leftSelected) => {
          _allItems.forEach((itemLeft) => {
            if (itemLeft.machine_company_type_item_tree_id == leftSelected.machine_company_type_item_tree_id) {
              itemLeft.childrens = itemLeft.childrens.filter(child => child.index != leftSelected.index);
            }
          });
        });
        _allItems = _allItems.filter(element => element.childrens.length > 0);
        this.machine_companies = [..._allItems];
      } else { // remove right
        let _allItems = _.cloneDeep(this.machine_company_fields);
        _selecteds.forEach((rightSelected) => {
          _allItems.forEach((itemRight) => {
            if (itemRight.machine_company_type_item_tree_id == rightSelected.machine_company_type_item_tree_id) {
              if (rightSelected.machine_company_field_id == null) {
                itemRight.childrens = itemRight.childrens.filter(element => element.index != rightSelected.index);
              } else {
                itemRight.childrens.forEach((child) => {
                  if (child.index == rightSelected.index) {
                    child.deleted_flag = 1;
                  }
                });
              }
            }
          });
        });
        _allItems = _allItems.filter(element => element.childrens.length > 0);
        this.machine_company_fields = [..._allItems];
      }
    },
    /**
     * get child push to parent in list
     */
    getChildPush(index, selected, flag) {
      return flag ? {
          index: index,
          id: selected.machine_company_id,
          name: selected.name,
          machine_number: selected.machine_number,
          machine_company_field_id: selected.machine_company_field_id
        } :
        {
          index: index,
          machine_company_id: selected.id,
          machine_company_field_id: selected.machine_company_field_id,
          name: selected.name,
          machine_number: selected.machine_number
        };
    },
    /**
     * search in left list - don't call api
     */
    onSearch() {
      this.flagSearch = true;
      let _machine_companies_before_search = _.cloneDeep(this.machine_companies_before_search);
      let param = { ...this.apiParams };
      let rs = _machine_companies_before_search;
      if (param.machine_company_type_item_tree_id.length > 0) {
        rs = rs.filter(e => param.machine_company_type_item_tree_id.includes(e.machine_company_type_item_tree_id));
      }
      if (param.machine_company_name) {
        let string = this.convert(param.machine_company_name);
        rs.forEach(element => {
          element.childrens = element.childrens.filter(child => this.convert(child.name).includes(string));
        });
        rs = rs.filter(element => element.childrens.length > 0);
      }
      this.machine_companies = [...rs];
    },
    /**
     * update search params
     */
    onChangeSearchParam({ name, value }) {
      let _searchParams = { ...this.searchParams };
      _searchParams[name] = value;
      this.searchParams = { ..._searchParams };
    },
    /**
     * open form machine company type
     */
    async openFormMachineCompanyType() {
      const companyUser = this.COMPANY_USER;
      await Store.dispatch(`CompanyMachines/getMachineCompanyType`, {
        company_id: companyUser.company_id,
        company_branch_id: companyUser.company_branch_id ? companyUser.company_branch_id : ""
      });
      this.isShowMachineCompanyType = true;
    },
    /**
     * convert string to search
     */
    convert(str) {
      str = str.trim();
      str = str.toLowerCase();
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      str = str.replace(/đ/g, "d");
      return str;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";

.header-title {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 26px;
  font-weight: bold;
  color: black;
}
</style>
