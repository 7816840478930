<template>
  <div :style="`height:${tableHeight}px; overflow-x: hidden; overflow-y: auto;`" v-if="isTableTypeSchedule">
    <ScheduleCompanyRowPlans
      v-for="item in items"
      :key="item.work_plan_id"
      :isTableTypeSchedule="isTableTypeSchedule"
      :item="item"
      :today="date"
      @update="functions.updateSelectedItems"
      @openItemForm="functions.openItemForm"
      @getChildItems="functions.getChildItems"
      @openForm="functions.openForm"
    />
  </div>
  <div :style="`height:${tableHeight}px; overflow-x: hidden; overflow-y: auto;`" v-else>
    <ScheduleCompanyRowResults
      v-for="item in items"
      :key="item.work_result_id"
      :isTableTypeSchedule="isTableTypeSchedule"
      :item="item"
      :today="date"
      @update="functions.updateSelectedItems"
      @openItemForm="functions.openItemForm"
      @getChildItems="functions.getChildItems"
      @openForm="functions.openForm"
    />
  </div>
</template>
<script>
import ScheduleCompanyRowPlans from "./ScheduleCompanyRowPlans.vue";
import ScheduleCompanyRowResults from "./ScheduleCompanyRowResults.vue";
export default {
  components: {
    ScheduleCompanyRowPlans,
    ScheduleCompanyRowResults
  },
  props: {
    isTableTypeSchedule: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
    },
    functions: {
      type: Object,
    },
    date: {
      type: String,
    },
    tableHeight: Number,
  },
};
</script>
