<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-list>
                    <Label label="工事" :editable="editable" />
                    <Select
                      name="roll"
                      :items="selectRollItems"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                    />
                    <v-treeview
                      :items="locations"
                      activatable
                      item-key="path"
                      return-object
                      @update:active="onUpdate"
                      :key="flg"
                    >
                      <template v-slot:label="{ item, active }">
                        <!-- すでにselectされたitemをクリックしても@updateさせない -->
                        <div @click="active ? $event.stopPropagation() : null">
                          {{ item.item_name }}
                        </div>
                      </template>
                    </v-treeview>
                  </v-list>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col cols="12" sm="8" md="8">
                  <v-app-bar
                    v-if="editable"
                    flat
                    height="48px"
                    color="rgba(0, 0, 0, 0)"
                  >
                    <v-spacer></v-spacer>
                    <!-- チェックした画像をまとめてapiへなげて削除 -->
                    <v-btn
                      class="mx-2 px-5 white--text"
                      depressed
                      small
                      color="amber darken-4"
                      :disabled="!editable || selectedFileIds.length == 0"
                      @click="openRemoveDialog"
                    >
                      削除
                    </v-btn>
                    <!-- 画像ファイルを選択してapiでアップロード -->
                    <v-btn
                      class="px-5"
                      depressed
                      small
                      color="primary"
                      :disabled="
                        !editable || !isShowBtnAddImage || locations.length == 0
                      "
                      @click="openInput"
                    >
                      追加
                    </v-btn>
                    <!-- 非表示のfile input -->
                    <form ref="form">
                      <input
                        style="display: none"
                        ref="input_drawing_file"
                        type="file"
                        accept="image/jpeg, image/jpg, image/png, application/pdf"
                        @change="onImageInput()"
                      />
                    </form>
                  </v-app-bar>
                  <v-row class="justify-center ma-10">
                    <v-col
                      v-for="item in items"
                      :key="item.original_index"
                      class="d-flex"
                      cols="12"
                      sm="6"
                      md="6"
                      lg="3"
                    >
                      <v-checkbox
                        v-if="editable"
                        v-model="selectedFileIds"
                        :value="item.original_index"
                        class="mt-n4 mr-n4"
                      ></v-checkbox>
                      <v-card elevation="0">
                        <v-img
                          v-if="!item.pdf"
                          width="200px"
                          height="300px"
                          :src="
                            item.image_data || getUrlImage(item.original_index)
                          "
                          class="grey lighten-2"
                          @click="openDrawingDialog(item, item.original_index)"
                        ></v-img>
                        <span
                          @click="openDrawingDialog(item, item.original_index)"
                          v-if="item.pdf"
                        >
                          <pdf
                            :src="
                              item.pdf_data ||
                              getUrlImage(item.original_index, item.pdf)
                            "
                            style="
                              display: inline-block;
                              width: 200px;
                              height: 300px;
                            "
                          >
                          </pdf>
                        </span>
                        <v-list two-line class="text-center">
                          <v-list-item-content>
                            <v-list-item-subtitle
                              >hoge{{ item.updated_at }}</v-list-item-subtitle
                            >
                            <v-list-item-title v-if="!item.pdf"
                              >hoge{{ item.image }}</v-list-item-title
                            >
                            <v-list-item-title v-if="item.pdf"
                              >hoge{{ item.pdf }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <!-- 画像表示ダイアログ -->
              <Popup width="1200px" :dialog="isShowDrawingDialog">
                <v-card class="text-center">
                  <v-card-title>
                    <div>{{ showDrawingDialogImage.updated_at }}</div>
                    <v-spacer></v-spacer>
                    <div>{{ showDrawingDialogImage.image }}</div>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="editable"
                      class="mr-3"
                      depressed
                      small
                      color="primary"
                      @click="createChatRoomFromFieldId(showDrawingDialogImage)"
                    >
                      チャットルーム・配置計画を作成
                    </v-btn>
                    <v-btn
                      v-if="editable"
                      class="mr-3"
                      depressed
                      small
                      color="primary"
                      @click="openInput"
                    >
                      図面更新
                    </v-btn>
                    <v-btn small icon @click="closeDrawingDialog">
                      <v-icon style="position: fixed">mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-img
                      v-if="!showDrawingDialogImage.pdf"
                      max-height="690"
                      max-width="1200"
                      :src="
                        showDrawingDialogImage.image_data ||
                        getUrlImage(showDrawingDialogImage.index)
                      "
                      position="center"
                      contain
                    >
                    </v-img>
                    <img
                      v-if="showDrawingDialogImage.pdf"
                      :src="
                        showDrawingDialogImage.image_data ||
                        getUrlImage(showDrawingDialogImage.index)
                      "
                    />
                  </v-card-text>
                </v-card>
              </Popup>
              <!-- 削除確認ダイアログ -->
              <Popup width="480px" :dialog="isShowRemoveDialog">
                <ConfirmRemoveDialog
                  @close="closeRemoveDialog()"
                  @yes="remove()"
                  title="選択項目の削除"
                  text="選択した画像を削除してもよろしいですか？"
                  warning
                />
              </Popup>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    <script type="application/javascript" src="/js/pdf.min.js"></script>
    <script type="application/javascript" src="/js/pdf.worker.min.js"></script>
  </div>
</template>
<script>
import pdf from "vue-pdf";
import { Store } from "@/store/Store.js";
import Label from "@/components/forms/elements/Label";
import InputText from "@/components/forms/elements/InputText";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import Select from "@/components/forms/elements/Select.vue";
import SiteImages from "./components/SiteImages";
import Checkbox from "../elements/Checkbox.vue";
import Popup from "@/components/common/Popup.vue";
import ConfirmRemoveDialog from "@/components/dialog/ConfirmRemoveDialog.vue";
import _ from "lodash";
import { joinImages } from "@/utils/join-images";
import { getParamsOfUrlReadFile } from "@/utils/viewSourceAuth";

// init data
// 作業場所
const LOCATIONS = [];
// 図面
const DRAWINGS = [];

export default {
  data: () => {
    return {
      formValues: {},
      //作業場所
      locations: LOCATIONS,
      //画像一覧
      items: DRAWINGS,
      // 選択図面id
      selectedFileIds: [],
      // 権限リスト？
      selectRollItems: [],
      //
      searchParams: {},
      // 図面表示ダイアログ
      isShowDrawingDialog: false,
      // ダイアログに表示する図面
      showDrawingDialogImage: {},
      // 図面削除確認ダイアログ
      isShowRemoveDialog: false,
      path: "",
      isUpdate: false,
      isShowBtnAddImage: false,
      itemSelected: [],
      constructions: [],
      sourceImages: [],
      flg: false,
    };
  },

  components: {
    Label,
    InputText,
    InputDatepicker,
    Select,
    SiteImages,
    Checkbox,
    Popup,
    ConfirmRemoveDialog,
    pdf,
  },

  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    locationSelect: Array,
    idSelect: Number,
  },

  async mounted() {
    // propで渡されるformデータをwatch
    //「locationsに変更があるときだけ実行する」とすると、他のタブで変更があった場合、その変更内容がformValuesに反映されずに、
    // locationsに変更があると、他のタブの変更内容が消えてしまうため、変更を比較するコードを削除する。
    this.$watch(
      () => this.item,
      (newValue) => {
        this.initData(newValue);
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.item.field_constructions,
      (newValue) => {
        if (
          JSON.stringify(newValue) !== JSON.stringify(this.constructions) &&
          newValue.length > 0
        ) {
          this.constructions = _.cloneDeep(newValue);
          let _selectedRollItems = [];
          this.constructions?.forEach((item) => {
            if (item.construction_name)
              _selectedRollItems.push({
                id: item.id ? item.id : item.construction_name,
                name: item.construction_name,
                key: item.key,
              });
          });
          this.selectRollItems = _selectedRollItems;
          this.locations = this.getDataLocation(newValue);
          if (this.selectRollItems.length > 0 && !this.searchParams.roll) {
            let searchParams = { ...this.searchParams };
            searchParams.roll = this.selectRollItems[0].id;
            searchParams.rollName = this.selectRollItems[0].name;
            searchParams.key = this.selectRollItems[0].key;
            this.searchParams = searchParams;
            if (!this.idSelect) {
              this.onChangeSearchParams({
                name: "roll",
                value: this.selectRollItems[0].id,
              });
              this.$emit(
                "filter-select",
                this.locations,
                this.selectRollItems[0].id
              );
            }
          }
          this.insertPath();
          this.updateConstructions();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.locationSelect,
      (value) => {
        this.locations = value;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.idSelect,
      (value) => {
        if (value) {
          this.onChangeSearchParams({
            name: "roll",
            value: value,
          });
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  computed: {
    /**
     * (共通)
     * 定数をapi/ストアからまとめて取得します
     *
     * this.CONSTANTS.roll
     * こうやって個々の定数を取得します
     */
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    // propsで渡されるformデータをcomponent用にset
    async fetchImageSource(sourceUrl) {
      let image = "";
      if (sourceUrl) {
        const params = getParamsOfUrlReadFile(sourceUrl);
        const response = await Store.dispatch("File/readFile", params);
        if (!response.hasError) {
          image = window.URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers["content-type"],
            })
          );
        }
      }
      return image;
    },

    async initiateImageSource() {
      await this.items.forEach((item) => {
        if (item.image_url) this.createObjectUrlForImage(item);
      });
    },

    async createObjectUrlForImage(item) {
      if (item.pdf) {
        this.sourceImages.push({
          id: item.original_index,
          url: await this.fetchImageSource(item.image_url),
          pdf_url: await this.fetchImageSource(item.pdf_url),
        });
      } else {
        this.sourceImages.push({
          id: item.original_index,
          url: await this.fetchImageSource(item.image_url),
        });
      }
    },

    initData(data) {
      const formValues = data;
      this.formValues = _.cloneDeep(formValues);
    },

    formUpdate(newValue) {
      const formValues = _.cloneDeep(this.formValues);
      formValues.field_constructions.forEach((element) => {
        if (element.id) {
          if (element.id == this.searchParams["roll"]) {
            element["field_item_trees"] = newValue;
          }
        } else {
          if (element.construction_name == this.searchParams["roll"]) {
            element["field_item_trees"] = newValue;
          }
        }
      });
      this.formValues = formValues;
      this.$emit("formUpdate", this.formValues);
    },

    insertPath() {
      const pathArray = "";
      const req = (arr, locations) => {
        locations.forEach((element, index) => {
          element["path"] = arr != "" ? `${arr}_${index}` : `${index}`;
          element.field_images.forEach((image, i) => {
            image["original_index"] = i;
          });
          if (element.children && element.children.length > 0) {
            req(element["path"], locations[index].children);
          }
        });
      };
      if (this.locations.length > 0) {
        req(pathArray, this.locations);
      }
    },

    updateConstructions() {
      const req = (locations) => {
        locations.forEach((element, index) => {
          for (let i = element.field_images.length - 1; i >= 0; i--) {
            const selectedRollItem = this.selectRollItems.find(
              (item) => item.key == element.field_images[i].key
            );
            if (!selectedRollItem) {
              element.field_images.splice(i, 1);
            } else {
              element.field_images[i].field_construction_name =
                selectedRollItem.name;
            }
          }
          if (element.children && element.children.length > 0) {
            req(locations[index].children);
          }
        });
      };
      if (this.locations.length > 0) {
        req(this.locations);
        this.formUpdate(this.locations);
        // this.onUpdate(this.itemSelected);
      }
    },

    updateBase64Image(value, name) {
      let item = [];
      const pathArray = this.path.split("_");
      const _locations = [...this.locations];
      const req = (arr, locations) => {
        const tindex = arr.shift();
        if (arr.length) {
          return req(arr, locations[+tindex].children);
        } else {
          locations[+tindex].field_images.forEach((e2) => {
            if (e2.pdf == name) {
              e2.image_data = value;
            }
          });
          item.push(locations[+tindex]);
          return true;
        }
      };
      if (req(pathArray, _locations)) {
        this.onUpdate(item);
        this.formUpdate(_locations);
      }
    },

    addImage(values) {
      const pathArray = this.path.split("_");
      let item = [];
      const req = (arr, locations) => {
        const tindex = arr.shift();
        if (arr.length) {
          return req(arr, locations[+tindex].children);
        } else {
          let itemImage = {};
          if (typeof this.searchParams["roll"] === "number") {
            itemImage["field_construction_name"] =
              this.searchParams["rollName"];
            itemImage["key"] = this.searchParams["key"];
            itemImage["image"] = values.file_name;
            itemImage["image_data"] = values.file;
            if (this.isPdf(itemImage["image"])) {
              itemImage["image"] = this.renamePdfToImage(values.file_name);
              itemImage["pdf"] = values.file_name;
              itemImage["pdf_data"] = values.file;
              var pdfAsArray = this.convertDataURIToBinary(values.file);
              this.pdfToImage(this, pdfAsArray, itemImage["pdf"]);
            } else {
              itemImage["pdf_data"] = null;
              itemImage["pdf"] = null;
            }
          } else {
            itemImage["field_construction_name"] =
              this.searchParams["rollName"];
            itemImage["key"] = this.searchParams["key"];
            itemImage["image"] = values.file_name;
            itemImage["image_data"] = values.file;
            if (this.isPdf(itemImage["image"])) {
              itemImage["image"] = this.renamePdfToImage(values.file_name);
              itemImage["pdf"] = values.file_name;
              itemImage["pdf_data"] = values.file;
              var pdfAsArray1 = this.convertDataURIToBinary(values.file);
              this.pdfToImage(this, pdfAsArray1, values.file_name);
            } else {
              itemImage["pdf_data"] = null;
              itemImage["pdf"] = null;
            }
          }
          locations[+tindex].field_images.push(itemImage);
          item.push(locations[+tindex]);
          return true;
        }
      };
      if (req(pathArray, this.locations)) {
        this.formUpdate(this.locations);
        this.onUpdate(item);
      }
    },

    updateImage(values, index) {
      const pathArray = this.path.split("_");
      let item = [];
      const req = (arr, locations) => {
        const tindex = arr.shift();
        if (arr.length) {
          return req(arr, locations[+tindex].children);
        } else {
          locations[+tindex].field_images[index]["key"] =
            this.searchParams["key"];
          locations[+tindex].field_images[index]["image"] = values.file_name;
          locations[+tindex].field_images[index]["image_data"] = values.file;
          if (this.isPdf(values.file_name)) {
            locations[+tindex].field_images[index]["image"] =
              this.renamePdfToImage(values.file_name);
            locations[+tindex].field_images[index]["pdf"] = values.file_name;
            locations[+tindex].field_images[index]["pdf_data"] = values.file;
            var pdfAsArray = this.convertDataURIToBinary(values.file);
            this.pdfToImage(this, pdfAsArray, values.file_name);
          } else {
            locations[+tindex].field_images[index]["pdf"] = null;
            locations[+tindex].field_images[index]["pdf_data"] = null;
          }
          delete locations[+tindex].field_images[index]["image_url"];
          item.push(locations[+tindex]);
          return true;
        }
      };
      if (req(pathArray, this.locations)) {
        this.formUpdate(this.locations);
        this.onUpdate(item);
      }
    },
    isPdf(fileName) {
      if (fileName == undefined) {
        return false;
      } else {
        return fileName.toLocaleLowerCase().match(/\.(pdf)$/);
      }
    },

    remove() {
      if (this.selectedFileIds.length > 1)
        this.selectedFileIds.sort((first, second) => second - first);
      const pathArray = this.path.split("_");
      let item = [];
      const req = (arr, locations) => {
        const tindex = arr.shift();
        if (arr.length) {
          return req(arr, locations[+tindex].children);
        } else {
          this.selectedFileIds.forEach((index) => {
            if (locations[+tindex].field_images[index]?.id) {
              locations[+tindex].field_images[index]["image_deleted"] = 1;
              delete locations[+tindex].field_images[index]["image"];
              delete locations[+tindex].field_images[index]["image_url"];
            } else {
              locations[+tindex].field_images.splice(index, 1);
            }
          });
          item.push(locations[+tindex]);
          return true;
        }
      };
      if (req(pathArray, this.locations)) {
        this.formUpdate(this.locations);
      }
      this.onUpdate(item);
      this.isShowRemoveDialog = false;
      this.selectedFileIds = [];
    },

    // 画像選択してapi post
    onImageInput() {
      const file = this.$refs.input_drawing_file.files[0];
      if (!file) {
        return;
      }
      this.$refs.form.reset();
      if (file.name.toLocaleLowerCase().match(/\.(jpg|jpeg|png|pdf)$/)) {
        if (file.size >= 10 * 1024 * 1024) {
          Store.dispatch(
            "Error/show",
            {
              status: "400",
              message: "アップロードのファイルは、最大10MBまでです。",
            },
            { root: true }
          );
          return;
        }
      } else {
        Store.dispatch(
          "Error/show",
          {
            status: "400",
            message: "jpg, png, jpeg, pdfファイルのみアップロード可能です。",
          },
          { root: true }
        );
        return;
      }
      this.getBase64(file)
        .then((data64) => {
          let params = {
            file_name: file.name,
            file_type: file.type,
            file: data64,
          };
          this.isUpdate
            ? this.updateImage(params, this.showDrawingDialogImage["index"])
            : this.addImage(params);
          this.isShowDrawingDialog = false;
          this.isUpdate = false;
        })
        .catch(() => console.log("ファイルアップロードエラー"));
    },

    // Base64変換
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    getUrlImage(key, isPdf) {
      return isPdf
        ? this.sourceImages.find((item) => item.id == key)?.pdf_url
        : this.sourceImages.find((item) => item.id == key)?.url;
    },

    /**
     * クリックで選択した項目の図面表示
     */
    onUpdate(val) {
      if (!val[0]) {
        return;
      }
      this.itemSelected = val;
      this.path = val[0].path;
      let itemsImage = [];
      if (val[0].field_images) {
        val[0].field_images.forEach((item) => {
          if (item.key == this.searchParams["key"] && !item.image_deleted) {
            itemsImage.push(item);
          }
        });
      } else {
        this.items = [];
      }
      this.items = itemsImage;
      this.sourceImages = [];
      this.initiateImageSource();
      if (this.searchParams.roll) this.isShowBtnAddImage = true;
    },

    convertDataURIToBinary(dataURI) {
      var BASE64_MARKER = ";base64,";
      var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
      var base64 = dataURI.substring(base64Index);
      var raw = window.atob(base64);
      var rawLength = raw.length;
      var array = new Uint8Array(new ArrayBuffer(rawLength));

      for (var i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
      }
      return array;
    },

    renamePdfToImage(value) {
      let namePdf = value;
      return namePdf.split(".")[0] + ".png";
    },

    // 画像表示ダイアログopen
    openDrawingDialog(item, index) {
      this.showDrawingDialogImage = { ...item };
      this.showDrawingDialogImage["index"] = index;
      this.isShowDrawingDialog = true;
      this.isUpdate = true;
    },

    pdfToImage(value, url, name) {
      var PDFJS = window["pdfjs-dist/build/pdf"];
      PDFJS.GlobalWorkerOptions.workerSrc = "js/pdf.worker.min.js";
      PDFJS.disableWorker = true;
      var loadingTask = PDFJS.getDocument(url);
      var MAX_PAGE = 60;
      loadingTask.promise.then(
        function (pdf) {
          var totalPages = pdf.numPages > MAX_PAGE ? MAX_PAGE : pdf.numPages;
          var dataImg = [];
          for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
            pdf.getPage(pageNumber).then(function (page) {
              var scale = 1.3;
              var viewport = page.getViewport({ scale: scale });
              var canvas = document.createElement("canvas");
              // Prepare canvas using PDF page dimensions
              var context = canvas.getContext("2d");
              var height = viewport.height;
              var width = viewport.width;
              canvas.height = height;
              canvas.width = width;
              // Render PDF page into canvas context
              var renderContext = {
                canvasContext: context,
                viewport: viewport,
              };

              var renderTask = page.render(renderContext);

              renderTask.promise.then(function () {
                dataImg[pageNumber - 1] = canvas.toDataURL("image/png"); //B64
                if (dataImg.length == totalPages) {
                  joinImages(dataImg).then(function (b64) {
                    if (!b64 || b64 == "data:,") return;
                    value.updateBase64Image(b64, name);
                  });
                }
              });
            });
          }
        },
        function (reason) {
          // PDF loading error
          console.error(reason);
        }
      );
    },
    // 画像表示ダイアログclose
    closeDrawingDialog() {
      this.showDrawingDialogImage = {};
      this.isShowDrawingDialog = false;
      this.isUpdate = false;
    },

    // 削除確認ダイアログclose
    closeRemoveDialog() {
      this.isShowRemoveDialog = false;
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      let result = this.selectRollItems.find((item) => item.id == value);
      searchParams[name] = value;
      searchParams["rollName"] = result.name;
      searchParams["key"] = result.key;
      this.searchParams = searchParams;
      this.flg = !this.flg; // reload treeview
      this.locations = this.getDataLocation(
        this.formValues.field_constructions
      );
      this.$emit("filter-select", this.locations, value);
      this.insertPath();
      this.onUpdate(this.itemSelected);
      this.isShowBtnAddImage = false; // disable button add image
      this.items = []; // set items is empty
    },

    /**
     * 図面ファイル選択input
     */
    openInput() {
      this.$refs.input_drawing_file.click();
    },

    /**
     * 削除確認
     */
    openRemoveDialog() {
      this.isShowRemoveDialog = true;
    },

    getDataLocation(construction) {
      let rs = [];
      construction.forEach((e) => {
        if (e.id) {
          if (e.id == this.searchParams["roll"]) {
            rs = e.field_item_trees || [];
            return rs;
          }
        } else {
          if (e.construction_name == this.searchParams["roll"]) {
            rs = e.field_item_trees || [];
            return rs;
          }
        }
      });
      return rs;
    },
    /**
     * お絵描き＆チャットルーム作成
     * @param {int}} fieldId
     */
    async createChatRoomFromFieldId({ id, image }) {
      const field_id = this.formValues.fields.id;

      //お絵描き作成
      const _result = await Store.dispatch("Oekaki/loadData", {
        field_image_id: id,
      });

      //お絵描きデータがなければ初期化
      if (_result.hasError) {
        await Store.dispatch("Oekaki/saveData", {
          field_image_id: id,
          layout: { contents: null, background: null },
        });
      }

      //チャットルーム作成
      const result = await Store.dispatch(
        "TextChatManage/roomCreateFromFieldImageId",
        {
          name: image,
          field_image_id: id,
          field_id,
          approval_flag: 1,
        }
      );
      if (result.hasError) {
        if (result.response.status === 409) {
          Store.dispatch("Error/show", {
            status: 409,
            message: "チャットルームはすでに存在しています",
          });
        } else {
          Store.dispatch("Error/show", {
            status: 500,
            message: "チャットルームが作成できませんでした。",
          });
        }
      } else {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "チャットルームが作成できました。",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";

.v-input {
  z-index: 1000;
}
</style>
