import {
  MENU_SCHEDULE_ITEMS,
  MENU_ENTRANCE_ITEMS,
  MENU_DEVICE_ITEMS,
  MENU_CHAT_ITEMS,
  MENU_OTHER_ITEMS
} from "@/constants/COMMON.js";

import { getDropDownMenuVisibility, ENV_CONFIG_RENTAL } from "./ENV_CLIENT";

// ポータルの種類
const PORTAL_LIST = {
  INHOUSE_PORTAL: { id: 1, name: "自社ポータル" },
  SITE_PORTAL: { id: 2, name: "現場ポータル" },
  SP: { id: 3, name: "モバイル" },
};

// 現ポータル
const CURRENT_PORTAL = { id: 2, name: "現場ポータル" };

// タブメニュー項目 自社ポータル
const HEADER_MENU_ITEMS_INHOUSE = {
  PORTAL: { id: 0, name: "ポータル", path: "/portal" },
  SITES: { id: 1, name: "現場", path: "/master/sites" },
  EMPLOYEES: { id: 2, name: "社員", path: "/master/employees" },
  COMPANY_MACHINES: { id: 3, name: "機材", path: "/master/companyMachines" },
  OFFICES: { id: 4, name: "支店", path: "/master/offices" },
};

const HEADER_MENU_ITEMS_INHOUSE_WITHOUT_OFFICE = {
  PORTAL: { id: 0, name: "ポータル", path: "/portal" },
  SITES: { id: 1, name: "現場", path: "/master/sites" },
  EMPLOYEES: { id: 2, name: "社員", path: "/master/employees" },
  COMPANY_MACHINES: { id: 3, name: "機材", path: "/master/companyMachines" },
};

const HEADER_MENU_ITEMS_INHOUSE_ARRAY = Object.keys(
  HEADER_MENU_ITEMS_INHOUSE
).map((key) => {
  return HEADER_MENU_ITEMS_INHOUSE[key];
});

const HEADER_MENU_ITEMS_INHOUSE_ARRAY_WITHOUT_OFFICE = Object.keys(
  HEADER_MENU_ITEMS_INHOUSE_WITHOUT_OFFICE
).map((key) => {
  return HEADER_MENU_ITEMS_INHOUSE_WITHOUT_OFFICE[key];
});

// タブメニュー項目 現場ポータル
const HEADER_MENU_ITEMS_SITE = {
  PORTAL: { id: 0, name: "掲示板", path: "/portal/dashboard" },
  CHART: { id: 1, name: "体制管理", path: "/portal/chart" },
  SAFETY: { id: 2, name: "労務管理", path: "/safety/chart" },
  SCHEDULE: { id: 3, name: "予定管理", path: "/schedule/works" },
  ENTRANCE: { id: 4, name: "入退場管理", path: "/entrance/new_worker" },
  DEVICE_MANAGEMENT: { id: 5, name: "資機材管理", path: "/device/stock" },
  PLACEMENT: { id: 6, name: "配置計画", path: "/oekaki/" },
  TEXTCHAT: {
    id: 7,
    name: "チャット管理",
    path: "/portal/chatmanage/siterooms",
  },
  WORK_SCHEDULE: { id: 8, name: "作業予定", path: "/schedule/works" },
  WORK_RECORD: { id: 9, name: "作業実績", path: "/schedule/records" },
  USAGE_SCHEDULE: { id: 10, name: "使用予定", path: "/schedule/usage" },
  SAFETY_PATROLS: { id: 11, name: "巡回記録", path: "/schedule/patrols" },
  SAFETY_REPORTS: { id: 12, name: "帳票出力", path: "/schedule/reports" },
  OTHERS: { id: 13, name: "その他", path: "" },
};

// レンタル会社メニュー
const HEADER_MENU_ITEMS_SITE_FOR_USER_RENTAL_COMPANY = {
  PORTAL: { id: 0, name: "掲示板", path: "/portal/dashboard" },
  DEVICE_MANAGEMENT: { id: 5, name: "資機材管理", path: "/device/stock" },
};

const HEADER_MENU_ITEMS_SITE_ARRAY = Object.keys(HEADER_MENU_ITEMS_SITE).map(
  (key) => {
    return HEADER_MENU_ITEMS_SITE[key];
  }
);

const HEADER_MENU_ITEMS_SITE_ARRAY_USER_RENTAL_COMPANY = Object.keys(
  HEADER_MENU_ITEMS_SITE_FOR_USER_RENTAL_COMPANY
).map((key) => {
  return HEADER_MENU_ITEMS_SITE_FOR_USER_RENTAL_COMPANY[key];
});

/**
 * ドロップダウンを表示する項目
 */
const MENU_SCHEDULE_ITEMS_VIS = () => {
  const VISIBILITY = getDropDownMenuVisibility("MENU_SCHEDULE_ITEMS");
  return MENU_SCHEDULE_ITEMS.map((item) => {
    const vis = VISIBILITY.find((_item) => _item.id === item.id);
    return { ...item, ...vis };
  });
};

const MENU_ENTRANCE_ITEMS_VIS = () => {
  const VISIBILITY = getDropDownMenuVisibility("MENU_ENTRANCE_ITEMS");
  return MENU_ENTRANCE_ITEMS.map((item) => {
    const vis = VISIBILITY.find((_item) => _item.id === item.id);
    return { ...item, ...vis };
  });
};

const MENU_DEVICE_ITEMS_VIS = () => {
  const VISIBILITY = getDropDownMenuVisibility("MENU_DEVICE_ITEMS");
  return MENU_DEVICE_ITEMS.map((item) => {
    const vis = VISIBILITY.find((_item) => _item.id === item.id);
    return { ...item, ...vis };
  });
};

const MENU_CHAT_ITEMS_VIS = () => {
  const VISIBILITY = getDropDownMenuVisibility("MENU_CHAT_ITEMS");
  return MENU_CHAT_ITEMS.map((item) => {
    const vis = VISIBILITY.find((_item) => _item.id === item.id);
    return { ...item, ...vis };
  });
};

const MENU_OTHER_ITEMS_VIS = () => {
  const VISIBILITY = getDropDownMenuVisibility("MENU_OTHER_ITEMS");
  return MENU_OTHER_ITEMS.map((item) => {
    const vis = VISIBILITY.find((_item) => _item.id === item.id);
    return { ...item, ...vis };
  });
};

const HEADER_MENU_DROPDOWN = [
  {
    id: HEADER_MENU_ITEMS_SITE.SCHEDULE.id,
    menu: MENU_SCHEDULE_ITEMS_VIS(),
  },
  { id: HEADER_MENU_ITEMS_SITE.ENTRANCE.id, menu: MENU_ENTRANCE_ITEMS_VIS() }, // COMMON.jsの予定管理一覧
  {
    id: HEADER_MENU_ITEMS_SITE.DEVICE_MANAGEMENT.id,
    menu: MENU_DEVICE_ITEMS_VIS(),
  },
  {
    id: HEADER_MENU_ITEMS_SITE.TEXTCHAT.id,
    menu: MENU_CHAT_ITEMS_VIS(),
  },
  {
    id: HEADER_MENU_ITEMS_SITE.OTHERS.id,
    menu: MENU_OTHER_ITEMS_VIS(),
  },
];

// レンタル会社のドロップダウンメニューを環境に左右されないので、固定で返す
const HEADER_MENU_DROPDOWN_FOR_USER_RENTAL_COMPANY = [
  {
    id: HEADER_MENU_ITEMS_SITE_FOR_USER_RENTAL_COMPANY.DEVICE_MANAGEMENT.id,
    menu: ENV_CONFIG_RENTAL.MENU_DEVICE_ITEMS.map((item) => {
      const findItem = MENU_DEVICE_ITEMS.find((_item) => _item.id === item.id);
      return Object.assign(findItem, item);
    }),
  },
];

// ローカルストレージで保持
const STORAGE_KEY = "GRN_HEADERS";

const MENU_ENTRANCE_ITEMS_MAP = {
  REGISTER_FACE: { id: 0, name: "顔画像登録" },
  HISTORY: { id: 1, name: "入退場履歴" },
  RECORDS: { id: 2, name: "入退場実績" },
  NEW_WORKER: { id: 3, name: "新規入場者" },
  SUMMARY: { id: 4, name: "集計" },
};

export {
  PORTAL_LIST,
  HEADER_MENU_ITEMS_INHOUSE,
  HEADER_MENU_ITEMS_INHOUSE_WITHOUT_OFFICE,
  HEADER_MENU_ITEMS_INHOUSE_ARRAY,
  HEADER_MENU_ITEMS_INHOUSE_ARRAY_WITHOUT_OFFICE,
  HEADER_MENU_ITEMS_SITE,
  HEADER_MENU_ITEMS_SITE_ARRAY,
  HEADER_MENU_DROPDOWN,
  HEADER_MENU_DROPDOWN_FOR_USER_RENTAL_COMPANY,
  CURRENT_PORTAL,
  STORAGE_KEY,
  HEADER_MENU_ITEMS_SITE_FOR_USER_RENTAL_COMPANY,
  HEADER_MENU_ITEMS_SITE_ARRAY_USER_RENTAL_COMPANY,
  MENU_ENTRANCE_ITEMS_MAP,
};
