<template>
  <v-card class="dialog">
    <v-card-title>
      <div class="title">管理責任者</div>
    </v-card-title>
    <v-card-text>
      <ValidationObserver ref="observer" v-slot="observer">
        <v-form ref="form" lazy-validation autocomplete="off">
          <v-list-item>
            <v-list-item-content>
              <Label label="氏名" :editable="true" required>
                <Select
                  name="id"
                  :items="dataSelect"
                  :editable="true"
                  :values="formValues"
                  item_text="user_name"
                  validation_label="氏名"
                  validation_rules="required"
                  @onInput="onInput"
                >
                </Select>
              </Label>
            </v-list-item-content>
          </v-list-item>
        </v-form>
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>
      <div></div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="primary"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn
        depressed
        class="btn"
        color="primary"
        @click.once="onSubmit"
        :key="flag"
        :disabled="!valid"
      >
        保存
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback";
import { Store } from "@/store/Store.js";
import Select from "@/components/forms/elements/Select";
import Label from "@/components/forms/elements/Label";
export default {
  data() {
    return {
      formValues: {
        id: null,
      },
      valid: false,
      dataSelect: [],
    };
  },
  components: {
    Select,
    Label,
    ValidationCallback,
    ValidationObserver,
  },
  props: {
    flag: {
      type: Boolean,
    },
  },
  mounted() {
    this.$watch(
      () => Store.getters["PortalChart/getListDirectors"],
      (newVal) => {
        if (Array.isArray(newVal)) {
          this.dataSelect = [...newVal].map(e => ({
            id: e.id,
            user_name: `${e.user_name_sei} ${e.user_name_mei}`
          }));
          if (this.dataSelect.length > 0) this.formValues.id = this.dataSelect[0].id;
        }
      }
    )
  },
  methods: {
    onInput({ name, value }) {
      this.formValues[name] = value;
    },
    onSubmit() {
      this.$emit("onUpdateFieldTreeManagers", this.formValues.id);
    },
    updateValidate({ valid }) {
      this.valid = valid;
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .title {
    font-size: 12px;
    color: #1b9c4f;
  }
}
</style>
