<template>
  <v-container>
    <ValidationProvider
      v-slot="{ errors, valid }"
      :vid="field_tree_id"
      :name="validation_label"
      :rules="validation_rules"
    >
      <v-autocomplete
        v-model="selectedManchine"
        :items="items"
        :item-text="item_text"
        :item-value="field_tree_id"
        :error-messages="errors"
        :success="valid"
        chips
        placeholder="検索"
        class="btn-checkbox select-multi-reports"
        multiple
        :noDataText="NO_DATA_MESSAGE"
        :search-input.sync="searchText"
        :filter="filterObject"
        @blur="handleResetSearch"
      >
        <template v-slot:prepend-inner>
          <v-icon color="success"> mdi-magnify </v-icon>
        </template>
        <template v-slot:prepend-item>
          <v-list-item
            ripple
            @mousedown.prevent
            @click="toggle"
          >
            <v-list-item-action>
              <v-icon :color="selectedManchine.length > 0 ? 'indigo darken-4' : ''">
                {{ icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                全て選択
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
        <template #selection="{ item,index }" v-if="selectedManchine.length > 0">
          <v-chip small color="primary" v-if="index === 0">{{ selectedManchine.length }}個選択中</v-chip>
        </template>
      </v-autocomplete>
    </ValidationProvider>
  </v-container>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import _ from "lodash";
  export default {
    data: () => ({
      field_tree_id: 'field_tree_id',
      selectedManchine: [],
      NO_DATA_MESSAGE,
      isUpdateSelected: false,
      searchText: ''
    }),
    components: {
      ValidationProvider,
    },
    props: {
      name: {
        type: String,
      },
      items: {
        type: Array
      },
      validation_rules: {
        type: String,
        default: "",
      },
      validation_label: {
        type: String,
        default: "",
      },
      item_text: {
        type: String,
      },
      isReset: Boolean
    },
    mounted() {
      this.$watch(
        () => this.selectedManchine,
        (newValue) => {
          this.$emit("onInput", {
            name: this.name,
            value: newValue
          });
        },
        {
          immediate: true,
          deep: true
        }
      );
      this.$watch(
        () => this.items,
        () => {
          if(this.isUpdateSelected) return
          this.selectedManchine=[];
          this.isUpdateSelected = false
        },
        {
          immediate: true,
          deep: true
        }
      );
      this.$watch(
        () => this.isReset,
        () => {
          this.selectedManchine = [];
        },
        {
          immediate: true,
          deep: true
        }
      );
    },
    computed: {
      checkNull() {
        const result = this.filterItems()
        return this.checkNullSearch(result);
      },
      checkSome() {
        return this.selectedManchine.length > 0 && !this.checkNull;
      },
      icon() {
        if (this.checkNull) {
          return 'mdi-close-box';
        }
        if (this.checkSome) {
          return 'mdi-minus-box';
        }
        return 'mdi-checkbox-blank-outline';
      },
    },

    methods: {
      toggle() {
        if (this.checkNull) {
          const arrayFilter = this.filterItems();
          this.selectedManchine = _.difference(this.selectedManchine, arrayFilter?.map(k => { return k.field_tree_id}));
        } else {
          const arrayFilter = this.filterItems();
          const result = [...this.selectedManchine, ...arrayFilter?.map(k => { return k.field_tree_id})];
          this.selectedManchine = _.uniq(result);
        }
      },
      filterItems () {
        return this.searchText ? this.items?.filter(item => {
          return item.company_name.toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase().trim()) > -1
        }) : this.items
      },
      checkNullSearch (arrayChild) {
        if (this.searchText) {
          const intersectionArr = _.intersection(this.selectedManchine, arrayChild?.map(k => { return k.field_tree_id}));
          return intersectionArr.length === arrayChild.length;
        } else return this.selectedManchine.length === arrayChild.length;

      },
      filterObject(item, queryText) {
        return (
          item.company_name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase().trim()) >
          -1
        );
      },
      handleResetSearch () {
        const self = this
        const debounce_fun  = _.debounce(function () {
            self.searchText = ''
        }, 500);
        debounce_fun()
      }
    },
  }
</script>
<style>

  .select-multi-reports .v-input__slot {
    background: rgba(0, 0, 0, 0.06);
    padding: 0 12px;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }
  .select-multi-reports .v-select__slot .v-input__append-inner {
    margin-top: 9px !important;
  }
  .select-multi-reports .v-select__slot{
    height: 40px !important;
    overflow: hidden;
  }
</style>

<style scoped>
::v-deep .v-select__selections {
    flex: 1 auto !important;
    flex-wrap: nowrap !important;
}
::v-deep .v-select__selections .v-chip {
    display: inline-table !important;
}
::v-deep .v-select__selections input {
    min-width: auto !important;
}
::v-deep .v-input__prepend-inner {
    margin-top: 8px !important;
    margin-left: -2px !important;
}
::v-deep .v-autocomplete .v-select__slot .v-select__selections input {
    min-height: 40px !important;
}

::v-deep .v-text-field {
    padding-top: 0;
    margin-top: 0;
}
::v-deep .v-autocomplete .v-select__slot .v-select__selections input::placeholder {
    font-size: 13px;
}
</style>
