<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-list-item>
          <v-list-item-content class="row-position">
            <Label label="特別教育名" :editable="editable">
              <InputText
                name="training_name"
                :values="item"
                :editable="editable"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>
      </v-main>
    </v-container>
  </div>
</template>
<script>

import Label from "@/components/forms/elements/Label.vue";
import InputText from "@/components/forms/elements/InputText.vue";

const INITIAL_VALUES = {
  training_name: null
};

export default {
  data: () => {
    return {
      items: []
    };
  },
  components: {
    InputText,
    Label
  },
  props: {
    name: {
      type: String
    },
    values: {
      type: Object
    },
    editable: {
      type: Boolean
    },
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (this.items?.length == 0) {
          this.addEmpty();
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  methods: {
    addEmpty() {
      this.items.push({ ...INITIAL_VALUES });
    }
  }
};
</script>
