<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <WorkContentInput
                    name="device_work_classifications"
                    :values="formValues"
                    :editable="editable"
                    :isShowOccupation="checkShow('occupation')"
                    :isRequiredOccupation="checkRequired('occupation')"
                    :isShowClassification="checkShow('work_content')"
                    :isRequiredClassification="checkRequired('work_content')"
                    @onInput="onInput"
                  />
                  <v-list-item v-if="checkShow('work_details')">
                    <v-list-item-content>
                      <!-- 作業詳細 -->
                      <Label label="作業詳細" :editable="editable" :required="checkRequired('work_details')">
                        <InputTextArea
                          name="work_details"
                          :values="formValues"
                          :editable="editable"
                          validation_label="作業詳細"
                          :validation_rules="checkRequired('work_details')?'required|max:512':'max:512'"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="checkShow('expected_danger')">
                    <v-list-item-content>
                      <!-- 予想される危険 -->
                      <Label label="予想される危険" :editable="editable" :required="checkRequired('expected_danger')">
                        <InputTextArea
                          name="expected_danger"
                          :values="formValues"
                          :editable="editable"
                          validation_label="予想される危険"
                          :validation_rules="checkRequired('expected_danger')?'required|max:512':'max:512'"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <SafetyInstructionInput
                    name="device_safety_instructions"
                    :values="formValues"
                    :editable="editable"
                    :selectArrays="SAFETY_INSTRUCTION_ARRAY"
                    :isShowSafetyInstruction="checkShow('safety_instruction')"
                    :isRequiredSafetyInstruction="checkRequired('safety_instruction')"
                    @onInput="onInput"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="checkShow('status_code')">
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="搬入出" :editable="editable" :required="checkRequired('status_code')">
                        <RadioGroup
                          v-if="editable"
                          name="status_code"
                          :values="formValues"
                          :items="STATUS_CODE"
                          :editable="editable"
                          validation_label="搬入出"
                          :validation_rules="checkRequired('status_code')?'required':''"
                          @onInput="onInput"
                        />
                        <span v-if="!editable">{{
                          getRadioName(STATUS_CODE, formValues["status_code"])
                        }}</span>
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <DeviceUsageTimeInput
                    name="device_usage_times"
                    :values="formValues"
                    :editable="editable"
                    :timeZone="timeZone"
                    :isShowUsageTime="checkShow('usage_time')"
                    :isRequiredUsageTime="checkRequired('usage_time')"
                    @onInput="onInput"
                  />

                  <SectionLabel label="元請担当者" :editable="editable" />
                  <DeviceUserInput
                    name="device_users"
                    :values="formValues"
                    :editable="editable"
                    :selectArrays="FIELD_DIRECTOR_ARRAY"
                    :isShowDeviceUsers="checkShow('field_director_id')"
                    :isRequiredDeviceUsers="checkRequired('field_director_id')"
                    @onInput="onInput"
                    :timeZone="timeZone"
                  />
                  <SectionLabel label="車輌運転者" :editable="editable" v-if="checkShow('driver_company_name')"/>
                  <v-list-item v-if="checkShow('driver_company_name')">
                    <v-list-item-content>
                      <!-- 車輌運転者 -->
                      <Label label="会社名" :editable="editable" :required="checkRequired('driver_company_name')">
                        <InputText
                          name="driver_company_name"
                          :values="formValues"
                          :editable="editable"
                          placeholder="大山建設株式会社"
                          validation_label="会社名"
                          :validation_rules="checkRequired('driver_company_name')?'required|max:255':'max:255'"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="checkShow('driver_company_name')">
                    <v-list-item-content>
                      <Label label="氏名" :editable="editable" :required="checkRequired('driver_company_name')">
                        <InputText
                          name="driver_name"
                          :values="formValues"
                          :editable="editable"
                          placeholder="中島明"
                          validation_label="氏名"
                          :validation_rules="checkRequired('driver_company_name')?'required|max:255':'max:255'"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import InputTextArea from "@/components/forms/elements/InputTextArea";
import Select from "@/components/forms/elements/Select";
import RadioGroup from "@/components/forms/elements/RadioGroup";
import WorkContentInput from "./components/WorkContentInput.vue";
import SafetyInstructionInput from "./components/SafetyInstructionInput.vue";
import DeviceUsageTimeInput from "./components/DeviceUsageTimeInput.vue";
import DeviceUserInput from "./components/DeviceUserInput.vue";
import { STATUS_CODE } from "@/constants/SCHEDULE_GATE";
const IS_CUSTOMIZE = 1;

export default {
  data: () => {
    return {
      formValues: {},
      STATUS_CODE,
      DEFAULT_ARRAY: [],
      SAFETY_INSTRUCTION_ARRAY: [],
      FIELD_DIRECTOR_ARRAY: [],
    };
  },
  components: {
    Label,
    SectionLabel,
    InputText,
    Select,
    RadioGroup,
    InputTextArea,
    WorkContentInput,
    SafetyInstructionInput,
    DeviceUsageTimeInput,
    DeviceUserInput,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    timeZone: Object,
    formUpdate: Function,
    gatePassagesCustomize: Object,
  },
  mounted() {
    this.$watch(
      () => Store.getters["CmnMst/get"],
      (data) => {
        if (data) {
          this.DEFAULT_ARRAY = [...data.entries.cmn_mst_work_classifications];
          this.SAFETY_INSTRUCTION_ARRAY = [
            ...data.entries.cmn_mst_safety_instructions,
          ];
        }
        this.SAFETY_INSTRUCTION_ARRAY.unshift({
          id: null,
          safety_instruction: "",
        });
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getListDirectorsObayashi"],
      (data) => {
        let directors = [];
        data.forEach((item) => {
          directors.push({
            id: item.id,
            name: item.user_name_sei + " " + item.user_name_mei,
          });
        });
        this.FIELD_DIRECTOR_ARRAY = [{ id: null, name: "" },...directors];
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = {...newValue};
        if(!this.formValues?.field_construction_id) {
          this.FIELD_DIRECTOR_ARRAY = [{ id: null, name: ""}];
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      const headerHeight = 48;
      let style = `height:${this.mainHeight - headerHeight}px;`;
      return style;
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    getRadioName(items, value) {
      return items.find((item) => item.value == value)?.name;
    },
    /**
     * Check item requred
     */
    checkRequired(name) {
      return this.gatePassagesCustomize[name]?.required === IS_CUSTOMIZE;
    },
    
    /**
     * Check item show or hidden
     */
    checkShow(name) {
      return this.gatePassagesCustomize[name]?.form_view === IS_CUSTOMIZE;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
</style>
