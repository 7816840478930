const KEY_ACTIONS_VALUES = {
  // key :[ctr,shift,code]
  UNDO: [true, false, "KeyZ"],
  REDO: [true, true, "KeyZ"],
  COPY: [true, false, "KeyC"],
  PASTE: [true, false, "KeyV"],
  BACKSPACE: [true, false, "Backspace"],
};

export const KEY_ACTIONS = {
  UNDO: "undo",
  REDO: "red",
  COPY: "copy",
  PASTE: "paste",
  BACKSPACE: "backspace",
};

const _KeyCapture = () => {
  let callbacks = [];

  const init = () => {
    document.addEventListener("keyup", setHandler);
  };

  const destroy = () => {
    document.removeEventListener("keyup", setHandler);
  };

  const capture = (evt, arr) => {
    const arr2 = [evt.ctrlKey, evt.shiftKey, evt.code];
    return JSON.stringify(arr) === JSON.stringify(arr2);
  };

  const setHandler = (event) => {
    Object.keys(KEY_ACTIONS_VALUES).forEach((key) => {
      if (capture(event, KEY_ACTIONS_VALUES[key])) {
        callbacks.forEach((callback) => {
          callback(KEY_ACTIONS[key]);
        });
      }
    });
  };

  const setCallback = (callback) => {
    callbacks.push(callback);
  };

  return {
    init,
    setCallback,
    destroy,
  };
};

export const KeyCapture = _KeyCapture();
