<template>
  <div class="image">
    <div v-if="isImage">
      <img :src="data" />
    </div>
    <div v-if="!isImage">
      <div class="pdf_navi">
        <div class="pdf_navi--inner">
          <v-btn small icon @click="prev" :disabled="currentPage === 1">
            <v-icon color="primary">mdi-chevron-left</v-icon>
          </v-btn>
          <div class="pdf_navi--counter">
            {{ currentPage }} / {{ pageCount }}
          </div>
          <v-btn small icon @click="next" :disabled="currentPage === pageCount">
            <v-icon color="primary">mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
      <pdf
        :src="pdfData"
        :page="currentPage"
        @num-pages="pageCount = $event"
        @page-loaded="currentPage = $event"
      ></pdf>
    </div>
    <v-btn class="close" icon @click="$emit('close')">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </div>
</template>
<script>
import pdf from "vue-pdf";
export default {
  props: {
    file: {
      type: Object,
    },
  },
  data() {
    return {
      currentPage: 1,
      pageCount: 0,
    };
  },
  components: {
    pdf,
  },
  computed: {
    pdfData() {
      return this.file && this.file.type === "pdf"
        ? `data:application/pdf;base64,${this.file.data}`
        : null;
    },
    data() {
      return this.file.data ? this.file.data : null;
    },
    isImage() {
      const imgTypes = ["jpg", "png", "image"];
      return imgTypes.includes(this.file.type);
    },
  },

  methods: {
    prev() {
      this.currentPage = this.currentPage - 1;
    },
    next() {
      this.currentPage = this.currentPage + 1;
    },
  },
};
</script>
<style lang="scss" scoped>
.image {
  position: relative;

  img {
    width: 100%;
  }
}
.close {
  position: fixed;
  top: 48px;
  right: 48px;
  width: 24px;
  height: 24px;
  border-radius: 18px;
  background-color: #ccc;
  z-index: 99;
}

.pdf_navi {
  position: fixed;
  bottom: 64px;
  left: 0;
  width: 100%;
  z-index: 99;
  text-align: center;
}
.pdf_navi--counter {
  text-align: center;
  margin: 0 8px;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.pdf_navi--inner {
  display: inline-flex;
  justify-content: center;
  background-color: #efefef;
  padding: 4px 16px;
  border-radius: 4px;
}
</style>
