<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <!-- col 1  -->
                <v-col cols="12" sm="6" md="6">
                  <DeviceWorkClassifications
                    name="device_work_classifications"
                    :values="formValues"
                    :editable="editable"
                    @onInput="onInput"
                  />

                  <v-list-item>
                    <v-list-item-content>
                      <Label label="作業詳細">
                        <InputTextArea
                          name="work_details"
                          :values="formValues"
                          :editable="editable"
                          validation_label="作業詳細"
                          validation_rules="max:255"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <Label label="予想される危険">
                        <InputTextArea
                          name="expected_danger"
                          :values="formValues"
                          :editable="editable"
                          validation_label="予想される危険"
                          validation_rules="max:255"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>

                  <DeviceSafetyInstructions
                    name="device_safety_instructions"
                    :values="formValues"
                    :editable="editable"
                    @onInput="onInput"
                  />
                </v-col>

                <!-- col 2  -->
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <SwitchInput
                        name="status_code"
                        :label="`スポット作業`"
                        :values="workStatus"
                        :editable="editable"
                        @onInput="onInput"
                      />
                    </v-list-item-content>
                  </v-list-item>

                  <DeviceUsageTimes
                    name="device_usage_times"
                    :values="formValues"
                    :editable="editable"
                    @onInput="onInput"
                  />

                  <ConductorLeadingWorkers
                    name="conductor_leadingworkers"
                    :field_tree_id="item.field_tree_id"
                    :values="formValues"
                    :editable="editable"
                    @onInput="onInput"
                  />

                  <SectionLabel label="元請担当者" class="ml-4" />
                  <DeviceUsers
                    name="device_users"
                    :group_id="formValues.group_id"
                    :field_construction_id="formValues.field_construction_id"
                    :values="formValues"
                    :editable="editable"
                    @onInput="onInput"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputTextArea from "@/components/forms/elements/InputTextArea";

import DeviceWorkClassifications from "./components/DeviceWorkClassificationsInput.vue";
import DeviceSafetyInstructions from "./components/DeviceSafetyInstructionsInput.vue";
import DeviceUsageTimes from "./components/DeviceUsageTimesInput.vue";
import ConductorLeadingWorkers from "./components/CondutorLeadingWorkersInput.vue";
import DeviceUsers from "./components/DeviceUsersInput.vue";
import SwitchInput from "@/components/forms/elements/SwitchInput";
import { SWITCH_VALUE } from "@/constants/COMMON";

const WORK_STATUS = {
  OCCUPANCY: 1,
  SPOT: 2,
};

export default {
  data: () => {
    return {
      formValues: {},
      workStatus: { status_code: 0 },
    };
  },
  components: {
    Label,
    SectionLabel,
    InputTextArea,
    DeviceWorkClassifications,
    DeviceSafetyInstructions,
    DeviceUsers,
    ConductorLeadingWorkers,
    DeviceUsageTimes,
    SwitchInput,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
        if (this.formValues["device_work_classifications"]) {
          this.workStatus.status_code = this.formValues["status_code"] == WORK_STATUS.OCCUPANCY ? SWITCH_VALUE.OFF : SWITCH_VALUE.ON;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      const headerHeight = 48;
      let style = `height:${this.mainHeight - headerHeight}px;`;
      return style;
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      if(name == "status_code") {
        this.workStatus.status_code = value;
        this.formValues["status_code"] = value == SWITCH_VALUE.ON ? WORK_STATUS.SPOT : WORK_STATUS.OCCUPANCY;
      }
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
</style>
