import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const Action = {
  namespaced: true,

  state: {
    index: 0,
    actionSection: 0,
    isShowScroll: false,
    listData: [],
  },

  mutations: {
    SET_INDEX(state, payload) {
      state.index = payload;
    },
    SET_LIST_DATA(state, payload) {
      state.listData = payload;
    },
    SET_IS_SHOW_SCROLL(state, payload) {
      state.isShowScroll = payload;
    },
    SET_ACTION_SECTION(state, payload) {
      state.actionSection = payload;
    },
  },

  actions: {
    setIndex({ commit }, payload) {
      commit("SET_INDEX", payload);
    },
    setIsShowScroll({ commit }, payload) {
      commit("SET_IS_SHOW_SCROLL", payload);
    },
    setListData({ commit }, payload) {
      commit("SET_LIST_DATA", payload);
    },
    setActionSection({ commit }, payload) {
      commit("SET_ACTION_SECTION", payload);
    },
  },

  getters: {
    getIndex: (state) => {
      return state.index;
    },
    getIsShowScroll: (state) => {
      return state.isShowScroll;
    },
    getListData: (state) => {
      return state.listData;
    },
    getActionSection: (state) => {
      return state.actionSection;
    },
  },
};
