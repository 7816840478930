import Vue from "vue";
import Vuex from "vuex";
import { gates } from "../../../api/modules/gates";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = gates;

export const Gates = {
  namespaced: true,

  state: {
    data: [],
    pagination: {},
    gates: {},
    listGate: [],
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_GATE(state, payload) {
      state.gates = payload;
    },
    SET_LIST_GATE_BY_FIELD(state, payload) {
      state.listGate = payload;
    }
  },

  actions: {
    async get({ commit }, payload) {
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;
      
      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },
    async getDetail({ commit }, payload) {
      const response = await ENTITY.getDetail(payload);
      const entries =  response.data.contents.entries;
      commit("SET_GATE", entries);
      return response
    },
    
    async post(_context, payload) {
      return await ENTITY.post(payload);
    },

    async update(_context, payload) {
      return await ENTITY.update(payload);
    },

    async delete(_context, payload) {
      return await ENTITY.delete(payload);
    },

    async getListGateByField({ commit }, payload) {
      const response = await ENTITY.getListGateByField(payload);
      const contents = response.data.contents;
      commit("SET_LIST_GATE_BY_FIELD", contents.entries);
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getGates: (state) => {
      return state.gates;
    },
    getListGateByField: (state) => {
      return state.listGate;
    }
  },
};

