import { Store } from "@/store/Store";
import { LOGIN_FLAG_KEY } from "@/constants/LOGIN";

export const APP_LOGIN_TYPE = {
  DRAWING: "drawing", // appType:'drawing'
};

const showError = () => {
  console.log("■■■■■ showError");
  // unityへメッセージ送信
  window.open(
    'uniwebview://action?message="セッションの期限が切れました。再度ログインしてください。"'
  );
  setTimeout(() => {
    //webview閉じる
    window.open("uniwebview://action?func=close");
  }, 1000);
};

export const AppChatLogin = async ({ query, callback }) => {
  if (
    query.app === APP_LOGIN_TYPE.DRAWING &&
    "token" in query &&
    "fieldId" in query
  ) {
    const { token, fieldId } = query;

    // ストレージにトークンを保持
    sessionStorage.setItem(
      "GRN",
      JSON.stringify({ Login: { api_token: token } })
    );
    localStorage.setItem(LOGIN_FLAG_KEY, 1);

    // 再ログイン
    const loginResponse = await Store.dispatch("Login/loginReacqire");
    if (loginResponse.hasError) {
      showError();
      return;
    }

    //現場一覧を取得
    const sitesResponse = await Store.dispatch("GlobalHeader/searchSites", {
      field_id: +fieldId,
    });
    if (sitesResponse.hasError) {
      showError();
      return;
    }

    //現場を選択
    const result = await Store.dispatch("GlobalHeader/setSiteForApp", {
      field_id: +fieldId,
    });

    //アプリフラグ
    Store.dispatch("Login/isDrawingApp", true);

    // 成功
    if (result) {
      // チャットルームidがある場合はチャット画面へ遷移
      // TODO chatRoomid を数値でバリデート
      if ("chatRoomId" in query) {
        callback({ path: `/chat/${query.chatRoomId}/` });
      } else {
        // チャット一覧へ遷移
        callback({ path: "/room" });
      }
      return;
    } else {
      showError();
      callback({ path: "/" });
    }
  }
  callback(null);
};
