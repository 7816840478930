<template>
  <div class="popupReadUsers">
    <div class="header">
      <button class="btnBack" @click.prevent="$emit('close')">
        <ChevronLeft />
      </button>
      <div class="h1">{{ title }}</div>
    </div>
    <div class="body">
      <div class="users">
        <div class="user" v-for="user in users" :key="user.id">
          <div class="userImageWrapper">
            <UserImage :imageUrl="user.user_image_url" />
          </div>
          <div class="name">{{ user.username }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ChevronLeft from "@/components/icon/ChevronLeft.vue";
import UserImage from "./UserImage.vue";
export default {
  components: {
    UserImage,
    ChevronLeft,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    users: {
      type: Array,
    },
  },
};
</script>
<style lang="scss" scoped>
.popupReadUsers {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: white;

  .header {
    position: fixed;
    top: 0;
    left: 0;
    height: 48px;
    width: 100%;
    z-index: 1;
    background-color: white;
    border-bottom: 1px solid #efefef;
    display: flex;
    align-items: center;
  }

  .btnBack {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
  .h1 {
    font-size: 16px;
    flex: 1;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
  }
  .body {
    position: absolute;
    top: 48px;
    width: 100%;
    height: calc(100vh - 48px);
    overflow: auto;
  }
  .user {
    display: inline-block;

    .name {
      text-align: center;
    }
  }

  .userImageWrapper {
    width: 72px;
    height: 72px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
}
</style>
