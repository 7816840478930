<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <!--  (共通) 会社ヘッダー -->
        <CompanyHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout :layoutParams="layoutParams" :hideFooter="searchParams.pageCount >= searchParams.total_item">
          <template #tableHeader="{ updateHeader }">
            <!--  
              (共通) テーブルヘッダー 
              このなかに、新規作成ボタン、検索ボタン、一括削除ボタンが実装されてます
              @openRemoveDialog : 一括削除の確認ダイアログを開く
              @openItemForm : 新規作成フォームを開く
            -->
            <TableHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :multiRemoveStatus="disableRemoveBtn"
              :updateHeader="updateHeader"
              @openRemoveDialog="openRemoveDialog"
              @openItemForm="openNewItemForm"
            >
              <!-- 
                検索はテーブルごとに実装します 
                @onInput 
              -->
              <SearchFormWrapper>
                <!-- 文字入力 -->
                <Label label="支店名">
                  <InputText
                    name="word"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <!--  (共通) 検索ボタン -->
                <v-spacer></v-spacer>
                <v-btn class="mr-6" color="primary" depressed @click="onSearch">
                  検索
                </v-btn>
              </SearchFormWrapper>
            </TableHeader>

            <!-- 
              (共通)
              ソートのレイアウトを調整するラッパーコンポーネントです
            -->
            <TableSortWrapper>
              <!--  
                (共通) ソート 
                ソート項目、ソート順、表示件数の選択、総件数の表示
              -->
              <TableSort
                :values="searchParams"
                :sort_items="SORT_ITEMS"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                @onInput="onChangeSortParams"
              />
            </TableSortWrapper>
          </template>
          <!-- 
            (共通)テーブル
            v-data-table自体は共通ですが、
            カラムによって変更をしたい場合はそれぞれ実装してください。
          -->
          <template #tableBody="{ tableHeight }">
            <v-data-table
              v-model="selectedItems"
              :headers="TABLE_LABELS"
              :items="items"
              :items-per-page="searchParams.pageCount"
              :height="tableHeight"
              fixed-header
              hide-default-footer
              disable-sort
              class="elevation-0 v-data-table__wrapper"
              sort-by="updatedAt"
              show-select
              item-key="id"
              :noDataText="noDataMessage"
              @click:row="openItemForm"
            >
              <template v-slot:[`item.name`]="{ item }">
                <div>
                  {{ convertLongText(item.name, 35) }}
                </div>
              </template>
              <template v-slot:[`item.address`]="{ item }">
                <div>
                  {{ concatAddress(item) }}
                </div>
              </template>
            </v-data-table>
          </template>
          <template #tableFooter >
            <!-- (共通) ページネーション -->
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>
    <!-- 追加/編集ダイアログ -->
    <Popup :dialog="popups.isShowItemForm">
      <OfficeForm
        :isNewItem="isNewItem"
        :companyBranchId="company_branch_id"
        @cancel="closeItemForm"
      />
    </Popup>
    <!--  (共通) 削除ダイアログ / 文言変えてもok -->
    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :items="selectedItems"
        warning
      />
    </Popup>
  </div>
</template>

<script>
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import ConfirmRemoveDialog from "./components/ConfirmRemoveDialog";
import CompanyHeader from "@/components/companyHeader/CompanyHeader";
import Popup from "@/components/common/Popup";
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import { Store } from "@/store/Store.js";
import { OFFICES_TABLE_LABELS, OFFICE_SORT_ITEMS } from "@/constants/OFFICES"; //絞り込みフォームで使用
import { TABLES_PER_PAGE, TABLE_SORT_ORDERS, NO_DATA_MESSAGE } from "@/constants/COMMON";
import OfficeForm from "@/components/forms/offices/OfficeForm";
import { HEADER_MENU_ITEMS_INHOUSE } from "@/constants/GLOBALHEADER"; //グローバルヘッダーメニュー

//タイトル
const PAGE_TITLE = "支店";
// テーブル設定
const TABLE_LABELS = OFFICES_TABLE_LABELS;
//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;
// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;
//ソート要素
const SORT_ITEMS = OFFICE_SORT_ITEMS;
//ストア
const STORE = "Offices";
//１ページあたりのテーブル件数
const PAGE_COUNT = 25;

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      PAGE_TITLE,
      SORT_ITEMS,
      TABLE_LABELS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      NO_DATA_MESSAGE,
      /**
       * (共通)
       * 一覧データ
       */
      items: [],

      /**
       * (共通)
       * checkbox選択item
       */
      selectedItems: [],

      // 検索パラメータ
      searchParams: {
        word: "",
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: OFFICE_SORT_ITEMS[0].id, //sortする項目
        asc: true,
      },
      /**
       * (共通)
       * ポップアップの状態管理
       */
      popups: {
        // 追加/編集フォーム
        isShowItemForm: false,
        // 削除確認ダイアログ表示
        isShowRemoveDialog: false,
      },
      /**
       * (共通)
       * 新規フラグ
       */
      isNewItem: false,
      company_branch_id: null,
      noDataMessage: "",
    };
  },
  computed: {
    /**
     * (共通)
     * 削除ボタンの活性・非活性
     * selectedItems.length === 0
     * 自体はtemplate側で記述できますが、
     * ここに明示的に状態を定義して可読性を担保してます
     */
    disableRemoveBtn() {
      return this.selectedItems.length === 0;
    },
    /**
     *
     * API Param
     */
    apiParams() {
      const companyUser = JSON.parse(sessionStorage.getItem("COMPANY_USER"))
        .Login.company_user;
      return {
        company_id: companyUser.company_id,
        page_count: this.searchParams.pageCount,
        current_page: this.searchParams.currentPage,
        sort: this.searchParams.sort,
        asc: this.searchParams.asc ? 1 : 0,
        word: this.searchParams.word,
      };
    },
  },
  methods: {
    /**
     * (共通)
     * 検索
     */
    onSearch() {
      this.searchParams["currentPage"] = 1;
      this.getItems();
    },
    /**
     *  (共通)
     * 確認ダイアログ
     * 基本的に変更しないでいいはず
     */
    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },
    /**
     * (共通)
     * 並び替えパラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      name == "pageCount" ? (searchParams["currentPage"] = 1) : "";
      this.searchParams = searchParams;
      this.getItems();
    },
    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
    },
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },
    /**
     * (共通)
     * 作成/編集フォーム
     * INITIAL_ITEM で定義した値がitemに入ります
     * @param INITIAL_ITEMで定義している値
     */
    openNewItemForm() {
      this.company_branch_id = null;
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },
    async openItemForm(item) {
      this.company_branch_id = item.id;
      this.isNewItem = false;
      this.popups.isShowItemForm = true;
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.getItems();
    },
    // 削除
    async removeItems() {
      const ids = this.selectedItems.map((items) => items.id);
      const result = await Store.dispatch(`${STORE}/delete`, { ids });

      if (!result.hasError) {
        this.searchParams["currentPage"] = 1;
        //ダイアログ閉じる
        this.closeRemoveDialog();
        //成功したら値を更新
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
      }
    },
    /**
     * データ取得
     */
    async getItems() {
      Store.dispatch(`${STORE}/get`, { params: this.apiParams });
    },
    convertLongText(name, lengthText) {
      let result = "";
      if (name) {
        result = name;
        if (name.length > lengthText) {
          result = name.substring(0, lengthText) + "•••";
        }
      }
      return result;
    },

    concatAddress(item) {
      let addressInfo = [];
      addressInfo.push(item.prefecture);
      addressInfo.push(item.city_name);
      if (item.building_name) {
        addressInfo.push(item.building_name);
      }
      return this.convertLongText(addressInfo.join(" "), 60);
    }
  },
  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    /**
     * グローバルヘッダーメニューを更新
     */
    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_INHOUSE.OFFICES.id,
    });

    this.getItems();
    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data, oldData) => {
        /**
         *  (共通)
         * 一覧リストの格納
         */
        this.items = data[0];
        if (oldData && data[0] !== oldData[0] && this.items.length == 0) {
          this.noDataMessage = NO_DATA_MESSAGE;
        }
        /**
         *  (共通)
         * ページネーション更新
         */
        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  components: {
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    CompanyHeader,
    Popup,
    ConfirmRemoveDialog,
    Label,
    InputText,
    OfficeForm,
  },
};
</script>
