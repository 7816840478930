<template>
  <v-card class="mx-auto">
    <v-card-title>
      <div class="title">現場ユーザー選択</div>
      <v-spacer></v-spacer>
      <v-btn small icon text @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="body">
      <div class="dialog-wrapper">
        <p>工事</p>
        <Select
          class="select-position"
          name="field_construction_id"
          :values="work_group_plans"
          :editable="true"
          :items="CONSTRUCTION"
          validation_label="工事"
          validation_rules="required"
          @onInput="onSelect"
        />
        <p>作業グループ</p>
        <Select
          class="select-position"
          name="id"
          :values="work_group_plans"
          :editable="true"
          :items="WORKING_GROUP"
          item_text="group_name"
          item_value="id"
          placeholder=""
          validation_label="作業グループ"
          @onInput="onSelect"
        />
        <p>協力会社</p>
        <Select
          class="select-position"
          name="field_tree_id"
          :values="work_group_plans"
          :items="PARTNER_COMPANY"
          item_text="company_name"
          item_value="field_tree_id"
          :editable="true"
          validation_label="協力会社"
          validation_rules="required"
          @onInput="onSelect"
        />
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { format } from "date-fns";
import { Store } from "@/store/Store.js";
import Select from "../../elements/Select.vue";

/**
 *
 * 1. 作業一覧
 * https://shogatsu777.warasubo.jp/v1/api/fields/get_list_field_constructions/{id}
 *
 * 2. 作業グループ
 * https://shogatsu777.warasubo.jp/v1/api/field_trees/list_group/{id}/
 *
 * 3.協力会社
 * https://shogatsu777.warasubo.jp/v1/api/field_trees/list_company_partner/?field_tree_id_1={id}&state_handling_flag=0
 *
 * 4.現場監督?
 * https://shogatsu777.warasubo.jp/v1/api/field_trees/list_field_director/{id}/?state_handling_flag=0
 *
 * 5.職長?
 * https://shogatsu777.warasubo.jp/v1/api/field_trees/list_company_partner_user/{id}/?foreman_flag=1
 *
 * 6.作業員?
 * https://shogatsu777.warasubo.jp/v1/api/field_trees/list_company_partner_user/{id}/?foreman_flag=0
 */

export default {
  data: () => {
    return {
      fieldId: null,
      CONSTRUCTION: [],
      WORKING_GROUP: [
        {
          id: null,
          group_name: "",
        },
      ],
      PARTNER_COMPANY: [
        {
          field_tree_id: null,
          company_name: "",
        },
      ],
      FOREMAN: [],
      work_group_plans: {
        field_construction_id: null,
        id: null, //作業グループ
      },
    };
  },
  async mounted() {
    // 現在の現場id
    this.fieldId = Store.state.GlobalHeader.selectedSite.field_id;

    // 作業一覧取得
    let workTypes = await Store.dispatch(
      "PortalChart/getWorkTypes",
      this.fieldId
    );
    this.CONSTRUCTION = [
      ...this.CONSTRUCTION,
      ...workTypes.data.contents.entries,
    ];

    //工事の初期値を設定
    const field_construction_id = workTypes.data.contents?.entries[0]?.id;
    const work_group_plans = { ...this.work_group_plans };
    work_group_plans.field_construction_id = field_construction_id;
    this.work_group_plans = work_group_plans;
    // 初期値を設定した後の次のselectの一覧を取得する
    this.$nextTick(() => {
      this.onSelect({
        name: "field_construction_id",
        value: field_construction_id,
      });
    });
  },
  components: {
    Select,
  },
  methods: {
    async onSelect({ name, value }) {
      const work_group_plans = { ...this.work_group_plans };
      work_group_plans[name] = value;
      this.work_group_plans = work_group_plans;
      console.log("onSelect", { ...work_group_plans });

      // 作業グループ一覧
      if (name === "field_construction_id") {
        this.work_group_plans["id"] = null;
        this.work_group_plans["field_tree_id"] = null;
        this.WORKING_GROUP = [{ id: null, group_name: "" }];
        this.PARTNER_COMPANY = [
          { field_tree_id: null, company_name: "" },
        ];
        this.FOREMAN = [];
        let workingGroups = await Store.dispatch(
          "PortalChart/getListGroups",
          work_group_plans.field_construction_id
        );
        this.WORKING_GROUP = [
          ...this.WORKING_GROUP,
          ...workingGroups.data.contents.entries,
        ];
        this.getListCompanyPartner(work_group_plans);
      }

      // 協力会社一覧
      else if (name === "id") {
        this.work_group_plans["field_tree_id"] = null;
        this.PARTNER_COMPANY = [
          { field_tree_id: null, company_name: "" },
        ];
        this.FOREMAN = [];
        this.getListCompanyPartner(work_group_plans);
      }

      // 職長一覧
      else if (name === "field_tree_id") {
        if (!this.work_group_plans["id"] && value) {
          let groupId = this.getGroupdIdByFieldTree(value);
          let hasGroup = this.hasGroupById(groupId);
          if (hasGroup) {
            this.work_group_plans["id"] = groupId;
            this.getListCompanyPartner(work_group_plans);
          }
        }

        //全員
        let partnerUser = await Store.dispatch(
          `PortalChart/getListCompanyPartnerUser`,
          { id: work_group_plans.field_tree_id }
        );
        const _partnerUser = partnerUser.data.contents.entries;
        this.FOREMAN = [
          // apiのレスポンスによってuser_idしかない場合があるので、全てにidとuser_idを付与する
          ..._partnerUser.map((user) => {
            return {
              ...user,
              id: user?.id || user.user_id,
              user_id: user?.user_id || user.id,
            };
          }),
        ];
        this.$emit("loadedUsers", this.FOREMAN);
        this.$nextTick(() => {
          this.$emit("close");
        });
      }
    },

    async getListCompanyPartner(work_group_plans) {
      // 元請け企業を取得
      const field_tree_date = format(new Date(), "yyyy/MM/dd");
      const { company_name, id } = await Store.dispatch(
        "PortalChart/getChatFieldTreeCompany",
        {
          work_type_id: work_group_plans.field_construction_id, //工事id必須
          field_tree_date,
        }
      );

      // 協力会社
      let params = { state_handling_flag: 0 };
      if (work_group_plans.id) params.field_tree_group_id = work_group_plans.id;
      else if (work_group_plans.field_construction_id)
        params.field_construction_id = work_group_plans.field_construction_id;
      else return;
      let partnerCompany = await Store.dispatch(
        "PortalChart/getListCompanyPartner",
        { params }
      );
      // 元請けを追加
      this.PARTNER_COMPANY = [
        ...this.PARTNER_COMPANY,
        {
          company_name,
          id,
          field_tree_id: id,
        },
        ...partnerCompany.data.contents.entries,
      ];
    },

    getGroupdIdByFieldTree(fieldTreeId) {
      return (
        this.PARTNER_COMPANY.find((e) => e.field_tree_id === fieldTreeId)
          ?.field_tree_group_id || null
      );
    },
    hasGroupById(groupId) {
      if (!groupId) return false;
      let group = this.WORKING_GROUP.find((e) => e.id === groupId);
      if (group) return true;
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";

.v-input {
  z-index: 1000;
}

.dialog {
  padding-bottom: 20px;
  .header {
    .header-title-area {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      max-width: 100%;
    }
  }
  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;
      .text {
        text-align: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 80%;
      }
    }
  }
  .btn {
    margin-right: 12px;
  }
}
</style>
