<template>
  <v-form autocomplete="off">
    <v-container>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-row>
            <v-list-item>
              <v-list-item-content>
                <p class="ml-2">招待メールの送信先を入力してください。</p>
              </v-list-item-content>
            </v-list-item>
          </v-row>
          <v-row style="margin-top: -30px">
            <v-col cols="12" md="12">
              <v-list-item>
                <v-list-item-content>
                  <Label label="メールアドレス" required editable>
                    <InputText
                      name="email"
                      :values="formValue"
                      :editable="true"
                      validation_label="メールアドレス"
                      validation_rules="required|email|max:255"
                      @onInput="onchangeEmail"
                    />
                  </Label>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row style="margin-top: -30px">
            <v-list-item>
              <v-list-item-content>
                <v-col class="width-row">
                  <Label label="姓" required editable>
                    <InputText
                      name="name_sei"
                      :values="formValue"
                      :editable="true && !disable"
                      validation_label="姓"
                      validation_rules="required|max:64"
                      @onInput="onInput"
                    />
                  </Label>
                </v-col>
                <v-col class="width-row">
                  <Label label="名" required editable>
                    <InputText
                      name="name_mei"
                      :values="formValue"
                      :editable="true && !disable"
                      validation_label="名"
                      validation_rules="required|max:64"
                      @onInput="onInput"
                    />
                  </Label>
                </v-col>
              </v-list-item-content>
            </v-list-item>
          </v-row>
          <v-row style="margin-top: -40px">
            <v-col cols="12" md="12">
              <v-list-item>
                <v-list-item-content>
                  <Label label="メッセージ">
                    <InputTextArea
                      name="message"
                      :values="formValue"
                      :editable="true"
                      validation_label="メッセージ"
                      validation_rules="max:512"
                      @onInput="onInput"
                    />
                  </Label>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import Label from "@/components/forms/elements/Label";
import InputText from "@/components/forms/elements/InputText";
import InputTextArea from "@/components/forms/elements/InputTextArea.vue";
import { emailSearch } from "@/utils/emailSearch";
export default {
  data: () => {
    return {
      formValue: {},
      disable: false,
    };
  },
  components: {
    Label,
    InputText,
    InputTextArea,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValue = { ...newValue };
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValue = { ...this.formValue };
      formValue[name] = value;
      this.formValue = formValue;
      this.$emit("formUpdate", {"tab2": formValue});
    },
    async onchangeEmail({ value }) {
      const formValue = { ...this.formValue };
      formValue.email = value;
      if (value == "") {
        formValue.name_sei = "";
        formValue.name_mei = "";
        this.disable = false;
      } else if (
        value.match(/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,6})+$/)
      ) {
        const result = await emailSearch.search({ email: value });
        if (result.name_sei != "" && result.name_mei != "") {
          formValue.name_sei = result.name_sei;
          formValue.name_mei = result.name_mei;
          this.disable = true;
        } else {
          formValue.name_sei = "";
          formValue.name_mei = "";
          this.disable = false;
        }
      }
      this.formValue = formValue;
      this.$emit("formUpdate", {"tab2": formValue});
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
.width-row {
  max-width: 50%;
}
</style>
