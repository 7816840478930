<template>
  <FormDialog>
    <template #header>
      <!-- 
        (共通)
        ヘッダーは全て共通
       -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!editable && stateHandlingFlag == 0 && approvalFlagSelectedItems == 0"
          depressed
          small
          color="warning"
          class="mr-5"
          outlined
          @click="isShowScheduleDialog = true"
        >
          中止
        </v-btn>
        <v-btn
          v-if="!editable && stateHandlingFlag == 1 && groupStateHandlingFlag == 0"
          depressed
          small
          color="warning"
          class="mr-5"
          outlined
          @click="isShowScheduleDialog = true"
        >
          中止取消
        </v-btn>
        <v-btn
          v-if="!editable && stateHandlingFlag == 0 && approvalFlagSelectedItems == 0"
          depressed
          small
          class="mr-5"
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn
          v-if="editable"
          class="mr-5"
          depressed
          small
          outlined
          color="primary"
          @click="isShowConfirmDialog = true"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable && isShowButtonCopy"
          depressed
          class="mr-5"
          small
          color="primary"
          @click="onCopy"
        >
          前回コピー
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          small
          :disabled="!valid || isSubmitted"
          color="primary"
          @click="onSubmit"
        >
          保存
        </v-btn>
        <v-btn icon @click="onClickBtnClose" data-testid="btn-close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab
              v-for="(form, key) in FORMS"
              :key="key"
              :data-testid="`workerForm-tab-${key}`"
            >
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <ValidationObserver ref="observer" v-slot="observer">
        <!-- フォームtab -->
        <v-tabs-items v-model="tab">
          <!-- 基本情報 -->
          <v-tab-item v-if="ENV_OBAYASHI">
            <ScheduleCompanyResultObayashiPage1
              :editable="editable"
              :item="item"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item v-else>
            <ScheduleCompanyResultFormPage1
              :editable="editable"
              :item="item"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <!-- 建設業の許可 -->
          <v-tab-item>
            <ScheduleCompanyResultFormPage2
              :editable="editable"
              :item="item"
              :mainHeight="params.mainHeight"
              :errors="errors"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <ScheduleCompanyResultFormPage3
              :editable="editable"
              :item="item"
              :mainHeight="params.mainHeight"
              :errors="errors"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <ScheduleCompanyResultFormPage4
              :editable="editable"
              :item="item"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <ScheduleCompanyResultFormPage5
              :item="item.work_company_results"
              :mainHeight="params.mainHeight"
            />
          </v-tab-item>
        </v-tabs-items>
        <!-- 
          (共通)
          ValidationObserverのスロットプロパティを受け取って
          updateValidateメソッドを実行して、
          バリデーション結果をスクリプト側に渡してます
        -->
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="isShowConfirmDialog = false"
          @yes="closeForm"
          warning
        />
      </Popup>

      <Popup width="480px" :dialog="isShowScheduleDialog">
        <ConfirmScheduleDialog
          @close="isShowScheduleDialog = false"
          @yes="updateStateHandling()"
          :title="getTitle()"
          :text="getText()"
          :textBtn="getTextBtn()"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "../../elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import ScheduleCompanyResultFormPage1 from "@/components/forms/schedule/company/ScheduleCompanyResultFormPage1.vue";
import ScheduleCompanyResultObayashiPage1 from "@/components/forms/schedule/company/components/obayashi/ScheduleCompanyResultObayashiPage1.vue";
import ScheduleCompanyResultFormPage2 from "@/components/forms/schedule/company/ScheduleCompanyResultFormPage2.vue";
import ScheduleCompanyResultFormPage3 from "@/components/forms/schedule/company/ScheduleCompanyResultFormPage3.vue";
import ScheduleCompanyResultFormPage4 from "@/components/forms/schedule/company/ScheduleCompanyResultFormPage4.vue";
import ScheduleCompanyResultFormPage5 from "@/components/forms/schedule/company/ScheduleCompanyResultFormPage5.vue";
import ConfirmScheduleDialog from "./components/ConfirmScheduleDialog.vue";
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON.js";
import { time_convert, convertTimeZone } from "@/constants/SCHEDULE_COMPANY";
import { Store } from "@/store/Store.js";
import _ from "lodash";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
/**
 * (共通)
 * タイトル
 */
const TITLE = {
  NEW: "自社作業実績情報",
};

/**
 * フォームとタブの設定
 */
const FORMS = {
  CompanyFormResultPage1: { id: 1, title: "基本情報" },
  CompanyFormResultPage2: { id: 2, title: "作業内容" },
  CompanyFormResultPage3: { id: 3, title: "火気作業" },
  CompanyFormResultPage4: { id: 4, title: "酸素欠乏危険作業" },
  CompanyFormResultPage5: { id: 5, title: "登録・変更履歴" },
};

export default {
  data: () => {
    return {
      FORMS,
      valid: false, //バリデーション結果
      errors: {},
      // tab初期化
      tab: null,
      editable: false,
      isShowButtonCopy: false,
      isShowConfirmDialog: false,
      isShowScheduleDialog: false,
      isClickCloseBtn: false,
      stateHandlingFlag: 0,
      isSubmitted: false,
      timeout: null,
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    ScheduleCompanyResultFormPage1,
    ScheduleCompanyResultFormPage2,
    ScheduleCompanyResultFormPage3,
    ScheduleCompanyResultFormPage4,
    ScheduleCompanyResultFormPage5,
    ConfirmScheduleDialog,
    ScheduleCompanyResultObayashiPage1,
  },
  props: {
    item: Object,
    isNewItem: Boolean,
    beforeUpdateItem: Object,
    approvalFlagSelectedItems: Number,
    groupStateHandlingFlag: Number,
  },
  mounted() {
    /**
     * (共通)
     * 新規作成を監視
     */
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        //新規の場合は最初から編集モード
        if (flag) {
          this.editable = true;
          this.isShowButtonCopy = true;
        } 
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.item,
      (data) => {
        if (data) {
          this.stateHandlingFlag = this.item.work_company_results.state_handling_flag;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    // 新規or編集でタイトル変更
    formtitle() {
      return TITLE.NEW;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    ENV_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
  },
  methods: {
    /**
     * (共通)
     * フォームの変更を送信
     */
    formUpdate(params) {
      this.$emit("formUpdate", params);
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      this.editable = true;
    },

    /**
     * (共通)
     * 状態を詳細に変更
     */
    onDetail() {
      this.editable = false;
    },

    /**
     * (共通)
     * 登録
     */
    onSubmit() {
      this.isSubmitted = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isSubmitted = false
      }, SUBMIT_DELAY_TIMEOUT);
      this.$emit("submit");
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid, errors }) {
      this.valid = valid;
      this.errors = errors;
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        const item = _.cloneDeep(this.beforeUpdateItem);
        this.$emit("formUpdate", item);
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },

    closePopup() {
      this.isShowConfirmDialog = false;
    },
    /**
     * (共通)
     * click button Close (X)
     */
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },
    async onCopy() {
      const params = {
        field_id: this.CURRENT_SITE.field_id
      }
      const result = await Store.dispatch("ScheduleCompany/getWorkCompanyLastTimeResults", params);
      if (result.hasError || !result.data.contents.entries) {
        return;
      }
      let _item = { ...result.data.contents.entries };
      _item.work_company_results = {
        ...this.item.work_company_results,
        ..._item.work_company_results
      };
      _item.work_company_results.earlytime_end = convertTimeZone(this.item.work_groups.work_hour_zone_start_time);
      _item.work_company_results.overtime_start = convertTimeZone(this.item.work_groups.work_hour_zone_end_time);
      _item.work_company_results.earlytime_start = _item.work_company_results.earlytime_hour != "0.00" ? this.calculateTimeEarlyTimeStart(_item.work_company_results) : null;
      _item.work_company_results.overtime_end =_item.work_company_results.overtime_hour != "0.00" ? this.calculateTimeOverTimeEnd(_item.work_company_results) : null;
      if(_item.work_company_results.field_tree_id != this.item.works.field_tree_id) {
        _item.work_company_results.employee_user_id = this.item.work_company_results.employee_user_id;
        _item.work_company_user_results = this.item.work_company_user_results;
      }
      _item.work_company_time_results = this.item.work_company_time_results;
      _item.works = this.item.works;
      _item.work_groups = this.item.work_groups;
      this.$emit("formUpdate", _item);
    },

    calculateTimeEarlyTimeStart(itemEdited) {
      let timeSplit = itemEdited.earlytime_end.split(":");
      let time = parseInt(timeSplit[0]) * 60 + parseInt(timeSplit[1]) - itemEdited.earlytime_hour * 60;
      if(time < 0) {
        time = time + 24 * 60;
      }
      return time_convert(time);
    },

    calculateTimeOverTimeEnd(itemEdited) {
      let timeSplit = itemEdited.overtime_start.split(":");
      let time = parseInt(timeSplit[0]) * 60 + parseInt(timeSplit[1]) + itemEdited.overtime_hour * 60;
      time = time % (24 * 60);
      return time_convert(time);
    },

    getTitle() {
      return this.stateHandlingFlag == 0 ? "予定の中止" : "中止取消の確認";
    },
    getText() {
      return this.stateHandlingFlag == 0
        ? "作業予定を中止します"
        : "中止した作業予定を解除してもよろしいですか。";
    },
    getTextBtn() {
      return this.stateHandlingFlag == 0 ? "中止" : "解除";
    },
    async updateStateHandling() {
      const apiParams = {
        work_company_result_id: this.item.work_company_results.id,
        state_handling_flag: this.stateHandlingFlag == 0 ? 1 : 0,
      };
      this.isShowScheduleDialog = false;
      const result =await Store.dispatch("ScheduleCompany/updateStateHandlingResults", apiParams);
      if (result.hasError) {
        return;
      } else {
        Store.dispatch("Toast/show", {
          status: 200,
          message: this.stateHandlingFlag == 0 ? "中止しました" : "中止取消しました",
        });
      }
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
