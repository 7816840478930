import Vue from "vue";
import Vuex from "vuex";
import { customizes } from "../../../api/modules/customizes";

Vue.use(Vuex);

/**
 * 施工体制図/現場監督APIを管理するstore
 */

// api名を指定
const ENTITY = customizes;

export const Customizes = {
  namespaced: true,

  state: {
    data: {},
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
  },

  actions: {
    async get({ commit }, payload) {
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_DATA", entries);
      return response;
    },

    async updateItem(_context, payload) {
      return await ENTITY.updateItem(payload);
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
  },
};
