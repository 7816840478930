<template>
  <div class="locationTree">
    <div v-for="(location, index) in cells" :key="index">
      <div class="locationTree__wrapper" :class="depth === 0 ? 'root' : ''">
        <div class="locationTree__parent">
          <ValidationProvider
            v-slot="{ errors, valid }"
            name="作業場所"
            rules="required|max:255"
          >
            <v-text-field
              v-model="location.item_name"
              :error-messages="errors"
              :success="valid"
              :readonly="!editable"
              filled
              @change="updateName($event, index)"
            >
              <!-- {{ location.name }} -->
              <template v-slot:append-outer>
                <!-- 削除ボタン -->
                <v-icon
                  class="remove-icon"
                  v-if="editable"
                  @click="removeLocation(index)"
                  >mdi-delete-outline</v-icon
                >
                <!-- 追加ボタン 階層3では非表示 -->
                <v-icon
                  color="primary"
                  v-if="editable"
                  @click="addLocation(index)"
                  >mdi-plus</v-icon
                >
                <v-icon v-if="!editable"
                  >mdi-chevron-right</v-icon
                >
              </template>
            </v-text-field>
          </ValidationProvider>
        </div>

        <!-- location に child locationsがあれば続けて表示 -->
        <div class="locationTree__children">
          <div
            v-if="location.children && location.children.length"
            class="ml-4"
          >
            <LocationTree
              :locations="location.children"
              :editable="editable ? true : false"
              :filled="editable"
              :depth="depth + 1"
              :path="getPath(index)"
              :push="push"
              :update="update"
              :remove="remove"
            ></LocationTree>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "LocationTree",
  data: () => {
    return {
      cells: [],
    };
  },
  components: {
    ValidationProvider,
  },

  props: {
    locations: Array,
    editable: Boolean,
    push: {
      type: Function,
    },
    update: {
      type: Function,
    },
    remove: {
      type: Function,
    },
    depth: {
      type: Number,
    },
    path: {
      type: String,
      default: null,
    },
  },

  mounted() {
    this.$watch(
      () => this.locations,
      (newValue) => {
        this.cells = newValue;
      },
      { immediate: true, deep: true }
    );
  },

  methods: {
    getPath(index) {
      return this.path ? `${this.path}_${index}` : `${index}`;
    },
    /**
     * 名前を更新
     */
    updateName(val, index) {
      const path = this.getPath(index);
      this.update({ path: `${path}`, index, name: val });
    },
    addLocation(index) {
      const path = this.getPath(index);
      this.push({ path: `${path}`, index });
      // this.locations[index].children.push({
      //   name: "",
      //   children: [],
      // });
    },
    removeLocation(index) {
      console.log("remove", index);
      const path = this.getPath(index);
      this.remove({ path: `${path}`, index });
      // this.locations.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.v-text-field {
  width: 200px;
}
::v-deep .v-input--is-readonly > .v-input__control > .v-input__slot {
  &:after {
    display: none !important;
  }
}
::v-deep .v-input--is-readonly > .v-input__control > .v-input__slot {
  &:before {
    border-color: transparent !important;
  }
}
::v-deep .v-input--is-readonly > .v-input__control > .v-input__slot {
  //background-color: #fcfcfc !important;
}
::v-deep .v-input__slot {
  min-height: 24px !important;
}

::v-deep .v-input__prepend-inner {
  margin-top: 0 !important;
}

::v-deep .v-text-field--enclosed .v-input__append-outer,
::v-deep .v-text-field--enclosed .v-input__prepend-outer,
::v-deep .v-text-field--full-width .v-input__prepend-outer {
  margin-top: 4px !important;
}

button.remove-icon {
  color: #bbbbbb;
}
.locationTree__wrapper {
  display: flex;
  &.root {
    position: relative;
    margin-bottom: 8px;
    padding-left: 16px;
    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      height: 100%;
      width: 6px;
      background-color: $color_primary_sub;
    }
  }
}
.locationTree__parent {
  // border: 1px solid red;
}
.locationTree__children {
  // border: 1px solid blue;
}
</style>
