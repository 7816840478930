var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"elevation-0 v-data-table__wrapper",attrs:{"headers":_vm.tableLabels,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"height":_vm.tableHeight,"fixed-header":"","hide-default-footer":"","disable-sort":"","show-select":!_vm.isTableTypeAll,"sort-by":"updatedAt","noDataText":_vm.NO_DATA_MESSAGE},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return _vm._l((items),function(item){return _c('tbody',{key:item.schedule_id},[_c('tr',{on:{"click":function($event){return _vm.$emit('openItemForm', item)}}},[_c('td',{staticClass:"start"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.toggleShowChildren(item.schedule_id)}}},[_vm._v(" "+_vm._s(_vm.isShowChildren[item.schedule_id] ? "mdi-chevron-down" : "mdi-chevron-right")+" ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(!_vm.isTableTypeAll)?_c('v-simple-checkbox',{attrs:{"value":_vm.isSelected(item.schedule_id)},on:{"input":function($event){return _vm.updateCheckbox(item, $event)}}}):_vm._e()],1)],1)],1),_c('td',[_vm._v(_vm._s(item.approve_name))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.job_name))]),_c('td',[_vm._v(" "+_vm._s(item.children_description.join(", "))+" ")]),_c('td',[_vm._v(_vm._s(item.description))]),_c('td',[_vm._v(_vm._s(item.attendance_name))]),_c('td',[_vm._v(_vm._s(item.work_time))])]),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowChildren[item.schedule_id]),expression:"isShowChildren[item.schedule_id]"}]},[_c('td',{attrs:{"colspan":"8"}},[_c('v-data-table',{staticClass:"elevation-0 v-data-table__wrapper",attrs:{"items":item.children,"hide-default-header":"","hide-default-footer":"","disable-sort":"","sort-by":"updatedAt","show-select":!_vm.isTableTypeAll,"noDataText":_vm.NO_DATA_MESSAGE,"disable-pagination":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return _vm._l((items),function(item){return _c('tbody',{key:item.schedule_id},[_c('tr',{staticClass:"child-table",on:{"click":function($event){return _vm.$emit('openItemForm', item)}}},[_c('td',{staticClass:"start"},[(!_vm.isTableTypeAll)?_c('v-simple-checkbox',{attrs:{"value":_vm.isSelected(item.schedule_id)},on:{"input":function($event){return _vm.updateCheckbox(item, $event)}}}):_vm._e()],1),_c('td',[_vm._v(_vm._s(item.approve_name))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.job_name))]),_c('td',[_vm._v(" "+_vm._s(item.children_description.join(", "))+" ")]),_c('td',[_vm._v(_vm._s(item.description))]),_c('td',[_vm._v(" "+_vm._s(item.attendance_name)+" ")]),_c('td',[_vm._v(_vm._s(item.work_time))])])])})}}],null,true)})],1)]),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowChildren[item.schedule_id]),expression:"isShowChildren[item.schedule_id]"}]},[_c('td',{attrs:{"colspan":"8"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"mr-4 display-more",attrs:{"x-small":"","depressed":"","color":"#E5E5E5"},on:{"click":function($event){return _vm.$emit('getChildItems', item.schedule_parent_id)}}},[_vm._v("さらに表示する "),_c('v-badge',{staticClass:"display-more-number",attrs:{"inline":"","color":"#767676","content":_vm.childTableDisplayMoreNumber(
                      item.total_children_item,
                      item.children.length
                    )}})],1),_c('v-btn',{staticClass:"add-new-child",attrs:{"depressed":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.$emit('addNewChild', item.schedule_parent_id)}}},[_vm._v("作業を新規追加")])],1)])])])})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }