<template>
  <v-card flat>
    <v-card-text>
      <v-form autocomplete="off">
        <v-container>
          <v-row no-gutters>
            <!-- 現場監督:leftInspection -->
            <v-col cols="12" sm="6">
              <div class="text-right">
                <v-btn
                  :disabled="isLeftInspectionSelected"
                  color="primary"
                  @click="addInspec"
                >登録
                </v-btn>
              </div>
              <!-- テーブル -->
              <v-data-table
                :headers="INSPECTION_LEFT_TABLE_LABELS"
                v-model="selectedLeftInspec"
                :items="leftInspection"
                hide-default-footer
                :items-per-page="leftInspection.length"
                disable-sort
                class="elevation-0 v-data-table__wrapper"
                show-select
                outlined
                item-key="machine_company_inspection_list_id"
                :height="mainHeight-36"
                :noDataText="NO_DATA_MESSAGE"
              >
              </v-data-table>
            </v-col>
            <!-- 現場監督:rightInspection -->
            <v-col cols="12" sm="6">
              <div>
                <div class="row">
                  <div class="col-sm-7 text-right">
                    <v-btn outlined 
                      color="primary"
                      @click="addInspection"
                    ><v-icon>mdi-plus</v-icon>フリー入力</v-btn>
                  </div>
                  <div class="col-sm-5 text-right pr-15">
                    <v-btn
                      :disabled="isRightInspectionSelected"
                      class="primary"
                      @click="removeInspec"
                    >解除</v-btn>
                  </div>
                </div>
                <!-- テーブル -->
                <v-data-table
                  v-model="selectedRighInspec"
                  :headers="INSPECTION_RIGHT_TABLE_LABELS"
                  :items="rightInspection"
                  hide-default-footer
                  :items-per-page="rightInspection.length"
                  disable-sort
                  class="elevation-0 v-data-table__wrapper"
                  show-select
                  item-key="machine_company_inspection_list_id"
                  :height="mainHeight-36"
                  :noDataText="NO_DATA_MESSAGE"
                >
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <Popup width="480px" :dialog="showPopupAddInspec">
          <FormAddInspection
            title="点検項目追加"
            @close="closePopupAddInspec()"
            @addInspec="addNewInspection"
          />
      </Popup>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import {
  INSPECTION_LEFT_TABLE_LABELS,
  INSPECTION_RIGHT_TABLE_LABELS,
  TYPE_ACTION,
} from "@/constants/DEVICE_STOCK";
import { NO_DATA_MESSAGE } from "@/constants/COMMON.js";
import FormDialog from "@/components/dialog/FormDialog.vue";
import Popup from "@/components/common/Popup.vue";
import FormAddInspection from "./components/FormAddInspection.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";

export default {
  data: () => {
    return {
      INSPECTION_LEFT_TABLE_LABELS,
      INSPECTION_RIGHT_TABLE_LABELS,
      TYPE_ACTION,
      NO_DATA_MESSAGE,
      leftInspection: [],
      rightInspection: [],
      selectedLeftInspec: [],
      selectedRighInspec: [],
      formValues: {},
      showPopupAddInspec: false,
    };
  },
  components: {
    FormDialog,
    Popup,
    ConfirmCloseDialog,
    FormAddInspection,
  },
  props: {
    item: Object,
    mainHeight: {
      type: Number,
      default: 0
    },
    editable : Boolean
  },
  mounted() {
    this.$watch(
      () => this.item.machine_company_field_inspection_items,
      (newValue,oldValue) => {
        if(JSON.stringify(newValue) !== JSON.stringify(oldValue) && Array.isArray(newValue)) {
          this.rightInspection = this.getDataRightInspection(this.item.dataInspectionDaily,newValue);
          if (this.item.dataInspectionDaily?.length > 0) {
            this.leftInspection = this.getDataLeftInspection(this.item.dataInspectionDaily, newValue);
          } else {
            this.leftInspection = [];
          }
        }
      },
      {
        deep: true,
        immediate: true
      }
    );
  },
  computed: {
    isLeftInspectionSelected() {
      return this.selectedLeftInspec.length === 0;
    },
    isRightInspectionSelected() {
      return this.selectedRighInspec.length === 0;
    }
  },
  methods: {
    removeInspec() {
      this.moveItems(this.rightInspection, this.selectedRighInspec, TYPE_ACTION.REMOVE);
    },

    addInspec() {
      this.moveItems(this.leftInspection, this.selectedLeftInspec, TYPE_ACTION.ADD);
    },

    /**
     * Function move item betwen two table
     */
    moveItems(items, selectedItems, type) {
      // Delete item
      selectedItems.forEach(e => {
        items = items.filter((i) => i.machine_company_inspection_list_id !== e.machine_company_inspection_list_id);
      });
      // Add item
      let dataItems = type == TYPE_ACTION.ADD ? [...this.rightInspection] : [...this.leftInspection];
      selectedItems.forEach(e => {
        if (!e?.isAddNew) dataItems.push(e);
      });
      if (type == TYPE_ACTION.ADD) {
        this.selectedLeftInspec = [];
        this.rightInspection = [...dataItems];
        this.leftInspection = [...items];
      } else {
        this.selectedRighInspec = [];
        this.leftInspection = [...dataItems];
        this.rightInspection = [...items];
      }
      this.formUpdate();
    },

    addInspection() {
      this.showPopupAddInspec = true;
    },

    closePopupAddInspec() {
      this.showPopupAddInspec = false;
    },

    addNewInspection(inspection) {
      // Find id max in two table
      let idMax = this.findIdMaxInTwoTable(this.getIdMax(this.rightInspection), this.getIdMax(this.leftInspection));
      this.rightInspection.push({
        isAddNew: true,
        machine_company_inspection_list_id: parseInt(idMax + 1),
        inspection_item: inspection.inspection_item,
        main_point: inspection.main_point
      });
      this.closePopupAddInspec();
      this.formUpdate();
    },

    getIdMax(items) {
      if (items.length == 0) return 0;
      else {
        return items.sort((e1, e2) => {
          return e1.machine_company_inspection_list_id - e2.machine_company_inspection_list_id;
        }).at(-1)?.machine_company_inspection_list_id;
      }
    },

    findIdMaxInTwoTable(val1, val2) {
      return val1 >= val2 ? val1 : val2;
    },

    formUpdate() {
      let _formValues = {...this.item };
      _formValues.machine_company_field_inspection_items = [...this.rightInspection];
      this.$emit("formUpdate", _formValues);
    },

    getDataLeftInspection(arrList, arrRight) {
      let rs = [];
      arrList.forEach(e => {
        if (!arrRight.find(e1 => e1.machine_company_inspection_list_id == e.id)) {
          rs.push({
            machine_company_inspection_list_id: e.id,
            inspection_item: e.inspection_item,
            main_point: e.main_point
          });
        }
      });
      return rs;
    },

    getDataRightInspection(arrList , arrRight){
      let rs = [];
      let _arrList = arrList ? arrList : [];
      let max = _arrList.length > 0 ? _arrList.at(-1).id : 0;
      arrRight.forEach(e => {
        if (e.machine_company_inspection_list_id) {
          rs.push(e);
        } else {
          rs.push({
            id: e.id,
            isAddNew: true,
            machine_company_inspection_list_id: max + 1,
            inspection_item: e.inspection_item,
            main_point: e.main_point
          });
          max++;
        }
      });
      return rs;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
::v-deep .v-data-table .v-data-table__wrapper table thead tr {
  position: sticky;
  z-index: 2;
  top: 0;
  background-color: #FFFFFF;
}
</style>
