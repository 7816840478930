import Api, { Mock } from "../api";
import mock_employees from "./mock/employees.json";
import mock_employee_detail from "./mock/employee_detail.json";
import mock_user_experiences from "./mock/mock_user_experiences.json";

const EMPLOYEES_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/users`;
const GET_LIST_URL = `${EMPLOYEES_BASE_URL}/list`;
const DELETE_URL = `${EMPLOYEES_BASE_URL}/delete`;
const INVITE_EMPLOYEE_URL = `${EMPLOYEES_BASE_URL}/invite_company`;
const CREATE_EMPLOYEE_URL = `${EMPLOYEES_BASE_URL}/create`;
const UPDATE_EMPLOYEE_URL = `${EMPLOYEES_BASE_URL}/update`;
const GET_DETAIL_EMPLOYEE_URL = `${EMPLOYEES_BASE_URL}/detail`;
const GET_USER_EXPERIENCES_URL = `${EMPLOYEES_BASE_URL}/get_user_experiences`;
const URL_CHECK_IS_DIRECTOR = `${EMPLOYEES_BASE_URL}/is_field_director`;
const URL_CHECK_IS_FOREMAN = `${EMPLOYEES_BASE_URL}/is_field_foreman`;
const URL_CHECK_FIELD_DIRECTOR = `${EMPLOYEES_BASE_URL}/is_field_director_or_foreman`;
const UPDATE_STOP_EMPLOYEE_URL = `${EMPLOYEES_BASE_URL}/update_status`;
const ADD_USER_INFO = `${EMPLOYEES_BASE_URL}/add_all_users`;
const URL_GET_ALL_USER = `${EMPLOYEES_BASE_URL}/list_by_name`;

// .envのVUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  Mock.onGet(GET_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_employees,
  });

  Mock.onGet(new RegExp(`${GET_DETAIL_EMPLOYEE_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_employee_detail,
  });

  Mock.onPost(CREATE_EMPLOYEE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_employees,
  });
  Mock.onPost(UPDATE_EMPLOYEE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(DELETE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(INVITE_EMPLOYEE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(new RegExp(`${GET_USER_EXPERIENCES_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_user_experiences,
  });
  Mock.onGet(new RegExp(URL_CHECK_IS_DIRECTOR)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: {
        is_field_director: true,
      },
    },
  });
  Mock.onGet(new RegExp(URL_CHECK_FIELD_DIRECTOR)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: {
        is_field_director_or_foreman: true,
      },
    },
  });
  Mock.onPost(UPDATE_STOP_EMPLOYEE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(ADD_USER_INFO).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_employees,
  });
  Mock.onGet(new RegExp(URL_GET_ALL_USER)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
}

export const employees = {
  get: (params) => {
    return Api.get(GET_LIST_URL, params);
  },
  getDetail: async (params) => {
    return Api.get(`${GET_DETAIL_EMPLOYEE_URL}/${params}`);
  },
  post: (params) => {
    return Api.post(CREATE_EMPLOYEE_URL, params);
  },
  update: async (params) => {
    return Api.post(UPDATE_EMPLOYEE_URL, params);
  },
  delete: async (params) => {
    return Api.post(DELETE_URL, params);
  },
  inviteEmployee: async (params) => {
    return Api.post(INVITE_EMPLOYEE_URL, params);
  },
  getUserExperiences: async (params) => {
    return Api.get(`${GET_USER_EXPERIENCES_URL}/${params}`);
  },
  checkIsDirector: async (params) => {
    return Api.get(URL_CHECK_IS_DIRECTOR, params);
  },
  checkIsForeman: async (params) => {
    return Api.get(URL_CHECK_IS_FOREMAN, params);
  },
  checkIsFieldDirector: async (params) => {
    console.log("■", URL_CHECK_FIELD_DIRECTOR, params);
    return Api.get(URL_CHECK_FIELD_DIRECTOR, { params });
  },
  updateStopUser: async (params) => {
    return Api.post(UPDATE_STOP_EMPLOYEE_URL, params);
  },
  addUserInfo: (params) => {
    return Api.post(ADD_USER_INFO, params);
  },
  getAllUser: (params) => {
    return Api.get(URL_GET_ALL_USER, { params });
  },
};
