<template>
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <!-- <FormWorkOccupation
                    name="work_company_classification_plans"
                    :values="formValues"
                    :editable="editable"
                    @onInput="onInput"
                  /> -->
                  <SectionLabel v-if="ENV_OBAYASHI" label="作業詳細" />
                  <v-row v-if="ENV_OBAYASHI">
                    <v-col cols="12" sm="9" md="9" class="ml-3">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="作業内容" :editable="editable">
                            <InputTextArea
                              name="contents"
                              :values="formValues.work_company_plans"
                              :editable="editable"
                              placeholder=""
                              validation_label="作業内容'"
                              validation_rules="max:255"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="mt-8">
                      <v-list-item class="pr-0">
                        <v-list-item-content>
                          <v-btn v-if="editable" :disabled="disableBtn" depressed small color="primary" @click="openFormSelectDialog()">履歴</v-btn>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-col cols="12" sm="12" md="12" v-if="!ENV_OBAYASHI">
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="作業詳細" :editable="editable">
                          <InputTextArea
                            name="contents"
                            :values="formValues.work_company_plans"
                            :editable="editable"
                            placeholder=""
                            validation_label="作業詳細"
                            validation_rules="max:255"
                            @onInput="onInput"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <span v-if="!ENV_OBAYASHI"><v-btn v-if="editable" :disabled="disableBtn" depressed small color="primary" @click="openFormSelectDialog()">履歴</v-btn></span>
                  <v-col cols="12" sm="12" md="12">
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="備考" :editable="editable">
                          <InputTextArea
                            name="remarks"
                            :values="formValues.work_company_plans"
                            :editable="editable"
                            placeholder=""
                            validation_label="備考"
                            validation_rules="max:512"
                            @onInput="onInput"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <FormPredictedRisk
                      name="work_company_predicted_risk_plans"
                      :values="formValues"
                      :editable="editable"
                      @onInput="onInput"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <FormSafetyIntruction
                      name="work_company_safety_instruction_plans"
                      :values="formValues"
                      :editable="editable"
                      @onInput="onInput"
                    />
                  </v-col>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <FormPurposeOfUse2
                    name="work_company_time_plans"
                    :values="formValues"
                    :editable="editable"
                    :timeZone="timeZone"
                    @onInput="onInput"
                  />
                  <v-list-item>
                    <v-list-item-content>
                      <WorkEarlyTimes
                        name="work_company_plans"
                        :values="formValues"
                        :editable="editable"
                        :timeZone="timeZone"
                        @onInput="onInput"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <WorkOverTimes
                        name="work_company_plans"
                        :values="formValues"
                        :editable="editable"
                        :timeZone="timeZone"
                        @onInput="onInput"
                    /></v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    <Popup width="480px" :dialog="isShowSearchDialog">
      <HistorySelectDialog
        nameStore="ScheduleCompany"
        name="Plan"
        :field_tree_id ="formValues.works ? formValues.works.field_tree_id : null"
        @close="isShowSearchDialog = false"
        @updateCommentHistory="updateCommentHistory"
      />
    </Popup>
  </div>
</template>
<script>
import Label from "../../elements/Label";
import InputText from "../../elements/InputText";
import InputTextArea from "../../elements/InputTextArea.vue";
import Select from "../../elements/Select.vue";
import FormWorkOccupation from "./components/FormWorkOccupation.vue";
import WorkEarlyTimes from "./components/WorkEarlyTimes.vue";
import WorkOverTimes from "./components/WorkOverTimes.vue";
import FormPurposeOfUse2 from "./components/FormPurposeOfUse2.vue";
import FormPredictedRisk from "./components/FormPredictedRisk.vue";
import FormSafetyIntruction from "./components/FormSafetyIntruction.vue";
import { convertTimeZone } from "@/constants/SCHEDULE_COMPANY";
import Popup from "@/components/common/Popup";
import HistorySelectDialog from "../components/HistorySelectDialog.vue";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
import SectionLabel from "@/components/forms/elements/SectionLabel";

export default {
  data: () => {
    return {
      formValues: {},
      timeZone: null,
      isShowSearchDialog: false,
    };
  },
  components: {
    Label,
    InputText,
    InputTextArea,
    Select,
    FormWorkOccupation,
    WorkEarlyTimes,
    WorkOverTimes,
    FormPurposeOfUse2,
    FormPredictedRisk,
    FormSafetyIntruction,
    Popup,
    HistorySelectDialog,
    SectionLabel,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
        this.timeZone = {
          id_start_time : this.formValues.work_groups.work_hour_zone_start_time.substring(0,5),
          start_time : convertTimeZone(this.formValues.work_groups.work_hour_zone_start_time),
          id_end_time : this.formValues.work_groups.work_hour_zone_end_time.substring(0,5),
          end_time : convertTimeZone(this.formValues.work_groups.work_hour_zone_end_time)
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    disableBtn(){
      return this.formValues.works ? this.formValues.works.field_tree_id == null : true;
    },
    ENV_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      if(name == "contents" || name == "remarks") {
        formValues["work_company_plans"][name] = value;
      } else {
        formValues[name] = value;
      }
      this.$emit("formUpdate", formValues);
    },
    openFormSelectDialog(){
      this.isShowSearchDialog = true;
    },
    updateCommentHistory(item){
      this.isShowSearchDialog = false;
      const formValues = { ...this.formValues };
      formValues['work_company_plans']['contents'] = item;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
</style>
