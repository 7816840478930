<template>
  <FormDialog>
    <template #header>
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title style="color: #1B9C4F">
          {{ form_title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          small
          color="primary"
          @click="onShowSendResultDialog"
          :disabled="selectedItems.length == 0 || roleUser != ROLE_USER[4] "
        >
          回答
        </v-btn>
        <v-btn icon @click="closeForm">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in TAB_NAME" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <FormMachineName
            :item="items"
            :flagEstimate="flagEstimate"
            :mainHeight="params.mainHeight"
            :roleUser="roleUser"
            :resetSelected="resetSelected"
            @updateSelectedItems="updateSelectedItems"
            @updateDetailMachine="updateDetailMachine"
          />
        </v-tab-item>
        <v-tab-item>
          <FormQuotation
            :item="items"
            :mainHeight="params.mainHeight"
            :roleUser="roleUser"
            @updateDetailMachine="updateDetailMachine"
            @createReport="createReport"
          />
        </v-tab-item>
      </v-tabs-items>

      <Popup width="480px" :dialog="isShowSendResultDialog">
        <SendResultDialog
          :flagHandleConfirm="flagHandleConfirm"
          :flagEstimate="flagEstimate"
          @close="closeSendResultDialog"
          @yes="onSubmitSendResult"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>
<script>

import { Store } from "@/store/Store.js";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import FormMachineName from "./FormMachineName.vue";
import FormQuotation from "./FormQuotation.vue";
import SendResultDialog from "./components/SendResultDialog.vue";
import { ROLE_USER, TAB_NAME_ESTIMATE, TAB_NAME_ORDER } from "@/constants/DEVICE_COORDINATOR";

const STORE = "DeviceCoordinator";

export default {
  data: () => {
    return {
      TAB_NAME_ESTIMATE,
      TAB_NAME_ORDER,
      ROLE_USER,
      items: {},
      tab: null,
      isShowConfirmDialog: false,
      isShowSendResultDialog: false,
      flagHandleConfirm: false,
      selectedItems: [],
      resetSelected: false
    };
  },
  components: {
    Popup,
    FormDialog,
    FormMachineName,
    FormQuotation,
    SendResultDialog
  },
  props: {
    flagEstimate: Boolean,
    roleUser: Number
  },
  computed: {
    form_title() {
      return this.flagEstimate ? "見積情報" : "発注情報";
    },
    TAB_NAME() {
      return this.flagEstimate ? this.TAB_NAME_ESTIMATE : this.TAB_NAME_ORDER;
    },
    FLAG_NAME() {
      return this.flagEstimate ? "estimate" : "order";
    }
  },
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };

    this.$watch(
      () => Store.getters[`${STORE}/get${this.FLAG_NAME}`],
      (data) => {
        this.items = { ...data };
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  methods: {
    closeForm() {
      this.$emit("closeForm");
    },
    onShowSendResultDialog() {
      this.isShowSendResultDialog = true;
    },
    async onSubmitSendResult(status) {
      let rs = null;
      let ids = this.selectedItems.map(e => e.machine_company_field_estimate_order_relation_detail_id);
      let params = this.flagEstimate ? { ids, estimate_status: status } : { ids, order_status: status };
      if (this.flagEstimate) {
        rs = await Store.dispatch(`${STORE}/replyEstimateResult`,  params );
      } else {
        rs = await Store.dispatch(`${STORE}/replyOrderResult`,  params );
      }
      if (rs.hasError) {
        this.flagHandleConfirm = !this.flagHandleConfirm;
      } else {
        await this.updateDetailMachine();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        this.closeSendResultDialog();
      }
    },
    updateSelectedItems(selectedItems) {
      this.selectedItems = [...selectedItems];
    },
    async updateDetailMachine() {
      this.flagEstimate ? await Store.dispatch(`${STORE}/getEstimate`, this.items.id) :
        await Store.dispatch(`${STORE}/getOrder`, this.items.id);
      this.$emit("resetItems");
    },
    async createReport(file) {
      let params = {
        id: this.items.id,
        report_type: this.flagEstimate ? 0 : 1,
        report: file.report,
        report_file_data: file.report_url
      };
      let rs = await Store.dispatch(`${STORE}/createReport`,  params );
      if (!rs.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        await this.updateDetailMachine();
      }
    },
    closeSendResultDialog() {
      this.isShowSendResultDialog = false;
      this.resetSelected = !this.resetSelected;
    }
  }
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
