<template>
  <div>
    <v-container>
      <v-row class="ml-1">
        <v-list-item>
          <v-col cols="12" sm="3" md="3"><span>人数</span></v-col>
          <v-col cols="12" sm="3" md="3"><span>時間</span></v-col>
          <v-col cols="12" sm="3" md="3" v-if="!editable"><span>開始時刻</span></v-col>
          <v-col cols="12" sm="3" md="3" v-if="!editable"><span>終了時刻</span></v-col>
        </v-list-item>
      </v-row>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row v-if="item.early_over_type == 0" no-gutters>
          <v-col cols="2.5">
            <v-list-item>
              <v-list-item-content>
                <Label :label="editable ? '人数' : ''" :editable="editable">
                  <Select
                    name="working_temp_person"
                    :values="item"
                    :items="NUMBER_OF_PEOPLE"
                    :editable="editable"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="2.5">
            <v-list-item>
              <v-list-item-content>
                <Label :label="editable ? '時間' : ''" :editable="editable">
                  <Select
                    name="working_temp_hour"
                    :values="item"
                    :items="NUMBER_OF_DAYS"
                    :editable="editable"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="3" md="3" :class="editable ? 'mt-2' : ''">
            <v-list-item>
              <v-list-item-content>
                <Label label="" :editable="editable">
                  <InputText
                    name="overtime_start"
                    :values="item"
                    :editable="false"
                    placeholder=""
                    @onInput="onInput"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <div :class="editable ? 'pt-14' : 'pt-12'">〜</div>
          <v-col cols="12" sm="3" md="3" :class="editable ? 'mt-2' : ''">
            <v-list-item>
              <v-list-item-content>
                <Label label="" :editable="editable">
                  <InputText
                    name="earlytime_end"
                    :values="item"
                    :editable="false"
                    placeholder=""
                    @onInput="onInput"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col
            cols="12"
            sm="1"
            md="1"
            class="pt-8 pb-0"
            v-if="editable && index == row"
          >
            <v-btn icon @click="addEmpty"><v-icon>mdi-plus</v-icon></v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="1"
            md="1"
            class="pt-8 pb-0"
            v-if="index > row && editable"
          >
            <v-btn icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import { Store } from "@/store/Store.js";
import Label from "../../../elements/Label.vue";
import InputText from "../../../elements/InputText.vue";
import Select from "../../../elements/Select.vue";
import { NUMBER_OF_PEOPLE, NUMBER_OF_DAYS, time_convert } from "@/constants/SCHEDULE_WORKS";
import _ from "lodash";
//初期値を設定します
const INITIAL_VALUES = {
  working_temp_person: 0,
  working_temp_hour: "0.00",
  earlytime_end: null,
  overtime_start: null,
  early_over_type: 0,
};

export default {
  data: () => {
    return {
      items: [],
      NUMBER_OF_PEOPLE,
      NUMBER_OF_DAYS,
      listTimeZones: Store.state.PortalChart.listTimeZones,
      totalUsers: 0,
      totalUserEarly: 0,
      row: 0,
      timeZone: null,
    };
  },
  components: {
    Label,
    InputText,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    selectItems: {
      type: Array,
    },
    type: Number,
    total: {
      type: Number,
      default: 0,
    }
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if(this.items?.filter((item) => item.early_over_type == 0).length == 0) this.addEmpty();
        const timeZoneNew = this.getTimeZone(newValue[0],newValue[1]);
        this.row = _.findIndex(this.items, (item) => item.early_over_type == 0);
        this.totalUserEarly = 0;
        this.items?.forEach(item => {
          if(item.early_over_type == 0) {
            if(timeZoneNew && timeZoneNew.start_time != "") {
              let startTime = timeZoneNew.start_time.split(":");
              item.earlytime_end = (parseInt(startTime[0]) > 24 ? parseInt(startTime[0]) - 24 : parseInt(startTime[0])) + ":" + startTime[1];
              if (item.overtime_start) this.calculateTimeEarlyTimeStart(item);
            } else {
              item.earlytime_end = null;
            }

            this.totalUserEarly += item.working_temp_person;
          }
        })
        if (this.timeZone != timeZoneNew) {
          this.timeZone = timeZoneNew;
          this.$emit("onInput", { name: this.name, value: this.items });
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.total,
      (newValue) => {
        this.totalUsers = newValue;
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      const itemEdited = { ...this.items[index] };
      itemEdited[value.name] = value.value;
      const items = [...this.items];
      if(itemEdited['working_temp_hour'] != "0.00" && itemEdited['working_temp_person'] != 0) {
        this.calculateTimeEarlyTimeStart(itemEdited);
      } else {
        itemEdited.overtime_start = null;
      }
      items[index] = itemEdited;
      this.$emit("onInput", { name: this.name, value: items });
    },
    remove(index) {
      const items = this.items.filter((item, i) => {
        return index != i;
      });
      this.$emit("onInput", { name: this.name, value: items });
    },
    addEmpty() {
      let _items = [...this.items];
      _items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    calculateTimeEarlyTimeStart(itemEdited) {
      if(itemEdited.earlytime_end) {
        let timeSplit = itemEdited.earlytime_end.split(":");
        let time = parseInt(timeSplit[0]) * 60 + parseInt(timeSplit[1]) - itemEdited.working_temp_hour * 60;
        if(time < 0) {
          time = time + 24 * 60;
        }
        itemEdited.overtime_start = time_convert(time);
      }
    },
    getTimeZone(value,name){
      return name == "work_early_over_time_plans" ? this.listTimeZones.find(element => element.id === value?.work_group_plans?.work_hour_zone) 
      : this.listTimeZones.find(element => element.id === value?.work_group_results?.work_hour_zone) ;
    }
  },
};
</script>
