<template>
  <!-- parent table -->
  <v-data-table
    :headers="tableLabels"
    :items="items"
    :items-per-page="itemsPerPage"
    :height="tableHeight"
    hide-default-header
    hide-default-footer
    disable-sort
    :show-select="true"
    class="elevation-0 v-data-table__wrapper"
    sort-by="updatedAt"
  >
    <!-- parent table template -->
    <template v-slot:body="{ items }">
      <template v-if="items && items.length > 0">
        <tbody v-for="item in items" :key="item.work_plan_id">
          <tr>
            <td>
              <v-card
                class="card__parent mt-4 mb-0 rounded-sm"
                :class="item.state_handling_flag == 1 ? 'grey lighten-3' : ''"
                @click="$emit('openItemForm', item)"
              >
                <!-- ヘッダー -->
                <div class="row__header">
                  <v-row>
                    <!-- チェック -->
                    <v-col
                      v-if="!isTableTypeAll"
                      class="col-items"
                      cols="2"
                      sm="2"
                    >
                      <v-simple-checkbox
                        :value="isSelectedParent(item.work_plan_id)"
                        @input="updateCheckbox(item, $event, true)"
                      ></v-simple-checkbox>
                      <v-simple-checkbox
                        :value="isSelected(item.work_plan_id)"
                        @input="updateCheckbox(item, $event, null, item)"
                      ></v-simple-checkbox>
                    </v-col>
                    <!-- ステータス -->
                    <v-col class="col-items" cols="3" sm="3">
                      <!-- status -->
                      <div class="col-status">
                        <div
                          v-for="(status, index) in approveStatus"
                          :key="`status_${status}_${index}_1`"
                        >
                          <v-chip
                            v-if="status.id === item.work_approval_flag"
                            class="ma-2"
                            small
                            :color="`${status.class}`"
                            dark
                            >{{ status.name }}</v-chip
                          >
                        </div>
                      </div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <!-- 早残 & 人工 -->
                    <v-col class="col-items end" cols="2" sm="2">
                      <!-- 早残 -->
                      <div>
                        <v-chip
                          v-if="item.early_over_type.earlytime_hour > 0"
                          class="ma-2"
                          small
                          :color="`${SCHEDULE_ATTENDANCE.early_flag.class}`"
                          dark
                          >{{
                            SCHEDULE_ATTENDANCE.early_flag.name +
                            "（" +
                            item.early_over_type.earlytime_hour +
                            " h）"
                          }}</v-chip
                        >
                        <v-chip
                          v-if="item.early_over_type.overtime_hour > 0"
                          class="ma-2"
                          small
                          :color="`${SCHEDULE_ATTENDANCE.over_flag.class}`"
                          dark
                          >{{
                            SCHEDULE_ATTENDANCE.over_flag.name +
                            "（" +
                            item.early_over_type.overtime_hour +
                            " h）"
                          }}</v-chip
                        >
                      </div>
                      <!-- 人工 -->
                      <div class="label__dayworker">
                        <div>
                          <span
                            @click.stop="$emit('openItemForm', item, true)"
                            >{{ item.dayworker_plan }}</span
                          >
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <!-- 会社・現場情報 -->
                <v-card-text>
                  <v-row>
                    <v-col>
                      <div class="label__company">
                        {{ item.company_name }}
                      </div>
                      <div class="wrapper__site">
                        <div class="label__site">{{ item.job_name }}</div>
                        <div class="label__site">
                          {{ item.location_name }}
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
                <!-- 説明 -->
                <v-card-text class="row_border">
                  <v-row>
                    <v-col>
                      <table>
                        <tr>
                          <td>
                            <template
                              v-if="
                                SCHEDULE_WARNING.hot_work_flag.id ===
                                item.hot_work_flag
                              "
                            >
                              <div
                                :class="`warning-icon ${SCHEDULE_WARNING.hot_work_flag.class}`"
                              >
                                {{ SCHEDULE_WARNING.hot_work_flag.name }}
                              </div>
                            </template>
                            <template
                              v-if="
                                SCHEDULE_WARNING.oxygen_lack_flag.id ===
                                item.oxygen_lack_flag
                              "
                            >
                              <div
                                :class="`warning-icon ${SCHEDULE_WARNING.oxygen_lack_flag.class}`"
                              >
                                {{ SCHEDULE_WARNING.oxygen_lack_flag.name }}
                              </div>
                            </template>
                          </td>
                          <td>
                            <div class="label__description">
                              {{ item.contents }}
                            </div>
                          </td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>
                </v-card-text>
                <!-- 作業グループ開閉ボタン -->
                <button
                  class="row__toggle row_border"
                  @click.stop="toggleShowChildren(item.work_plan_id)"
                >
                  <v-icon v-if="!$sp">
                    {{
                      isShowChildren[item.work_plan_id]
                        ? "mdi-chevron-down"
                        : "mdi-chevron-right"
                    }}
                  </v-icon>
                  <v-icon v-if="$sp">
                    {{
                      isShowChildren[item.work_plan_id]
                        ? "mdi-chevron-down"
                        : "mdi-chevron-up"
                    }}
                  </v-icon>
                  <span>作業グループ</span>
                </button>
              </v-card>
            </td>
          </tr>
          <!-- child table template -->
          <template v-for="itemChild in item.children">
            <tr class="child-table" :key="itemChild.work_plan_id">
              <td v-show="isShowChildren[item.work_plan_id]">
                <v-card
                  class="card__child mt-2 mb-2 rounded-sm"
                  :class="
                    itemChild.state_handling_flag == 1 ? 'grey lighten-3' : ''
                  "
                  @click="$emit('openItemForm', itemChild)"
                >
                  <div class="row__header">
                    <v-row>
                      <!-- チェック -->
                      <v-col
                        v-if="!isTableTypeAll"
                        class="col-items"
                        cols="2"
                        sm="2"
                      >
                        <v-simple-checkbox
                          :value="isSelected(itemChild.work_plan_id)"
                          @input="updateCheckbox(itemChild, $event, null, item)"
                        ></v-simple-checkbox>
                      </v-col>

                      <!-- ステータス -->
                      <v-col class="col-items" cols="3" sm="3">
                        <div class="col-status">
                          <div
                            v-for="(status, index) in approveStatus"
                            :key="`status_${status}_${index}_2`"
                          >
                            <v-chip
                              v-if="status.id === itemChild.work_approval_flag"
                              class="ma-2"
                              small
                              :color="`${status.class}`"
                              dark
                              >{{ status.name }}</v-chip
                            >
                          </div>
                        </div>
                      </v-col>

                      <v-spacer></v-spacer>

                      <!-- 早残 & 人工 -->
                      <v-col class="col-items end" cols="2" sm="2">
                        <div>
                          <v-chip
                            v-if="itemChild.early_over_type.earlytime_hour > 0"
                            class="ma-2"
                            small
                            :color="`${SCHEDULE_ATTENDANCE.early_flag.class}`"
                            dark
                            >{{
                              SCHEDULE_ATTENDANCE.early_flag.name +
                              "（" +
                              itemChild.early_over_type.earlytime_hour +
                              " h）"
                            }}</v-chip
                          >
                          <v-chip
                            v-if="itemChild.early_over_type.overtime_hour > 0"
                            class="ma-2"
                            small
                            :color="`${SCHEDULE_ATTENDANCE.over_flag.class}`"
                            dark
                            >{{
                              SCHEDULE_ATTENDANCE.over_flag.name +
                              "（" +
                              itemChild.early_over_type.overtime_hour +
                              " h）"
                            }}</v-chip
                          >
                        </div>
                        <!-- 人工 -->
                        <div class="label__dayworker">
                          <div>
                            <span
                              @click.stop="$emit('openItemForm', itemChild, true)"
                              >{{ itemChild.dayworker_plan }}</span
                            >
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                  <!--  会社情報 -->
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <div class="label__company">
                          {{ itemChild.company_name }}
                        </div>
                        <div class="wrapper__site">
                          <div class="label__site">
                            {{ itemChild.job_name }}
                          </div>
                          <div class="label__site">
                            {{ itemChild.location_name }}
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <!-- 説明 -->
                  <v-card-text class="row_border">
                    <v-row>
                      <v-col>
                        <table>
                          <tr>
                            <td>
                              <div
                                v-if="
                                  SCHEDULE_WARNING.hot_work_flag.id ===
                                  itemChild.hot_work_flag
                                "
                                :class="`warning-icon ${SCHEDULE_WARNING.hot_work_flag.class}`"
                              >
                                {{ SCHEDULE_WARNING.hot_work_flag.name }}
                              </div>
                              <div
                                v-if="
                                  SCHEDULE_WARNING.oxygen_lack_flag.id ===
                                  itemChild.oxygen_lack_flag
                                "
                                :class="`warning-icon ${SCHEDULE_WARNING.oxygen_lack_flag.class}`"
                              >
                                {{ SCHEDULE_WARNING.oxygen_lack_flag.name }}
                              </div>
                            </td>
                            <td>
                              <div class="label__description">
                                {{ itemChild.contents }}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </td>
              <td
                v-show="!isShowChildren[item.work_plan_id]"
                style="height: 4px"
              >
                <div
                  style="
                    height: 2px;
                    width: 90%;
                    margin: 0 5%;
                    background-color: #eee;
                  "
                ></div>
              </td>
            </tr>
          </template>
          <tr
            v-show="
              isShowChildren[item.work_plan_id] &&
              (childTableDisplayMoreNumber(
                item.total_children_item,
                item.children.length
              ) > 0 ||
                !isTableTypeAll)
            "
          >
            <td colspan="8">
              <div class="d-flex justify-center">
                <v-btn
                  x-small
                  depressed
                  class="mr-4 display-more"
                  :class="$sp ? 'sp' : ''"
                  color="#E5E5E5"
                  v-if="
                    childTableDisplayMoreNumber(
                      item.total_children_item,
                      item.children.length
                    ) > 0
                  "
                  @click="
                    $emit(
                      'getChildItems',
                      item.work_group_plan_id,
                      Math.floor((item.children.length - 3) / 5) + 2
                    )
                  "
                  >さらに表示する
                  <v-badge
                    class="display-more-number"
                    inline
                    color="#767676"
                    :content="
                      childTableDisplayMoreNumber(
                        item.total_children_item,
                        item.children.length
                      )
                    "
                  />
                </v-btn>
                <v-btn
                  class="add-new-child"
                  :class="$sp ? 'sp' : ''"
                  depressed
                  x-small
                  v-if="!isTableTypeAll"
                  @click="$emit('addNewChild', item)"
                  color="primary"
                  >作業を新規追加</v-btn
                >
              </div>
            </td>
          </tr>
        </tbody>
      </template>
      <div v-else-if="flagNotData" class="schedule_nodata mt-4">
        <span>{{ NO_DATA_MESSAGE }}</span>
      </div>
    </template>
  </v-data-table>
</template>
<script>
export default {
  props: {
    tableLabels: Array,
    itemsPerPage: Number,
    tableHeight: Number,
    isTableTypeAll: Boolean,
    items: Array,
    isSelected: Function,
    isSelectedParent: Function,
    updateCheckbox: Function,
    toggleShowChildren: Function,
    childTableDisplayMoreNumber: Function,
    isShowChildren: Object,
    approveStatus: Array,
    SCHEDULE_ATTENDANCE: Object,
    SCHEDULE_WARNING: Object,
    NO_DATA_MESSAGE: String,
    flagNotData: Boolean,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
// ヘッダーとテーブルコンテンツのたて位置を合わせるために
// show-selectオプションをONにした状態でcheckboxを非表示
::v-deep .v-data-table-header {
  .v-input--selection-controls__input {
    display: none !important;
  }
}
.v-data-table .child-table td {
  border-bottom: none !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 4px !important;
}

.row_border {
  border-top: 1px solid #e5e5e5;
}

.row__header {
  display: flex;
  padding: 0 8px;
  justify-content: space-between;
  align-items: center;
}

.row__toggle {
  padding: 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 10px;
}

/**
親カード
*/
.card__parent {
  position: relative;
  &:after {
    position: absolute;
    content: "";
    display: block;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: $color_primary;
  }
}

/**
子カード
 */
.card__child {
  position: relative;
  margin: 0 8px;
  &:after {
    position: absolute;
    content: "";
    display: block;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: $color_util_2;
  }
}

// 現場情報
.wrapper__site {
  display: flex;
  justify-content: space-between;
}

// ラベリング
.label__company {
  font-size: 14px;
  color: black;
}
.label__site {
  color: $color_text_sub;
  font-size: 12px;
}

.label__dayworker {
  color: $color_util_1;
  font-size: 18px;
}
// 説明
.label__description {
  font-size: 11px;
  line-height: 14px;
}

.col-items {
  display: flex;
  align-items: center;

  &.start {
    padding-left: 16px;
    justify-content: flex-start;
  }

  &.end {
    padding-right: 16px;
    justify-content: flex-end;
  }
}

.schedule_nodata {
  position: absolute;
  width: 100%;
  text-align: center;
}

button.add-new-child,
button.display-more {
  height: 20px !important;

  &.sp {
    height: 32px !important;
    font-size: 14px;
  }
}

.display-more-number ::v-deep .v-badge__badge {
  font-size: 10px;
}

.display-more-number ::v-deep .v-badge__badge {
  padding: 1px;
  height: 14px;
}

.circle-red {
  background-color: $color_warning_dark_red;
}
.circle-blue {
  background-color: $color_warning_blue;
}
.warning-icon {
  display: inline-flex;
  justify-content: center;
  border-radius: 50%;
  font-size: 10px;
  color: white;
  width: 25px;
  height: 25px;
  padding-top: 4px;
  margin: 3px;
}
</style>
