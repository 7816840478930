<template>
  <div>
    <v-container>
      <v-main >
        <v-row class="select-search row" no-gutters>
          <v-col >
            <v-list-item class="select-search item">
              <v-list-item-content class="row-position">
                <SelectSearch
                  class="select-position"
                  name="field_tree_id"
                  item_text="company_name"
                  item_adress="address"
                  :values="items"
                  :items="fieldTree"
                  :editable="editable"
                  :isShowPopup="isShowPopup"
                  :fieldConstructionId="fieldConstructionId"
                  :show="show"
                  title="会社選択"
                  title_search="会社名"
                  @onInput="onInput({ value: $event })"
                  @search="clickFieldTree"
                />
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col
            cols="12"
            sm="2"
            md="2"
            class="pt-0 pb-0"
          >
            <v-btn v-if="editable" color="primary" @click="clickFieldTree">検索</v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
  </div>
</template>
<script>
import Label from "@/components/forms/elements/Label.vue";
import Select from "@/components/forms/elements/Select.vue";
import { Store } from "@/store/Store.js";
import SelectSearch from "./SelectSearch.vue";
import { USER_ROLE } from "@/constants/SAFETY_FORM_PRINT";

//初期値を設定します
const STORE = "Report";

export default {
  data: () => {
    return {
      items: {
        field_tree_id: null,
      },
      isShowPopup: false,
      nestNo: null,
      nameSearch: null,
      fieldTree: [{ field_tree_id: null, company_name: "" }],
      field_id: null,
      fieldConstructionId: null,
      rolePartner: false,
    };
  },
  components: {
    SelectSearch,
    Label,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    selectItems: {
      type: Array,
    },
    row: {
      type: Number,
      default: 1,
    },
    show: {
      type: Boolean,
    },
    nameFile: {
      type: String,
    },
    statusFlag : {
      type: Boolean,
      default : false
    }
  },
  mounted() {
    this.$watch(
      () => Store.getters["Report/getUserRole"],
      (data) => {
        if (data.role == USER_ROLE.PARTNERCOMPANY.id) {
          this.rolePartner = true;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["Report/getCompanyPartnerForPrint"],
      (data, oldData) => {
        if (data && JSON.stringify(data) !== JSON.stringify(oldData)) {
          if (this.rolePartner 
            && (this.nameFile === "Grn_Work_Total" 
            || this.nameFile === "Grn_All_Work_Detailed_Statement_Payment")
          ) {
            data = data.filter(
              (item) => item.company_id == this.COMPANY_ID
            );
          }
          this.fieldTree = [...data];
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      (data, oldData) => {
        if (data && JSON.stringify(data) !== JSON.stringify(oldData) && data.field_id) {
          this.field_id = data.field_id;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["Report/getUserRole"],
      (data) => {
        if(data) {
          this.nestNo = data.nest_no;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    COMPANY_ID() {
      return JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user.company_id;
    },
  },
  methods: {
    onInput({ value }) {
      this.$emit("onInput", { name: value.name, value: value.value });
    },
    async clickFieldTree (keyword) {
      let apiParams = null;
      this.nameSearch = keyword.keyword;
      if (this.statusFlag) {
        apiParams = {
          field_construction_id: this.values.field_construction_id,
          company_for_user_flag: [0]
        };
      }else {
        if (keyword.keyword != "") {
          this.nameSearch = keyword.keyword;
        } else {
          this.nameSearch = "";
        }
        let nestNoCheck = this.nestNo.filter(e => e == 0);
        let nestNoTemp = this.nestNo.filter(e => e == 1);
        if (nestNoCheck.length > 0) {
          nestNoTemp = [0];
        } else if (this.nestNo.filter(e => e >= 2).length > 0) {
          nestNoTemp.push(2);
        }
        this.nestNo = nestNoTemp;
        apiParams = {
          field_construction_id: this.values.field_construction_id,
          company_for_user_flag: [0],
        };
      }
      if(this.show == true) {
        apiParams.field_tree_group_id = this.values.field_tree_group_id;
        this.fieldConstructionId = this.values.field_tree_group_id;
      }
      if(this.nameSearch != "") {
        apiParams.company_name = this.nameSearch;
      }
      if ((this.show == true && this.values.field_tree_group_id) || this.show == false) {
        await Store.dispatch(`${STORE}/getUserRole`, this.field_id + "?field_construction_id=" + this.values.field_construction_id);
        await Store.dispatch(`${STORE}/getCompanyPartnerForPrint`, { params: apiParams });
      }
      this.isShowPopup = !this.isShowPopup
    }
  },
};
</script>
<style lang="scss" scoped>

.row-position {
  margin-top: -20px;
  height: 150px;
  position: relative;
}

.select-position {
  width: 100%;
  position: absolute;
}

.select-search.row {
  align-items: center;
}

.pt-0.pb-0 {
  margin-bottom: 18px;
}

.select-search.item {
  padding: 0 16px 0 0 !important;
}
</style>
