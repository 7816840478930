<template>
  <div>
    <v-app-bar class="spHeader pa-0" dense>
      <div class="spHeader__inner">
        <div class="spHeader__title">
          {{ PAGE_TITLE }}
        </div>
        <!-- 現場選択ダイアログ -->
        <div class="btn__wrapper">
          <button class="spHeader__site" @click="openSearchDialog">
            <div>
              <!-- 現場名 -->
              <div class="sites_name_wrapper">
                {{ CurrentSite ? CurrentSite.field_name : "-" }}
              </div>
              <!-- 日付 -->
              <span class="spHeader-date primary--text mx-4">
                {{ getFormattedDate() }}
              </span>
            </div>
            <v-spacer></v-spacer>
            <v-icon right class="ml-0 mr-1"> mdi-menu-down </v-icon>
          </button>
        </div>
        <!-- drawer -->
        <v-btn icon @click="openDrawer">
          <v-app-bar-nav-icon></v-app-bar-nav-icon>
        </v-btn>
      </div>
    </v-app-bar>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import { getDateJp } from "@/utils/timeUtil"; //日付フォーマット変換で使用
import {
  HEADER_MENU_ITEMS_INHOUSE_ARRAY,
  PORTAL_LIST,
} from "@/constants/GLOBALHEADER";

export default {
  data() {
    return {
      title: "GREEN",

      // タブメニュー表示項目
      HEADER_MENU_ITEMS_INHOUSE_ARRAY,

      // 選択中のタブメニューid
      active_tab: 0,

      // 現場検索データ
      formValues: {
        // 検索items
        items: [],
        // 検索ワード
        keyword: "",
        // 選択項目id
        selected: null,
      },

      // ダイアログ
      isShowMainDialog: false,

      // 検索ダイアログ表示
      isShowSearchDialog: false,
    };
  },

  props: {
    companyId: Number,
    isTableTypeAll: {
      type: Boolean,
    },
    PAGE_TITLE: {
      type: String,
    },
    //現場選択で戻るパスurl
    fallbackPath: {
      type: String,
    },
  },

  async mounted() {
    /**
     * 選択しているメニューを取得
     */
    this.$watch(
      () => Store.getters[`GlobalHeader/getSiteMenuId`],
      (menuId) => {
        this.active_tab = menuId;
      },
      {
        immediate: true,
      }
    );

    /**
     * 現場一覧を取得
     */
    this.$watch(
      () => Store.getters[`GlobalHeader/getSiteList`],
      (data) => {
        const siteList = {
          ...data,
        };
        this.formValues.items = siteList;
      },
      {
        immidiate: true,
        deep: true,
      }
    );
  },

  computed: {
    /**
     * 現在の現場を取得
     */
    CurrentSite() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },

  methods: {
    // ドロワー
    openDrawer() {
      Store.dispatch("Drawer/show", {
        type: PORTAL_LIST.SP.id,
      });
    },

    // 検索ダイアログ
    async openSearchDialog() {
      const url = this.fallbackPath
        ? `/sp/siteSelect?fallback=${this.fallbackPath}`
        : `/sp/siteSelect`;
      console.log("-----------", this.fallbackPath, url);
      this.$router.push(url);
    },

    /**
     * 日付変換
     * @param date:yyyy/mm/dd（day）
     */
    getFormattedDate() {
      return getDateJp(new Date()).date;
    },
  },
};
</script>
<style lang="scss">
.spHeader {
  .v-toolbar__content {
    padding: 0 !important;
    width: 100%;
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.spHeader {
  .spHeader-siteSelect {
    font-size: 11px;
  }
  .spHeader-date {
    font-size: 10px;
  }

  .v-toolbar__content {
    padding: 0 !important;
  }
}
.spHeader__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.spHeader__title {
  font-size: 14px;
  padding-right: 8px;
  color: $color_primary;
  padding-left: 8px;
}

.spHeader__site {
  background-color: #eee;
  height: 36px;
  overflow: hidden;
  border-radius: 4px;
  display: flex;
}

.v-badge__badge {
  font-size: 9px !important;
}
.theme--light.v-badge .v-badge__badge:after {
  border-color: none !important;
}

.btn__wrapper {
  overflow: hidden;
  width: 220px;
  padding: 4px;
}

.sites_name_wrapper {
  width: 180px;
  font-size: 11px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.buruburu {
  display: inline-block;
  animation: hurueru 1s infinite;
  transition-timing-function: ease-in-out;
  &::before {
    color: $color_util_1;
  }
}

@keyframes hurueru {
  0% {
    transform: rotateZ(10deg);
  }
  50% {
    transform: rotateZ(-10deg);
  }
  100% {
    transform: rotateZ(10deg);
  }
}
</style>
