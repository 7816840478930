export const SPRITE_MACHINES = [
  {
    id: 1,
    machine_type_id: 1, // 大分類
    name: "クローラ クレーン 1",
    basic_weight: "10.0", //定格荷重 (t)
    work_radius: "12.0", //作業半径 (m)
    image_top_url: null, // 上の画像url
    image_side_url: "/images/placementSplite/machine/001.png", // 横の画像url
  },
  {
    id: 2,
    machine_type_id: 1, // 大分類
    name: "クローラ クレーン 2",
    basic_weight: "10.0", //定格荷重 (t)
    work_radius: "12.0", //作業半径 (m)
    image_top_url: null, // 上の画像url
    image_side_url: "/images/placementSplite/machine/002.png", // 横の画像url
  },
  {
    id: 3,
    machine_type_id: 1, // 大分類
    name: "クローラ クレーン 3",
    basic_weight: "10.0", //定格荷重 (t)
    work_radius: "12.0", //作業半径 (m)
    image_top_url: null, // 上の画像url
    image_side_url: "/images/placementSplite/machine/003.png", // 横の画像url
  },
  {
    id: 4,
    machine_type_id: 1, // 大分類
    name: "クローラ クレーン 4",
    basic_weight: "10.0", //定格荷重 (t)
    work_radius: "12.0", //作業半径 (m)
    image_top_url: null, // 上の画像url
    image_side_url: "/images/placementSplite/machine/004.png", // 横の画像url
  },
  {
    id: 5,
    machine_type_id: 2, // 大分類
    name: "クローラ 1",
    basic_weight: "10.0", //定格荷重 (t)
    work_radius: "12.0", //作業半径 (m)
    image_top_url: null, // 上の画像url
    image_side_url: "/images/placementSplite/machine/005.png", // 横の画像url
  },
  {
    id: 6,
    machine_type_id: 2, // 大分類
    name: "クローラ 2",
    basic_weight: "10.0", //定格荷重 (t)
    work_radius: "12.0", //作業半径 (m)
    image_top_url: null, // 上の画像url
    image_side_url: "/images/placementSplite/machine/006.png", // 横の画像url
  },
  {
    id: 7,
    machine_type_id: 2, // 大分類
    name: "クローラ 3",
    basic_weight: "10.0", //定格荷重 (t)
    work_radius: "12.0", //作業半径 (m)
    image_top_url: null, // 上の画像url
    image_side_url: "/images/placementSplite/machine/007.png", // 横の画像url
  },
  {
    id: 8,
    machine_type_id: 3, // 大分類
    name: "クローラ 31",
    basic_weight: "10.0", //定格荷重 (t)
    work_radius: "12.0", //作業半径 (m)
    image_top_url: null, // 上の画像url
    image_side_url: "/images/placementSplite/machine/008.png", // 横の画像url
  },
  {
    id: 9,
    machine_type_id: 3, // 大分類
    name: "クローラ 32",
    basic_weight: "10.0", //定格荷重 (t)
    work_radius: "12.0", //作業半径 (m)
    image_top_url: null, // 上の画像url
    image_side_url: "/images/placementSplite/machine/009.png", // 横の画像url
  },
  {
    id: 10,
    machine_type_id: 3, // 大分類
    name: "クローラ 33",
    basic_weight: "10.0", //定格荷重 (t)
    work_radius: "12.0", //作業半径 (m)
    image_top_url: null, // 上の画像url
    image_side_url: "/images/placementSplite/machine/010.png", // 横の画像url
  },
  {
    id: 11,
    machine_type_id: 3, // 大分類
    name: "クローラ 34",
    basic_weight: "10.0", //定格荷重 (t)
    work_radius: "12.0", //作業半径 (m)
    image_top_url: null, // 上の画像url
    image_side_url: "/images/placementSplite/machine/011.png", // 横の画像url
  },
  {
    id: 12,
    machine_type_id: 3, // 大分類
    name: "クローラ 35",
    basic_weight: "10.0", //定格荷重 (t)
    work_radius: "12.0", //作業半径 (m)
    image_top_url: null, // 上の画像url
    image_side_url: "/images/placementSplite/machine/012.png", // 横の画像url
  },
];
