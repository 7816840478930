export const TEXT_ALIGN = {
  left: "left",
  center: "center",
  right: "right"
};

export const TEXT_SIZE = {
  small: 12,
  middle: 16,
  big: 20
};

export const TEXT_COLOR = {
  black: "black",
  white: "white",
  red: "red",
  blue: "blue",
  green: "green"
};
