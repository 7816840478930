<template>
  <div class="mt-3">
    <v-container>
      <v-row v-for="(item, index) in items" :key="index">
        <v-col cols="12" sm="6" md="6">
          <Label label="職種" :editable="editable">
            <Select
              name="user_experience_id"
              :values="item"
              :items="USER_EXPERIENCES[index]"
              :editable="editable"
              @onInput="onInput({ index, value: $event })"
            />
          </Label>
        </v-col>
        <v-col>
          <Label label="経験年数" :editable="editable">
            <InputText
              name="experience"
              :values="getExperiences(item.user_experience_id, index)"
              :editable="false"
            />
          </Label>
        </v-col>
        <v-btn
          v-if="editable && index > 0"
          class="mt-8"
          icon
          @click="remove(index)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
    </v-container>
    <v-btn v-if="editable && items.length < row" color="primary" @click="addEmpty">追加</v-btn>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "@/components/forms/elements/Label";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import Checkbox from "@/components/forms/elements/Checkbox";
import Select from "@/components/forms/elements/Select";
import InputText from "@/components/forms/elements/InputText";
import { Store } from "@/store/Store";
import _ from "lodash";

const INITIAL_VALUES = {
  user_experience_id: null,
};

export default {
  data: () => {
    return {
      items: [],
      OCCUPATION_TYPE: Store.state.CmnMst.constants.entries.cmn_mst_skills,
      USER_EXPERIENCES: [],
      row: 1,
    };
  },
  components: {
    Label,
    InputDatepicker,
    Select,
    Checkbox,
    InputText,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue, oldValue) => {
        if (this.items?.length !== newValue[0][newValue[1]]?.length
          || JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.items = newValue[0][newValue[1]];
          if (this.items?.length == 0) {
            this.addEmpty();
          }
          if(newValue[0][newValue[1]]) this.initDataUserExperience(newValue[0]);
        }
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      const itemEdited = {...this.items[index]};
      itemEdited[value.name] = value.value;
      const items = [...this.items];
      items[index] = itemEdited;
      this.$emit("onInput", { name: this.name, value: items });
    },
    remove(index) {
      const items = this.items.filter((item, i) => {
        return index != i;
      });
      this.$emit("onInput", { name: this.name, value: items });
    },
    addEmpty() {
      const items = [...this.items];
      items.push({ ...INITIAL_VALUES });
      this.items = items;
      this.$emit("onInput", { name: this.name, value: items });
    },
    initDataUserExperience(data) {
      this.USER_EXPERIENCES = [];
      let userExperience =  _.cloneDeep(data.user_experiences).filter(item => item.cmn_mst_skills_id);
      this.row = userExperience.length;
      userExperience.forEach((item) => {
        const skill = this.OCCUPATION_TYPE.find((element) => element.id == item.cmn_mst_skills_id);
        item['name'] = skill.catergory + " " + skill.sub_catergory;
      });
      userExperience.unshift({id: null, name: ""});
      data.field_tree_user_experiences.forEach(() => this.USER_EXPERIENCES.push(_.cloneDeep(userExperience)));
      data.field_tree_user_experiences.forEach((item, index) => {
        if(item.user_experience_id) {
          this.convertDataUserExperience(item.user_experience_id, index);
        }
      });
    },
    convertDataUserExperience(user_experience_id, index) {
      this.USER_EXPERIENCES.forEach((element, i) => {
        if (index != i) {
          element.forEach((_item, _index) => {
            if (_item.id == user_experience_id) element.splice(_index, 1);
          });
        }
      });
    },
    getExperiences(id, index) {
      if (!id) return { experience: "" };
      return this.USER_EXPERIENCES[index]?.find((item) => item.id == id);
    },
  },
};
</script>
