<template>
  <div>
    <ScheduleRow
      v-for="(item, index) in items.devices"
      :key="item.device_id"
      :index="index"
      :work_start_time="items.work_start_time"
      :isGates="isGates"
      :item="item"
      :today="items.date"
      :flagBanMachine="flagBanMachine"
      :isNotShowNoData="isNotShowNoData"
      :isNotShowTemplate="isNotShowTemplate"
      :isShowTemplateNoData="isShowTemplateNoData"
      @update="updateSelectedItems"
      @openItemForm="openItemForm"
      @getChildItems="getChildItems"
      @addNewChild="addNewChild"
      @resetList="functions.resetList"
      :isUsage="isUsage"
    />
  </div>
</template>
<script>
import ScheduleRow from "./ScheduleRow";
export default {
  components: {
    ScheduleRow,
  },
  props: {
    isUsage: {
      type: Boolean,
      default: false,
    },
    isGates: {
      type: Boolean,
    },
    items: {
      type: Object,
    },
    functions: {
      type: Object,
    },
    tableHeight: {
      type: Number,
    },
    flagBanMachine: {
      type: Boolean,
      default: false
    },
    isNotShowNoData: {
      type: Boolean,
      default: false
    },
    isNotShowTemplate: {
      type: Boolean,
      default: false
    },
    isShowTemplateNoData: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    updateSelectedItems(updateList) {
      this.$emit('setIsGate');
      return this.functions.updateSelectedItems(updateList);
    },
    openItemForm(schedule_id) {
      this.$emit('setIsGate');
      return this.functions.openItemForm(schedule_id);
    },
    getChildItems(deviceId, pageNumber) {
      this.$emit('setIsGate');
      return this.functions.getChildItems(deviceId, pageNumber);
    },
    addNewChild(parentId) {
      this.$emit('setIsGate');
      return this.functions.addNewChild(parentId);
    },
  },
};
</script>