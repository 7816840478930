<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-list-item v-if="!editable">
          <v-list-item-content>
            <Label label="時間" :editable="!editable">
              <v-text-field
                :label="getProhibitTime(item)"
                dense
                color="primary"
                :disabled="!editable"
                :filled="editable"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>
        <v-row v-if="editable">
          <v-col cols="12" sm="5" md="5">
            <v-list-item>
              <v-list-item-content>
                <Label label="開始時間" :editable="editable" :required="!item.usage_start_time && item.usage_end_time">
                  <Select
                    name="usage_start_time"
                    :values="item"
                    :items="time_start"
                    :editable="editable"
                    :validation_label="`開始時間 [${index}]`"
                    :validation_rules="getStartTimeRules(item)"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="5" md="5">
            <v-list-item>
              <v-list-item-content>
                <Label label="終了時間" :editable="editable" :required="item.usage_start_time && !item.usage_end_time">
                  <Select
                    name="usage_end_time"
                    :values="item"
                    :items="time_end"
                    :editable="editable"
                    :validation_label="`終了時間 [${index}]`"
                    :validation_rules="getEndTimeRules(item)"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col v-if="index > 0 && editable" cols="12" sm="2" md="2" class="pt-0 pb-0">
            <v-btn class="mt-8" icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn class="ml-4" v-if="editable" color="primary" @click="addEmpty" style="z-index: 3;" depressed small
      >追加</v-btn
    >
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "@/components/forms/elements/Label";
import Select from "@/components/forms/elements/Select";
import { cmn_mst_time } from "@/constants/SCHEDULE_CRANE";
import { Store } from "@/store/Store.js";

const INITIAL_VALUES = {
  usage_start_time: null,
  usage_end_time: null,
};

export default {
  data: () => {
    return {
      items: [],
      time_start: [],
      time_end: [],
      WORKING_TIME_ZONE: Store.state.PortalChart.listTimeZones,
      workingTime: {},
      cmn_mst_time,
    };
  },
  components: {
    Label,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue, oldValue) => {
        if (this.items?.length !== newValue[0][newValue[1]]?.length
          || JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.items = newValue[0][newValue[1]];
          this.workingTime = this.WORKING_TIME_ZONE.find(item => item.id == newValue[0]['work_hour_zone'] );
          this.getTime();
        }
        if(!this.items || this.items?.length == 0) {
          this.items = [];
          this.items.push({ ...INITIAL_VALUES });
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters['ScheduleMachines/getWorkHouseZone'],
      (newValue) => {
        this.workingTime = this.WORKING_TIME_ZONE.find(item => item.id == newValue );
        this.getTime();
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      const items = [...this.items];
      const itemEdited = {...this.items[index]};
      itemEdited[value.name] = value.value;
      items[index] = itemEdited;
      this.$emit("onInput", { name: this.name, value: items });
    },
    remove(index) {
      const items = this.items.filter((item, i) => {
        return index != i;
      });
      this.$emit("onInput", { name: this.name, value: items });
    },
    addEmpty() {
      const items = [...this.items];
      items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: items });
    },
    getProhibitTime(item) {
      const _item = {...item};
      let usage_start_time = _item.usage_start_time
        ? this.time_start.find(item => item.id == _item.usage_start_time).name
        : "";
      let usage_end_time = _item.usage_end_time
        ? this.time_end.find(item => item.id == _item.usage_end_time).name
        : "";
      return usage_start_time != "" && usage_end_time != ""
        ? usage_start_time + "～" + usage_end_time
        : "";
    },
    getTime() {
      if(this.workingTime) {
        this.time_start = this.cmn_mst_time.filter(item => {
          return item.id >= this.workingTime.start_time && item.id < this.workingTime.end_time
        });
        this.time_start.unshift({ id: null, name: "" });
        this.time_end = this.cmn_mst_time.filter(item => {
          return item.id > this.workingTime.start_time && item.id <= this.workingTime.end_time
        });
        this.time_end.unshift({ id: null, name: "" });
      }
    },
    getStartTimeRules(item) {
      let rule = item.usage_end_time ? "required-usage-start-time|" + `compare-to-end-time:${item.usage_end_time}|` : "";
      if(this.items.length > 1 && item.usage_start_time && item.usage_end_time) {
        const isNotDuplicate = this.getCountOfItem(item)== 1 ? 1 : 0;
        rule = rule + `|duplicate-time:${isNotDuplicate}`;
      }
      return rule;
    },
    getEndTimeRules(item) {
      let rule = item.usage_start_time ? "required-usage-end-time|" + `compare-to-start-time:${item.usage_start_time}|` : "";
      if(this.items.length > 1 && item.usage_start_time && item.usage_end_time) {
        const isNotDuplicate = this.getCountOfItem(item)== 1 ? 1 : 0;
        rule = rule + `|duplicate-time:${isNotDuplicate}`;
      }
      return rule;
    },
    getCountOfItem(item) {
      let index = 0;
      this.items.forEach(element => {
        if(item.usage_start_time == element.usage_start_time && item.usage_end_time == element.usage_end_time) {
          index++;
        }
      })
      return index;
    }
  },
};
</script>
