<template>
  <div class="wrapper">
    <Select
        :name="name"
        :values="values"
        :items="items"
        :editable="true"
        @onInput="onInput"
    />
  </div>
</template>
<script>
import Select from "@/components/forms/elements/Select.vue";
import { Store } from "@/store/Store.js";

export default {
  data: () => {
    return {
      items: [],
    };
  },
  props: {
    name: {
      type: String,
    },
    siteId: {
      type: Number,
    },
    values: {
      type: Object,
    },
  },
  components: {
    Select,
  },
  mounted() {
    /**
     * 作業場所一覧
     */
    this.$watch(
        () => Store.getters["PortalChart/getWorkTypes"],
        (newValue) => {
          this.items = [...newValue];
          if (newValue.length > 0 && !this.values[this.name]) {
            const item = newValue[0];
            this.onInput({ name: this.name, value: item.id });
          }
        },
        { deep: true }
    );

    /**
     * 現場idから作業場所一覧を取得
     */
    this.$watch(
        () => this.siteId,
        (newValue) => {
          if (newValue > 0) {
            Store.dispatch("PortalChart/getWorkTypes", newValue);
          } else {
            this.items = [];
          }
        },
        { immediate: true, deep: true }
    );
  },
  methods: {
    onInput({ name, value }) {
      console.log("作業場所", name, value);
      this.$emit("onInput", { name, value });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  height: 40px;
}
</style>
