<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click.stop="$emit('downloadDocument', {file_code: file_code, machine_company_field_id: machine_company_field_id , machine_company_field_report_id : machine_company_field_report_id})"
  >
    <path
      d="M 11.033203 3.2207031 C 10.958186 3.2183086 10.882062 3.2229062 10.804688 3.2382812 L 2.8046875 4.8398438 C 2.3366875 4.9338437 2 5.3433125 2 5.8203125 L 2 18.179688 C 2 18.656688 2.3366875 19.066156 2.8046875 19.160156 L 10.804688 20.761719 C 11.423687 20.885719 12 20.410297 12 19.779297 L 12 4.2207031 C 12 3.6685781 11.558326 3.2374648 11.033203 3.2207031 z M 14 5 L 14 7 L 16 7 L 16 9 L 14 9 L 14 11 L 16 11 L 16 13 L 14 13 L 14 15 L 16 15 L 16 17 L 14 17 L 14 19 L 21 19 C 21.552 19 22 18.552 22 18 L 22 6 C 22 5.448 21.552 5 21 5 L 14 5 z M 18 7 L 20 7 L 20 9 L 18 9 L 18 7 z M 5.4375 8.296875 C 5.7495 8.296875 6.0323438 8.4854375 6.1523438 8.7734375 L 6.8769531 10.511719 C 6.9519531 10.692719 7.0084063 10.902625 7.0664062 11.140625 L 7.0917969 11.140625 C 7.1247969 10.997625 7.1919688 10.779141 7.2929688 10.494141 L 8.1328125 8.703125 C 8.2488125 8.455125 8.4974844 8.296875 8.7714844 8.296875 C 9.3034844 8.296875 9.6444844 8.8620312 9.3964844 9.3320312 L 8.0078125 11.966797 L 9.4140625 14.599609 C 9.6780625 15.099609 9.3179531 15.703125 8.7519531 15.703125 C 8.4599531 15.703125 8.1923125 15.533578 8.0703125 15.267578 L 7.1582031 13.287109 C 7.1162031 13.202109 7.0663906 13.032922 7.0253906 12.794922 L 7.0097656 12.794922 C 6.9847656 12.908922 6.934375 13.079594 6.859375 13.308594 L 5.9277344 15.296875 C 5.8117344 15.544875 5.5611094 15.703125 5.2871094 15.703125 L 5.2011719 15.703125 C 4.6621719 15.703125 4.3210312 15.123344 4.5820312 14.652344 L 6.0605469 11.994141 L 4.7480469 9.421875 C 4.4860469 8.906875 4.8595 8.296875 5.4375 8.296875 z M 18 11 L 20 11 L 20 13 L 18 13 L 18 11 z M 18 15 L 20 15 L 20 17 L 18 17 L 18 15 z"
      fill="black"
    />
  </svg>
</template>
<script>
export default {
  props: {
    machine_company_field_id: Number,
    file_code: Number,
    machine_company_field_report_id : Number
  },
};
</script>