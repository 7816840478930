<template>
  <v-card class="dialog">
    <div class="header sticky">
      <v-card-title small>
        <div class="title">履歴から選択</div>
        <v-spacer></v-spacer>
        <v-btn small icon text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
    </div>
    <SearchSelectList
      placeHolder=""
      @search="getDataHistory"
      @formUpdate="formUpdate"
      hidden
    >
    </SearchSelectList>
    <v-list v-if="list.length > 0" style="border-top: 1px solid #ddd">
      <v-list-item
        v-for="(item, i) in list"
        :key="i"
        ripple 
        :class="{'border' : i != list.length - 1}"
      >
        <v-list-item-content class="text-break" style="white-space: pre-wrap;" @click="onClick(item)">
          <div>
            <span v-for="(childItem, j) in item" :key="`${i}-${j}`">
              {{ listWorkQualifications.find(x => x.id == childItem).name }}
              {{ j == item.length - 1 ? '' : '、'}}
            </span>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import SearchSelectList from "./SearchSelectList.vue";
import { Store } from "@/store/Store.js";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
export default {
  components: {
    SearchSelectList,
  },

  props: {
    field_id: {
      type: Number,
      default: null
    },
    isObayashi: {
      type: Number,
      default: null
    },
    listWorkQualifications: {
      type: Array,
      default: () => []
    },
    nameFunction: {
      type: String,
      default: ''
    },
    historyType: {
      type: Number,
      default: null
    }
  },

  data: () => {
    return {
      keyword: "",
      list: [],
      NO_DATA_MESSAGE,
      ENV_CLIENT
    };
  },
  async mounted() {
    await this.getDataHistory()
  },
  methods: {
    formUpdate(values) {
      this.keyword = values.keyword;
    },

    onClick(item) {
      this.$emit("updateQualificationHistory", item);
    },
    async getDataHistory(){
      const params = {
        field_id: this.field_id,
        content_search: this.keyword,
        is_oba: this.isObayashi,
        history_type: this.historyType
      }
      await Store.dispatch(`Schedule/${this.nameFunction}`, params).then(res => {
        this.list = res.data.contents.entries
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #1B9C4F;
}
.dialog {
  padding-bottom: 20px;
  .header {
    height: 65px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }
  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;
      .text {
        text-align: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 80%;
      }
    }
  }
  .btn {
    margin-right: 12px;
  }
}

.border {
  border-bottom: 1px solid #ddd;
}
</style>
