<template>
  <FormDialog>
    <template #header>
      <!-- 
        (共通)
        ヘッダーは全て共通
       -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          {{ companyName }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeForm">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="form in FORMS_ARRAY" :key="form.id">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <DocumentViewer
            :isOwner="true"
            :tab="0"
            :currenTab="tab"
            :forms="FORMS_ARRAY"
            :histories="histories"
            :mainHeight="params.mainHeight"
            @onClickCreateReportsOwner="onClickCreateReportsOwner"
          />
        </v-tab-item>
        <v-tab-item>
          <DocumentViewer
            :isOwner="true"
            :tab="1"
            :forms="FORMS_ARRAY"
            :currenTab="tab"
            :histories="histories"
            :mainHeight="params.mainHeight"
            @onClickCreateReportsOwner="onClickCreateReportsOwner"
          />
        </v-tab-item>
        <v-tab-item v-if="IS_OBAYASHI">
          <DocumentViewer
            :isOwner="true"
            :tab="2"
            :forms="FORMS_ARRAY"
            :currenTab="tab"
            :histories="histories"
            :mainHeight="params.mainHeight"
            @onClickCreateReportsOwner="onClickCreateReportsOwner"
          />
        </v-tab-item>
        <v-tab-item v-if="IS_OBAYASHI">
          <DocumentViewer
            :isOwner="true"
            :tab="3"
            :forms="FORMS_ARRAY"
            :currenTab="tab"
            :histories="histories"
            :mainHeight="params.mainHeight"
            @onClickCreateReportsOwner="onClickCreateReportsOwner"
          />
        </v-tab-item>
        <v-tab-item v-if="!IS_OBAYASHI">
          <DocumentViewer
            :isOwner="true"
            :tab="2"
            :currenTab="tab"
            :forms="FORMS_ARRAY"
            :histories="histories"
            :mainHeight="params.mainHeight"
            @onClickCreateReportsOwner="onClickCreateReportsOwner"
          />
        </v-tab-item>
      </v-tabs-items>
    </template>
  </FormDialog>
</template>

<script>
import FormDialog from "@/components/dialog/FormDialog.vue";
import DocumentViewer from "./components/DocumentViewer.vue";
import { FORMS_OWNER_COMPANY , FORMS_OWNER_COMPANY_OBAYASHI, TAB_CREATE_DOCUMENT_OWNER } from "@/constants/SAFETY_CHART.js";
import { Store } from "@/store/Store.js";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";

export default {
  name: "DocumentForm",
  data: () => {
    return {
      tab: null,
      isClickCancelBtn: false,
      histories: null,
    };
  },
  components: {
    FormDialog,
    DocumentViewer,
  },
  props: {
    companyId: Number,
    companyName: String,
    chartId: Number,
    formTab: Object,
    tabCurrent: Number,
  },
  computed : {
    IS_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
    FORMS_ARRAY() {
      if (this.IS_OBAYASHI) {
        return Object.keys(FORMS_OWNER_COMPANY_OBAYASHI).map((key) => {
          return FORMS_OWNER_COMPANY_OBAYASHI[key];
        });
      } else {
        return Object.keys(FORMS_OWNER_COMPANY).map((key) => {
          return FORMS_OWNER_COMPANY[key];
        });
      }
    },
  },
  mounted() {
    /**
     * get data field_tree
     */
    this.$watch(
      () => Store.getters["SafetyChart/getPrimeReports"],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.histories = newValue;
          if (this.tabCurrent) this.tab = this.tabCurrent;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    /**
     * プレビュー
     */
    onClickCreateReportsOwner() {
      this.$nextTick(() => {
        const chart = {
          id: this.chartId,
          tabCreateReport: this.tab
        }
        if (this.tab == TAB_CREATE_DOCUMENT_OWNER.TAB_DOC_SYSTEM_NOTE) {
          this.$emit('listCompanyLevel1', this.histories?.field_trees);
        }
        this.$emit("onCreateReportOwner", chart);
      });
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      this.isClickCancelBtn = false;
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
