<template>
  <div class="schedule__type-label">
    <div>
      <span class="type-circle type_occupy"></span
      ><span class="type-label">占有</span>
    </div>
    <div v-if="!isObayashi">
      <span class="type-circle type_spot"></span
      ><span class="type-label">スポット</span>
    </div>
    <div v-if="!flagBanMachine">
      <span class="type-circle type_disable"></span
      ><span class="type-label">禁止</span>
    </div>
    <div v-if="!isCompany">
      <span class="type-circle type_overlap"></span
      ><span class="type-label">重複</span>
    </div>
  </div>
</template>
<script>
import { ENV_CLIENT } from "@/constants/ENV_CLIENT";
export default {
  props: {
    isCompany: {
      type: Boolean,
      default: false,
    },
    flagBanMachine: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isObayashi() {
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.schedule__type-label {
  display: flex;
  align-items: center;
  padding: 0 8px;

  .type-circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: black;
    border-radius: 5px;
    margin-right: 2px;
    &.type_occupy {
      background-color: $color_schedule_type_occupy;
    }
    &.type_spot {
      background-color: $color_schedule_type_spot;
    }
    &.type_disable {
      background: repeating-linear-gradient(119deg, #ffffff, #ffffff 0px, #9e9e9e 4px, #9e9e9e 0px);
    }
    &.type_overlap {
      background-color: $color_schedule_type_overlap;
    }
  }
  .type-label {
    font-size: 12px;
    height: 10px;
    margin-right: 8px;
  }
}
</style>
