<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row v-if="isShowSafetyInstruction">
          <v-col cols="12" sm="11" md="11">
            <v-list-item>
              <v-list-item-content>
                <Label label="安全指示事項" :editable="editable" :required="isRequiredSafetyInstruction">
                  <Select
                    name="cmn_mst_safety_instruction_id"
                    :values="item"
                    item_text="safety_instruction"
                    :items="safety_instructions"
                    validation_label="安全指示事項"
                    :validation_rules="isRequiredSafetyInstruction?'required':''"
                    :editable="editable"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col v-if="index > 0 && editable" cols="12" sm="1" md="1" class="pt-0 pb-0">
            <v-btn class="mt-8" icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn class="ml-4" v-if="editable && isShowSafetyInstruction" color="primary" @click="addEmpty" depressed small
      >追加</v-btn
    >
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "../../../elements/Label.vue";
import Select from "../../../elements/Select.vue";
import { Store } from "@/store/Store.js";

const INITIAL_VALUES = {
  cmn_mst_safety_instruction_id: null,
};

export default {
  data: () => {
    return {
      items: [],
      cmn_mst_safety_instructions: Store.state.CmnMst.constants.entries.cmn_mst_safety_instructions,
      safety_instructions: []
    };
  },
  components: {
    Label,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    isShowSafetyInstruction: {
      type: Boolean,
      default: true
    },
    isRequiredSafetyInstruction: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue, oldValue) => {
        if (this.items?.length !== newValue[0][newValue[1]]?.length
          || JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.items = newValue[0][newValue[1]];
        }
        if(!this.items || this.items?.length == 0) {
          this.items = [];
          this.items.push({ ...INITIAL_VALUES });
        }
        this.safety_instructions = [{ id: null, safety_instruction: "" }, ...this.cmn_mst_safety_instructions];
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      const items = [...this.items];
      const itemEdited = {...this.items[index]};
      itemEdited[value.name] = value.value;
      items[index] = itemEdited;
      this.$emit("onInput", { name: this.name, value: items });
    },
    remove(index) {
      const items = this.items.filter((item, i) => {
        return index != i;
      });
      this.$emit("onInput", { name: this.name, value: items });
    },
    addEmpty() {
      const items = [...this.items];
      items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: items });
    },
  },
};
</script>
