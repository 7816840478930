import Vue from "vue";
import Vuex from "vuex";
import { machineField } from "../../../api/modules/machineField";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = machineField;

export const MachineField = {
  namespaced: true,

  state: {
    listMachineField : {}
  },

  mutations: {
    SET_LIST_MACHINE_FIELD(state, payload) {
      state.listMachineField = payload;
    },
  },

  actions: {
    async getListMachineField({ commit }, payload) {
      const response = await ENTITY.getListMachineField(payload);
      if (response.hasError) {
        return response;
      }
      const entries = response.data.contents.entries;
      commit("SET_LIST_MACHINE_FIELD", entries);
      return response;
    },
    async updateMachineFieldRelation(_context, payload) {
      return await ENTITY.updateMachineFieldRelation(payload);
    },
  },

  getters: {
    getListMachineField: (state) => {
      return state.listMachineField;
    }
  },
};
