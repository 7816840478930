<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="最高作業床高さ" :editable="editable">
                            <InputText
                              name="maximum_work_floor_height"
                              :values="formValues"
                              :editable="editable"
                              placeholder="0"
                              suffix="m"
                              validation_label="最高作業床高さ"
                              validation_rules="decimal"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="積載荷重" :editable="editable">
                            <InputText
                              name="movable_load"
                              :values="formValues"
                              :editable="editable"
                              placeholder="0"
                              suffix="kg"
                              validation_label="積載荷重"
                              validation_rules="decimal"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="車体全長" :editable="editable">
                            <InputText
                              name="body_length"
                              :values="formValues"
                              :editable="editable"
                              placeholder="0"
                              suffix="mm"
                              validation_label="車体全長"
                              validation_rules="decimal"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="車体全幅" :editable="editable">
                            <InputText
                              name="body_width"
                              :values="formValues"
                              :editable="editable"
                              placeholder="0"
                              suffix="mm"
                              validation_label="車体全幅"
                              validation_rules="decimal"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="車体全高" :editable="editable">
                            <InputText
                              name="body_height"
                              :values="formValues"
                              :editable="editable"
                              placeholder="0"
                              suffix="mm"
                              validation_label="車体全高"
                              validation_rules="decimal"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="車輌重量" :editable="editable">
                            <InputText
                              name="vehicle_weight"
                              :values="formValues"
                              :editable="editable"
                              placeholder="0"
                              suffix="kg"
                              validation_label="車輌重量"
                              validation_rules="decimal"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label
                            label="アウトリガ張出寸法"
                            :editable="editable"
                          >
                            <InputText
                              name="outrigger_overhang_dimensions"
                              :values="formValues"
                              :editable="editable"
                              placeholder="0"
                              suffix="mm"
                              validation_label="アウトリガ張出寸法"
                              validation_rules="decimal"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="バケット容積" :editable="editable">
                            <InputText
                              name="bucket_volume"
                              :values="formValues"
                              :editable="editable"
                              placeholder="0"
                              suffix="m3"
                              validation_label="バケット容積"
                              validation_rules="decimal"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="接触防止措置等">
                        <InputTextArea
                          name="prevention_measures"
                          :values="formValues"
                          :editable="editable"
                          validation_label="接触防止措置等"
                          @onInput="onInput"
                          validation_rules="max:512"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <SectionLabel label="資格・免許" :editable="editable" class="mt-6" />
                  <QualificationLicenseInput
                    name="machine_company_licenses"
                    :values="formValues"
                    :editable="editable"
                    @onInput="onInput"
                  />
                  <SectionLabel label="特別教育" :editable="editable" class="mt-6" />
                  <SpecialEducationInput
                    name="machine_company_sp_trainings"
                    :values="formValues"
                    :editable="editable"
                    @onInput="onInput"
                  />
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="機械等の特性・その他その使用上注意すべき事項" :editable="editable">
                        <InputTextArea
                          name="characteristics"
                          :values="formValues"
                          :editable="editable"
                          validation_label="備考"
                          @onInput="onInput"
                          validation_rules="max:512"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import Label from "@/components/forms/elements/Label.vue";
import SectionLabel from "@/components/forms/elements/SectionLabel.vue";
import InputText from "@/components/forms/elements/InputText.vue";
import InputTextArea from "../elements/InputTextArea.vue";
import QualificationLicenseInput from "./components/QualificationLicenseInput.vue";
import SpecialEducationInput from "./components/SpecialEducationInput.vue";

export default {
  data: () => {
    return {
      formValues: {},
    };
  },
  components: {
    ValidationObserver,
    Label,
    InputText,
    QualificationLicenseInput,
    SpecialEducationInput,
    SectionLabel,
    InputTextArea
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      const headerHeight = 48;
      let style = `height:${this.mainHeight - headerHeight}px;`;
      return style;
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";
</style>
