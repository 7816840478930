<template>
  <div
    ref="wrapper"
    class="tooltipWrapper"
    :style="wrapperStyle"
    @mouseenter="onEnter"
    @mouseleave="onLeave"
  >
    <slot></slot>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
export default {
  props: {
    inline: {
      type: Boolean,
      default: true,
    },
    block: {
      type: Boolean,
      default: false,
    },
    flex: {
      type: Boolean,
      default: false,
    },
    inlineFlex: {
      type: Boolean,
      default: false,
    },
    inlineBlock: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    style: {
      type: String,
      default: "",
    },
  },
  computed: {
    wrapperStyle() {
      let styleObject = { display: "block" };
      if (this.flex) {
        styleObject.display = "flex";
        styleObject.flex = "1 1";
      }
      if (this.inlineFlex) {
        styleObject.display = "inline-flex";
        styleObject.flex = "1 1";
      }
      if (this.inline) styleObject.display = "inline";
      if (this.inlineBlock) styleObject.display = "inline-block";
      const styleStringArray = Object.keys(styleObject).map((key) => {
        return `${key}:${styleObject[key]};`;
      });

      return `${styleStringArray.join("")}${this.style}`;
    },
  },
  methods: {
    onEnter(event) {
      if (this.text.length) {
        // スクロールが複雑になることをを考慮して、yはマウスイベントから取得
        const wrapperRect = this.$refs.wrapper.getBoundingClientRect();
        const rect = {
          x: wrapperRect.x,
          y: event.clientY,
          left: wrapperRect.left,
          top: event.clientY,
          width: wrapperRect.width,
          height: wrapperRect.height,
        };
        Store.dispatch("Tooltip/show", { message: this.text, rect });
      }
    },
    onLeave() {
      Store.dispatch("Tooltip/destroy");
    },
  },
};
</script>
<style lang="scss" scoped>
.tooltipWrapper {
  display: block;
}
</style>
