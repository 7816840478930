<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row v-for="(item, index) in items" :key="index">
                <v-col cols="12" sm="6" md="6">
                  <SectionLabel :label="`見積依頼日  ${item.estimate_request_date || ''}`" class="ml-4"/>
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="依頼者">
                            <InputText
                              name="estimate_requester_user_name"
                              :values="item"
                              :editable="false"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="依頼先">
                            <InputText
                              name="estimate_rental_company_name"
                              :values="item"
                              :editable="false"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="見積番号">
                            <InputText
                              name="estimate_number"
                              :values="item"
                              :editable="false"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <SectionLabel :label="`発注日  ${item.order_request_date || ''}`" class="ml-4"/>
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="発注者">
                            <InputText
                              name="order_requester_user_name"
                              :values="item"
                              :editable="false"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="発注先">
                            <InputText
                              name="order_rental_company_name"
                              :values="item"
                              :editable="false"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="発注番号">
                            <InputText
                              name="order_number"
                              :values="item"
                              :editable="false"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "@/components/forms/elements/Label.vue";
import InputText from "@/components/forms/elements/InputText.vue";
import SectionLabel from "@/components/forms/elements/SectionLabel.vue";
import { INIT_ITEM_FORM3 } from "@/constants/DEVICE_COORDINATOR";

export default {
  data: () => {
    return {
      items: [],
      INIT_ITEM_FORM3
    };
  },
  components: {
    Label,
    InputText,
    SectionLabel,
  },
  props: {
    item: Object,
    mainHeight: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        if ([...newValue?.machine_company_field_estimate_order_relations].length > 0) {
          this.items = [...newValue.machine_company_field_estimate_order_relations];
        } else {
          this.items = this.INIT_ITEM_FORM3;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
</style>
