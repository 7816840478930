<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="6">
                      <!-- 提出書類名 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="提出書類名" :editable="editable">
                            <Select
                              name="cmn_mst_identification_id"
                              :values="userValue"
                              :items="IDENTIFICATION_ARRAY"
                              item_text="document_name"
                              :editable="editable"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <!-- 公的身分証明書 -->
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="公的身分証明書" :editable="editable">
                            <span
                              class="file"
                              v-if="!editable && userValue['identification_image_name']"
                              @click="viewSourceWithAuth(userValue['identification_image_name_url'])"
                            >{{ userValue["identification_image_name"] }}
                            </span>
                            <FileInput
                              :style="getFileInputStyle('identification_image_name')"
                              :resetValue="resetValue['identification_image_name']"
                              v-if="editable"
                              name="identification_image_name"
                              :values="userValue"
                              :editable="editable"
                              accept="image/jpg, image/png, image/jpeg, application/pdf"
                              validation_label="identification_image_name"
                              validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                            <span
                              class="file"
                              v-if="judgeDisplayFileInput('identification_image_name')"
                              >{{ userValue["identification_image_name"] }}
                              <v-btn
                                small
                                icon
                                text
                                @click="onRemoveFile({
                                  parents_name: USER_EVENT_TARGET.USERS,
                                  name: 'identification_image_name'})"
                              >
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </span>
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <!-- 顔写真 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="顔写真" :editable="editable">
                            <v-img
                              v-if="
                                !editable && userValue['profile_image_name'] && avatar
                              "
                              :src="avatar"
                              max-width="120px"
                              max-height="120px"
                            />
                            <FileInput
                              :style="getFileInputStyle('profile_image_name')"
                              :resetValue="resetValue['profile_image_name']"
                              v-if="editable"
                              name="profile_image_name"
                              :values="userValue"
                              :editable="editable"
                              accept="image/jpg, image/png, image/jpeg"
                              validation_label="profile_image_name"
                              validation_rules="maxsize:10|file-type:jpg, png, jpeg"
                              @onInput="onInputImage(USER_EVENT_TARGET.USERS, $event)"
                            />
                            <span
                              class="file"
                              v-if="judgeDisplayFileInput('profile_image_name')"
                              >{{ userValue["profile_image_name"] }}
                              <v-btn
                                small
                                icon
                                text
                                @click="
                                  onRemoveFile({
                                    parents_name: USER_EVENT_TARGET.USERS,
                                    name: 'profile_image_name',
                                  })
                                "
                              >
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </span>
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <SectionLabel label="家族連絡先" :editable="editable" />
                  <v-row no-gutters>
                    <!-- 氏名 -->
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="氏名" :editable="editable">
                            <InputText
                              name="relation_full_name"
                              :values="userValue"
                              :editable="editable"
                              placeholder="山田 花子"
                              validation_label="氏名"
                              validation_rules="max:128"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <!-- 続柄 -->
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="続柄" :editable="editable">
                            <InputText
                              name="relationship_with_user"
                              :values="userValue"
                              :editable="editable"
                              placeholder="母"
                              validation_label="続柄"
                              validation_rules="max:32"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <!-- 郵便番号 -->
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="郵便番号" :editable="editable">
                            <InputText
                              name="relation_postal_code"
                              :values="userValue"
                              :editable="editable"
                              placeholder="001-0010"
                              validation_label="郵便番号"
                              validation_rules="postcode"
                              @onInput="onChangePostalCode"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <!-- 都道府県 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="都道府県" :editable="editable">
                            <Select
                              name="relation_cmn_mst_prefecture_id"
                              :values="userValue"
                              :items="PREFECTURES"
                              item_text="prefecture"
                              :editable="editable"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <!-- 市区町村 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="市区町村" :editable="editable">
                            <InputText
                              name="relation_city"
                              :values="userValue"
                              :editable="editable"
                              placeholder="千代田区"
                              validation_label="市区町村"
                              validation_rules="max:255"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <!-- 番地・建物名 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="番地・建物名" :editable="editable">
                            <InputText
                              name="relation_address"
                              :values="userValue"
                              :editable="editable"
                              placeholder="番地・建物名"
                              validation_label="番地・建物名"
                              validation_rules="max:255"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <!-- 電話番号 -->
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="電話番号" :editable="editable">
                            <InputText
                              name="relation_tel"
                              :values="userValue"
                              :editable="editable"
                              placeholder="03-1234-5678"
                              validation_label="電話番号"
                              validation_rules="phone"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "../elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "../elements/InputText";
import InputDatepicker from "../elements/InputDatepicker.vue";
import Select from "../elements/Select.vue";
import FileInput from "../elements/FileInput.vue";
import { USER_EVENT_TARGET } from "@/constants/EMPLOYEE";
import _ from "lodash";
import { postalCodeSearch } from "@/utils/postalCodeSearch";
import { viewSourceWithAuth, getParamsOfUrlReadFile } from "@/utils/viewSourceAuth";
import { convertPngToJpg } from "@/utils/convertPngToJpg";

export default {
  data: () => {
    return {
      formValues: {},
      userValue: {},
      IDENTIFICATION_ARRAY: [{ id: null, document_name: "" },...Store.state.CmnMst.constants.entries.cmn_mst_identification],
      PREFECTURES: [{ id: null, prefecture: "" },...Store.state.CmnMst.constants.entries.cmn_mst_prefectures],
      USER_EVENT_TARGET,
      fileValid: {},
      resetValue: {},
      avatar: null,
      viewSourceWithAuth: viewSourceWithAuth
    };
  },
  components: {
    Label,
    SectionLabel,
    InputText,
    InputDatepicker,
    Select,
    FileInput,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    errors: {
      type: Object,
      default: {},
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        const formValues = { ...newValue };
        this.formValues = { ...formValues };
        this.userValue = { ...formValues[USER_EVENT_TARGET.USERS] };
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.userValue.profile_image_name_url,
      (newValue, oldValue) => {
        if(newValue != oldValue) {
          this.avatar = null;
          this.viewAvatar(newValue);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    // check input file error
    this.$watch(
      () => [this.item, this.errors],
      (data) => {
        this.formValues = { ...data[0] };
        const errors = { ...data[1] };
        // the validation_label list of the FileInput element
        const names = ["identification_image_name", "profile_image_name"];
        const fileValid = { ...this.fileValid };
        const formValues = _.cloneDeep(this.formValues);
        if (errors) {
          for (let name in errors) {
            if (names.some((item) => item === name)) {
              if (errors[name].length > 0) {
                delete formValues[USER_EVENT_TARGET.USERS][name];
                delete formValues[USER_EVENT_TARGET.USERS][`${name}_data`];
                fileValid[name] = false;
              } else {
                fileValid[name] = true;
              }
            }
          }
        }
        this.formValues = formValues;
        this.fileValid = fileValid;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    async viewAvatar(sourceUrl) {
      if (sourceUrl) {
        const params = getParamsOfUrlReadFile(sourceUrl);
        const response = await Store.dispatch("File/readFile", params);
        if(!response.hasError) {
          this.avatar = window.URL.createObjectURL(new Blob([response.data], { type: response.headers["content-type"]}));
        }
      }
    },

    onInput(parents_name, { name, value, fileName }) {
      const formValues = { ...this.formValues };
      if (fileName) {
        formValues[parents_name][`${name}_data`] = value;
        formValues[parents_name][name] = fileName;
        if (!value) {
          delete formValues[parents_name][`${name}_deleted`];
        }
      } else {
        formValues[parents_name][name] = value;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    async onInputImage(parents_name, { name, value, fileName }) {
      if(value) {
        if(fileName.indexOf(".png") !== -1) {
          value = await convertPngToJpg(value);
          fileName = fileName.replace("png", "jpg");
        }
        const params = {
          worker_picture: value
        };
        const result = await Store.dispatch(`FaceRecognition/checkFace`, params);
        if(!result.hasError) {
          const formValues = { ...this.formValues };
          formValues[parents_name][`${name}_data`] = value;
          formValues[parents_name][name] = fileName;
          if (!value) {
            delete formValues[parents_name][`${name}_deleted`];
          }
          this.$emit("formUpdate", formValues);
        }
      }
    },
    onRemoveFile({ parents_name, name }) {
      const formValues = { ...this.formValues };
      delete formValues[parents_name][name];
      delete formValues[parents_name][`${name}_data`];
      if (formValues[parents_name][`${name}_url`])
        formValues[parents_name][`${name}_deleted`] = 1;
      this.formValues = formValues;
      this.resetValue[name] = !this.resetValue[name];
      this.$emit("formUpdate", formValues);
    },
    getFileInputStyle(fieldName) {
      return this.fileValid[fieldName] && this.userValue[fieldName]
        ? "display:none;"
        : "";
    },
    judgeDisplayFileInput(fieldName) {
      return (
        this.editable && this.fileValid[fieldName] && this.userValue[fieldName]
      );
    },

    /**
     * 郵便番号から都道府県、市町村名を検索
     */
    async onChangePostalCode({ value }) {
      const formValues = { ...this.formValues };
      formValues.users.relation_postal_code = value;
      if (value == "") {
        formValues.users.relation_cmn_mst_prefecture_id = "";
        formValues.users.relation_city = "";
      } else if (value.match(/^\d{3}-?\d{4}$/g)) {
        const result = await postalCodeSearch.search({ postal_code: value });
        if (result) {
          formValues.users.relation_cmn_mst_prefecture_id = result.prefecture.id;
          formValues.users.relation_city = result.city;
        }
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";
</style>
