import Api, { Mock } from "../api";
import mock from "./mock/constants.json";

const PATH = "cmn_mst";
const URL = `${process.env.VUE_APP_API_BASE_URL}/${PATH}`;

// .envのVUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  Mock.onGet(URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock,
  });
}

export const cmnMst = {
  get: (params) => {
    return Api.get(URL, params);
  },
};
