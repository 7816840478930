<template>
  <div class="mt-5 mb-cst">
    <!-- List button header -->
    <template>
      <v-btn
        depressed
        class="mx-3"
        color="warning"
        :disabled="disableBtn"
        v-if="roleUser === ROLE_USER[3]"
        @click="confirmUnApprove()"
      >
        承認解除
      </v-btn>
      <v-btn
        depressed
        class="mx-3"
        color="primary"
        :disabled="disableBtn"
        v-if="roleUser === ROLE_USER[3]"
        @click="confirmApprove()"
      >
        承認
      </v-btn>
      <v-btn
        class="mx-3"
        small
        icon
        :disabled="disableBtn"
        @click="confimRemoveDialog()"
      >
        <v-icon> mdi-delete </v-icon>
      </v-btn>
    </template>
    <!-- parent table -->
    <v-data-table
      :headers="tableLabels"
      :items="items"
      v-model="selectedItems"
      :items-per-page="itemsPerPage"
      :height="tableHeight-40"
      fixed-header
      hide-default-footer
      hide-default-header
      disable-sort
      :show-select="true"
      class="elevation-0 v-data-table__wrapper"
      sort-by="updatedAt"
      :noDataText="NO_DATA_MESSAGE"
      item-key="id"
      @click:row="openUpdateDetailForm"
    >
      <template slot="header" :headers="tableLabels">
        <thead>
          <tr>
            <th style="width: 50px">
              <v-simple-checkbox
                :value="selectedItems.length === items.length && items.length > 0"
                @input="clickCheckAll($event)"
              ></v-simple-checkbox>
            </th>
            <th v-for="(h, index) in tableLabels" :key="h.value" :style="getWidthStyle(index)">
              <div v-for="text in convertTextHeaderArr(h.text)" :key="text">
                <div>{{text}}</div>
              </div>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:[`item.approval_flag`]="{ item }" label small>
        <v-chip
          small
          :color="getColor(item.approval_flag)"
          >{{ getAproveStatusValue(item.approval_flag) }}</v-chip
        >
      </template>
      <template v-slot:[`item.machine_type_extral_machine_name`]="{ item }" label small>
        <div class="cst-overflow-td">{{ item.machine_type }}</div>
        <div class="cst-overflow-td">{{ item.machine_name }}</div>
      </template>
      <template v-slot:[`item.delivery_company_name_extral_machine_number`]="{ item }" label small>
        <div class="cst-overflow-td">{{ item.delivery_company_name }}</div>
        <div class="cst-overflow-td">{{ item.machine_number }}</div>
      </template>
      <template v-slot:[`item.export_form`]="{ item }" label small>
        <MarkExcel
          class="mr-4"
          :file_code="FILE_CODE.EXCEL"
          :machine_company_field_id="item.machine_company_field_id"
          @downloadDocument="downloadDocument"
        />
        <MarkPdf
          :file_code="FILE_CODE.PDF"
          :machine_company_field_id="item.machine_company_field_id"
          @downloadDocument="downloadDocument"
        />
      </template>
    </v-data-table>
    <Popup width="480px" :dialog="popups.isShowConfirmDialog">
      <ConfirmAproveDialog
        :flagError="flagError"
        @close="closeConfirmDialog()"
        @yes="updateApproval()"
        :title="popups.title"
        :text="popups.message"
        :text2="popups.text2"
        :titleBtnOk="popups.titleBtnOk"
        :flagAprrove="approvalFlag"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        :flagError="flagError"
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        warning
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowMessageDialog">
      <ConfirmDialog
        @OK="closeMessageDialog()"
        :title="popups.title"
        :message="popups.message"
        :flagAprrove="approvalFlag"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowMessageDelDialog">
      <ConfirmMessDeleteDialog
        @OK="closeMessageDelDialog()"
        :title="popups.title"
        :message="popups.message"
        :text2="popups.text2"
        warning
      />
    </Popup>
    <Popup :dialog="popups.isShowMachineBringForm">
      <MachineBringForm
        @cancel="closeItemForm"
      />
    </Popup>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import { NO_DATA_MESSAGE, FILE_CODE } from "@/constants/COMMON";
import { ROLE_USER, APPROVAL_COLORS, INFO_CONFIRM_DIALOG, CARRY_ONS_TABS, FILE_NAME_DOWNLOAD } from "@/constants/DEVICE_CARRY_ON";
import Popup from "@/components/common/Popup";
import ConfirmAproveDialog from './ConfirmAproveDialog.vue';
import ConfirmMessDeleteDialog from './ConfirmMessDeleteDialog.vue';
import ConfirmDialog from './ConfirmDialog.vue';
import ConfirmRemoveDialog from './ConfirmRemoveDialog.vue';
import MarkExcel from './MarkExcel.vue';
import MarkPdf from './MarkPdf.vue';
import { format } from "date-fns";
import MachineBringForm from "@/components/forms/device/carryOn/MachineBringForm.vue";
const STORE = "DeviceCarryOns";
const URL_EXPORT_NOTIFICATION_OF_USE_CRANE = `${process.env.VUE_APP_API_BASE_URL}/machine_field_reports/notification_of_use_crane`;
const URL_EXPORT_MACHINE_NOTIFICATION_CERTIFICATE = `${process.env.VUE_APP_API_BASE_URL}/reports/machine_notification_certificate`;
const FILE_TYPE_CODE = FILE_CODE;

export default {
  data() {
    return {
      NO_DATA_MESSAGE,
      ROLE_USER,
      APPROVAL_COLORS,
      INFO_CONFIRM_DIALOG,
      approvalFlag: false,
      selectedItems: [],
      flagError: false,
      popups: {
        isShowRemoveDialog: false,
        isShowConfirmDialog: false,
        isShowMessageDialog: false,
        isShowMessageDelDialog: false,
        isShowMachineBringForm : false,
        title: "",
        message: "",
        text2: "",
        titleBtnOk: ""
      },
      FILE_CODE,
    };
  },
  components: {
    Popup,
    ConfirmAproveDialog,
    ConfirmDialog,
    ConfirmRemoveDialog,
    ConfirmMessDeleteDialog,
    MarkPdf,
    MarkExcel,
    MachineBringForm
  },
  props: {
    tableLabels: {
      type: Array,
    },
    items: Array,
    itemsPerPage: Number,
    tableHeight: Number,
    roleUser: Number,
    table_type: Number,
  },
  mounted() {
    this.$watch(
      () => [Store.getters["File/getData"], Store.getters["File/getTypeDoc"]],
      (data) => {
        if (data && data[0] && data[1]) {
          const url = window.URL.createObjectURL(new Blob([data[0]]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${
              this.getNameFile(this.table_type, data[1])
            }_${format(new Date(), "yyyyMMddhhmmss")}.${
              data[1] == FILE_CODE.PDF ? "pdf" : "xlsx"
            }`
          );
          document.body.appendChild(link);
          link.click();
          Store.dispatch("File/clearData");
        }
      },
      {
        deep: true,
      }
    );
  },
  computed: {
    disableBtn() {
      return this.selectedItems.length === 0;
    }
  },
  methods: {
    /**
     * set width for column
     */
    getWidthStyle(column) {
      let style = "";
      if (column == 0) {
        style = 'width: 100px';
      } else if (column == 2) {
        style = 'width: 25vw';
      } else if (column == 3) {
        style = 'width: 20vw';
      } else {
        style = 'width: 10vw';
      }
      return style;
    },
    /**
     * Click checkbox on header
     */
    clickCheckAll(event) {
      if (event) {
        this.selectedItems = this.items;
      } else {
        this.selectedItems = [];
      }
    },
    /**
     * Convert label header to array contain data 2 row
     */
    convertTextHeaderArr(textHeader) {
      let textHeaderArr = [];
      if (textHeader.includes(" / ")) {
        textHeaderArr = textHeader.split(" / ");
        textHeaderArr[1] = "/ ".concat(textHeaderArr[1]);
      } else {
        textHeaderArr.push(textHeader);
      }
      return textHeaderArr;
    },
    /**
     * Get color aprroval or unaprroval
     */
    getColor(applyStatus) {
      return this.APPROVAL_COLORS[applyStatus]?.color;
    },
    getAproveStatusValue(applyStatus) {
      return this.APPROVAL_COLORS[applyStatus]?.value;
    },
    /**
     * Show popups confirm aprrove
     */
    confirmApprove() {
      const machineUnAproves = this.selectedItems.filter((e) => e.approval_flag === 0) || [];
      const isAllAproved = machineUnAproves.length == 0;
      if (isAllAproved) {
        this.popups.title = INFO_CONFIRM_DIALOG.CONFIRM_APPROVE_1.title;
        this.popups.message = INFO_CONFIRM_DIALOG.CONFIRM_APPROVE_1.message;
        this.popups.isShowMessageDialog = true;
      } else {
        this.popups.title = INFO_CONFIRM_DIALOG.CONFIRM_APPROVE_2.title;
        this.popups.message = INFO_CONFIRM_DIALOG.CONFIRM_APPROVE_2.message;
        this.popups.text2 = INFO_CONFIRM_DIALOG.CONFIRM_APPROVE_2.text2;
        this.popups.titleBtnOk = INFO_CONFIRM_DIALOG.CONFIRM_APPROVE_2.titleBtnOk;
        this.popups.isShowConfirmDialog = true;
      }
      this.approvalFlag = true;
    },
    /**
     * Show popups confirm unaprrove
     */
    confirmUnApprove() {
      const machineAproveds = this.selectedItems.filter((e) => e.approval_flag === 1) || [];
      const isAllUnAprove = machineAproveds.length === 0;
      if (isAllUnAprove) {
        this.popups.title = INFO_CONFIRM_DIALOG.CONFIRM_UNAPPROVE_1.title;
        this.popups.message = INFO_CONFIRM_DIALOG.CONFIRM_UNAPPROVE_1.message;
        this.popups.isShowMessageDialog = true;
      } else {
        this.popups.title = INFO_CONFIRM_DIALOG.CONFIRM_UNAPPROVE_2.title;
        this.popups.message = INFO_CONFIRM_DIALOG.CONFIRM_UNAPPROVE_2.message;
        this.popups.text2 = INFO_CONFIRM_DIALOG.CONFIRM_UNAPPROVE_2.text2;
        this.popups.titleBtnOk = INFO_CONFIRM_DIALOG.CONFIRM_UNAPPROVE_2.titleBtnOk;
        this.popups.isShowConfirmDialog = true;
      }
      this.approvalFlag = false;
    },
    /**
     * Close popups confirm dialog
     */
    closeConfirmDialog() {
      this.selectedItems = [];
      this.popups.isShowConfirmDialog = false;
    },
    /**
     * Close popups nottify dialog
     */
    closeMessageDialog() {
      this.selectedItems = [];
      this.popups.isShowMessageDialog = false;
    },
    /**
     * Call API update status aproval
     */
    async updateApproval() {
      const ids = this.selectedItems.map((item) => item.id);
      const parrams = {
        ids,
        approval_flag: this.approvalFlag ? 1 : 0
      };
      const result = await Store.dispatch(`${STORE}/updateStatus`, parrams);
      if (result.hasError) {
        this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.message,
        });
        return;
      } else {
        this.closeConfirmDialog();
        Store.dispatch("Toast/show", {
          status: 200,
          message: this.approvalFlag ? "承認しました" : "承認解除しました",
        });
        this.$emit('resetDataItems');
      }
    },
    closeMessageDelDialog() {
      this.selectedItems = [];
      this.popups.isShowMessageDelDialog = false;
    },
    /**
     * show dialog delete
     */
    confimRemoveDialog() {
      const machineUnAproves = this.selectedItems.filter((e) => e.approval_flag === 0) || [];
      const isAllAproved = machineUnAproves.length == 0;
      if (isAllAproved) {
        this.popups.title = INFO_CONFIRM_DIALOG.CONFIRM_REMOVE_DIALOG_1.title;
        this.popups.message = INFO_CONFIRM_DIALOG.CONFIRM_REMOVE_DIALOG_1.message;
        this.popups.text2 = INFO_CONFIRM_DIALOG.CONFIRM_REMOVE_DIALOG_1.text2;
        this.popups.isShowMessageDelDialog = true;
      } else {
        this.popups.isShowRemoveDialog = true;
      }
    },
    closeRemoveDialog() {
      this.selectedItems = [];
      this.popups.isShowRemoveDialog = false;
    },
    /**
     * Call API delete list report
     */
    async removeItems() {
      const ids = this.selectedItems.filter(element => element.approval_flag == 0).map((item) => item.id);
      const result = await Store.dispatch(`${STORE}/delete`, { ids });

      if (result.hasError) {
        this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.message,
        });
        return;
      } else {
        this.closeRemoveDialog();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
        this.$emit('resetDataItems');
      }
    },
    closePreviewDeviceForm() {
      this.popups.isShowRegisterDeviceDialog = false;
      this.dataRegister = {};
    },
    formUpdate(params) {
      this.dataRegister = {...this.dataRegister, ...params};
    },
    async openUpdateDetailForm(item){
      await Store.dispatch(`${STORE}/getMachineFieldDetail`, item.machine_company_field_id);
      this.popups.isShowMachineBringForm = true;
    },
    closeItemForm(){
      this.popups.isShowMachineBringForm = false;
    },
    async downloadDocument(param) {
      this.popups.isShowMachineBringForm = false;
      let params = param;
      if (this.table_type === CARRY_ONS_TABS.TAB2) {
        params.type = CARRY_ONS_TABS.TAB2;
      } else if (this.table_type === CARRY_ONS_TABS.TAB3) {
        params.type = CARRY_ONS_TABS.TAB3;
      }
      const configParams = {
        url: this.table_type == CARRY_ONS_TABS.TAB5 ? 
          URL_EXPORT_MACHINE_NOTIFICATION_CERTIFICATE : URL_EXPORT_NOTIFICATION_OF_USE_CRANE,
        params,
      }
      await Store.dispatch("File/download", configParams);
    },
    getNameFile(tab, file_doc) {
      if(tab == CARRY_ONS_TABS.TAB5) {
        return file_doc == FILE_TYPE_CODE.EXCEL ? FILE_NAME_DOWNLOAD.MACHINE_NOTIFICATION_CERTIFICATE_JP 
          : FILE_NAME_DOWNLOAD.MACHINE_NOTIFICATION_CERTIFICATE;
      } else {
        return file_doc == FILE_TYPE_CODE.EXCEL ? FILE_NAME_DOWNLOAD.NOTIFICATION_OF_USE_CRANE_JP 
          : FILE_NAME_DOWNLOAD.NOTIFICATION_OF_USE_CRANE;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.cst-overflow-td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mb-cst {
  margin-bottom: 2vh !important;
}
::v-deep .v-data-table .v-data-table__wrapper table {
  table-layout: fixed;
}
::v-deep .v-data-table .v-data-table__wrapper table tbody tr td {
  word-break: break-all;
}
</style>
