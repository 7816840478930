<template>
  <div>
    <v-container>
      <v-row v-for="(item, index) in items" :key="index">
        <v-col cols="12" sm="6" md="6">
          <!-- 期間 -->
          <v-list-item>
            <v-list-item-content>
              <Label label="施工に必要な許可業種" :editable="editable">
                <Checkbox
                  name="cmn_mst_construction_permit_ids"
                  :values="item"
                  :items="convertConstructionPermit"
                  item_label="abbr"
                  :editable="editable"
                  @onInput="onInput({ value: $event, index })"
                />
              </Label>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col>
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col>
                  <Label
                    label="建設業許可番号種類"
                    :editable="editable"
                  >
                    <Select
                      name="cmn_mst_construction_permit_number_id"
                      :values="item"
                      :items="PERMITTED_INDUSTRY_REQUIRED_FOR_CONSTRUCTION"
                      item_text="type"
                      :editable="editable"
                      placeholder="東京都知事"
                      @onInput="onInput({ value: $event, index })"
                    />
                  </Label>
                  <Label
                    label="建設業許可番号種別"
                    :editable="editable"
                  >
                    <Select
                      name="construction_permit_type"
                      :values="item"
                      :items="FIELD_TREE_CONSTRUCTION_PERMITS"
                      :editable="editable"
                      placeholder="特定"
                      @onInput="onInput({ value: $event, index })"
                    />
                  </Label>
                </v-col>
                <v-col>
                  <Label
                    label="建設業許可番号振興局"
                    :editable="editable"
                    :required="item.cmn_mst_construction_permit_number_id === 2
                       ? !item.cmn_mst_construction_permit_number_do_id
                       : false"
                  >
                  <Label label="※北海道知事が選択された場合" v-if="!editable" />
                    <Select
                      name="cmn_mst_construction_permit_number_do_id"
                      :values="item"
                      :items="CONSTRUCTION_BUSINESS_PERMIT_NUMBER_PROMOTION_BUREAU"
                      :editable="editable"
                      placeholder="石狩振興局"
                      :id="`${index}建設業許可番号振興局`"
                      :validation_label="'建設業許可番号振興局'"
                      :validation_rules="getValidationRules(item)"
                      @onInput="onInput({ value: $event, index })"
                    />
                  <span v-if="editable">北海道知事が選択された場合</span>
                  </Label>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col>
                  <Label label="建設業許可番号年" :editable="editable">
                    <InputText
                      name="expired_year"
                      :values="item"
                      :editable="editable"
                      placeholder="29"
                      validation_label="建設業許可番号年"
                      validation_rules="digit|max:10"
                      @onInput="onInput({ value: $event, index })"
                    />
                  </Label>
                  <Label
                    label="許可（更新）年月日"
                    :editable="editable"
                  >
                    <InputDatepicker
                      name="renewal_date"
                      :values="item"
                      :editable="editable"
                      placeholder="2020/05/19"
                      :max="max"
                      :flagNull="true"
                      @onInput="onInput({ value: $event, index })"
                    />
                  </Label>
                </v-col>
                <v-col>
                  <Label label="建設業許可番号" :editable="editable">
                    <InputText
                      name="permit_number"
                      :values="item"
                      :editable="editable"
                      placeholder="建設業許可番号"
                      validation_label="建設業許可番号"
                      validation_rules="number-char-symbol|max:20"
                      @onInput="onInput({ value: $event, index })"
                    />
                  </Label>
                </v-col>
                <v-col cols="12" sm="2" md="2" class="pt-0 pb-0">
                  <v-btn v-if="index > 1 && editable" class="mt-8" icon @click="remove(index)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-container>
    <v-btn v-if="editable" color="primary" @click="addEmpty(false)">追加</v-btn>
  </div>
</template>
<script>
/**
 * Import
 */
import Label from "@/components/forms/elements/Label";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import Checkbox from "@/components/forms/elements/Checkbox";
import Select from "@/components/forms/elements/Select";
import InputText from "@/components/forms/elements/InputText";
import {
  FIELD_TREE_CONSTRUCTION_PERMITS
} from "@/constants/PORTAL_CHART.js";
import { Store } from "@/store/Store";

//初期値を設定します
const INITIAL_VALUES = {
  // 施工に必要な許可業種
  cmn_mst_construction_permit_ids: [],
  // 建設業許可番号種類
  cmn_mst_construction_permit_number_id: null,
  // 建設業許可番号振興局
  cmn_mst_construction_permit_number_do_id: null,
  // 建設業許可番号種別
  construction_permit_type: null,
  // 建設業許可番号年
  expired_year: "",
  // 建設業許可番号
  permit_number: "",
  // 許可（更新）年月日
  renewal_date: "",
  id: null,
};

export default {
  data: () => {
    return {
      items: [],
      PERMITTED_INDUSTRY_REQUIRED_FOR_CONSTRUCTION: [{ id: null, type: "" },...Store.state.CmnMst.constants.entries.cmn_mst_construction_permit_numbers],
      CONSTRUCTION_BUSINESS_PERMIT_NUMBER_PROMOTION_BUREAU: [{ id: null, name: "" },...Store.state.CmnMst.constants.entries.cmn_mst_construction_permit_number_do],
      FIELD_TREE_CONSTRUCTION_PERMITS,
      max: new Date().toISOString().slice(0, 10)
    };
  },
  components: {
    Label,
    InputText,
    InputDatepicker,
    Select,
    Checkbox,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue, oldValue) => {
        if (this.items?.length !== newValue[0][newValue[1]]?.length
          || JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.items = newValue[0][newValue[1]];
        }
        if(!this.items || this.items?.length === 0) {
          this.items = [];
          this.addEmpty(true);
        } else if(this.items?.length === 1) {
          this.addEmpty(false);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    convertConstructionPermit() {
      let masterData = Store.state.CmnMst.constants.entries.cmn_mst_construction_permit;
      return masterData.map(item => {
        return { id: item.id, name: item.abbr, value: item.id };
      });
    }
  },

  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      const itemEdited = {...this.items[index]};
      itemEdited[value.name] = value.value;
      const items = [...this.items];
      items[index] = itemEdited;
      this.$emit("onInput", { name: this.name, value: items });
    },
    remove(index) {
      const items = this.items.filter((item, i) => {
        return index != i;
      });
      this.$emit("onInput", { name: this.name, value: items });
    },
    addEmpty(flag) {
      const items = [...this.items];
      items.push({ ...INITIAL_VALUES });
      if(flag) items.push({ ...INITIAL_VALUES });
      this.items = items;
    },
    getValidationRules(item) {
      // do not required if construction_permit_number is not 北海道
      return (item?.cmn_mst_construction_permit_number_id === 2) ? "required" : "";
    },
  },
};
</script>
