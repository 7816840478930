import Vue from "vue";
import Vuex from "vuex";
import { workgroups } from "../../../api/modules/workgroups";

Vue.use(Vuex);

/**
 * 作業グループ一覧APIを管理するstore
 */

// api名を指定
const ENTITY = workgroups;

export const WorkGroups = {
  namespaced: true,

  state: {
    // 作業グループデータ
    data: [],
    // ペジネーション
    pagination: {},
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
  },

  actions: {
    // 作業グループ一覧データ取得
    async get({ commit }, payload) {
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;
      // stateにset
      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },

    async post({ state }) {
      const payload = state.data;
      return await ENTITY.post(payload);
    },

    async update(_context, payload) {
      return await ENTITY.put(payload);
    },

    async delete(_context, payload) {
      return await ENTITY.delete(payload);
    },
  },

  getters: {
    // state全体を返す
    getData: (state) => {
      return state.data;
    },
    // ペジネーションを返す
    getPagination: (state) => {
      return state.pagination;
    },
  },
};
