<template>
  <FormDialog>
    <template #header>
      <!--
        (共通)
        ヘッダーは全て共通
       -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title style="color: #1b9c4f">
          {{ formTitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!editable && isShowBtnUsageProhibited && !onlyView"
          class="mr-5"
          depressed
          small
          outlined
          color="warning"
          @click="handleUsageProhibited"
        >
          使用不可
        </v-btn>
        <v-btn
          v-if="!editable && isShowBtnEnable && !onlyView"
          class="mr-5"
          depressed
          small
          outlined
          color="primary"
          @click="handleEnable"
        >
          使用可にする
        </v-btn>
        <v-btn
          v-if="editable && !onlyView"
          class="mr-5"
          depressed
          small
          outlined
          color="primary"
          @click="isShowConfirmCloseDialog = true"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable && !onlyView"
          depressed
          small
          :disabled="!valid"
          color="primary"
          :key="flagSubmit"
          @click.once="onSubmit"
          data-testid="btn-submit"
        >
          保存
        </v-btn>
        <v-btn
          v-if="!editable && !isShowBtnEnable && !onlyView"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in GET_FORM_TABS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!--
        (共通)
        全てのフォームのバリデーションの監視を行う
       -->
      <ValidationObserver ref="observer" v-slot="observer">
        <!-- フォームtab -->
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <MachineFieldFormPage1
              :editable="editable"
              :item="editedItem"
              :errors="errors"
              :mainHeight="params.mainHeight"
              :initialIds="initialIds"
              :initialInspectionItems="initialInspectionItems"
              :backData="backData"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item v-if="!editable">
            <MachineFieldFormPage2
              :editable="editable"
              :item="editedItem"
              :mainHeight="params.mainHeight"
            />
          </v-tab-item>
          <v-tab-item v-if="!editable">
            <MachineFieldFormPage3
              :item="editedItem"
              :mainHeight="params.mainHeight"
              :titleDetail="titleDetail"
            />
          </v-tab-item>
          <v-tab-item v-if="editable">
            <MachineFieldFormPage4
              :mainHeight="params.mainHeight"
              :item="editedItem"
              :editable="editable"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item v-if="editable">
            <MachineFieldFormPage5
              :item="editedItem"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item v-if="!editable">
            <MachineFieldFormPage6
              :item="editedItem"
              :mainHeight="params.mainHeight"
            />
          </v-tab-item>
          <v-tab-item v-if="!editable">
            <MachineFieldFormPage7
              :item="editedItem"
              :mainHeight="params.mainHeight"
            />
          </v-tab-item>
        </v-tabs-items>
        <!--
          (共通)
          ValidationObserverのスロットプロパティを受け取って
          updateValidateメソッドを実行して、
          バリデーション結果をスクリプト側に渡してます
        -->
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmCloseDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>

      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmDialog
          :flagHandleConfirm="flagHandleConfirm"
          :dialogText="dialogText"
          @close="closeConfirmDialog"
          @yes="handleConfirmDialog"
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { Store } from "@/store/Store.js";
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import MachineFieldFormPage1 from "./MachineFieldFormPage1.vue";
import MachineFieldFormPage2 from "./MachineFieldFormPage2.vue";
import MachineFieldFormPage3 from "./MachineFieldFormPage3.vue";
import MachineFieldFormPage4 from "./MachineFieldFormPage4.vue";
import MachineFieldFormPage5 from "./MachineFieldFormPage5.vue";
import MachineFieldFormPage6 from "./MachineFieldFormPage6.vue";
import MachineFieldFormPage7 from "./MachineFieldFormPage7.vue";
import { MACHINE_FIELD_FORM_TABS_EDIT , MACHINE_FIELD_FORM_TABS_VIEW , DIALOG_CONFIRM_HANDLE } from "@/constants/MACHINE_FIELD";
import _ from "lodash";
import ConfirmDialog from "./components/ConfirmDialog.vue";
const STORE = "DeviceStocks";

export default {
  data: () => {
    return {
      DIALOG_CONFIRM_HANDLE,
      MACHINE_FIELD_FORM_TABS_EDIT,
      MACHINE_FIELD_FORM_TABS_VIEW,
      valid: false,
      tab: null,
      editable: false,
      isShowConfirmCloseDialog: false,
      isShowConfirmDialog:false,
      beforeUpdateItem: {},
      isClickCloseBtn: false,
      editedItem: {},
      errors: {},
      dialogText : {},
      flagSubmit : true,
      flagHandleConfirm : true,
      isShowBtnEnable : false,
      isShowBtnUsageProhibited : false,
      titleDetail: null,
      backData : false,
      initialIds : [],
      initialInspectionItems : {}
    };
  },
  components: {
    ConfirmDialog,
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    MachineFieldFormPage1,
    MachineFieldFormPage2,
    MachineFieldFormPage3,
    MachineFieldFormPage4,
    MachineFieldFormPage5,
    MachineFieldFormPage6,
    MachineFieldFormPage7,
  },
  props: {
    item: Object,
    isShowButtonEnable : Boolean,
    isShowButtonUsageProhibited : Boolean,
    onlyView: {
      type: Boolean,
      default: false
    }
  },
  computed : {
    GET_FORM_TABS(){
      return this.editable ? this.MACHINE_FIELD_FORM_TABS_EDIT : this.MACHINE_FIELD_FORM_TABS_VIEW;
    },
    formTitle(){
      if (this.beforeUpdateItem) {
        let title_array = [];
        if (this.beforeUpdateItem?.machine_companies?.machine_company_type_item_tree_last_name) {
          title_array.push(this.beforeUpdateItem?.machine_companies?.machine_company_type_item_tree_last_name);
        }
        if (this.beforeUpdateItem?.machine_companies?.machine_name) {
          title_array.push(this.beforeUpdateItem?.machine_companies.machine_name);
        }
        if (this.beforeUpdateItem?.control_name) {
          title_array.push(this.beforeUpdateItem?.control_name);
        }
        this.titleDetail = title_array.join("/");
        return title_array.join("/");
      }
    }
  },
  mounted() {
    this.$watch(
      () => Store.getters[`${STORE}/getMachineFieldDetail`],
      (data) => {
        this.editedItem = _.cloneDeep(data);
        this.beforeUpdateItem = _.cloneDeep(data);
        if(this.editedItem?.machine_company_field_type_item_tree_ids?.length > 0){
          this.initialIds = [...this.editedItem.machine_company_field_type_item_tree_ids];
          this.initialInspectionItems = {
            daily: [...this.editedItem.machine_company_field_inspection_items],
            monthly: [...this.editedItem.machine_company_field_monthly_inspection_items]
          };
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => this.isShowButtonEnable,
      (data) => {
        this.isShowBtnEnable = data;
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => this.isShowButtonUsageProhibited,
      (data) => {
        this.isShowBtnUsageProhibited = data;
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  methods: {

    formUpdate(params) {
      this.editedItem = { ...params };
    },

    onEditable() {
      this.tab = 0;// first tab in edit
      this.editable = true;
      this.backData = false;
    },

    async onSubmit() {
      let _itemSubmit = _.cloneDeep(this.editedItem);
      _itemSubmit = this.deleteParams(_itemSubmit);
      const rs = await Store.dispatch(
        `${STORE}/updateMachineInventories`,
        _itemSubmit
      );
      if (rs.hasError) {
        this.flagSubmit = !this.flagSubmit;
      } else {
        await Store.dispatch(`${STORE}/getMachineFieldDetail`, _itemSubmit.id);
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        this.editable = false;
        this.tab = 0;// first tab in edit
        this.$emit("updateListItems");
      }
    },

    deleteParams(item){
      let temp = { ...item };
      let type = [...temp.machine_company_field_type_item_tree_ids];
      if (type.length > 0) {
        temp.machine_company_field_type_item_tree_id = type[type.length - 1];
      } else {
        temp.machine_company_field_type_item_tree_id = null;
      }
      delete temp.machine_companies;
      delete temp.machine_company_field_type_item_tree_ids;
      delete temp.machine_company_licenses;
      delete temp.machine_company_sp_trainings;
      delete temp.machine_company_field_histories;
      delete temp.dataInspectionDaily;
      delete temp.dataInspectionMonthly;
      temp.machine_company_field_images = temp.machine_company_field_images.filter(image => image.image);
      temp.machine_company_field_inspection_items = this.customItemByInspecResult(temp.machine_company_field_inspection_items);
      temp.machine_company_field_monthly_inspection_items = this.customItemByInspecResult(temp.machine_company_field_monthly_inspection_items);
      delete temp?.machine_company_inspection_lists;
      delete temp?.machine_company_monthly_inspection_lists;
      return temp;
    },

    customItemByInspecResult(arr) {
      arr.forEach(element => {
        if (element.isAddNew) {
          delete element.isAddNew;
          delete element.machine_company_inspection_list_id;
        }
      });
      return arr;
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid, errors }) {
      this.valid = valid;
      this.errors = errors;
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    async closeForm() {
      if (this.editable && !this.isClickCloseBtn) {
        this.isShowConfirmCloseDialog = false;
        const item = _.cloneDeep(this.beforeUpdateItem);
        this.tab = 0; // move tab first view detail
        this.formUpdate(item);
        this.backData = true;
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },
    /**
     * (共通)
     * click button Close (X)
     */
    onClickBtnClose() {
      if (!this.editable) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmCloseDialog = true;
      }
    },
    onClickBtnCancelForm() {
      this.isShowConfirmCloseDialog = false;
      this.isClickCloseBtn = false;
    },
    closeConfirmDialog(){
      this.isShowConfirmDialog = false;
      this.dialogText = {};
    },
    async handleConfirmDialog(){
      let params = {
        machine_company_field_detail_id : this.editedItem.id,
        prohibit_type : this.isShowBtnEnable ? 1 : 0
      }
      const rs = await Store.dispatch(
        `${STORE}/updateMachineInventoryProhibitStatus`,
         params
      );
      if (rs.hasError) {
        this.flagHandleConfirm = !this.flagHandleConfirm;
      } else {
        this.dialogText = {};
        this.isShowBtnEnable = !this.isShowBtnEnable;
        this.isShowBtnUsageProhibited = !this.isShowBtnUsageProhibited;
        this.isShowConfirmDialog = false;
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        this.$emit("updateListItems");
      }
    },
    handleUsageProhibited() {
      this.isShowConfirmDialog = true;
      this.dialogText = {
        title: this.DIALOG_CONFIRM_HANDLE.TITLE_PROHIBIT,
        text: this.DIALOG_CONFIRM_HANDLE.TEXT_PROHIBIT,
        warning: true
      };
    },
    handleEnable() {
      this.isShowConfirmDialog = true;
      this.dialogText = {
        title: this.DIALOG_CONFIRM_HANDLE.TITLE_ENABLE,
        text: this.DIALOG_CONFIRM_HANDLE.TEXT_ENABLE,
        warning: false
      };
    },
  }

};
</script>
<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
