export const sortByDate = (a) => {
  return a.sort((prv, nxt) => {
    const prvDate = new Date(prv.created_at).getTime();
    const nxtDate = new Date(nxt.created_at).getTime();
    if (prvDate > nxtDate) {
      return -1;
    } else if (prvDate < nxtDate) {
      return 1;
    } else {
      return 0;
    }
  });
};
