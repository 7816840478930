var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"self",staticClass:"box"},[_c('v-card',{class:_vm.getClassBox,attrs:{"id":("box_" + (_vm.item.id))},on:{"click":function($event){return _vm.$emit('onClickBox', {
        companyId: _vm.item.company_id,
        companyName: _vm.item.company_name,
        chartId: _vm.item.id,
        state_handling_flag: _vm.item.state_handling_flag,
        item: _vm.item,
      })}}},[_c('header',{staticClass:"box-header"},[_c('div',[_c('p',{staticClass:"work_group_name"},[_vm._v(" "+_vm._s(_vm.item.field_tree_administrator_name || _vm.item.field_tree_group_name)+" ")]),(_vm.item.invite_status != 0)?_c('p',{staticClass:"company_name"},[_vm._v(_vm._s(_vm.convertCompanyName(_vm.item)))]):_vm._e(),(_vm.item.invite_status == 0)?_c('p',{staticClass:"company_name"},[_vm._v(_vm._s(_vm.item.invite_email))]):_vm._e()]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.checkFieldTreeDate),expression:"!checkFieldTreeDate"}],attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.menuItems),function(menu,index){return _c('v-list-item',{key:index,on:{"click":function($event){$event.preventDefault();return _vm.$emit('onClickDropdownMenu', {
                menu_id: menu.id,
                current_company: _vm.item,
              })}}},[_c('v-list-item-title',[_vm._v(_vm._s(menu.name))])],1)}),1)],1)],1),(_vm.item.invite_status == 0)?_c('div',{staticClass:"box-invite-0"},[_vm._v("招 待 中")]):_vm._e(),(_vm.item.invite_status != 0)?_c('div',{staticClass:"box-body"},[_c('div',[(!_vm.isOwner)?_c('p',{staticClass:"period"},[_c('span',[_vm._v(_vm._s(_vm.item.groundbreaking_date)+" "+_vm._s(_vm.item.groundbreaking_date && _vm.item.complete_schedule_date ? "～" : "")+" "+_vm._s(_vm.item.complete_schedule_date))])]):_vm._e(),(_vm.isOwner)?_c('div',{staticClass:"label"},[_vm._v("統括安全衛生責任者")]):_vm._e(),(_vm.isOwner)?_c('p',{staticClass:"sub-content"},[_vm._v(" "+_vm._s(_vm.item.chairman_name ? _vm.item.chairman_name : " ")+" ")]):_vm._e(),(_vm.isOwner)?_c('div',{staticClass:"label"},[_vm._v("監督員")]):_vm._e(),(_vm.isOwner)?_c('p',{staticClass:"sub-content"},[_vm._v(" "+_vm._s(_vm.item.supervisor_name ? _vm.item.supervisor_name : " ")+" ")]):_vm._e(),(!_vm.isOwner)?_c('div',{staticClass:"label"},[_vm._v("安全衛生責任者")]):_vm._e(),(!_vm.isOwner)?_c('p',{staticClass:"sub-content"},[_vm._v(" "+_vm._s(_vm.item.health_safety_manager_name ? _vm.item.health_safety_manager_name : " ")+" ")]):_vm._e(),(_vm.isOwner)?_c('div',{staticClass:"label"},[_vm._v("監理技術者・主任技術者")]):_vm._e(),(!_vm.isOwner)?_c('div',{staticClass:"label"},[_vm._v("主任技術者")]):_vm._e(),_c('p',{staticClass:"sub-content"},[_vm._v(" "+_vm._s(_vm.item.chief_engineer_name ? _vm.item.chief_engineer_name : " ")+" ")]),(_vm.isOwner)?_c('div',{staticClass:"label"},[_vm._v("専門技術者")]):_vm._e(),(_vm.isOwner)?_c('p',{staticClass:"sub-content"},[_vm._v(" "+_vm._s(_vm.item.technician_name ? _vm.item.technician_name : " ")+" ")]):_vm._e()]),_c('div',{staticClass:"members"},[_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.$emit('onClickMembers', {
              companyId: _vm.item.company_id,
              companyName: _vm.item.company_name,
              chartId: _vm.item.id,
              state_handling_flag: _vm.item.state_handling_flag,
              item: _vm.item,
            })}}},[_c('v-icon',[_vm._v("mdi-account")]),_c('p',[_vm._v(_vm._s(_vm.item.member_count))])],1)],1)]):_vm._e()]),_c('div',{staticClass:"line",style:(_vm.lineStyle)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }