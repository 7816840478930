import Vue from "vue";
import Vuex from "vuex";
import { scheduleRecords } from "../../../api/modules/scheduleRecords";
import { schedule } from "../../../api/modules/schedule";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = scheduleRecords;
const API_SCHEDULE = schedule;

export const ScheduleRecords = {
    namespaced: true,

    state: {
        dataAll: [],
        dataGroup: [],
        pagination: {},
        workPlace: [],
        workScheduleDetail: {},
        dayWorkers: {},
        dataHistory: [],
        listFieldTreeUserHistory:[],
        listManagerHistory : [],
        listStatusApproval: [],
        listSearchHistoryWorkResult: [],
    },

    mutations: {
        SET_DATA_ALL(state, payload) {
            state.dataAll = payload;
        },
        SET_DATA_GROUP(state, payload) {
            state.dataGroup = payload;
        },
        SET_PAGINATION(state, payload) {
            state.pagination = payload;
        },
        SET_CHILD_DATA(state, payload) {
            let parent = [];
            let children = [];
            let data = [];
            if (payload.isTabAll) {
                parent = state.dataAll.find(
                    (item) => item.work_group_result_id === payload.work_group_result_id
                );
                children = [...parent.children, ...payload.entries];
                data = state.dataAll.map((item) => {
                    if (item.work_group_result_id === payload.work_group_result_id) {
                        const _item = { ...item };
                        _item.children = children;
                        return _item;
                    }
                    return item;
                });
                state.dataAll = data;
            } else {
                parent = state.dataGroup.find(
                    (item) => item.work_group_result_id === payload.work_group_result_id
                );
                children = [...parent.children, ...payload.entries];
                data = state.dataGroup.map((item) => {
                    if (item.work_group_result_id === payload.work_group_result_id) {
                        const _item = { ...item };
                        _item.children = children;
                        return _item;
                    }
                    return item;
                });
                state.dataGroup = data;
            }
        },
        SET_WORK_SCHEDULE_DETAIL(state, payload) {
            state.workScheduleDetail = payload;
        },
        SET_WORK_PLACE(state, payload) {
            state.workPlace = payload;
        },
        SET_DAY_WORKERS(state, payload) {
            state.dayWorkers = payload;
        },
        SET_DATA_HISTORY(state, payload) {
            state.dataHistory = payload;
        },
        SET_LIST_FIELD_TREE_USER_HISTORY(state, payload) {
            state.listFieldTreeUserHistory = payload;
        },
        SET_LIST_MANAGER_HISTORY(state, payload) {
            state.listManagerHistory = payload;
        },
        SET_LIST_STATUS_APPROVAL(state, payload) {
            state.listStatusApproval = payload;
        },
        SET_LIST_SEARCH_HISTORY_WORK_RESULT(state, payload) {
        state.listSearchHistoryWorkResult = payload;
        }
    },

    actions: {
        //機材予定一覧
        async getListWorks({ commit }, payload) {
            const { searchParams, isTabAll } = payload;
            const response = await ENTITY.getListWorks(searchParams);
            if (response.hasError) {
                return response;
            }
            const { entries, pagination } = response.data.contents;
            if (isTabAll) {
                commit("SET_DATA_ALL", entries);
            } else {
                commit("SET_DATA_GROUP", entries);
            }
            commit("SET_PAGINATION", pagination);
            return response;
        },

        async copyWorkPlans(_context, payload) {
            const response = await ENTITY.copyWorkPlans(payload);
            return response;
        },

        async getDetail({ commit }, payload) {
            const response = await ENTITY.getDetail(payload);
            if (response.hasError) {
                return response;
            }
            const entries = response.data.contents.entries;
            commit("SET_WORK_SCHEDULE_DETAIL", entries);
            return response;
        },

        async getDetailObayashi({ commit }, payload) {
            const response = await ENTITY.getDetailObayashi(payload);
            if (response.hasError) {
                return response;
            }
            const entries = response.data.contents.entries;
            commit("SET_WORK_SCHEDULE_DETAIL", entries);
            return response;
        },

        async loadChildren({ commit }, payload) {
            const { searchParams, isTabAll } = payload;
            const response = await ENTITY.getScheduleChildren(searchParams);
            if (response.hasError) {
                return response;
            }
            const { entries } = response.data.contents;
            commit("SET_CHILD_DATA", {
                entries,
                work_group_result_id: searchParams.work_group_result_id,
                isTabAll
            });
            return response;
        },

        async loadChildrenScroll (context, payload) {
            const { searchParams } = payload;
            const response = await ENTITY.getScheduleChildren(searchParams);
            return response;
        },

        /**
         * 協力会社検索
         */
        async getPartnerCompanies({ dispatch }, { keyword }) {
            return dispatch("Schedule/getPartnerCompanies", { keyword });
        },

        //検索結果をクリア
        clearPartnerCompanies({ dispatch }) {
            dispatch("Schedule/clearPartnerCompanies", null);
        },

        /**
         * 作業用場所を検索
         */
        async getLocations({ dispatch }, { site_id }) {
            return dispatch("Schedule/getPartnerCompanies", { site_id });
        },

        //検索結果をクリア
        clearLocations({ dispatch }) {
            dispatch("Schedule/clearLocations", null);
        },

        async getTimeframes({ dispatch }, { site_id }) {
            return dispatch("Schedule/getTimeframes", { site_id });
        },

        // 承認/承認解除
        async updateApproveWorks(_context, payload) {
            const response = await ENTITY.updateApproveWorks(payload);
            return response;
        },

        // 解除
        async deleteWorks(_context, payload) {
            return await ENTITY.deleteWorks(payload);
        },
        async getWorkPlace({ commit }, payload) {
            const response = await ENTITY.getWorkPlace(payload);
            if (response.hasError) {
                return response;
            }
            const entries = response.data.contents.entries;
            commit("SET_WORK_PLACE", entries);
            return response;
        },
        async getPersonInCharge({ commit }, payload) {
            const response = await ENTITY.getPersonInCharge(payload);
            if (response.hasError) {
                return response;
            }
            const entries = response.data.contents.entries;
            commit("SET_PERSON_IN_CHARGE", entries);
            return response;
        },
        async post(_context, payload) {
            return await scheduleRecords.post(payload);
        },
        async update(_context, payload) {
            return await scheduleRecords.update(payload);
        },
        async getWorkLastTime(_context, payload) {
            return await ENTITY.getWorkLastTime(payload);
        },
        async updateStateHandling(_context, payload) {
            return await ENTITY.updateStateHandling(payload);
        },
        async getDayWorkerInfo({ commit }, payload) {
            const response = await ENTITY.getDayWorkerInfo(payload);
            if (response.hasError) {
                return response;
            }
            const entries = response.data.contents.entries;
            commit("SET_DAY_WORKERS", entries);
            return response;
        },
        async getWorkDetailCompanyResult(_context, payload) {
            return await ENTITY.getWorkDetailCompanyResult(payload);
        },
        async getDataHistory({ commit }, payload) {
            const response = await ENTITY.getDataHistory(payload);
            const entries = response.data.contents.entries;
            commit("SET_DATA_HISTORY", entries);
            return response;
        },
        async getListFieldTreeUserHistory({ commit }, payload) {
            const response = await ENTITY.getListFieldTreeUserHistory(payload);
            if(response.hasError){
                return response;
            }
            const { entries } = response.data.contents;
            commit("SET_LIST_FIELD_TREE_USER_HISTORY", entries);
            return response;
        },

        async getListManagerHistory({ commit }, payload) {
            const response = await ENTITY.getListManagerHistory(payload);
            if(response.hasError){
                return response;
            }
            const { entries } = response.data.contents;
            commit("SET_LIST_MANAGER_HISTORY", entries);
            return response;
        },

        async getListStatusApproval({ commit }, payload) {
            const response = await ENTITY.getListStatusApproval(payload);
            if(response.hasError){
              return response;
            }
            const { entries } = response.data.contents;
            commit("SET_LIST_STATUS_APPROVAL", entries);
            return response;
          },
        async getListSearchHistoryWorkResult({ commit }, payload) {
            const response = await API_SCHEDULE.getListSearchHistoryWorkResult(payload);
            commit("SET_LIST_SEARCH_HISTORY_WORK_RESULT", response.data.contents.entries.list_history)
            return response;
        },
        async createSearchHistoryWorkResult(_context, payload) {
            const response = await API_SCHEDULE.createSearchHistoryWorkResult(payload);
            return response;
        },
        async deleteSearchHistoryWorkResult(_context, payload) {
            const response = await API_SCHEDULE.deleteSearchHistoryWorkResult(payload);
            return response;
        }
    },

    getters: {
        getDataAll: (state) => {
            return state.dataAll;
        },
        getDataGroup: (state) => {
            return state.dataGroup;
        },
        getPagination: (state) => {
            return state.pagination;
        },
        getPartnerCompanies: (getters) => {
            return getters.schedule.partnerCompanies;
        },
        getLocations: (getters) => {
            return getters.schedule.locations;
        },
        getTimeframes: (getters) => {
            return getters.schedule.timeframes;
        },
        getWorkScheduleDetail: (state) => {
            return state.workScheduleDetail;
        },
        getWorkPlace: (state) => {
            return state.workPlace;
        },
        getDayWorkerInfo: (state) => {
            return state.dayWorkers;
        },
        getDataHistory: (state) => {
            return state.dataHistory;
        },
        getListFieldTreeUserHistory: (state) => {
            return state.listFieldTreeUserHistory;
        },
        getListManagerHistory: (state) => {
            return state.listManagerHistory;
        },
        getListStatusApproval: (state) => {
            return state.listStatusApproval;
        },
        getListSearchHistoryWorkResult: (state) => {
          return state.listSearchHistoryWorkResult;
        }
    },
};
