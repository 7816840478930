<template>
  <div>
    <div class="sticky">
      <v-row no-gutters>
        <v-col cols="9" sm="10" class="pa-3">
          <!-- 検索ワード -->
          <SearchInputText
            name="keyword"
            :values="formValues"
            :placeholder="placeHolder"
            validation_label="検索ワード"
            :validation_rules="rule"
            :editable="true"
            :clearable="true"
            prependInnerIcon="mdi-magnify"
            @onInput="onInput"
            @keyEnter="$emit('search')"
          />
        </v-col>
        <v-col cols="3" sm="2" class="pt-3 pr-2">
          <!-- 検索ボタン -->
          <v-btn @click="$emit('search')" color="primary">検索</v-btn>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>

    <!-- リスト -->
    <slot></slot>
  </div>
</template>

<script>
import SearchInputText from "@/components/forms/elements/SearchInputText.vue";

export default {
  data() {
    return {
    formValues: {},
      values: {
        // 表示するリスト
        items: [],
      },
    };
  },

  components: {
    SearchInputText,
  },

  props: {
    validateRule: String,
    placeHolder:{
      type : String,
      default : '作業詳細'
    }
  },

  computed: {
    rule() {
      return this.validateRule ? "max:255|" + this.validateRule : "max:255";
    },
  },

  methods: {
    onInput({ name, value }) {
      this.values[name] = value;
      this.$emit("formUpdate", this.values);
    },
  },
};
</script>
<style lang="scss" scoped>
.sticky {
  position: sticky;
  top: 65px;
  z-index: 1;
  background-color: white;
}
</style>
