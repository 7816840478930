import Api, { Mock } from "../api";
import entrance_new_worker_list from "./mock/entrance_new_worker_list.json";
import entrance_new_worker_children from "./mock/entrance_new_worker_children.json";

const SCHEDULE_WORK_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/fields`;
const GET_LIST_URL = `${SCHEDULE_WORK_BASE_URL}/get_list_new_arrival`;
const GET_CHILDREN_URL = `${SCHEDULE_WORK_BASE_URL}/get_list_new_arrival_by_field_tree`;

if (Mock) {
    Mock.onGet(GET_LIST_URL).reply(200, {
        status_code: 200,
        message: "",
        result: true,
        contents: entrance_new_worker_list,
    });

    Mock.onGet(GET_CHILDREN_URL).reply(200, {
        status_code: 200,
        message: "",
        result: true,
        contents: entrance_new_worker_children,
    });
}

export const entranceNewWorker = {
    getListNewArrival: (params) => {
        return Api.get(GET_LIST_URL, params );
    },
    loadChildren: (params) => {
        return Api.get(GET_CHILDREN_URL,  params );
    },
};
