import Api, { Mock } from "../api";
import rooms from "./mock/textchat/rooms.json";

// レファレンス
// https://shogatsu.backlog.com/git/GRN/green_web/blob/development/core/laravel_api/resources/docs/groups/chatroom.md

// ルーム一覧
const URL_ROOMS = `${process.env.VUE_APP_API_BASE_URL}/chat/room_list`;

// ルーム更新
const URL_ROOM_CREATE = `${process.env.VUE_APP_API_BASE_URL}/chat/room_create`;

const URL_ROOM_CREATE_FROM_FIELD_IMAGE_ID = `${process.env.VUE_APP_API_BASE_URL}/chat/room_create`;

// ルーム更新
const URL_ROOM_UPDATE = `${process.env.VUE_APP_API_BASE_URL}/chat/update`;

// ルーム削除
const URL_ROOM_DELETE = `${process.env.VUE_APP_API_BASE_URL}/chat/room_delete`;

// ユーザー更新
const URL_ROOM_USER_UPDATE = `${process.env.VUE_APP_API_BASE_URL}/chat/room_attach_user`;

// ユーザー権限
// https://shogatsu777.warasubo.jp/v1/api/users/is_field_director_or_foreman/?field_id=204
const URL_USER_ROLL = `${process.env.VUE_APP_API_BASE_URL}/users/is_field_director_or_foreman`;

// VUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  Mock.onGet(URL_ROOMS).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: rooms,
  });
  Mock.onPost(URL_ROOM_CREATE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(URL_ROOM_UPDATE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(URL_ROOM_DELETE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(URL_ROOM_USER_UPDATE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
}

export const textChatManage = {
  rooms: (params) => {
    console.log("■", URL_ROOMS, params);
    return Api.get(URL_ROOMS, { params });
  },
  roomCreate: (params) => {
    console.log("■ create", URL_ROOM_CREATE, params);
    return Api.post(URL_ROOM_CREATE, params);
  },
  roomCreateFromFieldImageId: (params) => {
    console.log("■ create", URL_ROOM_CREATE_FROM_FIELD_IMAGE_ID, params);
    return Api.post(URL_ROOM_CREATE_FROM_FIELD_IMAGE_ID, params);
  },
  roomUpdate: (params) => {
    console.log("■ update", URL_ROOM_UPDATE, params);
    return Api.post(URL_ROOM_UPDATE, params);
  },
  roomDelete: (params) => {
    console.log("■ delete", URL_ROOM_DELETE, params);
    return Api.post(URL_ROOM_DELETE, params);
  },
  usersUpdate: (params) => {
    console.log("■ users update", URL_ROOM_USER_UPDATE, params);
    return Api.post(URL_ROOM_USER_UPDATE, params);
  },
  userRoll: (params) => {
    return Api.get(URL_USER_ROLL, { params });
  },
};
