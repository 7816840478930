<template>
  <div style="overflow: auto">
    <DefaultLayout>
      <template #mainHeader>
        <!--  現場ポータルヘッダー -->
        <SitePortalHeader :flagUserRentalCompany="flagUserRentalCompany" />
      </template>
      <template #page>
        <v-layout class="mx-3 my-7" v-if="haveSafetyDeclaration">
          <v-flex md12 xs12 class="mx-5 mt-5">
            <div class="slogan">
              {{ items.field.safety_declaration }}
            </div>
          </v-flex>
        </v-layout>
        <v-layout class="mx-3 my-7">
          <v-flex md4 xs12 class="mx-5">
            <v-card-title></v-card-title>
            <ItemWrapper class="rounded-lg">
              <v-row class="px-4 mb-3">
                <v-col>
                  <v-list-item class="px-0 py-0">
                    <v-list-item-content class="px-0 pt-0 pb-0">
                      <h2
                        v-text="companies.name"
                        class="company-name mb-1"
                      ></h2>
                      <v-list-item-subtitle
                        v-text="companies.address"
                        class="mt-2"
                        color="grey"
                      ></v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-text="`着工- 竣工 ${companies.term || ''}`"
                        class="mt-2"
                        color="grey"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="mt-n5 mr-3">
                      <v-btn
                        icon
                        fab
                        small
                        dark
                        depressed
                        class="green accent-2"
                        @click="openFieldForm(field_id)"
                      >
                        <v-icon>mdi-cog-outline</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-col>
              </v-row>
            </ItemWrapper>
            <Weather :flagUserRentalCompany="flagUserRentalCompany" />

            <!-- 最大風速予測 -->
            <v-item-group class="mb-4" v-if="!ENV_OBAYASHI">
              <v-list>
                <v-list-item class="header_maximum_win_speed">
                  <h2 class="font-weight-bold">最大風速予測</h2>
                  <v-spacer></v-spacer>
                  <div>
                    <div style="display: flex; align-items: center">
                      <span> 高度選択 </span>
                      <span class="ml-3 mt-4">
                        <v-select
                          :items="MAXIMUM_WIN_SPEED_ITEMS"
                          label="30m"
                          dense
                          solo
                        ></v-select>
                      </span>
                    </div>
                    <span class="font-weight-bold reload_maximum_win_speed">
                      <span>更新時間</span>
                      <span class="ml-1">{{ timeNow }}</span>
                      <v-btn icon :disabled="flagUserRentalCompany"
                        ><v-icon>mdi-cached</v-icon></v-btn
                      >
                    </span>
                  </div>
                </v-list-item>
              </v-list>
              <ItemWrapper class="rounded-lg mb-3" style="max-width: 100%">
                <MaximumWindSpeed :items="DATA_MAXIMUM_WIN_SPEED" />
              </ItemWrapper>
            </v-item-group>

            <!-- 暑さ指数（WBGT） -->
            <TableUpdateLabel
              label="暑さ指数（WBGT)"
              :time="timeNow"
              :flagUserRentalCompany="flagUserRentalCompany"
              v-if="!ENV_OBAYASHI"
            >
              <ItemWrapper class="rounded-lg mb-3 height_item_wrapper">
                <HeatIndex />
              </ItemWrapper>
            </TableUpdateLabel>
          </v-flex>
          <v-flex md4 xs12 class="mx-5">
            <!-- サマリー -->
            <div class="portal-summary">
              <div class="portal-summay__row">
                <router-link
                  class="portal-summary__link"
                  :to="urlScheduleGate"
                >
                  <div class="portal-summary__title">
                    <div class="portal-summary__label">ゲート予定</div>
                    <div class="portal-summary__arrow">
                      <v-btn small icon v-if="!flagUserRentalCompany"
                        ><v-icon color="primary"
                          >mdi-chevron-right</v-icon
                        ></v-btn
                      >
                    </div>
                  </div>
                  <div class="portal-summary__content">
                    <div class="portal-summary__content-title">今日/明日</div>
                    <div class="d-flex">
                      <div class="portal-summary__value">
                        {{ getGatePassageNumberTodayPerTomorrow() }}
                      </div>
                      <div class="portal-summary__unit">件</div>
                    </div>
                  </div>
                </router-link>
                <div>
                  <div class="portal-summary__divider"></div>
                </div>
                <router-link
                  class="portal-summary__link"
                  :to="urlScheduleCrane"
                >
                  <div class="portal-summary__title">
                    <div class="portal-summary__label">揚重予定</div>
                    <div class="portal-summary__arrow">
                      <v-btn small icon v-if="!flagUserRentalCompany"
                        ><v-icon color="primary"
                          >mdi-chevron-right</v-icon
                        ></v-btn
                      >
                    </div>
                  </div>
                  <div class="portal-summary__content">
                    <div class="portal-summary__content-title">今日/明日</div>
                    <div class="d-flex">
                      <div class="portal-summary__value">
                        {{ getCraneUseNumberTodayPerTomorrow() }}
                      </div>
                      <div class="portal-summary__unit">件</div>
                    </div>
                  </div>
                </router-link>
              </div>
              <v-divider></v-divider>
              <div class="portal-summay__row"  v-if="!ENV_OBAYASHI">
                <router-link
                  class="portal-summary__link"
                  :to="flagUserRentalCompany ? '' : '/schedule/machines'"
                >
                  <div class="portal-summary__title">
                    <div class="portal-summary__label">機材予定</div>
                    <div class="portal-summary__arrow">
                      <v-btn small icon v-if="!flagUserRentalCompany"
                        ><v-icon color="primary"
                          >mdi-chevron-right</v-icon
                        ></v-btn
                      >
                    </div>
                  </div>
                  <div class="portal-summary__content">
                    <div class="portal-summary__content-title">今日/明日</div>
                    <div class="d-flex">
                      <div class="portal-summary__value">
                        {{ getMachineUseNumberTodayPerTomorrow() }}
                      </div>
                      <div class="portal-summary__unit">件</div>
                    </div>
                  </div>
                </router-link>
                <div>
                  <div class="portal-summary__divider"></div>
                </div>
                <router-link
                  class="portal-summary__link"
                  :to="flagUserRentalCompany ? '' : '/entrance/new_worker'"
                >
                  <div class="portal-summary__title">
                    <div class="portal-summary__label">新規入場者</div>
                    <div class="portal-summary__arrow">
                      <v-btn small icon v-if="!flagUserRentalCompany"
                        ><v-icon color="primary"
                          >mdi-chevron-right</v-icon
                        ></v-btn
                      >
                    </div>
                  </div>
                  <div class="portal-summary__content">
                    <div class="portal-summary__content-title">３日後まで</div>
                    <div class="d-flex">
                      <div class="portal-summary__value">
                        {{ items.next_week_worker_number || 0 }}
                      </div>
                      <div class="portal-summary__unit">人</div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
            <!-- 全人工 -->
            <TableLabel
              @open="goPage"
              :flagUserRentalCompany="flagUserRentalCompany"
              label="全人工"
              path="/schedule/records"
            >
              <ScheduleChart :dataScheduleChart="items.work_dayworker" />
            </TableLabel>
            <!-- 作業場所 -->
            <TableLabel label="作業場所">
              <ItemWrapper class="rounded-lg mb-3 height_item_wrapper">
                <WorkPlace
                  :items="WORK_RESULTS"
                  :isNoDataWorkPlace="isNoDataWorkPlace"
                />
              </ItemWrapper>
            </TableLabel>
            <!-- summary user FIELD_ENTRANCE_HISTORY_DAILYS -->
            <TableUpdateLabel
              :time="FIELD_ENTRANCE_HISTORY_DAILYS.last_update"
              @updateTable="getUserEnteringField"
              :loading="userEnteringLoading"
              :flagUserRentalCompany="flagUserRentalCompany"
              v-if="!ENV_OBAYASHI"
            >
              <div class="portal-summary">
                <div class="portal-summay__row">
                  <router-link
                    class="portal-summary__link"
                    :to="flagUserRentalCompany ? '' : '/entrance/history'"
                  >
                    <div class="portal-summary__content">
                      <div class="portal-summary__label">
                        今日の入場者数（累計）
                      </div>
                      <div class="d-flex" style="justify-content: center">
                        <div
                          class="portal-summary__value"
                          style="flex: initial"
                        >
                          {{
                            FIELD_ENTRANCE_HISTORY_DAILYS.total_user_entering_field
                          }}
                        </div>
                        <div class="portal-summary__unit ml-3">人</div>
                      </div>
                    </div>
                  </router-link>
                  <div>
                    <div class="portal-summary__divider"></div>
                  </div>
                  <div
                    class="portal-summary__link"
                    @click="gotoEntranceHistory()"
                    style="cursor: pointer"
                  >
                    <div class="portal-summary__content">
                      <div class="portal-summary__label">残留者数</div>
                      <div class="d-flex" style="justify-content: center">
                        <div
                          class="portal-summary__value"
                          style="flex: initial"
                        >
                          {{
                            FIELD_ENTRANCE_HISTORY_DAILYS.total_user_in_field
                          }}
                        </div>
                        <div class="portal-summary__unit ml-3">人</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TableUpdateLabel>
            <!-- 配置図 -->
            <TableLabel @open="goPage" label="現場配置計画" v-if="!ENV_OBAYASHI">
              <v-card>
                <v-img height="252" src="/images/noimage.png"></v-img>
              </v-card>
            </TableLabel>
          </v-flex>
          <v-flex md4 xs12 class="mx-5">
            <TableLabel
              @open="goPage"
              label="巡回記録・各種連絡"
              path="/schedule/patrols"
              :flagUserRentalCompany="flagUserRentalCompany"
            >
              <ItemWrapper class="rounded-lg mb-3">
                <template>
                  <div 
                    class="px-4 py-2 subtitle-1 font-weight-bold">
                    指摘
                  </div>
                  <v-data-table
                    item-key="safety_diary_comment_correction_id"
                    :headers="RECORDS_LABELS"
                    :items="getDataTable(SAFETY_DIARIES.FORM_SHITEKI)"
                    hide-default-header
                    hide-default-footer
                    class="border-bottom"
                    :class="{ scrollTable: ENV_OBAYASHI }"
                    :noDataText="NO_DATA_MESSAGE"
                    @click:row="openItemForm"
                    :disable-pagination="ENV_OBAYASHI"
                  >
                    <template v-slot:[`item.location`]="{ item }">
                      <h4 class="font-weight-light">
                        <div class="h4 location ml-1">{{ item.location }}</div>
                        <div :class="{ itemRecord: ENV_OBAYASHI }">
                          <v-chip
                            class="ma-1 pa-2"
                            small
                            dark
                            :color="URGENCY_COLORS[item.urgency]['color']"
                            >{{ URGENCY_COLORS[item.urgency]["value"] }}</v-chip
                          >
                          <span v-if="ENV_OBAYASHI" class="record">{{item.created_at}} {{ item.pointing_out_detail }}</span>
                          <span v-if="!ENV_OBAYASHI" >{{ item.pointing_out_detail }}</span>
                        </div>
                      </h4>
                    </template>
                  </v-data-table>
                  <div class="px-4 py-2 subtitle-1 font-weight-bold">
                    共通連絡
                  </div>
                  <v-data-table
                    item-key="safety_diary_comment_correction_id"
                    :headers="RECORDS_LABELS"
                    :items="getDataTable(SAFETY_DIARIES.FORM_CONTACT_TYPE_2)"
                    hide-default-header
                    hide-default-footer
                    class="border-bottom"
                    :class="{ scrollTable: ENV_OBAYASHI }"
                    :noDataText="NO_DATA_MESSAGE"
                    @click:row="openItemForm"
                    :disable-pagination="ENV_OBAYASHI"
                  >
                    <template v-slot:[`item.location`]="{ item }">
                       <h4 class="font-weight-light">
                        <div class="h4 location ml-1">{{ item.location }}</div>
                        <div :class="{ itemRecord: ENV_OBAYASHI }">
                          <v-chip
                            class="ma-1 pa-2"
                            small
                            dark
                            :color="URGENCY_COLORS[item.urgency]['color']"
                            >{{ URGENCY_COLORS[item.urgency]["value"] }}</v-chip
                          >
                          <span v-if="ENV_OBAYASHI" class="record">{{item.created_at}} {{ item.pointing_out_detail }}</span>
                          <span v-if="!ENV_OBAYASHI" >{{ item.pointing_out_detail }}</span>
                        </div>
                      </h4>
                    </template>
                  </v-data-table>
                  <div class="px-4 py-2 subtitle-1 font-weight-bold">
                    安全巡視
                  </div>
                  <v-data-table
                    item-key="safety_diary_comment_correction_id"
                    :headers="RECORDS_LABELS"
                    :items="getDataTable(SAFETY_DIARIES.FORM_CONTACT_TYPE_3)"
                    hide-default-header
                    hide-default-footer
                    class="border-bottom"
                    :class="{ scrollTable: ENV_OBAYASHI }"
                    :noDataText="NO_DATA_MESSAGE"
                    @click:row="openItemForm"
                    :disable-pagination="ENV_OBAYASHI"
                  >
                    <template v-slot:[`item.location`]="{ item }">
                      <h4 class="font-weight-light">
                        <div class="h4 location ml-1">{{ item.location }}</div>
                        <div :class="{ itemRecord: ENV_OBAYASHI }">
                          <v-chip
                            class="ma-1 pa-2"
                            small
                            dark
                            :color="URGENCY_COLORS[item.urgency]['color']"
                            >{{ URGENCY_COLORS[item.urgency]["value"] }}</v-chip
                          >
                          <span v-if="ENV_OBAYASHI" class="record">{{item.created_at}} {{ item.pointing_out_detail }}</span>
                          <span v-if="!ENV_OBAYASHI" >{{ item.pointing_out_detail }}</span>
                        </div>
                      </h4>
                    </template>
                  </v-data-table>
                </template>
              </ItemWrapper>
            </TableLabel>
            <!-- 早残予定 -->
            <v-item-group class="mb-4" >
              <v-list>
                <v-list-item justify="space-around">
                  <h2 class="font-weight-bold">早残予定</h2>
                  <v-spacer></v-spacer>
                  <span class="font-weight-bold"
                    >{{ EARLY_OVER_TIMES.total || 0 }}件</span
                  >
                </v-list-item>
              </v-list>
              <ItemWrapper class="rounded-lg mb-3 height_item_wrapper">
                <EarlyOverTime 
                  :items="EARLY_OVER_TIMES.childrens || {} "
                  :isNoDataMessage="isNoDataMessage"
                />
              </ItemWrapper>
            </v-item-group>
            <!-- 職種別入場者 -->
            <TableUpdateLabel
              label="職種別入場者"
              :time="FIELD_ENTRANCE_HISTORY_DAILYS.last_update"
              @updateTable="getUserEnteringField"
              :loading="userEnteringLoading"
              :flagUserRentalCompany="flagUserRentalCompany"
              v-if="!ENV_OBAYASHI"
            >
              <ItemWrapper class="rounded-lg mb-3 height_item_wrapper">
                <VisitorsJobType
                  :items="FIELD_ENTRANCE_HISTORY_DAILYS.jobs || []"
                  :isNoDataJobType="isNoDataJobType"
                />
              </ItemWrapper>
            </TableUpdateLabel>
            <v-item-group class="mb-4" v-if="!ENV_OBAYASHI">
              <v-list>
                <v-list-item justify="space-around">
                  <h2 class="font-weight-bold">火気使用中</h2>
                  <v-spacer></v-spacer>
                  <span class="font-weight-bold"
                    >{{ WORK_HOT_RESULT.total }}件</span
                  >
                </v-list-item>
              </v-list>
              <ItemWrapper class="rounded-lg mb-3 height_item_wrapper">
                <FireUse
                  :items="WORK_HOT_RESULT.childrens || []"
                  :isNoDataFireUse="isNoDataFireUse"
                />
              </ItemWrapper>
            </v-item-group>
          </v-flex>
        </v-layout>
      </template>
    </DefaultLayout>
    <Popup :dialog="isShowItemForm">
      <SiteForm
        :flag="false"
        :item="editedItem"
        :isNewItem="false"
        :isErrorSubmit="isErrorSubmit"
        :isEmitted="isEmitted"
        :flagUserRentalCompany="flagUserRentalCompany"
        :flagSupervisor="flagSupervisor"
        :flagErrorUser="flagErrorUser"
        :flagEditSuccess="flagEditSuccess"
        @updateFlag="updateFlag"
        @formUpdate="formUpdate"
        @submit="submitForm(editedItem, true)"
        @cancel="closeItemForm"
        @updateListRight="updateListRight"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowPaltrolForm">
      <PaltrolForm
        :construction_id="field_construction_id"
        :isNewItem="isNewItem.isNewItemPaltrols"
        :recordId="patrol_record_id"
        :typeForm="typeFormPaltrol"
        :childId="childId"
        @actionSuccess="actionSuccess"
        @closeFormPalTrol="closeFormPalTrol"
        @updateRecordId="updateRecordId"
        :isShowMove="false"
        :disabledDoubleNext="true"
        :disabledDoublePrev="true"
      />
    </Popup>
  </div>
</template>
<script>
/**
 * (共通)
 * ポータル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import { fmtSlashDate } from "@/utils/timeUtil";

/**
 * 現場ポータルのみで使用するコンポーネント、関数
 */
import ItemWrapper from "@/components/portal/elements/ItemWrapper";
import Table from "./components/Table";
import TableLabel from "./components/TableLabel";
import TableUpdateLabel from "./components/TableUpdateLabel";
import { HEADER_MENU_ITEMS_SITE } from "@/constants/GLOBALHEADER";
import ScheduleChart from "./components/ScheduleChart.vue";
import {
  NO_DATA_MESSAGE,
  ROLE_SUPERVISOR,
  ROLE_USER_RENTAL_COMPANY,
  FILELD_APPROVALS_TYPE,
} from "@/constants/COMMON";
import Weather from "./components/Weather";
import EarlyOverTime from "./components/EarlyOverTime";
import HeatIndex from "./components/HeatIndex";
import MaximumWindSpeed from "./components/MaximumWindSpeed";
import VisitorsJobType from "./components/VisitorsJobType";
import FireUse from "./components/FireUse";
import WorkPlace from "./components/WorkPlace";
import Select from "@/components/forms/elements/Select";
import _ from "lodash";
import { ENTERING_STATUS } from "@/constants/ENTRANCE_HISTORY";
import SiteForm from "@/components/forms/sites/SiteForm";
import Popup from "@/components/common/Popup";
import { PASSWORD_DEFAULT } from "@/constants/COMMON";
import { FACE_RECOGNITION_FLAG } from "@/constants/REGISTER_FACE";
import { ENV_CLIENT } from "@/constants/ENV_CLIENT";
import { FILELD_APPROVALS } from "@/constants/SITES";
import PaltrolForm from "@/components/forms/schedule/patrols/PaltrolForm/index.vue"
import {
  PATROLS_TABLE_LABELS,
  PATROL_SORT_ITEMS,
  DIALOG_TEXT,
  DIALOG_REMOVE_TEXT,
  URGENCY_CLASSIFICATION_SELECT,
  STATUS_CODE,
  MENU_FORM_PATROL,
  STATUS_CODE_ARRAY,
  STATUS_OBJECT,
  MODE_ACTION,
  PATROL_TYPE_ALL
} from "@/constants/PATROL_RECORD"; //絞り込みフォームで使用
//ストア
const STORE = "SitePortal";

const URGENCY_COLORS = {
  0: { color: "#29ABE2", value: "低" },
  1: { color: "#1B9C4F", value: "中" },
  2: { color: "#FF7B52", value: "高" },
  3: { color: "#B00020", value: "急" },
};

const SAFETY_DIARIES = {
  FORM_SHITEKI: 1, //指摘
  FORM_CONTACT_TYPE_2: 2, // 共通連絡
  FORM_CONTACT_TYPE_3: 3 // 安全巡視
};

const RECORDS_LABELS = [{ text: "タイトル", value: "location" }];

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: "掲示板" };
    },
  },
  data() {
    return {
      RECORDS_LABELS,
      NO_DATA_MESSAGE,
      RECORDS_ITEMS: [],
      SAFETY_DIARIES,
      URGENCY_COLORS,
      items: {},
      companies: {},
      cmn_mst_prefectures: [],
      userEnteringLoading: false,
      WORK_RESULTS: [],
      WORK_HOT_RESULT: {},
      EARLY_OVER_TIMES:{},
      FIELD_ENTRANCE_HISTORY_DAILYS: {},
      field_id: null,
      ENTERING_STATUS,
      isShowItemForm: false,
      editedItem: {},
      isErrorSubmit: false,
      isEmitted: false,
      //todo
      MAXIMUM_WIN_SPEED_ITEMS: ["30m", "40m"],
      //todo
      DATA_MAXIMUM_WIN_SPEED: [
        { hour: "10時", icon: "2", temp: "6", rain: "12" },
        { hour: "11時", icon: "3", temp: "5", rain: "14" },
        { hour: "12時", icon: "2", temp: "6", rain: "16" },
        { hour: "13時", icon: "3", temp: "5", rain: "18" },
        { hour: "14時", icon: "2", temp: "6", rain: "20" },
        { hour: "15時", icon: "3", temp: "5", rain: "22" },
      ],
      timeNow: null,
      flagSupervisor: false,
      flagUserRentalCompany: false,
      ROLE_SUPERVISOR,
      ROLE_USER_RENTAL_COMPANY,
      flagErrorUser: false,
      flagEditSuccess : false,
      isNoDataMessage: false,
      isNoDataFireUse: false,
      isNoDataJobType: false,
      isNoDataWorkPlace: false,
      FILELD_APPROVALS_TYPE,
      FILELD_APPROVALS,
      popups: {
        isShowPaltrolForm: false
      },
      typeFormPaltrol: null,
      isNewItem : {
        isNewItemPaltrols: false,
      },
      patrol_record_id: null,
      field_construction_id: null,
      childId: null
    };
  },
  computed: {
    haveSafetyDeclaration() {
      return this.items?.field?.safety_declaration;
    },
    ENV_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
    urlScheduleCrane(){
      if(this.flagUserRentalCompany) return '';
      if(this.ENV_OBAYASHI) return'/schedule/usage';
      else return '/schedule/cranes';
    },
    urlScheduleGate(){
      if(this.flagUserRentalCompany) return '';
      if(this.ENV_OBAYASHI) return'/schedule/usage';
      else return '/schedule/gates';
    },
  },
  components: {
    //共通のコンポーネント
    DefaultLayout,
    SitePortalHeader,
    ItemWrapper,
    TableLabel,
    // 現場ポータル用のコンポーネント
    Table,
    ScheduleChart,
    TableUpdateLabel,
    Weather,
    EarlyOverTime,
    Select,
    MaximumWindSpeed,
    HeatIndex,
    VisitorsJobType,
    FireUse,
    WorkPlace,
    SiteForm,
    Popup,
    PaltrolForm,
    PATROLS_TABLE_LABELS,
    PATROL_SORT_ITEMS,
    DIALOG_TEXT,
    DIALOG_REMOVE_TEXT,
    URGENCY_CLASSIFICATION_SELECT,
    STATUS_CODE,
    MENU_FORM_PATROL,
    STATUS_CODE_ARRAY,
    STATUS_OBJECT,
    MODE_ACTION,
    PATROL_TYPE_ALL
  },

  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    Store.dispatch("GlobalHeader/setSiteMenu", {
      menuId: HEADER_MENU_ITEMS_SITE.PORTAL.id,
    });
    this.getTimeNow();
    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      (data) => {
        if (data["field_id"]) {
          this.field_id = data["field_id"];
          this.callApiCheckUserRole();
          this.getData(data["field_id"]);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters[`${STORE}/getData`],
      (data) => {
        this.initData(data);
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters[`Sites/getUserEnteringField`],
      (data) => {
        let _data = { ...data };
        this.FIELD_ENTRANCE_HISTORY_DAILYS = this.convertDataFieldEntranceHistory(_data);
        this.isNoDataJobType = this.FIELD_ENTRANCE_HISTORY_DAILYS.jobs?.length == 0;
      },
      {
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["Sites/getUserRole"],
      (data) => {
        if (data == this.ROLE_SUPERVISOR) {
          this.flagSupervisor = true;
        } else if (data == this.ROLE_USER_RENTAL_COMPANY) {
          this.flagUserRentalCompany = true;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Sites/getSite"],
      (data) => {
        this.editedItem = { ...data };
      },
      {
        deep: true,
      }
    );
  },

  methods: {
    updateFlag() {
      this.flagEditSuccess = !this.flagEditSuccess;
    },
    //todo get timeNow
    getTimeNow() {
      let now = new Date();
      this.timeNow =
        ("0" + now.getHours()).slice(-2) +
        ":" +
        ("0" + now.getMinutes()).slice(-2);
    },

    initData(data) {
      this.items = data;
      this.RECORDS_ITEMS = data.safety_diaries;
      this.WORK_RESULTS = this.addIdToTreeView(data.work_results);
      this.isNoDataWorkPlace = this.WORK_RESULTS.length == 0;
      this.WORK_HOT_RESULT = _.cloneDeep(data.work_hot_results);
      this.WORK_HOT_RESULT.childrens = this.addIdToTreeView(
        this.WORK_HOT_RESULT.childrens
      );
      this.isNoDataFireUse = this.WORK_HOT_RESULT.childrens.length == 0;
      this.FIELD_ENTRANCE_HISTORY_DAILYS = this.convertDataFieldEntranceHistory(
        data.field_entrance_history_dailys
      );
      this.isNoDataJobType = this.FIELD_ENTRANCE_HISTORY_DAILYS.jobs?.length == 0;
      this.EARLY_OVER_TIMES = data.early_over_times;
      this.isNoDataMessage = this.EARLY_OVER_TIMES.early_times?.length == 0 && this.EARLY_OVER_TIMES.over_times?.length == 0;
      this.cmn_mst_prefectures =
        Store.state.CmnMst.constants.entries.cmn_mst_prefectures;
      this.initDataCompany();
    },

    initDataCompany() {
      let company = { ...this.companies };
      company.name = this.items.field.name;

      let prefecture = this.cmn_mst_prefectures.find(
        (item) => item.id == this.items?.field?.field_prefectures_id
      )?.prefecture;
      let address = this.items.field.address;
      address = address ? " 、" + address : "";
      let city = this.items.field.city;
      company.address = "" + prefecture + city + address;

      let groundbreaking_date = this.items.field.groundbreaking_date;
      let complete_schedule_date = this.items.field.complete_schedule_date;
      company.term =
        groundbreaking_date && complete_schedule_date
          ? this.getFormattedDate(groundbreaking_date) +
            "-" +
            this.getFormattedDate(complete_schedule_date)
          : "";
      this.companies = company;
    },

    /**
     * (共通)
     * ここからapiにリクエストします
     */
    async getData(field_id) {
      let params;
      if (ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT) {
        params = {
          field_id: field_id,
          is_oba: true
        };
      } else {
        params = {
          field_id: field_id,
        }
      }
      await Store.dispatch(`${STORE}/get`, {params});
    },

    getDataTable(type) {
      return this.RECORDS_ITEMS.find((item) => item.type == type)
        ?.safety_diaries;
    },
    /**
     * 日付変換
     * @param date:yyyy/mm/dd（day）
     */
    getFormattedDate(date) {
      return fmtSlashDate(date).date;
    },

    goPage(path) {
      this.$router.push(path);
    },

    getGatePassageNumberTodayPerTomorrow() {
      return `${this.items.gate_passage_number_today || 0}/${
        this.items.gate_passage_number_tomorrow || 0
      }`;
    },

    getCraneUseNumberTodayPerTomorrow() {
      return `${this.items.crane_use_number_today || 0}/${
        this.items.crane_use_number_tomorrow || 0
      }`;
    },

    getMachineUseNumberTodayPerTomorrow() {
      return `${this.items.machine_use_number_today || 0}/${
        this.items.machine_use_number_tomorrow || 0
      }`;
    },
    addIdToTreeView(dataArray) {
      const _key = "";
      let _data = _.cloneDeep(dataArray);
      const addKey = (key, data) => {
        data.forEach((item, index) => {
          item["id"] = key != "" ? `${key}_${index}` : `${index}`;
          if (item.childrens && item.childrens.length > 0) {
            addKey(item["id"], data[index].childrens);
          }
        });
      };
      addKey(_key, _data);
      return _data;
    },
    async getUserEnteringField() {
      this.userEnteringLoading = true;
      await Store.dispatch("Sites/getUserEnteringField", this.field_id);
      this.userEnteringLoading = false;
    },
    convertDataFieldEntranceHistory(data) {
      let _data = { ...data };
      _data.jobs = _data.jobs.concat(_data.no_jobs);
      _data.jobs = this.addIdToTreeView(_data.jobs);
      return _data;
    },
    gotoEntranceHistory() {
      Store.dispatch("EntranceHistory/updateAdmissionStatus", [
        this.ENTERING_STATUS.ENTERING.id,
      ]);
      Store.dispatch("ScheduleSearch/show", true);
      this.flagUserRentalCompany
        ? this.$router.push("")
        : this.$router.push("/entrance/history");
    },
    async openFieldForm(id) {
      let params = {id: id}
      this.ENV_OBAYASHI ? params.is_oba = 1 : '';
      const siteInfo = await Store.dispatch(`Sites/getDetail`, {params});
      if (!siteInfo.hasError) {
        const editedItem = _.cloneDeep(siteInfo.data.contents.entries);
        if(this.ENV_OBAYASHI){
          let filedListTemp = _.cloneDeep(this.FILELD_APPROVALS)
            filedListTemp.forEach(element => {
              if(!editedItem.field_approvals.find(e => e.type === element.type)){
                editedItem.field_approvals.push(element);
              }
            });
        }
        if (
          editedItem.fields.face_recognition_flag ==
          FACE_RECOGNITION_FLAG.FACE.id
        ) {
          editedItem.field_entrance_devices.forEach((item) => {
            item.password = PASSWORD_DEFAULT;
          });
        }
        this.editedItem = editedItem;
        this.addKeyConstructions();
        this.isShowItemForm = true;
      }
    },

    closeItemForm() {
      this.isShowItemForm = false;
    },
    formUpdate(params) {
      this.editedItem = { ...params };
    },
    addKeyConstructions() {
      this.editedItem.field_constructions.forEach((element, index) => {
        element["key"] = `${Date.now()}_${index}`;
      });
      const req = (locations , construction_id) => {
        locations.forEach((element, i) => {
          element.field_images?.forEach((image) => {
            if (image.id && !image.deleted) {
              let itemConstruction = this.editedItem.field_constructions.find(
                (item) => item.id == construction_id
              );
              image["key"] = itemConstruction["key"];
            }
          });
          if (element.children && element.children.length > 0) {
            req(locations[i].children,construction_id);
          }
        });
      };
      this.editedItem.field_constructions.forEach((element) => {
        req(element.field_item_trees, element.id);
      });
    },

    /**
     * (共通)
     * 新規登録 / 更新
     * - idがある場合は更新
     */
    async submitForm(data, flag) {
      const companyUser = JSON.parse(sessionStorage.getItem("COMPANY_USER"))
        .Login.company_user;
      const hasId = "id" in data.fields;
      const editedItem = _.cloneDeep(data);
      editedItem.field_technicians.forEach((element) => {
        delete element["exam_category"];
      });
      editedItem.field_constructions.forEach((element) => {
        delete element["key"];
        if (!element.field_item_trees) element.field_item_trees = [];
      });
      editedItem.company_fields.company_id = companyUser.company_id;
      if (
        companyUser.company_branch_id &&
        !editedItem.company_fields.company_branch_id
      ) {
        editedItem.company_fields.company_branch_id =
          companyUser.company_branch_id;
      }
      if (
        editedItem.fields.face_recognition_flag ==
        FACE_RECOGNITION_FLAG.NO_FACE.id
      ) {
        delete editedItem["field_entrance_devices"];
      }
      if (
        editedItem.fields.face_recognition_flag == FACE_RECOGNITION_FLAG.FACE.id
      ) {
        editedItem.field_entrance_devices.forEach((item) => {
          if (item.password?.indexOf("●") != -1) {
            item.password = "";
          }
        });
      }
      let type = 0;
      editedItem.field_approvals?.forEach(element => {
        type = this.FILELD_APPROVALS_TYPE.find( e => e.name === element.type).id;
        element.type = type;
      });
      this.ENV_OBAYASHI? editedItem.is_oba = 1 : '';
      const result = await Store.dispatch(
        hasId ? `Sites/edit` : `Sites/add`,
        editedItem
      );
      if (result.hasError) {
        this.isErrorSubmit = true;
        this.flagErrorUser = !this.flagErrorUser;
        return;
      } else if (hasId) {
        let params = { id: data.fields.id}
        this.ENV_OBAYASHI ? params.is_oba = 1 : '';
        const resultDetail = await Store.dispatch(
          `Sites/getDetail`,
          {params}
        );
        const editedItem = _.cloneDeep(resultDetail.data.contents.entries);
        if (
          editedItem.fields.face_recognition_flag ==
          FACE_RECOGNITION_FLAG.FACE.id
        ) {
          editedItem.field_entrance_devices.forEach((item) => {
            item.password = PASSWORD_DEFAULT;
          });
        }
        this.editedItem = editedItem;
        this.isErrorSubmit = false;
        this.addKeyConstructions();
        await Store.dispatch("GlobalHeader/searchSites", {
          keyword: "",
        });
        this.flagEditSuccess = !this.flagEditSuccess;
        await Store.dispatch("GlobalHeader/setSite", {
          field_id: this.editedItem.fields.id,
        });
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
      } else {
        //成功したら値を更新
        this.closeItemForm();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
      if (flag) {
        this.isEmitted = !this.isEmitted;
      }
    },
    async callApiCheckUserRole() {
      const params = {
        field_id: this.field_id,
        target_role: [this.ROLE_SUPERVISOR, this.ROLE_USER_RENTAL_COMPANY],
      };
      await Store.dispatch("Sites/checkUserRole", { params });
    },
    updateListRight(data) {
      let _data = { ...data };
      this.submitForm(_data, false);
    },

    async openItemForm(item) {
      if (this.ENV_OBAYASHI) {
        this.field_construction_id = item.field_construction_id;
        this.patrol_record_id = item.id;
        const itemDetail = await Store.dispatch(`PatrolRecord/getDetail`, this.patrol_record_id);
        this.popups.isShowPaltrolForm = true;
        this.isNewItem.isNewItemPaltrols = false;
        this.typeFormPaltrol = item.safety_form;
        if (this.typeFormPaltrol === MENU_FORM_PATROL.SHITEKI.id ||
          item.safety_form == MENU_FORM_PATROL.CONTACT.id
        ) {
          this.childId = item.safety_diary_comment_correction_id;
        }
        if (!item.safety_diary_comment_correction_id) {
          await Store.dispatch(`Action/setActionSection`, 0);
        } else {
          const actIdx = itemDetail?.safety_diary_comment_corrections
            .findIndex((itx) => {
              return itx?.id === item?.safety_diary_comment_correction_id
            })
          setTimeout(async () => {
            await Store.dispatch(`Action/setActionSection`, actIdx);
          }, 1500)
        }
      }
    },

    closeFormPalTrol() {
      this.popups.isShowPaltrolForm = false;
      this.isNewItem.isNewItemPaltrols = false;
    },

    actionSuccess(flag) {
      if (flag) {
        this.closeFormPalTrol();
      }
      this.getItems();
    },
    
    async getItems() {
      let params;
      const siteID = JSON.parse(sessionStorage.getItem("GRN_HEADERS"))?.site;
      if (ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT) {
        params = {
          field_id: siteID,
          is_oba: true
        };
      } else {
        params = {
          field_id: siteID,
        }
      }
      await Store.dispatch("SitePortal/get", {params});
    },

    updateRecordId(id) {
      this.patrol_record_id = id;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

h4 {
  border-left: 5px solid #34be6b;
  padding-left: 8px;
}
.company-name {
  color: $color_primary;
}
.portal-summary {
  margin-top: 16px;
  margin-bottom: 24px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  overflow: hidden;
}
.portal-summary__box {
  padding: 0;
}
.portal-summay__row {
  display: flex;
}
.portal-summary__link {
  flex: 1;
  display: block;
  text-decoration: none;
  padding: 16px;
  box-sizing: border-box;
  &:hover {
    background-color: #f8f8f8;
  }
}
.portal-summary__label {
  text-align: center;
  color: $color_text_sub;
  font-size: 14px;
  flex: 1;
  white-space: nowrap;
}
.portal-summary__content {
  display: block;
  height: 64px;
}
.portal-summary__title {
  display: flex;
  height: 34px;
}
.portal-summary__value {
  text-align: center;
  font-size: 42px;
  line-height: 60px;
  color: $color_primary;
  flex: 1;
}
.portal-summary__content-title {
  font-size: 14px;
  height: 17px;
  color: black;
  display: flex;
  justify-content: center;
  width: 90%;
}
.portal-summary__arrow {
  width: 24px;
}
.portal-summary__unit {
  width: 24px;
  line-height: 78px;
  font-size: 14px;
  color: $color_text_main;
}
.portal-summary__divider {
  width: 1px;
  height: 100%;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.12);
}
.border-bottom {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.scrollTable {
  max-height: 145px;
  overflow: auto;
}
.header_maximum_win_speed {
  align-items: baseline;
}
.reload_maximum_win_speed {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.height_item_wrapper {
  max-width: 100%;
  max-height: 300px;
  overflow: auto;
}
.slogan {
  background-color: #1b9c4f;
  color: white;
  height: 50px;
  text-align: center;
  line-height: 2;
  font-size: 24px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 98%;
  padding-left: 10px;
}
.location {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 25vw;
  font-size: 10px;
}
.itemRecord{
  display: flex;
  align-items: center;
}
.itemRecord .v-chip{
  flex: 0 0 28px;
}
.itemRecord .record{
  flex-grow: 1;
  max-width: 452px;
  white-space: nowrap;
  width: calc(100% - 44px);
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  font-weight: 400;
}
</style>
