<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-list-item>
              <v-list-item-content>
                <SectionLabel label="関連する作業予定" />
                <Label label="作業予定" :editable="editable">
                  <span style="cursor: pointer" @click="editable ? openFormScheduleWork(index) : ''" >
                    <v-text-field
                      :value="workPlanContents(item)"
                      dense
                      readonly
                      :disabled="!editable"
                      :filled="editable"
                      color="primary"
                      :append-icon="editable ? 'mdi-menu-down':''"
                    ></v-text-field>
                  </span>
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row class="cst-top">
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <Label label="作業予定日" :editable="editable">
                  <InputText
                    name="work_date"
                    :values="item"
                    :editable="false"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <Label label="作業時間帯" :editable="editable">
                  <InputText
                    name="time_zone_name"
                    :values="item"
                    :editable="false"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <Label label="工事" :editable="editable">
                  <InputText
                    name="field_construction_name"
                    :values="item"
                    :editable="false"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <Label label="協力会社" :editable="editable">
                  <InputText
                    name="field_tree_company_name"
                    :values="item"
                    :editable="false"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <Label label="職長" :editable="editable">
                  <InputText
                    name="forman_name"
                    :values="item"
                    :editable="false"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <Label label="電話番号" :editable="editable">
                  <InputText
                    name="forman_tel"
                    :values="item"
                    :editable="false"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="10" md="11">
            <v-list-item>
              <v-list-item-content>
                <Label label="作業場所" :editable="editable">
                  <InputText
                    name="field_tree_item_name"
                    :values="item"
                    :editable="false"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col v-if="index > 0" cols="12" sm="2" md="1">
            <v-btn class="mt-16" icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    <v-btn @click="addEmpty" color="primary" v-if="editable" class="mt-6 ml-4">追加</v-btn>
    </v-container>
    <Popup width="1000px" :dialog="isShowScheduleWorkForm">
      <ScheduleWorkForm
        title="作業予定選択"
        @selectedWork="updateFormValue"
        @close="closeScheduleWorkForm"
      />
    </Popup>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */
import Label from "@/components/forms/elements/Label.vue";
import Select from "@/components/forms/elements/Select.vue";
import SectionLabel from "@/components/forms/elements/SectionLabel.vue";
import InputText from "@/components/forms/elements/InputText.vue";
import ScheduleWorkForm from "./ScheduleWorkForm.vue";
import Popup from "./Popup.vue";
import _ from "lodash";

//初期値を設定します
const INITIAL_VALUES = {
  id: null,
  work_plan_id: null,
  work_plan_contents:"",
  work_date: "",
  time_zone_name: "",
  field_construction_name: "",
  field_tree_company_name: "",
  forman_name: "",
  forman_tel: "",
  field_tree_item_name: ""
};

export default {
  data: () => {
    return {
      items: [],
      scheduleWork: [],
      indexScheduleWork: null,
      isShowScheduleWorkForm: false,
    };
  },
  components: {
    Label,
    Select,
    InputText,
    ScheduleWorkForm,
    Popup,
    SectionLabel,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    listUser: Array,
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (!this.items || this.items?.length == 0) {
          this.items = [];
          this.addEmpty();
        }
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    workPlanContents(item) {
      return item?.work_plan_contents?.length > 0 ? item.work_plan_contents : '';
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.formUpdate(this.name, _items);
    },
    addEmpty() {
      const items = [...this.items];
      items.push(_.cloneDeep(INITIAL_VALUES));
      this.items = items;
      this.formUpdate(this.name, items);
    },
    openFormScheduleWork(index) {
      this.indexScheduleWork = index;
      this.isShowScheduleWorkForm = true;
    },
    closeScheduleWorkForm() {
      const items = [...this.items];
      items[this.indexScheduleWork] = _.cloneDeep(INITIAL_VALUES);
      this.isShowScheduleWorkForm = false;
      this.items = items;
      this.formUpdate(this.name, items);
    },
    updateFormValue(item) {
      const items = [...this.items];
      const workItem = item;
      workItem.id = items[this.indexScheduleWork]?.id;
      items[this.indexScheduleWork] = workItem;
      this.isShowScheduleWorkForm = false;
      this.items = items;
      this.formUpdate(this.name, items);
    },
    formUpdate(name, value) {
      this.$emit("onInput", { name: name, value: value });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
.row-position {
  margin-top: -30px;
  height: 150px;
  position: relative;
}

.select-position {
  width: 100%;
  position: absolute;
}

.cst-top {
  margin-top: -14px !important;
}
</style>
