<template>
  <div class="machineMenuFrame">
    <ul class="machineMenu">
      <li class="machineMenu__item" v-for="item in items" :key="item.key">
        <div class="machineMenu__label" style="display: none">
          {{ item.name }} /{{ item.basic_weight }}t
        </div>
        <div class="machineMenu__imageWrapper">
          <button
            class="machineMenu__btn"
            :disabled="!(item.image_side_url && isMoveable)"
            @click="$emit('addMachine', { id: item.id })"
          >
            <img :src="item.image_side_url ? item.image_side_url : noimage" />
          </button>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { SPRITE_MACHINES } from "../../constants/SPRITE_MACHINE";
export default {
  data: () => {
    return {
      noimage: "/images/noimage.png",
      items: SPRITE_MACHINES,
    };
  },
  props: {
    isMoveable: {
      type: Boolean,
      default: true,
    }
  },
};
</script>
<style lang="scss" scoped>
.machineMenuFrame {
  overflow-x: scroll;
  height: 100%;
}
.machineMenu {
  padding: 16px 0 26px 0;
}
.machineMenu__item {
  padding: 4px 0;
}
.machineMenu__label {
  font-size: 10px;
  padding: 0 16px;
  text-align: left;
}
.machineMenu__imageWrapper {
  display: flex;
  justify-content: center;
}
.machineMenu__btn {
  border: none;
  width: 100px;
  height: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 8px;
  img {
    opacity: 0.8;
    width: 100%;
  }

  &:hover {
    img {
      opacity: 1;
    }
  }
}
</style>
