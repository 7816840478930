<template>
  <div
    :ref="`message_${item.message_id}`"
    :id="`message_${item.message_id}`"
    class="message"
    :class="getMessageClass(item.user_id, item.status_flg)"
  >
    <div class="message_inner">
      <div class="message__avatar" color="primary">
        <div class="message__userimage">
          <!-- <img v-if="hasUserImage" :src="userImage" /> -->
          <UserImage :imageUrl="userImage" />
        </div>
      </div>
      <div class="message__wrapper">
        <div class="message__header">
          <div class="message__username">{{ userName }}</div>
          <div class="message__date">{{ getTime }}</div>
        </div>
        <div class="message__text">
          {{ item.message }}
        </div>
        <div v-if="hasFiles" class="message__images">
          <div class="message__image" v-for="img in item.files" :key="img.id">
            <AttachFileCell
              large
              :file="img"
              @showAttachFile="showMessageAttachFile"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="message__read">
      <div class="message__read-label">既読</div>
      <div class="message__read-users">
        <div
          v-for="user in readers"
          :key="user.id"
          class="message__thumbnail--s"
          @click="
            $emit('showReadUsers', {
              title: '既読ユーザー',
              messageId: item.message_id,
            })
          "
        >
          <!-- <span>{{ user.name }}</span> -->
          <!-- <img :src="user.user_image_url" alt="" /> -->
          <UserImage :imageUrl="user.user_image_url" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AttachFileCell from "./AttachFileCell.vue";
import UserImage from "./UserImage.vue";
import { Store } from "@/store/Store.js";
import { formatDistanceToNow, format } from "date-fns";
import { ja } from "date-fns/locale";

const EnterFrame = (frameId, myRef, callback) => {
  const frame = document.getElementById(frameId);
  const frameRect = frame.getBoundingClientRect();

  //画面に入っているかの判定
  const checkEnter = () => {
    const rect = myRef.getBoundingClientRect();
    // 0 > になったら既読
    // console.log(
    //   "checkEnter===",
    //   frameId,
    //   rect.top - frameRect.top > 0,
    //   rect.top - frameRect.top
    // );
    if (rect.top - frameRect.top > 0) {
      callback();
    }
  };

  frame.addEventListener("scroll", () => {
    checkEnter();
  });
  checkEnter();
};

export default {
  data: () => {
    return {
      //既読者一覧
      readers: [],
      // 既読
      isRead: false,
      // 画面内
      isEnterFrame: false,
    };
  },

  props: {
    item: {
      type: Object,
    },
    frameId: {
      type: String,
    },
    self: {
      type: Object,
    },
  },

  components: {
    AttachFileCell,
    UserImage,
  },

  computed: {
    getTime() {
      return format(new Date(this.item.updated_at), "yyyy-MM-dd HH:mm");
    },
    hasFiles() {
      return "files" in this.item && this.item.files.length > 0;
    },
    hasUserImage() {
      return this.item?.user_image_url ? true : false;
    },
    userImage() {
      return this.item?.user_image_url;
    },
    userName() {
      return this.item?.user_name;
    },
    // 既読ユーザに自分が含まれているか
    inReadsMember() {
      const self = this.item.reads.find(
        (user) => user.user_id === this.self.id
      );
      return self ? true : false;
    },
  },

  mounted() {
    this.$nextTick(() => {
      // メッセージ要素
      const myRef = this.$refs[`message_${this.item.message_id}`];

      // 既読処理
      EnterFrame(this.frameId, myRef, async () => {
        // console.log("画面内", this.item.message_id);
        this.isEnterFrame = true;
      });
    });

    // itemがきたら既読者を更新
    this.$watch(
      () => this.item,
      (data, prev) => {
        if (JSON.stringify(data) !== JSON.stringify(prev)) {
          setTimeout(() => {
            this.readers = data.reads;
          }, 1000);
        }
      },
      { immediate: true, deep: true }
    );

    // 既読処理
    this.$watch(
      () => [this.isRead, this.isEnterFrame, this.self],
      async (values) => {
        const isRead = values[0];
        const isEnterFrame = values[1];
        const self = values[2];

        // console.log(
        //   "既読チェック",
        //   this.inReadsMember,
        //   this.item.message_id,
        //   isRead,
        //   isEnterFrame
        // );

        // 既読処理
        if (!this.inReadsMember && !isRead && isEnterFrame) {
          // console.log("既読処理!!!!", self.name, this.item.message_id);
          const message_id = this.item.message_id;
          const chat_room_id = this.item.chat_room_id;
          this.isRead = true;

          Store.dispatch("TextChat/read", {
            user_id: self.id,
            message_id,
            chat_room_id,
          });
        }
      },
      { immediate: true, deep: true }
    );
  },

  methods: {
    isSelf(id) {
      return this.self.id === id;
    },

    getMessageClass(id, statusFlag) {
      let str = ``;
      if (this.self.id === id) str += "self"; // 自分
      if (statusFlag === 1) str += " important"; //重要
      return str;
    },

    dateFormat(d) {
      return formatDistanceToNow(new Date(d), { locale: ja });
    },

    // 添付ファイルを表示
    showMessageAttachFile({ id }) {
      this.$emit("showMessageAttachFile", {
        messageId: this.item.message_id,
        fileId: id,
      });
    },

    // 既読ユーザー一覧
    async getReadUsers() {},
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.message {
  padding: 0 8px;
  border-top: 1px solid #f8f8f8;
  position: relative;

  .message_inner {
    display: flex;
    align-items: flex-start;
  }

  .message__wrapper {
    padding: 4px 0;
    flex: 1;
    width: calc(100% - 48px);
  }

  &.self {
    .message_inner {
      flex-direction: row-reverse;
    }

    .message__header {
      justify-content: flex-end;
    }
  }

  &.important {
    background-color: $color_primary_message;
  }
}
.message__avatar {
  width: auto;
  height: 100%;
  padding: 8px 0;
}

.message__userimage {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: white;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    border-radius: 24px;
  }
}

.message__header {
  display: flex;
  align-items: flex-end;
}
.message__username {
  font-size: 12px;
  margin-right: 8px;
}
.message__date {
  font-size: 8px;
}
.message__text {
  word-break: break-all;
  white-space: pre-wrap;
  padding: 8px 0;
}
.message__read {
  padding-right: 8px;
  height: 32px;
}
.message__read-label {
  text-align: right;
  color: gray;
  font-size: 8px;
}
.message__read-users {
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
}
.message__thumbnail--m {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: white;
}

.message__thumbnail--s {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
  margin: 0 2px;
  img {
    width: 16px;
    height: 16px;
  }
}

.message__images {
  display: flex;
  width: 100%;
  overflow-x: auto;
}
.message__image {
}
</style>
