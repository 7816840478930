// テーブル設定
const TABLE_LABELS_1 = [
  {
    text: "管理番号",
    value: "control_number",
    align: "left",
    width: "30px;"
  },
  {
    text: "種別 / 機械名",
    value: "machine_type_extral_machine_name",
    align: "left",
    width: "40%"
  },
  {
    text: "持込会社 / 機番",
    value: "delivery_company_name_extral_machine_number",
    align: "left",
    width: "30%"
  },
  {
    text: "搬入日",
    value: "delivery_date",
    align: "left",
    width: "20%"
  },
];

const TABLE_LABELS_2 = [
  {
    text: "",
    align: "left",
    value: "approval_flag",
  },
  {
    text: "管理番号",
    value: "control_number",
    align: "left",
    width: "10%"
  },
  {
    text: "種別 / 機械名",
    value: "machine_type_extral_machine_name",
    align: "left",
    width: "10%"
  },
  {
    text: "持込会社 / 機番",
    value: "delivery_company_name_extral_machine_number",
    align: "left",
    width: "25%"
  },
  {
    text: "作成日",
    value: "report_created_date",
    align: "left",
    width: "5%"
  },
  {
    text: "承認者",
    value: "approval_user_name",
    align: "left",
    width: "20%"
  },
  {
    text: "",
    value: "export_form",
    align: "left",
    width: "5%"
  },
];

const TABLE_LABELS_4 = [
  {
    text: "",
    align: "left",
    value: "approval_flag",
    width: "5%"
  },
  {
    text: "管理番号",
    value: "control_number",
    align: "left",
    width: "10%"
  },
  {
    text: "種別 / 機械名",
    value: "machine_type_extral_machine_name",
    align: "left",
    width: "10%"
  },
  {
    text: "",
    align: "left",
    value: "other_machine_count",
    width: "5%",
  },
  {
    text: "持込会社 / 機番",
    value: "delivery_company_name_extral_machine_number",
    align: "left",
    width: "25%"
  },
  {
    text: "作成日",
    value: "report_created_date",
    align: "left",
    width: "5%"
  },
  {
    text: "承認者",
    value: "approval_user_name",
    align: "left",
    width: "20%"
  },
  {
    text: "",
    value: "export_form",
    align: "left",
    width: "5%"
  },
];

const SORT_ITEMS_1 = [
  { id: "control_number", name: "管理番号" },
  { id: "delivery_company_name", name: "協力会社" },
  { id: "machine_name", name: "機械名" },
  { id: "machine_number", name: "機番" },
  { id: "delivery_date", name: "搬入日" },
];

const SORT_ITEMS_2 = [
  { id: "report_created_date", name: " 作成日" },
  { id: "control_number", name: "管理番号" },
  { id: "delivery_company_name", name: "持込会社" },
  { id: "machine_name", name: "機械名" },
  { id: "machine_number", name: "機番" },
];

const INFO_FORM = [
  { id: 1, value: "持込機械等使用届（移動式クレーン）"},
  { id: 2, value: "持込機械等使用届（車両系建設機械等）"},
  { id: 3,  value: "持込機械等使用届（電動工具・電気溶接機等）" },
  { id: 4,  value: "持込機械届済証" },
];

const STATUS_COLORS = [
  { color: "#000000", value: "使用不可" },
  { color: "#e0e0e0", value: "搬入予定" },
  { color: "#E5951D", value: "未使用" },
  { color: "#29ABE2", value: "予約済" },
  { color: "#32C36C", value: "使用中" },
  { color: "#e0e0e0", value: "返却済" },
];

const APPROVAL_COLORS = [
  { color: "#E5951D", value: "未承認" },
  { color: "#32C36C", value: "承認済" },
];

const APPROVE_STATUS = {
  APPROVE: { id: 1, name: "承認済" },
  UNAPPROVE: { id: 0, name: "未承認" },
};

const ROLE_USER = [0, 1, 2, 3, 4];

const CARRY_ONS_TABS = {
  TAB1: 0,
  TAB2: 1,
  TAB3: 2,
  TAB4: 3,
  TAB5: 4,
};

const INFO_CONFIRM_DIALOG = {
  CONFIRM_APPROVE_1: {
    title: "承認確認",
    message: "選択されたデータは全て承認済みです。"
  },
  CONFIRM_APPROVE_2: {
    title: "承認確認",
    message: "選択したデータを承認します。",
    text2: "承認してもよろしいですか。",
    titleBtnOk: "承認"
  },
  CONFIRM_UNAPPROVE_1: {
    title: "承認解除確認",
    message: "選択したデータは全て承認されていないため、承認解除出来ません。"
  },
  CONFIRM_UNAPPROVE_2: {
    title: "承認解除確認",
    message: "選択したデータの承認を解除します。",
    text2: "承認を解除してもよろしいですか。",
    titleBtnOk: "解除"
  },
  CONFIRM_REMOVE_DIALOG_1: {
    title: "削除確認",
    message: "選択されたデータは全て承認済みです。",
    text2: "削除できません。"
  },
};

const RADIO_MACHINE_COMPANY_FIELD_REPORT_DETAILS = [
  { id: 1, name: "自社", value: 1 },
  { id: 2, name: "リース", value: 0 }
];

const TABLE_TAB_NAME_4 = {
  DETAIL_MACHINE: { id: 1, title: "機械名" },
};

const TABLE_LABELS_TAB_4 = [
  { text: "管理番号", value: "control_number", align: "left", width: "10%"},
  { text: "種別", value: "machine_type", align: "left", width: "20%" },
  { text: "機械名", value: "machine_name", align: "left", width: "20%" },
  { text: "機番", value: "machine_number", align: "left", width: "10%" },
  { text: "点検者", value: "checkup_user_name", align: "left", width: "20%" },
  { text: "取扱者", value: "handle_user_name", align: "left", width: "20%" }
];

const TAB_NAME_FORM = {
  FROM_COMPANY: { id: 1, title: "持込会社" },
  COMPANY_USER: { id: 2, title: "使用会社"}
};

const TAB_SELECT_NAME = {
  TABLE1: { id: 0, name: "持込機械" },
  TABLE2: { id: 1, name: "移動式クレーン" },
  TABLE3: { id: 2, name: "車両系建設機械" },
  TABLE4: { id: 3, name: "電動工具" },
  TABLE5: { id: 4, name: "持込機械届済証" },
};

export const FILE_NAME_DOWNLOAD = {
  NOTIFICATION_OF_USE_CRANE: "Notification_of_Use_Cranes",
  NOTIFICATION_OF_USE_CRANE_JP: "持込機械等使用届(移動式クレーン・車両系建設機械等)",
  MACHINE_NOTIFICATION_CERTIFICATE: "Machine_Notification_Certificate",
  MACHINE_NOTIFICATION_CERTIFICATE_JP: "持込機械届済証",
  NOTIFICATION_OF_USE_TOOL: "Notification_of_Use_Tools",
  NOTIFICATION_OF_USE_TOOL_JP: "持込機械等使用届(電動工具・電気溶接機等)",
};

export {
  TABLE_LABELS_1,
  TABLE_LABELS_2,
  TABLE_LABELS_4,
  SORT_ITEMS_1,
  SORT_ITEMS_2,
  INFO_FORM,
  STATUS_COLORS,
  ROLE_USER,
  APPROVAL_COLORS,
  CARRY_ONS_TABS,
  INFO_CONFIRM_DIALOG,
  APPROVE_STATUS,
  RADIO_MACHINE_COMPANY_FIELD_REPORT_DETAILS,
  TABLE_TAB_NAME_4,
  TABLE_LABELS_TAB_4,
  TAB_NAME_FORM,
  TAB_SELECT_NAME
};
