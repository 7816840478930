<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col>
                  <v-list-item>
                    <v-list-item-content>
                      <v-col>
                        <SwitchInput
                          name="hot_work_flag"
                          :label="`火気使用届を作成`"
                          :values="formValues['work_plans']"
                          :editable="editable"
                          @onInput="onInput"
                        />
                      </v-col>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters>
                    <v-col>
                      <FormPurposeOfUse
                        name="work_hot_plans"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters>
                    <v-col>
                      <FormFireSource
                        :name="USER_TYPE.TYPE_2"
                        flag_name="work_user_plans"
                        :values="formValues"
                        :editable="editable"
                        :EMPLOYEE_ARRAY="EMPLOYEE_ARRAY"
                        @onInput="onInput"
                      />
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <FormFireUse
                        :name="USER_TYPE.TYPE_3"
                        flag_name="work_user_plans"
                        :values="formValues"
                        :editable="editable"
                        :EMPLOYEE_ARRAY="EMPLOYEE_ARRAY"
                        @onInput="onInput"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "../../elements/Label";
import InputText from "../../elements/InputText";
import Select from "../../elements/Select.vue";
import SwitchInput from "../../elements/SwitchInput";
import FormPurposeOfUse from "./components/FormPurposeOfUse.vue";
import FormFireSource from "./components/FormFireSource.vue";
import FormFireUse from "./components/FormFireUse.vue";
import { USER_TYPE } from "@/constants/SCHEDULE_WORKS";
import { Store } from "@/store/Store.js";
import _ from "lodash";

export default {
  data: () => {
    return {
      formValues: {},
      USER_TYPE,
      EMPLOYEE_ARRAY: []
    };
  },
  components: {
    Label,
    InputText,
    Select,
    SwitchInput,
    FormPurposeOfUse,
    FormFireSource,
    FormFireUse,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue, oldValue) => {
        this.formValues = { ...newValue };
        if(JSON.stringify(newValue?.work_plans?.field_tree_id) != JSON.stringify(oldValue?.work_plans?.field_tree_id)) {
          this.getListUsers();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      if(name == USER_TYPE.TYPE_2 || name == USER_TYPE.TYPE_3) {
        formValues['work_user_plans'][name] = value;
      } else if(name == "hot_work_flag") {
        if(value == 0) {
          formValues['work_user_plans'][USER_TYPE.TYPE_2] = [];
          formValues['work_user_plans'][USER_TYPE.TYPE_3] = [];
          formValues['work_hot_plans'] = [];
        }
        formValues['work_plans'][name] = value;
      } else {
        formValues[name] = value;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    async getListUsers() {
      let field_tree_id = this.formValues.work_plans?.field_tree_id;
      if(field_tree_id) {
        const params = {
          id: field_tree_id,
          state_handling_flag: 0
        };
        const rs = await Store.dispatch("PortalChart/getListCompanyPartnerUser", params);
        this.EMPLOYEE_ARRAY = _.cloneDeep(rs.data.contents.entries);
        this.EMPLOYEE_ARRAY.forEach((element) => {
          element.name = element.user_name_sei + " " + element.user_name_mei;
          element.id = element.user_id;
        });
      } else {
        this.EMPLOYEE_ARRAY = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
</style>
