<template>
  <div ref="self">
    <v-toolbar flat>
      <v-toolbar-title class="worksHeader-title primary--text">
        {{ pageTitle }}
      </v-toolbar-title>
      <div class="ml-5 mt-5" style="min-width: 150px">
        <InputDatepicker
          name="start_safety_diary_date"
          :editable="true"
          :values="searchParams"
          validation_label="日付（終了)"
          :validation_rules="getStartDateRules"
          @onInput="onChangeSearchParams"
        />
      </div>
      <div class="textBold ml-5" >～</div>
      <div class="ml-5 mt-5" style="min-width: 150px">
        <InputDatepicker
          name="end_safety_diary_date"
          :editable="true"
          :values="searchParams"
          validation_label="日付（開始)"
          :validation_rules="getEndDateRules"
          @onInput="onChangeSearchParams"
        />
      </div>
      <v-spacer></v-spacer>
      <v-btn class="mx-2" small icon>
        <v-icon color="rgba(0, 0, 0, 0.6)" @click="$emit('openHealthAndSafetyPopup')">mdi-cog</v-icon>
      </v-btn>
      <v-btn class="mx-2" small icon @click="openSearch">
        <v-icon> mdi-magnify </v-icon>
      </v-btn>
      <v-btn
        class="mx-2"
        small
        icon
        @click="$emit('openRemoveDialog')"
        :disabled="multiSelectStatus"
      >
        <v-icon> mdi-delete </v-icon>
      </v-btn>
      <v-btn
        class="ma-2"
        depressed
        color="warning"
        :disabled="multiSelectStatus"
        @click="$emit('onReject')"
      >
        承認解除
      </v-btn>
      <v-btn
        depressed
        class="ma-2"
        color="primary"
        :disabled="multiSelectStatus"
        @click="$emit('onApprove')"
      >
        承認
      </v-btn>
      <v-menu
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            新規作成
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="item in MENU_FORM_PATROL"
            :key="item.id"
            style="cursor: pointer"
            @click="$emit('openItemForm',item.id)"
          >
            <v-list-item-title >{{ item.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <div v-if="isShowSearch">
      <slot />
    </div>
  </div>
</template>
<script>
import InputDatepicker from "@/components/forms/elements/InputDatepicker";
import { MENU_FORM_PATROL } from "@/constants/PATROL_RECORD";
export default {
  data: () => {
    return {
      isShowSearch: false,
    };
  },
  props: {
    pageTitle: {
      type: String,
    },
    multiSelectStatus: {
      type: Boolean,
      default: false,
    },
    updateHeader: {
      type: Object,
    },
    searchParams: {
      type: Object,
      default: null,
    },
  },
  components : {
    InputDatepicker
  },
  computed : {
    MENU_FORM_PATROL() {
      return Object.keys(MENU_FORM_PATROL).map((key) => {
        return MENU_FORM_PATROL[key];
      });
    },
    getStartDateRules() {
      return this.searchParams.end_safety_diary_date ? `compare-end-date:${this.searchParams.end_safety_diary_date?.replaceAll("-", "/")} `: "";
    },
    getEndDateRules() {
      return this.searchParams.start_safety_diary_date ? `compare-start-date:${this.searchParams.start_safety_diary_date?.replaceAll("-", "/")}` : "";
    },
  },
  mounted() {
    setTimeout(() => {
      this.updateHeaderHeight();
    }, 200);
  },
  methods: {
    onChangeSearchParams({ name, value }) {
      this.$emit("onInput", { name, value });
    },
    openSearch() {
      this.isShowSearch = !this.isShowSearch;
      this.$nextTick(() => {
        this.updateHeaderHeight();
      });
    },
    updateHeaderHeight() {
      const height = this.$refs.self.getBoundingClientRect().height;
      this.updateHeader.setHeaderHeight(height);
    },
  },
};
</script>
<style scoped>
.v-application--is-ltr .v-btn__content .v-icon--right,
.v-application--is-rtl .v-btn__content .v-icon--left {
  margin-left: 0px;
  margin-right: 0px;
}

.v-toolbar__title.worksHeader-title {
  font-size: 24px !important;
  font-weight: 600 !important;
}
.textBold {
  font-size: 24px !important;
  font-weight: bold !important;
}
</style>
