<template>
  <FormDialog>
    <template #header>
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title style="color: green"> 現場紐付け</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn class="mr-5" depressed small outlined color="primary" @click="openConfirmCloseForm">
          やめる
        </v-btn>
        <v-btn depressed small color="primary" :key="flagButtonSubmit" @click.once="submit"> 保存</v-btn>
        <v-btn icon>
          <v-icon @click="openConfirmCloseForm">mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in RELATE_MACHINE_FORM_TABS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <RelateMachineFormPage1
            :mainHeight="params.mainHeight"
            @updateMachineCompanyFields="updateMachineCompanyFields"
          />
        </v-tab-item>
      </v-tabs-items>
    <Popup width="480px" :dialog="isShowConfirmCloseDialog">
      <ConfirmCloseDialog
        title="フォームを閉じる確認"
        text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
        text2="このページから移動してもよろしいですか？"
        @close="onClickBtnCancelForm"
        @yes="closeForm"
        warning
      />
    </Popup>
    </template>
  </FormDialog>
</template>
<script>
import { Store } from "@/store/Store.js";
import FormDialog from "@/components/dialog/FormDialog.vue";
import RelateMachineFormPage1 from "./RelateMachineFormPage1";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import Popup from "@/components/common/Popup.vue";
import { RELATE_MACHINE_FORM_TABS } from "@/constants/MACHINE_FIELD";

export default {
  data() {
    return {
      RELATE_MACHINE_FORM_TABS,
      machineCompanyFields : [],
      items : {},
      tab: null,
      isShowConfirmCloseDialog : false,
      flagButtonSubmit : false
    };
  },
  components: {
    RelateMachineFormPage1,
    FormDialog,
    ConfirmCloseDialog,
    Popup
  },
  computed :{
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },
  methods:{
    /**
     * submit relate machine
     */
    async submit(){
      let params = {
        field_id: this.CURRENT_SITE.field_id,
        machine_company_ids: []
      };
      this.machineCompanyFields.forEach(element => {
        element.childrens.forEach(child => {
          delete child.index;
          delete child.name;
          delete child.machine_number;
          params.machine_company_ids.push(child);
        });
      });
      // call api update relate machine field relation
      let rs = await Store.dispatch(`MachineField/updateMachineFieldRelation`, params);
      if (rs.hasError) {
        this.flagButtonSubmit = !this.flagButtonSubmit;
      } else {
        await Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました"
        });
        this.$emit("updateItemsAfterRelate");
      }
    },
    /**
     * close button confirm close
     */
    onClickBtnCancelForm(){
      this.isShowConfirmCloseDialog = false;
    },
    /**
     * close relate machine form
     */
    closeForm(){
      this.isShowConfirmCloseDialog = false;
      this.$emit('closeForm');
    },
    /**
     * open confirm close dialog
     */
    openConfirmCloseForm(){
      this.isShowConfirmCloseDialog = true;
    },
    /**
     * update machine company field to submit
     */
    updateMachineCompanyFields(params){
      this.machineCompanyFields = [...params];
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
</style>
