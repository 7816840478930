<template>
  <FormDialog>
    <template #header>
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title style="color: #1b9c4f">
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editable && roleUser == ROLE_USER[4]"
          class="mr-5"
          depressed
          small
          outlined
          color="primary"
          @click="isShowConfirmDialog = true"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable && roleUser == ROLE_USER[4]"
          depressed
          small
          :disabled="!valid"
          color="primary"
          :key="flagError"
          @click.once="onSubmit"
        >
          保存
        </v-btn>
        <v-btn
          v-if="!editable && roleUser == ROLE_USER[4]"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <ValidationObserver ref="observer" v-slot="observer">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <MachineDetailFormTab1
              :flagEstimate="flagEstimate"
              :editable="editable"
              :item="editedItem"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <MachineDetailFormTab2
              :flagEstimate="flagEstimate"
              :editable="editable"
              :item="editedItem"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item v-if="flagEstimate">
            <MachineDetailFormTab3
              :item="editedItem"
              :mainHeight="params.mainHeight"
            />
          </v-tab-item>
        </v-tabs-items>
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
          入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import _ from "lodash";
import { Store } from "@/store/Store.js";
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/forms/device/coordinator/applications/components/ConfirmCloseDialog.vue";
import MachineDetailFormTab1 from "./MachineDetailFormTab1.vue";
import MachineDetailFormTab2 from "./MachineDetailFormTab2.vue";
import MachineDetailFormTab3 from "./MachineDetailFormTab3.vue";
import {
  FORMS_DETAIL_MACHINE_ESTIMATE,
  FORMS_DETAIL_MACHINE_ORDER,
  STORE,
  ROLE_USER
} from "@/constants/DEVICE_COORDINATOR";

export default {
  data: () => {
    return {
      STORE,
      ROLE_USER,
      FORMS_DETAIL_MACHINE_ESTIMATE,
      FORMS_DETAIL_MACHINE_ORDER,
      valid: false,
      tab: null,
      editable: false,
      editedItem: {},
      beforeUpdateItem: {},
      isClickCloseBtn: false,
      errors: {},
      isShowConfirmDialog: false,
      flagInitial: true
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    MachineDetailFormTab1,
    MachineDetailFormTab2,
    MachineDetailFormTab3
  },
  props: {
    items: Object,
    flagError: Boolean,
    isEmitted: Boolean,
    flagEstimate: Boolean,
    roleUser: Number
  },
  mounted() {
    this.$watch(
      () => Store.getters[`DeviceCoordinator/${this.URL_GETTER}`],
      (data) => {
        this.editedItem = _.cloneDeep(data);
        this.beforeUpdateItem = _.cloneDeep(data);
        if (this.flagInitial) {
          this.getListMachineCompanyType(data);
        }
        this.flagInitial = false;
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  computed: {
    formtitle() {
      return "機材情報";
    },
    FORMS() {
      return this.flagEstimate ? this.FORMS_DETAIL_MACHINE_ESTIMATE : this.FORMS_DETAIL_MACHINE_ORDER;
    },
    URL_GETTER() {
      return this.flagEstimate ? "getMachineEstimateDetail" : "getMachineOrderDetail";
    },
    TAB(){
      return this.flagEstimate ? 'estimate' : 'order';
    }
  },
  methods: {

    formUpdate(params) {
      this.editedItem = { ...params };
    },

    onEditable() {
      this.editable = true;
    },

    updateValidate({ valid, errors }) {
      this.valid = valid;
      this.errors = errors;
    },

    async closeForm() {
      if (this.editable && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        const item = _.cloneDeep(this.beforeUpdateItem);
        this.formUpdate(item);
        await this.getListMachineCompanyType(item);
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },

    onClickBtnClose() {
      if (!this.editable) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },

    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },

    async onSubmit() {
      let _item = { ...this.editedItem };
      _item.tab = this.flagEstimate ? 2 : 3;
      let temp = _item[`${this.TAB}_machine_company_type_item_tree_ids`];
      _item[`${this.TAB}_machine_company_type_item_tree_id`] = temp.length > 0 ? temp.at(-1) : null;
      delete _item.machine_company_field_applications;
      delete _item[`${this.TAB}_machine_company_type_item_tree_ids`];
      _item.machine_company_field_application_images = _item.machine_company_field_application_images.filter(e => e.image !== null);
      let rs = await Store.dispatch(`${STORE}/updateMachineEstimateOrder`, _item );
      if (rs.hasError) {
        this.flagError = !this.flagError;
      } else {
        this.flagEstimate ? await Store.dispatch(`${STORE}/getMachineEstimateDetail`, _item.id) :
          await Store.dispatch(`${STORE}/getMachineOrderDetail`, _item.id);
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました"
        });
        this.$emit("updateList");
        this.editable = false;
      }
    },

    async getListMachineCompanyType(data) {
      let params = {
        company_id: data[`${this.TAB}_rental_company_id`],
        company_branch_id: data[`${this.TAB}_rental_company_branch_id`] ? data[`${this.TAB}_rental_company_branch_id`] : ""
      };
      await Store.dispatch(`CompanyMachines/getMachineCompanyType`,  params );
    },
  }
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
