<template>
  <v-container>
    <v-main v-for="(item, index) in items" :key="index">
      <v-row>
        <v-col cols="12" sm="11" md="11">
          <v-list-item>
            <v-list-item-content>
              <Label
                label="作業安全指示書用画像（キープラン）欄"
                :editable="editable"
              >
                <span class="file"
                  v-if="!editable && item['image_url']"
                  @click="viewSourceWithAuth(item['image_url'])"
                >
                  {{ item["image"] }}
                </span>
                <FileInput
                  :style="getFileInputStyle(index, 'image')"
                  :resetValue="resetValue[`${index}_image`]"
                  v-if="editable"
                  name="image"
                  :values="item"
                  :editable="editable"
                  testid=""
                  accept="image/jpg, image/png, image/jpeg, application/pdf"
                  placeholder=""
                  v-bind:validation_label="`${index}_image`"
                  validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf"
                  @onInput="onInput({ value: $event, index })"
                />
                <span
                  class="file"
                  v-if="judgeDisplayFileInput(index,'image')"
                  >{{ item["image"] }}
                  <v-btn
                    small
                    icon
                    text
                    @click="
                      onRemoveFile({
                        index: index,
                        name: 'image',
                      })
                    "
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </span>
              </Label>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-btn
            v-if="index > 0 && editable"
            class="mt-7"
            icon
            @click="remove(index)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-main>
    <v-btn v-if="editable" depressed small color="primary" @click="addEmpty"
      >追加</v-btn
    >
  </v-container>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "../../../elements/Label.vue";
import InputText from "../../../elements/InputText.vue";
import FileInput from "../../../elements/FileInput.vue";
import Select from "../../../elements/Select.vue";
import _ from "lodash";
import { viewSourceWithAuth } from "@/utils/viewSourceAuth";

//初期値を設定します
const INITIAL_VALUES = {
  image: null,
};
const DELETED = 1;

export default {
  data: () => {
    return {
      items: [],
      fileValid: {},
      resetValue: {},
      viewSourceWithAuth: viewSourceWithAuth,
    };
  },
  components: {
    Label,
    InputText,
    FileInput,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    errors: {
      type: Object,
      default: {},
    },
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue, oldValue) => {
        if (this.items?.length !== newValue[0][newValue[1]]?.length
            || JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.items = newValue[0][newValue[1]];
          if(this.items?.length == 0){
            this.addEmpty();
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    // check input file error
    this.$watch(
      () => [this.values, this.name, this.errors],
      (data) => {
        const errors = { ...data[2] };
        // the validation_label list of the FileInput element
        const image = "image";
        const fileValid = { ...this.fileValid };
        const _items = _.cloneDeep(data[0][data[1]]);
        if (errors) {
          let index = 0;
          for (let name in errors) {
            if (name?.includes(image)) {
              if (errors[`${index}_${image}`].length > 0) {
                delete _items[index]["image"];
                delete _items[index]["image_data"];
                fileValid[name] = false;
              } else {
                fileValid[name] = true;
              }
              index++;
            }
          }
        }
        this.items = _items;
        this.fileValid = fileValid;
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      if (value.fileName) {
        _items[index][`${value.name}_data`] = value.value;
        _items[index][value.name] = value.fileName;
        if (!value.value) {
          delete _items[index][`${value.name}_deleted`];
        }
      } else {
        _items[index][value.name] = value.value;
      }
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(index) {
      const items = this.items.filter((item, i) => {
        return index != i;
      });
      this.resetValue[`${index}_image`] = !this.resetValue[`${index}_image`];
      this.$emit("onInput", { name: this.name, value: items });
    },
    addEmpty() {
      let _items = [...this.items];
      _items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    onRemoveFile({ index, name }) {
      let _items = [...this.items];
      delete _items[index][name];
      delete _items[index][`${name}_data`];
      if (_items[index][`${name}_url`]) _items[index][`${name}_deleted`] = DELETED;
      this.items = _items;
      this.resetValue[`${index}_image`] = !this.resetValue[`${index}_image`];
      this.$emit("onInput", { name: this.name, value: _items });
    },
    getFileInputStyle(index, fieldName) {
      return (this.fileValid[`${index}_${fieldName}`] && this.items[index][fieldName]) ? 'display:none;': '';
    },
    judgeDisplayFileInput(index, fieldName) {
      return this.editable && this.fileValid[`${index}_${fieldName}`] && this.items[index][fieldName];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/forms.scss";
</style>
