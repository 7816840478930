import Vue from "vue";
import Vuex from "vuex";
import { report } from "@/api/modules/reports"

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = report
export const Report = {

  namespaced: true,

  state: {
    data: [],
    userRole: {},
    companyPartnerForPrint: [],
    userRolePrime: null
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_USER_ROLE(state, payload) {
      state.userRole = payload;
    },
    SET_COMPANY_PARTNER_FOR_PRINT(state, payload) {
      state.companyPartnerForPrint = payload;
    },
    SET_DATA_ROLE_PRIME(state, payload) {
      state.userRolePrime = payload;
    }
  },

  actions: {
    async get({ commit }, payload) {
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_DATA", entries);
      return response;
    },
    async getUserRole({ commit }, payload) {
      const response = await ENTITY.getUserRole(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_USER_ROLE", entries);
      return response;
    },
    async getCompanyPartnerForPrint({ commit }, payload) {
      const response = await ENTITY.getCompanyPartnerForPrint(payload);
      const entries = response.data.contents.entries;
      commit("SET_COMPANY_PARTNER_FOR_PRINT", entries);
      return response;
    },
    async getUserRolePrime({ commit }, payload) {
      const response = await ENTITY.getUserRolePrime(payload);
      commit("SET_DATA_ROLE_PRIME", response.data.contents.entries.role);
    }
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getUserRole: (state) => {
      return state.userRole;
    },
    getCompanyPartnerForPrint: (state) => {
      return state.companyPartnerForPrint;
    }
  },
};
