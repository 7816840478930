<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row>
          <v-col cols="12" sm="11" md="11">
              <v-list-item>
                <v-list-item-content class="row-position">
                    <Label label="作業員" :editable="editable" required>
                      <Select
                        class="select-position"
                        name="id"
                        :values="item"
                        :items="FIELD_TREE_USER_ARRAY[index]"
                        :editable="editable"
                        @onInput="onInput({ index, value: $event })"
                        validation_label="作業員"
                        validation_rules="required"
                      />
                    </Label>
                </v-list-item-content>
              </v-list-item>
          </v-col>
          <v-col v-if="index > 0 && editable" cols="12" sm="1" md="1">
            <v-btn class="mt-16" icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn v-if="editable && items.length < users.length" class="mt-4 ml-4" color="primary" @click="addEmpty" depressed small> 追加 </v-btn>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "@/components/forms/elements/Label";
import Select from "@/components/forms/elements/Select";
import _ from "lodash";

//初期値を設定します
const INITIAL_VALUES = {
  id: null,
};

export default {
  data: () => {
    return {
      items: [],
      FIELD_TREE_USER_ARRAY: [],
    };
  },
  components: {
    Label,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    selectArrays: {
      type: Array,
    },
    users: {
      type: Array,
    },
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (!this.items || this.items?.length == 0) {
          this.items = [];
          this.addEmpty();
        }
        this.initDataArray();
      },
      {
        deep: true,
      }
    );
    this.$watch(
      () => this.users,
      () => {
        this.initDataArray();
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      const items = [...this.items];
      items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: items });
    },
    initDataArray() {
      this.FIELD_TREE_USER_ARRAY = [];
      this.items?.forEach(() => {
        const employee = _.cloneDeep(this.users);
        employee.unshift({id: null, name: ""});
        this.FIELD_TREE_USER_ARRAY.push(employee);
      });
      this.items?.forEach((items, index) => {
        if (items.id) {
          this.convertDataArray(index, items.id);
        }
      });
    },
    convertDataArray(index, id) {
      this.FIELD_TREE_USER_ARRAY.forEach((_items, i) => {
        if (index != i) {
          _items.forEach((_item, index) => {
            if (_item.id == id) _items.splice(index, 1);
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/forms.scss";
.row-position{
  margin-top: -15px;
  height: 150px;
  position: relative;
}

.select-position{
  width: 100%;
  position: absolute;
}
</style>