<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row no-gutters>
          <v-col v-if="!editable">
            <v-list-item>
              <v-list-item-content>
                <Label label="使用時間" :editable="!editable">
                  <v-text-field
                    :label="getProhibitTime(item)"
                    dense
                    color="primary"
                    :disabled="!editable"
                    :filled="editable"
                  ></v-text-field>
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col v-if="editable" cols="12" sm="5" md="5" class="pt-0 pb-0">
            <v-list-item>
              <v-list-item-content>
                <Label label="開始時間" :editable="editable" :required="Boolean(!item.start_time && item.end_time)">
                  <Select
                    name="start_time"
                    :values="item"
                    :items="startTimes"
                    :editable="editable"
                    :validation_label="`開始時間 [${index}]`"
                    :validation_rules="getRuleStartTime(item)"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col v-if="editable" cols="12" sm="5" md="5" class="pt-0 pb-0">
            <v-list-item>
              <v-list-item-content>
                <Label label="終了時間" :editable="editable" :required="Boolean(item.start_time && !item.end_time)">
                  <Select
                    name="end_time"
                    :values="item"
                    :items="endTimes"
                    :editable="editable"
                    :validation_label="`終了時間 [${index}]`"
                    :validation_rules="getRuleEndTime(item)"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="pt-0 pb-0">
            <v-btn
              v-if="index >= row && editable"
              class="mt-12"
              icon
              @click="remove(index)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn v-if="editable" depressed small color="primary" @click="addEmpty"
      >追加</v-btn
    >
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "../../../elements/Label.vue";
import Select from "../../../elements/Select.vue";
import {
  cmn_mst_time_start,
  cmn_mst_time_end,
} from "@/constants/SCHEDULE_COMPANY";
//初期値を設定します
const INITIAL_VALUES = {
  start_time: null,
  end_time: null,
};

export default {
  data: () => {
    return {
      items: [],
      cmn_mst_time_start,
      startTimes: [],
      cmn_mst_time_end,
      endTimes: []
    };
  },
  components: {
    Label,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    row: {
      type: Number,
      default: 1,
    },
    timeZone: {
      type: Object
    }
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (this.items?.length == 0) {
          this.addEmpty();
        }
        this.getData();
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      const itemEdited = { ...this.items[index] };
      itemEdited[value.name] = value.value;
      const items = [...this.items];
      items[index] = itemEdited;
      this.$emit("onInput", { name: this.name, value: items });
    },
    remove(index) {
      const items = this.items.filter((item, i) => {
        return index != i;
      });
      this.$emit("onInput", { name: this.name, value: items });
    },
    addEmpty() {
      let _items = [...this.items];
      _items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    getData() {
      if(this.timeZone) {
        let arrayStart = this.cmn_mst_time_start.filter((item) => {
          return item.id >= this.timeZone.id_start_time && item.id < this.timeZone.id_end_time;
        })
        this.startTimes = [{ id: null, name: "" },...arrayStart];
        let arrayEnd = this.cmn_mst_time_end.filter((item) => {
          return item.id > this.timeZone.id_start_time && item.id <= this.timeZone.id_end_time;
        })
        this.endTimes = [{ id: null, name: "" },...arrayEnd];
      }
    },
    convertTime(time) {
      let nums = time.split(":");
      let hours = ((nums[0] % 24) < 10 ? "0" + (nums[0] % 24) : (nums[0] % 24));
      if(nums[0] =="24") {
        hours = nums[0];
      }
      let minutes = nums[1];
      return hours + ":" + minutes;
    },

    getProhibitTime(item) {
      let start_time = item.start_time ? this.convertTime(item.start_time) : "";
      let end_time = item.end_time ? this.convertTime(item.end_time) : "";
      return start_time != "" && end_time != ""
        ? start_time + "～" + end_time
        : "";
    },

    getRuleStartTime(item) {
      let rule = item.end_time ? 'required-usage-start-time' + `|compare-to-end-time:${item.end_time}` : '';
      if(this.items.length > 1 && item.start_time && item.end_time) {
        const isNotDuplicate = this.getCountOfItem(item)== 1 ? 1 : 0;
        rule = rule + `|duplicate-time:${isNotDuplicate}`;
      }
      return rule;
    },
    getRuleEndTime(item) {
      let rule = item.start_time ? 'required-usage-end-time' + `|compare-to-start-time:${item.start_time}` : '';
      if(this.items.length > 1 && item.start_time && item.end_time) {
        const isNotDuplicate = this.getCountOfItem(item) == 1 ? 1 : 0;
        rule = rule + `|duplicate-time:${isNotDuplicate}`;
      }
      return rule;
    },
    getCountOfItem(item) {
      let index = 0;
      this.items.forEach(element => {
        if(item.start_time == element.start_time && item.end_time == element.end_time) {
          index++;
        }
      })
      return index;
    }
  },
};
</script>
