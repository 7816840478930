<template>
  <FormDialog>
    <template #header>
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          {{ TITLE }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <IconPdf
          v-if="editable && isLoaded"
          class="mr-5"
          @previewPdf="previewPdf"
        />
        <div v-if="isLoaded">
          <v-btn
              v-if="!editable && !isApproved && formValues['owner_flag'] === OWNER_FLAG.OWNER"
              depressed
              small
              outlined
              color="primary"
              class="mr-5"
              @click="onClickBtnClose"
          >
            やめる
          </v-btn>
          <v-btn
              ref="BtnToggleEditable"
              v-if="!editable && !isApproved && formValues['owner_flag'] === OWNER_FLAG.OWNER"
              depressed
              small
              color="primary"
              class="mr-5"
              @click="onEditable"
          >
            編集
          </v-btn>
          <v-btn v-if="editable" class="mr-5"
                 depressed
                 small
                 outlined
                 color="primary" @click="onReadonly">
            やめる
          </v-btn>
          <v-btn
              v-if="isNewItem"
              :disabled="!isLoaded"
              depressed
              class="mr-5"
              small
              color="primary"
              @click="onCopy"
          >
            前回コピー
          </v-btn>
          <v-btn
              v-if="editable && isNewItem"
              depressed
              small
              :disabled="!valid"
              color="primary"
              :key="flagError"
              @click.once="onSubmit"
          >
            登録
          </v-btn>
          <v-btn
              v-if="editable && !isNewItem"
              depressed
              small
              :disabled="!valid"
              color="primary"
              :key="flagError"
              @click.once="onSubmit"
          >
            更新
          </v-btn>
        </div>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <div>
        <transition name="fade">
          <div class="loading" v-if="!isLoaded">
            <half-circle-spinner
              :animation-duration="1000"
              :size="60"
              :color="'#61d690'"
            />
          </div>
        </transition>
      </div>
      <ValidationObserver ref="observer" v-slot="observer" v-if="isLoaded">
        <!-- フォームtab -->
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <!-- 基本情報 -->
            <ScheduleCraneFormObayashiPage1
              :item="formValues"
              :editable="editable"
              :isNewItem="isNewItem"
              :mainHeight="params.mainHeight"
              :roleUser="roleUser"
              :isCopy="isCopy"
              :cranePassagesCustomize="cranePassagesCustomize"
              @resetCopy="isCopy=false"
              @formUpdate="formUpdate"
              @changeModeFirst="checkMode"
            />
          </v-tab-item>
          <v-tab-item>
            <!-- 登録・変更履歴 -->
            <FormHistory
              :item="formValues"
              :editable="editable"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
        </v-tabs-items>
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="closePopup"
          @yes="closeForm"
          warning
        />
      </Popup>
      <Popup width="1500px" :dialog="isShowPopupPdf">
        <PopupPdf
          :mainHeight="params.mainHeight"
          :fieldDocument="fieldDocument"
          :numPages="numPages"
          @cancel="isShowPopupPdf=false"
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import FormHistory from "@/components/forms/schedule/cranes/FormHistory.vue";
import { Store } from "@/store/Store.js";
import { CONFIRM_DIALOG_STATUS, INIT_DATA_OBAYASHI, OWNER_FLAG } from "@/constants/SCHEDULE_CRANE";
import _ from "lodash";
import IconPdf from "@/components/forms/schedule/components/IconPdf";
import ScheduleCraneFormObayashiPage1 from "./ScheduleCraneFormObayashiPage1";
import PopupPdf from "../../../../../views/schedule/components/PopupPdf";
import pdf from "vue-pdf";
import { getParamsOfUrlReadFile } from "@/utils/viewSourceAuth";
import { removeOneDayToEndTime , addOneDayToEndTime } from "@/constants/CRANES";
import { HalfCircleSpinner } from "epic-spinners";
/**
 * フォームとタブの設定
 */
const FORMS = {
  FormBasicInfo: { id: 1, title: "基本情報" },
  FormHistory: { id: 2, title: "登録・変更履歴" },
};

export default {
  name: "LiftingScheduleForm",
  data: () => {
    return {
      OWNER_FLAG,
      TITLE: '揚重予定情報',
      FORMS,
      valid: false, //バリデーション結果
      // tab初期化
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      isClickCancelBtn: false,
      formValues: _.cloneDeep(INIT_DATA_OBAYASHI),
      backData: {},
      isApproved: false,
      roleUser : null,
      flagError: false,
      isCopy: false,
      isShowPopupPdf: false,
      fieldDocument: {},
      numPages: 0,
      cranePassagesCustomize: {},
      isLoaded: false
    };
  },
  components: {
    ScheduleCraneFormObayashiPage1,
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    FormHistory,
    IconPdf,
    PopupPdf,
    HalfCircleSpinner,
  },
  props: {
    item: Object,
    isNewItem: Boolean,
    dataInitial : Object
  },
  computed: {
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },
  created() {
    // call condition required and show/off item
    this.getPlanItemSetting();
  },
  mounted() {
    this.checkRoleUser(this.CURRENT_SITE.field_id);
    /**
     * (共通)
     * 新規作成を監視
     */
    this.$watch(
      () => this.isNewItem,
      async (flag) => {
        //新規の場合は最初から編集モード
        if (flag) {
          this.editable = true;
          let _formValues = {...INIT_DATA_OBAYASHI};
          _formValues.use_date_from = this.item.use_date_from;
          _formValues.use_date_to = this.item.use_date_to;
          _formValues.field_construction_id = this.item.field_construction_id;
          _formValues.work_hour_zone = this.item.work_hour_zone;
          this.formValues = { ..._formValues };
        }
        if (!flag) await this.getItems();
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => [Store.getters["ScheduleCrane/getScheduleCraneDetail"], this.editable],
      (data) => {
        this.isLoaded = true;
        if(!this.isNewItem) {
          let _formValues = _.cloneDeep(this.formValues);
          let _data = _.cloneDeep(data[0]);
          _data.device_usage_times = removeOneDayToEndTime(_data.device_usage_times);
          if (_data) _formValues = _.cloneDeep(_data);
          this.backData = _.cloneDeep(_data);
          if (_formValues.device_users.length === 0) {
            _formValues.device_users = [{field_director_id: null,}];
          }
          if (_formValues.is_company_spot == 1) {
            _formValues.is_company_spot = true;
          } else {
            _formValues.is_company_spot = false;
          }
          this.formValues = _formValues;
          if(this.formValues['approval'] == CONFIRM_DIALOG_STATUS.APPROVED) {
            this.isApproved = true;
          }
        }
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Sites/getRole"],
      (data) => {
        this.roleUser = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    checkMode () {
      if (this.formValues['approval'] == 0 && this.formValues['owner_flag'] === OWNER_FLAG.OWNER) {
        this.onEditable()
      }
    },
    async checkRoleUser(field_id){
      const params = {
        field_id,
        target_role: []
      };
      await Store.dispatch("Sites/getRole", params);
    },
    /**
     * (共通)
     * フォームの変更を送信
     */
    formUpdate(params, parent_name) {
      if(parent_name) {
        const formValues = {...this.formValues};
        formValues[parent_name] = {... formValues[parent_name], ...params};
        this.formValues = {...formValues};
      } else {
        this.formValues = {...this.formValues, ...params};
      }
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      this.editable = true;
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onReadonly() {
      this.isClickCancelBtn = true;
      this.isShowConfirmDialog = true;
    },

    /**
     * (共通)
     * 状態を詳細に変更
     */
    onDetail() {
      this.editable = false;
    },

    /**
     * (共通)
     * 登録
     */
    async onSubmit() {
      const apiParams = _.cloneDeep(this.formValues);
      apiParams.is_oba = true;
      const hasId = "id" in this.formValues;
      apiParams.device_usage_times = addOneDayToEndTime(apiParams.device_usage_times);
      if(apiParams.is_company_spot && apiParams.is_company_spot == 1) {
        apiParams.job_id = null;
        apiParams.field_tree_user_id = null;
        apiParams.field_tree_user_tel = null;
        apiParams.field_tree_id_1 = null;
      }
      const result = await Store.dispatch(
        //idがある場合は更新、ない場合は新規
        hasId ? `ScheduleCrane/update` : `ScheduleCrane/post`,
        apiParams
      );
      if (result.hasError) {
        this.flagError = !this.flagError;
        return;
      } else {
        if (this.isNewItem) {
          this.$emit("cancel");
        } else {
          this.getItems();
          this.onDetail();
          this.$emit("cancel");
        }
        Store.dispatch("Toast/show", {
          status: 200,
          message: hasId ? "更新しました" : "登録しました",
        });
      }
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid }) {
      this.valid = valid;
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      if(this.isClickCancelBtn && !this.isNewItem) {
        this.isShowConfirmDialog = false;
        this.editable = false;
        this.isClickCancelBtn = false;
        this.formValues = {...this.backData};
      } else {
        this.isShowConfirmDialog = false;
        this.formValues = {};
        this.$emit("cancel");
      }
    },
    closePopup() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
    /**
     * (共通)
     * click button Close (X)
     */
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isShowConfirmDialog = true;
      }
    },
    async getItems() {
      await Store.dispatch(`ScheduleCrane/getScheduleCraneDetail`, this.item.schedule_id);
    },
    /**
     * function copy data last regist
     */
    async onCopy() {
      const result = await Store.dispatch(`ScheduleCrane/getCraneUseLastTime`, this.CURRENT_SITE.field_id);
      if (!result.hasError && result.data.contents.entries) {
        let data = _.cloneDeep(result.data.contents.entries);
        data = {...this.formValues, ...data};
        data['use_date_from']= this.formValues['use_date_from'];
        data['use_date_to']= this.formValues['use_date_to'];
        data.device_usage_times = removeOneDayToEndTime(data.device_usage_times);
        this.formValues = _.cloneDeep(data);
        this.isCopy = true;
      } else {
        const data = _.cloneDeep(INIT_DATA_OBAYASHI);
        data.use_date_from = this.dataInitial.use_date_from;
        data.use_date_to = this.dataInitial.use_date_to;
        data.field_construction_id = this.dataInitial.field_construction_id;
        if (data.field_construction_id == this.formValues.field_construction_id) {
          let listCompanyPartnerLevel1 = [...Store.getters["PortalChart/getListCompanyPartnerLevel1"]];
          data.field_tree_id_1 = listCompanyPartnerLevel1[0].field_tree_id;
        }
        data.work_hour_zone = this.dataInitial.work_hour_zone;
        this.formValues = _.cloneDeep(data);
      }
    },

    async previewPdf(){
      let params = {
        field_id: this.CURRENT_SITE.field_id,
        type: 2
      };
      let rs = await Store.dispatch(`Sites/getFieldRuleDocument`, { params });
      this.fieldDocument = _.cloneDeep(rs.data.contents.entries.field_rule_documents);
      if (this.fieldDocument.document_url) {
        const _params = getParamsOfUrlReadFile(this.fieldDocument.document_url);
        const response = await Store.dispatch("File/readFile", _params);
        if (!response.hasError) {
          this.fieldDocument.document_url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers["content-type"] }));
        }
        let src = pdf.createLoadingTask(this.fieldDocument.document_url);
        await src.promise.then(pdf => {
          this.numPages = pdf.numPages;
        });
      }
      this.isShowPopupPdf = true;
    },

    async getPlanItemSetting() {
      let params = {
        field_id: this.CURRENT_SITE.field_id,
        company_type: 1 // 1: is obayashi
      };
      let rs = await Store.dispatch("Customizes/get", { params });
      this.cranePassagesCustomize = _.cloneDeep(rs.data.contents.entries)?.crane_uses;
      if (this.editable) {
        this.isLoaded = true;
      }
    },
  },
  destroyed() {
    Store.dispatch(`ScheduleCrane/clearDataWorkHourZone`);
  }
};
</script>

<style lang="sass" scoped>
  .loading 
    text-align: center
    position: absolute
    color: #fff
    z-index: 9
    padding: 8px 18px
    border-radius: 5px
    left: calc(50% - 45px)
    top: calc(50% - 18px)

  .from-close-btn 
    float: right
</style>
