<template>
  <div class="dialog">
    <v-card>
      <v-card-title>
        <div class="title color-title">{{ title }}</div>
      </v-card-title>
        <div class="machine-type">{{ text }}</div>
      <v-card-text v-if="sortItemClone">
        <draggable
          v-model="sortItemClone"
          @change="onSortableChange"
        >
          <div v-for="(item, index) in sortItemClone" :key="index" class="item">
            <div class="item-wrap">
              <div class="left-img">
                <img :src="imageMap.get(`image_url${item.id}`)" />
              </div>
              <div class="right-content">
                <p>{{ item.type }}</p>
                <button>
                  <v-icon>mdi-equal</v-icon>
                </button>
              </div>
            </div>
          </div>
        </draggable>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          outlined
          color="primary"
          class="btn"
          @click="close"
        >
          やめる
        </v-btn>
        <v-btn depressed :disabled="disabled" class="btn" color="primary" @click.once="saveOrder">
          登録
        </v-btn>
      </v-card-actions>
    </v-card>
    <Popup width="480px" :dialog="isShowConfirmDialog">
      <ConfirmCloseDialog
        title="フォームを閉じる確認"
        text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
        text2="このページから移動してもよろしいですか？"
        @close="onClickBtnCancelForm"
        @yes="closeForm"
        warning
      />
    </Popup>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import Popup from "@/components/common/Popup.vue";

export default {
  data() {
    return {
      disabled: false,
      timeout: null,
      sortItemClone: null,
      isShowConfirmDialog: false,
    }
  },
  components: {
    draggable,
    ConfirmCloseDialog,
    Popup
  },
  props: {
    text: {
      type: String,
    },
    title: {
      type: String,
    },
    sortItem: {
      type: Array,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    imageMap: Map,
  },
  async mounted() {
    this.$watch(
      () => this.sortItem,
      (item) => {
        this.sortItemClone = [...item];
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  methods: {
    close() {
      this.isShowConfirmDialog = true;
    },
    onSortableChange(data) {
      console.log(data);
    },
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
    },
    closeForm(){
      this.isShowConfirmDialog = false;
      this.$emit('close')
    },
    saveOrder() {
      this.$emit('updateRoot', this.sortItemClone.map((item, index) => {
        return {
          ...item,
          order_number: index
        }
      }))
      this.$emit('close')
    },
  }
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  overflow: hidden;
  .title {
    font-size: 12px;
    color: #1B9c4f;
  }
  .body {
    .text-wrap {
      .text {
        text-align: center;
        margin: auto;
        width: 80%;
      }
    }
  }
  .machine-type{
    margin-left: 50px;
  }
  .item{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    &-wrap{
      height: 45px;
      display: flex;
      align-content: center;
      align-items: center;
      .left-img{
        width: 45px;
        height: 45px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .right-content{
        display: flex;
        align-items: center;
        margin-left: 10px;
        height: 100%;
        overflow: hidden;
        p{
          background: rgb(221, 221, 221);
          width: 230px;
          height: 100%;
          display: flex;
          align-items: center;
          margin: 0!important;
        }
        button{
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
