<template>
  <img :src="fileSrc" alt="" />
</template>
<script>
import { fileCache } from "@/utils/fileCache";
export default {
  data: () => {
    return {
      fileSrc: "/images/noimage-user.png",
    };
  },
  mounted() {
    this.$watch(
      () => this.imageUrl,
      async (newValue) => {
        if (newValue) {
          const data = await fileCache.getFile(newValue);
          this.fileSrc = data ? data : "/images/noimage-user.png";
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  props: {
    imageUrl: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped></style>
