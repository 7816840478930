export const videoStream = async ({
  frameId,
  videoId,
  canvasId,
  detectScale,
  readyCallback,
}) => {
  const WINDOW_WIDTH = window.innerWidth;
  const WINDOW_HEIGHT = window.innerHeight;

  const frame = document.getElementById(frameId);
  const video = document.getElementById(videoId);
  const canvas = document.getElementById(canvasId);

  let isStreamingVideo = false;
  let video_info = {
    x: null,
    y: null,
    width: null,
    height: null,
    rate: null,
    rate_back: null,
  };

  /**
   * カメラの起動
   */
  const initCamera = () => {
    return new Promise((resolved, rejected) => {
      let media = navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: "user",
        },
      });
      media
        .then((stream) => {
          video.muted = true;
          video.playsinline = true;
          video.onloadedmetadata = () => {
            resolved(true);
          };
          video.srcObject = stream;
        })
        .catch((err) => {
          alert(err);
          rejected(false);
        });
    });
  };

  /**
   * videoのサイズを画面のサイズに最適化
   * 画面の高さにvideoの高さを合わせる
   */
  const adjustVideoSize = () => {
    const frameRect = frame.getBoundingClientRect();

    const size = {
      w: frameRect.width,
      h: frameRect.height,
    };

    const left = (size.w - WINDOW_WIDTH) / 2;
    const top = (size.h - WINDOW_HEIGHT) / 2;

    const styles = {
      width: size.w + "px",
      height: size.h + "px",
      left: 0 + "px",
      top: 0 + "px",
    };

    video.width = size.w;
    video.height = size.h;
    video.style.width = styles.width;
    video.style.height = styles.height;
    video.style.left = styles.left;
    video.style.top = styles.top;

    video_info = {
      x: left,
      y: top,
      width: size.w,
      height: size.h,
      shiftleft: -left,
      videoWidth: video.videoWidth,
      videoHeight: video.videoHeight,
      rate: 1,
      rate_back: 1,
    };
  };

  /**
   * 描画canvasのサイズ
   */
  const adjustCanvasSize = () => {
    const frameRect = frame.getBoundingClientRect();
    const rate = video_info.rate_back;
    // //動画を描画するcanvasは動画と同解像度
    canvas.style.width = frameRect.width + "px";
    canvas.style.height = frameRect.height + "px";
    canvas.width = `${Math.floor(frameRect.width * rate * detectScale)}`;
    canvas.height = `${Math.floor(frameRect.height * rate * detectScale)}`;
  };

  /**
   * 動画を描画
   */
  const drawOnCanvas = () => {
    const frameRect = video.getBoundingClientRect();

    //縮小率
    const rate = video_info.rate_back;
    const pasteScale = frameRect.width / video.videoWidth;
    const shitY =
      Math.floor((video.videoHeight * pasteScale - frameRect.height) / 2) * -1;

    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, frameRect.width, frameRect.height);
    ctx.save();
    ctx.scale(pasteScale, pasteScale);

    // 左右反転
    ctx.translate(video.videoWidth * rate * detectScale, 0);
    ctx.scale(-1, 1);

    //動画の切り取り範囲
    const sx = 0;
    const sy = 0;
    const sw = Math.floor(video.videoWidth * rate);
    const sh = Math.floor(video.videoHeight * rate);

    //左右反転に描画
    ctx.drawImage(
      video,
      sx,
      sy,
      sw,
      sh,
      video.videoWidth * rate * detectScale,
      shitY,
      video.videoWidth * rate * detectScale * -1,
      video.videoHeight * rate * detectScale
    );
    ctx.restore();
  };

  const stopVideoStream = () => {
    isStreamingVideo = false;
  };

  const replayVideoStream = () => {
    isStreamingVideo = true;
  };

  const render = () => {
    requestAnimationFrame(render);
    if (isStreamingVideo) {
      drawOnCanvas();
    }
  };

  await initCamera();
  adjustVideoSize();
  adjustCanvasSize();
  readyCallback(video_info);
  isStreamingVideo = true;
  render();

  return {
    initCamera,
    stopVideoStream,
    replayVideoStream,
  };
};
