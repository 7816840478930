<template>
  <v-card class="dialog">
    <v-card-title>
      <div :style="getTitleColorStyle" class="title">{{ title }}</div>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div class="text">{{ text }}</div>
      </div>
    </v-card-text>
    <v-card-text>
      <div class="text">
        <span class="item" v-for="item in items" :key="item.id">
          {{
            item.name
              ? convertName(item.name)
              : convertName(item.inspection_item)
          }}</span
        >
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="warning"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn
        depressed
        :disabled="disabled"
        class="btn"
        color="warning"
        @click="handleConfirm()"
      >
        削除
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON";
export default {
  data() {
    return {
      disabled: false,
      timeout: null,
    };
  },
  components: {},
  props: {
    text: {
      type: String,
    },
    title: {
      type: String,
    },
    items: {
      type: Array,
    },
    warning: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    getTitleColorStyle() {
      return this.warning ? "color:red;" : "";
    },
    getColor() {
      return this.warning ? "warning" : "primary";
    },
  },
  methods: {
    convertName(name) {
      let result = "";
      if (name) {
        result = name;
        if (name.length > 16) {
          result = name.substring(0, 16) + "•••";
        }
      }
      return result;
    },
    handleConfirm() {
      this.disabled = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.disabled = false;
      }, SUBMIT_DELAY_TIMEOUT);
      this.$emit("yes");
    },
  },
  /**
   * Important: clear timeout
   */
  beforeDestroy() {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout);
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .title {
    font-size: 12px;
  }
  .body {
    .text-wrap {
      .text {
        text-align: center;
        margin: auto;
        width: 80%;
      }
    }
  }
  .item {
    &:after {
      content: ",";
    }
    &:last-child {
      &:after {
        content: "";
      }
    }
  }
}
</style>
