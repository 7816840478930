const GATE_INITIAL_ITEM = {
  field_id: null,
  name: "",
  width: "",
  height: "",
  prohibit_start_date: null,
  prohibit_end_date: null,
  device_prohibit_times: [
    {
      prohibit_start_time: null,
      prohibit_end_time: null,
    },
  ],
  memo: "",
  monday_flg: 0,
  tuesday_flg: 0,
  wednesday_flg: 0,
  thursday_flg: 0,
  friday_flg: 0,
  saturday_flg: 0,
  sunday_flg: 0,
  public_holiday_flg: 0,
};

/**
 * (共通)
 * タイトル
 */
const GATE_FORM_TABS = {
  GateFormPage1: { id: 1, title: "基本情報" },
  GateFormPage2: { id: 2, title: "禁止時間帯" },
};

const GATE_TABLE_LABELS = [
  {
    text: "ゲート",
    value: "name",
    align: "left",
    sortable: true,
  },
  {
    text: "幅",
    value: "width",
    align: "left",
    sortable: false,
  },
  {
    text: "高さ",
    value: "height",
    align: "left",
    sortable: false,
  },
];
const GATE_SORT_ITEMS = [
  {
    id: "name",
    name: "ゲート名",
  },
  {
    id: "width",
    name: "幅",
  },
  {
    id: "height",
    name: "高さ",
  },
];
const MINS_ON_HOUR = ["00", "15", "30", "45"];
const getProhibitTimes = (isAll, isEnd) => {
  let prohibitTimes = [];
  let i = 0;
  while (i < 24) {
    for (let m of MINS_ON_HOUR) {
      if (isEnd && i === 0 && m === "00") {
        continue;
      }
      let hour = i < 10 ? "0" + i : i;
      let element = {
        id: hour + ":" + m,
        name: hour + ":" + m,
      };
      prohibitTimes.push(element);
    }
    i++;
  }
  if (isEnd || isAll) {
    prohibitTimes.push({ id: "24:00", name: "24:00" });
  }
  prohibitTimes.unshift({ id: null, name: "" });
  return prohibitTimes;
};

const PROHIBIT_TIME_START = getProhibitTimes(false, false);
const PROHIBIT_TIME_END = getProhibitTimes(false, true);

const SET_TIME_PROHIBIT_FORM_TABS = {
  TimeProhibitFormPage1: { id: 1, title: "禁止時間帯" },
};
export {
  GATE_TABLE_LABELS,
  GATE_SORT_ITEMS,
  GATE_INITIAL_ITEM,
  GATE_FORM_TABS,
  PROHIBIT_TIME_START,
  PROHIBIT_TIME_END,
  SET_TIME_PROHIBIT_FORM_TABS,
};
