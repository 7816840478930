<template>
  <div >
    <div class="cst-sticky">
      <v-row no-gutters>
        <v-col cols="10" sm="10" md="11" class="pa-3 d-flex">
          <Label label="日付" width="20vw">
            <InputDatepicker
              name="work_date"
              :editable="true"
              :flagNull="true"
              :values="searchParams"
              @onInput="onChangeSearchParams"
            />
          </Label>
          <Label label="作業時間帯" width="20vw" class="ml-2">
            <SelectTimeframe
              name="work_hour_zone"
              :constructionId="searchParams.field_construction_id"
              :values="searchParams"
              @onInput="onChangeSearchParams"
            />
          </Label>
          <Label label="工事" width="20vw" class="ml-2">
            <SearchSite
              name="field_construction_id"
              :siteId="CURRENT_SITE.field_id"
              :values="searchParams"
              @onInput="onChangeSearchParams"
            />
          </Label>
          <Label label="協力会社" width="18vw" class="ml-2">
            <SearchCompanyPartner
              name="field_tree_id"
              :constructionId="searchParams.field_construction_id"
              :values="searchParams"
              @onInput="onChangeSearchParams"
            />
          </Label>
        </v-col>
        <v-col cols="1" sm="2" md="1" class="pa-1 pt-11" style="width:18vw">
          <v-btn @click="onSearch" color="primary">検索</v-btn>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>
    <!-- リスト -->
    <slot></slot>
  </div>
</template>

<script>
import { Store } from "@/store/Store";
import Label from "@/components/forms/elements/Label.vue";
import InputDatepicker from "@/components/forms/elements/InputDatepicker";
import SelectTimeframe from "./SelectTimeframe";
import SearchSite from "./SearchSite";
import SearchCompanyPartner from "./SearchCompanyPartner";
import { format } from "date-fns";

export default {
  data() {
    return {
      searchParams: this.searchConditions,
    };
  },
  components: {
    InputDatepicker,
    SelectTimeframe,
    SearchSite,
    SearchCompanyPartner,
    Label,
  },
  props: {
    searchConditions: Object,
  },
  mounted() {
    this.getWorkDateFirstLoad();
  },
  computed: {
    /**
     * (スケジュール)
     * 現在の現場を取得
     */
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },
  methods: {
    getWorkDateFirstLoad() {
      const searchParams = { ...this.searchParams };
      const _date = new Date();
      _date.setDate(_date.getDate() + 1);
      searchParams.work_date = format(_date, "yyyy/MM/dd");
      this.searchParams = searchParams;
      this.$emit("formUpdate", searchParams);
    },
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.$emit("formUpdate", searchParams);
    },
    onSearch() {
      this.$emit('onSearch');
    }
  },
};
</script>
<style lang="scss" scoped>
</style>
