<template>
  <v-container :style="`height:${tableHeight - 150}px; overflow-y: auto;`">
    <RelateDataTable
      :items="items"
      :tableLabels="tableLabels"
      :type_id="type_id"
      :isSelected="isSelected"
      :checkSelectedParents="checkSelectedParents"
      :flagSearch="flagSearch"
      :isSelectedParent="isSelectedParent"
      :isCheckedAll="isCheckedAll"
      :updateCheckbox="updateCheckbox"
      :updateAllCheckbox="updateAllCheckbox"
    />
  </v-container>
</template>
<script>
import RelateDataTable from "./RelateDataTable";
import _ from "lodash";

export default {
  data: () => {
    return {
      selectedParent: []
    };
  },
  components: {
    RelateDataTable
  },

  mounted() {
    this.$watch(
      () => this.flag,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          this.selectedParent = [];
        }
      },
      {
        deep: true
      }
    );
  },

  props: {
    flagSearch : Boolean,
    flag : Boolean,
    type_id: String,
    items: {
      type: Array
    },
    tableHeight: {
      type: Number
    },
    tableLabels: {
      type: Array
    },
    functions: {
      type: Object
    },
    isSelected: Function,
    checkSelectedParents: Function,
    openItemForm: Function
  },

  methods: {
    /**
     * check parent in list selectedParent
     * @param id : id parent
     * @returns true : in , false : not in
     */
    isSelectedParent(id) {
      return this.selectedParent.includes(id);
    },
    /**
     * check all
     * @returns true : checked , false : unchecked
     */
    isCheckedAll() {
      let _items = _.cloneDeep(this.items);
      let rs = true;
      _items.forEach(e => {
        if(!this.checkSelectedParents(e,this.type_id)){
          rs = false;
          return;
        }
      })
      return rs && _items.length > 0;
    },
    /**
     * update checkbox to list selected
     */
    updateCheckbox(type_id, item, isChecked, isParent, itemParent) {
      // parent checked
      if (isParent && item.childrens) {
        this.updateSelectedParentItems(item.machine_company_type_item_tree_id, isChecked);
        item.childrens.forEach((child) => {
          this.$emit("update", item.machine_company_type_item_tree_id, item.type, isChecked, type_id, child);
        });
      } else {
        // childがchecked
        this.$emit("update", itemParent.machine_company_type_item_tree_id, itemParent.type, isChecked, type_id, item);
        if (itemParent && isChecked && this.checkSelectedParents(itemParent, type_id, item.index)) {
          this.selectedParent.push(itemParent.machine_company_type_item_tree_id);
        } else {
          this.selectedParent = this.selectedParent.filter((item) => {
            return item != itemParent.machine_company_type_item_tree_id;
          });
        }
      }
    },
    /**
     * update check all
     * @param isChecked : unchecked or checked
     */
    updateAllCheckbox(isChecked) {
      let allData = _.cloneDeep(this.items);
      allData.forEach(item_parent => {
        this.updateSelectedParentItems(item_parent.machine_company_type_item_tree_id, isChecked);
        item_parent.childrens.forEach((child) => {
          this.$emit("update", item_parent.machine_company_type_item_tree_id, item_parent.type, isChecked, this.type_id, child);
        });
      });
    },
    /**
     * update selected parent to list selectedParent
     * @param machine_company_type_item_tree_id : id parents
     * @param isChecked : checked or unchecked
     */
    updateSelectedParentItems(machine_company_type_item_tree_id, isChecked) {
      let _selectedItems = [];
      // checked
      if (isChecked) {
        _selectedItems = [...this.selectedParent, machine_company_type_item_tree_id];
      } else {
        // unchecked
        _selectedItems = this.selectedParent.filter((item) => {
          return item != machine_company_type_item_tree_id;
        });
      }
      // 重複削除してset
      this.selectedParent = [...new Set(_selectedItems)];
    }
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight - 45}px;`;
      return style;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
</style>
