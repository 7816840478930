const MACHINE_INITAL_ITEM = {
  cmn_mst_machine_type_id: null,
  name: "",
  company_id: null,
  maximum_work_floor_height: null,
  movable_load: null,
  body_length: null,
  body_width: null,
  body_height: null,
  vehicle_weight: null,
  outrigger_overhang_dimensions: null,
  bucket_volume: null,
  prohibit_start_date: null,
  prohibit_end_date: null,
  device_keyplan_images: [
    {
      id: null,
      image: null,
      image_url: null,
    },
  ],
  device_licences: [
    {
      type: 3,
      catergory_id: null,
      sub_catergory: "",
      cmn_mst_licence_id: null,
    },
  ],
  device_sp_trainings: [
    {
      type: 3,
      cmn_mst_sp_training_id: null,
    },
  ],
  device_prohibit_times: [
    {
      type: 3,
      prohibit_start_time: null,
      prohibit_end_time: null,
    },
  ],
  memo: "",
  monday_flg: 0,
  tuesday_flg: 0,
  wednesday_flg: 0,
  thursday_flg: 0,
  friday_flg: 0,
  saturday_flg: 0,
  sunday_flg: 0,
  public_holiday_flg: 0,
};

/**
 * (共通)
 * タイトル
 */
const TITLE_FORM_CREATE_EDIT = {
  NEW: "機材情報",
  EDIT: "機材情報",
};

/**
 * フォームとタブの設定
 */
const FORMS_CREATE_EDIT = {
  MachineFormPage1: { id: 1, title: "基本情報" },
  MachineFormPage2: { id: 2, title: "性能" },
  MachineFormPage3: { id: 3, title: "禁止時間帯" },
};

// テーブル設定
const MACHINE_TABLE_LABELS = [
  {
    text: "種別",
    value: "type",
    align: "left",
  },
  {
    text: "機材",
    value: "name",
    align: "left",
  },
  {
    text: "作業床高さ",
    value: "maximum_work_floor_height",
    align: "left",
  },
  {
    text: "積載荷重",
    value: "movable_load",
    align: "left",
  },
  {
    text: "車体全長",
    value: "body_length",
    align: "left",
  },
  {
    text: "車体全幅",
    value: "body_width",
    align: "left",
  },
  {
    text: "車体全高",
    value: "body_height",
    align: "left",
  },
  {
    text: "管理会社",
    value: "company_name",
    align: "left",
  },
];

const MACHINE_SORT_ITEMS = [
  { id: "type", name: "種別" },
  { id: "name", name: "機材名" },
  { id: "maximum_work_floor_height", name: "作業床高さ" },
  { id: "movable_load", name: "積載荷重" },
  { id: "body_length", name: "車体全長" },
  { id: "body_width", name: "車体全幅" },
  { id: "body_height", name: "車体全高" },
];

const MACHINE_TYPE_ARRAY = [
  { id: 1, name: "車輌系建設機械" },
  { id: 2, name: "フォークリフト" },
  { id: 3, name: "高所作業車" },
  { id: 4, name: "その他" },
];

const MINS_ON_HOUR = ["00", "15", "30", "45"];
const getProhibitTimes = (isAll, isEnd) => {
  let prohibitTimes = [];
  let i = 0;
  while (i < 24) {
    for (let m of MINS_ON_HOUR) {
      if (isEnd && i === 0 && m === "00") {
        continue;
      }
      let hour = i < 10 ? "0" + i : i;
      let element = {
        id: hour + ":" + m,
        name: hour + ":" + m,
      };
      prohibitTimes.push(element);
    }
    i++;
  }
  if (isEnd || isAll) {
    prohibitTimes.push({ id: "24:00", name: "24:00" });
  }
  return prohibitTimes;
};

const PROHIBIT_TIME_START = getProhibitTimes(false, false);
const PROHIBIT_TIME_END = getProhibitTimes(false, true);

export {
  MACHINE_INITAL_ITEM,
  MACHINE_TABLE_LABELS,
  MACHINE_SORT_ITEMS,
  MACHINE_TYPE_ARRAY,
  PROHIBIT_TIME_START,
  PROHIBIT_TIME_END,
  TITLE_FORM_CREATE_EDIT,
  FORMS_CREATE_EDIT,
};
