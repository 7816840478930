<template>
  <v-card class="dialog">
    <v-card-title>
      <div style="color: red;" class="title">{{ title }}</div>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div class="text">{{ text1 }}</div>
      </div>
      <div class="text-wrap">
        <div class="text">{{ text2 }}</div>
      </div>
      <div class="text-wrap">
        <div class="text">{{ text3 }}</div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed outlined class="btn" color="warning" @click="$emit('close')"> やめる </v-btn>
      <v-btn depressed class="btn" color="warning" @click="$emit('yes')">
        閉じる
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    text1: {
      type: String,
    },
    text2: {
      type: String,
    },
    text3: {
      type: String,
    },
    title: {
      type: String,
    },
  },
  data: () => ({}),
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .header {
    .header-title-area {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      max-width: 100%;
    }
  }
  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;
      .text {
        text-align: left !important;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 80%;
      }
    }
  }
  .btn {
    margin-right: 12px;
  }
}
</style>
