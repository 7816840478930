<template>
  <div class="FormMain">
    <moveActionComponent
      :multipleMove="true"
      :dataItems="dataItems"
      :activeSection="activeSection"
      :isNewItem="isNewItem"
      :disabledDoubleNext="disabledDoubleNext"
      :disabledDoublePrev="disabledDoublePrev"
      @prev="moveDown"
      @next="moveUp"
      @loading="setLoading"
    >
    </moveActionComponent>
    <div class="FormMainBody MainBodyMinScreen" id="FormMainBody" :style="getBodyStyle" :class="isNewItem ? 'formAddnew' : 'formEdit'">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <section v-for="(data, index) in dataItems" :key="index" class="fullpage item-full-page">
                <v-row no-gutters class="ml-5 mb-7">
                  <v-col cols="12" sm="6" md="6">
                    <v-list-item>
                      <v-list-item-content>
                        <imageFormMultiple
                          :title="title"
                          :editable="editable"
                          :isNewItem="isNewItem"
                          :formValues="data"
                          :allData="item"
                          :indexImage="index"
                          :countItem="dataItems.length"
                          :type="TYPE.TYPE1"
                          :workPlace="workPlaceArray"
                          :backPreview="backPreview"
                          :recordId="recordId"
                          @updateDataItems="updateDataItems"
                          @formUpdate="formUpdate"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-list-item>
                      <v-list-item-content>
                        <ImageFormRightMultiple
                          :title="titleFormRight"
                          :editable="editable"
                          :formValues="data"
                          :isNewItem="isNewItem"
                          :valid="valid"
                          :allData="item"
                          :indexImage="index"
                          :type="TYPE.TYPE2"
                          :backPreview="backPreview"
                          :recordId="recordId"
                          @onRecovery="onRecovery"
                          @onChangeStatusCode="onChangeStatusCode"
                          @updateStatusRejectAfterRecovery="updateStatusRejectAfterRecovery"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" v-if="(dataItems.length - 1) === index">
                    <div
                      class="control-mac-add"
                      v-if="(editable && isNewItem) || (editable && (ROLE_MAIN_USER_EDIT || ROLE_SUBCONSTRACTOR_USER_EDIT))">
                      <span v-if="editable" class="d-flex align-center flex-column justify-center pr-15">
                        <span style="font-size: 12px;">指摘を追加</span>
                        <v-icon @click="addEmpty" color="primary">mdi-plus-circle</v-icon>
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </section>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
      <Popup width="480px" :dialog="popups.isShowConfirmDialog">
        <ConfirmDialog
          :dialogText="DIALOG_APPROVE_TEXT"
          :isApproved="true"
          @close="popups.isShowConfirmDialog = false"
          @yes="popups.isShowConfirmDialog = false"
          :warning="false"
        />
      </Popup>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "@/components/forms/elements/Label.vue";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import Select from "@/components/forms/elements/Select.vue";
import SwitchInput from "@/components/forms/elements/SwitchInput";
import RadioGroup from "@/components/forms/elements/RadioGroup";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import InputTextArea from "@/components/forms/elements/InputTextArea";
import Popup from "@/components/common/Popup";
import ImageFormMultiple from "@/components/forms/schedule/patrols/components/ImageFormMultiple.vue";
import ImageFormRightMultiple from "@/components/forms/schedule/patrols/components/ImageFormRightMultiple.vue";
import SearchDetectUser from "@/components/forms/schedule/patrols/components/SearchDetectUser.vue";
import { URGENCY_CLASSIFICATION_RADIO, TYPE,DIALOG_APPROVE_TEXT } from "@/constants/PATROL_RECORD";
import ConfirmDialog from "@/views/schedule/patrols/components/ConfirmDialog.vue";
import moveActionComponent from "@/components/forms/schedule/patrols/PaltrolForm/moveActionComponent"


const INITIAL_DATA = {
  field_item_tree_id: null,
  pointing_out_detail: null,
  safety_diary_images: [],
  form_output_flag: 1,
  recovery_date: null,
  recovery_user_name: null,
  recovery_content: null,
  status_code: 0,
  recovery_flag: 0
};

export default {
  data: () => {
    return {
      DIALOG_APPROVE_TEXT,
      TYPE,
      formValues: {},
      safetyDiaryCommentCorrectionsValues: {},
      max: new Date().toISOString().slice(0, 10),
      WORK_PLACE_ARRAY: [],
      URGENCY_CLASSIFICATION_RADIO,
      popups: {
        isShowConfirmDialog : false
      },
      title: '指摘',
      titleFormRight: '是正',
      dataItems: [],
      workPlaceArray: [{ id: null, item_name: "" }],
      name: "safety_diary_comment_corrections",
      isEdit: false,
      inMove: false,
      inMoveDelay: 400,
      activeSection: 0,
      offsets: [],
      touchStartY: 0,
    };
  },
  components: {
    InputText,
    SectionLabel,
    RadioGroup,
    Label,
    InputDatepicker,
    Select,
    SwitchInput,
    InputTextArea,
    Popup,
    SearchDetectUser,
    ConfirmDialog,
    ImageFormMultiple,
    ImageFormRightMultiple,
    moveActionComponent,
  },
  props: {
    item: Object,
    mainHeight: {
      type: Number,
      default: 0
    },
    isNewItem : Boolean,
    editable : Boolean,
    valid : Boolean,
    backPreview: Boolean,
    isShowMove: Boolean,
    recordId: Number,
    onlyMoveTypeOne: Boolean,
    disabledDoubleNext: Boolean,
    disabledDoublePrev: Boolean,
  },
  mounted() {
    let elements = document.getElementById("FormMainBody");
    this.calculateSectionOffsets();
    elements.addEventListener('mousewheel', this.handleMouseWheel, { passive: false });
    elements.addEventListener('touchstart', this.touchStart, { passive: false });
    elements.addEventListener('touchmove', this.touchMove, { passive: false });
    this.getUserRole();
    this.$watch(
      () => this.item,
      (data) => {
        this.formValues = { ...data };
        this.calculateSectionOffsets();
        if (Array.isArray(data.safety_diary_comment_corrections)) {
          this.safetyDiaryCommentCorrectionsValues = data.safety_diary_comment_corrections[0];
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => Store.getters["PortalChart/getListItemTrees"],
      (data) => {
        this.WORK_PLACE_ARRAY = [this.getDefaultArrays("id", "item_name"), ...data];
      },
      {
        immediate: true,
        deep: true
      }
    );
    
    this.$watch(
      () => Store.getters["PortalChart/getListGroup"],
      (data) => {
        if (data && !this.popups.isShowChooseGroupNotification) {
          this.GROUP_NOTIFICATIONS_ARRAY = [this.getDefaultArrays("id", "group_name"), ...data];
        }
      },
      {
        deep: true
      }
    );
    
    this.$watch(
      () => this.formValues.safety_diary_comment_corrections,
      (newValue) => {
        if (Array.isArray(newValue)) {
          this.dataItems = [...newValue];
          if (this.dataItems.length == 0) {
            this.dataItems.push({ ...INITIAL_DATA });
            this.dataItems.push({ ...INITIAL_DATA });
            this.dataItems.push({ ...INITIAL_DATA });
            this.dataItems.push({ ...INITIAL_DATA });
            if (this.isNewItem) {
              this.item.safety_diary_comment_corrections.push({ ...INITIAL_DATA });
              this.item.safety_diary_comment_corrections.push({ ...INITIAL_DATA });
              this.item.safety_diary_comment_corrections.push({ ...INITIAL_DATA });
              this.item.safety_diary_comment_corrections.push({ ...INITIAL_DATA });
            }
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => this.formValues.safety_diaries.field_construction_id,
      (newValue, oldValue) => {
        if (newValue && JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.getWorkPlaces(newValue);
        }
        if (!newValue) {
          this.workPlaceArray = [this.getDefaultArrays("id", "item_name")];
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => Store.getters["PortalChart/getListItemTrees"],
      (data) => {
        this.workPlaceArray = [this.getDefaultArrays("id", "item_name"), ...data];
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => Store.getters["Action/getActionSection"],
      (data) => {
        this.activeSection = data;
        this.inMove = true;
        this.scrollToSection(data);
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = '';
      if (this.dataItems.length === 1) {
        style = `overflow: hidden;`;
      }
      if (screen.width <= 1440) {
        style += `height: 76.5vh;`;
        return style;
      }
      if (screen.width >= 1440 && screen.width <= 1600) {
        style += `height: 76vh;`;
        return style;
      }
      style = `height:78.5vh;`;
      return style;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    USER_ROLE(){
      return Store.getters["Sites/getRole"];
    },
    USER_LOGIN() {
      let user = Store.getters["Login/getUser"];
      return `${user.name_sei} ${user.name_mei}`;
    },
    ROLE_MAIN_USER_EDIT() {
      let userRole = Store.getters["Report/getUserRole"];
      return (!this.isNewItem && userRole?.role === 1)
    },
    ROLE_SUBCONSTRACTOR_USER_EDIT() {
      let userRole = Store.getters["Report/getUserRole"];
      let companyUser = JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user?.company_id;
      let fieldTreeCompanyId = this.item?.safety_diaries?.field_tree_company_id;
      return (
        !this.isNewItem &&
        userRole?.role !== 1 &&
        fieldTreeCompanyId === companyUser
      );
    }
  },
  methods: {
    async getItems(id) {
      if (id) {
        await Store.dispatch(`PatrolRecord/getDetail`, id);
      }
    },
    async getUserRole() {
      await Store.dispatch(`Report/getUserRole`, this.CURRENT_SITE.field_id);
    },
    async getWorkPlaces(constructionId) {
      if (constructionId) {
        let params = {
          field_id: this.CURRENT_SITE.field_id,
          field_construction_id: constructionId
        };
        await Store.dispatch("PortalChart/getListItemTrees", { params });
      } else {
        this.WORK_PLACE_ARRAY = [this.getDefaultArrays("id", "item_name")];
      }
    },
    getDefaultArrays(id, value) {
      let results = {};
      results[id] = null;
      results[value] = "";
      return results;
    },
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      if (name === "patrol_record_name") {
        formValues["safety_diaries"][name] = value;
      } else {
        formValues["safety_diary_comment_corrections"] = value;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    getColor(recovery) {
      return recovery === 0 ? "warning" : "primary";
    },
    getText(recovery) {
      return recovery === 0 ? "未是正" : "是正済";
    },
    onReject(){
      this.$emit("onChangeStatusCode",false);
    },
    onRecovery(index) {
      this.$emit("onRecovery", index);
    },
    onChangeStatusCode(flag, index, id) {
      this.$emit("onChangeStatusCode", flag, index, id);
    },
    async updateStatusRejectAfterRecovery(message, flgEdit, index, id){
      let temp = {
        safety_diary_ids: [this.formValues?.safety_diaries?.id],
        safety_diary_comment_correction_ids: [id],
        message_reject: message,
        flg_edit_message: flgEdit,
      };
      let params = {...temp};
      const result = await Store.dispatch(`PatrolRecord/updateStatusReject`, params);
      if (!result.hasError) {
        await this.getItems(this.recordId);
        Store.dispatch("Toast/show", {
          status: 200,
          message: flgEdit ? "否認通知の修正をしました。" : "差戻しました。"
        });
        this.formValues.safety_diary_comment_corrections[index].status_code = 2;
        this.formValues.safety_diary_comment_corrections[index].recovery_flag = 1;
        this.formValues.safety_diary_comment_corrections[index].message_reject = message;
        this.$emit("formUpdate", this.formValues);
        this.$emit("actionSuccess", false);
        this.popups.isShowConfirmRejectDialog = false;
      }
    },
    updateDataItems(item) {
      this.dataItems = item;
    },
    formUpdate(formValues) {
      this.$emit("formUpdate", formValues);
    },
    addEmpty() {
      let _items = [...this.dataItems];
      let temp = { ...INITIAL_DATA };
      _items.push(temp);
      this.onInput({ name: this.name, value: _items });
      this.calculateHeightSection();
      setTimeout(() => {
        this.inMove = true;
        this.scrollToSection(this.activeSection + 1);
      }, 500)
    },
    /**
     * Calcaulates the absolute offsets of each section on the page and pushs it into the offsets array
     */
    calculateSectionOffsets() {
      this.offsets = [];
      setTimeout(() => {
        let elements = document.getElementById("FormMainBody");
        let sections = document.getElementsByClassName('item-full-page');
        let length = sections.length;
        elements?.addEventListener("mouseup", this.handleScroll, {passive: false});
        for(let i = 0; i < length; i++) {
          let sectionOffset = sections[i].offsetTop;
          if (!this.offsets.includes(sectionOffset)) {
            this.offsets.push(sectionOffset);
          }
        }
      }, 1000)
    },
    calculateHeightSection() {
      let elements = document.getElementById("FormMainBody");
      let sections = document.getElementsByClassName('item-full-page');
      let length = sections.length;
      elements.addEventListener("mouseup", this.handleScroll, {passive: false});
      for(let i = 0; i < length; i++) {
        let sectionOffset = sections[i].offsetTop;
        this.offsets.push(sectionOffset);
      }
    },
    /**
     * Handle the 'mousewheel' event for other browsers
     */
    handleMouseWheel: function(e) {
      if (e.wheelDelta < 0 && !this.inMove) {
        this.moveUp();
      } else if (e.wheelDelta > 0 && !this.inMove) {
        this.moveDown();
      }
      e.preventDefault();
      return false;
    },
    /**
     * Handle the 'mousewheel' event for other browsers
     */
    handleScroll: function() {
      let elements = document.getElementById("FormMainBody");
    
      let sections = document.getElementsByClassName('item-full-page');
      let length = sections.length;
      const sectionHeight = elements.scrollHeight/length;
      this.activeSection = parseInt(((elements.scrollTop + sectionHeight/2)/sectionHeight));
      Store.dispatch(`Action/setActionSection`, this.activeSection);
      elements.scrollTo(200, sectionHeight * this.activeSection);
    },
    /**
     * Move to the previous section or the last section if you're on the first section
     */
    moveDown() {
      if (this.activeSection === 0) return;
      this.inMove = true;
      this.activeSection--;
      if(this.activeSection < 0)  {
        this.inMove = false;
        return;
      }
    
      this.scrollToSection(this.activeSection);
    },
    /**
     * Move to the next section or the first section if you're on the last section
     */
    moveUp() {
      if (this.activeSection === (this.dataItems.length - 1)) return;
      this.inMove = true;
      this.activeSection++;
      if(this.activeSection > this.offsets.length - 1) {
        this.inMove = false;
        return;
      }
    
      this.scrollToSection(this.activeSection);
    },
    /**
     * Scrolls to the passed section id if the section exists and the delay is over
     */
    scrollToSection(id) {
      if(!this.inMove) return false;
    
      this.activeSection = id;
      Store.dispatch(`Action/setActionSection`, this.activeSection);
      this.inMove = true;
      // get section and scroll into view if it exists
      let section = document.getElementsByClassName('item-full-page')[id];
      if(section) {
        document.getElementsByClassName('item-full-page')[id].scrollIntoView({behavior: 'smooth'});
      }
    
      setTimeout(() => {
        this.inMove = false;
      }, this.inMoveDelay);
    
    },
    /**
     * Handles the 'touchstart' event on mobile devices
     */
    touchStart(e) {
      e.preventDefault();
    
      this.touchStartY = e.touches[0].clientY;
    },
    /**
     * Handles the 'touchmove' event on mobile devices
     */
    touchMove(e) {
      if(this.inMove) return false;
      e.preventDefault();
    
      const currentY = e.touches[0].clientY;
    
      if(this.touchStartY < currentY) {
        this.moveDown();
      } else {
        this.moveUp();
      }
    
      this.touchStartY = 0;
      return false;
    },
    setLoading(flg) {
      this.$emit('loading', flg);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../../../style/forms.scss";

.select {
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.select_wrap {
  width: 100%;
  pointer-events: none;
}

::v-deep .v-chip .v-chip__content {
  height: 100%;
  max-width: 6vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
::v-deep .v-text-field--filled.v-input--dense.v-text-field--single-line .v-label, .v-text-field--full-width.v-input--dense.v-text-field--single-line .v-label {
  color: black !important;
}

::v-deep .v-select.v-input--dense .v-chip {
  background: #1B9C4F;
  color: white;
}

::v-deep .labelWrapper .label {
  white-space: nowrap !important;
}

.FormMain {
  position: relative;
}

.fullpage {
  height: 77.5vh;
  width: 100%;
}

.control-mac-add {
  margin-top: -15px !important;
}
@media (max-width: 1600px) and (min-width:1450px) {
  .fullpage {
    width: 100%;
  }
  .control-mac-add {
    margin-top: -48px !important;
  }
}
@media (max-width:1450px) {
  .fullpage {
    width: 100%;
  }
  .control-mac-add {
    margin-top: -30px !important;
  }
  .MainBodyMinScreen {
    height: 75vh !important;
  }
}
</style>
