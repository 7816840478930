<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row no-gutters>
          <v-col cols="12" sm="11" md="11">
            <v-list-item>
              <v-list-item-content>
                <Label label="安全指示事項" :editable="editable">
                  <Select
                    name="cmn_mst_safety_instruction_id"
                    :values="item"
                    :editable="editable"
                    :items="SAFETY_INSTRUCTION_ARRAY"
                    item_text="safety_instruction"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
           </v-list-item>
          </v-col>
          <v-col cols="12" sm="1" md="1" class="pt-0 pb-0">
            <v-btn
              v-if="index >= row && editable"
              class="mt-8"
              icon
              @click="remove(index)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn v-if="editable" depressed small color="primary" @click="addEmpty()">追加</v-btn>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "../../../elements/Label.vue";
import Select from "../../../elements/Select.vue";
import { Store } from "@/store/Store.js";

//初期値を設定します
const INITIAL_VALUES = {
  cmn_mst_safety_instruction_id: null,
};

export default {
  data: () => {
    return {
      items: [],
      SAFETY_INSTRUCTION_ARRAY: [{id: null, safety_instruction: ""},...Store.state.CmnMst.constants.entries.cmn_mst_safety_instructions],
    };
  },
  components: {
    Label,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    row: {
      type: Number,
      default: 1,
    },
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (this.items?.length == 0) {
          this.addEmpty();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(index) {
      const items = this.items.filter((item, i) => {
        return index != i;
      });
      this.$emit("onInput", { name: this.name, value: items });
    },
    addEmpty() {
      let _items = [...this.items];
      _items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: _items });
    },
  },
};
</script>
