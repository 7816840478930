<script>
import { Doughnut } from "vue-chartjs";
import Chart from "chart.js";

const options = {
  responsive: true,
  legend: {
    position: "top",
    labels: {
      fontSize: 6,
    },
  },
  maintainAspectRatio: false,
  animation: {
    animateRotate: false,
  },
};

const ChartConfig = {
  backgroundColor: ["#1b9c4f", "#ff7b52"],
};

export default {
  extends: Doughnut,
  props: ["values", "chartId", "labels", "totalCenter", "isNoData"],
  mounted() {
    this.$watch(
      () => this.values,
      (newValues, oldValues) => {
        if (JSON.stringify(newValues) !== JSON.stringify(oldValues)) {
          this.$nextTick(() => {
            this.drawChart();
          });
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    drawChart() {
      this.addPlugin({
        afterDraw(chart) {
          let ctx = chart.ctx;
          chart.data.datasets.forEach((dataset, i) => {
            let meta = chart.getDatasetMeta(i);
            chart.tooltip._options.enabled = false;
            if (!meta.hidden) {
              meta.data.forEach(function (element, index) {
                //設定
                ctx.fillStyle = "white";
                ctx.font = Chart.helpers.fontString(12);
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                let position = element.tooltipPosition();
                ctx.fillText(dataset.data[index] <= 0 ? "" : dataset.data[index], position.x, position.y);
                // 凡例を非表示
                let legend = chart.legend;
                legend.top = -100;
                // legend.top = chart.height - legend.height / 2 - legend.top / 2;
              });
              ctx.fillStyle = "black";
              if(dataset.label) ctx.fillText(dataset.label, 10.0, 10.0);
              if(dataset.totalCenter > 0 && !this.isNoData){
                let width = chart.width;
                let height = chart.height;
                let positionX = height / 2;
                let positionY = Math.round((width / 2)) + 5;
                ctx.fillText(dataset.totalCenter, positionX, positionY);
              }
            }
          });
        },
      });
      const chartData = {
        datasets: [
          {
            label: this.labels,
            data: this.values,
            backgroundColor: this.isNoData ? ["#C4C4C4", "#C4C4C4"]: ChartConfig.backgroundColor,
            totalCenter: this.totalCenter,
            borderWidth: 0,
          },
        ],
      };
      this.renderChart(chartData, "", options);
    },
  },
};
</script>
