<template>
  <v-card class="dialog">
    <div class="header sticky">
      <v-card-title small>
        <div class="title">{{ title }}</div>
        <v-spacer></v-spacer>
        <v-btn small icon text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
    </div>
    <!-- 検索コンポーネント -->
    <SearchList
      :formValues="formValues"
      @search="search"
      @formUpdate="formUpdate"
      searchLabel="氏名"
    >
      <div v-if="isNoDataMessage" style="text-align: center">
        {{ NO_DATA_MESSAGE }}
      </div>
      <v-list two-line style="max-height: 600px; overflow-y: auto;" id="infinite-user-list">
        <template v-for="item in formValues.items">
          <v-list-item :key="item.id" ripple>
            <v-list-item-content @click="onClick(item)">
              <v-list-item-title class="field_name">{{
                `${item.name_sei} ${item.name_mei}`
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                item.name_kana
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <transition name="fade">
        <div class="loading" v-show="loading">
          <span class="fa fa-spinner fa-spin"></span>Loading
        </div>
      </transition>
    </SearchList>
  </v-card>
</template>

<script>
import SearchList from "@/components/forms/elements/SearchList";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import { Store } from "@/store/Store.js";

export default {
  components: {
    SearchList,
  },

  props: {
    title: String,
    formValues: {
      type: Object,
      default: {
        // 検索ワード
        search: "",
        // 表示するリスト
        items: [],
        // 選択項目id
        selected: null,
      },
    },
    onSearch: Function,
    close: Function,
    isNoDataMessage: Boolean,
  },

  data: () => {
    return {
      keyword: "",
      NO_DATA_MESSAGE,
      loading: false,
      isReset: false,
    };
  },

  mounted() {
    this.$watch(
      () => Store.getters["Employees/getAllUser"],
      (newValue) => {
        if(newValue?.length == 0) {
          this.isReset = true;
        } else {
          this.isReset = false;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.checkScroll();
  },

  computed: {
    isCallAPI() {
      const paging = Store.getters["Employees/getPaginationUser"];
      return this.formValues.current_page < paging.total && paging.total_item != 0 ;
    }
  },

  methods: {
    // 検索(propsで渡すだけ)
    search() {
      this.$emit("onSearch", { keyword: this.keyword });
    },

    // formデータ更新(propsで渡すだけ)
    formUpdate(values) {
      this.keyword = values.keyword;
    },

    onClick(item) {
      this.$emit("formUpdate", item);
    },
    checkScroll() {
      const listElm = document.getElementById('infinite-user-list');
      listElm.addEventListener('scroll', () => {
        if((listElm.scrollTop + listElm.clientHeight >= (listElm.scrollHeight - 1)) && this.isCallAPI && !this.isReset) {
          this.loadMore();
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        }
      }, true);
    },
    loadMore(){
      this.$emit("onSearch", {isLoadMore: true});
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .header {
    height: 65px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    .header-title-area {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      max-width: 100%;
    }
  }
  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;
      .text {
        text-align: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 80%;
      }
    }
  }
  .btn {
    margin-right: 12px;
  }
}

.field_name {
  font-size: 16px !important;
  font-weight: bold !important;
}
.loading {
  text-align: center;
  position: absolute;
  color: #fff;
  z-index: 9;
  background: gray;
  padding: 8px 18px;
  border-radius: 5px;
  left: calc(50% - 45px);
  top: calc(50% - 18px);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to {
  opacity: 0
}
</style>
