<template>
  <div class="mt-5">
    <v-data-table
      hide-default-header
      :headers="tableLabels"
      v-model="selectedItems"
      :items="dataTable"
      :items-per-page="itemsPerPage"
      :height="tableHeight - 10"
      fixed-header
      hide-default-footer
      disable-sort
      :show-select="true"
      class="elevation-0 v-data-table__wrapper"
      sort-by="updatedAt"
      item-key="id"
      :noDataText="NO_DATA_MESSAGE"
      @click:row="openUpdateDetailForm"
    >
      <template slot="header" :headers="tableLabels">
        <thead>
          <tr>
            <th style="width: 50px"><v-simple-checkbox
              :value="selectedItems.length === dataTable.length && dataTable.length > 0"
              @input="clickCheckAll($event)"
            ></v-simple-checkbox></th>
            <th v-for="(h, index) in tableLabels" :key="h.value" :style="getWidthStyle(index)">
              <div v-for="text in convertTextHeaderArr(h.text)" :key="text">
                <div>{{text}}</div>
              </div>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:[`item.machine_type_extral_machine_name`]="{ item }" label small>
        <div class="cst-overflow-td">{{ item.machine_type }}</div>
        <div class="cst-overflow-td">{{ item.machine_name }}</div>
      </template>
      <template v-slot:[`item.delivery_company_name_extral_machine_number`]="{ item }" label small>
        <div class="cst-overflow-td">{{ item.delivery_company_name }}</div>
        <div class="cst-overflow-td">{{ item.machine_number }}</div>
      </template>
    </v-data-table>

    <Popup v-if="isShowMachineBringForm" :dialog="isShowMachineBringForm">
      <MachineBringForm
        :flagEdit="true"
        @resetItems="resetItems"
        @cancel="closeItemForm"
      />
    </Popup>
  </div>
</template>
<script>
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import Popup from "@/components/common/Popup";
import MachineBringForm from "@/components/forms/device/carryOn/MachineBringForm.vue";
import { Store } from "@/store/Store.js";

export default {
  data() {
    return {
      NO_DATA_MESSAGE,
      dataTable: [],
      selectedItems: [],
      isShowMachineBringForm : false
    };
  },
  components: {
    Popup,
    MachineBringForm
  },
  props: {
    tableLabels: Array,
    items: Array,
    itemsPerPage: Number,
    tableHeight: Number,
    removeItemTab1: Boolean,
  },
  mounted() {
    this.$watch(
      () => this.removeItemTab1,
      (newValue) => {
        if (newValue) {
          this.selectedItems = [];
          this.$emit('resetSelectedItemsTab1');
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.selectedItems,
      (newValue) => {
        this.$emit('selectedForm', newValue);
      },
      {
        immediate: true,
        deep: true,
      }
    );
    
    this.$watch(
      () => this.items,
      (newValue) => {
        this.dataTable = [...newValue];
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    /**
     * set width for column
     */
    getWidthStyle(column) {
      let style = "";
      if (column == 0) {
        style = 'width: 10vw';
      } else if (column == 3) {
        style = 'width: 10vw';
      } else {
        style = 'width: 35vw';
      }
      return style;
    },
    /**
     * Event click checkbox in header table
     */
    clickCheckAll(event) {
      if (event) {
        this.selectedItems = this.dataTable;
      } else {
        this.selectedItems = [];
      }
    },
    /**
     * Convert text header to arrray
     */
    convertTextHeaderArr(textHeader) {
      let textHeaderArr = [];
      if (textHeader.includes(" / ")) {
        textHeaderArr = textHeader.split(" / ");
        textHeaderArr[1] = "/ ".concat(textHeaderArr[1]);
      } else {
        textHeaderArr.push(textHeader);
      }
      return textHeaderArr;
    },
    async openUpdateDetailForm(item){
      await Store.dispatch(`DeviceCarryOns/getMachineFieldDetail`, item.id);
      this.isShowMachineBringForm = true;
    },
    closeItemForm(){
      this.isShowMachineBringForm = false;
    },
    resetItems(){
      this.$emit("resetDataItems");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.cst-overflow-td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mb-cst {
  margin-bottom: -1vh !important;
}
::v-deep .v-data-table .v-data-table__wrapper table {
  table-layout: fixed;
}
::v-deep .v-data-table .v-data-table__wrapper table tbody tr td {
  word-break: break-all;
}
</style>
