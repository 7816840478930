import Vue from "vue";
import Vuex from "vuex";
import { cranes } from "../../../api/modules/cranes";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = cranes;

export const Cranes = {
    namespaced: true,

    state: {
        data: [],
        pagination: {},
        cranes: {},
        listCraneByField: [],
    },

    mutations: {
        SET_DATA(state, payload) {
            state.data = payload;
        },
        SET_PAGINATION(state, payload) {
            state.pagination = payload;
        },
        SET_CRANE(state, payload) {
            state.cranes = payload;
        },
        SET_LIST_CRANE_NAME(state, payload) {
            state.listCraneByField = payload;
        }
    },

    actions: {
        async get({ commit }, payload) {
            const response = await ENTITY.get(payload);
            if (response.hasError) {
                return response;
            }
            const { entries, pagination } = response.data.contents;
            // check long string (under 16)
            for (var i = 0; i < entries.length; i++) {
                let name = entries[i].name;
                let companyName = entries[i].company_name;
                if (name.length > 16) {
                    name = name.substring(0, 16) + "•••";
                    entries[i].name = name;
                }
                if (companyName.length > 16) {
                    companyName = companyName.substring(0, 16) + "•••";
                    entries[i].company_name = companyName;
                }
            }
            commit("SET_DATA", entries);
            commit("SET_PAGINATION", pagination);
            return response;
        },

        async getListCraneByField({ commit }, payload) {
            const response = await ENTITY.getListCraneByField(payload);
            const entries =  response.data.contents.entries;
            commit("SET_LIST_CRANE_NAME", entries);
            return response
        },

        async getDetail({ commit }, payload) {
            const response = await ENTITY.getDetail(payload);
            const entries =  response.data.contents.entries;
            commit("SET_CRANE", entries);
            return response
        },

        async edit(_context, payload) {
            return await ENTITY.edit(payload);
        },

        async add(_context, payload) {
            return await ENTITY.add(payload);
        },

        async delete(_context, payload) {
            return await ENTITY.delete(payload);
        },
    },

    getters: {
        getData: (state) => {
            return state.data;
        },
        getPagination: (state) => {
            return state.pagination;
        },
        getCranes: (state) => {
            return state.cranes;
        },
        getListCraneByField: (state) => {
            return state.listCraneByField;
        }
    },
};
