<template>
  <DefaultLayout>
    <template #mainHeader>
      <!--  (共通) 会社ヘッダー -->
      <SitePortalHeader />
    </template>
    <template #page>
      <div class="chart">
        <v-container class="mt-8 ml-8">
          <v-row>
            <v-col cols="12" sm="3">
              <Select
                name="field_construction_id"
                :values="formValue"
                :items="workTypes"
                :editable="true"
                @onInput="onChangeWorkType"
              />
            </v-col>
          </v-row>
        </v-container>
        <div>
          <!-- 体制図 -->
          <Company
            v-for="item in companies"
            :item="item"
            :key="item.id"
            :level="1"
          />
        </div>
        <!-- 元請け -->
        <Popup :dialog="OwnerFormPopup.isShow">
          <SafetyOwnerForm
            v-bind="OwnerFormPopup"
            :tabCurrent="tabCurrent"
            @cancel="isShowConfirmCloseDialog = true"
            @listCompanyLevel1="getListCompanyLevel1"
            @onCreateReportOwner="onCreateReportOwner"
            :key="resetPdf"
          />
        </Popup>
        <!-- 1次 -->
        <Popup :dialog="FirstCompanyFormPopup.isShow">
          <SafetyFirstCompanyForm
            v-bind="FirstCompanyFormPopup"
            :flagError="flagErrorCompany"
            :flagErrorOther="flagErrorOther"
            :tabCurrent="tabCurrent"
            @cancel="isShowConfirmCloseDialog = true"
            @onSendNotify="onSendNotify"
            @onMessage="onMessage"
            @onCreateReport="onCreateReportCompanyPartner"
            @onClickRejected="onReject"
            @onClickApproved="onApprove"
            @createPartnerDocument="createPartnerDocument"
            :key="resetPdf"
          >
          </SafetyFirstCompanyForm>
        </Popup>
        <!-- 2次以下 -->
        <Popup :dialog="OtherCompanyFormPopup.isShow">
          <SafetyOtherCompanyForm
            v-bind="OtherCompanyFormPopup"
            :flagError="flagErrorCompany"
            :flagErrorOther="flagErrorOther"
            :tabCurrent="tabCurrent"
            @cancel="isShowConfirmCloseDialog = true"
            @onSendNotify="onSendNotify"
            @onCreateReport="onCreateReportOtherCompany"
            @onClickRejected="onReject"
            @onClickApproved="onApproveOtherCompany"
            @onMessage="onMessage"
            @createPartnerDocument="createPartnerDocument"
            :key="resetPdf"
          />
        </Popup>

        <!-- 差し戻し -->
        <Popup width="480px" :dialog="isRejectFormDialogShow">
          <RejectFormDialog
            :chartId="chartId"
            :messages="message"
            :isShowMessages="isShowMessages"
            :disabled="isSubmitted"
            @onCancel="closeRejectFormDialog()"
            @onSubmit="onSendReject"
          />
        </Popup>

        <Popup width="480px" :dialog="isShowConfirmDialog">
          <ConfirmDialog
            :dialogText="dialogReport"
            :disabled="isSubmitted"
            @close="closeConfirmDialog()"
            @yes="handleYes()"
          />
        </Popup>

        <Popup width="480px" :dialog="isShowConfirmCloseDialog">
          <ConfirmCloseDialog
            title="フォームを閉じる確認"
            text1="フォームを閉じますがよろしいですか？"
            text2="入力内容は保存されません。"
            text3="このページから移動してもよろしいですか？"
            @close="isShowConfirmCloseDialog = false"
            @yes="closeRelatedForm"
          />
        </Popup>

        <Popup width="480px" :dialog="isShowPopupSelectCompany">
          <SelectCompanyLevel
            :dataSelect="listCompanyLevel1"
            :flagError="flagError"
            @close="closePopupSelectCompany"
            @createReportOwnerSystemNote="createReportOwnerSystemNote"
          />
        </Popup>
      </div>
      <v-snackbars :messages.sync="notifications" bottom right color="primary">
      </v-snackbars>
    </template>
  </DefaultLayout>
</template>
<script>
import VSnackbars from "v-snackbars";
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader.vue";
import Company from "./components/Company/index.vue";
import SelectCompanyLevel from "./components/SelectCompanyLevel.vue";
import Select from "@/components/forms/elements/Select.vue";
import Popup from "@/components/common/Popup.vue";
import { Store } from "@/store/Store.js";
import SafetyOwnerForm from "@/components/forms/safety/chart/DocumentForm/SafetyOwnerForm.vue";
import SafetyFirstCompanyForm from "@/components/forms/safety/chart/DocumentForm/SafetyFirstCompanyForm.vue";
import SafetyOtherCompanyForm from "@/components/forms/safety/chart/DocumentForm/SafetyOtherCompanyForm.vue";
import {
  COMPANY_LEVEL,
  SAFETY_TEXT,
  SAFETY_DIALOG_DOWNLOAD,
  SAFETY_DIALOG_CREATE_REPORT,
  SAFETY_DIALOG_SEND_REPORT,
  SAFETY_DIALOG_APPROVE_REPORT,
  DOCUMENT_TYPES,
  getTabOwner,
  getTabCompanyFirst, getTabCompanyOrther
} from "@/constants/SAFETY_CHART";
import { SUBMIT_DELAY_TIMEOUT , ROLE_SUPERVISOR} from "@/constants/COMMON";
import RejectFormDialog from "./components/RejectFormDialog.vue";
import { HEADER_MENU_ITEMS_SITE } from "@/constants/GLOBALHEADER";
import ConfirmDialog from "./components/ConfirmDialog.vue";
import ConfirmCloseDialog from "./components/ConfirmCloseDialog.vue";
import { format } from "date-fns";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
const CHOOSE_ALL_COMPNAY_LEVEL1 = "全ての一次協力会社";
const TAB_DOCUMENT_OWNER = getTabOwner();
const TAB_FIRST = getTabCompanyFirst();
const TAB_ORTHER = getTabCompanyOrther();

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: "労務管理" };
    },
  },
  components: {
    SitePortalHeader,
    Company,
    Select,
    Popup,
    DefaultLayout,
    SafetyOwnerForm,
    SafetyFirstCompanyForm,
    SafetyOtherCompanyForm,
    RejectFormDialog,
    ConfirmDialog,
    ConfirmCloseDialog,
    VSnackbars,
    SelectCompanyLevel,
  },
  data: () => {
    return {
      isRejectFormDialogShow: false,
      isShowMessages: false,
      isShowConfirmDialog: false,
      isShowConfirmCloseDialog: false,
      isShowPopupSelectCompany: false,
      OwnerFormPopup: {
        isShow: false,
        companyId: null,
        companyName: null,
        chartId: null,
        formTab: null,
      },
      FirstCompanyFormPopup: {
        isShow: false,
        companyId: null,
        companyName: null,
        chartId: null,
        formTab: null,
      },
      OtherCompanyFormPopup: {
        isShow: false,
        companyId: null,
        companyName: null,
        chartId: null,
        formTab: null,
      },
      flagCreateOwnerReport: {
        tab1: false,
        tab2: false,
        tab3: false,
        tab4: false
      },
      flagCreate: false,
      flagSendNotify: false,
      field_tree_id: null,
      companies: [],
      workTypes: [], //工事選択一覧
      formValue: {
        site_id: JSON.parse(sessionStorage.getItem("GRN_HEADERS")).site,
        field_construction_id: null,
      },
      dialogReport: {
        title: "",
        text: "",
        btnSubmitText: "",
      },
      listCompanyLevel1: [],
      chooseChartId: null,
      chartId: null,
      message: null,
      notifications: [],
      resetPdf: false,
      isSubmitted: false,
      timeout: null,
      flagError: false,
      flagErrorCompany: false,
      flagErrorOther: false,
      documentSelected: {},
      tabCurrent: null,
      isFormFirstCompany: true
    };
  },
  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    Store.dispatch("GlobalHeader/setSiteMenu", {
      menuId: HEADER_MENU_ITEMS_SITE.SAFETY.id,
    });
    /**
     * 選択した協力会社のポップアップを開く
     */
    this.$watch(
      () => [Store.getters["SafetyChart/getSelectedCompany"]],
      (newVal) => {
        if (newVal) {
          console.log("popup", [...newVal]);
          const active = newVal.findIndex((item) => item.companyId);

          //companyIdがみつからない場合
          if (active === -1 || this.isShowConfirmDialog) return;

          const { companyId, companyName, chartId, formTab, companyLevel } =
            newVal[active];

          // Store.dispatch("SafetyChart/getPartnerReports", chartId);
          console.log("popup", active, companyLevel, companyId);

          //協力会社
          const FormPopup = {
            isShow: true,
            companyId,
            companyName,
            chartId,
            formTab,
          };

          // 元請けフォーム
          if (companyLevel === COMPANY_LEVEL.OWNER)
            this.OwnerFormPopup = FormPopup;

          // １次フォーム
          if (companyLevel === COMPANY_LEVEL.FIRST)
            this.FirstCompanyFormPopup = FormPopup;

          // ２次以下フォーム
          if (companyLevel >= COMPANY_LEVEL.OTHER)
            this.OtherCompanyFormPopup = FormPopup;
        }
      },
      {
        deep: true,
      }
    );

    /**
     * 体制図を取得
     */
    this.$watch(
      () => [Store.getters["SafetyChart/getCompanies"]],
      (newVal) => {
        if (newVal) {
          console.log("companies", newVal);
          const companies = [];
          companies.push({ ...newVal[0] });
          this.companies = companies;
        }
      },
      {
        deep: true,
      }
    );

    /**
     * 工事一覧を取得
     */
    this.$watch(
      () => Store.getters["PortalChart/getWorkTypes"],
      (newVal) => {
        if (newVal) {
          this.workTypes = [
            { id: null, name: "" },
            ...newVal
          ];
          this.formValue.field_construction_id = newVal[0].id;
          //体制図を取得
          this.loadCompanies();
        }
      },
      {
        deep: true,
      }
    );

    /**
     * 体制図を取得
     */
    this.$watch(
      () => Store.getters["SafetyChart/getDownloadId"],
      (newVal) => {
        if (newVal) {
          this.onClickDownload(newVal);
        }
      },
      {
        deep: true,
      }
    );
    //Owner Company
    this.$watch(
      () => this.flagCreateOwnerReport,
      async (newVal) => {
        if (newVal) {
          if (newVal.tab1 || newVal.tab2 || newVal.tab3 || newVal.tab4) {
            await Store.dispatch("Notification/getNewNoticeStatus");
            let params = { field_tree_id : this.chooseChartId }
            if (ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT) {
              params["is_oba"] = 1;
            }
            await Store.dispatch("SafetyChart/getPrimeReports", { params });
            this.resetPdf = !this.resetPdf;
            Store.dispatch("Toast/show", {
              status: 200,
              message: "作成しました。",
            });
          } else {
            return;
          }
          this.closeConfirmDialog();
        }
      },
      {
        deep: true,
      }
    );
    //First Company and Orther Company
    this.$watch(
      () => [this.flagCreate, this.flagSendNotify],
      async (newVal) => {
        if (newVal) {
          if (newVal[0] || newVal[1]) {
            await Store.dispatch("Notification/getNewNoticeStatus");
            await Store.dispatch(
              "SafetyChart/getPartnerReports",
              this.chooseChartId
            );
            this.resetPdf = !this.resetPdf;
            Store.dispatch("Toast/show", {
              status: 200,
              message: newVal[1] ? "提出しました。" : "作成しました",
            });
          } else {
            return;
          }
          this.closeConfirmDialog();
        }
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Sites/getRole"],
      (data) => {
        if (data) {
          this.loadWorkType();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  methods: {
    /**
     * @description 工事一覧を取得
     * @param
     * {
     *  site_id:現場id
     * }
     */
    async loadWorkType() {
      const { site_id } = { ...this.formValue };
      await Store.dispatch("PortalChart/getWorkTypes", site_id);
    },

    /**
     * @description 体制図のロード
     */
    async loadCompanies() {
      if (this.formValue["field_construction_id"]) {
        let params = {
          field_construction_id : this.formValue["field_construction_id"]
        };
        let roleMain = Store.getters["Sites/getRole"] == ROLE_SUPERVISOR;
        if (ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT) {
          params["is_oba"] = 1;
          if (roleMain) {
            params.prime_flag = 1;
          }
        }
        await Store.dispatch(
          "SafetyChart/getCompanies",
          { params }
        );
      } else {
        this.companies = [];
      }
    },

    onMessage(message) {
      this.message = message;
      this.isShowMessages = true;
      this.isRejectFormDialogShow = true;
    },

    closeRejectFormDialog() {
      this.message = null;
      this.isShowMessages = false;
      this.isRejectFormDialogShow = false;
    },

    /**
     * 承認
     */
    async onApprove(document) {
      this.setDataApproveDialog(document);
      this.isFormFirstCompany = true;
    },

     /**
     * 承認
     */
    async onApproveOtherCompany(document) {
      this.setDataApproveDialog(document);
      this.isFormFirstCompany = false;
    },

    setDataApproveDialog(document) {
      this.dialogReport = {
        title: SAFETY_DIALOG_APPROVE_REPORT.TITLE,
        text: SAFETY_DIALOG_APPROVE_REPORT.TEXT,
        btnSubmitText: SAFETY_DIALOG_APPROVE_REPORT.BTN_SUBMIT,
      };
      this.chooseChartId = document.id;
      this.tabCurrent = document.tabCurrent;
      this.isShowConfirmDialog = true;
    },

    onReject(document) {
      this.chartId = document.id;
      this.tabCurrent = document.tabCurrent;
      this.isRejectFormDialogShow = true;
    },

    onSendReport(id) {
      this.dialogReport = {
        title: SAFETY_DIALOG_SEND_REPORT.TITLE,
        text: SAFETY_DIALOG_SEND_REPORT.TEXT,
        btnSubmitText: SAFETY_DIALOG_SEND_REPORT.BTN_SUBMIT,
      };
      this.chooseChartId = id;
      this.isShowConfirmDialog = true;
    },

    async onCreateReportCompanyPartner(document) {
      this.chooseChartId = document.id;
      this.tabCurrent = document.tabCreateReport;
      await this.callApiSendReportOfFirstCompany(document.tabCreateReport);
    },

    async onCreateReportOtherCompany(document) {
      this.chooseChartId = document.id;
      this.tabCurrent = document.tabCreateReport;
      await this.callApiSendReportOfOrtherCompany(document.tabCreateReport);
    },

    onSendNotify(document) {
      this.documentSelected = {...document};
      this.dialogReport = {
        title: SAFETY_DIALOG_SEND_REPORT.TITLE,
        text: `${this.documentSelected.created_at}の${document.report_name_jp}を提出します。`,
        btnSubmitText: SAFETY_DIALOG_SEND_REPORT.BTN_SUBMIT,
        hasSendNotify: true
      };
      this.chooseChartId = document.chartId;
      this.tabCurrent = document.tabCurrent;
      this.isShowConfirmDialog = true;
    },

    async handleApprove() {
      const params = {
        field_tree_id: this.chooseChartId,
        form_status_code: 2,
        report_code: this.isFormFirstCompany ? this.getReportCodeFirstCompany() : this.getReportCodeOtherCompany(),
        message: null,
      };
      const response = await Store.dispatch("SafetyChart/updateSatus", params);
      this.closeConfirmDialog();
      if (response.hasError) {
        return;
      } else {
        this.resetPdf = !this.resetPdf;
        await Store.dispatch("SafetyChart/getPartnerReports", this.chooseChartId);
        Store.dispatch("Toast/show", {
          status: 200,
          message: "承認しました。",
        });
      }
    },

    /**
     * (2次請け以下)提出
     */
    async handleSendReportOfFirstCompany() {
      await this.callApiSendReportOfFirstCompany();
    },

    async handleSendReportOfOrtherCompany() {
      await this.callApiSendReportOfOrtherCompany();
    },

    async handleSendNotifyOfFirstCompany() {
      await this.callApiSendNotifyOfFirstCompany();
    },

    onCreateReportOwner(chart) {
      this.dialogReport = {
        title: SAFETY_DIALOG_CREATE_REPORT.TITLE,
        text: SAFETY_DIALOG_CREATE_REPORT.TEXT,
        btnSubmitText: SAFETY_DIALOG_CREATE_REPORT.BTN_SUBMIT,
      };
      this.chooseChartId = chart.id;
      this.tabCurrent = chart.tabCreateReport;
      // if tab documnet gf06
      if (this.tabCurrent == TAB_DOCUMENT_OWNER.TAB_DOC_SYSTEM_NOTE) {
        this.isShowPopupSelectCompany = true;
      } else {
        this.isShowConfirmDialog = true;
      }
    },

    async createReportOwnerSystemNote(company) {
      await this.callApiCreateReport(company);
    },

    async handleCreateReport() {
      await this.callApiCreateReport();
    },

    callApiCreateReport(companyLevel1) {
      const date = new Date();
      const params = {
        field_tree_id: this.chooseChartId,
        target_date: format(date, "yyyy/M/d"),
      };
      switch (this.tabCurrent) {
        // GF 01 -  first tab
        case TAB_DOCUMENT_OWNER.TAB_DOC_SYSTEM_INFO:
          params.report_code = DOCUMENT_TYPES.DocSystemInfo.id.toUpperCase();
          this.createNoticeOfContructionReport(params);
          break;
        // GF 06 - tab 1
        case TAB_DOCUMENT_OWNER.TAB_DOC_SYSTEM_NOTE:
          if (companyLevel1.field_tree_id_1 !== this.chooseChartId && companyLevel1.company_name !== CHOOSE_ALL_COMPNAY_LEVEL1) {
            params.field_tree_id_1 = companyLevel1.field_tree_id_1;
          }
          params.report_code = DOCUMENT_TYPES.DocSystemNote.id.toUpperCase();
          this.createConstructionSystemLedgerReport(params);
          break;
        // GF 07 - tab3
        case TAB_DOCUMENT_OWNER.TAB_DOC_ACCIDENT_PREVENT:
          params.report_code = DOCUMENT_TYPES.DocAccidentPrevent.id.toUpperCase();
          this.createConstructionStructureReport(params);
          break;
        // GF 11 - tab 2
        case TAB_DOCUMENT_OWNER.TAB_DOC_SYSTEM_CONSTRUCTION:
          params.report_code = DOCUMENT_TYPES.DocSystemConstruction.id.toUpperCase();
          this.createConstructionDocSystemReport(params);
          break;
        default:
          break;
      }
    },

    // GF 02, 03, 04, 05, 08, 09, 10
    callApiSendReportOfFirstCompany(tabCreateReport) {
      const date = new Date();
      const params = {
        field_tree_id: this.chooseChartId,
        target_date: format(date, "yyyy/M/d"),
      };
      switch (tabCreateReport) {
        // GF 02
        case TAB_FIRST.TAB_DOC_WORKERS.id:
          params.report_code = DOCUMENT_TYPES.DocWorkers.id.toUpperCase();
          this.createWorkerRosterReport(params);
          break;
        // GF 03
        case TAB_FIRST.TAB_DOC_FOREIGNERS.id:
          params.report_code = DOCUMENT_TYPES.DocForeigners.id.toUpperCase();
          this.createForeignEntryNotificationReport(params);
          break;
        // GF 04
        case TAB_FIRST.TAB_DOC_RECONTRACT.id:
          params.report_code = DOCUMENT_TYPES.DocRecontract.id.toUpperCase();
          this.createReSubcontractNoticeReport(params);
          break;
        // GF 10
        case TAB_FIRST.TAB_DOC_COMMITMENT.id:
          params.report_code = DOCUMENT_TYPES.COMMITMENT.id.toUpperCase();
          this.createCommitment(params);
          break;
        // GF 05
        case TAB_FIRST.TAB_DOC_CONTRACTCHART.id:
          params.report_code = DOCUMENT_TYPES.DocContractChart.id.toUpperCase();
          this.createSubcontractReport(params);
          break;
        // GF 08
        case TAB_FIRST.TAB_DOC_NEWWORKER.id:
          params.report_code = DOCUMENT_TYPES.DocNewWorker.id.toUpperCase();
          this.createEntranceQuestionnaireReport(params);
          break;
        // GF 09
        case TAB_FIRST.TAB_DOC_SENDOUT.id:
          params.report_code = DOCUMENT_TYPES.DocSendOut.id.toUpperCase();
          this.createEntranceEducationDocReport(params);
          break;
        default:
          break;
      }
    },
    // GF 02, 03, 04, 08, 09, 10
    callApiSendReportOfOrtherCompany(tabCreateReport) {
      const date = new Date();
      const params = {
        field_tree_id: this.chooseChartId,
        target_date: format(date, "yyyy/M/d"),
      };

      switch (tabCreateReport) {
        // GF 02
        case TAB_ORTHER.TAB_DOC_WORKERS.id:
          params.report_code = TAB_ORTHER.TAB_DOC_WORKERS.code;
          this.createWorkerRosterReport(params);
          break;
        // GF 03
        case TAB_ORTHER.TAB_DOC_FOREIGNERS.id:
          params.report_code = TAB_ORTHER.TAB_DOC_FOREIGNERS.code;
          this.createForeignEntryNotificationReport(params);
          break;
        // GF 04
        case TAB_ORTHER.TAB_DOC_RECONTRACT.id:
          params.report_code = TAB_ORTHER.TAB_DOC_RECONTRACT.code;
          this.createReSubcontractNoticeReport(params);
          break;
        // GF 10
        case TAB_ORTHER.TAB_DOC_COMMITMENT.id:
          params.report_code = TAB_ORTHER.TAB_DOC_COMMITMENT.code;
          this.createCommitment(params);
          break;
        // GF 08
        case TAB_ORTHER.TAB_DOC_NEWWORKER.id:
          params.report_code = TAB_ORTHER.TAB_DOC_NEWWORKER.code;
          this.createEntranceQuestionnaireReport(params);
          break;
        // GF 09
        case TAB_ORTHER.TAB_DOC_SENDOUT.id:
          params.report_code = TAB_ORTHER.TAB_DOC_SENDOUT.code;
          this.createEntranceEducationDocReport(params);
          break;
        default:
          break;
      }
    },

    async callApiSendNotifyOfFirstCompany() {
      const params = {
        gf_report_id: this.documentSelected.id
      };
      const response = await Store.dispatch("SafetyChart/createNoticeOfCompanyParner", params);
      if(response.hasError) {
        Store.dispatch("Error/show", {
          status: 500,
          message: "ファイルが作成できません。",
        });
      } else {
        this.flagSendNotify = true;
      }
    },

    // GF01 => prime company box
    async createNoticeOfContructionReport(params) {
      const response = await Store.dispatch("SafetyChart/createNoticeOfContructionReport", params);
      if(response.hasError) {
        Store.dispatch("Error/show", {
          status: 500,
          message: "ファイルが作成できません。",
        });
      } else {
        this.flagCreateOwnerReport.tab1 = true;
      }
    },
    // GF02 => partner company box: first + orther
    async createWorkerRosterReport(params) {
      const response = await Store.dispatch("SafetyChart/createWorkerRosterReport", params);
      if(response.hasError) {
        this.flagErrorCompany = !this.flagErrorCompany;
        Store.dispatch("Error/show", {
          status: 500,
          message: "ファイルが作成できません。",
        });
      } else {
        this.flagCreate = true;
      }
    },
    // GF03 => partner company box: first + orther
    async createForeignEntryNotificationReport(params) {
      const response = await Store.dispatch("SafetyChart/createForeignEntryNotificationReport", params);
      if(response.hasError) {
        this.flagErrorCompany = !this.flagErrorCompany;
        Store.dispatch("Error/show", {
          status: 500,
          message: "ファイルが作成できません。",
        });
      } else {
        this.flagCreate = true;
      }
    },
    // GF04 => partner company box: first + orther
    async createReSubcontractNoticeReport(params) {
      const response = await Store.dispatch("SafetyChart/createReSubcontractNoticeReport", params);
      if(response.hasError) {
        this.flagErrorCompany = !this.flagErrorCompany;
        Store.dispatch("Error/show", {
          status: 500,
          message: "ファイルが作成できません。",
        });
      } else {
        this.flagCreate = true;
      }
    },
    // GF10 => partner company box: first + orther
    async createCommitment(params) {
      const response = await Store.dispatch("SafetyChart/createCommitment", params);
      if(response.hasError) {
        this.flagErrorCompany = !this.flagErrorCompany;
        Store.dispatch("Error/show", {
          status: 500,
          message: "ファイルが作成できません。",
        });
      } else {
        this.flagCreate = true;
      }
    },
    // GF05 => partner company box: first
    async createSubcontractReport(params) {
      const response = await Store.dispatch("SafetyChart/createSubcontractReport", params);
      if(response.hasError) {
        this.flagErrorCompany = !this.flagErrorCompany;
        Store.dispatch("Error/show", {
          status: 500,
          message: "ファイルが作成できません。",
        });
      } else {
        this.flagCreate = true;
      }
    },
    // GF06 => prime company box
    async createConstructionSystemLedgerReport(params) {
      const response = await Store.dispatch("SafetyChart/createConstructionSystemLedgerReport", params);
      if(response.hasError) {
        this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 500,
          message: "ファイルが作成できません。",
        });
      } else {
        this.flagCreateOwnerReport.tab2 = true;
      }
      this.isShowPopupSelectCompany = false;
    },
    // GF07 => prime company box
    async createConstructionStructureReport(params) {
      const response = await Store.dispatch("SafetyChart/createConstructionStructureReport", params);
      if(response.hasError) {
        Store.dispatch("Error/show", {
          status: 500,
          message: "ファイルが作成できません。",
        });
      } else {
        this.flagCreateOwnerReport.tab3 = true;
      }
    },
    //GF11
    async createConstructionDocSystemReport(params) {
      const response = await Store.dispatch("SafetyChart/createConstructionDocSystemReport", params);
      if (response.hasError) {
        Store.dispatch("Error/show", {
          status: 500,
          message: "ファイルが作成できません。"
        });
      } else {
        this.flagCreateOwnerReport.tab4 = true;
      }
    },
    //GF08 => partner company box: first + other
    async createEntranceQuestionnaireReport(params) {
      const response = await Store.dispatch("SafetyChart/createEntranceQuestionnaireReport", params);
      if(response.hasError) {
        this.flagErrorCompany = !this.flagErrorCompany;
        Store.dispatch("Error/show", {
          status: 500,
          message: "ファイルが作成できません。",
        });
      } else {
        this.flagCreate = true;
      }
    },
    // GF09 => partner company box: first + other
    async createEntranceEducationDocReport(params) {
      const response = await Store.dispatch("SafetyChart/createEntranceEducationDocReport", params);
      if(response.hasError) {
        this.flagErrorCompany = !this.flagErrorCompany;
        Store.dispatch("Error/show", {
          status: 500,
          message: "ファイルが作成できません。",
        });
      } else {
        this.flagCreate = true;
      }
    },


    onClickDownload(id) {
      this.dialogReport = {
        title: SAFETY_DIALOG_DOWNLOAD.TITLE,
        text: SAFETY_DIALOG_DOWNLOAD.TEXT,
        btnSubmitText: SAFETY_DIALOG_DOWNLOAD.BTN_SUBMIT,
      };
      this.chooseChartId = id;
      this.isShowConfirmDialog = true;
    },

    async downloadZip() {
      const result = await Store.dispatch(
        "SafetyChart/generateZip",
        this.chooseChartId
      );
      this.closeConfirmDialog();
      if (!result.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: SAFETY_TEXT.GenerateZipSuccessMessage,
        });
      }
    },

    handleYes() {
      this.isSubmitted = true;
      // Re-enable after submit
      switch (this.dialogReport.title) {
        case SAFETY_DIALOG_DOWNLOAD.TITLE:
          this.timeout = setTimeout(() => {
            this.isSubmitted = false
          }, 1000);
          this.downloadZip();
          break;
        case SAFETY_DIALOG_CREATE_REPORT.TITLE:
          this.timeout = setTimeout(() => {
            this.isSubmitted = false
          }, SUBMIT_DELAY_TIMEOUT);
          this.handleCreateReport();
          break;
        case SAFETY_DIALOG_SEND_REPORT.TITLE:
          this.timeout = setTimeout(() => {
            this.isSubmitted = false
          }, SUBMIT_DELAY_TIMEOUT);
          // confirm send notification
          if (this.dialogReport.hasSendNotify) {
            this.handleSendNotifyOfFirstCompany();
            return;
          }
          if(this.FirstCompanyFormPopup.isShow) {
            this.handleSendReportOfFirstCompany();
          } else {
            this.handleSendReportOfOrtherCompany();
          }
          break;
        case SAFETY_DIALOG_APPROVE_REPORT.TITLE:
          this.timeout = setTimeout(() => {
            this.isSubmitted = false
          }, 1000);
          this.handleApprove();
          break;
        default:
          break;
      }
    },

    closeConfirmDialog() {
      this.isShowConfirmDialog = false;
      if (this.dialogReport.title == SAFETY_DIALOG_DOWNLOAD.TITLE) {
        Store.dispatch("SafetyChart/getDownloadId", null);
      }
      this.$nextTick(() => {
        this.dialogReport = {
          title: "",
          text: "",
          btnSubmitText: "",
        };
        this.flagCreateOwnerReport = {
          tab1: false,
          tab2: false,
          tab3: false,
          tab4: false
        },
        this.flagCreate = false;
        this.flagSendNotify = false;
        this.chooseChartId = null;
        this.documentSelected = {};
      });
    },

    /**
     * 工事変更
     */
    onChangeWorkType({ name, value }) {
      const formValue = { ...this.formValue };
      formValue[name] = value;
      this.formValue = formValue;
      this.loadCompanies();
    },
    /**
     * 会社ポップアプを閉じる
     */
    closeRelatedForm() {
      const param = {
        isShow: false,
      };
      this.OwnerFormPopup = param;
      this.FirstCompanyFormPopup = param;
      this.OtherCompanyFormPopup = param;
      Store.dispatch("SafetyChart/openCompanyBox", {
        companyId: null,
        chartId: null,
        formTab: null,
        companyLevel: null,
      });
      this.tabCurrent = null;
      this.isShowConfirmCloseDialog = false;
      this.loadCompanies();
    },

    /**
     * 差し戻し
     */
    async onSendReject({ field_tree_id, message }) {
      this.isSubmitted = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isSubmitted = false
      }, 1000);

      const params = {
        field_tree_id: field_tree_id,
        form_status_code: 1,
        message: message,
        report_code: this.isFormFirstCompany ? this.getReportCodeFirstCompany() : this.getReportCodeOtherCompany()
      };
      const result = await Store.dispatch("SafetyChart/updateSatus", params);

      this.isRejectFormDialogShow = false;
      this.closeConfirmDialog();
      if (result.hasError) {
        return;
      } else {
        this.resetPdf = !this.resetPdf;
        await Store.dispatch("SafetyChart/getPartnerReports", this.chartId);
        Store.dispatch("Toast/show", {
          status: 200,
          message: "差戻しました",
        });
      }
    },
    /**
     * get report code company level 1 by tab
     */
    getReportCodeFirstCompany() {
      return Object.keys(TAB_FIRST).map((key) =>
        {
          return TAB_FIRST[key];
        }).find((item) => item.id === this.tabCurrent).code;
    },
    /**
     * get report code company level > 1
     */
    getReportCodeOtherCompany() {
      return Object.keys(TAB_ORTHER).map((key) =>
      {
        return TAB_ORTHER[key];
      }).find((item) => item.id === this.tabCurrent).code;
    },
    closePopupSelectCompany() {
      this.dialogReport = {
        title: "",
        text: "",
        btnSubmitText: "",
      };
      this.chooseChartId = null;
      this.isShowPopupSelectCompany = false;
    },
    async createPartnerDocument(file) {
      this.tabCurrent = file.tabCurrent;
      const result = await Store.dispatch("SafetyChart/createPartnerDocument", file);
      if (!result.hasError) {
        this.resetPdf = !this.resetPdf;
        await Store.dispatch("SafetyChart/getPartnerReports", file.field_tree_id);
        Store.dispatch("Toast/show", {
          status: 200,
          message: "送信しました。",
        });
      } else {
        this.flagErrorOther = !this.flagErrorOther;
      }
    },
    getListCompanyLevel1(listCompany) {
      const company = [...listCompany];
      company.push(
        {
          field_tree_id_1: this.OwnerFormPopup?.chartId,
          company_name: "全ての一次協力会社"
        });
      this.listCompanyLevel1 = [...company];
    },
  },

  /**
   * Important: clear timeout
   */
  beforeDestroy () {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout)
  }
};
</script>
<style lang="scss" scoped>
.chart {
  margin-top: 48px;
  padding-bottom: 64px;
  height: 100%;
  overflow: auto;
  position: relative;
}
</style>
