<template>
  <v-card class="dialog">
    <v-card-title>
      <div :style="getTitleColorStyle" class="title">削除確認</div>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div class="text">選択したデータを削除します。</div>
        <div class="text">未承認のデータのみ削除できます。</div>
        <div class="text">この処理を元に戻すことはできません。</div>
        <div class="text">本当に削除してもよろしいですか？</div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="warning"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn
        depressed
        class="btn"
        color="warning"
        :key="flagError"
        @click.once="onSubmit"
      >
        削除
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  components: {},
  props: {
    text: {
      type: String,
    },
    title: {
      type: String,
    },
    flagError: Boolean,
    selectedItems: {
      type: Array,
    },
    warning: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getTitleColorStyle() {
      return this.warning ? "color:red;" : "";
    },
    getColor() {
      return this.warning ? "warning" : "primary";
    },
  },
  methods: {
    async onSubmit() {
      this.$emit('yes');
    }
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .title {
    font-size: 12px;
  }
  .body {
    .text-wrap {
      .text {
        text-align: center;
        margin: auto;
        width: 80%;
      }
    }
  }
  .item {
    &:after {
      content: ",";
    }
    &:last-child {
      &:after {
        content: "";
      }
    }
  }
}
</style>
