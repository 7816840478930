import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
export const IS_OBAYASHI = ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;

export const INVITE_STATUS = {
  INVITED: { id: 1, name: "招待中" },
  LOGINED: { id: 2, name: "ログイン済み" },
};

/**
 * ドロップダウンメニュー
 */
export const MENU_ITEMS = {
  INVITE: { id: 1, name: "招待する" },
  END_WORK: {id: 2, name: "作業終了"},
  REMOVE: { id: 3, name: "解除する" },
  RESTART_WORK: {id: 4, name: "作業再開"},
  MANAGE_USER: {id: 5, name: "管理責任者"},
  GROUP_WORK: {id: 6, name: "作業グループ"},
};

export const MENU_ITEMS_INVITEING = {
  REMOVE: { id: 3, name: "削除する" },
};

/**
 * 体制図の階層
 */
export const COMPANY_LEVEL = {
  OWNER: 1,
  RELATED: 2,
  LATEST: 6
};

/**
 * 元請けフォーム
 */
export const OWNER_FORMS = {
  FormInfo: { id: 1, title: "建設業の許可" },
  FormManagers: { id: 2, title: "現場監督" },
  FormGroups: { id: 3, title: "作業グループ" },
};

/**
 * 協力会社フォーム
 */
export const RELATED_FORMS = {
  FormInfo: { id: 1, title: "建設業の許可" },
  FormConstructionContractInfo: { id: 2, title: "工事請負契約情報" },
  FormWorkers: { id: 3, title: "現場作業員" },
};

/**
 * FIELD_TREE_INITIAL_ITEM
 */
export const FIELD_TREE_INITIAL_ITEM = {
  field_tree: {
    id: 1,
    field_tree_construction_permits: [
      {
        id: 1,
        cmn_mst_construction_permit_ids: [],
        cmn_mst_construction_permit_number_id: null,
        cmn_mst_construction_permit_number_do_id: null,
        construction_permit_type: null,
        expired_year: null,
        permit_number: null,
        renewal_date: null,
      }
    ],
    field_tree_contract_info: {
      field_tree_contracts: {
        id: 1,
        construction_details: "",
        groundbreaking_date: null,
        complete_schedule_date: null,
        contract_date: null,
        supervisor_name: "",
        supervisor_authority_and_request_method: "",
        site_agent_name: "上田　正",
        site_agent_authority_and_request_method: "",
        health_safety_manager_name: "",
        health_safety_promoter_name: "",
        chief_engineer_name: "",
        chief_engineer_fulltime_flag: 1,
        exam_id: null,
        chief_engineer_exam_id: null,
        cmn_mst_qualifications_construction_industry_id: 1,
        foreman_id: null
      },
      field_tree_main_work_contents: [],
      field_tree_safety_instructions:[],
      field_tree_technicians: [
        {
          id: 1,
          field_tree_contract_id: 1,
          technician_name: "",
          exam_id: 1,
          exam: null,
          work_details: ""
        },
      ],
      field_tree_core_technicians: [
        {
          id: 1,
          field_tree_contract_id: 1,
          qualification_status: 1,
          cmn_mst_engineer_job_id: null,
          completion_date: null,
          expiration_date: null,
          confirmation_document: null,
          confirmation_document_url: null
        },
      ]
    },
  },
};

export const CHIEF_ENGINEER_FULLTIME_FLAG = [
  { id: 1, name: "専任", value: 2 },
  { id: 2, name: "非専任", value: 1 },
];
export const QUALIFICATION_STATUS = [
  { id: 1, name: "有", value: 2 },
  { id: 2, name: "無", value: 1 },
];

/**
 * MESSAGE
 */
 export const TEXT_DIALOG = {
  ERROR_TITLE: "エラー",
  CONFIRM_TITLE: "解除確認",
  ERROR_TEXT_1: `以下の理由で解除できませんでした。`,
  ERROR_TEXT_2: `対象の協力会社の直下に紐づく協力会社が存在する/
  解除できるのは直下の協力会社のみ`,
  CONFIRM_TEXT_1: `協力会社を施工体制図から解除します。
  本当に解除してもよろしいですか？`,
  CONFIRM_TEXT_2: `この処理を元に戻すことはできません。`,
  CONFIRM_TITLE_INVITEING : "削除確認",
  CONFIRM_TEXT_1_INVITEING : `選択したデータを削除します。
  本当に削除してもよろしいですか？`,
  CONFIRM_TEXT_2_INVITEING : `この処理を元に戻すことはできません。`,
  TITLE_BTN : "解除",
  TITLE_BTN_INVITEING : "削除"
};

/**
 * DETAIL_USER_PARTNER = {
 */
export const DETAIL_USER_PARTNER = {
  detail_user: {
    field_tree_user_details: {
      id: 1,
      user_id: 1,
      field_tree_user_id: 1,
      education_acceptance_date: null,
      education_implementation_date: null,
      site_admission_time: null,
      site_exit_time: null,
      work_details: "",
      site_agent_flag: 0,
      work_chief_flag: 0,
      female_worker_flag: 0,
      age_under_08_flag: 0,
      lead_engineer_flag: 0,
      foreman_flag: 0,
      health_safety_manager_flag: 0,
      ability_improvement_education_flag: 0,
      risk_recurrence_prevention_education_flag: 0,
      foreigner_technical_intern_flag: 0,
      foreigner_worker_flag: 0,
      foreigner_best_specific_skill_flag: 0,
      required_qualifications_confirmation: 1,
      health_check_confirmation: 0,
      commute_method: null,
      protective_cap_flag: 0,
      safety_shoes_flag: 0,
      safety_belt_flag : 0,
      welding_helmet_flag: 0,
      welding_gloves_flag: 0,
      protective_eyewear_flag: 0,
      safety_and_health_manager_flag: 0,
      mask_with_electric_fan_flag: 0,
      earplug_flag: 0,
      reflective_vest_flag: 0,
    },
    user_experiences: [
      {
        id: 1,
        user_id: 1,
        cmn_mst_skills_id: 1,
        experience: null,
      },
    ],
  },
};

/**
 * 建設業許可番号年
 */
 export const FIELD_TREE_CONSTRUCTION_PERMITS = [
  { id: null, name: "" },
  { id: 1, name: "特定" },
  { id: 2, name: "一般" },
];


export const CONSTRUCTION_PERMIT_EVENT_TARGET = {
  CONSTRUCTION_PERMIT: 'construction_permit'
};

export const FORM_MANAGERS_LABELS = [
  { text: "氏名", value: "name" , align: "left"},
  { text: "役職", value: "position_safety" , align: "left"},
  { text: "作業グループ", value: "groups" , align: "left"},
];

export const FORM_MANAGERS_LABELS_OBAYASHI = [
  { text: "氏名", value: "name" , align: "left"},
  { text: "種別", value: "type" , align: "left"},
  { text: "役職", value: "position_safety" , align: "left"},
  { text: "作業グループ", value: "groups" , align: "left"},
];

export const FORM_WORKERS_TABLE_LABELS = [
  {
    text: "氏名",
    value: "name",
    align: "left",
  },
  {
    text: "※（特記事項）",
    value: "notification",
    align: "left",
  },
  {
    text: "職種",
    value: "field_tree_user_experience",
    align: "left",
  },
  {
    text: "現場入場期間",
    value: "timeout",
    align: "left",
  },
  {
    text: "新規入場者教育",
    value: "icon",
    align: "left",
  },
];


export const FORM_WORKERS_EDIT_TABLE_LABELS = [
  {
    text: "氏名",
    value: "name",
    align: "left",
  },
];

export const SITE_SUPPERVISION = [
  { text: "氏名", value: "name", align: "left" },
];

export const LEFT_TABLE_LABELS = [
  { text: "氏名", value: "name", align: "left" },
];
export const RIGHT_TABLE_LABELS = [
  { text: "氏名", value: "user_name", align: "left" },
];

export const CONSTRUCTION_CONTRACT_INFO = {
  field_tree_contracts: {
    id: null,
    construction_details: null,
    groundbreaking_date: null,
    complete_schedule_date: null,
    contract_date: null,
    supervisor_name: null,
    supervisor_authority_and_request_method: null,
    site_agent_name: null,
    site_agent_authority_and_request_method: null,
    health_safety_manager_name: null,
    health_safety_promoter_name: null,
    chief_engineer_name: null,
    chief_engineer_fulltime_flag: 2,
    chief_engineer_exam_id: null,
    specific_specialty_status: 1,
  },
  field_tree_technicians: [
    
  ],
  field_tree_core_technicians: [
    { 
      qualification_status: 1,
      cmn_mst_engineer_job_id: null,
    }
  ]
};

export const INSURANCE_STATUS_2 = [
  { id: 1, name: "無", value: 1 },
  { id: 2, name: "有", value: 2 },
];

export const CONFIRMATION_OF_REQUIRED_QUALIFICATIONS = [
  { id: 1, name: "済", value: 0 },
  { id: 2, name: "不要", value: 1 }
];

export const COMMUTE_METHOD_ARRAY = [
  { id: null, name: ""},
  { id: 1, name: "車"},
  { id: 2, name: "電車"},
  { id: 3, name: "その他"},
];

export const PORTAL_DOCUMENT_TYPE = {
  EXCEL: 1,
  PDF: 2,
};

export const PORTAL_ENTRANCE_QUESTIONAIRE = {
  id: "gf08",
  title_ja: "新規入場者教育時アンケート",
  title_eng: "Entrance_Questionnaire",
}

export const INVITE_TAB_FORM = {
  FormTab1: {id: 1, title: "会社選択"},
  FormTab2: {id: 2, title: "メールアドレス"}
}

export const STATE_HANDLING_FLAG = {
  WORKING: 0,
  FINISHED: 1,
}

export const END_WORK_MESSAGE_DIALOG = {
  title: "作業終了",
  message_text1: "協力会社を停止しようとしています。",
  message_text2: "停止すると、紐づくユーザーはログインできなくなります。",
  message_text3: "実行しますか？"
}

export const RESTART_WORK_MESSAGE_DIALOG = {
  title: "作業再開",
  message_text1: "この協力会社を再び有効化します。",
}

export const ERROR_WORK_MESSAGE_DIALOG = {
  title: "エラー",
  message_text1: "以下の理由で作業終了できませんでした。",
  message_text2: "対象の協力会社の直下に紐づく協力会社が存在する",
  message_text3: "作業終了できるのは直下の協力会社のみ",
}

export const OPTION_LANGUAGE = [
  { id: 0, value: "日本語"},
  { id: 1, value: "英語"},
  { id: 2,  value: "ベトナム語" },
  { id: 3,  value: "中国（北京）語 " },
]

export const FOREIGNER_FLAG = {
  NOT_FOREIGNER: 0,
  IS_FOREIGNER: 1
}

export const SETTING_PLAN_MANAGER = [
  { id: 1, value: "予定管理者"},
  { id: 0, value: "設定しない"},
];

export const SELECTED_GROUP = {
  OFF: 0,
  ON: 1
};

export const RADIO_CHECK = [
  { id: 1, value: "総括"},
  { id: 2, value: "所長・副所長"},
  { id: 3, value: "工事長"},
  { id: 4, value: "主任"},
  { id: 5, value: "係員"},
  { id: 6, value: "設定しない"},
];

export const POSITION_CHECK = [
  { id: 1, value: "統括"},
  { id: 2, value: "元方"},
  { id: 3, value: "設定しない"}
];