<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters>
                    <v-col cols="12" sm="5" md="5">
                      <!-- 日付 -->
                      <v-list-item>
                        <v-list-item-content>
                          <!-- 日付 -->
                          <Label label="日付（開始）" :editable="editable">
                            <InputDatepicker
                              name="start_date"
                              :values="formValues"
                              :editable="editable"
                              :flagNull="true"
                              placeholder="yyyy/mm/dd"
                              validation_label="日付（開始）"
                              :validation_rules="getStartDateRules()"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <div class="textBold mt-12 ml-5">～</div>
                    <v-col cols="12" sm="5" md="5">
                      <!-- 日付 -->
                      <v-list-item>
                        <v-list-item-content>
                          <!-- 日付 -->
                          <Label label="日付（終了）" :editable="editable">
                            <InputDatepicker
                              name="end_date"
                              :values="formValues"
                              :editable="editable"
                              :flagNull="true"
                              placeholder="yyyy/mm/dd"
                              validation_label="日付（終了）"
                              :validation_rules="getEndDateRules()"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="11" md="11">
                      <!-- 工事 -->
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label label="工事" :editable="editable" required>
                            <Select
                              class="select-position"
                              name="field_construction_id"
                              :values="formValues"
                              :items="FIELD_CONSTRUCTION_ARRAY"
                              :editable="editable"
                              @onInput="onInput"
                              validation_label="工事"
                              validation_rules="required"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="11" md="11">
                      <!-- 作業グループ -->
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label label="作業グループ" :editable="editable">
                            <Select
                              class="select-position"
                              name="group_id"
                              :values="formValues"
                              :items="FIELD_TREE_GROUP_ARRAY"
                              item_text="group_name"
                              :editable="editable"
                              @onInput="onInput"
                              validation_label="作業グループ"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="11" md="11">
                      <!-- 協力会社 -->
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label label="協力会社" :editable="editable" required>
                            <Select
                              class="select-position"
                              name="field_tree_id"
                              :values="formValues"
                              :items="COMPANY_PARTNER_ARRAY"
                              item_text="company_name"
                              item_value="field_tree_id"
                              :editable="editable"
                              @onInput="onInput"
                              validation_label="車輌手配業者"
                              validation_rules="required"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="12" md="12">
                      <!-- 職長 -->
                      <FieldTreeUserForm
                        name="user_ids"
                        :values="formValues"
                        :editable="editable"
                        :users="FOREMAN_ARRAY"
                        @onInput="onInput"
                      />
                     </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <FieldEntranceTimeForm
                    name="field_entrance_times"
                    :values="formValues"
                    :editable="editable"
                    @onInput="onInput"
                    :enterDayTime="enterDayTime"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "@/components/forms/elements/Label";
import InputDatepicker from "@/components/forms/elements/InputDatepicker";
import Select from "@/components/forms/elements/Select";
import FieldTreeUserForm from "./components/FieldTreeUserForm.vue";
import FieldEntranceTimeForm from "./components/FieldEntranceTimeForm.vue";

export default {
  data: () => {
    return {
      formValues: {},
      FIELD_CONSTRUCTION_ARRAY: [],
      FIELD_TREE_GROUP_ARRAY: [],
      COMPANY_PARTNER_ARRAY: [],
      FOREMAN_ARRAY: [],
    };
  },
  components: {
    Label,
    Select,
    InputDatepicker,
    FieldTreeUserForm,
    FieldEntranceTimeForm
  },
  props: {
    item: Object,
    enterDayTime: String,
    editable: {
      type: Boolean,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.getListConstruction();

    this.$watch(
      () => Store.getters["PortalChart/getWorkTypes"],
      (data) => {
        this.FIELD_CONSTRUCTION_ARRAY = [this.getDefaultArrays("id", "name"),...data];
        if (this.FIELD_CONSTRUCTION_ARRAY.length > 0) {
          if (!this.formValues["field_construction_id"]) {
            this.formValues["field_construction_id"] = data[0].id;
          }
        }
        if(!this.formValues["field_construction_id"]) {
          this.FIELD_TREE_GROUP_ARRAY = [this.getDefaultArrays("id", "group_name")];
          this.COMPANY_PARTNER_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name")];
          this.FOREMAN_ARRAY  = [];
        } else {
          this.getListGroups();
          this.getCompanyPartners();
        }
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getListGroup"],
      (data) => {
        this.FIELD_TREE_GROUP_ARRAY = [this.getDefaultArrays("id", "group_name"),...data];
      },
      {
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["PortalChart/getListCompanyPartner"],
      (data) => {
        this.COMPANY_PARTNER_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name"),...data];
        this.FOREMAN_ARRAY  = [];
      },
      {
        deep: true
      }
    );
    this.$watch(
      () => Store.getters["PortalChart/getListCompanyPartnerUser"],
      (data) => {
        const foremans = [];
        data.forEach((item) => {
          foremans.push({
            id: item.user_id,
            name: item.user_name_sei + " " + item.user_name_mei,
          });
        });
        this.FOREMAN_ARRAY = foremans;
      },
      {
        deep: true,
      }
    );
    
    this.$watch(
      () => this.item,
      (data) => {
        this.formValues = { ...data };
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      this.getDataByName(name);
      this.$emit("formUpdate", formValues);
    },
    getDataByName(name) {
      switch (name) {
        case "field_construction_id": {
          this.formValues.group_id = null;
          this.formValues.field_tree_id = null;
          this.formValues.user_ids = [];
          this.FIELD_TREE_GROUP_ARRAY = [this.getDefaultArrays("id", "group_name")];
          this.COMPANY_PARTNER_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name")];
          this.FOREMAN_ARRAY  = [];
          if(this.formValues[name]) {
            this.getListGroups();
            this.getCompanyPartners();
          }
          break;
        }
        case "group_id": {
          this.formValues.field_tree_id = null;
          this.formValues.user_ids = [];
          this.COMPANY_PARTNER_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name")];
          this.FOREMAN_ARRAY  = [];
          this.getCompanyPartners();
          break;
        }
        case "field_tree_id": {
          if (!this.formValues['group_id'] && this.formValues['field_tree_id']) {
            let groupId = this.getGroupdIdByFieldTree(this.formValues['field_tree_id']);
            let hasGroup = this.hasGroupById(groupId);
            if (hasGroup) {
              this.formValues['group_id'] = groupId;
              this.getCompanyPartners();
            }
          }
          this.formValues.user_ids = [];
          this.FOREMAN_ARRAY  = [];
          if(this.formValues[name]) {
            this.getListFieldTree();
          }
          break;
        }
        default:
          break;
      }
    },
    async getListConstruction() {
      await Store.dispatch("PortalChart/getWorkTypes", this.CURRENT_SITE.field_id);
    },
    async getListGroups() {
      await Store.dispatch(
        "PortalChart/getListGroups",
        this.formValues["field_construction_id"]
      );
    },
    async getCompanyPartners() {
      let params = {
        company_for_user_flag: 1,
        user_has_foreman: 1,
        state_handling_flag: 0
      };
      if (this.formValues["group_id"]) params.field_tree_group_id = this.formValues?.group_id;
      else if (this.formValues["field_construction_id"]) params.field_construction_id = this.formValues.field_construction_id;
      else return;
      await Store.dispatch("PortalChart/getListCompanyPartner", {params});
    },
    async getListFieldTree() {
      if (this.COMPANY_PARTNER_ARRAY.length > 0) {
        const params = {
          id: this.formValues["field_tree_id"],
          state_handling_flag: 0
        };
        await Store.dispatch("PortalChart/getListCompanyPartnerUser", params);
      }
    },
    getDefaultArrays(id, value) {
      let results = {};
      results[id] = null;
      results[value] = "";
      return results;
    },

    getStartDateRules() {
      return this.formValues?.end_date ? "choose-pass-date|" + `compare-end-date:${this.formValues.end_date.replaceAll("-", "/")}`: "";
    },
    getEndDateRules() {
      return this.formValues?.start_date ? "choose-pass-date|" + `compare-start-date:${this.formValues.start_date.replaceAll("-", "/")}`: "";
    },
    getGroupdIdByFieldTree(fieldTreeId) {
      return this.COMPANY_PARTNER_ARRAY.find(e => e.field_tree_id === fieldTreeId)?.field_tree_group_id || null;
    },
    hasGroupById(groupId) {
      if (!groupId) return false;
      let group = this.FIELD_TREE_GROUP_ARRAY.find(e => e.id === groupId);
      if (group) return true;
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
.textBold {
  font-size: 24px !important;
  font-weight: bold !important;
}

.row-position{
  margin-top: -15px;
  height: 150px;
  position: relative;
}

.select-position{
  width: 100%;
  position: absolute;
}
</style>