<template>
  <!-- parent table -->
  <v-data-table
    :headers="tableLabels"
    :items="items"
    :items-per-page="itemsPerPage"
    :height="tableHeight"
    fixed-header
    hide-default-footer
    disable-sort
    :show-select="true"
    class="elevation-0 v-data-table__wrapper"
    sort-by="updatedAt"
  >
    <!-- parent table template -->
    <template v-slot:body="{ items }">
      <template v-if="items && items.length > 0">
        <tbody v-for="item in items" :key="item.field_tree_id">
          <tr class="background-header-table-color">
            <td >
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <!-- toggle icon -->
                  <v-icon
                    @click.stop="toggleShowChildren(item.field_tree_id)"
                  >
                    {{
                      isShowChildren[item.field_tree_id]
                        ? "mdi-chevron-down"
                        : "mdi-chevron-right"
                    }}
                  </v-icon>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-simple-checkbox
                    :disabled="checkDisable(item)"
                    :value="isSelectedParent(item.field_tree_id) && checkSelectedParents(item, disableMap)"
                    @input="updateCheckbox(item, $event, true)"
                  ></v-simple-checkbox>
                </v-col>
              </v-row>
            </td>
            <td class="col-status" colspan="9">
              <div class="company-name">
                {{ item.company_name }}
              </div> 
            </td>
          </tr>
          <!-- child table template -->
          <template v-for="itemChild in item.children">
            <tr
              v-show="isShowChildren[item.field_tree_id]"
              class="child-table"
              :key="itemChild.field_tree_user_id"
            >
              <td class="center" style="max-width:50px">
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-simple-checkbox
                      :disabled="disableMap.get(`disable_${item.field_tree_id}_${itemChild.user_id}`)"
                      :value="isSelected(item.field_tree_id,itemChild.user_id)"
                      @input="updateCheckbox(itemChild, $event, null, item)"
                    ></v-simple-checkbox>
                  </v-col>
                </v-row>
              </td>
              <td class="user-name">
                {{ itemChild.user_name }}
              </td>
              <td>
                {{itemChild.job_name}}
              </td>
              <td>
                {{itemChild.site_admission_date}}
              </td>
              <td :style="getColor(itemChild.face_reco_status)" :class="`face_reco_status_${itemChild.field_user_reco_image_id}`">
                {{convertFaceRecoStatus(itemChild.face_reco_status)}}
              </td>
              <td>
                {{itemChild.face_reco_time}}
              </td>
              <td style="text-align: -webkit-center;">
                <v-img
                  width="60"
                  height="50"
                  :src="imageMap.get(`profile_image_url_${itemChild.user_id}`)"
                ></v-img>
              </td>
              <td style="text-align: -webkit-center;">
                <v-img
                  :src="imageMap.get(`person_image_url_${itemChild.user_id}`)"
                  width="60"
                  height="50"
                  @click="onSelectImage(item, itemChild)"
                ></v-img>
              </td>
            </tr>
          </template>
          <tr
            v-show="
              isShowChildren[item.field_tree_id] &&
              (childTableDisplayMoreNumber(
                item.total_children_item,
                item.children.length
              ) > 0)
            "
          >
            <td colspan="9">
              <div class="d-flex justify-center">
                <v-btn
                  x-small
                  depressed
                  :disabled="isDisable"
                  class="mr-4 display-more"
                  color="#E5E5E5"
                  :key="isLoadChild"
                  v-if="
                    childTableDisplayMoreNumber(
                      item.total_children_item,
                      item.children.length
                    ) > 0
                  "
                  @click.once="getChildren(item)"
                  >さらに表示する
                  <v-badge
                    class="display-more-number"
                    inline
                    color="#767676"
                    :content="
                      childTableDisplayMoreNumber(
                        item.total_children_item,
                        item.children.length
                      )
                    "
                  />
                </v-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
      <div v-else-if="isNoDataMessage" class="schedule_nodata mt-4">
        <span>{{ NO_DATA_MESSAGE }}</span>
      </div>
      <form ref="form">
        <input
          style="display: none"
          ref="input_drawing_file"
          type="file"
          accept="image/png,image/jpeg"
          @change="onImageInput()"
        />
      </form>
    </template>
  </v-data-table>
</template>
<script>
import {
  FACE_REGISTER_STATUS,
} from "@/constants/REGISTER_FACE";
import {
  IMAGE_NO_FACE,
} from "@/constants/COMMON";
import { Store } from "@/store/Store.js";
import { convertPngToJpg } from "@/utils/convertPngToJpg";
export default {
  data() { 
    return { 
      isDisable: false,
      time_out: null,
      current_user: null,
      current_item: null,
      IMAGE_NO_FACE,
      profile_image_url: null,
    }
  },
  props: {
    tableLabels: Array,
    itemsPerPage: Number,
    tableHeight: Number,
    items: Array,
    updateCheckbox: Function,
    toggleShowChildren: Function,
    childTableDisplayMoreNumber: Function,
    isShowChildren: Object,
    NO_DATA_MESSAGE: String,
    imageMap: Map,
    disableMap: Map,
    isSelectedParent : Function,
    isSelected: Function,
    checkSelectedParents:Function,
    isLoadChild: {
      typeof: Boolean,
      default: false,
    },
    isNoDataMessage: Boolean,
  },
  computed: {
    FACE_REGISTER_STATUS() {
      return Object.keys(FACE_REGISTER_STATUS).map((key) => {
        return FACE_REGISTER_STATUS[key];
      });
    },
  },
  methods: {
    getChildren(item) {
      this.isDisable = true;
      // Re-enable after submit
      this.time_out = setTimeout(() => {
        this.isDisable = false;
      }, 1000);
      this.$emit('getChildItems', item.field_tree_id, 
        Math.floor((item.children.length - 3) / 5) + 2);
    },
    convertFaceRecoStatus(face_reco_status) {
      if(!face_reco_status) face_reco_status = 0;
      const status = this.FACE_REGISTER_STATUS.find(item => item.id === face_reco_status);
      return status?.name;
    },
    getColor(face_reco_status) {
      if(!face_reco_status) face_reco_status = 0;
      const status = this.FACE_REGISTER_STATUS.find(item => item.id === face_reco_status);
      return status?.style;
    },
    onSelectImage(item, child) {
      this.current_user = child;
      this.current_item = item;
      this.$refs.input_drawing_file.click();
    },
    onImageInput() {
      const file = this.$refs.input_drawing_file.files[0];
      if (!file) {
        return;
      }
      this.$refs.form.reset();
      this.setFile(file);
    },
    async setFile(file) {
      if (file.name.toLocaleLowerCase().match(/\.(png|jpg|jpeg)$/)) {
        if (file.size >= 10 * 1024 * 1024) {
          Store.dispatch(
            "Error/show",
            {
              status: "400",
              message: "アップロードのファイルは、最大10MBまでです。",
            },
            { root: true }
          );
          return;
        }
      } else {
        Store.dispatch(
          "Error/show",
          { status: "400", message: "IMAGEファイルをアップロードしてください。" },
          { root: true }
        );
        return;
      }
      this.getBase64(file)
        .then((data) => {
          this.pictureCheck(data, file.name);
        })
        .catch(() => console.log("ファイルアップロードエラー"));
    },
    async pictureCheck(file, fileName) {
      if (fileName.indexOf(".png") !== -1) {
        file = await convertPngToJpg(file);
        fileName = fileName.replace("png", "jpg");
      }
      const params = {
        worker_picture: file
      };
      const result = await Store.dispatch(`FaceRecognition/checkFace`, params);
      if(!result.hasError) {
        this.$emit("onChangeImage", {
          current_item: this.current_item,
          current_user: this.current_user,
          value: file,
          fileName: fileName,
        });
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    checkDisable(item) {
      let isDisable = true;
      item.children.forEach(child => {
        if(child.profile_image_url || child.person_image_url || !this.disableMap.get(`disable_${item.field_tree_id}_${child.user_id}`)) {
          isDisable = false;
        }
      });
      return isDisable;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
// ヘッダーとテーブルコンテンツのたて位置を合わせるために
// show-selectオプションをONにした状態でcheckboxを非表示
::v-deep .v-data-table-header {
  .v-input--selection-controls__input {
    display: none !important;
  }
}

td.start div.row {
  width: 75px !important;
}
td.col-status {
  width: 110px !important;
}
.child-table {
  td.start {
    padding-right: 0;
  }
}

.schedule_nodata {
  position: absolute;
  left: 50%;
}

button.add-new-child,
button.display-more {
  height: 20px !important;
}

.display-more-number ::v-deep .v-badge__badge {
  font-size: 10px;
}

.display-more-number ::v-deep .v-badge__badge {
  padding: 1px;
  height: 14px;
}
.background-header-table-color {
  background-color: rgba(0, 0, 0, 0.04);
}
.company-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 85vw;
}
.user-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 16vw;
}
</style>
