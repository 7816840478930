<template>
  <div
    v-if="item.start_time && item.end_time"
    class="schedulePlan"
    :class="getClass(item.schedule_type)"
    :style="style"
    @click="catchClick(item.schedule_type)"
  ></div>
</template>
<script>
import { differenceInMinutes, format } from "date-fns";
import { SCHEDULE_TYPE } from "@/constants/SCHEDULE_WORKS";
import moment from "moment";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";

export default {
  data: () => {
    return {
      style: "",
    };
  },
  props: {
    isUsage: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
    index: {
      type: Number,
    },
    //1時間の分割数
    hourDivide: {
      type: Number,
    },
    //cellRect.widthが1時間の幅ピクセル
    cellRect: {
      type: Object,
    },
    //今日の日付
    today: {
      type: String,
    },
    //今日の開始ピクセル
    todayStartX: {
      type: Number,
    },
    //上部のマージン
    marginTop: {
      type: Number,
    },
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        const { start_time, end_time } = newValue;

      const twentyOne = 21;
      const six = 6;
      const zero = 0;
      const today = this.BEFORE_TIME;
      const start = new Date(start_time) < this.BEFORE_TIME ? this.BEFORE_TIME : new Date(start_time);
      let end = new Date(end_time) > this.AFTER_TIME ? this.AFTER_TIME : new Date(end_time);

      if(this.IS_OBAYASHI && start.getHours() < six) {
        start.setHours(six);
        start.setMinutes(zero);
      }

      if(this.IS_OBAYASHI && end.getHours() < six) {
        end.setHours(six);
        end.setMinutes(zero);
      }

      if(this.IS_OBAYASHI && start.getHours() >= twentyOne) {
        start.setHours(twentyOne);
        start.setMinutes(zero);
      }

      if(this.IS_OBAYASHI && end.getHours() >= twentyOne) {
        end.setHours(twentyOne);
        end.setMinutes(zero);
      }

      if(start_time && end_time) {
        if(this.IS_OBAYASHI && format(new Date(start), "yyyy/MM/dd") < format(new Date(end), "yyyy/MM/dd")) {
          end = new Date(format(new Date(start), "yyyy/MM/dd"));
          end.setHours(twentyOne);
          end.setMinutes(zero);
        }
      }

        const rect = {
          x: null,
          width: null,
        };

        // 継続時間
        const diffMinContinuous = differenceInMinutes(end, start);
        rect.width =
          (diffMinContinuous * (this.cellRect.width * this.hourDivide)) / 60;

        // 開始位置
        const timeDiff = this.IS_OBAYASHI && this.isUsage ? today.setHours(today.getHours() + 6) : today;
        const diffMinStart = differenceInMinutes(start, timeDiff);
        // console.log("--", start, today, this.todayStartX, diffMinStart); comment log
        rect.x =
          this.todayStartX +
          diffMinStart * ((this.cellRect.width * this.hourDivide) / 60);

        rect.top = this.index * 2 * this.cellRect.height + this.marginTop;
        rect.height = this.cellRect.height;

        this.style = `width:${rect?.width}px;height:${rect?.height}px;left:${rect?.x}px;top:${rect?.top}px;`;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    SCHEDULE_TYPE_ARRAY() {
      return Object.keys(SCHEDULE_TYPE).map((key) => {
        return { ...SCHEDULE_TYPE[key], key };
      });
    },
    BEFORE_TIME() {
      return new Date(this.today);
    },
    AFTER_TIME() {
      return moment(this.today).add(1, 'days').add(6, 'hours').toDate();
    },
    IS_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
  },
  methods: {
    getClass(type_id) {
      const type = this.SCHEDULE_TYPE_ARRAY.find((item) => {
        return item.id === type_id;
      });
      return `type_${type.key}`;
    },
    catchClick(type_id) {
      if (type_id === SCHEDULE_TYPE.DISABLE.id) {
        this.$emit('openSetTimeProhibit');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.schedulePlan {
  position: absolute;
  z-index: 1;
  border-radius: 4px;
  &.type_DISABLE {
    z-index: 0;
    top: 0 !important;
    height: 100% !important;
    border-radius: 0;
    opacity: 0.5;
    cursor: pointer;
    background: repeating-linear-gradient( 119deg, #ffffff, #ffffff 10px, #9e9e9e 10px, #9e9e9e 20px);
  }
  &.type_OVERLAP {
    background-color: $color_schedule_type_overlap;
    z-index: 0;
    top: 0 !important;
    height: 100% !important;
    border-radius: 0;
    opacity: 0.7;
  }
}
</style>
