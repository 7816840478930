<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-row>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="最高作業床高さ" />
                          <InputText
                            name="maximum_work_floor_height"
                            :values="formValues"
                            :editable="false"
                            placeholder="10"
                            suffix="m"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="積載荷重" />
                          <InputText
                            name="movable_load"
                            :values="formValues"
                            :editable="false"
                            suffix="kg"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="車体全長" />
                          <InputText
                            name="body_length"
                            :values="formValues"
                            :editable="false"
                            suffix="mm"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="車体全幅" />
                          <InputText
                            name="body_width"
                            :values="formValues"
                            :editable="false"
                            suffix="mm"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="車体全高" />
                          <InputText
                            name="body_height"
                            :values="formValues"
                            :editable="false"
                            suffix="mm"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="車輌重量" />
                          <InputText
                            name="vehicle_weight"
                            :values="formValues"
                            :editable="false"
                            suffix="kg"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="アウトリガ張出寸法" />
                          <InputText
                            name="outrigger_overhang_dimensions"
                            :values="formValues"
                            :editable="false"
                            suffix="mm"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="バケット容積" />
                          <InputText
                            name="bucket_volume"
                            :values="formValues"
                            :editable="false"
                            suffix="㎥"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="接触防止措置等" />
                      <InputText
                        name="prevention_measures"
                        :values="formValues"
                        :editable="false"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel label="資格・免許" />
                    </v-list-item-content>
                  </v-list-item>
                  <v-row>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="技能検定職種" />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="免許・資格名" />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-main
                    v-for="(item, index) in formValues.device_licences"
                    :key="index"
                  >
                    <v-row>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <InputText
                              name="catergory"
                              :values="
                                cmn_mst_licences.find(
                                  (element) => element.id === item.cmn_mst_licence_id
                                )
                              "
                              :editable="false"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <InputText
                              name="sub_catergory"
                              :values="
                                cmn_mst_licences.find(
                                  (element) => element.id === item.cmn_mst_licence_id
                                )
                              "
                              :editable="false"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-main>
                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel label="特別教育" />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="特別教育名" />
                    </v-list-item-content>
                  </v-list-item>
                  <v-main
                    v-for="item in formValues.device_sp_trainings"
                    :key="item.name"
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <InputText
                          name="training_name"
                          :values="
                            cmn_mst_sp_trainings.find(
                              (element) => element.id == item.cmn_mst_sp_training_id
                            )
                          "
                          :editable="false"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </v-main>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="機械等の特性・その他その使用上注意すべき事項" />
                      <InputText
                        name="characteristics"
                        :values="formValues"
                        :editable="false"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import Select from "@/components/forms/elements/Select";
import { Store } from "@/store/Store.js";
import _ from "lodash";
export default {
  data: () => {
    return {
      formValues: {},
      cmn_mst_sp_trainings: Store.state.CmnMst.constants.entries.cmn_mst_sp_trainings,
      cmn_mst_licences:  Store.state.CmnMst.constants.entries.cmn_mst_licences,
    };
  },
  components: {
    Label,
    SectionLabel,
    InputText,
    Select,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = _.cloneDeep(newValue);
        if(this.formValues.device_sp_trainings.length == 0){
          this.formValues.device_sp_trainings = new Array({training_name: ""});
        }
        if(this.formValues.device_licences.length == 0){
          this.formValues.device_licences = new Array({catergory:"",sub_catergory: ""});
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  
  computed: {
    getBodyStyle() {
      const headerHeight = 48;
      let style = `height:${this.mainHeight - headerHeight}px;`;
      return style;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
.row {
  margin: 0 !important;
}
</style>
