<template>
  <FormDialog>
    <template #header>
      <!-- 
        (共通)
        PC ヘッダーは全て共通
       -->
      <FormWorkNavi
        v-if="!$sp"
        v-model="tab"
        :tab="tab"
        :editable="editable"
        :workApprovalFlag="workApprovalFlag"
        :stateHandlingFlag="stateHandlingFlag"
        :isTablesGroup="isTablesGroup"
        :isNewItem="isNewItem"
        :isShowButtonCopy="isShowButtonCopy"
        :isShowConfirmDialog="isShowConfirmDialog"
        :isShowScheduleDialog="isShowScheduleDialog"
        :formtitle="formtitle"
        :FORMS="FORMS"
        :valid="valid"
        :onCopy="onCopy"
        :onEditable="onEditable"
        :onSubmit="onSubmit"
        :onClickBtnClose="onClickBtnClose"
        :statusEditable="statusEditable"
        @onShowScheduleDialog="
          () => {
            isShowScheduleDialog = true;
          }
        "
        @onCloseConfirmDialog="
          () => {
            isShowConfirmDialog = true;
          }
        "
      />
      <!-- 
        SP ナビゲーション 
      -->
      <SpFormWorkNavi
        v-if="$sp"
        v-model="tab"
        :tab="tab"
        :editable="editable"
        :workApprovalFlag="workApprovalFlag"
        :stateHandlingFlag="stateHandlingFlag"
        :isTablesGroup="isTablesGroup"
        :isNewItem="isNewItem"
        :isShowButtonCopy="isShowButtonCopy"
        :isShowConfirmDialog="isShowConfirmDialog"
        :isShowScheduleDialog="isShowScheduleDialog"
        :formtitle="formtitle"
        :FORMS="FORMS"
        :valid="valid"
        :onCopy="onCopy"
        :onEditable="onEditable"
        :onSubmit="onSubmit"
        :onClickBtnClose="onClickBtnClose"
        @onShowScheduleDialog="
          () => {
            isShowScheduleDialog = true;
          }
        "
        @onCloseConfirmDialog="
          () => {
            isShowConfirmDialog = true;
          }
        "
      />
    </template>
    <template #main="{ params }">
      <!-- 
        (共通)
        全てのフォームのバリデーションの監視を行う
       -->
      <ValidationObserver ref="observer" v-slot="observer">
        <!-- フォームtab -->
        <v-tabs-items v-model="tab">
          <!-- 基本情報 -->
          <!-- <keep-alive> -->
          <v-tab-item>
            <FormBasicInfo
              :editable="editable"
              :item="formValues"
              :isNewItem="isNewItem"
              :resetFormImage="resetFormImage"
              :disableData="disableData"
              :mainHeight="params.mainHeight"
              :errors="errors"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <FormWorkContent
              :editable="editable"
              :item="formValues"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <FormArtificial
              :editable="editable"
              :item="formValues"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <FormFireWork
              :editable="editable"
              :item="formValues"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <FormAnoxiaDangerousWork
              :editable="editable"
              :item="formValues"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <FormChangeHistory
              :editable="editable"
              :item="formValues"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <!-- </keep-alive> -->
        </v-tabs-items>

        <!-- 
          (共通)
          ValidationObserverのスロットプロパティを受け取って
          updateValidateメソッドを実行して、
          バリデーション結果をスクリプト側に渡してます
        -->
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>
      <Popup width="480px" :dialog="isShowScheduleDialog">
        <ConfirmScheduleDialog
          @close="isShowScheduleDialog = false"
          @yes="updateStateHandling"
          :title="getTitle()"
          :text="getText()"
          :textBtn="getTextBtn()"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import ConfirmScheduleDialog from "../works/components/ConfirmScheduleDialog.vue";
import FormBasicInfo from "./FormBasicInfo.vue";
import FormWorkContent from "./FormWorkContent.vue";
import FormArtificial from "./FormArtificial.vue";
import FormFireWork from "./FormFireWork.vue";
import FormAnoxiaDangerousWork from "./FormAnoxiaDangerousWork.vue";
import FormChangeHistory from "./FormChangeHistory.vue";
import FormWorkNavi from "./FormWorkNavi.vue";
import SpFormWorkNavi from "./SpFormWorkNavi.vue";
import { Store } from "@/store/Store.js";
import { SCHEDULE_RECORDS_INITIAL_ITEM, USER_TYPE } from "@/constants/SCHEDULE_WORKS";
import _ from "lodash";
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON";

/**
 * (共通)
 * タイトル
 */
const TITLE = {
  SCHEDULE_WORK: "作業予定情報",
  RECORD_WORK: "作業実績情報",
};

/**
 * フォームとタブの設定
 */
const FORMS = {
  FormBasicInfo: { id: 1, title: "基本情報" },
  FormWorkContent: { id: 2, title: "作業内容" },
  FormArtificial: { id: 3, title: "人工" },
  FormFireWork: { id: 4, title: "火気作業" },
  FormAnoxiaDangerousWork: { id: 5, title: "酸素欠乏危険作業" },
  FormChangeHistory: { id: 6, title: "登録・変更履歴" },
};

export default {
  data: () => {
    return {
      FORMS,
      valid: false, //バリデーション結果
      errors: {},
      // tab初期化
      tab: 0,
      editable: false,
      isShowConfirmDialog: false,
      isShowScheduleDialog: false,
      isClickCloseBtn: false,
      formValues: {},
      editedItem: {},
      beforeUpdateItem: {},
      resetFormImage: false,
      isShowButtonCopy: false,
      stateHandlingFlag: 0,
      workApprovalFlag: false,
      disableData: false,
      isCompleteEdit: false,
      isSubmitted: false,
      timeout: null,
      statusEditable : 1
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    FormBasicInfo,
    FormWorkContent,
    FormArtificial,
    FormFireWork,
    FormAnoxiaDangerousWork,
    FormChangeHistory,
    ConfirmScheduleDialog,
    SpFormWorkNavi,
    FormWorkNavi,
  },
  props: {
    isNewItem: Boolean,
    workGroupResults: Object,
    scheduleFlag: Number,
    isWorkPlan: Boolean,
    isTablesGroup: Boolean,
    isParent: Boolean,
  },
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    /**
     * (共通)
     * 新規作成を監視
     */
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        //新規の場合は最初から編集モード
        if (flag) {
          this.editable = true;
          this.isShowButtonCopy = true;
          this.formValues = _.cloneDeep(SCHEDULE_RECORDS_INITIAL_ITEM);
          this.formValues.work_group_results.field_construction_id = this.workGroupResults.field_construction_id;
          this.formValues.work_group_results.work_hour_zone = this.workGroupResults.work_hour_zone;
          if (this.workGroupResults["addChild"]) {
            this.formValues.work_results.field_tree_group_id = this.workGroupResults['field_tree_group_id'];
            this.formValues.work_group_results.id = this.workGroupResults.work_group_result_id;
            this.formValues.work_group_results.work_date_start = this.workGroupResults.work_date;
            this.formValues.work_group_results.work_date_end = this.workGroupResults.work_date;
            this.disableData = true;
          } else {
            this.disableData = false;
          }
        } else {
          this.disableData = !this.isParent ? true : false;
          this.initData(this.workGroupResults.id);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    // 新規or編集でタイトル変更
    formtitle() {
      return (this.scheduleFlag == 0) ? TITLE.RECORD_WORK : TITLE.SCHEDULE_WORK;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },
  methods: {
    /**
     * (共通)
     * フォームの変更を送信
     */
    formUpdate(params) {
      this.formValues = { ...params };
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      this.editable = true;
    },
    /**
     * (共通)
     * 状態を詳細に変更
     */
    onDetail() {
      this.editable = false;
    },

    /**
     * (共通)
     * 登録
     */
    async onSubmit() {
      this.isSubmitted = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isSubmitted = false;
      }, SUBMIT_DELAY_TIMEOUT);

      const hasId = "id" in this.formValues.work_results;
      const editedItem = _.cloneDeep(this.formValues);
      editedItem.work_classification_results.forEach((item) => {
        delete item["occupation"];
      });
      editedItem.work_dayworker_results.forEach((item) => {
        if (item.dayworker) {
          item.dayworker = String(item.dayworker);
        } else {
          item.dayworker = 0;
        }
      });
      delete editedItem["work_results"]["field_tree_group_id"];
      this.editedItem = editedItem;
      const result = await Store.dispatch(
        //idがある場合は更新、ない場合は新規
        hasId ? `ScheduleRecords/update` : `ScheduleRecords/post`,
        this.editedItem
      );
      //レスポンスエラーがある場合はフォームを閉じない
      //エラーメッセージは、api.jsが表示
      if (result.hasError) {
        return;
      } else {
        if (hasId) {
          this.getItems(this.formValues.work_results.id);
          this.onDetail();
          this.isCompleteEdit = true;
        } else {
          this.$emit("cancel");
        }
        //成功したら値を更新
        Store.dispatch("Toast/show", {
          status: 200,
          message: hasId ? "更新しました" : "登録しました",
        });
      }
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid, errors }) {
      this.valid = valid;
      this.errors = errors;
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        this.formValues = _.cloneDeep(this.beforeUpdateItem);
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },

    onClickBtnClose() {
      if (!this.editable && !this.isNewItem && this.workApprovalFlag) {
        this.$emit("cancel", this.isCompleteEdit);
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },

    async getItems(id) {
      let params = {
        id : id
      }
      const result = await Store.dispatch(`ScheduleRecords/getDetail`, { params });
      this.formValues = _.cloneDeep(result.data.contents.entries);
      this.beforeUpdateItem = _.cloneDeep(result.data.contents.entries);
      this.stateHandlingFlag = this.formValues.work_results.state_handling_flag;
      this.workApprovalFlag =
        this.formValues.work_results.work_approval_flag == 0 ? true : false;
      this.statusEditable = this.formValues.editable;
    },

    async initData(id) {
      await this.getItems(id);
      if (
        this.isWorkPlan &&
        this.workApprovalFlag &&
        this.stateHandlingFlag == 0 &&
        this.isTablesGroup
      ) {
        this.tab = 2;
        this.editable = true;
      }
    },

    async onCopy() {
      const params = {
        field_id: this.CURRENT_SITE.field_id
      }
      const result = await Store.dispatch(`ScheduleRecords/getWorkLastTime`, { params });
      if (!result.hasError && result.data.contents.entries) {
        let data = _.cloneDeep(result.data.contents.entries);
        data = {...this.formValues,...data};
        data['work_group_results']['work_date_start'] = this.formValues['work_group_results']['work_date_start'];
        data['work_group_results']['work_date_end'] = this.formValues['work_group_results']['work_date_end'];
        data['work_keyplan_image_results'] = this.formValues['work_keyplan_image_results'];
        if(this.disableData) {
          data['work_group_results'] = this.formValues['work_group_results'];
          if(data.work_results.field_tree_group_id != this.formValues.work_results.field_tree_group_id) {
            data.work_results.field_tree_id = this.formValues.work_results.field_tree_id;
            data.work_results.foreman_user_id = this.formValues.work_results.foreman_user_id;
            data["work_user_results"][USER_TYPE.TYPE_1] = [];
            data["work_user_results"][USER_TYPE.TYPE_2] = [];
            data["work_user_results"][USER_TYPE.TYPE_3] = [];
            data["work_user_results"][USER_TYPE.TYPE_4] = [];
            data["work_user_results"][USER_TYPE.TYPE_5] = [];
          }
          data.work_results.field_tree_group_id = this.formValues.work_results.field_tree_group_id;
        }
        this.formValues = _.cloneDeep(data);
      } else {
        this.initDataNewItem();
      }
      this.resetFormImage = !this.resetFormImage;
    },

    async updateStateHandling() {
      const apiParams = {
        work_result_id: this.formValues?.work_results.id,
        state_handling_flag: this.stateHandlingFlag == 0 ? 1 : 0,
      };
      await Store.dispatch(`ScheduleRecords/updateStateHandling`, apiParams);
      this.isShowScheduleDialog = false;
      this.$emit("cancel");
    },
    getTitle() {
      return this.stateHandlingFlag == 0 ? "予定の中止" : "中止取消の確認";
    },
    getText() {
      return this.stateHandlingFlag == 0
        ? "作業予定を中止します"
        : "中止した作業予定を解除してもよろしいですか。";
    },
    getTextBtn() {
      return this.stateHandlingFlag == 0 ? "中止" : "解除";
    },
    initDataNewItem() {
      const data = _.cloneDeep(SCHEDULE_RECORDS_INITIAL_ITEM);
      data.work_group_results.field_construction_id = this.workGroupResults.field_construction_id;
      data.work_group_results.work_hour_zone = this.workGroupResults.work_hour_zone;
      if(this.disableData) {
          data.work_group_results = this.formValues.work_group_results;
        }
      this.formValues = _.cloneDeep(data);
    },
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
  },

  /**
   * Important: clear timeout
   */
  beforeDestroy() {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout);
  },
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
