import Api, { Mock } from "../api";
import mock_detail_machine from "./mock/machine_field_detail.json";

const URL_MACHINE_FIELD_INVENTORIES = `${process.env.VUE_APP_API_BASE_URL}/machine_field_inventories`;
const GET_DETAIL_MACHINE_URL = `${URL_MACHINE_FIELD_INVENTORIES}/detail`;
const UPDATE_MACHINE_INVENTORIES_URL = `${URL_MACHINE_FIELD_INVENTORIES}/update`;
const UPDATE_MACHINE_INVENTORIES_PROHIBIT_STATUS_URL = `${URL_MACHINE_FIELD_INVENTORIES}/update_prohibit_status`;

const URL_MACHINE_FIELD = `${process.env.VUE_APP_API_BASE_URL}/machine_fields`;
const GET_LIST_MACHINE_FIELD_URL = `${URL_MACHINE_FIELD}/list`;
const UPDATE_MACHINE_FIELD_RELATION_URL = `${URL_MACHINE_FIELD}/update_machine_field_relation`;

// .envのVUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  Mock.onGet(new RegExp(`${GET_DETAIL_MACHINE_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_detail_machine,
  });
}

export const machineField = {
  getMachineFieldDetail: async (params) => {
    return Api.get(`${GET_DETAIL_MACHINE_URL}/${params}`);
  },
  getListMachineField: async (params) => {
    return Api.get(GET_LIST_MACHINE_FIELD_URL, params);
  },
  updateMachineFieldRelation : async (params) => {
    return Api.post(UPDATE_MACHINE_FIELD_RELATION_URL, params);
  },
  updateMachineInventories : async (params) => {
    return Api.post(UPDATE_MACHINE_INVENTORIES_URL, params);
  },
  updateMachineInventoryProhibitStatus : async (params) => {
    return Api.post(UPDATE_MACHINE_INVENTORIES_PROHIBIT_STATUS_URL, params);
  },
};
