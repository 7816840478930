<template>
  <div>
    <v-app-bar class="appHeader pa-0" dense>
      <div class="appHeader__inner">
        <div class="appHeader__title">
          {{ title }}
        </div>
        <!-- close -->
        <button class="appHeader__btn" @click="close">
          <v-icon class="appHeader_icon" right> mdi-close </v-icon>
        </button>
      </div>
    </v-app-bar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "チャット一覧",
    };
  },

  methods: {
    close() {
      window.open("uniwebview://action?func=close");
    },
  },
};
</script>
<style lang="scss">
.appHeader {
  .v-toolbar__content {
    padding: 0 !important;
    width: 100%;
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.appHeader {
  .appHeader-siteSelect {
    font-size: 11px;
  }
  .appHeader-date {
    font-size: 10px;
  }

  .v-toolbar__content {
    padding: 0 !important;
  }
}
.appHeader__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.appHeader__title {
  font-size: 14px;
  padding-right: 8px;
  color: $color_primary;
  padding-left: 8px;
}

.appHeader__btn {
  width: 48px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.appHeader_icon {
  display: inline !important;
  margin: 0 !important;
}
</style>
