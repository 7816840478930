<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <!--  (共通) 会社ヘッダー -->
        <CompanyHeader />
        <v-container fluid grid-list-lg @click="close">
          <v-layout class="mx-md-8" row wrap>
            <v-flex md4 sm12>
              <v-card
                class="mt-md-9 mt-sm-2 py-5 pl-4"
                elevation="3"
                min-height="87"
              >
                <v-list-item class="px-0 py-0">
                  <v-list-item-content class="px-0 pt-0 pb-2">
                    <v-list-item-title
                      v-text="companyName"
                      class="company-name mb-1"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-text="address"
                      class="py-2"
                      color="grey darken-1"
                      dark
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="mt-n5 mr-3">
                    <v-btn
                      icon
                      fab
                      small
                      dark
                      depressed
                      class="green accent-2"
                      @click="openSettingForm(item.id)"
                    >
                      <v-icon v-text="item.icon"></v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-spacer></v-spacer>
                <v-flex
                  lg8
                  xs10
                  sm10
                  d-flex
                  px-0
                  py-0
                  v-if="popups.isShowSelect"
                >
                  <v-select
                    v-model="selectOffice"
                    item-text="name"
                    item-value="id"
                    :items="listCompanyBranches"
                    hide-details
                    background-color="grey lighten-2"
                    color="black"
                    solo
                    flat
                    @input="loadData(selectOffice)"
                  ></v-select>
                </v-flex>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout class="mx-md-8" row wrap>
            <v-flex md4 xs12>
              <TableLabel @open="goPage" label="現場" path="/master/sites">
                <SiteTable />
              </TableLabel>
            </v-flex>
            <v-flex md4 xs12>
              <TableLabel @open="goPage" label="社員" path="/master/employees">
                <WorkersTable />
              </TableLabel>
            </v-flex>
            <v-flex md4 xs12 v-if="popups.isCompany">
              <TableLabel @open="goPage" label="支店" path="/master/offices">
                <BranchesTable />
              </TableLabel>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </DefaultLayout>

    <Popup :dialog="popups.isShowSettingForm && popups.isShowSettingCompany">
      <CompanySettingForm
        :companyId="popups.companyId"
        @cancel="closeSettingForm"
      />
    </Popup>
    <Popup :dialog="popups.isShowSettingForm && !popups.isShowSettingCompany">
      <OfficeForm
        :companyBranchId="companyBranchId"
        @cancel="closeSettingForm"
      />
    </Popup>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import CompanyHeader from "@/components/companyHeader/CompanyHeader";
import TableLabel from "@/components/companyPortal/TableLabel";

/**
 * 会社ポータルのみで使用するコンポーネント、関数
 */
import SiteTable from "./components/SiteTable";
import WorkersTable from "./components/WorkersTable";
import BranchesTable from "./components/BranchesTable";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import CompanySettingForm from "@/components/forms/company/CompanySettingForm";
import OfficeForm from "@/components/forms/offices/OfficeForm";
import { HEADER_MENU_ITEMS_INHOUSE } from "@/constants/GLOBALHEADER"; //グローバルヘッダーメニュー

/**
 * 定数
 * この一覧ページで使用する固有の定数を定義します。
 * 共通に使用する定数は基本的に@/constants/で定義します。
 * - 定数は大文字で定義します
 * - 定数は基本的にはdataに代入しないで直接参照します
 */

const CORPORATE_RATING_POSITION = {
  PREFIX: 1,
  SUFFIX: 2
};

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: "ポータル" };
    },
  },
  data() {
    return {
      item: {},
      popups: {
        isShowSettingForm: false,
        isCompany: false,
        isShowSelect: false,
        isShowSettingCompany: false,
        companyId: 0,
      },
      listCompanyBranches: [],
      selectOffice: 0,
      companyBranchId: 0,
      isUserCompany: false,
      PREFECTURES: [],
      CORPORATE_STATUS: [],
      CORPORATE_RATING_POSITION: CORPORATE_RATING_POSITION,
      address: "",
      companyName: "",
    };
  },

  components: {
    //共通のコンポーネント
    DefaultLayout,
    CompanyHeader,
    TableLabel,
    SiteTable,
    WorkersTable,
    BranchesTable,
    Popup,
    CompanySettingForm,
    OfficeForm,
  },

  computed: {},

  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    this.$watch(
      () => Store.getters["CmnMst/get"],
      (data) => {
        if (data && data.entries) {
          this.PREFECTURES = [...data.entries.cmn_mst_prefectures];
          this.CORPORATE_STATUS = [...data.entries.cmn_mst_corporate_status];
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * グローバルヘッダーメニューを更新
     */
    await Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_INHOUSE.PORTAL.id,
    });

    this.$watch(
      () => Store.getters["Offices/getCompanyBranches"],
      (data) => {
        let offices = [...data.company_branches];
        if (this.popups.isCompany) {
          offices.unshift({ id: 0, name: this.item.name });
        }
        this.listCompanyBranches = offices;
      },
      {
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters[`Offices/getOfficeDetail`],
      (data) => {
        let _item = { ...this.item };
        _item["name"] = data.company_branches.name;
        _item["address"] = data.company_branches.building_name;
        _item["city_name"] = data.company_branches.city_name;
        _item["cmn_mst_prefecture_id"] = data.company_branches.cmn_mst_prefecture_id;
        this.item = _item;
        this.concatAddress();
        this.convertCompanyName();
      },
      {
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["CompanyPortal/getCompany"],
      (data) => {
        let _item = { ...this.item };
        _item["name"] = data.name;
        _item["address"] = data.address;
        _item["city_name"] = data.city;
        _item["cmn_mst_prefecture_id"] = data.cmn_mst_prefecture_id;
        _item["corporate_rating_position"] = data.corporate_rating_position;
        _item["corporate_status_id"] = data.corporate_status_id;
        this.item = _item;
        this.concatAddress();
        this.convertCompanyName();

        let _companyBranches = [...this.listCompanyBranches];
        _companyBranches.splice(0, 1, { id: 0, name: data.name });
        this.listCompanyBranches = _companyBranches;
      },
      {
        deep: true,
      }
    );

    /**
     * (共通)
     * ここからapiにリクエストします
     */
    await this.getItems();
    await this.getData();
    this.concatAddress();
    this.convertCompanyName();
  },
  methods: {
    convertCompanyName() {
      if(this.CORPORATE_STATUS && this.CORPORATE_STATUS.length > 0) {
        if (!this.isUserCompany) {
          this.companyName = this.item.name;
          return;
        }
        if (!this.item.corporate_status_id) {
          this.companyName = this.item.name;
          return;
        }
        if (this.item.corporate_rating_position === this.CORPORATE_RATING_POSITION.PREFIX){
          this.companyName = this.CORPORATE_STATUS.find(element => element.id === this.item.corporate_status_id)?.name + " " + this.item.name;
        } else if (this.item.corporate_rating_position === this.CORPORATE_RATING_POSITION.SUFFIX) {
          this.companyName = this.item.name + " " + this.CORPORATE_STATUS.find(element => element.id === this.item.corporate_status_id)?.name;
        } else {
          this.companyName = this.item.name;
        }
      }
    },
    
    concatAddress() {
      if(this.PREFECTURES && this.PREFECTURES.length > 0) {
        let addressInfo = [];
        addressInfo.push(this.PREFECTURES.find(element => element.id === this.item.cmn_mst_prefecture_id)?.prefecture);
        addressInfo.push(this.item.city_name);
        if (this.item.address) {
          addressInfo.push(this.item.address);
        }
        this.address = addressInfo.join(" ");
      }
    },

    goPage(path) {
      this.$router.push(path);
      Store.dispatch(
        "PortalChart/setCompanyBranchId",
        this.item.company_branch_id
      );
    },

    /**
     *  (共通)
     * ストア / api
     * ストア実装ルールにしたがっている場合は、
     */

    // データ取得
    async getItems() {
      const companyUser = JSON.parse(sessionStorage.getItem("COMPANY_USER"))
        .Login.company_user;
      const item = { ...this.item };
      if (companyUser.company_id && companyUser.company_branch_id) {
        const apiParams = {
          company_id: companyUser.company_id,
          company_branch_id: companyUser.company_branch_id,
        };
        await Store.dispatch("Offices/getDetail", apiParams);
        item.id = companyUser.company_branch_id;
        item.company_id = companyUser.company_id;
        item.company_branch_id = companyUser.company_branch_id;
        item.name = this.item.name;
        item.address = this.item.address;
        item.city_name = this.item.city_name;
        item.cmn_mst_prefecture_id = this.item.cmn_mst_prefecture_id;
        item.icon = "mdi-cog-outline";
        this.companyBranchId = companyUser.company_branch_id;
      } else {
        const id = companyUser.company_id;
        await Store.dispatch("CompanyPortal/get", { id });
        item.id = companyUser.company_id;
        item.company_id = companyUser.company_id;
        item.name = this.item.name;
        item.address = this.item.address;
        item.city_name = this.item.city_name;
        item.cmn_mst_prefecture_id = this.item.cmn_mst_prefecture_id;
        item.corporate_rating_position = this.item.corporate_rating_position;
        item.corporate_status_id = this.item.corporate_status_id;
        item.icon = "mdi-cog-outline";
        this.popups.isCompany = true;
        this.popups.isShowSelect = true;
        this.popups.isShowSettingCompany = true;
        this.isUserCompany = true;
      }
      this.item = item;
      Store.dispatch(
          "PortalChart/setCompanyBranchId",
          this.item.company_branch_id
      );
    },

    async getData() {
      const apiParams = {
        location_flag: 1, // get list of site that is using for portal dashboard
        company_id: this.item.company_id,
        company_branch_id: this.item.company_branch_id,
        page_size: 25,
      };
      await Store.dispatch("Sites/get", { params: apiParams });
      await Store.dispatch("GlobalHeader/searchSites", { keyword: ""});
      await Store.dispatch("Employees/get", { params: apiParams });
      if (this.popups.isCompany) {
        await Store.dispatch(`Offices/getByCompanyId`, this.item.company_id);
      }
    },

    loadData(value) {
      if (value !== 0) {
        this.item.company_branch_id = value;
        let popups = { ...this.popups };
        popups.isCompany = false;
        this.popups = popups;
      } else {
        delete this.item.company_branch_id;
        let popups = { ...this.popups };
        popups.isCompany = true;
        popups.isShowSelect = true;
        popups.isShowSettingCompany = true;
        this.popups = popups;
      }
      this.getData();
    },

    openSettingForm(companyId) {
      this.popups.companyId = companyId;
      this.popups.isShowSettingForm = true;
    },
    closeSettingForm() {
      this.popups.isShowSettingForm = false;
    },

    close() {
      Store.dispatch("Drawer/close");
    },
  },
};
</script>
<style lang="scss" scoped>
.company-name {
  font-size: 18px !important;
  font-weight: bold;
  color: green;
}

.v-text-field__details {
  display: none !important;
}
</style>
