import Api, { Mock } from "../api";
import mock_cranes from "./mock/cranes.json";
import crane_detail from "./mock/crane_detail.json";

const CRANES_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/cranes`;
const GET_LIST_URL = `${CRANES_BASE_URL}/list`;
const GET_DETAIL_CRANE_URL = `${CRANES_BASE_URL}/detail`;
const DELETE_URL = `${CRANES_BASE_URL}/delete`;
const EDIT_URL = `${CRANES_BASE_URL}/update`;
const CREATE_URL = `${CRANES_BASE_URL}/create`;
const LIST_CRANE_BY_FIELD_URL = `${CRANES_BASE_URL}/list_crane_by_field`;

// .envのVUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  Mock.onGet(GET_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_cranes,
  });

  Mock.onGet(new RegExp(`${GET_DETAIL_CRANE_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: crane_detail,
  });

  Mock.onPost(EDIT_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(CREATE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(DELETE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(new RegExp(`${LIST_CRANE_BY_FIELD_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        {
          id: 1,
          crane_name: "Aクレーン",
        },
        {
          id: 2,
          crane_name: "Bクレーン",
        },
      ],
    },
  });
}

export const cranes = {
  get: (params) => {
    return Api.get(GET_LIST_URL, params);
  },
  getDetail: async (params) => {
    return Api.get(`${GET_DETAIL_CRANE_URL}/${params}`);
  },
  add: (params) => {
    return Api.post(CREATE_URL, params);
  },
  edit: async (params) => {
    return Api.post(EDIT_URL, params);
  },
  delete: async (params) => {
    return Api.post(DELETE_URL, params);
  },
  getListCraneByField: (params) => {
    return Api.get(`${LIST_CRANE_BY_FIELD_URL}/${params}`);
  },
};
