<template>
  <div class="mt-5 mb-cst">
    <!-- List button -->
    <template>
      <v-btn
        depressed
        class="mx-3"
        color="primary"
        v-if="roleUser !== ROLE_USER[4]"
        :disabled="isDisabledBtn"
        @click="openFormRegisterMachineField()"
      >
        使用申請
      </v-btn>
      <v-btn
        depressed
        class="mx-3"
        color="primary"
        v-if="roleUser !== ROLE_USER[4]"
        :disabled="isDisabledBtn"
        @click="openFormRegisterReturn()"
      >
        返却申請
      </v-btn>
      <v-btn
        class="mx-3"
        small
        icon
        :disabled="isDisabledBtn"
        @click="openRemoveDialog()"
      >
        <v-icon> mdi-delete </v-icon>
      </v-btn>
    </template>
    <!-- parent table --> 
    <v-data-table
      hide-default-header
      :headers="tableLabels"
      v-model="selectedItems"
      :items="dataTable"
      :items-per-page="itemsPerPage"
      :height="tableHeight-40"
      fixed-header
      hide-default-footer
      disable-sort
      :show-select="true"
      class="elevation-0 v-data-table__wrapper"
      sort-by="updatedAt"
      item-key="machine_company_field_detail_id"
      :noDataText="NO_DATA_MESSAGE"
      @click:row="openItemForm"
    >
      <template slot="header" :headers="tableLabels">
        <thead>
          <tr>
            <th style="width: 50px">
              <v-simple-checkbox
                :value="selectedItems.length === dataTable.length && dataTable.length > 0"
                @input="clickCheckAll($event)"
              ></v-simple-checkbox>
            </th>
            <th v-for="(h,index) in tableLabels" :key="h.value" :style="getWidthStyle(index)">
              <div v-for="text in convertTextHeaderArr(h.text)" :key="text">
                <div>{{text}}</div>
              </div>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:[`item.used_apply_status`]="{ item }" label small>
        <v-chip
          small
          :dark="item.used_apply_status === 0"
          :color="setColor(item.used_apply_status)"
          >{{ getStatusValue(item.used_apply_status) }}</v-chip
        >
      </template>
      <template v-slot:[`item.machine_type_extral_machine_name`]="{ item }" label small>
        <div class="cst-overflow-td">{{ item.machine_company_type_item_tree_name }}</div>
        <div class="cst-overflow-td">{{ item.machine_name }}</div>
      </template>
      <template v-slot:[`item.delivery_company_name_extral_machine_number`]="{ item }" label small>
        <div class="cst-overflow-td">{{ item.delivery_company_name }}</div>
        <div class="cst-overflow-td">{{ item.machine_number }}</div>
      </template>
      <template v-slot:[`item.used_company_name_extral_foreman_name`]="{ item }" label small>
        <div class="cst-overflow-td">{{ item.used_company_name }}</div>
        <div class="cst-overflow-td">{{ item.foreman_name }}</div>
      </template>
      <template v-slot:[`item.used_start_date_extral_used_end_date`]="{ item }" label small>
        <div class="cst-overflow-td">{{ item.used_start_date }}</div>
        <div class="cst-overflow-td">{{ item.used_end_date }}</div>
      </template>
      <template v-slot:[`item.inspection_results`]="{ item }" label small>
        <!-- Condition show btn TODO -->
        <v-btn
          v-if="item.has_inspection_result_flag == HAS_INSPECTION_RESULT"
          @click="openInspecResultForm(item)"
          class="primary">点検実績</v-btn>
      </template>
    </v-data-table>

    <Popup :dialog="popups.isShowPreviewDeviceForm">
      <PreviewDeviceForm
        :flagError="flagError"
        :items="dataRegister"
        :requiredEndDate="requiredEndDate"
        :machineSelected="selectedItems"
        :isNewItem="true"
        @formUpdate="formUpdate"
        @submit="updateMachineIvtRegister()"
        @cancel="closePreviewDeviceForm()"
        :isReturn="isReturn"
        ref="PreviewDeviceForm"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        :flagError="flagError"
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :selectedItems="selectedItems"
        warning
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowPreviewDialog">
      <ConfirmDialog
        @OK="closePreviewDialog()"
        :title="popups.title"
        :message="popups.message"
        warning
      />
    </Popup>
    <Popup :dialog="popups.isShowItemForm">
      <MachineFieldForm
        @updateListItems="resetDataItems"
        @cancel="closeMachineFieldForm"
        :isShowButtonUsageProhibited="isShowButtonUsageProhibited"
        :isShowButtonEnable="isShowButtonEnable"
        ref="MachineFieldForm"
      />
    </Popup>
    <Popup :dialog="popups.isClickOpenFormInspec">
      <InspectionResultForm
        :itemInspection="itemInspection"
        @updateListItems="resetDataItems"
        @cancel="closeInspecResultForm"
        ref="InspectionResultForm"
      />
    </Popup>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import { ROLE_USER, STATUS_COLORS, USED_APPLY_STATUS, REGISTER_TYPE, INIT_DATA_FORM_REGISTER, INFO_CONFIRM_DIALOG, HAS_INSPECTION_RESULT } from "@/constants/DEVICE_STOCK";
import PreviewDeviceForm from "@/components/forms/device/stock/preview_device/index.vue";
import Popup from "@/components/common/Popup";
import ConfirmDialog from "./ConfirmDialog";
import ConfirmRemoveDialog from "./ConfirmRemoveDialog";
import MachineFieldForm from "@/components/forms/device/stock/detail_update/MachineFieldForm.vue";
import InspectionResultForm from "@/components/forms/device/stock/inspection_result/InspectionResultForm.vue";
import _ from "lodash";
const STORE = "DeviceStocks";
const STORE_INSPEC = "MachineFieldInspection";

export default {
  data() {
    return {
      isReturn : false,
      NO_DATA_MESSAGE,
      STATUS_COLORS,
      HAS_INSPECTION_RESULT,
      ROLE_USER,
      USED_APPLY_STATUS,
      REGISTER_TYPE,
      INFO_CONFIRM_DIALOG,
      dataTable: [],
      selectedItems: [],
      registerType: null,
      flagError: false,
      popups: {
        isShowPreviewDeviceForm: false,
        isShowPreviewDialog: false,
        isShowRemoveDialog: false,
        isShowItemForm: false,
        isClickOpenFormInspec: false,
        title: "",
        message: ""
      },
      isShowButtonUsageProhibited: false,
      isShowButtonEnable: false,
      dataRegister: { ...INIT_DATA_FORM_REGISTER },
      requiredEndDate: false,
      itemInspection: {},
    };
  },
  components: {
    PreviewDeviceForm,
    Popup,
    ConfirmDialog,
    ConfirmRemoveDialog,
    MachineFieldForm,
    InspectionResultForm,
  },
  props: {
    tableLabels: Array,
    items: Array,
    itemsPerPage: Number,
    tableHeight: Number,
    roleUser: Number,
    resetSelected : Boolean
  },
  mounted() {
     this.$watch(
      () => this.items,
      (newValue) => {
        this.dataTable = [...newValue];
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.resetSelected,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          this.selectedItems = [];
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => Store.getters[`${STORE}/getUsage`],
      (data) => {
        if (data) {
          this.dataRegister = _.cloneDeep(data);
        }
      },
      {
        deep: true
      }
    );
  },
  computed: {
    isDisabledBtn() {
      return this.selectedItems.length === 0;
    },
    getFieldId() {
      return Store.getters["GlobalHeader/getCurrentSite"]?.field_id;
    },
  },
  methods: {
    /**
     * set width for column
     */
    getWidthStyle(column) {
      let style = "";
      if (column == 0) {
        style = 'width: 100px';
      } else if (column == 2) {
        style = 'width: 25vw';
      } else {
        style = 'width: 10vw';
      }
      return style;
    },
    /**
     * open detail machine iventory
     * @param item : item click
     * @returns {Promise<void>} : item detail
     */
    async openItemForm(item) {
      if (this.popups.isClickOpenFormInspec) return;
      switch (item.used_apply_status) {
        case USED_APPLY_STATUS.UNUSE_STATUS.status:
        case USED_APPLY_STATUS.BOOKED_STATUS.status:
        case USED_APPLY_STATUS.USING_STATUS.status:
        case USED_APPLY_STATUS.RETURN_BOOK_STATUS.status:
          this.isShowButtonUsageProhibited = true;
          this.isShowButtonEnable = false;
          break;
        case USED_APPLY_STATUS.UNUSABLE_STATUS.status :
          this.isShowButtonEnable = true;
          this.isShowButtonUsageProhibited = false;
          break;
        default :
          this.isShowButtonUsageProhibited = false;
          this.isShowButtonEnable = false;
      }
      const rs = await Store.dispatch(`${STORE}/getMachineFieldDetail`, item.machine_company_field_detail_id);
      // Show Detail Form
      if (!rs.hasError) {
        this.popups.isShowItemForm = true;
      }
    },
    /**
     * close form edit machine inventory
     */
    closeMachineFieldForm(){
      this.popups.isShowItemForm = false;
    },
    /**
     * reset data items when update success
     */
    resetDataItems(){
      this.$emit('resetDataItems');
    },
    /**
     * Event click checkbox in header table
     */
    clickCheckAll(event) {
      if (event) {
        this.selectedItems = this.dataTable;
      } else {
        this.selectedItems = [];
      }
    },
    /**
     * Convert text header to arrray
     */
    convertTextHeaderArr(textHeader) {
      let textHeaderArr = [];
      if (textHeader.includes(" / ")) {
        textHeaderArr = textHeader.split(" / ");
        textHeaderArr[1] = "/ ".concat(textHeaderArr[1]);
      } else {
        textHeaderArr.push(textHeader);
      }
      return textHeaderArr;
    },
    setColor(applyStatus) {
      return this.STATUS_COLORS[applyStatus]?.color;
    },
    getStatusValue(applyStatus) {
      return this.STATUS_COLORS[applyStatus]?.value;
    },
    openFormRegisterMachineField() {
      const listItemsCanUse = this.selectedItems.filter(e => e.used_apply_status == USED_APPLY_STATUS.UNUSE_STATUS.status || e.used_apply_status == USED_APPLY_STATUS.BOOKED_STATUS.status || e.used_apply_status == USED_APPLY_STATUS.RETURN_BOOK_STATUS.status);
      if (listItemsCanUse.length == 0) {
        this.popups.title = INFO_CONFIRM_DIALOG.REGISTER_MACHINE_FIELD_2.title;
        this.popups.message = INFO_CONFIRM_DIALOG.REGISTER_MACHINE_FIELD_2.message;
        this.popups.isShowPreviewDialog = true;
      } else if (listItemsCanUse.length < this.selectedItems.length) {
        this.popups.title = INFO_CONFIRM_DIALOG.REGISTER_MACHINE_FIELD_1.title;
        this.popups.message = INFO_CONFIRM_DIALOG.REGISTER_MACHINE_FIELD_1.message;
        this.popups.isShowPreviewDialog = true;
      } else {
        this.registerType = REGISTER_TYPE.REGISTER_USE.type;
        this.popups.isShowPreviewDeviceForm = true;
      }
    },
    /**
     * Show popup confirm register return
     */
    async openFormRegisterReturn() {
      const listItemCanReturn = this.selectedItems.filter(e => e.used_apply_status == USED_APPLY_STATUS.USING_STATUS.status);
      if (listItemCanReturn.length == 0) {
        this.popups.title = INFO_CONFIRM_DIALOG.REGISTER_RETURN_2.title;
        this.popups.message = INFO_CONFIRM_DIALOG.REGISTER_RETURN_2.message;
        this.popups.isShowPreviewDialog = true;
      } else if (listItemCanReturn.length < this.selectedItems.length) {
        this.popups.title = INFO_CONFIRM_DIALOG.REGISTER_RETURN_1.title;
        this.popups.message = INFO_CONFIRM_DIALOG.REGISTER_RETURN_1.message;
        this.popups.isShowPreviewDialog = true;
      } else {
        this.registerType = REGISTER_TYPE.REGISTER_RETURN.type;
        this.requiredEndDate = true;
        let id = this.selectedItems[0].machine_company_field_history_id;
        await Store.dispatch(`${STORE}/getUsage`, id);
        this.isReturn = true;
        this.popups.isShowPreviewDeviceForm = true;
      }
    },
    closePreviewDeviceForm() {
      this.isReturn = false;
      this.requiredEndDate = false;
      this.selectedItems = [];
      this.dataRegister = {...INIT_DATA_FORM_REGISTER};
      this.popups.isShowPreviewDeviceForm = false;
    },
    closePreviewDialog() {
      this.selectedItems = [];
      this.popups.isShowPreviewDialog = false;
    },
    formUpdate(params) {
      this.dataRegister = {...this.dataRegister, ...params};
    },
    /**
     * Call Api updateMachineInventoryRegister
     */
    async updateMachineIvtRegister() {
      const dataRegister = {...this.dataRegister };
      dataRegister.field_id = this.getFieldId;
      dataRegister.register_type = this.registerType;
      if (dataRegister.register_type == 0) {
        dataRegister['machine_company_field_detail_ids'] = this.selectedItems.map((items) => items.machine_company_field_detail_id);
      } else {
        dataRegister['machine_company_field_history_ids'] = this.selectedItems.map((items) => items.machine_company_field_history_id);
      }
      this.dataRegister = dataRegister;
      const result = await Store.dispatch(`${STORE}/updateMachineInventoryRegister`, this.dataRegister);
      if (result.hasError) {
        this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.response.data.message,
        });
        return;
      } else {
        this.closePreviewDeviceForm();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
        this.$emit('resetDataItems');
      }
    },
    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.selectedItems = [];
      this.popups.isShowRemoveDialog = false;
    },
    /**
     * Delete machine company field
     */
    async removeItems() {
      const machine_company_field_detail_ids = this.selectedItems.map((items) => items.machine_company_field_detail_id);
      const result = await Store.dispatch(`${STORE}/delete`, { machine_company_field_detail_ids });

      if (result.hasError) {
        this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.response.data.message,
        });
        return;
      } else {
        this.closeRemoveDialog();
        let ids = [...result.data.contents.entries.ids];
        if (ids.length > 0) {
          Store.dispatch("Toast/show", {
            status: 200,
            message: "削除しました"
          });
          this.$emit("resetDataItems");
        } else {
          this.popups.title = INFO_CONFIRM_DIALOG.CONFIRM_DELETE_ERROR.title;
          this.popups.message = INFO_CONFIRM_DIALOG.CONFIRM_DELETE_ERROR.message;
          this.popups.isShowPreviewDialog = true;
        }
      }
    },

    async openInspecResultForm(item) {
      this.popups.isClickOpenFormInspec = true;
      const params = {
        machine_company_field_detail_id: item.machine_company_field_detail_id,
      };
      const result = await Store.dispatch(`${STORE_INSPEC}/getInspectionResults`, params);
      if (!result.hasError) {
        this.itemInspection = { ...item };
      } else {
        this.popups.isClickOpenFormInspec = false;
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
    },
    closeInspecResultForm() {
      this.popups.isClickOpenFormInspec = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.cst-overflow-td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mb-cst {
  margin-bottom: 2vh !important;
}
::v-deep .v-data-table .v-data-table__wrapper table {
  table-layout: fixed;
}
::v-deep .v-data-table .v-data-table__wrapper table tbody tr td {
  word-break: break-all;
}
</style>
