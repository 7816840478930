<template>
  <div>
    <v-container>
      <v-row v-for="(item, index) in items" :key="index">
        <v-col cols="12" sm="5" md="5" class="pt-0 pb-0">
          <Label label="健康診断種別名" :editable="editable">
            <Select
              name="sub_catergory_id"
              :values="item"
              :items="selectItems"
              item_text="sub_catergory"
              :editable="editable"
              @onInput="onInput({ value: $event, index })"
            />
          </Label>
        </v-col>
        <v-col cols="12" sm="5" md="5" class="pt-0 pb-0">
          <Label label="健康診断受診日" :editable="editable">
            <InputDatepicker
              name="health_checkup_date"
              :values="item"
              :editable="editable"
              placeholder=""
              :max="max"
              validation_label="健康診断受診日"
              :flagNull="true"
              @onInput="onInput({ value: $event, index })"
            />
          </Label>
        </v-col>
        <v-col cols="12" sm="2" md="2" class="pt-0 pb-0">
          <v-btn
            v-if="index >= row && editable"
            class="mt-8"
            icon
            @click="remove(index)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-btn v-if="editable" depressed small color="primary" @click="addEmpty"
      >追加</v-btn
    >
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "../../elements/Label.vue";
import Select from "../../elements/Select.vue";
import InputDatepicker from "../../elements/InputDatepicker.vue";

//初期値を設定します
const INITIAL_VALUES = {
  sub_catergory_id: null,
  health_checkup_date: null,
};

export default {
  data: () => {
    return {
      items: [],
      max : new Date().toISOString().slice(0, 10)
    };
  },
  components: {
    Label,
    InputDatepicker,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    selectItems: {
      type: Array,
    },
    row: {
      type: Number,
      default: 1,
    },
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue, oldValue) => {
        if (this.items?.length !== newValue[0][newValue[1]]?.length
          || JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.items = newValue[0][newValue[1]];
        }
        if(!this.items) {
          this.items = [];
        }
        if (this.name === "special_medical_examination") {
          while (this.items?.length < 4) {
            this.addEmpty();
          }
        } else if (this.items?.length === 0) {
          this.addEmpty();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      this.items.push({ ...INITIAL_VALUES });
    },
  },
};
</script>
