<template>
  <div class="wrapper">
    <Select
      :name="name"
      :items="items"
      :editable="true"
      @onInput="onInput"
      :multiple="true"
      item_text="name"
      item_value="id"
      :isReset="isReset"
    />
  </div>
</template>
<script>
import Select from "@/views/schedule/usage/components/SelectMulti.vue";
import { Store } from "@/store/Store.js";

export default {
  data: () => {
    return {
      items: [],
    };
  },
  props: {
    name: {
      type: String,
    },
    fieldId: {
      type: Number,
    },
    isReset: Boolean
  },
  components: {
    Select,
  },
  mounted() {
    /**
     * 工事
     */
    this.$watch(
      () => Store.getters["Gates/getListGateByField"],
      (newValue) => {
        if(newValue) {
        this.items = newValue;
        }
      },
      { deep: true }
    );
    /**
     * 現場idから工事を取得
     */
    this.$watch(
      () => this.fieldId,
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          if(newValue) {
            Store.dispatch("Gates/getListGateByField", newValue);
          }
        }
      },
      { immediate: true, deep: true }
    );
  },
  methods: {
    onInput({ name, value }) {
      this.$emit("onInput", { name, value });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  height: 40px;
}
</style>
