<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row class="fixed-header">
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content class="cst-header">
                      入力項目
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content class="cst-header">
                      必須入力
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content class="cst-header">
                      ビューア表示
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content class="cst-header">
                      出力項目
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 日付 1 -->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      日付  
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.passage_date['form_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.passage_date['required']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.passage_date['timebar_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.passage_date['report_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 時間 2 -->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      時間 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.usage_time['form_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.usage_time['required']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.usage_time['timebar_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.usage_time['report_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 作業時間帯 3 -->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      作業時間帯 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.work_time_zone['form_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.work_time_zone['required']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.work_time_zone['timebar_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.work_time_zone['report_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 協力会社 4 -->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      協力会社 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_tree_id_1['form_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_tree_id_1['required']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_tree_id_1['timebar_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_tree_id_1['report_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 職種 5-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      職種 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.job_id['form_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.job_id['required']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.job_id['timebar_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.job_id['report_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 担当者氏名 6-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      担当者氏名 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_tree_user_id['form_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_tree_user_id['required']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_tree_user_id['timebar_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_tree_user_id['report_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 担当者連絡先 7-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      担当者連絡先 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_tree_user_tel['form_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_tree_user_tel['required']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_tree_user_tel['timebar_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_tree_user_tel['report_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 元請担当者 8-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      元請担当者 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_director_id['form_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_director_id['required']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_director_id['timebar_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_director_id['report_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- ゲート 9-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      ゲート 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.gate_id['form_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.gate_id['required']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.gate_id['timebar_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.gate_id['report_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 車種 10-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      車種 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.cmn_mst_vehicle_type_id['form_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.cmn_mst_vehicle_type_id['required']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.cmn_mst_vehicle_type_id['timebar_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.cmn_mst_vehicle_type_id['report_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 台数 11-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      台数 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.truck_count['form_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.truck_count['required']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.truck_count['timebar_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.truck_count['report_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- ステータス 12-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      ステータス 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.status_code['form_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.status_code['required']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.status_code['timebar_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.status_code['report_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 運送会社 13-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      運送会社 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.shipping_company['form_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.shipping_company['required']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.shipping_company['timebar_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.shipping_company['report_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 運送会社電話番号  14-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      運送会社電話番号   
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.shipping_company_tel['form_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.shipping_company_tel['required']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.shipping_company_tel['timebar_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.shipping_company_tel['report_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 荷種 15-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      荷種   
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.commodity_type['form_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.commodity_type['required']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.commodity_type['timebar_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.commodity_type['report_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 使用揚重機 16-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      使用揚重機 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.crane_id['form_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.crane_id['required']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.crane_id['timebar_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.crane_id['report_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import InputDatepicker from "@/components/forms/elements/InputDatepicker";
import Select from "@/components/forms/elements/Select";
import InputTextArea from "@/components/forms/elements/InputTextArea";

export default {
  components: {
    Label,
    SectionLabel,
    InputText,
    Select,
    InputDatepicker,
    InputTextArea,
  },
  props: {
    mainHeight: {
      type: Number,
      default: 0,
    },
    gate_passages: {
      type: Object,
      default: {},
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    onChange() {
      this.$emit("formUpdate", { name: `gate_passages`, value: this.gate_passages})
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/forms.scss";
.FormDialog .container>.row>*, .FormDialog .container>.row>*>.row>* {
  padding: 6px!important;
}
.FormDialog .container>.row {
  border-bottom: 1px solid #e9e9e9;
  height: 70px;
  div:first-child .v-list-item {
    top: 10px !important;
  }
  div .v-list-item .v-list-item__content .v-input--checkbox {
    padding-left: 9px;
  }
}
.fixed-header {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
}
.cst-header {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  padding-left: 11px !important;
  line-height: 1.75rem;
}
::v-deep .FormMainBody .container .row:not(:first-child) {
  font-size: 16px !important;
}
</style>
