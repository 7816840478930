var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DefaultLayout',{scopedSlots:_vm._u([{key:"mainHeader",fn:function(){return [_c('CompanyHeader')]},proxy:true},{key:"page",fn:function(ref){
var layoutParams = ref.layoutParams;
return [_c('TableLayout',{attrs:{"layoutParams":layoutParams,"hideFooter":_vm.searchParams.pageCount >= _vm.searchParams.total_item},scopedSlots:_vm._u([{key:"tableHeader",fn:function(ref){
var updateHeader = ref.updateHeader;
return [_c('TableHeader',{ref:"tableHeader",attrs:{"pageTitle":_vm.PAGE_TITLE,"multiRemoveStatus":_vm.disableRemoveBtn,"updateHeader":updateHeader,"isInvite":true,"isFormInfo":true},on:{"openRemoveDialog":_vm.openRemoveDialog,"openItemForm":_vm.openNewItemForm,"openInviteDialog":_vm.openInviteEmployeeDialog,"openFormInfoDialog":function($event){_vm.popups.isShowUploadForm = true}}},[_c('SearchFormWrapper',[_c('Label',{attrs:{"label":"社員名"}},[_c('InputText',{attrs:{"name":"word","editable":true,"values":_vm.searchParams},on:{"onInput":_vm.onChangeSearchParams}})],1),_c('v-spacer'),_c('v-btn',{staticClass:"mr-6",attrs:{"color":"primary","depressed":""},on:{"click":_vm.onSearch}},[_vm._v(" 検索 ")])],1)],1),_c('TableSortWrapper',[_c('TableSort',{attrs:{"values":_vm.searchParams,"sort_items":_vm.SORT_ITEMS,"sort_item_text":"name","sort_item_value":"id","page_counts_options":_vm.PAGE_COUNT_OPTIONS,"sort_order_options":_vm.SORT_ORDERS,"total_item":_vm.searchParams.total_item},on:{"onInput":_vm.onChangeSortParams}})],1)]}},{key:"tableBody",fn:function(ref){
var tableHeight = ref.tableHeight;
return [_c('v-data-table',{staticClass:"elevation-0 v-data-table__wrapper",attrs:{"item-key":"id","headers":_vm.TABLE_LABELS,"items":_vm.items,"items-per-page":_vm.searchParams.pageCount,"height":tableHeight,"fixed-header":"","hide-default-footer":"","disable-sort":"","item-class":_vm.rowClass,"sort-by":"updatedAt","show-select":"","noDataText":_vm.noDataMessage},on:{"click:row":_vm.openItemForm},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.convertLongText(item.name, 16))+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.convertLongText(item.email, 40))+" ")])]}},{key:"item.invite_flg",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.handleInviteFlg(item.invite_flg))+" ")]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})]}},{key:"tableFooter",fn:function(){return [_c('Pagination',{attrs:{"current":_vm.searchParams.currentPage,"total":_vm.searchParams.totalPage},on:{"pageUpdate":_vm.pageUpdate}})]},proxy:true}],null,true)})]}}])}),_c('Popup',{attrs:{"dialog":_vm.popups.isShowItemForm}},[_c('EmployeeForm',{attrs:{"item":_vm.editedItem,"isNewItem":_vm.isNewItem,"isErrorSubmit":_vm.isErrorSubmit,"isEmitted":_vm.isEmitted,"isStopUser":_vm.isStopUser},on:{"formUpdate":_vm.formUpdate,"submit":_vm.submitForm,"cancel":_vm.closeItemForm}})],1),_c('Popup',{attrs:{"width":"480px","dialog":_vm.popups.isShowRemoveDialog}},[_c('ConfirmRemoveDialog',{attrs:{"title":"選択項目の削除","text":"以下を削除してもよろしいですか？","items":_vm.selectedItems,"warning":""},on:{"close":function($event){return _vm.closeRemoveDialog()},"yes":function($event){return _vm.removeItems()}}})],1),_c('Popup',{attrs:{"width":"480px","dialog":_vm.popups.isShowInviteEmployeeDialog}},[_c('InviteEmployeeDialog',{attrs:{"title":"ユーザー追加","text1":"招待メールをお送りします。","text2":"メールアドレスが設定されていない社員データに\n招待メールは送信されません。","items":_vm.selectedItems,"isDisable":_vm.inviteSubmitted},on:{"close":function($event){return _vm.closeInviteDialogForm()},"yes":function($event){return _vm.inviteEmployees()}}})],1),_c('Popup',{attrs:{"width":"500px","dialog":_vm.popups.isShowUploadForm}},[_c('UploadInfoDialog',{attrs:{"flagError":_vm.flagError},on:{"close":_vm.closeUploadDialog,"yes":_vm.submitUploadInfo}})],1),_c('Popup',{attrs:{"width":"480px","dialog":_vm.popups.isShowErorDialog}},[_c('ShowErrorDialog',{attrs:{"text":_vm.textError},on:{"close":function($event){_vm.popups.isShowErorDialog = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }