var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DefaultLayout',{scopedSlots:_vm._u([{key:"mainHeader",fn:function(){return [_c('SitePortalHeader')]},proxy:true},{key:"page",fn:function(ref){
var layoutParams = ref.layoutParams;
return [_c('TableLayout',{attrs:{"layoutParams":layoutParams},scopedSlots:_vm._u([{key:"tableHeader",fn:function(ref){
var updateHeader = ref.updateHeader;
return [_c('TableHeader',{ref:"tableHeader",attrs:{"isDelete":false,"isAddNew":false,"isSearch":false,"pageTitle":_vm.PAGE_TITLE,"updateHeader":updateHeader}})]}},{key:"tableBody",fn:function(ref){
var tableHeight = ref.tableHeight;
return [_c('v-data-table',{staticClass:"elevation-0 v-data-table__wrapper",attrs:{"headers":_vm.TABLE_LABELS,"items":_vm.items,"items-per-page":_vm.items.length,"height":tableHeight,"fixed-header":"","hide-default-footer":"","disable-sort":"","item-key":"id"},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertType(item.type))+" ")]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('MarkExcel',{staticClass:"mr-4",attrs:{"name_type":item.report_name_en,"name_title":item.report_name_jp},on:{"openItemForm":_vm.openItemForm}}),_c('MarkPdf',{attrs:{"name_type":item.report_name_en,"name_title":item.report_name_jp},on:{"openItemForm":_vm.openItemForm}})]}}],null,true)})]}}],null,true)})]}}])}),_c('Popup',{attrs:{"dialog":_vm.popups.isShowItemForm,"width":"540px"}},[_c('FormPrintingDialog',{attrs:{"statusFlag":_vm.statusFlag,"report_name_jp":_vm.report_name_jp,"nameFile":_vm.nameFile},on:{"onSubmit":_vm.submitForm,"onCancel":_vm.closeItemForm}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }