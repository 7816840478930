<template>
  <div>
    <div
      v-for="(item, index) in listTime"
      :key="`time_${item.time_type}_${index}`"
      class="schedulePlan"
      :class="getClass(item.time_type)"
      :style="getStyle(item.start_time, item.end_time)"
    >
      <div
        class="start"
        :class="isTime0(item.start_time) ? 'time0' : ''"
        v-if="isFirstDisplay(index, item.start_time)"
      >
        {{ formatDate(item.start_time) }}
      </div>
      <div class="end" v-if="isFirstDisplay(index, item.end_time)">
        {{ formatDate(item.end_time) }}
      </div>
    </div>
  </div>
</template>
<script>
import { differenceInMinutes, format } from "date-fns";
import { SCHEDULE_TYPE } from "@/constants/SCHEDULE_COMPANY";
import moment from "moment";

export default {
  data: () => {
    return {
      listTime: [],
    };
  },
  props: {
    item: {
      type: Object,
    },
    index: {
      type: Number,
    },
    //1時間の分割数
    hourDivide: {
      type: Number,
    },
    //cellRect.widthが1時間の幅ピクセル
    cellRect: {
      type: Object,
    },
    //今日の日付
    today: {
      type: String,
    },
    //今日の開始ピクセル
    todayStartX: {
      type: Number,
    },
    //上部のマージン
    marginTop: {
      type: Number,
    },
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        let { time_summary } = newValue;
        time_summary = time_summary.filter(
          (time) =>
            !(
              new Date(time.start_time) >= this.AFTER_TIME &&
              new Date(time.end_time) > this.AFTER_TIME
            )
        );
        this.listTime = [...time_summary];
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    SCHEDULE_TYPE_ARRAY() {
      return Object.keys(SCHEDULE_TYPE).map((key) => {
        return { ...SCHEDULE_TYPE[key], key };
      });
    },
    BEFORE_TIME() {
      return new Date(this.today);
    },
    AFTER_TIME() {
      return moment(this.today).add(1, "days").add(6, "hours").toDate();
    },
  },
  methods: {
    getClass(type_id) {
      const type = this.SCHEDULE_TYPE_ARRAY.find((item) => {
        return item.id === type_id;
      });
      return `type_${type.key}`;
    },
    formatDate(time) {
      return time ? format(new Date(time), "HH:mm") : "--:--";
    },
    getStyle(startTime, endTime) {
      const today = this.BEFORE_TIME;
      const start =
        new Date(startTime) < this.BEFORE_TIME
          ? this.BEFORE_TIME
          : new Date(startTime);
      const end =
        new Date(endTime) > this.AFTER_TIME
          ? this.AFTER_TIME
          : new Date(endTime);
      const rect = {
        x: null,
        width: null,
      };

      // 継続時間
      const diffMinContinuous = differenceInMinutes(end, start);
      rect.width =
        (diffMinContinuous * (this.cellRect.width * this.hourDivide)) / 60;
      // 開始位置
      const diffMinStart = differenceInMinutes(start, today);
      rect.x =
        this.todayStartX +
        diffMinStart * ((this.cellRect.width * this.hourDivide) / 60);
      rect.top = this.index * this.cellRect.height + this.marginTop + 6;
      rect.height = this.cellRect.height - 7;
      return `width:${rect?.width}px;height:${rect?.height}px;left:${rect?.x}px;top:${rect?.top}px;`;
    },
    isFirstDisplay(index, time) {
      for (let i = 0; i < index; i++) {
        let item = this.listTime[i];
        if (time == item.start_time || time == item.end_time) {
          return false;
        }
      }
      return true;
    },
    // 0:00の時に表示位置を変更する
    isTime0(startTime) {
      return new Date(startTime) <= this.BEFORE_TIME;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.schedulePlan {
  position: absolute;
  z-index: 1;
  border-radius: 4px;
  &:before {
    content: "";
    z-index: 0;
    position: absolute;
    width: 100%;
    height: calc(100% - 8px);
    top: 8px;
    border-radius: 2px;
  }
  &.type_SCHEDULE_TIME {
    &:before {
      background-color: $color_schedule_type_occupy;
    }
  }
  &.type_EARLY_TIME {
    &:before {
      background-color: $color_schedule_type_spot;
    }
  }
  &.type_OVER_TIME {
    &:before {
      background-color: $color_schedule_type_disable;
    }
  }
}

.start {
  font-size: 12px;
  font-weight: normal;
  color: $color_text_sub;
  position: absolute;
  top: -8px;
  left: -24px;
  // transform: scale(0.8);
  &.time0 {
    left: 0;
  }
}
.end {
  font-size: 12px;
  font-weight: normal;
  color: $color_text_sub;
  position: absolute;
  top: -8px;
  right: -24px;
  // transform: scale(0.8);
}
</style>
