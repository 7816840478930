
const convertCurrentcy = (price) => {
  if (price) {
    let number = Intl.NumberFormat("en-US");
    return number.format(price) || "";
  } else {
    return "";
  }
};

export { convertCurrentcy };