<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row>
          <v-col cols="12" sm="5" md="5" v-if="isShowSlinging">
            <v-list-item>
              <v-list-item-content class="row-position">
                <Label label="玉掛者" :editable="editable" :required="isRequiredSlinging">
                  <Select
                    class="select-position"
                    name="slinging_worker_id"
                    :values="item"
                    item_value="user_id"
                    :items="listCompanyUser"
                    :editable="editable"
                    :label="item.slinging_worker_name"
                    validation_label="玉掛者"
                    :validation_rules="isRequiredSlinging?'required':''"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="5" md="5" v-if="isShowSignal">
            <v-list-item>
              <v-list-item-content class="row-position">
                <Label label="合図者" :editable="editable" :required="isRequiredSignal">
                  <Select
                    class="select-position"
                    name="signal_worker_id"
                    :values="item"
                    item_value="user_id"
                    :items="listCompanyUser"
                    :editable="editable"
                    :label="item.signal_worker_name"
                    validation_label="合図者"
                    :validation_rules="isRequiredSignal?'required':''"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col v-if="index > 0 && editable && (isShowSignal || isShowSlinging)" cols="12" sm="2" md="2" class="pt-0 pb-0">
            <v-btn class="mt-8" icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn class="ml-4" v-if="editable && (isShowSignal || isShowSlinging)" color="primary" @click="addEmpty" depressed small
      >追加</v-btn
    >
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "../../../elements/Label.vue";
import Select from "../../../elements/Select.vue";
import { Store } from "@/store/Store.js";

const INITIAL_VALUES = {
  slinging_worker_id: null,
  signal_worker_id: null,
};

export default {
  data: () => {
    return {
      items: [],
      listCompanyUser: [{ user_id: null, name: "" }],
    };
  },
  components: {
    Label,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    isShowSignal: {
      type: Boolean,
      default: true
    },
    isRequiredSignal: {
      type: Boolean,
      default: false
    },
    isShowSlinging: {
      type: Boolean,
      default: true
    },
    isRequiredSlinging: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    this.$watch(
      () => this.values.field_tree_id,
      (newValue, oldValue) => {
        if(JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.getListUsers(this.values.field_tree_id);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue, oldValue) => {
        if (this.items?.length !== newValue[0][newValue[1]]?.length
          || JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
            this.items = newValue[0][newValue[1]];
        }
        if(!this.items || this.items?.length == 0) {
          this.items = [];
          this.items.push({ ...INITIAL_VALUES });
        }
        if(!this.values?.field_tree_id) {
          this.listCompanyUser = [{ user_id: null, name: "" }];
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      const items = [...this.items];
      const itemEdited = {...this.items[index]};
      itemEdited[value.name] = value.value;
      items[index] = itemEdited;
      this.$emit("onInput", { name: this.name, value: items });
    },
    remove(index) {
      const items = this.items.filter((item, i) => {
        return index != i;
      });
      this.$emit("onInput", { name: this.name, value: items });
    },
    addEmpty() {
      const items = [...this.items];
      items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: items });
    },
    async getListUsers(field_tree_id) {
      const params = {
        id: field_tree_id,
        state_handling_flag: 0
      };
      let rs = await Store.dispatch(
        "PortalChart/getListCompanyPartnerUser",
        params
      );
      if(!rs.hasError) {
        let _listCompanyPartnerUser = [...[...rs.data.contents.entries]];
        _listCompanyPartnerUser.forEach(item => {
          item['name'] = `${item.user_name_sei} ${item.user_name_mei}`
        });
        if(_listCompanyPartnerUser && _listCompanyPartnerUser.length > 0) {
          this.listCompanyUser = [{ user_id: null, name: "" },..._listCompanyPartnerUser];
        }
      } else {
        this.listCompanyUser = [{ user_id: null, name: "" }];
      }
    },
  },
};
</script>
<style scoped>
.row-position{
  margin-top: -50px;
  height: 180px;
  position: relative;
  z-index: 2;
}

.select-position{
  width: 100%;
  position: absolute;
}
</style>