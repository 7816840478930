<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <CompanyDocuments
                name="company_documents"
                :values="formValues"
                :editable="editable"
                @onInput="onInput"
                :errors="errors"
              />
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "../elements/Label";
import InputTextArea from "../elements/InputTextArea";
import CompanyDocuments from "./components/CompanyDocumentInput.vue";
import _ from "lodash";
export default {
  data: () => {
    return {
      formValues: {},
    };
  },
  components: {
    Label,
    CompanyDocuments,
    InputTextArea,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    errors: {
      type: Object,
      default: {},
    },
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = _.cloneDeep(newValue);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.$emit("formUpdate", { "company_documents": value });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";
</style>
