<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row no-gutters>
                <v-col cols="12" sm="6" md="6">
                  <SectionLabel label="持込会社" class="ml-4"/>
                  <v-list-item>
                    <v-list-item-content class="row-position">
                      <Label label="会社名">
                        <InputText
                          name="delivery_company_name"
                          :editable="false"
                          :values="formValues.machine_company_field_report_details"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <SectionLabel label="点検者" class="ml-4" />
                  <UserCompaniesInput
                    name="machine_company_field_report_checkups"
                    textId="checkup_user_id"
                    textName="checkup_user_name"
                    :values="formValues"
                    :editable="editable"
                    :userArray="USER_ARRAYS"
                    @onInput="onInput"
                  />
                  <v-row class="pt-4">
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="点検日" :editable="editable">
                            <InputDatepicker
                              name="checkup_date"
                              :values="formValues.machine_company_field_report_details"
                              :editable="editable"
                              :max="max"
                              :flagNull="true"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <Label label="点検表" :editable="editable">
                    <ReportDocument
                      name="machine_company_field_report_checkup_documents"
                      :values="formValues"
                      :editable="editable"
                      @onInput="onInput"
                      :errors="errors"
                    />
                  </Label>
                  <SectionLabel label="取扱者" class="ml-4" />
                  <UserCompaniesInput
                    name="machine_company_field_report_handles"
                    textId="handle_user_id"
                    textName="handle_user_name"
                    :values="formValues"
                    :editable="editable"
                    :userArray="USER_ARRAYS"
                    @onInput="onInput"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "@/components/forms/elements/Label.vue";
import InputText from "@/components/forms/elements/InputText.vue";
import Select from "@/components/forms/elements/Select.vue";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import SectionLabel from "@/components/forms/elements/SectionLabel.vue";
import UserCompaniesInput from "./components/UserCompaniesInput.vue";
import ReportDocument from "./components/ReportDocument.vue";
import _ from "lodash";
const STORE = "DeviceCarryOns";

export default {
  data: () => {
    return {
      USER_ARRAYS:[],
      formValues: {},
      max: new Date().toISOString().slice(0, 10),
      flagInitial : false
    };
  },
  components: {
    Label,
    InputText,
    Select,
    SectionLabel,
    UserCompaniesInput,
    InputDatepicker,
    ReportDocument,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    errors: {
      type: Object,
      default: {},
    },
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        if (newValue.machine_company_field_report_details) {
          this.formValues = { ...newValue };
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.item,
      (newValue) => {
        if (newValue.machine_company_field_report_details) {
          this.formValues = { ...newValue };
          if (!this.flagInitial) {
            this.getListOwnerCompanyUsers(newValue);
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );


    this.$watch(
      () => Store.getters[`${STORE}/getListOwnerCompanyUser`],
      (newValue) => {
        let items = [...newValue];
        items.forEach((item) => {
          item["name"] = item["name_sei"] + " " + item["name_mei"];
        });
        this.USER_ARRAYS = [{ user_id: null, name: "" }, ...items];
      },
      {
        deep: true
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      if (name == "checkup_date") {
        formValues.machine_company_field_report_details[name] = value;
      } else {
        formValues[name] = value;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },

    async getListOwnerCompanyUsers(data) {
      let _data = _.cloneDeep(data);
      let companyId = _data.machine_company_field_report_details?.delivery_company_id;
      let companyBranchId = _data.machine_company_field_report_details?.delivery_company_branch_id;
      let params = {
        field_id: this.CURRENT_SITE.field_id,
        delivery_company_id: companyId,
        delivery_company_branch_id: companyBranchId
      };
      await Store.dispatch(`${STORE}/getListOwnerCompanyUser`, { params });
      this.flagInitial = true;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
.row-position {
  margin-top: -6px;
  height: 150px;
  position: relative;
}

.select-position {
  width: 100%;
  position: absolute;
}
</style>
