<template>
  <v-card class="dialog">
    <div class="header sticky">
      <v-card-title small>
        <div class="title">会社選択 </div>
        <v-spacer></v-spacer>
        <v-btn small icon text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
    </div>
    <SearchSelectList
      title_search="会社名"
      @search="searchData"
      @formUpdate="formUpdate"
    >
      <template>
        <v-data-table
          :height="items.length >= 12 ? '67vh' : ''"
          item-key="id"
          v-model="dataChoose"
          :items="items"
          :items-per-page="items.length"
          :headers="content"
          fixed-header
          hide-default-footer
          disable-sort
          class="content-table"
          show-select
          :noDataText="NO_DATA_MESSAGE"
          @click:row="(item, {select, isSelected}) => select(!isSelected)"
          @item-selected="updateSelectedItems"
          @toggle-select-all="selectAllItems"
        >
          <template #item.company_info="{ item }">
            <div
              style="
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 380px;
                white-space: nowrap
              "
            >
              <v-list-item-title>
                  {{ getCompanyName(item) }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ getInfor(item) }}
              </v-list-item-subtitle>
            </div>
          </template>
        </v-data-table>
      </template>
    </SearchSelectList>
    <div class="button-group">
      <v-row no-gutters>
        <v-col cols="9" sm="10" class="pa-3"></v-col>
        <v-col cols="3" sm="2" class="pt-3 pr-2">
          <v-btn @click="$emit('clickButton')" color="primary">決定</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import SearchSelectList from "@/components/forms/elements/SearchSelectList.vue";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import { Store } from "@/store/Store";

export default {
  components: {
    SearchSelectList
  },

  props: {
    field_construction_id: Number,
    listFieldTree : Array
  },

  data: () => {
    return {
      keyword: "",
      NO_DATA_MESSAGE,
      items: [],
      isNoDataMessage: false,
      dataChoose: [],
      content: [
        {
          text: "全て選択",
          align: "left",
          sortable: false,
          value: "company_info",
        },
      ]
    };
  },
  async mounted() {
    this.$watch(
      () => Store.getters["PortalChart/getListCompanyPartner"],
      (data) => {
        if (data) {
          this.items = [...data];
          this.isNoDataMessage = this.items?.length == 0;
        } else {
          this.isNoDataMessage = true;
        }
      },
      {
        deep: true
      }
    );
    await this.searchData();
    this.initialDataChoose();
  },
  methods: {
    async searchData() {
      if (this.field_construction_id) {
        const params = {
          field_construction_id: this.field_construction_id,
          state_handling_flag: 0,
          display_branch_name_flag: 1,
          force_all_partner_flag : 1,
          text_search_company_name: this.keyword
        };
        await Store.dispatch(`PortalChart/getListCompanyPartner`, { params });
        this.dataChoose = [];
      } else {
        this.items = [];
        this.isNoDataMessage = true;
      }
    },
    formUpdate(values) {
      this.keyword = values.keyword;
    },
    getCompanyName(item) {
      let rs = item.corporate_rating_position == 2 ? `${item.main_company_name}株式会社` : `株式会社${item.main_company_name}`;
      if (item.branch_company_name) {
        rs += `（${item.branch_company_name}）`;
      }
      return rs;
    },
    getInfor(item) {
      let perfectures = [...Store.state.CmnMst.constants.entries.cmn_mst_prefectures];
      let prefecture_name = item.prefecture_id ? perfectures.find(e => e.id == item.prefecture_id)?.prefecture || "" : "";
      let city = item.city || "";
      let address = item.address || "";
      let tel = item.tel || "";
      let representative = item.representative || "";
      let rs = [];
      if (city || prefecture_name || address) {
        rs.push(`${prefecture_name}${city}${address}`);
      }
      if (tel) {
        rs.push(`${tel}`);
      }
      if (representative) {
        rs.push(`${representative}`);
      }
      return rs.join("/");
    },
    initialDataChoose(){
      this.dataChoose = this.items.filter(e => this.listFieldTree.includes(e.id));
      this.$emit("updateChecked", this.dataChoose);
    },
    selectAllItems(value) {
      this.dataChoose = [];
      let idSet = new Set();
      if (value.value) {
        value.items.forEach((item) => {
          if (!idSet.has(item.id)) {
            this.dataChoose.push(item);
            idSet.add(item.id);
          }
        });
      }
      this.$emit("updateChecked", this.dataChoose);
    },
    updateSelectedItems(value) {
      let currentItems = value.value
        ? this.items.filter(
            (element) => element.id === value.item.id
          )
        : [];
      if (currentItems.length > 0) {
        this.dataChoose.push(...currentItems);
      }
      else {
        this.dataChoose = this.dataChoose.filter(
          (item) => item.id !== value.item.id
        );
      }
      this.$emit("updateChecked", this.dataChoose);
    },
  }
};
</script>

<style lang="scss" scoped>
.title {
  color: #1B9C4F;
}
.button-group {
  position: sticky;
  bottom: 0;
  left: 0;
  padding-bottom: 20px;
  z-index: 1;
  background-color: white;
}
.dialog {
  .header {
    height: 65px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }
}
::v-deep .v-data-table--fixed-header>.v-data-table__wrapper>table .text-start {
  padding-right: 0;
}
::v-deep .v-data-table--fixed-header>.v-data-table__wrapper>table .text-left {
  padding-left: 0;
}
</style>
