<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters>
                    <v-list-item>
                      <v-list-item-content>
                        <v-col cols="12" sm="6" md="6">
                          <!-- 登録者 -->
                          <Label label="登録者" :editable="editable">
                            <InputText
                              name="register_person"
                              :values="registerName"
                            />
                          </Label>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <!-- 登録日時 -->
                          <Label label="登録日時" :editable="editable">
                            <InputText
                              name="created_at"
                              :values="formValues"
                            />
                          </Label>
                        </v-col>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-row no-gutters>
                    <v-list-item>
                      <v-list-item-content>
                        <v-col cols="12" sm="6" md="6">
                          <!-- 最終更新者 -->
                          <Label label="最終更新者" :editable="editable">
                            <InputText
                              name="final_updater"
                              :values="updateName"
                            />
                          </Label>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <!-- 最終更新日時 -->
                          <Label label="最終更新日時" :editable="editable">
                            <InputText
                              name="updated_at"
                              :values="formValues"
                            />
                          </Label>
                        </v-col>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-row no-gutters>
                    <v-list-item>
                      <v-list-item-content>
                        <v-col cols="12" sm="6" md="6">
                          <!-- 最終承認者 -->
                          <Label label="最終承認者" :editable="editable">
                            <v-text-field
                              v-model="getApprovalUser"
                              dense
                              color="primary"
                              :disabled="true"
                            ></v-text-field>
                          </Label>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <!-- 最終承認日時 -->
                          <Label label="最終承認日時" :editable="editable">
                            <InputText
                              name="approval_date"
                              :values="formValues"
                            />
                          </Label>
                        </v-col>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "@/components/forms/elements/Label";
import InputText from "@/components/forms/elements/InputText";

export default {
  data: () => {
    return {
      formValues: {},
      registerName: {
        register_person: ""
      },
      updateName: {
        final_updater: ""
      }
    };
  },
  components: {
    Label,
    InputText,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
        if(this.formValues.created_at){
          this.formValues.created_at = this.formValues.created_at.replaceAll('-', '/')
        }
        if(this.formValues.updated_at){
          this.formValues.updated_at = this.formValues.updated_at.replaceAll('-', '/')
        }
        if (this.formValues.approval_date) {
          this.formValues.approval_date = this.formValues.approval_date.replaceAll('-', '/')
        }
        this.registerName['register_person'] = (this.formValues['create_user_name_sei']||"") + " " +  (this.formValues['create_user_name_mei']||"");
        this.updateName['final_updater'] = (this.formValues['update_user_name_sei']||"") + " " +  (this.formValues['update_user_name_mei']||"");
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      const headerHeight = 48;
      let style = `height:${this.mainHeight - headerHeight}px;`;
      return style;
    },
    getApprovalUser () {
      return `${this.formValues['approval_user_name_sei'] || ""} ${this.formValues['approval_user_name_mei'] || ""}`;
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
</style>
