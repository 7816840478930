<template>
  <div>
    <GlobalDrawer width="260px">
      <!-- 自社 or 現場ポータルでコンテンツ切り分け -->
      <!-- 共通 -->
      <v-list-item>
        <v-icon class="mr-2" color="primary">mdi-account-circle-outline</v-icon>
        <span class="cts-user-name">
          {{ userName }}
        </span>
        <v-spacer></v-spacer>

        <!-- プロフィール -->
        <!-- <v-btn icon @click="onClickOpen('/')">
          <v-icon color="primary">mdi-cog-outline</v-icon>
        </v-btn> -->

        <!-- 自社ポータル -->
        <v-btn v-if="!$sp && drawerType === PORTAL_LIST_TYPE.SITE_PORTAL.id" icon @click="goToHomePage" >
          <v-icon color="primary">mdi-home</v-icon>
        </v-btn>
      </v-list-item>
      <!-- ここにマスター画面への遷移 -->
      <v-container v-if="!IS_OBAYASHI">
        <!-- 自社ポータルメニュー -->
        <div v-if="drawerType === PORTAL_LIST_TYPE.INHOUSE_PORTAL.id">
          <InhouseDrawerMenu @onClickOpen="onClickOpen" />
        </div>
      </v-container>

      <!-- 共通 -->
      <v-list-item>
        <v-spacer v-if="!IS_OBAYASHI"></v-spacer>
        <v-btn @click="logout" color="primary">ログアウト</v-btn>
      </v-list-item>
      <v-container v-if="!flagUserRentalCompany && !IS_OBAYASHI">
        <!-- 現場ポータルメニュー -->
        <div v-if="drawerType === PORTAL_LIST_TYPE.SITE_PORTAL.id">
          <SitePortalDrawerMenu @onClickOpen="onClickOpen" />
        </div>
        <!-- SP現場ポータルメニュー -->
        <div v-if="drawerType === PORTAL_LIST_TYPE.SP.id">
          <SpDrawerMenu @onClickOpen="onClickOpen" />
        </div>
      </v-container>
    </GlobalDrawer>
    <Popup :dialog="isShowFieldDocumentForm">
        <DocumentForm
          @cancel="closeSettingForm"
        />
      </Popup>

  </div>
</template>

<script>
import { Store } from "@/store/Store.js";
// storeを使ったDrawer
import GlobalDrawer from "@/components/common/GlobalDrawer";
import { PORTAL_LIST } from "@/constants/GLOBALHEADER";
import { ROLE_USER_RENTAL_COMPANY } from "@/constants/COMMON";
import InhouseDrawerMenu from "./InhouseDrawerMenu.vue";
import SitePortalDrawerMenu from "./SitePortalDrawerMenu.vue";
import SpDrawerMenu from "./SpDrawerMenu.vue";
import DocumentForm from "@/components/forms/document/DocumentSettingForm";
import Popup from "@/components/common/Popup";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
/**
 * ポータル共通 初期値
 */

export default {
  data() {
    return {
      isShowFieldDocumentForm : false,
      ROLE_USER_RENTAL_COMPANY,
      flagUserRentalCompany: false,
      dataUser: {},
      userName : '',
    };
  },

  components: {
    GlobalDrawer,
    InhouseDrawerMenu,
    SitePortalDrawerMenu,
    SpDrawerMenu,
    DocumentForm,
    Popup
  },

  async mounted() {
    this.$watch(
      () => Store.getters["Sites/getUserRole"],
      (data) => {
        if (data == this.ROLE_USER_RENTAL_COMPANY) {
          this.flagUserRentalCompany = true;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Employees/getEmployeeDetail"],
      (data) => {
        if(data.users.id == this.dataUser.id){
          this.userName = `${data.users.name_sei} ${data.users.name_mei}`;
        }
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Login/getUser"],
      (data) => {
        this.dataUser = {...data};
        this.userName = `${data.name_sei} ${data.name_mei}`;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  computed: {
    //
    drawerType() {
      return Store.getters["Drawer/getType"];
    },
    PORTAL_LIST_TYPE() {
      return PORTAL_LIST;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    IS_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
  },

  methods: {
    // ログアウト
    async logout() {
      const result = await Store.dispatch("Login/logout");
      if (result.hasError && result.response.status !== 403) {
        Store.dispatch("Error/show", {
          status: 200,
          message: "ログアウトできませんでした",
        });
        return;
      }
      Store.dispatch("Drawer/close");
      // login画面に遷移
      window.location.href = "/";
      // this.$router.push("/");
    },
    //画面遷移
    async onClickOpen(url) {
      Store.dispatch("Drawer/close");
      if (!url) {
        const field_id = this.CURRENT_SITE.field_id;
        const params = {
          field_id,
          target_role: []
        };
        await Store.dispatch("Sites/getRole", params);
        this.isShowFieldDocumentForm = true;
      } else {
        this.$router.push(url);
      }
    },
    goToHomePage() {
      Store.dispatch("Drawer/close");
      Store.dispatch("GlobalHeader/setSite", {
        field_id: null,
      });
      this.$router.push("/portal");
    },
    closeSettingForm() {
      this.isShowFieldDocumentForm = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-company-url {
  background-color: #f8f8f8;
  border-radius: 4px;
}

a.user-company-url-text {
  color: black;
  text-decoration: none;
}

.cts-user-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
}
</style>
