import Api, { Mock } from "../api";
import mock_machines from "./mock/machines.json";
import mock_detail_machine from "./mock/machine_detail.json";

const PATH = "machines";
const URL = `${process.env.VUE_APP_API_BASE_URL}/${PATH}`;
const GET_LIST_URL = `${URL}/list`;
const DELETE_URL = `${URL}/delete`;
const GET_DETAIL_MACHINE_URL = `${URL}/detail`;
const CREATE_MACHINE_URL = `${URL}/create`;
const UPDATE_MACHINE_URL = `${URL}/update`;
const GET_LIST_MACHINE_BY_TYPE_URL = `${URL}/list_machine_by_type`;

// .envのVUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  console.log("init Mock");
  Mock.onGet(new RegExp(`${GET_DETAIL_MACHINE_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_detail_machine,
  });

  Mock.onGet(GET_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_machines,
  });

  Mock.onPost(CREATE_MACHINE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(UPDATE_MACHINE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(DELETE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(new RegExp(`${GET_LIST_MACHINE_BY_TYPE_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        {
          id: 1,
          machine_name: "machine A"
        },
        {
          id: 2,
          machine_name: "machine B"
        }
      ]
    },
  });
}

export const machines = {
  getDetail: async (params) => {
    return Api.get(`${GET_DETAIL_MACHINE_URL}/${params}`);
  },
  get: (params) => {
    return Api.get(GET_LIST_URL, params);
  },
  post: (params) => {
    console.log("post:", params);
    return Api.post(CREATE_MACHINE_URL, params);
  },
  update: async (params) => {
    return Api.post(UPDATE_MACHINE_URL, params);
  },
  delete: async (params) => {
    return Api.post(DELETE_URL, params);
  },
  getListMachineByType: async (requestParams) => {
    let id = requestParams.id;
    let params = { machine_type_id: requestParams.machine_type_id };
    return Api.get(`${GET_LIST_MACHINE_BY_TYPE_URL}/${id}`, { params });
  },
};
