<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row no-gutters>
          <v-col cols="12" sm="10" md="10">
            <v-list-item>
              <v-list-item-content class="row-position">
                <Label label="特別教育名" :editable="editable">
                  <SelectSearch
                    class="select-position"
                    name="user_sp_training_id"
                    item_value="user_sp_training_id"
                    item_text="training_name"
                    :values="item"
                    :items="trainningArrays"
                    :editable="editable"
                    title="特別教育名選択"
                    title_search="特別教育名"
                    @onInput="onInput({ index, value: $event })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col v-if="index == 0 && editable" cols="12" sm="2" md="2">
            <v-btn class="mt-16" icon @click="addEmpty">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
          <v-col v-if="index > 0 && editable" cols="12" sm="2" md="2">
            <v-btn class="mt-16" icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "@/components/forms/elements/Label.vue";
import Select from "@/components/forms/elements/Select.vue";
import { Store } from "@/store/Store.js";
import SelectSearch from "@/components/forms/elements/SelectSearch";

//初期値を設定します
const INITIAL_VALUES = {
  user_sp_training_id: null,
};

export default {
  data: () => {
    return {
      items: [],
      trainningArrays : [{ user_sp_training_id: null, training_name: "" }]
    };
  },
  components: {
    SelectSearch,
    Label,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    userId :{
      type : Number
    }
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (!this.items || this.items?.length == 0) {
          this.items = [];
          this.addEmpty();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => [Store.getters[`DeviceCarryOns/getDriverInfo`], this.userId],
      (newValue) => {
        if (newValue[1] && Array.isArray(newValue[0])) {
          let data = [...newValue[0]];
          let temp = data.find(e => e.driver_user_id == newValue[1])?.driver_sp_trainings;
          temp = temp ? temp : [];
          this.trainningArrays = [{ user_sp_training_id: null, training_name: "" }, ...temp];
        } else {
          this.trainningArrays = [{ user_sp_training_id: null, training_name: "" }];
        }
      },
      {
        deep: true
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      const items = [...this.items];
      items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: items });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
.row-position {
  margin-top: -30px;
  height: 150px;
  position: relative;
}

.select-position {
  width: 100%;
  position: absolute;
}
</style>
