<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form ref="form" lazy-validation autocomplete="off">
            <v-container>
              <v-row>
                <!-- col 1 -->
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <Label
                        label="商号または名称"
                        :editable="editable"
                        required
                      >
                        <InputText
                          name="name"
                          :values="formValues"
                          :editable="editable"
                          placeholder=""
                          validation_label="商号または名称"
                          validation_rules="required|max:100"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-row>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="フリガナ" :editable="editable" required>
                            <InputText
                              name="name_kana"
                              :values="formValues"
                              :editable="editable"
                              placeholder=""
                              validation_label="フリガナ"
                              validation_rules="required|max:255|kana"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="CCUS事業者ID" :editable="editable">
                            <InputText
                              name="ccus_business_id"
                              :values="formValues"
                              :editable="editable"
                              placeholder=""
                              validation_label="CCUS事業者ID"
                              validation_rules="digit|max:20"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="法人格" :editable="editable" required>
                            <Select
                              name="corporate_status_id"
                              :values="formValues"
                              :items="corporateStatuses"
                              :editable="editable"
                              validation_label="法人格"
                              validation_rules="required|max:512"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                      <v-list-item>
                        <v-list-item-content>
                          <Label
                            label="法人格付与位置"
                            :editable="editable"
                            required
                          >
                            <RadioGroup
                              v-if="editable"
                              name="corporate_rating_position"
                              :values="formValues"
                              :items="CORPORATE_RATING_POSITION"
                              :editable="editable"
                              validation_label="法人格付与位置"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                            <span v-if="!editable">{{
                              getRadioName(
                                CORPORATE_RATING_POSITION,
                                formValues["corporate_rating_position"]
                              )
                            }}</span>
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="法人区分" :editable="editable">
                        <RadioGroup
                          v-if="editable"
                          name="corporate_classification"
                          :values="formValues"
                          :items="CORPORATE_CLASSIFICATION"
                          :editable="editable"
                          validation_label="法人区分"
                          @onInput="onInput"
                        />
                        <span v-if="!editable">{{
                          getRadioName(
                            CORPORATE_CLASSIFICATION,
                            formValues["corporate_classification"]
                          )
                        }}</span>
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="法人番号" :editable="editable">
                        <InputText
                          name="corporate_number"
                          :values="formValues"
                          :editable="editable"
                          placeholder=""
                          validation_label="法人番号"
                          validation_rules="digit|max:13"
                          @onInput="onInput"
                        />
                        <span v-if="editable"
                        >13桁の半角数字で入力してください</span
                        >
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="企業ロゴ" :editable="editable">
                        <v-img
                          v-if="!editable && formValues['logo_image_url']"
                          :src="logo"
                          max-width="120px"
                          max-height="120px"
                        />
                        <FileInput
                          :style="getFileInputStyle('logo_image')"
                          :resetValue="resetValue['logo_image']"
                          v-if="editable"
                          name="logo_image"
                          :values="formValues"
                          :editable="editable"
                          accept="image/jpg, image/png, image/jpeg"
                          placeholder=""
                          validation_label="logo_image"
                          validation_rules="maxsize:10|file-type:jpg, png, jpeg"
                          @onInput="onInput"
                        />
                        <span
                          class="file"
                          v-if="judgeDisplayFileInput('logo_image')"
                        >{{ formValues["logo_image"] }}
                          <v-btn
                            small
                            icon
                            text
                            @click="onRemoveFile({ name: 'logo_image' })"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </span>
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <!-- col 2 -->
                <v-col cols="12" sm="6" md="6">
                  <v-row class="pt-3">
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="郵便番号" :editable="editable" required>
                            <InputText
                              name="postal_code"
                              :values="formValues"
                              :editable="editable"
                              placeholder=""
                              validation_label="郵便番号"
                              validation_rules="required|postcode|max:8"
                              @onInput="onChangePostalCode"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="都道府県" :editable="editable" required>
                            <Select
                              name="cmn_mst_prefecture_id"
                              :values="formValues"
                              :items="PREFECTURES"
                              item_text="prefecture"
                              :editable="editable"
                              validation_label="都道府県"
                              validation_rules="required|max:127"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <!-- 市区町村 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="市区町村" :editable="editable" required>
                        <InputText
                          name="city"
                          :values="formValues"
                          :editable="editable"
                          placeholder=""
                          validation_label="市区町村"
                          validation_rules="required|max:512"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="番地・建物名" :editable="editable">
                            <InputText
                              name="address"
                              :values="formValues"
                              :editable="editable"
                              placeholder=""
                              validation_label="番地・建物名"
                              validation_rules="max:512"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="電話番号" :editable="editable" required>
                            <InputText
                              name="tel"
                              :values="formValues"
                              :editable="editable"
                              placeholder=""
                              validation_label="電話番号"
                              validation_rules="required|phone|max:16"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <!-- FAX -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="FAX" :editable="editable">
                            <InputText
                              name="fax"
                              :values="formValues"
                              :editable="editable"
                              placeholder=""
                              validation_label="FAX"
                              validation_rules="phone|max:16"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                      <v-list-item>
                        <v-list-item-content>
                          <!-- 代表者 -->
                          <Label label="代表者" :editable="editable">
                            <InputText
                              name="representative"
                              :values="formValues"
                              :editable="editable"
                              placeholder=""
                              validation_label="代表者"
                              validation_rules="max:255"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" :class="editable ? 'pt-2 pb-0':'pt-0 pb-0'">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="雇用管理責任者" :editable="editable" v-if="!checkObayashi">
                            <InputText
                              name="employment_manager"
                              :values="formValues"
                              :editable="editable"
                              placeholder=""
                              validation_label="雇用管理責任者"
                              validation_rules="max:255"
                              @onInput="onInput"
                            />
                          </Label>
                          <Label label="代表者メールアドレス" :editable="editable" v-if="checkObayashi">
                            <InputText
                              name="representative_email"
                              :values="formValues"
                              :editable="editable"
                              validation_label="代表者メールアドレス"
                              validation_rules="halfsize|email|max:255"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row v-if="checkObayashi">
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="雇用管理責任者" :editable="editable">
                            <InputText
                              name="employment_manager"
                              :values="formValues"
                              :editable="editable"
                              placeholder=""
                              validation_label="雇用管理責任者"
                              validation_rules="max:255"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col></v-col>
                  </v-row>
                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel label="所属団体" :editable="editable" />
                    </v-list-item-content>
                  </v-list-item>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="業種" :editable="editable">
                            <Select
                              name="catergory_id"
                              :values="formValues"
                              :items="CATEGORY_ARRAY"
                              :editable="editable"
                              validation_label="業種"
                              validation_rules="max:255"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="団体名" :editable="editable">
                            <Select
                              name="com_info_id"
                              :values="formValues"
                              :items="GROUP_ARRAY"
                              :editable="editable"
                              validation_label="団体名"
                              validation_rules="max:255"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import Label from "../elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "../elements/InputText";
import FileInput from "../elements/FileInput";
import Select from "../elements/Select.vue";
import RadioGroup from "../elements/RadioGroup";
import {
  CORPORATE_RATING_POSITION,
  CORPORATE_CLASSIFICATION,
} from "@/constants/COMPANY_PORTAL";
import { Store } from "@/store/Store.js";
import { postalCodeSearch } from "@/utils/postalCodeSearch";
import { kuroshiro } from "@/utils/convertKana";
import { getParamsOfUrlReadFile } from "@/utils/viewSourceAuth";
import _ from "lodash";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";

export default {
  data: () => {
    return {
      CORPORATE_RATING_POSITION,
      CORPORATE_CLASSIFICATION,
      formValues: {},
      corporateStatuses: [{ id: null, name: "" },...Store.state.CmnMst.constants.entries.cmn_mst_corporate_status],
      PREFECTURES: [
        { id: null, prefecture: "" },
        ...Store.state.CmnMst.constants.entries.cmn_mst_prefectures,
      ],
      COMPANY_INFO_GROUPS:
        Store.state.CmnMst.constants.entries.cmn_mst_com_info,
      fileValid: {},
      resetValue: {},
      CATEGORY_ARRAY: [],
      GROUP_ARRAY: [],
      logo: null,
    };
  },
  components: {
    ValidationProvider,
    Label,
    SectionLabel,
    InputText,
    FileInput,
    Select,
    RadioGroup,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    errors: {
      type: Object,
      default: {},
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (data) => {
        this.initData(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );

    // check input file error
    this.$watch(
      () => this.errors,
      (value) => {
        const errors = value;
        const names = ["logo_image"];
        const fileValid = _.cloneDeep(this.fileValid);
        const formValues = _.cloneDeep(this.formValues);
        if (errors) {
          for (let name in errors) {
            if (names.some((item) => item === name)) {
              if (errors[name].length > 0) {
                delete formValues[name];
                delete formValues[`${name}_data`];
                fileValid[name] = false;
              } else {
                fileValid[name] = true;
              }
            }
          }
        }
        this.fileValid = fileValid;
        this.COMPANY_INFO_GROUPS.forEach((element) => {
          if (formValues["com_info_id"] && formValues["com_info_id"] === element.id) {
            formValues["catergory_id"] = element.catergory;
          } else if (!formValues["com_info_id"] && !this.editable) {
            formValues["catergory_id"] = null;
          }
        });
        this.formValues = formValues;
        this.getCatergories();
        this.getCompanyGroups();
      },
      {
        deep: true
      }
    );

  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    checkObayashi(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
  },
  methods: {
    async viewLogo(sourceUrl) {
      if (sourceUrl) {
        const params = getParamsOfUrlReadFile(sourceUrl);
        const response = await Store.dispatch("File/readFile", params);
        if(!response.hasError) {
          this.logo = window.URL.createObjectURL(new Blob([response.data], { type: response.headers["content-type"]}));
        }
      }
    },

    initData(data) {
      const formValues = data;
      this.formValues = _.cloneDeep(formValues);
      this.viewLogo(this.formValues['logo_image_url']);
    },

    getRadioName(items, value) {
      return items.find((item) => item.value == value)?.name;
    },

    onRemoveFile({ name }) {
      const formValues = { ...this.formValues };
      delete formValues[name];
      delete formValues[`${name}_data`];
      if (formValues[`${name}_url`]) formValues[`${name}_deleted`] = 1;
      this.formValues = formValues;
      this.resetValue[name] = !this.resetValue[name];
      this.$emit("formUpdate", { company: formValues });
    },

    onInput({ name, value, fileName }) {
      const formValues = { ...this.formValues };
      if (fileName) {
        formValues[`${name}_data`] = value;
        formValues[name] = fileName;
        if (!value) {
          delete formValues[`${name}_deleted`];
        }
      } else {
        formValues[name] = value;
      }
      if (name == "catergory_id") {
        formValues["com_info_id"] = null;
      }
      if (name == "catergory_id") {
        formValues["com_info_id"] = null;
      }
      if (name == 'name'){
        this.getNameKana(value.trim());
      }
      this.formValues = formValues;
      this.$emit("formUpdate", { company: formValues });

      if (name == "com_info_catergory") {
        this.initDataForElementGroup(value);
      }
    },
    getCatergories() {
      let catergories = [];
      let temp = "";
      this.COMPANY_INFO_GROUPS.forEach((element) => {
        if (temp != element.catergory) {
          catergories.push({ id: element.catergory, name: element.catergory });
          temp = element.catergory;
        }
      });
      this.CATEGORY_ARRAY = [
        { id: null, name: "" },
        ...catergories,
      ];
    },

    async getNameKana(value) {
      let result = await kuroshiro.convert(value, {
        mode: "normal",
        to: "katakana",
      });
      const formValues = { ...this.formValues };
      formValues["name_kana"] = result;
      this.$emit("formUpdate", { company: formValues });
    },

    getCompanyGroups() {
      let groups = [];
      if (this.formValues["catergory_id"]) {
        this.COMPANY_INFO_GROUPS.forEach((element) => {
          if (this.formValues.catergory_id === element.catergory) {
            groups.push({ id: element.id, name: element.group });
          }
        });
      }
      this.GROUP_ARRAY = [{ id: null, name: "" }, ...groups];
    },
    getFileInputStyle(fieldName) {
      return this.fileValid[fieldName] && this.formValues[fieldName]
        ? "display:none;"
        : "";
    },
    judgeDisplayFileInput(fieldName) {
      return (
        this.editable && this.fileValid[fieldName] && this.formValues[fieldName]
      );
    },

    /**
     * 郵便番号から都道府県、市町村名を検索
     */
    async onChangePostalCode({ value }) {
      const formValues = { ...this.formValues };
      formValues.postal_code = value;
      this.formValues = formValues;
      if (value == "") {
        formValues.cmn_mst_prefecture_id = '';
        formValues.city = '';
        this.formValues = formValues;
      } else if (value.match(/^\d{3}-?\d{4}$/g)) {
        const result = await postalCodeSearch.search({ postal_code: value });
        if(result){
          formValues.cmn_mst_prefecture_id = result.prefecture.id;
          formValues.city = result.city;
        }
      }
      this.formValues = formValues;
      this.$emit("formUpdate", { company: formValues });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";
</style>
