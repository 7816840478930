<template>
  <v-card height="87vh">
    <div class="d-flex px-3 justify-end pt-3">
      <v-btn icon @click="$emit('closeShowImageDialog')" data-testid="btn-close" class="cursor-pointer">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="content content-size d-flex">
      <div class="style-icon d-flex align-center justify-center">
        <v-btn icon class="cursor-pointer" @click="clickPrev"  v-show="indexImage != 0"  style="width: 32px">
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </div>
      <div class="d-flex justify-center flex-column" style="width: calc(100% - 66px); padding-bottom: 20px">
        <v-zoomer
            ref="zoomer"
            class="zoomer-size zoomer-custom"
            :pivot="'image-center'"
            :aspect-ratio="imageAspectRatio"
            :max-scale="10"
            :zooming-elastic="false"
            style="height: calc( 87vh - 68px); width: calc( 60vw - 66px)"
        >
          <img
              :src="listDataImage[indexImage]"
              class="image-size"
              @load="onImageLoad"
          >
        </v-zoomer>
      </div>
      <div class="style-icon d-flex align-center justify-center">
        <v-btn icon class="cursor-pointer" @click="clickNext" v-show="!(listDataImage.length == 1 ||indexImage == listDataImage.length - 1)" style="width: 32px">
          <v-icon large>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
  </v-card>
</template>
<script>
import VueZoomer from 'vue-zoomer'

export default {
  data: () => {
    return {
      indexImage: 0,
      listDataImage: [],
      imageAspectRatio: 1,
    }
  },
  props: {
    listUrlImage: {
      type: Array,
      default: []
    },
    indexOfSelectedImages: {
      type: Number,
      default: 0
    },
  },
  components: {
    VZoomer: VueZoomer.Zoomer
  },
  mounted() {
      this.$watch(
          () => this.listUrlImage,
          (data) => {
            this.listDataImage = data
          },
          {
            immediate: true,
            deep: true
          }
      ),
      this.$watch(
          () => this.indexOfSelectedImages,
          (data) => {
            this.indexImage = data
          },
          {
            immediate: true,
            deep: true
          }
      )
  },
  methods: {
    clickNext() {
      this.indexImage += 1;
      this.$refs.zoomer.reset();
    },
    clickPrev() {
      this.indexImage -= 1;
      this.$refs.zoomer.reset();
    },
    onImageLoad(e) {
      const img = e.target
      this.imageAspectRatio = img.naturalWidth / img.naturalHeight
    },
  }
}
</script>
<style scoped>
.style-icon {
  width: 32px;
  min-width: 32px
}
.cursor-pointer {
  cursor: pointer
}
.content {
  height: calc(100% - 48px);
  width: 100%;
}
.image-size {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.zoomer-size {
  width: 55vw;
  height: 80vh;
}
</style>
