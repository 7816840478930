<template>
  <FormDialog>
    <template #header>
      <!-- 
        (共通)
        ヘッダーは全て共通
       -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          {{ TITLE }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!editable && !isApproved && owner_flag"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn v-if="editable" class="mr-5"
          depressed
          small
          outlined
          color="primary" @click="onReadonly">
          やめる
        </v-btn>
        <v-btn
          v-if="isNewItem"
          depressed
          class="mr-5"
          small
          color="primary"
          @click="onCopy"
        >
          前回コピー
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          small
          :disabled="!valid || isSubmitted"
          color="primary"
          @click="onSubmit"
        >
          保存
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!-- 
        (共通)
        全てのフォームのバリデーションの監視を行う
       -->
      <ValidationObserver ref="observer" v-slot="observer">
        <!-- フォームtab -->
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <!-- 基本情報 -->
            <FormBasicInfo
              :item="formValues"
              :editable="editable"
              :isNewItem="isNewItem"
              :mainHeight="params.mainHeight"
              :cranePassagesCustomize="cranePassagesCustomize"
              @chooseCrane="chooseCrane"
              @formUpdate="formUpdate"
              @updateTimeZone="updateTimeZone"
            />
          </v-tab-item>
          <v-tab-item>
            <!-- 作業内容 -->
            <FormWorkContent
              :item="formValues"
              :editable="editable"
              :mainHeight="params.mainHeight"
              :cranePassagesCustomize="cranePassagesCustomize"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <!-- 性能 -->
            <FormPerformance
              :item="formValues"
              :editable="editable"
              :crane_id="crane_id"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <!-- 登録・変更履歴 -->
            <FormHistory
              :item="formValues"
              :editable="editable"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
        </v-tabs-items>
        <!-- 
          (共通)
          ValidationObserverのスロットプロパティを受け取って
          updateValidateメソッドを実行して、
          バリデーション結果をスクリプト側に渡してます
        -->
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="closePopup"
          @yes="closeForm"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import FormBasicInfo from "./FormBasicInfo.vue";
import FormWorkContent from "./FormWorkContent.vue";
import FormPerformance from "./FormPerformance.vue";
import FormHistory from "./FormHistory.vue";
import { Store } from "@/store/Store.js";
import {
  CONFIRM_DIALOG_STATUS,
  INIT_DATA,
  OWNER_FLAG,
} from "@/constants/SCHEDULE_CRANE";
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON";
import _ from "lodash";
import { addOneDayToEndTime , removeOneDayToEndTime} from "@/constants/CRANES";
/**
 * フォームとタブの設定
 */
const FORMS = {
  FormBasicInfo: { id: 1, title: "基本情報" },
  FormWorkContent: { id: 2, title: "作業内容" },
  FormPerformance: { id: 3, title: "性能" },
  FormHistory: { id: 4, title: "登録・変更履歴" },
};

export default {
  name: "LiftingScheduleForm",
  data: () => {
    return {
      TITLE: '揚重予定情報',
      FORMS,
      valid: false, //バリデーション結果
      // tab初期化
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      isClickCancelBtn: false,
      formValues: INIT_DATA,
      backData: {},
      isApproved: false,
      crane_id: null,
      isSubmitted: false,
      timeout: null,
      owner_flag: false,
      cranePassagesCustomize: {},
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    FormBasicInfo,
    FormWorkContent,
    FormPerformance,
    FormHistory,
  },
  props: {
    item: Object,
    isNewItem: Boolean,
  },
  created() {
    // call condition required and show/off item
    this.getPlanItemSetting();
  },
  computed: {
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    /**
     * (共通)
     * 新規作成を監視
     */
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        //新規の場合は最初から編集モード
        if (flag) {
          this.editable = true;
          let _formValues = {...this.formValues};
          _formValues.use_date_from = this.item.use_date_from;
          _formValues.use_date_to = this.item.use_date_to;
          _formValues.field_construction_id = this.item.field_construction_id;
          _formValues.work_hour_zone = this.item.work_hour_zone;
          this.formValues = { ..._formValues };
        }
        if (!flag) this.getItems();
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => [
        Store.getters["ScheduleCrane/getScheduleCraneDetail"],
        this.editable
      ],
      (data) => {
        if(!this.isNewItem) {
          let _formValues = _.cloneDeep(this.formValues);
          if (data[0]) _formValues = _.cloneDeep(data[0]);
          _formValues.device_usage_times = removeOneDayToEndTime(_formValues.device_usage_times);
          this.formValues = _formValues;
          if(this.formValues['approval'] == CONFIRM_DIALOG_STATUS.APPROVED) {
            this.isApproved = true;
          }
          if(this.formValues['owner_flag'] == OWNER_FLAG.OWNER) {
            this.owner_flag = true;
          }
        }
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    /**
     * (共通)
     * フォームの変更を送信
     */
    formUpdate(params, parent_name) {
      if(parent_name) {
        const formValues = {...this.formValues};
        formValues[parent_name] = {... formValues[parent_name], ...params};
        this.formValues = {...formValues};
      } else {
        this.formValues = {...this.formValues, ...params};
      }
    },
    updateTimeZone(params) {
      this.formValues.device_usage_times = params;
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      this.editable = true;
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onReadonly() {
      this.isClickCancelBtn = true;
      this.isShowConfirmDialog = true;
    },

    /**
     * (共通)
     * 状態を詳細に変更
     */
    onDetail() {
      this.editable = false;
    },

    /**
     * (共通)
     * 登録
     */
    async onSubmit() {
      this.isSubmitted = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isSubmitted = false
      }, SUBMIT_DELAY_TIMEOUT);
      const hasId = "id" in this.formValues;
      const { field_construction_id, field_tree_id, foreman_user_id, work_hour_zone, crane_id,
        field_item_tree_id, work_details, expected_danger, status_code, device_work_classifications,
        device_safety_instructions, device_usage_times, slinger_signallers, device_users} = _.cloneDeep(this.formValues);
      const apiParams = {field_construction_id, field_tree_id, foreman_user_id, work_hour_zone, crane_id,
        field_item_tree_id, work_details, expected_danger, status_code, device_work_classifications,
        device_safety_instructions, device_usage_times, slinger_signallers, device_users
      };

      if (hasId) {
        apiParams.id = this.formValues.id;
        apiParams.use_date = this.formValues.use_date;
      } else {
        apiParams.use_date_from = this.formValues.use_date_from;
        apiParams.use_date_to = this.formValues.use_date_to;
      }
      apiParams.device_usage_times = addOneDayToEndTime(apiParams.device_usage_times);
      const result = await Store.dispatch(
        //idがある場合は更新、ない場合は新規
        hasId ? `ScheduleCrane/update` : `ScheduleCrane/post`,
        apiParams
      );
      //レスポンスエラーがある場合はフォームを閉じない
      //エラーメッセージは、api.jsが表示
      if (result.hasError) {
        return;
      } else {
        if (this.isNewItem) {
          this.$emit("cancel");
        } else {
          this.getItems();
          this.onDetail();
        }
        Store.dispatch("Toast/show", {
          status: 200,
          message: hasId ? "更新しました" : "登録しました",
        });
      }
    },
    
    chooseCrane(id) {
      this.crane_id = id;
    },
    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid }) {
      this.valid = valid;
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      if(this.isClickCancelBtn && !this.isNewItem) {
        this.isShowConfirmDialog = false;
        this.editable = false;
        this.isClickCancelBtn = false;
        this.formValues = {...this.backData};
      } else {
        this.isShowConfirmDialog = false;
        this.formValues = {};
        this.$emit("cancel");
      }
    },
    closePopup() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
    /**
     * (共通)
     * click button Close (X)
     */
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isShowConfirmDialog = true;
      }
    },
    async getItems() {
      Store.dispatch(`ScheduleCrane/getScheduleCraneDetail`, this.item.schedule_id);
    },
    /**
     * function copy data last regist
     */
    async onCopy() {
      const result = await Store.dispatch(`ScheduleCrane/getCraneUseLastTime`, this.CURRENT_SITE.field_id);
      if (!result.hasError && result.data.contents.entries) {
        let data = _.cloneDeep(result.data.contents.entries);
        data = {...this.formValues, ...data};
        data['use_date_from']= this.formValues['use_date_from'];
        data['use_date_to']= this.formValues['use_date_to'];
        this.formValues = _.cloneDeep(data);
      } else {
        this.initDataNewItem();
      }
    },
    initDataNewItem() {
      const data = _.cloneDeep(INIT_DATA);
      data.use_date_from = this.item.use_date_from;
      data.use_date_to = this.item.use_date_to;
      data.field_construction_id = this.item.field_construction_id;
      data.work_hour_zone = this.item.work_hour_zone;
      this.formValues = _.cloneDeep(data);
    },
    async getPlanItemSetting() {
      let params = {
        field_id: this.CURRENT_SITE.field_id,
        company_type: 0 // 0: not obayashi
      };
      let rs = await Store.dispatch("Customizes/get", { params });
      this.cranePassagesCustomize = _.cloneDeep(rs.data.contents.entries)?.crane_uses;
    },
  },
  
  /**
   * Important: clear timeout
   */
  beforeDestroy () {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout)
  }
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
