<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <!--col 1 -->
                <v-col cols="12" sm="6" md="6">
                  <SectionLabel label="日付" class="ml-4" v-if="isNewItem"/>
                  <v-row no-gutters v-if="isNewItem">
                    <v-col cols="12" sm="4" md="4" class="workDate">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="日付(開始)" :editable="editable" required>
                            <InputDatepicker
                              name="work_date_start"
                              :values="work_group_results"
                              :editable="editable && !disableData"
                              validation_label="日付(開始)"
                              :validation_rules="getStartDateRules"
                              @onInput="onInput"
                              :flagNull="true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="workDate">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="日付(終了)" :editable="editable" required>
                            <InputDatepicker
                              name="work_date_end"
                              :values="work_group_results"
                              :editable="editable && !disableData"
                              validation_label="日付(終了)"
                              :validation_rules="getEndDateRules"
                              @onInput="onInput"
                              :flagNull="true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="作業時間帯" :editable="editable" required>
                            <Select
                              name="work_hour_zone"
                              :values="work_group_results"
                              :editable="editable && !disableData"
                              :items="WORK_TIMEZONE_ARRAY"
                              validation_label="作業時間帯"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-else>
                    <v-col cols="12" sm="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="日付" :editable="editable" required>
                            <InputDatepicker
                              name="work_date"
                              :values="work_group_results"
                              :editable="editable && !disableData"
                              validation_label="日付"
                              validation_rules="required"
                              @onInput="onInput"
                              :flagNull="true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item :class="editable ? '' : 'mt-2'">
                        <v-list-item-content>
                          <Label label="作業時間帯" :editable="editable" required>
                            <Select
                              name="work_hour_zone"
                              :values="work_group_results"
                              :editable="editable && !disableData"
                              :items="WORK_TIMEZONE_ARRAY"
                              validation_label="作業時間帯"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <SectionLabel class="ml-4" label="作業責任者等情報" />
                  <v-row no-gutters>
                    <v-col cols="12" sm="4" md="4">
                      <Label label="協力会社" :editable="editable" required :class="editable && !enableFieldTreeId1 ? 'ml-3 mt-2':'ml-3'">
                        <SelectWithFilter
                          name="field_tree_id_1"
                          :values="work_results"
                          :editable="editable && isParentEditable && enableFieldTreeId1"
                          :enableFieldTreeId1="enableFieldTreeId1"
                          :class="{'py-2 d-block': editable && enableFieldTreeId1 && !isParentEditable}"
                          :items="COMPANY_PARTNER_LEVEL_1_ARRAY"
                          item_value="field_tree_id"
                          item_text="company_name"
                          :label="work_results.field_tree_company_name_1 || ''"
                          validation_label="協力会社"
                          validation_rules="required"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <Label label="職種" :editable="editable" required>
                        <Select
                          name="cmn_mst_skill_id"
                          :values="work_results"
                          :editable="editable && isParentEditable"
                          :class="{'py-2 d-block': editable && !isParentEditable}"
                          :items="JOB_ARRAY"
                          item_text="sub_category"
                          item_value="cmn_mst_skill_id"
                          validation_label="職種"
                          validation_rules="required"
                          :label="work_results.job_name_sub_category || ''"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <Label label="作業責任者" :editable="editable" required>
                        <span @click="editable ? openPopUpSearchUser() : null" class="select">
                          <span class="select_wrap">
                            <Select
                              name="field_tree_user_id"
                              :values="work_results"
                              :editable="editable"
                              :items="FIELD_TREE_USER_ARRAY"
                              item_text="user_name"
                              item_value="field_tree_user_id"
                              validation_label="作業責任者"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </span>
                        </span>
                      </Label>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <Label class="mr-4" label="元請担当者" :editable="editable" required>
                        <span @click="editable ? openPopUpSearchManager() : null" class="select">
                          <span class="select_wrap">
                            <Select
                              name="work_user_results_manager_id"
                              :values="work_results"
                              :editable="editable"
                              :items="MANAGER_ARRAY"
                              item_text="user_name"
                              validation_label="元請担当者"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </span>
                        </span>
                      </Label>
                    </v-col>
                  </v-row>
                  <SectionLabel class="ml-4" label="作業詳細"/>
                  <v-row no-gutters class="work-content">
                    <v-col cols="12" :sm="editable ? '10':'12'" :md="editable ? '10':'12'">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="作業内容" :editable="editable" required>
                            <InputTextArea
                              name="contents"
                              :values="work_results"
                              :editable="editable"
                              validation_label="作業内容"
                              validation_rules="required|max:255"
                              @onInput="onInput"
                              :countRow="2"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" v-if="editable">
                      <v-btn color="#1B9C4F" class="mt-11 mr-3 white--text" width="5vw" style="float: right" @click="openHistoryPopup(HISTORY_TYPE.contents,'作業詳細')">
                        履歴
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" :sm="editable ? '10':'12'" :md="editable ? '10':'12'">
                      <v-list-item>
                        <v-list-item-content>
                          <Label class="mr-4" label="資格" :editable="editable">
                            <div @click="editable ? openPopUpSearchQualifications() : null" class="">
                              <InputTextSelectMultiple
                                name="work_qualifications"
                                :values="formValues"
                                :editable="editable"
                                :listWorkQualifications="listWorkQualifications"
                                validation_label="資格"
                                :readonly="true"
                                @onInput="onInput"
                              />
                            </div>
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" v-if="editable">
                      <v-btn color="#1B9C4F" class="mt-11 mr-3 white--text" width="5vw" style="float: right"
                      @click="openHistoryQualificationPopup()">
                        履歴
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="備考" :editable="editable">
                            <InputText
                              name="remarks"
                              :values="work_results"
                              :editable="editable"
                              validation_label="備考"
                              validation_rules="max:512"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="作業場所" :editable="editable">
                            <Select
                              name="field_item_tree_id"
                              :values="work_results"
                              :editable="editable"
                              :items="WORK_PLACE"
                              item_text="item_name"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <SwitchInput
                            class="mt-6 ml-4"
                            name="hot_work_flag"
                            label="火気作業の有無"
                            :values="work_results"
                            :editable="editable"
                            @onInput="onInput"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" :sm="editable ? '10':'12'" :md="editable ? '10':'12'">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="安全指示事項" :editable="editable" required>
                            <InputText
                              name="safety_instructions"
                              :values="work_results"
                              :editable="editable"
                              validation_label="安全指示事項"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" v-if="editable">
                      <v-btn color="#1B9C4F" class="mt-11 mr-3 white--text" width="5vw" style="float: right"
                        @click="openHistoryPopup(HISTORY_TYPE.safety_instructions,'安全指示事項')">
                        履歴
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <!--Col 2-->
                <v-col>
                  <SectionLabel label="契約" class="ml-2"/>
                  <v-row no-gutters>
                    <v-list-item v-if="!editable">
                      <v-list-item-content>
                        <v-col cols="12" sm="6" md="6"><span>請負/常傭</span></v-col>
                      </v-list-item-content>
                    </v-list-item>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label :label="editable ? '契約区分' : ''" :editable="editable">
                            <Select
                              name="dayworker_type"
                              :values="work_results"
                              :items="CONTRACT_CLASSIFICATION"
                              :editable="editable"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row justify="space-between" no-gutters style="height: 40px">
                    <v-col>
                      <SectionLabel label="工数" style="margin-left: -4px"/>
                    </v-col>
                    <v-col>
                      <SwitchInput
                        name="display_fifteen_minute_flg"
                        :label="`人工を15分単位で計算する`"
                        :values="work_results"
                        :editable="editable"
                        @onInput="onInput"
                      />
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="7" md="7">
                      <WorkDayWorker
                        name="work_dayworker_results"
                        class="work-dayworker-results"
                        :values="formValues"
                        :editable="editable"
                        :is-result="true"
                        @onInput="onInput"
                      />
                    </v-col>
                    <v-col cols="12" sm="5" md="5">
                      <v-row no-gutters style="margin-left: 15px;">
                        <v-list-item v-if="!editable">
                          <v-list-item-content>
                            <v-col cols="12" sm="6" md="6"><span class="ws-no-wrap">女性</span></v-col>
                            <v-col cols="12" sm="6" md="6"><span class="ws-no-wrap">外国人</span></v-col>
                          </v-list-item-content>
                        </v-list-item>
                        <v-col>
                          <v-list-item>
                            <v-list-item-content>
                              <Label :label="editable ? '女性' : ''" :editable="editable">
                                <Select
                                  name="woman"
                                  :values="work_results"
                                  :items="NUMBER_OF_PEOPLE"
                                  :editable="editable"
                                  :validation_rules="getValidationRules()"
                                  @onInput="onInput"
                                />
                              </Label>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col>
                          <v-list-item>
                            <v-list-item-content>
                              <Label :label="editable ? '外国人' : ''" :editable="editable">
                                <Select
                                  name="non_japanese"
                                  :values="work_results"
                                  :items="NUMBER_OF_PEOPLE"
                                  :validation_rules="getValidationRules()"
                                  :editable="editable"
                                  @onInput="onInput"
                                />
                              </Label>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <SectionLabel label="早出" class="ml-2" />
                  <WorkEarlyTimes
                    name="work_early_over_time_results"
                    :values="formValues"
                    :editable="editable"
                    :total="totalUsers"
                    @onInput="onInput"
                  />
                  <SectionLabel label="残業" class="ml-2" />
                  <WorkOverTimes
                    name="work_early_over_time_results"
                    :values="formValues"
                    :editable="editable"
                    :total="totalUsers"
                    @onInput="onInput"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
      <Popup width="480px" :dialog="isShowHistoryDialog">
        <HistorySelectDialog
          nameStore="ScheduleRecords"
          :field_tree_id="formValues.work_results ? formValues.work_results.field_tree_id : null"
          :historyType="historyType"
          :placeHolder="placeHolderHistory"
          @close="closeHistoryDialog"
          @updateCommentHistory="updateCommentHistory"
        />
      </Popup>
      <Popup width="480px" :dialog="isShowSearchUserDialog">
        <SearchUserDialog
          :field_construction_id="work_group_results.field_construction_id"
          :field_tree_id_1="work_results.field_tree_id_1"
          :job_id="work_results.job_id"
          :is_oba="ENV_OBAYASHI ? 1 : null"
          storeName="ScheduleRecords"
          @close="isShowSearchUserDialog = false"
          @updateData="updateUser"
        />
      </Popup>
      <Popup width="480px" :dialog="isShowSearchManagerDialog">
        <SearchManagerDialog
          :field_construction_id="work_group_results.field_construction_id"
          :job_id="work_results.job_id"
          storeName="ScheduleRecords"
          @close="isShowSearchManagerDialog = false"
          @updateData="updateManager"
        />
      </Popup>
      <Popup width="480px" :dialog="isShowSearchQualificationsDialog">
        <SearchQualificationsDialog
          :workQualifications="formValues.work_qualifications"
          :listWorkQualifications="listWorkQualifications"
          storeName="ScheduleWorks"
          @close="isShowSearchQualificationsDialog = false"
          @updateData="updateQualifications"
        />
      </Popup>
      <Popup width="480px" :dialog="isShowHistoryQualificationDialog">
        <HistoryQualificationSelectDialog
          nameFunction="getListHistoryQualificationWorkResults"
          :field_id="CURRENT_SITE.field_id"
          :isObayashi="ENV_OBAYASHI ? 1 : 0"
          :listWorkQualifications="listWorkQualifications"
          :historyType="2"
          @close="(isShowHistoryQualificationDialog = false)"
          @updateQualificationHistory="updateQualificationHistory"
        />
      </Popup>
    </div>
  </div>
</template>
<script>
import Label from "@/components/forms/elements/Label";
import Popup from "@/components/common/Popup";
import InputText from "@/components/forms/elements/InputText";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import Select from "@/components/forms/elements/Select.vue";
import SelectWithFilter from "@/components/forms/elements/SelectWithFilter.vue";
import SwitchInput from "@/components/forms/elements/SwitchInput";
import WorkPlaceInput from "@/components/forms/schedule/works/components/WorkPlaceInput.vue";
import HistorySelectDialog from "@/components/forms/schedule/components/HistorySelectDialog.vue";
import HistoryQualificationSelectDialog from "./../../components/HistoryQualificationSelectDialog.vue";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import WorkDayWorker from "@/components/forms/schedule/works/components/WorkDayWorker.vue";
import WorkEarlyTimes from "@/components/forms/schedule/works/components/WorkEarlyTimes.vue";
import WorkOverTimes from "@/components/forms/schedule/works/components/WorkOverTimes.vue";
import SearchUserDialog from "../../components/SearchUserDialog";
import SearchManagerDialog from "../../components/SearchManagerDialog";
import SearchQualificationsDialog from "../../components/SearchQualificationsDialog";
import InputTextSelectMultiple from "../../components/InputTextSelectMultiple";
import InputTextArea from "@/views/schedule/patrols/components/InputTextArea";
import { NUMBER_OF_PEOPLE, CONTRACT_CLASSIFICATION, HISTORY_TYPE, USER_TYPE } from "@/constants/SCHEDULE_WORKS";
import { Store } from "@/store/Store.js";
import _ from "lodash";
import { ROLE_USER } from "@/constants/DEVICE_STOCK";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";

const INIT_WORK_USER_RESULTS = {
  "1": [
    {
      user_id: null,
      used_type: 1,
    },
  ],
  "2": [
    {
      user_id: null,
      used_type: 2,
    },
  ],
  "3": [
    {
      user_id: null,
      used_type: 3,
    },
  ],
  "4": [
    {
      user_id: null,
      used_type: 4,
    },
    {
      user_id: null,
      used_type: 4,
    },
  ],
  "5": [
    {
      user_id: null,
      used_type: 5,
    },
    {
      user_id: null,
      used_type: 5,
    },
  ],
}

export default {
  data: () => {
    return {
      formValues: {},
      CONSTRUCTION: [],
      WORKING_GROUP: [],
      PARTNER_COMPANY: [],
      FOREMAN: [],
      WORK_PLACE: [],
      USER_EXPERIENCES: [],
      CMN_MST_SKILLS: Store.state.CmnMst.constants.entries.cmn_mst_skills,
      WORK_TIMEZONE_ARRAY: [],
      work_results: {},
      work_group_results: {},
      COMPANY_PARTNER_LEVEL_1_ARRAY : [{ field_tree_id: null, company_name: "" }],
      JOB_ARRAY: [{ cmn_mst_skill_id: null, sub_category: "" }],
      FIELD_TREE_USER_ARRAY : [{ field_tree_user_id: null, user_name: "" }],
      MANAGER_ARRAY : [{ id: null, user_name: "" }],
      NUMBER_OF_PEOPLE,
      CONTRACT_CLASSIFICATION,
      HISTORY_TYPE,
      dataTimeMax: Store.state.PortalChart.workingTime,
      totalUsers: 0,
      totalNonJapanWoman: 0,
      isShowHistoryDialog : false,
      isShowSearchUserDialog : false,
      isShowSearchManagerDialog : false,
      isShowSearchQualificationsDialog : false,
      isShowWorkSafetyInstructionHistoryDialog : false,
      historyType : null,
      placeHolderHistory : null,
      isEditChildren: false,
      listWorkQualifications: [],
      isShowHistoryQualificationDialog: false
    };
  },
  components: {
    SectionLabel,
    Label,
    Popup,
    InputText,
    InputDatepicker,
    Select,
    SelectWithFilter,
    WorkPlaceInput,
    SwitchInput,
    WorkDayWorker,
    WorkEarlyTimes,
    WorkOverTimes,
    HistorySelectDialog,
    HistoryQualificationSelectDialog,
    SearchUserDialog,
    SearchManagerDialog,
    SearchQualificationsDialog,
    InputTextSelectMultiple,
    InputTextArea
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    errors: {
      type: Object,
      default: {},
    },
    disableData: Boolean,
    formUpdate: Function,
    isNewItem: Boolean,
    resetFormImage: Boolean,
    roleUser : Number,
    isBackData : Boolean,
    copyObject : Object,
    arrLevel1 : Array,
    isParentEditable : Number
  },
  mounted() {
    this.getlistWorkQualifications()
    this.getWorkTypes(this.CURRENT_SITE.field_id);
    this.$watch(
      () => this.formValues?.work_group_results?.field_construction_id,
      (newVal,oldVal) => {
        if (newVal && oldVal === undefined) {
          this.initData(this.formValues);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = _.cloneDeep(newValue);
        this.work_results = {...newValue['work_results']};
        this.work_group_results = {...newValue['work_group_results']};
        this.totalNonJapanWoman = this.work_results?.non_japanese + this.work_results?.woman;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.isBackData,
      (newValue,oldValue) => {
        if (newValue !== oldValue && newValue === true) {
          this.initData(this.item);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => this.arrLevel1,
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue) && newValue.length > 0) {
          this.COMPANY_PARTNER_LEVEL_1_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name"), ...newValue];
          this.getListSkillAndCmnMstSkillIds(this.item.work_results.field_tree_id_1, this.item.work_group_results.field_construction_id);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => this.copyObject,
      (newValue) => {
        if (newValue && newValue.status === true) {
          this.initDataOncopy(this.item, newValue);
        }
      },
      {
        immediate : true,
        deep: true
      }
    );
    this.$watch(
      () => Store.getters["PortalChart/getListItemTrees"],
      (data) => {
        this.WORK_PLACE = [this.getDefaultArrays("id", "item_name"),...data];
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["PortalChart/getFieldTimeZones"],
      (data) => {
        this.WORK_TIMEZONE_ARRAY = [this.getDefaultArrays("id", "name"),...data];
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getListSkillAndCmnMstSkillIds"],
      (data) => {
        this.JOB_ARRAY = [this.getDefaultArrays("cmn_mst_skill_id", "sub_category"), ...data];
      },
      {
        deep: true
      }
    );
    this.$watch(
      () => Store.getters["Schedule/getListQualification"],
      (data) => {
        this.listWorkQualifications = _.cloneDeep(data)
      },
      {
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    getStartDateRules() {},
    getEndDateRules() {},
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    ENV_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
    enableFieldTreeId1() {
      return this.roleUser == ROLE_USER[3] || this.COMPANY_PARTNER_LEVEL_1_ARRAY.length > 2 || !this.work_group_results.field_construction_id;
    },
    disableBtn() {
      return this.formValues.work_results ? this.formValues.work_results.field_tree_id == null : true;
    },
  },
  methods: {
    initDataOncopy(formValues,copy){
      let data = _.cloneDeep(formValues);
      // if change construction (not disable data ) => call api for field_construction_id
      if (copy.changeConstruction) {
        this.getListItemTrees(data.work_group_results.field_construction_id);
        this.getDataTimeZones(data.work_group_results.field_construction_id);
        this.getListCompanyPartnerLevel1(data.work_group_results.field_construction_id,true)
      }
      if (copy.flag) {
        // call api for all
        this.getListSkillAndCmnMstSkillIds(data.work_results.field_tree_id_1, data.work_group_results.field_construction_id);
        this.FIELD_TREE_USER_ARRAY = [{
          field_tree_user_id: data.work_results.field_tree_user_id,
          user_name: data.work_results.field_tree_user_id_name
        }];
        this.MANAGER_ARRAY = [{
          id: data.work_results.work_user_results_manager_id,
          user_name: data.work_user_results[USER_TYPE.TYPE_1][0].user_name
        }];
      }
      this.$emit('initialObjectCopy', this.formValues.work_results.addChild);
    },
    initData(formValues){
      let data = _.cloneDeep(formValues);
      this.getListItemTrees(data.work_group_results.field_construction_id);
      this.getDataTimeZones(data.work_group_results.field_construction_id);
      this.getListCompanyPartnerLevel1(data.work_group_results.field_construction_id);
      if (this.editable === false) {
        this.getListSkillAndCmnMstSkillIds(data.work_results.field_tree_id_1, data.work_group_results.field_construction_id);
        this.FIELD_TREE_USER_ARRAY = [{
            field_tree_user_id: data.work_results.field_tree_user_id,
            user_name: data.work_results.field_tree_user_id_name
          }];
        this.MANAGER_ARRAY = [{
          id: data.work_results.work_user_results_manager_id,
          user_name: data.work_user_results[USER_TYPE.TYPE_1][0].user_name
        }];
      }
    },
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      if (name == "field_construction_id" || name == "work_date_start" || name == "work_date_end" || name == "work_date" || name == "work_hour_zone") {
        if (name == "field_construction_id") {
          formValues.work_results.field_tree_id_1 = null;
          formValues.work_results.job_id = null;
          formValues.work_results.cmn_mst_skill_id = null;
          formValues.work_results.field_item_tree_id = null;
          formValues.work_results.field_tree_user_id = null;
          formValues.work_results.field_tree_id = null;
          formValues.work_results.work_user_results_manager_id = null;
          formValues.work_user_results = _.cloneDeep(INIT_WORK_USER_RESULTS);
          if (!value) {
            this.COMPANY_PARTNER_LEVEL_1_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name")];
            this.WORK_PLACE = [this.getDefaultArrays("id", "item_name")];
          } else {
            this.getListItemTrees(value);
            this.getDataTimeZones(value);
            this.getListCompanyPartnerLevel1(value);
          }
          this.JOB_ARRAY = [this.getDefaultArrays("cmn_mst_skill_id", "sub_category")];
          this.FIELD_TREE_USER_ARRAY = [this.getDefaultArrays("field_tree_user_id", "user_name")];
          this.MANAGER_ARRAY = [this.getDefaultArrays("id", "user_name")];
        }

        if (name == 'work_date_start' || name == 'work_date_end') {
          let date = value.replaceAll("-", "/");
          this.work_group_results.work_date_start = this.work_group_results.work_date_start.replaceAll("-", "/");
          this.work_group_results.work_date_end = this.work_group_results.work_date_end.replaceAll("-", "/");
          let start_date = this.work_group_results.work_date_start;
          let end_date = this.work_group_results.work_date_end;
          if (date <  start_date )  {
            this.work_group_results.work_date_start = date ;
          formValues["work_group_results"]['work_date_start'] = date;
          }
          if (date > end_date )  {
          this.work_group_results['work_date_end'] =  date ;
          formValues["work_group_results"]['work_date_end'] = date;
          }
      }
        formValues["work_group_results"][name] = value;
      } else {
        if (name == "work_keyplan_image_results" || name == "work_dayworker_results" || name == "work_early_over_time_results") {
          formValues[name] = value;
        } else if (name == "field_tree_id_1") {
          this.getListSkillAndCmnMstSkillIds(value, formValues.work_group_results.field_construction_id);
          formValues.work_results.job_id = null;
          formValues.work_results.cmn_mst_skill_id = null;
          formValues.work_results.field_tree_user_id = null;
          formValues.work_results.field_tree_id = null;
          formValues.work_results.work_user_results_manager_id = null;
          formValues.work_user_results = _.cloneDeep(INIT_WORK_USER_RESULTS);
          formValues["work_results"][name] = value;
          this.FIELD_TREE_USER_ARRAY = [this.getDefaultArrays("field_tree_user_id", "user_name")];
          this.MANAGER_ARRAY = [this.getDefaultArrays("id", "user_name")];
        } else if (name == "display_fifteen_minute_flg") {
          formValues.work_dayworker_results.forEach(item => {
            item.working_temp_hour = null;
            this.calculateDayWorkers(item, value);
          });
          formValues["work_results"][name] = value;
        } else {
          if (name == "cmn_mst_skill_id") {
            formValues.work_results.field_tree_user_id = null;
            formValues.work_results.field_tree_id = null;
            formValues.work_results.job_id = this.JOB_ARRAY.find((item) => item.cmn_mst_skill_id == value)?.category_id;
            formValues.work_results.work_user_results_manager_id = null;
            this.FIELD_TREE_USER_ARRAY = [this.getDefaultArrays("field_tree_user_id", "user_name")];
            this.MANAGER_ARRAY = [this.getDefaultArrays("id", "user_name")];
          }
          formValues["work_results"][name] = value;
        }
      }

      this.$emit("formUpdate", formValues);
    },
    async getWorkTypes(fieldId) {
      await Store.dispatch("PortalChart/getWorkTypes", fieldId);
      this.CONSTRUCTION = [this.getDefaultArrays("id", "name"),...Store.getters["PortalChart/getWorkTypes"]];
    },

    async getListCompanyPartnerLevel1(fieldConstructionId,flag) {
      if (fieldConstructionId) {
        const params = { field_construction_id: fieldConstructionId };
        if (this.ENV_OBAYASHI) {
            params.is_oba = 1
        }
        let rs = await Store.dispatch("PortalChart/getListCompanyPartnerLevel1", { params });
        let arr = _.cloneDeep(rs.data.contents.entries);
        if (arr.length > 0) {
          this.COMPANY_PARTNER_LEVEL_1_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name"), ...arr];
          if (this.editable && !flag) {
            const formValues = { ...this.formValues };
            if (this.isNewItem) {
              const isSelected = arr.find(item => {
                if (formValues.work_results.addChild) {
                  return this.work_results.field_tree_id_1 === item.field_tree_id
                } else {
                  return item.is_selected === true
                }
              })
              formValues.work_results.field_tree_id_1 = isSelected ? isSelected.field_tree_id : arr[0].field_tree_id;
              if(formValues.work_results.addChild) {
                formValues.work_results.field_tree_company_name_1 = isSelected.company_name
              }
            }
            await this.getListSkillAndCmnMstSkillIds(formValues.work_results.field_tree_id_1, formValues.work_group_results.field_construction_id);
            this.$emit("formUpdate", formValues);
          }
        } else {
          this.COMPANY_PARTNER_LEVEL_1_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name")];
          this.JOB_ARRAY = [this.getDefaultArrays("cmn_mst_skill_id", "sub_category")];
          const formValues = { ...this.formValues };
          formValues.work_results.field_tree_id_1 = null;
          this.$emit("formUpdate", formValues);
        }
      }
    },

    getSkills(cmn_mst_skills_id) {
      let skill = this.CMN_MST_SKILLS.find((e) => e.id == cmn_mst_skills_id);
      if (skill) {
        return { name: skill.catergory + " " + skill.sub_catergory };
      }
      return { name: "" };
    },
    getDefaultArrays(id, value) {
      let results = {};
      results[id] = null;
      results[value] = "";
      return results;
    },
    async getListItemTrees(val) {
      let params = {
        field_id: this.CURRENT_SITE.field_id,
        field_construction_id: val
      };
      await Store.dispatch("PortalChart/getListItemTrees", { params });
    },
    async getDataTimeZones(field_construction_id){
      await Store.dispatch(`PortalChart/getFieldTimeZones`, field_construction_id);
    },
    calculateDayWorkers(itemEdited, displayFifteenMinuteFlg) {
      let dayworker = 0;
      if (displayFifteenMinuteFlg == 0 && itemEdited.working_temp_person > 0) {
        dayworker = itemEdited.working_temp_person * itemEdited.working_temp_hour;
      } else if (displayFifteenMinuteFlg == 1 && itemEdited.working_temp_person > 0) {
        dayworker = (itemEdited.working_temp_person * itemEdited.working_temp_hour) / this.dataTimeMax;
      }
      if (isNaN(dayworker) || itemEdited.working_temp_hour == null) {
        itemEdited.dayworker = '--';
      } else {
        itemEdited.dayworker = (itemEdited.working_temp_person != 0) ? `${parseFloat(Number(dayworker).toFixed(displayFifteenMinuteFlg == 0 ? 2 : 5)) + 0}` : 0;
      }
    },
    getValidationRules() {
      let total = 0;
      if (this.formValues && this.formValues.work_dayworker_results) {
        this.formValues.work_dayworker_results.forEach(element => total += element.working_temp_person);
        this.totalUsers = total;
        return `compare-data-selectbox:${total}|compare-total-data-selectbox:${this.totalNonJapanWoman},${total}`;
      }
    },

    openHistoryPopup(type,placeHolder){
      this.placeHolderHistory = placeHolder;
      this.historyType = type;
      this.isShowHistoryDialog = true;
    },
    openHistoryQualificationPopup() {
      this.isShowHistoryQualificationDialog = true;
    },
    updateQualificationHistory(value) {
      this.formValues.work_qualifications = value
      this.isShowHistoryQualificationDialog = false;
      this.$emit("formUpdate", this.formValues);
    },
    closeHistoryDialog() {
      this.historyType = null;
      this.placeHolderHistory = null;
      this.isShowHistoryDialog = false;
    },
    updateCommentHistory(item){
      this.isShowHistoryDialog = false;
      const formValues = { ...this.formValues };
      if (this.historyType == HISTORY_TYPE.contents) {
        formValues["work_results"]["contents"] = item;
      } else {
        formValues["work_results"]["safety_instructions"] = item;
      }
      this.historyType = null;
      this.placeHolderHistory = null;
      this.$emit("formUpdate", formValues);
    },
    async getListSkillAndCmnMstSkillIds(id, fieldConstructionId) {
      if (id) {
        let params = { field_tree_id_1: id, field_construction_id: fieldConstructionId , is_oba: this.ENV_OBAYASHI ? 1 : null };
        await Store.dispatch("PortalChart/getListSkillAndCmnMstSkillIds", { params });
      } else {
        this.JOB_ARRAY = [this.getDefaultArrays("cmn_mst_skill_id", "sub_category")];
      }
    },
    openPopUpSearchUser(){
      this.isShowSearchUserDialog = true;
    },
    openPopUpSearchManager(){
      this.isShowSearchManagerDialog = true;
    },
    openPopUpSearchQualifications(){
      this.isShowSearchQualificationsDialog = true;
    },
    updateUser(item,data){
      this.isShowSearchUserDialog = false;
      this.FIELD_TREE_USER_ARRAY = [this.getDefaultArrays('field_tree_user_id','user_name'),...[...data]];
      let formValues = {...this.formValues};
      formValues.work_results.field_tree_user_id = item.field_tree_user_id;
      formValues.work_results.field_tree_id = item.field_tree_id;
      this.$emit("formUpdate", formValues);
    },
    updateManager(item,data){
      this.isShowSearchManagerDialog = false;
      this.MANAGER_ARRAY = [this.getDefaultArrays('id','user_name'),...[...data]];
      let formValues = { ...this.formValues };
      formValues.work_results.work_user_results_manager_id = item.id;
      formValues.work_user_results[USER_TYPE.TYPE_1] = [{ user_id: item.id, used_type: 1 }];
      this.$emit("formUpdate", formValues);
    },
    async getlistWorkQualifications() {
      await Store.dispatch("Schedule/getListQualification")
    },
    updateQualifications(value){
      this.isShowSearchQualificationsDialog = false;
      this.formValues.work_qualifications = value.map(x => x.id)
      this.$emit("formUpdate", this.formValues);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/forms.scss";

.select {
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.row-position {
  margin-top: -20px;
  height: 150px;
  position: relative;
}

.select-position {
  width: 100%;
  position: absolute;
}

.select_wrap{
  width: 100%;
  pointer-events: none;
}
.ws-no-wrap {
  white-space: nowrap;
}
@media only screen and (max-width: 1300px) {
  .FormDialog .container > .row > * > .row > .workDate {
    padding: 0px !important;
  }
  ::v-deep .workDate .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot {
      padding: 0px 4px;
    }
}
::v-deep .v-select__selections {
  flex: 1 auto !important;
  flex-wrap: nowrap !important;
}
::v-deep .v-select__selections .v-chip {
  display: inline-table !important;
}
::v-deep .v-select__selections input {
  min-width: auto !important;
}
::v-deep .v-input__prepend-inner {
  margin-top: 8px !important;
  margin-left: -2px !important;
}
::v-deep .work-content .theme--light.v-text-field.v-input--is-readonly .v-input__slot:before {
  border-image: repeating-linear-gradient(90deg,rgba(0,0,0,.38) 0,rgba(0,0,0,.38) 2px,transparent 0,transparent 4px) 1 repeat;
}
::v-deep  .work-content .v-text-field.v-input--is-readonly>.v-input__control>.v-input__slot:after {
  background-color: unset !important;
  border-color: unset !important;
  border-style: unset !important;
  border-width: unset !important;
}
</style>
