<template>
  <v-card class="dialog">
    <v-card-title>
      <div class="title">画像の追加</div>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        画像1枚あたり最大10MBまでアップロードできます。
      </div>
      <div class="file-input-area" @drop.prevent="addFile" @dragover.prevent>
        <div class="text">ここにファイルを</div>
        <span class="text">ドラッグ＆ドロップ</span>
        <div class="file-name" v-for="(file, index) in files" :key="index" >
          <div class="file-name-detail">
            {{ getFileName(file.name,index) }}
          </div>
          <v-btn small icon text v-if="files.length > 0" class="btn-delete" @click="removeFile(index)" title="削除">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
        <div class="center mb-8">
          <v-btn depressed color="primary" class="btn" @click="onUpload">
            ファイルから選択
          </v-btn>
        </div>
        <form ref="form">
          <input
            multiple
            style="display: none"
            ref="input_drawing_file"
            type="file"
            accept="image/jpg, image/png, image/jpeg"
            @change="onImageInput()"
          />
        </form>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="primary"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn
        depressed
        class="btn"
        color="primary"
        :disabled="files.length == 0"
        :key="flagError"
        @click.once="onSubmit"
      >
        登録
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { Store } from "@/store/Store.js";
export default {
  data() {
    return {
      files: [],
    };
  },
  props: {
    flagError: Boolean,
    countImage: Number
  },
  mounted() {

  },
  methods: {
    addFile(e) {
      let files = [];
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach((f) => {
        files.push(f);
      });
      this.setFile(files);
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    onUpload() {
      this.$refs.input_drawing_file.click();
    },
    onImageInput() {
      const file = this.$refs.input_drawing_file.files;
      if (!file) {
        return;
      }
      this.setFile(file);
      this.$refs.form.reset();
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async onSubmit() {
      const handleFiles = async (files) => {
        const list = await Promise.all(files.map(async (file) => {
          return {
            fileName: file.name,
            data: await this.getBase64(file)
          };
        }));
        return list;
      };
      let rs = await handleFiles(this.files);
      this.$emit("yes", rs);
    },
    setFile(file) {
      let files = [];
      let sumFiles = 0;
      if (file.length > 5) {
        Store.dispatch(
            "Error/show",
            { status: "400", message: "最大５枚までアップロード可能です。" },
            { root: true }
        );
        return;
      }
      sumFiles = this.countImage + file.length;
      if (sumFiles > 5) {
        Store.dispatch(
            "Error/show",
            { status: "400", message: "最大５枚までアップロード可能です。" },
            { root: true }
        );
        return;
      }
      if (Array.from(file).filter(e => e.name.toLocaleLowerCase().match(/\.(png|jpg|jpeg)$/)).length == file.length){
        Array.from(file).forEach(e1 => {
          if (e1.size > 10 * 1024 * 1024) {
            Store.dispatch(
                "Error/show",
                {
                  status: "400",
                  message: "画像ファイルは10.0MB以内でお願いします。",
                },
                { root: true }
            );
            return;
          }
          files.push(e1);
        });
      } else {
        Store.dispatch(
          "Error/show",
          { status: "400", message: "画像ファイルをアップロードしてください" },
          { root: true }
        );
        return;
      }
      this.files = Array.from(files);
    },
    getFileName(name, index) {
      let rs = name;
      if (index !== this.files.length - 1) {
        rs += ",";
      }
      return rs;
    }
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .title {
    color: green;
  }
  .header {
    .header-title-area {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      max-width: 100%;
    }
  }
  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;
      font-size: 18px;
      text-align: center;
    }
  }
  .btn {
    margin-right: 12px;
  }
  .file-input-area {
    min-height: 230px;
    background: #e5e5e5;
    border-style: dashed;
    .text {
      text-align: center !important;
      position: relative;
      width: 200px;
      margin-left: 130px;
      margin-top: 28px;
      font-size: 20px;
      color: black;
    }
    .center {
      text-align: center;
    }
    .file-name {
      height: 60px;
      position: relative;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .file-name-detail {
      white-space: nowrap;
      overflow-x: hidden;
      max-width: 90%;
      text-overflow: ellipsis;
    }
    .btn-delete {
      margin-left: 10px;
    }
  }
}
</style>
