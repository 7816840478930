<template>
  <v-card class="dialog">
    <div class="header sticky">
      <v-card-title small>
        <div class="title">履歴から選択</div>
        <v-spacer></v-spacer>
        <v-btn small icon text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
    </div>
    <!-- 検索コンポーネント -->
    <SearchSelectList
      :placeHolder="placeHolder"
      @search="getDataHistory"
      @formUpdate="formUpdate"
    >
      <v-list v-if="items.length > 0">
        <template v-for="(item, index) in items">
          <v-list-item :key="index" ripple>
            <v-list-item-content class="text-break" style="white-space: pre-wrap;" @click="onClick(item)">
              {{item}}
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <div style="display: flex;justify-content: center" v-else-if="isNoDataMessage">
        {{ NO_DATA_MESSAGE }}
      </div>
    </SearchSelectList>
  </v-card>
</template>

<script>
import SearchSelectList from "./SearchSelectList.vue";
import { Store } from "@/store/Store.js";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
export default {
  components: {
    SearchSelectList,
  },

  props: {
    nameStore: String,
    close: Function,
    field_tree_id: {
      type: Number,
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    placeHolder: {
      type: String
    },
    historyType : {
      type : Number
    }
  },

  data: () => {
    return {
      keyword: "",
      items: [],
      NO_DATA_MESSAGE,
      isNoDataMessage: false,
      ENV_CLIENT
    };
  },
  computed: {
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },
  async mounted() {
    await this.getDataHistory();
    this.$watch(
      () => Store.getters[`${this.nameStore}/getDataHistory${this.name}`],
      (data) => {
        if (data) {
          this.items = [...data];
          this.isNoDataMessage = this.items.length == 0;
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  methods: {
    formUpdate(values) {
      this.keyword = values.keyword;
    },

    onClick(item) {
      this.$emit("updateCommentHistory", item);
    },
    async getDataHistory(){
      const params = {
        field_id: this.CURRENT_SITE.field_id,
        history_type: 0,
        field_tree_id: this.field_tree_id,
        content_search: this.keyword
      }
      if (ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT) {
        params["history_type"] = this.historyType;
      }
      await Store.dispatch(`${this.nameStore}/getDataHistory${this.name}`, {params});
    }
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #1B9C4F;
}
.dialog {
  padding-bottom: 20px;
  .header {
    height: 65px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }
  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;
      .text {
        text-align: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 80%;
      }
    }
  }
  .btn {
    margin-right: 12px;
  }
}
</style>
