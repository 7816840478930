<template>
  <FormDialog>
    <template #header>
      <!-- 
        (共通)
        ヘッダーは全て共通
      -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editable"
          class="mr-5"
          depressed
          small
          outlined
          color="primary"
          @click="isShowConfirmDialog = true"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="isNewItem"
          depressed
          class="mr-5"
          small
          color="primary"
          @click="onCopy"
        >
          前回コピー
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          small
          :disabled="!valid || isSubmitted"
          color="primary"
          @click="onSubmit"
        >
          保存
        </v-btn>
        <v-btn
          v-if="!editable && item.approval == 0 && owner_flag"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!-- 
        (共通)
        全てのフォームのバリデーションの監視を行う
       -->
      <ValidationObserver ref="observer" v-slot="observer">
        <!-- フォームtab -->
        <v-tabs-items v-model="tab">
          <!-- 基本情報 -->
          <v-tab-item>
            <MachineUseFormPage1
              :editable="editable"
              :item="formValues"
              :isNewItem="isNewItem"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <MachineUseFormPage2
              :editable="editable"
              :item="formValues"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <MachineUseFormPage3
              :editable="editable"
              :item="formValues"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <MachineUseFormPage4
              :editable="editable"
              :item="formValues"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
        </v-tabs-items>
        <!-- 
          (共通)
          ValidationObserverのスロットプロパティを受け取って
          updateValidateメソッドを実行して、
          バリデーション結果をスクリプト側に渡してます
        -->
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { Store } from "@/store/Store.js";
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import MachineUseFormPage1 from "@/components/forms/schedule/machines/MachineUseFormPage1.vue";
import MachineUseFormPage2 from "@/components/forms/schedule/machines/MachineUseFormPage2.vue";
import MachineUseFormPage3 from "@/components/forms/schedule/machines/MachineUseFormPage3.vue";
import MachineUseFormPage4 from "@/components/forms/schedule/machines/MachineUseFormPage4.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import { TITLE, FORMS, MACHINE_USE_INITIAL_ITEM } from "@/constants/SCHEDULE_MACHINE";
import {
  OWNER_FLAG,
} from "@/constants/SCHEDULE_CRANE";
import _ from "lodash";
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON.js";

export default {
  data: () => {
    return {
      valid: false,
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      TITLE,
      FORMS,
      isSubmitted: false,
      timeout: null,
      SUBMIT_DELAY_TIMEOUT,
      owner_flag: false,
      MACHINE_USE_INITIAL_ITEM,
      formValues: {},
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    MachineUseFormPage1,
    MachineUseFormPage2,
    MachineUseFormPage3,
    MachineUseFormPage4,
  },
  props: {
    item: Object,
    isNewItem: Boolean,
    beforeUpdateItem : Object,
    isCheckApprove: Boolean,
    isErrorSubmit: Boolean,
    isEmitted: Boolean
  },
  mounted() {
    this.$watch(
      () => this.item,
      (data) => {
        this.formValues = _.cloneDeep(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * (共通)
     * 新規作成を監視
     */
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        if (flag) this.editable = true;
        if(this.item['owner_flag'] == OWNER_FLAG.OWNER) {
            this.owner_flag = true;
          }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * Catch after submitted
     */
    this.$watch(
        () => this.isEmitted,
        () => {
          if (!this.isErrorSubmit) {
            this.editable = false;
          }
        }
    );
  },
  computed: {
    formtitle() {
      return this.isNewItem ? this.TITLE.NEW : this.TITLE.EDIT;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },
  methods: {
    /**
     * (共通)
     * フォームの変更を送信
     */
    formUpdate(params) {
      this.$emit("formUpdate", params);
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      this.editable = true;
    },
    /**
     * (共通)
     * 状態を詳細に変更
     */
    onDetail() {
      this.editable = false;
    },

    /**
     * (共通)
     * 登録
     */
    onSubmit() {
      this.isSubmitted = true;
      this.$emit("submit");
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isSubmitted = false
      }, SUBMIT_DELAY_TIMEOUT);
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid }) {
      this.valid = valid;
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        let _beforeUpdateItem = _.cloneDeep(this.beforeUpdateItem);
        this.$emit("formUpdate", _beforeUpdateItem);
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
      this.isShowConfirmDialog = false;
    },

    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
    /**
     * function copy data last regist
     */
    async onCopy() {
      const result = await Store.dispatch(`ScheduleMachines/getMachineUseLastTime`, this.CURRENT_SITE.field_id);
      if (!result.hasError && result.data.contents.entries) {
        let data = _.cloneDeep(result.data.contents.entries);
        data = {...this.formValues, ...data};
        data['use_date_from']= this.item['use_date_from'];
        data['use_date_to']= this.item['use_date_to'];
        this.formValues = _.cloneDeep(data);
        await this.getMachineCompanyType();
      } else {
        this.initDataNewItem();
      }
      this.formUpdate(this.formValues);
    },
    initDataNewItem() {
      const data = _.cloneDeep(MACHINE_USE_INITIAL_ITEM);
      data.use_date_from= this.item.use_date_from;
      data.use_date_to= this.item.use_date_to;
      data.field_construction_id = this.item.field_construction_id;
      data.work_hour_zone = this.item.work_hour_zone;
      this.formValues = _.cloneDeep(data);
    },
    async getMachineCompanyType(){
      const companyUser = this.CURRENT_SITE;
      await Store.dispatch(`CompanyMachines/getMachineCompanyType`, {
        company_id: companyUser.company_id,
        company_branch_id: companyUser.company_branch_id || ""
      });
    },
  },
  /**
   * Important: clear timeout
   */
  beforeDestroy () {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout)
  }
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
