<template>
  <v-card class="dialog">
    <v-card-title>
      <div class="title">{{ title }}</div>
    </v-card-title>
    <v-card-text>
      <ValidationObserver ref="observer" v-slot="observer">
        <v-form ref="form" lazy-validation autocomplete="off">
          <v-list-item>
            <v-list-item-content>
              <Label label="建機レンタル会社" :editable="true" required>
                <Select
                  name="estimate_company_id"
                  :items="dataSelect"
                  :editable="true"
                  :values="formValues"
                  validation_label="建機レンタル会社"
                  validation_rules="required"
                  @onInput="onInput"
                >
                </Select>
              </Label>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <Label label="回答期限（希望日）" width="250px">
                <InputDatepicker
                  name="estimate_desired_response_date"
                  :editable="true"
                  :values="formValues"
                  :flagNull="true"
                  @onInput="onInput"
                />
              </Label>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <Label label="メッセージ">
                <InputTextArea
                  name="estimate_remarks"
                  :editable="true"
                  :values="formValues"
                  @onInput="onInput"
                />
              </Label>
            </v-list-item-content>
          </v-list-item>
        </v-form>
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>
      <div></div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="primary"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn
        depressed
        class="btn"
        color="primary"
        @click.once="onSubmitRequestEstimate"
        :key="flag"
        :disabled="!valid"
      >
        依頼
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback";
import Select from "@/components/forms/elements/Select";
import Label from "@/components/forms/elements/Label";
import InputTextArea from "@/components/forms/elements/InputTextArea";
import InputDatepicker from "@/components/forms/elements/InputDatepicker";
export default {
  data() {
    return {
      formValues: {
        estimate_company_id: null,
        estimate_desired_response_date: null,
        estimate_rental_company_id: null,
        estimate_rental_company_branch_id: null,
        estimate_remarks: null,
      },
      valid: false,
    };
  },
  components: {
    Select,
    Label,
    InputTextArea,
    InputDatepicker,
    ValidationCallback,
    ValidationObserver,
  },
  props: {
    title: {
      type: String,
    },
    flag: {
      type: Boolean,
    },
    dataSelect: {
      type: Array,
    },
  },
  methods: {
    onInput({ name, value }) {
      if (name == "estimate_company_id") {
        let temp = this.dataSelect.find( (e) => e.id == value );
        this.formValues.estimate_rental_company_id = temp?.company_id;
        this.formValues.estimate_rental_company_branch_id = temp?.company_branch_id;
      }
      this.formValues[name] = value;
    },
    onSubmitRequestEstimate() {
      this.$emit("onSubmitRequestEstimate", this.formValues);
    },
    updateValidate({ valid }) {
      this.valid = valid;
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .title {
    font-size: 12px;
    color: #1b9c4f;
  }
}
</style>
