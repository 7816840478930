<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <SitePortalHeader :flagUserRentalCompany="IS_ROLE_USER_RENTAL_COMPANY"/>
      </template>
      <template #page="{ layoutParams }">
        <TableLayout :layoutParams="layoutParams" :hideFooter="sortParams.pageCount >= sortParams.total_item">
          <template #tableHeader="{ updateHeader }">
            <TableHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :isDelete="false"
              :isAddNew="false"
              :isOpenFormRelate="true"
              :updateHeader="updateHeader"
              @openItemForm="openRelateMachineItemForm"
            >
            <ValidationObserver ref="observer" v-slot="observer">
              <SearchFormWrapper>
                <Label label="搬入予定日"
                  v-if="table_type == STOCK_TABS.TAB2"
                  width="250px" editable
                  :isDatepicker="true"
                  >
                  <InputDatepicker
                    name="delivery_date_from"
                    :editable="true"
                    :values="searchParams"
                    :flagNull="true"
                    validation_rules="compare-to-delivery-end-date:@搬入予定日（終了）"
                    validation_label="搬入予定日（開始）"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <v-icon v-if="table_type == STOCK_TABS.TAB2" class="mt-9">mdi-tilde</v-icon>
                <Label label="搬入予定日"
                  editable
                  v-if="table_type == STOCK_TABS.TAB2" width="250px"
                  :isDatepicker="true"
                  >
                  <InputDatepicker
                    name="delivery_date_to"
                    :editable="true"
                    :values="searchParams"
                    :flagNull="true"
                    validation_rules="compare-to-delivery-start-date:@搬入予定日（開始）"
                    validation_label="搬入予定日（終了）"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <Label label="持込会社" width="250px" :class="roleUser == ROLE_USER[4] ? 'mt-2':''">
                  <Select
                    name="index_delivery_company_id"
                    :items="DELIVERY_COMPANY_ARRAY"
                    :editable="roleUser !== ROLE_USER[4]"
                    item_text="delivery_company_name"
                    :values="searchParams"
                    validation_label="持込会社"
                    validation_rules=""
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <Label label="使用会社"
                  v-if="table_type == STOCK_TABS.TAB3 || table_type == STOCK_TABS.TAB4"
                  width="250px"
                  >
                  <Select
                    name="used_field_tree_id"
                    :items="MACHINE_USE_COMPANIES"
                    :editable="true"
                    item_text="used_company_name"
                    item_value="used_field_tree_id"
                    :values="searchParams"
                    validation_label="使用会社"
                    validation_rules=""
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <Label label="種別" v-if="table_type !== STOCK_TABS.TAB2" width="250px">
                  <span style="cursor: pointer" @click="openFormMachineCompanyType()">
                    <v-text-field
                      v-model="machineType"
                      dense
                      readonly
                      filled
                      color="primary"
                      append-icon="mdi-menu-down"
                    ></v-text-field>
                  </span>
                </Label>
                <Label label="機械名">
                  <InputText
                    name="machine_name"
                    :editable="true"
                    :values="searchParams"
                    validation_label="機械名"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <Label label="管理番号" v-if="table_type !== STOCK_TABS.TAB2">
                  <InputText
                    name="control_number"
                    :editable="true"
                    :values="searchParams"
                    validation_label="管理番号"
                    validation_rules="number-char-symbol"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <TabSelect
                  v-if="table_type === STOCK_TABS.TAB1"
                  class="mt-8"
                  name="used_apply_status"
                  :items="USING_STATUS"
                  :editable="true"
                  :values="searchParams"
                  @onInput="onChangeSearchParams"
                />
                <TabSelect
                  v-if="table_type == STOCK_TABS.TAB3 || table_type == STOCK_TABS.TAB4"
                  class="mt-8"
                  name="used_approval_flag"
                  :items="APPROVE_STATUS"
                  :editable="true"
                  :values="searchParams"
                  @onInput="onChangeSearchParams"
                />
                <TabSelect
                  v-if="table_type == STOCK_TABS.TAB5"
                  class="mt-8"
                  name="used_apply_status"
                  :items="UNUSED_RESERVED_STATUS"
                  :editable="true"
                  :values="searchParams"
                  @onInput="onChangeSearchParams"
                />
                <SwitchInput
                  v-if="table_type == STOCK_TABS.TAB2 || table_type == STOCK_TABS.TAB1"
                  class="mt-4"
                  name="machine_rental_company_flag"
                  :label="`リース品のみ表示`"
                  :values="searchParams"
                  :editable="true"
                  @onInput="onChangeSearchParams"
                />
                <SwitchInput
                  v-if="table_type == STOCK_TABS.TAB5"
                  class="mt-4"
                  name="returned_flag"
                  :label="`返却した機械も表示`"
                  :values="searchParams"
                  :editable="true"
                  @onInput="onChangeSearchParams"
                />
                <v-spacer></v-spacer>
                <v-btn class="mr-6"
                  color="primary"
                  :disabled="!valid"
                  depressed
                  @click="onSearch"
                >
                  検索
                </v-btn>
              </SearchFormWrapper>
              <ValidationCallback :observer="observer" @callback="updateValidate" />
            </ValidationObserver>
            </TableHeader>

            <!-- 
              (共通)
              ソートのレイアウトを調整するラッパーコンポーネントです
            -->
            <TableSortWrapper>
              <!--  
                (共通) ソート 
                ソート項目、ソート順、表示件数の選択、総件数の表示
              -->
              <TableSort
                :values="searchParams"
                :sort_items="sortItems"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="sortParams.total_item"
                :pageSize="sortParams.pageCount"
                :isShowTotalItem="false"
                @onInput="onChangeSortParams"
                :key="flagSort"
              />
            </TableSortWrapper>
            <TableTab
              :items="TAB_SELECT"
              @onChangeTableTab="onChangeTableTab"
            />
          </template>
          <!-- body -->
          <template #tableBody="{ tableHeight }">
            <Table1
              v-if="table_type === STOCK_TABS.TAB1"
              :roleUser="roleUser"
              :tableLabels="TABLE_LABELS_1"
              :tableHeight="tableHeight"
              :items="items"
              :itemsPerPage="sortParams.pageCount"
              :resetSelected="resetSelected"
              @resetDataItems="resetItems()"
            />
            <Table2
              v-else-if="table_type === STOCK_TABS.TAB2"
              :roleUser="roleUser"
              :tableLabels="TABLE_LABELS_2"
              :tableHeight="tableHeight"
              :items="items"
              :itemsPerPage="sortParams.pageCount"
              :resetSelected="resetSelected"
              @resetDataItems="resetItems()"
            />
            <Table3
              v-else-if="table_type === STOCK_TABS.TAB3 && roleUser !== ROLE_USER[4]"
              :roleUser="roleUser"
              :tableLabels="TABLE_LABELS_3"
              :tableHeight="tableHeight"
              :items="items"
              :itemsPerPage="sortParams.pageCount"
              :resetSelected="resetSelected"
              @resetDataItems="resetItems()"
            />
            <Table4
              v-else-if="table_type === STOCK_TABS.TAB4 && roleUser !== ROLE_USER[4]"
              :tableLabels="TABLE_LABELS_4"
              :roleUser="roleUser"
              :tableHeight="tableHeight"
              :items="items"
              :itemsPerPage="sortParams.pageCount"
              :resetSelected="resetSelected"
              @resetDataItems="resetItems()"
            />
            <Table5
              v-else-if="table_type === STOCK_TABS.TAB5 && (roleUser === ROLE_USER[3] || roleUser === ROLE_USER[4])"
              :tableLabels="TABLE_LABELS_5"
              :roleUser="roleUser"
              :tableHeight="tableHeight"
              :items="items"
              :itemsPerPage="sortParams.pageCount"
              :resetSelected="resetSelected"
              @resetDataItems="resetItems()"
            />
          </template>
          <!-- footer -->
          <template #tableFooter>
            <Pagination
              :current="sortParams.currentPage"
              :total="sortParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>
    <Popup :dialog="popups.isShowRelateMachineForm">
      <RelateMachineForm
        @updateItemsAfterRelate="updateItemsAfterRelate"
        @closeForm="popups.isShowRelateMachineForm = false"
      />
    </Popup>
    <Popup :dialog="popups.isShowMachineCompanyType">
      <MachineCompanyTypeForm
        @cancel="closeMachineCompanyForm"
        @changeMachineCompanyType="changeMachineCompanyType"
        :machine_company_field_type_item_tree_id="[]"
        :machine_company_type_tree="MACHINE_COMPANY_FIELD_TYPE_ARRAYS"
      />
    </Popup>
  </div>
</template>

<script>
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "./components/TableDeviceStockSort.vue";
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import Popup from "@/components/common/Popup";
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import { Store } from "@/store/Store.js";
import {
  FORMS,
  STOCK_TABS,
  STOCK_SORT_ITEMS_1,
  STOCK_SORT_ITEMS_2,
  STOCK_SORT_ITEMS_3,
  STOCK_SORT_ITEMS_4,
  STOCK_SORT_ITEMS_5,
  STOCK_TABLE_LABELS_1,
  STOCK_TABLE_LABELS_2,
  STOCK_TABLE_LABELS_3,
  STOCK_TABLE_LABELS_4,
  STOCK_TABLE_LABELS_5,
  USING_STATUS,
  APPROVE_STATUS,
  UNUSED_RESERVED_STATUS,
  ROLE_USER,
  SEARCH_PARAMS_DEFAULT_TAB_1,
  SEARCH_PARAMS_DEFAULT_TAB_2,
  SEARCH_PARAMS_DEFAULT_TAB_3_4,
  SEARCH_PARAMS_DEFAULT_TAB_5
} from "@/constants/DEVICE_STOCK";
import {
  TABLES_PER_PAGE,
  TABLE_SORT_ORDERS,
  ROLE_USER_RENTAL_COMPANY
} from "@/constants/COMMON";
import PreviewDeviceForm from "@/components/forms/device/stock/preview_device/index.vue";
import Table1 from "./components/Table1.vue";
import Table2 from "./components/Table2.vue";
import Table3 from "./components/Table3.vue";
import Table4 from "./components/Table4.vue";
import Table5 from "./components/Table5.vue";
import TableTab from "./components/TableTab";
import { HEADER_MENU_ITEMS_SITE, HEADER_MENU_ITEMS_SITE_FOR_USER_RENTAL_COMPANY } from "@/constants/GLOBALHEADER"; //グローバルヘッダーメニュー
import Select from "@/components/forms/elements/Select";
import TabSelect from "@/components/forms/elements/TabSelect"; //絞り込みフォームで使用
import SwitchInput from "@/components/forms/elements/SwitchInput";
import InputDatepicker from "@/components/forms/elements/InputDatepicker";
import RelateMachineForm from "@/components/forms/device/stock/relate/RelateMachineForm";
import MachineCompanyTypeForm from "@/components/forms/companyMachines/components/MachineCompanyTypeForm.vue";
import _ from "lodash";

//タイトル
const PAGE_TITLE = "在庫";
const TABLE_LABELS_1 = STOCK_TABLE_LABELS_1;
const TABLE_LABELS_2 = STOCK_TABLE_LABELS_2;
const TABLE_LABELS_3 = STOCK_TABLE_LABELS_3;
const TABLE_LABELS_4 = STOCK_TABLE_LABELS_4;
const TABLE_LABELS_5 = STOCK_TABLE_LABELS_5;
//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;
// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;
//ストア
const STORE = "DeviceStocks";
//１ページあたりのテーブル件数
const PAGE_COUNT = 25;

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      PAGE_TITLE,
      TABLE_LABELS_1,
      TABLE_LABELS_2,
      TABLE_LABELS_3,
      TABLE_LABELS_4,
      TABLE_LABELS_5,
      STOCK_SORT_ITEMS_1,
      STOCK_SORT_ITEMS_2,
      STOCK_SORT_ITEMS_3,
      STOCK_SORT_ITEMS_4,
      STOCK_SORT_ITEMS_5,
      SEARCH_PARAMS_DEFAULT_TAB_1,
      SEARCH_PARAMS_DEFAULT_TAB_2,
      SEARCH_PARAMS_DEFAULT_TAB_3_4,
      SEARCH_PARAMS_DEFAULT_TAB_5,
      STOCK_TABS,
      MACHINE_USE_COMPANIES: [],
      DELIVERY_COMPANY_ARRAY: [],
      MACHINE_COMPANY_FIELD_TYPE_ARRAYS : [],
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      FORMS,
      ROLE_USER,
      roleUser: null,
      tab: FORMS.TABLE1.id,
      tableHeight: null,
      sortItems: STOCK_SORT_ITEMS_1,
      field_id: null,
      totalRecordTab1: null,
      totalRecordTab2: null,
      totalRecordTab3: null,
      totalRecordTab4: null,
      totalRecordTab5: null,
      valid: false,
      ROLE_USER_RENTAL_COMPANY,
      flagSort : false,
      /**
       * (共通)
       * 一覧データ
       */
      items: [],
      searchParams : SEARCH_PARAMS_DEFAULT_TAB_1,
      searchInputsTab : {
        searchInputsTab1:SEARCH_PARAMS_DEFAULT_TAB_1,
        searchInputsTab2:SEARCH_PARAMS_DEFAULT_TAB_2,
        searchInputsTab3:SEARCH_PARAMS_DEFAULT_TAB_3_4,
        searchInputsTab4:SEARCH_PARAMS_DEFAULT_TAB_3_4,
        searchInputsTab5:SEARCH_PARAMS_DEFAULT_TAB_5,
      },
      sortParams: {
        pageCount: PAGE_COUNT,
        currentPage: 1,
        total_item: null,
        totalPage: 1,
        sort: STOCK_SORT_ITEMS_1[0].id,
        asc: true
      },

      /**
       * (共通)
       * checkbox選択item
       */
      selectedItems: [],
      table_type : STOCK_TABS.TAB1,

      // 検索パラメータ
      /**
       * (共通)
       * 新規フラグ
       */
      isNewItem: false,
      company_branch_id: null,
      dataRegister: {},
      popups: {
        isShowRelateMachineForm : false,
        isShowMachineCompanyType : false
      },
      machineType : "",
      machineTypeTab : {
        tab1 : '',
        tab3 : '',
        tab4 : '',
        tab5 : ''
      },
      resetSelected : false
    };
  },
  components: {
    MachineCompanyTypeForm,
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    SitePortalHeader,
    Popup,
    Label,
    InputText,
    PreviewDeviceForm,
    Table1,
    Table2,
    Table3,
    Table4,
    Table5,
    Select,
    TabSelect,
    SwitchInput,
    InputDatepicker,
    TableTab,
    RelateMachineForm,
    ValidationObserver,
    ValidationCallback
  },
  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    await Store.dispatch("GlobalHeader/setSiteMenu", {
      menuId: this.IS_ROLE_USER_RENTAL_COMPANY ? HEADER_MENU_ITEMS_SITE_FOR_USER_RENTAL_COMPANY.DEVICE_MANAGEMENT.id : HEADER_MENU_ITEMS_SITE.DEVICE_MANAGEMENT.id,
    });
    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      (data, oldData) => {
        if (data && JSON.stringify(data) !== JSON.stringify(oldData)) {
          this.field_id = data.field_id;
          this.initialData();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters[`CompanyMachines/getMachineCompanyType`],
      (data) => {
        if (data) {
          this.MACHINE_COMPANY_FIELD_TYPE_ARRAYS = [...data];
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => Store.getters["DeviceStocks/getListMachineOwnerCompanies"],
      (data) => {
        this.DELIVERY_COMPANY_ARRAY = this.getDataListMachineOwnerCompanies(data);
        if (this.roleUser === ROLE_USER[4]) {
          let temp = this.DELIVERY_COMPANY_ARRAY.find(e => e.delivery_company_id == this.COMPANY_USER.company_id && e.delivery_company_branch_id == this.COMPANY_USER.company_branch_id);
          this.searchInputsTab[`searchInputsTab1`] = this.getDefaultRentalCompany(this.searchInputsTab[`searchInputsTab1`],temp);
          this.searchInputsTab[`searchInputsTab2`] = this.getDefaultRentalCompany(this.searchInputsTab[`searchInputsTab2`],temp);
          this.searchInputsTab[`searchInputsTab5`] = this.getDefaultRentalCompany(this.searchInputsTab[`searchInputsTab5`],temp);
          this.searchParams = this.searchInputsTab[`searchInputsTab1`];
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["DeviceStocks/getListMachineUseCompanies"],
      (data) => {
        this.MACHINE_USE_COMPANIES = [{ used_field_tree_id: null, used_company_name: ""}, 
          ...data];
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Sites/getRole"],
      (data) => {
        this.roleUser = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    
    this.$watch(
      () => Store.getters["DeviceStocks/getNumberRecordTab1"],
      (data, oldData) => {
        if (data !== oldData) {
          this.totalRecordTab1 = data;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["DeviceStocks/getNumberRecordTab2"],
      (data, oldData) => {
        if (data !== oldData) {
          this.totalRecordTab2 = data;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["DeviceStocks/getNumberRecordTab3"],
      (data, oldData) => {
        if (data !== oldData) {
          this.totalRecordTab3 = data;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["DeviceStocks/getNumberRecordTab4"],
      (data, oldData) => {
        if ( data !== oldData) {
          this.totalRecordTab4 = data;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["DeviceStocks/getNumberRecordTab5"],
      (data, oldData) => {
        if (data !== oldData) {
          this.totalRecordTab5 = data;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        let _items = data[0].find(e => e.tab == this.table_type)?.data;
        this.items = _items ? _items : [];
        let pagination = data[1].find(e => e.tab == this.table_type)?.pagination;
        if (pagination) {
          let sortParams = { ...this.sortParams };
          sortParams.currentPage = parseInt(pagination.current || 1);
          sortParams.totalPage = pagination.total;
          sortParams.total_item = pagination.total_item;
          this.sortParams = sortParams;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

  },
  computed: {
    IS_ROLE_USER_RENTAL_COMPANY(){
      return Store.getters["Sites/getUserRole"] == this.ROLE_USER_RENTAL_COMPANY;
    },
    TAB_SELECT() {
      if(this.roleUser != null) {
        const form = this.addNumberRecordForTabs();
        if (this.roleUser === this.ROLE_USER[4]) {
          delete form.TABLE3;
          delete form.TABLE4;
        } else if (this.roleUser === this.ROLE_USER[1] || this.roleUser === this.ROLE_USER[2]) {
          delete form.TABLE5;
        }
        return Object.keys(form).map((key) => {
          return FORMS[key];
        });
      }
    },
    USING_STATUS() {
      return Object.keys(USING_STATUS).map((key) => {
        return USING_STATUS[key];
      });
    },
    APPROVE_STATUS() {
      return Object.keys(APPROVE_STATUS).map((key) => {
        return APPROVE_STATUS[key];
      });
    },
    UNUSED_RESERVED_STATUS() {
      return Object.keys(UNUSED_RESERVED_STATUS).map((key) => {
        return UNUSED_RESERVED_STATUS[key];
      });
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    COMPANY_USER() {
      return JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user;
    },

    /**
     *
     * API Param
     */
    apiParams() {
      let rs = null;
      switch (this.table_type) {
        case STOCK_TABS.TAB1 :
          rs = {
            delivery_company_id: this.searchInputsTab.searchInputsTab1.delivery_company_id,
            delivery_company_branch_id : this.searchInputsTab.searchInputsTab1.delivery_company_branch_id,
            machine_company_type_item_tree_id: this.searchInputsTab.searchInputsTab1.machine_company_type_item_tree_id,
            machine_name: this.searchInputsTab.searchInputsTab1.machine_name,
            control_number: this.searchInputsTab.searchInputsTab1.control_number,
            used_apply_status: this.searchInputsTab.searchInputsTab1.used_apply_status,
            machine_rental_company_flag: this.searchInputsTab.searchInputsTab1.machine_rental_company_flag
          };
          break;
        case STOCK_TABS.TAB2 :
          rs = {
            delivery_date_from: this.searchInputsTab.searchInputsTab2.delivery_date_from,
            delivery_date_to: this.searchInputsTab.searchInputsTab2.delivery_date_to,
            delivery_company_id: this.searchInputsTab.searchInputsTab2.delivery_company_id,
            delivery_company_branch_id : this.searchInputsTab.searchInputsTab2.delivery_company_branch_id,
            machine_name: this.searchInputsTab.searchInputsTab2.machine_name,
            machine_rental_company_flag: this.searchInputsTab.searchInputsTab2.machine_rental_company_flag
          };
          break;
        case STOCK_TABS.TAB3:
        case STOCK_TABS.TAB4:
          rs = {
            delivery_company_id: this.searchInputsTab[`searchInputsTab${this.table_type}`].delivery_company_id,
            delivery_company_branch_id : this.searchInputsTab[`searchInputsTab${this.table_type}`].delivery_company_branch_id,
            used_field_tree_id : this.searchInputsTab[`searchInputsTab${this.table_type}`].used_field_tree_id,
            machine_company_type_item_tree_id: this.searchInputsTab[`searchInputsTab${this.table_type}`].machine_company_type_item_tree_id,
            machine_name: this.searchInputsTab[`searchInputsTab${this.table_type}`].machine_name,
            control_number: this.searchInputsTab[`searchInputsTab${this.table_type}`].control_number,
            used_approval_flag: this.searchInputsTab[`searchInputsTab${this.table_type}`].used_approval_flag
          };
          break;
        case STOCK_TABS.TAB5:
          rs = {
            delivery_company_id: this.searchInputsTab.searchInputsTab5.delivery_company_id,
            delivery_company_branch_id : this.searchInputsTab.searchInputsTab5.delivery_company_branch_id,
            machine_company_type_item_tree_id: this.searchInputsTab.searchInputsTab5.machine_company_type_item_tree_id,
            machine_name: this.searchInputsTab.searchInputsTab5.machine_name,
            control_number: this.searchInputsTab.searchInputsTab5.control_number,
            used_apply_status: this.searchInputsTab.searchInputsTab5.used_apply_status,
            returned_flag: this.searchInputsTab.searchInputsTab5.returned_flag
          };
      }
      let temp = {
        field_id: this.CURRENT_SITE.field_id,
        tab: this.table_type,
        sort_value: this.sortParams.sort,
        sort_by: this.sortParams.asc ? 1 : 0,
        page_number: this.sortParams.currentPage,
        page_size: this.sortParams.pageCount
      };
      let apiParams = { ...rs, ...temp };
      return apiParams;
    },
  },
  methods: {
    async initialData(){
      await this.getRoleInField();
      await this.getListMachineOwnerCompanies();
      if (this.roleUser != this.ROLE_USER[4]) {
        this.getListMachineUseCompanies();
      }
      this.getItemsTabByRole();
    },
    getDefaultRentalCompany(searchParams, temp) {
      let _searchParams = _.cloneDeep(searchParams);
      _searchParams.index_delivery_company_id = temp.id;
      _searchParams.delivery_company_id = temp.delivery_company_id;
      _searchParams.delivery_company_branch_id = temp.delivery_company_branch_id;
      return _searchParams;
    },
    getDataListMachineOwnerCompanies(arr) {
      let _arr = [{ id: null, delivery_company_id: null, delivery_company_branch_id: null, delivery_company_name: "" }];
      arr.forEach((item, index) => {
        _arr.push({
          id: index,
          delivery_company_id: item.delivery_company_id,
          delivery_company_branch_id: item.delivery_company_branch_id,
          delivery_company_name: item.delivery_company_name
        });
      });
      return _arr;
    },
    /**
     * initial get data all tab to get number
     */
    async getItemsTabByRole() {
      Store.dispatch("GlobalHeader/setSiteMenu", {
        menuId: this.IS_ROLE_USER_RENTAL_COMPANY ? HEADER_MENU_ITEMS_SITE_FOR_USER_RENTAL_COMPANY.DEVICE_MANAGEMENT.id : HEADER_MENU_ITEMS_SITE.DEVICE_MANAGEMENT.id,
      });
      this.getItemsTab(STOCK_TABS.TAB1);
      this.getItemsTab(STOCK_TABS.TAB2);
      if (this.roleUser && this.roleUser !== this.ROLE_USER[4]) {
        this.getItemsTab(STOCK_TABS.TAB3);
        this.getItemsTab(STOCK_TABS.TAB4);
      }
      if (this.roleUser && this.roleUser !== this.ROLE_USER[1] && this.roleUser !== this.ROLE_USER[2]) {
        this.getItemsTab(STOCK_TABS.TAB5);
      }
    },
    addNumberRecordForTabs() {
      let form = { ...FORMS };
      form.TABLE1.name = `全体（${this.totalRecordTab1 || 0}）`;
      form.TABLE2.name = `搬入予定（${this.totalRecordTab2 || 0}）`;
      form.TABLE3.name = `使用（${this.totalRecordTab3 || 0}）`;
      form.TABLE4.name = `返却（${this.totalRecordTab4 || 0}）`;
      form.TABLE5.name = `リース返却（${this.totalRecordTab5 || 0}）`;
      return form;
    },
    async getListMachineOwnerCompanies() {
      if(this.CURRENT_SITE.field_id) {
        const field_id = this.CURRENT_SITE.field_id;
        await Store.dispatch(`${STORE}/getListMachineOwnerCompanies`, {field_id});
      }
    },
    async getListMachineUseCompanies() {
      if(this.CURRENT_SITE.field_id) {
        const field_id = this.CURRENT_SITE.field_id;
        await Store.dispatch(`${STORE}/getListMachineUseCompanies`, {field_id});
      }
    },
    async getRoleInField() {
      if(this.CURRENT_SITE.field_id) {
        const field_id = this.CURRENT_SITE.field_id;
        const params = {
          field_id,
          target_role: []
        };
        await Store.dispatch("Sites/getRole", params);
      }
    },
    onChangeTableTab(item) {
      this.items = [];
      //change table type
      this.table_type = item.id;
      // change sort items
      this.changeSortItems(item.id);
      this.searchParams = this.searchInputsTab[`searchInputsTab${item.id}`];
      this.machineType = this.machineTypeTab[`tab${item.id}`];
      const sortParams = { ...this.sortParams };
      // change current page to 1 , sort by control_number , sort by asc
      sortParams.currentPage = 1;
      sortParams.sort = this.sortItems[0].id;
      sortParams.asc = true ;
      // update this.sort param
      this.sortParams = sortParams;
      this.flagSort = ! this.flagSort;
      this.getItems();
    },
    changeSortItems(id) {
      if (id === FORMS.TABLE1.id) {
        this.sortItems = this.STOCK_SORT_ITEMS_1;
      } else if (id === FORMS.TABLE2.id) {
        this.sortItems = this.STOCK_SORT_ITEMS_2;
      } else if (id === FORMS.TABLE3.id) {
        this.sortItems = this.STOCK_SORT_ITEMS_3;
      } else if (id === FORMS.TABLE4.id) {
        this.sortItems = this.STOCK_SORT_ITEMS_4;
      } else if (id === FORMS.TABLE5.id) {
        this.sortItems = this.STOCK_SORT_ITEMS_5;
      }
    },
    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid }) {
      this.valid = valid;
    },
    /**
     * (共通)
     * 検索
     */
    onSearch() {
      this.resetSelected = ! this.resetSelected;
      let _searchParams = { ...this.searchParams };
      this.searchInputsTab[`searchInputsTab${this.table_type}`] = { ..._searchParams };
      this.machineTypeTab[`tab${this.table_type}`] = this.machineType;
      this.sortParams["currentPage"] = 1;
      this.getItems();
    },
    /**
     * (共通)
     * 並び替えパラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let sortParams = { ...this.sortParams };
      sortParams[name] = value;
      // check if sort by page count is render to page 1
      name == "pageCount" ? (sortParams["currentPage"] = 1) : "";
      this.sortParams = sortParams;
      this.getItems();
    },
    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      if (name == "index_delivery_company_id") {
        let temp = this.DELIVERY_COMPANY_ARRAY.find(e => e.id == value);
        searchParams.delivery_company_id = temp.delivery_company_id;
        searchParams.delivery_company_branch_id = temp.delivery_company_branch_id;
      }
      this.searchParams = searchParams;
    },
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let sortParams = { ...this.sortParams };
      sortParams.currentPage = n;
      this.sortParams = sortParams;
      this.getItems();
    },
    resetItems() {
      Store.dispatch("Notification/getNewNoticeStatus"); 
      this.getItemsTabByRole();
    },
    /**
     * open relate machine form
     */
    openRelateMachineItemForm() {
      this.popups.isShowRelateMachineForm = true;
    },
    getApiParramsByTab(tableType) {
      let apiParams = {...this.apiParams};
      apiParams.tab = tableType;
      return apiParams;
    },
    /**
     * データ取得
     */
    async getItems() {
      let apiParams = this.getApiParramsByTab(this.table_type);
      if(this.CURRENT_SITE.field_id) {
        await  Store.dispatch(`${STORE}/get`, { params: apiParams });
      }
    },
    async openFormMachineCompanyType() {
      await Store.dispatch(`CompanyMachines/getMachineCompanyType`, {
        company_id: this.CURRENT_SITE.company_id,
        company_branch_id: this.CURRENT_SITE.company_branch_id ? this.CURRENT_SITE.company_branch_id : ''
      });
      this.popups.isShowMachineCompanyType = true;
    },
    /**
     * initial or resetItems in all Tab
     * @param tab : tab to call api
     */
    async getItemsTab(tab) {
      // get search input by tab
      let apiParams = { ...this.searchInputsTab[`searchInputsTab${tab}`] };
      delete apiParams.index_delivery_company_id;
      // add param to api to get
      apiParams.field_id = this.CURRENT_SITE.field_id;
      apiParams.tab = tab;
      // if update in tab , save sort value and sort by
      if (tab == this.table_type) {
        apiParams.page_number = this.sortParams.currentPage;
        apiParams.sort_value = this.sortParams.sort;
        apiParams.sort_by = this.sortParams.asc ? 1 : 0;
      } else {
        // sort value and sort_by is default
        apiParams.page_number = 1;
        apiParams.sort_value = "control_number";
        apiParams.sort_by = 1;
      }
      apiParams.page_size = this.sortParams.pageCount;
      if (this.CURRENT_SITE.field_id) {
        await Store.dispatch(`${STORE}/get`, { params: apiParams });
      }
      if (tab == this.table_type && this.items.length == 0 && this.sortParams.currentPage > 1) {
        let temp = this.sortParams.currentPage - 1;
        this.sortParams.currentPage = temp;
        apiParams.page_number = temp;
        await Store.dispatch(`${STORE}/get`, { params: apiParams });
      }
    },
    closeMachineCompanyForm(){
      this.popups.isShowMachineCompanyType = false;
    },
    changeMachineCompanyType(value) {
      this.popups.isShowMachineCompanyType = false;
      this.machineType = this.getMachineCompanyFieldType(value);
      const _searchParams = { ...this.searchParams };
      _searchParams.machine_company_type_item_tree_id = value.length > 0 ? value[value.length - 1] : null;
      this.searchParams = { ..._searchParams };
    },
    getMachineCompanyFieldType(ids) {
      let arr = _.cloneDeep(ids);
      let machine_type_array = _.cloneDeep(this.MACHINE_COMPANY_FIELD_TYPE_ARRAYS);
      let rs = "";
      if (arr.length > 0 && machine_type_array.length > 0) {
        arr.forEach((e, index) => {
          let a1 = machine_type_array.find(e1 => e1.id == e);
          rs = index == 0 ? rs + a1.type : rs + "/" + a1.type;
          machine_type_array = a1.childrens;
        });
      } else {
        rs = "";
      }
      return rs;
    },
    /**
     * update item after relate success
     */
    updateItemsAfterRelate(){
      this.getListMachineOwnerCompanies();
      this.getListMachineUseCompanies();
      this.popups.isShowRelateMachineForm = false;
      this.resetItems();
    },
  },
};
</script>
