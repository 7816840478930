<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="種別" width="100%" :editable="editable">
                        <span @click="editable ? openFormMachineCompanyType() : null" class="select">
                          <span class="select_wrap">
                            <Select
                              name="machineType"
                              :values="dataMachineType"
                              :items="TYPE_DATA"
                              :editable="editable"
                              validation_label="種別"
                              validation_rules=""
                            />
                          </span>
                        </span>
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="機材名" :editable="editable">
                        <Select
                          :name="`${TAB}_machine_company_id`"
                          :values="formValues"
                          :editable="editable"
                          :items="MACHINE_COMPANY_ARRAY"
                          validation_label="機材名"
                          validation_rules=""
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item :class="!editable ? 'mt-2':''">
                        <v-list-item-content>
                          <Label :label="flagEstimate?'見積量':'納品数'" :editable="editable">
                            <InputNumber
                              :name="flagEstimate ? 'estimate_amount' : 'order_delivery_amount'"
                              :values="formValues"
                              :editable="editable"
                              validation_label="見積量"
                              validation_rules="min-quantity-value|digit|estimate-order-amount"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="搬入予定日" :editable="editable">
                            <InputDatepicker
                              :name="`${TAB}_scheduled_delivery_date`"
                              :editable="editable"
                              :values="formValues"
                              :flagNull="true"
                              validation_label="搬入予定日"
                              validation_rules=""
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="備考" :editable="editable">
                        <InputTextArea
                          :name="`${TAB}_remarks`"
                          :editable="editable"
                          :values="formValues"
                          validation_label="備考"
                          validation_rules="max:512"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
      <Popup :dialog="isShowMachineCompanyType">
        <MachineCompanyTypeForm
          @cancel="closeMachineCompanyForm"
          @changeMachineCompanyType="changeMachineCompanyType"
          :machine_company_field_type_item_tree_id="[]"
          :machine_company_type_tree="MACHINE_COMPANY_FIELD_TYPE_ARRAYS"
        />
      </Popup>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store";
import Label from "@/components/forms/elements/Label.vue";
import InputText from "@/components/forms/elements/InputText.vue";
import Select from "@/components/forms/elements/Select.vue";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import InputTextArea from "@/components/forms/elements/InputTextArea";
import InputNumber from "@/components/forms/elements/InputNumber";
import MachineCompanyTypeForm from "@/components/forms/companyMachines/components/MachineCompanyTypeForm.vue";
import Popup from "@/components/common/Popup.vue";
import { ROLE_USER } from "@/constants/DEVICE_COORDINATOR";
import _ from  "lodash";

export default {
  data: () => {
    return {
      TYPE_DATA: [{ id: null, name: "" }],
      dataMachineType: { machineType: null },
      formValues: {},
      isShowMachineCompanyType: false,
      ROLE_USER,
      MACHINE_COMPANY_ARRAY : [{ id: null, name: "" }],
      MACHINE_COMPANY_FIELD_TYPE_ARRAYS : []
    };
  },
  components: {
    InputTextArea,
    Label,
    InputText,
    Select,
    InputDatepicker,
    MachineCompanyTypeForm,
    Popup,
    InputNumber
  },
  props: {
    item: { type: Object },
    editable: {
      type: Boolean,
      default: false
    },
    mainHeight: {
      type: Number,
      default: 0
    },
    roleUser: Number,
    flagEstimate : Boolean
  },
  async mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
      },
      {
        deep: true
      }
    );

    this.$watch(
      () => Store.getters[`CompanyMachines/getMachineCompanyByType`],
      (data) => {
        if (Array.isArray(data)) {
          this.MACHINE_COMPANY_ARRAY = [{ id: null, name: "" }, ...data];
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => Store.getters[`CompanyMachines/getMachineCompanyType`],
      (data) => {
        if (data && this.item.id) {
          let ids = this.item[`${this.TAB}_machine_company_type_item_tree_ids`];
          this.MACHINE_COMPANY_FIELD_TYPE_ARRAYS = [...data];
          this.getMachineCompanyFieldType(ids);
          if (ids.length > 0) {
            this.getListMachineCompanyByType(ids.at(-1));
          }
        }
      },
      {
        immediate : true,
        deep: true
      }
    );

  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    TAB(){
      return this.flagEstimate ? 'estimate' : 'order';
    }
  },
  methods: {
    // Call API set data store for [機材名]
    async getListMachineCompanyByType(machine_company_type_item_tree_id) {
      let params = {
        machine_company_type_item_tree_id : machine_company_type_item_tree_id
      }
      await Store.dispatch(`CompanyMachines/getMachineCompanyByType`, params);
    },

    onInput({ name, value }) {
      const _formValues = { ...this.formValues };
      _formValues[name] = value;
      this.formValues = _formValues;
      this.$emit("formUpdate", _formValues);
    },
    async openFormMachineCompanyType() {
      this.isShowMachineCompanyType = true;
    },
    closeMachineCompanyForm() {
      this.isShowMachineCompanyType = false;
    },
    changeMachineCompanyType(value) {
      this.isShowMachineCompanyType = false;
      const _formValues = { ...this.formValues };
      _formValues[`${this.TAB}_machine_company_type_item_tree_ids`] = value;
      if (value?.length > 0) {
        const machine_company_type_item_tree_id = value.at(-1);
        _formValues[`${this.TAB}_machine_company_id`] = null;
        this.getListMachineCompanyByType(machine_company_type_item_tree_id);
      } else {
        _formValues[`${this.TAB}_machine_company_id`] = null;
        this.MACHINE_COMPANY_ARRAY = [{ id: null, name: "" }];
      }
      this.getMachineCompanyFieldType(value);
      this.formValues = {..._formValues};
      this.$emit("formUpdate", _formValues);
    },
    getMachineCompanyFieldType(ids) {
      let arr = _.cloneDeep(ids);
      let machine_type_array = _.cloneDeep(this.MACHINE_COMPANY_FIELD_TYPE_ARRAYS);
      let rs = "";
      if (arr.length > 0 && machine_type_array.length > 0) {
        arr.forEach((e, index) => {
          let a1 = machine_type_array.find(e1 => e1.id == e);
          rs = index == 0 ? rs + a1.type : rs + "/" + a1.type;
          machine_type_array = a1.childrens;
        });
        this.dataMachineType.machineType = 1;
        this.TYPE_DATA = [{ id: 1, name: rs }];
      } else {
        rs = "";
        this.dataMachineType.machineType = null;
        this.TYPE_DATA = [{ id: null, name: rs }];
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
.text-btn {
  text-transform: none !important;
}
.machine-type {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 35vw;
  white-space: nowrap;
  text-align: left
}
.select{
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.select_wrap{
  width: 100%;
  pointer-events: none;
}
</style>
