<template>
  <div class="mt-5 mb-cst">
    <!-- List button -->
    <template>
      <v-btn
        v-if='roleUser !== ROLE_USER[4]'
        depressed class="mx-3"
        :disabled="isDisabledBtn"
        @click="openConfirmDeliveryDialog()"
        color="primary"> 搬入確定 </v-btn>
      <v-btn
        class="mx-3"
        small icon
        @click='openRemoveDialog()'
        :disabled="isDisabledBtn">
        <v-icon> mdi-delete </v-icon>
      </v-btn>
    </template>
    <v-data-table
      hide-default-header
      :headers="tableLabels"
      v-model="selectedItems"
      :items="items"
      :items-per-page="itemsPerPage"
      :height="tableHeight-40"
      fixed-header
      hide-default-footer
      disable-sort
      :show-select="true"
      class="elevation-0 v-data-table__wrapper"
      sort-by="updatedAt"
      item-key="machine_company_field_detail_id"
      :noDataText="NO_DATA_MESSAGE"
      @click:row="openItemForm"
    >
      <template slot="header" :headers="tableLabels">
        <thead>
          <tr>
            <th style="width: 50px">
              <v-simple-checkbox
                :value="selectedItems.length === items.length && items.length > 0"
                @input="clickCheckAll($event)"
              ></v-simple-checkbox>
            </th>
            <th v-for="(h, index) in tableLabels" :key="h.value" :style="getWidthStyle(index)">
              <div v-for="text in convertTextHeaderArr(h.text)" :key="text">
                <div>{{text}}</div>
              </div>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:[`item.used_apply_status`]="{ item }" label small>
        <v-chip
          small
          :dark="item.used_apply_status === 0"
          :color="setColor(item.used_apply_status)"
          >{{ getStatusValue(item.used_apply_status) }}</v-chip
        >
      </template>
      <template v-slot:[`item.machine_type_extral_machine_name`]="{ item }" label small>
        <div class="cst-overflow-td">{{ item.machine_company_type_item_tree_name }}</div>
        <div class="cst-overflow-td">{{ item.machine_name }}</div>
      </template>
      <template v-slot:[`item.delivery_company_name_extral_machine_number`]="{ item }" label small>
        <div class="cst-overflow-td">{{ item.delivery_company_name }}</div>
        <div class="cst-overflow-td">{{ item.machine_number }}</div>
      </template>
    </v-data-table>
    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        :flagError="flagError"
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :selectedItems="selectedItems"
        warning
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowConfirmDialog">
      <ConfirmDeliveryDialog
        :flagError="flagError"
        @close="closeConfirmDialog()"
        @yes="confirmDeliveries()"
        title="荷受確定"
        text="搬入された機械を在庫登録します。"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowPreviewDialog">
      <ConfirmDialog
        @OK="closePreviewDialog()"
        title="選択項目の削除"
        message="自分が所有していない機械は削除できません。"
        warning
      />
    </Popup>
    <Popup :dialog="popups.isShowItemForm">
      <MachineFieldForm
        @updateListItems="resetDataItems"
        @cancel="closeMachineFieldForm"
      />
    </Popup>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import { ROLE_USER, STATUS_COLORS ,USED_APPLY_STATUS} from "@/constants/DEVICE_STOCK";
import Popup from "@/components/common/Popup";
import ConfirmRemoveDialog from "./ConfirmRemoveDialog.vue";
import ConfirmDeliveryDialog from "./ConfirmDeliveryDialog.vue";
import ConfirmDialog from "./ConfirmDialog.vue";
import MachineFieldForm from "@/components/forms/device/stock/detail_update/MachineFieldForm.vue";
const STORE = 'DeviceStocks';

export default {
  data() {
    return {
      USED_APPLY_STATUS,
      NO_DATA_MESSAGE,
      selectedItems: [],
      STATUS_COLORS,
      ROLE_USER,
      STORE,
      flagError: false,
      popups: {
        isShowRemoveDialog: false,
        isShowConfirmDialog: false,
        isShowItemForm: false,
        isShowPreviewDialog : false
      },
    };
  },
  components: {
    Popup,
    ConfirmRemoveDialog,
    ConfirmDeliveryDialog,
    MachineFieldForm,
    ConfirmDialog
  },
  props: {
    tableLabels: {
      type: Array,
    },
    roleUser: Number,
    items: Array,
    itemsPerPage: Number,
    tableHeight: Number,
    resetSelected : Boolean
  },
  mounted() {
    this.$watch(
      () => this.resetSelected,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          this.selectedItems = [];
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  computed: {
    isDisabledBtn() {
      return this.selectedItems.length === 0;
    },
    getFieldId() {
      return Store.getters["GlobalHeader/getCurrentSite"]?.field_id;
    },
  },
  methods: {
    /**
     * set width for column
     */
    getWidthStyle(column) {
      let style = "";
      if (column == 0) {
        style = 'width: 100px';
      } else if (column == 2) {
        style = 'width: 35vw';
      } else {
        style = 'width: 10vw';
      }
      return style;
    },
    /**
     * open detail machine iventory
     * @param item : item click
     * @returns {Promise<void>} : item detail
     */
    async openItemForm(item) {
      const rs = await Store.dispatch(`${STORE}/getMachineFieldDetail`, item.machine_company_field_detail_id);
      // Show Detail Form
      if (!rs.hasError) {
        this.popups.isShowItemForm = true;
      }
    },
    /**
     * close form edit machine inventory
     */
    closeMachineFieldForm(){
      this.popups.isShowItemForm = false;
    },
    /**
     * reset data items when update success
     */
    resetDataItems(){
      this.$emit('resetDataItems');
    },
    clickCheckAll(event) {
      if (event) {
        this.selectedItems = this.items;
      } else {
        this.selectedItems = [];
      }
    },
    convertTextHeaderArr(textHeader) {
      let textHeaderArr = [];
      if (textHeader.includes(" / ")) {
        textHeaderArr = textHeader.split(" / ");
        textHeaderArr[1] = "/ ".concat(textHeaderArr[1]);
      } else {
        textHeaderArr.push(textHeader);
      }
      return textHeaderArr;
    },
    setColor(applyStatus) {
      return this.STATUS_COLORS[applyStatus]?.color;
    },
    getStatusValue(applyStatus) {
      return this.STATUS_COLORS[applyStatus]?.value;
    },
    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.selectedItems = [];
      this.popups.isShowRemoveDialog = false;
    },
    async removeItems() {
      const machine_company_field_detail_ids = this.selectedItems.map((items) => items.machine_company_field_detail_id);
      const result = await Store.dispatch(`${STORE}/delete`, { machine_company_field_detail_ids });

      if (result.hasError) {
        this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.response.data.message,
        });
        return;
      } else {
        this.closeRemoveDialog();
        let ids = [...result.data.contents.entries.ids];
        if (ids.length > 0) {
          this.selectedItems = [];
          Store.dispatch("Toast/show", {
            status: 200,
            message: "削除しました"
          });
          this.$emit("resetDataItems");
        } else {
          this.popups.isShowPreviewDialog = true;
        }
      }
    },
    openConfirmDeliveryDialog() {
      this.popups.isShowConfirmDialog = true;
    },
    closeConfirmDialog() {
      this.selectedItems = [];
      this.popups.isShowConfirmDialog = false;
    },
    async confirmDeliveries() {
      const machine_company_field_detail_ids = this.selectedItems.map((items) => items.machine_company_field_detail_id);
      const params = {
        field_id: this.getFieldId,
        machine_company_field_detail_ids
      }
      const result = await Store.dispatch(`${STORE}/confirmDelivery`, params);
      if (result.hasError) {
        this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.response.data.message,
        });
        return;
      } else {
        this.closeConfirmDialog();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "確定しました",
        });
        this.$emit('resetDataItems');
      }
    },
    closePreviewDialog() {
      this.selectedItems = [];
      this.popups.isShowPreviewDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.cst-overflow-td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mb-cst {
  margin-bottom: 2vh !important;
}
::v-deep .v-data-table .v-data-table__wrapper table {
  table-layout: fixed;
}
::v-deep .v-data-table .v-data-table__wrapper table tbody tr td {
  word-break: break-all;
}
</style>
