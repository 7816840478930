<template>
  <FormDialog>
    <template #header>
      <!--
        (共通)
        ヘッダーは全て共通
       -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          {{ companyName }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!editable && !checkFieldTreeDate && !isEndWorked"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn
          v-if="editable"
          class="mr-5"
          depressed
          small
          outlined
          color="primary"
          @click="onReadonly"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          small
          :disabled="!valid || isSubmitted"
          color="primary"
          @click="onSubmit"
        >
          保存
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!--
        (共通)
        全てのフォームのバリデーションの監視を行う
       -->
      <ValidationObserver ref="observer" v-slot="observer">
        <!-- フォームtab -->
        <v-tabs-items v-model="tab">
          <v-tab-item v-if="isShow.FormInfo">
            <!-- 建設業の許可 -->
            <FormInfo
              :item="formValues"
              :editable="editable"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <!-- TODO -->
          <!-- 工事請負契約情報 -->
          <v-tab-item v-if="isShow.FormConstructionContractInfo">
            <FormConstructionContractInfo
              :item="formValues.construction_contract_info"
              :editable="editable"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
              :errors="errors"
              :level="level"
            />
          </v-tab-item>
          <v-tab-item isShow.FormWorkers>
            <!-- 作業員(READONLY) -->
            <FormWorkers
              v-if="!editable"
              :editable="false"
              :mainHeight="params.mainHeight"
              :fieldContructionId="fieldContructionId"
              :item="formValues.field_worker"
              :checkFieldTreeDate="checkFieldTreeDate"
              :fieldTreeDate="fieldTreeDate"
              :state_handling_flag="state_handling_flag"
              :chartId="chartId"
              @resetForm="resetForm()"
            />
            <!-- 作業員:編集-->
            <FormWorkersEdit
              v-if="editable"
              :editable="true"
              :mainHeight="params.mainHeight"
              :checkFieldTreeDate="checkFieldTreeDate"
              :state_handling_flag="state_handling_flag"
              @formUpdate="formUpdate"
              :item="formValues.field_worker"
            />
          </v-tab-item>
        </v-tabs-items>
        <!--
          (共通)
          ValidationObserverのスロットプロパティを受け取って
          updateValidateメソッドを実行して、
          バリデーション結果をスクリプト側に渡してます
        -->
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="isShowConfirmDialog = false"
          @yes="closeForm"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import FormInfo from "./FormInfo.vue";
import FormWorkers from "./FormWorkers.vue";
import FormWorkersEdit from "./FormWorkersEdit.vue";
import FormConstructionContractInfo from "./FormConstructionContractInfo.vue";
import {
  RELATED_FORMS,
  STATE_HANDLING_FLAG,
} from "@/constants/PORTAL_CHART.js";
import { Store } from "@/store/Store.js";
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON";
import _ from "lodash";

export default {
  name: "RelatedCompanyForm",
  data: () => {
    return {
      FORMS: RELATED_FORMS,
      valid: false, //バリデーション結果
      errors: {},
      // tab初期化
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      isClickCloseBtn: false,
      companyName: null,
      formValues: {},
      isSubmitted: false,
      timeout: null,
      RELATED_FORMS,
      isShow: {
        FormConstructionContractInfo: true,
        FormInfo: true,
        FormWorkers: true,
      },
      isEndWorked: false,
      level: 0
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    FormInfo,
    FormWorkers,
    FormWorkersEdit,
    FormConstructionContractInfo,
  },
  props: {
    isNewItem: Boolean,
    companyId: Number,
    chartId: Number,
    formTab: Object,
    checkFieldTreeDate: Boolean,
    state_handling_flag: Number,
    dataDetail: Object,
    fieldContructionId: Number,
    fieldTreeDate: String,
    isObayashi: Boolean,
  },
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    /**
     * (共通)
     * 新規作成を監視
     */
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        //新規の場合は最初から編集モード
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.checkFieldTreeDate,
      (flag) => {
        if (flag) {
          this.isShowFormInfo = false;
          this.isShowFormConstructionContractInfo = false;
          let form = {...RELATED_FORMS};
          delete form['FormConstructionContractInfo'];
          delete form['FormInfo'];
          this.isShow = {
            FormConstructionContractInfo: false,
            FormInfo: false,
            FormWorkers: true
          }
          this.FORMS = form;
          if(this.dataDetail) {
            let formValues = {...this.formValues};
            let field_worker = {};
            field_worker['field_tree_users'] = this.dataDetail['field_tree_users'];
            formValues['field_worker'] = field_worker;
            this.formValues = formValues;
            this.companyName = Store.getters["PortalChart/getSelectedRelatedCompany"].companyName;
            this.level = Store.getters["PortalChart/getSelectedRelatedCompany"].level;
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.state_handling_flag,
      (flg) => {
        if (flg == STATE_HANDLING_FLAG.FINISHED) {
          this.isShowFormInfo = false;
          this.isShowFormConstructionContractInfo = false;
          this.isEndWorked = true;
          let form = {...RELATED_FORMS};
          delete form['FormConstructionContractInfo'];
          delete form['FormInfo'];
          this.isShow = {
            FormConstructionContractInfo: false,
            FormInfo: false,
            FormWorkers: true
          }
          this.FORMS = form;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * 現場監督一覧を開く
     */
    this.$watch(
      () => this.formTab,
      (newVal) => {
        if (
          JSON.stringify(newVal) === JSON.stringify(RELATED_FORMS.FormWorkers)
        ) {
          const index = Object.keys(RELATED_FORMS)
            .map((key) => RELATED_FORMS[key])
            .findIndex((item) => item === newVal);
          //nextTickだと描画されない
          setTimeout(() => {
            this.tab = index;
          }, 500);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * get data field_tree
     */
    this.$watch(
      () => [Store.getters["PortalChart/getRelatedCompany"], this.editable],
      (data) => {
        if(!this.checkFieldTreeDate) {
          let _formValues = { ...this.formValues };
          if (data[0]) _formValues = data[0];
          this.formValues = _formValues;
          this.companyName =
          Store.getters["PortalChart/getSelectedRelatedCompany"].companyName;
          this.level = Store.getters["PortalChart/getSelectedRelatedCompany"].level;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    if(this.isObayashi) {
      Store.dispatch("PortalChart/getFieldTreeForeman", this.chartId);
    }
  },
  methods: {
    /**
     * (共通)
     * フォームの変更を送信
     */
    formUpdate(params, parent_name) {
      if (parent_name) {
        const formValues = { ...this.formValues };
        formValues[parent_name] = { ...formValues[parent_name], ...params };
        this.formValues = { ...formValues };
      } else {
        this.formValues = { ...this.formValues, ...params };
      }
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      this.editable = true;
    },
    /**
     * (共通)
     * 状態を詳細に変更
     */
    onReadonly() {
      this.isShowConfirmDialog = true;
    },
    /**
     * (共通)
     * 状態を詳細に変更
     */
    onDetail() {
      this.editable = false;
    },

    /**
     * (共通)
     * 登録
     */
    async onSubmit() {
      this.isSubmitted = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isSubmitted = false
      }, SUBMIT_DELAY_TIMEOUT);

      let _item = _.cloneDeep(this.formValues);
      _item["field_tree_id"] = this.chartId;
      let _construction_contract_info = {
        ..._item["construction_contract_info"],
      };
      let _field_tree_technicians = [
        ..._construction_contract_info["field_tree_technicians"],
      ];

      if (_construction_contract_info) {
        _field_tree_technicians.forEach((element) => {
          delete element["quality_id"];
        });
        delete _field_tree_technicians["qualification"];
      }
      _construction_contract_info["field_tree_technicians"] =
        _field_tree_technicians;
      _item["construction_contract_info"] = _construction_contract_info;
      let _field_worker = { ..._item["field_worker"] };
      if (_field_worker) {
        let ids = [];
        let _field_tree_users = [];
        if (_field_worker["field_tree_users"]) {
          _field_tree_users = [..._field_worker["field_tree_users"]];
        }
        _field_tree_users?.forEach((user) => {
          if (user.user_id) {
            ids.push(user.user_id);
          } else {
            ids.push(user.id);
          }
        });
        _field_tree_users = {
          ids: ids,
        };
        _field_worker["field_tree_users"] = _field_tree_users;
        delete _field_worker["list_user_of_company"];
        _item["field_worker"] = _field_worker;

        // sort field_tree_construction_permits by asc
        if (_item["construction_permit"] && _item["construction_permit"]["field_tree_construction_permits"]) {
          _item["construction_permit"]["field_tree_construction_permits"].forEach(element => {
            element["cmn_mst_construction_permit_ids"] = element["cmn_mst_construction_permit_ids"]
                .sort((first, second) => first - second);
          });
        }
      }
      const result = await Store.dispatch(
        "PortalChart/updateRelatedCompany",
        _item
      );
      //レスポンスエラーがある場合はフォームを閉じない
      //エラーメッセージは、api.jsが表示
      if (result.hasError) {
        return;
      } else {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        await Store.dispatch("PortalChart/getRelatedCompany", this.chartId);
        this.editable = !this.editable;
        this.$emit("onChange");
      }
    },

    async resetForm() {
      await Store.dispatch("PortalChart/getRelatedCompany", this.chartId);
      Store.dispatch("PortalChart/getFieldTreeForeman", this.chartId);
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid, errors }) {
      this.errors = errors;
      this.valid = valid;
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        this.editable = false;
      } else {
        this.isShowConfirmDialog = false;
        this.$emit("cancel");
      }
    },
    /**
     * (共通)
     * click button Close (X)
     */
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },
  },

  /**
   * Important: clear timeout
   */
  beforeDestroy () {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout)
  }
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
