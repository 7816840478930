<template>
  <div>
    <v-container>
      <v-row v-for="(item, index) in items" :key="index">
        <v-list-item>
          <v-list-item-content>
            <SectionLabel label="取引先" />
          </v-list-item-content>
        </v-list-item>
        <v-col cols="12" sm="6" md="6">
          <v-list-item>
            <v-list-item-content>
              <Label label="会社名">
                <InputText name="company_name" :values="item" />
              </Label>
            </v-list-item-content>
          </v-list-item>
          <Label label="担当者"> </Label>
          <v-list-item
            v-for="element in item.machine_rental_company_field_users"
            :key="element.id"
          >
            <v-list-item-content>
              <InputText name="user_name" :values="element" />
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-list-item>
            <v-list-item-content>
              <Label label="備考" :editable="editable">
                <InputTextArea
                  name="remarks"
                  :values="item"
                  :editable="editable"
                  @onInput="onInput({ value: $event, index })"
                  validation_label="備考"
                  validation_rules="max:512"
                />
              </Label>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-btn
          class="btn-search mr-4 ml-6"
          depressed
          outlined
          color="warning"
          @click="deleteCompany(item.id)"
          v-if="editable && flagSupervisor"
        >
          削除
        </v-btn>
        <v-btn
          class="btn-search ml-6"
          color="primary"
          @click="openAddWorker(item)"
          v-if="editable && flagUserRentalCompany"
        >
          編集
        </v-btn>
      </v-row>
      <Popup width="480px" :dialog="isShowForm">
        <FormCompanyRental
          @closeFormRegister="closeFormRegister"
          :item="rightUserItem"
          @updateDataListRight="updateDataListRight"
          :flagErrorUser="flagErrorUser"
        />
      </Popup>

      <Popup width="480px" :dialog="isDialogRemove">
        <ConfirmRemoveCompanyDialog
          @close="isDialogRemove = false"
          @yes="remove()"
          title="作業終了"
          text1="協力会社を停止しようとしています。
停止すると、紐づくユーザーはログインできなくなります。"
          text2="実行しますか？"
          warning
        />
      </Popup>
    </v-container>
  </div>
</template>

<script>
import Label from "@/components/forms/elements/Label";
import InputTextArea from "@/components/forms/elements/InputTextArea";
import InputText from "@/components/forms/elements/InputText";
import Popup from "@/components/common/Popup.vue";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import FormCompanyRental from "./FormCompanyRental.vue";
import ConfirmRemoveCompanyDialog from "./ConfirmRemoveCompanyDialog.vue";
import { Store } from "@/store/Store.js";
import _ from "lodash";
export default {
  data: () => {
    return {
      isShowForm: false,
      isDialogRemove: false,
      items: [],
      id: null,
      rightUserItem: {},
    };
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.items = newValue[0][newValue[1]];
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    formValues: {
      type: Object,
    },
    formUpdate: Function,
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    flagUserRentalCompany: {
      type: Boolean,
      default: false,
    },
    flagSupervisor: {
      type: Boolean,
      default: false,
    },
    flagErrorUser: Boolean,
  },
  components: {
    Label,
    InputTextArea,
    InputText,
    SectionLabel,
    Popup,
    FormCompanyRental,
    ConfirmRemoveCompanyDialog,
  },
  methods: {
    openAddWorker(item) {
      this.isShowForm = true;
      this.rightUserItem = { ...item };
    },
    onInput({ index, value }) {
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    closeFormRegister() {
      this.isShowForm = false;
    },

    deleteCompany(id) {
      this.isDialogRemove = true;
      this.id = id;
    },
    async remove() {
      const params = {
        id: this.id,
      };
      let rs = await Store.dispatch("Sites/deleteMachineRentalCompany", params);
      if (!rs.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
        this.$emit("updateDataDetail");
        this.isDialogRemove = false;
      }
    },
    updateDataListRight(data, id) {
      let array = _.cloneDeep(this.items);
      array.forEach((e) => {
        if (e.id == id) {
          e.machine_rental_company_field_users = [...data];
        }
      });
      this.isShowForm = false;
      this.$emit("updateDataListRight", { name: this.name, value: array });
    },
  },
};
</script>

<style>
</style>