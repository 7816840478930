<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <!--  (共通) 会社ヘッダー -->
        <SitePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <Loading v-if="isLoading" />
        <TableLayout :layoutParams="layoutParams" :hideFooter="searchParams.totalPage == 1">
          <template #tableHeader="{ updateHeader }">
            <PatrolHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :multiSelectStatus="disableApproveBtn"
              :updateHeader="updateHeader"
              :searchParams="searchParams"
              @openRemoveDialog="openRemoveDialog()"
              @onReject="openConfirmDialog(true)"
              @onApprove="openConfirmDialog(false)"
              @openItemForm="openNewItemForm"
              @onInput="onChangeSearchParams"
              @openHealthAndSafetyPopup="openHealthAndSafetyPopup"
            >
              <SearchFormWrapper>
                <Label label="ステータス" width="300px">
                  <Select
                    name="status_codes"
                    :values="searchParams"
                    :editable="true"
                    :multiple="true"
                    :items="STATUS_CODE_ARRAY"
                    label=""
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <Label label="工事" width="200px">
                  <Select
                    name="field_construction_id"
                    :values="searchParams"
                    :editable="true"
                    :items="listFieldConstruction"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <Label label="種別" width="200px">
                  <Select
                    name="type"
                    item_text="type"
                    :values="searchParams"
                    :editable="true"
                    :items="PATROL_TYPE_ALL"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <Label label="記入者" width="200px">
                  <InputText
                    name="detect_user"
                    :values="searchParams"
                    :editable="true"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <Label label="緊急度" width="200px">
                  <Select
                    name="urgency"
                    :values="searchParams"
                    item_value="value"
                    :editable="true"
                    :items="URGENCY_CLASSIFICATION_SELECT"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <Label label="巡回記録名称" width="200px">
                  <InputText
                    name="patrol_record_name"
                    :values="searchParams"
                    :editable="true"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <Label label="場所" width="200px">
                  <Select
                    name="field_item_tree_id"
                    :values="searchParams"
                    :editable="true"
                    :items="listFieldPlace"
                    item_text="item_name"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <Label label="対応者" width="200px">
                  <InputText
                    name="recovery_user"
                    :values="searchParams"
                    :editable="true"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <v-spacer></v-spacer>
                <v-btn class="mx-2 mt-11" color="primary" depressed @click="onSearch">
                  検索
                </v-btn>
              </SearchFormWrapper>
            </PatrolHeader>
            <TableSortWrapper>
              <TableSort
                :hidePageCount="true"
                :values="searchParams"
                :sort_items="SORT_ITEMS"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                @onInput="onChangeSortParams"
              />
            </TableSortWrapper>
          </template>
          <template #tableBody="{ tableHeight }">
            <PatrolTables
              :tableLabels="TABLE_LABELS"
              :tableHeight="tableHeight"
              :items="items"
              :flagNotData="flagNotData"
              :itemsPerPage="searchParams.total_group"
              :isSelected="isSelected"
              :checkSelectedParents="checkSelectedParents"
              :openItemForm="openItemForm"
              :getChildItems="getChildItems"
              :reloadList="reloadList"
              @update="updateSelectedItems"
            />
          </template>
          <template #tableFooter >
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>
    <Popup width="480px" :dialog="popups.isShowConfirmDialog">
      <ConfirmDialog
        :dialogText="dialogApproveText"
        :isApproved="isApproved"
        @close="closeApproveDialog()"
        @yes="handleConfirm()"
        :warning="dialogApproveText.flg"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmDialog
        :dialogText="dialogRemoveText"
        :isApproved="isApproved"
        @close="closeRemoveDialog()"
        @yes="onRemove()"
        warning
      />
    </Popup>
    <Popup :dialog="popups.isShowFormCombine">
      <FormCombine
        @cancel="popups.isShowFormCombine = false"
      >
      </FormCombine>
    </Popup>
    <Popup width="960px" :dialog="popups.isShowHealthAndSafetyPopup">
      <HealthAndSafetyPopup
        :construction_id="searchParams.field_construction_id"
        :flagErrorCreateSafetyDiarySettings="flagErrorCreateSafetyDiarySettings"
        @handleYes="createSafetyDiarySetting"
        @cancel="popups.isShowHealthAndSafetyPopup = false"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowPaltrolForm">
      <PaltrolForm
        :construction_id="searchParams.field_construction_id"
        :isNewItem="isNewItem.isNewItemPaltrols"
        :recordId="patrol_record_id"
        :typeForm="typeFormPaltrol"
        :childId="childId"
        @updateTypeForm="updateTypeForm"
        @actionSuccess="actionSuccess"
        @closeFormPalTrol="closeFormPalTrol"
        @updateRecordId="updateRecordId"
        :isShowMove="true"
        :disabledDoubleNext="false"
        :disabledDoublePrev="false"
      />
    </Popup>
  </div>
</template>

<script>
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "./components/TableSort";
import ConfirmDialog from "./components/ConfirmDialog.vue";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import Popup from "@/components/common/Popup";
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import TabSelect from "@/components/forms/elements/TabSelect";
import Select from "@/components/forms/elements/Select";
import PatrolHeader from "./components/PatrolHeader.vue";
import PaltrolForm from "@/components/forms/schedule/patrols/PaltrolForm/index.vue"
import { Store } from "@/store/Store.js";
import _ from "lodash";
import {
  PATROLS_TABLE_LABELS,
  PATROL_SORT_ITEMS,
  DIALOG_TEXT,
  DIALOG_REMOVE_TEXT,
  URGENCY_CLASSIFICATION_SELECT,
  STATUS_CODE,
  MENU_FORM_PATROL,
  STATUS_CODE_ARRAY,
  STATUS_OBJECT,
  MODE_ACTION,
  PATROL_TYPE_ALL
} from "@/constants/PATROL_RECORD"; //絞り込みフォームで使用
import {
  TABLES_PER_PAGE,
  TABLE_SORT_ORDERS,
  NO_DATA_MESSAGE
} from "@/constants/COMMON";
import { HEADER_MENU_ITEMS_SITE } from "@/constants/GLOBALHEADER";
import InputText from "@/components/forms/elements/InputText";
import HealthAndSafetyPopup from "./components/HealthAndSafetyPopup.vue";
import PatrolTables from "@/views/schedule/patrols/components/PatrolTables";
import { format } from "date-fns";
import Loading from "@/components/loading/Loading.vue";

//タイトル
const PAGE_TITLE = "巡回記録・各種連絡";
// テーブル設定
const TABLE_LABELS = PATROLS_TABLE_LABELS;
//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;
// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;
//ソート要素
const SORT_ITEMS = PATROL_SORT_ITEMS;
//ストア
const STORE = "PatrolRecord";

const typeJumpOne = 1;
const typeJumpContact = 2;

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      PAGE_TITLE,
      SORT_ITEMS,
      TABLE_LABELS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      URGENCY_CLASSIFICATION_SELECT,
      STATUS_CODE,
      STATUS_CODE_ARRAY,
      NO_DATA_MESSAGE,
      flagNotData: false,
      PATROL_TYPE_ALL,
      items: [],
      ENV_CLIENT,
      /**
       * (共通)
       * checkbox選択item
       */
      selectedItems: {
        safety_diary_ids: [],
        safety_diary_comment_correction_ids: []
      },
      searchParams: {
        start_safety_diary_date: format(new Date().setDate(new Date().getDate()), "yyyy-MM-dd"),
        end_safety_diary_date: format(new Date().setDate(new Date().getDate()), "yyyy-MM-dd"),
        field_construction_id: null,
        patrol_record_name: null,
        field_item_tree_id: null,
        type: null,
        urgency: null,
        detect_user: null,
        recovery_user: null,
        status_codes: [],
        sort: SORT_ITEMS[0].id,
        asc: false,
        currentPage: 1,
        total_group : null,
        total_item : null
      },
      popups: {
        isShowItemForm: false,
        isShowConfirmDialog: false,
        isShowRemoveDialog: false,
        isShowHealthAndSafetyPopup : false,
        isShowPaltrolForm : false,
      },
      dialogApproveText: {
        title: "",
        text1: "",
        text2: "",
        btnSubmitText: "",
        flg: false,
      },
      dialogRemoveText: {
        title: "",
        text1: "",
        text2: "",
        text3: null,
        text4: null,
        btnSubmitText: "",
      },
      /**
       * (共通)
       * 新規フラグ
       */
      isApproved: false,
      reloadList: false,
      field_id: null,
      listFieldConstruction: [],
      listFieldPlace: [],
      patrol_record_id: null,
      childId : null,
      isNewItem : {
        isNewItemPaltrols: false,
      },
      flagErrorCreateSafetyDiarySettings : false,
      typeFormPaltrol: null,
      indexAction: 0,
      listDataAction: [],
      itemDetail: {},
      isLoading: false,
    };
  },
  computed: {
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    disableApproveBtn() {
      return (this.selectedItems.safety_diary_ids.length + this.selectedItems.safety_diary_comment_correction_ids.length) === 0;
    },
    disableRemoveBtn() {
      return (this.selectedItems.safety_diary_ids.length + this.selectedItems.safety_diary_comment_correction_ids.length) === 0;
    },
    apiParams() {
      return {
        start_safety_diary_date : this.searchParams.start_safety_diary_date,
        end_safety_diary_date: this.searchParams.end_safety_diary_date,
        field_construction_id: this.searchParams.field_construction_id,
        patrol_record_name : this.searchParams.patrol_record_name,
        field_item_tree_id: this.searchParams.field_item_tree_id,
        type: this.searchParams.type,
        urgency: this.searchParams.urgency,
        detect_user : this.searchParams.detect_user,
        recovery_user : this.searchParams.recovery_user,
        status_codes: this.searchParams.status_codes,
        sort_value: this.searchParams.sort,
        sort_by: this.searchParams.asc ? 1 : 0,
        page_number: this.searchParams.currentPage
      };
    },
    SELECTED_ITEMS() {
      let rs = [];
      this.selectedItems.safety_diary_ids.forEach(e => {
        rs.push(e);
      });
      this.selectedItems.safety_diary_comment_correction_ids.forEach(e => {
        rs.push(e);
      });
      return rs;
    }
  },

  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    if (ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT) {
      Store.dispatch("GlobalHeader/setSiteMenu", {
        menuId: HEADER_MENU_ITEMS_SITE.SAFETY_PATROLS.id,
      });
    }
    else{
      Store.dispatch("GlobalHeader/setSiteMenu", {
        menuId: HEADER_MENU_ITEMS_SITE.SCHEDULE.id,
      });
    }

    // getCurrentSite
    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      (data) => {
        this.field_id = data["field_id"];
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters[`Action/getIndex`],
      async (data) => {
        this.indexAction = data;
        if (this.indexAction === this.listDataAction.length - 1) {
          let _params = { ... this.apiParams};
          _params.page_number = _params.page_number + 1;
          // await Store.dispatch(`PatrolRecord/getForAction`, {params: _params});
        }
      },
      {
        immediate : true,
        deep: true
      }
    );
    this.$watch(
      () => Store.getters[`Action/getListData`],
      (data) => {
        this.listDataAction = _.cloneDeep(data);
      },
      {
        immediate : true,
        deep: true
      }
    );
    this.$watch(
      () => Store.getters[`PatrolRecord/getDataAction`],
      (data) => {
        this.listDataAction = this.listDataAction.concat(data)
        Store.dispatch(`Action/setListData`, this.listDataAction);
      },
      {
        immediate : true,
        deep: true
      }
    );
    this.$watch(
      () => Store.getters[`PatrolRecord/getListSafetyJump`],
      (data) => {
        this.listDataAction = [...data]
        Store.dispatch(`Action/setListData`, this.listDataAction);
      },
      {
        immediate : true,
        deep: true
      }
    );

    this.$watch(
      () => this.field_id,
      (data, oldData) => {
        if (data && JSON.stringify(data) !== JSON.stringify(oldData)) {
          this.getDataListFieldConstructions();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        this.items = this.addStatusToItems(data[0]);
        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = Number(data[1].current);
        searchParams.total_item = this.getTotalItem(data[0],data[1].total_item);
        searchParams.total_group = data[1].total_group;
        this.searchParams = searchParams;
        this.flagNotData = this.searchParams.total_item == 0;
      },
      {
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["PatrolRecord/getIsLoading"],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  components: {
    PatrolTables,
    InputText,
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    SitePortalHeader,
    Popup,
    ConfirmDialog,
    Label,
    TabSelect,
    Select,
    PatrolHeader,
    HealthAndSafetyPopup,
    PaltrolForm,
    Loading,
  },
  methods: {
    getTotalItem(arr, total) {
      return total;
    },
    /**
     * (共通)
     * 検索
     */
    onSearch() {
      (this.searchParams["currentPage"] = 1), this.getItems();
    },

    /**
     * closeRemoveDialog
     */
    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
      this.$nextTick(() => {
        this.selectedItems = {
          safety_diary_ids : [],
          safety_diary_comment_correction_ids : []
        };
        this.dialogRemoveText = {
          title: "",
          text1: "",
          text2: "",
          text3: null,
          text4: null,
          btnSubmitText: "",
        };
      });
      this.reloadList = !this.reloadList;
    },
    /**
     * (共通)
     * 並び替えパラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.getItems();
    },

    checkStartEndDate(){
      return format(new Date(this.searchParams.start_safety_diary_date), "yyyy/MM/dd") <= format(new Date(this.searchParams.end_safety_diary_date), "yyyy/MM/dd");
    },
    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      if (name == "start_safety_diary_date" || name == "end_safety_diary_date") {
        searchParams[name] = format(new Date(value), "yyyy-MM-dd");
        this.searchParams["currentPage"] = 1;
        this.selectedItems = {
          safety_diary_ids : [],
          safety_diary_comment_correction_ids : []
        };
        this.searchParams = searchParams;
        this.getItems();
      } else {
        if (name == "field_construction_id") {
          searchParams.field_item_tree_id = null;
          this.getDataListItemTrees(value);
        }
        searchParams[name] = value;
        this.searchParams = searchParams;
      }
    },

    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    openNewItemForm(id) {
      this.isNewItem.isNewItemPaltrols = true;
      this.popups.isShowPaltrolForm = true;
      this.typeFormPaltrol = id;
    },

    async openItemForm(item) {
      let _params = { ... this.apiParams};
      if (item.safety_form === MENU_FORM_PATROL.SHITEKI.id || item.safety_form === MENU_FORM_PATROL.MULTIPLE_SHITEKI.id) {
        _params.type_jump = typeJumpOne;
      } else {
        _params.type_jump = typeJumpContact;
      }
      let data = await Store.dispatch(`PatrolRecord/getListSafetyJump`, {params: _params});
      await Store.dispatch(`Action/setListData`, data);
      const idx = data.findIndex(it => it.safety_diaries_id === item.safety_diaries_id)
      await Store.dispatch(`Action/setIndex`, idx);
      this.patrol_record_id = item.safety_diaries_id;
      await Store.dispatch(`${STORE}/getDetail`, this.patrol_record_id);
      this.popups.isShowPaltrolForm = true;
      this.isNewItem.isNewItemPaltrols = false;
      this.typeFormPaltrol = item.safety_form;
      if (this.typeFormPaltrol === MENU_FORM_PATROL.SHITEKI.id ||
        item.safety_form == MENU_FORM_PATROL.CONTACT.id
      ) {
        this.childId = item.children[0].safety_diary_comment_correction_id;
      }

      if (!item.itemChild) {
        await Store.dispatch(`Action/setActionSection`, 0);
      } else {
        const actIdx = item?.children
          .findIndex((itx) => {
            return itx?.safety_diary_comment_correction_id === item?.itemChild?.safety_diary_comment_correction_id
          })
        setTimeout(async () => {
          await Store.dispatch(`Action/setActionSection`, actIdx);
        }, 1500)
      }
    },

    openRemoveDialog() {
      this.isApproved = this.SELECTED_ITEMS.filter(e => this.canAction(MODE_ACTION.DELETE, e.status)).length == 0;
      if (this.isApproved) {
        this.dialogRemoveText = {
          title: DIALOG_REMOVE_TEXT.TITLE,
          text1: DIALOG_REMOVE_TEXT.TEXT_ALL_APPROVE_1,
          text2: DIALOG_REMOVE_TEXT.TEXT_ALL_APPROVE_2,
          text3: null,
          text4: null,
          btnSubmitText: DIALOG_REMOVE_TEXT.BTN_OK,
        };
      } else {
        this.dialogRemoveText = {
          title: DIALOG_REMOVE_TEXT.TITLE,
          text1: DIALOG_REMOVE_TEXT.TEXT_1,
          text2: DIALOG_REMOVE_TEXT.TEXT_2,
          text3: DIALOG_REMOVE_TEXT.TEXT_3,
          text4: DIALOG_REMOVE_TEXT.TEXT_4,
          btnSubmitText: DIALOG_REMOVE_TEXT.BTN_SUBMIT,
        };
      }
      this.popups.isShowRemoveDialog = true;
    },
    /**
     * true: 承認解除
     * false: 承認
     */
    openConfirmDialog(flg) {
      this.isApproved = this.SELECTED_ITEMS.filter(e => this.canAction(MODE_ACTION.APPROVE,e.status)).length == 0 && !flg;
      let isRejected = this.SELECTED_ITEMS.filter(e => this.canAction(MODE_ACTION.REJECT,e.status)).length == 0 && flg;
      this.popups.isShowConfirmDialog = true;
      const {
        REJECT_BTN_SUBMIT,
        REJECT_TEXT2,
        REJECT_TEXT1,
        REJECT_TITLE,
        APPROVE_BTN_SUBMIT,
        APPROVE_TEXT2,
        APPROVE_TEXT1,
        APPROVE_TITLE,
        ALL_APPROVE_TITTLE,
        ALL_APPROVE_TEXT,
        ALL_REJECT_TITTLE,
        ALL_REJECT_TEXT,
        BTN_OK,
      } = DIALOG_TEXT;
      if (this.isApproved) {
        this.dialogApproveText.title = ALL_APPROVE_TITTLE;
        this.dialogApproveText.text1 = ALL_APPROVE_TEXT;
        this.dialogApproveText.text2 = null;
        this.dialogApproveText.btnSubmitText = BTN_OK;
      } else if (isRejected) {
        this.isApproved = isRejected;
        this.dialogApproveText.title = ALL_REJECT_TITTLE;
        this.dialogApproveText.text1 = ALL_REJECT_TEXT;
        this.dialogApproveText.text2 = null;
        this.dialogApproveText.btnSubmitText = BTN_OK;
      } else {
        this.dialogApproveText.title = flg ? REJECT_TITLE : APPROVE_TITLE;
        this.dialogApproveText.text1 = flg ? REJECT_TEXT1 : APPROVE_TEXT1;
        this.dialogApproveText.text2 = flg ? REJECT_TEXT2 : APPROVE_TEXT2;
        this.dialogApproveText.btnSubmitText = flg
          ? REJECT_BTN_SUBMIT
          : APPROVE_BTN_SUBMIT;
      }
      this.dialogApproveText.flg = flg;
    },
    /**
     * closeApproveDialog
     */
    closeApproveDialog() {
      this.popups.isShowConfirmDialog = false;
      this.$nextTick(() => {
        this.selectedItems = {
          safety_diary_ids : [],
          safety_diary_comment_correction_ids : []
        };
        this.dialogApproveText = {
          title: "",
          text1: "",
          text2: "",
          btnSubmitText: "",
          flg: false,
        };
      });
      this.reloadList = !this.reloadList;
    },
    /**
     * handleConfirm
     */
    handleConfirm() {
      if (this.dialogApproveText.flg) {
        this.rejectItems();
      } else {
        this.approveItems();
      }
      this.selectedItems = {
        safety_diary_ids : [],
        safety_diary_comment_correction_ids : []
      };
      this.reloadList = !this.reloadList;
    },

    canAction(mode, status) {
      if (mode == MODE_ACTION.DELETE) {
        return status !== STATUS_OBJECT.EX4.status;
      } else if (mode == MODE_ACTION.REJECT) {
        return status == STATUS_OBJECT.EX4.status;
      } else {
        return status == STATUS_OBJECT.EX2.status || status == STATUS_OBJECT.EX3.status;
      }
    },

    async onRemove() {
      let _selectedItems = { ...this.selectedItems };
      let params = this.getParamsAction(MODE_ACTION.DELETE,_selectedItems)
      const result = await Store.dispatch(`${STORE}/delete`, params );
      if (!result.hasError) {
        this.searchParams["currentPage"] = 1;
        //ダイアログ閉じる
        //成功したら値を更新
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
      }
      this.closeRemoveDialog();
    },
    getParamsAction(mode,selectedItems){
      let rs = {
        safety_diary_ids: [],
        safety_diary_comment_correction_ids: []
      };
      selectedItems.safety_diary_ids.forEach(e => {
        if (this.canAction(mode, e.status)) {
          rs.safety_diary_ids.push(e.parentId);
        }
      });
      selectedItems.safety_diary_comment_correction_ids.forEach(e => {
        if (this.canAction(mode, e.status)) {
          rs.safety_diary_comment_correction_ids.push(e.childId);
        }
      });
      rs.safety_diary_ids.forEach(e => {
        let temp = this.items.find(e1 => e1.safety_diaries_id == e).children;
        rs.safety_diary_comment_correction_ids = rs.safety_diary_comment_correction_ids.filter(e1 => !temp.find(e2 => e2.safety_diary_comment_correction_id == e1));
      });
      return rs;
    },
    async approveItems() {
      let _selectedItems = { ...this.selectedItems };
      let temp = this.getParamsAction(MODE_ACTION.APPROVE,_selectedItems)
      let params = { ...temp, approve_flag: 1 };
      const result = await Store.dispatch(`${STORE}/updateStatus`, params);
      if (!result.hasError) {
        //成功したら値を更新
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "承認しました。"
        });
      }
      this.closeApproveDialog();
    },

    // 承認解除
    async rejectItems() {
      let _selectedItems = { ...this.selectedItems };
      let temp = this.getParamsAction(MODE_ACTION.REJECT,_selectedItems)
      let params = { ...temp, approve_flag: 0 };
      const result = await Store.dispatch(`${STORE}/updateStatus`, params);
      if (!result.hasError) {
        //成功したら値を更新
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "承認解除しました。"
        });
      }
      this.closeApproveDialog();
    },
    /**
     * データ取得
     */
    async getItems() {
      if (this.searchParams.field_construction_id && this.checkStartEndDate()) {
        let _params = { ... this.apiParams};
        await Store.dispatch(`${STORE}/get`, { params: _params });
      } else {
        this.flagNotData = true;
        this.items = [];
        this.searchParams.total_item = 0;
        this.searchParams.total_group = 0;
      }
      this.selectedItems = {
        safety_diary_ids: [],
        safety_diary_comment_correction_ids: []
      };
      this.reloadList = !this.reloadList;
      Store.dispatch("Notification/getNewNoticeStatus");
    },
    /**
     * get List Construction
     * @returns List koji
     */
    async getDataListFieldConstructions() {
      if (this.field_id) {
        const responseSite = await Store.dispatch("PortalChart/getWorkTypes", this.field_id);
        this.listFieldConstruction = [...responseSite.data.contents.entries];
        if (!this.searchParams.field_construction_id) {
          this.searchParams.field_construction_id = this.listFieldConstruction[0].id;
          this.getItems();
          this.getDataListItemTrees(this.searchParams.field_construction_id);
        }
      }
    },
    /**
     * get data list item tree by koji
     * @param val : koji
     */
    async getDataListItemTrees(val){
      if (val) {
        let params = {
          field_id: this.field_id,
          field_construction_id: val
        };
        const responseItemTree = await Store.dispatch("PortalChart/getListItemTrees", { params });
        let array = [...responseItemTree.data.contents.entries];
        array.unshift({ id: null, item_name: "" });
        this.listFieldPlace = [...array];
      }
    },
    /**
     * call api export reports
     */
    async openHealthAndSafetyPopup() {
      if (this.field_id) {
        await Store.dispatch(`PatrolRecord/getDetailSafetyDiarySettings`, this.field_id);
        this.popups.isShowHealthAndSafetyPopup = true;
      }
    },
    checkSelectedParents(itemParent) {
      for (let i = 0; i < itemParent.children.length; i++) {
        if (!this.isSelected(itemParent.children[i].safety_diary_comment_correction_id)) {
          return false;
        }
      }
      return true;
    },

    isSelected(id) {
      return Boolean(this.selectedItems.safety_diary_comment_correction_ids.find(e => e.childId == id));
    },

    async getChildItems(safety_diaries_id,flag, pageNumber) {
      let _apiParams = { ...this.apiParams };
      _apiParams["safety_diary_id"] = safety_diaries_id;
      _apiParams["page_number"] = pageNumber;
      if (flag) {
        _apiParams["status_code_flag_2"] = true;
      }
      let rs = await Store.dispatch(`${STORE}/loadChildren`, { params: _apiParams });
      let child = _.cloneDeep(rs.data.contents.entries);
      if (this.selectedItems.safety_diary_ids.find(e => e.parentId == safety_diaries_id)) {
        child.forEach(e => {
          let status = null;
          if (e.status_code == 2) {
            status = STATUS_OBJECT.EX7.status
          } else if (e.status_code == 1) {
            status = STATUS_OBJECT.EX4.status
          } else if (e.status_code == 0 && e.recovery_flag == 1) {
            status = STATUS_OBJECT.EX3.status;
          } else {
            status = STATUS_OBJECT.EX1.status;
          }
          this.selectedItems.safety_diary_comment_correction_ids.push({
            childId: e.safety_diary_comment_correction_id,
            status: status
          });
        });
      }
    },

    /**
     * checkboxの値からselectedItemsを更新
     */
    updateSelectedItems(objId, isChecked) {
      let _selectedItems = { ...this.selectedItems };
      // checked
      if (isChecked) {
        objId.child.forEach(child => {
          if (!_selectedItems.safety_diary_comment_correction_ids.find(e => e.childId == child.childId)) {
            _selectedItems.safety_diary_comment_correction_ids.push(child);
          }
        })
      } else {
        // unchecked
        _selectedItems.safety_diary_comment_correction_ids = this.selectedItems.safety_diary_comment_correction_ids.filter((child) => {
          return !objId.child.find(e => e.childId == child.childId);
        });
      }
      _selectedItems.safety_diary_ids = objId.parent;
      this.selectedItems = { ..._selectedItems };
    },
    async createSafetyDiarySetting(formValues) {
      let _formValues = { ...formValues };
      _formValues.field_id = this.field_id;
      let params = {
        safety_diary_settings: _formValues
      };
      let result = await Store.dispatch(`PatrolRecord/createSafetyDiarySettings`,  params );
      if (!result.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        this.popups.isShowHealthAndSafetyPopup = false;
      } else {
        this.flagErrorCreateSafetyDiarySettings = !this.flagErrorCreateSafetyDiarySettings;
      }
    },
    changeListBySafeForm(arr) {
      let rs = [];
      arr.forEach(e => {
        if (e.safety_form !== 2) {
          let children = e.children[0];
          rs.push({
            recovery_flag: children.recovery_flag,
            status_code: children.status_code,
            patrol_record_date: e.patrol_record_date,
            detect_user: e.detect_user,
            patrol_record_name: children.pointing_out_detail,
            type: e.type,
            urgency: e.urgency,
            total_children_item: 0,
            field_items: children.field_items,
            recovery_user: children.recovery_user,
            recovery_date: children.recovery_date,
            recovery_content: children.recovery_content,
            image_flag: children.image_flag,
            safety_form: e.safety_form,
            safety_diaries_id: e.safety_diaries_id,
            children: e.children,
            field_tree_company_id:e.field_tree_company_id,
            detect_user_id:e.detect_user_id
          });
        } else {
          rs.push(e);
        }
      });
      return rs;
    },
    addStatusToItems(arr) {
      let _arr = this.changeListBySafeForm(_.cloneDeep(arr));
      _arr.forEach(itemParent => {
        itemParent["status"] = this.getStatusParentItem(itemParent, itemParent.children || []);
        itemParent.children.forEach(itemChild => {
          if (itemParent.safety_form == 3) {
            itemChild["status"] = itemParent["status"];
          } else {
            if (itemChild.status_code == 2) {
              itemChild["status"] =  STATUS_OBJECT.EX7.status;
            } else if (itemChild.status_code == 1) {
              itemChild["status"] =  STATUS_OBJECT.EX4.status;
            } else if (itemChild.status_code == 0 && itemChild.recovery_flag == 1) {
              itemChild["status"] =  STATUS_OBJECT.EX3.status;
            } else if (itemChild.status_code == 0 && itemChild.recovery_flag == 0) {
              itemChild["status"] =  STATUS_OBJECT.EX1.status;
            }
          }
        });
      });
      return _arr;
    },
    getStatusParentItem(parent, childArr) {
      if (parent.safety_form == 3) {
        return parent.type == 2 ? STATUS_OBJECT.EX5.status : STATUS_OBJECT.EX6.status;
      } else {
        if (parent.status_code_flag_2) {
          return STATUS_OBJECT.EX2.status;
        } else {
          if (parent.status_code_flag_3) {
            return STATUS_OBJECT.EX3.status;
          } else {
            if (childArr.length > 0) {
              let lengthChildUnRecovery = childArr.filter(e => e.recovery_flag == 0).length;
              if (lengthChildUnRecovery == childArr.length) {
                return STATUS_OBJECT.EX1.status;
              } else if (lengthChildUnRecovery > 0 && lengthChildUnRecovery < childArr.length) {
                return STATUS_OBJECT.EX2.status;
              } else {
                if (childArr.filter(e => e.status_code == 2 && e.recovery_flag == 1).length == childArr.length) {
                  return STATUS_OBJECT.EX7.status;
                } else {
                  return childArr.filter(e => e.status_code == 1).length == childArr.length ? STATUS_OBJECT.EX4.status : STATUS_OBJECT.EX3.status;
                }
              }
            } else {
              if (parent.status_code == 2) {
                return STATUS_OBJECT.EX7.status;
              } else if (parent.status_code == 1) {
                return STATUS_OBJECT.EX4.status;
              } else if (parent.status_code == 0 && parent.recovery_flag == 1) {
                return STATUS_OBJECT.EX3.status;
              } else if (parent.status_code == 0 && parent.recovery_flag == 0) {
                return STATUS_OBJECT.EX1.status;
              }
            }
          }
        }
      }
    },
    closeFormPalTrol() {
      this.popups.isShowPaltrolForm = false;
      this.isNewItem.isNewItemPaltrols = false;
      this.patrol_record_id = null;
      this.typeFormPaltrol = null;
    },
    actionSuccess(flag) {
      if (flag) {
        this.closeFormPalTrol();
      }
      this.getItems();
    },
    updateRecordId(id) {
      this.patrol_record_id = id;
    },
    updateTypeForm(typeForm) {
      this.typeFormPaltrol = typeForm;
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-select.v-input--dense .v-chip {
  background: #1B9C4F;
  color: white;
}

::v-deep .v-text-field--filled.v-input--dense.v-text-field--single-line .v-label, .v-text-field--full-width.v-input--dense.v-text-field--single-line .v-label {
  color: black !important;
}
.v-progress-circular {
  margin: 1rem;
}
</style>
