<template>
  <div class="tabSelect">
    <div class="tabSelectwrapper" v-for="(item, index) in items" :key="item.id">
      <label :for="`checkbox_${randId}_${index}`">
        <input
          class="tabCheckbox"
          type="radio"
          v-model="selected"
          :id="`checkbox_${randId}_${index}`"
          :value="item.id"
           @change="$emit('onInput', { name, value: selected })"
        />
        <div class="tabLabel">{{ item.name }}</div>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      randId: Math.floor(Math.random() * 999999),
      selected: null,
    };
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    items: {
      type: Array,
    },
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        const formValues = newValue[0];
        const name = newValue[1];
        if (formValues && name) this.selected = formValues[name];
      },
      { immediate: true, deep: true }
    );
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.tabSelect {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0 8px;
  height: 42px;
}

.tabLabel {
  height: 24px;
  font-size: 11px;
  padding: 4px 8px;
  background-color: $color_form_bg;
  color: #a1a1a1;
  &:hover {
    cursor: pointer;
  }
}

.tabCheckbox:checked + .tabLabel {
  font-weight: bold;
  background-color: $color_primary;
  color: white;
}

.tabSelectwrapper {
  &:first-of-type {
    .tabLabel {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
  &:last-of-type {
    .tabLabel {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.tabCheckbox {
  display: none;
}
</style>
