<template>
  <v-card class="dialog">
    <v-card-title>
      <div class="title">{{ title }}</div>
    </v-card-title>
    <v-card-text>
      <div>
        <RadioGroup
          name="type_file"
          :items="TYPE_FILE"
          :editable="true"
          :values="formValues"
          @onInput="onInput('type_file', $event)"
        />
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="warning"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn depressed class="btn" color="primary" @click.once="downloadFile()" :key="flag">
        出力
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import RadioGroup from "@/components/forms/elements/RadioGroup";
const TYPE_FILE = [
  { id: 1, name: "PDF", value: 2 },
  { id: 2, name: "Excel", value: 1 },
];
export default {
  data() {
    return {
      TYPE_FILE,
      formValues: {
        type_file: 2,
      },
    };
  },
  components: {
    RadioGroup,
  },
  props: {
    title: {
      type: String,
    },
    flag: {
      type: Boolean,
    },
  },
  methods: {
    onInput(name, { value }) {
      this.formValues[name] = value;
    },
    downloadFile() {
      this.$emit("downloadFile", this.formValues["type_file"]);
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .title {
    font-size: 12px;
    color: #1b9c4f;
  }
}
</style>
