import Api, { Mock } from "../api";
import notificationList from "./mock/notification_list.json";

const URL = `${process.env.VUE_APP_API_BASE_URL}/notices/list`;
const URL_GET_NEW_NOTICE_STATUS = `${process.env.VUE_APP_API_BASE_URL}/notices/get_new_notice_status`;
const URL_UPDATE_NOTICE_DISPLAY = `${process.env.VUE_APP_API_BASE_URL}/notices/update_displayed`;

// TODO apiができたら
// import Api, { Mock } from "../apiForceMock";
// ↓
// import Api, { Mock } from "../api";
// に変更する

if (Mock) {
  Mock.onGet(URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: notificationList,
  });

  Mock.onGet(URL_GET_NEW_NOTICE_STATUS).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      new_notice_status: 1,
    },
  });
  Mock.onPost(URL_UPDATE_NOTICE_DISPLAY).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      ids: [1],
    },
  });
}

export const notification = {
  get: () => {
    return Api.get(URL);
  },
  getNewNoticeStatus: () => {
    return Api.get(URL_GET_NEW_NOTICE_STATUS);
  },
  updateNoticeDisplayed: async (params) => {
    return Api.post(URL_UPDATE_NOTICE_DISPLAY, params);
  },
};
