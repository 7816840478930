<template>
  <v-container>
    <v-row class="cst-row">
      <v-col cols="12" :sm="sm" :md="md" class="cst-col">
        <!-- タブ -->
        <v-tabs grow v-model="selectedTab">
          <v-tab v-for="item in items" :key="item.id">
            {{ item.name }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        class="column__end"
        :class="$sp ? 'sp' : ''"
        cols="12"
        sm="12"
        md="3"
      >
        <div class="mr-0 select-time" :class="$sp ? 'sp' : ''">
          <!-- 時間帯選択 -->
          <slot></slot>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => {
    return {
      selectedTab: 0,
    };
  },
  props: {
    items: {
      type: Array,
    },
    sm: {
      type: Number
    },
    md: {
      type: Number
    }
  },
  mounted() {
    this.$watch(
      () => this.selectedTab,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          const index = newValue;
          this.$emit("onChangeTableTab", this.items[index]);
        }
      }
    );
  },
};
</script>
<style lang="scss" scoped>
.select-time {
  min-width: 375px;
  padding-top: 12px;
  &.sp {
    padding-top: 0;
  }
}

.column__end {
  display: flex;
  justify-content: flex-end;

  &.sp {
    padding: 0 12px;
    height: 64px;
  }
}

.cst-row {
  border-bottom: 1px solid;
  padding-top: 12px;
  border-color: #e0e0e0;
}

.cst-col {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
