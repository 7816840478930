<template>
  <div class="dropdown-search-history">
    <v-menu offset-y max-width="400" v-model="isShowMenu">
      <template v-slot:activator="{ on, attrs }">
        <img v-bind="attrs" v-on="on" src="@/assets/svg/add_list.svg" alt="">
      </template>
      <v-card
        class="mx-auto"
        tile
      >
        <v-btn
          width="100%"
          text
          :disabled="(disableBtnCreateSearchHistory || listSearchHistory.length == 10)"
          class="text-transform-none d-flex justify-space-between"
          @click="$emit('openPopupCreateSearchHistory')"
        >
          検索条件を保存
          <v-icon
            right
            dark
            size="18"
          >
            mdi-plus
          </v-icon>
        </v-btn>
        <v-list v-if="listSearchHistory.length" dense class="pt-0">
          <v-list-item-group
            v-model="selectedItem"
            color="primary"
          >
            <v-list-item
              v-for="(item, i) in listSearchHistory"
              :key="i"
              class="pr-2"
              :class="{'item-focus-delete': item.id == idFocusDelete}"
              @click="$emit('seachByItemHistory', item.contents)"
            >
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action @click.stop="deleteItems(item)">
                <v-btn icon small>
                  <v-icon size="18">mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
// import { Store } from "@/store/Store.js";
export default {
  data: () => {
    return {
      selectedItem: null,
      idFocusDelete: null,
      isShowMenu: false,
    };
  },
  props: {
    listSearchHistory: {
      type: Array,
      default: () =>  []
    },
    disableBtnCreateSearchHistory: {
      type : Boolean,
      default : false
    }
  },
  watch: {
    isShowMenu(val) {
      if(!val) {
        this.idFocusDelete = null
      }
    }
  },
  methods: {
    deleteItems(item) {
      this.idFocusDelete = item.id;
      this.$emit('deleteItemsSeachHistory', item.id)
    }
  },
};
</script>
<style lang="scss" scoped>
.dropdown-search-history_create{
  height: 60px;
  padding: 0 10px 0 16px;
  cursor: pointer;
}
.v-list-item__action {
  margin: 3px 0;
}
.text-transform-none {
  text-transform: none
}
.item-focus-delete:before {
  opacity: .04;
}
</style>