<template>
  <div class="">
    <div class="table">
      <!-- Label -->
      <ul class="information">
        <div class="schedule__label">
          <ScheduleTypeLabel/>
        </div>
        <!-- サマリー -->
        <div v-if="item.work_plan_id" class="schedule__summary device__label">
          <!-- 開閉 -->
          <button @click="toggleChild">
            <v-icon v-if="isShowChild">mdi-chevron-down</v-icon>
            <v-icon v-if="!isShowChild">mdi-chevron-right</v-icon>
          </button>
          <v-checkbox
            class="mt-4"
            v-model="forchChecked"
            dense
            @change="onChangeForceChecked"
          ></v-checkbox>
          <!-- 機材名 -->
          <div class="device__name" v-if="item.contents">
            {{ item.contents.length > 60 ? item.contents.substring(0, 60) + "•••" : item.contents }}
          </div>
        </div>
        <!-- 予定情報 -->
        <div v-if="isShowChild && item.work_plan_id" class="schedule__plan">
          <div>
            <ScheduleInfoCompanyPlans
              v-for="(item, index) in getPlans"
              :key="`info_${index}_${item.work_plan_id}_${item.work_company_plan_id}`"
              :item="item"
              :index="index"
              :isTableTypeSchedule="isTableTypeSchedule"
              :cellRect="cellRect"
              :forchChecked="getChecked(item.work_company_plan_id)"
              @onChange="onCheck"
              @openItemForm="openItemForm"
            />
          </div>
        </div>
      </ul>
      <!-- Gage -->
      <div :class="`schedule ${item.work_plan_id ? '' : 'schedule_hidescroll'}`">
        <div class="scheduleInner" :style="getDaySize">
          <!-- 時間表記 -->
          <div class="schedule__label">
            <ScheduleTimeLabel
              :timelabelHeight="margin_top"
              :cellRectWidth="cellRect.width"
              :totalTime="total_hours"
              :prevTime="prev_hours"
              :afterTime="after_hours"
              :hourDivide="hour_divide"
            />
          </div>
          <div class="schedule__summary"></div>
          <div v-if="isShowChild" class="schedule__plan">
            <SchedulePlanCompanyCell
              v-for="(item, index) in getPlans"
              :key="`plan_${index}_${item.work_plan_id}_${item.work_company_plan_id}`"
              :index="index"
              :item="item"
              :cellRect="cellRect"
              :hourDivide="hour_divide"
              :today="getToday"
              :todayStartX="getTodayStartX"
              :marginTop="margin_top"
            ></SchedulePlanCompanyCell>
          </div>
          <div
            v-if="isShowChild && item.work_plan_id"
            class="scheduleBackground"
            :style="getDaySize"
          >
            <!-- 背景グリッド -->
            <div
              v-for="(item, index) in cells"
              :key="`cell_${index}`"
              class="scheduleCell"
              :class="`${getCellStatus(index)} cell_${index}`"
              :style="getCellStyle"
            ></div>
            <!-- 罫線 -->
            <div
              v-for="(item, index) in getHorizontalLines"
              :key="`line_${index}`"
              class="scheduleHorizontalLine"
              :style="getHorizontalLineTop(index)"
            ></div>
          </div>
        </div>
      </div>
      
    </div>
    <div class="table_footer" v-if="item.work_plan_id">
      <v-btn
        v-if="isShowChild &&
          item.total_children_item > 3 &&
          childTableDisplayMoreNumber(
            item.total_children_item,
            item.children.length
          ) > 0
        "
        x-small
        depressed
        :disabled="disabled"
        class="mr-4 display-more"
        color="#E5E5E5"
        @click="getChildItems(item)"
        >さらに表示する
        <v-badge
          class="display-more-number"
          inline
          color="#767676"
          :content="
            childTableDisplayMoreNumber(
              item.total_children_item,
              item.children.length
            )
          "
        />
      </v-btn>
      <v-btn
        :disabled="Boolean(item.state_handling_flag)"
        x-small
        depressed
        color="primary"
        @click="$emit('openForm', item.work_plan_id)"
        >作業を新規追加
      </v-btn>
    </div>
    <div v-if="!item.work_plan_id" class="schedule_nodata">
      <span>{{ NO_DATA_MESSAGE }}</span>
    </div>
  </div>
</template>

<script>
import ScheduleTimeLabel from "./ScheduleTimeLabel";
import SchedulePlanCompanyCell from "./SchedulePlanCompanyCell";
import ScheduleInfoCompanyPlans from "./ScheduleInfoCompanyPlans.vue";
import ScheduleTypeLabel from "./ScheduleTypeLabel.vue";
import { NO_DATA_MESSAGE } from "@/constants/COMMON.js";
/**
 * 前後 12時間を加えて、全部で48時間の幅を持つ
 */

//1時間の分割数
const config_hour_divide = 4;

//当日前の時間
const config_schedule_prev_hours = 0;

//当日後の時間
const config_schedule_after_hours = 6;

//スケジュールで表示する時間
const config_schedule_hours =
  24 + config_schedule_prev_hours + config_schedule_after_hours;

//スケジュールで表示する15分セルの数
const config_all_cells = config_hour_divide * config_schedule_hours;

//1時間のセルのサイズ(px)
const config_cell_width = 16;
const config_cell_height = 32;

//予定の上マージン
const config_margin_top = 0;

export default {
  components: {
    ScheduleTimeLabel,
    SchedulePlanCompanyCell,
    ScheduleInfoCompanyPlans,
    ScheduleTypeLabel,
  },
  data: () => {
    return {
      NO_DATA_MESSAGE,
      checkList: [],
      selectedList: [],
      forchChecked: false,
      isShowChild: true,
      cells: config_all_cells,
      total_hours: config_schedule_hours,
      prev_hours: config_schedule_prev_hours,
      after_hours: config_schedule_after_hours,
      cellRect: { width: config_cell_width, height: config_cell_height },
      hour_divide: config_hour_divide,
      margin_top: config_margin_top,
      disabled: false,
      timeout: null
    };
  },
  props: {
    isTableTypeSchedule: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
    today: {
      type: String,
    },
  },
  computed: {
    // 本日
    getToday() {
      return `${this.today} 00:00:00`;
    },

    // 子要素一覧
    getPlans() {
      const { children } = this.item;
      return children;
    },

    //スケジュールの大きさを表示する時間範囲によって変更
    getDaySize() {
      const { children } = this.item;
      const height = this.isShowChild
        ? (children.length + 2) * config_cell_height + config_margin_top
        : config_cell_height + config_margin_top;

      return `width: ${
        config_all_cells * config_cell_width
      }px;height:${height}px`;
    },

    //スケジュールの予定量にあわせて罫線を作成
    getHorizontalLines() {
      const { children } = this.item;
      return [...new Array(children.length + 1)];
    },
    //スケジュールのグリッドのスタイルを作成
    getCellStyle() {
      const { children } = this.item;
      return `width:${this.cellRect.width}px;height:${
        this.cellRect.height * children.length + config_margin_top
      }px;`;
    },
    //今日の開始ピクセル位置
    getTodayStartX() {
      const prevWidth =
        config_schedule_prev_hours * this.cellRect.width * config_hour_divide;
      return prevWidth;
    },
  },
  mounted() {
    this.$watch(
      () => this.item.children,
      (newValue, oldValue) => {
        if (newValue?.length != oldValue?.length) {
          this.forchChecked = false;
        }
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    // さらに表示できるchild table数
    childTableDisplayMoreNumber(totalNum, displayNum) {
      // 親が持つすべてのchild table数から表示されている数をひく
      const _number = totalNum - displayNum;
      return _number > 0 ? _number : "0";
    },

    openItemForm(work_plan_id, work_company_plan_id, work_approval_flag) {
      this.$emit("openItemForm", work_plan_id, work_company_plan_id, work_approval_flag, this.item.state_handling_flag);
    },

    //全ての子チェックボックスを強制的にチェックする
    onChangeForceChecked(value) {
      this.forchChecked = value;
      const { children } = this.item;
      const updateList = children.map((item) => {
        if(value) {
          this.checkList.push(item.work_company_plan_id);
        } else {
          this.checkList = []
        }
        return {
          id: item.work_company_plan_id,
          isChecked: value,
          schedule_approval: item.work_approval_flag,
          state_handling_flag : item.state_handling_flag,
        };
      });
      this.selectedList = updateList;
      this.$emit("update", updateList);
    },

    // チェックリストを更新
    onCheck({ work_company_plan_id, checked }) {
      let checkList = [...this.checkList];
      //trueの場合
      if (checked) {
        const result = checkList.find((id) => id === work_company_plan_id);
        if (!result) checkList.push(work_company_plan_id);
      } else {
        checkList = checkList.filter((id) => {
          return id !== work_company_plan_id;
        });
      }
      this.checkList = checkList;
      this.forchChecked = this.checkList.length == this.item.children.length;
      // 比較
      const { children } = this.item;
      const updateList = children.map((item) => {
        const has = checkList.includes(item.work_company_plan_id);
        return {
          id: item.work_company_plan_id,
          isChecked: has,
          schedule_approval: item.work_approval_flag,
          state_handling_flag : item.state_handling_flag
        };
      });
      this.selectedList = updateList;
      this.$emit("update", updateList);
    },

    getChecked(work_company_plan_id) {
      const result = this.selectedList.find((item) => item.id === work_company_plan_id);
      return result?.isChecked;
    },

    // 子予定の表示/非表示
    toggleChild() {
      this.isShowChild = !this.isShowChild;
    },

    /**
     * レイアウト関連のメソッド
     */

    // 背景横線の座標
    getHorizontalLineTop(index) {
      const top = index * config_cell_height + config_margin_top;
      return `top:${top}px`;
    },

    // 背景グリッドの状態
    getCellStatus(index) {
      const isAfter =
        index >=
        config_all_cells - config_schedule_after_hours * config_hour_divide;
      if (isAfter) return "isAfter";
      return null;
    },
    getChildItems(item) {
       this.disabled = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.disabled = false;
      }, 1000);
      this.$emit('getChildItems', item.work_plan_id, Math.floor((item.children.length - 3) / 5) + 2);
    },
  },

  /**
   * Important: clear timeout
   */
  beforeDestroy () {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
$border-color: #e5e5e5;

.table {
  width: 100%;
  height: auto;
  display: flex;
}

.table_footer {
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.information {
  position: relative;
  margin: 0;
  margin-left: 2px;
  padding: 0;
  width: 50%;
  background-color: white;

  .schedule__plan {
    margin-left: 4px;
  }
}
.schedule {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  background-color: white;
}

.scheduleInner {
  position: relative;
  height: 100%;
  margin-left: 50px !important;
}

.schedule__label {
  margin-left: 4px;
  height: 24px;
  border-bottom: 1px solid $border-color;
}

.schedule__summary {
  position: relative;
  height: 37px;
  top: 0;
}

.schedule_hidescroll {
  overflow: hidden !important;
}

.schedule__plan {
  position: relative;
}

.schedule_nodata {
  position: absolute;
  left: 50%;
}

//背景
.scheduleBackground {
  position: relative;
  height: 100%;
  display: flex;
}
.scheduleCell {
  position: relative;
  height: 100%;
  background-color: #f8f8f8;
  &:nth-of-type(4n) {
    &:before {
      content: "";
      position: absolute;
      z-index: 0;
      width: 1px;
      height: 100%;
      top: 0;
      right: 0;
      background-color: #ddd;
    }
  }

  &.isAfter {
    background-color: #e8e8e8;
    /* &:before {
      display: none;
    } */
  }
}
.scheduleHorizontalLine {
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
  background-color: #cfcfcf;
}

.device__label {
  display: flex;
  text-align: left;
  align-items: center;
  .device__name {
    font-size: 14px;
    min-width: 180px;
  }
  .device__description {
    overflow: hidden;
    font-size: 9px;
  }
  .device__description-text {
    white-space: nowrap;
  }
}
</style>
