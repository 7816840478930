<template>
  <FormDialog>
    <template #header class="machineType">
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary" class="title">
          機材分類選択
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editable"
          class="mx-2"
          depressed
          small
          outlined
          color="success"
          @click="openConfirmDialog"
        >
          やめる
        </v-btn>

        <v-btn
          v-if="editable"
          class="mx-2"
          depressed
          small
          :disabled="!valid"
          color="primary"
          @click="saveMachineCompanyType"
        >
          保存
        </v-btn>

        <v-btn icon>
          <v-icon @click="openConfirmDialog">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <div>
        <transition name="fade">
          <div class="loading" v-if="!isLoaded">
            <half-circle-spinner
              :animation-duration="1000"
              :size="60"
              :color="'#61d690'"
            />
          </div>
        </transition>
      </div>
      <div v-if="total && Array.isArray(total) && total.length > 0 && isLoaded">
        <span v-for="type in typeLevel.filter(e => e.id < level && level != 1)" :key="type.id">
          <span class="ml-5"
                :style="`font-size:25px;${type.id == level ? 'color : black' : 'color:green;text-decoration: underline;cursor: pointer;'}`"
                @click="changeLevel(type.id)">
            {{ type.name }}
          </span>
          <v-icon class="ml-5" v-if="type.id != level">mdi-chevron-right</v-icon>
        </span>
        <ValidationObserver ref="observer" v-slot="observer">
          <PanelMachineCompanyType
            :items="ITEM_LEVEL"
            :mainHeight="params.mainHeight"
            :level="level"
            :checkedArray="checkedArray"
            :imageMap="imageMap"
            @openChildrensLevel="openChildrensLevel"
            @updateChecked="updateChecked"
          />
          <ValidationCallback :observer="observer" @callback="updateValidate" />
        </ValidationObserver>
      </div>
      <div style="display: flex;justify-content: center" v-if="isNoDataMessage">
        {{ NO_DATA_MESSAGE }}
      </div>
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          warning
          @close="onClickBtnCancelForm"
          @yes="closeForm"
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import PanelMachineCompanyType from "./PanelMachineCompanyType.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import { Store } from "@/store/Store.js";
import { IMAGE_NO_FACE } from '@/constants/COMMON.js';
import { getParamsOfUrlReadFile } from "@/utils/viewSourceAuth";
import { NO_DATA_MESSAGE } from "@/constants/COMMON"; 
import { HalfCircleSpinner } from "epic-spinners";
export default {
  data: () => {
    return {
      editable: true,
      valid: false,
      tab: null,
      isShowConfirmDialog: false,
      typeLevel:
        [{ id: 1, name: "" },
          { id: 2, name: "" },
          { id: 3, name: "" },
          { id: 4, name: "" }],
      level: 1,
      total: [],
      checkedArray: [],
      imageMap: new Map(),
      NO_DATA_MESSAGE,
      isNoDataMessage: false,
      isLoaded: false,
    };
  },
  components: {
    FormDialog,
    PanelMachineCompanyType,
    ConfirmCloseDialog,
    ValidationObserver,
    ValidationCallback,
    HalfCircleSpinner,
    Popup
  },
  props: {
    machine_company_type_tree: Array,
    machine_company_field_type_item_tree_id: Array
  },
  computed: {
    ITEM_LEVEL() {
      return this.total[this.level - 1];
    },
  },
  mounted() {
    this.$watch(
      () => this.machine_company_type_tree,
      (newValue) => {
        this.getData(newValue);
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => this.machine_company_field_type_item_tree_id,
      (newValue) => {
        if(newValue) {
          this.checkedArray = [...newValue];
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  methods: {
    async getData(newValue) {
      await this.getImageMap(newValue);
      if(newValue && Array.isArray(newValue) && newValue.length > 0){
        this.total.push(newValue);
      } else {
        this.isNoDataMessage = true;
      }
      this.isLoaded = true;
    },
    openConfirmDialog() {
      this.isShowConfirmDialog = true;
    },
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
    },
    closeForm() {
      this.$emit("cancel");
    },
    //change level
    changeLevel(id) {
      this.level = id;
    },
    updateValidate({ valid }) {
      this.valid = valid;
    },

    //open chidrens
    openChildrensLevel(id, flag) {
      if (flag) {
        this.updateChecked(id);
      }
      let item = this.total[this.level - 1];
      item = item.find(e => e.id == id);
      this.typeLevel.forEach((e) => {
        if (e.id == this.level) {
          e.name = item.type;
        }
      });
      item = item.childrens;
      this.level++;
      this.total[this.level - 1] = item;
    },
    // update checked ( set array after to null )
    updateChecked(id) {
      this.checkedArray[this.level - 1] = id;
      this.checkedArray = this.checkedArray.filter((e, index) => !(index + 1 > this.level));
    },
    saveMachineCompanyType() {
      this.$emit("changeMachineCompanyType", this.checkedArray);
    },
    async getImageMap(companyTree) {
      const arr = [...companyTree];
      const imgHandle = async (item) => {
        for(let obj of item) {
          await this.setImage(obj.id, obj.image_url);
          if(obj.childrens) await imgHandle(obj.childrens);
        }
      }
      await imgHandle(arr);
    },
    async setImage(key, value) {
      const url = await this.getImage(value);
      if(url) {
        this.imageMap.set(key, url);
      } else {
        this.imageMap.set(key, IMAGE_NO_FACE);
      }
    },
    async getImage(url) {
      let img = "";
      if (url) {
        const params = getParamsOfUrlReadFile(url);
        const response = await Store.dispatch("File/readFile", params);
        if(!response.hasError) {
          img = window.URL.createObjectURL(new Blob([response.data], { type: response.headers["content-type"]}));
        }
      }
      return img;
    },
  }
};
</script>

<style lang="scss" scoped>
.machineType{
  z-index: 100;
}
.from-close-btn {
  float: right
}

.type-level-loong {
  display:inline-block;
  width: 14vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 35px;
  line-height: normal;
}
.title{
  color: #1B9C4F
}

.loading {
  text-align: center;
  position: absolute;
  color: #fff;
  z-index: 9;
  padding: 8px 18px;
  border-radius: 5px;
  left: calc(50% - 45px);
  top: calc(50% - 18px);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
