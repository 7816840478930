// src/plugins/vuetify.js
import Vue from "vue";
import Vuetify from "vuetify";
import colors from "vuetify/lib/util/colors";
import "vuetify/dist/vuetify.min.css";

// アイコン設定
import RemoveCheck from "@/components/icon/RemoveCheck.vue";

Vue.use(Vuetify);

const COLORS = {
  primary: "#1b9c4f",
  warning: "#ff7b52",
  form_bg: "#f8f8f8",
  sp_btn_cancel: "#aaa",
};

export default new Vuetify({
  icons: {
    values: {
      removeCheck: {
        component: RemoveCheck,
      },
    },
  },
  theme: {
    themes: {
      light: {
        primary: COLORS.primary,
        secondary: colors.indigo.lighten4,
        accent: colors.indigo.base,
        warning: COLORS.warning,
        form_bg: COLORS.form_bg,
        sp_btn: COLORS.sp_btn_cancel,
      },
      options: {
        // 色変数を使えるように
        // ex. var(--v-primary-base);
        customProperties: true,
      },
    },
  },
});
