<template>
  <Popup width="800px" :dialog="isShow">
    <v-card class="mx-auto my-0 mb-0 pb-8">
      <div class="dialog-header sticky">
        <div class="dialog-title text-left">図面選択ダイアログ</div>
        <v-btn
          depressed
          small
          icon
          color="primary"
          class="mx-1 px-4"
          @click="onClose"
        >
          <v-icon @click="onClose">mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="dialog-wrapper">
        <DrawingSelectForm :editable="true" :mainHeight="600" />
      </div>
    </v-card>
  </Popup>
</template>
<script>
import Popup from "@/components/common/Popup.vue";
import DrawingSelectForm from "./components/DrawingSelectForm.vue";
export default {
  data: () => {
    return {};
  },
  props: {
    isShow: {
      type: Boolean,
    },
  },
  components: {
    Popup,
    DrawingSelectForm,
  },
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
  },
  methods: {
    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/dialog.scss";
.dialog-wrapper {
  background-color: white;
}
</style>
