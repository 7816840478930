<template>
  <v-card class="dialog">
    <div class="header sticky">
      <v-card-title small>
        <div class="title">現場監督選択</div>
        <v-spacer></v-spacer>
        <v-btn small icon text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
    </div>
    <SearchSelectList
      title_search="氏名"
      @search="searchData"
      @formUpdate="formUpdate"
      class="search-list"
    >
      <template>
        <v-data-table
          :height="items.length >= 12 ? '60vh' : ''"
          item-key="id"
          v-model="dataChoose"
          :items="items"
          :items-per-page="items.length"
          :headers="content"
          fixed-header
          hide-default-footer
          disable-sort
          class="content-table"
          show-select
          :noDataText="NO_DATA_MESSAGE"
          @click:row="(item, {select, isSelected}) => select(!isSelected)"
          @item-selected="updateSelectedItems"
          @toggle-select-all="selectAllItems"
        >
        </v-data-table>
      </template>
    </SearchSelectList>
    <div class="button-group">
      <v-row no-gutters>
        <v-col cols="9" sm="10" class="pa-3"></v-col>
        <v-col cols="3" sm="2" class="pt-3 pr-2">
          <v-btn @click="$emit('clickButton')" color="primary">決定</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import SearchSelectList from "@/components/forms/elements/SearchSelectList.vue";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import { Store } from "@/store/Store";

export default {
  components: {
    SearchSelectList
  },

  props: {
    field_construction_id: Number,
    listUser : Array
  },

  data: () => {
    return {
      keyword: "",
      NO_DATA_MESSAGE,
      items: [],
      isNoDataMessage: false,
      dataChoose: [],
      selectAll: false,
      content: [
        {
          text: "全て選択",
          align: "left",
          sortable: false,
          value: "user_name",
        },
      ]
    };
  },
  async mounted() {
    this.$watch(
      () => Store.getters["PortalChart/getListDirectorsObayashi"],
      (data) => {
        if (data) {
          this.items = [...data];
          this.items.forEach(e => {
            e.user_name = e.user_name_sei + " " + e.user_name_mei;
          });
          this.isNoDataMessage = this.items?.length == 0;
        } else {
          this.isNoDataMessage = true;
        }
      },
      {
        deep: true
      }
    );
    await this.searchData();
    this.initialDataChoose();
  },
  methods: {
    async searchData() {
      if (this.field_construction_id) {
        let params = {
          field_construction_id: this.field_construction_id,
          state_handling_flag: 0,
          user_name: this.keyword
        };
        await Store.dispatch("PortalChart/getFieldDirectorsObayashi", params);
        this.dataChoose = [];
      } else {
        this.items = [];
        this.isNoDataMessage = true;
      }
    },
    formUpdate(values) {
      this.keyword = values.keyword;
    },
    initialDataChoose(){
      this.dataChoose = this.items.filter(e => this.listUser.includes(e.id));
      this.$emit("updateChecked", this.dataChoose);
    },
    selectAllItems(value) {
      this.dataChoose = [];
      let idSet = new Set();
      if (value.value) {
        value.items.forEach((item) => {
          if (!idSet.has(item.id)) {
            this.dataChoose.push(item);
            idSet.add(item.id);
          }
        });
      }
      this.$emit("updateChecked", this.dataChoose);
    },
    updateSelectedItems(value) {
      let currentItems = value.value
        ? this.items.filter(
            (element) => element.id === value.item.id
          )
        : [];
      if (currentItems.length > 0) {
        this.dataChoose.push(...currentItems);
      }
      else {
        this.dataChoose = this.dataChoose.filter(
          (item) => item.id !== value.item.id
        );
      }
      this.$emit("updateChecked", this.dataChoose);
    },
  }
};
</script>

<style lang="scss" scoped>
.title {
  color: #1B9C4F;
}
.button-group {
  position: sticky;
  bottom: 0;
  left: 0;
  padding-bottom: 20px;
  z-index: 10000000000000;
  background-color: white;
}
.dialog .header {
    height: 65px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1000000000000000;
}
.content-table {
  z-index: 0;
}
::v-deep .v-data-table--fixed-header>.v-data-table__wrapper>table .text-start {
  padding-right: 0;
}
::v-deep .v-data-table--fixed-header>.v-data-table__wrapper>table .text-left {
  padding-left: 0;
}
</style>
