<template>
  <div class="mt-3">
    <v-container>
      <v-row v-for="(item, index) in items" :key="index">
        <v-col cols="12" sm="10" md="10" class="pt-0 pb-0">
          <Label label="専門技術者名" :editable="editable">
            <InputText
              name="technician_name"
              :values="item"
              :editable="editable"
              placeholder="夏川  二郎"
              validation_label="専門技術者名"
              validation_rules="max:255"
              @onInput="onInput({ value: $event, index })"
            />
          </Label>

          <Label label="資格" :editable="editable" />

          <Label label="試験等" :editable="editable">
            <Select
              name="exam_category"
              :values="item"
              :items="getcatergoryItems()"
              :editable="editable"
              @onInput="onInput({ value: $event, index })"
            />
          </Label>

          <!-- ロール -->
          <Label label="資格区分" :editable="editable">
            <Select
              name="exam_id"
              :values="item"
              item_text="qualification"
              :items="getSubCatergoryItems(item, 'exam_category')"
              :editable="editable"
              @onInput="onInput({ value: $event, index })"
            />
          </Label>

          <Label label="担当工事内容" :editable="editable">
            <InputText
              name="work_details"
              :values="item"
              :editable="editable"
              placeholder=""
              validation_label="担当工事内容"
              validation_rules="max:512"
              @onInput="onInput({ value: $event, index })"
            />
          </Label>
        </v-col>

        <v-col cols="12" sm="2" md="2" class="pt-0 pb-0">
          <v-btn
            v-if="index > 0 && editable"
            class="mt-8"
            icon
            @click="remove(index)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-btn @click="addEmpty" color="primary" v-if="editable">追加</v-btn>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */
import Label from "../../elements/Label.vue";
import Select from "../../elements/Select.vue";
import InputText from "../../elements/InputText.vue";
import InputDatepicker from "../../elements/InputDatepicker.vue";
import { Store } from "@/store/Store.js";

const INITIAL_VALUES = {
  technician_name: null,
  exam_id: null,
  work_details: null,
  exam_category: null,
};

export default {
  data: () => {
    return {
      cmn_mst_qualifications_construction_industries:
        Store.state.CmnMst.constants.entries
          .cmn_mst_qualifications_construction_industries,
      items: [],
    };
  },

  components: {
    Select,
    InputDatepicker,
    Label,
    InputText,
  },

  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.items = newValue[0][newValue[1]];
          if (this.items?.length == 0) {
            this.addEmpty();
          } else if (this.items != null) {
            this.items.forEach((_item) => {
              this.cmn_mst_qualifications_construction_industries.forEach(
                (element) => {
                  if (_item.exam_id) {
                    if (_item.exam_id == element.id) {
                      _item["exam_category"] = element.exam_id;
                    }
                  } else {
                    _item["exam_category"] = null;
                  }
                }
              );
            });
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      if (value.name == "exam_category") {
        _items[index].exam_id = null;
      }
      this.items = [..._items];
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      let _items = [...this.items];
      _items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    getcatergoryItems() {
      let temp = "";
      let rs = [{ id: null, name: ""}];
      this.cmn_mst_qualifications_construction_industries.forEach((element) => {
        if (temp != element.exam_id) {
          rs.push({ id: element.exam_id, name: element.exams });
          temp = element.exam_id;
        }
      });
      return rs;
    },

    getSubCatergoryItems(item, name) {
      let result = this.cmn_mst_qualifications_construction_industries.filter((e) => e.exam_id == item[name]);
      result.unshift({id: null, qualification: ""});
      return result;
    },
  },
};
</script>
