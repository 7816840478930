<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <!--  (共通) 会社ヘッダー -->
        <SitePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout :layoutParams="layoutParams" :hideFooter="searchParams.pageCount >= searchParams.total_item">
          <template #tableHeader="{ updateHeader }">
            <div>
              <ScheduleHeader
                ref="tableHeader"
                :pageTitle="PAGE_TITLE"
                :multiSelectStatus="disableApproveBtn"
                :updateHeader="updateHeader"
                :searchParams="searchParams"
                :isDirector="isDirector"
                :isShowGraphButton="false"
                @onReject="openConfirmDialog(CONFIRM_DIALOG_STATUS.REJECT)"
                @onApprove="openConfirmDialog(CONFIRM_DIALOG_STATUS.APPROVED)"
                @openItemForm="openNewItemForm"
                @openRemoveDialog="openConfirmDialog(CONFIRM_DIALOG_STATUS.DELETE)"
                @onInput="onChangeSearchParams"
              >
                <SearchFormWrapper>
                  <Label label="工事" width="200px">
                    <SearchSites
                      name="field_construction_id"
                      :fieldId="CURRENT_SITE.field_id"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>
                  <Label label="協力会社" width="200px">
                    <SearchCompanyPartner
                      name="field_tree_id"
                      :fieldId="searchParams.field_construction_id"
                      :hasGetByPlanManager="ENV_OBAYASHI && isPlanManager"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>
                  <Label label="揚重機" :editable="true" width="200px">
                    <SearchCraneName
                      name="crane_id"
                      :fieldId="CURRENT_SITE.field_id"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>
                  <TabSelect
                    class="mt-8"
                    name="status_code"
                    :items="SCHEDULE_OCCUPANCY"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />
                  <TabSelect
                    class="mt-8"
                    name="approval"
                    :items="SCHDULE_APPROVE"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />
                  <v-spacer></v-spacer>
                  <Label label="">
                    <v-btn
                      depressed
                      class="mx-2 mr-4 mt-0"
                      color="primary"
                      @click="onSearch()"
                    >
                      検索
                    </v-btn>
                  </Label>
                </SearchFormWrapper>
              </ScheduleHeader>
              <v-container class="tableSortWrapper">
                <v-spacer></v-spacer>
                <div class="sortLabel">作業時間帯:</div>
                <div class="sortElement">
                  <SelectFieldTimeZones
                    name="work_hour_zone"
                    :searchParams="searchParams"
                    :fieldId="searchParams.field_construction_id"
                    @onInput="onChangeSearchParams"
                  />
                </div>
                <div class="sortLabel">総件数:</div>
                <div class="sortElement total_item">
                  {{ searchParams.all_record }}件
                </div>
              </v-container>
            </div>
          </template>

          <!-- 
            (共通)テーブル
            v-data-table自体は共通ですが、
            カラムによって変更をしたい場合はそれぞれ実装してください。
          -->
          <template #tableBody="{ tableHeight }">
            <ScheduleGageTable
              :items="items"
              :key="resetSelected"
              :tableHeight="tableHeight"
              :functions="functions"
              :isUsage="false"
            />
          </template>

          <template #tableFooter>
            <!-- (共通) ページネーション -->
            <Pagination
              :current="searchParams.page_number"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>

    <!--  (ユーザー用) 追加/編集ダイアログ -->
    <Popup :dialog="popups.isShowItemForm">
      <ScheduleCraneFormObayashi
        v-if="ENV_OBAYASHI"
        :item="editedItem"
        :isNewItem="isNewItem"
        :dataInitial="dataInitial"
        @cancel="closeItemForm"
      />
      <ScheduleCraneForm
        v-else
        :item="editedItem"
        :isNewItem="isNewItem"
        @cancel="closeItemForm"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowConfirmDialog">
      <ConfirmRemoveDialog
        :dialogText="dialogText"
        @close="closeRemoveDialog()"
        @yes="handleConfirm()"
        :warning="dialogText.flg != CONFIRM_DIALOG_STATUS.APPROVED"
      />
    </Popup>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";

/**
 * コンポーネント、関数
 */
import ScheduleCraneForm from "@/components/forms/schedule/cranes/index"; //ユーザー登録編集フォーム
import { fmtSlashDate } from "@/utils/timeUtil"; //日付フォーマット変換で使用
import { getRollLabelById } from "@/utils/users/index"; //ロール情報の変換で使用
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import InputDatepicker from "@/components/forms/elements/InputDatepicker"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import TabSelect from "@/components/forms/elements/TabSelect"; //絞り込みフォームで使用
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
import ScheduleCraneFormObayashi from "@/components/forms/schedule/cranes/obayashi/ScheduleCraneFormObayashi";

/**
 * 固有のコンポーネント
 */
import ScheduleHeader from "../components/ScheduleHeader.vue";
import SearchCompanyPartner from "../components/SearchCompanyPartner.vue";
import SearchSites from "../components/SearchSites.vue";
import SearchCraneName from "../components/SearchCraneName.vue";
import SelectFieldTimeZones from "../components/SelectFieldTimeZones.vue"; //絞り込みフォームで使用
import ScheduleGageTable from "../components/ScheduleGageTable/index.vue";
import ConfirmRemoveDialog from "../components/ConfirmRemoveDialog.vue";

import {
  SCHEDULE_OCCUPANCY,
  SCHDULE_APPROVE,
  DIALOG_TEXT,
  CONFIRM_DIALOG_STATUS,
} from "@/constants/SCHEDULE_CRANE";

import { format } from "date-fns";
import { HEADER_MENU_ITEMS_SITE } from "@/constants/GLOBALHEADER";

/**
 * API
 */
// import { scheduleChildren } from "@/api/modules/scheduleChildren";

/**
 * 定数
 * この一覧ページで使用する固有の定数を定義します。
 * 共通に使用する定数は基本的に@/constants/で定義します。
 * - 定数は大文字で定義します
 * - 定数は基本的にはdataに代入しないで直接参照します
 */

//タイトル
const PAGE_TITLE = "揚重予定";

//追加でロードする子の予定の数
// const CHILDREN_PAGE_COUNT = 20;
const PAGE_COUNT = 25;

//ストア
const STORE = "ScheduleCrane";

//フォーム初期値
const INITIAL_ITEM = {};

const DATE = format(new Date().setDate(new Date().getDate() + 1), "yyyy-MM-dd");

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      PAGE_TITLE,
      CONFIRM_DIALOG_STATUS,

      /**
       * (共通)
       * 一覧データ
       */
      items: {},

      /**
       * (共通)
       * checkbox選択item
       */
      selectedItems: [],

      /**
       * (共通)
       * 編集アイテム
       * 定数から初期値を代入
       */
      editedItem: { ...INITIAL_ITEM },
      dataInitial : {},

      /**
       * (共通)
       * 新規フラグ
       */
      isNewItem: false,

      // 検索パラメータ
      searchParams: {
        date: DATE, //初期値は今日
        field_construction_id: null, //会社id
        field_tree_id: null, //作業場所id
        crane_id: null, //時間帯id
        status_code: [], //契約
        approval: [], //承認
        page_number: 1,
        work_hour_zone: null,
        pageCount: PAGE_COUNT,
        all_record: 0,
      },

      /**
       * (共通)
       * ポップアップの状態管理
       */
      popups: {
        // 追加/編集フォーム
        isShowItemForm: false,
        isShowConfirmDialog: false,
      },

      isDirector: false,

      /**
       * 絞り込み 協力会社検索パラメータ
       */
      searchCompanyParams: {
        keyword: "",
      },

      /**
       * childテーブルの表示数デフォルト
       */
      childTableDisplayNumber: 3,

      /**
       * まとめてメソッドを子コンポーネントに渡す
       */
      functions: {
        updateSelectedItems: this.updateSelectedItems,
        openItemForm: this.openItemForm,
        getChildItems: this.getChildItems,
        addNewChild: this.addNewChild,
        resetList: this.getItems,
      },

      /**
       * dialogText
       */
      dialogText: {
        title: "",
        text1: "",
        text2: "",
        btnSubmitText: "",
        flg: null,
        isError: false,
      },
      resetSelected: false,
      ENV_CLIENT,
      isPlanManager: false,
      firstLoad: true,
    };
  },

  components: {
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    SitePortalHeader,
    Popup,
    ConfirmRemoveDialog,
    ScheduleCraneForm,
    InputText,
    InputDatepicker,
    Select,
    Label,

    //固有のコンポーネント
    ScheduleHeader,
    TabSelect,
    SearchCompanyPartner,
    SearchCraneName,
    SearchSites,
    SelectFieldTimeZones,
    ScheduleGageTable,
    ScheduleCraneFormObayashi
  },

  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    await Store.dispatch("GlobalHeader/setSiteMenu", {
      menuId: HEADER_MENU_ITEMS_SITE.SCHEDULE.id,
    });

    this.$watch(
      () => Store.getters["Employees/getIsDirector"],
      (data) => {
        this.isDirector = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Employees/getIsPlanManager"],
      (data) => {
        this.isPlanManager = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        // if is data old in store then not show
        if (this.firstLoad) {
          this.firstLoad = false;
          return;
        }
        
        let searchParams = { ...this.searchParams };
        /**
         *  (共通)
         * 一覧リストの格納
         */
        this.items = data[0];
        this.resetSelected = !this.resetSelected;
        searchParams.work_hour_zone = data[0].time_frame_id;
        searchParams.date = data[0].date;
        /**
         *  (共通)
         * ページネーション更新
         */
        searchParams.totalPage = data[1].total;
        searchParams.page_number = data[1].current;
        searchParams.total_item = data[1].total_item;
        searchParams.all_record = data[1].all_record;
        this.searchParams = searchParams;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */
  computed: {
    /**
     * 承認・承認解除ボタンの活性・非活性
     * selectedItems.length === 0
     * 自体はtemplate側で記述できますが、
     * ここに明示的に状態を定義して可読性を担保してます
     */
    disableApproveBtn() {
      return this.selectedItems.length === 0;
    },

    /**
     * (スケジュール)
     * 絞り込み:契約項目
     */
    SCHEDULE_OCCUPANCY() {
      return Object.keys(SCHEDULE_OCCUPANCY).map((key) => {
        return SCHEDULE_OCCUPANCY[key];
      });
    },

    /**
     *  (スケジュール)
     * 絞り込み:承認/未承認
     */
    SCHDULE_APPROVE() {
      return Object.keys(SCHDULE_APPROVE).map((key) => {
        return SCHDULE_APPROVE[key];
      });
    },

    /**
     * (スケジュール)
     * 現在の現場を取得
     */
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },

    ENV_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    }
  },

  methods: {
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.page_number = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 検索
     */
    onSearch() {
      this.searchParams.page_number = 1;
      this.getItems();
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      if (name === "date") {
        searchParams[name] = format(new Date(value), "yyyy-MM-dd");
      } else {
        searchParams[name] = value;
      }
      this.searchParams = searchParams;
      if (name == "field_construction_id") {
        this.callApiCheckIsDirector();
      }
      if (name == "work_hour_zone" || name == "date") {
        this.searchParams["page_number"] = 1;
        this.getItems();
      }
    },

    async callApiCheckIsDirector() {
      const params = {
        user_id: JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login
          .company_user.user_id,
        field_construction_id: this.searchParams.field_construction_id,
      };
      await Store.dispatch("Employees/checkIsDirector", { params });
    },

    /**
     * (共通)
     * 作成/編集フォーム
     * INITIAL_ITEM で定義した値がitemに入ります
     * @param INITIAL_ITEMで定義している値
     */
    openNewItemForm() {
      this.getDataSelect();
      let initial_item = { ...INITIAL_ITEM };
      initial_item.use_date_from = this.searchParams.date;
      initial_item.use_date_to = this.searchParams.date;
      initial_item.field_construction_id = this.searchParams.field_construction_id;
      initial_item.work_hour_zone = this.searchParams.work_hour_zone;
      this.editedItem = { ...initial_item };
      this.dataInitial = { ...initial_item };
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },
    openItemForm(schedule_id) {
      const item = {
        schedule_id,
      };
      this.getDataSelect();
      this.editedItem = { ...item };
      this.isNewItem = false;
      this.popups.isShowItemForm = true;
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.$nextTick(() => {
        //選択を初期化
        this.editedItem = { ...INITIAL_ITEM };
        this.dataInitial = {};
      });
      this.getItems();
    },

    /**
     * 1: 承認解除
     * 2: 承認
     * 3: Delete
     */
    openConfirmDialog(flg) {
      if (flg == CONFIRM_DIALOG_STATUS.REJECT) {
        const {
          REJECT_BTN_SUBMIT,
          REJECT_TEXT2,
          REJECT_TEXT1,
          REJECT_TITLE,
          REJECT_ERROR_TEXT1,
          ERROR_BTN,
        } = DIALOG_TEXT;
        const disapproves = this.selectedItems.filter(
          (item) => item.schedule_approval == CONFIRM_DIALOG_STATUS.REJECT
        );
        const isAllDisApproved =
          disapproves?.length == this.selectedItems.length;
        this.dialogText.title = REJECT_TITLE;
        this.dialogText.text1 = isAllDisApproved
          ? REJECT_ERROR_TEXT1
          : REJECT_TEXT1;
        this.dialogText.text2 = isAllDisApproved ? "" : REJECT_TEXT2;
        this.dialogText.btnSubmitText = isAllDisApproved
          ? ERROR_BTN
          : REJECT_BTN_SUBMIT;
        this.dialogText.isError = isAllDisApproved ? true : false;
      } else {
        const approves = this.selectedItems.filter(
          (item) => item.schedule_approval == CONFIRM_DIALOG_STATUS.APPROVED
        );
        const isAllApproved = approves?.length == this.selectedItems.length;
        if (flg == CONFIRM_DIALOG_STATUS.APPROVED) {
          const {
            APPROVE_TITLE,
            APPROVE_TEXT1,
            APPROVE_TEXT2,
            APPROVE_BTN_SUBMIT,
            APPROVE_ERROR_TEXT1,
            ERROR_BTN,
          } = DIALOG_TEXT;
          this.dialogText.title = APPROVE_TITLE;
          this.dialogText.text1 = isAllApproved
            ? APPROVE_ERROR_TEXT1
            : APPROVE_TEXT1;
          this.dialogText.text2 = isAllApproved ? "" : APPROVE_TEXT2;
          this.dialogText.btnSubmitText = isAllApproved
            ? ERROR_BTN
            : APPROVE_BTN_SUBMIT;
        } else {
          const {
            DELETE_BTN_SUBMIT,
            DELETE_TEXT2,
            DELETE_TEXT1,
            DELETE_TITLE,
            ERROR_BTN,
            DELETE_ERROR_TEXT2,
            DELETE_ERROR_TEXT1,
          } = DIALOG_TEXT;
          this.dialogText.title = DELETE_TITLE;
          this.dialogText.text1 = isAllApproved
            ? DELETE_ERROR_TEXT1
            : DELETE_TEXT1;
          this.dialogText.text2 = isAllApproved
            ? DELETE_ERROR_TEXT2
            : DELETE_TEXT2;
          this.dialogText.btnSubmitText = isAllApproved
            ? ERROR_BTN
            : DELETE_BTN_SUBMIT;
        }
        this.dialogText.isError = isAllApproved ? true : false;
      }
      this.dialogText.flg = flg;
      this.popups.isShowConfirmDialog = true;
    },

    /**
     * handleConfirm
     */
    handleConfirm() {
      if (this.dialogText.flg == CONFIRM_DIALOG_STATUS.APPROVED) {
        this.approveItems();
      } else if (this.dialogText.flg == CONFIRM_DIALOG_STATUS.REJECT) {
        this.rejectItems();
      } else {
        this.removeItems();
      }
    },

    /**
     * closeRemoveDialog
     */
    closeRemoveDialog() {
      this.popups.isShowConfirmDialog = false;
      this.$nextTick(() => {
        this.dialogText = {
          title: "",
          text1: "",
          text2: "",
          btnSubmitText: "",
          flg: "",
        };
        this.selectedItems = [];
        this.resetSelected = !this.resetSelected;
      });
    },

    /**
     * 親ステートの子テーブルを更新
     */
    async getChildItems(deviceId, pageNumber) {
      const {
        status_code,
        approval,
        field_construction_id,
        work_hour_zone,
        date,
        page_number,
        field_tree_id,
      } = { ...this.searchParams };
      const params = {};
      params["status_codes"] = status_code;
      params["approvals"] = approval;
      params["use_date"] = date;
      params["field_construction_id"] = field_construction_id;
      params["work_hour_zone"] = work_hour_zone;
      params["page_number"] = page_number;
      params["field_tree_id"] = field_tree_id;
      params["page_number"] = pageNumber;
      params["crane_id"] = deviceId;
      if (this.ENV_OBAYASHI) params["is_oba"] = true;
      await Store.dispatch(`${STORE}/loadChildren`, params);
    },

    /**
     *  (共通)
     * ストア / api
     * ストア実装ルールにしたがっている場合は、
     */

    // データ取得
    async getItems() {
      const {
        status_code,
        approval,
        field_construction_id,
        work_hour_zone,
        date,
        page_number,
        field_tree_id,
        crane_id,
      } = { ...this.searchParams };
      const params = {};
      params["status_codes"] = status_code;
      params["approvals"] = approval;
      params["use_date"] = date ? date : DATE;
      params["field_construction_id"] = field_construction_id;
      params["work_hour_zone"] = work_hour_zone;
      params["page_number"] = page_number;
      params["field_tree_id"] = field_tree_id;
      params["crane_id"] = crane_id;
      if (this.ENV_OBAYASHI) params["is_oba"] = true;
      
      if (field_construction_id && work_hour_zone && params["use_date"]) {
        await Store.dispatch(`${STORE}/get`, { params });
      }
    },
    getDataSelect() {
      let params = {
        field_id: this.CURRENT_SITE.field_id,
        field_construction_id: this.searchParams.field_construction_id
      };
      Store.dispatch("PortalChart/getListItemTrees", { params });
      Store.dispatch(
        "CompanyPortal/getListCompanyByField",
        this.CURRENT_SITE.field_id
      );
    },

    // 承認
    async approveItems() {
      const approves = this.selectedItems.filter(
        (item) => item.schedule_approval == CONFIRM_DIALOG_STATUS.REJECT
      );
      const ids = approves.map((items) => items.id);
      if (ids?.length > 0) {
        let params = {
          ids: ids,
          field_construction_id: this.searchParams.field_construction_id,
        };
        if (this.ENV_OBAYASHI) params.is_oba = true;
        const result = await Store.dispatch(`${STORE}/approve`, params);

        if (!result.hasError) {
          this.selectedItems = [];
          this.resetSelected = !this.resetSelected;
          this.closeRemoveDialog();
          this.getItems();
          Store.dispatch("Notification/getNewNoticeStatus");
          Store.dispatch("Toast/show", {
            status: 200,
            message: "承認しました",
          });
        } else {
          // TODO
          this.closeRemoveDialog();
        }
      }
    },

    // 承認解除
    async rejectItems() {
      const disapproves = this.selectedItems.filter(
        (item) => item.schedule_approval == CONFIRM_DIALOG_STATUS.APPROVED
      );
      const ids = disapproves.map((items) => items.id);
      if (ids?.length > 0) {
        let params = {
          ids: ids,
          field_construction_id: this.searchParams.field_construction_id,
        };
        if (this.ENV_OBAYASHI) params.is_oba = true;
        const result = await Store.dispatch(`${STORE}/disapprove`, params);

        if (!result.hasError) {
          this.selectedItems = [];
          this.resetSelected = !this.resetSelected;
          this.closeRemoveDialog();
          this.getItems();
          Store.dispatch("Notification/getNewNoticeStatus");
          Store.dispatch("Toast/show", {
            status: 200,
            message: "承認解除しました",
          });
        }
      } else {
        // TODO
        this.closeRemoveDialog();
      }
    },

    // 削除
    async removeItems() {
      const approves = this.selectedItems.filter(
        (item) => item.schedule_approval == CONFIRM_DIALOG_STATUS.REJECT
      );
      const ids = approves.map((items) => items.id);
      let params = this.ENV_OBAYASHI ? { ids, is_oba: true } : { ids };
      const result = await Store.dispatch(`${STORE}/delete`,  params );

      if (!result.hasError) {
        this.searchParams.page_number = 1;
        this.selectedItems = [];
        this.resetSelected = !this.resetSelected;
        this.closeRemoveDialog();
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
      }
    },

    /**
     *  (ユーザー用)
     * ユーザ権限名文字列取得
     * @param id:Number
     */
    getRollLabel(id) {
      return getRollLabelById(id);
    },

    /**
     *  (ユーザー用)
     * 日付変換
     * @param date:yyyy-mm-ddThh:mm:ss:z
     */
    getFormattedDate(date) {
      return fmtSlashDate(date);
    },

    /**
     * checkboxの値からselectedItemsを更新
     */
    updateSelectedItems(list) {
      list.forEach((item) => {
        this.updateSelectedItem(item);
      });
    },
    updateSelectedItem({ id, isChecked, schedule_approval }) {
      let _selectedItems = [...this.selectedItems];
      // checked
      if (isChecked) {
        const isNew = this.selectedItems.some((item) => item.id == id);
        if (!isNew)
          _selectedItems = [..._selectedItems, { id, schedule_approval }];
      } else {
        // unchecked
        _selectedItems = this.selectedItems.filter((item) => {
          return item.id != id;
        });
      }
      // 重複削除してset
      this.selectedItems = [...new Set(_selectedItems)];
    },

    // checkboxの ON/OFFステータス
    isSelected(id) {
      return this.selectedItems.includes(id);
    },

    // 親にchild table追加
    addNewChild(parentId) {
      // 新規追加itemに parent idをセット
      this.editedItem.schedule_parent_id = parentId;
      this.openNewItemForm();
    },
  },
  beforeDestroy() {
    Store.dispatch(`${STORE}/resetData`)
  }
};
</script>
<style lang="scss" scoped>
.tableSortWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 64px;
  .sortLabel {
    font-size: 12px;
    height: 32px;
    margin-right: 16px;
  }
  .sortElement {
    max-width: 168px;
    margin-right: 24px;
  }
  .total_item {
    padding-bottom: 15px;
  }
}
</style>
