<template>
  <v-container>
    <v-data-table
      v-model="selectedItems"
      :headers="INSPECTION_LABELS_FORM1"
      :items="dataTable"
      hide-default-footer
      disable-sort
      :height="mainHeight"
      :items-per-page="dataTable.length"
      class="elevation-0 v-data-table__wrapper"
      show-select
      @click:row="openItemForm"
      :noDataText="NO_DATA_MESSAGE"
    >
      <template v-slot:[`item.status`]="{ item }">
        <div style="min-width: 100px;">
          <v-chip
            text-color="black"
            medium
            :color="item.status == APPROVE_STATUS.UNAPPROVE.id ? 'orange' : 'rgb(50, 195, 108)'"
            >{{ item.status == APPROVE_STATUS.UNAPPROVE.id ? APPROVE_STATUS.UNAPPROVE.name : APPROVE_STATUS.APPROVE.name }}
          </v-chip>
        </div>
      </template>
      <template v-slot:[`item.overall_condition`]="{ item }">
        <div style="font-size: 18px">
          {{convertOverall(item.overall_condition)}}
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { Store } from "@/store/Store.js";
import { NO_DATA_MESSAGE } from "@/constants/COMMON.js";
import { INSPECTION_LABELS_FORM1, OVERALL_CONDITION_STATUS } from "@/constants/MACHINE_FIELD";
import { APPROVE_STATUS } from "@/constants/DEVICE_STOCK";
const STORE = "MachineFieldInspection";
export default {
  data: () => {
    return {
      NO_DATA_MESSAGE,
      INSPECTION_LABELS_FORM1,
      OVERALL_CONDITION_STATUS,
      dataTable: [],
      APPROVE_STATUS,
      selectedItems: [],
    }
  },
  props: {
    item: Array,
    mainHeight: {
      type: Number,
      default: 0
    },
    isResetSelected: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$watch(
      () => this.item,
      (data, oldData) => {
        if (data !== oldData) this.dataTable = [...data];
      },
      {
        immediate: true,
        deep: true,
      }
    );
    
    this.$watch(
      () => this.isResetSelected,
      (data, oldData) => {
        if (data !== oldData && data) {
          this.selectedItems = [];
          this.$emit('resetFlagSelected');
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.selectedItems,
      (data, oldData) => {
        if (data !== oldData) {
          this.$emit('selectedItems', [...data]);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    convertOverall(result) {
      let rs = OVERALL_CONDITION_STATUS.NEED_REPAIR.text;
      if (result == OVERALL_CONDITION_STATUS.IN_REPAIR.value) {
        rs = OVERALL_CONDITION_STATUS.IN_REPAIR.text;
      } else if (result == OVERALL_CONDITION_STATUS.GOOD.value) {
        rs = OVERALL_CONDITION_STATUS.GOOD.text;
      }
      return rs;
    },
    /**
     * open item form details
     */
    async openItemForm(item) {
      const rs = await Store.dispatch(`${STORE}/getInspecResultDetail`, item.id);
      if (!rs.hasError) {
        this.$emit('isShowFormInspecDetail', item);
      } else {
        Store.dispatch("Error/show", {
          status: 200,
          message: rs.response.data.message,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
@media only screen and (max-width: 860px) {
  ::v-deep .v-data-table .v-data-table__wrapper table thead tr:last-child th:nth-child(2) {
    width: 15vw !important;
    min-width: 15vw !important;
  }
}
</style>
