import instance from "@/api/api";

const request = async (params, callback) => {
  const URL = `${process.env.VUE_APP_API_BASE_URL}/users/get_user_by_email`;
  const result = await instance.get(URL, { params });
  if (!result.hasError) {
    const email = result.data.contents.entries;
    if (!email) {
      callback(null);
      return;
    }
    const { name_sei, name_mei } = email;

    callback({
      name_sei: name_sei,
      name_mei: name_mei,
    });
    return;
  }
  callback(null);
};

const _emailSearch = () => {
  let timeout = null;
  const search = async (params) => {
    return new Promise((resolved) => {
      const email = params.email;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        request({ email }, (vals) => {
          console.log(vals);
          resolved(vals);
        });
      }, 500);
    });
  };
  return {
    search,
  };
};

export const emailSearch = _emailSearch();