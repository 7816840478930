<template>
  <div>
    <v-btn v-if="IS_OBAYASHI && !checkFieldTreeDate" :disabled="this.selectedItems.length === 0 || !isRoleMain" color="primary" class="mt-3" @click="isShowPopupClassify = true">種別設定</v-btn>
    <v-btn :disabled="this.selectedItems.length === 0 || !isRoleMain" v-if="!checkFieldTreeDate" color="primary" class="mt-3 ml-3" @click="isShowPopupPosition = true">役職設定</v-btn>
    <div :style="getBodyStyle">
      <TableLayout
        :layoutParams="getLaytouParams"
        :hideHeader="true"
        :hideFooter="true"
      >
        <template #tableHeader="{ updateHeader }">
          <TableHeader
            ref="tableHeader"
            pageTitle=""
            :multiRemoveStatus="false"
            :updateHeader="updateHeader"
          >
          </TableHeader>
        </template>
        <!--
              (共通)テーブル
              v-data-table自体は共通ですが、
              カラムによって変更をしたい場合はそれぞれ実装してください。
            -->
        <template #tableBody="{ tableHeight }">
          <v-data-table
            v-model="selectedItems"
            :headers="IS_OBAYASHI ? FORM_MANAGERS_LABELS_OBAYASHI : FORM_MANAGERS_LABELS"
            :items="items"
            :height="tableHeight - 50"
            :items-per-page="itemsPerPage"
            fixed-header
            hide-default-footer
            disable-sort
            :show-select="!checkFieldTreeDate"
            class="elevation-0 v-data-table__wrapper"
            sort-by="updatedAt"
            :noDataText="NO_DATA_MESSAGE"
          >
          </v-data-table>
        </template>
      </TableLayout>
    </div>
    <Popup width="480px" :dialog="isShowPopupClassify">
      <PopupClassify
        @close="closePopupClassify"
        @chooseType="chooseType"
        :nameSelected="nameSelected"
      />
    </Popup>
    <Popup width="480px" :dialog="isShowPopupPosition">
      <PopupPosition
        @close="closePopupPosition"
        @choosePosition="choosePosition"
        :positionSelected="positionSelected"
        :flagUpdatePosition="flagUpdatePosition"
      />
    </Popup>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";

import { FORM_MANAGERS_LABELS, FORM_MANAGERS_LABELS_OBAYASHI } from "@/constants/PORTAL_CHART.js";
import { NO_DATA_MESSAGE , ROLE_SUPERVISOR} from "@/constants/COMMON.js";
import PopupClassify from "../../components/PopupClassify.vue";
import PopupPosition from "../../components/PopupPosition.vue";
import Popup from "@/components/common/Popup.vue";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
import { Store } from "@/store/Store.js";
/**
 * 定数
 * この一覧ページで使用する固有の定数を定義します。
 * 共通に使用する定数は基本的に@/constants/で定義します。
 * - 定数は大文字で定義します
 * - 定数は基本的にはdataに代入しないで直接参照します
 */

//テーブルヘッダーラベル

export default {
  data() {
    return {
      /**
       * (共通)
       */
      FORM_MANAGERS_LABELS,
      FORM_MANAGERS_LABELS_OBAYASHI,
      NO_DATA_MESSAGE,

      /**
       * (共通)
       * 一覧データ
       */
      items: [],
      selectedItems : [],
      isShowPopupClassify : false,
      nameSelected: null,
      positionSelected : null,
      flagUpdatePosition : false,
      isShowPopupPosition: false,
    };
  },

  components: {
    //共通のコンポーネント
    TableLayout,
    TableHeader,
    Popup,
    PopupClassify,
    PopupPosition
  },

  props: {
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    item: Object,
    field_construction_id: Number,
    checkFieldTreeDate: Boolean,
  },

  async mounted() {
    this.$watch(
      () => this.mainHeight,
      (newVal) => {
        console.log("mainHeight", newVal);
      },
      { immediate: true, deep: true }
    );

    this.$watch(
      () => this.item,
      (newValue) => {
        let items = newValue?.site_director?.field_tree_users;
        items?.forEach(item => {
          item['name'] = item['name_sei'] + " " + item['name_mei'];
          item['groups'] = item['field_tree_groups']?.map(element => element['group_name']).join();
        })
        this.items = items;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.selectedItems,
      (newVal) => {
        if(newVal){
          this.nameSelected = newVal.length? newVal[0].type: "";
          this.positionSelected = newVal.length ? newVal[0].position_safety : "";
        }
      },
      { immediate: true, deep: true }
    );
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */
  computed: {
    IS_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
    getBodyStyle() {
      return `height:${this.mainHeight - 30}px;`;
    },
    getLaytouParams() {
      return { pageHeight: this.mainHeight};
    },
    itemsPerPage() {
      return this.items ? this.items.length : 0;
    },
    isRoleMain(){
      return Store.getters["Sites/getRole"] == ROLE_SUPERVISOR;
    }
  },
  methods:{
    closePopupClassify() {
      this.isShowPopupClassify = false;
      this.selectedItems = [];
    },
    async chooseType(id){
      let params = {
        field_construction_id: this.field_construction_id,
        user_id: this.selectedItems.map(item => item.user_id),
        type: id,
      }
      const res = await Store.dispatch('PortalChart/updateUpdateLevelApproval', params);
      if (res.hasError) {
        this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 200,
          message: res.data.response.message,
        });
      } else {
        this.isShowPopupClassify = false;
        let params = {
          field_tree_id: this.item.field_tree.id,
          is_oba: 1,
        }
        await Store.dispatch("PortalChart/getOwnerCompany", {params});
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        this.selectedItems = [];
      }
    },
    closePopupPosition(){
      this.isShowPopupPosition = false;
      this.selectedItems = [];
    },
    async choosePosition(id){
      let params = {
        field_construction_id: this.field_construction_id,
        user_id: this.selectedItems.map(item => item.user_id),
        position_safety: id
      };
      const res = await Store.dispatch('PortalChart/updatePositionSafety', params);
      if (res.hasError) {
        Store.dispatch("Error/show", {
          status: 200,
          message: res.data.response.message,
        });
        this.flagUpdatePosition = !this.flagUpdatePosition;
      } else {
        this.closePopupPosition();
        let params = { field_tree_id: this.item.field_tree.id };
        if (this.IS_OBAYASHI) params.is_oba = 1;
        await Store.dispatch("PortalChart/getOwnerCompany", { params });
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました"
        });
      }
    },
  }

};
</script>