import Api, { Mock } from "../api";
import mock from "./mock/safety_chart.json";
import primes_reports from "./mock/primes_reports.json";
import partners_reports from "./mock/partner_reports.json";

const URL_CHART = `${process.env.VUE_APP_API_BASE_URL}/reports/get_tree`; //体制図の取得
const URL_GET_PRIME_REPORTS = `${process.env.VUE_APP_API_BASE_URL}/reports/detail_prime`;
const URL_GET_PARTNER_REPORTS = `${process.env.VUE_APP_API_BASE_URL}/reports/detail_partner`; // 帳票データ
const URL_UPDATE_STATUS = `${process.env.VUE_APP_API_BASE_URL}/reports/update_status`; //差し戻し
const URL_CREATE_NOTICE_OF_CONTRUCTION_REPORT = `${process.env.VUE_APP_API_BASE_URL}/reports/create_notice_of_construction`;
const URL_CREATE_NOTICE_OF_COMPANY_PARNER = `${process.env.VUE_APP_API_BASE_URL}/reports/create_notification`;
const URL_CREATE_WORKER_ROSTER_REPORT = `${process.env.VUE_APP_API_BASE_URL}/reports/create_worker_roster`;
const URL_CREATE_FOREIGN_ENTRY_NOTIFICATION_REPORT = `${process.env.VUE_APP_API_BASE_URL}/reports/create_foreign_entry_notification`;
const URL_CREATE_RE_SUBCONTRACT_NOTICE_REPORT = `${process.env.VUE_APP_API_BASE_URL}/reports/create_re_subcontract_notice`;
const URL_CREATE_COMMITMENT = `${process.env.VUE_APP_API_BASE_URL}/reports/create_commitment`;
const URL_CREATE_SUBCONTRACT_REPORT = `${process.env.VUE_APP_API_BASE_URL}/reports/create_subcontract`;
const URL_CREATE_CONSTRUCTION_SYSTEM_LEDGER_REPORT = `${process.env.VUE_APP_API_BASE_URL}/reports/create_construction_system_ledger`;
const URL_CREATE_CONSTRUCTION_STRUCTURE_REPORT = `${process.env.VUE_APP_API_BASE_URL}/reports/create_construction_structure`;
const URL_CREATE_ENTRANCE_QUESTIONAIRE_REPORT = `${process.env.VUE_APP_API_BASE_URL}/reports/create_entrance_questionnaire`;
const URL_CREATE_ENTRANCE_EDUCATION_DOC_REPORT = `${process.env.VUE_APP_API_BASE_URL}/reports/create_entrance_education_document`;
const URL_GENERATE_ZIP = `${process.env.VUE_APP_API_BASE_URL}/reports/download_all`; //(元請け)プレビュー作成
const URL_CREATE_PARTNER_DOCUMENT = `${process.env.VUE_APP_API_BASE_URL}/reports/create_partner_documents`;
const URL_CREATE_CONSTRUCTION_DOC_SYSTEM_REPORT = `${process.env.VUE_APP_API_BASE_URL}/reports/create_construction_system_ledger_management_table`;

/**
 * Mockで動作させる手順
 *
 * 1.apiをapiForceMockにかえる
 * import Api, { Mock } from "../api";
 * ↓
 * import Api, { Mock } from "../apiForceMock";
 *
 * 2.VUE_APP_NODE_ENV=develop でビルド
 * 3.ログイン
 * 4.現場を選択
 * 5. http://localhost:8000/safety/chart を入力
 *
 *
 */

if (Mock) {
  //体制図
  //TODO 初期値は work_type_id を nullなのでリクエスト -> nullの時は一番新しい工事を返す

  Mock.onGet(new RegExp(`${URL_CHART}/[\\d]+`)).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: mock,
  });

  Mock.onGet(new RegExp(`${URL_GET_PRIME_REPORTS}/[\\d]+`)).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: primes_reports,
  });

  Mock.onGet(new RegExp(`${URL_GET_PARTNER_REPORTS}/[\\d]+`)).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: partners_reports,
  });

  Mock.onPost(URL_UPDATE_STATUS).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {},
  });

  Mock.onPost(URL_CREATE_NOTICE_OF_CONTRUCTION_REPORT).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {},
  });

  Mock.onPost(URL_CREATE_NOTICE_OF_COMPANY_PARNER).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {},
  });

  Mock.onPost(URL_CREATE_WORKER_ROSTER_REPORT).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {},
  });

  Mock.onPost(URL_CREATE_FOREIGN_ENTRY_NOTIFICATION_REPORT).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {},
  });

  Mock.onPost(URL_CREATE_RE_SUBCONTRACT_NOTICE_REPORT).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {},
  });

  Mock.onPost(URL_CREATE_COMMITMENT).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {},
  });

  Mock.onPost(URL_CREATE_SUBCONTRACT_REPORT).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {},
  });

  Mock.onPost(URL_CREATE_CONSTRUCTION_SYSTEM_LEDGER_REPORT).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {},
  });
  
  Mock.onPost(URL_CREATE_CONSTRUCTION_STRUCTURE_REPORT).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {},
  });

  Mock.onPost(URL_CREATE_ENTRANCE_QUESTIONAIRE_REPORT).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {},
  });

  Mock.onPost(URL_CREATE_ENTRANCE_EDUCATION_DOC_REPORT).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {},
  });

  Mock.onGet(URL_GENERATE_ZIP).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {},
  });

  Mock.onPost(URL_CREATE_PARTNER_DOCUMENT).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: {},
  });
}

export const chart = {
  /**
   *
   * 体制図取得
   * @param {
   *   site_id:"現場id",
   * }
   */
  get: (params) => {
    return Api.get(URL_CHART, params);
  },

  getPrimeReports: (params) => {
    return Api.get(URL_GET_PRIME_REPORTS, params);
  },

  getPartnerReports: (params) => {
    return Api.get(`${URL_GET_PARTNER_REPORTS}/${params}`);
  },

  /**
   * (1次請け)差し戻し
   * @param {Object} params {chartId, form_status_code ,message}
   * @returns
   */
   updateSatus: (params) => {
    return Api.post(URL_UPDATE_STATUS, params);
  },

  /**
   * (２次以下) 帳票作成＆提出
   * @param {Object} params {chartId}
   * @returns
   */
  createNoticeOfContructionReport: (params) => {
    return Api.post(URL_CREATE_NOTICE_OF_CONTRUCTION_REPORT, params);
  },

  /**
   * (1次以下) 提出
   * @param {Object} params {gf_report_id}
   * @returns
   */
  createNoticeOfCompanyParner: (params) => {
    return Api.post(URL_CREATE_NOTICE_OF_COMPANY_PARNER, params);
  },

  /**
   * (元請け) 帳票作成
   * @param {Object} params {chartId}
   * @returns
   */
  createWorkerRosterReport: (params) => {
    return Api.post(URL_CREATE_WORKER_ROSTER_REPORT, params);
  },

  createForeignEntryNotificationReport: (params) => {
    return Api.post(URL_CREATE_FOREIGN_ENTRY_NOTIFICATION_REPORT, params);
  },

  createReSubcontractNoticeReport: (params) => {
    return Api.post(URL_CREATE_RE_SUBCONTRACT_NOTICE_REPORT, params);
  },

  createCommitment: (params) => {
    return Api.post(URL_CREATE_COMMITMENT, params);
  },

  createSubcontractReport: (params) => {
    return Api.post(URL_CREATE_SUBCONTRACT_REPORT, params);
  },

  createConstructionSystemLedgerReport: (params) => {
    return Api.post(URL_CREATE_CONSTRUCTION_SYSTEM_LEDGER_REPORT, params);
  },

  createConstructionStructureReport: (params) => {
    return Api.post(URL_CREATE_CONSTRUCTION_STRUCTURE_REPORT, params);
  },

  createEntranceQuestionnaireReport:(params) => {
    return Api.post(URL_CREATE_ENTRANCE_QUESTIONAIRE_REPORT, params);
  },

  createEntranceEducationDocReport: (params) => {
    return Api.post(URL_CREATE_ENTRANCE_EDUCATION_DOC_REPORT, params);
  },

  createConstructionDocSystemReport: (params) => {
    return Api.post(URL_CREATE_CONSTRUCTION_DOC_SYSTEM_REPORT, params);
  },

  /**
   * zip作成
   * @param {Object} params {chartId}
   * @returns
   */
  generateZip: (params) => {
    return Api.get(`${URL_GENERATE_ZIP}/${params}`);
  },
  
  createPartnerDocument: (params) => {
    return Api.post(URL_CREATE_PARTNER_DOCUMENT, params);
  },
};
