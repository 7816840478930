<template>
  <div class="mt-5 mb-cst">
    <!-- List button -->
    <template>
      <v-btn
        depressed
        class="mx-3"
        color="primary"
        v-if="roleUser != ROLE_USER[4]"
        @click="openNewFormApplication()"
      >
        物品申請
      </v-btn>
      <v-btn
        depressed
        class="mx-3"
        color="primary"
        :disabled="isDisabledBtn"
        @click="openFormRequestEstimate()"
        v-if="roleUser == ROLE_USER[3]"
      >
        見積依頼
      </v-btn>
      <v-btn
        depressed
        class="mx-3"
        color="primary"
        :disabled="isDisabledBtn"
        @click="openFormRequestOrder()"
        v-if="roleUser == ROLE_USER[3]"
      >
        即時発注
      </v-btn>
      <v-btn
        class="mx-3"
        small
        icon
        :disabled="isDisabledBtn"
        @click="openRemoveDialog()"
        v-if="roleUser != ROLE_USER[4]"
      >
        <v-icon> mdi-delete </v-icon>
      </v-btn>
    </template>
    <!-- parent table -->
    <v-data-table
      hide-default-header
      :headers="tableLabels"
      v-model="selectedItems"
      :items="dataTable"
      :items-per-page="itemsPerPage"
      :height="tableHeight - 40"
      fixed-header
      hide-default-footer
      disable-sort
      :show-select="true"
      class="elevation-0 v-data-table__wrapper"
      sort-by="updatedAt"
      item-key="machine_company_field_application_id"
      :noDataText="NO_DATA_MESSAGE"
      :item-class="displayRecord"
      @click:row="openItemForm"
    >
      <template slot="header" :headers="tableLabels">
        <thead>
          <tr>
            <th style="width: 50px">
              <v-simple-checkbox
                :value="selectedItems.length === dataTable.length && dataTable.length > 0"
                @input="clickCheckAll($event)"
              ></v-simple-checkbox>
            </th>
            <th v-for="(h, index) in tableLabels" :key="h.value" :style="getWidthStyle(index)" >
              <div v-for="text in convertTextHeaderArr(h.text)" :key="text">
                <div>{{ text }}</div>
              </div>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:[`item.application_status`]="{ item }" label small>
        <v-chip small
          :color="setColor(item.application_status)"
          :text-color="setTextColor(item.application_status)"
          >{{ getStatusValue(item.application_status) }}</v-chip>
      </template>
      <template
        v-slot:[`item.machine_company_type_items_extral_machine_companies`]="{ item }"
         label
         small>
        <div class="cst-overflow-td">
          {{ item.machine_company_type_item_tree_name }}
        </div>
        <div class="cst-overflow-td">{{ item.machine_name }}</div>
      </template>
      <template
        v-slot:[`item.company_branches_extral_users`]="{ item }"
        label
        small
      >
        <div class="cst-overflow-td">
          {{ item.created_company_name }}
        </div>
        <div class="cst-overflow-td">{{ item.created_user_name }}</div>
      </template>
      <template
        v-slot:[`item.used_start_date_extral_used_end_date`]="{ item }"
        label
        small
      >
        <div class="cst-overflow-td">{{ item.used_start_date }}</div>
        <div class="cst-overflow-td">{{ item.used_end_date }}</div>
      </template>
    </v-data-table>

    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        :flagError="flagError"
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :selectedItems="delItems"
        warning
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowRemoveDialogOption">
      <ComfirmRemoveDialogOption
        @yes="closeRemoveItems()"
        title="選択項目の削除"
        text="他社の申請を削除することができません。"
        warning
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowFormRequestEstimate">
      <FormRequestEstimate
        @close="closeFormRequestEstimate()"
        :dataSelect="dataSelect"
        :flag="flag"
        @onSubmitRequestEstimate="onSubmitRequestEstimate"
        title="見積依頼"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowFormRequestOrder">
      <FormRequestOrder
        :flagRequire="true"
        :dataSelect="dataSelect"
        :flag="flag"
        @close="closeFormRequestOrder()"
        @onSubmitRequestOrder="onSubmitRequestOrder"
        title="発注"
      />
    </Popup>
    <!--  (ユーザー用) 追加/編集ダイアログ -->
    <Popup :dialog="popups.isShowItemForm">
      <MachineApplicationsForm
        :isNewItem="isNewItem"
        :flagError="flagError"
        :isEmitted="isEmitted"
        :roleUser="roleUser"
        :createdCompanyId="createdCompanyId"
        @resetFlagEmit="isEmitted=false"
        @formUpdate="formUpdate"
        @submitForm="submit"
        @cancel="closeItemForm"
      />
    </Popup>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import {
  ROLE_USER,
  STATUS_COLORS_TAB1,
  INIT_DATA_FORM,
  STORE,
} from "@/constants/DEVICE_COORDINATOR";
import Popup from "@/components/common/Popup";
import ConfirmRemoveDialog from "./ConfirmRemoveDialog.vue";
import FormRequestEstimate from "./FormRequestEstimate.vue";
import FormRequestOrder from "./FormRequestOrder.vue";
import ComfirmRemoveDialogOption from "./ComfirmRemoveDialogOption.vue";
import MachineApplicationsForm from "@/components/forms/device/coordinator/applications/MachineApplicationsForm.vue";

export default {
  data() {
    return {
      NO_DATA_MESSAGE,
      STATUS_COLORS_TAB1,
      ROLE_USER,
      dataTable: [],
      selectedItems: [],
      registerType: null,
      checkedAll: false,
      flagError: false,
      flag: false,
      delItems: [],
      popups: {
        isShowRemoveDialogOption: false,
        isShowFormRequestEstimate: false,
        isShowFormRequestOrder: false,
        isShowRemoveDialog: false,
        isShowItemForm: false,
      },
      isNewItem: false,
      isEmitted: false,
      dataForm: { ...INIT_DATA_FORM },
      createdCompanyId: null,
    };
  },
  components: {
    Popup,
    ConfirmRemoveDialog,
    FormRequestEstimate,
    FormRequestOrder,
    ComfirmRemoveDialogOption,
    MachineApplicationsForm,
  },
  props: {
    tableLabels: Array,
    items: Array,
    itemsPerPage: Number,
    tableHeight: Number,
    roleUser: Number,
    dataSelect: Array,
    resetSelected : Boolean
  },
  mounted() {
    this.$watch(
      () => this.items,
      (newValue) => {
        this.dataTable = [...newValue];
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.resetSelected,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          this.selectedItems = [];
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  computed: {
    isDisabledBtn() {
      return this.selectedItems.length === 0;
    },
    getFieldId() {
      return Store.getters["GlobalHeader/getCurrentSite"]?.field_id;
    },
    USER_COMPANY_ID() { 
      return JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user.company_id;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },
  methods: {
    /**
     * set width for column
     */
    getWidthStyle(column) {
      let style = "";
      if (column == 0) {
        style = "width: 100px";
      } else if (column == 2) {
        style = "width: 20vw";
      } else {
        style = "width: 10vw";
      }
      return style;
    },
    /**
     * reset data items when update success
     */
    resetDataItems() {
      this.$emit("resetDataItems");
    },
    /**
     * Event click checkbox in header table
     */
    clickCheckAll(event) {
      if (event) {
        this.selectedItems = this.dataTable;
      } else {
        this.selectedItems = [];
      }
    },
    /**
     * Convert text header to arrray
     */
    convertTextHeaderArr(textHeader) {
      let textHeaderArr = [];
      if (textHeader.includes(" / ")) {
        textHeaderArr = textHeader.split(" / ");
        textHeaderArr[1] = "/ ".concat(textHeaderArr[1]);
      } else {
        textHeaderArr.push(textHeader);
      }
      return textHeaderArr;
    },
    setColor(applyStatus) {
      return this.STATUS_COLORS_TAB1[applyStatus]?.color;
    },
    setTextColor(applyStatus) {
      return applyStatus == STATUS_COLORS_TAB1[3].status ? "white" : "";
    },
    getStatusValue(applyStatus) {
      return this.STATUS_COLORS_TAB1[applyStatus]?.value;
    },
    async onSubmitRequestOrder(data) {
      const machine_company_field_application_ids = this.selectedItems.map(
        (items) => items.machine_company_field_application_id
      );
      const params = {
        machine_company_field_application_ids,
        order_remarks: data.order_remarks,
        order_desired_delivery_date: data.order_scheduled_delivery_date,
        order_rental_company_id: data.order_rental_company_id,
        order_rental_company_branch_id: data.order_rental_company_branch_id,
        field_id: this.CURRENT_SITE.field_id,
      };
      const result = await Store.dispatch(
        `${STORE}/createMachineOrder`,
        params
      );
      if (result.hasError) {
        this.flag = !this.flag;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.response.message,
        });
        return;
      } else {
        this.closeFormRequestOrder();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "発注しました",
        });
        this.$emit("resetDataItems");
      }
    },

    async onSubmitRequestEstimate(data) {
      const machine_company_field_application_ids = this.selectedItems.map(
        (items) => items.machine_company_field_application_id
      );
      const params = {
        machine_company_field_application_ids,
        estimate_remarks: data.estimate_remarks,
        estimate_desired_response_date: data.estimate_desired_response_date,
        estimate_rental_company_id: data.estimate_rental_company_id,
        estimate_rental_company_branch_id: data.estimate_rental_company_branch_id,
        field_id: this.CURRENT_SITE.field_id,
      };
      const result = await Store.dispatch(
        `${STORE}/createMachineEstimate`,
        params
      );
      if (result.hasError) {
        this.flag = !this.flag;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.response.message,
        });
        return;
      } else {
        this.closeFormRequestEstimate();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "見積しました",
        });
        this.$emit("resetDataItems");
      }
    },
    openFormRequestOrder() {
      this.popups.isShowFormRequestOrder = true;
    },
    openFormRequestEstimate() {
      this.popups.isShowFormRequestEstimate = true;
    },
    closePreviewDeviceForm() {
      this.selectedItems = [];
    },
    formUpdate(params) {
      this.dataForm = { ...params };
    },
    closeRemoveItems() {
      this.selectedItems = [];
      this.popups.isShowRemoveDialogOption = false;
    },
    openRemoveDialog() {
      if(this.roleUser == this.ROLE_USER[3]){
          this.delItems = this.selectedItems;
          this.popups.isShowRemoveDialog = true;
      }else if(this.roleUser == this.ROLE_USER[1] || this.roleUser == this.ROLE_USER[2]){
        let rs = this.selectedItems.filter(item => item.created_company_id == this.USER_COMPANY_ID);
        this.delItems = rs;
        if (rs.length == 0) {
          this.popups.isShowRemoveDialogOption = true;
        } else {
          this.popups.isShowRemoveDialog = true;
        }
      }
    },
    closeRemoveDialog() {
      this.selectedItems = [];
      this.popups.isShowRemoveDialog = false;
    },
    closeFormRequestOrder() {
      this.selectedItems = [];
      this.popups.isShowFormRequestOrder = false;
    },
    closeFormRequestEstimate() {
      this.selectedItems = [];
      this.popups.isShowFormRequestEstimate = false;
    },
    /**
     * Delete machine company field
     */
    async removeItems() {
      const machine_company_field_application_ids = this.delItems.map(
        (items) => items.machine_company_field_application_id
      );
      const params = {
        machine_company_field_application_ids,
        field_id : this.CURRENT_SITE.field_id
      }
      const result = await Store.dispatch(`${STORE}/delete`, params);

      if (result.hasError) {
        this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.response.message,
        });
        return;
      } else {
        this.closeRemoveDialog();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
        this.$emit("resetDataItems");
      }
    },
    displayRecord(item) {
      return item?.application_status == STATUS_COLORS_TAB1[3].status ? "grey lighten-1" : "";
    },
    // open new form create
    openNewFormApplication() {
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },
    // open form detail/edit
    async openItemForm(item) {
      const result = await Store.dispatch(`${STORE}/getDetailMachineApplication`, item.machine_company_field_application_id);
      if (!result.hasError) {
        this.isNewItem = false;
        this.createdCompanyId = item?.created_company_id;
        this.popups.isShowItemForm = true;
      }
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.$nextTick(() => {
        this.dataForm = { ...INIT_DATA_FORM };
      });
    },
    async submit(isContinueConfirm) {
      let dataForm = this.getParamRequest();
      dataForm.machine_company_field_applications.field_id = this.CURRENT_SITE.field_id;
      const idEdit = dataForm?.machine_company_field_applications?.id;
      const result = await Store.dispatch( idEdit ? `${STORE}/updateMachineApplication`:
        `${STORE}/createMachineApplication`, dataForm);
      if (result.hasError) {
        if (!isContinueConfirm) this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.response.data.message,
        });
        return;
      } else if (idEdit) {
        // Load data item updated
        await Store.dispatch(`${STORE}/getDetailMachineApplication`, idEdit);
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        // change status form edit -> form detail
        this.isEmitted = true;
      } else {
        // Close form after create new item
        if (!isContinueConfirm) this.closeItemForm();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
      this.$emit("resetItemTab1");
    },
    getParamRequest() {
      let data = {...this.dataForm};
      let machine_company_field_application_drivers = data?.machine_company_field_application_drivers.filter(e => e.driver_user_id != null);
      let machine_company_field_application_images = data?.machine_company_field_application_images.filter(e => (e.image || "").length > 0);
      let machine_company_field_application_work_plans = data?.machine_company_field_application_work_plans.filter(e => e.work_plan_id != null);
      
      if (machine_company_field_application_drivers.length > 0) {
        data.machine_company_field_application_drivers = machine_company_field_application_drivers;
        data.machine_company_field_application_drivers.forEach((e) => {
          if (!e.id) delete e.id;
          if (e?.driver_user_name) delete e.driver_user_name;
          e.machine_company_field_application_driver_licences.forEach((i) => {
            if (!i.id) {
              delete i.id;
            }
          });
          e.machine_company_field_application_driver_sp_trainings.forEach((i) => {
            if (!i.id) {
              delete i.id;
            }
          })
        });
      } else {
        delete data.machine_company_field_application_drivers;
      }

      if (machine_company_field_application_images.length > 0) {
        data.machine_company_field_application_images = machine_company_field_application_images;
      } else {
        delete data.machine_company_field_application_images;
      }

      if (machine_company_field_application_work_plans.length > 0) {
        data.machine_company_field_application_work_plans = machine_company_field_application_work_plans.map(item => {
          return item.id ? 
            {
              id: item.id,
              work_plan_id: item.work_plan_id
            } :
            {
              work_plan_id: item.work_plan_id
            };
        })
      } else {
        delete data.machine_company_field_application_work_plans;
      }
      delete data?.machine_company_field_estimate_order_relations;
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.cst-overflow-td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mb-cst {
  margin-bottom: 2vh !important;
}
::v-deep .v-data-table .v-data-table__wrapper table {
  table-layout: fixed;
}
::v-deep .v-data-table .v-data-table__wrapper table tbody tr td {
  word-break: break-all;
}
</style>
