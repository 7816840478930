import Vue from "vue";
import Vuex from "vuex";
import { scheduleCompany } from "../../../api/modules/scheduleCompany";
import { SCHEDULE_COMPANY_EMPTY } from "@/constants/SCHEDULE_COMPANY";

Vue.use(Vuex);

/**
 * apiを指定
 * 共通のapiは、Store.ScheduleWorksを参照する
 */
const ENTITY = scheduleCompany;

export const ScheduleCompany = {
  namespaced: true,

  state: {
    dataScheduleCompany: [],
    dataScheduleResult: [],
    pagination: {},
    details: {},
    dataHistoryPlan: [],
    dataHistoryResult: [],
  },

  mutations: {
    SET_DATA_SCHEDULE_COMPANY(state, payload) {
      state.dataScheduleCompany = payload;
    },
    SET_DATA_SCHEDULE_RESULT(state, payload) {
      state.dataScheduleResult = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_DETAIL_SCHEDULE_COMPANY(state, payload) {
      state.details = payload;
    },
    SET_CHILD_DATA(state, payload) {
      let parent = [];
      let children = [];
      let data = [];
      if (payload.isTabScheduleCompanyPlan) {
        parent = state.dataScheduleCompany.find(
          (item) => item.work_plan_id == payload.entries[0].work_plan_id
        );
        children = [...parent.children, ...payload.entries];
        data = [...state.dataScheduleCompany];
        const devices = data.map((item) => {
          if (
            item.work_plan_id ==
            payload.entries[0].work_plan_id
          ) {
            const _item = { ...item };
            _item.children = children;
            return _item;
          }
          return item;
        });
        data = devices;
        state.dataScheduleCompany = data;
      } else {
        parent = state.dataScheduleResult.find(
          (item) =>
            item.work_result_id ==
            payload.entries[0].work_result_id
        );
        children = [...parent.children, ...payload.entries];
        data = [...state.dataScheduleResult];
        const devices = data.map((item) => {
          if (
            item.work_result_id ==
            payload.entries[0].work_result_id
          ) {
            const _item = { ...item };
            _item.children = children;
            return _item;
          }
          return item;
        });
        data = devices;
        state.dataScheduleResult = data;
      }
    },
    // form
    SET_PARTNER_COMPANY(state, payload) {
      state.partnerCompany = payload;
    },
    SET_FOREMEN(state, payload) {
      state.foremen = payload;
    },
    SET_WORK_PLACE(state, payload) {
      state.workPlace = payload;
    },
    SET_DATA_HISTORY_PLAN(state, payload) {
      state.dataHistoryPlan = payload;
    },
    SET_DATA_HISTORY_RESULT(state, payload) {
      state.dataHistoryResult = payload;
    },
  },

  actions: {
    //機材予定一覧
    async get({ commit }, payload) {
      const { searchParams, isTabScheduleCompanyPlan } = payload;
      if (isTabScheduleCompanyPlan) {
        const response = await ENTITY.getListWorkCompanyPlans(searchParams);
        if (response.hasError) {
          return response;
        }
        let { entries, pagination } = response.data.contents;
        if (entries.length == 0) {
          entries = [...SCHEDULE_COMPANY_EMPTY];
        }
        commit("SET_DATA_SCHEDULE_COMPANY", entries);
        commit("SET_PAGINATION", pagination);
        return response;
      } else {
        const response = await ENTITY.getListWorkCompanyResults(searchParams);
        if (response.hasError) {
          return;
        }
        let { entries, pagination } = response.data.contents;
        if (entries.length == 0) {
          entries = [...SCHEDULE_COMPANY_EMPTY];
        }
        commit("SET_DATA_SCHEDULE_RESULT", entries);
        commit("SET_PAGINATION", pagination);
        return response;
      }
    },
    async postPlans(_context, payload) {
      return await ENTITY.postPlans(payload);
    },

    async updatePlans(_context, payload) {
      return await ENTITY.updatePlans(payload);
    },
    async postResults(_context, payload) {
      return await ENTITY.postResults(payload);
    },

    async updateResults(_context, payload) {
      return await ENTITY.updateResults(payload);
    },
    async updateStateHandlingPlans(_context, payload) {
      return await ENTITY.updateStateHandlingPlans(payload);
    },
    async updateStateHandlingResults(_context, payload) {
      return await ENTITY.updateStateHandlingResults(payload);
    },
    async loadChildren({ commit }, payload) {
      const { isTabScheduleCompanyPlan, searchParams } = payload;
      if (isTabScheduleCompanyPlan) {
        const response = await ENTITY.getScheduleCompanyPlanChildren(
          searchParams
        );
        if (response.hasError) {
          return response;
        }
        const { entries } = response.data.contents;
        commit("SET_CHILD_DATA", {
          entries,
          isTabScheduleCompanyPlan: isTabScheduleCompanyPlan,
        });
        return response;
      } else {
        const response = await ENTITY.getScheduleCompanyResultChildren(
          searchParams
        );
        if (response.hasError) {
          return response;
        }
        const { entries } = response.data.contents;
        commit("SET_CHILD_DATA", {
          entries,
          isTabScheduleCompanyPlan: isTabScheduleCompanyPlan,
        });
        return response;
      }
    },
    async getDetailPlans({ commit }, payload) {
      const response = await ENTITY.getDetailPlans(payload);
      const entries = response.data.contents.entries;
      commit("SET_DETAIL_SCHEDULE_COMPANY", entries);
      return response;
    },

    async getDetailResults({ commit }, payload) {
      const response = await ENTITY.getDetailResults(payload);
      const entries = response.data.contents.entries;
      commit("SET_DETAIL_SCHEDULE_COMPANY", entries);
      return response;
    },
    // 承認
    async updateApprovalWorkCompanyPlans(context, payload) {
      return await ENTITY.updateApprovalWorkCompanyPlans(payload);
    },

    async updateApprovalWorkCompanyResults(context, payload) {
      return await ENTITY.updateApprovalWorkCompanyResults(payload);
    },
    async getWorkCompanyLastTimePlans(_context, payload) {
      return await ENTITY.getWorkCompanyLastTimePlans(payload);
    },
    async getWorkCompanyLastTimeResults(_context, payload) {
      return await ENTITY.getWorkCompanyLastTimeResults(payload);
    },

    async deletePlans(_context, payload) {
      return await ENTITY.deletePlans(payload);
    },

    async deleteResults(_context, payload) {
      return await ENTITY.deleteResults(payload);
    },

    async getDataHistoryPlan({ commit }, payload) {
      const response = await ENTITY.getDataHistoryPlan(payload);
      const entries = response.data.contents.entries;
      commit("SET_DATA_HISTORY_PLAN", entries);
      return response;
    },

    async getDataHistoryResult({ commit }, payload) {
      const response = await ENTITY.getDataHistoryResult(payload);
      const entries = response.data.contents.entries;
      commit("SET_DATA_HISTORY_RESULT", entries);
      return response;
    },
  },

  getters: {
    getDataScheduleCompany: (state) => {
      return state.dataScheduleCompany;
    },
    getDataScheduleResult: (state) => {
      return state.dataScheduleResult;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getDetail: (state) => {
      return state.details;
    },
    getDataHistoryPlan: (state) => {
      return state.dataHistoryPlan;
    },
    getDataHistoryResult: (state) => {
      return state.dataHistoryResult;
    },
  },
};
