<template>
  <div class="wrapper">
    <Select
      :name="name"
      :values="formValues"
      item_value="field_tree_id"
      item_text="company_name"
      :items="items"
      :editable="editable"
      @onInput="onInput"
    />
  </div>
</template>
<script>
import Select from "@/components/forms/elements/Select.vue";
import { Store } from "@/store/Store.js";

export default {
  data: () => {
    return {
      items: [],
    };
  },
  props: {
    name: {
      type: String,
    },
    fieldId: {
      type: Number,
    },
    groupId: {
      type: Number,
    },
    hasGetByPlanManager: {
      type: Boolean,
      default: false
    },
    otherLevel1OnlyFlg: {
      type: Number,
      default: 0
    },
    editable: {
      type: Boolean,
      default: true
    },
    formValues: {
      type: Object,
      default: () => {
        return { field_tree_id: null };
      },
    },
  },
  components: {
    Select,
  },
  mounted() {
    /**
     * 現場idから協力会社を取得
     */
    this.$watch(
      () => [this.fieldId, this.groupId, this.hasGetByPlanManager, this.otherLevel1OnlyFlg],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.formValues = { field_tree_id: null };
          this.onInput({ name: "field_tree_id", value: null });
          this.getListCompanyPartner(newValue[0], newValue[1], newValue[3]);
        }
      },
      { immediate: true, deep: true }
    )
  },
  methods: {
    onInput({ name, value }) {
      this.$emit("onInput", { name, value });
    },
    async getListCompanyPartner(fieldConstructionId, groupId, otherLevel1OnlyFlg) {
      if(fieldConstructionId || groupId) {
        let params = {};
        // if is obayashi and role is manager plan
        if (this.hasGetByPlanManager && fieldConstructionId) {
          params = {
            field_construction_id: fieldConstructionId,
            is_plan_manager: 1
          };
        } else {
          params = {
            field_construction_id: fieldConstructionId,
            field_tree_group_id: groupId,
            display_branch_name_flag: 1,
            other_level_1_only_flg: otherLevel1OnlyFlg
          };
          if (params.field_tree_group_id) {
            delete params.field_construction_id;
          } else {
            delete params.field_tree_group_id;
          }
        }
        const results = await Store.dispatch("PortalChart/getListCompanyPartner", { params });
        this.items = [{ field_tree_id: null, company_name: "" }, ...results.data.contents.entries];
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  height: 40px;
}
</style>
