import Api, { Mock } from "../api";
import schedule_machines from "./mock/schedule_machines.json";
import schedule_machines_children from "./mock/schedule_machines_children.json";
import device_detail from "./mock/device/device_detail.json";
import machine_use_last_time from "./mock/device/machine_use_last_time.json";

const SCHEDULE_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/machine_uses`; // 予定
const GET_LIST_URL = `${SCHEDULE_BASE_URL}/works`; // 予定一覧取得
const GET_MACHINES_LIST_URL = `${SCHEDULE_BASE_URL}/list`; // 機材予定一覧取得
const GET_MACHINES_CHILDREN_SCHEDULE = `${SCHEDULE_BASE_URL}/get_machine_use_by_machine`; // 子供の予定一覧を取得
const POST_MACHINES_APPROVE = `${SCHEDULE_BASE_URL}/approve`; //承認
const POST_MACHINES_REJECT = `${SCHEDULE_BASE_URL}/disapprove`; //承認解除

const EDIT_URL = `${SCHEDULE_BASE_URL}/update`;
const CREATE_URL = `${SCHEDULE_BASE_URL}/create`;
const DELETE_URL = `${SCHEDULE_BASE_URL}/delete`;
const GET_DETAIL_DEVICE_URL = `${SCHEDULE_BASE_URL}/detail`;
const GET_MACHINE_USE_LASTTIME_URL = `${SCHEDULE_BASE_URL}/get_machine_use_last_time`;

// .envのVUE_APP_NODE_ENV = local の場合はモックを返す

if (Mock) {
    Mock.onGet(new RegExp(`${GET_DETAIL_DEVICE_URL}/[\\d]+`)).reply(200, {
        status_code: 200,
        message: "",
        result: true,
        contents: device_detail,
    });

    Mock.onGet(GET_MACHINES_LIST_URL).reply(200, {
        status_code: 200,
        message: "",
        result: true,
        contents: schedule_machines,
    });


    Mock.onGet(GET_MACHINES_CHILDREN_SCHEDULE).reply(200, {
        status_code: 200,
        message: "",
        result: true,
        contents: schedule_machines_children,
    });

    Mock.onPost(POST_MACHINES_APPROVE).reply(200, {
        status_code: 200,
        message: "",
        result: true,
        contents: {},
    });

    Mock.onPost(POST_MACHINES_REJECT).reply(200, {
        status_code: 200,
        message: "",
        result: true,
        contents: {},
    });

    Mock.onPost(EDIT_URL).reply(200, {
        status_code: 200,
        message: "",
        result: true,
        contents: {},
    });
    Mock.onPost(CREATE_URL).reply(200, {
        status_code: 200,
        message: "",
        result: true,
        contents: {},
    });
    Mock.onPost(DELETE_URL).reply(200, {
        status_code: 200,
        message: "",
        result: true,
        contents: {},
    });
    Mock.onGet(new RegExp(`${GET_MACHINE_USE_LASTTIME_URL}/[\\d]+`)).reply(200, {
        status_code: 200,
        message: "",
        result: true,
        contents: machine_use_last_time,
    });
}

export const scheduleMachines = {
    

    /**
     * 予定
     */
    get: (params) => {
        return Api.get(GET_LIST_URL, params);
    },
    getMachines: (params) => {
        return Api.get(GET_MACHINES_LIST_URL, params);
    },
    
    getScheduleMachinesChildren: (params) => {
        return Api.get(GET_MACHINES_CHILDREN_SCHEDULE, params);
    },
    postScheduleMachinesApprove: (params) => {
        return Api.post(POST_MACHINES_APPROVE, params);
    },
    postScheduleMachinesReject: (params) => {
        return Api.post(POST_MACHINES_REJECT, params);
    },
    add: (params) => {
        return Api.post(CREATE_URL, params);
    },
    edit: async (params) => {
        return Api.post(EDIT_URL, params);
    },
    delete: async (params) => {
        return Api.post(DELETE_URL, params);
    },
    getDetail: async (params) => {
        return Api.get(`${GET_DETAIL_DEVICE_URL}/${params}`);
    },
    getMachineUseLastTime: async (params) => {
        return Api.get(`${GET_MACHINE_USE_LASTTIME_URL}/${params}`);
    },
};
