export const MACHINE_FIELD_FORM_TABS_VIEW = {
  MachineFieldFormPage1: { id: 1, title: "基本情報" },
  MachineFieldFormPage2: { id: 2, title: "性能" },
  MachineFieldFormPage3: { id: 3, title: "貸出履歴" },
  MachineFieldFormPage4: { id: 4, title: "作業前点検" },
  MachineFieldFormPage5: { id: 5, title: "月例点検" },
};

export const MACHINE_FIELD_FORM_TABS_EDIT = {
  MachineFieldFormPage1: { id: 1, title: "基本情報" },
  MachineFieldFormPage2: { id: 2, title: "作業前点検" },
  MachineFieldFormPage3: { id: 3, title: "月例点検" },
};

export const RELATE_MACHINE_FORM_TABS = {
  RelateMachineFormPage1: { id: 1, title: "現場" }
};


export const RELATE_MACHINE_FORM_LABELS_TABLE = [
  {
    text: "種別 / 機材",
    align: "left",
    sortable: true,
    value: "name"
  },
  {
    text: "機番",
    value: "machine_number",
    align: "left",
    sortable: false
  }
]

export const TABLE_LABELS = [
  {
    text: "使用会社",
    align: "left",
    sortable: true,
    value: "used_company_name",
    width:"20%"
  },
  {
    text: "職長",
    value: "foreman_name",
    align: "left",
    sortable: false,
    width:"20%"
  },
  {
    text: "使用申請者/承認者",
    value: "apply_user_name",
    align: "left",
    sortable: false,
    width:"20%"
  },
  {
    text: "貸出日",
    value: "used_start_date",
    align: "left",
    sortable: false,
    width:"10%"
  },
  {
    text: "返却申請者/承認者",
    value: "return_user_name",
    align: "left",
    sortable: true,
    width:"10%"
  },
  {
    text: "返却日",
    value: "used_end_date",
    align: "left",
    sortable: true,
    width:"10%"
  },
  {
    text: "",
    value: "inspection_results",
    align: "left",
    sortable: true,
    width:"10%"
  },
];

export const DIALOG_CONFIRM_HANDLE = {
  TITLE_PROHIBIT : "使用不可",
  TEXT_PROHIBIT : "点検中、または故障のため使用不可にします。",
  TITLE_ENABLE : "使用可",
  TEXT_ENABLE : "使用不可の機材を使用可に戻します。"
};

export const TABLE_LABELS_INSPECTION = [
  {
    text: "点検項目",
    align: "left",
    value: "inspection_item",
    width:"40%"
  },
  {
    text: "点検主眼点",
    align: "left",
    value: "main_point",
    width:"50%"
  },
];

export const MACHINE_COMPANY_INSPECTION_TABS = {
  MachineInspectionFormTab1: { id: 1, title: "作業前点検" },
  MachineInspectionFormTab2: { id: 2, title: "月例点検" },
};

export const MACHINE_COMPANY_INSPECTION_DETAIL_TABS = {
  MachineInspectionDetailFormTab1: { id: 1, title: "点検結果" },
  MachineInspectionDetailFormTab2: { id: 2, title: "写真" },
};

export const INSPECTION_RESULT_CATEGORY = {
  REJECT_APPROVE: "reject_approve",
  APPROVE_ITEMS: "approve_items",
};

export const INSPECTION_LABELS_FORM1 = [
  {
    text: "ステータス",
    value: "status",
    align: "left",
    sortable: false,
    width: "10%",
  },
  {
    text: "点検実施者",
    value: "create_company_name",
    align: "left",
    sortable: false,
    width: "30%",
  },
  {
    text: "",
    value: "create_user_name",
    align: "left",
    sortable: false,
    width: "20%",
  },
  {
    text: "点検実施日",
    value: "created_at",
    align: "left",
    sortable: false,
    width: "25%",
  },
  {
    text: "状態",
    value: "overall_condition",
    align: "left",
    sortable: false,
    width: "10%",
  },
];

export const MEMO_LABELS = [
  {
    text: "点検内容",
    value: "memo",
    align: "left",
  },
];

export const INSPECTION_DETAIL_LABELS = [
  {
    text: "",
    value: "inspection_status",
    align: "left",
    sortable: false,
    width: "10%",
  },
  {
    text: "点検項目",
    value: "inspection_item",
    align: "left",
    sortable: false,
    width: "30%",
  },
  {
    text: "点検主眼点",
    value: "main_point",
    align: "left",
    sortable: false,
    width: "60%",
  },
];

export const OVERALL_CONDITION_STATUS = {
  GOOD: { text: "レ", value: 0 },
  IN_REPAIR: { text: "●", value: 1 },
  NEED_REPAIR: { text: "×", value: 2 },
};

export const NOTE_TEXT = "レ　良好　〇　調整または補修したとき　×　調整または要修理";

export const TABS = [0, 1];