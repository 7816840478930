<template>
  <v-card class="dialog">
    <v-card-title>
      <div :style="getTitleColorStyle" class="title">{{ dialogText.title }}</div>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div class="text">{{ dialogText.text1 }}</div>
      </div>
    </v-card-text>
    <v-card-text v-if="dialogText.text2" class="body">
      <div class="text-wrap">
        <div class="text left-text-confirm">{{ dialogText.text2 }}</div>
      </div>
    </v-card-text>
    <v-card-text v-if="dialogText.text3" class="body">
      <div class="text-wrap">
        <div class="text left-text-confirm">{{ dialogText.text3 }}</div>
      </div>
    </v-card-text>
    <v-card-text v-if="dialogText.text4" class="body">
      <div class="text-wrap">
        <div class="text left-text-confirm">{{ dialogText.text4 }}</div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!isApproved"
        depressed
        outlined
        :color="getColor"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn depressed :disabled="disabled" class="btn" :color="getColor" @click="isApproved ? $emit('close'): handleConfirm()">
        {{ dialogText.btnSubmitText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON";
export default {
  data() {
    return {
      disabled: false,
      timeout: null
    }
  },
  components: {},
  props: {
    dialogText: Object,
    isApproved: {
      type: Boolean,
    },
    warning: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    getTitleColorStyle() {
      return this.warning ? "color:red;" : "color:green;";
    },
    getColor() {
      return this.warning ? "warning" : "primary";
    },
  },
  methods: {
    handleConfirm() {
      this.disabled = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.disabled = false
      }, SUBMIT_DELAY_TIMEOUT);
      this.$emit('yes');
    }
  },
  /**
   * Important: clear timeout
   */
  beforeDestroy () {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout)
  }
};
</script>
