<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters>
                    <!-- ゲート名 * -->
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="ゲート名" :editable="editable" required>
                          <InputText
                            name="name"
                            :values="formValues"
                            :editable="editable"
                            placeholder="南第三ゲート"
                            validation_label="ゲート名"
                            validation_rules="required|max:255"
                            @onInput="onInput"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="6">
                      <!-- 幅 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="幅" :editable="editable">
                            <InputText
                              name="width"
                              :values="formValues"
                              :editable="editable"
                              suffix="m"
                              placeholder=""
                              validation_label="幅"
                              validation_rules="decimal"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <!--高さ -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="高さ" :editable="editable">
                            <InputText
                              name="height"
                              :values="formValues"
                              :editable="editable"
                              suffix="m"
                              placeholder=""
                              validation_label="高さ"
                              validation_rules="decimal"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="6"> </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "../elements/Label";
import InputText from "../elements/InputText";

export default {
  data: () => {
    return {
      formValues: {},
    };
  },
  components: {
    Label,
    InputText,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";
</style>
