// テーブル設定
const OFFICES_TABLE_LABELS = [
  {
    text: "支店",
    align: "left",
    value: "name",
    width: "30%",
  },
  {
    text: "住所",
    value: "address",
    align: "left",
    width: "70%",
  },
];

const OFFICE_SORT_ITEMS = [
  { id: "name", name: "支店名" },
  { id: "address", name: "住所" },
];

const OFFICE_INITAL_ITEM = {
  company_branches: {
    company_id: 1,
    name: "",
    postal_code: "",
    cmn_mst_prefecture_id: null,
    city_name: "",
    building_name: "",
    tel: "",
    fax: "",
    remarks: "",
  },
  company_branch_insurances: {
    health_insurance_status: 1,
    health_insurance_office_symbol: null,
    health_insurance_number: null,
    health_insurance_association_status: 1,
    health_insurance_association_name: null,
    national_health_insurance_association_status: 1,
    national_health_insurance_association_name: null,
    national_health_insurance_association_document: null,
    insurance_accident_status: 1,
    insurance_accident_number: null,
    reference_number: null,
    insurance_accident_document: null,
    pension_insurance_status: 1,
    pension_insurance_office_symbol: null,
    pension_insurance_number: null,
    pension_insurance_document: null,
    employment_insurance_status: 1,
    employment_insurance_number: null,
    employment_insurance_document: null,
    participation_construction_status: 1,
    participation_construction_number: null,
    participation_construction_document: null,
    participation_sme_status: 1,
    participation_sme_number: null,
    participation_sme_document: null,
  },
  company_employment_restrictions: {
    under_15_worker: 1,
    under_18_worker_certificate: 1,
    under_18_contract_with_parent_or_guardians: 1,
    under_18_pay_wages_to_parent_or_guardians: 1,
    under_18_overworked_or_work_overtime: 1,
    under_18_work_overnight: 1,
    violate_restriction_for_young_people: 0,
    under_18_use_electricity_devices: 1,
    under_18_handling_dangerous_substances: 1,
    under_18_working_place_emit_harmful_gas_or_dust: 1,
    under_18_abnormal_atmosphere: 1,
    under_18_under_ground: 1,
    under_18_crane_lifting_slinging_work: 1,
    under_18_landslide_risk: 1,
    under_18_high_place_collision_risk: 1,
    under_18_assdemble_scaffold: 1,
    under_18_handle_heavy_goods: 1,
    under_18_bear_return_home_expenses: 1,
    pregnant_women_not_do_restricted_work: 1,
    woman_comply_young_age_employment_restriction: 1,
    pregnant_women_work_overtime: 1,
    pregnant_women_work_overtime_holiday: 1,
    pregnant_women_work_late: 1,
    prenatal_postnatal_women_not_work_illegally: 1,
    women_have_childcare_time: 1,
    women_not_work_menstrual_day: 1,
  },
  company_safety_rules: [],
};

const INSURANCE_STATUS_2 = [
  { id: 1, name: "無", value: 1 },
  { id: 2, name: "有", value: 2 },
];

export {
  OFFICES_TABLE_LABELS,
  OFFICE_SORT_ITEMS,
  OFFICE_INITAL_ITEM,
  INSURANCE_STATUS_2,
};
