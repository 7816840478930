<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row no-gutters>
          <v-col cols="12">
            <v-list-item>
              <v-list-item-content>
                <Label label="予想される危険" :editable="editable">
                  <InputText
                    name="predicted_risk"
                    :values="item"
                    :editable="editable"
                    placeholder=""
                    validation_label="予想される危険"
                    validation_rules="max:64"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="9">
            <v-list-item>
              <v-list-item-content>
                <Label label="危険の大きさ" :editable="editable">
                  <Select
                    name="cmn_mst_predicted_risk_magnitude_id"
                    :values="item"
                    :editable="editable && Boolean(item.predicted_risk)"
                    :items="WORK_PREDICTED_RISK_MAGNITUDS"
                    item_text="type"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="3">
            <v-list-item>
              <v-list-item-content>
                <Label label="重篤度" :editable="editable">
                  <InputText 
                  name="severity"
                  :values="getSeverity(index)"
                  :editable="false" 
                  placeholder="" 
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="9">
            <v-list-item>
              <v-list-item-content>
                <Label label="発生の可能性" :editable="editable">
                  <Select
                    name="cmn_mst_predicted_risk_frequency_id"
                    :values="item"
                    :editable="editable && Boolean(item.predicted_risk)"
                    :items="WORK_PREDICTED_RISK_FREQUENCIES"
                    item_text="type"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="3">
            <v-list-item>
              <v-list-item-content>
                <Label label="頻度" :editable="editable">
                  <InputText 
                  name="frequency"
                  :values="getFrequency(index)"
                  :editable="false" 
                  item_text="id"
                  placeholder="" />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="3.6">
            <v-list-item>
              <v-list-item-content>
                <Label label="点数" :editable="editable">
                  <InputText 
                  name="degree_risk_number"
                  :values="getDegreeRickNumber(index)"
                  :editable="false" 
                  placeholder="" />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="3.6">
            <v-list-item>
              <v-list-item-content>
                <Label label="危険度" :editable="editable">
                  <InputText 
                  name="degree_risk"
                  :values="getDegreeRisk(index)"
                  :editable="false" 
                  placeholder="" />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="3.6">
            <v-list-item>
              <v-list-item-content>
                <Label label="評価" :editable="editable">
                  <InputText 
                  name="evaluation"
                  :values="getDegreeRisk(index)"
                  :editable="false" 
                  placeholder="" />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="1">
            <v-btn
            v-if="index >= row && editable"
            class="mt-7"
            icon
            @click="remove(index)"
            >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn v-if="editable" depressed small color="primary" @click="addEmpty"
      >追加</v-btn
    >
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "../../../elements/Label.vue";
import Select from "../../../elements/Select.vue";
import _ from "lodash";
import { Store } from "@/store/Store.js";
import InputText from "../../../elements/InputText";

const INITIAL_VALUES = {
  predicted_risk: null,
  cmn_mst_predicted_risk_magnitude_id: null,
  cmn_mst_predicted_risk_frequency_id : null,
};
export default {
  data: () => {
    return {
      items: [],
      WORK_PREDICTED_RISK_MAGNITUDS: [],
      WORK_PREDICTED_RISK_FREQUENCIES: [],
      CMN_MST_PREDICTED_RISK_EVALUATION: [],
    };
  },
  components: {
    Label,
    Select,
    InputText,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    row: {
      type: Number,
      default: 1,
    },
  },
  mounted() {
    this.$watch(
      () => Store.getters["CmnMst/get"],
      (data) => {
        this.WORK_PREDICTED_RISK_MAGNITUDS = _.cloneDeep(data.entries.cmn_mst_predicted_risk_magnitudes);
        this.WORK_PREDICTED_RISK_MAGNITUDS.unshift({id: null, type: ""});
        this.WORK_PREDICTED_RISK_FREQUENCIES = _.cloneDeep(data.entries.cmn_mst_predicted_risk_frequencies);
        this.WORK_PREDICTED_RISK_FREQUENCIES.unshift({id: null, type: ""});
        this.CMN_MST_PREDICTED_RISK_EVALUATION = _.cloneDeep(data.entries.cmn_mst_predicted_risk_evaluations);
        
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        if (newValue[0][newValue[1]]) {
          this.items = _.cloneDeep(newValue[0][newValue[1]]);
          if (this.items?.length == 0) {
            this.addEmpty();
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      if(value.name == "predicted_risk" && !value.value) {
        _items[index]["cmn_mst_predicted_risk_magnitude_id"] = null;
        _items[index]["cmn_mst_predicted_risk_frequency_id"] = null;
      }
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      let _items = [...this.items];
      _items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    getSeverity(index) {
      let severity = { severity: null };
      const cmn_mst_predicted_risk_magnitude_id =
        this.items[index]["cmn_mst_predicted_risk_magnitude_id"];
      if (cmn_mst_predicted_risk_magnitude_id) {
        this.WORK_PREDICTED_RISK_MAGNITUDS.forEach((item) => {
          if (item.id == cmn_mst_predicted_risk_magnitude_id) {
            severity = { severity: item.severity };
          }
        });
      }
      return severity;
    },
    getFrequency(index) {
      let frequency = { frequency: null };
      const cmn_mst_predicted_risk_frequency_id =
        this.items[index]["cmn_mst_predicted_risk_frequency_id"];
      if (cmn_mst_predicted_risk_frequency_id) {
        this.WORK_PREDICTED_RISK_FREQUENCIES.forEach((item) => {
          if (item.id == cmn_mst_predicted_risk_frequency_id) {
            frequency = { frequency: item.frequency };
          }
        });
      }
      return frequency;
    },
    getDegreeRickNumber(index) {
      const severity = this.getSeverity(index);
      const frequency = this.getFrequency(index);
      let degreeRickNumber = { degree_risk_number: null };
      if (severity.severity && frequency.frequency) {
        degreeRickNumber = { degree_risk_number: severity.severity * frequency.frequency };
      }
      return degreeRickNumber;
    },
    getDegreeRisk(index) {
      const degreeRickNumber = this.getDegreeRickNumber(index);
      let degreeRick = {
        degree_risk: null,
        evaluation: null,
      };
      if (degreeRickNumber.degree_risk_number) {
        this.CMN_MST_PREDICTED_RISK_EVALUATION.forEach((item) => {
          if (item.score == degreeRickNumber.degree_risk_number) {
            degreeRick = {
              degree_risk: item.degree_risk,
              evaluation: item.evaluation,
            };
          }
        });
      }
      return degreeRick;
    },
  },
};
</script>
