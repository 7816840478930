import Vue from "vue";
import Vuex from "vuex";
import { faceRecognition } from "../../../api/modules/faceRecognition";
import { faceEntrance } from "../../../api/modules/faceEntrance";

Vue.use(Vuex);

export const FaceRecognition = {
  namespaced: true,

  actions: {
    /**
     * 顔認証
     */
    async enter(context, payload) {
      // 顔認証
      let response = await faceRecognition.postAuth({
        ...payload,
      });

      // console.log("--", payload, response);

      //顔が認証できない
      if (response.hasError) {
        return { ...response, error: { type: "face" } };
      }

      // 取得
      const { over_tolerance, person_id } = response.data.contents;
      // console.log("--", over_tolerance, person_id);

      // over_tolerance = 緯度経度が不正
      if (over_tolerance) {
        return { ...response, error: { type: "location" } };
      }

      // 入場
      response = await faceEntrance.post({
        ...payload,
        person_id,
      });

      if (response.hasError) {
        return {
          ...response,
          error: { type: payload.entrance_status ? "enter" : "leave" },
        };
      }

      return response;
    },

    async checkFace(context, payload) {
      let response = await faceRecognition.checkFace({
        ...payload,
      });
      return response;
    },
  },

  getters: {},
};
