var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"FormMain"},[_c('div',{staticClass:"FormMainBody",style:(_vm.getBodyStyle)},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(observer){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-form',{attrs:{"autocomplete":"off"}},[_c('v-container',[_c('div',[(!_vm.editMode)?_c('v-row',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-col',{staticClass:"mode-fix"},[_c('v-btn',{staticClass:"mx-2",attrs:{"depressed":"","color":"primary","data-testid":"add-new-btn","disabled":_vm.dataItems.length == 0 || _vm.roleUser != _vm.ROLE_USER[4]},on:{"click":function($event){_vm.editMode = true}}},[_vm._v(" 数量・単価編集 ")])],1)],1):_vm._e(),(_vm.editMode)?_c('v-row',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[(_vm.roleUser == _vm.ROLE_USER[4])?_c('v-btn',{staticClass:"mr-3",attrs:{"depressed":"","outlined":"","color":"primary"},on:{"click":_vm.closeUpdate}},[_vm._v(" やめる ")]):_vm._e(),(_vm.roleUser == _vm.ROLE_USER[4])?_c('v-btn',{key:_vm.flagUpdateEstimateOrder,attrs:{"disabled":!_vm.valid,"depressed":"","color":"primary","data-testid":"btn-submit"},on:{"~click":function($event){return _vm.updateEstimateOrder($event)}}},[_vm._v(" 保存 ")]):_vm._e()],1)],1):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-0 v-data-table__wrapper",attrs:{"headers":_vm.TABLE_LABELS_FORM_MACHINE,"items":_vm.dataItems,"items-per-page":_vm.dataItems.length,"fixed-header":"","item-key":"machine_company_field_estimate_order_relation_detail_id","hide-default-footer":"","hide-default-header":"","disable-sort":"","show-select":true,"sort-by":"updatedAt","noDataText":_vm.NO_DATA_MESSAGE},on:{"click:row":_vm.openMachineDetailForm},scopedSlots:_vm._u([(_vm.flagEstimate)?{key:"item.estimate_status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.setColor(item.estimate_status)}},[_vm._v(" "+_vm._s(_vm.getStatusValue(item.estimate_status))+" ")])]}}:null,{key:"item.machine_company_type_item_tree_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cst-overflow-td"},[_vm._v(_vm._s(item.machine_company_type_item_tree_name))]),_c('div',{staticClass:"cst-overflow-td"},[_vm._v(_vm._s(item.machine_name))])]}},(_vm.flagEstimate)?{key:"item.estimate_amount",fn:function(ref){
var item = ref.item;
return [(_vm.editMode)?_c('div',{staticStyle:{"margin-top":"20px","min-width":"100px"}},[_c('InputText',{attrs:{"name":"estimate_amount","values":item,"editable":true,"validation_label":"見積量","validation_rules":"estimate-order-amount|digit"},on:{"onInput":function($event){return _vm.onInput({value: $event, id: item.machine_company_field_estimate_order_relation_detail_id})}}})],1):_c('div',[_vm._v(_vm._s(item.estimate_amount))])]}}:null,(_vm.flagEstimate)?{key:"item.estimate_unit_price",fn:function(ref){
var item = ref.item;
return [(_vm.editMode)?_c('div',{staticStyle:{"margin-top":"20px","min-width":"100px"}},[_c('InputText',{attrs:{"name":"estimate_unit_price","values":item,"editable":true,"validation_label":"単価","validation_rules":"decimal|max-price","prefix":"￥"},on:{"onInput":function($event){return _vm.onInput({value: $event, id: item.machine_company_field_estimate_order_relation_detail_id})}}})],1):_c('div',{staticStyle:{"min-width":"120%"}},[_vm._v(_vm._s(_vm.convertCurrentcy(item.estimate_unit_price)))])]}}:null,(_vm.flagEstimate)?{key:"item.estimate_total_amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"120%"}},[_vm._v(_vm._s(_vm.convertCurrentcy(item.estimate_total_amount)))])]}}:null,(_vm.flagEstimate)?{key:"item.estimate_scheduled_delivery_date",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.convertDate(item.estimate_scheduled_delivery_date)))])]}}:null,(_vm.flagEstimate)?{key:"item.estimate_remarks",fn:function(ref){
var item = ref.item;
return [(_vm.editMode)?_c('div',{staticStyle:{"margin-top":"20px","min-width":"100px"}},[_c('InputText',{attrs:{"name":"estimate_remarks","values":item,"editable":true,"validation_label":"備考","validation_rules":"max:512"},on:{"onInput":function($event){return _vm.onInput({value: $event, id: item.machine_company_field_estimate_order_relation_detail_id})}}})],1):_c('div',[_vm._v(_vm._s(item.estimate_remarks))])]}}:null,(!_vm.flagEstimate)?{key:"item.order_status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.setColor(item.order_status)}},[_vm._v(" "+_vm._s(_vm.getStatusValue(item.order_status))+" ")])]}}:null,(!_vm.flagEstimate)?{key:"item.order_delivery_amount",fn:function(ref){
var item = ref.item;
return [(_vm.editMode)?_c('div',{staticStyle:{"margin-top":"20px","min-width":"100px"}},[_c('InputText',{attrs:{"name":"order_delivery_amount","values":item,"editable":true,"validation_label":"納品数","validation_rules":"estimate-order-amount|digit"},on:{"onInput":function($event){return _vm.onInput({value: $event, id: item.machine_company_field_estimate_order_relation_detail_id})}}})],1):_c('div',[_vm._v(_vm._s(item.order_delivery_amount))])]}}:null,(!_vm.flagEstimate)?{key:"item.order_unit_price",fn:function(ref){
var item = ref.item;
return [(_vm.editMode)?_c('div',{staticStyle:{"margin-top":"20px","min-width":"100px"}},[_c('InputText',{attrs:{"name":"order_unit_price","values":item,"editable":true,"validation_label":"単価","validation_rules":"decimal|max-price","prefix":"￥"},on:{"onInput":function($event){return _vm.onInput({value: $event, id: item.machine_company_field_estimate_order_relation_detail_id})}}})],1):_c('div',{staticStyle:{"min-width":"120%"}},[_vm._v(_vm._s(_vm.convertCurrentcy(item.order_unit_price)))])]}}:null,(!_vm.flagEstimate)?{key:"item.order_total_amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"120%"}},[_vm._v(_vm._s(_vm.convertCurrentcy(item.order_total_amount)))])]}}:null,(!_vm.flagEstimate)?{key:"item.order_scheduled_delivery_date",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.convertDate(item.order_scheduled_delivery_date)))])]}}:null,(!_vm.flagEstimate)?{key:"item.order_remarks",fn:function(ref){
var item = ref.item;
return [(_vm.editMode)?_c('div',{staticStyle:{"margin-top":"20px","min-width":"100px"}},[_c('InputText',{attrs:{"name":"order_remarks","values":item,"editable":true,"validation_label":"備考","validation_rules":"max:512"},on:{"onInput":function($event){return _vm.onInput({value: $event, id: item.machine_company_field_estimate_order_relation_detail_id})}}})],1):_c('div',[_vm._v(_vm._s(item.order_remarks))])]}}:null],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},[_c('template',{attrs:{"headers":_vm.TABLE_LABELS_FORM_MACHINE},slot:"header"},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"50px"}},[_c('v-simple-checkbox',{attrs:{"value":_vm.selectedItems.length === _vm.dataItems.length && _vm.dataItems.length > 0},on:{"input":function($event){return _vm.clickCheckAll($event)}}})],1),_vm._l((_vm.TABLE_LABELS_FORM_MACHINE),function(h,index){return _c('th',{key:h.value,style:(_vm.getWidthStyle(index))},_vm._l((_vm.convertTextHeaderArr(h.text)),function(text){return _c('div',{key:text},[_c('div',[_vm._v(_vm._s(text))])])}),0)})],2)])])],2)],1)],1)],1)],1),_c('Popup',{attrs:{"dialog":_vm.popups.isShowMachineDetailForm}},[_c('MachineDetailForm',{attrs:{"flagEstimate":_vm.flagEstimate,"roleUser":_vm.roleUser},on:{"updateList":_vm.updateList,"cancel":function($event){_vm.popups.isShowMachineDetailForm = false}}})],1),_c('Popup',{attrs:{"width":"480px","dialog":_vm.popups.isShowConfirmDialog}},[_c('ConfirmCloseDialog',{attrs:{"title":"フォームを閉じる確認","text1":"フォームを閉じますがよろしいですか？\n入力内容は保存されません。","text2":"このページから移動してもよろしいですか？","warning":""},on:{"close":_vm.onClickBtnCancelForm,"yes":_vm.cancelUpdate}})],1),_c('ValidationCallback',{attrs:{"observer":observer},on:{"callback":_vm.updateValidate}})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }