<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    :vid="id"
    :name="validation_label"
    :rules="validation_rules"
  >
    <v-select
      v-model="val"
      dense
      :items="items"
      :item-text="item_text"
      :item-value="item_value"
      :disabled="!editable"
      :success="valid"
      :filled="editable"
      single-line
      :error-messages="errors"
      :label="label"
      multiple
      :chips="multiple"
      @input="$emit('onInput', { name, value: val })"
      :data-testid="testid"
      :menu-props="{ maxWidth: 800, auto: autoCenter, offsetY: offsetY }"
    >
      <template #selection="{ item,index }" v-if="val.length > 0">
        <v-chip :disabled="!editable" class="v-chip-value" small color="primary" v-if="index === 0" >{{ countSelect.length }}個選択中</v-chip>
      </template>
    </v-select>
  </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
export default {
  components: {
    ValidationProvider,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    items: {
      type: Array,
    },
    label: {
      type: String,
    },
    validation_rules: {
      type: String,
      default: "",
    },
    validation_label: {
      type: String,
      default: "",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    testid: {
      type: String,
    },
    item_text: {
      type: String,
      default: "name",
    },
    item_value: {
      type: String,
      default: "id",
    },
    id: {
      type: String,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    autoCenter: {
      type: Boolean,
      default: false,
    },
    offsetY: {
      type: Boolean,
      default: false,
    }
  },
  data: () => {
    return {
      val: "",
      countSelect: [],
    };
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        const formValues = newValue[0];
        const name = newValue[1];
        if (formValues && name) {
          this.val = formValues[name];
          this.countSelect = this.val.filter(function(e, index, self) {
            return index === self.indexOf(e);
          })
        }
      },
      { immediate: true, deep: true }
    );
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-select.v-input--dense .v-select__selection--comma {
  position: absolute;
}
::v-deep .v-chip-value.v-chip .v-chip__content {
  align-items: center;
  display: inline-flex !important;
  height: 100%;
  max-width: 100%;
  white-space: nowrap;
  margin-top: 0 !important;
}
</style>