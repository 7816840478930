<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <ValidationObserver>
            <v-form ref="form" lazy-validation autocomplete="off">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="メール" :editable="editable">
                          <InputText
                            name="login_id"
                            :values="formValues"
                            :editable="editable"
                            placeholder="メール"
                            validation_label="メール"
                            validation_rules="minmax:6,10"
                            @onInput="onInput"
                            testid="login_id"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-list-item>
                      <v-list-item-content>
                        <SampleValiableInput
                          name="items"
                          :values="formValues"
                          :editable="editable"
                          @onInput="onInput"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import Label from "@/components/forms/elements/Label.vue";
import InputText from "@/components/forms/elements/InputText.vue";
import SampleValiableInput from "./components/SampleValiableInput.vue";
import { WORKER_ROLES } from "@/constants/WORKER"; // ユーザ権限リスト

export default {
  data: () => {
    return {
      formValues: {},
    };
  },
  components: {
    ValidationObserver,
    Label,
    InputText,
    SampleValiableInput,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
        console.log("item", this.item);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      const headerHeight = 48;
      let style = `height:${this.mainHeight - headerHeight}px;`;
      return style;
    },
    // select用にフォーマット
    userRolesList() {
      return Object.values(WORKER_ROLES);
    },
  },
  methods: {
    onUpadte() {
      this.$emit("formUpdate", {});
    },
    onEdit() {
      // this.$emit("onEdit");
    },
    onInput({ name, value }) {
      console.log("update!!", name, value);
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
