<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <ValidationObserver>
            <v-form autocomplete="off">
              <v-container>
                <v-row>
                  <!-- first col -->
                  <v-col cols="12" sm="6" md="6">
                    <SectionLabel label="監理技術者・主任技術者" class="ml-4" />
                    <v-list-item>
                      <v-list-item-content>
                        <Label
                          label="監理技術者・主任技術者名"
                          :editable="editable"
                        >
                          <InputText
                            name="chief_engineer_name"
                            :values="fieldValues"
                            :editable="editable"
                            placeholder="夏川 二郎"
                            validation_label="監理技術者・主任技術者名"
                            validation_rules="max:255"
                            @onInput="onInput"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <Label label="専任の有無" :editable="editable">
                          <RadioGroup
                            v-if="editable"
                            name="chief_engineer_fulltime_flag"
                            :values="fieldValues"
                            :items="CHIEF_ENGINEER_FULLTIME_FLAG"
                            :editable="editable"
                            @onInput="onInput"
                          />
                          <slot v-if="!editable">{{
                            getRadioName(
                              CHIEF_ENGINEER_FULLTIME_FLAG,
                              fieldValues["chief_engineer_fulltime_flag"]
                            )
                          }}</slot>
                        </Label>
                      </v-list-item-content>
                    </v-list-item>

                    <Label label="資格" class="ml-4" />

                    <v-list-item>
                      <v-list-item-content>
                        <Label label="試験等" :editable="editable">
                          <Select
                            name="chief_engineer_category"
                            :values="fieldValues"
                            :items="getcatergoryItems()"
                            :editable="editable"
                            @onInput="onInput"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <Label label="資格区分" :editable="editable">
                          <Select
                            name="chief_engineer_exam_id"
                            :values="fieldValues"
                            item_text="qualification"
                            :items="
                              getSubCatergoryItems('chief_engineer_category')
                            "
                            :editable="editable"
                            @onInput="onInput"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>

                    <SectionLabel label="監理技術者補佐" class="ml-4" />

                    <v-list-item>
                      <v-list-item-content>
                        <Label label="監理技術者補佐名" :editable="editable">
                          <InputText
                            name="supervising_engineer_assistant_name"
                            :values="fieldValues"
                            :editable="editable"
                            placeholder="夏川 二郎"
                            validation_label="監理技術者補佐名"
                            validation_rules="max:255"
                            @onInput="onInput"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>

                    <Label label="資格" class="ml-4" />

                    <v-list-item>
                      <v-list-item-content>
                        <Label label="試験等" :editable="editable">
                          <Select
                            name="supervising_engineer_category"
                            :values="fieldValues"
                            :items="getcatergoryItems()"
                            :editable="editable"
                            @onInput="onInput"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <Label label="資格区分" :editable="editable">
                          <Select
                            name="supervising_engineer_exam_id"
                            :values="fieldValues"
                            item_text="qualification"
                            :items="
                              getSubCatergoryItems(
                                'supervising_engineer_category'
                              )
                            "
                            :editable="editable"
                            @onInput="onInput"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>

                    <SectionLabel label="専門技術者" class="ml-4" />

                    <v-list-item>
                      <v-list-item-content>
                        <SiteTechnicians
                          name="field_technicians"
                          :values="formValues"
                          :editable="editable"
                          @onInput="onInput"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <!-- second col -->

                  <v-col cols="12" sm="6" md="6">
                    <SectionLabel label="会長" class="ml-4" />
                    <v-list-item>
                      <v-list-item-content>
                        <Label
                          label="統括安全衛生責任者名"
                          :editable="editable"
                        >
                          <InputText
                            name="chairman_name"
                            :values="fieldValues"
                            :editable="editable"
                            placeholder="夏川 二郎"
                            validation_label="会長名"
                            validation_rules="max:255"
                            @onInput="onInput"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>

                    <SectionLabel label="副会長" class="ml-4" />
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="会社名" :editable="editable">
                          <InputText
                            name="vice_chairman_company_name"
                            :values="fieldValues"
                            :editable="editable"
                            placeholder="大山建設株式会社"
                            validation_label="会社名"
                            validation_rules="max:255"
                            @onInput="onInput"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <Label label="副会長名" :editable="editable">
                          <InputText
                            name="vice_chairman_name"
                            :values="fieldValues"
                            :editable="editable"
                            placeholder="中島 明"
                            validation_label="副会長名"
                            validation_rules="max:255"
                            @onInput="onInput"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>

                    <SectionLabel label="元方安全衛生管理者" class="ml-4" />
                    <v-list-item>
                      <v-list-item-content>
                        <Label
                          label="元方安全衛生管理者名"
                          :editable="editable"
                        >
                          <InputText
                            name="health_safety_manager_name"
                            :values="fieldValues"
                            :editable="editable"
                            placeholder="夏川 二郎"
                            validation_label="元方安全衛生管理者名"
                            validation_rules="max:255"
                            @onInput="onInput"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>

                    <SectionLabel label="書記" class="ml-4" />
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="書記名" :editable="editable">
                          <InputText
                            name="secretary_name"
                            :values="fieldValues"
                            :editable="editable"
                            placeholder="佐藤  実"
                            validation_label="書記名"
                            validation_rules="max:255"
                            @onInput="onInput"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>

                    <SectionLabel label="書類等の提出先及び担当者" class="ml-4" />
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="提出先" :editable="editable">
                          <InputText
                            name="document_submission_address"
                            :values="fieldValues"
                            :editable="editable"
                            placeholder="作業所  事務課"
                            validation_label="提出先"
                            validation_rules="max:512"
                            @onInput="onInput"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <Label label="担当者名" :editable="editable">
                          <InputText
                            name="document_person_in_charge_name"
                            :values="fieldValues"
                            :editable="editable"
                            placeholder="佐藤  実"
                            validation_label="担当者名"
                            validation_rules="max:255"
                            @onInput="onInput"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import { Store } from "@/store/Store.js";
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import RadioGroup from "@/components/forms/elements/RadioGroup";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import Select from "@/components/forms/elements/Select.vue";
import SiteTechnicians from "./components/SiteTechnicians.vue";
import { CHIEF_ENGINEER_FULLTIME_FLAG } from "@/constants/SITES";

export default {
  data: () => {
    return {
      fieldValues: {},
      formValues: {},
      CHIEF_ENGINEER_FULLTIME_FLAG,
      cmn_mst_qualifications_construction_industries:
        Store.state.CmnMst.constants.entries
          .cmn_mst_qualifications_construction_industries,
    };
  },
  components: {
    SiteTechnicians,
    ValidationObserver,
    Label,
    SectionLabel,
    InputText,
    InputDatepicker,
    Select,
    RadioGroup,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (data) => {
        this.initData(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    /**
     * (共通)
     * 定数をapi/ストアからまとめて取得します
     *
     * this.CONSTANTS.roll
     * こうやって個々の定数を取得します
     */
    CONSTANTS() {
      return Store.getters["Constants/get"];
    },
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    initData(data) {
      const formValues = data;
      this.fieldValues = formValues.fields;
      this.cmn_mst_qualifications_construction_industries.forEach((element) => {
        if (this.fieldValues.chief_engineer_exam_id == element.id) {
          this.fieldValues["chief_engineer_category"] = element.exam_id;
        }
        if (this.fieldValues.supervising_engineer_exam_id == element.id) {
          this.fieldValues["supervising_engineer_category"] = element.exam_id;
        }
      });
      if(!this.fieldValues["chief_engineer_category"]) this.fieldValues["chief_engineer_category"] = null;
      if(!this.fieldValues["supervising_engineer_category"]) this.fieldValues["supervising_engineer_category"] = null;
      this.formValues = { ...formValues };
    },
    onInput({ name, value, fileName }) {
      const formValues = { ...this.formValues };

      if (fileName) {
        formValues["fields"][`${name}_data`] = value;
        formValues["fields"][name] = fileName;
      } else {
        if (name == "field_technicians") {
          formValues["field_technicians"] = value;
        } else {
          if (name == "chief_engineer_category") {
            formValues["fields"].chief_engineer_exam_id = null;
          }
          if (name == "supervising_engineer_category") {
            formValues["fields"].supervising_engineer_exam_id = null;
          }

          formValues["fields"][name] = value;
        }
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    getRadioName(items, value) {
      return items.find((item) => item.value == value)?.name;
    },
    getcatergoryItems() {
      let temp = "";
      let rs = [{ id: null, name: ""}];
      this.cmn_mst_qualifications_construction_industries.forEach((element) => {
        if (temp != element.exam_id) {
          rs.push({ id: element.exam_id, name: element.exams });
          temp = element.exam_id;
        }
      });
      return rs;
    },

    getSubCatergoryItems(name) {
      let result = this.cmn_mst_qualifications_construction_industries.filter(item=>item.exam_id==this.fieldValues[name]);
      result.unshift({id: null, qualification: ""});
      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
</style>
