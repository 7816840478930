<template>
  <div>
    <v-item>
      <v-card>
        <v-data-table
        :headers="TABLE_LABELS"
        :items="items"
        :items-per-page="25"
        :height="tableHeight"
        :noDataText="NO_DATA_MESSAGE"
        hide-default-header
        hide-default-footer
        class="elevation-0 v-data-table__wrapper"
        sort-by="field_id"
        @dblclick:row="goToDashboard"
      >
        <template v-slot:[`item.site`]="{ item }">
          <div>{{ convertName(item.field_name) }}</div>
          <div>{{ convertName(item.full_address) }}</div>
        </template>
      </v-data-table>
      </v-card>
    </v-item>
  </div>
</template>

<script>
import { Store } from "@/store/Store.js";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
const  TABLE_LABELS = [
    { text: "現場", value: "site" },
];
export default {
  data() {
    return {
      TABLE_LABELS,
      items : [],
      tableHeight: "0px",
      NO_DATA_MESSAGE
    };
  },
  async mounted() {
    this.$watch(
      () => Store.getters["Sites/getData"],
      (data) => {
        this.items = data;
        this.updateTableHeight();
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    updateTableHeight() {
      const columns = this.items.length;
      if(columns == 0) {
        this.tableHeight = "48px";
      } else {
        let itemHeight = columns > 10 ? 480 : 48 * columns;
        this.tableHeight = itemHeight + "px";
      }
    },

    convertName(name) {
      let result = "";
      if (name) {
        result = name;
        if (name.length > 36) {
          result = name.substring(0, 36) + "•••";
        }
      }
      return result;
    },
    goToDashboard(event, { item }) {
      Store.dispatch("GlobalHeader/setSite", {
        field_id: item.field_id
      });
      this.$router.push("/portal/dashboard");
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .v-data-table__wrapper  table  tbody  tr td:hover {
  cursor: pointer;
}
</style>