import Vue from "vue";
import Vuex from "vuex";
import { offices } from "@/api/modules/offices"

Vue.use(Vuex);

/**
 * apiを指定
 */

export const Offices = {
  namespaced: true,

  state: {
    data: [],
    pagination: {},
    companyBranches: [],
    officeDetail: {},
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_COMPANY_BRANCHES(state, payload) {
      state.companyBranches = payload;
    },
    SET_OFFICE_DETAIL(state, payload) {
      state.officeDetail = payload;
    },
  },

  actions: {
    async get({ commit }, payload) {
      const response = await offices.get(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;
      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },
    async getDetail({ commit }, payload) {
      const response = await offices.getDetail(payload);
      if (response.hasError) {
        return response;
      }
      const entries= response.data.contents.entries;
      commit("SET_OFFICE_DETAIL", entries);
      return response;
    },
    async getByCompanyId({ commit }, payload) {
      const response = await offices.getByCompanyId(payload);
      const companyBranches = response.data.contents.entries;
      commit("SET_COMPANY_BRANCHES", companyBranches);
      return response;
    },
    async post(_context, payload) {
      return await offices.post(payload);
    },
    async update(_context, payload) {
      return await offices.update(payload);
    },
    async delete(_context, payload) {
      return await offices.delete(payload);
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getCompanyBranches: (state) => {
      return state.companyBranches;
    },
    getOfficeDetail: (state) => {
      return state.officeDetail;
    },
  },
};
