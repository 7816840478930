import Api, { Mock } from "../api";
import mock_fieldslist from "./mock/globalheader_fieldslist.json";

const URL = `${process.env.VUE_APP_API_BASE_URL}`;

//現場一覧
const FIELDS_LIST = `${URL}/fields/list`;

// .envのVUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  //現場一覧
  Mock.onGet(FIELDS_LIST).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_fieldslist,
  });
}

export const globalheader = {
  searchSites: (params) => {
    return Api.get(FIELDS_LIST, { params });
  },
};
