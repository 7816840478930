<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <SitePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout
          :layoutParams="layoutParams"
          :hideFooter="searchParams.page_count >= searchParams.total_item"
        >
          <template #tableHeader="{ updateHeader }">
            <RegisterFaceHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :updateHeader="updateHeader"
              :isDisableBtn="isDisableBtn"
              @onRegister="onRegister"
            >
              <v-row>
                <v-col sm="11" md="11">
                  <SearchFormWrapper>
                    <Label label="入場開始日（開始）" editable :required="isRequiredStart">
                      <InputDatepicker
                        name="site_admission_date_from"
                        :editable="true"
                        :values="searchParams"
                        :flagNull="true"
                        :validation_rules="getStartDateRules"
                        validation_label="入場開始日（開始）"
                        @onInput="onChangeSearchParams"
                      />
                    </Label>
                    <div class="textBold">〜</div>
                    <Label label="入場開始日（終了）" editable :required="isRequiredEnd">
                      <InputDatepicker
                        name="site_admission_date_to"
                        :editable="true"
                        :flagNull="true"
                        :values="searchParams"
                        :validation_rules="getEndDateRules"
                        validation_label="入場開始日（終了）"
                        @onInput="onChangeSearchParams"
                      />
                    </Label>
                    <Label label="協力会社" width="300px">
                      <Select
                        name="field_tree_id"
                        :items="COMPANY_PARTNER_ARRAY"
                        :editable="true"
                        item_text="company_name"
                        item_value="field_tree_id"
                        :values="searchParams"
                        validation_label="協力会社"
                        validation_rules=""
                        @onInput="onChangeSearchParams"
                      />
                    </Label>
                    <Label label="氏名">
                      <InputText
                        name="user_name"
                        :editable="true"
                        :values="searchParams"
                        @onInput="onChangeSearchParams"
                      />
                    </Label>
                    <TabSelect
                      class="mt-8"
                      name="face_reco_status"
                      :items="REGISTRATION_STATUS"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                    />
                    <SwitchInput
                      class="mt-4"
                      name="no_image_flag"
                      :label="`顔画像なし`"
                      :values="searchParams"
                      :editable="true"
                      @onInput="onChangeSearchParams"
                    />
                  </SearchFormWrapper>
                </v-col>
                <v-col sm="1" md="1" class="text-right">
                  <v-btn
                    class="mr-6 ma-2"
                    depressed
                    :disabled="!isEnableBtnSearch"
                    color="primary"
                    @click="onSearch()"
                  >
                    検索
                  </v-btn>
                </v-col>
              </v-row>
            </RegisterFaceHeader>
            <v-container class="tableSortWrapper">
              <v-spacer></v-spacer>
              <div class="sortLabel">総件数:</div>
              <div class="sortElement total_item">
                {{ searchParams.all_record }}件
              </div>
            </v-container>
          </template>
          <template #tableBody="{ tableHeight }">
            <RegisterFaceTable
              :tableLabels="TABLE_LABELS"
              :tableHeight="tableHeight"
              :items="items"
              :isNoDataMessage="isNoDataMessage"
              :isSelected="isSelected"
              :itemsPerPage="searchParams.page_count"
              :userImgMap="userImgMap"
              :checkSelectedParents="checkSelectedParents"
              :getChildItems="getChildItems"
              :isResetDisableMap="isResetDisableMap"
              @update="updateSelectedItems"
              @updateUserImgMap="updateUserImgMap"
            />
          </template>
          <template #tableFooter>
            <Pagination
              :current="searchParams.current_page"
              :total="searchParams.total_page"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>
    <Popup width="480px" :dialog="popups.isShowRegisterDialog">
      <ConfirmRegisterDialog
        :isReset="isReset"
        @close="closeConfirmRegisterDialog()"
        @yes="onSubmit"
      />
    </Popup>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import Pagination from "@/components/masterTable/elements/Pagination";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import RegisterFaceHeader from "./components/RegisterFaceHeader";
import RegisterFaceTable from "./components/RegisterFaceTable.vue";
import ConfirmRegisterDialog from "./components/ConfirmRegisterDialog.vue";
import { HEADER_MENU_ITEMS_SITE } from "@/constants/GLOBALHEADER";
import Select from "@/components/forms/elements/Select";
import Label from "@/components/forms/elements/Label";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TabSelect from "@/components/forms/elements/TabSelect"; //絞り込みフォームで使用
import {
  TABLE_HEADER_LABELS,
  REGISTRATION_STATUS,
} from "@/constants/REGISTER_FACE";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import InputText from "@/components/forms/elements/InputText.vue";
import SwitchInput from "@/components/forms/elements/SwitchInput";
import InputDatepicker from "@/components/forms/elements/InputDatepicker";
import { ROLE_SUPERVISOR, ROLE_FOREMAN} from "@/constants/COMMON.js";

const PAGE_TITLE = "顔画像登録";

const PAGE_COUNT = 3;

const STORE = "RegisterFace";

const TABLE_LABELS = TABLE_HEADER_LABELS;

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      field_id: null,
      PAGE_TITLE,
      TABLE_LABELS,
      items: [],
      searchParams: {
        field_tree_id: null,
        site_admission_date_from: null,
        site_admission_date_to: null,
        face_reco_status: [],
        no_image_flag: null,
        user_name: null,
        page_count: PAGE_COUNT,
        current_page: 1,
        total_page: 1,
        total_item: 0,
        all_record: 0,
      },
      COMPANY_PARTNER_ARRAY: [],
      selectedItems: [],
      popups: {
        isShowRegisterDialog: false,
      },
      isRegisterBySearchParams: null,
      userImgMap: new Map(),
      isReset: false,
      isResetDisableMap: false,
      isNoDataMessage: false,
    };
  },

  components: {
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    Pagination,
    SitePortalHeader,
    RegisterFaceHeader,
    RegisterFaceTable,
    Select,
    Label,
    SearchFormWrapper,
    TabSelect,
    Popup,
    InputText,
    SwitchInput,
    InputDatepicker,
    ConfirmRegisterDialog,
  },

  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    await this.getItems();
    await Store.dispatch("GlobalHeader/setSiteMenu", {
      menuId: HEADER_MENU_ITEMS_SITE.ENTRANCE.id,
    });

    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      (data, oldData) => {
        if (
          data &&
          JSON.stringify(data) !== JSON.stringify(oldData) &&
          data.field_id
        ) {
          this.field_id = data.field_id;
          this.checkRoleUser(this.field_id);
          this.getItems();
          this.getListCompanyPartner();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Sites/getRole"],
      (data) => {
        if (data && data !== ROLE_SUPERVISOR && data !== ROLE_FOREMAN) {
          Store.dispatch("Error/show", {
            status: 401,
            message: "操作する権限が存在しません。",
          });
          this.$router.push("/portal/dashboard");
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        this.items = data[0];
        this.isNoDataMessage = this.items.length == 0;
        let searchParams = { ...this.searchParams };
        searchParams.total_page = data[1].total;
        searchParams.current_page = parseInt(data[1].current);
        searchParams.total_item = data[1].total_item;
        searchParams.all_record = data[1].all_record;
        this.searchParams = { ...searchParams };
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Sites/getListCompanyPartner"],
      (value) => {
        this.COMPANY_PARTNER_ARRAY = [
          { field_tree_id: null, company_name: "" },
          ...value,
        ];
      },
      {
        deep: true,
      }
    );
  },

  computed: {
    /**
     * API Param
     */
    apiParams() {
      return {
        field_id: this.CURRENT_SITE.field_id,
        site_admission_date_from: this.searchParams.site_admission_date_from,
        site_admission_date_to: this.searchParams.site_admission_date_to,
        field_tree_id: this.searchParams.field_tree_id,
        user_name: this.searchParams.user_name,
        face_reco_status: this.searchParams.face_reco_status,
        no_image_flag: this.searchParams.no_image_flag,
        page_number: this.searchParams.current_page,
      };
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    REGISTRATION_STATUS() {
      return Object.keys(REGISTRATION_STATUS).map((key) => {
        return REGISTRATION_STATUS[key];
      });
    },
    isDisableBtn() {
      return this.selectedItems.length == 0;
    },
    getStartDateRules() {
      return this.searchParams.site_admission_date_to
        ? "required|" +
            `compare-end-date-regis-face:${this.searchParams.site_admission_date_to.replaceAll(
              "-",
              "/"
            )}`
        : "";
    },
    getEndDateRules() {
      const rule = this.searchParams.site_admission_date_from
        ? "required|" +
            `compare-start-date-regis-face:${this.searchParams.site_admission_date_from.replaceAll(
              "-",
              "/"
            )}`
        : "";
      return rule
    },
    isRequiredStart() {
      return !this.searchParams.site_admission_date_from && this.searchParams.site_admission_date_to
    },
    isRequiredEnd() {
      return this.searchParams.site_admission_date_from && !this.searchParams.site_admission_date_to
    },
    isEnableBtnSearch() {
      return (this.searchParams.site_admission_date_from 
        && this.searchParams.site_admission_date_to 
        && this.searchParams.site_admission_date_from <= this.searchParams.site_admission_date_to) || 
        (!this.searchParams.site_admission_date_from && !this.searchParams.site_admission_date_to)
    }
  },
  methods: {
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.current_page = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 検索
     */
    onSearch() {
      this.searchParams["current_page"] = 1;
      this.getItems();
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
    },

    async getChildItems(id, pageNumber) {
      let searchParams = { ...this.searchParams };
      searchParams["field_tree_id"] = id;
      searchParams["page_number"] = pageNumber;
      delete searchParams.total_item;
      delete searchParams.page_count;
      delete searchParams.current_page;
      delete searchParams.total_page;
      const params = {
        params: searchParams,
      };
      await Store.dispatch(`${STORE}/loadChildren`, params);
    },

    async getItems() {
      if (this.field_id) {
        await Store.dispatch(`${STORE}/getListUserResImages`, {
          params: this.apiParams,
        });
      }
    },

    async getListCompanyPartner() {
      if (this.field_id) {
        let params = {
          field_id: this.field_id,
          manager_or_foreman_flg: 1,
        };
        await Store.dispatch("Sites/getListCompanyPartner", { params });
      }
    },
    isSelected(field_tree_id, id) {
      return Boolean(this.selectedItems.find(element => element.field_tree_id ==field_tree_id && element.user_id == id));
    },

    checkSelectedParents(itemParent, map) {
      for (let i = 0; i < itemParent.children.length; i++) {
        if(!itemParent.children[i].profile_image_url && !itemParent.children[i].person_image_url 
          && map?.get(`disable_${itemParent.field_tree_id}_${itemParent.children[i].user_id}`)) {
          return true;
        }
        if (!this.isSelected(itemParent.field_tree_id,itemParent.children[i].user_id)) {
          return false;
        }
      }
      return true;
    },
    /**
     * checkboxの値からselectedItemsを更新
     */
    updateSelectedItems(fieldTreeId,id, isChecked , face_reco_status, hasImage, field_user_reco_image_id) {
      let _selectedItems = [];
      // checked
      if (isChecked) {
        _selectedItems = [...this.selectedItems, {'field_tree_id' : fieldTreeId ,'user_id': id ,'face_reco_status': face_reco_status, 
          hasImage: hasImage, field_user_reco_image_id: field_user_reco_image_id}];
      } else {
        // unchecked
        _selectedItems = this.selectedItems.filter((item) => {
          return item.user_id != id || item.field_tree_id != fieldTreeId ;
        });
      }
      // 重複削除してset
      this.selectedItems = [...new Set(_selectedItems)];
    },
    onRegister(flg) {
      this.isRegisterBySearchParams = flg;
      this.popups = {
        isShowRegisterDialog: true,
      }
    },
    closeConfirmRegisterDialog() {
      this.popups = {
        isShowRegisterDialog: false,
      }
      this.isRegisterBySearchParams = null;
      this.$nextTick(() => {
        this.selectedItems = [];
        this.isResetDisableMap = true;
      });
    },
    async onSubmit() {
      let params = {};
      if(this.isRegisterBySearchParams) {
        const {field_id, site_admission_date_from, site_admission_date_to,
          field_tree_id, user_name, face_reco_status, page_number} = {...this.apiParams};
        params = {
          field_id,
          searches: {
            site_admission_date_from,
            site_admission_date_to,
            field_tree_id,
            user_name,
            face_reco_status,
            page_number
          }
        };
      } else {
        const {field_id} = {...this.apiParams};
        const disapproves = this.selectedItems.filter((item) => item.hasImage);
        const field_user_reco_image_ids = disapproves.map((items) => {
          if(items.field_user_reco_image_id) {
            return items.field_user_reco_image_id;
          } else {
            return this.userImgMap.get(`userImgMap_${items.user_id}`)
          }
        });
        params = {
          field_id,
          field_user_reco_image_ids
        };
      }
      const result = await Store.dispatch(`${STORE}/updateActivities`, params);
      if(!result.hasError) {
        this.closeConfirmRegisterDialog();
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
      this.isReset = !this.isReset;
    },
    updateUserImgMap(params) {
      this.userImgMap = params;
      this.getItems();
    },

    async checkRoleUser(field_id){
      if (!field_id) return;
      const params = {
        field_id,
        target_role: []
      };
      await Store.dispatch("Sites/getRole", params);
    },
  },
};
</script>

<style lang="scss" scoped>
.tableSortWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 64px;
  .sortLabel {
    font-size: 12px;
    height: 32px;
    margin-right: 16px;
  }
  .sortElement {
    max-width: 168px;
    margin-right: 24px;
  }
  .total_item {
    padding-bottom: 15px;
  }
  .button_search {
    margin-right: 28px;
  }
}
.textBold {
  font-size: 20px !important;
  margin-left: 10px;
  margin-top: 37px;
}
</style>
