<template>
  <div>
    <PlacementAppHeader
      ref="placementAppHeader"
      :chat_room_id="chat_room_id"
      @openChat="onOpenChat"
      @onPrint="onPrint"
      @onSave="onSave"
      @onReload="onReload"
      @onCreateChatRoom="onCreateChatRoom"
    />
    <div ref="placementApp" class="placementApp">
      <div ref="leftPanel" class="leftPanel">
        <div class="SpriteMenu my-3">
          <v-btn :disabled='!isMoveable' icon large @click="onCallMainpanel('addShape')" class="ml-3">
            <v-icon>mdi-shape-outline</v-icon>
          </v-btn>
          <v-btn :disabled='!isMoveable' icon large @click="onCallMainpanel('addText')" class="ml-3">
            <v-icon>mdi-format-text</v-icon>
          </v-btn>
          <v-btn :disabled='!isMoveable' icon depressed large @click="onCallMainpanel('addImage')" class="ml-3">
            <v-icon>mdi-file-image</v-icon>
          </v-btn>

          <v-btn icon large @click="onCallMainpanel('dropdown_history')" class="ml-3">
            <v-icon>mdi-calendar</v-icon>
          </v-btn>

          <!-- <v-btn icon large @click="onCallMainpanel('showCanvas')" class="ml-3">
            <v-icon>mdi-draw</v-icon>
          </v-btn> -->
        </div>
        <v-divider></v-divider>
        <MachineMenu :isMoveable="isMoveable" @addMachine="onCallMainpanelAddMachine" />
      </div>
      <div class="centerPanel" :style="mainPanelStyle">
        <!-- メイン画面 -->
        <MainCanvas>
          <Mainpanel
            ref="mainPanel"
            :readOnly="isShoot"
            :items="items"
            :background="background"
            :isMoveable="isMoveable"
            @on-update-items="onUpdateItems"
            @on-update-background="onUpdateBackground"
          />
        </MainCanvas>
        <SpriteEditMenu :items="items" @on-edit-items="onUpdateItems" />
        <Submenu :items="items" @on-edit-items="onUpdateItems" />
        <v-list-item :style="{'display': isDisplayDropdownHistory ? 'block': 'none'}">
          <v-list-item-content>
            <Select
              class="dropdown_history"
              item_text="date"
              item_value="drawing_layout_id"
              :editable="true"
              :items="LAYOUT_HISTORY_ARRAY"
              @onInput="onChangedrawingLayout"
            />
          </v-list-item-content>
        </v-list-item>

        <!-- 非表示のfile input -->
        <form ref="form">
          <input
            style="display: none"
            ref="input_update_image"
            type="file"
            accept="image/jpeg, image/jpg, image/png, application/pdf"
            @change="onImageInput()"
          />
        </form>

      </div>
      <div class="chatViewWrapper" v-show="isShowChat">
        <div class="chatViewWrapper__chatview">
          <!-- ここにチャットを表示 -->
          <ChatView :chat_room_id="chat_room_id" :user_id="USER_ID" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Firebase } from "@/views/oekaki/components/util/FirebaseUtil.js";
import html2canvas from "html2canvas";
import PlacementAppHeader from "./components/components/PlacementAppHeader.vue";
import Mainpanel from "@/views/oekaki/components/components/Mainpanel.vue";
import MachineMenu from "@/views/oekaki/components/components/MachineMenu/index.vue";
import { UndoRedo } from "./components/util/UndoRedo";
import {
  KeyCapture,
  KEY_ACTIONS,
} from "@/views/oekaki/components/util/KeyCapture.js";
import MainCanvas from "@/views/oekaki/components/components/MainCanvas.vue";
import SpriteEditMenu from "@/views/oekaki/components/components/SpriteEditMenu/index.vue";
import Submenu from "@/views/oekaki/components/components/SubmenuWrapper/index.vue";
import { Store } from "@/store/Store.js";
// import { ENDPOINT_URL, USER_ACCESS_TOKEN, USER_ID } from "./AZURE_CONFIG";
import { getParamsOfUrlReadFile } from "@/utils/viewSourceAuth";
import { INITIAL_PARAM } from "./components/constants/INITIAL_PARAM";
// import ChatView from "@/views/textchat/chat/index.vue";
import ChatView from "@/views/textchat/wrapper/indexOekakiWeb.vue";
import { ROOM_APPROVAL_FLAG } from "@/store/modules/api/TextChatManage";
import Select from "@/components/forms/elements/Select.vue";
import _ from 'lodash';
import jsPDF from "jspdf";

// const image =
//   "https://firebasestorage.googleapis.com/v0/b/bacx-65724.appspot.com/o/uploads%2Fsamne-kentiku1.png?alt=media&token=671ff8df-cb83-4ef7-9abd-2bc4ce7422ba";

const SOCKET_CALLBACKS = {
  UPDATE: "update",
};

// ソケット監視
const SocketController = () => {
  // ソケットの連続リッスンを遅延実行
  let callbackTimerUpdate;
  const initListen = (field_image_id, callback) => {
    window.Echo.channel(field_image_id).listen("UpdateDraw", (e) => {
      clearTimeout(callbackTimerUpdate);
      callbackTimerUpdate = setTimeout(() => {
        callback(SOCKET_CALLBACKS.UPDATE, e.field_image_id, e);
      }, 200);
    });
  };

  const addRoomListen = (roomId, callback) => {
    initListen(roomId, callback);
  };

  return {
    addRoomListen,
  };
};

export default {
  name: "Oekaki",
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: "調整会議" };
    },
  },
  components: {
    PlacementAppHeader,
    Mainpanel,
    MachineMenu,
    MainCanvas,
    SpriteEditMenu,
    Submenu,
    ChatView,
    Select,
  },
  data: () => {
    return {
      // Firebase: Firebase(),
      field_image_id: null,
      // レイアウトするスプライト
      items: [],
      //チャットid
      chat_room_id: null,
      //現場id
      field_id: null,
      // 背景図面
      background: {
        id: 10000,
        image_url: null,
        image_url_blob: null,
        transform: {
          z_index: 0,
          x: 0,
          y: 0,
          width: 800,
          height: 800,
          rotation: 0,
        },
      },

      //チャット
      isShowChat: false,

      // スクショ
      isShoot: false,
      tmpScreenShot: null,

      // メインパネルのサイズ
      mainPanelSize: {
        width: 0,
        height: 0,
      },

      //ウィンドウサイズ
      windowSize: {
        width: 0,
        height: 0,
      },

      //保存遅延実行タイマー
      onSaveDelayHandler: null,

      //
      ENDPOINT_URL: null,
      USER_ACCESS_TOKEN: null,
      USER_ID: null,
      MASTER_ACCESS_TOKEN: null,
      MASTER_ID: null,
      IMAGE_ID: null,
      loginUserName: "guest",
      LAYOUT_HISTORY_ARRAY: [{drawing_layout_id: null, date: ""}],
      isMoveable: true,
      isDisplayDropdownHistory: false,
      drawingLayoutId: "",
      backgroundImageUrl: "",
    };
  },
  destroyed() {
    KeyCapture.destroy();
  },
  computed: {
    mainPanelStyle() {
      return `width:${this.mainPanelSize.width}px;height:${this.mainPanelSize.height}px`;
    },
  },
  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    // ユーザー情報
    this.$watch(
      () => Store.getters[`Login/getUser`],
      async (data) => {
        if (data) this.USER_ID = data.id;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    // 現場id
    this.$watch(
      () => Store.getters[`GlobalHeader/getCurrentSite`],
      async (data) => {
        if (data && "field_id" in data) {
          this.field_id = data.field_id;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    // 図面idを取得 -> ロード
    this.$watch(
      () => this.$route.query,
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          if ("image_id" in newValue && newValue.image_id) {
            this.field_image_id = +newValue.image_id;
            this.loadData({ field_image_id: +newValue.image_id });
            this.getImage({ field_image_id: +newValue.image_id });

            //ソケット通知
            SocketController().addRoomListen(
              `field_image_${this.field_image_id}`,
              this.socketCallback
            );
          } else {
            //TODO 画像idがないことを通知
            // console.log("TODO 画像idがないことを通知する", newValue);
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.getHistory({ field_image_id: this.field_image_id });

    UndoRedo.setCallback(({ items }) => {
      this.items = items;
      //遅延実行で保存
      if (this.onSaveDelayHandler) clearTimeout(this.onSaveDelayHandler);
      this.onSaveDelayHandler = setTimeout(() => {
        this.onSave();
      }, 300);
    });

    // キーボード入力
    KeyCapture.init();
    KeyCapture.setCallback((key) => {
      //undo
      if (key === KEY_ACTIONS.UNDO) {
        UndoRedo.undo();
      }
      //redo
      if (key === KEY_ACTIONS.REDO) {
        UndoRedo.redo();
      }
    });

    //サイズ
    window.addEventListener("resize", () => {
      this.getWindowSize();
    });
    this.getWindowSize();
  },

  methods: {
    async getImage(params) {
      const data = await Store.dispatch("Oekaki/getImage", params);
      const entries = { ...data.contents.entries };

      if (entries && "image_url" in entries) {
        //新しいパラメータ作成
        const image_url = entries.image_url;
        this.getImageByURL(image_url)
      }
    },
    async getImageByURL(image_url) {
      if (!image_url) return;
        const params = getParamsOfUrlReadFile(image_url);

        //画像を取得
        const response = await Store.dispatch("File/readFile", params);
        if (!response.hasError) {
          const img = window.URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers["content-type"],
            })
          );
          const background = { ...this.background };
          background.image_url_blob = img;
          background.image_url = image_url.replace(`${process.env.VUE_APP_WEB_BASE_URL}`,"");
          this.background = background;
        }
    },
    convertJsonNewToOld(items){
        items.forEach(item => {
          //TEXTBOX
          if(item.image_svg_type=="text"){
            item.text=item.transform.text;
            item.transform.z_index=item.z_index;
            item.color=item.transform.fill;
            item.text_bold=false;
            item.text_size =item.transform.font_size;
            item.transform.rotation=item.transform.rotate;
            item.text_align=item.transform.text_align;
            item.text_color=item.transform.fill;
          }
          //SHAPE RECTANGLE
          else if(item.image_svg_type=="rectangle"){
            item.image_svg="Rectangle_" + item.transform.fill;
            item.color=item.transform.fill;
            item.transform.z_index=item.z_index;
            item.transform.rotation=item.transform.rotate;
          }
          //SHAPE ELIPPSE
          else if(item.image_svg_type=="ellipse"){
            item.image_svg="Circle_"+ item.transform.fill;
            item.color=item.transform.fill;
            item.transform.z_index=item.z_index;
            item.transform.rotation=item.transform.rotate;
            item.transform.x=item.transform.cx-item.transform.width/2;
            item.transform.y=item.transform.cy-item.transform.height/2;
          }
          //IMAGE CRANE
          else if(item.image_svg_type=="image"){
            item.transform.z_index=item.z_index;
            item.transform.rotation=item.transform.rotate;
            item.machine_id=item.transform.machine_id;
            item.image_base64 = item.transform.base64;
          }
        });
    },

    async loadData(params, isSetHistory = true) {
      const data = await Store.dispatch("Oekaki/loadData", params);
      const entries = { ...data.contents.entries };

      if("id" in entries && !this.drawingLayoutId) {
        this.drawingLayoutId = entries.id;
      }

      // チャットのidを渡す
      if ("chat_room_id" in entries && entries.chat_room_id) {
        this.chat_room_id = entries.chat_room_id;
      }

      if ("layout" in entries && entries.layout.length) {
        const contentsArray = JSON.parse(entries.layout);
        this.backgroundImageUrl =  `${process.env.VUE_APP_WEB_BASE_URL}`+ contentsArray.background.image_url;
        await this.getImageByURL(this.backgroundImageUrl);
        //アイテム
        // TODO 古い形式は配列でかえる
        const { contents } = Array.isArray(contentsArray)
          ? contentsArray[0]
          : contentsArray;
        this.items = contents.filter((v) => v);
        this.items = this.items.map((item) => {
          return {
            ...INITIAL_PARAM,
            ...item,
          };
        });
        this.convertJsonNewToOld(this.items);

        UndoRedo.syncHistory(this.items);
        if (isSetHistory) {
          UndoRedo.setHistory(this.items); //ヒストリに追加
        }

      }
    },

    async loadDataSocket(params, isSetHistory = true, result) {
      let contentsArray;
      if (result && _.get(result, "0")) {
        contentsArray = JSON.parse(_.get(result, "0"));
      } else {
        const data = await Store.dispatch("Oekaki/loadData", params);
        const entries = { ...data.contents.entries };
        if ("layout" in entries && entries.layout.length) {
          contentsArray = JSON.parse(entries.layout);
        }
      }

      if (contentsArray) {
        //アイテム
        // TODO 古い形式は配列でかえる
        const { contents } = Array.isArray(contentsArray)
          ? contentsArray[0]
          : contentsArray;
        this.items = contents.filter((v) => v);
        this.items = this.items.map((item) => {
          return {
            ...INITIAL_PARAM,
            ...item,
          };
        });
        this.convertJsonNewToOld(this.items);
        UndoRedo.syncHistory(this.items);
        if (isSetHistory) {
          UndoRedo.setHistory(this.items); //ヒストリに追加
        }

      }
    },

    async getHistory(params) {
      const data = await Store.dispatch("Oekaki/getHistory", params)
      const entries = { ...data.contents.entries };
      let historyList = [];
      if ("historyList" in entries && entries.historyList.length) {
        historyList.push(this.buildHistoryDateElement("drawing_layout_id", "date", null, "今日"));
        entries.historyList.forEach(element => {
          let dateArrays = element.date.split("-");
          let drawing_layout_id = element.drawing_layout_id;
          let date = dateArrays.length == 3 ? dateArrays[0] + "年" + dateArrays[1] + "月" + dateArrays[2] + "日" : "";
          historyList.push(this.buildHistoryDateElement("drawing_layout_id", "date", drawing_layout_id, date));
        });
      }
      this.LAYOUT_HISTORY_ARRAY = historyList.length ? historyList : [this.buildHistoryDateElement("drawing_layout_id", "date", null, "今日")];
    },

    // ソケット
    socketCallback(TYPE, id, result) {
      this.loadDataSocket({ field_image_id: this.field_image_id }, false, result);
    },

    // 追加
    onCallMainpanel(method) {
      if (method === "addShape") this.$refs.mainPanel.addShape();
      if (method === "showCanvas") this.$refs.mainPanel.showCanvas();
      if (method === "addText") this.$refs.mainPanel.addText();
      if (method === "dropdown_history") this.isDisplayDropdownHistory = !this.isDisplayDropdownHistory;
      if (method === "addImage") this.$refs.input_update_image.click();
    },

    onChangedrawingLayout({value}) {
      this.isMoveable = (value == null);
      this.drawingLayoutId = value;
      if (!this.isMoveable) {
        this.loadData({ field_image_id: this.field_image_id, drawing_layout_id: value});
      } else {
        this.loadData({ field_image_id: this.field_image_id });
        this.getImage({ field_image_id: this.field_image_id });
      }
      this.isDisplayDropdownHistory = false;
      UndoRedo.clearHistory();
    },

    onImageInput() {
      const file = this.$refs.input_update_image.files[0];
      if (!file) {
        return;
      }
      this.$refs.form.reset();
      if (file.name.toLocaleLowerCase().match(/\.(jpg|jpeg|png)$/)) {
        if (file.size >= 1 * 1024 * 1024) {
          Store.dispatch(
            "Error/show",
            {
              status: "400",
              message: "アップロードのファイルは、最大1MBまでです。",
            },
            { root: true }
          );
          return;
        }
      } else {
        Store.dispatch(
          "Error/show",
          {
            status: "400",
            message: "jpg, png, jpeg ファイルのみアップロード可能です。",
          },
          { root: true }
        );
        return;
      }
      this.getBase64(file)
        .then((data64) => {
          var mainPanel=this.$refs.mainPanel;
          var i = new Image(); 
          i.src = data64;
          i.onload = function(){
            var tempWidth= 100;
            var tempHeight=100/i.width*i.height;
            i=null; 
            mainPanel.addSpriteFromCanvas(data64, {width: tempWidth, height: tempHeight});
          };
        })
        .catch(() => console.log("ファイルアップロードエラー"));
    },

    // Base64変換
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    onCallMainpanelAddMachine(value) {
      this.$refs.mainPanel.addMachine(value);
    },

    // 更新
    onUpdateItems(items) {
      this.items = items;
      if (items) UndoRedo.setHistory(this.items); //ヒストリに追加

      //遅延実行で保存
      if (this.onSaveDelayHandler) clearTimeout(this.onSaveDelayHandler);
      this.onSaveDelayHandler = setTimeout(() => {
        this.onSave();
      }, 300);
    },

    onUpdateBackground(background) {
      this.background = background;
    },

    onOpenChat() {
      this.isShowChat = !this.isShowChat;
    },

    // リロード
    onReload() {
      this.loadData({ field_image_id: this.field_image_id });
    },

    async onPrint() {
      const content = document.getElementById("mainPanel");
      const HTML_Width = 2400;
      const HTML_Height = 1800;
      const top_left_margin = 15;
      const PDF_Height = HTML_Height + (top_left_margin * 2);
      const PDF_Width = HTML_Width + (top_left_margin * 2);
      const canvas_image_width = HTML_Width;
      const canvas_image_height = HTML_Height;
      const totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
      const field_image_id = this.field_image_id;
      const drawing_layout_id = this.drawingLayoutId;
      const exportFileName = this.getDateOfPrint() + "_" + "field_image_id_" + field_image_id + "_drawing_layout_id" + drawing_layout_id + ".pdf";

      html2canvas(content).then(function (canvas) {
          const imgData = canvas.toDataURL("image/jpeg", 1.0);
          const pdf = new jsPDF('l', 'pt', [PDF_Width, PDF_Height]);
          pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
          for (let i = 1; i <= totalPDFPages; i++) { 
              pdf.addPage(PDF_Width, PDF_Height);
              pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height*i)+(top_left_margin*4),canvas_image_width,canvas_image_height);
          }
          pdf.save(exportFileName);

          const pdfContent = pdf.output("dataurlstring", exportFileName);
          Store.dispatch("Oekaki/saveExportedFile", {
              field_image_id: field_image_id,
              drawing_layout_id: drawing_layout_id,
              content_file: pdfContent
          });
          content.disable = true;
      });
    },

    getDateOfPrint() {
      if(this.drawingLayoutId) {
        const index = this.LAYOUT_HISTORY_ARRAY.findIndex(o1 => (o1.drawing_layout_id == this.drawingLayoutId));
        if(index > 0) {
          return this.LAYOUT_HISTORY_ARRAY[index].date;
        }
      }
      return this.getCurrentDate();
    },

    getCurrentDate() {
      const date = new Date();
      const month= date.getMonth() + 1;
      return date.getFullYear() + "年" + month + "月" + date.getDate() + "日";
    },

    // 保存
    async onSave() {
      const tempItems=JSON.parse(JSON.stringify(this.items));
      tempItems.forEach(item => {
        //TEXTBOX
          if(item.text){
            item.image_svg_type="text";
            item.z_index=item.transform.z_index;
            item.transform.text=item.text;
            item.transform.font_size=item.text_size;
            item.transform.rotate=item.transform.rotation;
            item.transform.text_align = item.text_align;
            item.transform.fill = item.text_color;
            //remove old atrribute
            delete item.text;
            delete item.color;
            delete item.image_svg;
            delete item.image_url;
            delete item.text_bold;
            delete item.text_size;
            delete item.machine_id;
            delete item.text_align;
            delete item.text_color;
            delete item.image_base64;
            delete item.image_side_url;
          }
          //SHAPE RECTANGLE
          else if(item.image_svg && item.image_svg.includes("Rectangle")){
            item.image_svg_type="rectangle";
            item.z_index=item.transform.z_index;
            item.transform.rotate=item.transform.rotation;
            item.transform.fill=item.image_svg.substring(10);
            //remove old atrribute
            delete item.text;
            delete item.color;
            delete item.image_svg;
            delete item.image_url;
            delete item.text_bold;
            delete item.text_size;
            delete item.machine_id;
            delete item.text_align;
            delete item.text_color;
            delete item.image_base64;
            delete item.image_side_url;
          }
          //SHAPE ELIPPSE
          else if(item.image_svg &&  item.image_svg.includes("Circle")){
            item.image_svg_type="ellipse";
            item.z_index=item.transform.z_index;
            item.transform.rotate=item.transform.rotation;
            item.transform.cx=parseFloat(item.transform.x)+parseFloat(item.transform.width)/2;
            item.transform.cy=parseFloat(item.transform.y)+parseFloat(item.transform.height)/2;
            item.transform.rx=item.transform.width/2;
            item.transform.ry=item.transform.height/2;
            item.transform.fill=item.image_svg.substring(7);
            //remove old atrribute
            delete item.text;
            delete item.color;
            delete item.image_svg;
            delete item.image_url;
            delete item.text_bold;
            delete item.text_size;
            delete item.machine_id;
            delete item.text_align;
            delete item.text_color;
            delete item.image_base64;
            delete item.image_side_url;
          }
          //IMAGE CRANE
          else if(item.machine_id || item.image_base64){
            item.image_svg_type="image";
            item.z_index=item.transform.z_index;
            item.transform.rotate=item.transform.rotation;
            item.transform.machine_id=item.machine_id;
            item.transform.base64=item.image_base64;
            //remove old atrribute
            delete item.text;
            delete item.color;
            delete item.image_svg;
            delete item.image_url;
            delete item.text_bold;
            delete item.text_size;
            delete item.machine_id;
            delete item.text_align;
            delete item.text_color;
            delete item.image_base64;
            delete item.image_side_url            
          }
      });
      await Store.dispatch("Oekaki/saveData", {
        field_image_id: +this.field_image_id,
        layout: {
          contents: tempItems,
          background: this.background,
        },
      });
    },

    async onCreateChatRoom() {
      const params = {
        name: `図面 ${this.field_image_id}`,
        field_id: this.field_id,
        field_image_id: this.field_image_id,
        approval_flag: ROOM_APPROVAL_FLAG.APPROVED,
      };
      const result = await Store.dispatch(`TextChatManage/roomCreate`, params);

      // 409以外はエラー表示
      // 409はすでにルームがある
      if (result.hasError && +result.response?.status !== 409) {
        Store.dispatch("Error/show", {
          status: 200,
          message: "作成できませんでした",
        });
        return;
      }
    },

    // メインパネルのサイズ変更
    getWindowSize() {
      this.windowSize = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      const headerRect =
        this.$refs.placementAppHeader?.$el.getBoundingClientRect();
      const leftPanelRect = this.$refs.leftPanel.getBoundingClientRect();
      const width = window.innerWidth - leftPanelRect.width;
      const height = window.innerHeight - headerRect.height;

      this.mainPanelSize = {
        width,
        height,
      };
    },
    buildHistoryDateElement(id_text, value_text, id, value) {
      let results = {};
      results[id_text] = id;
      results[value_text] = value;
      return results;
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 0;
  margin: 0;
}

.placementApp {
  display: flex;
  height: calc(100vh - 48px);
  width: 100vw;
}
.leftPanel {
  width: 240px;
  border-right: 1px solid black;
  background-color: white;
}
.rightPanel {
  width: 240px;
  height: 100%;
  background-color: white;
}
.centerPanel {
  flex: 1;
  height: 100%;
  position: relative;
  overflow: auto;
}
.mainPanelGoast {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.previewScreenshot {
  position: fixed;
  bottom: 32px;
  right: 32px;
  width: 320px;
  height: 320px;
  display: flex;
  align-items: center;
  border: 1px solid black;
  z-index: 999;
  background-color: white;
  img {
    width: 100%;
  }
}

.SpriteMenu {
  display: flex;
}

.chatViewWrapper {
  width: 320px;
  height: calc(100% - 48px);
  position: fixed;
  top: 48px;
  right: 0;
  background-color: white;
  border-left: 1px solid #efefef;
  z-index: 998;
  display: flex;
  flex-direction: column;
}
.chatViewWrapper__header {
}
.chatViewWrapper__chatview {
  padding: 0 8px;
  flex: 1;
  height: calc(100% - 84px);
}
.inActive {
  opacity: 30%;
}
.dropdown_history {
  width: 160px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 60px;
  z-index: 999;
}
</style>
