<template>
  <div class="FormMain">
    <div ref="mainBody" class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-container class="mt-8">
          <v-row class="mb-2">
            <v-btn depressed color="primary"
              v-if="isOwner && roleUser == this.ROLE_OWNER"
              medium
              class="ml-15"
              @click="$emit('onClickCreateReportsOwner')"
            >
              作成
            </v-btn>
            <v-btn depressed color="primary"
              v-if="!isOwner && userPermissions.includes(SAFETY_PERMISSIONS.CREATE)"
              medium
              class="ml-8 mr-2"
              :key="flagError"
              @click.once="$emit('onCreateReport')"
            >
              作成
            </v-btn>
            <v-btn
              v-if="!isOwner && userPermissions.includes(SAFETY_PERMISSIONS.CREATE)"
              depressed
              medium
              color="primary"
              class="mr-2"
              :disabled="!selectedReport.id"
              @click="onSendNotify"
            >
              提出
            </v-btn>
            <v-btn
              v-if="!isOwner && userPermissions.includes(SAFETY_PERMISSIONS.APPROVE)"
              :disabled="!formStatusCode || formStatusCode === APPROVED_FLAGS.REJECTED.id"
              depressed
              medium
              color="warning"
              :class="NOT_USER_OF_COMPANY?'mr-2 ml-8':'mr-2'"
              @click="$emit('onClickRejected')"
            >
              差戻し
            </v-btn>
            <v-btn
              v-if="!isOwner && userPermissions.includes(SAFETY_PERMISSIONS.APPROVE)"
              :disabled="!formStatusCode || formStatusCode === APPROVED_FLAGS.APPROVED.id"
              depressed
              medium
              color="primary"
              class="mr-2"
              @click="$emit('onClickApproved')"
            >
              承認
            </v-btn>
          </v-row>
          <v-row no-gutters>
            <v-col sm="3" md="3" class="pdfViewer" :style="getBodyHistoryStyle">
              <v-radio-group
                v-model="selectedReport"
              >
                <!-- 履歴 -->
                <div class="historyItem" v-for="(item, index) in document" :key="index">
                  <!-- add row radio -->
                  <div class="col_radio_report ml-3" v-if="item.form_status_code == APPROVED_FLAGS.CREATED.id && !isOwner">
                    <v-radio
                      :key="item.id"
                      :value="item"
                    ></v-radio>
                  </div>
                  <div class="col_status ml-3" v-else>
                    <span
                      class="historyItem__status"
                      :class="item ? statusClass(item.form_status_code) : ''"
                      >{{
                        item ? getApproveStatus(item.form_status_code).name : ""
                      }}</span
                    >
                  </div>
                  <div class="col_date">
                    <span class="historyItem__date">{{ item.created_at }}</span>
                  </div>
                  <div class="col_buttons">
                    <button
                      v-if="
                        !isOwner &&
                        item.form_status_code == APPROVED_FLAGS.REJECTED.id
                      "
                      class="historyItem__btn"
                    >
                      <v-icon size="14" @click="onMessage(item.message)"
                        >mdi-comment-text-outline</v-icon
                      >
                    </button>
                    <button
                      v-if="
                        isOwner ||
                        item.form_status_code == APPROVED_FLAGS.CREATED.id ||
                        item.form_status_code == APPROVED_FLAGS.SUBMITED.id
                      "
                      class="historyItem__btn"
                      :disabled="isSubmitted"
                      @click="
                        downloadDocument(item.id, SAFETY_DOCUMENT_TYPE.EXCEL)
                      "
                    >
                      <MarkExcel />
                    </button>
                    <button
                      v-if="
                        isOwner ||
                        item.form_status_code == APPROVED_FLAGS.CREATED.id ||
                        item.form_status_code == APPROVED_FLAGS.SUBMITED.id
                      "
                      class="historyItem__btn"
                      :disabled="isSubmitted"
                      @click="downloadDocument(item.id, SAFETY_DOCUMENT_TYPE.PDF)"
                    >
                      <MarkPdf />
                    </button>
                  </div>
                </div>
              </v-radio-group>
            </v-col>
            <v-col sm="9" md="9" class="pl-4">
              <!-- PDFナビゲーション -->
              <div ref="pdfNavigation" class="pdfNavigation" v-if="document && document.length > 0">
                <div>
                  <span
                    class="historyItem__status"
                    :class="statusClass(selectedPdf.form_status_code)"
                    >{{
                      getApproveStatus(selectedPdf.form_status_code).name
                    }}</span
                  >
                  <span class="historyItem__date">{{
                    selectedPdf.created_at
                  }}</span>
                </div>
                <div class="pdfNavigation__pages">
                  {{ pdfPage.currentPage }} / {{ pdfPage.pageCount }}
                </div>
                <div class="pdfNavigation__navi">
                  <v-btn
                    small
                    icon
                    :disabled="pdfPage.currentPage === 1"
                    @click="pdfNaviPrev"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn
                    small
                    icon
                    :disabled="pdfPage.currentPage === pdfPage.pageCount"
                    @click="pdfNaviNext"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </div>
              <div ref="pdfNavigation" class="pdfNavigation" v-if="isNoDataMessage">
                {{ NO_DATA_MESSAGE }}
              </div>
              <div ref="pdfViewer" class="pdfViewer" :style="pdfPage.style">
                <v-card outlined tile v-if="urlPdf">
                  <!-- PDF -->
                  <pdf
                    :src="urlPdf"
                    :page="pdfPage.selectPage"
                    @num-pages="pdfPage.pageCount = $event"
                    @page-loaded="pdfPage.currentPage = $event"
                  ></pdf>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
  </div>
</template>
<script>
import pdf from "vue-pdf";
import { APPROVED_FLAGS, SAFETY_DOCUMENT_TYPE, SAFETY_PERMISSIONS, ROLE_OWNER } from "@/constants/SAFETY_CHART";
import { NO_DATA_MESSAGE } from "@/constants/COMMON.js";
import MarkExcel from "./MarkExcel.vue";
import MarkPdf from "./MarkPdf.vue";
import { format } from "date-fns";
import { Store } from "@/store/Store.js";
import { getParamsOfUrlReadFile } from "@/utils/viewSourceAuth";

const URL = `${process.env.VUE_APP_API_BASE_URL}`;
const DOWNLOAD_URL = `${URL}/reports/export`;

export default {
  data: () => {
    return {
      SAFETY_DOCUMENT_TYPE,
      NO_DATA_MESSAGE,
      APPROVED_FLAGS,
      SAFETY_PERMISSIONS,
      ROLE_OWNER,
      selectedPdf: {
        id: null,
        form_status_code: null,
        created_at: null,
        print_excel_status: null,
        print_pdf_status: null,
      },
      document: [],
      pdfPage: {
        pageCount: 0,
        currentPage: 0,
        selectPage: 1,
        style: "",
      },
      urlPdf: null,
      typeReport: null,
      isSubmitted: false,
      timeout: null,
      selectedReport: {id: null},
      formStatusCode: null,
      isNoDataMessage: false,
    };
  },
  components: {
    MarkExcel,
    MarkPdf,
    pdf,
  },
  props: {
    forms: {
      type: Array,
    },
    tab: {
      type: Number
    },
    histories: {
      type: Object,
    },
    mainHeight: {
      type: Number,
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
    userPermissions: {
      type: Array
    },
    flagError: Boolean,
    currenTab : Number
  },
  mounted() {
    this.$watch(
      () => this.mainHeight,
      () => {
        this.updatePdfViewerHeight();
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => [Store.getters["File/getData"], Store.getters["File/getTypeDoc"]],
      (data) => {
        if (data && data[0] && data[1] && this.currenTab == this.tab) {
          const date = new Date();
          const url = window.URL.createObjectURL(new Blob([data[0]]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${
              data[1] == SAFETY_DOCUMENT_TYPE.PDF
                ? this.forms[this.tab].title_eng
                : this.forms[this.tab].title_ja
            }_${format(date, "yyyyMMddHHmmss")}.${
              data[1] == SAFETY_DOCUMENT_TYPE.PDF ? "pdf" : "xlsx"
            }`
          );
          document.body.appendChild(link);
          link.click();
          Store.dispatch("File/clearData");
        }
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => [this.tab, this.histories],
      async (newValue, oldValue) => {
        if(!oldValue || JSON.stringify(newValue[1]) !== JSON.stringify(oldValue[1])) {
          //タブ選択されていて、履歴がある場合
          if (newValue[0] > -1 && newValue[1]) {
            const { id } = this.forms[newValue[0]];
            this.typeReport = id.toUpperCase();
            let document = newValue[1][id]?.gf_list ? newValue[1][id].gf_list : [];
            this.formStatusCode = newValue[1][id]?.form_status_code;
            this.document = [...document];
            this.isNoDataMessage = this.document.length == 0;
            this.selectedReport = this.document.find(e => e.form_status_code == this.APPROVED_FLAGS.CREATED.id) || {};
            let url = newValue[1][id]?.gf_lastest_file ? newValue[1][id].gf_lastest_file : null;
            if(url) {
              const params = getParamsOfUrlReadFile(url);
              const response = await Store.dispatch("File/readFile", params);
              if(!response.hasError) {
                this.urlPdf = window.URL.createObjectURL(new Blob([response.data], { type: response.headers["content-type"]}));
              }
            }
          }

          // タブが選択されていて、pdfが何も表示されていない時
          if (this.document.length) {
            this.setLatestPdf();
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    getBodyHistoryStyle() {
      return this.pdfPage.style;
    },
    roleUser() {
      return Store.getters["Sites/getRole"];
    },
    NOT_USER_OF_COMPANY() {
      return !this.userPermissions.includes(SAFETY_PERMISSIONS.CREATE);
    }
  },
  methods: {
    setLatestPdf() {
      this.selectedPdf = this.document[0];
    },
    statusClass(id) {
      const flag = Object.keys(APPROVED_FLAGS)
        .map((key) => {
          return APPROVED_FLAGS[key];
        })
        .find((item) => item.id === id);
      if (!flag) return ``;
      return `status_${flag.key}`;
    },
    getApproveStatus(id) {
      if (id == null) return { name: "" };
      return Object.keys(APPROVED_FLAGS)
        .map((key) => {
          return APPROVED_FLAGS[key];
        })
        .find((item) => item.id === id);
    },
    updatePdfViewerHeight() {
      const mainBody = this.$refs.mainBody;
      const pdfViewer = this.$refs.pdfViewer;
      if (pdfViewer && mainBody) {
        const mainBodyTop = mainBody.getBoundingClientRect().top;
        const pdfViewerTop = pdfViewer.getBoundingClientRect().top;
        const marginTop = mainBodyTop - pdfViewerTop;
        const pdfPage = { ...this.pdfPage };
        pdfPage.style = `height:${this.mainHeight + marginTop - 32}px;`;
        this.pdfPage = pdfPage;
      }
    },
    pdfNaviPrev() {
      const pdfPage = { ...this.pdfPage };
      pdfPage.selectPage = pdfPage.selectPage > 1 ? pdfPage.selectPage - 1 : 1;
      this.pdfPage = pdfPage;
    },
    pdfNaviNext() {
      const pdfPage = { ...this.pdfPage };
      pdfPage.selectPage =
        pdfPage.selectPage < pdfPage.pageCount
          ? pdfPage.selectPage + 1
          : pdfPage.pageCount;
      this.pdfPage = pdfPage;
    },

    onMessage(message) {
      this.$emit("onMessage", message);
    },

    async downloadDocument(id, typeDoc) {
      this.isSubmitted = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isSubmitted = false
      }, 1000);

      const params = {
        gf_report_id: id,
        file_code: typeDoc,
      };
      const configParams = { 
        url: DOWNLOAD_URL,
        params: params
      }
      await Store.dispatch("File/download", configParams);
    },
    onSendNotify() {
      if (!this.selectedReport?.id) return;
      this.$emit("onSendNotify", this.selectedReport);
    },
  },
  /**
   * Important: clear timeout
   */
  beforeDestroy () {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout)
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
@import "@/components/forms/style/forms.scss";
.FormMainBody {
  overflow: hidden;
}
.pdfViewer {
  overflow: auto;
}
.pdfNavigation {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
}
.historyItem {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 8px;
}
.col_status {
  width: 60px;
}
.col_radio_report {
  width: 60px;
  display: flex;
  justify-content: flex-end;
}
.col_date {
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
}
.col_buttons {
  width: 48px;
  display: flex;
  justify-content: space-between;
}
.historyItem__status {
  font-size: 9px;
  color: white;
  padding: 0 6px;
  height: 18px;
  border-radius: 4px;
  line-height: 18px;
  &.status_approved {
    background-color: $color_approved;
  }
  &.status_rejected {
    background-color: $color_rejected;
  }
  &.status_submited {
    background-color: $color_created;
  }
}
.historyItem__date {
  // font-size: 12px;
  margin: 0 4px;
}
.historyItem__btn {
  display: flex;
  align-items: center;
  margin-left: 8px;
  &.none {
    display: none;
    cursor: default;
    pointer-events: none;
  }
}
</style>
