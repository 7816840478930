<template>
  <div class="mt-5 mb-cst">
    <!-- List button -->
    <template>
      <v-btn
        depressed
        class="mx-3"
        :disabled="isDisabledBtn"
        @click="openConfirmSendOff()"
        color="warning"
        v-if="roleUser == ROLE_USER[3]"
      >
        見送り
      </v-btn>
      <v-btn
        depressed
        class="mx-3"
        :disabled="isDisabledBtn"
        @click="openFormRequestOrderOfEstimate()"
        color="primary"
        v-if="roleUser == ROLE_USER[3]"
      >
        発注
      </v-btn>
      <v-btn
        depressed
        class="mx-3"
        :disabled="isDisabledBtn"
        @click="openConfirmSendMachineEstimateReport()"
        color="primary"
        v-if="roleUser == ROLE_USER[4]"
      >
        見積報告
      </v-btn>
      <v-btn
        class="mx-3"
        small
        icon
        @click="openRemoveDialog()"
        :disabled="isDisabledBtn"
        :flagTab2="true"
        v-if="roleUser == ROLE_USER[3]"
      >
        <v-icon> mdi-delete </v-icon>
      </v-btn>
    </template>
    <v-data-table
      hide-default-header
      :headers="tableLabels"
      v-model="selectedItems"
      :items="items"
      :items-per-page="itemsPerPage"
      :height="tableHeight - 40"
      fixed-header
      hide-default-footer
      disable-sort
      :show-select="true"
      class="elevation-0 v-data-table__wrapper"
      sort-by="updatedAt"
      item-key="machine_company_field_estimate_order_id"
      :noDataText="NO_DATA_MESSAGE"
      :item-class="displayRecord"
      @click:row="openMachineEstimateOrderForm"
    >
      <template slot="header" :headers="tableLabels">
        <thead>
          <tr>
            <th style="width: 50px">
              <v-simple-checkbox
                :value="selectedItems.length === items.length && items.length > 0"
                @input="clickCheckAll($event)"
              ></v-simple-checkbox>
            </th>
            <th v-for="h in tableLabels" :key="h.value">
              <div>{{ h.text }}</div>
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:[`item.estimate_status`]="{ item }" label small>
        <v-chip
          small
          :color="setColor(item.estimate_status)"
          :text-color="setTextColor(item.estimate_status)"
          >{{ getStatusValue(item.estimate_status) }}</v-chip
        >
      </template>
      <template v-slot:[`item.last_update_date`]="{ item }" label small>
        <div class="cst-overflow-td">
          {{ splitData(item.last_update_date, 0) }}
        </div>
        <div class="cst-overflow-td">
          {{ splitData(item.last_update_date, 1) }}
        </div>
      </template>
      <template v-slot:[`item.report_flag`]="{ item }" label small>
        <div><v-icon v-if="item.report_flag == 1"> mdi-paperclip </v-icon></div>
      </template>
    </v-data-table>
    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        :flagTab2="true"
        :flagError="flagError"
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :selectedItems="selectedItems"
        warning
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowSendOff">
      <ComfirmSendOff
        @closeComfirmSendOff="closeComfirmSendOff"
        @onSubmitSendOff="onSubmitSendOff"
        :warning="true"
        :flagSendOff="flagSendOff"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowSendMachineEstimateReport">
      <FormSendMachineEstimateReport
        :flag="flag"
        @close="closeSendMachineEstimateReport()"
        @onSubmitSendMachineEstimateReport="onSubmitSendMachineEstimateReport"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowFormRequestOrderOfEstimate">
      <FormRequestOrder
        :flag="flagOder"
        :flagShow="true"
        @close="closeFormRequestOrderOfEstimate()"
        @onSubmitRequestOrder="onSubmitRequestOrderOfEstimate"
        title="発注"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowReportEstimateOption">
      <ComfirmReportEstimateDialogOption
        @yes="closeReportEstimateItems()"
        title="見積報告確認"
        text="回答済みのデータに対して見積報告できません。"
        warning
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowOrderDialogOption">
      <ComfirmOrderDialogOption
        @yes="closeOrderDialogOption()"
        title="発注確認"
        text="回答済みのものだけ発注できます。"
        warning
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowOrderDialogOther">
      <ComfirmOrderDialogOther
        @nextForm="openNextFormRequestOrderOfEstimate(true)"
        @closeForm="closeOrderDialogOther(true)"
        title="発注確認"
        text1="回答済みのものだけ発注できます。"
        text2="発注しますか。"
        titleButton="発注"
        warning
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowReportEstimateDialogOther">
      <ComfirmReportEstimateDialogOther
        @nextForm="openNextFormRequestOrderOfEstimate(false)"
        @closeForm="closeOrderDialogOther(false)"
        title="見積報告確認"
        text1="未回答のデータのみ見積報告できます。"
        text2="見積報告をしますか。"
        titleButton="見積報告"
        warning
      />
    </Popup>
    <Popup :dialog="popups.isShowMachineEstimateOrderForm">
      <MachineEstimateOrderForm
        :flagEstimate="true"
        :roleUser="roleUser"
        @closeForm="popups.isShowMachineEstimateOrderForm = false"
        @resetItems="resetItems"
      />
    </Popup>
  </div>
</template> 
<script>
import { Store } from "@/store/Store.js";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import {
  ROLE_USER,
  STATUS_COLORS_TAB2,
} from "@/constants/DEVICE_COORDINATOR";
import Popup from "@/components/common/Popup";
import ConfirmRemoveDialog from "./ConfirmRemoveDialog.vue";
import ComfirmSendOff from "./ComfirmSendOff.vue";
import FormSendMachineEstimateReport from "./FormSendMachineEstimateReport.vue";
import FormRequestOrder from "./FormRequestOrder.vue";
import ComfirmReportEstimateDialogOption from "./ComfirmRemoveDialogOption.vue";
import ComfirmOrderDialogOption from "./ComfirmRemoveDialogOption.vue";
import ComfirmOrderDialogOther from "./ComfirmDialogOther.vue";
import ComfirmReportEstimateDialogOther from "./ComfirmDialogOther.vue";
import MachineEstimateOrderForm from "@/components/forms/device/coordinator/estimateOrder/index.vue";
const STORE = "DeviceCoordinator";

export default {
  data() {
    return {
      NO_DATA_MESSAGE,
      selectedItems: [],
      STATUS_COLORS_TAB2,
      ROLE_USER,
      STORE,
      checkedAll: false,
      flagError: false,
      flag: false,
      flagOder: false,
      flagSendOff: false,
      popups: {
        isShowSendOff: false,
        isShowRemoveDialog: false,
        isShowSendMachineEstimateReport: false,
        isShowFormRequestOrderOfEstimate: false,
        isShowReportEstimateOption: false,
        isShowOrderDialogOption: false,
        isShowOrderDialogOther: false,
        isShowReportEstimateDialogOther: false,
        isShowMachineEstimateOrderForm : false
      },
    };
  },
  components: {
    Popup,
    ConfirmRemoveDialog,
    ComfirmSendOff,
    FormSendMachineEstimateReport,
    FormRequestOrder,
    ComfirmReportEstimateDialogOption,
    ComfirmOrderDialogOption,
    ComfirmOrderDialogOther,
    ComfirmReportEstimateDialogOther,
    MachineEstimateOrderForm
  },
  props: {
    tableLabels: {
      type: Array,
    },
    roleUser: Number,
    items: Array,
    itemsPerPage: Number,
    tableHeight: Number,
    dataSelect: Array,
    resetSelected : Boolean

  },
  mounted() {
    this.$watch(
      () => this.resetSelected,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          this.selectedItems = [];
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  computed: {
    isDisabledBtn() {
      return this.selectedItems.length === 0;
    },
    getFieldId() {
      return Store.getters["GlobalHeader/getCurrentSite"]?.field_id;
    },
  },
  methods: {
    /**
     * reset data items when update success
     */
    resetDataItems() {
      this.$emit("resetDataItems");
    },
    clickCheckAll(event) {
      if (event) {
        this.selectedItems = this.items;
      } else {
        this.selectedItems = [];
      }
    },
    setColor(applyStatus) {
      return this.STATUS_COLORS_TAB2[applyStatus]?.color;
    },
    getStatusValue(applyStatus) {
      return this.STATUS_COLORS_TAB2[applyStatus]?.value;
    },
    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.selectedItems = [];
      this.popups.isShowRemoveDialog = false;
    },
    closeReportEstimateItems() {
      this.selectedItems = [];
      this.popups.isShowReportEstimateOption = false;
    },
    async removeItems() {
      const machine_company_field_estimate_order_ids = this.selectedItems.map(
        (items) => items.machine_company_field_estimate_order_id
      );
      const params = {
        machine_company_field_estimate_order_ids,
        type: 0
      }
      const result = await Store.dispatch(`${STORE}/deleteOrderEstimate`, params);

      if (result.hasError) {
        this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.message,
        });
        return;
      } else {
        this.closeRemoveDialog();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
        this.$emit("resetDataItems");
      }
    },
    openConfirmSendOff() {
      this.popups.isShowSendOff = true;
    },
    closeComfirmSendOff() {
      this.selectedItems = [];
      this.popups.isShowSendOff = false;
    },
    async onSubmitSendOff(data) {
      const machine_company_field_estimate_order_ids = this.selectedItems.map(
        (items) => items.machine_company_field_estimate_order_id
      );
      const params = {
        field_id: this.getFieldId,
        machine_company_field_estimate_order_ids,
        estimate_remarks: data.estimate_remarks,
      };
      const result = await Store.dispatch(
        `${STORE}/cancelMachineEstimate`,
        params
      );
      if (result.hasError) {
        this.flagSendOff = !this.flagSendOff;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.response.message,
        });
        return;
      } else {
        this.closeComfirmSendOff();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "見送りしました",
        });
        this.$emit("resetDataItems");
      }
    },
    async onSubmitSendMachineEstimateReport(data) {
      const machine_company_field_estimate_order_ids = this.selectedItems.map(
        (items) => items.machine_company_field_estimate_order_id
      );
      const params = {
        field_id: this.getFieldId,
        machine_company_field_estimate_order_ids,
        estimate_expiration_date: data.estimate_expiration_date,
      };
      const result = await Store.dispatch(
        `${STORE}/sendMachineEstimateReport`,
        params
      );
      if (result.hasError) {
        this.flag = !this.flag;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.response.message,
        });
        return;
      } else {
        this.closeSendMachineEstimateReport();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "見積報告しました",
        });
        this.$emit("resetDataItems");
      }
    },
    openConfirmSendMachineEstimateReport() {
      let result = this.selectedItems.filter((e) => e.estimate_status == STATUS_COLORS_TAB2[0].status);
      if (result.length == 0) {
        this.popups.isShowReportEstimateOption = true;
      } else if (result.length == this.selectedItems.length) {
        this.popups.isShowSendMachineEstimateReport = true;
      } else {
        this.selectedItems = result;
        this.popups.isShowReportEstimateDialogOther = true;
      }
    },
    closeSendMachineEstimateReport() {
      this.selectedItems = [];
      this.popups.isShowSendMachineEstimateReport = false;
    },
    openFormRequestOrderOfEstimate() {
      let result = this.selectedItems.filter(e => e.estimate_status == STATUS_COLORS_TAB2[1].status);
      if (result.length == 0) {
        this.popups.isShowOrderDialogOption = true;
      } else if (result.length == this.selectedItems.length) {
        this.popups.isShowFormRequestOrderOfEstimate = true;
      } else {
        this.popups.isShowOrderDialogOther = true;
        this.selectedItems = result;
      }
    },
    openNextFormRequestOrderOfEstimate(type) {
      if (type) {
        this.popups.isShowFormRequestOrderOfEstimate = true;
        this.popups.isShowOrderDialogOther = false;
      } else {
        this.popups.isShowSendMachineEstimateReport = true;
        this.popups.isShowReportEstimateDialogOther = false;
      }
    },
    closeOrderDialogOther(type) {
      if (type) {
        this.popups.isShowOrderDialogOther = false;
      } else {
        this.popups.isShowReportEstimateDialogOther = false;
      }
      this.selectedItems = [];
    },
    closeOrderDialogOption() {
      this.selectedItems = [];
      this.popups.isShowOrderDialogOption = false;
    },
    closeFormRequestOrderOfEstimate() {
      this.selectedItems = [];
      this.popups.isShowFormRequestOrderOfEstimate = false;
    },
    async onSubmitRequestOrderOfEstimate(data) {
      const ids = this.selectedItems.map(
        (items) => items.machine_company_field_estimate_order_id
      );
      const params = {
        field_id: this.getFieldId,
        ids,
        order_desired_delivery_date: data.order_scheduled_delivery_date,
        order_remarks: data.order_remarks,
      };
      const result = await Store.dispatch(
        `${STORE}/createOrderOfEstimate`,
        params
      );
      if (result.hasError) {
        this.flag = !this.flag;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.response.message,
        });
        return;
      } else {
        this.closeFormRequestOrderOfEstimate();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "発注しました",
        });
        this.$emit("resetDataItems");
      }
    },

    splitData(item, i) {
      let rs = "";
      if (item) {
        let text = item.split(" ");
        rs = text[i];
      }
      return rs;
    },
    displayRecord(item) {
      return item?.estimate_status == STATUS_COLORS_TAB2[2].status || item?.estimate_status == STATUS_COLORS_TAB2[3].status
        ? "grey lighten-1"
        : "";
    },
    setTextColor(applyStatus) {
      return applyStatus == STATUS_COLORS_TAB2[2].status || applyStatus == STATUS_COLORS_TAB2[3].status ? "white" : "";
    },
    async openMachineEstimateOrderForm(item) {
      await Store.dispatch(`${STORE}/getEstimate`, item.machine_company_field_estimate_order_id);
      this.popups.isShowMachineEstimateOrderForm = true;
    },
    resetItems(){
      this.$emit("resetItemTab2");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
::v-deep .v-data-table .v-data-table__wrapper table tbody tr td {
  word-break: break-all;
}
.cst-overflow-td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mb-cst {
  margin-bottom: 2vh !important;
}
</style>
