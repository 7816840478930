<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <SitePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout :layoutParams="layoutParams" :hideFooter="searchParams.page_count >= searchParams.total_item">
          <template #tableHeader="{ updateHeader }">
            <EntranceNewWorkerHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :updateHeader="updateHeader"
              @onInput="onChangeSearchParams"
            >
              <v-row>
                <v-col sm="11" md="11">
                  <SearchFormWrapper>
                    <Label label="協力会社" width="300px">
                      <Select
                        name="field_tree_id"
                        :items="COMPANY_PARTNER_ARRAY"
                        :editable="true"
                        item_text="company_name"
                        item_value="field_tree_id"
                        :values="searchParams"
                        validation_label="協力会社"
                        validation_rules=""
                        @onInput="onChangeSearchParams"
                      />
                    </Label>
                  </SearchFormWrapper>
                </v-col>
                <v-col sm="1" md="1" class="text-right">
                  <v-btn
                    class="mr-6 ma-2"
                    depressed
                    color="primary"
                    @click="onSearch()"
                  >
                    検索
                  </v-btn>
                </v-col>
              </v-row>
            </EntranceNewWorkerHeader>
            <v-container class="tableSortWrapper">
              <v-spacer></v-spacer>
              <div class="sortLabel">総件数:</div>
              <div class="sortElement total_item">
                {{ searchParams.all_record }}件
              </div>
            </v-container>
          </template>
          <template #tableBody="{ tableHeight }">
            <NewWorkerTable
              :tableLabels="TABLE_LABELS"
              :tableHeight="tableHeight"
              :items="items"
              :isNoDataMessage="isNoDataMessage"
              :itemsPerPage="searchParams.page_count"
              :getChildItems="getChildItems"
            />
          </template>
          <template #tableFooter>
            <Pagination
              :current="searchParams.current_page"
              :total="searchParams.total_page"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import EntranceNewWorkerHeader from "./components/EntranceNewWorkerHeader";
import NewWorkerTable from "./components/NewWorkerTable.vue";
import { HEADER_MENU_ITEMS_SITE } from "@/constants/GLOBALHEADER";
import Select from "@/components/forms/elements/Select";
import Label from "@/components/forms/elements/Label";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";

const PAGE_TITLE = "新規入場者";

const PAGE_COUNT = 3;

const STORE = "EntranceNewWorker";

const TABLE_LABELS = [
  {
    text: "",
    value: "",
    align: "left",
    width: "55%",
  },
  {
    text: "職種",
    value: "skill",
    align: "left",
    width: "35%",
  },
  {
    text: "入場開始日",
    value: "site_admission_time",
    align: "left",
    width: "10%",
  },
];

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      field_id : null,
      PAGE_TITLE,
      TABLE_LABELS,
      items: [],
      searchParams: {
        field_tree_id: null,
        page_count: PAGE_COUNT,
        current_page: 1,
        total_page: 1 ,
        total_item : 0,
        all_record: 0,
      },
      COMPANY_PARTNER_ARRAY: [],
      isNoDataMessage: false,
    };
  },

  components: {
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SitePortalHeader,
    EntranceNewWorkerHeader,
    NewWorkerTable,
    Select,
    Label,
    SearchFormWrapper,
  },

  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    await Store.dispatch("GlobalHeader/setSiteMenu", {
      menuId: HEADER_MENU_ITEMS_SITE.ENTRANCE.id,
    });

    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      (data, oldData) => {
        if (data && JSON.stringify(data) !== JSON.stringify(oldData)) {
          this.field_id = data.field_id;
          this.getItems();
          this.getListCompanyPartner();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    
    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        this.items = data[0];
        this.isNoDataMessage = this.items.length == 0;
        let searchParams = { ...this.searchParams };
        searchParams.total_page = data[1].total;
        searchParams.current_page = parseInt(data[1].current);
        searchParams.total_item = data[1].total_item;
        searchParams.all_record = data[1].all_record;
        this.searchParams = { ...searchParams };
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Sites/getListCompanyPartner"],
      (value) => {
        this.COMPANY_PARTNER_ARRAY = [
          { field_tree_id: null, company_name: "" },
          ...value,
        ];
      },
      {
        deep: true,
      }
    );
  },

  computed:{
    /**
     * API Param
     */
    apiParams() {
      return {
        field_id: this.field_id,
        field_tree_id: this.searchParams.field_tree_id,
        page_number: this.searchParams.current_page,
      };
    },
  },
  methods: {
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.current_page = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 検索
     */
    onSearch() {
      this.searchParams["current_page"] = 1;
      this.getItems();
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
    },

    async getChildItems(id, pageNumber) {
      let searchParams = { ...this.searchParams };
      searchParams["field_tree_id"] = id;
      searchParams["page_number"] = pageNumber;
      delete searchParams.total_item;
      delete searchParams.all_record;
      delete searchParams.page_count;
      delete searchParams.current_page;
      delete searchParams.total_page;
      const params = {
        params: searchParams,
      };
      await Store.dispatch(`${STORE}/loadChildren`, params);
    },

    async getItems() {
      if(this.field_id){
        await Store.dispatch(`${STORE}/getListNewArrival`, { params: this.apiParams });
      }
    },

    async getListCompanyPartner() {
      if(this.field_id){
        let params = { field_id: this.field_id };
        await Store.dispatch("Sites/getListCompanyPartner", { params });
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.tableSortWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 64px;
  .sortLabel {
    font-size: 12px;
    height: 32px;
    margin-right: 16px;
  }
  .sortElement {
    max-width: 168px;
    margin-right: 24px;
  }
  .total_item {
    padding-bottom: 15px;
  }
  .button_search {
    margin-right: 28px;
  }
}
</style>
