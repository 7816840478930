<template>
  <li
    :class="`information_row ${item.state_handling_flag == 1? 'grey lighten-1' : ''}`"
    :key="`info_${item.work_result_id}_${item.work_company_result_id}`"
    :index="index"
    :style="getInformationSize"
  >
    <div class="information_col">
      <v-checkbox
        class="ml-10  mt-0"
        v-model="checked"
        dense
        @change="onChange"
      ></v-checkbox>
    </div>
    <div class="information_approval">
      <BulletApproval :value="item.work_approval_flag" />
    </div>
    <v-row class="ml-4" @click="openItemForm()">
      <v-col cols="12" sm="5">{{ convertString(item.employee_user_name, 13) }}</v-col>
      <v-col cols="12" sm="3">{{ convertString(item.job_name, 7) }}</v-col>
      <v-col cols="12" sm="2"><span class="total_time" >{{ item.total_time_plan == 0 ? "" : item.total_time_plan }}</span></v-col>
      <v-col cols="12" sm="2"><span class="total_time" >{{ item.total_time_result == 0 ? "" : item.total_time_result }}</span></v-col>
    </v-row>
  </li>
</template>
<script>
import BulletApproval from "./ButtelApproval.vue";
export default {
  data: () => {
    return {
      checked: false,
    };
  },
  components: {
    BulletApproval,
  },
  props: {
    item: { type: Object },
    isTableTypeSchedule : {type :Boolean},
    index: { type: Number },
    forchChecked: { type: Boolean },
    cellRect: { type: Object },
  },
  computed: {
    // informationの高さ
    getInformationSize() {
      return `height:${this.cellRect.height}px;line-height::${this.cellRect.height}px;`;
    },
  },
  mounted() {
    this.$watch(
      () => this.forchChecked,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.checked = newValue;
        }
      },
      {
        immediate: true,
      }
    );
  },
  methods: {
    onChange(value) {
      this.checked = value;
      this.$emit("onChange", {
        work_company_result_id: this.item.work_company_result_id,
        checked: value,
      });
    },
    openItemForm() {
      this.$emit("openItemForm", this.item.work_result_id, this.item.work_company_result_id, this.item.work_approval_flag);
    },
    convertString(text, maxlength) {
      let result = "";
      if (text) {
        result = text;
        if (text.length > maxlength) {
          result = text.substring(0, maxlength) + " •••";
        }
      }
      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.information_row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  font-size: 11px;
  border-left: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.information_col {
  height: 32px;
}
.information_approval {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.total_time {
  color: $color_schedule_type_spot;
}
.information_name {
  font-size: 13px;
  color: $color_primary;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
}
.information_location {
  font-size: 8px;
  color: $color_text_sub;
  width: 40%;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
}
</style>
