<template>
  <FormDialog>
    <template #header>
      <!-- 
        (共通)
        ヘッダーは全て共通
       -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editable"
          class="mr-5"
          depressed
          small
          outlined
          color="primary"
          @click="isShowConfirmDialog = true"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          small
          :disabled="!valid"
          color="primary"
          :key="flagErrorSubmit"
          @click.once="onSubmit"
        >
          保存
        </v-btn>
        <v-btn
          v-if="!editable"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!-- 
        (共通)
        全てのフォームのバリデーションの監視を行う
       -->
      <ValidationObserver ref="observer" v-slot="observer">
        <!-- フォームtab -->
        <v-tabs-items v-model="tab">
          <!-- 禁止時間帯 -->
          <v-tab-item>
            <SetTimeProhibitFormPage1
              :editable="editable"
              :item="formValues"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
        </v-tabs-items>
        <!-- 
          (共通)
          ValidationObserverのスロットプロパティを受け取って
          updateValidateメソッドを実行して、
          バリデーション結果をスクリプト側に渡してます
        -->
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import SetTimeProhibitFormPage1 from "@/components/forms/gates/GateFormPage2.vue";
import { SET_TIME_PROHIBIT_FORM_TABS } from "@/constants/GATES";
import _ from "lodash";
import { Store } from "@/store/Store.js";

/**
 * (共通)
 * タイトル
 */
const TITLE = {
  GATE: "ゲート情報",
  CRANE: "揚重機情報",
};

//ストア
const STORE_GATE = "Gates";
const STORE_CRANE = "Cranes";

/**
 * フォームとタブの設定
 */
const FORMS = SET_TIME_PROHIBIT_FORM_TABS;

export default {
  data: () => {
    return {
      FORMS,
      valid: false,
      formValues: {},
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      isClickCloseBtn: false,
      beforeUpdateItem: {},
      flagErrorSubmit: false,
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    SetTimeProhibitFormPage1,
  },
  props: {
    isGates: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.$watch(
      () => [Store.getters[`${this.STORE}/${this.getGetter}`], this.editable],
      (data) => {
        this.formValues = _.cloneDeep(data[0]);
        this.beforeUpdateItem = _.cloneDeep(data[0]);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    // 新規or編集でタイトル変更
    formtitle() {
      return this.isGates ? TITLE.GATE : TITLE.CRANE;
    },
    STORE() {
      return this.isGates ? STORE_GATE : STORE_CRANE;
    },
    getGetter() {
      return this.isGates ? 'getGates' : 'getCranes';
    },
  },
  methods: {
    /**
     * (共通)
     * フォームの変更を送信
     */
    formUpdate(params) {
      let formValues = {...params};
      this.formValues = formValues;
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      this.editable = true;
    },
    /**
     * (共通)
     * 状態を詳細に変更
     */
    onDetail() {
      this.editable = false;
    },

    /**
     * (共通)
     * 登録
     */
    async onSubmit() {
      let editedItem = {...this.formValues};
      if (editedItem.device_prohibit_times) {
        editedItem.device_prohibit_times = editedItem.device_prohibit_times.filter(e => e.prohibit_start_time && e.prohibit_end_time);
      }
      const result = await Store.dispatch(`${this.STORE}/${this.isGates?'update':'edit'}`, editedItem);
      if (result.hasError) {
        this.flagErrorSubmit = !this.flagErrorSubmit;
        return;
      } else {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        await Store.dispatch( `${this.STORE}/getDetail`, editedItem.id);
        this.editable = false;
        this.$emit('resetList');
      }
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid }) {
      this.valid = valid;
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        const item = _.cloneDeep(this.beforeUpdateItem);
        this.formUpdate(item);
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },
    /**
     * (共通)
     * click button Close (X)
     */
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },

    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
