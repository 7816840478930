<template>
  <v-container class="pa-5" :style="`height: ${tableHeight}px;overflow:auto;`">
    <v-row dense :style="`width: ${widthContainer}vw`">
      <v-col
        v-for="card in LIST_ENTRANCE_PANEL"
        :key="card.title"
        cols="auto"
        style="margin-top: -15px"
      >
        <v-card
          v-if="card.id"
          :style="`width: ${getWithCard}vw; height: ${getHeightCard}vw;background-color:${
            card.admission_status == 2 && card.field_tree_user_id? 'rgb(170,170,170);opacity: 0.5' : ''
          }`"
        >
          <v-row class="mt-5" style="height: 100%" v-if="card.field_tree_user_id">
            <v-col cols="12" sm="5" md="5" class="image_col">
              <v-avatar size="95%" class="ml-4">
                <img :src="card.person_image_url ? card.person_image_url : urlNoAvatar" style="height: 68%" />
              </v-avatar>
            </v-col>
            <v-col cols="12" sm="7" md="7" class="information_col">
              <div v-show="card.admission_status == 1" class="entering">
                <span class="text_entering" :style="`font-size: ${getFontSizeCompanyName}vw;`">入場中</span>
              </div>
              <div
                class="text_company_name"
                :style="`font-size: ${getFontSizeCompanyName}vw;margin-top: ${card.admission_status == 2 ? '35%' : '10%'};`">
                {{ card.company_name }}
              </div>
              <div
                class="text_user_name"
                :style="`font-size: ${getFontSizeUserName}vw;margin-top: ${card.company_name ? '15%' : '37%'};`">
                {{ card.user_name }}
              </div>
            </v-col>
          </v-row>
          <v-img class="mt-5" v-else :src="urlNoImage"></v-img>
        </v-card>
        <v-card v-else class="mt-5" :style="`width: ${getWithCard}vw; height: ${getHeightCard}vw`"></v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { Store } from "@/store/Store";
import { getParamsOfUrlReadFile } from "@/utils/viewSourceAuth";
import _ from "lodash";
const STORE = "EntranceHistory";


export default {
  data: () => ({
    numberOfSizeTextCompanyName : 8,
    numberOfSizeTextUserName : 9,
    defaultPaddingCard : 4,
    widthContainer : 96,
    ratioCard : 0.6,
    LIST_ENTRANCE_PANEL: [],
    padding: null,
    row: 0,
    col: 0,
    urlNoAvatar: "/images/no_face.png",
    urlNoImage: "/images/noimage.png",
    field_id: null
  }),
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  props: {
    tableHeight: Number
  },
  computed: {
    getWithCard() {
      let rs = (this.widthContainer - this.convertPxToVw(this.col * 2 * this.defaultPaddingCard)) / this.col;
      return rs;
    },
    getHeightCard() {
      return this.widthContainer * this.ratioCard / this.col;
    },
    getFontSizeCompanyName() {
      return this.numberOfSizeTextCompanyName / this.col;
    },
    getFontSizeUserName() {
      return this.numberOfSizeTextUserName / this.col;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    }
  },
  async mounted() {
    // get list entrance panel
    await this.getListEntrancePanel();
    this.$watch(
      () => Store.getters[`${STORE}/getListEntrancePanel`],
      (data) => {
        if (data.field_entrance_history_panels) {
          const row = { ...data.field_entrance_panel_sizes }.row;
          const col = { ...data.field_entrance_panel_sizes }.column;
          // get row and col in view grid
          this.row = row ;
          this.col = col;
          let field_entrance_history_panels = [...data.field_entrance_history_panels];
          // get data to view grid
          this.getDataList(field_entrance_history_panels);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      (data, oldData) => {
        if (data && JSON.stringify(data) !== JSON.stringify(oldData) && data.field_id) {
          this.field_id = data.field_id;
          this.getListEntrancePanel();
        }
      },
      {
        deep: true
      }
    );
  },
  methods: {
    async getDataList(data) {
      // custom data list
      await this.customDataList(data);
    },
    myEventHandler() {
      this.padding = document.documentElement.clientWidth;
    },
    convertPxToVw(data) {
      let a = this.padding ? this.padding : document.documentElement.clientWidth;
      return data * (100 / a);
    },
    async getListEntrancePanel() {
      if (this.CURRENT_SITE.field_id) {
        let params = { field_id: this.CURRENT_SITE.field_id };
        await Store.dispatch(`${STORE}/getListEntrancePanel`, {
          params
        });
      }
    },
    async getListEntrancePanelByRowCol(array) {
      // get list order_number in list panel
      let listOrderNumber = array.map(item =>
        item.order_number
      );
      // get list order_number of all card in view by row and col
      let allCard = _.range(1, this.row * this.col + 1);
      // get index need fill to view
      let index = allCard.filter(x => !listOrderNumber.includes(x));
      // insert to fill all card to view
      index.forEach(e => {
        array.splice(e - 1, 0, { id: null, order_number: e });
      });
      this.LIST_ENTRANCE_PANEL = [...array];
    },

    async getImage(url) {
      let img = "";
      if (url) {
        const params = getParamsOfUrlReadFile(url);
        const response = await Store.dispatch("File/readFile", params);
        if (!response.hasError) {
          img = window.URL.createObjectURL(new Blob([response.data], { type: response.headers["content-type"] }));
        }
      }
      return img;
    },

    async customDataList(data) {
      // custom image_url in list data
      let arr = _.cloneDeep(data);
      for (let obj of arr) {
        if (obj.person_image_url) {
          obj.person_image_url = await this.getImage(obj.person_image_url);
        }
      }
      // add empty to fill row col
      this.getListEntrancePanelByRowCol(arr);
    }
  }
};
</script>
<style scoped>
.image_col {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.information_col {
  padding-top: 0;
  height: 100%;
}

.entering {
  background-color: green;
  border-radius: 0px 0px 0px 20px;
  text-align: center;
  height: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text_company_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: gray
}

.text_user_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text_entering {
  color: white;
  line-height: 1.5vw
}
</style>
