<template>
  <div>
    <v-container>
      <v-row v-for="(item, index) in items" :key="index">
        <v-list-item>
          <v-list-item-content>
            <SectionLabel label="専門技術者" :editable="editable" class="mt-2"/>
            <Label label="専門技術者名" :editable="editable">
              <InputText
                name="technician_name"
                :values="item"
                :editable="editable"
                placeholder="夏川 二郎"
                validation_label="専門技術者名"
                validation_rules="max:255"
                @onInput="onInput({value: $event, index})"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <Label label="資格" :editable="editable"></Label>
            <Label label="試験等" :editable="editable">
              <Select
                name="exam"
                :values="item"
                :items="getcatergoryItems()"
                :editable="editable"
                validation_label=""
                validation_rules=""
                @onInput="onInput({value: $event, index})"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <Label label="資格区分" :editable="editable">
              <Select
                name="exam_id"
                :values="item"
                item_text="qualification"
                :items="getSubCatergoryItems(item, 'exam')"
                :editable="editable"
                validation_label="資格区分"
                validation_rules=""
                @onInput="onInput({value: $event, index})"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <Label label="担当工事内容" :editable="editable">
              <InputText
                name="work_details"
                :values="item"
                :editable="editable"
                placeholder=""
                validation_label="担当工事内容"
                validation_rules="max:512"
                @onInput="onInput({value: $event, index})"
              />
            </Label>
          </v-list-item-content>
          <v-btn
            v-if="editable && index > 0"
            class="mt-8"
            icon
            @click="remove(index)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-list-item>
      </v-row>
    </v-container>
    <v-btn v-if="editable" color="primary" @click="addEmpty">追加</v-btn>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "@/components/forms/elements/Label";
import Select from "@/components/forms/elements/Select";
import InputText from "@/components/forms/elements/InputText";
import SectionLabel from "@/components/forms/elements/SectionLabel";

import { Store } from "@/store/Store";

const INITIAL_VALUES = {
  id : null,
  field_tree_contract_id: null,
  exam_id: null,
  exam: null,
  technician_name: "",
  work_details: "",
};
export default {
  data: () => {
    return {
      items: [],
      cmn_mst_qualifications_construction_industries: Store.state.CmnMst.constants.entries
        .cmn_mst_qualifications_construction_industries,
    };
  },
  components: {
    Label,
    InputText,
    Select,
    SectionLabel
  },
  props: {
    field_tree_contract_id:{
      type : Number
    },
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue, oldValue) => {
        if(newValue[0]){
          if (this.items?.length !== newValue[0][newValue[1]]?.length
            || JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
            this.items = newValue[0][newValue[1]];
            if (this.items?.length == 0) {
              this.addEmpty();
            } else if (this.items != null) {
              this.items.forEach((_item) => {
                this.cmn_mst_qualifications_construction_industries.forEach(
                  (element) => {
                    if (_item.exam_id == element.id) {
                      _item["exam"] = element.exam_id;
                    }
                  }
                );
              });
            }
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      const itemEdited = {...this.items[index]};
      itemEdited[value.name] = value.value;
      if (value.name == "exam") {
        itemEdited.exam_id = null;
      }
      const items = [...this.items];
      items[index] = itemEdited;
      this.$emit("onInput", { name: this.name, value: items });
    },
    remove(index) {
      const items = this.items.filter((item, i) => {
        return index != i;
      });
      this.$emit("onInput", { name: this.name, value: items });
    },
    addEmpty() {
      const items = [...this.items];
      let new_item = { ...INITIAL_VALUES };
      // field_tree_contract_id for new item
      new_item.field_tree_contract_id = this.field_tree_contract_id;
      items.push({ ...new_item });
      this.items = items;
    },
    getcatergoryItems() {
      let temp = "";
      let rs = [{ id: null, name: ""}];
      this.cmn_mst_qualifications_construction_industries.forEach((element) => {
        if (temp != element.exam_id) {
          rs.push({ id: element.exam_id, name: element.exams });
          temp = element.exam_id;
        }
      });
      return rs;
    },
    getSubCatergoryItems(item, name) {
      let cmn_mst_qualifications_construction_industries = this.cmn_mst_qualifications_construction_industries.filter(
        (e) => e.exam_id == item[name]
      );
      cmn_mst_qualifications_construction_industries.unshift({ id: null, qualification: ""})
      return cmn_mst_qualifications_construction_industries;
    },
  },
};
</script>
