<template>
  <div>
    <v-treeview
      v-if="items.length > 0"
      hoverable
      dense
      :items="items"
      item-children="childrens"
      return-object
      expand-icon="mdi-chevron-down"
    >
      <template v-slot:label="{ item }">
        <div v-if="item.childrens" class="location_name">
          {{ item.location_name }}
        </div>
        <div v-else class="infor_work_place">
          <div class="infor_company_and_foreman_name">
            <div class="infor_company_name">
              {{ item.company_name }}
            </div>
            <div class="infor_foreman_name">
              {{ item.foreman_name }}
            </div>
          </div>
          <div>
            {{ item.dayworker }}
          </div>
        </div>
      </template>
    </v-treeview>
    <div v-else-if="isNoDataWorkPlace" class="mt-10" style="text-align: center">
      <span class="nodata">{{ NO_DATA_MESSAGE }}</span>
    </div>
  </div>
</template>
<script>
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
export default {
  data: () => {
    return {
      NO_DATA_MESSAGE,
    };
  },
  props: {
    items: {
      type: Array,
    },
    isNoDataWorkPlace: Boolean,
  },
};
</script>
<style lang="scss" scoped>
.location_name {
  text-overflow: ellipsis;
  max-width: 80%;
  overflow: hidden;
}
.infor_work_place {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.infor_company_name {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 170px;
  overflow: hidden;
}
.infor_foreman_name {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
  overflow: hidden;
}
.infor_company_and_foreman_name {
  display: flex;
  flex-direction: row;
  width: 70%;
  justify-content: space-between;
}
.nodata {
  color: rgba(162, 162, 162, 255);
}
</style>
