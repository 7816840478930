<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-col cols="12" sm="11" md="11">
          <v-row style="position: relative;">
            <v-col cols="12" sm="6" md="6">
              <Label label="入場時間" :editable="editable" required>
                <div class="flex-direction" v-if="editable">
                  <Select
                    class="select"
                    name="admission_time_hour"
                    :values="item"
                    :items="hoursArray"
                    :validation_rules="getStartHour(item, index)"
                    :editable="editable"
                    @onInput="onInput({ index, value: $event })"
                    :validation_label="`入場時間（時）[${index + 1}]`"
                  />
                  <Select
                    class="select"
                    name="admission_time_minute"
                    :values="item"
                    :items="minsArray"
                    :editable="editable"
                    validation_rules="required-admission-time-min"
                    :validation_label="`入場時間（分）[${index + 1}]`"
                    @onInput="onInput({ index, value: $event })"
                  />
                </div>
                <div class="flex-direction" v-else>
                  <v-text-field
                    disabled
                    :value="getAdmissionTime(item)"
                  >
                  </v-text-field>
                </div>
              </Label>
            </v-col>
            <div :style="editable ? `top: 65px;` : `top: 75px;`" class="position">〜</div>
            <v-col cols="12" sm="6" md="6">
              <Label label="退場時間" :editable="editable" required>
                <div class="flex-direction" v-if="editable">
                  <Select
                    class="select"
                    name="exit_time_hour"
                    :values="item"
                    :items="hoursArray"
                    :validation_rules="getEndHour(item, index)"
                    :editable="editable"
                    @onInput="onInput({ index, value: $event })"
                    :validation_label="`退場時間（時）[${index + 1}]`"
                  />
                  <Select
                    class="select"
                    name="exit_time_minute"
                    :values="item"
                    :items="minsArray"
                    :editable="editable"
                    validation_rules="required-exit-time-min"
                    @onInput="onInput({ index, value: $event })"
                    :validation_label="`退場時間（分）[${index + 1}]`"
                  />
                </div>
                <div class="flex-direction" v-else>
                  <v-text-field
                    disabled
                    :value="getExitTime(item)"
                  >
                  </v-text-field>
                </div>
              </Label>
            </v-col>
            <div v-if="index > 0 && editable" class="position_close">
              <v-btn class="mt-16" icon @click="remove(index)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-row>
        </v-col>
      </v-main>
    </v-container>
    <v-btn v-if="editable" class="ml-4" color="primary" @click="addEmpty" depressed small
      >追加</v-btn
    >
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "@/components/forms/elements/Label";
import Select from "@/components/forms/elements/Select";
import {
  TIME_HOUR_ARRAY,
  TIME_MINUTE_ARRAY,
} from "@/constants/ENTRANCE_HISTORY";
//初期値を設定します
const INITIAL_VALUES = {
  admission_time_hour: null,
  admission_time_minute: null,
  exit_time_hour: null,
  exit_time_minute: null,
};

export default {
  data: () => {
    return {
      items: [],
      hoursArray: [],
      minsArray: [{ id: null, name: "" }, ...TIME_MINUTE_ARRAY],
      timeValidate: {
        start: null,
        end: null
      }
    };
  },
  components: {
    Label,
    Select,
  },
  props: {
    enterDayTime: String,
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    selectArrays: {
      type: Array,
    },
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (this.items?.length == 0) {
          this.addEmpty();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.enterDayTime,
      (newValue) => {
        if (newValue) {
          this.caculateHourArray(newValue);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      const items = [...this.items];
      items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: items });
    },
    getAdmissionTime(item) {
      return (item.admission_time_hour && item.admission_time_minute) ? (item.admission_time_hour + ':' + item.admission_time_minute) : '';
    },
    getExitTime(item) {
      return (item.exit_time_hour && item.exit_time_minute) ? (item.exit_time_hour + ':' + item.exit_time_minute) : '';
    },
    getStartHour(item, index) {
      let results = `required-admission-time-hour|compare-start-hour:${item.admission_time_minute}, ${item.exit_time_hour}, ${item.exit_time_minute}`;
      if (this.items[index - 1]) {
        results = `${results}|compare-start-hour-with-end-hour:${
          item.admission_time_minute
        }, ${this.items[index - 1].exit_time_hour}, ${
          this.items[index - 1].exit_time_minute
        }`;
      }
      if(item.admission_time_hour && item.admission_time_minute) {
        results = `${results}|check-start-hour:${item.admission_time_hour + ":" + item.admission_time_minute},${this.timeValidate.start},${this.timeValidate.end}`;
      }
      return results;
    },

    getEndHour(item, index) {
      let results = `required-exit-time-hour|compare-end-hour:${item.admission_time_hour}, ${item.admission_time_minute}, ${item.exit_time_minute}`;
      if (this.items[index + 1]) {
        results = `${results}|compare-end-hour-with-start-hour:${
          this.items[index + 1].admission_time_hour
        }, ${this.items[index + 1].admission_time_minute}, ${
          item.exit_time_minute
        }`;
      }
      if(item.exit_time_hour && item.exit_time_minute) {
        results = `${results}|check-end-hour:${item.exit_time_hour + ":" + item.exit_time_minute},${this.timeValidate.start},${this.timeValidate.end}`;
      }
      return results;
    },

    caculateHourArray(enterDayTime) {
      const start = enterDayTime.split(":")[0];
      const end = Number(start) + 24 + "";
      this.hoursArray = TIME_HOUR_ARRAY.filter((item) => {
        return item.id >= start && item.id <= end;
      });
      this.hoursArray.unshift({ id: null, name: "" });
      this.timeValidate = {
        start: enterDayTime,
        end: end + ":" + enterDayTime.split(":")[1]
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/forms.scss";
.position {
  position: absolute;
  left: 49%;
}

.position_close {
  position: absolute;
  left: 100%;
  top: -5%;
}

.flex-direction{
  display: flex;
  flex-direction: row;
}

.select{
  min-width: 50px;
  padding: 0 6px;
}
</style>
