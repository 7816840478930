<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row>
          <v-col cols="12" sm="10" md="10">
            <v-list-item>
              <v-list-item-content>
                <span class="file"
                  style="cursor: pointer"
                  v-if="!editable && item['document_url']"
                  @click="viewSourceWithAuth(item['document_url'])"
                >
                  {{ item["document_name"] }}
                </span>
                <FileInput
                  :style="getFileInputStyle(index, 'document_name', 'document_name')"
                  :resetValue="resetValue[`${index}_document_name`]"
                  v-if="editable"
                  name="document_name"
                  :values="item"
                  :editable="editable"
                  accept="image/jpg, image/png, image/jpeg, application/pdf, application/vnd.ms-excel"
                  placeholder=""
                  v-bind:validation_label="`${index}_document_name`"
                  validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf, xls, xlsx"
                  @onInput="onInput({ index, value: $event })"
                  :test-id="`variable-document_name-${index}`"
                />
                <span
                  class="file"
                  v-if="judgeDisplayFileInput(index, 'document_name', 'document_name')"
                >{{ item['document_name'] }}
                  <v-btn small icon text
                    @click="onRemoveFile(index)"
                  ><v-icon>mdi-close</v-icon></v-btn>
                </span>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col v-if="index == 0 && editable" cols="12" sm="2" md="2">
            <v-btn class="mt-5" icon @click="addEmpty">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
          <v-col v-if="index > 0 && editable" cols="12" sm="2" md="2">
            <v-btn class="mt-3" icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */
import FileInput from "@/components/forms/elements/FileInput.vue";
import _ from "lodash";
import { viewSourceWithAuth } from "@/utils/viewSourceAuth";

//初期値を設定します
const INITIAL_VALUES = {
  document_name: null,
};

export default {
  data: () => {
    return {
      items: [],
      fileValid: {},
      resetValue: {},
      viewSourceWithAuth: viewSourceWithAuth,
    };
  },
  components: {
    FileInput,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    errors: {
      type: Object,
      default: {},
    },
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (this.items?.length == 0) {
          this.addEmpty();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    // check input file errors
    this.$watch(
      () => [this.values, this.name, this.errors],
      (data) => {
        const errors = { ...data[2] };
        // the validation_label list of the FileInput element
        const documentName = "_document_name";
        const fileValid = { ...this.fileValid };
        const _items = _.cloneDeep(data[0][data[1]]);
        if (errors) {
          let index = 0;
          for (let name in errors) {
            if (name?.includes(documentName)) {
              if (errors[`${index}_document_name`]?.length > 0) {
                delete _items[index]?.document_name;
                delete _items[index]?.document_data;
                fileValid[name] = false;
              } else {
                fileValid[name] = true;
              }
              index++;
            }
          }
        }
        this.items = _items;
        this.fileValid = fileValid;
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    async onInput({ index, value }) {
      let _items = [...this.items];
      _items[index]['document_data'] = value.value;
      _items[index][value.name] = value.fileName;
      if (!value.value) delete _items[index]?.document_deleted;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(index) {
      let items = this.items.filter((item, i) => {
        return index != i;
      });
      this.resetValue[`${index}_document_name`] = !this.resetValue[`${index}_document_name`];
      this.$emit("onInput", { name: this.name, value: items });
    },
    addEmpty() {
      const _items = [...this.items];
      _items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    onRemoveFile(index) {
      let _items = [...this.items];
      delete _items[index]['document_name'];
      delete _items[index]['document_data'];
      if(_items[index]['document_url']) _items[index]['document_deleted'] = 1;
      this.items = _items;
      this.resetValue[`${index}_document_name`] = !this.resetValue[`${index}_document_name`];
      this.$emit("onInput", { name: this.name, value: _items });
    },
    getFileInputStyle(index, subField, parentField) {
      return (this.fileValid[`${index}_${subField}`] && this.items[index][parentField]) ? 'display:none;' : '';
    },
    judgeDisplayFileInput(index,subField, parentField) {
      return this.editable && this.fileValid[`${index}_${subField}`] && this.items[index][parentField];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
</style>
