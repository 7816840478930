<template>
  <div class="timeLabels">
    <div class="timeLabel"> {{ IS_OBAYASHI && isUsage ? '6' : '0' }}</div>
    <div
      v-if="!isShowAfterAndPreTime"
      v-for="time in prevLabel"
      :key="`prev_${time}`"
      class="timeLabel"
      :style="cellStyle"
    >
      {{ time }}
    </div>
    <div
      v-for="time in mainLabel"
      :key="`main_${time}`"
      class="timeLabel"
      :style="cellStyle"
    >
      {{ time }}
    </div>
    <div
      v-if="!isShowAfterAndPreTime"
      v-for="time in afterLabel"
      :key="`after_${time}`"
      class="timeLabel"
      :style="cellStyle"
    >
      {{ time }}
    </div>
  </div>
</template>
<script>
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
export default {
  props: {
    isUsage: {
      type: Boolean,
      default: false,
    },
    totalTime: {
      type: Number
    },
    prevTime: {
      type: Number
    },
    afterTime: {
      type: Number
    },
    hourDivide: {
      type: Number
    },
    cellRectWidth: {
      type: Number,
      default: 0
    },
    timelabelHeight: {
      type: Number
    }
  },
  computed: {
    // 1セルの分
    cellMin() {
      return Math.floor(60 / this.hourDivide);
    },
    cellStyle() {
      return `width:${this.cellRectWidth * this.hourDivide}px;height:${
        this.timelabelHeight
      }px`;
    },
    mainLabel() {
      let time = this.IS_OBAYASHI && this.isUsage ? 7 : 1;
      const label = this.IS_OBAYASHI && this.isUsage ?
      [...new Array(15)].map(() => {
        let timeLabel = time++;
        return timeLabel == 24 ? 0 : timeLabel;
      }) :
      [...new Array(24)].map(() => {
        let timeLabel = time++;
        return timeLabel == 24 ? 0 : timeLabel;
      });
      return label;
    },
    prevLabel() {
      let time = 24 - this.prevTime;
      const label = [...new Array(this.prevTime)].map(() => {
        return time++;
      });
      return label;
    },
    afterLabel() {
      let time = 1;
      const label = [...new Array(this.afterTime)].map(() => {
        return time++;
      });
      return label;
    },
    IS_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
    isShowAfterAndPreTime() {
      return this.IS_OBAYASHI && this.isUsage;
    }
  }
};
</script>
<style lang="scss" scoped>
.timeLabels {
  display: flex;
  justify-content: space-between;
}
.timeLabel {
  font-size: 12px;
  text-align: right;
}
</style>
