<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-btn
                class="btn-search"
                color="primary"
                @click="inviteOther()"
                v-if="editable && flagSupervisor"
              >
                招待
              </v-btn>
              <Suppliers
                name="machine_rental_company_fields"
                :values="formValues"
                :editable="editable"
                @updateDataDetail="updateDataDetail"
                @updateDataListRight="updateDataListRight"
                @onInput="onInput"
                :flagUserRentalCompany="flagUserRentalCompany"
                :flagSupervisor="flagSupervisor"
                :flagErrorUser="flagErrorUser"
              />
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
      <Popup :dialog="isShow" width="480px">
        <InviteCompanyOther @onCancel="inviteCancel" @onSubmit="inviteSubmit" />
      </Popup>
    </div>
  </div>
</template>
<script>
import InviteCompanyOther from "./InviteForm/InviteCompanyOther.vue";
import Suppliers from "./components/Suppliers.vue";
import Popup from "@/components/common/Popup.vue";
import _ from "lodash";
import { Store } from "@/store/Store.js";
import { ENV_CLIENT } from "@/constants/ENV_CLIENT";

export default {
  data: () => {
    return {
      isShow: false,
      formValues: {},
    };
  },
  components: {
    Suppliers,
    InviteCompanyOther,
    Popup,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    flagUserRentalCompany:{
      type: Boolean,
      default: false
    },
    flagSupervisor:{
      type: Boolean,
      default: false
    },
    flagErrorUser : Boolean,
  },

  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },

    ENV_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    }
  },
  methods: {
    formUpdate() {
      const formValues = _.cloneDeep(this.formValues);
      this.formValues = formValues;
      this.$emit("formUpdate", this.formValues);
    },

    inviteOther() {
      this.isShow = true;
    },

    inviteCancel() {
      this.isShow = false;
      this.$emit("closeForm");
    },
    
    async inviteSubmit(data) {
      data.field_id = this.CURRENT_SITE.field_id;
      let rs = await Store.dispatch("Sites/inviteMachineRentalCompany", data);
      if (!rs.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: `${data.name_sei} ${data.name_mei}さんを招待しました。`,
        });
        await this.updateDataDetail();
        this.isShow = false;
      }
    },
    async updateDataDetail() {
      let params = { id: this.CURRENT_SITE.field_id}
      this.ENV_OBAYASHI ? params.is_oba = 1 : '';
      await Store.dispatch("Sites/getDetail", {params});
      this.$emit("updateFlag");
    },

    updateDataListRight({name,value}){
      let _formValues = _.cloneDeep(this.formValues);
      _formValues[name] = value;
      this.$emit("updateListRight", _formValues);
    },

    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/components/forms/style/forms.scss";
</style>
