const SCHEDULE_COMPANY_INITIAL_ITEM_PLANS = {
  work_company_plans: {
    work_id: null,
    employee_user_id: null,
    field_item_tree_id: null,
    contents: null,
    earlytime_hour: "0.00",
    earlytime_start: null,
    earlytime_end: null,
    overtime_hour: "0.00",
    overtime_start: null,
    overtime_end: null,
    approve_user: null,
    approval_date: null,
    remarks: null
  },
  work_company_classification_plans: [
    // {
    //   cmn_mst_work_classification_id: 1,
    // },
  ],
  work_company_predicted_risk_plans: [
    {
      predicted_risk: null,
      cmn_mst_predicted_risk_magnitude_id: null,
      cmn_mst_predicted_risk_frequency_id: null,
    },
  ],
  work_company_safety_instruction_plans: [
    {
      cmn_mst_safety_instruction_id: null,
    },
  ],
  work_company_user_plans: {
    2: [
      {
        user_id: null,
        used_type: 2,
      },
    ],
    3: [
      {
        user_id: null,
        used_type: 3,
      },
    ],
    4: [
      {
        user_id: null,
        used_type: 4,
      },
      {
        user_id: null,
        used_type: 4,
      },
    ],
    5: [
      {
        user_id: null,
        used_type: 5,
      },
      {
        user_id: null,
        used_type: 5,
      },
    ],
  },
  work_company_time_plans: [
    {
      start_time: null,
      end_time: null,
    },
  ],
  work_company_hot_plans: [
    {
      cmn_mst_fire_purpose_use_id: null,
      cmn_mst_fire_type_id: null,
      cmn_mst_fire_management_method_id: null,
      hot_work_start: null,
      hot_work_end: null,
    },
  ],
};

const SCHEDULE_COMPANY_INITIAL_ITEM_RESULTS = {
  work_company_results: {
    work_id: null,
    employee_user_id: null,
    field_item_tree_id: null,
    contents: null,
    earlytime_hour: "0.00",
    earlytime_start: null,
    earlytime_end: null,
    overtime_hour: "0.00",
    overtime_start: null,
    overtime_end: null,
    approve_user: null,
    approval_date: null,
    remarks: null
  },
  work_company_classification_results: [
    // {
    //   cmn_mst_work_classification_id: 1,
    // },
  ],
  work_company_predicted_risk_results: [
    {
      predicted_risk: null,
      cmn_mst_predicted_risk_magnitude_id: null,
      cmn_mst_predicted_risk_frequency_id: null,
    },
  ],
  work_company_safety_instruction_results: [
    {
      cmn_mst_safety_instruction_id: null,
    },
  ],
  work_company_user_results: {
    2: [
      {
        user_id: null,
        used_type: 2,
      },
    ],
    3: [
      {
        user_id: null,
        used_type: 3,
      },
    ],
    4: [
      {
        user_id: null,
        used_type: 4,
      },
      {
        user_id: null,
        used_type: 4,
      },
    ],
    5: [
      {
        user_id: null,
        used_type: 5,
      },
      {
        user_id: null,
        used_type: 5,
      },
    ],
  },
  work_company_time_results: [
    {
      start_time: null,
      end_time: null,
    },
  ],
  work_company_hot_results: [
    {
      cmn_mst_fire_purpose_use_id: null,
      cmn_mst_fire_type_id: null,
      cmn_mst_fire_management_method_id: null,
      hot_work_start: null,
      hot_work_end: null,
    },
  ],
};

const SCHEDULE_COMPANY_STATUS = {
  EARLY_DEPARTURE: { id: 0, name: " 早出" },
  OVERTIME: { id: 1, name: "残業" },
};

const DIALOG_TEXT_PLANS = {
  REJECT_TITLE: "承認解除確認",
  REJECT_TEXT1: "中止または未承認のものは承認解除できません。",
  REJECT_TEXT2: "承認解除してもよろしいですか。",
  REJECT_BTN_SUBMIT: "解除",
  REJECT_ERROR_TEXT1: "中止または未承認のものは承認解除できません。",
  APPROVE_TITLE: "承認確認",
  APPROVE_TEXT1: "中止または承認済みのものは承認できません。",
  APPROVE_TEXT2: "承認してもよろしいですか。",
  APPROVE_BTN_SUBMIT: "承認",
  ALL_APPROVE_TTTLE: "承認確認",
  ALL_APPROVE_TEXT: "中止または承認済みのものは承認できません。",
  BTN_OK: "OK",
};

const DIALOG_TEXT_RESULTS = {
  REJECT_TITLE: "承認解除確認",
  REJECT_TEXT1: "中止または未承認のものは承認解除できません。",
  REJECT_TEXT2: "承認解除してもよろしいですか。",
  REJECT_BTN_SUBMIT: "解除",
  REJECT_ERROR_TEXT1: "中止または未承認のものは承認解除できません。",
  APPROVE_TITLE: "承認確認",
  APPROVE_TEXT1: "中止または承認済みのものは承認できません。",
  APPROVE_TEXT2: "承認してもよろしいですか。",
  APPROVE_BTN_SUBMIT: "承認",
  ALL_APPROVE_TTTLE: "承認確認",
  ALL_APPROVE_TEXT: "中止または承認済みのものは承認できません。",
  BTN_OK: "OK",
};

const DIALOG_REMOVE_TEXT_PLANS = {
  TITLE: "削除確認",
  TEXT_ALL_APPROVE_1: "選択された作業予定は全て承認済みです。",
  TEXT_ALL_APPROVE_2: "削除できません。",
  BTN_OK: "OK",
  TEXT_1: "選択したデータを削除します。",
  TEXT_2: "未承認のデータのみ削除できます。",
  TEXT_3: "この処理を元に戻すことはできません。",
  TEXT_4: "本当に削除してもよろしいですか？",
  BTN_SUBMIT: "削除",
};

const DIALOG_REMOVE_TEXT_RESULTS = {
  TITLE: "削除確認",
  TEXT_ALL_APPROVE_1: "選択された作業実績は全て承認済みです。",
  TEXT_ALL_APPROVE_2: "削除できません。",
  BTN_OK: "OK",
  TEXT_1: "選択したデータを削除します。",
  TEXT_2: "未承認のデータのみ削除できます。",
  TEXT_3: "この処理を元に戻すことはできません。",
  TEXT_4: "本当に削除してもよろしいですか？",
  BTN_SUBMIT: "削除",
};

const SCHEDULE_APPROVE = {
  REJECT: { id: 0, name: "未承認" },
  APPROVED: { id: 1, name: "承認済" },
};

/**
 * テーブルのタブ
 */
const SCHEDULE_TABLE_TAB_ITEMS = {
  SCHEDULE_COMPANY: { id: 1, name: "予定" },
  SCHEDULE_RESULT: { id: 0, name: "実績" },
};

const SCHEDULE_TYPE = {
  SCHEDULE_TIME: { id: 1, name: "通常" },
  EARLY_TIME: { id: 2, name: "早出" },
  OVER_TIME: { id: 3, name: "残業" },
};

const MINS_ON_HOUR = ["00", "15", "30", "45"];
const getProhibitTimes = (isAll, isEnd) => {
  let prohibitTimes = [];
  let i = 0;
  while (i <= 47) {
    for (let m of MINS_ON_HOUR) {
      let hour = i < 10 ? "0" + i : i;
      let element = {
        id: hour + ":" + m,
        name: ((i % 24) < 10 ? (i == 24 && m == '00' ? 24 : ("0" + (i % 24))) : (i % 24)) + ":" + m,
      };
      prohibitTimes.push(element);
    }
    i++;
  }
  if (isEnd || isAll) {
    prohibitTimes.push({ id: "48:00", name: "24:00" });
  }
  return prohibitTimes;
};

const cmn_mst_time_start = getProhibitTimes(false, false);
const cmn_mst_time_end = getProhibitTimes(false, true);

const getHotTimes = (isAll, isEnd) => {
  let hotTimes = [{ id: null, name: ""}];
  let i = 0;
  while (i <= 23) {
    for (let m of MINS_ON_HOUR) {
      let hour = i < 10 ? "0" + i : i;
      let element = {
        id: hour + ":" + m,
        name: ((i % 24) < 10 ? "0" + (i % 24) : (i % 24)) + ":" + m,
      };
      if(i != 0 || m != "00" || !isEnd) {
        hotTimes.push(element);
      }
    }
    i++;
  }
  if (isEnd || isAll) {
    hotTimes.push({ id: "24:00", name: "24:00" });
  }
  return hotTimes;
};

const hot_time_start = getHotTimes(false, false);
const hot_time_end = getHotTimes(false, true);

const createNumberOfDay = () => {
  let result = [];
  for (let i = 0; i <= 24; i += 0.25) {
    result.push({ id: `${Number(i).toFixed(2)}`, name: Number(i).toFixed(2) });
  }
  return result;
};

const NUMBER_OF_DAYS = createNumberOfDay();

const time_convert = (num) => {
  var hours = Math.floor(num / 60);
  var minutes = num % 60;
  minutes = (minutes == 0) ? (minutes + "0") : minutes;
  return (hours <10 ? "0" + hours : hours) + ":" + minutes;
};

const convertTimeZone = (time) => {
  let nums = time.split(":");
  let hours = ((nums[0] % 24) < 10 ? "0" + (nums[0] % 24) : (nums[0] % 24));
  if(nums[0] =="24") {
    hours = nums[0];
  }
  let minutes = nums[1];
  return hours + ":" + minutes;
}

const USER_TYPE = {
  TYPE_1: "1", // 元請担当者
  TYPE_2: "2", // 火元責任者
  TYPE_3: "3", // 火気使用責任者
  TYPE_4: "4", // 酸素欠乏危険作業主任者
  TYPE_5: "5", // 特別教育修了者
};

const SCHEDULE_COMPANY_EMPTY = [{ 
  work_id: null,
  children: []
}];

export {
  SCHEDULE_COMPANY_INITIAL_ITEM_PLANS,
  SCHEDULE_COMPANY_INITIAL_ITEM_RESULTS,
  SCHEDULE_COMPANY_STATUS,
  DIALOG_TEXT_PLANS,
  DIALOG_TEXT_RESULTS,
  DIALOG_REMOVE_TEXT_PLANS,
  DIALOG_REMOVE_TEXT_RESULTS,
  SCHEDULE_APPROVE,
  SCHEDULE_TABLE_TAB_ITEMS,
  SCHEDULE_TYPE,
  cmn_mst_time_start,
  cmn_mst_time_end,
  hot_time_start,
  hot_time_end,
  NUMBER_OF_DAYS,
  USER_TYPE,
  SCHEDULE_COMPANY_EMPTY,
  time_convert,
  convertTimeZone
};
