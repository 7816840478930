<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <SafetyRules
                        name="company_safety_rules"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="備考">
                        <InputTextArea
                          name="remarks"
                          :values="formValues.company"
                          :editable="editable"
                          validation_label="備考"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "../elements/Label";
import InputTextArea from "../elements/InputTextArea";
import SafetyRules from "./components/SafetyRules.vue";
import _ from "lodash";
export default {
  data: () => {
    return {
      formValues: {},
    };
  },
  components: {
    Label,
    SafetyRules,
    InputTextArea,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = _.cloneDeep(newValue);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      if(name == "remarks") {
        let company = {...formValues['company']};
        company[name] = value;
        this.$emit("formUpdate", {'company': company});
      } else {
        this.$emit("formUpdate", {'company_safety_rules': value});
      }
     
    },
  },
};
</script>
