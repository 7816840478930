<template>
  <v-card flat>
    <v-card-text>
      <v-form autocomplete="off">
        <v-container>
          <v-data-table
            :headers="TABLE_LABELS"
            :items="item.machine_company_field_histories"
            :items-per-page="item.machine_company_field_histories.length"
            :height="mainHeight-16"
            fixed-header
            hide-default-footer
            disable-sort
            class="elevation-0 v-data-table__wrapper"
            sort-by="updatedAt"
            :noDataText="NO_DATA_MESSAGE"
          >
            <template v-slot:[`item.used_company_name`]="{ item }">
              <div class="long-text">{{ item.used_company_name }}</div>
            </template>
            <template v-slot:[`item.foreman_name`]="{ item }">
              <div class="long-text">{{ item.foreman_name }}</div>
            </template>
            <template v-slot:[`item.apply_user_name`]="{ item }">
              <div class="long-text">{{ item.apply_user_name }}</div>
              <div class="long-text">{{ item.approve_apply_user_name }}</div>
            </template>
            <template v-slot:[`item.return_user_name`]="{ item }">
              <div class="long-text">{{ item.return_user_name }}</div>
              <div class="long-text">{{ item.approve_return_user_name }}</div>
            </template>
            <template v-slot:[`item.inspection_results`]="{ item }">
              <v-btn
                v-if="item.has_inspection_result_flag == HAS_INSPECTION_RESULT"
                @click="openInspecResultForm(item)"
                class="primary btn-inspec">{{ TITLE_BTN_INSPEC_RESULT }}</v-btn>
            </template>
          </v-data-table>
          <Popup :dialog="isClickOpenFormInspec">
            <InspectionResultForm
              :isMoveFromViewDetail="true"
              :titleMoveDetail="titleDetail"
              :machineCompanyFieldHistoryId="machineCompanyFieldHistoryId"
              @cancel="closeInspecResultForm"
            />
          </Popup>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import { Store } from "@/store/Store.js";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import { TABLE_LABELS } from "@/constants/MACHINE_FIELD";
import { USED_APPLY_STATUS, HAS_INSPECTION_RESULT } from "@/constants/DEVICE_STOCK";
import InspectionResultForm from "@/components/forms/device/stock/inspection_result/InspectionResultForm.vue";
import Popup from "@/components/common/Popup";
const STORE_INSPEC = "MachineFieldInspection";
const TITLE_BTN_INSPEC_RESULT = "点検実績";

export default {
  data: () => {
    return {
      NO_DATA_MESSAGE,
      TABLE_LABELS,
      USED_APPLY_STATUS,
      isClickOpenFormInspec: false,
      TITLE_BTN_INSPEC_RESULT,
      machineCompanyFieldHistoryId: null,
      HAS_INSPECTION_RESULT,
    };
  },
  components: {
    InspectionResultForm,
    Popup,
  },
  props: {
    item: Object,
    mainHeight: {
      type: Number,
      default: 0
    },
    titleDetail: String,
  },
  methods: {
    async openInspecResultForm(item) {
      const params = {
        machine_company_field_history_id: item.id,
      };
      const result = await Store.dispatch(`${STORE_INSPEC}/getInspectionResults`, params);
      if (!result.hasError) {
        this.isClickOpenFormInspec = true;
        this.machineCompanyFieldHistoryId = item.id;
      } else {
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
    },
    closeInspecResultForm() {
      this.isClickOpenFormInspec = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.long-text {
  word-break: break-all;
}
@media screen and (max-width: 1280px) {
  ::v-deep .v-data-table .v-data-table__wrapper table tbody tr td:last-child {
    padding: 0 !important;
  }
  table {
    .btn-inspec {
      width: 50px;
      font-size: 12px !important;
    }
  }
}
</style>
