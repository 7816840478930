<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters>
                    <v-col v-if="editable" cols="12" sm="3" md="3">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="最低血圧" :editable="editable">
                            <InputNumber
                              name="blood_pressure_min"
                              :values="formValues.users"
                              :editable="editable"
                              placeholder=""
                              validation_label="最低血圧"
                              :validation_rules="getBloodPressureMin"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col v-if="editable" cols="12" sm="3" md="3">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="最高血圧" :editable="editable">
                            <InputNumber
                              name="blood_pressure_max"
                              :values="formValues.users"
                              :editable="editable"
                              placeholder=""
                              validation_label="最高血圧"
                              :validation_rules="getBloodPressureMax"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col v-if="!editable" cols="12" sm="6" md="6">
                      <!-- 血圧 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="血圧" :editable="editable">
                            <InputText
                              name="blood_pressure"
                              :values="bloodPressure"
                              :editable="editable"
                              placeholder=""
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <!-- 血液型 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="血液型" :editable="editable">
                            <Select
                              name="cmn_mst_bloodtype_id"
                              :values="formValues.users"
                              :editable="editable"
                              item_text="bloodtype"
                              :items="BLOODTYPE"
                              placeholder=""
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <!-- 最近の健康診断日 -->
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item class="date-fix">
                        <v-list-item-content>
                          <Label label="最近の健康診断日" :editable="editable">
                            <InputDatepicker
                              name="health_examination_date"
                              :values="formValues.users"
                              :editable="editable"
                              placeholder=""
                              :max="max"
                              validation_label="最近の健康診断日"
                              :flagNull="true"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-col
                    ><SectionLabel label="一般健康診断" :editable="editable"
                  /></v-col>
                  <!-- 一般健康診断 -->
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <HealthValiableInput
                            name="general_medical_examination"
                            :values="user_health_informations"
                            :editable="editable"
                            :selectItems="GENERAL_MEDICAL_EXAMINATION"
                            @onInput="
                              onInput(USER_EVENT_TARGET.USER_HEALTH_INFORMATIONS, $event)
                            "
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <!-- じん肺健康診断 -->
                  <v-col><SectionLabel label="じん肺健康診断" /></v-col>
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <HealthValiableInput
                            name="pneumoconiosis_health_checkup"
                            :values="user_health_informations"
                            :editable="editable"
                            :selectItems="PNEUMOCONIOSIS_HEALTH_CHECKUP"
                            @onInput="
                              onInput(USER_EVENT_TARGET.USER_HEALTH_INFORMATIONS, $event)
                            "
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <!-- 特殊健康診断 -->
                  <v-col><SectionLabel label=" 特殊健康診断" /></v-col>
                  <v-list-item>
                    <v-list-item-content>
                      <HealthValiableInput
                        name="special_medical_examination"
                        :values="user_health_informations"
                        :editable="editable"
                        :selectItems="SPECIAL_MEDICAL_EXAMINATION"
                        :row="4"
                        @onInput="onInput(USER_EVENT_TARGET.USER_HEALTH_INFORMATIONS, $event)"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "../elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "../elements/InputText";
import InputNumber from "../elements/InputNumber";
import InputDatepicker from "../elements/InputDatepicker.vue";
import Select from "../elements/Select.vue";
import HealthValiableInput from "./components/HealthValiableInput.vue";
import {
  USER_EVENT_TARGET,
} from "@/constants/EMPLOYEE";

export default {
  data: () => {
    return {
      formValues: {},
      bloodPressure: {
        blood_pressure: null,
      },
      BLOODTYPE: [{ id: null, bloodtype: "" },...Store.state.CmnMst.constants.entries.cmn_mst_bloodtype],
      CHECKUP_ARRAY: Store.state.CmnMst.constants.entries.cmn_mst_checkup,
      GENERAL_MEDICAL_EXAMINATION: [],
      SPECIAL_MEDICAL_EXAMINATION: [],
      PNEUMOCONIOSIS_HEALTH_CHECKUP: [],
      USER_EVENT_TARGET,
      items: [],
      user_health_informations: {},
      max: new Date().toISOString().slice(0, 10),
    };
  },
  components: {
    Label,
    SectionLabel,
    InputText,
    InputDatepicker,
    Select,
    InputNumber,
    HealthValiableInput,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
        this.user_health_informations = {
          ...this.formValues.user_health_informations,
        };
        if (!this.editable) {
          if (!this.formValues.users.blood_pressure_max && !this.formValues.users.blood_pressure_min) {
            this.bloodPressure["blood_pressure"] = "";
          } else {
            let _blood_pressure_min = this.formValues.users.blood_pressure_min ? this.formValues.users.blood_pressure_min : "";
            let _blood_pressure_max = this.formValues.users.blood_pressure_max ? this.formValues.users.blood_pressure_max : "";
            this.bloodPressure["blood_pressure"] = `${_blood_pressure_min}～${_blood_pressure_max}`;
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.convertData(this.CHECKUP_ARRAY);
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    getBloodPressureMax(){
      let results = `minmax-pulldown:40,300`;
      if(this.formValues?.users?.blood_pressure_min){
      results = `${results}|compare-blood-pressure-max:${this.formValues.users.blood_pressure_min}`
      }
      return results
    },
    getBloodPressureMin(){
      let results = `minmax-pulldown:40,300`;
      if(this.formValues?.users?.blood_pressure_max){
        results = `${results}|compare-blood-pressure-min:${this.formValues.users.blood_pressure_max}`
      }
      return results
    }
  },
  methods: {
    onInput(parents_name, { name, value }) {
      const formValues = { ...this.formValues };
      formValues[parents_name][name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    convertData(data) {
      let results = data.reduce(function(results, item) {
        (results[item.catergory_id] = results[item.catergory_id] || []).push(item);
        return results;
      }, {});
      this.GENERAL_MEDICAL_EXAMINATION = [{ id: null, sub_catergory: "" },...results[1]];
      this.SPECIAL_MEDICAL_EXAMINATION = [{ id: null, sub_catergory: "" },...results[2]];
      this.PNEUMOCONIOSIS_HEALTH_CHECKUP = [{ id: null, sub_catergory: "" },...results[3]];
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";

.date-fix {
  max-width: 42%;
}
</style>
