<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <Label label="最大作業半径" :editable="editable">
                            <InputText
                              name="maximum_working_radius"
                              :values="formValues"
                              :editable="isReadOnly"
                              placeholder="10"
                              suffix="m"
                            />
                          </Label>
                        </v-col>
                        <v-col>
                          <Label label="最大作業半径時の定格荷重" :editable="editable">
                            <InputText
                              name="maximum_rated_load"
                              :values="formValues"
                              :editable="isReadOnly"
                              placeholder="0.8"
                              suffix="t"
                            />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <Label label="ブーム長さ" :editable="editable">
                            <InputText
                              name="boom_length"
                              :values="formValues"
                              :editable="isReadOnly"
                              placeholder="38.7"
                              suffix="m"
                            />
                          </Label>
                        </v-col>
                        <v-col>
                          <Label label="アウトリガ張出寸法" :editable="editable">
                            <InputText
                              name="outrigger_overhang_dimensions"
                              :values="formValues"
                              :editable="isReadOnly"
                              placeholder="1,695"
                              suffix="mm"
                            />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <Label label="駆動方式" :editable="editable">
                            <v-text-field
                              v-model="drive_system_name"
                              :disabled="true"
                            ></v-text-field>
                          </Label>
                        </v-col>
                        <v-col><Label/></v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <Label label="最大吊上げ荷重" :editable="editable">
                            <InputText
                              name="maximum_lifting_load"
                              :values="formValues"
                              :editable="isReadOnly"
                              placeholder="1,000"
                              suffix="t"
                            />
                          </Label>
                        </v-col>
                        <v-col>
                          <Label label="最大地上揚程" :editable="editable">
                            <InputText
                              name="maximum_ground_lift"
                              :values="formValues"
                              :editable="isReadOnly"
                              placeholder="0.75"
                              suffix="m"
                            />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <Label label="主フック重量" :editable="editable">
                            <InputText
                              name="main_hook_weight"
                              :values="formValues"
                              :editable="isReadOnly"
                              placeholder="1,000"
                              suffix="t"
                            />
                          </Label>
                        </v-col>
                        <v-col>
                          <Label label="補フック重量" :editable="editable">
                            <InputText
                              name="supplementary_hook_weight"
                              :values="formValues"
                              :editable="isReadOnly"
                              placeholder="0.75"
                              suffix="t"
                            />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <SectionLabel label="資格・免許" :editable="editable"/>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <Label label="技能検定職種" :editable="editable">
                            <InputText
                              v-for="(item, index) in device_licences" 
                              :key="index"
                              name="license_name"
                              :values="item"
                              :editable="isReadOnly"
                              placeholder="施工関係"
                            />
                          </Label>
                        </v-col>
                        <v-col>
                          <Label label="免許・資格名" :editable="editable">
                            <InputText
                              v-for="(item, index) in device_licences" 
                              :key="index"
                              name="skill_test_occupation"
                              :values="item"
                              :editable="isReadOnly"
                              placeholder="測量士"
                            />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <SectionLabel label="特別教育" :editable="editable"/>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="特別教育名" :editable="editable">
                        <InputText
                          v-for="(item, index) in device_sp_trainings" 
                          :key="index"
                          name="training_name"
                          :values="item"
                          :editable="isReadOnly"
                          placeholder="動力プレス機の金型等の取付け、取外し調整"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "../../elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "../../elements/InputText";
import Select from "../../elements/Select.vue";
import InputDatepicker from "../../elements/InputDatepicker.vue";
import { Store } from "@/store/Store.js";

export default {
  data: () => {
    return {
      formValues: {},
      isReadOnly: false,
      device_licences: [],
      device_sp_trainings: [],
      cmn_mst_sp_trainings: [],
      cmn_mst_licences: [],
      cmn_mst_crane_drive_systems: [],
      drive_system_name: "",
    };
  },
  components: {
    Label,
    SectionLabel,
    InputText,
    Select,
    InputDatepicker,
  },
  props: {
    item: Object,
    crane_id: Number,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted () {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
        this.cmn_mst_sp_trainings = Store.state.CmnMst.constants.entries.cmn_mst_sp_trainings;
        this.cmn_mst_licences = Store.state.CmnMst.constants.entries.cmn_mst_licences;
        this.cmn_mst_crane_drive_systems = Store.state.CmnMst.constants.entries.cmn_mst_crane_drive_systems;
        this.getDeviceLicences();
        this.getTrainingName();
        this.getDriveSystemName();
        if(!this.crane_id) {
          this.formValues = {};
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters['Cranes/getCranes'],
      (newValue) => {
        if (this.crane_id) {
          this.formValues = {...this.formValues, ...newValue};
          this.getDeviceLicences();
          this.getTrainingName();
          this.getDriveSystemName();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle () {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    getTrainingName() {
      this.device_sp_trainings = [{training_name: ""}];
      if (this.crane_id) {
        this.device_sp_trainings = [...this.formValues['device_sp_trainings']];
        this.device_sp_trainings.forEach(item => {
          const sp_training = this.cmn_mst_sp_trainings.find(skill => skill.id == item.cmn_mst_sp_training_id);
          if(sp_training) {
            item['training_name'] = `${sp_training['training_name']}`;
          }
        });
        if(this.device_sp_trainings?.length == 0) {
          this.device_sp_trainings.push({training_name: ""});
        }
      }
    },
    getDeviceLicences() {
      this.device_licences = [{skill_test_occupation: "", license_name: ""}];
      if (this.crane_id) {
        this.device_licences = [...this.formValues['device_licences']];
        this.device_licences.forEach(item => {
          const sp_training = this.cmn_mst_licences.find(skill => skill.id == item.cmn_mst_licence_id);
          if(sp_training) {
            item['skill_test_occupation'] = `${sp_training['sub_catergory']}`;
            item['license_name'] = `${sp_training['catergory']}`;
          }
        });
        if(this.device_licences?.length == 0) {
          this.device_licences.push({skill_test_occupation: "", license_name: ""});
        }
      }
    },
    getDriveSystemName() {
      this.drive_system_name = "";
      if (this.crane_id) {
        this.cmn_mst_crane_drive_systems.forEach(item => {
          if(item.id == this.formValues['cmn_mst_crane_drive_system_id']) {
            this.drive_system_name = item.drive_system;
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
</style>
