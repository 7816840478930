// import Vue from "vue";
import Echo from "laravel-echo";
window.io = require("socket.io-client");

export default {
  install: () => {
    console.log("VUE_APP_SOCKET_HOST:", process.env.VUE_APP_SOCKET_HOST);
    window.Echo = new Echo({
      broadcaster: "socket.io",
      host: process.env.VUE_APP_SOCKET_HOST,
      path: "/websocket/socket.io/",
    });
  },
};
