import Vue from "vue";
import Vuex from "vuex";
import { entranceSummary } from "../../../api/modules/entranceSummary";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = entranceSummary;

export const EntranceSummary = {
    namespaced: true,

    state: {
        data: [],
        pagination: {},
    },

    mutations: {
        SET_DATA(state, payload) {
            state.data = payload;
        },
        SET_PAGINATION(state, payload) {
            state.pagination = payload;
        },
        SET_CHILD_DATA(state, payload) {
            let parent = [];
            let children = [];
            let data = [];
            parent = state.data.find(
                (item) => item.field_tree_id === payload.field_tree_id
            );
            children = [...parent.children, ...payload.entries];
            data = state.data.map((item) => {
                if (item.field_tree_id === payload.field_tree_id) {
                    const _item = { ...item };
                    _item.children = children;
                    return _item;
                }
                return item;
            });
            state.data = data;
        },
    },

    actions: {
        //機材予定一覧
        async getListEntranceSummary({ commit }, payload) {
            const response = await ENTITY.getListEntranceSummary(payload);
            if (response.hasError) {
                return response;
            }
            const { entries, pagination } = response.data.contents;
            commit("SET_DATA", entries);
            commit("SET_PAGINATION", pagination);
            return response;
        },

        async getListEntranceSummariesByCompany({ commit }, payload) {
            const response = await ENTITY.getListEntranceSummariesByCompany(payload);
            if (response.hasError) {
                return response;
            }
            const entries = response.data.contents;
            commit("SET_CHILD_DATA", {
                entries,
                field_tree_id: payload.params.field_tree_id,
            });
            return response;
        },
    },

    getters: {
        getData: (state) => {
            return state.data;
        },
        getPagination: (state) => {
            return state.pagination;
        },
    },
};
