var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"dialog"},[_c('div',{staticClass:"header sticky"},[_c('v-card-title',{attrs:{"small":""}},[_c('div',{staticClass:"title"},[_vm._v("作業グループ選択")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","icon":"","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider')],1),_c('SearchSelectList',{attrs:{"title_search":"作業グループ名"},on:{"search":_vm.searchData,"formUpdate":_vm.formUpdate}},[[_c('v-data-table',{staticClass:"content-table",attrs:{"height":_vm.items.length >= 12 ? '67vh' : '',"item-key":"id","items":_vm.items,"items-per-page":_vm.items.length,"headers":_vm.content,"fixed-header":"","hide-default-footer":"","disable-sort":"","show-select":"","noDataText":_vm.NO_DATA_MESSAGE},on:{"click:row":function (item, ref) {
	var select = ref.select;
	var isSelected = ref.isSelected;

	return select(!isSelected);
},"item-selected":_vm.updateSelectedItems,"toggle-select-all":_vm.selectAllItems},scopedSlots:_vm._u([{key:"item.company_info",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"overflow":"hidden","text-overflow":"ellipsis","max-width":"380px","white-space":"nowrap"}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.group_name)+" ")])],1)]}}]),model:{value:(_vm.dataChoose),callback:function ($$v) {_vm.dataChoose=$$v},expression:"dataChoose"}})]],2),_c('div',{staticClass:"button-group"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-3",attrs:{"cols":"9","sm":"10"}}),_c('v-col',{staticClass:"pt-3 pr-2",attrs:{"cols":"3","sm":"2"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('clickButton')}}},[_vm._v("決定")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }