<template>
  <FormDialog>
    <template #header>
      <!-- 
        (共通)
        ヘッダーは全て共通
       -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editable"
          depressed
          small
          outlined
          color="primary"
          class="mr-5"
          @click="onDetail"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="isEditable && !editable"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn
          v-if="isEditable && editable"
          depressed
          small
          :disabled="!valid"
          color="primary"
          @click="onSubmit"
        >
          登録
        </v-btn>
        <v-btn icon @click="isShowConfirmDialog = true" data-testid="btn-close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!-- 
        (共通)
        全てのフォームのバリデーションの監視を行う
       -->
      <ValidationObserver ref="observer" v-slot="observer">
        <!-- フォームtab -->
        <RoomForm1
          :editable="editable"
          :item="item"
          :isApproval="isApproval"
          :isEditable="isEditable"
          :mainHeight="params.mainHeight"
          @formUpdate="formUpdate"
        />

        <!-- 
          (共通)
          ValidationObserverのスロットプロパティを受け取って
          updateValidateメソッドを実行して、
          バリデーション結果をスクリプト側に渡してます
        -->
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmDialog
          title=""
          text="「登録」ボタンで登録していないフォームの値は消えますがよろしいですか？"
          @close="isShowConfirmDialog = false"
          @yes="closeForm"
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "../elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import RoomForm1 from "@/components/forms/chatroom/RoomFormPage1.vue";
import { TEXTCHAT_APPROVAL_TYPE } from "@/constants/TEXTCHAT"; //絞り込みフォームで使用

/**
 * (共通)
 * タイトル
 */
const TITLE = {
  NEW: "ルーム作成",
  EDIT: "ルーム編集",
};

export default {
  data: () => {
    return {
      valid: false, //バリデーション結果
      // tab初期化
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      TEXTCHAT_APPROVAL_TYPE,
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmDialog,
    RoomForm1,
  },
  props: {
    item: Object,
    isNewItem: Boolean,
    isApproval: Boolean,
    isEditable: Boolean,
  },
  async mounted() {
    /**
     * (共通)
     * 新規作成を監視
     */
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        //新規の場合は最初から編集モード
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    // 新規or編集でタイトル変更
    formtitle() {
      return this.isNewItem ? TITLE.NEW : TITLE.EDIT;
    },
  },
  methods: {
    /**
     * (共通)
     * フォームの変更を送信
     */
    formUpdate(params) {
      this.$emit("formUpdate", params);
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      this.editable = true;
    },
    /**
     * (共通)
     * 状態を詳細に変更
     */
    onDetail() {
      this.editable = false;
    },

    /**
     * (共通)
     * 登録
     */
    onSubmit() {
      // 承認権限がない場合は、承認をしてもらう必要がある -> 更新は承認必要ない
      // const params = {
      //   needApproval: !this.isApproval ? true : false,
      // };

      //新規&承認権限ない場合のみ承認必要
      const needApprove = this.isNewItem && !this.isApproval;
      const params = {
        needApproval: needApprove ? true : false,
      };
      this.$emit("submit", params);
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid }) {
      console.log("valid", valid);
      this.valid = valid;
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
