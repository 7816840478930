<template>
  <!-- parent table -->
  <v-data-table
    :headers="tableLabels"
    :items="items"
    :items-per-page="itemsPerPage"
    :height="tableHeight"
    fixed-header
    hide-default-footer
    disable-sort
    :show-select="true"
    class="elevation-0 v-data-table__wrapper"
    sort-by="updatedAt"
  >
    <!-- parent table template -->
    <template v-slot:body="{ items }">
      <template v-if="items && items.length > 0">
        <tbody v-for="item in items" :key="item.safety_diaries_id">
        <tr>
          <td class="start">
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <!-- toggle icon -->
                <v-icon @click.stop="toggleShowChildren(item.safety_diaries_id)" v-if="item.children.length > 0 && item.safety_form == 2">
                  {{
                    isShowChildren[item.safety_diaries_id]
                      ? "mdi-chevron-down"
                      : "mdi-chevron-right"
                  }}
                </v-icon>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <!-- checkbox -->
                <v-simple-checkbox
                  v-if="ROLE_DELETE_APPROVE_CHECKBOX(item)"
                  :value="isSelectedParent(item.safety_diaries_id)"
                  @input="updateCheckbox(item.status,item, $event, true)"
                ></v-simple-checkbox>
              </v-col>
            </v-row>
          </td>
          <td class="col-status" @click="$emit('openItemForm', item)">
            <v-chip
              v-if="item.status"
              :color="getStatusCode(item.status).color"
              small
              dark>{{ getStatusCode(item.status).status_code || "" }}
            </v-chip>
          </td>
          <td @click="$emit('openItemForm', item)">
            {{ item.patrol_record_date }}
          </td>
          <td @click="$emit('openItemForm', item)">
            <div>
              {{ item.detect_user.substring(0, item.detect_user.indexOf('(')) }}
            </div>
            <div>
              {{ item.detect_user.substring(item.detect_user.indexOf('(')) }}
            </div>
          </td>
          <td @click="$emit('openItemForm', item)">
            <v-chip
              v-if="item.urgency || item.urgency == 0"
              class="ma-2"
              small
              text-color="white"
              :color="getUrgency(item.urgency).color"
            >
              {{ getUrgency(item.urgency).name || "" }}
            </v-chip>
          </td>
          <td @click="$emit('openItemForm', item)">
            <div class="data-long">
              <TooltipWrapper inline :text="item.patrol_record_name">
                {{ item.patrol_record_name }}
              </TooltipWrapper>
            </div>
          </td>
          <td @click="$emit('openItemForm', item)">
            <span v-if="item.safety_form !== 2">
              {{ item.field_items || "" }}
            </span>
          </td>
          <td @click="$emit('openItemForm', item)">
            <span v-if="item.safety_form !== 2">
              {{ item.recovery_user || "" }}
            </span>
          </td>
          <td @click="$emit('openItemForm', item)">
            <span v-if="item.safety_form !== 2">
              {{ item.recovery_date || "" }}
            </span>
          </td>
          <td @click="$emit('openItemForm', item)">
            <div class="data-long" v-if="item.safety_form !== 2">
              <TooltipWrapper inline :text="item.recovery_content">
                {{ item.recovery_content || "" }}
              </TooltipWrapper>
            </div>
          </td>
          <td @click="$emit('openItemForm', item)"><v-icon v-if="item.image_flag === 1 && item.safety_form !== 2" style="color: black">mdi-paperclip</v-icon></td>
        </tr>
        <!-- child table template -->
        <template v-for="itemChild in item.children" v-if="item.children.length > 0 && item.safety_form == 2">
          <tr
            v-show="isShowChildren[item.safety_diaries_id]"
            class="child-table"
            :key="itemChild.safety_diary_comment_correction_id"
          >
            <td class="start">
              <v-row>
                <v-col cols="12" sm="6" md="6"></v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-simple-checkbox
                    v-if="ROLE_DELETE_APPROVE_CHECKBOX(item)"
                    :value="isSelected(itemChild.safety_diary_comment_correction_id)"
                    @input="updateCheckbox(itemChild.status,itemChild, $event, null, item)"
                  ></v-simple-checkbox>
                </v-col>
              </v-row>
            </td>
            <td class="col-status" @click="$emit('openItemForm', { ...item, itemChild: itemChild })">
              <v-chip
                v-if="itemChild.status"
                :color="getStatusCode(itemChild.status).color"
                small
                dark>{{ getStatusCode(itemChild.status).status_code || ""}}
              </v-chip>
            </td>
            <td @click="$emit('openItemForm', { ...item, itemChild: itemChild })">
            </td>
            <td @click="$emit('openItemForm', { ...item, itemChild: itemChild })">
            </td>
            <td @click="$emit('openItemForm', { ...item, itemChild: itemChild })"></td>
            <td @click="$emit('openItemForm', { ...item, itemChild: itemChild })">
              <div class="data-long">
                <TooltipWrapper inline :text="itemChild.pointing_out_detail">
                  {{ itemChild.pointing_out_detail }}
                </TooltipWrapper>
              </div>
            </td>
            <td @click="$emit('openItemForm', { ...item, itemChild: itemChild })">
              {{ itemChild.field_items }}
            </td>
            <td @click="$emit('openItemForm', { ...item, itemChild: itemChild })">
              {{ itemChild.recovery_user }}
            </td>
            <td @click="$emit('openItemForm', { ...item, itemChild: itemChild })">
              {{ itemChild.recovery_date }}
            </td>
            <td @click="$emit('openItemForm', { ...item, itemChild: itemChild })">
              <div class="data-long">
                <TooltipWrapper inline :text="itemChild.recovery_content">
                  {{ itemChild.recovery_content }}
                </TooltipWrapper>
              </div>
            </td>
            <td @click="$emit('openItemForm', { ...item, itemChild: itemChild })">
              <v-icon v-if="itemChild.image_flag === 1" style="color: black">mdi-paperclip</v-icon>
            </td>
          </tr>
        </template>
        <tr
          v-show="
              isShowChildren[item.safety_diaries_id] &&
              (childTableDisplayMoreNumber(item.total_children_item,item.children.length) > 0 )
            "
        >
          <td colspan="11" style="border-bottom: 0">
            <div class="d-flex justify-center">
              <v-btn
                x-small
                depressed
                :disabled="isDisable"
                class="mr-4 display-more"
                color="#E5E5E5"
                v-if="childTableDisplayMoreNumber(item.total_children_item,item.children.length) > 0"
                @click="getChildren(item)"
              >さらに表示する
                <v-badge
                  class="display-more-number"
                  inline
                  color="#767676"
                  :content="
                      childTableDisplayMoreNumber(
                        item.total_children_item,
                        item.children.length
                      )
                    "
                />
              </v-btn>
            </div>
          </td>
        </tr>
        </tbody>
      </template>
      <div v-else-if="flagNotData" class="schedule_nodata mt-4">
        <span>{{ NO_DATA_MESSAGE }}</span>
      </div>
    </template>
  </v-data-table>
</template>
<script>
import { URGENCY_CLASSIFICATION_SELECT , STATUS_OBJECT} from "@/constants/PATROL_RECORD";
import TooltipWrapper from "@/components/tooltip/TooltipWrapper.vue";
import {Store} from "@/store/Store";


export default {
  data() {
    return {
      isDisable: false,
      time_out: null
    };
  },
  props: {
    tableLabels: Array,
    itemsPerPage: Number,
    tableHeight: Number,
    isTableTypeAll: Boolean,
    items: Array,
    isSelected: Function,
    isSelectedParent: Function,
    updateCheckbox: Function,
    toggleShowChildren: Function,
    childTableDisplayMoreNumber: Function,
    isShowChildren: Object,
    NO_DATA_MESSAGE: String,
    flagNotData: Boolean
  },
  components: {
    TooltipWrapper
  },
  computed : {
    STATUS_OBJECT() {
      return Object.keys(STATUS_OBJECT).map((key) => {
        return STATUS_OBJECT[key];
      });
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },
  mounted() {
    this.getUserRole();
  },
  methods: {
    async getUserRole() {
      await Store.dispatch(`Report/getUserRole`, this.CURRENT_SITE.field_id);
    },
    getChildren(item) {
      this.isDisable = true;
      // Re-enable after submit
      this.time_out = setTimeout(() => {
        this.isDisable = false;
      }, 1000);
      this.$emit("getChildItems", item.safety_diaries_id, item.status_code_flag_2,
        Math.floor((item.children.length - 3) / 3) + 2);
    },
    getUrgency(type) {
      return URGENCY_CLASSIFICATION_SELECT.find(e => e.value === type);
    },
    getStatusCode(status) {
      return this.STATUS_OBJECT.find(e => e.status == status);
    },
    ROLE_DELETE_APPROVE_CHECKBOX(parentItem) {
      let userRole = Store.getters["Report/getUserRole"];
      let userLogin = Store.getters["Login/getUser"];
      let companyUser = JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user?.company_id;

      let fieldTreeCompanyId = parentItem.field_tree_company_id;
      //連絡ITEM
      if(parentItem.safety_form == 3){
        if (Object.values(userRole?.nest_no).indexOf(0) > -1) return true;
        return (userLogin.id === parentItem.detect_user_id);
      }
      //指摘ITEM
      else{
        if(userRole?.role === 1) return true;
        return (fieldTreeCompanyId === companyUser);
      }
    }
  },
  /**
   * Important: clear timeout
   */
  beforeDestroy() {
    // clear the timeout before the component is destroyed
    clearTimeout(this.time_out);
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
// ヘッダーとテーブルコンテンツのたて位置を合わせるために
// show-selectオプションをONにした状態でcheckboxを非表示
::v-deep .v-data-table-header {
  .v-input--selection-controls__input {
    display: none !important;
  }
}

td.start div.row {
  width: 75px !important;
}

td.col-status {
  width: 110px !important;
}

.child-table {
  td.start {
    padding-right: 0;
  }
}

.schedule_nodata {
  position: absolute;
  left: 50%;
}

button.add-new-child,
button.display-more {
  height: 20px !important;
}

.display-more-number ::v-deep .v-badge__badge {
  font-size: 10px;
}

.display-more-number ::v-deep .v-badge__badge {
  padding: 1px;
  height: 14px;
}

div.circle-red {
  background-color: $color_warning_dark_red;
}

div.circle-blue {
  background-color: $color_warning_blue;
}

div.warning-icon {
  display: inline-flex;
  justify-content: center;
  border-radius: 50%;
  font-size: 10px;
  color: white;
  width: 25px;
  height: 25px;
  padding-top: 4px;
  margin: 3px;
}

.data-long {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-height: 1.2em; /* fallback */
  max-height: 4.5em;
  align-self: center;
  white-space: pre-line;
  word-break: break-all;
}
</style>
