<template>
  <div class="wrapper">
    <!-- web用のチャットビュー -->
    <wrapperWeb
      :goUsers="goUsers"
      title="チャット"
      :chat_room_id="chat_room_id"
    >
      <ChatView :chat_room_id="chat_room_id" :userId="user_id" />
    </wrapperWeb>
    <!--  (共通) 削除ダイアログ / 文言変えてもok -->
    <Popup :dialog="isShowItemForm">
      <Form
        :item="editedItem"
        :isNewItem="false"
        :isApproval="true"
        :isEditable="true"
        @formUpdate="formUpdate"
        @submit="submitForm"
        @cancel="closeItemForm"
        ref="Form"
      />
    </Popup>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import ChatView from "../chat/index.vue";
// // oekakiWeb用wrapperは枠内で表示
import wrapperWeb from "./components/oekakiWeb/index.vue";
import Popup from "@/components/common/Popup.vue";
import Form from "@/components/forms/chatroom/RoomForm.vue";

export default {
  data: () => {
    return {
      chat_room_id: null,
      user_id: null,
      room: {},
      isShowItemForm: false,
      //チャット情報
      editedItem: {
        name: "",
        users: [],
      },
    };
  },
  components: {
    ChatView,
    wrapperWeb,
    Form,
    Popup,
  },
  props: {
    chat_room_id: {
      type: Number,
    },
    user_id: {
      type: Number,
    },
    field_id: {
      type: Number,
    },
    // TODO 配置計画のlayoutAPIでユーザー一覧を取得
    users: {
      type: Array,
    },
  },
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
  },
  methods: {
    back() {
      //web
    },
    goUsers() {
      this.isShowItemForm = true;
    },
    closeItemForm() {
      this.isShowItemForm = false;
    },
    async formUpdate(params) {
      let editedItem = { ...this.editedItem };
      editedItem.name = params.name;
      editedItem.users = params.users;
      this.editedItem = editedItem;
    },
    /**
     * (共通)
     * 新規登録 / 更新
     * - idがある場合は更新
     */
    async submitForm() {
      const { name, users } = this.editedItem;

      // 更新 or 作成
      let params = {
        id: this.chat_room_id,
        name,
        field_id: this.field_id,
        approval_flag: 1,
      };

      // 更新
      const result = await Store.dispatch(`TextChatManage/roomUpdate`, params);

      if (result.hasError) {
        Store.dispatch("Error/show", {
          status: 200,
          message: "保存できませんでした",
        });
        return;
      }

      // ユーザーいる場合
      if (users.length > 0) {
        const user_ids = users.map((user) => {
          return user.user_id;
        });
        const chat_room_id = result.data.contents.entries[0].id;
        params = { chat_room_id, user_ids, approval_flag: 1 };
        const resultUser = await Store.dispatch(
          `TextChatManage/usersUpdate`,
          params
        );

        if (resultUser.hasError) {
          Store.dispatch("Error/show", {
            status: 200,
            message: "ユーザーを保存できませんでした",
          });
          return;
        }
      }

      // 一覧を更新
      this.getItems();
      this.isShowItemForm = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  position: relative;
  height: 100%;
}
</style>
