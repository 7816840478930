<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <SectionLabel label="機材" class="ml-4"></SectionLabel>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="種別">
                        <InputText
                          name="machine_company_type_item_tree_name"
                          :editable="false"
                          :values="formValues"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="機材名">
                        <InputText
                          name="machine_company_name"
                          :editable="false"
                          :values="formValues"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-row>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="その他機材">
                            <InputText
                              name="other_equipment"
                              :editable="false"
                              :values="formValues"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="性能規格">
                            <InputText
                              name="standard_capacity"
                              :editable="false"
                              :values="formValues"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="要望・特記使用">
                        <InputTextArea
                          name="special_specification"
                          :editable="false"
                          :values="formValues"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-row style="margin-top: -2px;">
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="数量">
                            <InputText
                              name="quantity"
                              :editable="false"
                              :values="formValues"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col></v-col>
                  </v-row>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="使用場所">
                        <InputText
                          name="field_item_tree_name"
                          :editable="false"
                          :values="formValues"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-row>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="使用開始日">
                            <InputDatepicker
                              name="use_start_date"
                              :editable="false"
                              :flagNull="true"
                              :values="formValues"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="使用終了日">
                            <InputDatepicker
                              name="use_end_date"
                              :editable="false"
                              :flagNull="true"
                              :values="formValues"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="使用用途・申請理由">
                        <InputTextArea
                          name="application_reason"
                          :editable="false"
                          :values="formValues"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "@/components/forms/elements/Label.vue";
import InputText from "@/components/forms/elements/InputText.vue";
import Select from "@/components/forms/elements/Select.vue";
import SectionLabel from "@/components/forms/elements/SectionLabel.vue";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import InputTextArea from "@/components/forms/elements/InputTextArea";

export default {
  data: () => {
    return {
      formValues: {}
    };
  },
  components: {
    InputTextArea,
    Label,
    InputText,
    Select,
    InputDatepicker,
    SectionLabel
  },
  props: {
    item: {
      type: Object,
      default: {}
    },
    mainHeight: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue.machine_company_field_applications };
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  computed: {
    getBodyStyle() {
      return `height:${this.mainHeight}px;`;
    }
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
</style>
