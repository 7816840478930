import Api, { Mock } from "../api";
import mock_offices from "./mock/offices/offices.json";
import mock_office_detail from "./mock/offices/office_detail.json";
import mock_office_by_company from "./mock/offices/offices_by_company.json";

const COMPANY_BRANCHES_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/company_branches`;
const GET_LIST_URL = `${COMPANY_BRANCHES_BASE_URL}/list`;
const GET_DETAIL_URL = `${COMPANY_BRANCHES_BASE_URL}/detail`;
const GET_BY_COMPANY_ID_URL = `${COMPANY_BRANCHES_BASE_URL}/get_list_company_branch`;
const CREATE_URL = `${COMPANY_BRANCHES_BASE_URL}/create`;
const UPDATE_URL = `${COMPANY_BRANCHES_BASE_URL}/update`;
const DELETE_URL = `${COMPANY_BRANCHES_BASE_URL}/delete`;

// .envのVUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  Mock.onGet(GET_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_offices,
  });
  Mock.onGet(new RegExp(`${GET_DETAIL_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_office_detail,
  });
  Mock.onGet(new RegExp(`${GET_BY_COMPANY_ID_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_office_by_company,
  });
  Mock.onPost(CREATE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(new RegExp(`${UPDATE_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(DELETE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
}

export const offices = {
  get: (params) => {
    return Api.get(GET_LIST_URL, params);
  },
  getDetail: async (params) => {
    return Api.get(`${GET_DETAIL_URL}/${params.company_id}/${params.company_branch_id}`);
  },
  getByCompanyId: async (params) => {
    return Api.get(`${GET_BY_COMPANY_ID_URL}/${params}`);
  },
  post: async (params) => {
    return Api.post(CREATE_URL, params);
  },
  update: async (params) => {
    return Api.post(UPDATE_URL, params);
  },
  delete: async (params) => {
    return Api.post(DELETE_URL, params);
  },
};
