var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DefaultLayout',{scopedSlots:_vm._u([{key:"mainHeader",fn:function(){return [_c('SitePortalHeader',{attrs:{"flagUserRentalCompany":_vm.IS_ROLE_USER_RENTAL_COMPANY}})]},proxy:true},{key:"page",fn:function(ref){
var layoutParams = ref.layoutParams;
return [_c('TableLayout',{attrs:{"layoutParams":layoutParams,"hideFooter":_vm.searchParams.pageCount >= _vm.searchParams.total_item},scopedSlots:_vm._u([{key:"tableHeader",fn:function(ref){
var updateHeader = ref.updateHeader;
return [_c('TableHeader',{ref:"tableHeader",attrs:{"pageTitle":_vm.PAGE_TITLE,"isDelete":false,"isAddForm":_vm.searchParams.table_type === _vm.CARRY_ONS_TABS.TAB1,"isAddNew":false,"disableBtnForm":_vm.disableBtnForm,"updateHeader":updateHeader},on:{"openItemForm":_vm.openPrintForm}},[_c('SearchFormWrapper',[_c('Label',{attrs:{"label":"管理番号"}},[_c('InputText',{attrs:{"name":"control_number","editable":true,"values":_vm.searchParams,"validation_label":"管理番号"},on:{"onInput":_vm.onChangeSearchParams}})],1),_c('Label',{attrs:{"label":"機械名"}},[_c('InputText',{attrs:{"name":"machine_name","editable":true,"values":_vm.searchParams,"validation_label":"機械名"},on:{"onInput":_vm.onChangeSearchParams}})],1),_c('Label',{attrs:{"label":"機番"}},[_c('InputText',{attrs:{"name":"machine_number","editable":true,"values":_vm.searchParams,"validation_label":"機番"},on:{"onInput":_vm.onChangeSearchParams}})],1),(_vm.searchParams.table_type !== _vm.CARRY_ONS_TABS.TAB1)?_c('TabSelect',{staticClass:"mt-8",attrs:{"name":"approval_flag","items":_vm.APPROVE_STATUS,"editable":true,"values":_vm.searchParams},on:{"onInput":_vm.onChangeSearchParams}}):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"mr-6",attrs:{"color":"primary","depressed":""},on:{"click":_vm.onSearch}},[_vm._v(" 検索 ")])],1)],1),_c('TableSortWrapper',[_c('TableSort',{key:_vm.flagSort,attrs:{"values":_vm.searchParams,"sort_items":_vm.sortItems,"sort_item_text":"name","sort_item_value":"id","sort_default":_vm.searchParams.table_type === _vm.CARRY_ONS_TABS.TAB1 ? 0 : 1,"page_counts_options":_vm.PAGE_COUNT_OPTIONS,"sort_order_options":_vm.SORT_ORDERS,"total_item":_vm.searchParams.total_item},on:{"onInput":_vm.onChangeSortParams}})],1),_c('TableTab',{attrs:{"items":_vm.TAB_SELECT},on:{"onChangeTableTab":_vm.onChangeTableTab}})]}},{key:"tableBody",fn:function(ref){
var tableHeight = ref.tableHeight;
return [(_vm.searchParams.table_type === _vm.CARRY_ONS_TABS.TAB1)?_c('Table1',{attrs:{"tableLabels":_vm.TABLE_LABELS_1,"tableHeight":tableHeight,"removeItemTab1":_vm.removeItemTab1,"items":_vm.items,"itemsPerPage":_vm.searchParams.pageCount},on:{"selectedForm":_vm.showBtnCreateForm,"openUpdateDetailForm":_vm.openUpdateDetailForm,"resetSelectedItemsTab1":_vm.resetItemTab1,"resetDataItems":function($event){return _vm.resetItems()}}}):(_vm.searchParams.table_type === _vm.CARRY_ONS_TABS.TAB2 ||
              _vm.searchParams.table_type === _vm.CARRY_ONS_TABS.TAB3 ||
              _vm.searchParams.table_type === _vm.CARRY_ONS_TABS.TAB5)?_c('Table2',{attrs:{"roleUser":_vm.roleUser,"tableLabels":_vm.TABLE_LABELS_2,"tableHeight":tableHeight,"items":_vm.items,"table_type":_vm.searchParams.table_type,"itemsPerPage":_vm.searchParams.pageCount},on:{"resetDataItems":function($event){return _vm.resetItems()},"openUpdateDetailForm":_vm.openUpdateDetailForm}}):(_vm.searchParams.table_type === _vm.CARRY_ONS_TABS.TAB4)?_c('Table4',{attrs:{"roleUser":_vm.roleUser,"tableLabels":_vm.TABLE_LABELS_4,"tableHeight":tableHeight,"items":_vm.items,"itemsPerPage":_vm.searchParams.pageCount},on:{"resetDataItems":function($event){return _vm.resetItems()},"openDetailForm4":_vm.openDetailForm4}}):_vm._e()]}},{key:"tableFooter",fn:function(){return [_c('Pagination',{attrs:{"current":_vm.searchParams.currentPage,"total":_vm.searchParams.totalPage},on:{"pageUpdate":_vm.pageUpdate}})]},proxy:true}],null,true)})]}}])}),_c('Popup',{attrs:{"width":"480px","dialog":_vm.isShowPrintForm}},[_c('PrintForm',{attrs:{"flagError":_vm.flagError},on:{"close":_vm.closePrintForm,"submit":_vm.submitCreateForm}})],1),_c('Popup',{attrs:{"dialog":_vm.isShowItemForm}},[_c('MachineBringForm',{attrs:{"flagDetail":_vm.flagDetail},on:{"cancel":_vm.closeItemForm,"reloadItem":_vm.reloadItem}})],1),_c('Popup',{attrs:{"dialog":_vm.isShowFormDetail}},[_c('InformationMachineForm',{on:{"cancel":_vm.closeItemForm}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }