<template>
  <div class="FormMain mt-3">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
            <v-form ref="form" lazy-validation autocomplete="off">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-row>
                      <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="就労場所" :editable="editable">
                              <InputText
                                name="work_place"
                                :values="formValues"
                                :editable="editable"
                                placeholder=""
                                validation_label="就労場所"
                                validation_rules="max:255"
                                @onInput="onInput"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="従事させる業務の内容" :editable="editable">
                              <InputText
                                name="engagement_content"
                                :values="formValues"
                                :editable="editable"
                                placeholder=""
                                validation_label="従事させる業務の内容"
                                validation_rules="max:255"
                                @onInput="onInput"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                       </v-col>
                    </v-row>
                    <v-row v-if="editable">
                      <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="従事させる期間（開始）" :editable="editable" :required="formValues.engagement_end_date && !formValues.engagement_start_date">
                              <InputDatepicker
                                name="engagement_start_date"
                                :values="formValues"
                                :editable="editable"
                                validation_label="従事させる期間（開始）"
                                :validation_rules="getStartDateRules"
                                :flagNull="true"
                                @onInput="onInput"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                       </v-col>
                      <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="従事させる期間（終了）" :editable="editable" :required="formValues.engagement_start_date && !formValues.engagement_end_date">
                              <InputDatepicker
                                name="engagement_end_date"
                                :values="formValues"
                                :editable="editable"
                                validation_label="従事させる期間（終了）"
                                :validation_rules="getEndDateRules"
                                :flagNull="true"
                                @onInput="onInput"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                       </v-col>
                    </v-row>
                    <v-row v-if="!editable">
                      <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="従事させる期間（計画期間）" :editable="editable">
                                  <v-text-field
                                    v-model="engagementStartEndDate"
                                    dense
                                    color="primary"
                                    :disabled="!editable"
                                    :filled="editable"
                                  ></v-text-field>
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                       </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-row>
                      <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                        <SectionLabel label="責任者（連絡窓口）"/>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="役職" :editable="editable">
                              <InputText
                                name="responsible_person_position_name"
                                :values="formValues"
                                :editable="editable"
                                placeholder=""
                                validation_label="役職"
                                validation_rules="max:255"
                                @onInput="onInput"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="氏名" :editable="editable">
                              <InputText
                                name="responsible_person_name"
                                :values="formValues"
                                :editable="editable"
                                placeholder=""
                                validation_label="氏名"
                                validation_rules="max:255"
                                @onInput="onInput"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="電話番号" :editable="editable">
                              <InputText
                                name="responsible_phone_number"
                                :values="formValues"
                                :editable="editable"
                                placeholder=""
                                validation_label="電話番号"
                                validation_rules="phone|max:16"
                                @onInput="onInput"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="建設特定技能受入計画認定証" :editable="editable">
                          <span class="file"
                             v-if="!editable && formValues['construction_specific_skill_certificate_image_url']"
                             @click="viewSourceWithAuth(formValues['construction_specific_skill_certificate_image_url'])"
                          >
                            {{ formValues['construction_specific_skill_certificate_image'] }}
                          </span>
                          <FileInput
                            :style="getFileInputStyle('construction_specific_skill_certificate_image')"
                            :resetValue="resetValue['construction_specific_skill_certificate_image']"
                            v-if="editable"
                            name="construction_specific_skill_certificate_image"
                            :values="formValues"
                            :editable="editable"
                            accept="image/jpg, image/png, image/jpeg, application/pdf"
                            placeholder=""
                            validation_label="construction_specific_skill_certificate_image"
                            validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf"
                            @onInput="onInput"
                          />
                          <span class="file"
                                v-if="judgeDisplayFileInput('construction_specific_skill_certificate_image')"
                          >{{ formValues['construction_specific_skill_certificate_image'] }}
                            <v-btn small icon text
                                   @click="onRemoveFile({ name:'construction_specific_skill_certificate_image' })">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </span>
                        </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="適正監理計画認定証" :editable="editable">
                          <span class="file"
                             v-if="!editable && formValues['appropriate_supervision_certificate_image_url']"
                             @click="viewSourceWithAuth(formValues['appropriate_supervision_certificate_image_url'])"
                          >
                            {{ formValues['appropriate_supervision_certificate_image'] }}
                          </span>
                          <FileInput
                            :style="getFileInputStyle('appropriate_supervision_certificate_image')"
                            :resetValue="resetValue['appropriate_supervision_certificate_image']"
                            v-if="editable"
                            name="appropriate_supervision_certificate_image"
                            :values="formValues"
                            :editable="editable"
                            accept="image/jpg, image/png, image/jpeg, application/pdf"
                            placeholder=""
                            validation_label="appropriate_supervision_certificate_image"
                            validation_rules="maxsize:10|file-type:jpg, png, jpeg, pdf"
                            @onInput="onInput"
                          />
                          <span class="file"
                                v-if="judgeDisplayFileInput('appropriate_supervision_certificate_image')"
                          >{{ formValues['appropriate_supervision_certificate_image'] }}
                            <v-btn small icon text
                                   @click="onRemoveFile({ name:'appropriate_supervision_certificate_image' })">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </span>
                        </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import InputDatepicker from "../elements/InputDatepicker.vue";
import FileInput from "../elements/FileInput";
import { fmtSlashDate } from "@/utils/timeUtil";
import { viewSourceWithAuth } from "@/utils/viewSourceAuth";

export default {
  data: () => {
    return {
      formValues: {},
      healthInsurance: 0,
      engagementStartEndDate: '',
      fileValid: {},
      resetValue: {},
      viewSourceWithAuth: viewSourceWithAuth,
    };
  },
  components: {
    Label,
    SectionLabel,
    InputText,
    FileInput,
    InputDatepicker,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    errors: {
      type: Object,
      default: {},
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => [
        this.item,
        this.editable,
      ],
      (data) => {
        this.initData(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );
    // check input file error
    this.$watch(
      () => [this.item, this.errors],
      (data) => {
        this.formValues = { ...data[0] };
        const errors = {...data[1]};
        // the validation_label list of the FileInput element
        const names = [
          "construction_specific_skill_certificate_image",
          "appropriate_supervision_certificate_image",
        ];
        const fileValid = { ...this.fileValid};
        const formValues = { ...this.formValues};
        if (errors) {
          for(let name in errors) {
            if (names.some((item) => item === name)){ 
              if(errors[name].length > 0){
                delete formValues[name];
                delete formValues[`${name}_data`];
                fileValid[name] = false;
              } else {
                fileValid[name] = true;
              }
            }
          }
        }

        this.formValues = formValues;
        this.fileValid = fileValid;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      const headerHeight = 48;
      let style = `height:${this.mainHeight - headerHeight}px;`;
      return style;
    },
    getStartDateRules() {
      return this.formValues.engagement_end_date
        ? "required|" +
            `compare-to-end-date:${this.formValues.engagement_end_date.replaceAll(
              "-",
              "/"
            )}`
        : "";
    },
    getEndDateRules() {
      return this.formValues.engagement_start_date
        ? "required|" +
            `compare-to-start-date:${this.formValues.engagement_start_date.replaceAll(
              "-",
              "/"
            )}`
        : "";
    },
  },
  methods: {
    initDataElementEngagementDate(formValues) {
      const startDate = formValues['engagement_start_date'] ? fmtSlashDate(formValues['engagement_start_date']) : '';
      const endDate = formValues['engagement_end_date'] ? fmtSlashDate(formValues['engagement_end_date']) : '';
      if (startDate || endDate) {
        this.engagementStartEndDate = `${startDate.date}〜${endDate.date}`;
      } else {
        this.engagementStartEndDate = '';
      }
    },
    initData(data) {
      const formValues = data[0];
      this.formValues = { ...formValues };
      this.initDataElementEngagementDate(formValues);
    },
    onInput({ name, value, fileName }) {
      const formValues = { ...this.formValues };

      if (fileName) {
        formValues[`${name}_data`] = value;
        formValues[name] = fileName;
        if (!value) {
          delete formValues[`${name}_deleted`];
        }
      } else {
        formValues[name] = value;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", {"company_branch_foreigners": formValues});
      
      this.initDataElementEngagementDate(formValues);
    },
    onRemoveFile({ name }) {
      const formValues = { ...this.formValues };
      delete formValues[name];
      delete formValues[`${name}_data`];
      if(formValues[`${name}_url`]) formValues[`${name}_deleted`] = 1;
      this.formValues = formValues;
      this.resetValue[name] = !this.resetValue[name];
      this.$emit("formUpdate", {"company_branch_foreigners": formValues});
    },
    getFileInputStyle(fieldName) {
      return (this.fileValid[fieldName] && this.formValues[fieldName]) ? 'display:none;' : '';
    },
    judgeDisplayFileInput(fieldName) {
      return this.editable && this.fileValid[fieldName] && this.formValues[fieldName];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";
</style>