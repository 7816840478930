import Vue from "vue";
import Vuex from "vuex";
import { chart } from "@/api/modules/safetyChart";

Vue.use(Vuex);

/**
 * @description  労務安全ツリー 全体のイベントの管理
 *
 * - 選択している工事の状態管理
 * - 元請け/協力会社ポップアップの状態管理
 * - 招待フォームの状態管理
 */

export const SafetyChart = {
  namespaced: true,

  state: {
    //体制図データ
    companies: [],
    selectedCompany: {
      companyId: null,
      companyName: null,
      chartId: null,
      formTab: null,
      companyLevel: null, // @/constants/SAFET_CHART.js #COMPANY_LEVEL
    },
    downloadId: null,
    primeReports: {},
    // ドキュメント
    partnerReports: {},
  },

  mutations: {
    SET_COMPANIES(state, payload) {
      state.companies = payload;
    },
    SET_FIELD_TREE(state, payload) {
      state.field_tree = payload;
    },
    SET_PRIME_RECORDS(state, payload) {
      state.primeReports = payload;
    },
    SET_PARTNER_REPORTS(state, payload) {
      state.partnerReports = payload;
    },
    OPEN_COMPANY_BOX(state, payload) {
      state.selectedCompany = { ...payload };
    },
    SET_DOWNLOAD_ID(state, payload) {
      state.downloadId = payload;
    }
  },

  actions: {
    /**
     * 体制図を取得
     */
    async getCompanies({ commit }, payload) {
      const response = await chart.get(payload);
      if (!response.hasError) {
        const { entries } = response.data.contents;
        const companies = entries?.field_tree;
        if (entries?.field_tree_children) {
          companies["field_tree_children"] = entries?.field_tree_children;
        }
        commit("SET_COMPANIES", companies);
      }
    },

    /**
     * 帳票データを取得
     */
     async getPrimeReports({ commit }, chartId) {
      const response = await chart.getPrimeReports(chartId);
      const documents = response.data?.contents;
      commit("SET_PRIME_RECORDS", documents?.entries);
      return response;
    },

    /**
     * 帳票データを取得
     */
    async getPartnerReports({ commit }, chartId) {
      const response = await chart.getPartnerReports(chartId);
      const documents = response.data?.contents;
      commit("SET_PARTNER_REPORTS", documents?.entries);
      return response;
    },

    /**
     * 会社のボックスを開く
     */
    openCompanyBox({ commit }, payload) {
      commit("OPEN_COMPANY_BOX", payload);
    },

    async createNoticeOfContructionReport(_context, payload) {
      const response = await chart.createNoticeOfContructionReport(payload);
      return response;
    },
    async createNoticeOfCompanyParner(_context, payload) {
      const response = await chart.createNoticeOfCompanyParner(payload);
      return response;
    },
    async createWorkerRosterReport(_context, payload) {
      const response = await chart.createWorkerRosterReport(payload);
      return response;
    },
    async createForeignEntryNotificationReport(_context, payload) {
      const response = await chart.createForeignEntryNotificationReport(payload);
      return response;
    },
    async createReSubcontractNoticeReport(_context, payload) {
      const response = await chart.createReSubcontractNoticeReport(payload);
      return response;
    },
    async createCommitment(_context, payload) {
      const response = await chart.createCommitment(payload);
      return response;
    },
    async createSubcontractReport(_context, payload) {
      const response = await chart.createSubcontractReport(payload);
      return response;
    },
    async createConstructionSystemLedgerReport(_context, payload) {
      const response = await chart.createConstructionSystemLedgerReport(payload);
      return response;
    },
    async createConstructionStructureReport(_context, payload) {
      const response = await chart.createConstructionStructureReport(payload);
      return response;
    },
    async createEntranceQuestionnaireReport(_context, payload) {
      const response = await chart.createEntranceQuestionnaireReport(payload);
      return response;
    },
    async createEntranceEducationDocReport(_context, payload) {
      const response = await chart.createEntranceEducationDocReport(payload);
      return response;
    },
    async createConstructionDocSystemReport(_context, payload) {
      const response = await chart.createConstructionDocSystemReport(payload);
      return response;
    },


    /**
     * 1次
     * 差し戻し
     */
    async updateSatus(_context, payload) {
      const response = await chart.updateSatus(payload);
      return response;
    },

    /**
     * zip作成をリクエスト
     */
    async generateZip(_context, payload) {
      return await chart.generateZip(payload);
    },

    getDownloadId({ commit }, payload) {
      commit("SET_DOWNLOAD_ID", payload);
    },

    async createPartnerDocument(_context, payload) {
      const response = await chart.createPartnerDocument(payload);
      return response;
    },
  },

  getters: {
    //体制図データ
    getCompanies: (state) => {
      return state.companies;
    },
    getPrimeReports: (state) => {
      return state.primeReports;
    },
    // ドキュメント情報を返す
    getPartnerReports: (state) => {
      return state.partnerReports;
    },
    // フォームを開く
    getSelectedCompany: (state) => {
      return state.selectedCompany;
    },
    getDownloadId: (state) => {
      return state.downloadId;
    }
  },
};
