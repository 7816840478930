<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <Label
                        label="一号特定技能外国人の従事の状況"
                        :editable="editable"
                      >
                        <RadioGroup
                          v-if="editable"
                          name="foreigner_worker_specific_skill_status"
                          :values="fieldValues"
                          :items="FOREIGN_WORKER_SPECIFIC_SKILL_STATUS_ITEMS"
                          :editable="editable"
                          @onInput="onInput"
                        />
                        <slot v-if="!editable">
                          {{
                            getRadioName(
                              FOREIGN_WORKER_SPECIFIC_SKILL_STATUS_ITEMS,
                              fieldValues[
                                "foreigner_worker_specific_skill_status"
                              ]
                            )
                          }}</slot
                        >
                      </Label>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <Label
                        label="外国人建設就労者の従事の状況"
                        :editable="editable"
                      >
                        <RadioGroup
                          v-if="editable"
                          name="foreigner_worker_status"
                          :values="fieldValues"
                          :items="FOREIGN_WORKER_STATUS_ITEMS"
                          :editable="editable"
                          @onInput="onInput"
                        />
                        <slot v-if="!editable">
                          {{
                            getRadioName(
                              FOREIGN_WORKER_STATUS_ITEMS,
                              fieldValues["foreigner_worker_status"]
                            )
                          }}</slot
                        >
                      </Label>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <Label
                        label="外国人技能実習生の従事の状況"
                        :editable="editable"
                      >
                        <RadioGroup
                          v-if="editable"
                          name="foreigner_worker_intern_status"
                          :values="fieldValues"
                          :items="FOREIGN_WORKER_INTERN_STATUS_ITEMS"
                          :editable="editable"
                          @onInput="onInput"
                        />
                        <slot v-if="!editable">
                          {{
                            getRadioName(
                              FOREIGN_WORKER_INTERN_STATUS_ITEMS,
                              fieldValues["foreigner_worker_intern_status"]
                            )
                          }}</slot
                        >
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "@/components/forms/elements/Label";
import InputText from "@/components/forms/elements/InputText";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import Select from "@/components/forms/elements/Select.vue";
import RadioGroup from "@/components/forms/elements/RadioGroup";
import {
  FOREIGN_WORKER_STATUS_ITEMS,
  FOREIGN_WORKER_SPECIFIC_SKILL_STATUS_ITEMS,
  FOREIGN_WORKER_INTERN_STATUS_ITEMS,
} from "@/constants/SITES";

export default {
  data: () => {
    return {
      fieldValues: {},
      formValues: {},
      FOREIGN_WORKER_STATUS_ITEMS,
      FOREIGN_WORKER_SPECIFIC_SKILL_STATUS_ITEMS,
      FOREIGN_WORKER_INTERN_STATUS_ITEMS,
    };
  },

  components: {
    RadioGroup,
    Label,
    InputText,
    InputDatepicker,
    Select,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (data) => {
        this.initData(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    /**
     * (共通)
     * 定数をapi/ストアからまとめて取得します
     *
     * this.CONSTANTS.roll
     * こうやって個々の定数を取得します
     */
    CONSTANTS() {
      return Store.getters["Constants/get"];
    },
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    initData(data) {
      // get form value by this.item
      const formValues = data;
      // get fieldValues
      this.fieldValues = formValues.fields;
      this.formValues = { ...formValues };
    },
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues["fields"][name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    getRadioName(items, value) {
      return items.find((item) => item.value == value)?.name;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
</style>
