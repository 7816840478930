var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"FormMain"},[_c('div',{staticClass:"FormMainBody",style:(_vm.getBodyStyle)},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-form',{attrs:{"autocomplete":"off"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-list-item',[_c('v-list-item-content',[_c('Label',{attrs:{"label":"工事内容","editable":_vm.editable}},[_c('InputText',{attrs:{"name":"construction_details","values":_vm.fieldTreeContracts,"editable":_vm.editable,"placeholder":"","validation_label":"工事内容","validation_rules":"max:255"},on:{"onInput":function($event){return _vm.onInput(
                            _vm.CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                            $event
                          )}}})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('WorkContents',{attrs:{"name":"field_tree_main_work_contents","values":_vm.formValues,"editable":_vm.editable},on:{"onInput":function($event){return _vm.onInput(
                          _vm.CONTRACTS_EVENT_TARGET.FIELD_TREE_MAIN_WORK_CONTENTS,
                          $event
                        )}}})],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('SafetyInstructions',{attrs:{"name":"field_tree_safety_instructions","values":_vm.formValues,"editable":_vm.editable},on:{"onInput":function($event){return _vm.onInput(
                          _vm.CONTRACTS_EVENT_TARGET.FIELD_TREE_SAFETY_INSTRUCTIONS,
                          $event
                        )}}})],1)],1),_c('v-list-item',[_c('v-list-item-content',[(_vm.editable)?_c('v-row',[_c('v-col',[_c('Label',{attrs:{"label":"工期（着工）","editable":_vm.editable,"required":_vm.isRequireStartDate}},[_c('InputDatepicker',{attrs:{"name":"groundbreaking_date","values":_vm.fieldTreeContracts,"editable":_vm.editable,"flagNull":true,"validation_label":"工期（着工）","id":"groundbreaking_date","validation_rules":_vm.getStartDateRules},on:{"onInput":function($event){return _vm.onInput(
                                _vm.CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                                $event
                              )}}})],1)],1),_c('v-col',[_c('Label',{attrs:{"label":"工期（竣工）","editable":_vm.editable,"required":_vm.isRequireEndDate}},[_c('InputDatepicker',{attrs:{"name":"complete_schedule_date","values":_vm.fieldTreeContracts,"editable":_vm.editable,"flagNull":true,"placeholder":"2023/01/08","validation_label":"工期（竣工）","id":"complete_schedule_date","validation_rules":_vm.getEndDateRules},on:{"onInput":function($event){return _vm.onInput(
                                _vm.CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                                $event
                              )}}})],1)],1)],1):_vm._e(),(!_vm.editable)?_c('v-row',[_c('v-col',[_c('Label',{attrs:{"label":"工期","editable":_vm.editable}},[_c('InputText',{attrs:{"name":"construction_period","values":_vm.constructionPeriod}})],1)],1)],1):_vm._e()],1)],1),_c('v-list-item',[_c('v-list-item-content',[(_vm.editable)?_c('v-row',[_c('v-col',[_c('Label',{attrs:{"label":"契約日","editable":_vm.editable}},[_c('InputDatepicker',{attrs:{"name":"contract_date","values":_vm.fieldTreeContracts,"editable":_vm.editable,"flagNull":true,"placeholder":"2020/05/19","validation_label":"契約日","validation_rules":"","max":_vm.max},on:{"onInput":function($event){return _vm.onInput(
                                _vm.CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                                $event
                              )}}})],1)],1)],1):_vm._e(),(!_vm.editable)?_c('v-row',[_c('v-col',[_c('Label',{attrs:{"label":"契約日","editable":_vm.editable}},[_c('InputText',{attrs:{"name":"contract_date","values":_vm.fieldTreeContracts,"editable":_vm.editable,"placeholder":"yyyy/mm/dd〜yyyy/mm/dd","validation_label":"工事内容","validation_rules":""},on:{"onInput":function($event){return _vm.onInput(
                                _vm.CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                                $event
                              )}}})],1)],1),_c('v-col')],1):_vm._e()],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('Label',{attrs:{"label":"特定専門工事の有無","editable":_vm.editable}},[(_vm.editable)?_c('RadioGroup',{attrs:{"name":"specific_specialty_status","values":_vm.fieldTreeContracts,"items":_vm.INSURANCE_STATUS_2,"editable":_vm.editable},on:{"onInput":function($event){return _vm.onInput(_vm.CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS, $event)}}}):_vm._e()],1),(!_vm.editable)?_c('span',[_vm._v(_vm._s(_vm.getRadioName( _vm.INSURANCE_STATUS_2, _vm.fieldTreeContracts["specific_specialty_status"] )))]):_vm._e()],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('SectionLabel',{attrs:{"label":"監督員名・権限及び意見申出方法"}}),_c('v-row',[_c('v-col',[_c('Label',{attrs:{"label":"監督員名","editable":_vm.editable}},[_c('InputText',{attrs:{"name":"supervisor_name","values":_vm.fieldTreeContracts,"editable":_vm.editable,"placeholder":"上田 正","validation_label":"監督員名","validation_rules":"max:255"},on:{"onInput":function($event){return _vm.onInput(
                                _vm.CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                                $event
                              )}}})],1)],1)],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-row',[_c('v-col',[_c('Label',{attrs:{"label":"権限及び意見申出方法","editable":_vm.editable}},[_c('InputText',{attrs:{"name":"supervisor_authority_and_request_method","values":_vm.fieldTreeContracts,"editable":_vm.editable,"placeholder":"下請負契約第️○️条記載のとおり","validation_label":"権限及び意見申出方法","validation_rules":"max:255"},on:{"onInput":function($event){return _vm.onInput(
                                _vm.CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                                $event
                              )}}})],1)],1)],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('SectionLabel',{attrs:{"label":"現場代理人名・権限及び意見申し出方法"}}),_c('v-row',[_c('v-col',[_c('Label',{attrs:{"label":"現場代理人名","editable":_vm.editable}},[_c('InputText',{attrs:{"name":"site_agent_name","values":_vm.fieldTreeContracts,"editable":_vm.editable,"placeholder":"夏川 二郎","validation_label":"現場代理人名","validation_rules":"max:255"},on:{"onInput":function($event){return _vm.onInput(
                                _vm.CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                                $event
                              )}}})],1)],1)],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-row',[_c('v-col',[_c('Label',{attrs:{"label":"権限及び意見申出方法","editable":_vm.editable}},[_c('InputText',{attrs:{"name":"site_agent_authority_and_request_method","values":_vm.fieldTreeContracts,"editable":_vm.editable,"placeholder":"下請負契約第️○️条記載のとおり","validation_label":"権限及び意見申出方法","validation_rules":"max:255"},on:{"onInput":function($event){return _vm.onInput(
                                _vm.CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                                $event
                              )}}})],1)],1)],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('SectionLabel',{attrs:{"label":"安全衛生管理"}}),_c('v-row',[_c('v-col',[_c('Label',{attrs:{"label":"安全衛生責任者名","editable":_vm.editable}},[_c('InputText',{attrs:{"name":"health_safety_manager_name","values":_vm.fieldTreeContracts,"editable":_vm.editable,"placeholder":"夏川 二郎","validation_label":"安全衛生責任者名","validation_rules":"max:255"},on:{"onInput":function($event){return _vm.onInput(
                                _vm.CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                                $event
                              )}}})],1)],1)],1)],1)],1),(_vm.ENV_OBAYASHI)?_c('v-list-item',[_c('v-list-item-content',[_c('v-row',[_c('v-col',[_c('Label',{attrs:{"label":"職長","editable":_vm.editable,"required":_vm.level === 2 && _vm.editable}},[_c('Select',{attrs:{"name":"foreman_id","values":_vm.fieldTreeContracts,"items":_vm.getForemanItems(),"editable":_vm.editable,"validation_label":"職長","validation_rules":_vm.level == 2 && _vm.editable ? 'required' : ''},on:{"onInput":function($event){return _vm.onInput(
                                _vm.CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                                $event
                              )}}})],1)],1)],1)],1)],1):_vm._e(),_c('v-list-item',[_c('v-list-item-content',[_c('v-row',[_c('v-col',[_c('Label',{attrs:{"label":"安全衛生推進者名","editable":_vm.editable}},[_c('InputText',{attrs:{"name":"health_safety_promoter_name","values":_vm.fieldTreeContracts,"editable":_vm.editable,"placeholder":"夏川 二郎","validation_label":"安全衛生推進者名","validation_rules":"max:255"},on:{"onInput":function($event){return _vm.onInput(
                                _vm.CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                                $event
                              )}}})],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-list-item',[_c('v-list-item-content',[_c('SectionLabel',{attrs:{"label":"主任技術者","editable":_vm.editable}}),_c('Label',{attrs:{"label":"主任技術者名","editable":_vm.editable}},[_c('InputText',{attrs:{"name":"chief_engineer_name","values":_vm.fieldTreeContracts,"editable":_vm.editable,"placeholder":"夏川 二郎","validation_label":"主任技術者名","validation_rules":"max:255"},on:{"onInput":function($event){return _vm.onInput(
                            _vm.CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                            $event
                          )}}})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('Label',{attrs:{"label":"専任の有無","editable":_vm.editable}},[(_vm.editable)?_c('RadioGroup',{attrs:{"name":"chief_engineer_fulltime_flag","values":_vm.fieldTreeContracts,"items":_vm.CHIEF_ENGINEER_FULLTIME_FLAG,"editable":_vm.editable,"validation_label":"専任の有無","validation_rules":""},on:{"onInput":function($event){return _vm.onInput(
                            _vm.CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                            $event
                          )}}}):_vm._e(),(!_vm.editable)?_c('span',[_vm._v(_vm._s(_vm.getRadioName( _vm.CHIEF_ENGINEER_FULLTIME_FLAG, _vm.fieldTreeContracts.chief_engineer_fulltime_flag )))]):_vm._e()],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('Label',{attrs:{"label":"資格","editable":_vm.editable}}),_c('Label',{attrs:{"label":"試験等","editable":_vm.editable}},[_c('Select',{attrs:{"name":"exam_id","values":_vm.fieldTreeContracts,"items":_vm.getcatergoryItems(),"editable":_vm.editable,"validation_label":"","validation_rules":""},on:{"onInput":function($event){return _vm.onInput(
                            _vm.CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                            $event
                          )}}})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('Label',{attrs:{"label":"資格区分","editable":_vm.editable}},[_c('Select',{attrs:{"name":"chief_engineer_exam_id","values":_vm.fieldTreeContracts,"items":_vm.getSubCatergoryItems('exam_id'),"item_text":"qualification","editable":_vm.editable,"validation_label":"","validation_rules":""},on:{"onInput":function($event){return _vm.onInput(
                            _vm.CONTRACTS_EVENT_TARGET.FIELD_TREE_CONTRACTS,
                            $event
                          )}}})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('FormQualifications',{attrs:{"name":"field_tree_technicians","values":_vm.formValues,"field_tree_contract_id":_vm.field_tree_contract_id,"editable":_vm.editable},on:{"onInput":function($event){return _vm.onInput(
                          _vm.CONTRACTS_EVENT_TARGET.FIELD_TREE_TECHNICIANS,
                          $event
                        )}}})],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('FormRegisteredCoreTech',{attrs:{"name":"field_tree_core_technicians","values":_vm.formValues,"field_tree_contract_id":_vm.field_tree_contract_id,"editable":_vm.editable,"errors":_vm.errors},on:{"onInput":function($event){return _vm.onInput(
                          _vm.CONTRACTS_EVENT_TARGET.FIELD_TREE_CORE_TECHNICIANS,
                          $event
                        )}}})],1)],1)],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }