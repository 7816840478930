import Api, { Mock } from "../api";
import mock_company_machine_inspection_list from './mock/mock_company_machine_inspection_list.json';
import mock_inspection_result from './mock/mock_inspection_result.json';
import mock_inspection_result_detail from './mock/mock_inspection_result_detail.json';

const PATH = "machine_field_inspection_items";
const PATH_INSPECTION_LIST = "machine_company_inspection_lists";
const URL = `${process.env.VUE_APP_API_BASE_URL}/${PATH}`;
const URL_INSPECTION_LIST = `${process.env.VUE_APP_API_BASE_URL}/${PATH_INSPECTION_LIST}`;
const GET_MACHINE_COMPANY_INSPEC_LIST_URL = `${URL_INSPECTION_LIST}/get_list`;
const GET_INSPECTION_RESULTS_URL = `${URL}/get_results`;
const GET_INSPECTION_RESULTS_DETAIL_URL = `${URL}/get_item_results`;
const UPDATE_APPROVAL_STATUS_URL = `${URL}/update_status`;


// .envのVUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  Mock.onGet(new RegExp(`${GET_MACHINE_COMPANY_INSPEC_LIST_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_company_machine_inspection_list,
  });
  Mock.onGet(GET_INSPECTION_RESULTS_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_inspection_result,
  });
  Mock.onPost(UPDATE_APPROVAL_STATUS_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(new RegExp(`${GET_INSPECTION_RESULTS_DETAIL_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_inspection_result_detail,
  });
}

export const machineFieldInspection = {
  getMachineCompanyInspecList: (id) => {
    return Api.get(`${GET_MACHINE_COMPANY_INSPEC_LIST_URL}/${id}`);
  },
  getInspectionResults: (params) => {
    return Api.get(GET_INSPECTION_RESULTS_URL, { params });
  },
  updateApprovalStatus: async (params) => {
    return Api.post(UPDATE_APPROVAL_STATUS_URL, params);
  },
  getInspecResultDetail: async (params) => {
    return Api.get(`${GET_INSPECTION_RESULTS_DETAIL_URL}/${params}`);
  },
};
