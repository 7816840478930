<template>
  <div>
    <div
      v-for="(element, index) in listTime"
      :key="`time_${item.schedule_id}_${item.schedule_type}_${index}`"
      class="schedulePlan"
      :class="getClass(item.schedule_type)"
      :style="getStyle(element.start_time, element.end_time, index)"
    >
      <div class="text_timebar" v-if="index == 0">
        {{ TIMEBAR_VIEW_FIELDS }}
      </div>
      <div
        class="start"
        :class="isTime0(element.start_time) ? 'time0' : ''"
        :style="isTime0Minute15(element.start_time) && !IS_OBAYASHI ? 'left: -14px;' : ''"
        v-if="isFirstDisplay(index, element.start_time)"
      >
        {{ formatDate(element.start_time) }}
      </div>
      <div class="end" v-if="isFirstDisplay(index, element.end_time)">
        {{ formatDate(element.end_time) }}
      </div>
    </div>
  </div>
</template>
<script>
import { differenceInMinutes, format } from "date-fns";
import { SCHEDULE_TYPE } from "@/constants/SCHEDULE_WORKS";
import moment from "moment";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
export default {
  data: () => {
    return {
      listTime: [],
    };
  },
  props: {
    isUsage: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
    index: {
      type: Number,
    },
    //1時間の分割数
    hourDivide: {
      type: Number,
    },
    //cellRect.widthが1時間の幅ピクセル
    cellRect: {
      type: Object,
    },
    //今日の日付
    today: {
      type: String,
    },
    //今日の開始ピクセル
    todayStartX: {
      type: Number,
    },
    //上部のマージン
    marginTop: {
      type: Number,
    },
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        let { device_usage_times } = newValue;
        device_usage_times = device_usage_times.filter(
          (time) =>
            !(
              new Date(time.start_time) >= this.AFTER_TIME &&
              new Date(time.end_time) > this.AFTER_TIME
            )
        );
        this.listTime = [...device_usage_times];
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    SCHEDULE_TYPE_ARRAY() {
      return Object.keys(SCHEDULE_TYPE).map((key) => {
        return { ...SCHEDULE_TYPE[key], key };
      });
    },
    BEFORE_TIME() {
      return new Date(this.today);
    },
    AFTER_TIME() {
      return moment(this.today).add(1, "days").add(6, "hours").toDate();
    },
    TIMEBAR_VIEW_FIELDS () {
      return this.item.timebar_view_fields || "";
    },
    IS_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
  },
  methods: {
    getClass(type_id) {
      const type = this.SCHEDULE_TYPE_ARRAY.find((item) => {
        return item.id === type_id;
      });
      return `type_${type.key}`;
    },
    formatDate(time) {
      return time ? format(new Date(time), "HH:mm") : "--:--";
    },

    getStyle(startTime, endTime, index) {
      const twentyOne = 21;
      const six = 6;
      const zero = 0;
      const today = this.BEFORE_TIME;
      const start =
        new Date(startTime) < this.BEFORE_TIME
          ? this.BEFORE_TIME
          : new Date(startTime);
      let end =
        new Date(endTime) > this.AFTER_TIME
          ? this.AFTER_TIME
          : new Date(endTime);

      if(this.IS_OBAYASHI && start.getHours() < six) {
        start.setHours(six);
        start.setMinutes(zero);
      }

      if(this.IS_OBAYASHI && end.getHours() < six) {
        end.setHours(six);
        end.setMinutes(zero);
      }

      if(this.IS_OBAYASHI && start.getHours() >= twentyOne) {
        start.setHours(twentyOne);
        start.setMinutes(zero);
      }

      if(this.IS_OBAYASHI && end.getHours() >= twentyOne) {
        end.setHours(twentyOne);
        end.setMinutes(zero);
      }

      if(this.IS_OBAYASHI && format(new Date(start), "yyyy/MM/dd") < format(new Date(end), "yyyy/MM/dd")) {
        end = new Date(format(new Date(start), "yyyy/MM/dd"));
        end.setHours(twentyOne);
        end.setMinutes(zero);
      }

      const rect = {
        x: null,
        width: null,
      };

      // 継続時間
      const diffMinContinuous = differenceInMinutes(end, start);
      rect.width =
        (diffMinContinuous * (this.cellRect.width * this.hourDivide)) / 60;
      // 開始位置
      const timeDiff = this.IS_OBAYASHI && this.isUsage ? today.setHours(today.getHours() + 6) : today;
      const diffMinStart = differenceInMinutes(start, timeDiff);
      rect.x =
        this.todayStartX +
        diffMinStart * ((this.cellRect.width * this.hourDivide) / 60);

      rect.top = this.index * this.cellRect.height + this.marginTop + 6;
      rect.height = this.cellRect.height - 7;

      let style = `width:${rect?.width}px;height:${rect?.height}px;left:${rect?.x}px;top:${rect?.top}px;`;
      if (!this.isFirstDisplay(index, startTime)) style = `${style}z-index: 1;`
      return style;
    },
    isFirstDisplay(index, time) {
      for (let i = 0; i < index; i++) {
        let item = this.listTime[i];
        if (time == item.start_time || time == item.end_time) {
          return false;
        }
      }
      return true;
    },
    // 0:00の時に表示位置を変更する
    isTime0(startTime) {
      return new Date(startTime) <= this.BEFORE_TIME;
    },
    isTime0Minute15(startTime) {
      if (String(startTime).length > 0) {
        let arrDate = startTime.split(' ');
        let arrTime = arrDate[1].split(':');
        if (arrTime[0] === '00' && Number(arrTime[1]) === 15) return true;
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.schedulePlan {
  position: absolute;
  z-index: 7;
  border-radius: 4px;
  &:before {
    content: "";
    z-index: 0;
    position: absolute;
    width: 100%;
    height: calc(100% - 8px);
    top: 8px;
    border-radius: 2px;
  }
  &.type_OCCUPY {
    &:before {
      background-color: $color_schedule_type_occupy;
    }
  }
  &.type_SPOT {
    &:before {
      background-color: $color_schedule_type_spot;
    }
  }
  &.type_POSESSION {
    &:before {
      background-color: $color_schedule_type_possession;
    }
  }
  &.type_DISABLE {
    &:before {
      background-color: $color_schedule_type_disable;
    }
  }
  &.type_OVERLAP {
    &:before {
      background-color: $color_schedule_type_overlap;
    }
  }
}

.start {
  font-size: 12px;
  font-weight: bold;
  color: black;
  position: absolute;
  top: 11px;
  left: -33px;
  // transform: scale(0.8);
  &.time0 {
    //left: 0;
  }
}
.end {
  font-size: 12px;
  font-weight: bold;
  color: black;
  position: absolute;
  top: 11px;
  right: -33px;
  // transform: scale(0.8);
}

.text_timebar {
  font-size: 12px;
  font-weight: bold;
  color: black;
  position: absolute;
  top: -7px;
  left: -3px;
  white-space: pre;
  // transform: scale(0.8);
}
</style>
