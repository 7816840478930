import Api, { Mock } from "../api";
import schedule_works from "./mock/schedule_works.json";
import schedule_machines from "./mock/schedule_machines.json";
import schedule_partner_companies from "./mock/schedule_partner_companies.json";
import schedule_locations from "./mock/schedule_locations.json";
import schedule_timeframes from "./mock/schedule_timeframes.json";
import schedule_children from "./mock/schedule_children.json";
import schedule_machines_children from "./mock/schedule_machines_children.json";
import work_schedule_information from "./mock/work_schedule_information.json";
import get_work_place from "./mock/work_place.json";
import work_last_time from "./mock/work_last_time.json";
import { ENV_CLIENT } from "../../constants/ENV_CLIENT";
/**
 * 共通
 */
const SCHEDULE_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/works`; // 予定
const GET_PARTNER_COMPANIES = `${SCHEDULE_BASE_URL}/partner_companies`; //協力会社一覧検索
const GET_LOCATIONS = `${SCHEDULE_BASE_URL}/scheudle_locations`; // 作業場所一覧
const GET_TIMEFRAMES = `${process.env.VUE_APP_API_BASE_URL}/fields/list_time_zones`; // 時間帯一覧

/**
 * 予定 works
 */
const SCHEDULE_WORK_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/work_plans`;
const GET_LIST_URL = `${SCHEDULE_WORK_BASE_URL}/list`; // 予定一覧取得
const COPY_WORK_PLANS = `${SCHEDULE_WORK_BASE_URL}/copies`;
const GET_CHILDREN_SCHEDULE_URL = `${SCHEDULE_WORK_BASE_URL}/list_work_by_group`; // 子供の予定一覧を取得
const POST_WORKS_DELETE_URL = `${SCHEDULE_WORK_BASE_URL}/delete`; // 解除
const POST_WORKS_UPDATE_APPROVE_URL = `${SCHEDULE_WORK_BASE_URL}/update_approval`; // 承認/承認解除
/**
 * 予定 result
 */
 const SCHEDULE_RESULT_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/work_results`;
/**
 * 機材予定
 */
const GET_MACHINES_LIST_URL = `${SCHEDULE_BASE_URL}/machines`; // 機材予定一覧取得
const GET_MACHINES_CHILDREN_SCHEDULE = `${SCHEDULE_BASE_URL}/machines_children`; // 子供の予定一覧を取得
const POST_MACHINES_APPROVE = `${SCHEDULE_BASE_URL}/machines/approve`; //承認
const POST_MACHINES_REJECT = `${SCHEDULE_BASE_URL}/machines/reject`; //承認解除

const EDIT_URL = `${SCHEDULE_BASE_URL}/update`;
const CREATE_URL = `${SCHEDULE_BASE_URL}/create`;
const DELETE_URL = `${SCHEDULE_BASE_URL}/delete`;
const GET_DETAIL_URL = `${SCHEDULE_WORK_BASE_URL}/detail`;
const GET_WORK_PLACE = `${SCHEDULE_WORK_BASE_URL}/get_work_place`;

const CREATE_WORK = `${SCHEDULE_WORK_BASE_URL}/create`;
const UPDATE_WORK_INFOR = `${SCHEDULE_WORK_BASE_URL}/update`;
const GET_WORK_LAST_TIME = `${SCHEDULE_WORK_BASE_URL}/get_work_last_time`;
const GET_WORK_DETAIL_COMPANY_PLAN = `${SCHEDULE_WORK_BASE_URL}/detail_work_plans`;
const UPDATE_STATE_HANDLING = `${SCHEDULE_WORK_BASE_URL}/update_state_handling`;
const GET_DAY_WORKER_INFO = `${SCHEDULE_WORK_BASE_URL}/dayworker`;
const GET_DATA_HISTORY_TEXT = `${SCHEDULE_WORK_BASE_URL}/list_history`;
const GET_LIST_FIELD_TREE_USER_HISTORY_URL = `${SCHEDULE_WORK_BASE_URL}/list_field_tree_user_history`;
const GET_LIST_MANAGER_HISTORY_URL = `${SCHEDULE_WORK_BASE_URL}/list_manager_history`;
const GET_LIST_STATUS_APPROVAL = `${SCHEDULE_WORK_BASE_URL}/list_status_approval`;
// CHECK IS OBAYASHI
const IS_OBAYASHI = process.env.VUE_APP_CLIENT === ENV_CLIENT.OBAYASHI;
// qualifications (OBAYASHI)
const GET_LIST_QUALIFICATION_URL = `${process.env.VUE_APP_API_BASE_URL}/qualifications/list`;
// history qualifications work plans (OBAYASHI)
const GET_LIST_HISTORY_QUALIFICATION_WORK_PLANS_URL = `${SCHEDULE_WORK_BASE_URL}/qualification_history`
// history qualifications work result (OBAYASHI)
const GET_LIST_HISTORY_QUALIFICATION_WORK_RESULTS_URL = `${SCHEDULE_RESULT_BASE_URL}/qualification_history`
// .envのVUE_APP_NODE_ENV = local の場合はモックを返す

// SEARCH HISTORY WORK PLAN
const GET_LIST_WORK_PLAN_SEARCH_HISTORY = `${SCHEDULE_WORK_BASE_URL}/search_history`;
const GET_LIST_WORK_PLAN_SEARCH_HISTORY_DELETE = `${GET_LIST_WORK_PLAN_SEARCH_HISTORY}/delete`;
// SEARCH HISTORY WORK RESULT
const GET_LIST_WORK_RESULT_SEARCH_HISTORY = `${SCHEDULE_RESULT_BASE_URL}/search_history`;
const GET_LIST_WORK_RESULT_SEARCH_HISTORY_DELETE = `${GET_LIST_WORK_RESULT_SEARCH_HISTORY}/delete`;
if (Mock) {
  Mock.onGet(GET_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: schedule_works,
  });

  Mock.onGet(GET_MACHINES_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: schedule_machines,
  });

  Mock.onGet(GET_PARTNER_COMPANIES).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: schedule_partner_companies,
  });

  Mock.onGet(GET_LOCATIONS).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: schedule_locations,
  });

  Mock.onGet(new RegExp(`${GET_TIMEFRAMES}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: schedule_timeframes,
  });
  Mock.onGet(GET_CHILDREN_SCHEDULE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: schedule_children,
  });

  Mock.onGet(GET_MACHINES_CHILDREN_SCHEDULE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: schedule_machines_children,
  });

  Mock.onPost(POST_MACHINES_APPROVE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });

  Mock.onPost(POST_MACHINES_REJECT).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });

  Mock.onPost(EDIT_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(CREATE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(DELETE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(new RegExp(`${GET_DETAIL_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: work_schedule_information,
  });
  Mock.onGet(GET_WORK_PLACE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: get_work_place,
  });
  Mock.onPost(CREATE_WORK).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(UPDATE_WORK_INFOR).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(new RegExp(`${GET_WORK_LAST_TIME}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: work_last_time,
  });
  Mock.onPost(POST_WORKS_DELETE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });

  Mock.onPost(POST_WORKS_UPDATE_APPROVE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });

  Mock.onGet(GET_DAY_WORKER_INFO).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: {
        work_date : "2021/10/19",
        field_construction_id : 1,
        schedule : {
          total_ukeoi : "30",
          total_tsunei : "40",
          total : "70",
          data : [
            {
              name : "Group A",
              ukeoi : "15",
              tsunei : "20"
            },
            {
              name : "Group B",
              ukeoi : "18",
              tsunei : "25"
            }
          ]
        }
      },
    },
  });



  Mock.onGet(new RegExp(`${GET_WORK_DETAIL_COMPANY_PLAN}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: {
        work_plans: {
          work_plan_id: 1,
          field_construction_id: 1,
          field_construction_name: "Contruction 1",
          work_date: "2021/10/19",
          field_tree_name: "Field tree 1",
          field_tree_id: 1,
          field_tree_group_id: 1,
          field_tree_group_name: "Group 1",
          work_hour_zone: 1,
          work_hour_zone_name: "Ca 1",
          work_hour_zone_start_time: "08:00",
          work_hour_zone_end_time: "13:00",
        },
      },
    },
  });
  Mock.onGet(GET_DATA_HISTORY_TEXT).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        "content1",
        "content2"
      ]
    },
  });
}

export const schedule = {
  /**
   * 共通
   */
  getPartnerCompanies: (params) => {
    return Api.get(GET_PARTNER_COMPANIES, params);
  },
  getLocations: (params) => {
    return Api.get(GET_LOCATIONS, params);
  },
  getTimeframes: (params) => {
    return Api.get(`${GET_TIMEFRAMES}/${params}`);
  },

  /**
   * 予定 works
   */
  getListWorks: (params) => {
    return Api.get(GET_LIST_URL, { params });
  },
  copyWorkPlans: (params) => {
    return Api.post(COPY_WORK_PLANS, params);
  },
  getScheduleChildren: (params) => {
    return Api.get(GET_CHILDREN_SCHEDULE_URL, { params });
  },
  updateApproveWorks: (params) => {
    return Api.post(POST_WORKS_UPDATE_APPROVE_URL, params);
  },
  deleteWorks: async (params) => {
    return Api.post(POST_WORKS_DELETE_URL, params);
  },

  /**
   * 機材予定
   */
  getMachines: (params) => {
    return Api.get(GET_MACHINES_LIST_URL, params);
  },
  getScheduleMachinesChildren: (params) => {
    return Api.get(GET_MACHINES_CHILDREN_SCHEDULE, params);
  },
  postScheduleMachinesApprove: (params) => {
    return Api.post(POST_MACHINES_APPROVE, params);
  },
  postScheduleMachinesReject: (params) => {
    return Api.post(POST_MACHINES_REJECT, params);
  },
  add: (params) => {
    return Api.post(CREATE_URL, params);
  },
  edit: async (params) => {
    return Api.post(EDIT_URL, params);
  },
  delete: async (params) => {
    return Api.post(DELETE_URL, params);
  },
  getDetail: async (params) => {
    return Api.get(GET_DETAIL_URL, params);
  },
  getDetailObayashi: (params) => {
    return Api.get(GET_DETAIL_URL, params);
  },
  getWorkPlace: (params) => {
    return Api.get(GET_WORK_PLACE, params);
  },
  post: async (params) => {
    return Api.post(CREATE_WORK, params);
  },
  update: async (params) => {
    return Api.post(UPDATE_WORK_INFOR, params);
  },
  getWorkLastTime: async (params) => {
    return Api.get(GET_WORK_LAST_TIME, params);
  },
  getWorkDetailCompanyPlan: async (params) => {
    return Api.get(`${GET_WORK_DETAIL_COMPANY_PLAN}/${params}`);
  },
  updateStateHandling: async (params) => {
    return Api.post(UPDATE_STATE_HANDLING, params);
  },
  getDayWorkerInfo: async (params) => {
    return Api.get(GET_DAY_WORKER_INFO, { params });
  },
  getDataHistory: async (params) => {
    params.params.is_oba = IS_OBAYASHI ? 1 : null;
    return Api.get(GET_DATA_HISTORY_TEXT, params);
  },
  getListFieldTreeUserHistory: async (params) => {
    return Api.get(GET_LIST_FIELD_TREE_USER_HISTORY_URL, params);
  },
  getListManagerHistory: async (params) => {
    return Api.get(GET_LIST_MANAGER_HISTORY_URL, params);
  },
  getListStatusApproval: (params) => {
    return Api.get(`${GET_LIST_STATUS_APPROVAL}/${params}`);
  },

  // SEARCH HISTORY
  // work
  getListSearchHistoryWorkPlan: async (params) => {
    return Api.get(GET_LIST_WORK_PLAN_SEARCH_HISTORY, {params});
  },
  createSearchHistoryWorkPlan: async (params) => {
    return Api.post(GET_LIST_WORK_PLAN_SEARCH_HISTORY, params);
  },
  deleteSearchHistoryWorkPlan: async (params) => {
    return Api.post(GET_LIST_WORK_PLAN_SEARCH_HISTORY_DELETE, params);
  },
  //record
  getListSearchHistoryWorkResult: async (params) => {
    return Api.get(GET_LIST_WORK_RESULT_SEARCH_HISTORY, {params});
  },
  createSearchHistoryWorkResult: async (params) => {
    return Api.post(GET_LIST_WORK_RESULT_SEARCH_HISTORY, params);
  },
  deleteSearchHistoryWorkResult: async (params) => {
    return Api.post(GET_LIST_WORK_RESULT_SEARCH_HISTORY_DELETE, params);
  },
  getListQualification: (params) => {
    return Api.get(GET_LIST_QUALIFICATION_URL, {params});
  },
  getListHistoryQualificationWorkPlans: (params) => {
    return Api.get(GET_LIST_HISTORY_QUALIFICATION_WORK_PLANS_URL, {params});
  },
  getListHistoryQualificationWorkResults: (params) => {
    return Api.get(GET_LIST_HISTORY_QUALIFICATION_WORK_RESULTS_URL, {params});
  }
};
