<template>
  <v-container>
    <v-row no-gutters>
      <!-- 現場監督:managers -->
      <v-col cols="12" sm="6">
        <div class="pa-1 manager-edit-table-wrapper">
          <v-toolbar flat>
            <div class="title-list">社員</div>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="isManagersSelected"
              @click="register"
              color="primary"
              >登録</v-btn
            >
          </v-toolbar>
          <!-- テーブル -->
          <v-data-table
            v-model="selectedManagers"
            :headers="TABLE_LABELS"
            :items="managers"
            hide-default-footer
            :items-per-page=managers.length
            disable-sort
            class="elevation-0 v-data-table__wrapper"
            show-select
            item-key="id"
            :noDataText="NO_DATA_MESSAGE"
            outlined
            :style="`height:${mainHeight - 75}px;overflow-x: hidden;overflow-y: auto;`"
          >
          </v-data-table>
        </div>
      </v-col>
      <!-- 現場監督:siteManagers -->
      <v-col cols="12" sm="6">
        <div class="pa-1 manager-edit-table-wrapper site-manager-edit">
          <v-toolbar flat>
            <div class="title-list">現場監督</div>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="isSiteManagersSelected"
              @click="remove"
              color="primary"
              >解除</v-btn
            >
          </v-toolbar>
          <!-- テーブル -->
          <v-data-table
            v-model="selectedSiteManagers"
            :headers="TABLE_LABELS"
            :items="siteManagers"
            hide-default-footer
            :items-per-page=siteManagers.length
            disable-sort
            class="elevation-0 v-data-table__wrapper"
            show-select
            item-key="user_id"
            :noDataText="NO_DATA_MESSAGE"
            outlined
            :style="`height:${mainHeight - 75}px;overflow-x: hidden;overflow-y: auto;`"
          >
          </v-data-table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
/**
 * ユーザーで使用するコンポーネント、関数
 */
import { SITE_SUPPERVISION } from "@/constants/PORTAL_CHART";
import { NO_DATA_MESSAGE } from "@/constants/COMMON.js";

/**
 * 定数
 */

//テーブルヘッダーラベル
const TABLE_LABELS = SITE_SUPPERVISION;

export default {
  data() {
    return {
      // テーブルヘッダーラベル
      TABLE_LABELS,
      NO_DATA_MESSAGE,

      // テーブルデータ
      // 現場監督
      managers: [],
      // 登録現場作業員
      siteManagers: [],

      //checkbox選択item
      selectedManagers: [],
      selectedSiteManagers: [],

      // api更新ボタンdisabled
      editable: false,
    };
  },

  props: {
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    item: Object,
  },

  async mounted() {

    this.$watch(
      () => this.item,
      (newValue) => {
        let leftItems = newValue?.site_director?.company_users || [];
        let rightItems = newValue?.site_director?.field_tree_users || [];

        leftItems?.forEach(item => {
          item['name'] = item['name_sei'] + " " + item['name_mei'];
        });
        rightItems?.forEach(item => {
          item['name'] = item['name_sei'] + " " + item['name_mei'];
        });
        this.managers = this.removeArray({
          from: leftItems.filter(item => item.state_handling_flag === 0),
          remove: rightItems,
        });
        this.siteManagers = rightItems;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    /**
     * 登録現場監督を監視して変更があればformUpdateする
     */
    this.$watch(
      () => this.siteManagers,
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.$emit("formUpdate", { field_tree_users: newValue, company_users: this.managers }, "site_director");
        }
      },
      {
        deep: true,
      }
    );
  },

  computed: {
    // 作業員登録ボタンdisabled
    isManagersSelected() {
      return this.selectedManagers.length === 0;
    },

    // 現場作業員解除ボタンdisabled
    isSiteManagersSelected() {
      return this.selectedSiteManagers.length === 0;
    },
  },

  methods: {
    // 現場監督登録
    register() {
      // 現場監督テーブル更新
      this.managers = this.removeArray({
        from: this.managers,
        remove: this.selectedManagers,
      });

      // 登録現場監督テーブル更新
      this.siteManagers = [...this.siteManagers, ...this.selectedManagers];

      // 選択解除
      this.selectedManagers = [];
    },

    // 登録現場監督解除
    remove() {
      // 登録現場監督テーブル更新
      this.siteManagers = this.removeArray({
        from: this.siteManagers,
        remove: this.selectedSiteManagers,
      });
      // 現場監督テーブル更新
      this.managers = [...this.managers, ...this.selectedSiteManagers.filter(e => e.state_handling_flag === 0)];

      // 選択解除
      this.selectedSiteManagers = [];
    },

    // 配列引き算
    removeArray(arrs) {
      return [...arrs.from].filter((from) => {
        // idが重複しないmanagerのみ残す
        return ![...arrs.remove].some((remove) => {
          return remove.user_id === from.user_id;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.manager-edit-table-wrapper.site-manager-edit {
  border-left: none;
}
.title-list {
  margin-left: 43%;
  font-weight: bold;
}
</style>
