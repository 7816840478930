var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-app-bar',{staticClass:"header",scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-tabs',{attrs:{"show-arrows":"","grow":""},model:{value:(_vm.nTab),callback:function ($$v) {_vm.nTab=$$v},expression:"nTab"}},_vm._l((_vm.FORMS),function(form,key){return _c('v-tab',{key:key,attrs:{"data-testid":("workerForm-tab-" + key)}},[_vm._v(" "+_vm._s(form.title)+" ")])}),1)]},proxy:true}])},[_c('v-toolbar-title',{staticStyle:{"width":"100%","text-align":"center"},attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.formtitle)+" ")]),_c('v-btn',{attrs:{"icon":"","data-testid":"btn-close"},on:{"click":_vm.onClickBtnClose}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"footer"},[(
        !_vm.editable &&
        _vm.stateHandlingFlag == 0 &&
        _vm.workApprovalFlag &&
        _vm.isTablesGroup
      )?_c('v-btn',{attrs:{"depressed":"","small":"","color":"warning"},on:{"click":function($event){return _vm.$emit('onShowScheduleDialog')}}},[_vm._v(" 中止 ")]):_vm._e(),(
        !_vm.editable &&
        _vm.stateHandlingFlag == 1 &&
        _vm.workApprovalFlag &&
        _vm.isTablesGroup
      )?_c('v-btn',{attrs:{"depressed":"","text":"","small":"","color":"warning"},on:{"click":function($event){return _vm.$emit('onShowScheduleDialog')}}},[_vm._v(" 中止取消 ")]):_vm._e(),(
        !_vm.editable &&
        _vm.stateHandlingFlag == 0 &&
        _vm.workApprovalFlag &&
        _vm.isTablesGroup
      )?_c('v-btn',{attrs:{"depressed":"","text":"","small":"","color":"primary"},on:{"click":_vm.onEditable}},[_vm._v(" 編集 ")]):_vm._e(),((_vm.editable && _vm.isTablesGroup) || _vm.isNewItem)?_c('v-btn',{attrs:{"depressed":"","text":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$emit('onCloseConfirmDialog')}}},[_vm._v(" やめる ")]):_vm._e(),((_vm.editable && _vm.isShowButtonCopy && _vm.isTablesGroup) || _vm.isNewItem)?_c('v-btn',{attrs:{"depressed":"","text":"","small":"","color":"primary"},on:{"click":_vm.onCopy}},[_vm._v(" 前回コピー ")]):_vm._e(),((_vm.editable && _vm.isTablesGroup) || _vm.isNewItem)?_c('v-btn',{attrs:{"depressed":"","text":"","small":"","disabled":!_vm.valid,"color":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(" 保存 ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }