<template>
  <v-item-group>
    <v-list>
      <v-list-item justify="space-around">
        <h2 class="font-weight-bold">{{ label }}</h2>
        <v-spacer></v-spacer>
        <v-btn
          v-if="path"
          icon
          color="primary"
          medium
          @click="$emit('open', path)"
        >
          <v-icon>mdi-greater-than</v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
    <slot />
  </v-item-group>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
    },
    path: {
      type: String,
    },
  },
};
</script>
