<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <!--  (共通) 会社ヘッダー -->
        <SitePortalHeader :flagUserRentalCompany="IS_ROLE_USER_RENTAL_COMPANY"/>
      </template>
      <template #page="{ layoutParams }">
        <TableLayout :layoutParams="layoutParams" :hideFooter="searchParams.pageCount >= searchParams.total_item">
          <template #tableHeader="{ updateHeader }">
            <!--  
              (共通) テーブルヘッダー 
              このなかに、新規作成ボタン、検索ボタン、一括削除ボタンが実装されてます
              @openRemoveDialog : 一括削除の確認ダイアログを開く
              @openItemForm : 新規作成フォームを開く
            -->
            <TableHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :isDelete="false"
              :isAddForm="searchParams.table_type === CARRY_ONS_TABS.TAB1"
              :isAddNew="false"
              :disableBtnForm="disableBtnForm"
              :updateHeader="updateHeader"
              @openItemForm="openPrintForm"
            >
              <!-- 
                検索はテーブルごとに実装します 
                @onInput
              -->
              <SearchFormWrapper>
                <Label label="管理番号">
                  <InputText
                    name="control_number"
                    :editable="true"
                    :values="searchParams"
                    validation_label="管理番号"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <Label label="機械名">
                  <InputText
                    name="machine_name"
                    :editable="true"
                    :values="searchParams"
                    validation_label="機械名"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <Label label="機番">
                  <InputText
                    name="machine_number"
                    :editable="true"
                    :values="searchParams"
                    validation_label="機番"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <TabSelect
                  v-if="searchParams.table_type !== CARRY_ONS_TABS.TAB1"
                  class="mt-8"
                  name="approval_flag"
                  :items="APPROVE_STATUS"
                  :editable="true"
                  :values="searchParams"
                  @onInput="onChangeSearchParams"
                />
                <v-spacer></v-spacer>
                <v-btn class="mr-6" color="primary" depressed @click="onSearch">
                  検索
                </v-btn>
              </SearchFormWrapper>
            </TableHeader>

            <!-- 
              (共通)
              ソートのレイアウトを調整するラッパーコンポーネントです
            -->
            <TableSortWrapper>
              <!--  
                (共通) ソート 
                ソート項目、ソート順、表示件数の選択、総件数の表示
              -->
              <TableSort
                :values="searchParams"
                :sort_items="sortItems"
                sort_item_text="name"
                sort_item_value="id"
                :sort_default="searchParams.table_type === CARRY_ONS_TABS.TAB1 ? 0 : 1"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                @onInput="onChangeSortParams"
                :key="flagSort"
              />
            </TableSortWrapper>
            <TableTab
              :items="TAB_SELECT"
              @onChangeTableTab="onChangeTableTab"
            />
          </template>
          <!-- body -->
          <template #tableBody="{ tableHeight }">
            <Table1
              v-if="searchParams.table_type === CARRY_ONS_TABS.TAB1"
              :tableLabels="TABLE_LABELS_1"
              :tableHeight="tableHeight"
              :removeItemTab1="removeItemTab1"
              :items="items"
              :itemsPerPage="searchParams.pageCount"
              @selectedForm="showBtnCreateForm"
              @openUpdateDetailForm="openUpdateDetailForm"
              @resetSelectedItemsTab1="resetItemTab1"
              @resetDataItems="resetItems()"
            />
            <Table2
              v-else-if="searchParams.table_type === CARRY_ONS_TABS.TAB2 ||
                searchParams.table_type === CARRY_ONS_TABS.TAB3 ||
                searchParams.table_type === CARRY_ONS_TABS.TAB5"
              :roleUser="roleUser"
              :tableLabels="TABLE_LABELS_2"
              :tableHeight="tableHeight"
              :items="items"
              :table_type="searchParams.table_type"
              :itemsPerPage="searchParams.pageCount"
              @resetDataItems="resetItems()"
              @openUpdateDetailForm="openUpdateDetailForm"
            />
            <Table4
              v-else-if="searchParams.table_type === CARRY_ONS_TABS.TAB4"
              :roleUser="roleUser"
              :tableLabels="TABLE_LABELS_4"
              :tableHeight="tableHeight"
              :items="items"
              :itemsPerPage="searchParams.pageCount"
              @resetDataItems="resetItems()"
              @openDetailForm4="openDetailForm4"
            />
          </template>
          <!-- footer -->
          <template #tableFooter>
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>
    <Popup width="480px" :dialog="isShowPrintForm">
      <PrintForm
        :flagError="flagError"
        @close="closePrintForm"
        @submit="submitCreateForm"
      />
    </Popup>
    <Popup :dialog="isShowItemForm">
      <MachineBringForm
        :flagDetail="flagDetail"
        @cancel="closeItemForm"
        @reloadItem="reloadItem"
      />
    </Popup>
    <Popup :dialog="isShowFormDetail">
      <InformationMachineForm
        @cancel="closeItemForm"
      />
    </Popup>
  </div>
</template>

<script>
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import Popup from "@/components/common/Popup";
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import { Store } from "@/store/Store.js";
import {
  SORT_ITEMS_1,
  SORT_ITEMS_2,
  TABLE_LABELS_1,
  TABLE_LABELS_2,
  TABLE_LABELS_4,
  CARRY_ONS_TABS,
  APPROVE_STATUS,
  ROLE_USER,
  TAB_SELECT_NAME
} from "@/constants/DEVICE_CARRY_ON";
import {
  TABLES_PER_PAGE,
  TABLE_SORT_ORDERS,
  ROLE_USER_RENTAL_COMPANY
} from "@/constants/COMMON";
import PrintForm from "./components/PrintForm.vue";
import Table1 from "./components/Table1.vue";
import Table2 from "./components/Table2.vue";
import Table4 from "./components/Table4.vue";
import TableTab from "./components/TableTab";
import { HEADER_MENU_ITEMS_SITE, HEADER_MENU_ITEMS_SITE_FOR_USER_RENTAL_COMPANY } from "@/constants/GLOBALHEADER"; //グローバルヘッダーメニュー
import Select from "@/components/forms/elements/Select";
import TabSelect from "@/components/forms/elements/TabSelect"; //絞り込みフォームで使用
import SwitchInput from "@/components/forms/elements/SwitchInput";
import InputDatepicker from "@/components/forms/elements/InputDatepicker";
import MachineBringForm from "@/components/forms/device/carryOn/MachineBringForm.vue";
import InformationMachineForm from "@/components/forms/device/carryOn/InformationMachineForm.vue";

//タイトル
const PAGE_TITLE = "持込機械届";
//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;
// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;
//ストア
const STORE = "DeviceCarryOns";
//１ページあたりのテーブル件数
const PAGE_COUNT = 25;


export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      PAGE_TITLE,
      TABLE_LABELS_1,
      TABLE_LABELS_2,
      TABLE_LABELS_4,
      SORT_ITEMS_1,
      SORT_ITEMS_2,
      MACHINE_USE_COMPANIES: [],
      DELIVERY_COMPANY_ARRAY: [],
      CARRY_ONS_TABS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      TAB_SELECT_NAME,
      ROLE_USER,
      roleUser: null,
      sortItems: SORT_ITEMS_1,
      disableBtnForm: false,
      isShowPrintForm: false,
      selectedItems: [],
      flagError: false,
      removeItemTab1: false,
      isShowItemForm: false,
      flagDetail: false,
      isShowFormDetail: false,
      ROLE_USER_RENTAL_COMPANY,
      flagSort : false,
      /**
       * (共通)
       * 一覧データ
       */
      items: [],

      // 検索パラメータ
      searchParams: {
        table_type: CARRY_ONS_TABS.TAB1,
        control_number: "",
        machine_name: "",
        approval_flag: null,
        pageCount: PAGE_COUNT,
        currentPage: 1,
        total_item: null,
        totalPage: 1,
        sort: SORT_ITEMS_1[0].id,
        asc: true,
      },
    };
  },
  components: {
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    SitePortalHeader,
    Popup,
    Label,
    InputText,
    PrintForm,
    Table1,
    Table2,
    Table4,
    Select,
    TabSelect,
    SwitchInput,
    InputDatepicker,
    TableTab,
    MachineBringForm,
    InformationMachineForm,
  },
  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      (data, oldData) => {
        if (data && JSON.stringify(data) !== JSON.stringify(oldData)) {
          this.getRoleInField();
          Store.dispatch("GlobalHeader/setSiteMenu", {
            menuId: this.IS_ROLE_USER_RENTAL_COMPANY ? HEADER_MENU_ITEMS_SITE_FOR_USER_RENTAL_COMPANY.DEVICE_MANAGEMENT.id : HEADER_MENU_ITEMS_SITE.DEVICE_MANAGEMENT.id,
          });
          this.getItems();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Sites/getRole"],
      (data) => {
        this.roleUser = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    
    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        /**
         *  (共通)
         * 一覧リストの格納
         */
        this.items = data[0];
        /**
         *  (共通)
         * ページネーション更新
         */
        let searchParams = { ...this.searchParams };
        searchParams.currentPage = data[1].current;
        searchParams.totalPage = data[1].total;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    TAB_SELECT() {
      return Object.keys(TAB_SELECT_NAME).map((key) => {
        return TAB_SELECT_NAME[key];
      });
    },
    APPROVE_STATUS() {
      return Object.keys(APPROVE_STATUS).map((key) => {
        return APPROVE_STATUS[key];
      });
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    
    /**
     *
     * API Param
     */
    apiParams() {
      return {
        field_id: this.CURRENT_SITE.field_id,
        tab_number: this.searchParams.table_type,
        control_number: this.searchParams.control_number,
        machine_name: this.searchParams.machine_name,
        machine_number: this.searchParams.machine_number,
        approval_flag: this.searchParams.approval_flag,
        sort_value: this.searchParams.sort,
        sort_by: this.searchParams.asc ? 1 : 0,
        page_size: this.searchParams.pageCount,
        page_number: this.searchParams.currentPage
      };
    },
    IS_ROLE_USER_RENTAL_COMPANY(){
      return Store.getters["Sites/getUserRole"] == this.ROLE_USER_RENTAL_COMPANY;
    },
  },
  methods: {
    async getRoleInField() {
      if(this.CURRENT_SITE.field_id) {
        const field_id = this.CURRENT_SITE.field_id;
        const params = {
          field_id,
          target_role: []
        };
        await Store.dispatch("Sites/getRole", params);
        await Store.dispatch("GlobalHeader/setSiteMenu", {
          menuId: this.IS_ROLE_USER_RENTAL_COMPANY ? HEADER_MENU_ITEMS_SITE_FOR_USER_RENTAL_COMPANY.DEVICE_MANAGEMENT.id : HEADER_MENU_ITEMS_SITE.DEVICE_MANAGEMENT.id,
        });
      }
    },
    async onChangeTableTab(item) {
      this.items = [];
      this.changeSortItems(item.id);
      const searchParams = { ...this.searchParams };
      searchParams.pageCount = 25;
      searchParams.currentPage = 1;
      searchParams.table_type = item.id;
      searchParams.sort = item.id === TAB_SELECT_NAME.TABLE1.id ? this.sortItems[0].id : this.sortItems[1].id;
      this.searchParams = searchParams;
      this.flagSort = ! this.flagSort;
      this.getItems();
    },
    changeSortItems(id) {
      if (id === TAB_SELECT_NAME.TABLE1.id) {
        this.sortItems = this.SORT_ITEMS_1;
      } else {
        this.sortItems = this.SORT_ITEMS_2;
      }
    },
    /**
     * (共通)
     * 検索
     */
    onSearch() {
      this.searchParams["currentPage"] = 1;
      this.getItems();
    },
    /**
     * (共通)
     * 並び替えパラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      name == "pageCount" ? (searchParams["currentPage"] = 1) : "";
      this.searchParams = searchParams;
      this.getItems();
    },
    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
    },
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },
    resetItems() {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = 1;
      this.searchParams = searchParams;
      this.getItems();
    },
    /**
     * データ取得
     */
    async getItems() {
      const apiParams = { ...this.apiParams };
      let path = null;
      if (this.searchParams.table_type === CARRY_ONS_TABS.TAB1) {
        delete apiParams?.tab_number;
        delete apiParams?.approval_flag;
        path = "get";
      } else {
        path = "getReport";
      }
      if(this.CURRENT_SITE.field_id) {
        Store.dispatch(`${STORE}/${path}`, { params: apiParams });
      }
    },

    showBtnCreateForm(newValue) {
      this.disableBtnForm = newValue.length === 0;
      this.selectedItems = [...newValue];
    },

    resetItemTab1() {
      this.removeItemTab1 = false;
    },

    openPrintForm() {
      this.isShowPrintForm = true;
    },

    closePrintForm() {
      this.removeItemTab1 = true;
      this.isShowPrintForm = false;
    },
    async openUpdateDetailForm(id, flagDetail){
      this.isShowItemForm = true;
      if(flagDetail){
        this.flagDetail = true;
      }
      await Store.dispatch(`${STORE}/getMachineFieldDetail`, id);
    },
    async openDetailForm4(id){
      this.isShowFormDetail = true;
      await Store.dispatch(`${STORE}/getListMachineElectricalTools`, id);
    },
    async reloadItem(){
      this.getItems();
    },
    /**
     * Call API create report
     */
    async submitCreateForm(createFormByTab) {
      const machine_company_field_ids = this.selectedItems.map(e => e.id);
      const params = {
        report_type: createFormByTab,
        machine_company_field_ids
      };
      const result = await Store.dispatch(`${STORE}/createMachineFieldReport`, params);
      if (result.hasError) {
        this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.message,
        });
        return;
      } else {
        this.closePrintForm();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "作成しました",
        });
        this.getItems();
      }
    },
    async closeItemForm(){
      this.isShowItemForm = false;
      this.isShowFormDetail = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.tableLayout .tableFooter {
  margin-top: -10px;
}
</style>
