export const NOTIFICATION_COLOR = {
  POSITIVE: { id: 1, key: "positive", color: "#1b9c4f" },
  NEGATIVE: { id: 2, key: "negative", color: "#ff7b52" },
  NORMAL: { id: 3, key: "normal", color: "#767676" },
  BLUE: { id: 4, key: "blue", color: "#29abe2" },
  RED: { id: 5, key: "red", color: "#ab0000" },
  ORANGE_LIGHT: { id: 6, key: "orange_light", color: "#e5951d" },
};

export const NOTIFICATION_MESSAGE_TYPE = [
  { id: 1, name: "連絡事項", tag_color: 3 },
  { id: 2, name: "承認解除", tag_color: 2 },
  { id: 3, name: "承認", tag_color: 1 },
  { id: 4, name: "提出", tag_color: 3 },
  { id: 5, name: "差戻し", tag_color: 2 },
  { id: 6, name: "搬入", tag_color: 1 },
  { id: 7, name: "使用申請", tag_color: 6 },
  { id: 8, name: "使用開始", tag_color: 1 },
  { id: 9, name: "返却申請", tag_color: 6 },
  { id: 10, name: "返却完了", tag_color: 1 },
  // 調達
  { id: 11, name: "見積依頼", tag_color: 6 },
  { id: 12, name: "受注", tag_color: 6 },
  { id: 13, name: "発注見送り", tag_color: 2 },
  { id: 14, name: "見積回答", tag_color: 1 },
  { id: 15, name: "納品報告", tag_color: 1 },
  { id: 16, name: "使用終了", tag_color: 1 },
  { id: 17, name: "返却申請", tag_color: 6 },
];
export const NOTIFICATION_SYS = [
  { id: 1, name: "予定管理" },
  { id: 2, name: "巡回記録" },
  { id: 3, name: "労務管理" },
  { id: 4, name: "自社管理" },
  { id: 5, name: "資機材管理" },
];

export const NOTIFICATION_URGENCY = [
  { id: 0, name: "低", tag_color: 4 },
  { id: 1, name: "中", tag_color: 1 },
  { id: 2, name: "高", tag_color: 2 },
  { id: 3, name: "急", tag_color: 5 },
];
