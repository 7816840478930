<template>
  <li>
    <div class="item" @click="openCheckForm(item.machine_company_type_item_id)" :class="styleItem">
      <div class="left-img">
        <ItemImage
          :editable="edit"
          :item="item"
          :imageMap="imageMap"
          :id="item.id"
          :key="name"
          @onChangeValue="onChangeValue"
        />
      </div>
      <div class="right-content">
        <ValidationProvider
          v-slot="{ errors, valid }"
          :vid="name"
          name="Type"
          validation_label="type"
          v-if="edit"
          rules="required|max:255"
        >
          <v-text-field
            :error-messages="errors"
            :success="valid"
            v-model="item.type"
            @input="handleChange"
          />
        </ValidationProvider>
        <p v-else>{{ item.type }}</p>
        <div v-if="edit" class="action">
          <button @click="emitRemoveItem(item.id)">
            <v-icon>mdi-delete</v-icon>
          </button>
          <div v-if="level < 4">
            <button @click="setSortItem(item.type)">
              <v-icon>mdi-sort-variant</v-icon>
            </button>
            <button @click="addItem">
              <v-icon color="green darken-2">mdi-plus</v-icon>
            </button>
          </div>
        </div>
        <div
          v-if="
            !edit && Array.isArray(item.childrens) && item.childrens.length > 0
          "
          class="arrown"
        ></div>
      </div>
    </div>
    <ul>
      <Item
        v-for="(item, index) in item.childrens"
        :item="item"
        :key="index"
        :edit="edit"
        :imageMap="imageMap"
        :index="index"
        :level="level + 1"
        @deleteChild="removeItem"
        @onChangeValue="onChangeValue"
        @sortItem="setSortItem"
        @updateSort="updateSort"
        @openCheckForm="openCheckForm"
      />
    </ul>
    <Popup width="480px" :dialog="enableSortItem">
      <SortMachine
        title="機材選択表示順設定"
        :text="nameParentSort"
        warning
        :sortItem="[...this.item.childrens]"
        :imageMap="imageMap"
        @close="closeSortPopup"
        @updateRoot="updateRoot"
      />
    </Popup>
  </li>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import Popup from "@/components/common/Popup.vue";
import SortMachine from "./SortMachine.vue";
import ItemImage from "./ItemImage.vue";
import { ValidationProvider } from "vee-validate";

export default {
  name: "Item",
  props: {
    item: Object,
    edit: Boolean,
    imageMap: Map,
    level: Number,
  },
  computed: {
    name() {
      return `type${this.item.id}${uuidv4()}`;
    },
    styleItem() {
      if(!this.edit) return 'poiter';
      return '';
    }
  },
  components: {
    ItemImage,
    Popup,
    SortMachine,
    ValidationProvider,
  },
  data() {
    return {
      enableSortItem: false,
      nameParentSort: String,
    };
  },
  methods: {
    handleChange() {
      let item = { ...this.item };
      item.type = this.item.type;
      this.$emit("onChangeValue", { id: item.id, item: item, option: "value" });
    },
    onChangeValue(params) {
      this.$emit("onChangeValue", params);
    },
    generateDefaultItem() {
      return {
        id: uuidv4(),
        type: "",
        newItem: true,
        order_number: this.maxParentOrder() + 1,
        image_url: "",
        image: "",
        childrens: [],
      };
    },
    maxParentOrder() {
      if (!this.item || !Array.isArray(this.item) || !this.item.length)
        return 0;
      return this.item[this.item.length - 1].order_number || 0;
    },
    addItem() {
      if (
        !this.item.childrens ||
        !Array.isArray(this.item.childrens) ||
        !this.item.childrens.length
      ) {
        this.item.childrens = [];
      }

      this.item.childrens.push(this.generateDefaultItem());
    },
    emitRemoveItem(id) {
      if (this.level === 1) this.$emit("deleteRoot", id);
      else this.$emit("deleteChild", id);
    },
    removeItem(id) {
      this.item.childrens = this.item.childrens.filter(
        (item) => item.id !== id
      );
    },
    closeSortPopup() {
      this.enableSortItem = false;
    },
    updateRoot(payload) {
      this.item.childrens = [...payload];
      this.$emit("updateSort", payload);
    },
    setSortItem(value) {
      this.nameParentSort = value;
      this.enableSortItem = true;
    },
    updateSort(payload) {
      this.$emit("updateSort", payload);
    },
    openCheckForm(payload) {
      if (this.edit) return;
      this.$emit("openCheckForm", payload);
    },
  },
};
</script>

<style scoped>
.poiter{
  cursor: pointer;
}
.action {
  display: flex;
  cursor: pointer;
  white-space: nowrap;
}
.action i {
  margin: 4px;
}
p {
  margin: 0 !important;
}
.left-img {
  width: 45px;
  height: 45px;
}
.left-img img {
  width: 45px;
}
.right-content {
  width: 210px;
  background: white;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  margin-left: 5px;
}
.right-content p {
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.item {
  display: flex;
  align-items: center;
  height: 45px;
  margin: 5px;
}
ul {
  padding: 0 !important;
  margin: 0 !important;
}
ul li {
  display: flex;
}
.arrown {
  width: 8px;
  height: 8px;
  border-left: 2px solid rgba(0, 0, 0, 0.38);
  border-top: 2px solid rgba(0, 0, 0, 0.38);
  transform: rotateZ(135deg);
  margin-right: 10px;
}
</style>
