<template>
  <!-- parent table -->
    <v-data-table
      :headers="tableLabels"
      :items="items"
      :height="tableHeight"
      :items-per-page="itemsPerPage"
      hide-default-footer
      hide-default-header
      fixed-header
      disable-sort
      class="elevation-0"
      sort-by="updatedAt"
    >
      <template v-slot:header="{ props }">
        <tr>
          <th
            v-for="(my_header,index) in props.headers"
            :key="my_header.value"
            :style="`text-align: left;${index == 0 ? 'width:4vw' : ''};${index == 1 ? 'width:25vw' : ''}`"
            class="border_bottom"
            height="50px"
          >
            <v-row v-if="index == 0">
              <v-col cols="12" sm="6" md="6">
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-simple-checkbox
                  :value="isCheckedAll()"
                  @input="updateAllCheckbox($event)"
                ></v-simple-checkbox>
              </v-col>
            </v-row>
            <div class="ml-4" style="text-align: left" v-else>{{ my_header.text }}</div>
          </th>
        </tr>
      </template>
      <!-- parent table template -->
      <template v-slot:body="{ items }">
        <template v-if="items && items.length > 0">
          <tbody v-for="item in items" :key="item.id" >
          <tr v-on:click="openItemForm(item)" class="border_bottom start" > 
            <td class="border_bottom start">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <!-- toggle icon -->
                  <v-icon @click.stop="toggleShowChildren(item.id)" v-if="item.childrens">
                    {{
                      isShowChildren[item.id]
                        ? "mdi-chevron-down"
                        : "mdi-chevron-up"
                    }}
                  </v-icon>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-simple-checkbox
                    :value="isSelectedParent(item.id) && checkSelectedParents(item)"
                    @input="updateCheckbox(item, $event, true)"
                  ></v-simple-checkbox>
                </v-col>
              </v-row>
            </td>
            <td style="word-break: break-all;">{{ item.type }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.remaining }}</td>
            <td>{{ item.quantity }}</td>
            <td>{{ item.machine_number }}</td>
            <td>
              <div v-if="!Array.isArray(item.childrens)">
                <p class="text-green">{{ item.field_name }}</p>
                <p >{{ item.delivery_date }}</p>
              </div>
            </td>
            <td class="border_bottom"><v-icon v-if="item.image_flag == 1" style="color: black">mdi-paperclip</v-icon></td>
          </tr>
          <!-- child table template -->
          <template v-if="Array.isArray(item.childrens)">
            <tr
              v-for="itemChild in item.childrens"
              v-show="isShowChildren[item.id]"
              :key="itemChild.index"
            >
              <td class="border_bottom start">
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-simple-checkbox
                      :value="isSelected(item.id,itemChild.index)"
                      @input="updateCheckbox(itemChild, $event, null, item)"
                    ></v-simple-checkbox>
                  </v-col>
                </v-row>
              </td>
              <td class="border_bottom" style="word-break: break-all;"></td>
              <td class="border_bottom">{{ itemChild.name }}</td>
              <td class="border_bottom">{{ itemChild.remaining }}</td>
              <td class="border_bottom">{{ itemChild.quantity }}</td>
              <td class="border_bottom">{{ itemChild.machine_number }}</td>
              <td class="border_bottom">
                <p class="text-green">{{ itemChild.field_name }}</p>
                <p >{{ itemChild.delivery_date }}</p>
              </td>
              <td class="border_bottom"></td>
            </tr>
          </template>
          <tr
            v-show="
              isShowChildren[item.id]  && 
              (item.childrens && Array.isArray(item.childrens)) && 
              childTableDisplayMoreNumber(item.total_children_item,(typeof item.childrens != 'undefined' ? item.childrens.length : 0)) > 0
            "
          >
            <td colspan="9">
              <div class="d-flex justify-center">
                <v-btn
                  x-small
                  depressed
                  class="mr-4 display-more"
                  color="#E5E5E5"
                  v-if="
                    childTableDisplayMoreNumber(
                      item.total_children_item,
                      (typeof item.childrens != 'undefined' ? item.childrens.length : 0)
                    ) > 0
                  "
                  @click="getChildren(item)"
                  >さらに表示する
                  <v-badge
                    class="display-more-number"
                    inline
                    color="#767676"
                    :content="
                      childTableDisplayMoreNumber(
                        item.total_children_item,
                        (typeof item.childrens != 'undefined' ? item.childrens.length : 0)
                      )
                    "
                  />
                </v-btn>
              </div>
            </td>
          </tr>
          </tbody>
        </template>
        <template v-if="isNoDataMessage">
          <td colspan="9">
            <div class="d-flex justify-center">
              {{ NO_DATA_MESSAGE }}
            </div>
          </td>
        </template>
      </template>
    </v-data-table>
</template>
<script>
import _ from "lodash";
import { NO_DATA_MESSAGE } from "@/constants/COMMON"; 

export default {
  data() {
    return {
      isShowChildren: {},
      isDisable: false,
      time_out: null,
      NO_DATA_MESSAGE
    };
  },
  props: {
    id:String,
    tableLabels: Array,
    itemsPerPage: Number,
    tableHeight: Number,
    items: Array,
    isSelectedParent: Function,
    isSelected: Function,
    updateCheckbox: Function,
    checkSelectedParents: Function,
    updateAllCheckbox : Function,
    isCheckedAll : Function,
    openItemForm: Function,
    getChildItems: Function,
    refreshSelectChildren: Function,
    isNoDataMessage: Boolean,
  },
  mounted() {
    this.$watch(
      () => this.items,
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          const items = _.cloneDeep(newValue);
          this.initIsShowChildren(items);
          this.refreshSelectChildren();
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  methods: {
    initIsShowChildren(items) {
      let _isShowChildren = _.cloneDeep(this.isShowChildren);
      items.forEach((item) => {
        if(!_isShowChildren[item.id]) _isShowChildren[item.id] = false;
      });
      this.isShowChildren = _isShowChildren;
    },
    toggleShowChildren(id) {
      this.isShowChildren[id] = !this.isShowChildren[id];
    },
    childTableDisplayMoreNumber(totalNum,displayNum) {
      const _number = totalNum - displayNum;
      return _number > 0 ? _number : "0";
    },
    getChildren(item) {
      this.isDisable = true;
      this.time_out = setTimeout(() => {
        this.isDisable = false;
      }, 1000);
      this.$emit('getChildItems', item.id, 
        Math.floor((item.childrens.length - 3) / 5) + 2);
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.text-green{
  color: #1B9C4F;
}

::v-deep .v-data-table-header {
  .v-input--selection-controls__input {
    display: none !important;
  }
}

td.start div.row {
  width: 75px !important;
}

td.col-status {
  width: 110px !important;
}

.child-table {
  td.start {
    padding-right: 0;
  }
}

.schedule_nodata {
  position: absolute;
  left: 50%;
}

button.display-more {
  height: 20px !important;
}

.display-more-number ::v-deep .v-badge__badge {
  font-size: 10px;
}

.display-more-number ::v-deep .v-badge__badge {
  padding: 1px;
  height: 14px;
}
.border_bottom {
  border-bottom: 1px solid #E8E8E8;
}
</style>
