<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <!--  (共通) 会社ヘッダー -->
        <SitePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout :layoutParams="layoutParams" :hideFooter="searchParams.pageCount >= total_item">
          <template #tableHeader="{ updateHeader }">
            <div>
              <ScheduleHeader
                ref="tableHeader"
                :pageTitle="PAGE_TITLE"
                :multiSelectStatus="disableApproveBtn"
                :updateHeader="updateHeader"
                :searchParams="searchParams"
                :isDirector="(!IS_OBAYASHI && isDirector) || (IS_OBAYASHI && (isDirector || isPlanManager))"
                :isPlanManager="isDirector"
                :isShowGraphButton="false"
                :isShowTypeAdd="true"
                :isShowSettingPlanManager="isDirector || isPlanManager"
                @openRemoveDialog="openRemoveDialog()"
                @onReject="openConfirmDialog(true)"
                @onApprove="openConfirmDialog(false)"
                @openNewItemFormByType="openNewItemForm"
                @onInput="onChangeSearchParams"
                @showSettingPlan="showSettingPlan"
              >
                <SearchFormWrapper>
                  <Label label="工事" width="200px">
                    <SearchSites
                      name="field_construction_id"
                      :fieldId="CURRENT_SITE.field_id"
                      @onInput="onChangeSearchParams"
                      :formValues="searchParams"
                    />
                  </Label>
                  <Label v-if="IS_OBAYASHI" label="協力会社" :width="$sp ? 'auto' : '200px'">
                    <SearchCompanyPartnerLv1
                      name="field_tree_id"
                      :fieldId="searchParams.field_construction_id"
                      :hasGetByPlanManager="IS_OBAYASHI && isPlanManager"
                      :otherLevel1OnlyFlg="null"
                      :hasPrime=1
                      @onInput="onChangeSearchParams"
                      :formValues="searchParams"
                    />
                  </Label>
                  <Label label="ゲート" width="200px">
                    <SearchGates
                      name="gate_id"
                      :fieldId="CURRENT_SITE.field_id"
                      @onInput="onChangeSearchParams"
                      :isReset="isReset"
                    />
                  </Label>
                  <Label label="揚重" width="200px">
                    <SearchCraneName
                      name="crane_id"
                      :fieldId="CURRENT_SITE.field_id"
                      @onInput="onChangeSearchParams"
                      :isReset="isReset"
                    />
                  </Label>
                  <TabSelect
                    class="mt-8"
                    name="status_code"
                    :items="SCHDULE_GATE_STATUS"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />
                  <TabSelect
                    class="mt-8"
                    name="approval"
                    :items="SCHDULE_APPROVE"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />
                  <SwitchInput
                    class="mt-4"
                    name="contain_without_plan"
                    :label="`予定がないデータも表示`"
                    :values="searchParams"
                    :editable="true"
                    @onInput="onChangeSearchParams"
                  />
                  <v-spacer></v-spacer>
                  <div class="d-flex flex-column">
                    <v-btn
                      class="mx-2 mr-4 mt-2"
                      color="primary"
                      depressed
                      @click="onSearch"
                    >
                      検索
                    </v-btn>
                  </div>
                </SearchFormWrapper>
              </ScheduleHeader>
              <v-container class="tableSortWrapper">
                <v-spacer></v-spacer>
                <div class="sortLabel">作業時間帯:</div>
                <div class="sortElement">
                  <SelectFieldTimeZones
                    name="work_hour_zone"
                    :fieldId="searchParams.field_construction_id"
                    @onInput="onChangeSearchParams"
                  />
                </div>
                <div class="sortLabel">総件数:</div>
                <div class="sortElement total_item">
                  {{ all_record || 0 }}件
                </div>
              </v-container>
            </div>
          </template>

          <!--
            (共通)テーブル
            v-data-table自体は共通ですが、
            カラムによって変更をしたい場合はそれぞれ実装してください。
          -->
          <template #tableBody="{ tableHeight }">
            <div :style="`height:${tableHeight}px; overflow-x: hidden; overflow-y: auto;`">
              <div>
                <p class="title_label" v-if="!isNoDataGate">{{ TITLE_GATE }}</p>
                <ScheduleGageTable
                  :isGates="true"
                  :isNotShowTemplate="isNoDataGate"
                  :isShowTemplateNoData="isNoDataGate && isNoDataCrane"
                  :items="items"
                  :functions="functions"
                  :isNotShowNoData="true"
                  :key="reloadList"
                  @setIsGate="isGates=true"
                  :isUsage="true"
                />
                <p class="title_label" v-if="!isNoDataCrane">{{ TITLE_CRANE }}</p>
                <ScheduleGageTable
                  :isGates="false"
                  :isNotShowTemplate="isNoDataCrane"
                  :items="itemCranes"
                  :functions="functions"
                  :isNotShowNoData="true"
                  :key="reloadListCrane"
                  @setIsGate="isGates=false"
                  :isUsage="true"
                />
              </div>
              <div v-if="isShowNoDataList" class="schedule_nodata">
                <span>{{ NO_DATA_MESSAGE }}</span>
              </div>
            </div>
          </template>

          <template #tableFooter>
            <!-- (共通) ページネーション -->
            <Pagination
              :current="searchParams.page_number"
              :total="totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>
    <Popup :dialog="popups.isShowItemForm && isGates">
      <ScheduleGateFormObayashi
        v-if="IS_OBAYASHI"
        :item="editedItem"
        :isNewItem="isNewItem"
        :isErrorSubmit="isErrorSubmit"
        :isEmitted="isEmitted"
        :dataInitial="dataInitial"
        @formUpdate="formUpdate"
        @submit="submitForm"
        @cancel="closeItemForm"
      />
      <ScheduleGateForm
        v-else
        :item="editedItem"
        :isNewItem="isNewItem"
        :isErrorSubmit="isErrorSubmit"
        :isEmitted="isEmitted"
        @formUpdate="formUpdate"
        @submit="submitForm"
        @cancel="closeItemForm"
      />
    </Popup>

    <Popup :dialog="popups.isShowItemForm && !isGates">
      <ScheduleCraneFormObayashi
        v-if="IS_OBAYASHI"
        :item="editedItem"
        :isNewItem="isNewItem"
        :dataInitial="dataInitial"
        @cancel="closeItemForm"
      />
      <ScheduleCraneForm
        v-else
        :item="editedItem"
        :isNewItem="isNewItem"
        @cancel="closeItemForm"
      />
    </Popup>

    <Popup width="480px" :dialog="popups.isShowConfirmDialog">
      <ConfirmDialog
        :dialogText="dialogApproveText"
        :isApproved="isApproved"
        @close="closeApproveDialog()"
        @yes="handleConfirm()"
        :warning="dialogApproveText.flg"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmDialog
        :dialogText="dialogRemoveText"
        :isApproved="isApproved"
        @close="closeRemoveDialog()"
        @yes="onRemove()"
        warning
      />
    </Popup>
    <Popup width="480px" :dialog="popups.openSettingPlanForm">
      <SettingPlan
        @resetList="getItems"
        @closeSettingPlanForm="popups.openSettingPlanForm = false"
      />
    </Popup>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import { TABLES_PER_PAGE } from "@/constants/COMMON"; //絞り込みフォームで使用

/**
 * コンポーネント、関数
 */
import ScheduleGateForm from "@/components/forms/schedule/gates/ScheduleGateForm"; //ユーザー登録編集フォーム
import ScheduleGateFormObayashi from "@/components/forms/schedule/gates/obayashi/ScheduleGateFormObayashi";
import ScheduleCraneForm from "@/components/forms/schedule/cranes/index"; //ユーザー登録編集フォーム
import ScheduleCraneFormObayashi from "@/components/forms/schedule/cranes/obayashi/ScheduleCraneFormObayashi";
import InputDatepicker from "@/components/forms/elements/InputDatepicker"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import TabSelect from "@/components/forms/elements/TabSelect"; //絞り込みフォームで使用
import SwitchInput from "@/components/forms/elements/SwitchInput";
import ConfirmDialog from "./components/ConfirmDialog.vue";

/**
 * 固有のコンポーネント
 */
import ScheduleHeader from "../components/ScheduleHeader.vue";
import SettingPlan from "@/components/forms/schedule/components/settingPlan/SettingPlan.vue";
import ScheduleGageTable from "../components/ScheduleGageTable/indexUsage.vue";
import SearchSites from "../components/SearchSites.vue";
import SearchCompanyPartner from "../components/SearchCompanyPartner.vue";
import SearchCompanyPartnerLv1 from "../components/SearchCompanyPartnerLv1.vue";
import SelectFieldTimeZones from "../components/SelectFieldTimeZones.vue";
import SearchGates from "../components/SearchGates.vue";
import SearchCraneName from "../components/SearchCraneName.vue";
import {
  SCHEDULE_GATE_INITIAL_ITEM_OBAYASHI,
  SCHEDULE_GATE_INITIAL_ITEM,
  SCHDULE_GATE_STATUS,
  DIALOG_TEXT,
  DIALOG_REMOVE_TEXT,
  SCHDULE_APPROVE,
} from "@/constants/SCHEDULE_GATE"; //絞り込みフォームで使用

import { format } from "date-fns";
import _ from "lodash";
import { HEADER_MENU_ITEMS_SITE } from "@/constants/GLOBALHEADER";
import { INIT_DATA, INIT_DATA_OBAYASHI } from "@/constants/SCHEDULE_CRANE";
/**
 * API
 */
// import { scheduleChildren } from "@/api/modules/scheduleChildren";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
import { NO_DATA_MESSAGE } from "@/constants/COMMON.js";
import { TYPE_ADD } from "@/constants/SCHEDULE_USAGE";
import { addOneDayToEndTime , removeOneDayToEndTime} from "@/constants/CRANES";
import { TITLE_CRANE, TITLE_GATE } from "@/constants/SCHEDULE_USAGE";

/**
 * 定数
 * この一覧ページで使用する固有の定数を定義します。
 * 共通に使用する定数は基本的に@/constants/で定義します。
 * - 定数は大文字で定義します
 * - 定数は基本的にはdataに代入しないで直接参照します
 */

//搬入出予定
const PAGE_TITLE = "使用予定";

//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

//ストア
const STORE_GATE = "ScheduleGates";
const STORE_CRANE = "ScheduleCrane";

const PAGE_COUNT = 25;
const PAGE_SIZE_CRANE = 12;
const PAGE_SIZE_GATE = 13;

//テーブルヘッダーラベル


const DATE = format(new Date().setDate(new Date().getDate() + 1), "yyyy-MM-dd");

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      PAGE_TITLE,
      PAGE_COUNT_OPTIONS,
      NO_DATA_MESSAGE,
      TYPE_ADD,
      TITLE_GATE,
      TITLE_CRANE,

      /**
       * (共通)
       * 一覧データ
       */
      items: {},

      itemCranes: {},

      /**
       * (共通)
       * checkbox選択item
       */
      selectedItems: [],

      /**
       * (共通)
       * 編集アイテム
       * 定数から初期値を代入
       */
      editedItem: {},
      dataInitial : {},

      /**
       * (共通)
       * 新規フラグ
       */
      isNewItem: false,
      isDirector: false,
      isGates: true,
      // 検索パラメータ
      searchParams: {
        date: DATE, //初期値は今日
        field_construction_id: null,
        field_tree_id: null,
        gate_id: null,
        crane_id: null,
        contain_without_plan: 1,
        work_hour_zone: null,
        status_code: [], //契約
        approval: [], //承認
        page_number: 1,
        pageCount: PAGE_COUNT,
        all_record_gate: 0,
        all_record_crane: 0,
        total_item_gate: 0,
        total_item_crane: 0,
        totalPageGate: 0,
        totalPageCrane: 0,
      },

      /**
       * (共通)
       * ポップアップの状態管理
       */
      popups: {
        // 追加/編集フォーム
        isShowItemForm: false,
        isShowGateForm: false,
        isShowCraneForm: false,
        isShowConfirmDialog: false,
        isShowRemoveDialog: false,
        openSettingPlanForm: false,
      },

      /**
       * まとめてメソッドを子コンポーネントに渡す
       */
      functions: {
        updateSelectedItems: this.updateSelectedItems,
        openItemForm: this.openItemForm,
        getChildItems: this.getChildItems,
        addNewChild: this.addNewChild,
        resetList: this.getItems,
      },
      dialogApproveText: {
        title: "",
        text1: "",
        text2: "",
        btnSubmitText: "",
        flg: false,
      },
      dialogRemoveText: {
        title: "",
        text1: "",
        text2: "",
        text3: null,
        text4: null,
        btnSubmitText: "",
      },
      isApproved: false,
      reloadList: false,
      reloadListCrane: 0,
      isErrorSubmit: false,
      isEmitted: false,
      ENV_CLIENT,
      SCHEDULE_GATE_INITIAL_ITEM_OBAYASHI,
      SCHEDULE_GATE_INITIAL_ITEM,
      INIT_DATA_OBAYASHI,
      INIT_DATA,
      isPlanManager: false,
      isShowNoDataList: false,
      firstLoad: true,
      isReset: false,
    };
  },

  components: {
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    SitePortalHeader,
    Popup,
    ConfirmDialog,
    ScheduleGateForm,
    InputDatepicker,
    Label,
    //固有のコンポーネント
    ScheduleHeader,
    TabSelect,
    SelectFieldTimeZones,
    ScheduleGageTable,
    SearchSites,
    SearchCompanyPartner,
    SearchCompanyPartnerLv1,
    SearchGates,
    ScheduleGateFormObayashi,
    SwitchInput,
    SearchCraneName,
    ScheduleCraneFormObayashi,
    ScheduleCraneForm,
    SettingPlan,
  },

  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    if (ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT) {
      await Store.dispatch("GlobalHeader/setSiteMenu", {
        menuId: HEADER_MENU_ITEMS_SITE.USAGE_SCHEDULE.id,
      });
    }else {
      await Store.dispatch("GlobalHeader/setSiteMenu", {
        menuId: HEADER_MENU_ITEMS_SITE.SCHEDULE.id,
      });
    }
    this.$watch(
      () => Store.getters["PortalChart/getWorkTypes"],
      (data) => {
        if (data.length > 0) {
          if (this.searchParams.field_construction_id && !this.popups.isShowItemForm) {
            this.getWorkHourZones();
            this.callApiCheckIsDirector();
          }
        }
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Employees/getIsDirector"],
      (data) => {
        this.isDirector = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Employees/getIsPlanManager"],
      (data) => {
        this.isPlanManager = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE_GATE}/getData`],
        Store.getters[`${STORE_GATE}/getPagination`],
        Store.getters[`${STORE_CRANE}/getData`],
        Store.getters[`${STORE_CRANE}/getPagination`],
      ],
      (data) => {
        // if is data old in store then not show
        if (this.firstLoad) {
          this.firstLoad = false;
          return;
        }
        /**
         *  (共通)
         * 一覧リストの格納
         */
        this.items = data[0];
        this.itemCranes = data[2];
        if (!!data && !data[0]?.devices[0].device_id && !data[2]?.devices[0].device_id) this.isShowNoDataList = true;
        else this.isShowNoDataList = false;
        this.reloadComponentList();

        /**
         *  (共通)
         * ページネーション更新
         */
        let searchParams = { ...this.searchParams };
        if (data[0]?.date && data[0]?.time_frame_id) {
          searchParams.work_hour_zone = data[0].time_frame_id;
        }
        searchParams.page_number = data[1].current;

        searchParams.totalPageGate = data[1].total;
        searchParams.total_item_gate = data[1].total_item;
        searchParams.all_record_gate = data[1].all_record;

        searchParams.totalPageCrane = data[3].total;
        searchParams.total_item_crane = data[3].total_item;
        searchParams.all_record_crane = data[3].all_record;
        this.searchParams = searchParams;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */
  computed: {
    /**
     * 承認・承認解除ボタンの活性・非活性
     * selectedItems.length === 0
     * 自体はtemplate側で記述できますが、
     * ここに明示的に状態を定義して可読性を担保してます
     */
    disableApproveBtn() {
      return this.selectedItems.length === 0;
    },

    SCHDULE_GATE_STATUS() {
      return Object.keys(SCHDULE_GATE_STATUS).map((key) => {
        return SCHDULE_GATE_STATUS[key];
      });
    },

    /**
     *  (スケジュール)
     * 絞り込み:承認/未承認
     */
    SCHDULE_APPROVE() {
      return Object.keys(SCHDULE_APPROVE).map((key) => {
        return SCHDULE_APPROVE[key];
      });
    },

    /**
     * (スケジュール)
     * 現在の現場を取得
     */
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    IS_OBAYASHI(){
      return this.ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
    all_record() {
      return this.searchParams.all_record_gate + this.searchParams.all_record_crane;
    },
    total_item() {
      return this.searchParams.total_item_gate + this.searchParams.total_item_crane;
    },
    totalPage() {
      return this.searchParams.totalPageGate >= this.searchParams.totalPageCrane ? this.searchParams.totalPageGate : this.searchParams.totalPageCrane;
    },
    isNoDataGate() {
      return !this.items?.devices || !this.items?.devices[0].device_id;
    },
    isNoDataCrane() {
      return !this.itemCranes?.devices || !this.itemCranes?.devices[0].device_id;
    },
  },

  methods: {
    async getWorkHourZones() {
      const response = await Store.dispatch(
        "PortalChart/getFieldTimeZones",
        this.searchParams.field_construction_id
      );
      this.listWorkHourZones = [...response.data.contents.entries];
    },

    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.page_number = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 検索
     */
    onSearch() {
      this.searchParams.page_number = 1;
      this.getItems();
    },

    formUpdate(params) {
      this.editedItem = { ...params };
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      if (name === "date") {
        searchParams[name] = format(new Date(value), "yyyy-MM-dd");
      } else {
        searchParams[name] = value;
      }
      this.searchParams = searchParams;
      if (name == "field_construction_id") {
        this.getWorkHourZones();
        this.callApiCheckIsDirector();
      }
      if (name == "work_hour_zone" || name == "date") {
        this.searchParams["page_number"] = 1;
        this.getItems();
      }
    },

    async callApiCheckIsDirector() {
      const params = {
        user_id: JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user.user_id,
        field_construction_id: this.searchParams.field_construction_id,
      };
      await Store.dispatch("Employees/checkIsDirector", { params });
    },

    /**
     * (共通)
     * 作成/編集フォーム
     * INITIAL_ITEM で定義した値がitemに入ります
     * @param INITIAL_ITEMで定義している値
     */
    openNewItemForm(typeAdd) {
      this.isGates = typeAdd === this.TYPE_ADD[0].id;
      let initial_item = this.initDataItems();
      this.editedItem = _.cloneDeep(initial_item);
      this.dataInitial = _.cloneDeep(initial_item);
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },
    initDataItems() {
      let initial_item = {};
      if (this.isGates) {
        initial_item = this.IS_OBAYASHI ? { ...this.SCHEDULE_GATE_INITIAL_ITEM_OBAYASHI } : {...this.SCHEDULE_GATE_INITIAL_ITEM};
        initial_item.passage_date_from = this.searchParams.date;
        initial_item.passage_date_to = this.searchParams.date;
        initial_item.field_construction_id = this.searchParams.field_construction_id;
        initial_item.work_hour_zone = this.searchParams.work_hour_zone;
      } else {
        this.getDataSelectCrane();
        initial_item = this.IS_OBAYASHI ? { ...this.INIT_DATA_OBAYASHI } : {...this.INIT_DATA};
        initial_item.use_date_from = this.searchParams.date;
        initial_item.use_date_to = this.searchParams.date;
        initial_item.field_construction_id = this.searchParams.field_construction_id;
        initial_item.work_hour_zone = this.searchParams.work_hour_zone;
      }
      return initial_item;
    },
    getDataSelectCrane() {
      let params = {
        field_id: this.CURRENT_SITE.field_id,
        field_construction_id: this.searchParams.field_construction_id
      };
      Store.dispatch("PortalChart/getListItemTrees", { params });
      Store.dispatch(
        "CompanyPortal/getListCompanyByField",
        this.CURRENT_SITE.field_id
      );
    },
    async openItemForm(schedule_id) {
      if (this.isGates) this.openItemFormGate(schedule_id);
      else this.openItemFormCrane(schedule_id);
      this.isNewItem = false;
      this.popups.isShowItemForm = true;
    },
    async openItemFormGate(schedule_id) {
      let params = {id: schedule_id};
      if (this.IS_OBAYASHI) params["is_oba"] = 1;
      const result = await Store.dispatch(`${STORE_GATE}/getDetail`, { params });
      // Show Detail Form
      if (!result.hasError) {
        this.editedItem = _.cloneDeep(result.data.contents.entries);
        if (this.IS_OBAYASHI) {
          this.editedItem["main_user_id"] = this.editedItem.device_users.length > 0 ? this.editedItem.device_users[0].field_director_id : null;
        }
        if (this.editedItem.device_work_classifications && this.editedItem.device_work_classifications.length == 0) {
          this.editedItem.device_work_classifications = [{ cmn_mst_work_classification_id: null }];
        }
        this.editedItem.device_usage_times = removeOneDayToEndTime(this.editedItem.device_usage_times);
      }
    },
    async openItemFormCrane(schedule_id) {
      const item = {
        schedule_id,
      };
      this.getDataSelectCrane();
      this.editedItem = { ...item };
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.$nextTick(() => {
        //選択を初期化
        this.editedItem = this.initDataItems();
        this.dataInitial = {};
      });
      this.getItems();
    },

    /**
     * true: 承認解除
     * false: 承認
     */
    openConfirmDialog(flg) {
      const approves = this.selectedItems.filter(
        (item) => item.schedule_approval == 1
      );
      this.isApproved = (approves.length == this.selectedItems.length && !flg) || (approves.length == 0 && flg);
      const {
        REJECT_BTN_SUBMIT,
        REJECT_TEXT2,
        REJECT_TEXT1,
        REJECT_TITLE,
        REJECT_ERROR_TEXT1,
        APPROVE_BTN_SUBMIT,
        APPROVE_TEXT2,
        APPROVE_TEXT1,
        APPROVE_TITLE,
        ALL_APPROVE_TTTLE,
        ALL_APPROVE_TEXT,
        BTN_OK,
      } = DIALOG_TEXT;
      if (this.isApproved) {
        this.dialogApproveText.title = flg ? REJECT_TITLE : ALL_APPROVE_TTTLE;
        this.dialogApproveText.text1 = flg
          ? REJECT_ERROR_TEXT1
          : ALL_APPROVE_TEXT;
        this.dialogApproveText.text2 = null;
        this.dialogApproveText.btnSubmitText = BTN_OK;
      } else {
        this.dialogApproveText.title = flg ? REJECT_TITLE : APPROVE_TITLE;
        this.dialogApproveText.text1 = flg ? REJECT_TEXT1 : APPROVE_TEXT1;
        this.dialogApproveText.text2 = flg ? REJECT_TEXT2 : APPROVE_TEXT2;
        this.dialogApproveText.btnSubmitText = flg
          ? REJECT_BTN_SUBMIT
          : APPROVE_BTN_SUBMIT;
      }
      this.dialogApproveText.flg = flg;
      this.popups.isShowConfirmDialog = true;
    },

    async onRemove() {
      const approves = this.selectedItems.filter(
        (item) => item.schedule_approval == 0
      );
      let statusRemoveGates = await this.removegates(approves.filter(e => e.isGates));
      let statusRemoveCranes = await this.removeCranes(approves.filter(e => !e.isGates));
      if (statusRemoveGates || statusRemoveCranes) {
        this.searchParams.page_number = 1;
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
      }
      this.closeRemoveDialog();
    },

    async removegates(approves) {
      const ids = approves.map((items) => items.id);
      if (ids.length === 0) return false;
      let params = this.IS_OBAYASHI ? { ids, is_oba: 1 } : { ids };
      const result = await Store.dispatch(`${STORE_GATE}/delete`,  params );
      return !result.hasError;
    },

    async removeCranes(approves) {
      const ids = approves.map((items) => items.id);
      if (ids.length === 0) return false;
      let params = this.IS_OBAYASHI ? { ids, is_oba: true } : { ids };
      const result = await Store.dispatch(`${STORE_CRANE}/delete`,  params );
      return !result.hasError;
    },

    /**
     * handleConfirm
     */
    handleConfirm() {
      if (this.dialogApproveText.flg) {
        this.rejectItems();
      } else {
        this.approveItems();
      }
    },

    openRemoveDialog() {
      const approves = this.selectedItems.filter(
        (item) => item.schedule_approval == 1
      );
      this.isApproved = approves.length == this.selectedItems.length;
      if (this.isApproved) {
        this.dialogRemoveText = {
          title: DIALOG_REMOVE_TEXT.TITLE,
          text1: DIALOG_REMOVE_TEXT.TEXT_ALL_APPROVE_1,
          text2: DIALOG_REMOVE_TEXT.TEXT_ALL_APPROVE_2,
          text3: null,
          text4: null,
          btnSubmitText: DIALOG_REMOVE_TEXT.BTN_OK,
        };
      } else {
        this.dialogRemoveText = {
          title: DIALOG_REMOVE_TEXT.TITLE,
          text1: DIALOG_REMOVE_TEXT.TEXT_1,
          text2: DIALOG_REMOVE_TEXT.TEXT_2,
          text3: DIALOG_REMOVE_TEXT.TEXT_3,
          text4: DIALOG_REMOVE_TEXT.TEXT_4,
          btnSubmitText: DIALOG_REMOVE_TEXT.BTN_SUBMIT,
        };
      }
      this.popups.isShowRemoveDialog = true;
    },

    /**
     * closeRemoveDialog
     */
    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
        this.reloadComponentList();
        this.dialogRemoveText = {
          title: "",
          text1: "",
          text2: "",
          text3: null,
          text4: null,
          btnSubmitText: "",
        };
      });
    },

    /**
     * closeApproveDialog
     */
    closeApproveDialog() {
      this.popups.isShowConfirmDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
        this.reloadComponentList();
        this.dialogApproveText = {
          title: "",
          text1: "",
          text2: "",
          btnSubmitText: "",
          flg: false,
        };
      });
    },

    /**
     * 親ステートの子テーブルを更新
     */
    async getChildItems(deviceId, pageNumber) {
      const params = {
        field_construction_id: this.searchParams.field_construction_id,
        field_tree_id: this.searchParams.field_tree_id,
        status_codes: this.searchParams.status_code,
        approvals: this.searchParams.approval,
        passage_date: this.searchParams.date,
        work_hour_zone: this.searchParams.work_hour_zone,
        page_number: pageNumber,
      };

      if (this.isGates) {
        params['gate_id'] = deviceId;
      } else {
        params['crane_id'] = deviceId;
        params["use_date"] = params.passage_date;
        delete params?.status_codes;
        delete params?.passage_date;
      }

      if (this.IS_OBAYASHI) {
        params["is_oba"] = this.isGates ? 1 : true;
      }
      if (this.isGates) await Store.dispatch(`${STORE_GATE}/loadChildren`, { params });
      else await Store.dispatch(`${STORE_CRANE}/loadChildren`, params);
    },

     getItems() {
      if (
        this.searchParams.field_construction_id &&
        this.searchParams.work_hour_zone &&
        this.searchParams.date
      ) {
        const params = {
          field_construction_id: this.searchParams.field_construction_id,
          field_tree_id: this.searchParams.field_tree_id,
          passage_date: this.searchParams.date,
          gate_id: this.searchParams.gate_id,
          crane_id: this.searchParams.crane_id,
          contain_without_plan: this.searchParams.contain_without_plan,
          status_codes: this.searchParams.status_code,
          approvals: this.searchParams.approval,
          page_number: this.searchParams.page_number,
          work_hour_zone: this.searchParams.work_hour_zone,
        };
        if (params.gate_id.length > 0 && params.crane_id.length == 0) params.crane_id = [0];
        if (params.gate_id.length == 0 && params.crane_id.length > 0) params.gate_id = [0];
        this.getListGates(params);
        this.getListCranes(params);
        this.reloadComponentList();
      }
    },
    /**
     * Call api get schedule gates
     */
    async getListGates(param) {
      let params = {...param};
      params['page_size'] = PAGE_SIZE_GATE;
      if (this.IS_OBAYASHI) params["is_oba"] = 1;
      delete params?.crane_id;
      await Store.dispatch(`${STORE_GATE}/get`, { params });
    },
    /**
     *  Call api get schedule cranes
     */
    async getListCranes(param) {
      let params = {...param};
      delete params?.gate_id;
      params['use_date'] = params.passage_date;
      params['page_size'] = PAGE_SIZE_CRANE;
      if (this.IS_OBAYASHI) params["is_oba"] = true;
      delete params?.passage_date;
      delete params?.status_codes;
      await Store.dispatch(`${STORE_CRANE}/get`, { params });
    },

    // 承認
    async approveItems() {
      const disapproves = this.selectedItems.filter(
        (item) => item.schedule_approval == 0
      );
      let statusApproveGate = await this.aprroveGates(disapproves.filter(e => e.isGates));
      let statusApproveCrane = await this.aprroveCranes(disapproves.filter(e => !e.isGates));

      if (statusApproveGate || statusApproveCrane) {
        //成功したら値を更新
        this.getItems();
        Store.dispatch("Notification/getNewNoticeStatus");
        Store.dispatch("Toast/show", {
          status: 200,
          message: "承認解除しました",
        });
      }
      this.closeApproveDialog();
    },

    async aprroveGates(approves) {
      const ids = approves.map((items) => items.id);
      if (ids?.length === 0) return false;
      let params = {
        ids: ids,
        field_construction_id: this.searchParams.field_construction_id,
      };
      if (this.IS_OBAYASHI) params["is_oba"] = 1;
      const result = await Store.dispatch(`${STORE_GATE}/approve`, params);
      return !result.hasError;
    },

    async aprroveCranes(approves) {
      const ids = approves.map((items) => items.id);
      if (ids?.length === 0) return false;
      let params = {
        ids: ids,
        field_construction_id: this.searchParams.field_construction_id,
      };
      if (this.IS_OBAYASHI) params["is_oba"] = true;
      const result = await Store.dispatch(`${STORE_CRANE}/approve`, params);
      return !result.hasError;
    },

    // 承認解除
    async rejectItems() {
      const disapproves = this.selectedItems.filter(
        (item) => item.schedule_approval == 1
      );
      let statusRejectGate = await this.rejectGates(disapproves.filter(e => e.isGates));
      let statusRejectCrane = await this.rejectCranes(disapproves.filter(e => !e.isGates));

      if (statusRejectGate || statusRejectCrane) {
        //成功したら値を更新
        this.getItems();
        Store.dispatch("Notification/getNewNoticeStatus");
        Store.dispatch("Toast/show", {
          status: 200,
          message: "承認解除しました",
        });
      }
      this.closeApproveDialog();
    },

    async rejectGates(approves) {
      const ids = approves.map((items) => items.id);
      if (ids?.length === 0) return false;
      let params = {
        ids: ids,
        field_construction_id: this.searchParams.field_construction_id,
      };
      if (this.IS_OBAYASHI) params["is_oba"] = 1;
      const result = await Store.dispatch(`${STORE_GATE}/reject`, params);
      return !result.hasError;
    },

    async rejectCranes(approves) {
      const ids = approves.map((items) => items.id);
      if (ids?.length === 0) return false;
      let params = {
        ids: ids,
        field_construction_id: this.searchParams.field_construction_id,
      };
      if (this.IS_OBAYASHI) params["is_oba"] = true;
      const result = await Store.dispatch(`${STORE_CRANE}/disapprove`, params);
      return !result.hasError;
    },

    /**
     * (共通)
     * 新規登録 / 更新
     * - idがある場合は更新
     */
    async submitForm() {
      let _editedItem = _.cloneDeep(this.editedItem);
      const hasId = "id" in _editedItem;
      if (this.IS_OBAYASHI) {
        _editedItem["is_oba"] = 1;
      }
      _editedItem.device_usage_times = addOneDayToEndTime(_editedItem.device_usage_times);
      const result = await Store.dispatch(
        //idがある場合は更新、ない場合は新規
        hasId ? `${STORE_GATE}/update` : `${STORE_GATE}/post`,
        _editedItem
      );
      //レスポンスエラーがある場合はフォームを閉じない
      //エラーメッセージは、api.jsが表示
      if (result.hasError) {
        this.isErrorSubmit = true;
        return;
      } else if (hasId) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        let params = { id: this.editedItem.id };
        if (this.IS_OBAYASHI) {
          params["is_oba"] = 1;
        }
        const resultDetail = await Store.dispatch(`${STORE_GATE}/getDetail`, { params });
        this.editedItem = _.cloneDeep({
          ...resultDetail.data.contents.entries,
        });
        if (this.IS_OBAYASHI) {
          this.editedItem["main_user_id"] = this.editedItem.device_users.length > 0 ? this.editedItem.device_users[0].field_director_id : null;
        }
        if (
          this.editedItem.device_work_classifications &&
          this.editedItem.device_work_classifications.length == 0
        ) {
          this.editedItem.device_work_classifications = [
            { cmn_mst_work_classification_id: null },
          ];
        }
        this.editedItem.device_usage_times = removeOneDayToEndTime(this.editedItem.device_usage_times);
        this.isErrorSubmit = false;
        this.closeItemForm();
      } else {
        //成功したら値を更新
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
        this.closeItemForm();
      }
      this.getItems();
      this.isEmitted = !this.isEmitted;
    },

    /**
     * checkboxの値からselectedItemsを更新
     */
    updateSelectedItems(list) {
      list.forEach((item) => {
        this.updateSelectedItem(item);
      });
    },
    updateSelectedItem({ id, isChecked, schedule_approval }) {
      let _selectedItems = [...this.selectedItems];
      // checked
      if (isChecked) {
        const isNew = this.selectedItems.some((item) => item.id == id);
        if (!isNew)
          _selectedItems = [..._selectedItems, { id, schedule_approval, isGates: this.isGates }];
      } else {
        // unchecked
        _selectedItems = this.selectedItems.filter((item) => {
          return item.id != id;
        });
      }
      // 重複削除してset
      this.selectedItems = [...new Set(_selectedItems)];
    },

    // 親にchild table追加
    addNewChild(parentId) {
      // 新規追加itemに parent idをセット
      this.editedItem.schedule_parent_id = parentId;
      this.openNewItemForm();
    },

    async showSettingPlan() {
      this.popups.openSettingPlanForm = true;
      let params = {
        field_id: this.CURRENT_SITE.field_id,
        company_type: this.IS_OBAYASHI ? 1: 0
      };
      await Store.dispatch("Customizes/get", { params });
    },

    reloadComponentList() {
      this.reloadList = !this.reloadList;
      this.reloadListCrane = this.reloadListCrane === 0 ? 1 : 0;
    },
    resetFormSearch(event) {
      event.preventDefault();
      this.isReset = !this.isReset
      this.searchParams.field_tree_id = null;
      this.searchParams.gate_id = [];
      this.searchParams.crane_id = [];
      this.searchParams.status_code = [];
      this.searchParams.approval = [];
      this.searchParams.page_number = 1;
      this.searchParams.pageCount = PAGE_COUNT
      this.searchParams.contain_without_plan = 1
      this.searchParams.work_hour_zone = null
    }
  },
  beforeDestroy() {
    Store.dispatch(`${STORE_CRANE}/resetData`);
    Store.dispatch(`${STORE_GATE}/resetData`);
    Store.dispatch(`Employees/resetData`);
  }
};
</script>
<style lang="scss" scoped>
.tableSortWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 64px;
  .sortLabel {
    font-size: 12px;
    height: 32px;
    margin-right: 16px;
  }
  .sortElement {
    max-width: 168px;
    margin-right: 24px;
  }
  .total_item {
    padding-bottom: 15px;
  }
}
.schedule_nodata {
  position: absolute;
  left: 50%;
}
.title_label{
  color: #1B9C4F;
  font-weight: bold;
  margin-left: 10px;
  font-size: 1.5em;
}
</style>
