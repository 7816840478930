<template>
  <v-card class="dialog">
    <v-card-title>
      <div class="title">{{ title }}</div>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div class="text">{{ title_radio }}</div>
      </div>
      <RadioGroup
        name="status"
        :values="formValues"
        :items="flagEstimate ? RESULT_BILL_STATUS_ESTIMATE : RESULT_BILL_STATUS_ORDER"
        :editable="true"
        @onInput="onInput"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="primary"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn :key="flagHandleConfirm" depressed class="btn" color="primary" @click.once="handleConfirm">
        確定
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import RadioGroup from "@/components/forms/elements/RadioGroup.vue";
import { RESULT_BILL_STATUS_ESTIMATE, RESULT_BILL_STATUS_ORDER } from "@/constants/DEVICE_COORDINATOR";

export default {
  data() {
    return {
      formValues: {
        status: 0
      },
      RESULT_BILL_STATUS_ESTIMATE,
      RESULT_BILL_STATUS_ORDER
    };
  },
  components: {
    RadioGroup
  },
  computed: {
    title() {
      return this.flagEstimate ? "見積回答" : "発注回答";
    },
    title_radio() {
      return this.flagEstimate ? "見積依頼された機械について回答します。" : "発注依頼された機械について回答します。";
    }
  },
  props: {
    flagHandleConfirm: Boolean,
    flagEstimate: Boolean
  },
  methods: {
    handleConfirm() {
      this.$emit("yes", this.formValues.status);
    },
    onInput({ value, name }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
    }
  }
};
</script>
<style scoped>
.title {
  color: #1B9C4F
}
</style>
