<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-radio-group class="ma-5" v-model="radioChecked">
        <v-row dense class="ml-5">
          <v-col v-for="card in items" :key="card.id" cols="auto">
            <v-card width="260px" height="130px">
              <v-row class="mt-5" style="height: 100%">
                <v-col cols="12" sm="5" md="5" class="image_col">
                  <img :src="getImageSrc(card.id)" style="max-width: 50px;max-height: 50px" />
                </v-col>
                <v-col cols="12" sm="4" md="4" class="gg">
                  {{ card.type }}
                </v-col>
                <v-col cols="12" sm="3" md="3" class="information_col" style="text-align: end">
                  <v-radio style="justify-content: end" :value="card.id" @click="clickRadio(card.id)"></v-radio>
                  <div style="justify-content: end">
                    <v-icon class="mr-2" v-if="card.childrens && Array.isArray(card.childrens) && card.childrens.length > 0" @click="openChildrensLevel(card.id)">mdi-chevron-down</v-icon>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-radio-group>
    </div>
  </div>
</template>
<script>
export default {
  name: 'test',
  data: () => ({
    radioChecked : null,
  }),
  props: {
    mainHeight: {
      type: Number,
      default: 0,
    },
    items: Array,
    level: Number,
    checkedArray : Array,
    imageMap: Map,
  },
  mounted() {
    this.$watch(
      () => [this.level,this.checkedArray],
      (newValue) => {
        this.radioChecked = newValue[1][newValue[0] - 1];
      },
      {
        immediate : true,
        deep: true
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight - 15}px;`;
      return style;
    },
  },
  methods: {
    openChildrensLevel(id) {
      if(this.checkedArray[this.level - 1] == id){
        this.$emit("openChildrensLevel", id , false);
      }else{
        this.$emit("openChildrensLevel", id , true);
      }
    },
    clickRadio(id){
      if (this.checkedArray[this.level - 1] != id) {
        this.$emit("updateChecked", id);
      }
    },
    getImageSrc(id){
      return this.imageMap.get(id)
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";

.image_col {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.information_col {
  padding-top: 0;
  height: 100%;
}

.entering {
  background-color: green;
  border-radius: 0px 0px 0px 20px;
  text-align: center;
  height: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text_company_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: gray
}

.text_user_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text_entering {
  color: white;
  line-height: 1.5vw
}

.gg {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-height: 1.2em; /* fallback */
  max-height: 4.5em;
  align-self: center;
}
</style>
