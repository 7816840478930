<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-list-item v-if="!editable">
                    <v-list-item-content>
                      <Label label="禁止期間" :editable="editable">
                        <v-text-field
                          v-model="dateComplete"
                          dense
                          color="primary"
                          :disabled="!editable"
                          :filled="editable"
                        ></v-text-field>
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-row v-if="editable" class="mt-0">
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label
                            label="禁止期間（開始）"
                            :editable="editable"
                            :required="isRequireStartDate"
                          >
                            <InputDatepicker
                              name="prohibit_start_date"
                              :values="item"
                              :editable="editable"
                              validation_label="禁止期間（開始）"
                              :validation_rules="getStartDateRules"
                              @onInput="onInput"
                              :flagNull="true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label
                            label="禁止期間（終了）"
                            :editable="editable"
                            :required="isRequireEndDate"
                          >
                            <InputDatepicker
                              name="prohibit_end_date"
                              :values="item"
                              :editable="editable"
                              validation_label="禁止期間（終了）"
                              :validation_rules="getEndDateRules"
                              @onInput="onInput"
                              :flagNull="true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                  <DeviceProhibitTimesInput
                    name="device_prohibit_times"
                    :values="formValues"
                    :editable="editable"
                    @onInput="onInput"
                  />

                  <v-list-item>
                    <v-list-item-content>
                      <Label label="備考">
                        <InputTextArea
                          name="memo"
                          :values="formValues"
                          :editable="editable"
                          validation_label="備考"
                          validation_rules="max:512"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <SwitchInput
                        name="monday_flg"
                        :label="`月曜日`"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput"
                      />
                      <SwitchInput
                        name="tuesday_flg"
                        :label="`火曜日`"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput"
                      />
                      <SwitchInput
                        name="wednesday_flg"
                        :label="`水曜日`"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput"
                      />
                      <SwitchInput
                        name="thursday_flg"
                        :label="`木曜日`"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput"
                      />
                      <SwitchInput
                        name="friday_flg"
                        :label="`金曜日`"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput"
                      />
                      <SwitchInput
                        name="saturday_flg"
                        :label="`土曜日`"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput"
                      />
                      <SwitchInput
                        name="sunday_flg"
                        :label="`日曜日`"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput"
                      />
                      <SwitchInput
                        name="public_holiday_flg"
                        :label="`祝日`"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import Label from "@/components/forms/elements/Label";
import InputText from "@/components/forms/elements/InputText";
import InputTextArea from "@/components/forms/elements/InputTextArea";
import SwitchInput from "@/components/forms/elements/SwitchInput";
import InputDatepicker from "@/components/forms/elements/InputDatepicker";
import DeviceProhibitTimesInput from "./components/DeviceProhibitTimesInput.vue";
import { fmtSlashDate } from "@/utils/timeUtil";

export default {
  data: () => {
    return {
      formValues: {},
      dateComplete: "",
    };
  },
  components: {
    ValidationObserver,
    Label,
    InputText,
    InputTextArea,
    InputDatepicker,
    SwitchInput,
    DeviceProhibitTimesInput,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (data) => {
        this.initData(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      const headerHeight = 48;
      let style = `height:${this.mainHeight - headerHeight}px;`;
      return style;
    },
    getStartDateRules() {
      return this.formValues.prohibit_end_date
        ? "required|" +
            `compare-to-end-date:${this.formValues.prohibit_end_date.replaceAll(
              "-",
              "/"
            )}`
        : "";
    },
    getEndDateRules() {
      return this.formValues.prohibit_start_date
        ? "required|" +
            `compare-to-start-date:${this.formValues.prohibit_start_date.replaceAll(
              "-",
              "/"
            )}`
        : "";
    },
    isRequireStartDate() {
      return !this.formValues.prohibit_start_date && this.formValues.prohibit_end_date !== null;
    },
    isRequireEndDate() {
      return this.formValues.prohibit_start_date !== null && !this.formValues.prohibit_end_date;
    },
  },
  methods: {
    initData(data) {
      // get form value by this.item
      const formValues = data;
      this.formValues = { ...formValues };
      this.initDataTime(this.formValues);
    },
    initDataTime(formValues) {
      const startDate = formValues["prohibit_start_date"]
        ? fmtSlashDate(formValues["prohibit_start_date"]).date
        : "";
      const endDate = formValues["prohibit_end_date"]
        ? fmtSlashDate(formValues["prohibit_end_date"]).date
        : "";
      if (startDate || endDate) {
        this.dateComplete = `${startDate}〜${endDate}`;
      } else {
        this.dateComplete = "";
      }
    },
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";
</style>
