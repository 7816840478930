<template>
  <ul class="editmenu px-1">
    <li>
      <button class="editmenu_item" :disabled="!selectedId" @click="onCopy">
        <v-icon class="mb-1">mdi-content-copy</v-icon>
        <br />コピー
      </button>
    </li>
    <li>
      <button class="editmenu_item" :disabled="!copyItem" @click="onPaste">
        <v-icon class="mb-1">mdi-content-paste</v-icon>
        <br />ペースト
      </button>
    </li>
    <li>
      <button class="editmenu_item" @click="onToFront">
        <v-icon class="mb-1">mdi-flip-to-front</v-icon>
        <br />前へ
      </button>
    </li>
    <li>
      <button class="editmenu_item" @click="onToBack">
        <v-icon class="mb-1">mdi-flip-to-back</v-icon>
        <br />後へ
      </button>
    </li>
    <li>
      <button class="editmenu_item" :disable="!enableZoomOut" @click="zoomOut">
        <v-icon class="mb-1">mdi-magnify-minus-outline</v-icon>
        <br />縮小
      </button>
    </li>
    <li>
      <button class="editmenu_item" :disable="!enableZoomIn" @click="zoomIn">
        <v-icon class="mb-1">mdi-magnify-plus-outline</v-icon>
        <br />拡大
      </button>
    </li>
    <li>
      <button class="editmenu_item" :disabled="!selectedId" @click="onDelete">
        <v-icon class="mb-1">mdi-delete-outline</v-icon>
        <br />削除
      </button>
    </li>
    <li>
      <button class="editmenu_item" :disabled="!canUndo" @click="undo">
        <v-icon class="mb-1">mdi-undo</v-icon>
        <br />取り消し
      </button>
    </li>
    <li>
      <button class="editmenu_item" :disabled="!canRedo" @click="redo">
        <v-icon class="mb-1">mdi-redo</v-icon>
        <br />やり直し
      </button>
    </li>
  </ul>
</template>
<script>
import {
  getUniqueId,
  getMaxZindex,
} from "@/views/oekaki/components/util/Util.js";
import { UndoRedo } from "../../util/UndoRedo";
import {
  KeyCapture,
  KEY_ACTIONS,
} from "@/views/oekaki/components/util/KeyCapture.js";
import { Store } from "@/store/Store.js";

export default {
  props: {
    items: {
      type: Array,
    },
    // selectedId: {
    //   type: Number,
    // },
  },
  data: () => {
    return {
      canUndo: false,
      canRedo: false,
      copyItem: null,
    };
  },
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    UndoRedo.setStatusCallback(({ canUndo, canRedo }) => {
      this.canUndo = canUndo;
      this.canRedo = canRedo;
    });

    // キーボード入力
    KeyCapture.init();
    KeyCapture.setCallback((key) => {
      //undo
      if (key === KEY_ACTIONS.COPY) {
        this.onCopy();
      }
      //redo
      if (key === KEY_ACTIONS.PASTE) {
        this.onPaste();
      }
      //delete
      if (key === KEY_ACTIONS.BACKSPACE) {
        this.onDelete();
      }
    });
  },
  computed: {
    enableZoomIn() {
      return Store.getters["Oekaki/enableZoomIn"];
    },
    enableZoomOut() {
      return Store.getters["Oekaki/enableZoomOut"];
    },
    selectedId() {
      return Store.getters["Oekaki/getSelectedItemId"];
    },
  },
  destroyed() {
    KeyCapture.destroy();
  },
  methods: {
    zoomIn() {
      Store.dispatch("Oekaki/addZoom", 0.1);
    },
    zoomOut() {
      Store.dispatch("Oekaki/addZoom", -0.1);
    },
    /**
     * コピー
     */
    onCopy() {
      //copy
      let items = JSON.parse(JSON.stringify(this.items));
      let target = items.find((item) => {
        return item?.id === this.selectedId;
      });
      const _target = JSON.parse(JSON.stringify(target));
      this.copyItem = _target;
    },

    onPaste() {
      // 新しいidを設定
      const id = getUniqueId(this.items);
      const _target = JSON.parse(JSON.stringify(this.copyItem));
      _target.id = id;
      let items = JSON.parse(JSON.stringify(this.items));
      items.push(_target);
      this.$emit("on-edit-items", items);
      this.$nextTick(() => {
        Store.dispatch("Oekaki/selectItem", _target.id);
      });
    },

    /**
     * 最下層へ
     */
    onToBack() {
      let z = getMaxZindex(this.items);
      this.items.forEach((item) => {
        if ("z_index" in item.transform && z > item.transform.z_index) {
          z = item.transform.z_index;
        }
      });
      let items = JSON.parse(JSON.stringify(this.items));
      items = items.map((item) => {
        if (item.id === this.selectedId) {
          item.transform.z_index = z - 1 < 0 ? 0 : z - 1;
          return item;
        }
        return item;
      });
      this.$emit("on-edit-items", items);
    },

    /**
     * 最上層へ
     */
    onToFront() {
      let z = getMaxZindex(this.items);
      let items = JSON.parse(JSON.stringify(this.items));
      items = items.map((item) => {
        if (item.id === this.selectedId) {
          let _item = { ...item };
          _item.transform["z_index"] = z + 1;
          return _item;
        }
        return item;
      });
      this.$emit("on-edit-items", items);
    },

    /**
     * 削除
     */
    onDelete() {
      const items = [...this.items];
      const index = this.items.findIndex((item) => {
        return item.id === this.selectedId;
      });
      if (index === -1) return;
      items.splice(index, 1);
      this.$emit("on-edit-items", items);
    },
    undo() {
      UndoRedo.undo();
    },
    redo() {
      UndoRedo.redo();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/views/oekaki/components/assets/scss/editmenu.scss";
.editmenu {
  position: fixed;
  bottom: 8px;
  left: calc(50% - 128px);
  z-index: 1000;
}
</style>
