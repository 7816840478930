<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="最大作業半径" :editable="editable">
                            <InputText
                              name="maximum_working_radius"
                              :values="formValues"
                              :editable="editable"
                              placeholder="10"
                              suffix="m"
                              validation_label="最大作業半径"
                              validation_rules="decimal"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label
                            label="最大作業半径時の定格荷重"
                            :editable="editable"
                          >
                            <InputText
                              name="maximum_rated_load"
                              :values="formValues"
                              :editable="editable"
                              placeholder="3.8"
                              suffix="t"
                              validation_label="最大作業半径時の定格荷重"
                              validation_rules="decimal"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="ブーム長さ" :editable="editable">
                            <InputText
                              name="boom_length"
                              :values="formValues"
                              :editable="editable"
                              placeholder="38.7"
                              suffix="m"
                              validation_label="ブーム長さ"
                              validation_rules="decimal"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label
                            label="アウトリガ張出寸法"
                            :editable="editable"
                          >
                            <InputText
                              name="outrigger_overhang_dimensions"
                              :values="formValues"
                              :editable="editable"
                              placeholder="1.69"
                              suffix="mm"
                              validation_label="アウトリガ張出寸法"
                              validation_rules="decimal"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="駆動方式" :editable="editable">
                            <Select
                              name="cmn_mst_crane_drive_system_id"
                              :values="formValues"
                              :editable="editable"
                              item_text="drive_system"
                              :items="cmn_mst_crane_drive_systems"
                              validation_label="駆動方式"
                              validation_rules=""
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col> </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="最大吊上げ荷重" :editable="editable">
                            <InputText
                              name="maximum_lifting_load"
                              :values="formValues"
                              :editable="editable"
                              placeholder="2.79"
                              suffix="t"
                              validation_label="最大吊上げ荷重"
                              validation_rules="decimal"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="最大地上揚程" :editable="editable">
                            <InputText
                              name="maximum_ground_lift"
                              :values="formValues"
                              :editable="editable"
                              placeholder="7.50"
                              suffix="m"
                              validation_label="最大地上揚程"
                              validation_rules="decimal"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="主フック重量" :editable="editable">
                            <InputText
                              name="main_hook_weight"
                              :values="formValues"
                              :editable="editable"
                              placeholder="1.00"
                              suffix="t"
                              validation_label="主フック重量"
                              validation_rules="decimal"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="補フック重量" :editable="editable">
                            <InputText
                              name="supplementary_hook_weight"
                              :values="formValues"
                              :editable="editable"
                              placeholder="0.75"
                              suffix="t"
                              validation_label="補フック重量"
                              validation_rules="decimal"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <SectionLabel
                    label="資格・免許"
                    :editable="editable"
                    class="mt-6 ml-4"
                  />
                  <DeviceLicsensesInput
                    name="device_licences"
                    :values="formValues"
                    :editable="editable"
                    @onInput="onInput"
                  />
                  <SectionLabel
                    label="特別教育"
                    :editable="editable"
                    class="mt-6 ml-4"
                  />
                  <DeviceSpTrainningsInput
                    name="device_sp_trainings"
                    :values="formValues"
                    :editable="editable"
                    @onInput="onInput"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputNumber from "@/components/forms/elements/InputNumber";
import Select from "@/components/forms/elements/Select";
import InputText from "@/components/forms/elements/InputText";

import DeviceLicsensesInput from "./components/DeviceLicsensesInput.vue";
import DeviceSpTrainningsInput from "./components/DeviceSpTrainningsInput.vue";
import { Store } from "@/store/Store.js";
export default {
  data: () => {
    return {
      formValues: {},
      cmn_mst_crane_drive_systems:
        [{ id: null, drive_system: "" },...Store.state.CmnMst.constants.entries.cmn_mst_crane_drive_systems],
    };
  },
  components: {
    ValidationObserver,
    Label,
    InputNumber,
    DeviceLicsensesInput,
    DeviceSpTrainningsInput,
    Select,
    InputText,
    SectionLabel
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      const headerHeight = 48;
      let style = `height:${this.mainHeight - headerHeight}px;`;
      return style;
    },
  },
  methods: {
    onUpadte() {
      this.$emit("formUpdate", {});
    },
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";
</style>
