<template>
  <v-card class="dialog">
    <v-card-title>
      <div :style="getTitleColorStyle" class="title">{{ title }}</div>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div class="text">{{ text }}</div>
      </div>
    </v-card-text>
    <v-card-text>
      <div class="text" v-if="flagTab3">
        <span class="item" v-for="item in items" :key="item.id">
          {{ item.order_number }}</span
        >
      </div>
      <div class="text" v-else-if="flagTab2">
        <span class="item" v-for="item in items" :key="item.id">
          {{ item.estimate_number }}</span
        >
      </div>
      <div class="text" v-else>
        <span class="item" v-for="item in items" :key="item.id">
          {{ convertMachineName(item.machine_name) }}</span
        >
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="warning"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn
        depressed
        class="btn"
        color="warning"
        :key="flagError"
        @click.once="onSubmit"
      >
        削除
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  data() {
    return {
      items: [],
    }
  },
  mounted(){
    this.$watch(
      () => this.selectedItems,
      (newValue) => {
        if(newValue){
          this.items = newValue.filter(e => e.machine_name)
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  props: {
    text: {
      type: String,
    },
    title: {
      type: String,
    },
    selectedItems: {
      type: Array,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    flagTab2: {
      type: Boolean,
      default: false,
    },
    flagTab3: {
      type: Boolean,
      default: false,
    },
    flagError: Boolean,
  },
  computed: {
    getTitleColorStyle() {
      return this.warning ? "color:#FF7B52;" : "";
    },
    getColor() {
      return this.warning ? "warning" : "primary";
    },
  },
  methods: {
    convertMachineName(machineName) {
      let result = machineName || "";
      if (machineName) {
        if (machineName.length > 16) {
          result = machineName.substring(0, 16) + "•••";
        }
      }
      return result;
    },
    async onSubmit() {
      this.$emit('yes');
    }
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .title {
    font-size: 12px;
  }
  .body {
    .text-wrap {
      .text {
        text-align: center;
        margin: auto;
        width: 80%;
      }
    }
  }
  .item {
    &:after {
      content: ",";
    }
    &:last-child {
      &:after {
        content: "";
      }
    }
  }
}
</style>
