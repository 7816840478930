import Vue from "vue";
import Vuex from "vuex";
import { chart } from "@/api/modules/portalChart";
import { CONSTRUCTION_CONTRACT_INFO } from "@/constants/PORTAL_CHART.js";

Vue.use(Vuex);

/**
 * @description  施工体制図 全体のイベントの管理
 *
 * - 選択している工事の状態管理
 * - 元請け/協力会社ポップアップの状態管理
 * - 招待フォームの状態管理
 */

export const PortalChart = {
  namespaced: true,

  state: {
    //体制図データ
    companies: [],
    //現場の工事一覧
    workTypes: [],
    //選択している工事
    workType: {},
    //元請けポップアップ
    selectedOwnerCompany: {
      companyId: null,
      companyName: null,
      chartId: null,
      formTab: null,
      state_handling_flag: null,
      dataDetail: null,
      level: null,
    },
    //開く会社ポップアップ
    selectedCompany: {
      companyId: null,
      companyName: null,
      chartId: null,
      formTab: null,
      state_handling_flag: null,
      dataDetail: null,
      level: null,
    },
    //１次請け招待フォーム
    inviteFromOwner: { chartId: null },
    //２次請け以下招待フォーム
    inviteFromRelated: { chartId: null },
    relatedCompany: {}, // TODO
    ownerCompany: {},
    field_tree: {}, // TODO
    detail_user: {},
    companyBranchesId: null,
    delete_chart: {},
    listGroup: [],
    listCompanyPartner: [],
    listCompanyPartnerUser: [],
    listTimeZones: [],
    listDirectors: [],
    listItemTrees: [],
    workingTime: null,
    endWorkChart: {},
    restartWorkChart: {},
    exclude_company_id: null,
    changeGroupWork: {},
    setManageUser: {},
    list_company_partner_level_1: [],
    all_list_company: [],
    list_user_from_company_partner_level_1: [],
    list_skills: [],
    list_skill_and_cmn_mst_skill_ids: [],
    list_directors_obayashi: [],
    field_tree_csv: {},
    // チャット用
    chat_field_tree_id: null,
    listFieldTreeForeman: [],
  },

  mutations: {
    SET_COMPANIES(state, payload) {
      state.companies = payload;
    },
    SET_WORKTYPES(state, payload) {
      state.workTypes = payload;
    },
    SET_FIELD_TREE(state, payload) {
      state.field_tree = payload;
    },
    SET_RELATED_COMPANY(state, payload) {
      state.relatedCompany = payload;
    },
    SET_OWNER_COMPANY(state, payload) {
      state.ownerCompany = payload;
    },
    SET_DETAIL_USER_PARTNER(state, payload) {
      state.detail_user = payload;
    },
    SET_FIELD_TREE_CSV(state, payload) {
      state.field_tree_csv = payload;
    },
    OPEN_OWNER_COMPANY(state, payload) {
      state.selectedOwnerCompany = { ...payload };
      state.selectedCompany = {
        companyId: null,
        companyName: null,
        chartId: null,
        formTab: null,
        state_handling_flag: null,
        dataDetail: null,
        level: null,
      }; //協力会社は閉じる
    },
    OPEN_RELATED_COMPANY(state, payload) {
      state.selectedCompany = { ...payload };
      state.selectedOwnerCompany = {
        companyId: null,
        companyName: null,
        chartId: null,
        formTab: null,
        state_handling_flag: null,
        dataDetail: null,
        level: null,
      }; //元請けは閉じる
    },
    INVITE_FROM_OWNER(state, payload) {
      state.inviteFromOwner = payload;
    },
    INVITE_FROM_RELATED(state, payload) {
      state.inviteFromRelated = payload;
    },
    SET_COMPANY_BRANCH_ID(state, payload) {
      state.companyBranchesId = payload;
    },
    DELETE_CHART(state, payload) {
      state.delete_chart = payload;
    },
    SET_LIST_GROUP(state, payload) {
      state.listGroup = payload;
    },
    SET_LIST_COMPANY_PARTNER_USER(state, payload) {
      state.listCompanyPartnerUser = payload;
    },
    SET_LIST_TIME_ZONES(state, payload) {
      state.listTimeZones = payload;
    },
    SET_LIST_DIRECTORS(state, payload) {
      state.listDirectors = payload;
      state.list_directors_obayashi = payload;
    },
    SET_LIST_COMPANY_PARTNER(state, payload) {
      state.listCompanyPartner = payload;
    },
    SET_LIST_ITEM_TREES(state, payload) {
      state.listItemTrees = payload;
    },
    SET_WORKING_TIME(state, payload) {
      state.workingTime = payload;
    },
    END_WORK_CHART(state, payload) {
      state.endWorkChart = payload;
    },
    RESTART_WORK_CHART(state, payload) {
      state.restartWorkChart = payload;
    },
    SET_EXCLUDE_COMPANY_ID(state, payload) {
      state.exclude_company_id = payload;
    },
    CHANGE_GROUP_WORK(state, payload) {
      state.changeGroupWork = payload;
    },
    SET_MANAGE_USER(state, payload) {
      state.setManageUser = payload;
    },
    SET_LIST_COMPANY_PARTNER_LEVEL_1(state, payload) {
      state.list_company_partner_level_1 = payload;
    },
    SET_ALL_LIST_COMPANY(state, payload) {
      state.all_list_company = payload;
    },
    SET_LIST_USER_FROM_COMPANY_PARTNER_LEVEL_1(state, payload) {
      state.list_user_from_company_partner_level_1 = payload;
    },
    SET_LIST_SKILLS(state, payload) {
      state.list_skills = payload;
    },
    SET_LIST_SKILL_AND_CMN_MST_SKILL_IDS(state, payload) {
      state.list_skill_and_cmn_mst_skill_ids = payload;
    },
    CLEAR_LIST_FIELD_DIRECTORS(state) {
      state.listDirectors = [];
      state.list_directors_obayashi = [];
    },
    SET_CHAT_FIELD_TREE_ID(state, payload) {
      state.chat_field_tree_id = payload;
    },
    SET_FIELD_TREE_FOREMAN(state, payload) {
      state.listFieldTreeForeman = payload;
    }
  },

  actions: {
    /**
     * 体制図を取得
     */
    async getCompanies({ commit }, payload) {
      const response = await chart.get(payload);
      const { entries } = response.data.contents;
      const companies = entries?.field_tree;
      if (entries?.field_tree_children) {
        companies["field_tree_children"] = entries?.field_tree_children;
      }
      commit("SET_COMPANIES", companies);
    },

    /**
     * 工事一覧を取得
     */
    async getWorkTypes({ commit }, payload) {
      const response = await chart.getWorkTypes(payload);
      const work_types = response.data.contents;
      commit("SET_WORKTYPES", work_types.entries);
      return response;
    },
    /**
     * TODO
     */
    async getDetailUserPartner({ commit }, payload) {
      const response = await chart.getDetailUserPartner(payload);
      const { entries } = response.data.contents;
      commit("SET_DETAIL_USER_PARTNER", entries);
    },

    /**
     * get ownerCompany
     */
    async getOwnerCompany({ commit }, payload) {
      const response = await chart.getOwnerCompany(payload);
      const { entries } = response.data.contents;
      if (entries?.site_director?.company_users) {
        entries?.site_director?.company_users.forEach((user) => {
          user["user_id"] = user["id"];
        });
      }
      commit("SET_OWNER_COMPANY", entries);
    },
    /**
     * get relatedCompany
     */
    async getRelatedCompany({ commit }, payload) {
      const response = await chart.getRelatedCompany(payload);
      const relatedCompany = response.data.contents.entries;
      if (!relatedCompany.construction_contract_info) {
        relatedCompany.construction_contract_info = {
          ...CONSTRUCTION_CONTRACT_INFO,
        };
      }
      relatedCompany?.construction_permit?.field_tree_construction_permits.forEach(
        (element) => {
          if (!element.cmn_mst_construction_permit_ids)
            element.cmn_mst_construction_permit_ids = [];
        }
      );
      commit("SET_RELATED_COMPANY", relatedCompany);
    },

    /**
     * updateRelatedCompany
     */
    async updateRelatedCompany(_context, payload) {
      return await chart.updateRelatedCompany(payload);
    },

    async getListCompanyPartners(_context, payload) {
      return await chart.getListCompanyPartner(payload);
    },

    /**
     * updateOwnerCompany
     */
    async updateOwnerCompany(_context, payload) {
      return await chart.updateOwnerCompany(payload);
    },
    async updateDetailUserPartner(_context, payload) {
      return await chart.updateDetailUserPartner(payload);
    },

    /**
     * delete chart
     */
    async deleteFieldTreePartner(_context, payload) {
      return await chart.deleteFieldTreePartner(payload);
    },

    /**
     * @description 元請けダイアログを開く
     */
    openOwnerCompany({ commit }, payload) {
      commit("OPEN_OWNER_COMPANY", payload);
    },
    /**
     * @description 1次以下協力会社ダイアログを開く
     */
    openRelatedCompany({ commit }, payload) {
      commit("OPEN_RELATED_COMPANY", payload);
    },

    /**
     *
     * @description 1次以下協力会社う招待フォームを開く
     */
    inviteFromOwner({ commit }, payload) {
      commit("INVITE_FROM_OWNER", payload);
    },

    /**
     *
     * @description ２次以下協力会社う招待フォームを開く
     */
    inviteFromRelated({ commit }, payload) {
      commit("INVITE_FROM_RELATED", payload);
    },

    endWorkChart({ commit }, payload) {
      commit("END_WORK_CHART", payload);
    },

    restartWorkChart({ commit }, payload) {
      commit("RESTART_WORK_CHART", payload);
    },

    setExcludeCompanyId({ commit }, payload) {
      commit("SET_EXCLUDE_COMPANY_ID", payload);
    },

    isChangeGroupWork({ commit }, payload) {
      commit("CHANGE_GROUP_WORK", payload);
    },

    isSetManageUser({ commit }, payload) {
      commit("SET_MANAGE_USER", payload);
    },

    /**
     *
     * @description 招待を送る
     */
    async sendInvite(_context, payload) {
      return await chart.sendInvite(payload);
    },

    setCompanyBranchId({ commit }, payload) {
      commit("SET_COMPANY_BRANCH_ID", payload);
    },

    deleteChart({ commit }, payload) {
      commit("DELETE_CHART", payload);
    },

    async getListGroups({ commit }, payload) {
      const response = await chart.getListGroups(payload);
      const listGroup = response.data.contents;
      commit("SET_LIST_GROUP", listGroup.entries);
      return response;
    },

    async getListCompanyPartner({ commit }, payload) {
      const response = await chart.getListCompanyPartner(payload);
      const { entries } = response.data.contents;
      commit("SET_LIST_COMPANY_PARTNER", entries);
      return response;
    },

    async getListCompanyPartnerUser({ commit }, payload) {
      const response = await chart.getListCompanyPartnerUser(payload);
      if (response.hasError) {
        return response;
      }
      const listCompanyUser = response.data.contents;
      commit("SET_LIST_COMPANY_PARTNER_USER", listCompanyUser.entries);
      return response;
    },

    async getFieldTimeZones({ commit }, payload) {
      const response = await chart.getFieldTimeZones(payload);
      const listTimeZones = response.data.contents;
      commit("SET_LIST_TIME_ZONES", listTimeZones.entries);
      commit("SET_WORKING_TIME", listTimeZones.working_time);
      return response;
    },

    async getFieldDirectors({ commit }, payload) {
      const response = await chart.getFieldDirectors(payload);
      const listDirectors = response.data.contents;
      commit("SET_LIST_DIRECTORS", listDirectors.entries);
      return response;
    },

    async getFieldDirectorsChatManage({ commit }, payload) {
      const response = await chart.getFieldDirectorsChatManage(payload);
      const listDirectors = response.data.contents;
      commit("SET_LIST_DIRECTORS", listDirectors.entries);
      return response;
    },
    async getFieldDirectorsChatManageFieldConstructionId({ commit }, payload) {
      const response =
        await chart.getFieldDirectorsChatManageFieldConstructionId(payload);
      const listDirectors = response.data.contents;
      commit("SET_LIST_DIRECTORS", listDirectors.entries);
      return response;
    },

    async getFieldDirectorsObayashi({ commit }, payload) {
      const response = await chart.getFieldDirectorsObayashi(payload);
      const listDirectors = response.data.contents;
      commit("SET_LIST_DIRECTORS", listDirectors.entries);
      return response;
    },

    async getListItemTrees({ commit }, payload) {
      const response = await chart.getListItemTrees(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_LIST_ITEM_TREES", entries);
      return response;
    },

    async getListCompanyPartnerUserFromUser(_context, payload) {
      return await chart.getListCompanyPartnerUserFromUser(payload);
    },

    /**
     * updateStatHandling
     */
    async updateStatHandling(_context, payload) {
      return await chart.updateStatHandling(payload);
    },

    async updateFieldTreeGroup(_context, payload) {
      return await chart.updateFieldTreeGroup(payload);
    },

    async updateFieldTreeManagers(_context, payload) {
      return await chart.updateFieldTreeManagers(payload);
    },
    async getListCompanyPartnerLevel1({ commit }, payload) {
      const response = await chart.getListCompanyPartnerLevel1(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_LIST_COMPANY_PARTNER_LEVEL_1", entries);
      return response;
    },
    async getAllListCompany({ commit }, payload) {
      const response = await chart.getAllListCompany(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_ALL_LIST_COMPANY", entries);
      return response;
    },

    async getListUserFromCompanyPartnerLevel1({ commit }, payload) {
      const response = await chart.getListUserFromCompanyPartnerLevel1(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_LIST_USER_FROM_COMPANY_PARTNER_LEVEL_1", entries);
      return response;
    },

    async getListSkills({ commit }, payload) {
      const response = await chart.getListSkills(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_LIST_SKILLS", entries);
      return response;
    },

    async getListSkillAndCmnMstSkillIds({ commit }, payload) {
      const response = await chart.getListSkillAndCmnMstSkillIds(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_LIST_SKILL_AND_CMN_MST_SKILL_IDS", entries);
      return response;
    },


    clearListFieldDirector({ commit }) {
      commit("CLEAR_LIST_FIELD_DIRECTORS");
    },
    async getFieldTreeCSV({ commit }, payload) {
      const response = await chart.getFieldTreeCSV(payload);
      const { entries } = response.data.contents;
      commit("SET_FIELD_TREE_CSV", entries);
      return response;
    },

    async updateUpdateLevelApproval(_context, payload) {
      return await chart.updateUpdateLevelApproval(payload);
    },
    async updatePlanManager(_context, payload) {
      return await chart.updatePlanManager(payload);
    },
    async updatePositionSafety(_context, payload) {
      return await chart.updatePositionSafety(payload);
    },

    /**
     * チャット用にfieldTreeIdを取得
     */
    async getChatFieldTreeCompany({ commit }, payload) {
      const response = await chart.get(payload);
      const { entries } = response.data.contents;
      const { company_name, id } = entries?.field_tree;
      commit("SET_CHAT_FIELD_TREE_ID", id);
      return { company_name, id };
    },
    /**
     * get ownerCompany
     */
    async getChatOwnerCompany({ commit }, payload) {
      const response = await chart.getOwnerCompany(payload);
      const { entries } = response.data.contents;
      commit("SET_OWNER_COMPANY", entries);
      // TODO 協力会社と同じデータ形式にして会社情報を返す
      if (entries?.site_director?.field_tree_users) {
        const field_tree_users = entries?.site_director?.field_tree_users;
        return field_tree_users;
      } else {
        return [];
      }
    },
    async getFieldTreeForeman({ commit }, payload) {
      const response = await chart.getFieldTreeForeman(payload);
      commit("SET_FIELD_TREE_FOREMAN", response.data.contents.entries.foreman);
    }
  },

  getters: {
    //体制図データ
    getCompanies: (state) => {
      return state.companies;
    },
    //選択している工事
    getSelectedWorkType: (state) => {
      return state.workType;
    },
    //工事一覧
    getWorkTypes: (state) => {
      return state.workTypes;
    },
    //元請けポップアップの表示/非表示
    getSelectedOwnerCompany: (state) => {
      return state.selectedOwnerCompany;
    },
    //協力会社ポップアップの表示/非表示
    getSelectedRelatedCompany: (state) => {
      return state.selectedCompany;
    },
    getFieldTree: (state) => {
      return state.field_tree;
    },
    getRelatedCompany: (state) => {
      return state.relatedCompany;
    },
    getOwnerCompany: (state) => {
      return state.ownerCompany;
    },
    getDetailUserPartner: (state) => {
      return state.detail_user;
    },
    //１次請け招待フォームの表示/非表示
    isInviteFromOwner: (state) => {
      return state.inviteFromOwner;
    },
    //2次請け以下招待フォームの表示/非表示
    isInviteFromRelated: (state) => {
      return state.inviteFromRelated;
    },
    getCompanyBranchId: (state) => {
      return state.companyBranchesId;
    },
    isDeleteChart: (state) => {
      return state.delete_chart;
    },
    getListGroup: (state) => {
      return state.listGroup;
    },
    getListCompanyPartner: (state) => {
      return state.listCompanyPartner;
    },
    getListCompanyPartnerUser: (state) => {
      return state.listCompanyPartnerUser;
    },
    getFieldTimeZones: (state) => {
      return state.listTimeZones;
    },
    getListDirectors: (state) => {
      return state.listDirectors;
    },
    getListItemTrees: (state) => {
      return state.listItemTrees;
    },
    isEndWorkChart: (state) => {
      return state.endWorkChart;
    },
    isRestartWorkChart: (state) => {
      return state.restartWorkChart;
    },
    getExcludeCompanyId: (state) => {
      return state.exclude_company_id;
    },
    isChangeGroupWork: (state) => {
      return state.changeGroupWork;
    },
    isSetManageUser: (state) => {
      return state.setManageUser;
    },
    getListCompanyPartnerLevel1: (state) => {
      return state.list_company_partner_level_1;
    },
    getAllListCompany: (state) => {
      return state.all_list_company;
    },
    getListUserFromCompanyPartnerLevel1: (state) => {
      return state.list_user_from_company_partner_level_1;
    },
    getListSkills: (state) => {
      return state.list_skills;
    },
    getListSkillAndCmnMstSkillIds: (state) => {
      return state.list_skill_and_cmn_mst_skill_ids;
    },
    getListDirectorsObayashi: (state) => {
      return state.list_directors_obayashi;
    },
    getFieldTreeCSV: (state) => {
      return state.field_tree_csv;
    },
    getChatFieldTreeId: (state) => {
      return state.chat_field_tree_id;
    },
    getFieldTreeForeman: (state) => {
      return state.listFieldTreeForeman
    }
  },
};
