<template>
  <div class="" v-if="!isNotShowTemplate || isShowTemplateNoData">
    <div class="table">
      <!-- Label -->
      <ul class="information">
        <!-- ラベル -->
        <div class="schedule__label" :class="{'border-bottom-label': index == 0 && isScreenUsage}" v-if="isGates">
          <ScheduleTypeLabelGate />
        </div>
        <div class="schedule__label" :class="{'border-bottom-label': index == 0 && isScreenUsage}" v-else>
          <ScheduleTypeLabel :flagBanMachine="flagBanMachine" />
        </div>
        <!-- サマリー -->
        <div v-if="item.device_id" class="schedule__summary device__label" >
          <!-- 開閉 -->
          <button @click="toggleChild">
            <v-icon v-if="isShowChild">mdi-chevron-down</v-icon>
            <v-icon v-if="!isShowChild">mdi-chevron-right</v-icon>
          </button>
          <v-checkbox
            class="mt-4"
            v-model="forchChecked"
            dense
            @change="onChangeForceChecked"
          ></v-checkbox>
          <!-- 機材名 -->
          <div class="device__name">
            {{ convertDeviceName(item.device_name) + `（${item.total_children_item}）` }}
          </div>
          <div
            class="device__description"
            v-if="item.device_description && item.device_description.length > 0"
          >
            <div class="device__description-text">
              {{ item.device_description[0] }}
            </div>
            <div class="device__description-text">
              {{ item.device_description[1] }}
            </div>
          </div>
        </div>
        <!-- 予定情報 -->
        <div v-if="isShowChild && item.device_id" class="schedule__plan">
          <ScheduleInfo
            v-for="(item, index) in getPlans"
            :key="`info_${index}_${item.device_id}_${item.schedule_id}`"
            :item="item"
            :index="index"
            :cellRect="cellRect"
            :forchChecked="getChecked(item.schedule_id)"
            :isGates="isGates"
            @onChange="onCheck"
            @openItemForm="openItemForm"
          />
        </div>
      </ul>
      <!-- Gage -->
      <div :class="`schedule ${item.device_id ? '' : 'schedule_hidescroll'}`" :id="`schedule_${isGates?'gate':'crane'}_${index}`" :style="styleSchedule">
        <div class="scheduleInner" :style="getDaySize">
          <!-- 時間表記 -->
          <div class="schedule__label" :class="{'border-top-label': index == 0 && isUsage}">
            <div class="border-offset" v-if="(index == 0 && isUsage)"></div>
            <ScheduleTimeLabel
              :timelabelHeight="margin_top"
              :cellRectWidth="cellRect.width"
              :totalTime="total_hours"
              :prevTime="prev_hours"
              :afterTime="after_hours"
              :hourDivide="hour_divide"
              :isUsage="isUsage"
            />
          </div>
          <!-- サマリー -->
          <div v-if="item.device_id" class="schedule__summary">
            <ScheduleSummaryCell
              v-for="(item, index) in getSummary"
              :key="`summary_${index}`"
              :index="index"
              :item="item"
              :cellRect="summaryRect"
              :hourDivide="hour_divide"
              :today="getToday"
              :todayStartX="getTodayStartX"
              :marginTop="margin_top"
              @openSetTimeProhibit="openFormTimeProhibit()"
              :isUsage="isUsage"
            ></ScheduleSummaryCell>
          </div>
          <!-- 予定 -->
          <div v-if="isShowChild && item.device_id" class="schedule__plan">
            <SchedulePlanCell
              v-for="(item, index) in getPlans"
              :key="`plan_${index}_${item.device_id}_${item.schedule_id}`"
              :index="index"
              :item="item"
              :cellRect="cellRect"
              :hourDivide="hour_divide"
              :today="getToday"
              :todayStartX="getTodayStartX"
              :marginTop="margin_top"
              :isUsage="isUsage"
            ></SchedulePlanCell>
          </div>
          <!-- 禁止 -->
          <ScheduleDisabledCell
            v-for="(item, index) in getDisable"
            :key="`disabled_${index}`"
            :item="item"
            :cellRect="cellRect"
            :hourDivide="hour_divide"
            :today="getToday"
            :todayStartX="getTodayStartX"
            :marginTop="margin_top"
            style="cursor: pointer;"
            @click.native="openFormTimeProhibit()"
            :isUsage="isUsage"
          ></ScheduleDisabledCell>
          <!-- 背景 -->
          <div
            v-if="isShowChild && item.device_id"
            class="scheduleBackground"
            :style="getDaySize"
          >
            <!-- 背景グリッド -->
            <div
              v-for="(item, index) in cells"
              :key="`cell_${index}`"
              class="scheduleCell"
              :class="`${getCellStatus(index)} cell_${index}`"
              :style="getCellStyle"
            ></div>
            <!-- 罫線 -->
            <div
              v-for="(item, index) in getHorizontalLines"
              :key="`line_${index}`"
              class="scheduleHorizontalLine"
              :style="getHorizontalLineTop(index)"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="table_footer" v-if="isShowChild && item.device_id">
      <v-btn
        v-if="
          item.total_children_item > 3 &&
            childTableDisplayMoreNumber(
              item.total_children_item,
              item.children.length
            ) > 0
        "
        x-small
        depressed
        class="mr-4 display-more"
        color="#E5E5E5"
        :disabled="isDisable"
        @click="onLoadChildren(item)"
        >さらに表示する
        <v-badge
          class="display-more-number"
          inline
          color="#767676"
          :content="
            childTableDisplayMoreNumber(
              item.total_children_item,
              item.children.length
            )
          "
        />
      </v-btn>
    </div>
    <div v-if="!item.device_id && !isNotShowNoData" class="schedule_nodata">
      <span>{{ NO_DATA_MESSAGE }}</span>
    </div>
    <Popup :dialog="popups.isOpenFormTimeProhibit" v-if="popups.isOpenFormTimeProhibit">
      <SetTimeProhibitForm
        :isGates="isGates"
        @cancel="closeFormTimeProhibit"
        @resetList="$emit('resetList')"
      />
    </Popup>
  </div>
</template>

<script>
import SetTimeProhibitForm from '@/components/forms/schedule/components/timeProhibit/SetTimeProhibitForm.vue';
import Popup from '@/components/common/Popup.vue';
import ScheduleTimeLabel from "./ScheduleTimeLabel";
import SchedulePlanCell from "./SchedulePlanCell";
import ScheduleDisabledCell from "./ScheduleDisabledCell";
import ScheduleSummaryCell from "./ScheduleSummaryCell";
import ScheduleInfo from "./ScheduleInfo";
import ScheduleTypeLabel from "./ScheduleTypeLabel.vue";
import ScheduleTypeLabelGate from "./ScheduleTypeLabelGate.vue";
import { SCHEDULE_TYPE } from "@/constants/SCHEDULE_WORKS.js";
import { NO_DATA_MESSAGE } from "@/constants/COMMON.js";
import { Store } from "@/store/Store.js";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";

/**
 * 前後 12時間を加えて、全部で48時間の幅を持つ
 */

//1時間の分割数
const config_hour_divide = 4;

//当日前の時間
const config_schedule_prev_hours = 0;

//当日後の時間
const config_schedule_after_hours = 6;

//スケジュールで表示する時間
const config_schedule_hours = 24 + config_schedule_prev_hours + config_schedule_after_hours;

//スケジュールで表示する15分セルの数
const config_all_cells = config_hour_divide * config_schedule_hours;

//1時間のセルのサイズ(px)
const config_cell_width = 16;
const config_cell_height = 32;

//予定の上マージン
const config_margin_top = 0;

//さらに表示する高さ
// const config_loadmore_height = 64;

//作業時間帯仮データ
// const work_timeframe = { startTime: "12:00", endTime: "18:00" };

//今日
// const today = "2021-01-01";

export default {
  components: {
    ScheduleTimeLabel,
    SchedulePlanCell,
    ScheduleSummaryCell,
    ScheduleDisabledCell,
    ScheduleInfo,
    ScheduleTypeLabel,
    ScheduleTypeLabelGate,
    Popup,
    SetTimeProhibitForm,
  },
  data: () => {
    return {
      NO_DATA_MESSAGE,
      checkList: [],
      selectedList: [],
      forchChecked: false,
      check_flag: true,
      isShowChild: true,
      cells: config_all_cells,
      total_hours: config_schedule_hours,
      prev_hours: config_schedule_prev_hours,
      after_hours: config_schedule_after_hours,
      summaryRect: { width: config_cell_width, height: 1 },
      cellRect: { width: config_cell_width, height: config_cell_height },
      configAllCellWidth: config_cell_width,
      hour_divide: config_hour_divide,
      margin_top: config_margin_top,
      isDisable: false,
      time_out: null,
      popups: {
        isOpenFormTimeProhibit: false,
      },
      deviceId: null,
    };
  },
  props: {
    isUsage: {
      type: Boolean,
      default: false,
    },
    isGates: {
      type: Boolean
    },
    item: {
      type: Object
    },
    today: {
      type: String
    },
    resetSelected: {
      type: Boolean,
      default: false
    },
    flagBanMachine: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number
    },
    work_start_time: {
      type: String
    },
    isNotShowNoData: {
      type: Boolean,
      default: false
    },
    isNotShowTemplate: {
      type: Boolean,
      default: false
    },
    isShowTemplateNoData: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    isScreenUsage() {
      return this.$route.name === "ScheduleUsage";
    },
    // 本日
    getToday() {
      return `${this.today} 00:00:00`;
    },

    // サマリー
    getSummary() {
      const { schedule_summary } = this.item;
      return schedule_summary;
    },

    // 禁止
    getDisable() {
      const { schedule_summary } = this.item;
      const disables = schedule_summary.filter((item) => {
        return item.schedule_type === SCHEDULE_TYPE.DISABLE.id;
      });
      return disables;
    },

    // 子要素一覧
    getPlans() {
      const { children } = this.item;
      return children;
    },

    // informationの高さ
    getInformationSize() {
      return `height:${config_cell_height}px`;
    },

    IS_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
    //スケジュールの大きさを表示する時間範囲によって変更
    getDaySize() {
      const { children } = this.item;
      const height = this.isShowChild
        ? (children.length + 2) * config_cell_height + config_margin_top
        : config_cell_height + config_margin_top;

      return `width: ${this.cells *
        this.configAllCellWidth}px;height:${height}px`;
    },

    //スケジュールの予定量にあわせて罫線を作成
    getHorizontalLines() {
      const { children } = this.item;
      return [...new Array(children.length + 1)];
    },
    //スケジュールのグリッドのスタイルを作成
    getCellStyle() {
      const { children } = this.item;
      return `width:${this.cellRect.width}px;height:${this.cellRect.height *
        children.length +
        config_margin_top}px;`;
    },
    //今日の開始ピクセル位置
    getTodayStartX() {
      const prevWidth =
        config_schedule_prev_hours * this.cellRect.width * config_hour_divide;
      return prevWidth;
    },
    styleSchedule() {
      return this.IS_OBAYASHI ? { 'overflow-x': 'auto' } : {};
    }
  },
  created(){
    if(this.IS_OBAYASHI && this.isScreenUsage){
      this.total_hours = 15
      this.configAllCellWidth = 20
      this.summaryRect.width = 20
      this.cellRect.width = 20
      this.cells = config_hour_divide * this.total_hours
    }
  },
  mounted() {
    this.firstFocustTimeBar();
    this.$watch(
      () => this.item.children,
      (newValue, oldValue) => {
        if (newValue?.length != oldValue?.length) {
          this.forchChecked = false;
        }
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    // さらに表示できるchild table数
    childTableDisplayMoreNumber(totalNum, displayNum) {
      // 親が持つすべてのchild table数から表示されている数をひく
      const _number = totalNum - displayNum;
      return _number > 0 ? _number : "0";
    },

    openItemForm(id) {
      this.$emit("openItemForm", id);
    },

    //全ての子チェックボックスを強制的にチェックする
    onChangeForceChecked(value) {
      this.check_flag = true;
      this.forchChecked = value;
      this.checkList = [];
      const { children } = this.item;
      const updateList = children.map((item) => {
        if (value) {
          this.checkList.push(item.schedule_id);
        } else {
          this.checkList = []
        }
        return {
          id: item.schedule_id,
          isChecked: value,
          schedule_approval: item.schedule_approval
        };
      });
      this.selectedList = updateList;
      this.$emit("update", updateList);
    },

    // チェックリストを更新
    onCheck({ schedule_id, checked }) {
      let checkList = [...this.checkList];
      //trueの場合
      if (checked) {
        const result = checkList.find((id) => id === schedule_id);
        if (!result) checkList.push(schedule_id);
      } else {
        checkList = checkList.filter((id) => {
          return id !== schedule_id;
        });
      }
      this.checkList = checkList;
      this.forchChecked = this.checkList.length == this.item.children.length;

      // 比較
      const { children } = this.item;
      const updateList = children.map((item) => {
        const has = checkList.includes(item.schedule_id);
        return {
          id: item.schedule_id,
          isChecked: has,
          schedule_approval: item.schedule_approval
        };
      });
      this.selectedList = updateList;
      this.$emit("update", updateList);
    },

    getChecked(schedule_id) {
      const result = this.selectedList.find((item) => item.id === schedule_id);
      return result?.isChecked;
    },

    // 子予定の表示/非表示
    toggleChild() {
      if (!this.forchChecked) {
        this.checkList = [];
        let checkList = [...this.checkList];
        // 比較
        const { children } = this.item;
        const updateList = children.map((item) => {
          const has = checkList.includes(item.schedule_id);
          return {
            id: item.schedule_id,
            isChecked: has,
            schedule_approval: item.schedule_approval
          };
        });
        this.$emit("update", updateList);
      }
      this.isShowChild = !this.isShowChild;
    },

    /**
     * レイアウト関連のメソッド
     */

    // 背景横線の座標
    getHorizontalLineTop(index) {
      const top = index * config_cell_height + config_margin_top;
      return `top:${top}px`;
    },

    // 背景グリッドの状態
    getCellStatus(index) {
      const isPrev = index < config_schedule_prev_hours * config_hour_divide;
      const isAfter =
        index >= (config_schedule_prev_hours + 24) * config_hour_divide;

      if (isPrev) return "isPrev";
      if (isAfter) return "isAfter";
      return null;
    },

    convertDeviceName(name) {
      let result = "";
      if (name) {
        result = name;
        if (name.length > 16) {
          result = name.substring(0, 16) + "•••";
        }
      }
      return result;
    },

    onLoadChildren(item) {
      this.isDisable = true;
      // Re-enable after submit
      this.time_out = setTimeout(() => {
        this.isDisable = false;
      }, 1000);
      this.$emit(
        'getChildItems',
        item.device_id,
        Math.floor((item.children.length - 3) / 5) + 2
      )
    },
    // Auto focus first timebar
    firstFocustTimeBar() {
      let el = document.getElementById(`schedule_${this.isGates?'gate':'crane'}_${this.index}`);
      if (!el) return;
      let time = Number((this.work_start_time || "").split(':')[0]);
      el.scrollLeft += time * this.hour_divide * this.configAllCellWidth - 2;
    },

    // Open form set time prohibit
    async openFormTimeProhibit() {
      let store = this.isGates ? 'Gates' : 'Cranes';
      const result = await Store.dispatch(`${store}/getDetail`, this.item.device_id);
      if (!result.hasError) {
        this.popups.isOpenFormTimeProhibit = true;
      }
      this.popups.isOpenFormTimeProhibit = true;
    },

    // Close form set time prohibit
    closeFormTimeProhibit() {
      this.popups.isOpenFormTimeProhibit = false;
    },
  },
  /**
   * Important: clear timeout
   */
  beforeDestroy() {
    // clear the timeout before the component is destroyed
    clearTimeout(this.time_out);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
$border-color: #e5e5e5;
$border-label: #1B9C4F;
.table {
  width: 100%;
  height: auto;
  display: flex;
}

.table_footer {
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.information {
  position: relative;
  margin: 0;
  margin-left: 2px;
  padding: 0;
  width: 50%;
  background-color: white;

  .schedule__plan {
    margin-left: 4px;
  }
}
.schedule {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  background-color: white;
}

.scheduleInner {
  position: relative;
  height: 100%;
  margin-left: 50px !important;

}

.schedule__label {
  height: 24px;
  border-bottom: 1px solid $border-color;
}

.border-bottom-label {
  border-bottom: 3px solid $border-label;
}

.schedule__type-label {
  margin-top: 1px;
}

.border-top-label {
  border-bottom: 3px solid $border-label;
  margin-top: 1px;
}

.border-offset {
  position: absolute;
  top: 21px;
  left: 0;
  margin-left: -50px;
  margin-top: 0px;
  width: 50px;
  height: 3px;
  border-top: 3px solid $border-label;
}

.schedule_nodata {
  position: absolute;
  left: 50%;
}

.schedule__summary {
  position: relative;
  height: 40px;
  top: 0;
}


.schedule_hidescroll {
  overflow: hidden !important;
}

.schedule__plan {
  position: relative;
}

//背景
.scheduleBackground {
  position: relative;
  height: 100%;
  display: flex;
}
.scheduleCell {
  position: relative;
  height: 100%;
  background-color: #f8f8f8;
  &:nth-of-type(4n) {
    &:before {
      content: "";
      position: absolute;
      z-index: 0;
      width: 1px;
      height: 100%;
      top: 0;
      right: 0;
      background-color: #ddd;
    }
  }

  &.isPrev,
  &.isAfter {
    background-color: #e8e8e8;
    /* &:before {
      display: none;
    } */
  }
}
.scheduleHorizontalLine {
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
  background-color: #cfcfcf;
}

.device__label {
  display: flex;
  text-align: left;
  align-items: center;
  .device__name {
    font-size: 16px;
    min-width: 180px;
  }
  .device__description {
    overflow: hidden;
    font-size: 11px;
  }
  .device__description-text {
    white-space: nowrap;
  }
}
</style>
