const CRANE_INITIAL_ITEM = {
  cmn_mst_crane_type_id: null,
  name: null,
  company_id: null,
  maximum_working_radius: null,
  maximum_rated_load: null,
  boom_length: null,
  outrigger_overhang_dimensions: null,
  cmn_mst_crane_drive_system_id:null,
  cmn_mst_lifting_machine_id: null,
  main_hook_weight: null,
  maximum_lifting_load: null,
  maximum_ground_lift: null,
  supplementary_hook_weight: null,
  prohibit_start_date: null,
  prohibit_end_date: null,
  memo: null,
  monday_flg: 0,
  tuesday_flg: 0,
  wednesday_flg: 0,
  thursday_flg: 0,
  friday_flg: 0,
  saturday_flg: 0,
  sunday_flg: 0,
  public_holiday_flg: 0,
  device_keyplan_images: [],
  device_licences: [],
  device_sp_trainings: [],
  device_prohibit_times: [],
};

const MINS_ON_HOUR = ["00", "15", "30", "45"];
const getProhibitTimes = (isAll, isEnd) => {
  let prohibitTimes = [{ id: null, name: "" }];
  let i = 0;
  while (i < 24) {
    for (let m of MINS_ON_HOUR) {
      if (isEnd && i === 0 && m === "00") {
        continue;
      }
      let hour = i < 10 ? "0" + i : i;
      let element = {
        id: hour + ":" + m,
        name: hour + ":" + m,
      };
      prohibitTimes.push(element);
    }
    i++;
  }
  if (isEnd || isAll) {
    prohibitTimes.push({ id: "24:00", name: "24:00" });
  }
  return prohibitTimes;
};

const cmn_mst_time_start = getProhibitTimes(false, false);
const cmn_mst_time_end = getProhibitTimes(false, true);

// テーブル設定
const CRANE_TABLE_LABELS = [
  {
    text: "揚重機",
    value: "name",
    align: "left",
  },
  {
    text: "最大作業半径",
    value: "maximum_working_radius",
    align: "left",
  },
  {
    text: "最大定格荷重",
    value: "maximum_rated_load",
    align: "left",
  },
  {
    text: "ブーム長さ",
    value: "boom_length",
    align: "left",
  },
  {
    text: "アウトリガ張出寸法",
    value: "outrigger_overhang_dimensions",
    align: "left",
  },
  {
    text: "駆動方式",
    value: "drive_system",
    align: "left",
  },
  {
    text: "管理会社",
    value: "company_name",
    align: "left",
  },
];


const TITLE = {
  NEW: "揚重機情報",
  EDIT: "揚重機情報",
};

/**
 * フォームとタブの設定
 */
const FORMS = {
  CraneFormPage1: { id: 1, title: "基本情報" },
  CraneFormPage2: { id: 2, title: "性能" },
  CraneFormPage3: { id: 3, title: "禁止時間帯" },
};

const CRANE_SORT_ITEMS = [
  { id: "name", name: "揚重機" },
  { id: "maximum_working_radius", name: "最大作業半径" },
  { id: "maximum_rated_load", name: "最大定格荷重" },
  { id: "boom_length", name: "ブーム長さ" },
  { id: "outrigger_overhang_dimensions", name: "アウトリガ張出寸法" },
  { id: "drive_system", name: "駆動方式" },
  { id: "company_name", name: "管理会社" },
];

/**
 * ロール
 * @returns Object
 */
const CRANE_ROLES = {
  CRANE: { id: 1, name: "作業員" },
};

/**
 * ロール Select用
 * @returns Array
 */
const CRANE_ROLES_ARRAY = Object.entries(CRANE_ROLES).map((item) => item[1]);

const addOneDayToEndTime = (arr) => {
  arr.forEach(e => {
    if (e["usage_start_time"] && e["usage_end_time"] && !(e["usage_start_time"] < e["usage_end_time"])) {
      let temp = parseInt(e["usage_end_time"].split(":")[0]) + 24;
      e["usage_end_time"] = temp + ":" + e["usage_end_time"].split(":")[1];
    }
  });
  return arr;
};

const removeOneDayToEndTime = (arr) => {
  arr.forEach(e => {
    let temp = parseInt(e["usage_end_time"].split(":")[0]) - 24;
    if (temp > 0 || (temp == 0 && e["usage_end_time"].split(":")[1] != "00")) {
      let hour = temp < 10 ? "0" + temp : temp;
      e["usage_end_time"] = hour + ":" + e["usage_end_time"].split(":")[1];
    }
  });
  return arr;
};

export {
  CRANE_INITIAL_ITEM,
  CRANE_TABLE_LABELS,
  CRANE_SORT_ITEMS,
  CRANE_ROLES,
  CRANE_ROLES_ARRAY,
  cmn_mst_time_start,
  cmn_mst_time_end,
  TITLE, FORMS,
  addOneDayToEndTime,
  removeOneDayToEndTime
};
