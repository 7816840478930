<template>
  <v-card class="dialog">
    <v-card-title>
      <div :style="getTitleColorStyle" class="title">{{ title }}</div>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div class="text">{{ text }}</div>
        <div v-if="text2.length > 0" class="text">{{ text2 }}</div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        :color="getColor"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn
        depressed
        class="btn"
        :key="flagError"
        :color="getColor"
        @click.once="onSubmit()"
      >
        {{titleBtnOk}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    text: {
      type: String,
    },
    text2: {
      type: String,
      default: "",
    },
    title: {
      type: String,
    },
    titleBtnOk: {
      type: String,
      default: "確定",
    },
    flagAprrove: {
      type: Boolean,
      default: true
    },
    flagError: Boolean,
  },
  computed: {
    getTitleColorStyle() {
      return this.flagAprrove ? "color: #1B9C4F;" : "color: #FF7B52;";
    },
    getColor() {
      return this.flagAprrove ? "primary" : "warning";
    }
  },
  methods: {
    async onSubmit() {
      this.$emit('yes');
    }
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .title {
    font-size: 12px;
  }
  .body {
    .text-wrap {
      .text {
        text-align: center;
        margin: auto;
        width: 80%;
      }
    }
  }
  .item {
    &:after {
      content: ",";
    }
    &:last-child {
      &:after {
        content: "";
      }
    }
  }
}
</style>
