<template>
  <v-card class="dialog">
    <div class="title">作成</div>
    <v-container
      class="px-10"
      fluid
    >
    <v-radio-group v-model="valueSelected">
      <v-radio
        v-for="n in INFO_FORM"
        :key="n.id"
        :label="n.value"
        :value="n.id"
      ></v-radio>
    </v-radio-group>
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="primary"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn
        depressed
        class="btn"
        color="primary"
        :key="flagError"
        @click.once="onSubmit()"
      >
        作成
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { INFO_FORM } from "@/constants/DEVICE_CARRY_ON";
export default {
  data() {
    return {
      INFO_FORM,
      valueSelected: null,
    }
  },
  components: {},
  props: {
    text: {
      type: String,
    },
    text2: {
      type: String,
      default: "",
    },
    title: {
      type: String,
    },
    flagError: Boolean,
  },
  computed: {
  },
  methods: {
    async onSubmit() {
      const createFormByTab = this.valueSelected;
      this.$emit('submit', createFormByTab);
    }
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 10px;
  .title {
    font-size: 12px;
    font-weight: bold;
    color: #1b9c4f !important;
    padding-left: 25px;
    padding-top: 20px;
  }
}
</style>
