<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row no-gutters>
          <v-col cols="12" sm="6" md="6">
            <v-list-item>
              <v-list-item-content>
                <Label label="作業分類職種" :editable="editable">
                  <Select
                    name="occupation"
                    :values="item"
                    :editable="editable"
                    :items="WORK_CONTENT_TYPE"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="11" md="11">
            <v-list-item>
              <v-list-item-content>
                <Label label="作業内容" :editable="editable">
                  <Select
                    name="cmn_mst_work_classification_id"
                    :values="item"
                    :editable="editable"
                    :items="filterSubCatergory(item)"
                    item_text="work_content"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="1" md="1">
            <v-btn
              v-if="index > 0 && editable"
              class="mt-12"
              icon
              @click="remove(index)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
      <v-btn v-if="editable" depressed small color="primary" @click="addEmpty"
        >追加</v-btn
      >
    </v-container>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "../../../elements/Label.vue";
import Select from "../../../elements/Select.vue";
import { Store } from "@/store/Store.js";
//初期値を設定します
const INITIAL_VALUES = {
  cmn_mst_work_classification_id: 1,
};

export default {
  data: () => {
    return {
      items: [],
      WORK_CLASSIFICATION: Store.state.CmnMst.constants.entries.cmn_mst_work_classifications,
      WORK_CLASSIFICATION_OCCUPATION: Store.state.CmnMst.constants.entries.cmn_mst_work_classifications,
      WORK_CONTENT_TYPE: [],
    };
  },
  components: {
    Label,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
  },
  mounted() {
    this.getDataArray();
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        if (newValue[0][newValue[1]]?.length) {
          this.items = newValue[0][newValue[1]];
          if (this.items?.length == 0) this.addEmpty();
          this.items?.forEach((_item) => {
            this.WORK_CLASSIFICATION.forEach((element) => {
              if (_item.cmn_mst_work_classification_id == element.id) {
                _item["occupation"] = element.occupation;
              }
            });
          });
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      const itemEdited = { ...this.items[index] };
      const items = [...this.items];
      itemEdited[value.name] = value.value;
      if (value.name == "occupation") {
        itemEdited["cmn_mst_work_classification_id"] =
          this.filterSubCatergory(itemEdited)[0].id;
      }
      delete itemEdited["occupation"];
      items[index] = itemEdited;
      this.$emit("onInput", { name: this.name, value: items });
    },
    remove(index) {
      const items = this.items.filter((item, i) => {
        return index != i;
      });
      this.$emit("onInput", { name: this.name, value: items });
    },
    addEmpty() {
      let _items = [...this.items];
      _items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    async getDataArray() {
      let results = this.WORK_CLASSIFICATION_OCCUPATION.reduce(
        (results, item) => {
          (results[item.occupation] = results[item.occupation] || []).push(
            item
          );
          return results;
        },
        []
      );
      this.WORK_CLASSIFICATION_OCCUPATION = results;
      for (let item in results) {
        this.WORK_CONTENT_TYPE.push({ id: item, name: item });
      }
    },
    filterSubCatergory(item) {
      return this.WORK_CLASSIFICATION_OCCUPATION[item.occupation];
    },
  },
};
</script>
