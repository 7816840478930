<template>
  <v-card class="dialog">
    <v-card-title>
      <div :style="getTitleColorStyle" class="title">発注見送り</div>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div class="text">建機レンタル会社へ通知します。</div>
      </div>
      <div>
        <Label label="メッセージ" :editable="true">
          <InputTextArea
            name="estimate_remarks"
            :editable="true"
            :values="formValues"
            @onInput="onInput"
          />
        </Label>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        :color="getColor"
        class="btn"
        @click="$emit('closeComfirmSendOff')"
      >
        やめる
      </v-btn>
      <v-btn
        depressed
        class="btn"
        :key="flagSendOff"
        :color="getColor"
        @click.once="onSubmit()"
      >
        見送り
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import InputTextArea from "@/components/forms/elements/InputTextArea";
import Label from "@/components/forms/elements/Label.vue";
export default {
  data() {
    return {
      formValues: {
        estimate_remarks: null,
      },
    };
  },
  components: {
    InputTextArea,
    Label,
  },
  props: {
    message: {
      type: String,
    },
    flagSendOff: {
      type: Boolean,
    },
    warning: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getTitleColorStyle() {
      return this.warning ? "color:#FF7B52;" : "";
    },
    getColor() {
      return this.warning ? "warning" : "primary";
    },
  },
  methods: {
    onSubmit() {
      this.$emit("onSubmitSendOff", this.formValues);
    },
    onInput({ name, value }) {
      this.formValues[name] = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;

  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;
      .text {
        text-align: left !important;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 80%;
      }
    }
  }
  .btn {
    margin-right: 12px;
  }
}
</style>
