const ENV_CLIENT = {
  GREEN: "GREEN",
  OBAYASHI: "OBAYASHI",
};

/**
 * 設定のキーラベル
 */
const ENV_CONFIG_LABELS = {
  HEADER_MENU_ITEMS_INHOUSE: "HEADER_MENU_ITEMS_INHOUSE",
  HEADER_MENU_ITEMS_INHOUSE_WITHOUT_OFFICE:
    "HEADER_MENU_ITEMS_INHOUSE_WITHOUT_OFFICE",
  HEADER_MENU_ITEMS_SITE: "HEADER_MENU_ITEMS_SITE",
};

/**
 * グリーンのメニュー
 */
const ENV_CONFIG_GREEN = {
  HEADER_MENU_ITEMS_INHOUSE: {
    PORTAL: { visibility: 1 },
    SITES: { visibility: 1 },
    EMPLOYEES: { visibility: 1 },
    COMPANY_MACHINES: { visibility: 1 },
    OFFICES: { visibility: 1 },
  },
  HEADER_MENU_ITEMS_INHOUSE_WITHOUT_OFFICE: {
    PORTAL: { visibility: 1 },
    SITES: { visibility: 1 },
    EMPLOYEES: { visibility: 1 },
    COMPANY_MACHINES: { visibility: 1 },
  },
  HEADER_MENU_ITEMS_SITE: {
    PORTAL: { visibility: 1 },
    CHART: { visibility: 1 },
    SAFETY: { visibility: 1 },
    SCHEDULE: { visibility: 1 },
    MACHINE: { visibility: 0 },
    ENTRANCE: { visibility: 1 },
    DEVICE_MANAGEMENT: { visibility: 1 },
    PLACEMENT: { visibility: 1 },
    TEXTCHAT: { visibility: 1 },
    WORK_SCHEDULE: { visibility: 0 },
    WORK_RECORD: { visibility: 0 },
    USAGE_SCHEDULE: { visibility: 0 },
    SAFETY_PATROLS: { visibility: 0 },
    SAFETY_REPORTS: { visibility: 0 },
    OTHERS: { visibility: 0 },
  },
  MENU_SCHEDULE_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
    { id: 2, visibility: 1 },
    { id: 3, visibility: 1 },
    { id: 4, visibility: 1 }, // 使用予定
    { id: 5, visibility: 1 },
    { id: 6, visibility: 1 },
  ],
  MENU_ENTRANCE_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
    { id: 2, visibility: 1 },
    { id: 3, visibility: 1 },
    { id: 4, visibility: 1 },
  ],
  MENU_DEVICE_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
    { id: 2, visibility: 1 },
  ],
  MENU_CHAT_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
  ],
  MENU_OTHER_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
    { id: 2, visibility: 1 },
  ],
};

/**
 * 大林のメニュー
 */
const ENV_CONFIG_OBAYASHI = {
  HEADER_MENU_ITEMS_INHOUSE: {
    PORTAL: { visibility: 1 },
    SITES: { visibility: 1 },
    EMPLOYEES: { visibility: 1 },
    COMPANY_MACHINES: { visibility: 1 },
    OFFICES: { visibility: 1 },
  },
  HEADER_MENU_ITEMS_INHOUSE_WITHOUT_OFFICE: {
    PORTAL: { visibility: 1 },
    SITES: { visibility: 1 },
    EMPLOYEES: { visibility: 1 },
    COMPANY_MACHINES: { visibility: 1 },
  },
  HEADER_MENU_ITEMS_SITE: {
    PORTAL: { visibility: 1 },
    CHART: { visibility: 1 },
    SAFETY: { visibility: 1 },
    SCHEDULE: { visibility: 0 },
    MACHINE: { visibility: 0 },
    ENTRANCE: { visibility: 0 },
    DEVICE_MANAGEMENT: { visibility: 0 },
    PLACEMENT: { visibility: 0 },
    TEXTCHAT: { visibility: 0 },
    WORK_SCHEDULE: { visibility: 1 },
    WORK_RECORD: { visibility: 1 },
    USAGE_SCHEDULE: { visibility: 1 },
    SAFETY_PATROLS: { visibility: 1 },
    SAFETY_REPORTS: { visibility: 1 },
    OTHERS: { visibility: 1 },
  },
  MENU_SCHEDULE_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
    { id: 2, visibility: 0 },
    { id: 3, visibility: 0 },
    { id: 4, visibility: 1 }, // 使用予定
    { id: 5, visibility: 1 },
    { id: 6, visibility: 1 },
  ],
  MENU_ENTRANCE_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
    { id: 2, visibility: 1 },
    { id: 3, visibility: 1 },
    { id: 4, visibility: 1 },
  ],
  MENU_DEVICE_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
    { id: 2, visibility: 1 },
  ],
  MENU_CHAT_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
  ],
  MENU_OTHER_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
    { id: 2, visibility: 1 },
  ],
};

/**
 * レンタル会社
 */
const ENV_CONFIG_RENTAL = {
  HEADER_MENU_ITEMS_INHOUSE: {
    PORTAL: { visibility: 1 },
    SITES: { visibility: 1 },
    EMPLOYEES: { visibility: 1 },
    COMPANY_MACHINES: { visibility: 1 },
    OFFICES: { visibility: 1 },
  },
  HEADER_MENU_ITEMS_INHOUSE_WITHOUT_OFFICE: {
    PORTAL: { visibility: 1 },
    SITES: { visibility: 1 },
    EMPLOYEES: { visibility: 1 },
    COMPANY_MACHINES: { visibility: 1 },
  },
  HEADER_MENU_ITEMS_SITE: {
    PORTAL: { visibility: 1 },
    DEVICE_MANAGEMENT: { visibility: 1 },
  },
  MENU_SCHEDULE_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
    { id: 2, visibility: 1 },
    { id: 3, visibility: 1 },
    { id: 4, visibility: 1 },
    { id: 5, visibility: 1 },
  ],
  MENU_ENTRANCE_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
    { id: 2, visibility: 1 },
    { id: 3, visibility: 1 },
    { id: 4, visibility: 1 },
  ],
  MENU_DEVICE_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
    { id: 2, visibility: 1 },
  ],
  MENU_CHAT_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
  ],
};

/**
 *
 * @param {string}} env
 * @param {string} configKeyName
 * @returns
 */
const getEnvConfigValue = (_ENV_CONFIG_LABEL) => {
  let _ENV_CONFIG = ENV_CONFIG_GREEN;
  //大林
  if (process.env.VUE_APP_CLIENT === ENV_CLIENT.OBAYASHI)
    _ENV_CONFIG = ENV_CONFIG_OBAYASHI;

  return _ENV_CONFIG[_ENV_CONFIG_LABEL];
};

const getDropDownMenuVisibility = (key) => {
  //大林
  if (process.env.VUE_APP_CLIENT === ENV_CLIENT.OBAYASHI) {
    return ENV_CONFIG_OBAYASHI[key];
  }

  //GREEN
  return ENV_CONFIG_GREEN[key];
};

export {
  ENV_CLIENT,
  ENV_CONFIG_LABELS,
  ENV_CONFIG_GREEN,
  ENV_CONFIG_OBAYASHI,
  ENV_CONFIG_RENTAL,
  getEnvConfigValue,
  getDropDownMenuVisibility,
};
