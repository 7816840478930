import Api, { Mock } from "../api";
import mock_patrol from "./mock/patrol_record.json";
import patrol_record from "./mock/patrol_record_detail.json";

const PATH = "safety_diaries";
const URL = `${process.env.VUE_APP_API_BASE_URL}/${PATH}`;
const URL_GET_LIST = `${URL}/list`;
const GET_URL_DETAIL = `${URL}/detail`;
const URL_UPDATE = `${URL}/update`;
const URL_CREATE = `${URL}/create`;
const URL_DELETE = `${URL}/delete`;
const URL_CONSTRUCTIONS = `${URL}/get_constructions`;
const URL_WORK_PLACES = `${URL}/get_work_places`;
const URL_URGENCIES = `${URL}/get_urgencies`;
const URL_UPDATE_STATUS = `${URL}/update_status`;
const URL_SAFETY_DIARY_SETTINGS = `${process.env.VUE_APP_API_BASE_URL}/safety_diary_settings`;
const GET_URL_DETAIL_SAFETY_DIARY_SETTINGS = `${URL_SAFETY_DIARY_SETTINGS}/detail`;
const URL_CREATE_SAFETY_DIARY_SETTINGS = `${URL_SAFETY_DIARY_SETTINGS}/create`;
const URL_GET_CHILD_SAFETY_DIARY = `${URL}/list_safety_diary_by_group`;
const URL_GET_LIST_POSITIONS = `${URL}/list_safety_position`;
const URL_REPORT = `${URL}/report`;
const URL_UPDATE_STATUS_REJECT = `${URL}/update_status_reject`;
const URL_GET_LIST_JUMP = `${URL}/list_safety_jump`;

// .envのVUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  Mock.onGet(URL_GET_LIST).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_patrol,
  });
  Mock.onGet(`${GET_URL_DETAIL}/[\\d]+`).reply(200, {
    status_code: 200,
    result: true,
    message: "",
    contents: patrol_record,
  });
  Mock.onPost(URL_UPDATE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(URL_CREATE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(URL_CONSTRUCTIONS).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        {
          id: 1,
          name: "解体工事 1",
        },
        {
          id: 2,
          name: "解体工事 2",
        },
        {
          id: 3,
          name: "解体工事 3",
        },
      ],
    },
  });
  Mock.onGet(new RegExp(`${URL_WORK_PLACES}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        {
          id: 1,
          name: "本館/1階/エリア 1",
        },
        {
          id: 2,
          name: "本館/1階/エリア 2",
        },
        {
          id: 3,
          name: "本館/1階/エリア 3",
        },
      ],
    },
  });
  Mock.onGet(URL_URGENCIES).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        {
          id: 1,
          name: "高 1",
        },
        {
          id: 2,
          name: "高 2",
        },
        {
          id: 3,
          name: "高 3",
        },
      ],
    },
  });
  Mock.onPost(URL_UPDATE_STATUS).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });

  Mock.onPost(URL_DELETE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
}

export const patrolRecord = {
  get: (params) => {
    return Api.get(URL_GET_LIST, params);
  },
  getUrgencies: (params) => {
    return Api.get(URL_URGENCIES, params);
  },
  getDetail: async (params) => {
    return Api.get(`${GET_URL_DETAIL}/${params}`);
  },
  getWorkPlaces: (params) => {
    return Api.get(`${URL_WORK_PLACES}/${params}`);
  },
  post: async (params) => {
    return Api.post(URL_CREATE, params);
  },
  updateStatus: (params) => {
    return Api.post(URL_UPDATE_STATUS, params);
  },
  updateStatusReject: (params) => {
    return Api.post(URL_UPDATE_STATUS_REJECT, params);
  },
  update: async (params) => {
    return Api.post(URL_UPDATE, params);
  },
  delete: async (params) => {
    return Api.post(URL_DELETE, params);
  },
  getDetailSafetyDiarySettings: async (params) => {
    return Api.get(`${GET_URL_DETAIL_SAFETY_DIARY_SETTINGS}/${params}`);
  },
  createSafetyDiarySettings: async (params) => {
    return Api.post(URL_CREATE_SAFETY_DIARY_SETTINGS, params);
  },
  loadChildren: async (params) => {
    return Api.get(URL_GET_CHILD_SAFETY_DIARY, params);
  },
  getListPositions:async (params) => {
    return Api.get(URL_GET_LIST_POSITIONS, params);
  },
  report: async (params) => {
    return Api.post(URL_REPORT, params);
  },
  getListSafetyJump: (params) => {
    return Api.get(URL_GET_LIST_JUMP, params);
  },
};
