<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row no-gutters>
                <v-col cols="12" sm="12" md="12">
                  <v-row no-gutters>
                    <v-col cols="3" sm="3" md="3">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="日付" :editable="editable" required>
                            <InputDatepicker
                              name="entries_date"
                              :editable="editable"
                              :values="formValues.safety_diaries"
                              validation_label="日付"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col></v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="3" sm="3" md="3">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="工事" :editable="editable" required>
                            <Select
                              class="select-position"
                              name="field_construction_id"
                              :values="formValues.safety_diaries"
                              :items="FIELD_CONSTRUCTION_ARRAY"
                              :editable="editable"
                              validation_label="工事"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="3" sm="3" md="3">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="作業時間帯" :editable="editable" required>
                            <Select
                              name="work_hour_zone"
                              :values="formValues.safety_diaries"
                              :editable="editable"
                              :items="TIME_ZONE_ARRAY"
                              validation_label="作業時間帯"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col sm="2">
                      <v-list-item>
                          <Label label="メール通知" :editable="editable">
                            <SwitchInput
                              name="mail_notification_flag"
                              :label="`連絡を送信する`"
                              :values="formValues.safety_diaries"
                              :editable="editable"
                              @onInput="onInput"
                            />
                          </Label>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col class="max-h" sm="3">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="会社" :editable="editable" required>
                            <Select
                              name="field_tree_id"
                              :values="formValues.safety_diaries"
                              :items="COMPANY_PARTNER_ARRAY"
                              item_text="company_name"
                              item_value="field_tree_id"
                              :editable="editable"
                              :label="formValues.safety_diaries ?  formValues.safety_diaries.field_tree_conpany_name : ''"
                              validation_label="会社"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col class="max-h" sm="3">
                      <v-row>
                        <v-col sm="6">
                          <v-list-item>
                            <v-list-item-content>
                              <Label label="記入者" :editable="editable" required>
                                <span @click="editable ? openPopUpSearchDetectUser() : null" class="select">
                                  <span class="select_wrap">
                                    <Select
                                      name="detect_user_id"
                                      :values="formValues.safety_diaries"
                                      :items="DETECT_USER_ARRAY"
                                      :editable="editable"
                                      :label="formValues.safety_diaries ?  formValues.safety_diaries.detect_user_name : ''"
                                      item_text="user_name"
                                      item_value="user_id"
                                      validation_label="記入者"
                                      validation_rules="required"
                                    />
                                  </span>
                                </span>
                              </Label>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col sm="6">
                          <v-list-item>
                            <v-list-item-content>
                              <Label label="記入者役職" :editable="editable">
                                <Select
                                  name="cmn_mst_safety_diary_journal_type_id"
                                  item_text="journal_type"
                                  :values="formValues.safety_diaries"
                                  :items="JOURNAL_ARRAY"
                                  :editable="editable"
                                  @onInput="onInput"
                                />
                              </Label>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="max-h" sm="6">
                      <v-row>
                        <v-col sm="12" class="min-h-174">
                          <v-list-item v-if="showMail(formValues)">
                            <v-list-item-content>
                              <SectionLabel label="通知対象"/>
                            </v-list-item-content>
                          </v-list-item>
                          <v-row no-gutters v-if="showMail(formValues)">
                            <v-col sm="4">
                              <v-list-item>
                                <v-list-item-content>
                                  <Label label="現場監督" :editable="editable">
                                    <span @click="editable ? openPopUpSearchUserNotifications() : null" class="select">
                                      <span class="select_wrap">
                                        <SelectMulti
                                          name="user_notifications"
                                          :values="formValues.safety_diaries"
                                          :editable="editable"
                                          :items="USER_NOTIFICATIONS_ARRAY"
                                          :multiple="formValues.safety_diaries ? formValues.safety_diaries.user_notifications.length > 0 : false"
                                          item_text="user_name"
                                          label=""
                                        />
                                      </span>
                                    </span>
                                  </Label>
                                </v-list-item-content>
                              </v-list-item>
                            </v-col>
                            <v-col>
                              <v-list-item>
                                <v-list-item-content>
                                  <Label label="協力会社" :editable="editable">
                                    <span @click="editable ? openPopUpSearchFieldTreeNotifications() : null" class="select">
                                      <span class="select_wrap">
                                        <SelectMulti
                                          name="field_tree_notifications"
                                          :values="formValues.safety_diaries"
                                          :editable="editable"
                                          :multiple="formValues.safety_diaries ? formValues.safety_diaries.field_tree_notifications.length > 0 : false"
                                          :items="FIELD_TREE_NOTIFICATIONS_ARRAY"
                                          item_text="company_name"
                                          item_value="field_tree_id"
                                          label=""
                                        />
                                      </span>
                                    </span>
                                  </Label>
                                </v-list-item-content>
                              </v-list-item>
                            </v-col>
                            <v-col>
                              <v-list-item>
                                <v-list-item-content>
                                  <Label label="作業グループ" :editable="editable">
                                    <span @click="editable ? openPopUpSearchGroupNotifications() : null" class="select">
                                      <span class="select_wrap">
                                        <SelectMulti
                                          name="group_notifications"
                                          :values="formValues.safety_diaries"
                                          :editable="editable"
                                          :multiple="formValues.safety_diaries ? formValues.safety_diaries.group_notifications.length > 0 : false"
                                          :items="GROUP_NOTIFICATIONS_ARRAY"
                                          item_text="group_name"
                                          label=""
                                        />
                                      </span>
                                    </span>
                                  </Label>
                                </v-list-item-content>
                              </v-list-item>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col sm="3">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="種別" :editable="editable">
                            <Select
                              name="cmn_mst_safety_diary_type_id"
                              item_text="type"
                              :values="formValues.safety_diaries"
                              :items="PATROL_TYPE"
                              :editable="editable"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col sm="3">
                      <v-list-item style="width: 35vw">
                        <v-list-item-content>
                          <Label label="緊急度" :editable="editable" style="width: 21vw">
                            <RadioGroup
                              v-if="editable"
                              name="urgency"
                              class="mt-2"
                              :values="formValues.safety_diaries"
                              :items="URGENCY_CLASSIFICATION_RADIO"
                              :checkRow="true"
                              :editable="editable"
                              @onInput="onInput"
                            />
                          </Label>
                          <span v-if="!editable" class="ml-1 mt-1">{{
                              getRadioName(
                                URGENCY_CLASSIFICATION_RADIO,
                                formValues
                              )
                            }}</span>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="2">
                      <v-list-item>
                          <Label label="安全衛生日誌" :editable="editable" class="ml-3">
                            <SwitchInput
                              name="print_diary_flag"
                              :label="`反映する`"
                              :values="formValues.safety_diaries"
                              :editable="editable"
                              @onInput="onInput"
                            />
                          </Label>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
      <PopupNotification width="480px" :dialog="popups.isShowChooseUserNotification">
        <ChooseUserNotificationPatrols
          :listUser="formValues.safety_diaries ? formValues.safety_diaries.user_notifications : []"
          :field_construction_id="formValues.safety_diaries ? formValues.safety_diaries.field_construction_id : null"
          @clickButton="clickOutSiteChooseUserNotification"
          @updateChecked="updateCheckedUserNotification"
          @close="closeChooseUser" />
      </PopupNotification>
      <PopupNotification width="480px" :dialog="popups.isShowChooseFieldTreeNotification">
        <ChooseFieldTreeNotificationPatrols
          :listFieldTree="formValues.safety_diaries ? formValues.safety_diaries.field_tree_notifications : []"
          :field_construction_id="formValues.safety_diaries ? formValues.safety_diaries.field_construction_id : null"
          @clickButton="clickOutSiteChooseFieldTreeNotification"
          @updateChecked="updateCheckedFieldTreeNotification"
          @close="closeChooseFieldTree" />
      </PopupNotification>
      <PopupNotification width="480px" :dialog="popups.isShowChooseGroupNotification">
        <ChooseGroupNotificationPatrols
          :listGroup="formValues.safety_diaries ? formValues.safety_diaries.group_notifications : []"
          :field_construction_id="formValues.safety_diaries ? formValues.safety_diaries.field_construction_id : null"
          @clickButton="clickOutSiteChooseGroupNotification"
          @updateChecked="updateCheckedGroupNotification"
          @close="closeChooseGroup" />
      </PopupNotification>
      <Popup width="480px" :dialog="popups.isShowSearchDetectUser">
        <SearchDetectUser
          :isPartnerLevel1="isPartnerLevel1"
          :field_construction_id="formValues.safety_diaries ? formValues.safety_diaries.field_construction_id : null"
          :field_tree_id="formValues.safety_diaries ? formValues.safety_diaries.field_tree_id : null"
          @updateData="updateDataDetectUser"
          @close="closePopUpSearchDetectUser" />
      </Popup>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "@/components/forms/elements/Label.vue";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import Select from "@/components/forms/elements/Select.vue";
import SwitchInput from "@/components/forms/elements/SwitchInput";
import RadioGroup from "@/components/forms/elements/RadioGroup";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import InputTextArea from "@/components/forms/elements/InputTextArea";
import Popup from "@/components/common/Popup";
import PopupNotification from "@/views/schedule/patrols/components/PopupNotification";
import ChooseUserNotificationPatrols from "@/components/forms/schedule/patrols/components/ChooseUserNotificationPatrols.vue";
import ChooseFieldTreeNotificationPatrols from "@/components/forms/schedule/patrols/components/ChooseFieldTreeNotificationPatrols.vue";
import ChooseGroupNotificationPatrols from "@/components/forms/schedule/patrols/components/ChooseGroupNotificationPatrols.vue";
import {
  URGENCY_CLASSIFICATION_RADIO,
  TYPE
} from "@/constants/PATROL_RECORD";
import SearchDetectUser from "@/components/forms/schedule/patrols/components/SearchDetectUser.vue";
import { ROLE_SUPERVISOR } from "@/constants/COMMON";
import _ from "lodash";
import SelectMulti from "@/views/schedule/patrols/components/SelectMulti.vue";

export default {
  data: () => {
    return {
      TYPE,
      formValues: {},
      safetyDiaryCommentCorrectionsValues: {},
      FIELD_CONSTRUCTION_ARRAY: [],
      TIME_ZONE_ARRAY: [],
      COMPANY_PARTNER_ARRAY: [],
      DETECT_USER_ARRAY: [{ user_id: null, user_name: "" }],
      JOURNAL_ARRAY: [{ id: null, journal_type: "" }],
      USER_NOTIFICATIONS_ARRAY: [{ id: null, user_name: "" }],
      FIELD_TREE_NOTIFICATIONS_ARRAY: [{ field_tree_id: null, company_name: "" }],
      GROUP_NOTIFICATIONS_ARRAY: [{ id: null, group_name: "" }],
      PATROL_TYPE: [{ id: 2, type: "共通連絡" }, { id: 3, type: "安全巡視" }],
      URGENCY_CLASSIFICATION_RADIO,
      popups: {
        isShowChooseUserNotification: false,
        isShowChooseFieldTreeNotification: false,
        isShowChooseGroupNotification: false,
        isShowSearchDetectUser : false
      },
      dataChooseUserNotification: [],
      dataChooseFieldTreeNotification: [],
      dataChooseGroupNotification: [],
      isPartnerLevel1 : false,
      WORK_PLACE_ARRAY: [],
    };
  },
  components: {
    InputText,
    SectionLabel,
    RadioGroup,
    Label,
    InputDatepicker,
    Select,
    SwitchInput,
    InputTextArea,
    Popup,
    ChooseUserNotificationPatrols,
    ChooseFieldTreeNotificationPatrols,
    ChooseGroupNotificationPatrols,
    SearchDetectUser,
    SelectMulti,
    PopupNotification,
  },
  props: {
    item: Object,
    mainHeight: {
      type: Number,
      default: 0
    },
    editable : Boolean
  },
  mounted() {
    this.getListConstruction();
    this.getUserRole();
    this.$watch(
      () => this.item,
      (data) => {
        this.formValues = { ...data };
        if (Array.isArray(data.safety_diary_comment_corrections)) {
          this.safetyDiaryCommentCorrectionsValues = data.safety_diary_comment_corrections[0];
        }
        if (!this.editable && data.safety_diaries.id) {
          this.getDataNotifications();
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => this.formValues.safety_diaries.field_construction_id,
      (newValue, oldValue) => {
        if (newValue && JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          try {
            this.$emit('showLoading', true);
            this.getWorkTimeZones(newValue);
            this.getListCompanyPartner(newValue);
          } catch (e) {
            this.$emit('showLoading', false);
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => this.formValues.safety_diaries.detect_user_id,
      (newValue, oldValue) => {
        if (newValue && JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          try {
            this.$emit('showLoading', true);
            this.getListPositions(newValue);
          } catch (e) {
            this.$emit('showLoading', false);
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => Store.getters["PortalChart/getFieldTimeZones"],
      (data) => {
        if (data.length > 0) {
          this.TIME_ZONE_ARRAY = [...data];
          if (!this.formValues.safety_diaries.work_hour_zone) {
            const formValues = { ...this.formValues };
            formValues["safety_diaries"]["work_hour_zone"] = data[0].id;
            this.formValues = { ...formValues };
            this.$emit("formUpdate", formValues);
          }
        } else {
          this.TIME_ZONE_ARRAY = [this.getDefaultArrays("id", "name")];
        }
      },
      {
        deep: true
      }
    );
    this.$watch(
      () => Store.getters["PortalChart/getListCompanyPartnerLevel1"],
      (data) => {
        if (data) {
          this.COMPANY_PARTNER_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name"), ...data];
          if (!this.formValues.safety_diaries.field_tree_id) {
            this.presetData();
          }
        }
      },
      {
        deep: true
      }
    );


    this.$watch(
      () => Store.getters["PortalChart/getListGroup"],
      (data) => {
        if (data && !this.popups.isShowChooseGroupNotification) {
          this.GROUP_NOTIFICATIONS_ARRAY = [this.getDefaultArrays("id", "group_name"), ...data];
        }
      },
      {
        deep: true
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getListDirectorsObayashi"],
      (data) => {
        if (data && !this.popups.isShowChooseUserNotification) {
          let rs = [...data];
          rs.forEach(e => {
            e.user_name = e.user_name_sei + " " + e.user_name_mei;
          });
          this.USER_NOTIFICATIONS_ARRAY = [this.getDefaultArrays("id", "user_name"), ...rs];
        }
      },
      {
        deep: true
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getListCompanyPartner"],
      (data) => {
        if (data && !this.popups.isShowChooseFieldTreeNotification) {
          this.FIELD_TREE_NOTIFICATIONS_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name"), ...data];
        }
      },
      {
        deep: true
      }
    );
  
    this.$watch(
      () => Store.getters["PortalChart/getListItemTrees"],
      (data) => {
        this.WORK_PLACE_ARRAY = [this.getDefaultArrays("id", "item_name"), ...data];
        this.$emit("updateWorkPlace", this.WORK_PLACE_ARRAY);
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    USER_ROLE(){
      return Store.getters["Sites/getRole"];
    }
  },
  methods: {
    getDataNotifications() {
      if (this.formValues.safety_diaries.user_notifications.filter(e => e).length > 0) {
        let params = {
          field_construction_id: this.formValues.safety_diaries.field_construction_id,
          state_handling_flag: 0
        };
        Store.dispatch("PortalChart/getFieldDirectorsObayashi", params);
      }
      if (this.formValues.safety_diaries.field_tree_notifications.filter(e => e).length > 0) {
        const params = {
          field_construction_id: this.formValues.safety_diaries.field_construction_id,
          state_handling_flag: 0,
          display_branch_name_flag: 1,
          force_all_partner_flag : 1
        };
        Store.dispatch(`PortalChart/getListCompanyPartner`, { params });
      }
      if (this.formValues.safety_diaries.group_notifications.filter(e => e).length > 0) {
        Store.dispatch(`PortalChart/getListGroups`, {
          field_construction_id: this.formValues.safety_diaries.field_construction_id,
          force_all_group_flag : 1,
          group_name: ""
        });
      }
    },
    async getUserRole() {
      await Store.dispatch(`Report/getUserRole`, this.CURRENT_SITE.field_id);
    },
    async getListConstruction() {
      const response = await Store.dispatch(
        "PortalChart/getWorkTypes",
        this.CURRENT_SITE.field_id
      );
      this.FIELD_CONSTRUCTION_ARRAY = [this.getDefaultArrays("id", "name"), ...response.data.contents.entries];
    },
    async getWorkTimeZones(constructionId) {
      if (constructionId) {
        await Store.dispatch("PortalChart/getFieldTimeZones", constructionId);
      } else {
        this.TIME_ZONE_ARRAY = [this.getDefaultArrays("id", "name")];
      }
    },
    async getListCompanyPartner(constructionId) {
      if (constructionId) {
        let params = {
          field_construction_id: constructionId,
          safety_diary_flag: 1
        };
        await Store.dispatch("PortalChart/getListCompanyPartnerLevel1", { params });
      } else {
        this.COMPANY_PARTNER_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name")];
      }
    },
    async getListPositions(userId) {
      if (this.formValues.safety_diaries.field_construction_id && userId) {
        let params = {
          field_construction_id: this.formValues.safety_diaries.field_construction_id,
          user_id: userId,
          prime_flag: this.COMPANY_PARTNER_ARRAY.find(e => e.field_tree_id == this.formValues.safety_diaries.field_tree_id)?.nest_no !== 0 ? 0 : 1
        };
        if (!this.editable) {
          if (this.formValues.safety_diaries.nest_no === 0) {
            params.prime_flag = 1;
          } else {
            params.prime_flag = 0;
          }
        }
        const rs = await Store.dispatch("PatrolRecord/getListPositions", { params });
        this.JOURNAL_ARRAY = [...rs.data.contents.entries.cmn_mst_safety_diary_journal_types];
        if (this.JOURNAL_ARRAY.length == 0) {
          this.JOURNAL_ARRAY = [this.getDefaultArrays("id", "journal_type")];
        } else {
          if (this.editable) {
            let formValues = _.cloneDeep(this.formValues);
            let position = this.JOURNAL_ARRAY[0].id;
            if (this.JOURNAL_ARRAY.length === 2 &&
              this.JOURNAL_ARRAY.filter(e => e.id == 6).length > 0 &&
              this.JOURNAL_ARRAY.filter(e => e.id == 7).length > 0
            ) {
              position = this.JOURNAL_ARRAY[1].id;
            }
            formValues["safety_diaries"]["cmn_mst_safety_diary_journal_type_id"] = position;
            this.formValues = formValues;
            this.$emit("formUpdate", _.cloneDeep(formValues));
          }
        }
      } else {
        this.JOURNAL_ARRAY = [this.getDefaultArrays("id", "journal_type")];
      }
      this.$emit('showLoading', false);
    },
    getDefaultArrays(id, value) {
      let results = {};
      results[id] = null;
      results[value] = "";
      return results;
    },
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      if (name == "mail_notification_flag") {
        if (!value) {
          formValues["safety_diaries"]["user_notifications"] = [];
          formValues["safety_diaries"]["field_tree_notifications"] = [];
          formValues["safety_diaries"]["group_notifications"] = [];
        }
      }
      if (name == "field_construction_id") {
        formValues["safety_diaries"]["field_tree_id"] = null;
        formValues["safety_diaries"]["detect_user_id"] = null;
        if (!value) {
          this.COMPANY_PARTNER_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name")];
        }
        if (value !== formValues['safety_diaries']['field_construction_id']) {
          formValues["safety_diaries"]["field_tree_notifications"] = [];
          formValues["safety_diaries"]["group_notifications"] = [];
          formValues["safety_diaries"]["user_notifications"] = [];
          this.JOURNAL_ARRAY = [this.getDefaultArrays("id", "journal_type")];
          this.DETECT_USER_ARRAY = [this.getDefaultArrays("user_id", "user_name")];
          this.USER_NOTIFICATIONS_ARRAY = [this.getDefaultArrays("id", "user_name")];
          this.FIELD_TREE_NOTIFICATIONS_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name")];
          this.GROUP_NOTIFICATIONS_ARRAY = [this.getDefaultArrays("id", "group_name")];
        }
      }
      if (name == "field_tree_id") {
        formValues["safety_diaries"]["detect_user_id"] = null;
        formValues["safety_diaries"]["cmn_mst_safety_diary_journal_type_id"] = null;
        this.DETECT_USER_ARRAY = [this.getDefaultArrays("user_id", "user_name")];
        this.JOURNAL_ARRAY = [this.getDefaultArrays("id", "journal_type")];
      }
      if (name == "field_item_tree_id" || name == "pointing_out_detail") {
        formValues["safety_diary_comment_corrections"][0][name] = value;
      } else if (name == "safety_diary_images") {
        formValues["safety_diary_comment_corrections"][0]["safety_diary_images"] = value;
      } else {
        formValues["safety_diaries"][name] = value;
      }
      if (name == "cmn_mst_safety_diary_type_id") {
        let titleImagePage2= this.PATROL_TYPE.filter(e => e.id === value)[0]?.type;
        this.$emit("updateTitlePage2", titleImagePage2);
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    getRadioName(items, value) {
      if (value) {
        return items.find((item) => item.value == value?.safety_diaries?.urgency)?.name;
      }
      return "";
    },
    checkCounter() {
      let count = this.safetyDiaryCommentCorrectionsValues?.pointing_out_detail?.length || "0";
      return `${count}/50`;
    },
    openPopUpSearchUserNotifications() {
      this.popups.isShowChooseUserNotification = true;
    },
    openPopUpSearchFieldTreeNotifications() {
      this.popups.isShowChooseFieldTreeNotification = true;
    },
    openPopUpSearchGroupNotifications() {
      this.popups.isShowChooseGroupNotification = true;
    },
    clickOutSiteChooseUserNotification() {
      this.USER_NOTIFICATIONS_ARRAY = [this.getDefaultArrays("id", "user_name"), ...this.dataChooseUserNotification];
      const formValues = { ...this.formValues };
      if (this.dataChooseUserNotification.length == 0) {
        formValues["safety_diaries"]["user_notifications"] = [];
      } else {
        let rs = [];
        this.dataChooseUserNotification.forEach(e => {
          rs.push(e.id);
        });
        formValues["safety_diaries"]["user_notifications"] = rs;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
      this.closeChooseUser();
    },
    clickOutSiteChooseFieldTreeNotification() {
      this.FIELD_TREE_NOTIFICATIONS_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name"), ...this.dataChooseFieldTreeNotification];
      const formValues = { ...this.formValues };
      if (this.dataChooseFieldTreeNotification.length == 0) {
        formValues["safety_diaries"]["field_tree_notifications"] = [];
      } else {
        let rs = [];
        this.dataChooseFieldTreeNotification.forEach(e => {
          rs.push(e.field_tree_id);
        });
        formValues["safety_diaries"]["field_tree_notifications"] = rs;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
      this.closeChooseFieldTree();
    },
    clickOutSiteChooseGroupNotification() {
      this.GROUP_NOTIFICATIONS_ARRAY = [this.getDefaultArrays("id", "group_name"), ...this.dataChooseGroupNotification];
      const formValues = { ...this.formValues };
      if (this.dataChooseGroupNotification.length == 0) {
        formValues["safety_diaries"]["group_notifications"] = [];
      } else {
        let rs = [];
        this.dataChooseGroupNotification.forEach(e => {
          rs.push(e.id);
        });
        formValues["safety_diaries"]["group_notifications"] = rs;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
      this.closeChooseGroup();
    },
    updateCheckedUserNotification(data) {
      this.dataChooseUserNotification = [...data];
    },
    updateCheckedFieldTreeNotification(data) {
      this.dataChooseFieldTreeNotification = [...data];
    },
    updateCheckedGroupNotification(data) {
      this.dataChooseGroupNotification = [...data];
    },
    closeChooseUser() {
      this.popups.isShowChooseUserNotification = false;
      this.dataChooseUserNotification = [];
    },
    closeChooseFieldTree() {
      this.popups.isShowChooseFieldTreeNotification = false;
      this.dataChooseFieldTreeNotification = [];
    },
    closeChooseGroup() {
      this.popups.isShowChooseGroupNotification = false;
      this.dataChooseGroupNotification = [];
    },
    openPopUpSearchDetectUser() {
      this.popups.isShowSearchDetectUser = true;
      if (this.USER_ROLE == ROLE_SUPERVISOR) {
        let temp = this.COMPANY_PARTNER_ARRAY.find(e => e.field_tree_id == this.formValues.safety_diaries.field_tree_id)?.nest_no == 0;
        this.isPartnerLevel1 = temp ? false : true;
      } else {
        if (this.COMPANY_PARTNER_ARRAY.find(e => e.field_tree_id == this.formValues.safety_diaries.field_tree_id)?.nest_no == 1) {
          this.isPartnerLevel1 = true;
        }
        if (this.COMPANY_PARTNER_ARRAY.find(e => e.field_tree_id == this.formValues.safety_diaries.field_tree_id)?.nest_no > 1) {
          this.isPartnerLevel1 = false;
        }
      }
    },
    closePopUpSearchDetectUser(){
      this.popups.isShowSearchDetectUser= false;
    },
    updateDataDetectUser(item) {
      this.DETECT_USER_ARRAY = [this.getDefaultArrays("user_id", "user_name"), ...[{
        user_id: item.user_id,
        user_name: item.user_name
      }]];
      const formValues = { ...this.formValues };
      formValues["safety_diaries"]["detect_user_id"] = item.user_id;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
      this.closePopUpSearchDetectUser();
    },
    presetData() {
      let companyId = JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user.company_id;
      let fieldTreeId = this.COMPANY_PARTNER_ARRAY.find(e => e.company_id == companyId)?.field_tree_id;
      if (this.USER_ROLE == ROLE_SUPERVISOR) {
        fieldTreeId = this.COMPANY_PARTNER_ARRAY.find(e => e.nest_no == 0)?.field_tree_id;
      }
      let userLogin = Store.getters["Login/getUser"];
      let user_name = `${userLogin.name_sei} ${userLogin.name_mei}`;
      const formValues = { ...this.formValues };
      formValues["safety_diaries"]["field_tree_id"] = fieldTreeId;
      formValues["safety_diaries"]["detect_user_id"] = userLogin.id;
      this.DETECT_USER_ARRAY = [
        { user_id: userLogin.id, user_name: user_name }
      ];
      this.formValues = { ...formValues };
      this.$emit("formUpdate", formValues);
    },
    showMail(formValues) {
      return formValues?.safety_diaries?.mail_notification_flag === 1;
    },
  }
};
</script>
<style lang="scss" scoped>
@import "../../../../style/forms.scss";

.select {
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.select_wrap {
  width: 100%;
  pointer-events: none;
}
::v-deep .v-chip .v-chip__content {
  height: 100%;
  max-width: 6vw;
  display: inline-block !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 14px;
}
::v-deep .v-text-field--filled.v-input--dense.v-text-field--single-line .v-label, .v-text-field--full-width.v-input--dense.v-text-field--single-line .v-label {
  color: black !important;
}

::v-deep .v-select.v-input--dense .v-chip {
  background: #1B9C4F;
  color: white;
}
::v-deep .labelWrapper .label {
  white-space: nowrap !important;
}
.min-h-174 {
  min-height: 174px;
}
::v-deep .FormMainBody{
  max-height: 758.296px;
}
.max-h {
  max-height: 11vh;
}
@media (max-width: 1600px) {
  .max-h {
    max-height: 14vh;
  }
}
</style>
