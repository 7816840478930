<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters>
                    <v-col cols="12" sm="6">
                      <!-- 日付 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="日付" :editable="editable">
                            <InputDatepicker
                              name="work_date"
                              :values="formValues.work_groups"
                              :editable="false"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <!-- 工事 * -->
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="工事" :editable="editable">
                            <InputText
                              name="field_construction_name"
                              :values="formValues.work_groups"
                              :editable="false"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <!-- 作業グループ -->
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="作業グループ" :editable="editable">
                            <InputText
                              name="field_tree_group_name"
                              :values="formValues.works"
                              :editable="false"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <!-- 協力会社 -->
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="協力会社" :editable="editable">
                            <InputText
                              name="field_tree_name"
                              :values="formValues.works"
                              :editable="false"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <!-- 職長 -->
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="作業者" :editable="editable" required>
                            <Select
                              name="employee_user_id"
                              :values="formValues.work_company_results"
                              :editable="editable"
                              :items="employeeList"
                              :label="employeeNameLabel"
                              validation_label="作業者"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <Label label="職種" class="ml-4"></Label>
                    </v-col>
                    <v-col>
                      <Label label="経験年数" class="ml-4"></Label>
                    </v-col>
                  </v-row>
                  <v-main
                    v-for="(element, index) in USER_EXPERIENCES"
                    :key="index"
                  >
                    <v-row no-gutters>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <InputText
                              name="name"
                              :values="getSkills(element.cmn_mst_skills_id)"
                              :editable="false"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <InputText
                              name="experience"
                              :values="element"
                              :editable="false"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-main>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="作業時間帯" :editable="editable">
                            <InputText
                              name="work_hour_zone_name"
                              :values="formValues.work_groups"
                              :editable="false"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label label="作業場所" :editable="editable">
                            <Select
                              class="select-position"
                              name="field_item_tree_id"
                              :values="formValues.work_company_results"
                              :editable="editable"
                              :items="WORK_PLACE"
                              item_text="item_name"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "../../elements/Label";
import InputText from "../../elements/InputText";
import InputDatepicker from "../../elements/InputDatepicker.vue";
import Select from "../../elements/Select.vue";
import { Store } from "@/store/Store.js";

export default {
  data: () => {
    return {
      formValues: {},
      EMPLOYEE_USER_ARRAY: [],
      CMN_MST_SKILLS:
        Store.state.CmnMst.constants.entries.cmn_mst_skills,
      WORK_PLACE: [],
      USER_EXPERIENCES: [],
    };
  },
  components: {
    Label,
    InputText,
    InputDatepicker,
    Select,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
        if(this.editable && this.formValues?.work_company_results?.employee_user_id) {
          this.EMPLOYEE_USER_ARRAY.forEach((item) => {
            if (item.id == this.formValues.work_company_results.employee_user_id) {
              this.USER_EXPERIENCES = item.user_experiences;
              return;
            }
          });
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["PortalChart/getListItemTrees"],
      (data) => {
        const array = [...data];
        array.unshift({ id: null, item_name: "" });
        this.WORK_PLACE = [...array];
      },
      {
        deep: true,
      }
    );

    this.getEmployeeUserArray();
    this.getWorkPlace(this.item.work_groups.field_construction_id);
  },
  computed: {
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    employeeList() {
      return this.EMPLOYEE_USER_ARRAY.filter(e => e.state_handling_flag === 0 || e.id === null);
    },
    employeeNameLabel() {
      return this.formValues.work_company_results ? this.formValues.work_company_results.employee_user_name : null;
    }
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      if(name == 'employee_user_id') {
        this.USER_EXPERIENCES = this.EMPLOYEE_USER_ARRAY.find(
          (element) => element?.id == value
        )?.user_experiences;
      }
      formValues["work_company_results"][name] = value;
      this.$emit("formUpdate", formValues);
    },

    async getEmployeeUserArray() {
      if (this.formValues.works.field_tree_id) {
        const params = {
          id: this.formValues.works.field_tree_id,
        };
        const response = await Store.dispatch(
          `PortalChart/getListCompanyPartnerUser`,
          params
        );
        const foremans = [];
        response.data.contents.entries.forEach((item) => {
          foremans.push({
            id: item.user_id,
            state_handling_flag: item.state_handling_flag,
            name: item.user_name_sei + " " + item.user_name_mei,
            user_experiences: item.user_experiences,
          });
        });
        this.EMPLOYEE_USER_ARRAY = [{ id: null, name: "" },...foremans];
        if (
          !this.formValues.work_company_results.employee_user_id &&
          this.editable
        ) {
          this.formValues.work_company_results.employee_user_id =
            this.EMPLOYEE_USER_ARRAY[0].id;
        }
        this.EMPLOYEE_USER_ARRAY.forEach((item) => {
          if (item.id == this.formValues.work_company_results.employee_user_id) {
            this.USER_EXPERIENCES = item.user_experiences;
            return;
          }
        });
      }
    },
    getSkills(cmn_mst_skills_id) {
      let skill = this.CMN_MST_SKILLS.find((e) => e.id == cmn_mst_skills_id);
      if (skill) {
        return { name: skill.catergory + " " + skill.sub_catergory };
      }
      return { name: "" };
    },
    async getWorkPlace(val) {
      if (val) {
        let params = {
          field_id: this.CURRENT_SITE.field_id,
          field_construction_id: val
        };
        await Store.dispatch("PortalChart/getListItemTrees", { params });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
.row-position{
  position: relative;
  height: 150px;
  margin-top: -20px;
}

.select-position{
  position: absolute;
  width: 100%;
}
</style>
