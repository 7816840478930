<template>
  <div ref="self" class="box">
    <v-card class="inner" :id="`box_${item.id}`" @click="openClickBox(item)">
      <div>
        <div class="box-header">
          <div></div>
          <div class="update_date">
            {{ item.update_date }}
          </div>
          <!-- 状態表示 -->
          <div v-if="level != 1">
            <!-- OK -->
            <div v-if="item.form_status_code === APPROVED_FLAGS.APPROVED.id">
              <MarkApproved />
            </div>
            <!-- NG -->
            <div v-else>
              <MarkRejected />
            </div>
          </div>
          <div v-else class="approved_flag" />
        </div>
        <div class="box-body">
          <div>
            <!-- 会社名 -->
            <p class="work_group_name" v-if="item.invite_status !== 0">
              {{ convertString(item.field_tree_group_name, 15) }}
            </p>
            <!-- 会社名 -->
            <p class="company_name">
              {{ convertCompanyName(item, 20) }}
            </p>
          </div>
          <div>
            <!-- ダウンロードボタン -->
            <v-btn class="zip_download" small icon @click="onClickDownload">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 18H4V8H20V18ZM20 6H12L10 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V8C22 7.46957 21.7893 6.96086 21.4142 6.58579C21.0391 6.21071 20.5304 6 20 6ZM14 9H16V13H19L15 17L11 13H14V9Z"
                  fill="black"
                />
              </svg>
            </v-btn>
          </div>
        </div>
      </div>
    </v-card>
    <div class="line" :style="lineStyle"></div>
  </div>
</template>
<script>
import { COMPANY_LEVEL, APPROVED_FLAGS } from "@/constants/SAFETY_CHART.js";
import MarkApproved from "../MarkApproved";
import MarkRejected from "../MarkRejected";
import { Store } from "@/store/Store.js";

export default {
  data: () => {
    return {
      APPROVED_FLAGS,
      lineRect: { x: null, y: null, width: null, height: null },
      isDownload: true,
    };
  },
  props: {
    item: {
      type: Object,
    },
    level: {
      type: Number,
      default: 0,
    },
    parentId: {
      type: Number,
    },
    // same area index
    areaIndex: {
      type: Number,
    },
  },
  components: {
    MarkApproved,
    MarkRejected,
  },
  mounted() {
    this.$watch(
      () => [this.parentId, this.areaIndex],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.setLine();
        }
      },
      {
        immediate: true,
      }
    );
  },
  computed: {
    lineStyle() {
      const rect = { ...this.lineRect };
      return `left:${rect.x}px;top:${rect.y - this.scrollY}px;width:${
        rect.width
      }px;height:${rect.height}px`;
    },
    scrollY() {
      return this.$window.pageYOffset;
    },
    /**
     * 元請け判定
     */
    isOwner() {
      return this.level === COMPANY_LEVEL.OWNER;
    },
  },
  methods: {
    onClickDownload() {
      this.isDownload = false;
      Store.dispatch("SafetyChart/getDownloadId", this.item.id);
    },

    openClickBox(item) {
      if (this.isDownload) {
        this.$emit("onClickBox", {
          companyId: item.company_id,
          companyName: item.company_name,
          chartId: item.id,
        });
      }
      this.isDownload = true;
    },

    /**
     * 線を氷河
     */
    setLine() {
      const self = this.$refs.self;
      const parent = document.getElementById(`box_${this.parentId}`);
      if (self && parent) {
        const selfRect = self.getBoundingClientRect();
        const parentRect = parent.getBoundingClientRect();
        const lineRect = {
          x: parentRect.x + parentRect.width + 16,
          y: parentRect.y + parentRect.height / 2 - 24, // reduce margin
          width: selfRect.x - (parentRect.x + parentRect.width) - 16,
          height: selfRect.y - parentRect.y,
        };
        if (this.areaIndex === 0) {
          lineRect.x = lineRect.x - 16;
          lineRect.width = lineRect.width + 16;
        }
        this.lineRect = lineRect;
      }
    },

    convertString(text, maxlength) {
      let result = "";
      if (text) {
        result = text;
        if (text.length > maxlength) {
          result = text.substring(0, maxlength) + " •••";
        }
      }
      return result;
    },
    convertCompanyName(item, maxlength) {
      let rs = item.company_name || "";
      if (item.company_branch_name) {
        rs += "（" + item.company_branch_name + "）";
      }
      if (rs) {
        if (rs.length > maxlength) {
          rs = rs.substring(0, maxlength) + " •••";
        }
      }
      return rs;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.approved_flag {
  min-height: 21px;
}
.box {
  .inner {
    height: auto;
    width: 220px;
    margin-top: 24px;
    background-color: white;
    color: black;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  }
  .line {
    position: absolute;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
  }
  p {
    padding: 2px 0;
    margin: 0;
  }

  .company_name {
    font-size: 14px;
    color: $color_text_main;
  }

  .work_group_name {
    font-size: 9px;
    color: $color_text_sub;
  }
}
.box-header {
  display: flex;
  justify-content: flex-end;
}

.box-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.update_date {
  text-align: right;
  margin-right: 8px;
  color: $color_text_sub;
  font-weight: normal;
  font-size: 12px;
}
.zip_download {
  &:disabled {
    opacity: 0.2;
  }
}
</style>
