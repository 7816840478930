<template>
  <div>
    <div>
      <ValidationObserver ref="treeValidate" v-slot="treeValidate">
        <FormDialog>
          <template #header>
            <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
              <v-toolbar-title color="primary" class="title">
                {{ TITLE_FORM_MACHINE_CLASSIFY }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                v-if="editable"
                class="mr-5"
                depressed
                small
                outlined
                color="primary"
                @click="isShowConfirmDialog = true"
              >
                やめる
              </v-btn>
              <v-btn
                v-if="editable"
                depressed
                small
                color="primary"
                @click="update"
                :disabled="!valid || isSubmitted"
                data-testid="btn-submit"
              >
                保存
              </v-btn>
              <v-btn
                v-if="!editable"
                depressed
                small
                color="primary"
                @click="onEditable"
              >
                編集
              </v-btn>
              <v-btn icon @click="onClickBtnClose">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <template v-slot:extension>
                <v-tabs v-model="tab" align-with-title>
                  <v-tabs-slider color="primary"></v-tabs-slider>
                  <v-tab
                    v-for="(form, key) in TITLE_FORM_CREATE_MACHINE_TYPE"
                    :key="key"
                  >
                    {{ form.title }}
                  </v-tab>
                </v-tabs>
              </template>
            </v-app-bar>
          </template>
          <template #main="{ params }">
            <div
              :style="`height:${params.mainHeight - 80}px;`"
              class="machine__wrap"
            >
              <transition name="fade">
                <div class="loading" v-if="!isLoaded">
                  <half-circle-spinner
                    :animation-duration="1000"
                    :size="60"
                    :color="'#61d690'"
                  />
                </div>
              </transition>
              <div v-if="companyTree && isLoaded">
                <ListItems
                  v-for="(item, index) in companyTree"
                  :item="item"
                  :edit="editable"
                  :imageMap="imageMap"
                  :key="index"
                  @deleteRoot="removeItem"
                  @onChangeValue="onChangeValue"
                  @updateSort="updateSort"
                  @openCheckForm="openCheckForm"
                />
              </div>
            </div>
            <div v-if="editable" class="button__wrap">
              <v-btn depressed small color="primary" @click="addParent">
                追加 <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn depressed small color="primary" @click="sortParent">
                並べ替え <v-icon>mdi-sort-variant</v-icon>
              </v-btn>
            </div>
            <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
            <Popup width="480px" :dialog="isShowConfirmDialog">
              <ConfirmCloseDialog
                title="フォームを閉じる確認"
                text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
                text2="このページから移動してもよろしいですか？"
                @close="onClickBtnCancelForm"
                @yes="closeForm"
                warning
              />
            </Popup>

            <Popup width="480px" :dialog="enableSortItem">
              <SortMachine
                title="機材選択表示順設定"
                text=""
                warning
                :sortItem="[...companyTree]"
                :imageMap="imageMap"
                @close="closeSortPopup"
                @updateRoot="updateRoot"
              />
            </Popup>
          </template>
        </FormDialog>
        <ValidationCallback
          :observer="treeValidate"
          @callback="updateValidate"
        />
      </ValidationObserver>
      <Popup :dialog="isShowCheckForm">
        <CheckListForm
          :checkFormItemId="checkFormItemId"
          @cancel="closeCheckForm"
        />
      </Popup>
    </div>
  </div>
</template>

<script>
import { Store } from "@/store/Store.js";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import ListItems from "./ListItems.vue";
import {
  TITLE_FORM_CREATE_MACHINE_TYPE,
  TITLE_FORM_MACHINE_CLASSIFY,
} from "@/constants/COMPANY_MACHINES";
import SortMachine from "./SortMachine.vue";
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import { dataURLtoFile } from "@/utils/files";
import { getParamsOfUrlReadFile } from "@/utils/viewSourceAuth";
import { IMAGE_NO_FACE } from "@/constants/COMMON.js";
import { v4 as uuidv4 } from "uuid";
import CheckListForm from "./CheckListForm/CheckListForm.vue";
import { HalfCircleSpinner } from "epic-spinners";
import _ from "lodash";

const STORE = "CompanyMachines";

export default {
  data: () => {
    return {
      flatArray: null,
      tab: null,
      isShowConfirmDialog: false,
      TITLE_FORM_MACHINE_CLASSIFY,
      TITLE_FORM_CREATE_MACHINE_TYPE,
      companyTree: null,
      editable: false,
      selectedItems: null,
      valid: true,
      enableSortItem: false,
      isSubmitted: false,
      deletedItems: [],
      imageMap: new Map(),
      isSort: false,
      isShowCheckForm: false,
      checkFormItemId: null,
      isLoaded: false,
    };
  },
  components: {
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    ListItems,
    SortMachine,
    ValidationObserver,
    ValidationCallback,
    CheckListForm,
    HalfCircleSpinner,
  },
  props: {
    item: Object,
  },
  mounted() {
    this.reload();
  },
  computed: {
    getBodyStyle(mainHeight) {
      return `height:${mainHeight}px;`;
    },
    getMachineCompanyType() {
      return Store.getters["CompanyMachines/getMachineCompanyType"];
    },
    isValid() {
      return true;
    },
  },
  methods: {
    generateDefaultItem() {
      return {
        id: uuidv4(),
        newItem: true,
        type: "",
        order_number: this.maxParentOrder() + 1,
        image_data: "",
        image: "",
        childrens: [],
      };
    },
    removeItem(id) {
      this.companyTree = this.companyTree.filter((i) => i.id !== id);
    },
    onChangeValue({ id, item, option }) {
      if (!item.image_url) {
        this.imageMap.set(`image_url${id}`, item.image_data);
      }
      const companyTree = [...this.companyTree];
      const findItemById = (companyTree, id, item) => {
        companyTree.forEach((element, index) => {
          if (element.id === id) {
            if (option === "image") {
              companyTree[index].image_data = item.image_data;
              companyTree[index].image = item.image;
            } else {
              companyTree[index].type = item.type;
            }
          } else {
            if (element.childrens.length > 0) {
              findItemById(element.childrens, id, item);
            }
          }
        });
      };
      findItemById(companyTree, id, item);
      this.companyTree = companyTree;
    },
    updateValidate({ valid, errors }) {
      this.valid = valid;
      this.errors = errors;
    },
    async reload() {
      await this.getCompanyType();
      const companyTree = this.initCompanyTree();
      if (!this.isSort) {
        await this.getImageMap(companyTree);
      }
      this.companyTree = companyTree;
    },
    generatePayload(rawCompanyTree) {
      const generateBase64FileRecursive = (item) => {
        return item.map((child) => {
          if (
            child.childrens &&
            Array.isArray(child.childrens) &&
            child.childrens.length
          ) {
            child.childrens = generateBase64FileRecursive(child.childrens);
          }

          const payload = {
            ...child,
            image_url: child.base64
              ? dataURLtoFile(child.image_url)
              : child.image_url,
          };

          delete payload.base64;
          return payload;
        });
      };

      return generateBase64FileRecursive(rawCompanyTree);
    },

    async update() {
      this.isSubmitted = true;
      const data = this.convertDataBeforeSave();
      const result = await Store.dispatch(
        `${STORE}/updateMachineCompanyType`,
        data
      );
      if (result.hasError) {
        this.isErrorSubmit = true;
        return;
      } else {
        Store.dispatch("Toast/show", {
          message: "登録しました",
        });
        this.isSubmitted = false;
        this.editable = false;
      }
      await this.reload();
    },

    initCompanyTree() {
      const companyTree = _.cloneDeep(this.getMachineCompanyType);
      return this.sortByOrder(companyTree);
    },
    sortByOrder(companyTree) {
      const recursiveSort = (item) => {
        if (item.childrens && Array.isArray(item.childrens)) {
          item.childrens = item.childrens.sort(
            (a, b) => a.order_number - b.order_number
          );
          item.childrens = item.childrens.map(recursiveSort);
        }

        return item;
      };
      companyTree = companyTree.map(recursiveSort);
      this.isLoaded = true;
      return companyTree.sort((a, b) => a.order_number - b.order_number);
    },
    maxParentOrder() {
      if (
        !this.companyTree ||
        !Array.isArray(this.companyTree) ||
        !this.companyTree.length
      )
        return 0;
      return this.companyTree[this.companyTree.length - 1].order_number || 0;
    },
    getCompanyType: async () => {
      const companyUser = JSON.parse(sessionStorage.getItem("COMPANY_USER"))
        .Login.company_user;
      const params = {
        company_id: companyUser.company_id,
        company_branch_id: companyUser.company_branch_id
          ? companyUser.company_branch_id
          : "",
      };
      await Store.dispatch("CompanyMachines/getMachineCompanyType", params);
    },
    async updateSort(payload) {
      await this.updateRoot(payload);
    },
    async updateRoot(payload) {
      this.isSort = true;
      const arr = [...payload].map((item) => {
        return {
          id: item.id,
          order_number: item.order_number,
        };
      });
      const params = {
        machine_company_type_item_trees: arr,
      };

      const rs = await Store.dispatch(
        "CompanyMachines/sortMachineCompanyType",
        params
      );
      if (!rs.hasError) {
        await this.reload();
        Store.dispatch("Toast/show", {
          message: "更新しました",
        });
        this.isSort = false;
      }
    },
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
    formUpdate(params) {
      this.$emit("formUpdate", params);
    },
    closeSortPopup() {
      this.enableSortItem = false;
    },
    onEditable() {
      this.editable = true;
    },
    sortParent() {
      this.enableSortItem = true;
    },
    addParent() {
      if (
        !this.companyTree ||
        !Array.isArray(this.companyTree) ||
        !this.companyTree.length
      ) {
        this.companyTree = [this.generateDefaultItem()];

        return;
      }

      this.companyTree.push(this.generateDefaultItem());
      return;
    },
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },
    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        this.companyTree = this.initCompanyTree();
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },
    convertDataBeforeSave() {
      let deleteField = function (data) {
        return data.map((item) => {
          if (item.newItem) {
            item.id = null;
            item.machine_company_type_item_id = null;
            delete item.newItem;
          }
          delete item.level;
          delete item.base64;
          if (item.childrens && Array.isArray(item.childrens))
            deleteField(item.childrens);
          return item;
        });
      };
      const arr = deleteField(this.companyTree);
      return arr;
    },
    async getImageMap(companyTree) {
      const arr = [...companyTree];
      const imgHandle = async (item) => {
        for (let obj of item) {
          this.setImage(`image_url${obj.id}`, obj.image_url);
          if (obj.childrens) await imgHandle(obj.childrens);
        }
      };
      await imgHandle(arr);
    },
    async setImage(key, value) {
      let _imgMap = _.cloneDeep(this.imageMap);
      const url = await this.getImage(value);
      if (url) {
        _imgMap.set(key, url);
      } else {
        _imgMap.set(key, IMAGE_NO_FACE);
      }
      this.imageMap = _imgMap;
    },
    async getImage(url) {
      let img = "";
      if (url) {
        const params = await getParamsOfUrlReadFile(url);
        const response = await Store.dispatch("File/readFile", params);
        if (!response.hasError) {
          img = window.URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers["content-type"],
            })
          );
        }
      }
      return img;
    },

    openCheckForm(id) {
      this.getMachineTypeInSpec(id);
    },

    async getMachineTypeInSpec(id) {
      await Store.dispatch(`${STORE}/GetMachineCompanyTypeInSpec`, id);
      this.checkFormItemId = id;
      this.isShowCheckForm = true;
    },

    closeCheckForm() {
      this.isShowCheckForm = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.machine__wrap
  overflow: auto
  color: black !important
  
.from-close-btn
  float: right
.button__wrap
  margin-top: 50px
.button__wrap > button
  margin: 0px 10px
.title
  color: #1B9C4F
.loading
  text-align: center
  position: absolute
  color: #fff
  z-index: 9
  padding: 8px 18px
  border-radius: 5px
  left: calc(50% - 45px)
  top: calc(50% - 18px)
.fade-enter-active, .fade-leave-active
  transition: opacity .5s
.fade-enter, .fade-leave-to
  opacity: 0
</style>
