import Api, { Mock } from "../api";
import mock_data from "./mock/workgroups.json";

const PATH = "workgroups";
const URL = `${process.env.VUE_APP_API_BASE_URL}/${PATH}`;

// .envのVUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  console.log("init Mock");
  Mock.onGet(URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_data,
  });

  Mock.onPost(URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPut(URL).reply(200, {
    status_code: 200,
    message: "",
    contents: {},
  });
  Mock.onDelete(URL).reply(200, {
    status_code: 200,
    message: "",
    contents: {},
  });
}

export const workgroups = {
  get: (params) => {
    return Api.get(URL, params);
  },
  post: (params) => {
    console.log("post:", params);
    return Api.post(URL, params);
  },
  put: async (params) => {
    console.log("put:", params);
    return Api.put(URL, params);
  },
  delete: async (params) => {
    console.log("delete:", params);
    return Api.delete(URL, params);
  },
};
