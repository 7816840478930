<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <SectionLabel label="自主検査有効期限" :editable="editable" class="mt-6" />
                    <!-- 年次 + 月次 -->
                    <v-row no-gutters v-if="!editable">
                      <v-col sm="6" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="年次" :editable="editable">
                              <v-text-field
                                v-model="formValues.annual"
                                dense
                                color="primary"
                                :disabled="!editable"
                                :filled="editable"
                              ></v-text-field>
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col sm="6" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="月次" :editable="editable">
                              <v-text-field
                                v-model="formValues.monthly"
                                dense
                                color="primary"
                                :disabled="!editable"
                                :filled="editable"
                              ></v-text-field>
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-row no-gutters v-if="editable">
                      <v-col sm="6" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="年次"
                              :editable="editable"
                              :required="isRequireStartDate"
                            >
                              <InputDatepicker
                                name="annual"
                                :editable="editable"
                                :values="formValues"
                                :min="min"
                                @onInput="onInput"
                                :flagNull="true"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col sm="6" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="月次"
                              :editable="editable"
                              :required="isRequireStartDate"
                            >
                              <InputDatepicker
                                name="monthly"
                                :editable="editable"
                                :values="formValues"
                                :min="min"
                                @onInput="onInput"
                                :flagNull="true"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    
                    <!-- 特定 -->
                    <v-row no-gutters v-if="!editable">
                      <v-col sm="6" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="特定" :editable="editable">
                              <v-text-field
                                v-model="formValues.identification"
                                dense
                                color="primary"
                                :disabled="!editable"
                                :filled="editable"
                              ></v-text-field>
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>

                    <v-row no-gutters v-if="editable">
                      <v-col sm="6" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="特定"
                              :editable="editable"
                            >
                              <InputDatepicker
                                name="identification"
                                :editable="editable"
                                :values="formValues"
                                :min="min"
                                @onInput="onInput"
                                :flagNull="true"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>

                    <!-- 性能検査 + 自動車検査 -->
                    <v-row no-gutters v-if="!editable">
                      <v-col sm="6" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="性能検査" :editable="editable">
                              <v-text-field
                                v-model="formValues.performance_test"
                                dense
                                color="primary"
                                :disabled="!editable"
                                :filled="editable"
                              ></v-text-field>
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col sm="6" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="自動車検査" :editable="editable">
                              <v-text-field
                                v-model="formValues.vehicle_inspection"
                                dense
                                color="primary"
                                :disabled="!editable"
                                :filled="editable"
                              ></v-text-field>
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-row no-gutters v-if="editable">
                      <v-col sm="6" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="性能検査"
                              :editable="editable"
                            >
                              <InputDatepicker
                                name="performance_test"
                                :editable="editable"
                                :values="formValues"
                                :min="min"
                                @onInput="onInput"
                                :flagNull="true"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col sm="6" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="自動車検査"
                              :editable="editable"
                              :required="isRequireStartDate"
                            >
                              <InputDatepicker
                                name="vehicle_inspection"
                                :editable="editable"
                                :values="formValues"
                                :min="min"
                                @onInput="onInput"
                                :flagNull="true"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                </v-col>
                
                <v-col cols="12" sm="6" md="6">
                  <SectionLabel label="任意保険" :editable="editable" class="mt-6" />
                  <!-- 対人 + 対物 -->
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label
                            label="対人"
                            :editable="editable"
                          >
                            <InputText
                              name="interpersonal"
                              :values="formValues"
                              :editable="editable"
                              @onInput="onInput"
                              validation_label="対人"
                              validation_rules="money"
                              suffix="千円"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label
                            label="対物"
                            :editable="editable"
                          >
                            <InputText
                              name="objective"
                              :values="formValues"
                              :editable="editable"
                              @onInput="onInput"
                              suffix="千円"
                              validation_label="対物"
                              validation_rules="money"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  
                  <!-- 搭乗者 + その他 -->
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label
                            label="搭乗者"
                            :editable="editable"
                          >
                            <InputText
                              name="passenger"
                              :values="formValues"
                              :editable="editable"
                              @onInput="onInput"
                              suffix="千円"
                              validation_label="搭乗者"
                              validation_rules="money"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label
                            label="その他"
                            :editable="editable"
                          >
                            <InputText
                              name="others"
                              :values="formValues"
                              :editable="editable"
                              @onInput="onInput"
                              suffix="千円"
                              validation_label="その他"
                              validation_rules="money"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                  <!-- 特定 -->
                  <v-row no-gutters v-if="!editable">
                    <v-col sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="有効期限" :editable="editable">
                            <v-text-field
                              v-model="formValues.date_of_expiry"
                              dense
                              color="primary"
                              :disabled="!editable"
                              :filled="editable"
                            ></v-text-field>
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                  <v-row no-gutters v-if="editable">
                    <v-col sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label
                            label="有効期限"
                            :editable="editable"
                          >
                            <InputDatepicker
                              name="date_of_expiry"
                              :editable="editable"
                              :values="formValues"
                              :min="min"
                              @onInput="onInput"
                              :flagNull="true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                  <v-list-item>
                    <v-list-item-content>
                      <Label label="備考" :editable="editable">
                        <InputTextArea
                          name="insurance_remarks"
                          :values="formValues"
                          :editable="editable"
                          validation_label="備考"
                          @onInput="onInput"
                          validation_rules="max:512"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import Label from "@/components/forms/elements/Label.vue";
import InputText from "@/components/forms/elements/InputText.vue";
import InputTextArea from "@/components/forms/elements/InputTextArea.vue";
import SwitchInput from "@/components/forms/elements/SwitchInput.vue";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import SectionLabel from "@/components/forms/elements/SectionLabel.vue";
import { convertCurrentcy } from "@/utils/currentcy";

export default {
  data: () => {
    return {
      formValues: {},
      prohibitionPeriod: {
        prohibition_period: null,
      },
      isRequireStartDate: false,
      isRequireEndDate: false,
      max: new Date().toISOString().slice(0, 10),
      min: new Date().toISOString().slice(0, 10),
    };
  },
  components: {
    ValidationObserver,
    Label,
    InputText,
    InputTextArea,
    InputDatepicker,
    SwitchInput,
    SectionLabel
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    machinesOnsite: Number
  },
  mounted() {
    const future = this.addDays(new Date(), 1);
    this.min = future.toISOString().slice(0, 10);
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      const headerHeight = 48;
      let style = `height:${this.mainHeight - headerHeight}px;`;
      return style;
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      if(name == "interpersonal" || name == "objective" || name == "passenger" || name == "others"){
        if (String(value.replaceAll(",", "")).match(/^[0-9]*$/)) {
          value = value.replaceAll(",", "");
          formValues[name] = convertCurrentcy(value);
        } else {
          formValues[name] = value;
        }
      } else {
        formValues[name] = value;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    addDays(date, days) {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";
</style>
