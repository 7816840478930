import { ENV_CLIENT } from "@/constants/ENV_CLIENT";

/**
 * 体制図の階層
 */
export const COMPANY_LEVEL = {
  OWNER: 1,
  FIRST: 2,
  OTHER: 3,
};

/**
 * 承認フラグ
 */
export const APPROVED_FLAGS = {
  CREATED: { id: 0, name: "", key: "created" },
  REJECTED: { id: 1, name: "差戻し", key: "rejected" },
  APPROVED: { id: 2, name: "承認済", key: "approved" },
  SUBMITED: { id: 3, name: "提出", key: "submited" },
};

export const DOCUMENT_TYPES = {
  DocSystemInfo: {
    id: "gf01",
    title: "施工体制台帳作成建設工事の通知",
    title_ja: "施工体制台帳作成建設工事の通知",
    title_eng: "Notice_of_Construction",
  },
  DocWorkers: {
    id: "gf02",
    title: "作業員名簿",
    title_ja: "作業員名簿",
    title_eng: "Worker_Roster",
  },
  DocForeigners: {
    id: "gf03",
    title: "外国人入場届出書",
    title_ja: "外国人建設就労者等建設現場入場届出書",
    title_eng: "Foreign_Entry_Notification",
  },
  COMMITMENT: {
    id: "gf10",
    title: "誓約書",
    title_ja: "誓約書",
    title_eng: "Subcontracting_Contracts_Pledge",
  },
  DocRecontract: {
    id: "gf04",
    title: "再下請負通知書",
    title_ja: "再下請負通知書",
    title_eng: "Re_Subcontract_Notice",
  },
  DocContractChart: {
    id: "gf05",
    title: "下請負業者編成表",
    title_ja: "下請負業者編成表",
    title_eng: "Subcontract",
  },
  DocSystemNote: {
    id: "gf06",
    title: "施工体制台帳",
    title_ja: "施工体制台帳",
    title_eng: "Construction_System_Ledger",
  },
  DocAccidentPrevent: {
    id: "gf07",
    title: "施工体系図",
    title_ja: "工事作業所災害防止協議会兼施工体系図",
    title_eng: "Construction_Structure",
  },
  DocNewWorker: {
    id: "gf08",
    title: "新規入場者教育",
    title_ja: "新規入場者教育時アンケート",
    title_eng: "Entrance_Questionnaire",
  },
  DocSendOut: {
    id: "gf09",
    title: "送り出し教育",
    title_ja: "送り出し教育資料",
    title_eng: "Entrance_Education_Document",
  },
  DocOther: {
    id: "gf_documents",
    title: "その他",
    title_ja: "その他",
    title_eng: "Other_Document",
  },
  DocSystemConstruction: {
    id: "gf11",
    title: "施工体制台帳管理表",
    title_ja: "施工体制台帳管理表",
    title_eng: "Construction_System_Ledger_Management_Table",
  },
};

/**
 * 元 請けフォーム
 */
export const FORMS_OWNER_COMPANY = {
  DocSystemInfo: DOCUMENT_TYPES.DocSystemInfo,
  DocSystemNote: DOCUMENT_TYPES.DocSystemNote,
  DocAccidentPrevent: DOCUMENT_TYPES.DocAccidentPrevent,
};

export const FORMS_OWNER_COMPANY_OBAYASHI = {
  DocSystemInfo: DOCUMENT_TYPES.DocSystemInfo,
  DocSystemNote: DOCUMENT_TYPES.DocSystemNote,
  DocSystemConstruction: DOCUMENT_TYPES.DocSystemConstruction,
  DocAccidentPrevent: DOCUMENT_TYPES.DocAccidentPrevent,
};

/**
 * １次請けフォーム
 */
export const FORMS_FIRST_COMPANY = {
  DocWorkers: DOCUMENT_TYPES.DocWorkers,
  DocForeigners: DOCUMENT_TYPES.DocForeigners,
  DocRecontract: DOCUMENT_TYPES.DocRecontract,
  COMMITMENT: DOCUMENT_TYPES.COMMITMENT,
  DocContractChart: DOCUMENT_TYPES.DocContractChart,
  DocNewWorker: DOCUMENT_TYPES.DocNewWorker,
  DocSendOut: DOCUMENT_TYPES.DocSendOut,
  DocOther: DOCUMENT_TYPES.DocOther,
};

export const FORMS_FIRST_COMPANY_NOT_OBAYASHI = {
  DocWorkers: DOCUMENT_TYPES.DocWorkers,
  DocForeigners: DOCUMENT_TYPES.DocForeigners,
  DocRecontract: DOCUMENT_TYPES.DocRecontract,
  DocContractChart: DOCUMENT_TYPES.DocContractChart,
  DocNewWorker: DOCUMENT_TYPES.DocNewWorker,
  DocSendOut: DOCUMENT_TYPES.DocSendOut,
  DocOther: DOCUMENT_TYPES.DocOther,
};


/**
 * 2次請け以下フォーム
 */
export const FORMS_OTHER_COMPANY = {
  DocWorkers: DOCUMENT_TYPES.DocWorkers,
  DocForeigners: DOCUMENT_TYPES.DocForeigners,
  DocRecontract: DOCUMENT_TYPES.DocRecontract,
  COMMITMENT: DOCUMENT_TYPES.COMMITMENT,
  DocNewWorker: DOCUMENT_TYPES.DocNewWorker,
  DocSendOut: DOCUMENT_TYPES.DocSendOut,
  DocOther: DOCUMENT_TYPES.DocOther,
};

export const FORMS_OTHER_COMPANY_NOT_OBAYASHI = {
  DocWorkers: DOCUMENT_TYPES.DocWorkers,
  DocForeigners: DOCUMENT_TYPES.DocForeigners,
  DocRecontract: DOCUMENT_TYPES.DocRecontract,
  DocNewWorker: DOCUMENT_TYPES.DocNewWorker,
  DocSendOut: DOCUMENT_TYPES.DocSendOut,
  DocOther: DOCUMENT_TYPES.DocOther,
};
/**
 * 特別なテキスト
 */
export const SAFETY_TEXT = {
  GenerateZipSuccessMessage: "zipの作成が完了したらメールが送信されます",
};

export const SAFETY_DIALOG_DOWNLOAD = {
  TITLE: "労務安全書類を保存",
  TEXT: "ダウンロードの準備ができましたら、お知らせします。書類が作られるまで時間がかかる場合がありますので、しばらくお待ちください。",
  BTN_SUBMIT: "保存",
};

export const SAFETY_DIALOG_CREATE_REPORT = {
  TITLE: " 作成",
  TEXT: "帳票を作成します。",
  BTN_SUBMIT: "作成",
};

export const SAFETY_DIALOG_SEND_REPORT = {
  TITLE: " 提出",
  TEXT: "帳票を提出します。",
  BTN_SUBMIT: "提出",
};

export const SAFETY_DIALOG_APPROVE_REPORT = {
  TITLE: " 承認",
  TEXT: "提出された書類を承認します。",
  BTN_SUBMIT: "承認",
};

export const SAFETY_PERMISSIONS = {
  APPROVE: 1,
  CREATE: 2,
  VIEW: 3,
};

export const SAFETY_DOCUMENT_TYPE = {
  EXCEL: 1,
  PDF: 2,
};

export const TAB_CREATE_DOCUMENT_OWNER = {
  TAB_DOC_SYSTEM_INFO: 0, //gf01
  TAB_DOC_SYSTEM_NOTE: 1, //gf06
  TAB_DOC_ACCIDENT_PREVENT: 2, //gf07
};

export const TAB_CREATE_DOCUMENT_OWNER_OBAYASHI = {
  TAB_DOC_SYSTEM_INFO: 0, //gf01
  TAB_DOC_SYSTEM_NOTE: 1, //gf06
  TAB_DOC_SYSTEM_CONSTRUCTION: 2, //gf11
  TAB_DOC_ACCIDENT_PREVENT: 3, //gf07
};

export const TAB_CREATE_DOCUMENT_FIRST_COMPANY = {
  TAB_DOC_WORKERS: {id:0, code:"GF02"}, //gf02
  TAB_DOC_FOREIGNERS: {id:1, code:"GF03"}, //gf03
  TAB_DOC_RECONTRACT: {id:2, code:"GF04"}, //gf04
  TAB_DOC_COMMITMENT: {id:3, code:"GF10"}, //gf10
  TAB_DOC_CONTRACTCHART: {id:4, code:"GF05"}, //gf05
  TAB_DOC_NEWWORKER: {id:5, code:"GF08"}, //gf08
  TAB_DOC_SENDOUT: {id:6, code:"GF09"}, //gf09
};

export const TAB_CREATE_DOCUMENT_FIRST_COMPANY_NOT_OBAYASHI = {
  TAB_DOC_WORKERS: { id: 0, code: "GF02" }, //gf02
  TAB_DOC_FOREIGNERS: { id: 1, code: "GF03" }, //gf03
  TAB_DOC_RECONTRACT: { id: 2, code: "GF04" }, //gf04
  TAB_DOC_CONTRACTCHART: { id: 3, code: "GF05" }, //gf05
  TAB_DOC_NEWWORKER: { id: 4, code: "GF08" }, //gf08
  TAB_DOC_SENDOUT: { id: 5, code: "GF09" }, //gf09
  TAB_DOC_COMMITMENT: { id: null, code: "GF10" } //gf10
};

export const TAB_CREATE_DOCUMENT_OTHER_COMPANY = {
  TAB_DOC_WORKERS: {id:0, code:"GF02"}, //gf02
  TAB_DOC_FOREIGNERS: {id:1, code:"GF03"}, //gf03
  TAB_DOC_RECONTRACT: {id:2, code:"GF04"}, //gf04
  TAB_DOC_COMMITMENT: {id:3, code:"GF10"}, //gf10
  TAB_DOC_NEWWORKER: {id:4, code:"GF08"}, //gf08
  TAB_DOC_SENDOUT: {id:5, code:"GF09"}, //gf09
};

export const TAB_CREATE_DOCUMENT_OTHER_COMPANY_NOT_OBAYASHI = {
  TAB_DOC_WORKERS: {id:0, code:"GF02"}, //gf02
  TAB_DOC_FOREIGNERS: {id:1, code:"GF03"}, //gf03
  TAB_DOC_RECONTRACT: {id:2, code:"GF04"}, //gf04
  TAB_DOC_NEWWORKER: {id:3, code:"GF08"}, //gf08
  TAB_DOC_SENDOUT: {id:4, code:"GF09"}, //gf09
  TAB_DOC_COMMITMENT: {id:null, code:"GF10"}, //gf10
};

export const TYPE_DOCUMENT = {
  IMAGE: 0,
  PDF: 1,
  OTHER: 2
};

export const MESSAGE_NOT_CAN_SHOW_DOCUMENT = "表示できません";

export const ROLE_OWNER = 3;

export const getTabOwner = () => {
  return process.env.VUE_APP_CLIENT === ENV_CLIENT.OBAYASHI ? TAB_CREATE_DOCUMENT_OWNER_OBAYASHI : TAB_CREATE_DOCUMENT_OWNER;
};

export const getTabFirst = () => {
  return process.env.VUE_APP_CLIENT === ENV_CLIENT.OBAYASHI ? FORMS_FIRST_COMPANY : FORMS_FIRST_COMPANY_NOT_OBAYASHI;
};

export const getTabCompanyFirst = () => {
  return process.env.VUE_APP_CLIENT === ENV_CLIENT.OBAYASHI ? TAB_CREATE_DOCUMENT_FIRST_COMPANY : TAB_CREATE_DOCUMENT_FIRST_COMPANY_NOT_OBAYASHI;
};

export const getTabOrther = () => {
  return process.env.VUE_APP_CLIENT === ENV_CLIENT.OBAYASHI ? FORMS_OTHER_COMPANY : FORMS_OTHER_COMPANY_NOT_OBAYASHI;
};

export const getTabCompanyOrther = () => {
  return process.env.VUE_APP_CLIENT === ENV_CLIENT.OBAYASHI ? TAB_CREATE_DOCUMENT_OTHER_COMPANY : TAB_CREATE_DOCUMENT_OTHER_COMPANY_NOT_OBAYASHI;
};