<template>
  <div class="items">
    <div class="content">
      <div class="line"></div>
      <ul>
        <Item
          :item="item"
          :edit="edit"
          :level="1"
          :imageMap="imageMap"
          @deleteRoot="removeItem"
          @onChangeValue="onChangeValue"
          @updateSort="updateSort"
          @openCheckForm="openCheckForm"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import Item from "./Item.vue";
export default {
  props: {
    item: Object,
    edit: Boolean,
    imageMap: Map,
  },
  components: {
    Item,
  },
  methods: {
    removeItem(id) {
      this.$emit("deleteRoot", id);
    },
    onChangeValue(params) {
      this.$emit("onChangeValue", params);
    },
    updateSort(payload) {
      this.$emit("updateSort", payload);
    },
    openCheckForm(payload) {
      this.$emit("openCheckForm", payload);
    },
  },
  name: "ListItem",
};
</script>

<style scoped>
.items {
  display: flex;
  margin-top: 40px;
}
.line {
  height: 100%;
  width: 10px;
  background: #32c36c;
  margin-left: 75px;
}
.content {
  display: flex;
}
</style>
