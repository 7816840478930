import axios from "axios";
import { Store } from "@/store/Store.js";

// 共通のhttp情報を設定
const instance = axios.create({
  responseType: "blob",
  timeout: 30000,
});

//トークンあれば返す
instance.interceptors.request.use((config) => {
  const GRN = JSON.parse(sessionStorage.getItem("GRN"));
  sessionStorage.getItem("GRN") &&
    (config.headers.Authorization = `${GRN.Login.api_token}`);
  config.url = `${config.url}/`; // ios safari で403になるので最後にスラッシュを追加する
  config.headers["Cache-Control"] = `public max-age=${60 * 10}`; // 最大キャッシュ秒数
  return config;
});

instance.interceptors.response.use(
  (response) => {
    let _response = { ...response };
    _response.hasError = false;

    return _response;
  },
  async (error) => {
    console.log("error", error.response);

    /**
     * 通信エラー
     * - ネットワーク接続がない場合
     * - サーバまでリクエストが届かない
     * など
     */
    if (!error.response) {
      let _error = { ...error };
      _error.hasError = true;
      Store.dispatch(
        "Error/show",
        { status: "500", message: "Call error" },
        { root: true }
      );

      return _error;
    }

    /**
     * サーバエラー
     * - webサーバが返すエラー
     */
    //400,500番台エラー
    const error_status = error.response?.status;
    let error_message = "";
    switch (true) {
      case /404/.test(error_status):
      case /500/.test(error_status):
        error_message = "エラー: " + error.response?.data["message"];
        break;
      case /413/.test(error_status):
        error_message = "コンテンツのサイズが大きすぎます ";
        break;
      default:
        error_message = "システムエラー";
    }
    let _error = { ...error };
    _error.hasError = true;

    Store.dispatch(
      "Error/show",
      {
        status: error_status,
        message: error_message,
      },
      { root: true }
    );

    return _error;
  }
);

export default instance;
