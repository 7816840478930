import Api, { Mock } from "../api";
import entrance_record_list from "./mock/entrance_record_list.json";
import entrance_record_children from "./mock/entrance_record_children.json";
import mock_detail from "./mock/detail_entrance_record.json";

const BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/result_arrivals`;
const GET_LIST_URL = `${BASE_URL}/list`;
const GET_CHILDREN_URL = `${BASE_URL}/get_list_by_field_tree`;
const DELETE_RESULT_ARRIVAL_URL = `${BASE_URL}/delete`;
const UPDATE_APPROVE_URL = `${BASE_URL}/update_status`;
const GET_DETAIL_URL = `${BASE_URL}/detail`;
const UPDATE_URL = `${BASE_URL}/update`;
const CREATE_URL = `${BASE_URL}/create`;

if (Mock) {
    Mock.onGet(GET_LIST_URL).reply(200, {
        status_code: 200,
        message: "",
        result: true,
        contents: entrance_record_list,
    });

    Mock.onGet(GET_CHILDREN_URL).reply(200, {
        status_code: 200,
        message: "",
        result: true,
        contents: entrance_record_children,
    });

    Mock.onGet(new RegExp(`${GET_DETAIL_URL}/[\\d]+`)).reply(200, {
      status_code: 200,
      message: "",
      result: true,
      contents: mock_detail,
    });

    Mock.onPost(UPDATE_URL).reply(200, {
      status_code: 200,
      message: "",
      result: true,
      contents: {},
    });

    Mock.onPost(CREATE_URL).reply(200, {
      status_code: 200,
      message: "",
      result: true,
      contents: {},
    });

    Mock.onPost(DELETE_RESULT_ARRIVAL_URL).reply(200, {
        status_code: 200,
        message: "",
        result: true,
        contents: {},
    });
    Mock.onPost(UPDATE_APPROVE_URL).reply(200, {
        status_code: 200,
        message: "",
        result: true,
        contents: {},
    });
}

export const entranceRecord = {
    getListResultArrival: (params) => {
        return Api.get(GET_LIST_URL, params );
    },
    loadChildren: (params) => {
        return Api.get(GET_CHILDREN_URL,  params );
    },
    deleteResultArrival : (params) => {
        return Api.post(DELETE_RESULT_ARRIVAL_URL, params);
    },
    updateApproveResultArrival: (params) => {
        return Api.post(UPDATE_APPROVE_URL, params);
    },
    getDetail: async (params) => {
      return Api.get(GET_DETAIL_URL, { params});
    },
    update: async (params) => {
      return Api.post(UPDATE_URL, params);
    },
    post: async (params) => {
      return Api.post(CREATE_URL, params);
    },
};
