<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="登録者">
                        <InputText
                          name="create_user"
                          :values="formValues"
                          :editable="false"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="最終更新者">
                        <InputText
                          name="update_user"
                          :values="formValues"
                          :editable="false"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="最終承認者">
                        <InputText
                          name="approval_user"
                          :values="formValues"
                          :editable="false"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="登録日時">
                        <InputText
                          name="created_at"
                          :values="formValues"
                          :editable="false"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="最終更新日時">
                        <InputText
                          name="updated_at"
                          :values="formValues"
                          :editable="false"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="最終承認日時">
                        <InputText
                          name="approval_date"
                          :values="formValues"
                          :editable="false"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "../../elements/Label";
import InputText from "../../elements/InputText";

export default {
  data: () => {
    return {
      formValues: {},
    };
  },
  components: {
    Label,
    InputText,
  },
  props: {
    item: Object,
    mainHeight: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
</style>
