<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <!-- trai -->
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-col>
                        <SwitchInput
                          name="display_fifteen_minute_flg"
                          :label="`人工を15分単位で計算する`"
                          :values="formValues['work_results']"
                          :editable="editable"
                          @onInput="onInput"
                        />
                      </v-col>
                    </v-list-item-content>
                  </v-list-item>
                  <v-row no-gutters>
                    <v-list-item v-if="!editable">
                      <v-list-item-content>
                        <v-col cols="12" sm="6" md="6"><span>請負/常傭</span></v-col>
                      </v-list-item-content>
                    </v-list-item>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label :label="editable ? '契約区分' : ''" :editable="editable">
                            <Select
                              name="dayworker_type"
                              :values="formValues['work_results']"
                              :items="CONTRACT_CLASSIFICATION"
                              :editable="editable"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-list-item>
                    <v-list-item-content>
                      <WorkDayWorker
                        name="work_dayworker_results"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput"
                      />
                    </v-list-item-content>
                  </v-list-item>
                <v-row no-gutters style="margin-left: 15px;">
                  <v-list-item v-if="!editable">
                      <v-list-item-content>
                        <v-col cols="12" sm="3" md="3"><span class="ws-no-wrap">女性</span></v-col>
                        <v-col cols="12" sm="3" md="3"><span class="ws-no-wrap">外国人</span></v-col>
                      </v-list-item-content>
                    </v-list-item>
                  <v-col cols="12" sm="3" md="3">
                      <v-list-item class="pd-6">
                        <v-list-item-content>
                          <Label :label="editable ? '女性' : ''" :editable="editable">
                            <Select
                              name="woman"
                              :values="formValues['work_results']"
                              :items="NUMBER_OF_PEOPLE"
                              :editable="editable"
                              :validation_rules="getValidationRules()"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-list-item class="pd-6 num-people">
                        <v-list-item-content>
                          <Label :label="editable ? '外国人' : ''" :editable="editable">
                            <Select
                              name="non_japanese"
                              :values="formValues['work_results']"
                              :items="NUMBER_OF_PEOPLE"
                              :editable="editable"
                              :validation_rules="getValidationRules()"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                </v-row>
                </v-col>
                <!-- phai -->
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel label="早出" class="mb-4" />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <WorkEarlyTimes
                        name="work_early_over_time_results"
                        :values="formValues"
                        :editable="editable"
                        :total="totalUsers"
                        @onInput="onInput"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel label="残業" class="mb-4" />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <WorkOverTimes
                        name="work_early_over_time_results"
                        :values="formValues"
                        :editable="editable"
                        :total="totalUsers"
                        @onInput="onInput"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "../../elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "../../elements/InputText";
import Select from "../../elements/Select.vue";
import InputTextArea from "../../elements/InputTextArea";
import SwitchInput from "../../elements/SwitchInput";
import WorkDayWorker from "../works/components/WorkDayWorker.vue";
import WorkEarlyTimes from "../works/components/WorkEarlyTimes.vue";
import WorkOverTimes from "../works/components/WorkOverTimes.vue";
import { NUMBER_OF_PEOPLE, CONTRACT_CLASSIFICATION } from "@/constants/SCHEDULE_WORKS";
import { Store } from "@/store/Store.js";

export default {
  data: () => {
    return {
      formValues: {},
      CONTRACT_CLASSIFICATION,
      NUMBER_OF_PEOPLE,
      totalUsers: 0,
      totalNonJapanWoman: 0,
      dataTimeMax: Store.state.PortalChart.workingTime,
    };
  },
  components: {
    Label,
    SectionLabel,
    InputText,
    Select,
    InputTextArea,
    SwitchInput,
    WorkDayWorker,
    WorkEarlyTimes,
    WorkOverTimes,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
        this.totalNonJapanWoman = this.formValues?.work_results?.non_japanese + this.formValues?.work_results?.woman;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      if(name == "display_fifteen_minute_flg") {
        formValues.work_dayworker_results.forEach(item => {
          item.working_temp_hour = value == 1 ? this.dataTimeMax : "1.00";
          this.calculateDayWorkers(item, value);
        })
        formValues['work_results'][name] = value;
      }
      if(name == "dayworker_type" || name == "woman" || name == "non_japanese") {
        formValues['work_results'][name] = value;
      } else {
        formValues[name] = value;
      }
      this.$emit("formUpdate", formValues);
    },
    getValidationRules() {
      let total = 0;
      if (this.formValues && this.formValues.work_dayworker_results) {
        this.formValues.work_dayworker_results.forEach(element => total += element.working_temp_person);
        this.totalUsers = total;
        return `compare-data-selectbox:${total}|compare-total-data-selectbox:${this.totalNonJapanWoman},${total}`;
      }
    },
    calculateDayWorkers(itemEdited, displayFifteenMinuteFlg) {
      let dayworker = 0;
      if(displayFifteenMinuteFlg == 0 && itemEdited.working_temp_person > 0) {
        dayworker = itemEdited.working_temp_person * itemEdited.working_temp_hour;
      } else if(displayFifteenMinuteFlg == 1 && itemEdited.working_temp_person > 0) {
        dayworker = (itemEdited.working_temp_person * itemEdited.working_temp_hour) / this.dataTimeMax;
      }
      itemEdited.dayworker = (itemEdited.working_temp_person != 0) ? `${ parseFloat(Number(dayworker).toFixed(displayFifteenMinuteFlg == 0 ? 2 : 5)) + 0 }` :0;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
.pd-6 {
  padding: 6px !important;
}
.num-people {
  margin-right: 4px;
  margin-left: -4px;
}
.ws-no-wrap {
  white-space: nowrap;
}
</style>
