import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const Weather = {
    namespaced: true,

    state: {
        weather: [],
    },

    mutations: {
        SET_WEATHER(state, payload) {
            state.weather = [...payload];
        },
        RESET_WEATHER(state) {
            state.weather = [];
        },
    },

    actions: {
        async setWeather({ commit }, payload) {
            commit("SET_WEATHER", payload);
        },
        async resetWeather({ commit }) {
            commit("RESET_WEATHER");
        },
    },

    getters: {
        getWeather: (state) => {
            return state.weather;
        },
    },
};
