<template>
  <div class="area">
    <Ui
      :item="item"
      :level="level"
      :parentId="parentId"
      :areaIndex="areaIndex"
      @onClickBox="onClickBox"
    />
    <div class="children">
      <Company
        v-for="(child, index) in item.field_tree_children"
        :parentId="item.id"
        :areaIndex="index"
        :item="child"
        :key="child.id"
        :level="level + 1"
      />
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store";
import Company from "./index.vue";
import Ui from "./ui.vue";
import { COMPANY_LEVEL } from "@/constants/SAFETY_CHART.js";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";

export default {
  name: "Company",
  data() {
    return {
      items: {},
      popups: {
        isShowRemoveDialog: false,
      },
    };
  },
  props: {
    item: {
      type: Object,
    },
    // 親id
    parentId: {
      type: Number,
    },
    //同じエリアの兄弟を識別する
    areaIndex: {
      type: Number,
    },
    level: {
      type: Number,
    },
  },
  components: {
    Company,
    Ui,
  },
  computed: {
    // 元請け
    isOwner() {
      return this.level === COMPANY_LEVEL.OWNER;
    },
    // 1次
    isFirst() {
      return this.level === COMPANY_LEVEL.FIRST;
    },
    // 2次以下
    isOther() {
      return this.level <= COMPANY_LEVEL.OTHER;
    },
  },
  methods: {
    /**
     * @description 協力会社フォームを開く
     */
    async onClickBox({ companyId, companyName, chartId }) {
      let response = null;
      if(this.isOwner) {
        let params = {
          field_tree_id: chartId
        };
        if (ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT) {
          params["is_oba"] = 1;
        }
        response = await Store.dispatch("SafetyChart/getPrimeReports", { params });
      } else {
        response = await Store.dispatch("SafetyChart/getPartnerReports", chartId);
      }
      if(!response.hasError) {
        Store.dispatch("SafetyChart/openCompanyBox", {
          companyId,
          companyName,
          chartId,
          companyLevel: this.level,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.area {
  display: flex;
  padding-left: 32px;
}
</style>
