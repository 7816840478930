<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <!--  (共通) 会社ヘッダー -->
        <SitePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout :layoutParams="layoutParams" :hideFooter="judgeHiddenFooter">
          <template #tableHeader="{ updateHeader }">
            <div>
              <ScheduleHeader
                ref="tableHeader"
                :pageTitle="PAGE_TITLE"
                :multiSelectStatus="disableApproveBtn"
                :updateHeader="updateHeader"
                :searchParams="searchParams"
                :isDirector="isForeman"
                :isCompany="true"
                :isShowGraphButton="false"
                @openRemoveDialog="openRemoveDialog()"
                @onReject="openConfirmDialog(true)"
                @onApprove="openConfirmDialog(false)"
                @onInput="onChangeSearchParams"
              >
                <SearchFormWrapper>
                  <Label label="工事" width="200px">
                    <SearchSites
                      name="field_construction_id"
                      :fieldId="CURRENT_SITE.field_id"
                      @onInput="onChangeSearchParams"
                      :key="resetSearchSite"
                    />
                  </Label>
                  <Label label="作業者" width="200px">
                    <div class="wrapper">
                      <Select
                        name="employee_user_id"
                        :items="EMPLOYEE_ARRAY"
                        :editable="true"
                        :values="searchParams"
                        @onInput="onChangeSearchParams"
                      />
                    </div>
                  </Label>
                  <TabSelect
                    class="mt-8"
                    name="early_over_type"
                    :items="SCHEDULE_COMPANY_STATUS"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />
                  <TabSelect
                    class="mt-8"
                    name="work_approval_flag"
                    :items="SCHEDULE_APPROVE"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mx-2"
                    color="primary"
                    depressed
                    @click="onSearch"
                  >
                    検索
                  </v-btn>
                </SearchFormWrapper>
              </ScheduleHeader>
              <TableTab
                :items="SCHEDULE_TABLE_TAB_ITEMS"
                @onChangeTableTab="onChangeTableTab"
              >
                <v-container class="tableSortWrapper">
                  <v-spacer></v-spacer>
                  <div class="sortLabel">作業時間帯:</div>
                  <div class="sortElement">
                    <Select
                      name="work_hour_zone"
                      :values="searchParams"
                      :items="listWorkHourZones"
                      :editable="true"
                      @onInput="onChangeSearchParams"
                    />
                  </div>
                  <div class="sortLabel">総件数:</div>
                  <div
                    class="sortElement total_item"
                    v-if="isTabScheduleCompanyPlan"
                  >
                    {{ searchParams.all_record_schedule_company }}件
                  </div>
                  <div class="sortElement total_item" v-else>
                    {{ searchParams.all_record_schedule_result }}件
                  </div>
                </v-container>
              </TableTab>
            </div>
          </template>

          <!-- 
            (共通)テーブル
            v-data-table自体は共通ですが、
            カラムによって変更をしたい場合はそれぞれ実装してください。
          -->
          <template #tableBody="{ tableHeight }">
            <ScheduleCompany
              v-if="isTabScheduleCompanyPlan"
              :items="itemsSchedule"
              :date="searchParams.date"
              :tableHeight="tableHeight"
              :isTableTypeSchedule="isTabScheduleCompanyPlan"
              :functions="functions"
              :key="reloadListSchedule"
            />
            <ResultCompany
              v-else
              :items="itemsResult"
              :date="searchParams.date"
              :tableHeight="tableHeight"
              :functions="functions"
              :key="reloadListResult"
            />
          </template>

          <template #tableFooter>
            <!-- (共通) ページネーション -->
            <PaginationScheduleCompany
              v-if="isTabScheduleCompanyPlan"
              :current="searchParams.current_page_schedule_company"
              :total="searchParams.total_page_schedule_company"
              @pageUpdate="pageUpdate"
            />
            <PaginationCompanyResult
              v-else
              :current="searchParams.current_page_schedule_result"
              :total="searchParams.total_page_schedule_result"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>

    <!--  (ユーザー用) 追加/編集ダイアログ -->
    <Popup :dialog="popups.isShowItemForm">
      <ScheduleCompanyForm
        v-if="isTabScheduleCompanyPlan"
        :item="editedItem"
        :isNewItem="isNewItem"
        :beforeUpdateItem="beforeUpdateItem"
        :approvalFlagSelectedItems="approvalFlagSelectedItems"
        :groupStateHandlingFlag="groupStateHandlingFlag"
        @formUpdate="formUpdate"
        @submit="submitForm"
        @cancel="closeItemForm"
        :key="isResetForm"
      />
      <ScheduleCompanyResultForm
        v-else
        :item="editedItem"
        :isNewItem="isNewItem"
        :beforeUpdateItem="beforeUpdateItem"
        :approvalFlagSelectedItems="approvalFlagSelectedItems"
        :groupStateHandlingFlag="groupStateHandlingFlag"
        @formUpdate="formUpdate"
        @submit="submitForm"
        @cancel="closeItemForm"
        :key="isResetForm"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowConfirmDialog">
      <ConfirmDialog
        :dialogText="dialogApproveText"
        :isApproved="isApproved"
        @close="closeApproveDialog()"
        @yes="handleConfirm()"
        :warning="dialogApproveText.flg"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmDialog
        :dialogText="dialogRemoveText"
        :isApproved="isApproved"
        @close="closeRemoveDialog()"
        @yes="onRemove()"
        warning
      />
    </Popup>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import PaginationScheduleCompany from "@/components/masterTable/elements/Pagination";
import PaginationCompanyResult from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";

import ScheduleCompanyForm from "@/components/forms/schedule/company/ScheduleCompanyForm.vue";
import ScheduleCompanyResultForm from "@/components/forms/schedule/company/ScheduleCompanyResultForm.vue";
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import TabSelect from "@/components/forms/elements/TabSelect"; //絞り込みフォームで使用
import ConfirmDialog from "./components/ConfirmDialog.vue";
import TableTab from "../components/TableTab.vue"; //テーブルの切り替え

/**
 * 固有のコンポーネント
 */
import ScheduleHeader from "../components/ScheduleHeader.vue";
import ScheduleCompany from "./components/ScheduleCompanyTable.vue";
import ResultCompany from "./components/ScheduleCompanyTable.vue";
import SearchSites from "../components/SearchSites.vue";
import {
  SCHEDULE_COMPANY_INITIAL_ITEM_PLANS,
  SCHEDULE_COMPANY_INITIAL_ITEM_RESULTS,
  SCHEDULE_COMPANY_STATUS,
  DIALOG_TEXT_PLANS,
  DIALOG_TEXT_RESULTS,
  SCHEDULE_TABLE_TAB_ITEMS,
  DIALOG_REMOVE_TEXT_PLANS,
  DIALOG_REMOVE_TEXT_RESULTS,
  SCHEDULE_APPROVE,
} from "@/constants/SCHEDULE_COMPANY"; //絞り込みフォームで使用
import { format } from "date-fns";
import _ from "lodash";
import { HEADER_MENU_ITEMS_SITE } from "@/constants/GLOBALHEADER";

/**
 * 定数
 * この一覧ページで使用する固有の定数を定義します。
 * 共通に使用する定数は基本的に@/constants/で定義します。
 * - 定数は大文字で定義します
 * - 定数は基本的にはdataに代入しないで直接参照します
 */

//タイトル
const PAGE_TITLE = "自社管理";

//ストア
const STORE = "ScheduleCompany";

const PAGE_COUNT = 25;

//テーブルヘッダーラベル

//フォーム初期値
const INITIAL_ITEM_PLANS = SCHEDULE_COMPANY_INITIAL_ITEM_PLANS;
const INITIAL_ITEM_RESULTS = SCHEDULE_COMPANY_INITIAL_ITEM_RESULTS;

const DATE = format(new Date().setDate(new Date().getDate() + 1), "yyyy-MM-dd");

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      PAGE_TITLE: "自社作業予定",

      /**
       * (共通)
       * 一覧データ
       */
      itemsSchedule: [],
      itemsResult: [],

      /**
       * (共通)
       * checkbox選択item
       */
      selectedItems: [],
      EMPLOYEE_ARRAY: [],

      /**
       * (共通)
       * 編集アイテム
       * 定数から初期値を代入
       */
      editedItem: {
        ...(this.isTabScheduleCompanyPlan
          ? INITIAL_ITEM_PLANS
          : INITIAL_ITEM_RESULTS),
      },

      /**
       * (共通)
       * 新規フラグ
       */
      isNewItem: false,
      isForeman: false,
      // 検索パラメータ
      searchParams: {
        date: DATE, //初期値は今日
        field_construction_id: null,
        table_type: SCHEDULE_TABLE_TAB_ITEMS.SCHEDULE_COMPANY.id,
        employee_user_id: null,
        work_hour_zone: null,
        early_over_type: [],
        work_approval_flag: [],
        page_count_schedule_company: PAGE_COUNT,
        current_page_schedule_company: 1,
        total_page_schedule_company: 1,
        page_count_schedule_result: PAGE_COUNT,
        current_page_schedule_result: 1,
        total_page_schedule_result: 1,
        all_record_schedule_company: 0,
        all_record_schedule_result: 0,
      },

      /**
       * (共通)
       * ポップアップの状態管理
       */
      popups: {
        // 追加/編集フォーム
        isShowItemForm: false,
        isShowConfirmDialog: false,
        isShowRemoveDialog: false,
      },

      /**
       * まとめてメソッドを子コンポーネントに渡す
       */
      functions: {
        updateSelectedItems: this.updateSelectedItems,
        openItemForm: this.openItemForm,
        getChildItems: this.getChildItems,
        openForm: this.openNewItemForm,
      },
      dialogApproveText: {
        title: "",
        text1: "",
        text2: "",
        btnSubmitText: "",
        flg: false,
      },
      dialogRemoveText: {
        title: "",
        text1: "",
        text2: "",
        text3: null,
        text4: null,
        btnSubmitText: "",
      },
      beforeUpdateItem: {},
      approvalFlagSelectedItems: 0,
      groupStateHandlingFlag: 0,
      isApproved: false,
      isResetForm: false,
      reloadListSchedule: false,
      reloadListResult: false,
      listWorkHourZones: [],
      resetSearchSite: false,
      isShowSearch: false,
    };
  },

  components: {
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    PaginationScheduleCompany,
    PaginationCompanyResult,
    SearchFormWrapper,
    TableSort,
    SitePortalHeader,
    Popup,
    ConfirmDialog,
    ScheduleCompanyForm,
    ScheduleCompanyResultForm,
    Select,
    Label,
    //固有のコンポーネント
    ScheduleHeader,
    TabSelect,
    ScheduleCompany,
    ResultCompany,
    SearchSites,
    TableTab,
  },

  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    await Store.dispatch("GlobalHeader/setSiteMenu", {
      menuId: HEADER_MENU_ITEMS_SITE.SCHEDULE.id,
    });

    this.$watch(
      () => Store.getters["ScheduleSearch/getActive"],
      (value) => {
        this.isShowSearch = value;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getWorkTypes"],
      (data) => {
        if (data.length > 0) {
          if (this.searchParams.field_construction_id) {
            this.getWorkHourZones();
            this.getListEmployeePartner();
          }
        }
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getFieldTimeZones"],
      (data) => {
        if (data.length > 0) {
          this.listWorkHourZones = [...data];
          this.searchParams.work_hour_zone = data[0].id;
          this.getItems();
        }
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Employees/getIsForeman"],
      (data) => {
        this.isForeman = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => [
        Store.getters[`${STORE}/getDataScheduleCompany`],
        Store.getters[`${STORE}/getDataScheduleResult`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        if (data) {
          this.itemsSchedule = [...data[0]];
          this.itemsResult = [...data[1]];
          let searchParams = { ...this.searchParams };
          if (this.isTabScheduleCompanyPlan) {
            searchParams.total_page_schedule_company = data[2].total;
            searchParams.current_page_schedule_company = data[2].current;
            searchParams.total_item_schedule_company = data[2].total_item;
            searchParams.all_record_schedule_company = data[2].all_record;
          } else {
            searchParams.total_page_schedule_result = data[2].total;
            searchParams.current_page_schedule_result = data[2].current;
            searchParams.total_item_schedule_result = data[2].total_item;
            searchParams.all_record_schedule_result = data[2].all_record;
          }
          this.searchParams = searchParams;
        }
      },
      {
        deep: true,
      }
    );
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */
  computed: {
    /**
     * 承認・承認解除ボタンの活性・非活性
     * selectedItems.length === 0
     * 自体はtemplate側で記述できますが、
     * ここに明示的に状態を定義して可読性を担保してます
     */
    disableApproveBtn() {
      return this.selectedItems.length === 0;
    },

    SCHEDULE_COMPANY_STATUS() {
      return Object.keys(SCHEDULE_COMPANY_STATUS).map((key) => {
        return SCHEDULE_COMPANY_STATUS[key];
      });
    },

    /**
     *  (スケジュール)
     * 絞り込み:承認/未承認
     */
    SCHEDULE_APPROVE() {
      return Object.keys(SCHEDULE_APPROVE).map((key) => {
        return SCHEDULE_APPROVE[key];
      });
    },

    /**
     *  (スケジュール)
     * テーブルタブ
     */
    SCHEDULE_TABLE_TAB_ITEMS() {
      return Object.keys(SCHEDULE_TABLE_TAB_ITEMS).map((key) => {
        return SCHEDULE_TABLE_TAB_ITEMS[key];
      });
    },

    /**
     * (スケジュール)
     * 現在の現場を取得
     */
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    isTabScheduleCompanyPlan() {
      return (
        this.searchParams.table_type ===
        SCHEDULE_TABLE_TAB_ITEMS.SCHEDULE_COMPANY.id
      );
    },

    judgeHiddenFooter() {
      return (this.isTabScheduleCompanyPlan && this.searchParams.page_count_schedule_company >= this.searchParams.total_item_schedule_company)
          || (!this.isTabScheduleCompanyPlan && this.searchParams.page_count_schedule_result >= this.searchParams.total_item_schedule_result);
    }
  },

  methods: {
    async callApiCheckIsForeman() {
      const params = {
        user_id: JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login
          .company_user.user_id,
        field_construction_id: this.searchParams.field_construction_id,
      };
      await Store.dispatch("Employees/checkIsForeman", { params });
    },
    async getWorkHourZones() {
      Store.dispatch(
        "PortalChart/getFieldTimeZones",
        this.searchParams.field_construction_id
      );
    },
    async getListEmployeePartner() {
      const response = await Store.dispatch(
        `PortalChart/getListCompanyPartnerUserFromUser`,
        this.searchParams.field_construction_id
      );
      if (response.hasErrors) {
        return;
      }
      this.EMPLOYEE_ARRAY = _.cloneDeep(response.data.contents.entries);
      this.EMPLOYEE_ARRAY.forEach((element) => {
        element.name = element.user_name_sei + " " + element.user_name_mei;
        element.id = element.user_id;
      });
      this.EMPLOYEE_ARRAY.unshift({ id: null, name: "" });
    },
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      if (this.isTabScheduleCompanyPlan) {
        searchParams.current_page_schedule_company = n;
      } else {
        searchParams.current_page_schedule_result = n;
      }
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 検索
     */
    onSearch() {
      if (this.isTabScheduleCompanyPlan) {
        this.searchParams.current_page_schedule_company = 1;
      } else {
        this.searchParams.current_page_schedule_result = 1;
      }
      this.getItems();
    },

    formUpdate(params) {
      this.editedItem = { ...params };
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      if (name === "date") {
        searchParams[name] = format(new Date(value), "yyyy-MM-dd");
      } else {
        searchParams[name] = value;
      }
      this.searchParams = searchParams;
      if (name == "date") {
        if (this.isTabScheduleCompanyPlan) {
          this.searchParams.current_page_schedule_company = 1;
        } else {
          this.searchParams.current_page_schedule_result = 1;
        }
      }
      if (name == "work_hour_zone" || name == "date") {
        this.getItems();
      }
      if (name == "field_construction_id") {
        this.callApiCheckIsForeman();
        this.getListEmployeePartner();
      }
    },
    /**
     * タブ変更/テーブル切り替え
     */
    onChangeTableTab(item) {
      if (item.id == 0) {
        this.PAGE_TITLE = "自社作業実績";
      } else {
        this.PAGE_TITLE = "自社作業予定";
      }
      this.selectedItems = [];
      const searchParams = { ...this.searchParams };
      searchParams.table_type = item.id;
      searchParams.employee_user_id = null;
      searchParams.early_over_type = [];
      searchParams.work_approval_flag = [];
      this.resetSearchSite = !this.resetSearchSite;
      if (this.isShowSearch) Store.dispatch("ScheduleSearch/show", !this.isShowSearch);
      this.searchParams = searchParams;
    },

    /**
     * (共通)
     * 作成/編集フォーム
     * INITIAL_ITEM で定義した値がitemに入ります
     * @param INITIAL_ITEMで定義している値
     */
    async openNewItemForm(id) {
      let items = null;
      if (this.isTabScheduleCompanyPlan) {
        let response = await Store.dispatch(
          "ScheduleWorks/getWorkDetailCompanyPlan",
          id
        );
        items = _.cloneDeep(INITIAL_ITEM_PLANS);
        items.works = response.data.contents.entries.work_plans;
        items.work_groups = response.data.contents.entries.work_group_plans;
        items.work_company_plans.work_id = items.works.work_plan_id;
      } else {
        let response = await Store.dispatch(
          "ScheduleRecords/getWorkDetailCompanyResult",
          id
        );
        items = _.cloneDeep(INITIAL_ITEM_RESULTS);
        items.works = response.data.contents.entries.work_results;
        items.work_groups = response.data.contents.entries.work_group_results;
        items.work_company_results.work_id = items.works.work_result_id;
      }
      this.editedItem = items;
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },

    async openItemForm(work_id, work_company_id, work_approval_flag, group_state_handling_flag) {
      this.approvalFlagSelectedItems = work_approval_flag;
      this.groupStateHandlingFlag = group_state_handling_flag;
      const result = await Store.dispatch(
        `${STORE}/getDetail${
          this.isTabScheduleCompanyPlan ? "Plans" : "Results"
        }`,
        work_company_id
      );
      if (!result.hasError) {
        let items = _.cloneDeep(result.data.contents.entries);
        if (this.isTabScheduleCompanyPlan) {
          let response = await Store.dispatch(
            "ScheduleWorks/getWorkDetailCompanyPlan",
            work_id
          );
          items.works = response.data.contents.entries.work_plans;
          items.work_groups = response.data.contents.entries.work_group_plans;
          items.work_company_plans.work_id = items.works.work_plan_id;
        } else {
          let response = await Store.dispatch(
            "ScheduleRecords/getWorkDetailCompanyResult",
            work_id
          );
          items.works = response.data.contents.entries.work_results;
          items.work_groups = response.data.contents.entries.work_group_results;
          items.work_company_results.work_id = items.works.work_result_id;
        }
        this.editedItem = _.cloneDeep(items);
        this.beforeUpdateItem = _.cloneDeep(items);
        this.isNewItem = false;
        this.popups.isShowItemForm = true;
      }
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.$nextTick(() => {
        //選択を初期化
        if (this.isTabScheduleCompanyPlan) {
          this.editedItem = { ...INITIAL_ITEM_PLANS };
        } else {
          this.editedItem = { ...INITIAL_ITEM_RESULTS };
        }
      });
      this.getItems();
    },

    /**
     * true: 承認解除
     * false: 承認
     */
    openConfirmDialog(flg) {
      let temp = flg ? 1 : 0;
      if (this.selectedItems.filter(e => e.state_handling_flag == 0 && e.schedule_approval == temp).length == 0) {
        this.isApproved = true;
      } else {
        const approves = this.selectedItems.filter(
          (item) => item.schedule_approval == 1
        );
        this.isApproved =
          (approves.length == this.selectedItems.length && !flg) ||
          (approves.length == 0 && flg);
      }
      const {
        REJECT_BTN_SUBMIT,
        REJECT_TEXT2,
        REJECT_TEXT1,
        REJECT_TITLE,
        REJECT_ERROR_TEXT1,
        APPROVE_BTN_SUBMIT,
        APPROVE_TEXT2,
        APPROVE_TEXT1,
        APPROVE_TITLE,
        ALL_APPROVE_TTTLE,
        ALL_APPROVE_TEXT,
        BTN_OK,
      } = this.isTabScheduleCompanyPlan ? DIALOG_TEXT_PLANS : DIALOG_TEXT_RESULTS;
      if (this.isApproved) {
        this.dialogApproveText.title = flg ? REJECT_TITLE : ALL_APPROVE_TTTLE;
        this.dialogApproveText.text1 = flg
          ? REJECT_ERROR_TEXT1
          : ALL_APPROVE_TEXT;
        this.dialogApproveText.text2 = null;
        this.dialogApproveText.btnSubmitText = BTN_OK;
      } else {
        this.dialogApproveText.title = flg ? REJECT_TITLE : APPROVE_TITLE;
        this.dialogApproveText.text1 = flg ? REJECT_TEXT1 : APPROVE_TEXT1;
        this.dialogApproveText.text2 = flg ? REJECT_TEXT2 : APPROVE_TEXT2;
        this.dialogApproveText.btnSubmitText = flg
          ? REJECT_BTN_SUBMIT
          : APPROVE_BTN_SUBMIT;
      }
      this.dialogApproveText.flg = flg;
      this.popups.isShowConfirmDialog = true;
    },

    async onRemove() {
      const removeIds = this.selectedItems.filter(
        (item) => item.schedule_approval == 0
      );
      if (this.isTabScheduleCompanyPlan) {
        const work_company_plan_ids = removeIds.map((items) => items.id);
        const result = await Store.dispatch(`${STORE}/deletePlans`, {
          work_company_plan_ids,
        });
        if (!result.hasError) {
          this.searchParams.current_page_schedule_company = 1;
          this.getItems();
          Store.dispatch("Toast/show", {
            status: 200,
            message: "削除しました",
          });
        }
      } else {
        const work_company_result_ids = removeIds.map((items) => items.id);
        const result = await Store.dispatch(`${STORE}/deleteResults`, {
          work_company_result_ids,
        });
        if (!result.hasError) {
          this.searchParams.current_page_schedule_result = 1;
          this.getItems();
          Store.dispatch("Toast/show", {
            status: 200,
            message: "削除しました",
          });
        }
      }
      this.closeRemoveDialog();
    },

    /**
     * handleConfirm
     */
    handleConfirm() {
      if (this.dialogApproveText.flg) {
        this.rejectItems();
      } else {
        this.approveItems();
      }
      this.selectedItems = [];
      if (this.isTabScheduleCompanyPlan) {
        this.reloadListSchedule = !this.reloadListSchedule;
      } else {
        this.reloadListResult = !this.reloadListResult;
      }
    },

    openRemoveDialog() {
      const approves = this.selectedItems.filter(
        (item) => item.schedule_approval == 1
      );
      this.isApproved = approves.length == this.selectedItems.length;
      if (this.isApproved) {
        this.dialogRemoveText = {
          title: this.isTabScheduleCompanyPlan ? DIALOG_REMOVE_TEXT_PLANS.TITLE : DIALOG_REMOVE_TEXT_RESULTS.TITLE,
          text1: this.isTabScheduleCompanyPlan ? DIALOG_REMOVE_TEXT_PLANS.TEXT_ALL_APPROVE_1 : DIALOG_REMOVE_TEXT_RESULTS.TEXT_ALL_APPROVE_1,
          text2: this.isTabScheduleCompanyPlan ? DIALOG_REMOVE_TEXT_PLANS.TEXT_ALL_APPROVE_2 : DIALOG_REMOVE_TEXT_RESULTS.TEXT_ALL_APPROVE_2,
          text3: null,
          text4: null,
          btnSubmitText: this.isTabScheduleCompanyPlan ? DIALOG_REMOVE_TEXT_PLANS.BTN_OK : DIALOG_REMOVE_TEXT_RESULTS.BTN_OK,
        };
      } else {
        this.dialogRemoveText = {
          title: this.isTabScheduleCompanyPlan ? DIALOG_REMOVE_TEXT_PLANS.TITLE : DIALOG_REMOVE_TEXT_RESULTS.TITLE,
          text1: this.isTabScheduleCompanyPlan ? DIALOG_REMOVE_TEXT_PLANS.TEXT_1 : DIALOG_REMOVE_TEXT_RESULTS.TEXT_1,
          text2: this.isTabScheduleCompanyPlan ? DIALOG_REMOVE_TEXT_PLANS.TEXT_2 : DIALOG_REMOVE_TEXT_RESULTS.TEXT_2,
          text3: this.isTabScheduleCompanyPlan ? DIALOG_REMOVE_TEXT_PLANS.TEXT_3 : DIALOG_REMOVE_TEXT_RESULTS.TEXT_3,
          text4: this.isTabScheduleCompanyPlan ? DIALOG_REMOVE_TEXT_PLANS.TEXT_4 : DIALOG_REMOVE_TEXT_RESULTS.TEXT_4,
          btnSubmitText: this.isTabScheduleCompanyPlan ? DIALOG_REMOVE_TEXT_PLANS.BTN_SUBMIT : DIALOG_REMOVE_TEXT_RESULTS.BTN_SUBMIT,
        };
      }
      this.popups.isShowRemoveDialog = true;
    },

    /**
     * closeRemoveDialog
     */
    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
        if (this.isTabScheduleCompanyPlan) {
          this.reloadListSchedule = !this.reloadListSchedule;
        } else {
          this.reloadListResult = !this.reloadListResult;
        }
        this.dialogRemoveText = {
          title: "",
          text1: "",
          text2: "",
          text3: null,
          text4: null,
          btnSubmitText: "",
        };
      });
    },

    /**
     * closeApproveDialog
     */
    closeApproveDialog() {
      this.popups.isShowConfirmDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
        if (this.isTabScheduleCompanyPlan) {
          this.reloadListSchedule = !this.reloadListSchedule;
        } else {
          this.reloadListResult = !this.reloadListResult;
        }
        this.dialogApproveText = {
          title: "",
          text1: "",
          text2: "",
          btnSubmitText: "",
          flg: false,
        };
      });
    },
    /**
     * 親ステートの子テーブルを更新
     */
    async getChildItems(work_group_company_id, pageNumber) {
      const searchParams = {
        field_construction_id: this.searchParams.field_construction_id,
        employee_user_id: this.searchParams.employee_user_id,
        work_id: work_group_company_id,
        early_over_type: this.searchParams.early_over_type,
        work_approval_flag: this.searchParams.work_approval_flag,
        work_date: this.searchParams.date,
        work_hour_zone: this.searchParams.work_hour_zone,
        page_number: pageNumber,
      };
      this.deleteParams(searchParams);
      let isTabScheduleCompanyPlan = this.isTabScheduleCompanyPlan;
      const params = {
        searchParams: searchParams,
        isTabScheduleCompanyPlan: isTabScheduleCompanyPlan,
      };
      await Store.dispatch(`${STORE}/loadChildren`, params);
    },
    /**
     *  (共通)
     * ストア / api
     * ストア実装ルールにしたがっている場合は、
     */

    // データ取得
    async getItems() {
      let searchParams = { ...this.searchParams };
      searchParams["work_date"] = searchParams["date"];
      if (this.isTabScheduleCompanyPlan) {
        searchParams["page_number"] =
          searchParams["current_page_schedule_company"];
      } else {
        searchParams["page_number"] =
          searchParams["current_page_schedule_result"];
      }
      if (
        this.searchParams.field_construction_id &&
        this.searchParams.work_hour_zone &&
        this.searchParams.date
      ) {
        let isTabScheduleCompanyPlan = this.isTabScheduleCompanyPlan;
        const params = {
          searchParams: searchParams,
          isTabScheduleCompanyPlan: isTabScheduleCompanyPlan,
        };
        this.deleteParams(searchParams);
        await Store.dispatch(`${STORE}/get`, params);
      }
    },

    deleteParams(searchParams) {
      delete searchParams["date"];
      delete searchParams["table_type"];
      delete searchParams["page_count_schedule_company"];
      delete searchParams["current_page_schedule_company"];
      delete searchParams["total_page_schedule_company"];
      delete searchParams["total_item_schedule_company"];
      delete searchParams["page_count_schedule_result"];
      delete searchParams["current_page_schedule_result"];
      delete searchParams["total_page_schedule_result"];
      delete searchParams["total_item_schedule_result"];
      delete searchParams["all_record_schedule_company"];
      delete searchParams["all_record_schedule_result"];
    },

    // 承認
    async approveItems() {
      const approves = this.selectedItems.filter(
        (item) => item.schedule_approval == 0
      );
      const ids = approves.map((items) => items.id);
      if (ids?.length > 0) {
        if (this.isTabScheduleCompanyPlan) {
          const params = { work_company_plan_ids: ids, work_approval_flag: 1 };
          const result = await Store.dispatch(
            `${STORE}/updateApprovalWorkCompanyPlans`,
            params
          );

          if (!result.hasError) {
            //成功したら値を更新
            this.getItems();
            Store.dispatch("Notification/getNewNoticeStatus");
            Store.dispatch("Toast/show", {
              status: 200,
              message: "承認しました",
            });
          }
        } else {
          const params = {
            work_company_result_ids: ids,
            work_approval_flag: 1,
          };
          const result = await Store.dispatch(
            `${STORE}/updateApprovalWorkCompanyResults`,
            params
          );

          if (!result.hasError) {
            //成功したら値を更新
            this.getItems();
            Store.dispatch("Notification/getNewNoticeStatus");
            Store.dispatch("Toast/show", {
              status: 200,
              message: "承認しました",
            });
          }
        }
      }
      this.closeApproveDialog();
    },

    // 承認解除
    async rejectItems() {
      const disapproves = this.selectedItems.filter(
        (item) => item.schedule_approval == 1
      );
      const ids = disapproves.map((items) => items.id);
      if (ids?.length > 0) {
        if (this.isTabScheduleCompanyPlan) {
          const params = { work_company_plan_ids: ids, work_approval_flag: 0 };
          const result = await Store.dispatch(
            `${STORE}/updateApprovalWorkCompanyPlans`,
            params
          );

          if (!result.hasError) {
            //成功したら値を更新
            this.getItems();
            Store.dispatch("Notification/getNewNoticeStatus");
            Store.dispatch("Toast/show", {
              status: 200,
              message: "承認解除しました",
            });
          }
        } else {
          const params = {
            work_company_result_ids: ids,
            work_approval_flag: 0,
          };
          const result = await Store.dispatch(
            `${STORE}/updateApprovalWorkCompanyResults`,
            params
          );
          if (!result.hasError) {
            //成功したら値を更新
            this.getItems();
            Store.dispatch("Notification/getNewNoticeStatus");
            Store.dispatch("Toast/show", {
              status: 200,
              message: "承認解除しました",
            });
          }
        }
        this.closeApproveDialog();
      }
    },
    /**
     * (共通)
     * 新規登録 / 更新
     * - idがある場合は更新
     */
    async submitForm() {
      let _editedItem = {...this.editedItem};
      _editedItem['field_id'] = this.CURRENT_SITE.field_id;
      let hasId = this.isTabScheduleCompanyPlan
        ? "id" in this.editedItem.work_company_plans
        : "id" in this.editedItem.work_company_results;
      const result = await Store.dispatch(
        //idがある場合は更新、ない場合は新規
        hasId
          ? `${STORE}/update${
              this.isTabScheduleCompanyPlan ? "Plans" : "Results"
            }`
          : `${STORE}/post${
              this.isTabScheduleCompanyPlan ? "Plans" : "Results"
            }`,
        _editedItem
      );
      //レスポンスエラーがある場合はフォームを閉じない
      //エラーメッセージは、api.jsが表示
      if (result.hasError) {
        return;
      } else if (hasId) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        let resultDetail = null;
        if (this.isTabScheduleCompanyPlan) {
          resultDetail = await Store.dispatch(
            `${STORE}/getDetailPlans`,
            this.editedItem.work_company_plans.id
          );
        } else {
          resultDetail = await Store.dispatch(
            `${STORE}/getDetailResults`,
            this.editedItem.work_company_results.id
          );
        }
        this.isResetForm = !this.isResetForm;
        let items = {
          ...this.editedItem,
          ...resultDetail.data.contents.entries,
        };
        this.editedItem = _.cloneDeep(items);
        this.beforeUpdateItem = _.cloneDeep(items);
      } else {
        //成功したら値を更新
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
        this.closeItemForm();
      }
      this.getItems();
    },

    /**
     * checkboxの値からselectedItemsを更新
     */
    updateSelectedItems(list) {
      list.forEach((item) => {
        this.updateSelectedItem(item);
      });
    },
    updateSelectedItem({ id, isChecked, schedule_approval , state_handling_flag}) {
      let _selectedItems = [...this.selectedItems];
      // checked
      if (isChecked) {
        const isNew = this.selectedItems.some((item) => item.id == id);
        if (!isNew)
          _selectedItems = [..._selectedItems, { id, schedule_approval , state_handling_flag }];
      } else {
        // unchecked
        _selectedItems = this.selectedItems.filter((item) => {
          return item.id != id;
        });
      }
      // 重複削除してset
      this.selectedItems = [...new Set(_selectedItems)];
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  height: 40px;
}
.tableSortWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  height: 32px;
  .sortLabel {
    font-size: 12px;
    height: 32px;
    margin-right: 16px;
  }
  .sortElement {
    max-width: 168px;
    margin-right: 24px;
  }
  .total_item {
    padding-bottom: 15px;
  }
}
</style>
