<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row v-if="editable" class="row">
          <v-col cols="12" sm="6" md="6">
            <v-list-item>
              <v-list-item-content>
                <Label label="禁止時間" :editable="editable" :required="Boolean(!item.prohibit_start_time && item.prohibit_end_time)">
                  <Select
                    name="prohibit_start_time"
                    :values="item"
                    :items="PROHIBIT_TIME_START"
                    :editable="editable"
                    :validation_label="`開始時間 [${index}]`"
                    :validation_rules="getRuleStartTime(item)"
                    @onInput="onInput({ index, value: $event })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-list-item>
              <v-list-item-content>
                <Label label="禁止時間" :editable="editable" :required="Boolean(item.prohibit_start_time && !item.prohibit_end_time)">
                  <Select
                    name="prohibit_end_time"
                    :values="item"
                    :items="PROHIBIT_TIME_END"
                    :editable="editable"
                    :validation_label="`終了時間 [${index}]`"
                    :validation_rules="getRuleEndTime(item)"
                    @onInput="onInput({ index, value: $event })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col v-if="index > 0" cols="12" sm="1" md="1" class="pt-0 pb-0">
            <v-btn class="mt-8 btn-close" icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="!editable">
          <v-col cols="12" sm="10" md="10">
            <v-list-item>
              <v-list-item-content>
                <Label label="禁止時間帯" :editable="editable">
                  <InputText
                    name="prohibit_times"
                    :values="getProhibitTimes(item)"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col
            v-if="index > 0 && editable"
            cols="12"
            sm="2"
            md="2"
            class="pt-0 pb-0"
          >
            <v-btn class="mt-8" icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn class="ml-4" v-if="editable" color="primary" @click="addEmpty">追加</v-btn>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "../../elements/Label.vue";
import Select from "../../elements/Select.vue";
import InputText from "../../elements/InputText.vue";
import { PROHIBIT_TIME_START, PROHIBIT_TIME_END } from "@/constants/GATES";

//初期値を設定します
const INITIAL_VALUES = {
  prohibit_start_time: null,
  prohibit_end_time: null,
};

export default {
  data: () => {
    return {
      items: [],
      PROHIBIT_TIME_START,
      PROHIBIT_TIME_END,
      prohibitTimes: {
        prohibit_start_time: null,
        prohibit_end_time: null,
      }
    };
  },
  components: {
    Label,
    Select,
    InputText,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (this.items?.length == 0) {
          this.addEmpty();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      this.items.push({ ...INITIAL_VALUES });
    },
    getProhibitTimes(item) {
      let prohibitTimes = {
        prohibit_times: null,
      };
      if (!this.editable) {
        let startTime = item.prohibit_start_time ? item.prohibit_start_time : "";
        let endTime = item.prohibit_end_time ? item.prohibit_end_time : "";
        if (startTime && endTime) {
          prohibitTimes.prohibit_times = `${startTime}～${endTime}`;
        }
      }
      return prohibitTimes;
    },
    getRuleStartTime (item) {
      let rule = item.prohibit_end_time ? "required-usage-start-time" : "";
      if(this.items.length > 1 && item.prohibit_start_time && item.prohibit_end_time) {
        const isNotDuplicate = this.getCountOfItem(item)== 1 ? 1 : 0;
        rule = rule + `|duplicate-time:${isNotDuplicate}`;
      }
      return rule;
    },
    getRuleEndTime (item) {
      let rule = item.prohibit_start_time ? "required-usage-end-time" : "";
      if(this.items.length > 1 && item.prohibit_start_time && item.prohibit_end_time) {
        const isNotDuplicate = this.getCountOfItem(item)== 1 ? 1 : 0;
        rule = rule + `|duplicate-time:${isNotDuplicate}`;
      }
      return rule;
    },
    getCountOfItem(item) {
      let index = 0;
      this.items.forEach(element => {
        if(item.prohibit_start_time == element.prohibit_start_time && item.prohibit_end_time == element.prohibit_end_time) {
          index++;
        }
      })
      return index;
    }
  },
};
</script>
<style scoped>
.btn-close {
  position: absolute;
  top: 25px;
  left: 97%;
}
.row{
  position: relative;
}
</style>