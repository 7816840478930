import Vue from "vue";
import Vuex from "vuex";
import { companyPortal } from "../../../api/modules/companyPortal";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = companyPortal;

export const CompanyPortal = {
  namespaced: true,

  state: {
    company: {},
    company_branch_insurances: {},
    company_branch_foreigners: {},
    company_employment_restrictions:{},
    company_by_field: [],
    company_safety_rules:[],
    company_documents:[],
    company_all: [],
    pagination_company: {},
  },

  mutations: {
    SET_COMPANY(state, payload) {
      state.company = payload;
    },
    SET_COMPANY_BRANCH_INSURANCES(state, payload) {
      state.company_branch_insurances = payload;
    },
    SET_COMPANY_BRANCH_FOREIGNERS(state, payload) {
      state.company_branch_foreigners = payload;
    },
    SET_COMPANY_BY_FIELD(state, payload) {
      state.company_by_field = payload;
    },
    SET_COMPANY_EMPLOYMENT_RESTRICTIONS(state, payload) {
      state.company_employment_restrictions = payload;
    },
    SET_COMPANY_SAFETY_RULES(state, payload) {
      state.company_safety_rules = payload;
    },
    SET_COMPANY_DOCUMENTS(state, payload) {
      state.company_documents = payload;
    },
    SET_COMPANY_ALL(state, payload) {
      if (payload.length > 0 && !state.company_all.find(e => e.company_id === payload[0].company_id && e.company_branch_id === payload[0].company_branch_id)) {
        state.company_all = state.company_all.concat(payload);
      }
    },
    CLEAR_COMPANY_ALL(state) {
      state.company_all = [];
    },
    SET_PAGINATION_COMPANY(state, payload) {
      state.pagination_company = payload;
    },
  },

  actions: {
    async get({ commit }, payload) {
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const { company, company_branch_insurances, company_branch_foreigners ,company_employment_restrictions, company_safety_rules, company_documents } =
        response.data.contents.entries;
      commit("SET_COMPANY", company);
      commit("SET_COMPANY_BRANCH_INSURANCES", company_branch_insurances);
      commit("SET_COMPANY_BRANCH_FOREIGNERS", company_branch_foreigners);
      commit("SET_COMPANY_EMPLOYMENT_RESTRICTIONS", company_employment_restrictions);
      commit("SET_COMPANY_SAFETY_RULES", company_safety_rules);
      commit("SET_COMPANY_DOCUMENTS", company_documents);
      return response;
    },

    async initialize(_context, payload) {
      // TODO api 初回自社情報の登録
      return await ENTITY.create(payload);
    },

    async update(_context, payload) {
      return await ENTITY.post(payload);
    },

    async getListCompanyByField({ commit }, payload) {
      const response = await ENTITY.getListCompanyByField(payload);
      if (response.hasError) {
        return response;
      }
      const entries = response.data.contents.entries;
      commit("SET_COMPANY_BY_FIELD", entries);
      return response;
    },
    async getListCompanyAll({ commit }, payload) {
      const response = await ENTITY.getListCompanyAll(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents.entries;
      commit("SET_COMPANY_ALL", entries);
      commit("SET_PAGINATION_COMPANY", pagination);
      return response;
    },
    clearListCompany({commit}) {
      commit("CLEAR_COMPANY_ALL");
    },
    async getAddUserToCompany(_context, payload){
      return await ENTITY.getAddUserToCompany(payload);
    }
  },

  getters: {
    getCompany: (state) => {
      return state.company;
    },
    getCompanyBranchInsurances: (state) => {
      return state.company_branch_insurances;
    },
    getCompanyBranchForeigners: (state) => {
      return state.company_branch_foreigners;
    },
    getCompanyEmploymentRestrictions: (state) => {
      return state.company_employment_restrictions;
    },
    getListCompanyByField: (state) => {
      return state.company_by_field;
    },
    getCompanySafetyRules: (state) => {
      return state.company_safety_rules;
    },
    getCompanyDocuments: (state) => {
      return state.company_documents;
    },
    getListCompanyAll: (state) => {
      return state.company_all;
    },
    getPaginationCompany: (state) => {
      return state.pagination_company;
    },
  },
};
