<template>
  <FormDialog>
    <template #header>
      <!-- 
        (共通)
        ヘッダーは全て共通
       -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary" class="title">
          {{ TITLE_FORM_MACHINE_CHECKLIST }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editable"
          class="mr-5"
          depressed
          small
          outlined
          color="primary"
          @click="isShowConfirmDialog = true"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          small
          color="primary"
          data-testid="btn-submit"
          :key="isErrorSubmit"
          @click.once="onSubmit"
        >
          保存
        </v-btn>

        <v-btn
          v-if="!editable"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS_CHECK_LIST" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <CheckListForm1
            :editable="editable"
            :item="machine_company_inspection_lists"
            :mainHeight="params.mainHeight"
            :selectedItemsForm1="selectedItemsForm1"
            @removeItems="removeItems"
            @changeValue="changeValue"
          />
        </v-tab-item>
        <v-tab-item>
          <CheckListForm2
            :editable="editable"
            :item="machine_company_monthly_inspection_lists"
            :mainHeight="params.mainHeight"
            :selectedItemsForm1="selectedItemsForm2"
            @removeItems="removeItems"
            @changeValue="changeValue"
          />
        </v-tab-item>
      </v-tabs-items>

      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { Store } from "@/store/Store.js";
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import CheckListForm1 from "./CheckListForm1.vue";
import CheckListForm2 from "./CheckListForm2.vue";
import {
  TITLE_FORM_MACHINE_CHECKLIST,
  FORMS_CHECK_LIST,
  MACHINE_TYPE_IN_SPEC,
} from "@/constants/COMPANY_MACHINES";
import _ from "lodash";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";

const STORE = "CompanyMachines";

export default {
  data: () => {
    return {
      valid: false,
      tab: null,
      editable: false,

      isShowConfirmDialog: false,
      isShowRemoveDialog: false,
      isShowAddSpecForm: false,
      isClickCloseBtn: false,

      FORMS_CHECK_LIST,
      TITLE_FORM_MACHINE_CHECKLIST,
      MACHINE_TYPE_IN_SPEC,

      machine_company_inspection_lists: null,
      machine_company_monthly_inspection_lists: null,
      machineTypeBeforeUpdate: null,
      company_machine_type_id: null,
      selectedItems: [],
      selectedItemsForm1: [],
      selectedItemsForm2: [],

      isErrorSubmit: false,
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    CheckListForm1,
    CheckListForm2,
    ConfirmCloseDialog,
  },
  props: {
    checkFormItemId: Number,
  },
  mounted() {
    /**
     * (共通)
     * 新規作成を監視
     */
    this.$watch(
      () => Store.getters[`${STORE}/getMachineCompanyTypeInSpec`],
      (value) => {
        this.initType(value);
        this.machineTypeBeforeUpdate = _.cloneDeep(value);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    initType(value) {
      this.machine_company_inspection_lists = [
        ...value.machine_company_inspection_lists,
      ];
      this.machine_company_monthly_inspection_lists = [
        ...value.machine_company_monthly_inspection_lists,
      ];
    },

    onEditable() {
      this.editable = true;
    },

    closeForm() {
      const data = _.cloneDeep(this.machineTypeBeforeUpdate);
      this.initType(data);
      this.isShowConfirmDialog = false;
      this.editable = false;
      this.$emit("cancel");
    },
    //   /**
    //    * (共通)
    //    * click button Close (X)
    //    */
    onClickBtnClose() {
      if(this.editable){
        this.isShowConfirmDialog = true;
      }else {
        this.$emit("cancel");
      }
    },
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },

    removeItems(params) {
      if (params.type === MACHINE_TYPE_IN_SPEC[0].id) {
        let items = [...this.machine_company_inspection_lists];
        const results = items.filter((item) => {
          return !params.value.some((removeItem) => item.id === removeItem.id);
        });
        this.machine_company_inspection_lists = results;
      }
      if (params.type === MACHINE_TYPE_IN_SPEC[1].id) {
        let items = [...this.machine_company_monthly_inspection_lists];
        const results = items.filter((item) => {
          return !params.value.some((removeItem) => item.id === removeItem.id);
        });
        this.machine_company_monthly_inspection_lists = results;
      }
    },
    changeValue(params) {
      if (params.type === MACHINE_TYPE_IN_SPEC[0].id) {
        let items = [...this.machine_company_inspection_lists];
        items.push(params.value);
        this.machine_company_inspection_lists = [...items];
        this.selectedItemsForm1 = params.selectedItems;
      }
      if (params.type === MACHINE_TYPE_IN_SPEC[1].id) {
        let items = [...this.machine_company_monthly_inspection_lists];
        items.push(params.value);
        this.machine_company_monthly_inspection_lists = items;
        this.selectedItemsForm2 = params.selectedItems;
      }
    },
    async onSubmit() {
      let data = {};
      data.machine_company_type_item_id = this.checkFormItemId;
      data.machine_company_inspection_lists = this.convertDataBeforeSave(
        this.machine_company_inspection_lists
      );
      data.machine_company_monthly_inspection_lists =
        this.convertDataBeforeSave(
          this.machine_company_monthly_inspection_lists
        );
      const rs = await Store.dispatch(
        `${STORE}/UpdateMachineCompanyTypeInSpec`,
        data
      );
      if (!rs.hasError) {
        await Store.dispatch(
          `${STORE}/GetMachineCompanyTypeInSpec`,
          this.checkFormItemId
        );
        this.isErrorSubmit = false;
        Store.dispatch("Toast/show", {
          message: "更新しました",
        });
      }
      this.isErrorSubmit = !this.isErrorSubmit;
      this.editable = false;
      return;
    },
    convertDataBeforeSave(data) {
      return data.map((item) => {
        if (item.newItem) {
          item.id = null;
          delete item.newItem;
        }
        return item;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.buttonAdd {
  border: 1px solid #1b9c4f;
  box-sizing: border-box;
  border-radius: 4px;
  color: #1b9c4f;
  padding: 5px;
  margin-right: 10px;
}
.from-close-btn {
  float: right;
}
.title {
  color: #1b9c4f;
}
</style>
