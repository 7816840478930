<template>
  <FormDialog>
    <template #header>
      <!--
        (共通)
        ヘッダーは全て共通
       -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editable"
          class="mr-5"
          depressed
          small
          outlined
          color="primary"
          @click="isShowConfirmDialog = true"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="isNewItem"
          depressed
          class="mr-5"
          small
          color="primary"
          @click="onCopy"
        >
          前回コピー
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          small
          :disabled="!valid || isSubmitted"
          color="primary"
          @click="onSubmit"
        >
          保存
        </v-btn>
        <v-btn
          v-if="!editable && item.approval == 0 && owner_flag"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS_CREATE_EDIT" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!--
        (共通)
        全てのフォームのバリデーションの監視を行う
       -->
      <ValidationObserver ref="observer" v-slot="observer">
        <!-- フォームtab -->
        <v-tabs-items v-model="tab">
          <!-- 基本情報 -->
          <v-tab-item>
            <ScheduleGateFormPage1
              :editable="editable"
              :item="formValues"
              :isNewItem="isNewItem"
              :mainHeight="params.mainHeight"
              :gatePassagesCustomize="gatePassagesCustomize"
              @onChooseTimeZone="onChooseTimeZone"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <!-- 作業内容 -->
          <v-tab-item>
            <ScheduleGateFormPage2
              :editable="editable"
              :item="formValues"
              :mainHeight="params.mainHeight"
              :timeZone="timeZone"
              :gatePassagesCustomize="gatePassagesCustomize"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <!-- 登録・変更履歴 -->
          <v-tab-item>
            <ScheduleGateFormPage3
              :editable="false"
              :item="formValues"
              :mainHeight="params.mainHeight"
              :gatePassagesCustomize="gatePassagesCustomize"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
        </v-tabs-items>
        <!--
          (共通)
          ValidationObserverのスロットプロパティを受け取って
          updateValidateメソッドを実行して、
          バリデーション結果をスクリプト側に渡してます
        -->
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { Store } from "@/store/Store.js";
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import ScheduleGateFormPage1 from "@/components/forms/schedule/gates/ScheduleGateFormPage1.vue";
import ScheduleGateFormPage2 from "@/components/forms/schedule/gates/ScheduleGateFormPage2.vue";
import ScheduleGateFormPage3 from "@/components/forms/schedule/gates/ScheduleGateFormPage3.vue";
import {
  TITLE_FORM_CREATE_EDIT,
  FORMS_CREATE_EDIT,
  SCHEDULE_GATE_INITIAL_ITEM,
} from "@/constants/SCHEDULE_GATE";
import {
  OWNER_FLAG,
} from "@/constants/SCHEDULE_CRANE";
import _ from "lodash";
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON.js";
import {  removeOneDayToEndTime} from "@/constants/CRANES";
const STORE = "ScheduleGates";

export default {
  data: () => {
    return {
      valid: false,
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      TITLE_FORM_CREATE_EDIT,
      FORMS_CREATE_EDIT,
      errors: {},
      timeZone: {},
      beforeUpdateItem: {},
      isClickCloseBtn: false,
      isSubmitted: false,
      timeout: null,
      SUBMIT_DELAY_TIMEOUT,
      owner_flag: false,
      formValues: {},
      FIELD_TREE_GROUP_ARRAY: [],
      gatePassagesCustomize: {},
      timeZoneStatus: true,
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    ScheduleGateFormPage1,
    ScheduleGateFormPage2,
    ScheduleGateFormPage3,
  },
  props: {
    item: Object,
    isNewItem: Boolean,
    isErrorSubmit: Boolean,
    isEmitted: Boolean
  },
  created() {
    // call condition required and show/off item
    this.getPlanItemSetting();
  },
  mounted() {
    this.$watch(
      () => this.item,
      (data) => {
        this.formValues = _.cloneDeep(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * (共通)
     * 新規作成を監視
     */
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        //新規の場合は最初から編集モード
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => [
        Store.getters[`${STORE}/getDetail`],
        this.editable,
      ],
      (data) => {
        if (!this.isNewItem) {
          this.beforeUpdateItem = _.cloneDeep(data[0]);
          this.beforeUpdateItem.device_usage_times = removeOneDayToEndTime(this.beforeUpdateItem.device_usage_times);
          if(this.beforeUpdateItem['owner_flag'] == OWNER_FLAG.OWNER) {
            this.owner_flag = true;
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    
    /**
     * Catch after submitted
     */
    this.$watch(
        () => this.isEmitted,
        () => {
          if (!this.isErrorSubmit) {
            this.editable = false;
          }
        }
    );
  },
  computed: {
    // 新規or編集でタイトル変更
    formtitle() {
      return this.isNewItem
        ? TITLE_FORM_CREATE_EDIT.NEW
        : TITLE_FORM_CREATE_EDIT.EDIT;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },
  methods: {
    /**
     * (共通)
     * フォームの変更を送信
     */
    formUpdate(params) {
      this.$emit("formUpdate", params);
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      this.editable = true;
    },
    /**
     * (共通)
     * 状態を詳細に変更
     */
    onDetail() {
      this.editable = false;
    },

    /**
     * (共通)
     * 登録
     */
    onSubmit() {
      this.isSubmitted = true;
      this.$emit("submit");
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isSubmitted = false
      }, SUBMIT_DELAY_TIMEOUT);
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid }) {
      this.valid = valid;
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        const item = _.cloneDeep(this.beforeUpdateItem);
        this.formUpdate(item);
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },
    /**
     * (共通)
     * click button Close (X)
     */
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },
    onChooseTimeZone(value, isChange) {
      this.timeZone = value;
      if(this.isNewItem) {
        if (this.timeZoneStatus) {
          this.formValues.device_usage_times[0].usage_start_time = value.start_time
          this.formValues.device_usage_times[0].usage_end_time = null
          this.timeZoneStatus = false
        } else {
          // this.formValues.device_usage_times[0].usage_start_time = null
          // this.formValues.device_usage_times[0].usage_end_time = null
        }
      } else {
        if(isChange) {
          // this.formValues.device_usage_times[0].usage_start_time = null
          // this.formValues.device_usage_times[0].usage_end_time = null
        }
      }
    },
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
    /**
     * function copy data last regist
     */
    async onCopy() {
      let params = {
        field_id : this.CURRENT_SITE.field_id,
      };
      const result = await Store.dispatch(`ScheduleGates/getGatePassageLastTime`, {params});
      if (!result.hasError && result.data.contents.entries) {
        let data = _.cloneDeep(result.data.contents.entries);
        data = {...this.formValues, ...data};
        data['passage_date_from']= this.item['passage_date_from'];
        data['passage_date_to']= this.item['passage_date_to'];
        this.formValues = _.cloneDeep(data);
        await this.getListGroups();
        await this.getCompanyPartners();
        await this.getListFieldTree();
        await this.getFieldDirectors();
      } else {
        this.initDataNewItem();
      }
      this.formUpdate(this.formValues);
    },
    initDataNewItem() {
      const data = _.cloneDeep(SCHEDULE_GATE_INITIAL_ITEM);
      data.passage_date_from = this.item.passage_date_from;
      data.passage_date_to = this.item.passage_date_to;
      data.field_construction_id = this.item.field_construction_id;
      data.work_hour_zone = this.item.work_hour_zone;
      this.formValues = _.cloneDeep(data);
    },
    async getListGroups() {
      const result = await Store.dispatch(
        "PortalChart/getListGroups",
        this.formValues["field_construction_id"]
      );
      this.FIELD_TREE_GROUP_ARRAY = [...result.data.contents.entries];
    },
    async getCompanyPartners() {
      let groupId = this.formValues?.group_id;
      let params = {
        state_handling_flag: 0,
        display_branch_name_flag: 1,
      };
      if (groupId) params.field_tree_group_id = groupId;
      else if (this.formValues["field_construction_id"]) params.field_construction_id = this.formValues.field_construction_id;
      else return;
      await Store.dispatch("PortalChart/getListCompanyPartner", {params});
    },
    async getListFieldTree() {
      if (this.formValues["field_tree_id"]) {
        const params = {
          id: this.formValues["field_tree_id"],
          foreman_flag: 1,
        };
        await Store.dispatch("PortalChart/getListCompanyPartnerUser", params);
      }
    },
    async getFieldDirectors() {
      let params = {state_handling_flag: 0};
      if (this.formValues["group_id"]) params.group_id = this.formValues["group_id"];
      else if (this.formValues["field_construction_id"]) params.field_construction_id = this.formValues["field_construction_id"];
      else return;
      await Store.dispatch("PortalChart/getFieldDirectorsObayashi", params);
    },
    async getPlanItemSetting() {
      let params = {
        field_id: this.CURRENT_SITE.field_id,
        company_type: 0 // 0: not obayashi
      };
      let rs = await Store.dispatch("Customizes/get", { params });
      this.gatePassagesCustomize = _.cloneDeep(rs.data.contents.entries)?.gate_passages;
    },
  },
  
  /**
   * Important: clear timeout
   */
  beforeDestroy () {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout)
  },
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
