<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row>
          <v-col cols="12" sm="10" md="11" class="pt-0 pb-0">
            <v-list-item>
              <v-list-item-content class="row-position">
                <Label label="氏名" :editable="editable">
                  <Select
                    class="select-position"
                    name="driver_user_id"
                    :values="item"
                    :items="listUser"
                    :label="item.driver_user_name"
                    :editable="editable"
                    @onInput="onInput({ index, value: $event })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="10" md="11">
            <Label label="資格・免許" :editable="editable">
              <LicenseForm
                name="machine_company_field_licences"
                :values="item"
                :editable="editable"
                :userId="item.driver_user_id"
                @onInput="onInput({ index, value: $event })"
              />
            </Label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="10" md="11">
            <Label label="特別教育" :editable="editable">
              <EducationForm
                name="machine_company_field_sp_trainings"
                :values="item"
                :editable="editable"
                :userId="item.driver_user_id"
                @onInput="onInput({ index, value: $event })"
              />
            </Label>
          </v-col>
          <v-col v-if="index > 0 && editable" cols="12" sm="2" md="1">
            <v-btn class="mt-16" icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn @click="addEmpty" color="primary" v-if="editable" class="mt-6 ml-5">追加</v-btn>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "@/components/forms/elements/Label.vue";
import Select from "@/components/forms/elements/Select.vue";
import LicenseForm from "@/components/forms/device/stock/preview_device/components/sub_components/LicenseForm.vue";
import EducationForm from "@/components/forms/device/stock/preview_device/components/sub_components/EducationForm.vue";
import SectionLabel from "@/components/forms/elements/SectionLabel.vue";
import _ from "lodash";
import { Store } from "@/store/Store";

//初期値を設定します
const INITIAL_VALUES = {
  id: null,
  driver_user_id: null,
  machine_company_field_sp_trainings: [{ user_sp_training_id: null }],
  machine_company_field_licences: [{ user_licence_id: null }],
};

export default {
  data: () => {
    return {
      items: [],
      flagInitial : true
    };
  },
  components: {
    Label,
    Select,
    LicenseForm,
    EducationForm,
    SectionLabel,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    listUser: Array,
    isReturn : {
      type : Boolean,
      default : false
    }
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (!this.items || this.items.length == 0) {
          this.items = [];
          this.addEmpty();
        }
        if (this.flagInitial) {
          this.initialDataArrays(newValue[0][newValue[1]]);
        }
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    async initialDataArrays(data) {
      this.flagInitial = false;
      let _data = _.cloneDeep(data);
      if (Array.isArray(_data)) {
        let temp = [...new Map(_data.map((item) => [item.driver_user_id, item])).values()];
        temp.forEach(e => {
          if (e.driver_user_id) {
            this.getDataDriverInfoByUserId(e.driver_user_id);
          }
        });
      }
    },
    async getDataDriverInfoByUserId(id){
      await Store.dispatch(`DeviceCarryOns/getDriverInfo`, id);
    },
    async onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = _.cloneDeep(this.items);
      if (value.name == "driver_user_id") {
        _items[index].machine_company_field_licences = [];
        _items[index].machine_company_field_sp_trainings = [];
        _items[index][value.name] = value.value;
        if (value.value) {
          await Store.dispatch(`DeviceCarryOns/getDriverInfo`, value.value);
        }
      } else {
        _items[index][value.name] = value.value;
      }
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      const items = _.cloneDeep(this.items);
      items.push(_.cloneDeep(INITIAL_VALUES));
      this.$emit("onInput", { name: this.name, value: items });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
.row-position {
  margin-top: -30px;
  height: 150px;
  position: relative;
}

.select-position {
  width: 100%;
  position: absolute;
}
</style>
