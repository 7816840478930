import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
/**
 * 現場idを保持するstore
 */
export const SiteId = {
  namespaced: true,
  state: {
    // 現場id
    id: null,
  },
  mutations: {
    SET_ID(state, payload) {
      state.id = payload;
    },
  },
  actions: {
    // idをset
    setId({ commit }, payload) {
      commit("SET_ID", payload);
    },
  },
  getters: {
    // idを返す
    getId: (state) => {
      return state.id;
    },
  },
};
