<template>
  <div class="FormMain">
    <div ref="mainBody" class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-container class="mt-8">
          <v-row class="mb-2">
            <v-btn
              v-if="userPermissions.includes(SAFETY_PERMISSIONS.CREATE)"
              depressed 
              color="primary" 
              medium class="ml-12" 
              @click="openPopupUploadFile()"
            >
              アップロード
            </v-btn>
          </v-row>
          <v-row no-gutters>
            <v-col sm="3" md="3" class="pdfViewer" :style="`overflow: auto;height:${mainHeight - 72}px`">
              <div
                class="historyItem"
                v-for="(item, index) in document"
                :key="index"
              >
                <div class="ml-8 document_title"
                  @click="clickDocument(item)"
                >
                  {{ item.title }}
                </div>
                <div class="col_date">
                  <span class="historyItem__date">{{ item.created_at }}</span>
                </div>
                <div class="col_buttons">
                  <button
                    class="historyItem__btn"
                    :disabled="isSubmitted"
                    @click="downloadDocument(item)"
                  >
                    <v-icon>mdi-download</v-icon>
                  </button>
                </div>
              </div>
            </v-col>
            <v-col sm="9" md="9" class="pl-4">
              <!-- ナビゲーション -->
              <div
                ref="pdfNavigation"
                class="pdfNavigation"
                v-if="document && document.length > 0"
              >
                <div>
                  <span class="mr-5">{{ this.selectedDocument.title }}</span>
                  <span class="historyItem__date">{{
                    this.selectedDocument.created_at
                  }}</span>
                </div>
                <div class="pdfNavigation__pages" v-if="pdfPage.pageCount > 0">
                  {{ pdfPage.currentPage }} / {{ pdfPage.pageCount }}
                </div>
                <div class="pdfNavigation__navi" v-if="pdfPage.pageCount > 0">
                  <v-btn
                    small
                    icon
                    :disabled="pdfPage.currentPage === 1"
                    @click="pdfNaviPrev"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn
                    small
                    icon
                    :disabled="pdfPage.currentPage === pdfPage.pageCount"
                    @click="pdfNaviNext"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </div>
              <div
                ref="pdfNavigation"
                class="pdfNavigation"
                v-if="isNoDataMessage"
              >
                {{ NO_DATA_MESSAGE }}
              </div>
              <!-- PDF -->
              <div
                ref="pdfViewer"
                class="pdfViewer"
                :style="pdfPage.style"
                :class="typeDocument !== TYPE_DOCUMENT.PDF ? 'dispaly-none':''"
              >
                <v-card outlined tile v-if="typeDocument === TYPE_DOCUMENT.PDF && urlDocument">
                  <pdf
                    :src="urlDocument"
                    :page="pdfPage.selectPage"
                    @num-pages="pdfPage.pageCount = $event"
                    @page-loaded="pdfPage.currentPage = $event"
                  ></pdf>
                </v-card>
              </div>
              <!-- IMAGE -->
              <div
                class="imageViewer"
                v-if="typeDocument === TYPE_DOCUMENT.IMAGE"
              >
                <v-img
                  contain
                  :src="this.urlDocument || ''"
                  max-width="40vw"
                  max-height="60vw"
                  class="mb-5"
                />
              </div>
              <!-- CAN NOT SHOW DOCUMENT -->
              <div
                class="not-show-document mt-2"
                v-if="typeDocument === TYPE_DOCUMENT.OTHER"
              >
                {{ MESSAGE_NOT_CAN_SHOW_DOCUMENT }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <Popup width="480px" :dialog="isShowPopupUploadFile">
        <UploadDocument
          :flagError="flagError"
          :editable="true"
          @close="closePopupUploadFile"
          @yes="createDocument"
        />
      </Popup>
    </div>
  </div>
</template>
<script>
import pdf from "vue-pdf";
import {
  APPROVED_FLAGS,
  SAFETY_DOCUMENT_TYPE,
  SAFETY_PERMISSIONS,
  TYPE_DOCUMENT,
  MESSAGE_NOT_CAN_SHOW_DOCUMENT,
} from "@/constants/SAFETY_CHART";
import { NO_DATA_MESSAGE } from "@/constants/COMMON.js";
import Popup from "@/components/common/Popup.vue";
import UploadDocument from "./UploadDocument.vue";
import { Store } from "@/store/Store.js";
import { getParamsOfUrlReadFile } from "@/utils/viewSourceAuth";
import _ from "lodash";

export default {
  data: () => {
    return {
      SAFETY_DOCUMENT_TYPE,
      NO_DATA_MESSAGE,
      APPROVED_FLAGS,
      TYPE_DOCUMENT,
      MESSAGE_NOT_CAN_SHOW_DOCUMENT,
      SAFETY_PERMISSIONS,
      selectedDocument: {
        id: null,
        title: null,
        file_url: null,
        created_at: null,
      },
      document: [],
      pdfPage: {
        pageCount: 0,
        currentPage: 0,
        selectPage: 1,
        style: "",
      },
      urlDocument: null,
      isSubmitted: false,
      timeout: null,
      typeDocument: null,
      isShowPopupUploadFile: false,
      isNoDataMessage: false,
    };
  },
  components: {
    pdf,
    UploadDocument,
    Popup,
  },
  props: {
    forms: {
      type: Array,
    },
    tab: {
      type: Number,
      default: 0,
    },
    histories: {
      type: Object,
    },
    mainHeight: {
      type: Number,
    },
    userPermissions: {
      type: Array
    },
    flagError: Boolean,
  },
  mounted() {
    this.$watch(
      () => this.mainHeight,
      () => {
        this.updatePdfViewerHeight();
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => [this.tab, this.histories],
      async (newValue, oldValue) => {
        if (!oldValue || JSON.stringify(newValue[1]) !== JSON.stringify(oldValue[1])
        ) {
          if (newValue[0] > -1 && newValue[1]) {
            const { id } = this.forms[newValue[0]];
            let document = newValue[1][id] ? newValue[1][id] : [];
            this.document = [...document];
            this.isNoDataMessage = this.document.length == 0;
            this.urlDocument = null;
            if (this.document.length > 0) { 
              this.selectedDocument = this.document[0];
              let url = this.document[0].file_url;
              this.typeDocument = this.getTypeDocument(url);
              if (this.typeDocument == TYPE_DOCUMENT.PDF || this.typeDocument == TYPE_DOCUMENT.IMAGE) {
                await this.getUrlPdfView(url);
              }
            }
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    getBodyHistoryStyle() {
      return this.pdfPage.style;
    },
  },
  methods: {
    updatePdfViewerHeight() {
      const mainBody = this.$refs.mainBody;
      const pdfViewer = this.$refs.pdfViewer;
      if (pdfViewer && mainBody) {
        const mainBodyTop = mainBody.getBoundingClientRect().top;
        const pdfViewerTop = pdfViewer.getBoundingClientRect().top;
        const marginTop = mainBodyTop - pdfViewerTop;
        const pdfPage = { ...this.pdfPage };
        pdfPage.style = `height:${this.mainHeight + marginTop - 32}px;`;
        this.pdfPage = pdfPage;
      }
    },
    pdfNaviPrev() {
      const pdfPage = { ...this.pdfPage };
      pdfPage.selectPage = pdfPage.selectPage > 1 ? pdfPage.selectPage - 1 : 1;
      this.pdfPage = pdfPage;
    },
    pdfNaviNext() {
      const pdfPage = { ...this.pdfPage };
      pdfPage.selectPage =
        pdfPage.selectPage < pdfPage.pageCount
          ? pdfPage.selectPage + 1
          : pdfPage.pageCount;
      this.pdfPage = pdfPage;
    },

    async downloadDocument(item) {
      this.isSubmitted = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isSubmitted = false;
      }, 1000);
      let _item = _.cloneDeep(item);
      const params = getParamsOfUrlReadFile(_item.file_url);
      const response = await Store.dispatch("File/readFile", params);
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${item.title}.${item.file_url.split(".")?.pop()}`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    getTypeDocument(url) {
      if (!url) return null;
      let _url = String(url);
      if (_url.includes(".pdf")) return TYPE_DOCUMENT.PDF;
      else if (["jpeg", "jpg", "png"].indexOf(
          _url.split(".")?.pop()) > -1)
        return TYPE_DOCUMENT.IMAGE;
      else return TYPE_DOCUMENT.OTHER;
    },

    async clickDocument(item) {
      this.typeDocument = this.getTypeDocument(item.file_url);
      this.urlDocument = null;
      this.selectedDocument = item;
      if (this.typeDocument == TYPE_DOCUMENT.PDF) {
        this.pdfPage.currentPage = 1;
        this.pdfPage.selectPage = 1;
        await this.getUrlPdfView(item.file_url);
      } else {
        this.pdfPage = {
          pageCount: 0,
          currentPage: 0,
          selectPage: 1,
          style: "",
        };
        if (this.typeDocument == TYPE_DOCUMENT.IMAGE) {
          await this.getUrlPdfView(item.file_url);
        }
      }
    },
    async getUrlPdfView(url) {
      if (url) {
        const params = getParamsOfUrlReadFile(url);
        const response = await Store.dispatch("File/readFile", params);
        if (!response.hasError) {
          this.urlDocument = window.URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers["content-type"],
            })
          );
        }
      }
    },
    async createDocument(file) {
      this.closePopupUploadFile()
      this.$emit('createDocument', file);
    },
    openPopupUploadFile() {
      this.isShowPopupUploadFile = true;
    },
    closePopupUploadFile() {
      this.isShowPopupUploadFile = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
@import "@/components/forms/style/forms.scss";
.FormMainBody {
  overflow: hidden;
}
.pdfViewer, .imageViewer {
  overflow: auto;
}
.pdfNavigation {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
}
.not-show-document {
  height: 32px;
  display: flex;
  justify-content: center;
}
.historyItem {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 8px;
}
.col_date {
  flex: 1;
}
.col_buttons {
  width: 48px;
  display: flex;
  justify-content: space-between;
}
.historyItem__date {
  // font-size: 12px;
  margin: 0 4px;
}
.historyItem__btn {
  display: flex;
  align-items: center;
  margin-left: 8px;
  &.none {
    display: none;
    cursor: default;
    pointer-events: none;
  }
}
.document_title {
  color: #0000ff;
  cursor: pointer;
  font-size: 16px;
  padding: 0 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 140px;
}
.dispaly-none {
  display: none !important;
}
</style>
