import Api, { Mock } from "../api";
import device_coordinator_list from "./mock/device_coordinator_list.json";
import mock_detail_machine_application from "./mock/machine_application_detail.json";
import mock_work_plan_list from "./mock/mock_work_plan_list.json";

const DEVICE_COORDINATOR_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/machine_field_applications`;
const GET_LIST_URL = `${DEVICE_COORDINATOR_BASE_URL}/list`;
const DELETE_URL = `${DEVICE_COORDINATOR_BASE_URL}/delete_applications`;
const CREATE_MACHINE_ESTIMATE = `${DEVICE_COORDINATOR_BASE_URL}/create_estimate`;
const CREATE_MACHINE_ORDER = `${DEVICE_COORDINATOR_BASE_URL}/create_order`;
const SEND_MACHINE_ORDER_REPORT = `${DEVICE_COORDINATOR_BASE_URL}/send_order_report`;
const SEND_MACHINE_ESTIMATE_REPORT = `${DEVICE_COORDINATOR_BASE_URL}/send_estimate_report`;
const CANCEL_MACHINE_ESTIMATE = `${DEVICE_COORDINATOR_BASE_URL}/cancel_estimate`;
const CREATE_ORDER_OF_ESTIMATE = `${DEVICE_COORDINATOR_BASE_URL}/create_order_of_estimate`;
const RE_ESTIMATE = `${DEVICE_COORDINATOR_BASE_URL}/re_estimate`;
const RE_ORDER = `${DEVICE_COORDINATOR_BASE_URL}/re_order`;
const LIST_CREATED_COMPANY = `${DEVICE_COORDINATOR_BASE_URL}/list_created_company`;
const GET_DETAIL_MACHINE_APPLICATION_URL = `${DEVICE_COORDINATOR_BASE_URL}/detail`;
const GET_WORK_PLAN_LIST_URL = `${DEVICE_COORDINATOR_BASE_URL}/work_plan_list`;
const CREATE_MACHINE_APPLICATION_URL = `${DEVICE_COORDINATOR_BASE_URL}/create`;
const UPDATE_MACHINE_APPLICATION_URL = `${DEVICE_COORDINATOR_BASE_URL}/update`;
const GET_ESTIMATE_URL = `${DEVICE_COORDINATOR_BASE_URL}/get_estimate`;
const GET_ORDER_URL = `${DEVICE_COORDINATOR_BASE_URL}/get_machine_order`;
const REPLY_ESTIMATE_RESULT_URL = `${DEVICE_COORDINATOR_BASE_URL}/reply_estimate_result`;
const REPLY_ORDER_RESULT_URL = `${DEVICE_COORDINATOR_BASE_URL}/reply_order_result`;
const DOWNLOAD_REPORT_URL = `${DEVICE_COORDINATOR_BASE_URL}/download_report`;
const GET_REPORT_COMMENT_URL = `${DEVICE_COORDINATOR_BASE_URL}/get_report_comment`;
const GET_MACHINE_ESTIMATE_DETAIL_URL = `${DEVICE_COORDINATOR_BASE_URL}/get_estimate_detail`;
const GET_MACHINE_ORDER_DETAIL_URL = `${DEVICE_COORDINATOR_BASE_URL}/get_order_detail`;
const UPDATE_MACHINE_ESTIMATE_ORDER_URL = `${DEVICE_COORDINATOR_BASE_URL}/update_machine_estimate_order`;
const CREATE_REPORT_COMMENT_URL = `${DEVICE_COORDINATOR_BASE_URL}/create_report_comment`;
const CREATE_REPORT_URL = `${DEVICE_COORDINATOR_BASE_URL}/create_report`;
const UPDATE_ESTIMATE_UNIT_PRICE_URL = `${DEVICE_COORDINATOR_BASE_URL}/update_estimate_unit_price`;
const UPDATE_ORDER_UNIT_PRICE_URL = `${DEVICE_COORDINATOR_BASE_URL}/update_order_unit_price`;
const DELETE_ORDER_ESTIMATE_URL = `${DEVICE_COORDINATOR_BASE_URL}/delete_machine_estimate_order`;



if (Mock) {
  Mock.onGet(GET_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: device_coordinator_list,
  });
  Mock.onPost(DELETE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(CREATE_MACHINE_ESTIMATE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(CREATE_MACHINE_ORDER).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(SEND_MACHINE_ORDER_REPORT).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(SEND_MACHINE_ESTIMATE_REPORT).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(CANCEL_MACHINE_ESTIMATE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(CREATE_ORDER_OF_ESTIMATE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(RE_ESTIMATE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(RE_ORDER).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(LIST_CREATED_COMPANY).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      "entries": [
        {
          "company_id": 1,
          "company_branch_id": 1,
          "name": "株式会社〇〇リース1"
        },
        {
          "company_id": 2,
          "company_branch_id": 1,
          "name": "株式会社〇〇リース2"
        }
      ]
    },
  });
  Mock.onGet(new RegExp(`${GET_DETAIL_MACHINE_APPLICATION_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_detail_machine_application,
  });
  Mock.onGet(GET_WORK_PLAN_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_work_plan_list,
  });
  Mock.onPost(CREATE_MACHINE_APPLICATION_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(UPDATE_MACHINE_APPLICATION_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(new RegExp(`${GET_ESTIMATE_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries:
        {
          machine_company_field_estimate_order_relations: [
            {
              id: 1,
              machine_company_field_estimate_order_relation_details : 1,
              estimate_status: 1,
              machine_company_type_item_tree_name: "クレーン、エレベータ",
              machine_name: "土砂ホッパー用",
              standard_capacity: "100W",
              special_specification: "要望・コメント",
              quantity: 10,
              estimate_amount: 10,
              estimate_unit_price: 1000,
              estimate_total_amount: 10000,
              estimate_scheduled_delivery_date: "2021/10/12",
              estimate_remarks: "xxx"
            }
          ],
          machine_company_field_estimate_order_reports: [
            {
              id: 1,
              report: "訂正_202112.pdf",
              report_url: "http://localhost:81/api/file/read_file/report_1.pdf",
              created_at: "2021-12-01 00:17",
              machine_company_field_estimate_order_report_comments: [
                {
                  id: 1,
                  created_at: "2021-10-11 20:17"
                },
                {
                  id: 2,
                  created_at: "2021-10-11 20:17"
                }
              ]
            },
            {
              id: 2,
              report: "訂正_202113.pdf",
              report_url: "http://localhost:81/api/file/read_file/report_2.pdf",
              created_at: "2021-13-01 00:17",
              machine_company_field_estimate_order_report_comments: [
                {
                  id: 3,
                  created_at: "2021-10-13 20:17"
                }
              ]
            }
          ]
        }
    },
  });

  Mock.onGet(new RegExp(`${GET_ORDER_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries:
        {
          machine_company_field_estimate_orders: [
            {
              id: 1,
              machine_company_field_estimate_order_relation_detail_id : 1,
              order_status: 1,
              machine_company_type_item_tree_name: "order123",
              machine_name: "order123",
              quantity: 77,
              order_delivery_amount: 777,
              order_unit_price: 77,
              order_total_amount: 777,
              order_scheduled_delivery_date: "2021/10/12",
              order_remarks: "777"
            }
          ],
          machine_company_field_estimate_order_reports: [
            {
              id: 1,
              report: "訂正_202112.pdf",
              report_url: "http://localhost:81/api/file/read_file/report_1.pdf",
              created_at: "2021-12-01 00:17",
              machine_company_field_estimate_order_report_comments: [
                {
                  id: 1,
                  created_at: "2021-10-11 20:17"
                },
                {
                  id: 2,
                  created_at: "2021-10-11 20:17"
                }
              ]
            },
            {
              id: 2,
              report: "訂正_202113.pdf",
              report_url: "http://localhost:81/api/file/read_file/report_2.pdf",
              created_at: "2021-13-01 00:17",
              machine_company_field_estimate_order_report_comments: [
                {
                  id: 3,
                  created_at: "2021-10-13 20:17"
                }
              ]
            }
          ]
        }
    },
  });

  Mock.onGet(GET_MACHINE_ESTIMATE_DETAIL_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: {
        id: 1,
        estimate_machine_company_type_item_tree_ids: [1, 2, 3],
        estimate_machine_company_id: 1,
        estimate_rental_company_id: 1,
        estimate_rental_company_branch_id: 2,
        estimate_amount: 10,
        estimate_scheduled_delivery_date: "2021/10/12",
        estimate_remarks: "ここに備考がはいります。",
        estimate_image_remarks: "ここに備考がはいります。",
        machine_company_field_application_images: [],
        machine_company_field_applications: {
          machine_company_type_item_tree_name: "土工機械/ショベル系掘削機/ブルドーザ及びスクレーパ/ブルドーザ（•••",
          machine_company_name: "トラクター・ショベル",
          other_equipment: "ショベル",
          standard_capacity: "100W",
          special_specification:"ここに備考がはいります。",
          quantity: 1,
          request_estimate_quantity: 1,
          field_item_tree_name: "本館/1階/Aエリア/A工区",
          use_start_date: "2021/10/12",
          use_end_date: "2021/10/12",
          application_reason: "使用用途・申請理由"
        }
      }
    }
  });
  Mock.onPost(DELETE_ORDER_ESTIMATE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
}

export const deviceCoordinator = {
  get: (params) => {
    return Api.get(GET_LIST_URL, params);
  },
  getItemsTab: (params) => {
    return Api.get(GET_LIST_URL, params);
  },
  delete: async (params) => {
    return Api.post(DELETE_URL, params);
  },
  createMachineEstimate: async (params) => {
    return Api.post(CREATE_MACHINE_ESTIMATE, params);
  },
  createMachineOrder: async (params) => {
    return Api.post(CREATE_MACHINE_ORDER, params);
  },
  sendOrderReport: async (params) => {
    return Api.post(SEND_MACHINE_ORDER_REPORT, params);
  },
  sendMachineEstimateReport: async (params) => {
    return Api.post(SEND_MACHINE_ESTIMATE_REPORT, params);
  },
  cancelMachineEstimate: async (params) => {
    return Api.post(CANCEL_MACHINE_ESTIMATE, params);
  },
  createOrderOfEstimate: async (params) => {
    return Api.post(CREATE_ORDER_OF_ESTIMATE, params);
  },
  reOrder: async (params) => {
    return Api.post(RE_ORDER, params);
  },
  reEstimate: async (params) => {
    return Api.post(RE_ESTIMATE, params);
  },
  getListCreatedCompany: async (params) => { 
    return Api.get(LIST_CREATED_COMPANY, params);
  },
  getDetailMachineApplication: async (params) => {
    return Api.get(`${GET_DETAIL_MACHINE_APPLICATION_URL}/${params}`);
  },
  getWorkPlanList: (params) => {
    return Api.get(GET_WORK_PLAN_LIST_URL, params);
  },
  createMachineApplication: (params) => {
    return Api.post(CREATE_MACHINE_APPLICATION_URL, params);
  },
  updateMachineApplication: async (params) => {
    return Api.post(UPDATE_MACHINE_APPLICATION_URL, params);
  },
  getEstimate: async (params) => {
    return Api.get(`${GET_ESTIMATE_URL}/${params}`);
  },
  getOrder: async (params) => {
    return Api.get(`${GET_ORDER_URL}/${params}`);
  },
  replyEstimateResult: async (params) => {
    return Api.post(REPLY_ESTIMATE_RESULT_URL, params);
  },
  replyOrderResult: async (params) => {
    return Api.post(REPLY_ORDER_RESULT_URL, params);
  },
  downloadReport: async (params) => {
    return Api.post(DOWNLOAD_REPORT_URL, params);
  },
  getReportComment: async (params) => {
    return Api.get(`${GET_REPORT_COMMENT_URL}/${params}`);
  },
  getMachineEstimateDetail: async (params) => {
    return Api.get(`${GET_MACHINE_ESTIMATE_DETAIL_URL}/${params}`);
  },
  getMachineOrderDetail: async (params) => {
    return Api.get(`${GET_MACHINE_ORDER_DETAIL_URL}/${params}`);
  },
  updateMachineEstimateOrder: async (params) => {
    return Api.post(UPDATE_MACHINE_ESTIMATE_ORDER_URL, params);
  },
  createReportComment: async (params) => {
    return Api.post(CREATE_REPORT_COMMENT_URL, params);
  },
  createReport: async (params) => {
    return Api.post(CREATE_REPORT_URL, params);
  },
  updateEstimateUnitPrice: async (params) => {
    return Api.post(UPDATE_ESTIMATE_UNIT_PRICE_URL, params);
  },
  updateOrderUnitPrice: async (params) => {
    return Api.post(UPDATE_ORDER_UNIT_PRICE_URL, params);
  },
  deleteOrderEstimate: async (params) => {
    return Api.post(DELETE_ORDER_ESTIMATE_URL, params);
  },
};
