<template>
  <div v-if="multipleMove">
    <div
      @click="prev"
      class="prev-form"
      v-if="index !== 0 && activeSection === 0 && !isNewItem && !disabledDoublePrev"
    >
      <v-icon
        large
      >
        mdi-chevron-double-up
      </v-icon>
      <div>前の項目へ</div>
    </div>
    
    <div
      @click="prevItem"
      class="prev-form"
      v-if="activeSection > 0"
    >
      <v-icon
        large
      >
        mdi-chevron-up
      </v-icon>
      <div>前へ</div>
    </div>
    
    <div
      @click="next"
      class="next-form"
      v-if="index < (listData.length - 1) && activeSection === (dataItems.length - 1) && !isNewItem && !disabledDoubleNext"
    >
      <div>次の項目へ</div>
      <v-icon
        large
      >
        mdi-chevron-double-down
      </v-icon>
    </div>
    
    <div
      @click="nextItem"
      class="next-form"
      v-if="activeSection < (dataItems.length - 1)"
    >
      <v-icon
        large
      >
        mdi-chevron-down
      </v-icon>
      <div>次へ</div>
    </div>
  </div>
  <div v-else>
    <div
      @click="prev"
      class="prev-form"
      v-if="index !== 0"
    >
      <v-icon
        large
      >
        mdi-chevron-double-up
      </v-icon>
      <div>前の項目へ</div>
    </div>
    <div
      @click="next"
      class="next-form"
      v-if="index < (listData.length - 1)"
    >
      <div>次の項目へ</div>
      <v-icon
        large
      >
        mdi-chevron-double-down
      </v-icon>
    </div>
  </div>
</template>

<script>

import {Store} from "@/store/Store";
import _ from "lodash";

export default {
  data: () => {
    return {
      index: 0,
      listData: [],
    };
  },
  components: {
  },
  props: {
    activeSection: {
      type: Number,
      default: 0,
    },
    dataItems: {
      type: Array,
      default: () => [],
    },
    multipleMove: Boolean,
    isNewItem: Boolean,
    disabledDoubleNext: Boolean,
    disabledDoublePrev: Boolean,
  },
  mounted() {
    this.$watch(
      () => Store.getters[`Action/getIndex`],
      (data) => {
        this.index = data;
      },
      {
        immediate : true,
        deep: true
      }
    );
    this.$watch(
      () => Store.getters[`Action/getListData`],
      (data) => {
        this.listData = _.cloneDeep(data);
      },
      {
        immediate : true,
        deep: true
      }
    );
  },
  computed: {
  },
  methods: {
    next() {
      if (this.index < this.listData.length - 1) {
        Store.dispatch(`Action/setIndex`, this.index + 1);
        this.$emit('loading', true);
      }
    },
    prev() {
      if (this.index > 0) {
        Store.dispatch(`Action/setIndex`, this.index - 1);
        this.$emit('loading', true);
      }
    },
    prevItem() {
      this.$emit("prev");
    },
    nextItem() {
      this.$emit("next");
    },
  },
};
</script>

<style lang="css" scoped>
.prev-form {
  position: absolute;
  width: 100px;
  height: 100px;
  z-index: 9999;
  right: 10px;
  top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.next-form {
  position: absolute;
  width: 100px;
  height: 100px;
  z-index: 9999;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
@media (max-width:1600px) {
  .next-form, .prev-form{
    width: 70px !important;
    font-size: 13px !important;
    font-weight: 500;
  }
  .next-form >div, .prev-form >div{
    white-space: nowrap;
  }
  .next-form i, .prev-form i{
    font-size: 28px !important;
  }

}
@media (max-width:1450px) {
  .next-form, .prev-form{
    width: 70px !important;
    font-size: 13px !important;
    font-weight: 500;
  }
  .next-form >div, .prev-form >div{
    white-space: nowrap;
  }
  .next-form i, .prev-form i{
    font-size: 28px !important;
  }
  
}
</style>
