import axios from "axios";

export function viewSourceWithAuth(sourceUrl) {
  if (!sourceUrl) {
    return;
  }

  axios({
    url: replaceProtocols(sourceUrl),
    method: "GET",
    responseType: "blob",
    interceptors: {
      request: axios.interceptors.request.use((config) => {
        const GRN = JSON.parse(sessionStorage.getItem("GRN"));
        sessionStorage.getItem("GRN") &&
          (config.headers.Authorization = `${GRN.Login.api_token}`);
        return config;
      }),
    },
  }).then((response) => {
    console.log("response", response);
    const objectURL = window.URL.createObjectURL(
      new Blob([response.data], { type: response.headers["content-type"] })
    );
    window.open(objectURL);
  });
}

export const getParamsOfUrlReadFile = (url) => {
  let urlTemps = replaceProtocols(url);
  let urlFile = replaceProtocols(urlTemps.substr(0, urlTemps.indexOf("?")));
  let stringParams = urlTemps.replace(urlFile + "?", "");
  let objectParams = JSON.parse(
    '{"' +
      decodeURI(stringParams)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
  let params = {
    url: urlFile,
    objectParams: objectParams,
  };

  return params;
};

const replaceProtocols = (url) => {
  if (url && process.env.VUE_APP_API_BASE_URL.includes("https:")) {
    url = url.replace("http:", "https:");
  }
  return url;
};
