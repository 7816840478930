<template>
    <ValidationProvider
      v-slot="{ errors, valid }"
      :name="validation_label"
      :rules="validation_rules"
      :vid="vid"
    >
      <v-text-field
        v-model="val"
        dense
        color="primary"
        :disabled="!editable"
        :filled="editable"
        :error-messages="errors"
        :success="valid"
        :suffix="suffix"
        :prefix="prefix"
        :readonly="readonly"
        :placeholder="editable ? placeholder : ''"
        append-icon="mdi-menu-down"
        @input="$emit('onInput', { name, value: val })"
        @click="$emit('onClick')"
        @blur="$emit('onBlur')"
        :data-testid="testid"
      ></v-text-field>
    </ValidationProvider>
  </template>
  <script>
  import { ValidationProvider } from "vee-validate";
  export default {
    components: {
      ValidationProvider,
    },
    props: {
      editable: {
        type: Boolean,
        default: false,
      },
      name: {
        type: String,
      },
      values: {
        type: Object,
      },
      validation_rules: {
        type: String,
      },
      validation_label: {
        type: String,
      },
      placeholder: {
        type: String,
        default: "",
      },
      testid: {
        type: String,
      },
      suffix: {
        type: String,
        default: "",
      },
      vid: {
        type: String,
      },
      prefix: {
        type: String,
        default: "",
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      listWorkQualifications: {
        type: Array,
        default: () => []
      }
    },
    data: () => {
      return {
        val: "",
      };
    },
    mounted() {
      this.$watch(
        () => [this.values, this.name],
        (newValue) => {
          const formValues = newValue[0];
          const name = newValue[1];
          if (formValues && name){
            let listText = []
            this.listWorkQualifications.forEach(x => {
              if(formValues[name].some(y => y == x.id)){
                listText.push(x.short_name)
              }
            })
            this.val = listText.toString().replace(/,/g, "、")
          }
        },
        { immediate: true, deep: true }
      )
    },
  };
  </script>
