<template>
  <div :style="getStyle">
    <slot></slot>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
export default {
  computed: {
    zoomLevel() {
      return Store.getters["Oekaki/getZoom"];
    },
    getStyle() {
      return `transform:scale(${this.zoomLevel});transform-origin:top left;`;
    },
  },
};
</script>
