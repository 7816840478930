<template>
  <div>
    <Loading v-if="isLoading" />
    <ShitekiForm
      :construction_id="construction_id"
      :recordId="recordId"
      :isNewItem="isNewItem"
      :childId="childId"
      :isShowMove="isShowMove"
      :disabledDoubleNext="disabledDoubleNext"
      :disabledDoublePrev="disabledDoublePrev"
      @actionSuccess="actionSuccess"
      @cancel="closeFormPalTrol"
      @loading="setLoading"
      @showLoading="showLoading"
      v-if="isShiteki"
    >
    </ShitekiForm>
    <MultipleShitekiForm
      :construction_id="construction_id"
      :isNewItem="isNewItem"
      :recordId="recordId"
      v-if="isMultipleShiteki"
      @actionSuccess="actionSuccess"
      @cancel="closeFormPalTrol"
      @loading="setLoading"
      @showLoading="showLoading"
      :isShowMove="true"
      :disabledDoubleNext="disabledDoubleNext"
      :disabledDoublePrev="disabledDoublePrev"
    >
    </MultipleShitekiForm>
    <ContactForm
      :construction_id="construction_id"
      :isNewItem="isNewItem"
      :recordId="recordId"
      v-if="isContact"
      @actionSuccess="actionSuccess"
      @cancel="closeFormPalTrol"
      @loading="setLoading"
      @showLoading="showLoading"
      :isShowMove="isShowMove"
      :disabledDoubleNext="disabledDoubleNext"
      :disabledDoublePrev="disabledDoublePrev"
    >
    </ContactForm>
  </div>
</template>

<script>
import ShitekiForm from "@/components/forms/schedule/patrols/PaltrolForm/ShitekiForm/index.vue";
import MultipleShitekiForm from "@/components/forms/schedule/patrols/PaltrolForm/MultipleShitekiForm/index.vue";
import ContactForm from "@/components/forms/schedule/patrols/PaltrolForm/ContactForm/index.vue";
import {
  MENU_FORM_PATROL,
} from "@/constants/PATROL_RECORD";
import _ from "lodash";
import { Store } from "@/store/Store";
import Loading from "@/components/loading/Loading.vue";

export default {
  data() {
    return {
      isShiteki: false,
      isMultipleShiteki: false,
      isContact: false,
      listDataAction: [],
      move: false,
      recordIdChange: null,
      isLoading: false,
    };
  },
  props: {
    isNewItem: Boolean,
    construction_id : Number,
    recordId : Number,
    typeForm: Number,
    childId: Number,
    isShowMove:Boolean,
    disabledDoubleNext: Boolean,
    disabledDoublePrev: Boolean,
  },
  components: {
    ShitekiForm,
    MultipleShitekiForm,
    ContactForm,
    Loading,
  },
  mounted() {
    this.$watch(
      () => this.typeForm,
      (id) => {
        if (id) {
          this.checkTypeForm(id);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => Store.getters[`Action/getListData`],
      (data) => {
        if (!this.isNewItem) {
          this.listDataAction = _.cloneDeep(data);
        }
      },
      {
        immediate : true,
        deep: true
      }
    );
  
    this.$watch(
      () => Store.getters[`Action/getIndex`],
      async (newValue, oldValue) => {
        await Store.dispatch(`Action/setActionSection`, 0);
        if (newValue !== oldValue && !this.isNewItem && this.move) {
          const item = this.listDataAction[newValue];
          if (item.safety_form) {
            this.patrol_record_id = item.safety_diaries_id;
            await Store.dispatch(`PatrolRecord/getDetailNew`, this.patrol_record_id);
            if (item.safety_form === MENU_FORM_PATROL.SHITEKI.id) {
              this.$emit('updateTypeForm', MENU_FORM_PATROL.SHITEKI.id);
            } else if (item.safety_form === MENU_FORM_PATROL.MULTIPLE_SHITEKI.id) {
              this.$emit('updateTypeForm', MENU_FORM_PATROL.MULTIPLE_SHITEKI.id);
            } else {
              this.$emit('updateTypeForm', MENU_FORM_PATROL.CONTACT.id);
            }
            this.recordIdChange = item.safety_diaries_id;
          }
        }
      },
      {
        immediate : true,
        deep: true
      }
    );
    this.$watch(
      () => this.recordIdChange,
      (newValue, oldValue) => {
        if (newValue !== oldValue && !this.isNewItem && this.move) {
          this.$emit('updateRecordId', newValue);
        }
      },
      {
        immediate : true,
        deep: true
      }
    );
  },
  methods: {
    checkTypeForm(id) {
      switch (id){
        case MENU_FORM_PATROL.SHITEKI.id :
          this.isShiteki = true;
          this.isMultipleShiteki = false;
          this.isContact =  false;
          break;
        case MENU_FORM_PATROL.MULTIPLE_SHITEKI.id :
          this.isShiteki = false;
          this.isMultipleShiteki = true;
          this.isContact =  false;
          break;
        case MENU_FORM_PATROL.CONTACT.id :
          this.isShiteki = false;
          this.isMultipleShiteki = false;
          this.isContact =  true;
          break;
        default:
          break;
      }
    },
    actionSuccess(flag) {
      this.$emit("actionSuccess", flag);
    },
    closeFormPalTrol(){
      this.isShiteki = false;
      this.isMultipleShiteki = false;
      this.isContact =  false;
      this.$emit("closeFormPalTrol");
    },
    setLoading(flg) {
      this.move = flg;
    },
    showLoading(flg) {
      this.isLoading = flg;
    }
  }
}
</script>

<style scoped>

</style>
