<template>
  <ValidationObserver ref="machineSpecValidate" v-slot="machineSpecValidate">
    <div class="dialog">
      <v-card>
        <v-card-title>
          <div class="title">点検項目追加</div>
        </v-card-title>
        <v-card-text class="body">
          <div class="text-wrap">
            <div class="text">機械特有の点検項目を追加します。</div>
          </div>
        </v-card-text>
        <v-card-text>
          <Label label="点検項目" :required="true" :editable="true">
            <InputText
              name="inspection_item"
              :values="formValues"
              :editable="true"
              validation_label="点検項目"
              validation_rules="required|max:255"
              @onInput="onInput"
            />
          </Label>
          <Label label="点検主眼点" :required="true" :editable="true">
            <InputTextArea
              name="main_point"
              :values="formValues"
              :editable="true"
              validation_label="点検主眼点"
              @onInput="onInput"
              validation_rules="required|max:512"
            />
          </Label>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed outlined color="primary" class="btn" @click="close">
            やめる
          </v-btn>
          <v-btn
            depressed
            :disabled="!valid"
            class="btn"
            color="primary"
            @click="handleSubmit()"
            data-testid="btn-submit"
          >
            追加
          </v-btn>
        </v-card-actions>
      </v-card>
      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>
    </div>
    <ValidationCallback
      :observer="machineSpecValidate"
      @callback="updateValidate"
    />
  </ValidationObserver>
</template>

<script>
import Label from "@/components/forms/elements/Label.vue";
import InputText from "@/components/forms/elements/InputText.vue";
import InputTextArea from "@/components/forms/elements/InputTextArea.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import Popup from "@/components/common/Popup.vue";
import ConfirmRemoveDialog from "./../../ConfirmRemoveDialog.vue";
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
export default {
  data() {
    return {
      timeout: null,
      isShowConfirmDialog: false,
      valid: false,
      error: null,
      formValues: null,
    };
  },
  components: {
    Label,
    InputText,
    InputTextArea,
    ConfirmCloseDialog,
    Popup,
    ConfirmRemoveDialog,
    ValidationObserver,
    ValidationCallback,
  },
  props: {
    newItem: {
      type: Object,
      default: null,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => [this.newItem],
      (newValue) => {
        this.formValues = { ...newValue[0] };
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    close() {
      this.isShowConfirmDialog = true;
    },
    handleSubmit() {
      this.$emit("yes");
    },
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
    },
    closeForm() {
      this.isShowConfirmDialog = false;
      this.$emit("close");
    },
    updateValidate({ valid, errors }) {
      this.valid = valid;
      this.errors = errors;
    },
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  overflow: hidden;
  .title {
    font-size: 12px;
    color: #1b9c4f;
  }
  .body {
    .text-wrap {
      .text {
        margin: auto;
        width: 95%;
      }
    }
  }
  .item {
    &:after {
      content: ",";
    }
    &:last-child {
      &:after {
        content: "";
      }
    }
  }
}
</style>
