import { format } from "date-fns";
// テーブル設定
const COORDINATOR_TABLE_LABELS_1 = [
  {
    text: "",
    align: "left",
    value: "application_status",
  },
  {
    text: "申請日",
    value: "application_date",
    align: "left",
  },
  {
    text: "種別 / 機械名",
    value: "machine_company_type_items_extral_machine_companies",
    align: "left",
  },
  {
    text: "性能規格",
    value: "standard_capacity",
    align: "left",
  },
  {
    text: "要望・特記仕様",
    value: "special_specification",
    align: "left",
  },
  {
    text: "数量",
    value: "quantity",
    align: "left",
  },
  {
    text: "申請者",
    value: "company_branches_extral_users",
    align: "left",
  },
  {
    text: "使用開始日 / 返却予定日",
    value: "used_start_date_extral_used_end_date",
    align: "left",
  },
  {
    text: "搬入予定日",
    value: "order_desired_delivery_date",
    align: "left",
  },
];

const COORDINATOR_TABLE_LABELS_2 = [
  {
    text: "",
    align: "left",
    value: "estimate_status",
  },
  {
    text: "依頼日",
    value: "estimate_request_date",
    align: "left",
  },
  {
    text: "見積番号",
    value: "estimate_number",
    align: "left",
  },
  {
    text: "回答期限",
    value: "estimate_desired_response_date",
    align: "left",
  },
  {
    text: "建機レンタル会社",
    value: "estimate_rental_company_name",
    align: "left",
  },
  {
    text: "有効期限",
    value: "estimate_expiration_date",
    align: "left",
  },
  {
    text: "最終更新者",
    value: "last_update_user",
    align: "left",
  },
  {
    text: "最終更新日時",
    value: "last_update_date",
    align: "left",
  },
  {
    text: "見積書",
    value: "report_flag",
    align: "left",
  },
];

const COORDINATOR_TABLE_LABELS_3 = [
  {
    text: "",
    align: "left",
    value: "order_status",
  },
  {
    text: "発注日",
    value: "order_request_date",
    align: "left",
  },
  {
    text: "発注番号",
    value: "order_number",
    align: "left",
  },
  {
    text: "見積番号",
    value: "estimate_number",
    align: "left",
  },
  {
    text: "搬入希望日",
    value: "order_desired_delivery_date",
    align: "left",
  },
  {
    text: "建機レンタル会社",
    value: "order_rental_company_name",
    align: "left",
  },
  {
    text: "最終更新者",
    value: "last_update_user",
    align: "left",
  },
  {
    text: "最終更新日時",
    value: "last_update_date",
    align: "left",
  },
  {
    text: "納品書",
    value: "report_flag",
    align: "left",
  },
];

const TARGET_ROLE = [1, 2, 3, 4];

const COORDINATOR_SORT_ITEMS_1 = [
  { id: "application_date", name: "申請日" },
  { id: "created_company_name", name: "物品申請会社" },
  { id: "machine_name", name: "機械名" },
];

const COORDINATOR_SORT_ITEMS_2 = [
  { id: "estimate_request_date", name: "依頼日" },
  { id: "estimate_number", name: "見積番号" },
  { id: "estimate_desired_response_date", name: "回答期限" },
  { id: "estimate_expiration_date", name: "有効期限" },
  { id: "last_update_date", name: " 最終更新日時" },
];

const COORDINATOR_SORT_ITEMS_3 = [
  { id: "order_request_date", name: "発注日" },
  { id: "order_number", name: "発注番号" },
  { id: "estimate_number", name: "見積番号" },
  { id: "order_desired_delivery_date", name: "搬入希望日" },
  { id: "last_update_date", name: "最終更新日時" },
];

const FORMS_ALL = {
  TABLE1: { id: 1, name: "物品申請" },
  TABLE2: { id: 2, name: "見積" },
  TABLE3: { id: 3, name: "発注" },
};

const FORMS_RENTAL = {
  TABLE2: { id: 2, name: "見積" },
  TABLE3: { id: 3, name: "発注" },
};


const FORMS_PARTNER = {
  TABLE1: { id: 1, name: "物品申請" },
};

const USING_STATUS = {
  USING: { id: 4, name: "使用中" },
  UNUSED: { id: 2, name: "未使用" },
};

const TYPE_STATUS = {
  UNAPPROVE: { id: 0, name: "未承認" },
  ESTIMATE: { id: 1, name: "見積中" },
  UNDER_ORDER: { id: 2, name: "発注中" },
};

const ANSWER_STATUS = {
  UNANSWERED: { id: 0, name: "未回答" },
  ANSWERED: { id: 1, name: "回答済" },
};

const ANSWER_EXPIRED_STATUS = {
  ANSWER_EXPIRED: { id: 0, name: "回答期限切れ" },
  EXPIRED: { id: 1, name: "有効期限切れ" }
}

const STATUS_COLORS_TAB1 = [
  { status: 0, color: "#E5951D", value: "未承認" },
  { status: 1, color: "#32C36C", value: "見積中" },
  { status: 2,color: "#29ABE2", value: "発注済" },
  { status: 3,color: "rgba(0, 0, 0, 0.6)", value: "納品済" }
];

const STATUS_COLORS_TAB2 = [
  { status: 0, color: "#E5951D", value: "未回答" },
  { status: 1, color: "#32C36C", value: "回答済" },
  { status: 2, color: "#FF7B52", value: "見送り" },
  { status: 3, color: "rgba(0, 0, 0, 0.6)", value: "発注済" }
];

const STATUS_COLORS_TAB3 = [
  { status: 0, color: "#E5951D", value: "未回答" },
  { status: 1, color: "#32C36C", value: "納品済" },
];

const TAB3_TYPE = {
  UNANSWERED: { id: 0, name: "未回答" },
  DELIVERED: { id: 1, name: "納品済" },
};

const ROLE_USER = [0, 1, 2, 3, 4];

const COORDINATOR_TABS = {
  TAB1: 1,
  TAB2: 2,
  TAB3: 3,
};

const SEARCH_PARAMS_DEFAULT_TAB_1 = {
  application_date_from: format(new Date(), "yyyy/MM/dd"),
  application_date_to: format(new Date(), "yyyy/MM/dd"),
  application_status: [],
  delivered_flag: 0,
  created_company_id: null,
  created_company_branch_id: null,
  index_company_id : null
};

const SEARCH_PARAMS_DEFAULT_TAB_2 = {
  estimate_request_date_from: format(new Date(), "yyyy/MM/dd"),
  estimate_request_date_to: format(new Date(), "yyyy/MM/dd"),
  estimate_rental_company_id: null,
  estimate_rental_company_branch_id: null,
  estimate_status: [],
  estimate_number: null,
  estimate_expired_flag: [],
  estimate_finished_flag: 0,
  index_estimate_company_id : null
};

const SEARCH_PARAMS_DEFAULT_TAB_3 = {
  order_request_date_from: format(new Date(), "yyyy/MM/dd"),
  order_request_date_to: format(new Date(), "yyyy/MM/dd"),
  order_rental_company_id: null,
  order_rental_company_branch_id: null,
  order_number: null,
  estimate_number: null,
  order_status: [],
  index_order_company_id : null
};

const INIT_DATA_FORM = {
  machine_company_field_applications: {
    machine_company_type_item_tree_id: null,
    machine_company_id: null,
    other_equipment: "",
    standard_capacity: "",
    special_specification: "",
    quantity: 1,
    request_estimate_quantity: 1,
    field_item_tree_id: null,
    use_start_date: "",
    use_end_date: "",
    application_reason: "",
    used_field_construction_id: null,
    used_field_tree_group_id: null,
    used_field_tree_id: null,
    used_foreman_user_id: null,
    image_remarks: ""
  },
  machine_company_field_application_work_plans: [
    {
      work_plan_id: null
    }
  ],
  machine_company_field_application_drivers: [
    {
      driver_user_id: null,
      machine_company_field_application_driver_licences: [
        {
          user_licence_id: null
        }
      ],
      machine_company_field_application_driver_sp_trainings: [
        {
          user_sp_training_id: null
        }
      ]
    }
  ],
  machine_company_field_application_images: [
    {
      image: "",
      image_data: "",
    }
  ]
};

const TITLE_FORMS = {
  NEW: "物品申請情報",
  EDIT: "物品申請情報",
};

/**
 * フォームとタブの設定
 */
 const FORMS_CREATE = {
  MachineApplicationsTab1: { id: 1, title: "基本情報" },
  MachineApplicationsTab2: { id: 2, title: "使用会社" },
  MachineApplicationsTab3: { id: 3, title: "写真" },
};

const FORMS_UPDATE = {
  MachineApplicationsTab1: { id: 1, title: "基本情報" },
  MachineApplicationsTab2: { id: 2, title: "使用会社" },
  MachineApplicationsTab3: { id: 3, title: "写真" },
  MachineApplicationsTab4: { id: 4, title: "見積・発注履歴" },
};

const FORMS_DETAIL_MACHINE_ESTIMATE = {
  MachineDetailFormPage1: { id: 1, title: "基本情報" },
  MachineDetailFormPage2: { id: 2, title: "写真" },
  MachineDetailFormPage3: { id: 3, title: "見積依頼" },
};

const FORMS_DETAIL_MACHINE_ORDER  = {
  MachineDetailFormPage1: { id: 1, title: "基本情報" },
  MachineDetailFormPage2: { id: 2, title: "写真" },
};

const TAB_NAME_ESTIMATE = {
  FormMachineName: { id: 1, title: "機械名" },
  FormQuotation: { id: 2, title: "見積書" },
};

const TAB_NAME_ORDER  = {
  FormMachineName: { id: 1, title: "機械名" },
  FormQuotation: { id: 2, title: "納品書" },
};

const STORE = "DeviceCoordinator";


const TABLE_LABELS_FORM_MACHINE_ESTIMATE = [
  {
    text: "",
    value: "estimate_status",
    align: "left",
    width: "10%"
  },
  {
    text: "種別 / 機械名",
    value: "machine_company_type_item_tree_name",
    align: "left",
    width: "20%"
  },
  {
    text: "性能規格",
    value: "standard_capacity",
    align: "left",
    width: "10%"
  },
  {
    text: "要望・特記仕様",
    value: "special_specification",
    align: "left",
    width: "10%"
  },
  {
    text: "数量",
    value: "quantity",
    align: "left",
    width: "5%"
  },
  {
    text: "見積量",
    value: "estimate_amount",
    align: "left",
    width: "5%"
  },
  {
    text: "単価",
    value: "estimate_unit_price",
    align: "left",
    width: "10%"
  },
  {
    text: "合計",
    value: "estimate_total_amount",
    align: "left",
    width: "10%"
  },
  {
    text: "搬入予定日",
    value: "estimate_scheduled_delivery_date",
    align: "left",
    width: "10%"
  },
  {
    text: "備考",
    value: "estimate_remarks",
    align: "left",
    width: "10%"
  },
];

const TABLE_LABELS_FORM_MACHINE_ORDER = [
  {
    text: "",
    value: "order_status",
    align: "left",
    width: "10%"
  },
  {
    text: "種別 / 機械名",
    value: "machine_company_type_item_tree_name",
    align: "left",
    width: "30%"
  },
  {
    text: "数量",
    value: "quantity",
    align: "left",
    width: "10%"
  },
  {
    text: "納品数",
    value: "order_delivery_amount",
    align: "left",
    width: "10%"
  },
  {
    text: "単価",
    value: "order_unit_price",
    align: "left",
    width: "10%"
  },
  {
    text: "合計",
    value: "order_total_amount",
    align: "left",
    width: "10%"
  },
  {
    text: "搬入予定日",
    value: "order_scheduled_delivery_date",
    align: "left",
    width: "10%"
  },
  {
    text: "備考",
    value: "order_remarks",
    align: "left",
    width: "10%"
  },
];

const BILL_STATUS_ESTIMATE = [
  {id: 0, name: "確認中", color: "#E5951D"},
  {id: 1, name: "回答済", color: "#32C36C"},
  {id: 2, name: "辞退", color: "#C0C0C0"},
];

const BILL_STATUS_ORDER = [
  {id: 0, name: "確認中", color: "#E5951D"},
  {id: 1, name: "納品済", color: "#32C36C"},
  {id: 2, name: "辞退", color: "#C0C0C0"},
];

const RESULT_BILL_STATUS_ESTIMATE = [
  {id: 0, name: "確認中にする", value: 0},
  {id: 1, name: "見積結果を確定する", value: 1},
  {id: 2, name: "見積を辞退する", value: 2}
];

const RESULT_BILL_STATUS_ORDER = [
  {id: 0, name: "確認中にする", value: 0},
  {id: 1, name: "納品済みする", value: 1},
  {id: 2, name: "辞退する", value: 2}
];

const INIT_ITEM_FORM3 =[
  {
    estimate_request_date: "",
    estimate_requester_user_name: "",
    estimate_rental_company_name: "",
    estimate_number: "",
    order_request_date: "",	
    order_requester_user_name: "",
    order_rental_company_name: "",
    order_number: ""
  }	
];

export {
  COORDINATOR_TABLE_LABELS_1,
  COORDINATOR_TABLE_LABELS_2,
  COORDINATOR_TABLE_LABELS_3,
  COORDINATOR_SORT_ITEMS_1,
  COORDINATOR_SORT_ITEMS_2,
  COORDINATOR_SORT_ITEMS_3,
  USING_STATUS,
  STATUS_COLORS_TAB1,
  STATUS_COLORS_TAB2,
  ROLE_USER,
  TYPE_STATUS,
  ANSWER_STATUS,
  TARGET_ROLE,
  STATUS_COLORS_TAB3,
  COORDINATOR_TABS,
  TAB3_TYPE,
  FORMS_ALL,
  FORMS_PARTNER,
  FORMS_RENTAL,
  ANSWER_EXPIRED_STATUS,
  SEARCH_PARAMS_DEFAULT_TAB_1,
  SEARCH_PARAMS_DEFAULT_TAB_2,
  SEARCH_PARAMS_DEFAULT_TAB_3,
  INIT_DATA_FORM,
  TITLE_FORMS,
  FORMS_CREATE,
  FORMS_UPDATE,
  STORE,
  TABLE_LABELS_FORM_MACHINE_ESTIMATE,
  TABLE_LABELS_FORM_MACHINE_ORDER,
  BILL_STATUS_ESTIMATE,
  BILL_STATUS_ORDER,
  RESULT_BILL_STATUS_ESTIMATE,
  RESULT_BILL_STATUS_ORDER,
  FORMS_DETAIL_MACHINE_ESTIMATE,
  FORMS_DETAIL_MACHINE_ORDER,
  TAB_NAME_ESTIMATE,
  TAB_NAME_ORDER,
  INIT_ITEM_FORM3,
};
