<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel label="職種" :editable="editable" />
                      <!-- 大分類 -->
                      <OccupationInput
                        name="user_experiences"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput(USER_EVENT_TARGET.USER_EXPERIENCES, $event)"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <!-- 雇入年月日 -->
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="6">
                      <Label label="雇入年月日" :editable="editable">
                        <InputDatepicker
                          name="employment_start_date"
                          :values="formValues.users"
                          :editable="editable"
                          placeholder="yyyy/mm/dd"
                          :max="max"
                          validation_label="雇入年月日"
                          validation_rules=""
                          :flagNull="true"
                          @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                        />
                      </Label>
                    </v-col>
                  </v-row>
                  <SectionLabel
                    label="保有する登録基幹技能者"
                    :editable="editable"
                    class="mt-6"
                  />
                  <RegisterCoreTechnicianInput
                    name="user_engineer_jobs"
                    :values="formValues"
                    :editable="editable"
                    :errors="errors"
                    @onInput="onInput(USER_EVENT_TARGET.USER_ENGINEER_JOBS, $event)"
                  />
                  <SectionLabel label="技能士" :editable="editable" class="mt-6" />
                  <SkillPersonInput
                    name="user_engineer_categories"
                    :values="formValues"
                    :editable="editable"
                    :errors="errors"
                    @onInput="onInput(USER_EVENT_TARGET.USER_ENGINEER_CATEGORIES, $event)"
                  />
                  <SectionLabel label="資格・免許" :editable="editable" class="mt-6" />
                  <QualificationLisenceInput
                    name="user_licenses"
                    :values="formValues"
                    :editable="editable"
                    :errors="errors"
                    @onInput="onInput(USER_EVENT_TARGET.USER_LICENSES, $event)"
                  />
                  <SectionLabel label="技能講習" :editable="editable" class="mt-6" />
                  <SkillTrainingInput
                    name="user_skill_trainings"
                    :values="formValues"
                    :editable="editable"
                    :errors="errors"
                    @onInput="onInput(USER_EVENT_TARGET.USER_SKILL_TRAINING, $event)"
                  />
                  <SectionLabel label="特別教育" :editable="editable" class="mt-6" />
                  <SpecialEducationInput
                    name="user_sp_trainings"
                    :values="formValues"
                    :editable="editable"
                    :errors="errors"
                    @onInput="onInput(USER_EVENT_TARGET.USER_SP_TRAININGS, $event)"
                  />
                  <SectionLabel
                    label="その他安全衛生講習"
                    :editable="editable"
                    class="mt-6"
                  />
                  <SafetyHealthTrainingInput
                    name="user_ex_trainings"
                    :values="formValues"
                    :editable="editable"
                    :errors="errors"
                    @onInput="onInput(USER_EVENT_TARGET.USER_EX_TRAININGS, $event)"
                  />
                  <SectionLabel
                    label="表彰等の履歴"
                    :editable="editable"
                    class="mt-6"
                  />
                  <HistoryAwardInput
                    name="user_awards"
                    :values="formValues"
                    :editable="editable"
                    :errors="errors"
                    @onInput="onInput(USER_EVENT_TARGET.USER_AWARDS, $event)"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "../elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputDatepicker from "../elements/InputDatepicker.vue";
import OccupationInput from "./components/OccupationInput.vue";
import RegisterCoreTechnicianInput from "./components/RegisterCoreTechnicianInput.vue";
import SkillPersonInput from "./components/SkillPersonInput.vue";
import QualificationLisenceInput from "./components/QualificationLisenceInput.vue";
import SkillTrainingInput from "./components/SkillTrainingInput.vue";
import SpecialEducationInput from "./components/SpecialEducationInput.vue";
import SafetyHealthTrainingInput from "./components/SafetyHealthTrainingInput.vue";
import HistoryAwardInput from "./components/HistoryAwardInput.vue";
import { USER_EVENT_TARGET } from "@/constants/EMPLOYEE";
export default {
  data: () => {
    return {
      formValues: {},
      USER_EVENT_TARGET,
      valid: false,
      employmentStartDate: {
        employment_start_date: null,
      },
      max : new Date().toISOString().slice(0, 10)
    };
  },
  components: {
    Label,
    SectionLabel,
    InputDatepicker,
    OccupationInput,
    RegisterCoreTechnicianInput,
    SkillPersonInput,
    QualificationLisenceInput,
    SkillTrainingInput,
    SpecialEducationInput,
    SafetyHealthTrainingInput,
    HistoryAwardInput,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    errors: {
      type: Object,
      default: {},
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
        if (!this.editable) {
          this.employmentStartDate[
            "employment_start_date"
          ] = `${this.formValues.users.employment_start_date}`;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      return `height:${this.mainHeight}px;`;
    },
  },
  methods: {
    onInput(parents_name, { name, value }) {
      const formValues = { ...this.formValues };
      if (parents_name === "users") {
        formValues[parents_name][name] = value;
      } else {
        formValues[name] = value;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";
</style>
