import Vue from "vue";
import Vuex from "vuex";
import { drawing } from "@/api/modules/drawing";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = drawing;

export const Oekaki = {
  namespaced: true,

  state: {
    selectedId: null,
    zoomLevel: 1,
  },

  mutations: {
    SET_ZOOM(state, value) {
      let zoomLevel = state.zoomLevel;
      zoomLevel = zoomLevel + value;
      state.zoomLevel = zoomLevel;
    },
    SET_ITEM_ID(state, id) {
      state.selectedId = id;
    },
  },

  actions: {
    // ロード
    async loadData(context, payload) {
      if (!payload)
        return {
          hasError: true,
        };
      const response = await ENTITY.get(payload);
      console.log("Oekaki loadData", response);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      return data;
    },

    // 画像取得
    async getImage(content, payload) {
      if (!payload)
        return {
          hasError: true,
        };
      const response = await ENTITY.getImage(payload);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      return data;
    },

    async getHistory(context, payload) {
      if (!payload)
        return {
          hasError: true,
        };
      const response = await ENTITY.getHistoryDates(payload);
      console.log("Oekaki getHistoryDates", response);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      return data;
    },

    async saveExportedFile(context, payload) {
      if (!payload)
        return {
          hasError: true,
        };
      const response = await ENTITY.saveExportedFile(payload);
      console.log("Oekaki saveExportedFile", response);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      return data;
    },

    /**
     * トークン
     */
    // async getIdentify(content, payload) {
    //   if (!payload)
    //     return {
    //       hasError: true,
    //     };
    //   const { Login } = JSON.parse(sessionStorage.getItem("COMPANY_USER"));
    //   const { user_id } = Login.company_user;

    //   const response = await ENTITY.getCommunicationIdentity({
    //     ...payload,
    //     user_id,
    //   });
    //   if (response.hasError) {
    //     return { ...response };
    //   }
    //   const { data } = response;
    //   return data;
    // },

    // 保存
    async saveData(context, payload) {
      const response = await ENTITY.post(payload);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      return data;
    },

    //ズーム
    addZoom({ commit, state }, value) {
      console.log("zoom", state.zoomLevel, value);
      // 拡大
      if (value > 0 && state.zoomLevel < 1.5) {
        commit("SET_ZOOM", value);
      }

      // 縮小
      if (value < 0 && state.zoomLevel > 0.5) {
        commit("SET_ZOOM", value);
      }
    },
    selectItem({ commit }, id) {
      commit("SET_ITEM_ID", id);
    },
  },

  getters: {
    getZoom: (state) => {
      return state.zoomLevel;
    },
    enableZoomIn: (state) => {
      return state.zoomLevel < 1.5;
    },
    enableZoomOut: (state) => {
      return state.zoomLevel > 0.5;
    },
    getSelectedItemId: (state) => {
      return state.selectedId;
    },
  },
};
