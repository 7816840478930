import instance from "@/api/api";
import { Store } from "@/store/Store";

const request = async (params, callback) => {
  const URL = `${process.env.VUE_APP_API_BASE_URL}/postal_code_mst`;
  const result = await instance.get(URL, { params });
  if (!result.hasError) {
    const { postal_code_master } = result.data.contents.entries;
    if (!postal_code_master) {
      callback(null);
      return;
    }

    const { todofuken, sikuchouson, chouikimei } = postal_code_master;
    const prefectures =
      Store.state.CmnMst.constants.entries.cmn_mst_prefectures;
    const prefecture = prefectures.find((item) => {
      const regexp = new RegExp("^" + item.prefecture + ".*", "g");
      return todofuken.match(regexp);
    });
    callback({
      prefecture,
      city: `${sikuchouson} ${chouikimei}`,
    });
    return;
  }
  callback(null);
};

const _postalCodeSearch = () => {
  let timeout = null;

  const search = async (params) => {
    return new Promise((resolved) => {
      const postal_code = params.postal_code.split("-").join("");
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        request({ postal_code }, (vals) => {
          console.log(vals);
          resolved(vals);
        });
      }, 500);
    });
  };

  return {
    search,
  };
};

export const postalCodeSearch = _postalCodeSearch();
