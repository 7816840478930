<template>
  <v-card class="dialog">
    <v-card-title class="header">
      <div class="header-title-area">
        <div class="title">全人工合計</div>
        <div>
          <v-btn color="darken-1" text @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card-title>
    <div class="label">
      <div class="label__item">
        <span class="label__text">請負</span>
        <span class="label__tip" style="background-color: #1b9c4f" />
      </div>
      <div class="label__item">
        <span class="label__text">常傭</span>
        <span class="label__tip" style="background-color: #ff7b52" />
      </div>
    </div>
    <v-card-text class="body">
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <Doughnut :values="plan.total" labels="予定" :totalCenter="plan.totalCenter" :isNoData="isNoDataPlan"/>
          <Chart
            :isNoData="isNoDataPlan"
            v-for="(item, index) in plan.groups"
            :key="index"
            :title="item.name"
            :values="item.values"
          />
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <Doughnut :values="actual.total" labels="実績" :totalCenter="actual.totalCenter" :isNoData="isNoDataActual"/>
          <Chart
            :isNoData="isNoDataActual"
            v-for="(item, index) in actual.groups"
            :key="index"
            :title="item.name"
            :values="item.values"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { Store } from "@/store/Store.js";
import Doughnut from "./Doughnut.vue";
import Chart from "./Chart.vue";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";

export default {
  components: {
    Doughnut,
    Chart,
  },
  props: {
    scheduleName : String,
  },
  data: () => ({
    isNoDataPlan: false,
    isNoDataActual: false,
    plan: {
      total: [],
      groups: [],
      totalCenter: 0,
    },
    actual: {
      total: [],
      groups: [],
      totalCenter: 0,
    },
    ENV_CLIENT
  }),
  mounted() {
    this.$watch(
      () => Store.getters[`${this.scheduleName}/getDayWorkerInfo`],
      (data) => {
        const dataSchedule = this.convertData(data?.schedule)
        this.plan = dataSchedule[0];
        this.isNoDataPlan = dataSchedule[1];
        const dataActual = this.convertData(data?.result)
        this.actual =  dataActual[0];
        this.isNoDataActual = dataActual[1];
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {},
  created() {},
  methods: {
    convertData: (data) => {
      if(!data || data?.total_ukeoi == 0 && data?.total_tsunei == 0) {
        return [{ total: [-1, 0], groups: [{name: "", values: [0, 0]}], totalCenter: 0 }, true];
      }
      let result = {total: [],groups: [], totalCenter: 0};
      result['totalCenter'] = data.total;
      result['total'] = [data.total_ukeoi, data.total_tsunei];
      data?.administrators?.forEach(element => {
        result["groups"].push({ name: element.name, values: [element.ukeoi, element.tsunei] });
      });
      data?.groups?.forEach(element => {
        result["groups"].push({ name: element.name, values: [element.ukeoi, element.tsunei] });
      });
      return [result, false];
    }
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .header {
    .header-title-area {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      max-width: 100%;
      .title {
        font-size: 16px !important;
        font-weight: bold;
      }
    }
  }
  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;
      .text {
        text-align: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 80%;
      }
    }
  }
}
.label {
  display: flex;
  font-size: 12px;
  padding: 0 24px;
}
.label__item {
  line-height: 12px;
  margin-right: 8px;
}
.label__tip {
  display: inline-block;
  width: 24px;
  height: 16px;
  vertical-align: middle;
}
.label__text {
  vertical-align: middle;
  margin-right: 4px;
}
</style>
