export const MACHINE_USE_INITIAL_ITEM = {
  use_date_from: null,
  use_date_to: null,
  field_construction_id: null,
  group_id: null,
  field_tree_id: null,
  foreman_user_id: null,
  machine_type: null,
  machine_id: null,
  work_hour_zone: null,
  company_name: null,
  field_item_tree_id: null,
  type_work_id: null,
  work_details: null,
  expected_danger: null,
  status_code: 1,
  work_content_id: null,
  device_users: [],
  device_safety_instructions: [],
  device_usage_times: [],
  conductor_leadingworkers: [],
  approval_user_name_sei: null,
  approval_user_name_mei: null,
  approval_date: null,
  owner_flag: 1,
  device_work_classifications:[
    {
      occupation: 1,
      cmn_mst_work_classification_id: 1,
    }
  ],
  device_licences:[],
  device_sp_trainings:[],
  maximum_work_floor_height: null,
  movable_load: null,
  body_length: null,
  body_width: null,
  body_height: null,
  vehicle_weight: null,
  outrigger_overhang_dimensions: null,
  bucket_volume: null,
  prevention_measure: null,
  characteristics: null,
  create_user_name_sei: undefined,
  create_user_name_mei: undefined,
  update_user_name_sei: undefined,
  update_user_name_mei: undefined,
  created_at: undefined,
  updated_at: undefined,
};

/**
 * 早出/残業
 */
export const SCHEDULE_ATTENDANCE = {
  EARLY: { id: 1, name: "早出" },
  OVERTIME: { id: 2, name: "残業" },
};

/**
 * 火/酸欠
 */
export const SCHEDULE_WARNING = {
  FIRE: { id: 1, name: "火器" },
  CO2: { id: 2, name: "酸欠" },
};

/**
 * 承認/未承認
 */
export const SCHDULE_APPROVE = {
  APPROVED: { id: 1, name: "承認済" },
  REJECT: { id: 0, name: "未承認" },
};
/**
 * 契約
 */
export const SCHDULE_CONTRACT = {
  UNDERTAKE: { id: 1, name: "占有" },
  REGULAR: { id: 2, name: "スポット" },
};
/**
 * 点検
 */
export const SCHEDULE_INSPECTION = {
  UNCHECKED: { id: 1, name: "未点検" },
  CHECKED: { id: 2, name: "点検済み" },
  APPROVED: { id: 3, name: "点検承認" },
  REJECT: { id: 4, name: "未承認" },
};

// テーブル設定
export const SCHDULE_TABLE_LABELS = [
  {
    text: "ステータス",
    value: "approve_name",
    align: "left",
  },
  {
    text: "協力会社",
    value: "name",
    align: "left",
  },
  {
    text: "職種",
    value: "job_name",
    align: "left",
  },
  {
    text: "作業場所",
    value: "location_name",
    align: "left",
  },
  {
    text: "作業内容",
    value: "descriptin",
    align: "left",
  },
  { text: "早残", value: "attendance_name", align: "left" },
  { text: "人工", value: "work_time", align: "left" },
];

// ソート項目
export const SCHDULE_SORT_ITEMS = [
  { id: "approve_name", name: "ステータス" },
  { id: "name", name: "協力会社" },
  { id: "job_name", name: "職種" },
];

/**
 * テーブルのタブ
 */
export const SCHEDULE_TABLE_TAB_ITEMS = {
  ALL: { id: 1, name: "全体" },
  WORK_GROUP: { id: 2, name: "作業グループ" },
};

/**
 * 予定表示
 */
export const SCHEDULE_TYPE = {
  OCCUPY: { id: 1, name: "占有" },
  SPOT: { id: 2, name: "スポット" },
  DISABLE: { id: 100, name: "禁止" },
  OVERLAP: { id: 101, name: "重複" },
};

export const DIALOG_TEXT = {
  DELETE_TITLE: "削除確認",
  DELETE_ERROR_TEXT1: "選択された作業予定は全て承認済みです。",
  DELETE_ERROR_TEXT2: "削除できません。",
  REJECT_TITLE: "承認解除確認",
  REJECT_TEXT1: "選択した予定の承認を解除します。",
  REJECT_TEXT2: "承認を解除してもよろしいですか。",
  REJECT_ERROR_TEXT1: "選択したデータは全て承認されていないため、承認解除出来ません。",
  APPROVE_TITLE: "承認確認",
  APPROVE_TEXT1: "選択した予定を承認します。",
  APPROVE_TEXT2: "承認してもよろしいですか。",
  DELETE_TEXT1: "選択したデータを削除します。",
  DELETE_TEXT2: "未承認のデータのみ削除できます。",
  DELETE_TEXT3: "この処理を元に戻すことはできません。",
  DELETE_TEXT4: "本当に削除してもよろしいですか？",
  BTN_REJECT : "解除",
  BTN_APPROVE :"承認",
  BTN_DELETE :"削除"
};

export const CONFIRM_DIALOG_STATUS = {
  APPROVED: 1,
  REJECT: 0,
  DELETE: 3,
  DELETE_ERROR: 4,
}

export const MODE_ACTION = {
  APPROVED: 3,
  REJECT: 1,
  DELETE: 2,
}

export const select_box_page_1 = [
  { id: 1, exams: "exams1", exam_id: 1, qualification: "qualification1", qualification_id: 1, name: "123" },
  { id: 2, exams: "exams1", exam_id: 1, qualification: "qualification1", qualification_id: 1, name: "456" },
  { id: 3, exams: "exams1", exam_id: 1, qualification: "qualification1", qualification_id: 1, name: "789" },
  { id: 4, exams: "exams1", exam_id: 1, qualification: "qualification2", qualification_id: 2, name: "abc" },
  { id: 5, exams: "exams1", exam_id: 1, qualification: "qualification2", qualification_id: 2, name: "xyz" },
  { id: 6, exams: "exams1", exam_id: 1, qualification: "qualification2", qualification_id: 2, name: "mnp" },
  { id: 7, exams: "exams2", exam_id: 2, qualification: "qualification4", qualification_id: 4, name: "x1" },
  { id: 8, exams: "exams2", exam_id: 2, qualification: "qualification4", qualification_id: 4, name: "x2" },
  { id: 9, exams: "exams2", exam_id: 2, qualification: "qualification5", qualification_id: 5, name: "x3" },
  { id: 10, exams: "exams2", exam_id: 2, qualification: "qualification5", qualification_id: 5, name: "x4" },
  { id: 11, exams: "exams2", exam_id: 2, qualification: "qualification6", qualification_id: 6, name: "x5" },
  { id: 12, exams: "exams2", exam_id: 2, qualification: "qualification6", qualification_id: 6, name: "x6" },

];

export const TITLE = {
  NEW: "機材予定情報",
  EDIT: "機材予定情報",
};

/**
 * フォームとタブの設定
 */
export const FORMS = {
  DeviceFormPage1: { id: 1, title: "基本情報" },
  DeviceFormPage2: { id: 2, title: "作業内容" },
  DeviceFormPage3: { id: 3, title: "性能" },
  DeviceFormPage4: { id: 4, title: "登録・変更履歴" },

};


export const MOCK_SELECT_BOX = [
  { id: null, name: "" },
  { id: 1, name: "Mock1" },
  { id: 2, name: "Mock2" },
  { id: 3, name: "Mock3" },
  { id: 4, name: "Mock4" },
  { id: 5, name: "Mock5" },
];

export const DEVICE_EMPTY = [
  {
    device_id: null,
    device_name: null,
    device_description: [],
    total_children_item: null,
    schedule_summary: [],
    children: []
  }
];
