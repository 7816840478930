<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-data-table
        :headers="TABLE_LABELS_TAB_4"
        :items="items"
        :height="this.mainHeight"
        fixed-header
        hide-default-footer
        :items-per-page="items.length"
        disable-sort
        class="elevation-0 v-data-table__wrapper"
        sort-by="updatedAt"
        :noDataText="NO_DATA_MESSAGE"
      >
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import { TABLE_LABELS_TAB_4 } from "@/constants/DEVICE_CARRY_ON";

export default {
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    }
  },
  data() {
    return {
      NO_DATA_MESSAGE,
      TABLE_LABELS_TAB_4
    };
  },
  props: {
    mainHeight: {
      type: Number,
      default: 0
    },
    items: Array
  }
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
::v-deep .v-data-table .v-data-table__wrapper table tbody tr td{
  word-break: break-all;
}
</style>