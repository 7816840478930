<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <!--  (共通) 会社ヘッダー -->
        <CompanyHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout :layoutParams="layoutParams">
          <template #tableHeader="{ updateHeader }">
            <!--  
              (共通) テーブルヘッダー 
              このなかに、新規作成ボタン、検索ボタン、一括削除ボタンが実装されてます
              @openRemoveDialog : 一括削除の確認ダイアログを開く
              @openItemForm : 新規作成フォームを開く
            -->
            <TableHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :multiRemoveStatus="disableRemoveBtn"
              :updateHeader="updateHeader"
              @openRemoveDialog="openRemoveDialog"
              @openItemForm="openNewItemForm"
            >
              <!-- 
                検索はテーブルごとに実装します 
                @onInput 
              -->
              <SearchFormWrapper>
                <!-- 文字入力 -->
                <Label label="名前">
                  <InputText
                    name="word"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <!-- ロール -->
                <Label label="ロール">
                  <Select
                    name="roll"
                    :items="selectRollItems"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <!-- 性別 -->
                <Label label="性別">
                  <Select
                    name="gender"
                    :items="selectGenderItems"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <!-- 登録日 -->
                <Label label="登録日">
                  <InputDatepicker
                    name="created_at"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <!--  (共通) 検索ボタン -->
                <v-btn class="mx-2" color="primary" depressed @click="onSearch">
                  <v-icon> mdi-magnify </v-icon>
                </v-btn>
              </SearchFormWrapper>
            </TableHeader>

            <!-- 
              (共通)
              ソートのレイアウトを調整するラッパーコンポーネントです
            -->
            <TableSortWrapper>
              <!--  
                (共通) ソート 
                ソート項目、ソート順、表示件数の選択、総件数の表示
              -->
              <TableSort
                :values="searchParams"
                :sort_items="SORT_ITEMS"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                @onInput="onChangeSortParams"
              />
            </TableSortWrapper>
          </template>
          <!-- 
            (共通)テーブル
            v-data-table自体は共通ですが、
            カラムによって変更をしたい場合はそれぞれ実装してください。
          -->
          <template #tableBody="{ tableHeight }">
            <v-data-table
              v-model="selectedItems"
              :headers="TABLE_LABELS"
              :items="items"
              :items-per-page="searchParams.pageCount"
              :height="tableHeight"
              fixed-header
              hide-default-footer
              disable-sort
              class="elevation-0 v-data-table__wrapper"
              sort-by="updatedAt"
              show-select
              :noDataText="NO_DATA_MESSAGE"
              @click:row="openItemForm"
            >
              <!-- テーブルごとに変更が必要な場合はここで個別に処理します -->
              <!-- ユーザ権限名文字列をidから取得 -->
              <template v-slot:[`item.roll`]="{ item }">
                {{ getRollLabel(item.roll) }}
              </template>
              <!-- 日付のフォーマットを変更 -->
              <template v-slot:[`item.created_at`]="{ item }">
                {{ getFormattedDate(item.created_at).date }}
              </template>
            </v-data-table>
          </template>
          <template #tableFooter>
            <!-- (共通) ページネーション -->
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>

    <!--  (ユーザー用) 追加/編集ダイアログ -->
    <Popup :dialog="popups.isShowItemForm">
      <UserForm
        :item="editedItem"
        :isNewItem="isNewItem"
        @formUpdate="formUpdate"
        @submit="submitForm"
        @cancel="closeItemForm"
        ref="UserForm"
      />
    </Popup>

    <!--  (共通) 削除ダイアログ / 文言変えてもok -->
    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :items="selectedItems"
        warning
      />
    </Popup>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import TableLayout from "@/components/layout/TableLayout.vue";
import TableHeader from "@/components/masterTable/elements/TableHeader.vue";
import Pagination from "@/components/masterTable/elements/Pagination.vue";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper.vue";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper.vue";
import TableSort from "@/components/masterTable/elements/TableSort.vue";
import Popup from "@/components/common/Popup.vue"; //モーダル用のポップアップ
import ConfirmRemoveDialog from "@/components/dialog/ConfirmRemoveDialog.vue"; //削除確認ダイアログ
import CompanyHeader from "@/components/companyHeader/CompanyHeader.vue";
import { TABLES_PER_PAGE, TABLE_SORT_ORDERS, NO_DATA_MESSAGE } from "@/constants/COMMON"; //絞り込みフォームで使用

/**
 * ユーザーで使用するコンポーネント、関数
 */
import UserForm from "@/components/forms/workers/WorkerForm.vue"; //ユーザー登録編集フォーム
import { fmtSlashDate } from "@/utils/timeUtil"; //日付フォーマット変換で使用
import { getRollLabelById } from "@/utils/users/index"; //ロール情報の変換で使用
import InputText from "@/components/forms/elements/InputText.vue"; //絞り込みフォームで使用
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select.vue"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label.vue"; //絞り込みフォームで使用
import {
  WORKER_ROLES_ARRAY,
  WORKER_GENDER_ARRAY,
  WORKER_TABLE_LABELS,
  WORKER_SORT_ITEMS,
  WORKER_INITAL_ITEM,
} from "@/constants/WORKER"; //絞り込みフォームで使用

/**
 * 定数
 * この一覧ページで使用する固有の定数を定義します。
 * 共通に使用する定数は基本的に@/constants/で定義します。
 * - 定数は大文字で定義します
 * - 定数は基本的にはdataに代入しないで直接参照します
 */

//タイトル
const PAGE_TITLE = "作業員一覧";

//１ページあたりのテーブル件数
const PAGE_COUNT = 50;

//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;

//ストア
const STORE = "Workers";

//テーブルヘッダーラベル
const TABLE_LABELS = WORKER_TABLE_LABELS;

//ソート要素
const SORT_ITEMS = WORKER_SORT_ITEMS;

//フォーム初期値
const INITIAL_ITEM = WORKER_INITAL_ITEM;

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      PAGE_TITLE,
      TABLE_LABELS,
      SORT_ITEMS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      NO_DATA_MESSAGE,

      /**
       * (共通)
       * 一覧データ
       */
      items: [],

      /**
       * (共通)
       * checkbox選択item
       */
      selectedItems: [],

      /**
       * (共通)
       * 編集アイテム
       * 定数から初期値を代入
       */
      editedItem: { ...INITIAL_ITEM },

      /**
       * (共通)
       * 新規フラグ
       */
      isNewItem: false,

      // 検索パラメータ
      searchParams: {
        word: "",
        created_at: null, //作成日
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: null, //sortする項目
        asc: true,
      },

      /**
       * (共通)
       * ポップアップの状態管理
       */
      popups: {
        // 追加/編集フォーム
        isShowItemForm: false,
        // 削除確認ダイアログ表示
        isShowRemoveDialog: false,
      },
    };
  },

  components: {
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    CompanyHeader,
    Popup,
    ConfirmRemoveDialog,
    //ユーザー用のコンポーネント
    UserForm,
    InputText,
    InputDatepicker,
    Select,
    Label,
  },

  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    /**
     * (共通)
     * ここからapiにリクエストします
     */
    this.getItems();

    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        /**
         *  (共通)
         * 一覧リストの格納
         */
        this.items = data[0];

        /**
         *  (共通)
         * ページネーション更新
         */
        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        immidiate: true,
        deep: true,
      }
    );
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */
  computed: {
    /**
     * (共通)
     * 削除ボタンの活性・非活性
     * selectedItems.length === 0
     * 自体はtemplate側で記述できますが、
     * ここに明示的に状態を定義して可読性を担保してます
     */
    disableRemoveBtn() {
      return this.selectedItems.length === 0;
    },

    /**
     * (ユーザー用)
     * 検索のロール一覧
     * ユーザ用ですが、他のテーブルで同様にSelectのアイテムを用意する場合は、
     * こういう形でデータを整形します
     */
    selectRollItems() {
      //1つ目に選択解除の選択肢を追加しています
      let items = [{ id: null, name: "選択しない" }];
      items = [...items, ...WORKER_ROLES_ARRAY];
      return items;
    },

    selectGenderItems() {
      //1つ目に選択解除の選択肢を追加しています
      let items = [{ id: null, name: "選択しない" }];
      items = [...items, ...WORKER_GENDER_ARRAY];
      return items;
    },
  },

  methods: {
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 検索
     */
    onSearch() {
      console.log({ ...this.searchParams });
      this.getItems();
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
    },

    /**
     * (共通)
     * 並び替えパラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 作成/編集フォーム
     * INITIAL_ITEM で定義した値がitemに入ります
     * @param INITIAL_ITEMで定義している値
     */
    openNewItemForm() {
      this.editedItem = { ...INITIAL_ITEM };
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },
    openItemForm(item) {
      this.editedItem = { ...item };
      this.isNewItem = false;
      this.popups.isShowItemForm = true;
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.$nextTick(() => {
        //選択を初期化
        this.editedItem = { ...INITIAL_ITEM };
      });
    },

    /**
     *  (共通)
     * 確認ダイアログ
     * 基本的に変更しないでいいはず
     */
    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },

    /**
     * (共通)
     * フォームの変更を受け取る
     */
    formUpdate(params) {
      this.editedItem = { ...params };
    },

    /**
     *  (共通)
     * ストア / api
     * ストア実装ルールにしたがっている場合は、
     */

    // データ取得
    async getItems() {
      await Store.dispatch(`${STORE}/get`, this.searchParams);
    },

    // 削除
    async removeItems() {
      const ids = this.selectedItems.map((items) => items.id);
      const result = await Store.dispatch(`${STORE}/delete`, ids);

      if (!result.hasError) {
        //ダイアログ閉じる
        this.closeRemoveDialog();
        //成功したら値を更新
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
      }
    },

    /**
     * (共通)
     * 新規登録 / 更新
     * - idがある場合は更新
     */
    async submitForm() {
      const hasId = "id" in this.editedItem;
      const result = await Store.dispatch(
        //idがある場合は更新、ない場合は新規
        hasId ? `${STORE}/update` : `${STORE}/post`,
        this.editedItem
      );
      //レスポンスエラーがある場合はフォームを閉じない
      //エラーメッセージは、api.jsが表示
      if (result.hasError) {
        return;
      } else {
        //成功したら値を更新
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
    },

    /**
     *  (ユーザー用)
     * ユーザ権限名文字列取得
     * @param id:Number
     */
    getRollLabel(id) {
      return getRollLabelById(id);
    },

    /**
     *  (ユーザー用)
     * 日付変換
     * @param date:yyyy-mm-ddThh:mm:ss:z
     */
    getFormattedDate(date) {
      return fmtSlashDate(date);
    },
  },
};
</script>
