<template>
  <div>
    <PanelImage />
  </div>
</template>
<script>
import PanelImage from "./PanelImage.vue";
export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: "入退場履歴" };
    },
  },
  components: {
    PanelImage,
  },
};
</script>
