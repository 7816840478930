<template>
  <v-card>
    <v-data-table
      :headers="HEADERS_LABELS"
      :items="branches.items"
      :items-per-page="25"
      :height="tableHeight"
      :noDataText="NO_DATA_MESSAGE"
      hide-default-header
      hide-default-footer
    >
      <template v-slot:[`item.name`]="{ item }">
        <div>{{ convertName(item.name) }}</div>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { Store } from "@/store/Store.js";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
const HEADERS_LABELS = [
  { text: '支店', value: 'name'},
];

export default {
  name: 'WorkersTable', 
  data() {
    return {
      HEADERS_LABELS,
      branches: {
        items: []
      },
      tableHeight: "0px",
      NO_DATA_MESSAGE
    }
  },
  async mounted() {
    this.$watch(
      () => Store.getters["Offices/getCompanyBranches"],
      (data) => {
        let items = data?.company_branches?.slice(0, 25);
        this.branches.items = [...items];
        this.updateTableHeight();
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    updateTableHeight() {
      const columns = this.branches.items.length;
      if(columns == 0) {
        this.tableHeight = "48px";
      } else {
        let itemHeight = columns > 10 ? 480 : 48 * columns;
        this.tableHeight = itemHeight + "px";
      }
    },

    convertName(name) {
      let result = "";
      if (name) {
        result = name;
        if (name.length > 75) {
          result = name.substring(0, 75) + "•••";
        }
      }
      return result;
    },
  }
};
</script>