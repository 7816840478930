<template>
  <div>
    <v-card v-for="item in items" :key="item.id" elevation="3">
      <v-card-title class="text-h5 mb-1 font-weight-bold">
        {{ item.title }}
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        :headers="TABLE_LABELS"
        hide-default-header
        :items="items"
        :items-per-page="items.length"
        hide-default-footer
        disable-sort
        class="elevation-0 v-data-table__wrapper"
        sort-by="updatedAt"
        :no-data-text="NO_DATA_MESSAGE"
      >
        <template v-slot:[`item.icon`]>
          <div class="list_icon">
            <v-icon
              v-for="(i, index) in item.icon"
              :key="index"
              :color="i == 1 ? 'rgb(255, 123, 82)' : 'black'"
              >{{ i == 1 ? "mdi-square" : "mdi-square-outline" }}</v-icon
            >
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-card>
      <v-card-title
        class="text-h5 font-weight-bold"
        style="place-content: center"
      >
        今日の最高ランク
      </v-card-title>
      <div
        class="text-h5 font-weight-bold"
        style="text-align: center; color: red"
      >
        厳重警戒
      </div>
      <div class="text-h6 font-weight-bold">
        気温が高いうえに、湿気が多いため汗をかきやすく熱中症に厳重な警戒が必要です。<br>
        作業現場では扇風機など、風通しをよくすること、頻繁に地面に散水するなど工夫すること。水分を15分〜20分に1回は摂り、梅干しや塩あめで塩分をしっかり摂ることも心がけましょう。また、気分が悪くなっていた>り顔が赤くなっていたら風通しのよい日陰に避難させ、早めに医師の診察を受けましょう。
      </div>
    </v-card>
  </div>
</template>
<script>
import { NO_DATA_MESSAGE } from "@/constants/COMMON.js";
const TABLE_LABELS = [
  {
    text: "時刻",
    value: "hour",
    align: "center",
  },
  {
    text: "平均",
    value: "icon",
    align: "center",
  },
  {
    text: "最大",
    value: "temp",
    align: "center",
  },
];
export default {
  data() {
    return {
      TABLE_LABELS,
      NO_DATA_MESSAGE,
      items: [
        {
          id: 1,
          title: "今日",
          text: "123",
          hour: "10時",
          icon: [1, 1, 1, 0, 0],
          temp: "ほぼ安全",
          rain: "12",
        },
        {
          id: 2,
          title: "明日",
          text: "234",
          hour: "11時",
          icon: [1, 1, 0, 0, 0],
          temp: "警戒",
          rain: "12",
        },
        {
          id: 3,
          title: "明後日",
          text: "456",
          hour: "12時",
          icon: [1, 1, 1, 0, 0],
          temp: "危険",
          rain: "12",
        },
        {
          id: 4,
          title: "明明後日",
          text: "789",
          hour: "13時",
          icon: [1, 1, 1, 1, 1],
          temp: "厳重警戒",
          rain: "12",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.list_icon {
  display: flex;
  justify-content: space-evenly;
}
</style>
