import Api, { Mock } from "../api";
import device_stock_list from "./mock/device_stock_list.json";
import mock_list_machine_owner_companies from "./mock/mock_list_machine_owner_companies.json";
import mock_detail_machine from "./mock/machine_field_detail.json";

const DEVICE_STOCK_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/machine_field_inventories`;
const GET_LIST_URL = `${DEVICE_STOCK_BASE_URL}/list`;
const GET_LIST_MACHINE_OWNER_COMPANIES_URL = `${DEVICE_STOCK_BASE_URL}/list_machine_owner_companies`;
const DELETE_URL = `${DEVICE_STOCK_BASE_URL}/delete`;
const CONFIRM_DELIVERY_URL = `${DEVICE_STOCK_BASE_URL}/confirm_delivery`;
const UPDATE_APPROVAL_FLAG_URL = `${DEVICE_STOCK_BASE_URL}/update_approval_flag`;
const UPDATE_MACHINE_START_USE_URL = `${DEVICE_STOCK_BASE_URL}/start_use`;
const UPDATE_MACHINE_STOP_USE_URL = `${DEVICE_STOCK_BASE_URL}/register_machine_field`;
const UPDATE_REGISTOR_RETURN_URL = `${DEVICE_STOCK_BASE_URL}/registor_return`;
const UPDATE_CONFIRM_RETURN_URL = `${DEVICE_STOCK_BASE_URL}/confirm_return`;
const UPDATE_MACHINE_INVENTORY_REGISTER_URL = `${DEVICE_STOCK_BASE_URL}/register`;
const GET_USAGE_URL = `${DEVICE_STOCK_BASE_URL}/get_usage`;
const GET_LIST_MACHINE_USE_COMPANIES_URL = `${DEVICE_STOCK_BASE_URL}/list_machine_use_companies`;
const GET_DETAIL_MACHINE_URL = `${DEVICE_STOCK_BASE_URL}/detail`;
const UPDATE_MACHINE_INVENTORIES_URL = `${DEVICE_STOCK_BASE_URL}/update`;
const UPDATE_MACHINE_INVENTORIES_PROHIBIT_STATUS_URL = `${DEVICE_STOCK_BASE_URL}/update_prohibit_status`;
const UPDATE_USAGE_URL = `${DEVICE_STOCK_BASE_URL}/update_usage`;


if (Mock) {
  Mock.onGet(GET_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: device_stock_list,
  });
  Mock.onPost(DELETE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(UPDATE_MACHINE_INVENTORY_REGISTER_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(UPDATE_APPROVAL_FLAG_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(UPDATE_REGISTOR_RETURN_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(UPDATE_CONFIRM_RETURN_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(UPDATE_MACHINE_START_USE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(UPDATE_MACHINE_STOP_USE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(CONFIRM_DELIVERY_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(GET_LIST_MACHINE_OWNER_COMPANIES_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_list_machine_owner_companies,
  });
  Mock.onGet(GET_LIST_MACHINE_USE_COMPANIES_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        {
          used_field_tree_id: 1,																			
          used_company_name: "株式会社〇〇建設"
        },
        {
          used_field_tree_id: 2,
          used_company_name: "株式会社〇〇建設（株式会社〇〇建設）"
        }	
      ]
    },
  });
  Mock.onGet(new RegExp(`${GET_DETAIL_MACHINE_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_detail_machine,
  });
}

export const deviceStocks = {
  get: (params) => {
    return Api.get(GET_LIST_URL, params);
  },
  getItemsTab: (params) => {
    return Api.get(GET_LIST_URL, params);
  },
  getListMachineOwnerCompanies: async (params) => {
    return Api.get(GET_LIST_MACHINE_OWNER_COMPANIES_URL, {params});
  },
  getListMachineUseCompanies: async (params) => {
    return Api.get(GET_LIST_MACHINE_USE_COMPANIES_URL, {params});
  },
  delete: async (params) => {
    return Api.post(DELETE_URL, params);
  },
  confirmDelivery: async (params) => {
    return Api.post(CONFIRM_DELIVERY_URL, params);
  },
  updateApprovalFlag: async (params) => {
    return Api.post(UPDATE_APPROVAL_FLAG_URL, params);
  },
  updateMachineInventoryStartUse: async (params) => {
    return Api.post(UPDATE_MACHINE_START_USE_URL, params);
  },
  updateMachineInventoryStopUse: async (params) => {
    return Api.post(UPDATE_MACHINE_STOP_USE_URL, params);
  },
  updateRegistorReturn: async (params) => {
    return Api.post(UPDATE_REGISTOR_RETURN_URL, params);
  },
  updateMachineInventoryConfirmReturn: async (params) => {
    return Api.post(UPDATE_CONFIRM_RETURN_URL, params);
  },
  updateMachineInventoryRegister: async (params) => {
    return Api.post(UPDATE_MACHINE_INVENTORY_REGISTER_URL, params);
  },
  getUsage: (params) => {
    return Api.get(`${GET_USAGE_URL}/${params}`);
  },
  getMachineFieldDetail: async (params) => {
    return Api.get(`${GET_DETAIL_MACHINE_URL}/${params}`);
  },
  updateMachineInventories : async (params) => {
    return Api.post(UPDATE_MACHINE_INVENTORIES_URL, params);
  },
  updateMachineInventoryProhibitStatus : async (params) => {
    return Api.post(UPDATE_MACHINE_INVENTORIES_PROHIBIT_STATUS_URL, params);
  },
  updateMachineInventoryUsage : async (params) => {
    return Api.post(UPDATE_USAGE_URL, params);
  },
};
