<template>
  <v-container>
    <v-row no-gutters>
      <!-- 作業員:workers -->
      <v-col cols="12" sm="6">
        <div class="pa-1 worker-edit-table-wrapper">
          <v-toolbar flat>
            <div class="header-list">
              <div class="title-list" >社員</div>
              <v-btn
                :disabled="isWorkersSelected"
                @click="register"
                color="primary"
                >登録</v-btn
              >
            </div>
          </v-toolbar>
          <!-- テーブル -->
          <v-data-table
            v-model="selectedWorkers"
            :headers="TABLE_LABELS"
            :items="workers"
            :items-per-page="workers.length"
            hide-default-footer
            disable-sort
            class="elevation-0 v-data-table__wrapper"
            show-select
            :noDataText="NO_DATA_MESSAGE"
            outlined
            v-if="!isLoadingTables"
            :style="`height:${mainHeight - 75}px;overflow-x: hidden;overflow-y: auto;`"
          >
          </v-data-table>
        </div>
      </v-col>
      <!-- 現場作業員:siteWorkers -->
      <v-col cols="12" sm="6">
        <div class="pa-1 worker-edit-table-wrapper site-worker-edit">
          <v-toolbar flat>
            <div class="header-list">
              <div class="title-list">現場作業員</div>
              <v-btn
                :disabled="isSiteWorkersSelected"
                @click="remove"
                color="primary"
                >解除</v-btn
              >
            </div>
          </v-toolbar>
          <!-- テーブル -->
          <v-data-table
            v-model="selectedSiteWorkers"
            :headers="TABLE_LABELS"
            :items="siteWorkers"
            :items-per-page="siteWorkers.length"
            hide-default-footer
            disable-sort
            class="elevation-0 v-data-table__wrapper"
            show-select
            :noDataText="NO_DATA_MESSAGE"
            outlined
            v-if="!isLoadingTables"
            :style="`height:${mainHeight - 75}px;overflow-x: hidden;overflow-y: auto;`"
          >
          </v-data-table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

/**
 * ユーザーで使用するコンポーネント、関数
 */
import { FORM_WORKERS_EDIT_TABLE_LABELS } from "@/constants/PORTAL_CHART"; //絞り込みフォームで使用
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
/**
 * 定数
 */

//テーブルヘッダーラベル
const TABLE_LABELS = FORM_WORKERS_EDIT_TABLE_LABELS;

export default {
  data() {
    return {
      // テーブルヘッダーラベル
      TABLE_LABELS,
      NO_DATA_MESSAGE,

      workers: [],
      // 現場作業員
      siteWorkers: [],

      //checkbox選択item
      selectedWorkers: [],
      selectedSiteWorkers: [],
    };
  },

  props: {
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    item: Object,
  },

  async mounted() {
    /**
     * 作業員を監視して変更があればformUpdateする
     */
    this.$watch(
      () => this.siteWorkers,
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          const workers = this.removeArray({
            from: this.workers,
            remove: newValue,
          });
          this.workers = workers;
          const _item = { ...this.item };
          _item["field_tree_users"] = newValue;
          _item["list_user_of_company"] = this.workers;
          this.emit("field_worker", _item);
        }
      },
      {
        deep: true,
      }
    );
    this.$watch(
      () => this.item,
      (data) => {
        let workers = data?.list_user_of_company || [];
        let siteWorkers = data?.field_tree_users || [];
        workers?.forEach(
          (ele) => (ele["name"] = ele["name_sei"] + " " + ele["name_mei"])
        );
        siteWorkers?.forEach(
          (ele) => (ele["name"] = ele["name_sei"] + " " + ele["name_mei"])
        );

        this.workers = this.removeArray({
          from: workers.filter(item => item.state_handling_flag === 0),
          remove: siteWorkers,
        });

        this.siteWorkers = siteWorkers;
      },
      {
        immediate: true,
        deep: true
      }
    );
  },

  computed: {
    // 作業員登録ボタンdisabled
    isWorkersSelected() {
      return this.selectedWorkers?.length === 0;
    },

    // 現場作業員解除ボタンdisabled
    isSiteWorkersSelected() {
      return this.selectedSiteWorkers?.length === 0;
    },

    // // テーブルデータ読込中
    isLoadingTables() {
      return this.siteWorkers?.length === 0 && this.workers?.length === 0;
    },
  },

  methods: {

    // 作業員登録
    register() {
      this.siteWorkers = [...this.siteWorkers, ...this.selectedWorkers];
      this.selectedWorkers = [];
    },

    // 現場作業員解除
    remove() {
      this.siteWorkers = this.removeArray({
        from: this.siteWorkers,
        remove: this.selectedSiteWorkers,
      });
      this.workers = [...this.workers, ...this.selectedSiteWorkers.filter(e => e.state_handling_flag === 0)];
      this.selectedSiteWorkers = [];
    },

    // 配列引き算
    removeArray(arrs) {
      return [...arrs.from]?.filter((from) => {
        // idが重複しないworkerのみ残す
        return ![...arrs.remove]?.some((remove) => {
          return remove.id === from.id;
        });
      });
    },

    emit(key, value) {
      const objNew = {};
      objNew[key] = value;
      this.$emit("formUpdate", objNew);
    },
  },
};
</script>

<style lang="scss" scoped>
.worker-edit-table-wrapper.site-worker-edit {
  border-left: none;
}
.header-list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}
.title-list {
  flex: 1;
  text-align: center;
  font-weight: bold;
}
</style>
