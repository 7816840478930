// import qs from "qs";
import Api from "../api";
import { format } from "date-fns";

// https://shogatsu-works.slack.com/archives/C0239SPTYMD/p1638442707388600?thread_ts=1638435209.378500&cid=C0239SPTYMD

const URL = `${process.env.VUE_APP_API_BASE_URL}/face_image_registrations/create_entrance_task_sp`;

export const faceEntrance = {
  post: (params) => {
    const { group_id, person_id, picture, entrance_status, device_id } = params;

    const _params = {
      field_entrance_device_id: device_id,
      field_id: group_id,
      person_id,
      entrance_picture: `${format(new Date(), "yyyyMMdd_HHmmss")}.jpeg`,
      entrance_picture_data: `data:image/octet-stream;base64,${picture}`,
      entrance_status,
      entrance_time: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
    };

    console.log("-- faceEntrance", URL, { ..._params });

    return Api.post(URL, _params);
  },
};
