<template>
  <FormDialog>
    <template #header>
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title style="color:#1B9C4F">
          {{ FORM_TITLE }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-5"
          depressed
          small
          outlined
          color="primary"
          @click="closeFormRegister()"
        >
          やめる
        </v-btn>
        <v-btn depressed small color="primary" @click.once="onSubmit" :key="flagErrorUser"> 保存 </v-btn>
        <v-btn icon @click="closeFormRegister()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs class="ml-1">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab> 社員 </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <v-container>
        <v-row no-gutters>
          <!-- 現場監督:leftUsers -->
          <v-col cols="12" sm="6">
            <div class="manager-edit-table-wrapper">
              <v-toolbar flat>
                <div></div>
                <div style="font-size:20px;font-weight:bold;"><span>社員</span></div>
                <v-btn
                  :disabled="isManagersSelected"
                  @click="register"
                  color="primary"
                  >登録</v-btn
                >
              </v-toolbar>
              <!-- テーブル -->
              <v-data-table
                v-model="selectedLeftUsers"
                :headers="LEFT_TABLE_LABELS"
                :items="leftUsers"
                hide-default-footer
                :items-per-page="leftUsers.length"
                disable-sort
                class="elevation-0 v-data-table__wrapper"
                show-select
                item-key="id"
                :noDataText="NO_DATA_MESSAGE"
                outlined
                :style="`height:${
                  params.mainHeight - 75
                }px;overflow-x: hidden;overflow-y: auto;`"
              >
              </v-data-table>
            </div>
          </v-col>
          <!-- 現場監督:rightUsers -->
          <v-col cols="12" sm="6">
            <div class="manager-edit-table-wrapper site-manager-edit">
              <v-toolbar flat>
                <div></div>
                <div style="font-size:20px;font-weight:bold;">現場監督</div>
                <v-btn
                  :disabled="isSiteManagersSelected"
                  @click="remove"
                  color="primary"
                  >解除</v-btn
                >
              </v-toolbar>
              <!-- テーブル -->
              <v-data-table
                v-model="selectedRightUsers"
                :headers="RIGHT_TABLE_LABELS"
                :items="rightUsers"
                hide-default-footer
                :items-per-page="rightUsers.length"
                disable-sort
                class="elevation-0 v-data-table__wrapper"
                show-select
                item-key="user_id"
                :noDataText="NO_DATA_MESSAGE"
                outlined
                :style="`height:${
                  params.mainHeight - 75
                }px;overflow-x: hidden;overflow-y: auto;`"
              >
              </v-data-table>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="isShowConfirmDialog = false"
          @yes="closeForm"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import {
  LEFT_TABLE_LABELS,
  RIGHT_TABLE_LABELS,
} from "@/constants/PORTAL_CHART";
import { NO_DATA_MESSAGE } from "@/constants/COMMON.js";
import FormDialog from "@/components/dialog/FormDialog.vue";
import Popup from "@/components/common/Popup.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import { Store } from "@/store/Store.js";

export default {
  data() {
    return {
      LEFT_TABLE_LABELS,
      RIGHT_TABLE_LABELS,
      NO_DATA_MESSAGE,
      leftUsers: [],
      rightUsers: [],
      selectedLeftUsers: [],
      selectedRightUsers: [],
      editable: false,
      isShowConfirmDialog: false,
      myFormValues:{},
    };
  },
  props: {
    formUpdate: Function,
    flagErrorUser: Boolean,
    item : Object
  },
  components: {
    FormDialog,
    Popup,
    ConfirmCloseDialog,
  },

  async mounted() {
    this.$watch(
      () => Store.getters["Employees/getData"],
      (newValue) => {
        let data = [...newValue];
        let _leftUsers = data.filter(element => element.state_handling_flag == 0);
        this.rightUsers.forEach(right=>{
          _leftUsers = _leftUsers.filter(left => left.id != right.user_id);
        });
        this.leftUsers = [..._leftUsers];
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => this.item,
      (newValue) => {
        if(newValue){
          let data = {...newValue};
          this.myFormValues = {...data};
          this.rightUsers = data?.machine_rental_company_field_users;
          this.getListLeftUser();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    // 作業員登録ボタンdisabled
    isManagersSelected() {
      return this.selectedLeftUsers.length === 0;
    },

    // 現場作業員解除ボタンdisabled
    isSiteManagersSelected() {
      return this.selectedRightUsers.length === 0;
    },
    FORM_TITLE(){
      return Store.getters["Login/getUser"].company.name;
    }
  },

  methods: {
    // 現場監督登録
    register() {
      // 現場監督テーブル更新
      this.leftUsers = this.removeArray('id',{
        from: this.leftUsers,
        remove: this.selectedLeftUsers,
      });
      let _rightUsers = [...this.rightUsers];
      this.selectedLeftUsers.forEach(element => {
        _rightUsers.push({
          user_id :element.id,
          user_name : element.name,
          state_handling_flag : element.state_handling_flag
        })
      })
      this.rightUsers = [..._rightUsers];
      this.selectedLeftUsers = [];
    },

    // 登録現場監督解除
    remove() {
      // 登録現場監督テーブル更新
      this.rightUsers = this.removeArray('user_id',{
        from: this.rightUsers,
        remove: this.selectedRightUsers,
      });
      // 現場監督テーブル更新
      let _leftUsers = [...this.leftUsers];
      this.selectedRightUsers.filter(right => right.state_handling_flag === 0).forEach(right=>{
        _leftUsers.push({
          id : right.user_id,
          name : right.user_name
        })
      })
      this.leftUsers = [..._leftUsers];
      // 選択解除
      this.selectedRightUsers = [];
    },

    // 配列引き算
    removeArray(type,arrs) {
      return [...arrs.from].filter((from) => {
        // idが重複しないmanagerのみ残す
        return ![...arrs.remove].some((remove) => {
          return remove[type] === from[type];
        });
      });
    },
    closeFormRegister() {
      this.isShowConfirmDialog = true;
    },
    closeForm() {
      this.isShowConfirmDialog = false;
      this.$emit("closeFormRegister");
    },
    onSubmit() {
      this.$emit("updateDataListRight", this.rightUsers , this.myFormValues.id);
    },
    async getListLeftUser(){
      const params= {
        company_id : this.myFormValues.company_id,
        company_brand_id: this.myFormValues.company_branch_id,
        sort_value: 'name',
        sort_by: 1
      };
      await Store.dispatch("Employees/get", {params});
    }
  },
};
</script>

<style lang="scss" scoped>
.manager-edit-table-wrapper.site-manager-edit {
  border-left: none;
}
::v-deep .v-toolbar .v-toolbar__content{
  justify-content: space-between;
}
</style>