<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" md="6" sm="6">
                  <v-list-item>
                    <v-list-item-content>
                      <InputImage
                        name="machine_company_field_application_images"
                        :editable="editable"
                        :values="formValues"
                        @onInput="onInput"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" md="6" sm="6">
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="備考" :editable="editable">
                        <InputTextArea
                          name="image_remarks"
                          :editable="editable"
                          :values="formValues.machine_company_field_applications"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputImage from "@/components/forms/device/coordinator/components/InputImage.vue";
import InputTextArea from "@/components/forms/elements/InputTextArea";

export default {
  data: () => {
    return {
      formValues: {
        machine_company_field_application_images: [
          {
            id: null,
            image: "",
            image_url: ""
          }
        ]
      },
    };
  },
  components: {
    ValidationObserver,
    Label,
    SectionLabel,
    InputImage,
    InputTextArea,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      const headerHeight = 48;
      let style = `height:${this.mainHeight - headerHeight}px;`;
      return style;
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      if (name == "image_remarks") {
        formValues['machine_company_field_applications'][name] = value;
      } else {
        formValues[name] = value;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
</style>
