<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters v-if="isNewItem">
                    <v-col cols="12" sm="6">
                      <!-- 日付 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="日付(開始)" :editable="editable" required>
                            <InputDatepicker
                              name="work_date_start"
                              :values="work_group_plans"
                              :editable="editable && !disableData"
                              placeholder=""
                              validation_label="日付(開始)"
                              :validation_rules="getStartDateRules"
                              @onInput="onInput"
                              :flagNull="true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <!-- 日付 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="日付(終了)" :editable="editable" required>
                            <InputDatepicker
                              name="work_date_end"
                              :values="work_group_plans"
                              :editable="editable && !disableData"
                              placeholder=""
                              validation_label="日付(終了)"
                              :validation_rules="getEndDateRules"
                              @onInput="onInput"
                              :flagNull="true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-else>
                    <v-col cols="12" sm="6">
                      <!-- 日付 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="日付" :editable="editable" required>
                            <InputDatepicker
                              name="work_date"
                              :values="work_group_plans"
                              :editable="editable && !disableData"
                              placeholder=""
                              validation_label="日付"
                              validation_rules="required"
                              @onInput="onInput"
                              :flagNull="true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <!-- 工事 * -->
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label label="工事" :editable="editable" required>
                            <Select
                              class="select-position"
                              name="field_construction_id"
                              :values="work_group_plans"
                              :editable="editable && !disableData"
                              :items="CONSTRUCTION"
                              validation_label="工事"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <!-- 作業グループ -->
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label
                            label="作業グループ"
                            :editable="editable"
                          >
                            <Select
                              class="select-position"
                              name="field_tree_group_id"
                              :values="work_plans"
                              :editable="editable && !disableData"
                              :items="WORKING_GROUP"
                              item_text="group_name"
                              validation_label="作業グループ"
                              validation_rules=""
                              :label="work_plans.group_name || ''"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <!-- 協力会社 -->
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label label="協力会社" :editable="editable" required>
                            <Select
                              class="select-position"
                              name="field_tree_id"
                              :values="work_plans"
                              :items="PARTNER_COMPANY"
                              item_text="company_name"
                              item_value="field_tree_id"
                              :editable="editable"
                              :label="work_plans.field_tree_conpany_name"
                              validation_label="協力会社"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <!-- 職長 -->
                    <v-col>
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label label="職長" :editable="editable" required>
                            <Select
                              class="select-position"
                              name="foreman_user_id"
                              :values="work_plans"
                              :editable="editable"
                              :items="foremanList"
                              :label="work_plans.foreman_user_name"
                              validation_label="職長"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <Label label="職種" class="ml-4"></Label>
                    </v-col>
                    <v-col>
                      <Label label="経験年数" class="ml-4"></Label>
                    </v-col>
                  </v-row>
                  <v-main
                    v-for="(element, index) in USER_EXPERIENCES"
                    :key="index"
                  >
                    <v-row no-gutters>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <InputText
                              name="name"
                              :values="getSkills(element.cmn_mst_skills_id)"
                              :editable="false"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <InputText
                              name="experience"
                              :values="element"
                              :editable="false"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-main>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label
                            label="作業時間帯"
                            :editable="editable"
                            required
                          >
                            <Select
                              class="select-position"
                              name="work_hour_zone"
                              :values="work_group_plans"
                              :editable="editable && !disableData"
                              :items="WORK_TIMEZONE_ARRAY"
                              validation_label="作業時間帯"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label label="作業場所" :editable="editable">
                            <Select
                              class="select-position"
                              name="field_item_tree_id"
                              :values="work_plans"
                              :editable="editable"
                              :items="WORK_PLACE"
                              item_text="item_name"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <WorkPlaceInput
                            name="work_keyplan_image_plans"
                            :values="formValues"
                            :editable="editable"
                            :errors="errors"
                            :key="resetFormImage"
                            @onInput="onInput"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "../../elements/Label";
import InputText from "../../elements/InputText";
import InputDatepicker from "../../elements/InputDatepicker.vue";
import Select from "../../elements/Select.vue";
import WorkPlaceInput from "./components/WorkPlaceInput.vue";
import { Store } from "@/store/Store.js";
import _ from "lodash";

const INIT_WORK_USER_PLANS = {
  "1": [
    {
      user_id: null,
      used_type: 1,
    },
  ],
  "2": [
    {
      user_id: null,
      used_type: 2,
    },
  ],
  "3": [
    {
      user_id: null,
      used_type: 3,
    },
  ],
  "4": [
    {
      user_id: null,
      used_type: 4,
    },
    {
      user_id: null,
      used_type: 4,
    },
  ],
  "5": [
    {
      user_id: null,
      used_type: 5,
    },
    {
      user_id: null,
      used_type: 5,
    },
  ],
}

export default {
  data: () => {
    return {
      formValues: {},
      CONSTRUCTION: [],
      WORKING_GROUP: [],
      PARTNER_COMPANY: [],
      FOREMAN: [],
      WORK_PLACE: [],
      USER_EXPERIENCES: [],
      CMN_MST_SKILLS: Store.state.CmnMst.constants.entries.cmn_mst_skills,
      WORK_TIMEZONE_ARRAY: [],
      work_plans: {},
      work_group_plans: {}
    };
  },
  components: {
    Label,
    InputText,
    InputDatepicker,
    Select,
    WorkPlaceInput,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    errors: {
      type: Object,
      default: {},
    },
    disableData: Boolean,
    formUpdate: Function,
    isNewItem: Boolean,
    resetFormImage: Boolean,
  },
  mounted() {
    this.getWorkTypes(this.CurrentSite.field_id);
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = _.cloneDeep(newValue);
        this.work_plans = {...newValue['work_plans']};
        this.work_group_plans = {...newValue['work_group_plans']};
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => [
        this.formValues?.work_group_plans?.field_construction_id,
        this.formValues?.work_plans?.field_tree_group_id,
        this.formValues?.work_plans?.field_tree_id,
      ],
      (newVal, oldVal) => {
        if (oldVal == undefined) {
          this.getListGroup(newVal[0]);
          this.getListCompanyPartner(newVal[0],newVal[1]);
          if(newVal[2]) {
            this.getListUsers(newVal[2]);
          }
        }
        if (oldVal != undefined) {
          if (newVal[0] != oldVal[0]) {
            this.getListItemTrees(newVal[0])
            this.getListGroup(newVal[0]);
            if(!newVal[1]) {
              this.getListCompanyPartner(newVal[0], null);
            }
            this.FOREMAN = [this.getDefaultArrays("id", "name")];
            this.USER_EXPERIENCES = [];
          }
          if (newVal[1] != oldVal[1] && newVal[1]) {
            this.getListCompanyPartner(newVal[0],newVal[1]);
            if (oldVal[1]) {
              this.FOREMAN = [this.getDefaultArrays("id", "name")];
              this.USER_EXPERIENCES = [];
            }
          }
          if (newVal[2] != oldVal[2] && newVal[2]) {
            this.getListUsers(newVal[2]);
          }
        }
      },
      {
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["PortalChart/getListItemTrees"],
      (data) => {
        const array = [...data];
        array.unshift(this.getDefaultArrays("id", "item_name"));
        this.WORK_PLACE = [...array];
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["PortalChart/getFieldTimeZones"],
      (data) => {
        this.WORK_TIMEZONE_ARRAY = [this.getDefaultArrays("id", "name"),...data];
      },
      {
        deep: true,
      }
    );
  },
  computed: {
    CurrentSite() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    getStartDateRules() {},
    getEndDateRules() {},
    foremanList() {
      return this.FOREMAN.filter(e => e.state_handling_flag === 0 || e.id === null);
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },
  methods: {
    async onInput({ name, value }) {
      const formValues = { ...this.formValues };
      if (name == "field_construction_id") {
        formValues.work_plans.field_tree_group_id = null;
        formValues.work_plans.field_tree_id = null;
        formValues.work_plans.foreman_user_id = null;
        formValues.work_plans.field_item_tree_id = null;
        formValues.work_user_plans = _.cloneDeep(INIT_WORK_USER_PLANS);
      }
      if (name == "field_tree_group_id") {
        formValues.work_plans.field_tree_id = null;
        formValues.work_plans.foreman_user_id = null;
        formValues.work_user_plans = _.cloneDeep(INIT_WORK_USER_PLANS);
        if (!value) {
          this.USER_EXPERIENCES = [];
          this.FOREMAN = [this.getDefaultArrays("id", "name")];
          await this.getListCompanyPartner(formValues.work_group_plans.field_construction_id, null);
        }
      }
      if (name == "field_tree_id") {
        if(!formValues.work_plans.field_tree_group_id) {
          formValues.work_plans.field_tree_group_id = this.PARTNER_COMPANY.find(e => e.field_tree_id == value)?.field_tree_group_id || null;
          if(formValues.work_plans.field_tree_group_id) {
            const paramsDirector = {
              group_id: formValues.work_plans.field_tree_group_id,
              state_handling_flag: 0
            };
            await Store.dispatch(`PortalChart/getFieldDirectorsObayashi`, paramsDirector);
          }
        }
        formValues.work_plans.foreman_user_id = null;
        formValues.work_user_plans = _.cloneDeep(INIT_WORK_USER_PLANS);
        if (!value) {
          this.USER_EXPERIENCES = [];
          this.FOREMAN = [this.getDefaultArrays("id", "name")];
        }
      }
      if (name == "foreman_user_id") {
        this.USER_EXPERIENCES = this.FOREMAN.find(
          (element) => element?.user_id == value
        )?.user_experiences;
      }
      if (name == "work_keyplan_image_plans"){
        formValues[name] = value;
      } else if (name == "field_construction_id" || name == "work_date_start" || name == "work_date_end" || name == "work_date" || name == "work_hour_zone") {
        formValues['work_group_plans'][name] = value;
      } else {
        formValues['work_plans'][name] = value;
      }
      if (name == 'work_date_start' || name == 'work_date_end') {
        let date = value.replaceAll("-", "/");
        this.work_group_plans.work_date_start = this.work_group_plans.work_date_start.replaceAll("-", "/");
        this.work_group_plans.work_date_end = this.work_group_plans.work_date_end.replaceAll("-", "/");
        let start_date = this.work_group_plans.work_date_start;
        let end_date = this.work_group_plans.work_date_end;
        if (date <  start_date )  {
            this.work_group_plans.work_date_start = date ;
            formValues["work_group_plans"]['work_date_start'] = date;
        } 
        if (date > end_date )  {
            this.work_group_plans['work_date_end'] = date ;
            formValues["work_group_plans"]['work_date_end'] = date;
        } 
      }
      this.$emit("formUpdate", formValues);
    },
    async getWorkTypes(id) {
      await Store.dispatch("PortalChart/getWorkTypes", id);
      this.CONSTRUCTION = [this.getDefaultArrays("id", "name"),...Store.getters["PortalChart/getWorkTypes"]];
    },
    async getListGroup(field_construction_id) {
      if (field_construction_id) {
        let rs = await Store.dispatch("PortalChart/getListGroups", field_construction_id);
        this.WORKING_GROUP = [this.getDefaultArrays("id", "group_name"),...rs.data.contents.entries];
        await Store.dispatch(`PortalChart/getFieldTimeZones`, field_construction_id);
      } else {
        this.WORKING_GROUP = [this.getDefaultArrays("id", "group_name")];
      }
    },

    async getListCompanyPartner(field_construction_id,field_tree_group_id) {
      if(field_construction_id || field_tree_group_id) {
        const params = {
          field_construction_id: field_construction_id,
          field_tree_group_id: field_tree_group_id,
          state_handling_flag: 0,
          display_branch_name_flag: 1
        };
        const paramsDirector = {
          field_construction_id: field_construction_id,
          group_id: field_tree_group_id,
          state_handling_flag: 0
        };
        if (field_tree_group_id) {
          delete params.field_construction_id;
          delete paramsDirector.field_construction_id;
        } else {
          delete params.field_tree_group_id;
          delete paramsDirector.group_id;
        }
        let rs = await Store.dispatch("PortalChart/getListCompanyPartner", {
          params: params,
        });
        this.PARTNER_COMPANY = [this.getDefaultArrays("field_tree_id", "company_name"), ...rs.data.contents.entries];
        await Store.dispatch(`PortalChart/getFieldDirectorsObayashi`, paramsDirector);
      } else {
        this.PARTNER_COMPANY = [this.getDefaultArrays("field_tree_id", "company_name")];
        await Store.dispatch(`PortalChart/clearListFieldDirector`);
      }
    },

    async getListUsers(field_tree_id) {
      const params = { id: field_tree_id, foreman_flag: 1 };
      let rs = await Store.dispatch("PortalChart/getListCompanyPartnerUser", params);
      this.FOREMAN = _.cloneDeep(rs.data.contents.entries);
      this.FOREMAN.forEach((element) => {
        element.name = element.user_name_sei + " " + element.user_name_mei;
        element.id = element.user_id;
      });
      this.FOREMAN.unshift(this.getDefaultArrays("id", "name"))
      this.USER_EXPERIENCES = this.FOREMAN.find(
              (element) => element?.user_id == this.formValues?.work_plans?.foreman_user_id
            )?.user_experiences;
    },
    getSkills(cmn_mst_skills_id) {
      let skill = this.CMN_MST_SKILLS.find((e) => e.id == cmn_mst_skills_id);
      if (skill) {
        return { name: skill.catergory + " " + skill.sub_catergory };
      }
      return { name: "" };
    },
    getDefaultArrays(id, value) {
      let results = {};
      results[id] = null;
      results[value] = "";
      return results;
    },
    async getListItemTrees(val) {
      if (val) {
        let params = {
          field_id: this.CURRENT_SITE.field_id,
          field_construction_id: val
        };
        await Store.dispatch("PortalChart/getListItemTrees", { params });
      } else {
        this.WORK_PLACE = [this.getDefaultArrays("id", "item_name")];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";

.row-position {
  margin-top: -20px;
  height: 150px;
  position: relative;
}

.select-position {
  width: 100%;
  position: absolute;
}
</style>
