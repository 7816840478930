<template>
  <div class="mt-5 mb-cst">
    <!-- List button -->
    <template>
      <v-btn
        depressed
        class="mx-3"
        color="primary"
        :disabled="isDisabledBtn"
        @click="openFormReEstimate()"
        v-if="roleUser == ROLE_USER[3]"
      >
        再見積
      </v-btn>
      <v-btn
        depressed
        class="mx-3"
        color="primary"
        :disabled="isDisabledBtn"
        @click="openFormReOrder()"
        v-if="roleUser == ROLE_USER[3]"
      >
        再発注
      </v-btn>
      <v-btn
        depressed
        class="mx-3"
        color="primary"
        :disabled="isDisabledBtn"
        @click="confirmDeliveryDialog()"
        v-if="roleUser == ROLE_USER[4]"
      >
        納品報告
      </v-btn>
      <v-btn
        class="mx-3"
        small
        icon
        :disabled="isDisabledBtn"
        @click="openRemoveDialog()"
        v-if="roleUser == ROLE_USER[3]"
      >
        <v-icon> mdi-delete </v-icon>
      </v-btn>
    </template>
    <!-- parent table -->
    <v-data-table
      hide-default-header
      :headers="tableLabels"
      :items="items"
      v-model="selectedItems"
      :items-per-page="itemsPerPage"
      :height="tableHeight - 40"
      fixed-header
      hide-default-footer
      disable-sort
      :show-select="true"
      class="elevation-0 v-data-table__wrapper"
      sort-by="updatedAt"
      :noDataText="NO_DATA_MESSAGE"
      item-key="machine_company_field_estimate_order_id"
      @click:row="openMachineEstimateOrderForm"
    >
      <template slot="header" :headers="tableLabels">
        <thead>
          <tr>
            <th style="width: 50px">
              <v-simple-checkbox
                :value="selectedItems.length === items.length && items.length > 0"
                @input="clickCheckAll($event)"
              ></v-simple-checkbox>
            </th>
            <th v-for="h in tableLabels" :key="h.value">
              <div>{{ h.text }}</div>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:[`item.order_status`]="{ item }" label small>
        <v-chip small :color="setColor(item.order_status)">{{
          getStatusValue(item.order_status)
        }}</v-chip>
      </template>
      <template v-slot:[`item.last_update_date`]="{ item }" label small>
        <div class="cst-overflow-td">
          {{ splitData(item.last_update_date, 0) }}
        </div>
        <div class="cst-overflow-td">
          {{ splitData(item.last_update_date, 1) }}
        </div>
      </template>
      <template v-slot:[`item.report_flag`]="{ item }" label small>
        <div><v-icon v-if="item.report_flag == 1"> mdi-paperclip </v-icon></div>
      </template>
    </v-data-table>
    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        :flagError="flagError"
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :selectedItems="selectedItems"
        :flagTab3="true"
        warning
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowFormReEstimate">
      <FormRequestEstimate
        @close="closeFormRequestEstimate()"
        @onSubmitRequestEstimate="onSubmitReEstimate"
        title="見積依頼"
        :dataSelect="dataSelect"
        :flag="flagEstimate"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowDeliveryDialog">
      <ConfirmDeliveryDialog
        :flagDelivery="flagDelivery"
        @close="closeDeliveryDialog()"
        @yes="submitDeliveryDialog()"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowFormReOrder">
      <FormRequestOrder
        :flag="flagOder"
        :flagShow="true"
        @close="closeFormRequestOrder()"
        @onSubmitRequestOrder="onSubmitReOrder"
        title="発注"
        :dataSelect="dataSelect"
      />
    </Popup>
    <Popup :dialog="popups.isShowMachineEstimateOrderForm">
      <MachineEstimateOrderForm
        :flagEstimate="false"
        :roleUser="roleUser"
        @closeForm="popups.isShowMachineEstimateOrderForm = false"
        @resetItems="resetItems"
      />
    </Popup>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import { ROLE_USER, STATUS_COLORS_TAB3 } from "@/constants/DEVICE_COORDINATOR";
import Popup from "@/components/common/Popup";
import ConfirmRemoveDialog from "./ConfirmRemoveDialog.vue";
import ConfirmDeliveryDialog from "./ConfirmDeliveryDialog.vue";
import FormRequestOrder from "./FormRequestOrder.vue";
import FormRequestEstimate from "./FormRequestEstimate.vue";
import MachineEstimateOrderForm from "@/components/forms/device/coordinator/estimateOrder/index.vue";
const STORE = "DeviceCoordinator";
export default {
  data() {
    return {
      isDisable: false,
      NO_DATA_MESSAGE,
      ROLE_USER,
      STATUS_COLORS_TAB3,
      selectedItems: [],
      checkedAll: false,
      flagError: false,
      flagDelivery: false,
      flagOder: false,
      flagEstimate: false,
      popups: {
        isShowRemoveDialog: false,
        isShowDeliveryDialog: false,
        isShowFormReOrder: false,
        isShowFormReEstimate: false,
        isShowMachineEstimateOrderForm : false
      },
    };
  },
  components: {
    Popup,
    ConfirmRemoveDialog,
    ConfirmDeliveryDialog,
    FormRequestOrder,
    FormRequestEstimate,
    MachineEstimateOrderForm
  },
  props: {
    tableLabels: {
      type: Array,
    },
    items: Array,
    itemsPerPage: Number,
    tableHeight: Number,
    roleUser: Number,
    dataSelect: Array,
    resetSelected : Boolean
  },
  mounted() {
    this.$watch(
      () => this.resetSelected,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          this.selectedItems = [];
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  computed: {
    isDisabledBtn() {
      return this.selectedItems.length === 0;
    },
    getFieldId() {
      return Store.getters["GlobalHeader/getCurrentSite"]?.field_id;
    },
  },
  methods: {
    clickCheckAll(event) {
      if (event) {
        this.selectedItems = this.items;
      } else {
        this.selectedItems = [];
      }
    },
    setColor(applyStatus) {
      return this.STATUS_COLORS_TAB3[applyStatus]?.color;
    },
    getStatusValue(applyStatus) {
      return this.STATUS_COLORS_TAB3[applyStatus]?.value;
    },

    closeRemoveDialog() {
      this.selectedItems = [];
      this.popups.isShowRemoveDialog = false;
    },
    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },
    async removeItems() {
      const machine_company_field_estimate_order_ids = this.selectedItems.map(
        (item) => item.machine_company_field_estimate_order_id
      );
      const params = {
        machine_company_field_estimate_order_ids,
        type: 1
      };
      const result = await Store.dispatch(`${STORE}/deleteOrderEstimate`, params);

      if (result.hasError) {
        this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.response.message,
        });
        return;
      } else {
        this.closeRemoveDialog();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
        this.$emit("resetDataItems");
      }
    },
    splitData(item, i) {
      let rs = "";
      if (item) {
        let text = item.split(" ");
        rs = text[i];
      }
      return rs;
    },
    confirmDeliveryDialog() {
      this.popups.isShowDeliveryDialog = true;
    },
    closeDeliveryDialog() {
      this.selectedItems = [];
      this.popups.isShowDeliveryDialog = false;
    },
    async submitDeliveryDialog() {
      let data_order_status = this.selectedItems.filter(e => e.order_status == STATUS_COLORS_TAB3[0].status);
      let machine_company_field_estimate_order_ids = data_order_status.map(
        (items) => items.machine_company_field_estimate_order_id
      );
      if(machine_company_field_estimate_order_ids.length == 0 ){
        this.closeDeliveryDialog();
        return;
      }
      const params = {
        field_id: this.getFieldId,
        machine_company_field_estimate_order_ids,
      };
      const result = await Store.dispatch(`${STORE}/sendOrderReport`, params);
      if (result.hasError) {
        this.flagDelivery = !this.flagDelivery;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.response.message,
        });
        return;
      } else {
        this.closeDeliveryDialog();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "納品報告しました",
        });
        this.$emit("resetDataItems");
      }
    },
    openFormReOrder() {
      this.popups.isShowFormReOrder = true;
    },
    closeFormRequestOrder() {
      this.selectedItems = [];
      this.popups.isShowFormReOrder = false;
    },
    async onSubmitReOrder(data) {
      const machine_company_field_estimate_order_ids = this.selectedItems.map(
        (items) => items.machine_company_field_estimate_order_id
      );
      const params = {
        field_id: this.getFieldId,
        machine_company_field_estimate_order_ids,
        order_remarks: data.order_remarks,
        order_desired_delivery_date: data.order_scheduled_delivery_date,
      };
      const result = await Store.dispatch(`${STORE}/reOrder`, params);
      if (result.hasError) {
        this.flagOder = !this.flagOder;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.response.message,
        });
        return;
      } else {
        this.closeFormRequestOrder();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "発注しました",
        });
        this.$emit("resetDataItems");
      }
    },
    openFormReEstimate() {
      this.popups.isShowFormReEstimate = true;
    },
    closeFormRequestEstimate() {
      this.selectedItems = [];
      this.popups.isShowFormReEstimate = false;
    },
    async onSubmitReEstimate(data) {
      const machine_company_field_estimate_order_ids = this.selectedItems.map(
        (items) => items.machine_company_field_estimate_order_id
      );
      const params = {
        field_id: this.getFieldId,
        machine_company_field_estimate_order_ids,
        estimate_remarks: data.estimate_remarks,
        estimate_rental_company_id: data.estimate_rental_company_id,
        estimate_rental_company_branch_id: data.estimate_rental_company_branch_id,
        estimate_desired_response_date: data.estimate_desired_response_date
      };
      const result = await Store.dispatch(`${STORE}/reEstimate`, params);
      if (result.hasError) {
        this.flagEstimate = !this.flagEstimate;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.response.message,
        });
        return;
      } else {
        this.closeFormRequestEstimate();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "見積しました",
        });
        this.$emit("resetDataItems");
      }
    },
    async openMachineEstimateOrderForm(item) {
      await Store.dispatch(`${STORE}/getOrder`, item.machine_company_field_estimate_order_id);
      this.popups.isShowMachineEstimateOrderForm = true;
    },
    resetItems(){
      this.$emit("resetItemTab3");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
::v-deep .v-data-table .v-data-table__wrapper table tbody tr td {
  word-break: break-all;
}
.cst-overflow-td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mb-cst {
  margin-bottom: 2vh !important;
}
</style>
