<template>
  <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
    <v-toolbar-title color="primary">
      {{ formtitle }}
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn
      v-if="
        !editable && stateHandlingFlag == 1 && workApprovalFlag && isTablesGroup && statusEditable == 1
      "
      depressed
      small
      color="warning"
      class="mr-5"
      outlined
      @click="$emit('onShowScheduleDialog')"
    >
      中止取消
    </v-btn>
    <v-btn
      v-if="(editable && isTablesGroup) || isNewItem"
      class="mr-5"
      depressed
      small
      outlined
      color="primary"
      @click="$emit('onCloseConfirmDialog')"
    >
      やめる
    </v-btn>
    <v-btn
        v-if="
        (!editable && stateHandlingFlag == 0 && workApprovalFlag && isTablesGroup) && statusEditable == 1 && ENV_OBAYASHI
      "
        class="mr-5"
        depressed
        small
        outlined
        color="primary"
        @click="onClickBtnClose"
    >
      やめる
    </v-btn>
    <v-btn
      v-if="stateHandlingFlag == 0 && workApprovalFlag && isTablesGroup && statusEditable == 1"
      depressed
      small
      color="warning"
      class="mr-5"
      outlined
      @click="$emit('onShowScheduleDialog')"
    >
      作業中止
    </v-btn>
    <v-btn
      v-if="(editable && isShowButtonCopy && isTablesGroup) || isNewItem"
      depressed
      class="mr-5"
      small
      color="primary"
      @click="onCopy"
    >
      前回コピー
    </v-btn>
    <v-btn
      v-if="
        !editable && stateHandlingFlag == 0 && workApprovalFlag && isTablesGroup && statusEditable == 1
      "
      depressed
      small
      color="primary"
      @click="onEditable"
    >
      編集
    </v-btn>
    <v-btn
        v-if="((editable && isTablesGroup) || isNewItem) && !ENV_OBAYASHI"
        depressed
        small
        :disabled="!valid"
        color="primary"
        @click="onSubmit"
    >
      保存
    </v-btn>
    <v-btn
      v-if="(editable && isTablesGroup) && !isNewItem && ENV_OBAYASHI"
      depressed
      small
      :disabled="!valid"
      color="primary"
      @click="onSubmit"
    >
      更新
    </v-btn>
    <v-btn
        v-if="(editable && isTablesGroup) && isNewItem && ENV_OBAYASHI"
        depressed
        small
        :disabled="!valid"
        color="primary"
        @click="onSubmit"
    >
      登録
    </v-btn>
    <v-btn icon @click="onClickBtnClose" data-testid="btn-close">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <template v-slot:extension>
      <v-tabs v-model="nTab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-for="(form, key) in FORMS"
          :key="key"
          :data-testid="`workerForm-tab-${key}`"
        >
          {{ form.title }}
        </v-tab>
      </v-tabs>
    </template>
  </v-app-bar>
</template>
<script>
import {ENV_CLIENT} from "@/constants/ENV_CLIENT";

export default {
  data: () => {
    return {
      nTab: 0,
    };
  },
  mounted() {
    this.$watch(
      () => this.tab,
      (newValue) => {
        this.nTab = newValue;
      }
    );
    this.$watch(
      () => this.nTab,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.$emit("change", newValue);
        }
      }
    );
  },
  // v-model
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    tab: {
      type: Number,
      default: 0,
    },
    editable: {
      type: Boolean,
    },
    workApprovalFlag: {
      type: Boolean,
    },
    stateHandlingFlag: {
      type: Number,
    },
    isTablesGroup: {
      type: Boolean,
    },
    isNewItem: {
      type: Boolean,
    },
    isShowButtonCopy: {
      type: Boolean,
    },
    isShowConfirmDialog: {
      type: Boolean,
    },
    isShowScheduleDialog: {
      type: Boolean,
    },
    formtitle: {
      type: String,
    },
    FORMS: {
      type: Object,
    },
    valid: {
      type: Boolean,
    },
    onCopy: {
      type: Function,
    },
    onEditable: {
      type: Function,
    },
    onSubmit: {
      type: Function,
    },
    onClickBtnClose: {
      type: Function,
    },
    statusEditable : {
      type : Number,
      default: 1
    }
  },
  computed: {
    ENV_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    }
  }
};
</script>
<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
