import { render, staticRenderFns } from "./MachineDetailFormTab1.vue?vue&type=template&id=2738bdaa&scoped=true&"
import script from "./MachineDetailFormTab1.vue?vue&type=script&lang=js&"
export * from "./MachineDetailFormTab1.vue?vue&type=script&lang=js&"
import style0 from "./MachineDetailFormTab1.vue?vue&type=style&index=0&id=2738bdaa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2738bdaa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCol,VContainer,VForm,VListItem,VListItemContent,VRow})
