import Api, { Mock } from "../api";
import entrance_summary_list from "./mock/entrance_summary_list.json";
import entrance_summary_children from "./mock/entrance_summary_children.json";

const SUMMARY_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/entrance_histories`;
const GET_LIST_URL = `${SUMMARY_BASE_URL}/get_summary`;
const GET_CHILDREN_URL = `${SUMMARY_BASE_URL}/get_summary_by_company`;

if (Mock) {
    Mock.onGet(GET_LIST_URL).reply(200, {
        status_code: 200,
        message: "",
        result: true,
        contents: entrance_summary_list,
    });

    Mock.onGet(GET_CHILDREN_URL).reply(200, {
        status_code: 200,
        message: "",
        result: true,
        contents: entrance_summary_children,
    });
}

export const entranceSummary = {
    getListEntranceSummary: (params) => {
        return Api.get(GET_LIST_URL, params );
    },
    getListEntranceSummariesByCompany: (params) => {
        return Api.get(GET_CHILDREN_URL,  params );
    },
};
