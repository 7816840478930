<template>
  <div class="mt-5 mb-cst">
    <!-- List button-->
    <template>
      <v-btn
        depressed
        class="mx-3"
        color="primary"
        :disabled="isDisabledBtn"
        v-if="roleUser === ROLE_USER[3]"
        @click="confirmApprove()"
      >
        承認
      </v-btn>
      <v-btn
        depressed
        class="mx-3"
        color="warning"
        :disabled="isDisabledBtn"
        v-if="roleUser === ROLE_USER[3]"
        @click="confirmUnApprove()"
      >
        承認解除
      </v-btn>
      <v-btn
        depressed
        class="mx-3"
        color="primary"
        :disabled="isDisabledBtn"
        v-if="roleUser === ROLE_USER[2] || roleUser === ROLE_USER[3]"
        @click="confirmStopUse()"
      >
        使用終了
      </v-btn>
      <v-btn
        class="mx-3"
        small
        icon
        :disabled="isDisabledBtn"
        v-if="roleUser !== ROLE_USER[4]"
        @click="openRemoveDialog()"
      >
        <v-icon> mdi-delete </v-icon>
      </v-btn>
    </template>
    <!-- parent table -->
    <v-data-table
      hide-default-header
      :headers="tableLabels"
      :items="items"
      v-model="selectedItems"
      :items-per-page="itemsPerPage"
      :height="tableHeight-40"
      fixed-header
      hide-default-footer
      disable-sort
      :show-select="true"
      class="elevation-0 v-data-table__wrapper"
      sort-by="updatedAt"
      :noDataText="NO_DATA_MESSAGE"
      item-key="machine_company_field_history_id"
      @click:row="openItemForm"
    >
      <template slot="header" :headers="tableLabels">
        <thead>
          <tr>
            <th style="width: 50px">
              <v-simple-checkbox
                :value="selectedItems.length === items.length && items.length > 0"
                @input="clickCheckAll($event)"
              ></v-simple-checkbox>
            </th>
            <th v-for="(h, index) in tableLabels" :key="h.value" :style="getWidthStyle(index)">
              <div v-for="text in convertTextHeaderArr(h.text)" :key="text">
                <div>{{text}}</div>
              </div>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:[`item.used_approval_flag`]="{ item }" label small>
        <v-chip
          small
          :color="setColor(item.used_approval_flag)"
          >{{ getStatusValue(item.used_approval_flag) }}</v-chip
        >
      </template>
      <template v-slot:[`item.machine_type_extral_machine_name`]="{ item }" label small>
        <div class="cst-overflow-td">{{ item.machine_company_type_item_tree_name }}</div>
        <div class="cst-overflow-td">{{ item.machine_name }}</div>
      </template>
      <template v-slot:[`item.delivery_company_name_extral_machine_number`]="{ item }" label small>
        <div class="cst-overflow-td">{{ item.delivery_company_name }}</div>
        <div class="cst-overflow-td">{{ item.machine_number }}</div>
      </template>
      <template v-slot:[`item.used_company_name_extral_foreman_name`]="{ item }" label small>
        <div class="cst-overflow-td">{{ item.used_company_name }}</div>
        <div class="cst-overflow-td">{{ item.foreman_name }}</div>
      </template>
      <template v-slot:[`item.used_start_date_extral_used_end_date`]="{ item }" label small>
        <div class="cst-overflow-td">{{ item.used_start_date }}</div>
        <div class="cst-overflow-td">{{ item.used_end_date }}</div>
      </template>
    </v-data-table>
    <Popup width="480px" :dialog="popups.isShowConfirmDialog">
      <ConfirmDeliveryDialog
        :flagError="flagError"
        @close="closeConfirmDialog()"
        @yes="updateApproval()"
        :title="popups.title"
        :text="popups.message"
        :text2="popups.text2"
        :titleBtnOk="popups.titleBtnOk"
        :flagAprrove="approvalFlag"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowConfirmStopUseDialog">
      <ConfirmDeliveryDialog
        :flagError="flagError"
        @close="closeConfirmStopUseDialog()"
        @yes="updateStopUse()"
        :title="popups.title"
        :text="popups.message"
        :titleBtnOk="popups.titleBtnOk"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        :flagError="flagError"
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :selectedItems="selectedItems"
        warning
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowMessageDialog">
      <ConfirmDialog
        @OK="closeMessageDialog()"
        :title="popups.title"
        :message="popups.message"
        warning
      />
    </Popup>
    <Popup :dialog="popups.isShowRegisterDeviceDialog">
      <PreviewDeviceForm
        :items="dataRegister"
        :isNewItem="false"
        :flagError="flagError"
        :requiredEndDate="requiredEndDate"
        @formUpdate="formUpdate"
        @submit="updateMachineInventoryUsage()"
        @cancel="closePreviewDeviceForm()"
      />
    </Popup>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import {
  ROLE_USER,
  APPROVAL_COLORS,
  APPROVE_STATUS,
  INFO_CONFIRM_DIALOG,
  INIT_DATA_FORM_REGISTER,
} from "@/constants/DEVICE_STOCK";
import Popup from "@/components/common/Popup";
import ConfirmDeliveryDialog from './ConfirmDeliveryDialog.vue';
import ConfirmDialog from './ConfirmDialog.vue';
import ConfirmRemoveDialog from './ConfirmRemoveDialog.vue';
import PreviewDeviceForm from "@/components/forms/device/stock/preview_device/index.vue";
const STORE = "DeviceStocks";
export default {
  data() {
    return {
      isDisable: false,
      NO_DATA_MESSAGE,
      ROLE_USER,
      APPROVAL_COLORS,
      APPROVE_STATUS,
      INFO_CONFIRM_DIALOG,
      approvalFlag: false,
      selectedItems: [],
      flagError: false,
      popups: {
        isShowRemoveDialog: false,
        isShowConfirmDialog: false,
        isShowMessageDialog: false,
        isShowConfirmStopUseDialog: false,
        isShowRegisterDeviceDialog: false,
        title: "",
        message: "",
        text2: "",
        titleBtnOk: ""
      },
      dataRegister: {...INIT_DATA_FORM_REGISTER},
      requiredEndDate : false
    };
  },
  components: {
    ConfirmDeliveryDialog,
    ConfirmDialog,
    Popup,
    ConfirmRemoveDialog,
    PreviewDeviceForm,
  },
  props: {
    tableLabels: {
      type: Array,
    },
    items: Array,
    itemsPerPage: Number,
    tableHeight: Number,
    roleUser: Number,
    resetSelected : Boolean
  },
  mounted() {
    this.$watch(
      () => this.resetSelected,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          this.selectedItems = [];
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  computed: {
    isDisabledBtn() {
      return this.selectedItems.length === 0;
    },
    getFieldId() {
      return Store.getters["GlobalHeader/getCurrentSite"]?.field_id;
    },
  },
  methods: {
    /**
     * set width for column
     */
    getWidthStyle(column) {
      let style = "";
      if (column == 0) {
        style = 'width: 100px';
      } else if (column == 2 || column == 4) {
        style = 'width: 25vw';
      } else {
        style = 'width: 10vw';
      }
      return style;
    },
    clickCheckAll(event) {
      if (event) {
        this.selectedItems = this.items;
      } else {
        this.selectedItems = [];
      }
    },
    convertTextHeaderArr(textHeader) {
      let textHeaderArr = [];
      if (textHeader.includes(" / ")) {
        textHeaderArr = textHeader.split(" / ");
        textHeaderArr[1] = "/ ".concat(textHeaderArr[1]);
      } else {
        textHeaderArr.push(textHeader);
      }
      return textHeaderArr;
    },
    setColor(applyStatus) {
      return this.APPROVAL_COLORS[applyStatus]?.color;
    },
    getStatusValue(applyStatus) {
      return this.APPROVAL_COLORS[applyStatus]?.value;
    },
    confirmApprove() {
      const machineUnAproves = this.selectedItems.filter((e) => e.used_approval_flag === APPROVE_STATUS.UNAPPROVE.id) || [];
      const isAllAproved = machineUnAproves.length == 0;
      if (isAllAproved) {
        this.popups.title = INFO_CONFIRM_DIALOG.CONFIRM_APPROVE_1.title;
        this.popups.message = INFO_CONFIRM_DIALOG.CONFIRM_APPROVE_1.message;
        this.popups.isShowMessageDialog = true;
      } else {
        this.popups.title = INFO_CONFIRM_DIALOG.CONFIRM_APPROVE_2.title;
        this.popups.message = INFO_CONFIRM_DIALOG.CONFIRM_APPROVE_2.message;
        this.popups.text2 = INFO_CONFIRM_DIALOG.CONFIRM_APPROVE_2.text2;
        this.popups.titleBtnOk = INFO_CONFIRM_DIALOG.CONFIRM_APPROVE_2.titleBtnOk;
        this.popups.isShowConfirmDialog = true;
        this.approvalFlag = true;
      }
    },
    confirmUnApprove() {
      const machineAproveds = this.selectedItems.filter((e) => e.used_approval_flag === APPROVE_STATUS.APPROVE.id) || [];
      const isAllUnAprove = machineAproveds.length === 0;
      if (isAllUnAprove) {
        this.popups.title = INFO_CONFIRM_DIALOG.CONFIRM_UNAPPROVE_1.title;
        this.popups.message = INFO_CONFIRM_DIALOG.CONFIRM_UNAPPROVE_1.message;
        this.popups.isShowMessageDialog = true;
      } else {
        this.popups.title = INFO_CONFIRM_DIALOG.CONFIRM_UNAPPROVE_2.title;
        this.popups.message = INFO_CONFIRM_DIALOG.CONFIRM_UNAPPROVE_2.message;
        this.popups.text2 = INFO_CONFIRM_DIALOG.CONFIRM_UNAPPROVE_2.text2;
        this.popups.titleBtnOk = INFO_CONFIRM_DIALOG.CONFIRM_UNAPPROVE_2.titleBtnOk;
        this.popups.isShowConfirmDialog = true;
        this.approvalFlag = false;
      }
    },
    closeConfirmDialog() {
      this.selectedItems = [];
      this.popups.isShowConfirmDialog = false;
    },
    closeConfirmStopUseDialog() {
      this.selectedItems = [];
      this.popups.isShowConfirmStopUseDialog = false;
    },
    closeMessageDialog() {
      this.selectedItems = [];
      this.popups.isShowMessageDialog = false;
    },
    async updateApproval() {
      const machine_company_field_history_ids = this.selectedItems.map((items) => items.machine_company_field_history_id);
      const parrams = {
        field_id: this.getFieldId,
        machine_company_field_history_ids,
        used_approval_flag: this.approvalFlag ? 1 : 0,
        approval_type: 1
      };
      const result = await Store.dispatch(`${STORE}/updateApprovalFlag`, parrams);
      if (result.hasError) {
        this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.response.data.message,
        });
        return;
      } else {
        this.closeConfirmDialog();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "承認解除しました",
        });
        this.$emit('resetDataItems');
      }
    },

    /**
     * Show popup confirm stop use
     */
    confirmStopUse() {
      const isAllApprove = (this.selectedItems.filter((e) => e.used_approval_flag === APPROVE_STATUS.UNAPPROVE.id) || []).length === 0;
      if ((isAllApprove && this.roleUser == this.ROLE_USER[3]) || this.roleUser == this.ROLE_USER[2]) {
        this.popups.title = INFO_CONFIRM_DIALOG.CONFIRM_STOP_USE_1.title;
        this.popups.message = INFO_CONFIRM_DIALOG.CONFIRM_STOP_USE_1.message;
        this.popups.titleBtnOk = INFO_CONFIRM_DIALOG.CONFIRM_STOP_USE_1.titleBtnOk;
        this.popups.isShowConfirmStopUseDialog = true;
      } else {
        this.popups.title = INFO_CONFIRM_DIALOG.END_USE_ERROR_TAB_4.title;
        this.popups.message = INFO_CONFIRM_DIALOG.END_USE_ERROR_TAB_4.message;
        this.popups.isShowMessageDialog = true;
      }
    },

    /**
     * Call API update stop use
     */
    async updateStopUse() {
      const machine_company_field_history_ids = this.selectedItems.map((items) => items.machine_company_field_history_id);
      const params = {
        field_id: this.getFieldId,
        machine_company_field_history_ids
      }
      const result = await Store.dispatch(`${STORE}/updateMachineInventoryStopUse`, params);
      if (result.hasError) {
        this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.response.data.message,
        });
        return;
      } else {
        this.closeConfirmStopUseDialog();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "使用終了が確定されました",
        });
        this.$emit('resetDataItems');
      }
    },
    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.selectedItems = [];
      this.popups.isShowRemoveDialog = false;
    },
    /**
     * Call API delete machine company field
     */
    async removeItems() {
      const machine_company_field_history_ids = this.selectedItems.map((items) => items.machine_company_field_history_id);
      const result = await Store.dispatch(`${STORE}/delete`, { machine_company_field_history_ids });

      if (result.hasError) {
        this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.response.data.message,
        });
        return;
      } else {
        this.closeRemoveDialog();
        let ids = [...result.data.contents.entries.ids];
        if (ids.length > 0) {
          Store.dispatch("Toast/show", {
            status: 200,
            message: "削除しました"
          });
          this.$emit("resetDataItems");
        } else {
          this.popups.title = "選択項目の削除";
          this.popups.message = "削除する権限がありません。";
          this.popups.isShowMessageDialog = true;
        }
      }
    },
    async openItemForm(item) {
      const result = await Store.dispatch(`${STORE}/getUsage`, item.machine_company_field_history_id);
      if(!result.hasError) {
        this.requiredEndDate = true;
        const { entries } = result.data.contents;
        this.dataRegister = {...entries};
        this.popups.isShowRegisterDeviceDialog = true;
      }
    },
    closePreviewDeviceForm() {
      this.requiredEndDate = false;
      this.popups.isShowRegisterDeviceDialog = false;
    },
    formUpdate(params) {
      this.dataRegister = {...this.dataRegister, ...params};
    },
    async updateMachineInventoryUsage() {
      const result = await Store.dispatch(`${STORE}/updateMachineInventoryUsage`, this.dataRegister);
      if (result.hasError) {
        this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.response.data.message,
        });
        return;
      } else {
        this.closePreviewDeviceForm();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        this.$emit('resetDataItems');
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.cst-overflow-td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mb-cst {
  margin-bottom: 2vh !important;
}
::v-deep .v-data-table .v-data-table__wrapper table {
  table-layout: fixed;
}
::v-deep .v-data-table .v-data-table__wrapper table tbody tr td {
  word-break: break-all;
}
</style>
