import Vue from "vue";
import Vuex from "vuex";
import { entranceNewWorker } from "../../../api/modules/entranceNewWorker";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = entranceNewWorker;

export const EntranceNewWorker = {
    namespaced: true,

    state: {
        data: [],
        pagination: {},
    },

    mutations: {
        SET_DATA(state, payload) {
            state.data = payload;
        },
        SET_PAGINATION(state, payload) {
            state.pagination = payload;
        },
        SET_CHILD_DATA(state, payload) {
            let parent = [];
            let children = [];
            let data = [];
            parent = state.data.find(
                (item) => item.field_tree_id === payload.field_tree_id
            );
            children = [...parent.children, ...payload.entries];
            data = state.data.map((item) => {
                if (item.field_tree_id === payload.field_tree_id) {
                    const _item = { ...item };
                    _item.children = children;
                    return _item;
                }
                return item;
            });
            state.data = data;
        },
    },

    actions: {
        //機材予定一覧
        async getListNewArrival({ commit }, payload) {
            const response = await ENTITY.getListNewArrival(payload);
            if (response.hasError) {
                return response;
            }
            const { entries, pagination } = response.data.contents;
            commit("SET_DATA", entries);
            commit("SET_PAGINATION", pagination);
            return response;
        },

        async loadChildren({ commit }, payload) {
            const response = await ENTITY.loadChildren(payload);
            if (response.hasError) {
                return response;
            }
            const { entries } = response.data.contents;
            commit("SET_CHILD_DATA", {
                entries,
                field_tree_id: payload.params.field_tree_id,
            });
            return response;
        },
    },

    getters: {
        getData: (state) => {
            return state.data;
        },
        getPagination: (state) => {
            return state.pagination;
        },
    },
};
