import Vue from "vue";
import Vuex from "vuex";
import { machineFieldInspection } from "../../../api/modules/machineFieldInspection";
Vue.use(Vuex);
/**
 * apiを指定
 */
const ENTITY = machineFieldInspection;
export const MachineFieldInspection = {
  namespaced: true,

  state: {
    inspectionList: [],
    inspectionResult: {},
    inspectionResultDetail: {},
  },

  mutations: {
    SET_MACHINE_COMPANY_INSPECTION(state, payload) {
      state.inspectionList = payload;
    },
    SET_INSPECTION_RESULTS(state, payload) {
      state.inspectionResult = payload;
    },
    SET_INSPECTION_RESULTS_DETAIL(state, payload) {
      state.inspectionResultDetail = payload;
    },
  },

  actions: {
    async getMachineCompanyInspecList({ commit }, payload) {
      const response = await ENTITY.getMachineCompanyInspecList(payload);
      const { entries } = response.data.contents;
      commit("SET_MACHINE_COMPANY_INSPECTION", entries);
      return response;
    },

    async getInspectionResults({ commit }, payload) {
      const response = await ENTITY.getInspectionResults(payload);
      if (response.hasError) return response;
      const entries = response.data.contents.entries;
      commit("SET_INSPECTION_RESULTS", entries);
      return response;
    },

    async updateApprovalStatus(_context, payload) {
      return await ENTITY.updateApprovalStatus(payload);
    },
    async getInspecResultDetail({ commit }, payload) {
      const response = await ENTITY.getInspecResultDetail(payload);
      const entries =  response.data.contents.entries;
      commit("SET_INSPECTION_RESULTS_DETAIL", entries);
      return response;
    },
  },

  getters: {
    getMachineCompanyInspecList: (state) => {
      return state.inspectionList;
    },
    getInspectionResults: (state) => {
      return state.inspectionResult;
    },
    getInspecResultDetail: (state) => {
      return state.inspectionResultDetail;
    },
  },
};
