import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

import { Error } from "./modules/Error";
import { Toast } from "./modules/Toast";
import { Drawer } from "./modules/Drawer";
import { Notification } from "./modules/Notification";
import { Tooltip } from "./modules/Tooltip";

import { SiteId } from "./modules/SiteId";

// 施工体制図
import { PortalChart } from "./modules/PortalChart";

/* API */
// APIエンドポイント単位で作成。場合によって細分化も可。
import { CmnMst } from "./modules/api/CmnMst.js";
import { Weather } from "./modules/api/Weather.js";
import { Login } from "./modules/api/Login";
import { Workers } from "./modules/api/Workers.js";
import { SitePortal } from "./modules/api/SitePortal.js";
import { GlobalHeader } from "./modules/GlobalHeader.js";
import { Offices } from "./modules/api/Offices.js";
import { Employees } from "./modules/api/Employees.js";
import { SiteWorkers } from "./modules/api/SiteWorkers.js";
import { SiteManagers } from "./modules/api/SiteManagers.js";
import { Sites } from "./modules/api/Sites.js";
import { CompanyPortal } from "./modules/api/CompanyPortal.js";
import { WorkGroups } from "./modules/api/WorkGroups.js";
import { Gates } from "./modules/api/Gates.js";
import { Machines } from "./modules/api/Machines.js";
import { Cranes } from "./modules/api/Cranes.js";
import { SiteDrawings } from "./modules/api/SiteDrawings.js";
import { CompanyMachines } from "./modules/api/CompanyMachines";

// 予定

import { Schedule } from "./modules/api/Schedule.js";
import { ScheduleWorks } from "./modules/api/ScheduleWorks.js";
import { ScheduleRecords } from "./modules/api/ScheduleRecords.js";
import { ScheduleMachines } from "./modules/api/ScheduleMachines.js";
import { ScheduleGates } from "./modules/api/ScheduleGates.js";
import { ScheduleCrane } from "./modules/api/ScheduleCrane.js";

import { PatrolRecord } from "./modules/api/PatrolRecord.js";
import { ScheduleCompany } from "./modules/api/ScheduleCompany.js";
import { Customizes } from "./modules/api/Customizes.js";

// 労務安全
import { SafetyChart } from "./modules/SafetyChart";
import { File } from "./modules/File";
import { Report } from "./modules/api/Report.js";

//SP
import { ScheduleSearch } from "./modules/ScheduleSearch";

import { Oekaki } from "./modules/Oekaki.js";
import { EntranceNewWorker } from "./modules/api/EntranceNewWorker.js";
import { EntranceHistory } from "./modules/api/EntranceHistory.js";
import { EntranceSummary } from "./modules/api/EntranceSummary.js";
import { EntranceRecord } from "./modules/api/EntranceRecord.js";
import { TextChat } from "./modules/api/TextChat.js";
import { TextChatManage } from "./modules/api/TextChatManage.js";
import { UserChatManage } from "./modules/api/UserChatManage.js";
import { RegisterFace } from "./modules/api/RegisterFace.js";
import { DeviceStocks } from "./modules/api/DeviceStocks.js";
import { DeviceCarryOns } from "./modules/api/DeviceCarryOns.js";
import { MachineField } from "./modules/api/MachineField.js";
import { DeviceCoordinator } from "./modules/api/DeviceCoordinator.js";
import { MachineFieldInspection } from "./modules/api/MachineFieldInspection.js";

// 顔認証 + 入場退場
import { FaceRecognition } from "./modules/api/FaceRecognition";
import { Action } from "./modules/Action";

Vue.use(Vuex);

export const Store = new Vuex.Store({
  modules: {
    Error,
    Toast,
    Drawer,
    Notification,
    Tooltip,
    // 画面
    PortalChart,
    // api
    CmnMst,
    Weather,
    Login,
    Workers,
    SitePortal,
    GlobalHeader,
    Offices,
    Employees,
    SiteWorkers,
    SiteManagers,
    Sites,
    CompanyMachines,
    CompanyPortal,
    WorkGroups,
    SiteId,
    Gates,
    Machines,
    Cranes,
    SiteDrawings,
    //予定
    Schedule,
    ScheduleWorks,
    ScheduleRecords,
    ScheduleMachines,
    Customizes,
    //予定の検索の開閉
    ScheduleSearch,
    //労務安全
    SafetyChart,
    ScheduleGates,
    ScheduleCrane,
    PatrolRecord,
    Report,
    ScheduleCompany,
    Oekaki,
    File,
    EntranceNewWorker,
    EntranceHistory,
    EntranceSummary,
    EntranceRecord,
    //チャット
    TextChat,
    TextChatManage,
    UserChatManage,
    FaceRecognition,
    RegisterFace,
    DeviceStocks,
    DeviceCarryOns,
    MachineField,
    DeviceCoordinator,
    MachineFieldInspection,
    Action,
  },
  strict: true,
  plugins: [
    createPersistedState({
      key: "GRN",
      paths: ["Login.api_token"],
      storage: window.sessionStorage,
    }),
    createPersistedState({
      key: "COMPANY_USER",
      paths: ["Login.company_user"],
      storage: window.sessionStorage,
    }),
  ],
});
