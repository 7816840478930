<template>
  <div class="mt-5 mb-cst">
    <!-- List button -->
    <template>
      <v-btn
        depressed class="mx-3"
        :disabled="isDisabledBtn"
        @click="openConfirmRegistorReturn()"
        v-if="roleUser === ROLE_USER[3]"
        color="primary"
      >
        返却申請
      </v-btn>
      <v-btn
        depressed class="mx-3"
        :disabled="isDisabledBtn"
        @click="openConfirmReturnDialog()"
        v-if="roleUser === ROLE_USER[4]"
        color="primary"
      >
        返却確定
      </v-btn>
    </template>
    <v-data-table
      hide-default-header
      :headers="tableLabels"
      v-model="selectedItems"
      :items="dataTable"
      :items-per-page="itemsPerPage"
      :height="tableHeight-40"
      :item-class="rowClass"
      show-select
      fixed-header
      hide-default-footer
      disable-sort
      item-key="machine_company_field_detail_id"
      class="elevation-0 v-data-table__wrapper"
      sort-by="updatedAt" 
      :noDataText="NO_DATA_MESSAGE"
      @click:row="openItemForm"
    >
      <template slot="header" :headers="tableLabels">
        <thead>
          <tr>
            <th style="width: 50px">
              <v-simple-checkbox
                :value="selectedItems.length === itemsReturn.length && itemsReturn.length > 0"
                @input="clickCheckAll($event)"
              ></v-simple-checkbox>
            </th>
            <th v-for="(h, index) in tableLabels" :key="h.value" :style="getWidthStyle(index)">
              <div v-for="text in convertTextHeaderArr(h.text)" :key="text">
                <div>{{text}}</div>
              </div>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:[`item.used_apply_status`]="{ item }" label small>
        <v-chip
          small
          :dark="item.used_apply_status === 0"
          :color="setColor(item.used_apply_status)"
          >{{ getStatusValue(item.used_apply_status)}}</v-chip
        >
      </template>
      <template v-slot:[`item.machine_type_extral_machine_name`]="{ item }" label small>
        <div class="cst-overflow-td">{{ item.machine_company_type_item_tree_name }}</div>
        <div class="cst-overflow-td">{{ item.machine_name }}</div>
      </template>
      <template v-slot:[`item.delivery_company_name_extral_machine_number`]="{ item }" label small>
        <div class="cst-overflow-td">{{ item.delivery_company_name }}</div>
        <div class="cst-overflow-td">{{ item.machine_number }}</div>
      </template>
    </v-data-table>
    <Popup width="480px" :dialog="popups.isShowConfirmRstReturnDialog">
      <ConfirmDeliveryDialog
        :flagError="flagError"
        @close="closeConfirmRstReturnDialog()"
        @yes="updateRegistorReturn()"
        :title="popups.title"
        :text="popups.message"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowConfirmReturnDialog">
      <ConfirmDeliveryDialog
        :flagError="flagError"
        @close="closeConfirmReturnDialog()"
        @yes="updateConfirmReturn()"
        :title="popups.title"
        :text="popups.message"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowMessageDialog">
      <ConfirmDialog
        @OK="closeMessageDialog()"
        :title="popups.title"
        :message="popups.message"
        warning
      />
    </Popup>
    <Popup :dialog="popups.isShowItemForm">
      <MachineFieldForm
        @updateListItems="resetDataItems"
        @cancel="closeMachineFieldForm"
        :onlyView="onlyView"
        :isShowButtonUsageProhibited="isShowButtonUsageProhibited"
        :isShowButtonEnable="isShowButtonEnable"
      />
    </Popup>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import { ROLE_USER, STATUS_COLORS, USED_APPLY_STATUS, INFO_CONFIRM_DIALOG } from "@/constants/DEVICE_STOCK";
import Popup from "@/components/common/Popup";
import ConfirmDialog from "./ConfirmDialog.vue";
import ConfirmDeliveryDialog from "./ConfirmDeliveryDialog.vue";
import MachineFieldForm from "@/components/forms/device/stock/detail_update/MachineFieldForm.vue";
const STORE = 'DeviceStocks';

export default {
  data() {
    return {
      NO_DATA_MESSAGE,
      selectedItems: [],
      STATUS_COLORS,
      INFO_CONFIRM_DIALOG,
      ROLE_USER,
      USED_APPLY_STATUS,
      STORE,
      flagError: false,
      itemsReturn: [],
      popups: {
        isShowConfirmRstReturnDialog: false,
        isShowConfirmReturnDialog: false,
        isShowMessageDialog: false,
        isShowItemForm : false,
        title: "",
        message: ""
      },
      isShowButtonUsageProhibited: false,
      isShowButtonEnable: false,
      onlyView : false
    };
  },
  components: {
    Popup,
    ConfirmDeliveryDialog,
    ConfirmDialog,
    MachineFieldForm
  },
  props: {
    tableLabels: {
      type: Array,
    },
    roleUser: Number,
    items: Array,
    itemsPerPage: Number,
    tableHeight: Number,
    resetSelected : Boolean
  },
  computed: {
    dataTable() {
      return this.items.map(x => ({ ...x, isSelectable: x.used_apply_status !== USED_APPLY_STATUS.RETURN_STATUS.status }));
    },
    isDisabledBtn() {
      return this.selectedItems.length === 0;
    },
    getFieldId() {
      return Store.getters["GlobalHeader/getCurrentSite"]?.field_id;
    },
  },
  mounted() {
    this.$watch(
      () => this.items,
      (newValue) => {
        this.itemsReturn = [...newValue].filter((e) => e.used_apply_status !== USED_APPLY_STATUS.RETURN_STATUS.status);
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => this.resetSelected,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          this.selectedItems = [];
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  methods: {
    /**
     * set width for column
     */
    getWidthStyle(column) {
      let style = "";
      if (column == 0) {
        style = 'width: 100px';
      } else if (column == 2 || column == 4) {
        style = 'width: 25vw';
      } else {
        style = 'width: 10vw';
      }
      return style;
    },
    /**
     * open detail machine iventory
     * @param item : item click
     * @returns {Promise<void>} : item detail
     */
    async openItemForm(item) {
      if (item.used_apply_status === USED_APPLY_STATUS.RETURN_STATUS.status) {
        this.onlyView = true;
      }else{
        this.onlyView = false;
      }
      switch (item.used_apply_status) {
        case USED_APPLY_STATUS.UNUSE_STATUS.status:
        case USED_APPLY_STATUS.RETURN_BOOK_STATUS.status:
          this.isShowButtonUsageProhibited = true;
          this.isShowButtonEnable = false;
          break;
        case USED_APPLY_STATUS.UNUSABLE_STATUS.status :
          this.isShowButtonEnable = true;
          this.isShowButtonUsageProhibited = false;
          break;
        default :
          this.isShowButtonUsageProhibited = false;
          this.isShowButtonEnable = false;
      }
      const rs = await Store.dispatch(`${STORE}/getMachineFieldDetail`, item.machine_company_field_detail_id);
      // Show Detail Form
      if (!rs.hasError) {
        this.popups.isShowItemForm = true;
      }
    },
    /**
     * close form edit machine inventory
     */
    closeMachineFieldForm(){
      this.popups.isShowItemForm = false;
    },
    /**
     * reset data items when update success
     */
    resetDataItems(){
      this.$emit('resetDataItems');
    },
    clickCheckAll(event) {
      if (event) {
        this.selectedItems = this.itemsReturn;
      } else {
        this.selectedItems = [];
      }
    },
    setColor(applyStatus) {
      return this.STATUS_COLORS[applyStatus]?.color;
    },
    getStatusValue(applyStatus) {
      return this.STATUS_COLORS[applyStatus]?.value;
    },
    convertTextHeaderArr(textHeader) {
      let textHeaderArr = [];
      if (textHeader.includes(" / ")) {
        textHeaderArr = textHeader.split(" / ");
        textHeaderArr[1] = "/ ".concat(textHeaderArr[1]);
      } else {
        textHeaderArr.push(textHeader);
      }
      return textHeaderArr;
    },
    openConfirmRegistorReturn() {
      const machineBookedArray = this.selectedItems.filter((e) => e.used_apply_status === USED_APPLY_STATUS.RETURN_BOOK_STATUS.status) || [];
      // if all selected is booked
      if (machineBookedArray.length == this.selectedItems.length) {
        this.popups.title = INFO_CONFIRM_DIALOG.REGISTER_RETURN_3.title;
        this.popups.message = INFO_CONFIRM_DIALOG.REGISTER_RETURN_3.message;
        this.popups.isShowMessageDialog = true;
      } else {
        this.popups.title = INFO_CONFIRM_DIALOG.REGISTER_RETURN_4.title;
        this.popups.message = INFO_CONFIRM_DIALOG.REGISTER_RETURN_4.message;
        this.popups.isShowConfirmRstReturnDialog = true;
      }
    },
    closeConfirmRstReturnDialog() {
      this.selectedItems = [];
      this.popups.isShowConfirmRstReturnDialog = false;
    },
    async updateRegistorReturn() {
      const machine_company_field_detail_ids = this.selectedItems.map((items) => items.machine_company_field_detail_id);
      const params = {
        field_id: this.getFieldId,
        machine_company_field_detail_ids
      };
      const result = await Store.dispatch(`${STORE}/updateRegistorReturn`, params);
      if (result.hasError) {
        this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.response.data.message,
        });
        return;
      } else {
        this.closeConfirmRstReturnDialog();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "返却申請しました",
        });
        this.$emit('resetDataItems');
      }
    },
    closeMessageDialog() {
      this.selectedItems = [];
      this.popups.isShowMessageDialog = false;
    },
    openConfirmReturnDialog() {
      this.popups.title = INFO_CONFIRM_DIALOG.CONFIRM_RETURN.title;
      this.popups.message = INFO_CONFIRM_DIALOG.CONFIRM_RETURN.message;
      this.popups.isShowConfirmReturnDialog= true;
    },
    closeConfirmReturnDialog() {
      this.selectedItems = [];
      this.popups.isShowConfirmReturnDialog = false;
    },
    async updateConfirmReturn() {
      const machine_company_field_detail_ids = this.selectedItems.map((items) => items.machine_company_field_detail_id);
      const params = {
        field_id: this.getFieldId,
        machine_company_field_detail_ids
      };
      const result = await Store.dispatch(`${STORE}/updateMachineInventoryConfirmReturn`, params);
      if (result.hasError) {
        this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.response.data.message,
        });
        return;
      } else {
        this.closeConfirmReturnDialog();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "返却申請しました",
        });
        this.$emit('resetDataItems');
      }
    },
    rowClass(item) {
      return item.used_apply_status == USED_APPLY_STATUS.RETURN_STATUS.status ? 'grey lighten-1' : '';
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.cst-overflow-td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mb-cst {
  margin-bottom: 2vh !important;
}
::v-deep .v-data-table .v-data-table__wrapper table {
  table-layout: fixed;
}
::v-deep .v-data-table .v-data-table__wrapper table tbody tr td {
  word-break: break-all;
}
</style>
