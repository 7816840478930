<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form ref="form" lazy-validation autocomplete="off">
            <v-container>
              <v-row>
                <!-- col 1  -->
                <v-col>
                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel label="最低年齢" />
                      <Label
                        label="満１５歳に満たない児童、及び満１５歳になった者でもなった日以後の最初の３月３１日を過ぎていない児童を使用していないか（労基法５６条）"
                        :editable="editable"
                        class="long_label"
                        :blackColor = true
                      >
                        <RadioGroup
                          v-if="editable"
                          name="under_15_worker"
                          :values="formValues"
                          :items="RADIO_ITEMS_YES_NO"
                          :editable="editable"
                          @onInput="onInput"
                        />
                      </Label>
                      <span class="ml-2" v-if="!editable">{{
                        getRadioName(RADIO_ITEMS_YES_NO, formValues['under_15_worker'])
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel label="年少者の証明書" />
                      <Label
                        label="満１８歳未満の者について、年齢を証明する証明書（住民票記載事項証明書等）を事業場に備えているか（労基法５７条）"
                        :editable="editable"
                        class="long_label"
                        :blackColor = true
                      >
                        <RadioGroup
                          v-if="editable"
                          name="under_18_worker_certificate"
                          :values="formValues"
                          :items="RADIO_ITEMS_YES_NO_IGNORE"
                          :editable="editable"
                          @onInput="onInput"
                        />
                      </Label>
                      <span class="ml-2" v-if="!editable">{{
                        getRadioName(RADIO_ITEMS_YES_NO_IGNORE, formValues['under_18_worker_certificate'])
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel label="未成年者の労働契約" />
                      <Label
                        label="未成年者に代わって親権者又は後見人と労働契約を締結していないか（労基法５８条）"
                        :editable="editable"
                        class="long_label"
                        :blackColor = true
                      >
                        <RadioGroup
                          v-if="editable"
                          name="under_18_contract_with_parent_or_guardians"
                          :values="formValues"
                          :items="RADIO_ITEMS_YES_NO_IGNORE"
                          :editable="editable"
                          @onInput="onInput"
                        />
                      </Label>
                      <span class="ml-2" v-if="!editable">{{
                        getRadioName(RADIO_ITEMS_YES_NO_IGNORE, formValues['under_18_contract_with_parent_or_guardians'])
                      }}</span>
                      </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <Label
                        label="未成年者の賃金を親権者又は後見人に支払っていないか (労基法５９条）"
                        :editable="editable"
                        class="custom_label"
                        :blackColor = true
                      >
                        <RadioGroup
                          v-if="editable"
                          name="under_18_pay_wages_to_parent_or_guardians"
                          :values="formValues"
                          :items="RADIO_ITEMS_YES_NO_IGNORE"
                          :editable="editable"
                          @onInput="onInput"
                        />
                      </Label>
                      <span class="ml-2" v-if="!editable">{{
                        getRadioName(RADIO_ITEMS_YES_NO_IGNORE, formValues['under_18_pay_wages_to_parent_or_guardians'])
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel label="年少者の労働時間、休日" />
                      <Label
                        label="満１８歳未満の者について、超過、休日勤務をさせていないか（労基法６０条）"
                        :editable="editable"
                        class="long_label"
                        :blackColor = true
                      >
                        <RadioGroup
                          v-if="editable"
                          name="under_18_overworked_or_work_overtime"
                          :values="formValues"
                          :items="RADIO_ITEMS_YES_NO_IGNORE"
                          :editable="editable"
                          @onInput="onInput"
                        />
                      </Label>
                      <span class="ml-2" v-if="!editable">{{
                        getRadioName(RADIO_ITEMS_YES_NO_IGNORE, formValues['under_18_overworked_or_work_overtime'])
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel label="年少者の深夜業" />
                      <Label
                        label="満１８歳未満の者について、深夜（午後１０時から午前５時）に就業させていないか（労基法６１条）"
                        :editable="editable"
                        class="long_label"
                        :blackColor = true
                      >
                        <RadioGroup
                          v-if="editable"
                          name="under_18_work_overnight"
                          :values="formValues"
                          :items="RADIO_ITEMS_YES_NO_IGNORE"
                          :editable="editable"
                          @onInput="onInput"
                        />
                      </Label>
                      <span class="ml-2" v-if="!editable">{{
                        getRadioName(RADIO_ITEMS_YES_NO_IGNORE, formValues['under_18_work_overnight'])
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel label="年少者の就業制限" />
                      <Label
                        label="満１８歳に満たない者に次の業務をさせていないか（労基法６２条、６３条、年少則８条）"
                        :editable="editable"
                        class="long_label"
                        :blackColor = true
                      >
                        <RadioGroup
                          v-if="editable"
                          name="violate_restriction_for_young_people"
                          :values="formValues"
                          :items="RADIO_ITEMS_VIOLATE_RESTRICTION_FOR_YOUNG_PEOPLE"
                          :editable="editable"
                          @onInput="onInput"
                        />
                      </Label>
                      <span class="ml-2" v-if="!editable">{{
                        getRadioName(RADIO_ITEMS_VIOLATE_RESTRICTION_FOR_YOUNG_PEOPLE, formValues['violate_restriction_for_young_people'])
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="formValues.violate_restriction_for_young_people == VIOLATE_RESTRICTION_FOR_YOUNG_PEOPLE_APPLY"
                  >
                    <v-list-item-content>
                      <Label
                        label="※該当ありの場合、以下を記入する"
                        :editable="editable"
                      >
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="（１）動力による土木建築用機械の運転をさせていないか"
                              :editable="editable"
                            >
                              <RadioGroup
                                v-if="editable"
                                name="under_18_use_electricity_devices"
                                :values="formValues"
                                :items="RADIO_ITEMS_YES_NO"
                                :editable="editable"
                                @onInput="onInput
                              
                                "
                              />
                            </Label>
                            <span class="ml-2" v-if="!editable">{{
                              getRadioName(RADIO_ITEMS_YES_NO, formValues['under_18_use_electricity_devices'])
                            }}</span>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="（２）火薬その他危険物（爆発性、引火性のあるもの）を取り扱う業務をさせていないか"
                              :editable="editable"
                              class="long_label"
                        :blackColor = true
                            >
                              <RadioGroup
                                v-if="editable"
                                name="under_18_handling_dangerous_substances"
                                :values="formValues"
                                :items="RADIO_ITEMS_YES_NO"
                                :editable="editable"
                                @onInput="
                                  onInput
                                "
                              />
                            </Label>
                            <span class="ml-2" v-if="!editable">{{
                              getRadioName(RADIO_ITEMS_YES_NO, formValues['under_18_handling_dangerous_substances'])
                            }}</span>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="（３）有害物のガス、蒸気又は粉じんを発散する場所での業務をさせていないか"
                              :editable="editable"
                              class="long_label"
                        :blackColor = true
                            >
                              <RadioGroup
                                v-if="editable"
                                name="under_18_working_place_emit_harmful_gas_or_dust"
                                :values="formValues"
                                :items="RADIO_ITEMS_YES_NO"
                                :editable="editable"
                                @onInput="
                                  onInput
                                "
                              />
                            </Label>
                            <span class="ml-2" v-if="!editable">{{
                              getRadioName(RADIO_ITEMS_YES_NO, formValues['under_18_working_place_emit_harmful_gas_or_dust'])
                            }}</span>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="（４）暑熱、寒冷な場所もしくは異常気圧下での業務をさせていないか"
                              :editable="editable"
                            >
                              <RadioGroup
                                v-if="editable"
                                name="under_18_abnormal_atmosphere"
                                :values="formValues"
                                :items="RADIO_ITEMS_YES_NO"
                                :editable="editable"
                                @onInput="onInput"
                              />
                            </Label>
                            <span class="ml-2" v-if="!editable">{{
                              getRadioName(RADIO_ITEMS_YES_NO, formValues['under_18_abnormal_atmosphere'])
                            }}</span>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="（５）坑内労働をさせていないか"
                              :editable="editable"
                            >
                              <RadioGroup
                                v-if="editable"
                                name="under_18_under_ground"
                                :values="formValues"
                                :items="RADIO_ITEMS_YES_NO"
                                :editable="editable"
                                @onInput="onInput"
                              />
                            </Label>
                            <span class="ml-2" v-if="!editable">{{
                              getRadioName(RADIO_ITEMS_YES_NO, formValues['under_18_under_ground'])
                            }}</span>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="（６）クレーン、デリック、揚貨装置の運転の業務又は玉掛け業務をさせていないか"
                              :editable="editable"
                              class="long_label"
                        :blackColor = true
                            >
                              <RadioGroup
                                v-if="editable"
                                name="under_18_crane_lifting_slinging_work"
                                :values="formValues"
                                :items="RADIO_ITEMS_YES_NO"
                                :editable="editable"
                                @onInput="onInput"
                              />
                            </Label>
                            <span class="ml-2" v-if="!editable">{{
                              getRadioName(RADIO_ITEMS_YES_NO, formValues['under_18_crane_lifting_slinging_work'])
                            }}</span>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="（７）土砂崩壊のおそれのある場所または深さ５m以上の地穴での業務をさせていないか"
                              :editable="editable"
                              class="long_label"
                        :blackColor = true
                            >
                              <RadioGroup
                                v-if="editable"
                                name="under_18_landslide_risk"
                                :values="formValues"
                                :items="RADIO_ITEMS_YES_NO"
                                :editable="editable"
                                @onInput="onInput"
                              />
                            </Label>
                            <span class="ml-2" v-if="!editable">{{
                              getRadioName(RADIO_ITEMS_YES_NO, formValues['under_18_landslide_risk'])
                            }}</span>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="（８）高さ５ｍ以上で墜落のおそれのある高所作業をさせていないか"
                              :editable="editable"
                            >
                              <RadioGroup
                                v-if="editable"
                                name="under_18_high_place_collision_risk"
                                :values="formValues"
                                :items="RADIO_ITEMS_YES_NO"
                                :editable="editable"
                                @onInput="onInput"
                              />
                            </Label>
                            <span class="ml-2" v-if="!editable">{{
                              getRadioName(RADIO_ITEMS_YES_NO, formValues['under_18_high_place_collision_risk'])
                            }}</span>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="（９）足場の組立、解体、変更の業務をさせていないか"
                              :editable="editable"
                            >
                              <RadioGroup
                                v-if="editable"
                                name="under_18_assdemble_scaffold"
                                :values="formValues"
                                :items="RADIO_ITEMS_YES_NO"
                                :editable="editable"
                                @onInput="onInput"
                              />
                            </Label>
                            <span class="ml-2" v-if="!editable">{{
                              getRadioName(RADIO_ITEMS_YES_NO, formValues['under_18_assdemble_scaffold'])
                            }}</span>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="（10）重量物の取扱業務をさせていないか"
                              :editable="editable"
                            >
                              <RadioGroup
                                v-if="editable"
                                name="under_18_handle_heavy_goods"
                                :values="formValues"
                                :items="RADIO_ITEMS_YES_NO"
                                :editable="editable"
                                @onInput="onInput"
                              />
                            </Label>
                            <span class="ml-2" v-if="!editable">{{
                              getRadioName(RADIO_ITEMS_YES_NO, formValues['under_18_handle_heavy_goods'])
                            }}</span>
                          </v-list-item-content>
                        </v-list-item>
                      </Label>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel label="帰郷旅費" />
                      <Label
                        label="満１８歳未満の者について解雇日から１４日以内に帰郷する場合、旅費を負担しているか（労基法６４条）"
                        :editable="editable"
                        class="long_label"
                        :blackColor = true
                      >
                        <RadioGroup
                          v-if="editable"
                          name="under_18_bear_return_home_expenses"
                          :values="formValues"
                          :items="RADIO_ITEMS_YES_NO_IGNORE"
                          :editable="editable"
                          @onInput="onInput"
                        />
                      </Label>
                      <span class="ml-2" v-if="!editable">{{
                        getRadioName(RADIO_ITEMS_YES_NO_IGNORE, formValues['under_18_bear_return_home_expenses'])
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <!-- col 2  -->
                <v-col>
                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel label="女性の就業制限" />
                      <Label
                        label="妊産婦については妊産婦の就業制限業務をさせていないか（労基法６４条の３、女性則２条）"
                        :editable="editable"
                        class="long_label"
                        :blackColor = true
                      >
                        <RadioGroup
                          v-if="editable"
                          name="pregnant_women_not_do_restricted_work"
                          :values="formValues"
                          :items="RADIO_ITEMS_YES_NO_IGNORE"
                          :editable="editable"
                          @onInput="onInput"
                        />
                      </Label>
                      <span class="ml-2" v-if="!editable">{{
                        getRadioName(RADIO_ITEMS_YES_NO_IGNORE, formValues['pregnant_women_not_do_restricted_work'])
                      }}</span>

                      <Label
                        label="妊産婦以外の女性については年少者の就業制限（３）、（５）、（10）の事項を守っているか（労基法６４条の２、女性則３条）"
                        :editable="editable"
                        class="long_label"
                        :blackColor = true
                      /> 
                      <Label
                        label="＊ただし、女性技術者が坑内の管理、監督業務等に従事することを除く。（Ｈ19.4.1施行）"
                        :editable="editable"
                        class="long_label"
                        :blackColor = true
                      /> 
                        <RadioGroup
                          v-if="editable"
                          name="woman_comply_young_age_employment_restriction"
                          :values="formValues"
                          :items="RADIO_ITEMS_YES_NO_IGNORE"
                          :editable="editable"
                          @onInput="onInput"
                        />
                      <span class="ml-2" v-if="!editable">{{
                        getRadioName(RADIO_ITEMS_YES_NO_IGNORE, formValues['woman_comply_young_age_employment_restriction'])
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel label="妊産婦の時間外、休日労働及び深夜業" />
                      <Label
                        label="妊産婦が請求した場合、次のことをさせていないか（労基法第６６条）"
                        :editable="editable"
                        :blackColor = true
                      >
                        <Label
                          label="（１）週４０時間、１日８時間を超えて就業させていないか"
                          :editable="editable"
                          :blackColor = true
                        >
                          <RadioGroup
                            v-if="editable"
                            name="pregnant_women_work_overtime"
                            :values="formValues"
                            :items="RADIO_ITEMS_YES_NO_IGNORE"
                            :editable="editable"
                            @onInput="onInput"
                          />
                          <span class="ml-2" v-if="!editable">{{
                            getRadioName(RADIO_ITEMS_YES_NO_IGNORE, formValues['pregnant_women_work_overtime'])
                          }}</span>
                        </Label>
                        <Label
                          label="（２）時間外、休日労働をさせていないか"
                          :editable="editable"
                          :blackColor = true
                        >
                          <RadioGroup
                            v-if="editable"
                            name="pregnant_women_work_overtime_holiday"
                            :values="formValues"
                            :items="RADIO_ITEMS_YES_NO_IGNORE"
                            :editable="editable"
                            @onInput="onInput"
                          />
                          <span class="ml-2" v-if="!editable">{{
                            getRadioName(RADIO_ITEMS_YES_NO_IGNORE, formValues['pregnant_women_work_overtime_holiday'])
                          }}</span>
                        </Label>
                        <Label
                          label="（３）深夜業をさせていないか"
                          :editable="editable"
                          :blackColor = true
                        >
                          <RadioGroup
                            v-if="editable"
                            name="pregnant_women_work_late"
                            :values="formValues"
                            :items="RADIO_ITEMS_YES_NO_IGNORE"
                            :editable="editable"
                            @onInput="onInput"
                          />
                          <span class="ml-2" v-if="!editable">{{
                            getRadioName(RADIO_ITEMS_YES_NO_IGNORE, formValues['pregnant_women_work_late'])
                          }}</span>
                        </Label>
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  
                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel label="産前産後" />
                      <Label
                        label="産前、産後の女性について違法就業（産前は６週間、但し、多胎妊娠の場合は１４週間、産後は８週間）させていないか（労基法６５条）"
                        :editable="editable"
                        class="long_label"
                        :blackColor = true
                      >
                        <RadioGroup
                          v-if="editable"
                          name="prenatal_postnatal_women_not_work_illegally"
                          :values="formValues"
                          :items="RADIO_ITEMS_YES_NO_IGNORE"
                          :editable="editable"
                          @onInput="onInput"
                        />
                      </Label>
                      <span class="ml-2" v-if="!editable">{{
                        getRadioName(RADIO_ITEMS_YES_NO_IGNORE, formValues['prenatal_postnatal_women_not_work_illegally'])
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel label="育児時間" />
                      <Label
                        label="生後満１年に達しない生児を育てる女性に育児時間（１日２回各々少なくとも３０分）を与えているか（労基法６７条）"
                        :editable="editable"
                        class="long_label"
                        :blackColor = true
                      >
                        <RadioGroup
                          v-if="editable"
                          name="women_have_childcare_time"
                          :values="formValues"
                          :items="RADIO_ITEMS_YES_NO_IGNORE"
                          :editable="editable"
                          @onInput="onInput"
                        />
                      </Label>
                      <span class="ml-2" v-if="!editable">{{
                        getRadioName(RADIO_ITEMS_YES_NO_IGNORE, formValues['women_have_childcare_time'])
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <SectionLabel label="生理休暇" />
                      <Label
                        label="生理日の就業が著しく困難な女性について強制就業させていないか（労基法６８条）"
                        :editable="editable"
                        class="long_label"
                        :blackColor = true
                      >
                        <RadioGroup
                          v-if="editable"
                          name="women_not_work_menstrual_day"
                          :values="formValues"
                          :items="RADIO_ITEMS_YES_NO_IGNORE"
                          :editable="editable"
                          @onInput="onInput"
                        />
                      </Label>
                      <span class="ml-2" v-if="!editable">{{
                        getRadioName(RADIO_ITEMS_YES_NO_IGNORE, formValues['women_not_work_menstrual_day'])
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import RadioGroup from "../elements/RadioGroup.vue";
import {
  RADIO_ITEMS_YES_NO,
  RADIO_ITEMS_YES_NO_IGNORE,
  RADIO_ITEMS_VIOLATE_RESTRICTION_FOR_YOUNG_PEOPLE,
  DEFAULT_RADIO_YES_NO,
  VIOLATE_RESTRICTION_FOR_YOUNG_PEOPLE_APPLY
} from "@/constants/COMPANY_PORTAL";

export default {
  data: () => {
    return {
      formValues: {},
      RADIO_ITEMS_YES_NO,
      RADIO_ITEMS_YES_NO_IGNORE,
      RADIO_ITEMS_VIOLATE_RESTRICTION_FOR_YOUNG_PEOPLE,
      DEFAULT_RADIO_YES_NO,
      VIOLATE_RESTRICTION_FOR_YOUNG_PEOPLE_APPLY
    };
  },
  components: {
    Label,
    SectionLabel,
    InputText,
    RadioGroup,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    errors: {
      type: Object,
      default: {},
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (data) => {
        this.formValues = {...data};
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      const headerHeight = 48;
      let style = `height:${this.mainHeight - headerHeight}px;`;
      return style;
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      if (name == 'violate_restriction_for_young_people'){
        formValues['under_18_use_electricity_devices'] = DEFAULT_RADIO_YES_NO;
        formValues['under_18_handling_dangerous_substances'] = DEFAULT_RADIO_YES_NO;
        formValues['under_18_working_place_emit_harmful_gas_or_dust'] = DEFAULT_RADIO_YES_NO;
        formValues['under_18_abnormal_atmosphere'] = DEFAULT_RADIO_YES_NO;
        formValues['under_18_under_ground'] = DEFAULT_RADIO_YES_NO;
        formValues['under_18_crane_lifting_slinging_work'] = DEFAULT_RADIO_YES_NO;
        formValues['under_18_landslide_risk'] = DEFAULT_RADIO_YES_NO;
        formValues['under_18_high_place_collision_risk'] = DEFAULT_RADIO_YES_NO;
        formValues['under_18_assdemble_scaffold'] = DEFAULT_RADIO_YES_NO;
        formValues['under_18_handle_heavy_goods'] = DEFAULT_RADIO_YES_NO;
      }
      formValues[name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", {"company_employment_restrictions": formValues});
    },
    getRadioName(items, value) {
      return items.find((item) => item.value == value)?.name;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";
.long_label{
  max-width: 55%;
};
.custom_label{
  max-width: 42%;
}
</style>
