<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row no-gutters>
          <v-col cols="12" sm="5" md="5">
            <v-list-item>
              <v-list-item-content>
                <Label label="技能検定職種" :editable="editable">
                  <Select
                    name="catergory_id"
                    :values="item"
                    :items="CATERGORY_ARRAY[index]"
                    :editable="editable"
                    @onInput="onInput({ value: $event, index })"
                    validation_label="技能検定職種"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="5" md="5">
            <v-list-item>
              <v-list-item-content>
                <Label label="免許・資格名" :editable="editable">
                  <SelectSearch
                    name="cmn_mst_licence_id"
                    :values="item"
                    :items="getSubCategories(item)"
                    :editable="editable"
                    @onInput="onInput({ value: $event, index })"
                    title="免許・資格名選択"
                    title_search="免許・資格名"
                    validation_label="免許・資格名"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col
            v-if="index > 0 && editable"
            cols="12"
            sm="2"
            md="2"
            class="pt-0 pb-0"
          >
            <v-btn class="mt-8" icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn v-if="editable" color="primary" @click="addEmpty">追加</v-btn>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "../../elements/Label.vue";
import Select from "../../elements/Select.vue";
import { Store } from "@/store/Store.js";
import SelectSearch from "@/components/forms/elements/SelectSearch";
//初期値を設定します
const INITIAL_VALUES = {
  id: null,
  catergory_id: null,
  cmn_mst_licence_id: null,
};

export default {
  data: () => {
    return {
      items: [],
      DEVICE_LISENCE: Store.state.CmnMst.constants.entries.cmn_mst_licences,
      dataCatergory :[],
      CATERGORY_ARRAY : []
    };
  },
  components: {
    SelectSearch,
    Label,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
  },
  mounted() {
    this.getCatergories();
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (this.items?.length == 0) {
          this.addEmpty();
        } else if (this.items != null) {
          this.CATERGORY_ARRAY = [];
          this.items.forEach((_item,index) => {
            if (_item.cmn_mst_licence_id) {
              let sub_name = this.DEVICE_LISENCE.find(e => e.id == _item.cmn_mst_licence_id)?.sub_catergory;
              let temp = this.DEVICE_LISENCE.filter(e => e.sub_catergory == sub_name);
              _item.catergory_id = temp.find(e => e.id == _item.cmn_mst_licence_id)?.catergory_id;
              this.CATERGORY_ARRAY[index] = [{ id: null, name: "" }];
              temp.forEach(e => {
                this.CATERGORY_ARRAY[index].push({ id: e.catergory_id, name: e.catergory });
              });
            }else{
              this.CATERGORY_ARRAY[index] = this.dataCatergory;
            }
          });
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      if (value.name == "catergory_id") {
        if(!value.value) {
          _items[index].cmn_mst_licence_id = null;
        }
        if(_items[index].cmn_mst_licence_id){
          let sub_name = this.DEVICE_LISENCE.find(e => e.id == _items[index].cmn_mst_licence_id)?.sub_catergory;
          _items[index].cmn_mst_licence_id = this.DEVICE_LISENCE.find(e => e.sub_catergory == sub_name && e.catergory_id == value.value)?.id;
        }
      }
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      this.items.push({ ...INITIAL_VALUES });
    },
    getCatergories() {
      let rs = [];
      let temp = "";
      this.DEVICE_LISENCE.forEach((element) => {
        if (temp != element.catergory_id) {
          rs.push({ id: element.catergory_id, name: element.catergory });
          temp = element.catergory_id;
        }
      });
      rs.unshift({ id: null, name: "" });
      this.dataCatergory = [...rs];
    },
    getSubCategories(item) {
      let subCategories = [];
      if(item.catergory_id) {
        this.DEVICE_LISENCE.forEach((element) => {
          if (item.catergory_id == element.catergory_id) {
            subCategories.push({ id: element.id, name: element.sub_catergory });
          }
        });
      } else {
        this.DEVICE_LISENCE.forEach((element) => {
          subCategories.push({ id: element.id, name: element.sub_catergory });
        });
      }
      subCategories.unshift({ id: null, name: "" });
      subCategories = this.getUniqueData(subCategories);
      return subCategories;
    },
    getUniqueData(arr){
      let rs = [];
      let temp = [];
      arr.forEach(e => {
        if(!temp.includes(e.name)){
          rs.push(e);
          temp.push((e.name));
        }
      })
      return rs;
    },
  },
};
</script>
