<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row>
          <v-col cols="12" sm="11" md="11">
            <v-list-item>
              <v-list-item-content class="row-position">
                <Label label="氏名" :editable="editable">
                  <Select
                    class="select-position"
                    :name="textId"
                    :values="item"
                    :items="userArray"
                    item_value="user_id"
                    :label="item[textName]"
                    :editable="editable"
                    @onInput="onInput({ index, value: $event })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col v-if="index > 0 && editable" cols="12" sm="1" md="1">
            <v-btn class="mt-16" icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn
      v-if="editable"
      class="ml-5"
      color="primary"
      @click="addEmpty"
      depressed
      small
    >追加
    </v-btn
    >
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */
import Label from "@/components/forms/elements/Label.vue";
import Select from "@/components/forms/elements/Select.vue";


export default {
  data: () => {
    return {
      items: [],
    };
  },
  components: {
    Label,
    Select
  },
  props: {
    name: {
      type: String
    },
    values: {
      type: Object
    },
    editable: {
      type: Boolean
    },
    textId: {
      type: String
    },
    textName: {
      type: String
    },
    userArray:{
      type : Array
    }
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (!this.items || this.items?.length == 0) {
          this.addEmpty();
        }
      },
      {
        deep: true
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      let initialValues = this.textId == "checkup_user_id" ? { checkup_user_id: null } : { handle_user_id: null };
      const items = [...this.items];
      items.push({ ...initialValues });
      this.$emit("onInput", { name: this.name, value: items });
    },
  }
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";

.row-position {
  margin-top: -30px;
  height: 150px;
  position: relative;
}

.select-position {
  width: 100%;
  position: absolute;
}
</style>
