<template>
  <v-container>
    <ValidationProvider
      v-slot="{ errors, valid }"
      :vid="id"
      :name="validation_label"
      :rules="validation_rules"
    >
      <v-select
        v-model="selectedManchine"
        :items="items"
        :item-text="item_text"
        :item-value="id"
        :error-messages="errors"
        :success="valid"
        chips
        label=""
        class="btn-checkbox select-multi-reports"
        multiple
        :noDataText="NO_DATA_MESSAGE"
      >
        <template v-slot:prepend-item>
          <v-list-item
            ripple
            @mousedown.prevent
            @click="toggle"
          >
            <v-list-item-action>
              <v-icon :color="selectedManchine.length > 0 ? 'indigo darken-4' : ''">
                {{ icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                全て選択
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
        <template #selection="{ item }">
          <v-chip small color="primary">{{ item.name }} {{ item.crane_name }}</v-chip>
        </template>
      </v-select>
    </ValidationProvider>
  </v-container>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
  export default {
    data: () => ({
      id: 'id',
      selectedManchine: [],
      NO_DATA_MESSAGE,
    }),
    components: {
      ValidationProvider,
    },
    props: {
      name: {
        type: String,
      },
      items: {
        type: Array
      },
      validation_rules: {
        type: String,
        default: "",
      },
      validation_label: {
        type: String,
        default: "",
      },
      item_text: {
        type: String,
      },
    },
    mounted() {
      this.$watch(
        () => this.selectedManchine,
        (newValue) => {
          this.$emit("onInput", { 
            name: this.name,
            value: newValue
          });
        },
        { 
          immediate: true, 
          deep: true 
        }
      );
    },
    computed: {
      checkNull() {
        return this.selectedManchine.length === this.items.length;
      },
      checkSome() {
        return this.selectedManchine.length > 0 && !this.checkNull;
      },
      icon() {
        if (this.checkNull) {
          return 'mdi-close-box';
        }
        if (this.checkSome) {
          return 'mdi-minus-box';
        }
        return 'mdi-checkbox-blank-outline';
      },
    },

    methods: {
      toggle() {
        this.$nextTick(() => {
          if (this.checkNull) {
            this.selectedManchine = [];
          } else {
            this.selectedManchine = this.items.slice();
            this.selectedManchine = this.selectedManchine.map(k => { return k.id});
          }
        })
      },
      updateDataSelected(value) {
        this.selectedManchine = [...value]
      }
    },
  }
</script>
<style>
  .select-multi-reports .v-input__slot {
    background: rgba(0, 0, 0, 0.06);
    padding: 0 12px;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }
  .select-multi-reports .v-select__slot .v-input__append-inner {
    margin-top: 9px !important;
  }
</style>

<style scoped>
::v-deep .select-multi-reports .v-select__slot {
  height: unset !important;
}
</style>
