const defaultOptions = {
    format: 'image/png',
    quality: 0.92,
    width: undefined,
    height: undefined,
    Canvas: undefined,
    crossOrigin: undefined
};

// Return Promise
const joinImages = function (sources, options) {
    if (sources === void 0) sources = [];
    if (options === void 0) options = {};

    return new Promise(function (resolve) {
        options = Object.assign({}, defaultOptions, options);

        // Setup browser/Node.js specific variables
        var canvas = options.Canvas ? new options.Canvas() : window.document.createElement('canvas');
        var Image = options.Image || window.Image;

        // Load sources
        var images = sources.map(function (source, index) {
            return new Promise(function (resolve, reject) {
                if (!source) return;
                // Convert sources to objects
                if (source.constructor.name !== 'Object') {
                    source = {src: source};
                }

                // Resolve source and img when loaded
                var img = new Image();
                img.crossOrigin = options.crossOrigin;
                img.onerror = function () {
                    return reject(new Error('Couldn\'t load image'));
                };
                img.onload = function () {
                    return resolve(Object.assign({}, source, {img: img}));
                };
                img.src = source.src;
                img.index = index + 1;
            });
        });

        // Get canvas context
        var ctx = canvas.getContext('2d');

        // When sources have loaded
        resolve(Promise.all(images)
            .then(function (images) {
                // Set canvas dimensions
                var getSize = function (dim) {
                    return options[dim] || Math.max.apply(Math, images.map(function (image) {
                        if (!image) return;
                        return image.img[dim];
                    }));
                };
                canvas.width = getSize('width');

                var canvasHeight = 0;
                images.forEach(function (image) {
                    if (!image) return;
                    canvasHeight += image.img['height'];
                });
                canvas.height = canvasHeight > 65535 ? 65535 : canvasHeight;

                // Draw images to canvas
                var pageHeight = 0;
                images.forEach(function (image) {
                    if (!image) return;
                    ctx.globalAlpha = image.opacity ? image.opacity : 1;
                    ctx.drawImage(image.img, 0, pageHeight || 0);
                    pageHeight = image.img['height'] * image.img.index;
                });

                if (options.Canvas && options.format === 'image/jpeg') {
                    // Resolve data URI for node-canvas jpeg async
                    return new Promise(function (resolve, reject) {
                        canvas.toDataURL(options.format, {
                            quality: options.quality,
                            progressive: false
                        }, function (err, jpeg) {
                            if (err) {
                                reject(err);
                                return;
                            }
                            resolve(jpeg);
                        });
                    });
                }

                // Resolve all other data URIs sync
                return canvas.toDataURL(options.format, options.quality);
            }));
    });
};
export { joinImages };