<template>
  <div class="wrapper">
    <wrapperWeb
      :back="back"
      :goUsers="goUsers"
      :title="roomTitle"
      :chat_room_id="chat_room_id"
    >
      <ChatView :chat_room_id="chat_room_id" :userId="user_id" />
    </wrapperWeb>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import ChatView from "../chat/index.vue";
// web用wrapperは全画面表示する
import wrapperWeb from "./components/web/index.vue";
export default {
  data: () => {
    return {
      chat_room_id: null,
      user_id: null,
      room: {},
    };
  },
  components: {
    ChatView,
    wrapperWeb,
  },
  computed: {
    roomTitle() {
      return this.room?.name;
    },
  },
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    this.chat_room_id = +this.$route.params.id;

    // 自分のidを取得
    this.$watch(
      () => Store.getters["Login/getUser"],
      (newValue) => {
        if (newValue && "id" in newValue) {
          this.user_id = newValue.id;
          this.init();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    // ルーム取得
    this.$watch(
      () => Store.getters["TextChat/getRooms"],
      (newValue) => {
        if ("chatrooms" in newValue && newValue.chatrooms.length > 0) {
          const rooms = newValue.chatrooms;
          this.room = rooms.find((room) => {
            return room.id == this.chat_room_id;
          });
        }
      },
      { immediate: true, deep: true }
    );
  },
  methods: {
    async init() {
      // ルーム一覧取得
      await Store.dispatch("TextChat/rooms", {
        user_id: this.user_id || 1,
      });
    },
    back() {
      const isDrawingApp = Store.state.Login.isDrawingApp;
      // お絵描きアプリ
      if (isDrawingApp) {
        //unityへ
        window.open("uniwebview://action?func=close");
      } else {
        this.$router.push("/room");
      }
    },
    goUsers() {
      this.$router.push(`/chat/${this.chat_room_id}/users`);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  position: relative;
  height: 100%;
}
</style>
