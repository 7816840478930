<template>
  <v-container>
    <!--
      ロジックを共通にしてtemplate部分だけを
      PCとSPのコンポーネントに切り出す
    -->
    <!-- PC用のテーブル -->
    <WorksDataTableOba
      v-if="!$sp"
      :tableLabels="tableLabels"
      :items="items"
      :flagNotData="flagNotData"
      :items-per-page="itemsPerPage"
      :tableHeight="tableHeight"
      :isTableTypeAll="isTableTypeAll"
      :isSelected="isSelected"
      :isSelectedParent="isSelectedParent"
      :isSelectedAllParent="isSelectedAllParent"
      :isShowChildren="isShowChildren"
      :updateCheckbox="updateCheckbox"
      :updateAllCheckbox ="updateAllCheckbox"
      :toggleShowChildren="toggleShowChildren"
      :childTableDisplayMoreNumber="childTableDisplayMoreNumber"
      :approveStatus="approveStatus"
      :SCHEDULE_ATTENDANCE="SCHEDULE_ATTENDANCE"
      :SCHEDULE_WARNING="SCHEDULE_WARNING"
      :isDisableCheckBox="isDisableCheckBox"
      :NO_DATA_MESSAGE="NO_DATA_MESSAGE"
      @openItemForm="openItemForm"
      @getChildItems="getChildItems"
      @addNewChild="addNewChild"
      @openPopupRatify="openPopupRatify"
      @updateItems="updateItems"
      :statusSearchItemTree="statusSearchItemTree"
      :paramsGetList="paramsGetList"
      :itemSelectedScroll="itemSelectedScroll"
      :listDeleteScroll="listDeleteScroll"
    />
    <!-- SP用のテーブル -->
    <SpWorksDataTable
      v-if="$sp"
      :tableLabels="tableLabels"
      :items="items"
      :flagNotData="flagNotData"
      :items-per-page="itemsPerPage"
      :tableHeight="tableHeight"
      :isTableTypeAll="isTableTypeAll"
      :isSelected="isSelected"
      :isSelectedParent="isSelectedParent"
      :isSelectedAllParent="isSelectedAllParent"
      :isShowChildren="isShowChildren"
      :updateCheckbox="updateCheckbox"
      :updateAllCheckbox="updateAllCheckbox"
      :toggleShowChildren="toggleShowChildren"
      :childTableDisplayMoreNumber="childTableDisplayMoreNumber"
      :approveStatus="approveStatus"
      :SCHEDULE_ATTENDANCE="SCHEDULE_ATTENDANCE"
      :SCHEDULE_WARNING="SCHEDULE_WARNING"
      :NO_DATA_MESSAGE="NO_DATA_MESSAGE"
      @openItemForm="openItemForm"
      @getChildItems="getChildItems"
      @addNewChild="addNewChild"
    />
  </v-container>
</template>
<script>
import {
  SCHDULE_APPROVE,
  SCHEDULE_WARNING,
  SCHEDULE_ATTENDANCE,
} from "@/constants/SCHEDULE_WORKS";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import WorksDataTableOba from "./WorksDataTableOba.vue";
import SpWorksDataTable from "./SpWorksDataTable.vue";

const APPROVE_CLASSES = [
  { id: 1, class: "primary" },
  { id: 0, class: "amber darken-2" },
];

export default {
  data: () => {
    return {
      // child table 表示ステート
      isShowChildren: {},
      selectedParent: [],
      approveStatus: [],
      SCHEDULE_WARNING: SCHEDULE_WARNING,
      SCHEDULE_ATTENDANCE: SCHEDULE_ATTENDANCE,
      NO_DATA_MESSAGE,
    };
  },
  components: {
    WorksDataTableOba,
    SpWorksDataTable,
  },
  props: {
    pageKey: String,

    // ヘッダー
    tableLabels: Array,

    // parent テーブルの高さ
    tableHeight: Number,

    // テーブル表示item
    items: Array,

    // 1ページあたりのitem数
    itemsPerPage: Number,

    // checkboxの更新
    update: Function,

    // checkboxの値
    isSelected: Function,

    // 作業予定データを開く
    openItemForm: Function,

    // child テーブルのデータをAPI取得
    getChildItems: Function,

    // 親にchild table追加
    addNewChild: Function,

    // テーブル全体判定
    isTableTypeAll: Boolean,

    checkSelectedParents: Function,

    flagNotData: Boolean,

    uncheckAll: Array,

    isDisableCheckBox: Boolean,

    statusSearchItemTree: {
      type: Boolean,
      default: false
    },
    paramsGetList: Object,
    itemSelectedScroll: Array,
    listDeleteScroll: Array,
  },

  async mounted() {
    this.approveStatus = this.embedClass(SCHDULE_APPROVE, APPROVE_CLASSES);

    this.$watch(
      // items propsを1度だけwatchして、child tableの表示ステートをinit
      () => this.items,
      (newValue) => {
        if(newValue) {
          const items = newValue;
          this.initIsShowChildren(items);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.uncheckAll,
      () => {
          this.updateAllCheckbox(this.items, false);
      }
    );
  },

  methods: {
    // さらに表示できるchild table数
    childTableDisplayMoreNumber(totalNum, displayNum) {
      // 親が持つすべてのchild table数から表示されている数をひく
      const _number = totalNum - 1 - displayNum;
      return _number > 0 ? _number : "0";
    },

    updateSelectedParentItems(id, isChecked) {
      let _selectedItems = [];
      // checked
      if (isChecked) {
        _selectedItems = [...this.selectedParent, id];
      } else {
        // unchecked
        _selectedItems = this.selectedParent.filter((item) => {
          return item != id;
        });
      }
      // 重複削除してset
      this.selectedParent = [...new Set(_selectedItems)];
    },

    isSelectedParent(id) {
      return this.selectedParent.includes(id);
    },

    isSelectedAllParent(items) {
      return this.selectedParent.length == items.length;
    },

    embedClass(objMain, objClass) {
      return Object.keys(objMain).map((key) => {
        const className = objClass.find((item) => item.id === objMain[key].id);
        return { ...objMain[key], ...className };
      });
    },

    updateCheckbox(item, isChecked, isParent, itemParent ) {
      // parentがchecked
      if (isParent && item.children) {
        this.updateSelectedParentItems(item.work_plan_id, isChecked);

        // childrenにもcheck入れる
        const childrendIds = item.children.map((child) => child.work_plan_id);
        const ids = [item.work_plan_id, ...childrendIds];
        ids.forEach((id) => {
          this.$emit("update", id, isChecked);
        });
      } else {
        // childがchecked
        // 自身のみにcheckを入れる
        this.$emit("update", item.work_plan_id, isChecked);
        if (itemParent && isChecked && this.checkSelectedParents(itemParent)) {
          this.selectedParent.push(itemParent.work_plan_id);
        } else {
          this.selectedParent = this.selectedParent.filter((item) => {
            return item != itemParent.work_plan_id;
          });
        }
      }
    },

    updateAllCheckbox(items, event, isParent = true, itemParent = null) {
        items.forEach((item) => {
          this.updateCheckbox(item, event, isParent, itemParent)
        });
    },

    // parent table ごとの child tableの表示ステートをinit
    initIsShowChildren(items) {
      let _isShowChildren = {};
      items.forEach((item) => {
        _isShowChildren[item.work_plan_id] = true;
      });
      this.isShowChildren = _isShowChildren;
    },

    // child tableの表示切り替え
    toggleShowChildren(id) {
      this.isShowChildren[id] = !this.isShowChildren[id];
    },
    convertData(name, maxLength) {
      let result = "";
      if (name) {
        result = name;
        if (name.length > maxLength) {
          result = name.substring(0, maxLength) + "•••";
        }
      }
      return result;
    },
    openPopupRatify(value){
      this.$emit('openPopupRatify', value);
    },
    updateItems (value) {
      this.$emit('updateItems', value)
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
// ヘッダーとテーブルコンテンツのたて位置を合わせるために
// show-selectオプションをONにした状態でcheckboxを非表示
::v-deep .v-data-table-header {
  .v-input--selection-controls__input {
    display: none !important;
  }
}

td.start div.row {
  width: 75px !important;
}
td.col-status {
  width: 110px !important;
}
.child-table {
  td.start {
    padding-right: 0;
  }
}

.schedule_nodata {
  position: absolute;
  left: 50%;
}

button.add-new-child,
button.show-more {
  height: 20px !important;
}

.display-more-number ::v-deep .v-badge__badge {
  font-size: 10px;
}

.display-more-number ::v-deep .v-badge__badge {
  padding: 1px;
  height: 14px;
}
div.circle-red {
  background-color: $color_warning_dark_red;
}
div.circle-blue {
  background-color: $color_warning_blue;
}
div.warning-icon {
  display: inline-flex;
  justify-content: center;
  border-radius: 50%;
  font-size: 10px;
  color: white;
  width: 25px;
  height: 25px;
  padding-top: 4px;
  margin: 3px;
}
</style>
