<template>
  <div>
    <SingleLayout>
      <div class="login-header">GREEN</div>
      <ValidationObserver v-slot="{ invalid }">
        <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
          <v-list-item>
            <v-list-item-content>
              <ValidationProvider
                v-slot="{ errors, valid }"
                name="メール"
                rules="required|email"
              >
                <v-text-field
                  v-model="formValue.login_id"
                  :error-messages="errors"
                  :success="valid"
                  autofocus
                  dense
                  height="48px"
                  @keydown.enter="invalid ? null : Login()"
                  placeholder="hoge@hoge.com"
                ></v-text-field>
              </ValidationProvider>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <ValidationProvider
                v-slot="{ errors }"
                name="パスワード"
                rules="required|password"
              >
                <v-text-field
                  v-model="formValue.password"
                  :error-messages="errors"
                  :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="passwordShow ? 'text' : 'password'"
                  dense
                  height="48px"
                  placeholder="password"
                  @keydown.enter="invalid ? null : Login()"
                  @click:append="passwordShow = !passwordShow"
                ></v-text-field>
              </ValidationProvider>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-btn
                height="48px"
                color="primary"
                depressed
                :disabled="invalid"
                @click="Login()"
                >ログイン</v-btn
              >
            </v-list-item-content>
          </v-list-item>
          <div class="links">
            <v-btn
              color="primary"
              text
              @click="isShowResetPasswordDialog = true"
            >
              パスワードを忘れた場合はこちら</v-btn
            >
          </div>
          <div style="visibility: hidden">v1.0.2</div>
        </v-form>
      </ValidationObserver>
    </SingleLayout>
    <!-- メールリセット -->
    <Popup width="374px" :dialog="isShowResetPasswordDialog">
      <ResetMail @close="isShowResetPasswordDialog = false" />
    </Popup>
    <Popup width="640px" :dialog="isShowCompanyInitDialog">
      <CompanyInitForm
        :flagError="flagError"
        :item="edittedItem"
        @onSubmitCompany="onSubmitCompany"
        @close="isShowCompanyInitDialog = false"
        @closeFormInit="closeFormInit"
        @updateForm="updateForm"
        ref="CompanyInitForm"
      />
    </Popup>
    <Popup width="640px" :dialog="isShowSearch">
      <SearchFirstLogin
        title="会社選択"
        :formValues="formValueCompany"
        :isNoDataMessage="isNoDataMessage"
        @onSearch="onSearchCompany"
        @close="closePopupSearch"
        @formUpdate="formUpdate"
      />
    </Popup>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SingleLayout from "@/components/layout/SingleLayout.vue";
import ResetMail from "./resetMail/index.vue";
import CompanyInitForm from "@/components/forms/companies/CompaniesForm.vue";
import SearchFirstLogin from "./searchFirstLogin/index.vue";
import Popup from "@/components/common/Popup.vue";
import { LOGIN_LINKS, LINK_TYPE } from "@/router/ROUTING_URL.js";
import { COMPANIES_INITAL_ITEM } from "@/constants/COMPANY_PORTAL";

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: "ログイン" };
    },
  },
  components: {
    SingleLayout,
    ValidationProvider,
    ValidationObserver,
    ResetMail,
    CompanyInitForm,
    SearchFirstLogin,
    Popup,
  },
  data: () => {
    return {
      formValue: {
        login_id: "",
        password: "",
      },
      passwordShow: false,
      //パスワードリセット
      isShowResetPasswordDialog: false,
      //初回自社情報登録フォーム
      isShowCompanyInitDialog: false,
      isShowSearch: false,
      formValueCompany: {
        // 検索ワード
        keyword: "",
        // 表示するリスト
        items: [],
        // 選択項目id
        selected: null,
      },
      flagError: false,
      edittedItem: { ...COMPANIES_INITAL_ITEM },
      isNoDataMessage: false,
    };
  },
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    // console.log("login", LINK_TYPE, LOGIN_LINKS[LINK_TYPE].LOGIN_SUCCESS);
    this.$watch(
      () => Store.getters[`CompanyPortal/getListCompanyAll`],
      (data) => {
        const companyList = [...data];
        this.formValueCompany.items = companyList;
        this.isNoDataMessage = this.formValueCompany.items?.length == 0;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    closePopupSearch(isShowCompany) {
      this.isShowSearch = false;
      if (isShowCompany) {
        this.isShowCompanyInitDialog = true;
      } else {
        this.formValueCompany.keyword = "";
        Store.dispatch("CompanyPortal/clearListCompany");
      }
    },
    closeFormInit() {
      this.isShowCompanyInitDialog = false;
      this.isShowSearch = true;
    },

    async formUpdate(value) {
      const addUserResponse = await Store.dispatch(
        `CompanyPortal/getAddUserToCompany`,
        value
      );
      if (addUserResponse.hasError) {
        // 再ログイン失敗
        return;
      }
      const loginResponse = await Store.dispatch("Login/loginReacqire");
      if (!loginResponse.hasError) {
        this.$router.push(LOGIN_LINKS[LINK_TYPE].LOGIN_SUCCESS);
        this.isShowSearch = false;
      }
    },
    /**
     *
     */
    onChangeEmail() {},
    /**
     * ログイン
     */
    async Login() {
      const res = await Store.dispatch("Login/login", this.formValue);
      if ("result" in res && res.result) {
        const { company } = res.contents.entries.user;
        if (company) {
          // 会社ある場合は、自社ポータル開く
          window.location.reload(true);
          this.$router.push(LOGIN_LINKS[LINK_TYPE].LOGIN_SUCCESS);
        } else {
          // 会社情報がない場合は、会社情報フォームを開く
          this.isShowSearch = true;
        }
      } else {
        Store.dispatch("Error/show", {
          status: 200,
          message: res.message || "ログインできませんでした",
        });
      }
    },
    /**
     * 初回の自社情報登録
     */
    async onSubmitCompany(formValues) {
      // TODO api 初回自社情報の登録
      const result = await Store.dispatch(
        `CompanyPortal/initialize`,
        formValues
      );
      if (result.hasError) {
        this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.message,
        });
        return;
      } else {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
        // ストアに登録
        const company = result.data.contents.entries;
        Store.dispatch("Login/setCompany", company);
        const response = await Store.dispatch("Login/loginReacqire");
        if (response.hasError) {
          // 再ログイン失敗
          return;
        }
        // 自社ポータル開く
        //TOTO ストアでcompany_idを保持
        this.$router.push(LOGIN_LINKS[LINK_TYPE].LOGIN_SUCCESS);
      }
    },
    async onSearchCompany(obj) {
      const params = {
        company_name: this.formValueCompany.keyword,
        current_page: this.formValueCompany.current_page,
      };

      if (obj?.isLoadMore) {
        this.formValueCompany["current_page"] =
          this.formValueCompany["current_page"] + 1;
        await Store.dispatch("CompanyPortal/getListCompanyAll", params);
      } else {
        if (
          params.company_name &&
          params.company_name.trim() !== "" &&
          params.company_name.length <= 255
        ) {
          Store.dispatch("CompanyPortal/clearListCompany");
          this.formValueCompany["current_page"] = 1;
          await Store.dispatch("CompanyPortal/getListCompanyAll", params);
        }
      }
    },
    updateForm(data) {
      this.edittedItem = { ...data };
    },
  },
};
</script>
<style lang="scss" scoped>
.login-header {
  font-size: 24px;
  font-weight: bold;
  padding: 16px;
  text-align: center;
  color: green;
}

.links {
  padding: 8px;
  text-align: center;
}
</style>
