<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <SitePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout
          :layoutParams="layoutParams"
          :hideFooter="searchParams.page_count >= searchParams.total_item"
        >
          <template #tableHeader="{ updateHeader }">
            <EntranceSummaryHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :updateHeader="updateHeader"
              @onInput="onChangeSearchParams"
              @openComfirmPrint="openComfirmPrint"
            >
              <v-row>
                <v-col sm="11" md="11">
                  <SearchFormWrapper>
                    <div class="mt-5 ml-4">
                      <TabSelect
                        name="summary_type"
                        :items="SORT_TIME"
                        :editable="true"
                        :values="searchParams"
                        @onInput="onChangeSearchParams"
                      />
                    </div>
                    <div class="inputDatepicker ml-5 mt-5">
                      <InputDatepicker
                        name="summary_date"
                        :editable="true"
                        :values="searchParams"
                        @onInput="onChangeSearchParams"
                      />
                    </div>
                  </SearchFormWrapper>
                </v-col>
                <v-col sm="1" md="1" class="text-right">
                  <v-btn
                    class="mr-6 ma-2"
                    depressed
                    color="primary"
                    @click="onSearch()"
                  >
                    検索
                  </v-btn>
                </v-col>
              </v-row>
            </EntranceSummaryHeader>
            <v-container class="tableSortWrapper">
              <v-spacer></v-spacer>
              <div class="sortLabel">総件数:</div>
              <div class="sortElement total_item">
                {{ searchParams.all_record }}件
              </div>
            </v-container>
          </template>
          <template #tableBody="{ tableHeight }">
            <SummaryTable
              :tableLabels="tableLabels"
              :tableHeight="tableHeight"
              :items="items"
              :isNoDataMessage="isNoDataMessage"
              :itemsPerPage="searchParams.page_count"
              :getChildItems="getChildItems"
            />
          </template>
          <template #tableFooter>
            <Pagination
              :current="searchParams.current_page"
              :total="searchParams.total_page"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>
    <Popup width="480px" :dialog="isShowConfirmDialog">
      <ConfirmPrintDialog
        title="出面表出力"
        @close="closePrintDialog()"
        @downloadFile="onSubmit"
        :flag="flag"
      />
    </Popup>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import Pagination from "@/components/masterTable/elements/Pagination";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import EntranceSummaryHeader from "./components/EntranceSummaryHeader";
import SummaryTable from "./components/SummaryTable.vue";
import { HEADER_MENU_ITEMS_SITE } from "@/constants/GLOBALHEADER";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TabSelect from "./components/TabSelect";
import InputDatepicker from "@/components/forms/elements/InputDatepicker";
import _ from "lodash";
import { format } from "date-fns";
import ConfirmPrintDialog from "./components/ConfirmPrintDialog.vue";
import Popup from "@/components/common/Popup";
import { SAFETY_DOCUMENT_TYPE, USER_ROLE } from "@/constants/SAFETY_FORM_PRINT";
import { ROLE_SUPERVISOR, ROLE_FOREMAN} from "@/constants/COMMON.js";

const URL_HEADER = `${process.env.VUE_APP_API_BASE_URL}/entrance_histories/entrance_report`;

const PAGE_TITLE = "集計";

const PAGE_COUNT = 3;

const STORE = "EntranceSummary";

const FLAG_PARTNER = 1;

const SORT_TIME = {
  WEEK: { id: 0, name: "週" },
  MONTH: { id: 1, name: "月" },
};

const TABLE_LABELS = [
  {
    text: "",
    value: "",
    align: "left",
    width: "20%",
  },
];

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      tableLabels: [],
      field_id: null,
      PAGE_TITLE,
      items: [],
      searchParams: {
        field_tree_id: null,
        summary_type: SORT_TIME.WEEK.id,
        page_count: PAGE_COUNT,
        summary_date: format(new Date(), "yyyy/MM/dd"),
        current_page: 1,
        total_page: 1,
        total_item: 0,
        all_record: 0,
      },
      isShowConfirmDialog: false,
      file_name_pdf: "Entrance_Report",
      file_name_excel: "出面表",
      is_partner_flag: 0,
      flag : false,
      isNoDataMessage: false,
    };
  },

  components: {
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    Pagination,
    SitePortalHeader,
    EntranceSummaryHeader,
    SummaryTable,
    SearchFormWrapper,
    TabSelect,
    InputDatepicker,
    ConfirmPrintDialog,
    Popup,
  },

  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    await Store.dispatch("GlobalHeader/setSiteMenu", {
      menuId: HEADER_MENU_ITEMS_SITE.ENTRANCE.id,
    });
    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      (data, oldData) => {
        if (data && JSON.stringify(data) !== JSON.stringify(oldData) && data.field_id) {
          this.field_id = data.field_id;
          this.checkRoleUser(this.field_id);
          this.getItems();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["Sites/getRole"],
      (data) => {
        if (data && data !== ROLE_SUPERVISOR && data !== ROLE_FOREMAN) {
          Store.dispatch("Error/show", {
            status: 401,
            message: "操作する権限が存在しません。",
          });
          this.$router.push("/portal/dashboard");
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => Store.getters["Report/getUserRole"],
      (data) => {
        if (data.role == USER_ROLE.PARTNERCOMPANY.id) this.is_partner_flag = FLAG_PARTNER;
      },
      {
        deep: true,
      }
    );

    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        this.getTableLabels(data[0]);
        let searchParams = { ...this.searchParams };
        searchParams.total_page = data[1].total;
        searchParams.current_page = parseInt(data[1].current);
        searchParams.total_item = data[1].total_item;
        searchParams.all_record = data[1].all_record;
        this.searchParams = { ...searchParams };
        if(data[1].total_item > 0){
          this.items = data[0];
        }else{
          this.items = [];
        }
        this.isNoDataMessage = this.items.length == 0;
      },
      {
        deep: true,
      }
    );
    this.$watch(
      () => [Store.getters["File/getData"], Store.getters["File/getTypeDoc"]],
      (data) => {
        if (data && data[0] && data[1]) {
          const url = window.URL.createObjectURL(new Blob([data[0]]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${
              data[1] == SAFETY_DOCUMENT_TYPE.PDF
                ? this.file_name_pdf
                : this.file_name_excel
            }_${format(new Date(this.searchParams.summary_date), "yyyyMMdd")}.${
              data[1] == SAFETY_DOCUMENT_TYPE.PDF ? "pdf" : "xlsx"
            }`
          );
          document.body.appendChild(link);
          link.click();
          Store.dispatch("File/clearData");
        }
      },
      {
        deep: true,
      }
    );
  },

  computed: {
    /**
     * API Param
     */
    apiParams() {
      return {
        field_id: this.field_id,
        summary_type: this.searchParams.summary_type,
        summary_date: this.searchParams.summary_date,
        page_number: this.searchParams.current_page,
      };
    },
    /**
     * (スケジュール)
     * 絞り込み:契約項目
     */
    SORT_TIME() {
      return Object.keys(SORT_TIME).map((key) => {
        return SORT_TIME[key];
      });
    },
  },
  methods: {
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.current_page = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 検索
     */
    onSearch() {
      this.searchParams["current_page"] = 1;
      this.getItems();
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
    },

    async getChildItems(id, pageNumber) {
      let searchParams = { ...this.searchParams };
      searchParams["field_tree_id"] = id;
      searchParams["page_number"] = pageNumber;
      delete searchParams.total_item;
      delete searchParams.page_count;
      delete searchParams.current_page;
      delete searchParams.total_page;
      delete searchParams.all_record;
      const params = {
        params: searchParams,
      };
      await Store.dispatch(
        `${STORE}/getListEntranceSummariesByCompany`,
        params
      );
    },

    async getItems() {
      if (this.field_id) {
        await Store.dispatch(`${STORE}/getListEntranceSummary`, {
          params: this.apiParams,
        });
      }
    },
    getTableLabels(data) {
      let labels = _.cloneDeep(TABLE_LABELS);
      if (data) {
        labels.push({
          text: "合計時間",
          value: "",
          align: "left",
          width: data[0].company_time_length[0].time_title.includes("/") ? "10%" : "11.4285%"
        });
        for (let i = 0; i < data[0].company_time_length.length; i++) {
          labels.push({
            text: data[0].company_time_length[i].time_title,
            value: i,
            align: "left",
            width: data[0].company_time_length[i].time_title.includes("/") ? "10%" : "11.4285%"
          });
        }
      }
      this.tableLabels = _.cloneDeep(labels);
    },
    async onSubmit(typeFile) {
      let params = {};
      params["file_code"] = typeFile;
      params["summary_date"] = this.searchParams.summary_date;
      params["field_id"] = this.field_id;
      params["is_partner_flag"] = this.is_partner_flag;
      this.downloadDocument(params);
    },
    async downloadDocument(params) {
      const configParams = {
        url: URL_HEADER,
        params: params,
      }
      let rs = await Store.dispatch("File/download", configParams);
      if(rs.hasError){
        this.flag = !this.flag
      }else{
        this.closePrintDialog();
      }
    },
    openComfirmPrint(){
      Store.dispatch("Report/getUserRole", this.field_id);
      this.isShowConfirmDialog = true;
    },
    closePrintDialog(){
      this.isShowConfirmDialog = false;
    },
    async checkRoleUser(field_id){
      if (!field_id) return;
      const params = {
        field_id,
        target_role: []
      };
      await Store.dispatch("Sites/getRole", params);
    },
  },
};
</script>

<style lang="scss" scoped>
.tableSortWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 64px;
  .sortLabel {
    font-size: 12px;
    height: 32px;
    margin-right: 16px;
  }
  .sortElement {
    max-width: 168px;
    margin-right: 24px;
  }
  .total_item {
    padding-bottom: 15px;
  }
}
</style>
