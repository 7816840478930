
<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <!--  (共通) 会社ヘッダー -->
        <SitePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout
          :layoutParams="layoutParams"
          :hideFooter="sortParams.pageCount >= sortParams.total_item"
        >
          <template #tableHeader="{ updateHeader }">
            <!--  
              (共通) テーブルヘッダー 
              このなかに、新規作成ボタン、検索ボタン、一括削除ボタンが実装されてます
              @openRemoveDialog : 一括削除の確認ダイアログを開く
              @openItemForm : 新規作成フォームを開く
            -->
            <TableHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :isDelete="false"
              :isAddNew="false"
              :updateHeader="updateHeader"
            >
              <ValidationObserver ref="observer" v-slot="observer">
                <SearchFormWrapper>
                  <Label
                    label="申請日"
                    editable
                    width="250px"
                    v-if="table_type == COORDINATOR_TABS.TAB1"
                  >
                    <InputDatepicker
                      name="application_date_from"
                      :editable="true"
                      :values="searchParams"
                      validation_rules="compare-to-application-end-date:@申請日2"
                      validation_label="申請日1"
                      :flagNull="true"
                      @onInput="onChangeSearchParams"
                      v-if="table_type == COORDINATOR_TABS.TAB1"
                    />
                  </Label>
                  <v-icon
                    class="mt-9"
                    v-if="table_type == COORDINATOR_TABS.TAB1"
                    >mdi-tilde</v-icon
                  >
                  <Label
                    label="申請日"
                    editable
                    width="250px"
                    v-if="table_type == COORDINATOR_TABS.TAB1"
                  >
                    <InputDatepicker
                      name="application_date_to"
                      :editable="true"
                      :values="searchParams"
                      :flagNull="true"
                      validation_rules="compare-to-application-start-date:@申請日1"
                      validation_label="申請日2"
                      @onInput="onChangeSearchParams"
                      v-if="table_type == COORDINATOR_TABS.TAB1"
                    />
                  </Label>
                  <Label
                    label="物品申請会社"
                    v-if="table_type == COORDINATOR_TABS.TAB1"
                    width="250px"
                  >
                    <Select
                      v-if="table_type == COORDINATOR_TABS.TAB1"
                      name="index_company_id"
                      :items="LIST_CREATED_COMPANY"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>

                  <TabSelect
                    v-if="table_type == COORDINATOR_TABS.TAB1"
                    class="mt-8"
                    name="application_status"
                    :items="TYPE_STATUS"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />

                  <Label
                    label="依頼日"
                    editable
                    width="250px"
                    v-if="table_type == COORDINATOR_TABS.TAB2"
                  >
                    <InputDatepicker
                      name="estimate_request_date_from"
                      :editable="true"
                      :values="searchParams"
                      :flagNull="true"
                      validation_rules="compare-to-estimate-end-date:@依頼日2"
                      validation_label="依頼日1"
                      @onInput="onChangeSearchParams"
                      v-if="table_type == COORDINATOR_TABS.TAB2"
                    />
                  </Label>
                  <v-icon
                    class="mt-9"
                    v-if="table_type == COORDINATOR_TABS.TAB2"
                    >mdi-tilde</v-icon
                  >
                  <Label
                    label="依頼日"
                    editable
                    width="250px"
                    v-if="table_type == COORDINATOR_TABS.TAB2"
                  >
                    <InputDatepicker
                      name="estimate_request_date_to"
                      :editable="true"
                      :values="searchParams"
                      :flagNull="true"
                      validation_rules="compare-to-estimate-start-date:@依頼日1"
                      validation_label="依頼日2"
                      @onInput="onChangeSearchParams"
                      v-if="table_type == COORDINATOR_TABS.TAB2"
                    />
                  </Label>

                  <Label
                    label="発注日"
                    editable
                    width="250px"
                    v-if="table_type == COORDINATOR_TABS.TAB3"
                  >
                    <InputDatepicker
                      name="order_request_date_from"
                      :editable="true"
                      :values="searchParams"
                      :flagNull="true"
                      validation_rules="compare-to-order-end-date:@発注日2"
                      validation_label="発注日1"
                      @onInput="onChangeSearchParams"
                      v-if="table_type == COORDINATOR_TABS.TAB3"
                    />
                  </Label>
                  <v-icon
                    class="mt-9"
                    v-if="table_type == COORDINATOR_TABS.TAB3"
                    >mdi-tilde</v-icon
                  >
                  <Label
                    label="発注日"
                    editable
                    width="250px"
                    v-if="table_type == COORDINATOR_TABS.TAB3"
                  >
                    <InputDatepicker
                      name="order_request_date_to"
                      :editable="true"
                      :values="searchParams"
                      :flagNull="true"
                      validation_rules="compare-to-order-start-date:@発注日1"
                      validation_label="発注日2"
                      @onInput="onChangeSearchParams"
                      v-if="table_type == COORDINATOR_TABS.TAB3"
                    />
                  </Label>

                  <Label
                    label="発注番号"
                    v-if="table_type == COORDINATOR_TABS.TAB3"
                  >
                    <InputText
                      name="order_number"
                      :editable="true"
                      :values="searchParams"
                      validation_label="発注番号"
                      validation_rules="max:16|number-char-symbol"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>

                  <Label
                    label="見積番号"
                    v-if="
                      table_type == COORDINATOR_TABS.TAB2 ||
                      table_type == COORDINATOR_TABS.TAB3
                    "
                    width="200px"
                  >
                    <InputText
                      name="estimate_number"
                      :editable="true"
                      :values="searchParams"
                      validation_label="見積番号"
                      validation_rules="max:16|number-char-symbol"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>

                  <Label
                    :class="roleUser == ROLE_USER[4] ? 'mt-2' : ''"
                    label="建機レンタル会社"
                    v-if="table_type == COORDINATOR_TABS.TAB2"
                    width="250px"
                  >
                    <Select
                      v-if="table_type == COORDINATOR_TABS.TAB2"
                      name="index_estimate_company_id"
                      :items="CONSTRUCTION_EQUIPMENT_RENTAL_COMPANY"
                      :editable="roleUser !== ROLE_USER[4]"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>

                  <Label
                    :class="roleUser == ROLE_USER[4] ? 'mt-2' : ''"
                    label="建機レンタル会社"
                    v-if="table_type == COORDINATOR_TABS.TAB3"
                    width="250px"
                  >
                    <Select
                      v-if="table_type == COORDINATOR_TABS.TAB3"
                      name="index_order_company_id"
                      :items="CONSTRUCTION_EQUIPMENT_RENTAL_COMPANY"
                      :editable="roleUser !== ROLE_USER[4]"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>

                  <TabSelect
                    v-if="table_type === COORDINATOR_TABS.TAB2"
                    class="mt-8"
                    name="estimate_status"
                    :items="ANSWER_STATUS"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />

                  <TabSelect
                    v-if="table_type === COORDINATOR_TABS.TAB2"
                    class="mt-8"
                    name="estimate_expired_flag"
                    :items="ANSWER_EXPIRED_STATUS"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />

                  <TabSelect
                    v-if="table_type === COORDINATOR_TABS.TAB3"
                    class="mt-8"
                    name="order_status"
                    :items="TAB3_TYPE"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />

                  <SwitchInput
                    v-if="table_type == COORDINATOR_TABS.TAB1"
                    class="mt-4"
                    name="delivered_flag"
                    :label="`納品済みの機材も表示`"
                    :values="searchParams"
                    :editable="true"
                    @onInput="onChangeSearchParams"
                  />
                  <SwitchInput
                    v-if="table_type == COORDINATOR_TABS.TAB2"
                    class="mt-4"
                    name="estimate_finished_flag"
                    :label="`終了した見積も表示`"
                    :values="searchParams"
                    :editable="true"
                    @onInput="onChangeSearchParams"
                  />
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mr-6"
                    color="primary"
                    :disabled="!valid"
                    depressed
                    @click="onSearch"
                  >
                    検索
                  </v-btn>
                </SearchFormWrapper>
                <ValidationCallback
                  :observer="observer"
                  @callback="updateValidate"
                />
              </ValidationObserver>
            </TableHeader>

            <!-- 
              (共通)
              ソートのレイアウトを調整するラッパーコンポーネントです
            -->
            <TableSortWrapper>
              <!--  
                (共通) ソート 
                ソート項目、ソート順、表示件数の選択、総件数の表示
              -->
              <TableSort
                :values="searchParams"
                :sort_items="sortItems"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="sortParams.total_item"
                :pageSize="sortParams.pageCount"
                :isShowTotalItem="false"
                @onInput="onChangeSortParams"
                :key="flagSort"
              />
            </TableSortWrapper>
            <TableTab
              :sm="widthTab.sm"
              :md="widthTab.md"
              :items="TAB_SELECT"
              @onChangeTableTab="onChangeTableTab"
            />
          </template>
          <!-- body -->
          <template #tableBody="{ tableHeight }">
            <Table1
              v-if="table_type === COORDINATOR_TABS.TAB1 && roleUser != ROLE_USER[4]"
              :roleUser="roleUser"
              :tableLabels="TABLE_LABELS_1"
              :tableHeight="tableHeight"
              :items="items"
              :dataSelect="CONSTRUCTION_EQUIPMENT_RENTAL_COMPANY"
              :itemsPerPage="sortParams.pageCount"
              :resetSelected = "resetSelected"
              @resetDataItems="resetItems()"
              @resetItemTab1="resetItemTab1()"
            />
            <Table2
              v-if="table_type === COORDINATOR_TABS.TAB2 && (roleUser == ROLE_USER[3] || roleUser == ROLE_USER[4])"
              :roleUser="roleUser"
              :tableLabels="TABLE_LABELS_2"
              :tableHeight="tableHeight"
              :items="items"
              :dataSelect="CONSTRUCTION_EQUIPMENT_RENTAL_COMPANY"
              :itemsPerPage="sortParams.pageCount"
              :resetSelected = "resetSelected"
              @resetDataItems="resetItems()"
              @resetItemTab2="resetItemTab2()"
            />
            <Table3
              v-if="table_type === COORDINATOR_TABS.TAB3 && (roleUser == ROLE_USER[3] || roleUser == ROLE_USER[4])"
              :roleUser="roleUser"
              :tableLabels="TABLE_LABELS_3"
              :tableHeight="tableHeight"
              :items="items"
              :dataSelect="CONSTRUCTION_EQUIPMENT_RENTAL_COMPANY"
              :itemsPerPage="sortParams.pageCount"
              :resetSelected = "resetSelected"
              @resetDataItems="resetItems()"
              @resetItemTab3="resetItemTab3()"
            />
          </template>
          <!-- footer -->
          <template #tableFooter>
            <Pagination
              :current="sortParams.currentPage"
              :total="sortParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>
  </div>
</template>

<script>
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "../stock/components/TableDeviceStockSort.vue";
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import Popup from "@/components/common/Popup";
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import { Store } from "@/store/Store.js";

import {
  FORMS_ALL,
  FORMS_PARTNER,
  FORMS_RENTAL,
  COORDINATOR_TABS,
  COORDINATOR_SORT_ITEMS_1,
  COORDINATOR_SORT_ITEMS_2,
  COORDINATOR_SORT_ITEMS_3,
  COORDINATOR_TABLE_LABELS_1,
  COORDINATOR_TABLE_LABELS_2,
  COORDINATOR_TABLE_LABELS_3,
  USING_STATUS,
  TYPE_STATUS,
  ANSWER_STATUS,
  ROLE_USER,
  ANSWER_EXPIRED_STATUS,
  TAB3_TYPE,
  SEARCH_PARAMS_DEFAULT_TAB_1,
  SEARCH_PARAMS_DEFAULT_TAB_2,
  SEARCH_PARAMS_DEFAULT_TAB_3,
} from "@/constants/DEVICE_COORDINATOR";
import {
  TABLES_PER_PAGE,
  TABLE_SORT_ORDERS,
  ROLE_USER_RENTAL_COMPANY
} from "@/constants/COMMON";
import Table1 from "./components/Table1.vue";
import Table2 from "./components/Table2.vue";
import Table3 from "./components/Table3.vue";
import TableTab from "./components/TableTab";
import { HEADER_MENU_ITEMS_SITE, HEADER_MENU_ITEMS_SITE_FOR_USER_RENTAL_COMPANY } from "@/constants/GLOBALHEADER"; //グローバルヘッダーメニュー
import Select from "@/components/forms/elements/Select";
import TabSelect from "@/components/forms/elements/TabSelect"; //絞り込みフォームで使用
import SwitchInput from "@/components/forms/elements/SwitchInput";
import InputDatepicker from "@/components/forms/elements/InputDatepicker";
import _ from "lodash";

//タイトル
const PAGE_TITLE = "物品申請・見積・発注";
const TABLE_LABELS_1 = COORDINATOR_TABLE_LABELS_1;
const TABLE_LABELS_2 = COORDINATOR_TABLE_LABELS_2;
const TABLE_LABELS_3 = COORDINATOR_TABLE_LABELS_3;
//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;
// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;
//ストア
const STORE = "DeviceCoordinator";
//１ページあたりのテーブル件数
const PAGE_COUNT = 25;

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      FORMS:[],
      PAGE_TITLE,
      TABLE_LABELS_1,
      TABLE_LABELS_2,
      TABLE_LABELS_3,
      COORDINATOR_SORT_ITEMS_1,
      COORDINATOR_SORT_ITEMS_2,
      COORDINATOR_SORT_ITEMS_3,
      SEARCH_PARAMS_DEFAULT_TAB_1,
      SEARCH_PARAMS_DEFAULT_TAB_2,
      SEARCH_PARAMS_DEFAULT_TAB_3,
      COORDINATOR_TABS,
      LIST_CREATED_COMPANY: [],
      CONSTRUCTION_EQUIPMENT_RENTAL_COMPANY: [],
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      FORMS_ALL,
      FORMS_PARTNER,
      FORMS_RENTAL,
      ROLE_USER,
      roleUser: null,
      tab: null,
      tableHeight: null,
      sortItems: COORDINATOR_SORT_ITEMS_1,
      field_id: null,
      valid: false,
      flagSort: false,
      /**
       * (共通)
       * 一覧データ
       */
      items: [],

      /**
       * (共通)
       * checkbox選択item
       */
      selectedItems: [],

      // 検索パラメータ
      table_type: COORDINATOR_TABS.TAB1,
      searchParams: SEARCH_PARAMS_DEFAULT_TAB_1,
      searchParamsTab: {
        searchParamsTab1: SEARCH_PARAMS_DEFAULT_TAB_1,
        searchParamsTab2: SEARCH_PARAMS_DEFAULT_TAB_2,
        searchParamsTab3: SEARCH_PARAMS_DEFAULT_TAB_3,
      },
      sortParams: {
        pageCount: PAGE_COUNT,
        currentPage: 1,
        total_item: null,
        totalPage: 1,
        sort: COORDINATOR_SORT_ITEMS_1[0].id,
        asc: true
      },
      widthTab : {
        sm : null,
        md : null
      },
      /**
       * (共通)
       * 新規フラグ
       */
      isNewItem: false,
      ROLE_USER_RENTAL_COMPANY,
      resetSelected : false
    };
  },
  components: {
    Table1,
    Table2,
    Table3,
    TableTab,
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    SitePortalHeader,
    Popup,
    Label,
    InputText,
    Select,
    TabSelect,
    SwitchInput,
    InputDatepicker,
    ValidationObserver,
    ValidationCallback,
  },
  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      (data, oldData) => {
        if (data && JSON.stringify(data) !== JSON.stringify(oldData)) {
          this.field_id = data.field_id;
          this.initialData();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Sites/getListCompanyRental"],
      (data) => {
        this.CONSTRUCTION_EQUIPMENT_RENTAL_COMPANY = this.getDataSelectSearch(data);
        if (this.roleUser === ROLE_USER[4]) {
          let temp = this.CONSTRUCTION_EQUIPMENT_RENTAL_COMPANY.find(e => e.company_id == this.COMPANY_USER.company_id && e.company_branch_id == this.COMPANY_USER.company_branch_id);
          let _searchParamsTab2 = { ...this.searchParamsTab[`searchParamsTab2`] };
          _searchParamsTab2.index_estimate_company_id = temp?.id;
          _searchParamsTab2.estimate_rental_company_id = temp?.company_id;
          _searchParamsTab2.estimate_rental_company_branch_id = temp?.company_branch_id;
          this.searchParamsTab[`searchParamsTab2`] = { ..._searchParamsTab2 };
          let _searchParamsTab3 = { ...this.searchParamsTab[`searchParamsTab3`] };

          _searchParamsTab3.index_order_company_id = temp?.id;
          _searchParamsTab3.order_rental_company_id = temp?.company_id;
          _searchParamsTab3.order_rental_company_branch_id = temp?.company_branch_id;
          this.searchParamsTab[`searchParamsTab3`] = { ..._searchParamsTab3 };
          this.searchParams = this.searchParamsTab[`searchParamsTab2`];
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
        () => Store.getters["DeviceCoordinator/getListCreatedCompany"],
        (data) => {
          this.LIST_CREATED_COMPANY = this.getDataSelectSearch(data);
        },
        {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Sites/getRole"],
      (data) => {
        if(data) {
          this.roleUser = data;
          this.checkRole(data);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        /**
         *  (共通)
         * 一覧リストの格納
         */
        this.items = data[0];
        /**
         *  (共通)
         * ページネーション更新
         */
        if(data[0]){
          let _items = data[0].find(e => e.tab == this.table_type)?.data;
          this.items = _items ? _items : [];

        }
        if(data[1]){
          let pagination = data[1].find(e => e.tab == this.table_type)?.pagination;
          if (pagination) {
            let sortParams = { ...this.sortParams };
            sortParams.currentPage = parseInt(pagination.current || 1);
            sortParams.totalPage = pagination.total;
            sortParams.total_item = pagination.total_item;
            this.sortParams = sortParams;
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    IS_ROLE_USER_RENTAL_COMPANY(){
      return Store.getters["Sites/getUserRole"] == this.ROLE_USER_RENTAL_COMPANY;
    },
    TAB_SELECT() {
      const form = this.addNumberRecordForTabs();
      return Object.keys(form).map((key) => {
        return this.FORMS[key];
      });
    },
    USING_STATUS() {
      return Object.keys(USING_STATUS).map((key) => {
        return USING_STATUS[key];
      });
    },
    TYPE_STATUS() {
      return Object.keys(TYPE_STATUS).map((key) => {
        return TYPE_STATUS[key];
      });
    },
    ANSWER_STATUS() {
      return Object.keys(ANSWER_STATUS).map((key) => {
        return ANSWER_STATUS[key];
      });
    },
    ANSWER_EXPIRED_STATUS() {
      return Object.keys(ANSWER_EXPIRED_STATUS).map((key) => {
        return ANSWER_EXPIRED_STATUS[key];
      });
    },
    TAB3_TYPE() {
      return Object.keys(TAB3_TYPE).map((key) => {
        return TAB3_TYPE[key];
      });
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },

    TOTAL_RECORDS() {
      return Store.getters["DeviceCoordinator/getTotalRecord"];
    },

    COMPANY_USER() {
      return JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user;
    },
    
    /**
     *
     * API Param
     */
    apiParams() {
      let rs = null;
      switch (this.table_type) {
        case COORDINATOR_TABS.TAB1 :
          rs = {
            application_date_from: this.searchParamsTab.searchParamsTab1.application_date_from,
            application_date_to: this.searchParamsTab.searchParamsTab1.application_date_to,
            application_status: this.searchParamsTab.searchParamsTab1.application_status,
            delivered_flag: this.searchParamsTab.searchParamsTab1.delivered_flag,
            created_company_id: this.searchParamsTab.searchParamsTab1.created_company_id,
            created_company_branch_id: this.searchParamsTab.searchParamsTab1.created_company_branch_id,
          };
          break;
        case COORDINATOR_TABS.TAB2 :
          rs = {
            estimate_request_date_from: this.searchParamsTab.searchParamsTab2.estimate_request_date_from,
            estimate_request_date_to: this.searchParamsTab.searchParamsTab2.estimate_request_date_to,
            estimate_rental_company_id: this.searchParamsTab.searchParamsTab2.estimate_rental_company_id,
            estimate_rental_company_branch_id: this.searchParamsTab.searchParamsTab2.estimate_rental_company_branch_id,
            estimate_status: this.searchParamsTab.searchParamsTab2.estimate_status,
            estimate_number: this.searchParamsTab.searchParamsTab2.estimate_number,
            estimate_finished_flag: this.searchParamsTab.searchParamsTab2.estimate_finished_flag,
            estimate_expired_flag: this.searchParamsTab.searchParamsTab2.estimate_expired_flag
          };
          break;
        case COORDINATOR_TABS.TAB3 :
          rs = {
            order_request_date_from: this.searchParamsTab.searchParamsTab3.order_request_date_from,
            order_request_date_to: this.searchParamsTab.searchParamsTab3.order_request_date_to,
            order_rental_company_id: this.searchParamsTab.searchParamsTab3.order_rental_company_id,
            order_rental_company_branch_id: this.searchParamsTab.searchParamsTab3.order_rental_company_branch_id,
            order_number: this.searchParamsTab.searchParamsTab3.order_number,
            estimate_number: this.searchParamsTab.searchParamsTab3.estimate_number,
            order_status: this.searchParamsTab.searchParamsTab3.order_status
          }
          break;
      }
        let temp = {
        field_id: this.CURRENT_SITE.field_id,
        tab: this.table_type,
        sort_value: this.sortParams.sort,
        sort_by: this.sortParams.asc ? 1 : 0,
        page_number: this.sortParams.currentPage,
        page_size: this.sortParams.pageCount
      };
      let apiParams = { ...rs, ...temp };
      return apiParams;
    },
  },
  methods: {
    checkRole(role){
      if (role == ROLE_USER[3]) {
        this.widthTab.sm = 6;
        this.widthTab.md = 7;
        this.FORMS = this.FORMS_ALL;
      } else if (role == ROLE_USER[4]) {
        this.widthTab.sm = 4;
        this.widthTab.md = 5;
        this.sortParams.sort = COORDINATOR_SORT_ITEMS_2[0].id;
        this.table_type = COORDINATOR_TABS.TAB2;
        this.FORMS = this.FORMS_RENTAL;
        this.flagSort = !this.flagSort;
        this.sortItems = COORDINATOR_SORT_ITEMS_2;
      } else {
        this.widthTab.sm = 2;
        this.widthTab.md = 3;
        this.FORMS = this.FORMS_PARTNER;
      }
      Store.dispatch("GlobalHeader/setSiteMenu", {
        menuId: this.IS_ROLE_USER_RENTAL_COMPANY ? HEADER_MENU_ITEMS_SITE_FOR_USER_RENTAL_COMPANY.DEVICE_MANAGEMENT.id : HEADER_MENU_ITEMS_SITE.DEVICE_MANAGEMENT.id,
      });
    },
    async initialData(){
      await this.getRoleInField();
      if (this.roleUser != ROLE_USER[4]) {
        this.getListCreatedCompany();
      }
      if (this.roleUser != ROLE_USER[1] && this.roleUser != ROLE_USER[2]) {
        await this.getListCompanyRental();
      }
      this.getItemsTabByRole();
    },
    getDataSelectSearch(data){
      let _data = [...data];
      const listData = [{id: null, name: ""}];
      _data.forEach((item, index) => {
        listData.push({
          id: index,
          company_id: item.company_id,
          company_branch_id: item.company_branch_id,
          name: item.name
        })
      });
      return listData;
    },
    getItemsTabByRole() {
      if(this.roleUser && this.roleUser != ROLE_USER[4]){
        this.getItemsTab(COORDINATOR_TABS.TAB1);
      }
      if(this.roleUser && this.roleUser != ROLE_USER[1] && this.roleUser != ROLE_USER[2]){
        this.getItemsTab(COORDINATOR_TABS.TAB2);
        this.getItemsTab(COORDINATOR_TABS.TAB3);
      }
    },

    addNumberRecordForTabs() {
      let form = { ...this.FORMS };
      if (!_.isEmpty(form)) {
        if (this.roleUser != ROLE_USER[4]) {
          let totaltab1 = this.TOTAL_RECORDS.find(e => e.tab == 1)?.total;
          form.TABLE1.name = `物品申請（${totaltab1 || 0}）`;
        }
        if (this.roleUser != ROLE_USER[1] && this.roleUser != ROLE_USER[2]) {
          let totaltab2 = this.TOTAL_RECORDS.find(e => e.tab == 2)?.total;
          let totaltab3 = this.TOTAL_RECORDS.find(e => e.tab == 3)?.total;
          form.TABLE2.name = `見積（${totaltab2 || 0}）`;
          form.TABLE3.name = `発注（${totaltab3 || 0}）`;
        }
      }
      return form;
    },

    async getListCompanyRental() {
      if(this.CURRENT_SITE.field_id) {
        const field_id = this.CURRENT_SITE.field_id;
        await Store.dispatch(`Sites/getListCompanyRental`, field_id);
      }
    },

    async getListCreatedCompany() {
      if(this.CURRENT_SITE.field_id) {
        let params = {
          field_id : this.CURRENT_SITE.field_id
        }
        await Store.dispatch(`${STORE}/getListCreatedCompany`, {params});
      }
    },

    async getRoleInField() {
      if(this.CURRENT_SITE.field_id) {
        const field_id = this.CURRENT_SITE.field_id;
        const params = {
          field_id,
          target_role: []
        };
        await Store.dispatch("Sites/getRole", params);
      }
    },
    onChangeTableTab(item) {
      this.items = [];
      this.table_type = item.id;
      this.changeSortItems(item.id);
      this.searchParams = this.searchParamsTab[`searchParamsTab${item.id}`];
      // change sort param to default
      const sortParams = { ...this.sortParams };
      // change current page to 1 , sort by control_number , sort by asc
      sortParams.sort = this.sortItems[0].id;
      sortParams.asc = true;
      sortParams.currentPage = 1;
      // update this.sort param
      this.sortParams = sortParams;
      this.flagSort = ! this.flagSort;
      this.getItems();
    },
    changeSortItems(id) {
      if (id === this.FORMS.TABLE1?.id) {
        this.sortItems = this.COORDINATOR_SORT_ITEMS_1;
      } else if (id === this.FORMS.TABLE2?.id) {
        this.sortItems = this.COORDINATOR_SORT_ITEMS_2;
      } else if (id === this.FORMS.TABLE3?.id) {
        this.sortItems = this.COORDINATOR_SORT_ITEMS_3;
      }
    },
    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid }) {
      this.valid = valid;
    },
    /**
     * (共通)
     * 検索
     */
    onSearch() {
      this.resetSelected = ! this.resetSelected;
      let _searchParams = { ...this.searchParams };
      this.searchParamsTab[`searchParamsTab${this.table_type}`] = { ..._searchParams };
      this.sortParams["currentPage"] = 1;
      this.getItems();
    },
    /**
     * (共通)
     * 並び替えパラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let sortParams = { ...this.sortParams };
      sortParams[name] = value;
      name == "pageCount" ? (sortParams["currentPage"] = 1) : "";
      this.sortParams = sortParams;
      this.getItems();
    },
    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      if(name == "index_company_id"){
        let temp = this.LIST_CREATED_COMPANY.find(e => e.id == value);
        searchParams.created_company_id = temp?.company_id;
        searchParams.created_company_branch_id =  temp?.company_branch_id;
      }
      if(name == "index_estimate_company_id"){
        let temp = this.CONSTRUCTION_EQUIPMENT_RENTAL_COMPANY.find(e => e.id == value);
        searchParams.estimate_rental_company_id = temp?.company_id;
        searchParams.estimate_rental_company_branch_id = temp?.company_branch_id;
      }
      if(name == "index_order_company_id"){
        let temp = this.CONSTRUCTION_EQUIPMENT_RENTAL_COMPANY.find(e => e.id == value);
        searchParams.order_rental_company_id = temp?.company_id;
        searchParams.order_rental_company_branch_id = temp?.company_branch_id;
      }
      searchParams[name] = value;
      this.searchParams = searchParams;
    },
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let sortParams = { ...this.sortParams };
      sortParams.currentPage = n;
      this.sortParams = sortParams;
      this.getItems();
    },
    resetItems() {
      Store.dispatch("Notification/getNewNoticeStatus");
      this.getItemsTabByRole();
    },
    getApiParramsByTab(tableType) {
      let apiParams = {...this.apiParams};
      apiParams.tab = tableType;
      return apiParams;
    },
    /**
     * データ取得
     */
    async getItems() {
      let apiParams = this.getApiParramsByTab(this.table_type);
      if(this.CURRENT_SITE.field_id) {
        Store.dispatch(`${STORE}/get`, { params: apiParams });
      }
    },
    async getItemsTab(tab) {
      let apiParams = this.searchParamsTab[`searchParamsTab${tab}`];
      apiParams.field_id = this.CURRENT_SITE.field_id;
      apiParams.tab = tab;
      // if update in tab , save sort value and sort by
      if (tab == this.table_type) {
        apiParams.page_number = this.sortParams.currentPage;
        apiParams.sort_value = this.sortParams.sort;
        apiParams.sort_by = this.sortParams.asc ? 1 : 0;
      } else {
        // sort value and sort_by is default
        if( tab == 1){
          apiParams.sort_value = "application_date";
        }else if( tab == 2){
          apiParams.sort_value = "estimate_request_date";
        }else {
          apiParams.sort_value = "order_request_date";
        }
        apiParams.page_number = 1;
        apiParams.sort_by = 1;
      }
      apiParams.page_size = this.sortParams.pageCount;
      if(this.CURRENT_SITE.field_id) {
        await Store.dispatch(`${STORE}/get`, { params: apiParams });
      }
      if (tab == this.table_type && this.items.length == 0 && this.sortParams.currentPage > 1) {
        let temp = this.sortParams.currentPage - 1;
        this.sortParams.currentPage = temp;
        apiParams.page_number = temp;
        await Store.dispatch(`${STORE}/get`, { params: apiParams });
      }
    },
    resetItemTab1() {
      this.getItemsTab(COORDINATOR_TABS.TAB1);
    },
    resetItemTab2() {
      this.getItemsTab(COORDINATOR_TABS.TAB2);
    },
    resetItemTab3() {
      this.getItemsTab(COORDINATOR_TABS.TAB3);
    }
  },
};
</script>
