<template>
  <v-item>
    <v-card>
      <v-data-table
        :headers="TABLE_LABELS"
        :items="items"
        :height="tableHeight"
        :items-per-page="25"
        :noDataText="NO_DATA_MESSAGE"
        hide-default-header
        hide-default-footer
        :item-class="rowClass"
      >
        <template v-slot:[`item.icon`]="{ item }" label small>
          <v-icon>{{ item.icon }}</v-icon>
        </template>
        <template v-slot:[`item.word`]="{ item }">
          <div>{{ convertName(item.name_sei + " " + item.name_mei) }}</div>
        </template>
        <template v-slot:[`item.gender`]="{ item }">
          <div class="text-center">
            <v-chip-group>
              <v-chip
                outlined
                small
                :class="[
                  item.cmn_mst_gender_id === 1
                    ? 'grey is-Active gender'
                    : 'grey lighten-4 is-inActive gender',
                ]"
              >
                男
              </v-chip>
              <v-chip
                outlined
                small
                :class="[
                  item.cmn_mst_gender_id === 2
                    ? 'grey is-Active gender'
                    : 'grey lighten-4 is-inActive gender',
                ]"
              >
                女
              </v-chip>
            </v-chip-group>
          </div>
        </template>
        <template v-slot:[`item.roll`]="{ item }">
          <v-chip
            v-if="item.skills_sub_catergory"
            class="ma-2"
            small
            color="amber darken-2"
            dark
            >{{ item.skills_sub_catergory }}</v-chip
          >
        </template>
      </v-data-table>
    </v-card>
  </v-item>
</template>

<script>
import { Store } from "@/store/Store.js";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
const TABLE_LABELS = [
  { text: "アイコン", value: "icon", aline: "center", width: "10%" },
  { text: "名前", value: "word", width: "50%" },
  { text: "性別", value: "gender", width: "10%", align: "right" },
  { text: "職種", value: "roll", width: "10%", align: "right" },
];
export default {
  data() {
    return {
      TABLE_LABELS,
      items: [
        {
          word: "山田太郎",
          icon: "mdi-account-circle-outline",
          gender: "男",
          roll: "とび工",
        },
      ],
      tableHeight: "0px",
      NO_DATA_MESSAGE
    };
  },
  async mounted() {
    this.$watch(
      () => Store.getters["Employees/getData"],
      (data) => {
        let items = data;
        items.forEach((item) => (item["icon"] = "mdi-account-circle-outline"));
        this.items = [...items];
        this.updateTableHeight();
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    updateTableHeight() {
      const columns = this.items.length;
      if (columns == 0) {
        this.tableHeight = "48px";
      } else {
        let itemHeight = columns > 10 ? 480 : 48 * columns;
        this.tableHeight = itemHeight + "px";
      }
    },

    convertName(name) {
      let result = "";
      if (name) {
        result = name;
        if (name.length > 34) {
          result = name.substring(0, 34) + "•••";
        }
      }
      return result;
    },

    rowClass(item) {
      return item.state_handling_flag == 1 ? 'grey lighten-1' : '';
    }
  },
};
</script>

<style scoped>
.v-chip {
  padding: 4% !important;
}
.v-chip.gender {
  width: 24px;
  justify-content: center;
}
.is-Active {
  color: #767676 !important;
}
.is-inActive {
  color: #c6c6c6 !important;
}
.text-start {
  padding: 0 !important;
}
</style>
