<template>
  <v-card class="dialog">
    <v-card-title>
      <div :style="getTitleColorStyle" class="title">{{ title }}</div>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div class="text">{{ text1 }}</div>
      </div>
    </v-card-text>
    <v-card-text class="body">
      <div v-if="isDelete">
        <div class="text left-text-confirm">{{ text2 }}</div>
      </div>
      <div v-if="!isDelete" class="left-text-error">
        <li>{{ errorText1 }}</li>
        <li>{{ errorText2 }}</li>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="warning"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn v-if="isDelete" depressed class="btn" color="warning" :disabled="isDisable" @click="$emit('yes')">
        {{ titleBtn }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      errorText1: "",
      errorText2: "",
    }
  },
  components: {},
  props: {
    text1: {
      type: String,
    },
    text2: {
      type: String,
    },
    title: {
      type: String,
    },
    items: {
      type: Array,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    isDelete: {
      type: Boolean,
      default: true,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    titleBtn : {
      type : String
    }
  },
  mounted() {
    this.$watch(
      () => this.isDelete,
      (flag) => {
        if (!flag) {
          this.errorText1 = this.text2.split("/")[0]
          this.errorText2 = this.text2.split("/")[1]
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getTitleColorStyle() {
      return this.warning ? "color:red;" : "";
    },
    getColor() {
      return this.warning ? "warning" : "primary";
    },
  },
};
</script>
