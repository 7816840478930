<template>
  <div class="FormMain" style="padding-bottom: 25px">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <!-- <v-list-item>
                    <v-list-item-content>
                      <FormWorkOccupation
                        name="work_classification_plans"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput"
                      />
                    </v-list-item-content>
                  </v-list-item> -->
                  <v-col cols="12" sm="12" md="12">
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="作業詳細" :editable="editable">
                          <InputTextArea
                            name="contents"
                            :values="formValues['work_plans']"
                            :editable="editable"
                            placeholder=""
                            validation_label="作業詳細"
                            validation_rules="max:255"
                            @onInput="onInput"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-btn class="ml-6" v-if="editable" :disabled="disableBtn" depressed small color="primary" @click="openFormSelectDialog()">履歴</v-btn>
                  <v-col cols="12" sm="12" md="12">
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="備考" :editable="editable">
                          <InputTextArea
                            name="remarks"
                            :values="formValues['work_plans']"
                            :editable="editable"
                            validation_label="備考"
                            validation_rules="max:512"
                            @onInput="onInput"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                    <DangerForecast
                        name="work_predicted_risk_plans"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <FormExpectedDanger
                        name="work_safety_instruction_plans"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <FormPersonInCharge
                        :name="USER_TYPE.TYPE_1"
                        flag_name = "work_user_plans"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    <Popup width="480px" :dialog="isShowSearchDialog">
      <HistorySelectDialog
        nameStore="ScheduleWorks"
        :field_tree_id="formValues.work_plans ? formValues.work_plans.field_tree_id : null"
        @close="isShowSearchDialog = false"
        @updateCommentHistory="updateCommentHistory"
      />
    </Popup>
  </div>
</template>
<script>
import Label from "../../elements/Label";
import InputText from "../../elements/InputText";
import InputTextArea from "../../elements/InputTextArea.vue";
import Select from "../../elements/Select.vue";
import FormWorkOccupation from "./components/FormWorkOccupation.vue";
import FormExpectedDanger from "./components/FormExpectedDanger.vue";
import FormPersonInCharge from "./components/FormPersonInCharge.vue";
import DangerForecast from "./components/DangerForecast.vue";
import { USER_TYPE } from "@/constants/SCHEDULE_WORKS";
import Popup from "@/components/common/Popup";
import HistorySelectDialog from "../components/HistorySelectDialog.vue";

export default {
  data: () => {
    return {
      formValues: {},
      USER_TYPE,
      isShowSearchDialog: false,
    };
  },
  components: {
    Label,
    InputText,
    InputTextArea,
    Select,
    FormWorkOccupation,
    FormExpectedDanger,
    FormPersonInCharge,
    DangerForecast,
    Popup,
    HistorySelectDialog,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue};
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    disableBtn(){
      return this.formValues.work_plans ? this.formValues.work_plans.field_tree_id == null : true;
    }
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      if(name == USER_TYPE.TYPE_1) {
        formValues['work_user_plans'][name] = value;
      } else if(name == "contents" || name == "predicted_risk" || name == "remarks") {
        formValues['work_plans'][name] = value;
      } else {
        formValues[name] = value;
      }
      this.$emit("formUpdate", formValues);
    },
    openFormSelectDialog(){
      this.isShowSearchDialog = true;
    },
    updateCommentHistory(item){
      this.isShowSearchDialog = false;
      const formValues = { ...this.formValues };
      formValues['work_plans']['contents'] = item;
      this.$emit("formUpdate", formValues);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
</style>
