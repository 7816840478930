<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row no-gutters>
          <v-col cols="12" sm="5" md="5" class="pt-0 pb-0">
            <v-list-item>
              <v-list-item-content>
                <Label label="使用目的" :editable="editable">
                  <Select
                    name="cmn_mst_fire_purpose_use_id"
                    :values="item"
                    :editable="editable"
                    :items="CMN_MST_PURPOSE_OF_USAGE"
                    item_text="purpose_use"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="5" md="5" class="pt-0 pb-0">
            <v-list-item>
              <v-list-item-content>
                <Label label="種類" :editable="editable">
                  <Select
                    name="cmn_mst_fire_type_id"
                    :values="item"
                    :items="CMN_MST_FIRE_TYPE"
                    item_text="type"
                    :editable="editable"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="5" md="5" class="pt-0 pb-0">
            <v-list-item>
              <v-list-item-content>
                <Label label="管理方法" :editable="editable">
                  <Select
                    name="cmn_mst_fire_management_method_id"
                    :values="item"
                    :editable="editable"
                    :items="CMN_MST_MANAGEMENT_METHOD"
                    item_text="management_method"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col v-if="!editable">
            <v-list-item>
              <v-list-item-content>
                <Label label="使用時間" :editable="!editable">
                  <v-text-field
                    :label="getProhibitTime(item)"
                    dense
                    color="primary"
                    :disabled="!editable"
                    :filled="editable"
                  ></v-text-field>
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col v-if="editable" cols="12" sm="5" md="5" class="pt-0 pb-0">
            <v-list-item>
              <v-list-item-content>
                <Label label="開始時間" :editable="editable" :required="Boolean(!item.hot_work_start && item.hot_work_end)">
                  <Select
                    name="hot_work_start"
                    :values="item"
                    :items="hot_time_start"
                    :editable="editable"
                    validation_label="開始時間"
                    :validation_rules="Boolean(!item.hot_work_start && item.hot_work_end) ? 'required' : ''"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col v-if="editable" cols="12" sm="5" md="5" class="pt-0 pb-0">
            <v-list-item>
              <v-list-item-content>
                <Label label="終了時間" :editable="editable" :required="Boolean(item.hot_work_start && !item.hot_work_end)">
                  <Select
                    name="hot_work_end"
                    :values="item"
                    :items="hot_time_end"
                    :editable="editable"
                    validation_label="終了時間"
                    :validation_rules="Boolean(item.hot_work_start && !item.hot_work_end) ? 'required' : ''"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="pt-0 pb-0">
            <v-btn
              v-if="index >= row && editable"
              class="mt-8"
              icon
              @click="remove(index)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-list-item>
      <v-list-item-content>
        <div>
          <v-btn v-if="editable" depressed small color="primary" @click="addEmpty"
            >追加</v-btn
          >
        </div>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "../../../elements/Label.vue";
import Select from "../../../elements/Select.vue";
import { Store } from "@/store/Store.js";
import {
  hot_time_start,
  hot_time_end,
} from "@/constants/SCHEDULE_COMPANY";
//初期値を設定します
const INITIAL_VALUES = {
  cmn_mst_fire_purpose_use_id: null,
  cmn_mst_fire_type_id: null,
  cmn_mst_fire_management_method_id: null,
  hot_work_start: null,
  hot_work_end: null,
};

export default {
  data: () => {
    return {
      items: [],
      formValues: {},
      CMN_MST_PURPOSE_OF_USAGE: [],
      CMN_MST_FIRE_TYPE: [],
      CMN_MST_MANAGEMENT_METHOD: [],
      hot_time_start,
      hot_time_end,
    };
  },
  components: {
    Label,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    row: {
      type: Number,
      default: 1,
    },
  },
  mounted() {
    this.$watch(
      () => Store.getters["CmnMst/get"],
      (data) => {
        if (data) {
          this.CMN_MST_PURPOSE_OF_USAGE = [
            ...data.entries.cmn_mst_fire_purpose_uses,
          ];
          this.CMN_MST_FIRE_TYPE = [...data.entries.cmn_mst_fire_types];
          this.CMN_MST_MANAGEMENT_METHOD = [
            ...data.entries.cmn_mst_fire_management_methods,
          ];
          this.CMN_MST_PURPOSE_OF_USAGE.unshift({
            id: null,
            purpose_use: "",
          });
          this.CMN_MST_FIRE_TYPE.unshift({
            id: null,
            type: "",
          });
          this.CMN_MST_MANAGEMENT_METHOD.unshift({
            id: null,
            management_method: "",
          });
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (this.items?.length == 0) {
          this.addEmpty();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      const itemEdited = { ...this.items[index] };
      itemEdited[value.name] = value.value;
      const items = [...this.items];
      items[index] = itemEdited;
      this.$emit("onInput", { name: this.name, value: items });
    },
    remove(index) {
      const items = this.items.filter((item, i) => {
        return index != i;
      });
      this.$emit("onInput", { name: this.name, value: items });
    },
    addEmpty() {
      let _items = [...this.items];
      _items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    getProhibitTime(item) {
      let hot_work_start = item.hot_work_start ? item.hot_work_start : "";
      let hot_work_end = item.hot_work_end ? item.hot_work_end : "";
      return hot_work_start != "" && hot_work_end != ""
        ? hot_work_start + "～" + hot_work_end
        : "";
    },
  },
};
</script>
