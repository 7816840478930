<template>
  <div>
    <v-container>
      <v-list-item>
        <v-list-item-content>
          <SectionLabel label="残業" />
        </v-list-item-content>
      </v-list-item>
      <v-main>
        <v-row>
          <v-list-item>
            <v-list-item-content>
              <v-col cols="12" sm="3" md="3">
                <Label label="時間" :editable="editable">
                  <Select
                    name="overtime_hour"
                    :values="item"
                    :items="NUMBER_OF_DAYS"
                    :editable="editable"
                    @onInput="onInput"
                  />
                </Label>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="mt-3">
                <Label label="" :editable="editable">
                  <InputText
                    name="overtime_start"
                    :values="item"
                    :editable="false"
                    placeholder=""
                    @onInput="onInput"
                  />
                </Label>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <Label label="" :editable="editable" class="mt-3">
                  <InputText
                    name="overtime_end"
                    :values="item"
                    :editable="false"
                    placeholder=""
                    @onInput="onInput"
                  />
                </Label>
              </v-col>
            </v-list-item-content>
          </v-list-item>
        </v-row>
      </v-main>
    </v-container>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "../../../elements/Label.vue";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "../../../elements/InputText.vue";
import Select from "../../../elements/Select.vue";
import { NUMBER_OF_DAYS, time_convert } from "@/constants/SCHEDULE_COMPANY";

export default {
  data: () => {
    return {
      item: {},
      NUMBER_OF_DAYS,
    };
  },
  components: {
    Label,
    SectionLabel,
    InputText,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    timeZone: {
      type: Object
    }
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        let _item = newValue[0][newValue[1]];
        if(this.timeZone) {
          _item.overtime_start = this.timeZone.end_time;
        }
        if(_item) _item.overtime_hour = _item.overtime_hour ? _item.overtime_hour : "0.00";
        if(_item) {
          _item.overtime_end =_item.overtime_hour != "0.00" ? this.calculateTimeOverTimeEnd(_item) : null;
        }
        this.item = { ..._item};
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({name, value }) {
      // 必ずコピーして変更をします
      let itemEdited = { ...this.item };
      itemEdited[name] = value;
      this.item = itemEdited;
      if(itemEdited['overtime_hour'] != "0.00") {
       itemEdited.overtime_end = this.calculateTimeOverTimeEnd(itemEdited);
      } else {
        itemEdited.overtime_end = null;
      }
      this.$emit("onInput", { name: this.name, value: itemEdited });
    },
    calculateTimeOverTimeEnd(itemEdited) {
      let timeSplit = itemEdited.overtime_start.split(":");
      let time = parseInt(timeSplit[0]) * 60 + parseInt(timeSplit[1]) + itemEdited.overtime_hour * 60;
      time = time % (24 * 60);
      return time_convert(time);
    },
  },
};
</script>
