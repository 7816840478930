<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row class="fixed-header">
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content class="cst-header">
                      入力項目
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content class="cst-header">
                      必須入力
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content class="cst-header">
                      ビューア表示
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content class="cst-header">
                      出力項目
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 日付 1 -->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      日付 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.passage_date['form_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.passage_date['required']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.passage_date['timebar_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.passage_date['report_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 工事 2 -->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      工事 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_construction_id['form_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_construction_id['required']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_construction_id['timebar_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_construction_id['report_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 作業グループ 3 -->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      作業グループ 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_tree_group_id['form_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_tree_group_id['required']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_tree_group_id['timebar_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_tree_group_id['report_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 車輌手配業者 4 -->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      車輌手配業者 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_tree_id['form_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_tree_id['required']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_tree_id['timebar_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_tree_id['report_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 職長 5-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      職長 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.foreman_user_id['form_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.foreman_user_id['required']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.foreman_user_id['timebar_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.foreman_user_id['report_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 作業時間帯 6-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      作業時間帯 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.work_time_zone['form_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.work_time_zone['required']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.work_time_zone['timebar_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.work_time_zone['report_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- ゲート 7-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      ゲート 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.gate_id['form_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.gate_id['required']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.gate_id['timebar_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.gate_id['report_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 車輌 8-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      車輌 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.truck_name['form_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.truck_name['required']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.truck_name['timebar_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.truck_name['report_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 台数 9-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      台数 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.truck_count['form_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.truck_count['required']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.truck_count['timebar_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.truck_count['report_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 荷種 10-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      荷種 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.luggage['form_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.luggage['required']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.luggage['timebar_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.luggage['report_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 作業分類職種 11-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      作業分類職種 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.occupation['form_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.occupation['required']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.occupation['timebar_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.occupation['report_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 作業内容 12-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      作業内容 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.work_content['form_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.work_content['required']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.work_content['timebar_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.work_content['report_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 作業詳細 13-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      作業詳細 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.work_details['form_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.work_details['required']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.work_details['timebar_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.work_details['report_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 予想される危険 14-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      予想される危険  
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.expected_danger['form_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.expected_danger['required']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.expected_danger['timebar_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.expected_danger['report_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 安全指示事項  15-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      安全指示事項  
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.safety_instruction['form_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.safety_instruction['required']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.safety_instruction['timebar_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.safety_instruction['report_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 搬入出 16-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      搬入出 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.status_code['form_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.status_code['required']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.status_code['timebar_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.status_code['report_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 開始終了時間 17-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      開始終了時間 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.usage_time['form_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.usage_time['required']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.usage_time['timebar_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.usage_time['report_view']"
                        disabled
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 元請担当者 18-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      元請担当者 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_director_id['form_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_director_id['required']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_director_id['timebar_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.field_director_id['report_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <!-- 車輌運転者 19-->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-list-item>
                    <v-list-item-content>
                      車輌運転者 
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.driver_company_name['form_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.driver_company_name['required']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.driver_company_name['timebar_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        v-model="gate_passages.driver_company_name['report_view']"
                        :disabled="!editable"
                        @change="onChange"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import InputDatepicker from "@/components/forms/elements/InputDatepicker";
import Select from "@/components/forms/elements/Select";
import InputTextArea from "@/components/forms/elements/InputTextArea";

export default {
  components: {
    Label,
    SectionLabel,
    InputText,
    Select,
    InputDatepicker,
    InputTextArea,
  },
  props: {
    mainHeight: {
      type: Number,
      default: 0,
    },
    editable: {
      type: Boolean,
      default: false
    },
    gate_passages: {
      type: Object,
      default: {},
    },
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    onChange() {
      this.$emit("formUpdate", { name: `gate_passages`, value: this.gate_passages})
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/forms.scss";
.FormDialog .container>.row>*, .FormDialog .container>.row>*>.row>* {
  padding: 6px!important;
}
.FormDialog .container>.row {
  border-bottom: 1px solid #e9e9e9;
  height: 70px;
  div:first-child .v-list-item {
    top: 10px !important;
  }
  div .v-list-item .v-list-item__content .v-input--checkbox {
    padding-left: 9px;
  }
}
.fixed-header {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
}
.cst-header {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  padding-left: 11px !important;
  line-height: 1.75rem;
}
::v-deep .FormMainBody .container .row:not(:first-child) {
  font-size: 16px !important;
}
</style>
