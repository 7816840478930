import { render, staticRenderFns } from "./SafetyOtherCompanyForm.vue?vue&type=template&id=60f6b34f&scoped=true&"
import script from "./SafetyOtherCompanyForm.vue?vue&type=script&lang=js&"
export * from "./SafetyOtherCompanyForm.vue?vue&type=script&lang=js&"
import style0 from "./SafetyOtherCompanyForm.vue?vue&type=style&index=0&id=60f6b34f&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60f6b34f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAppBar,VBtn,VIcon,VSpacer,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider,VToolbarTitle})
