<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row v-if="isShowSafetyInstruction">
          <v-col cols="12" sm="11" md="11">
            <v-list-item>
              <v-list-item-content>
                <!-- 安全指示事項 -->
                <Label label="安全指示事項" :editable="editable" :required="isRequiredSafetyInstruction">
                  <Select
                    name="cmn_mst_safety_instruction_id"
                    :values="item"
                    item_text="safety_instruction"
                    :items="selectArrays"
                    :editable="editable"
                    validation_label="安全指示事項"
                    :validation_rules="isRequiredSafetyInstruction?'required':''"
                    @onInput="onInput({ value: $event, index})"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col
            v-if="index > 0 && editable"
            cols="12"
            sm="1"
            md="1"
            class="pt-0 pb-0"
          >
            <v-btn class="mt-8" icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-btn v-if="editable && isShowSafetyInstruction" class="ml-4" color="primary" @click="addEmpty" depressed small>追加</v-btn>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "@/components/forms/elements/Label";
import Select from "@/components/forms/elements/Select";

//初期値を設定します
const INITIAL_VALUES = {
  cmn_mst_safety_instruction_id: null,
};

export default {
  data: () => {
    return {
      items: [],
    };
  },
  components: {
    Label,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    selectArrays: {
      type: Array,
    },
    isShowSafetyInstruction: {
      type: Boolean,
      default: true
    },
    isRequiredSafetyInstruction: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (this.items?.length == 0) {
          this.addEmpty();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      this.items.push({ ...INITIAL_VALUES });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/forms.scss";
</style>
