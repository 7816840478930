<template>
  <v-row justify="center">
    <v-navigation-drawer
      absolute
      right
      v-model="isActive"
      :width="width"
      :max-width="maxWidth"
    >
      <!-- コンポーネントを毎回削除 -->
      <div v-if="isActive" v-click-outside="close">
        <v-list-item class="pa-4">
          <v-icon @click="close">mdi-close</v-icon>
        </v-list-item>
        <slot />
      </div>
    </v-navigation-drawer>
  </v-row>
</template>

<script>
import { Store } from "@/store/Store.js";

/* storeを利用したdrawer */

export default {
  data() {
    return {
      isActive: false,
    };
  },
  props: {
    width: {
      type: String,
      defaultValue: "100%",
    },
    maxWidth: {
      type: String,
      defaultValue: "100%",
    },
  },

  mounted() {
    this.$watch(
      () => [Store.getters["Notification/getState"], this.isActive],
      (data) => {
        const { isActive } = data[0];
        this.isActive = isActive;
      },
      {
        immidiate: true,
        deep: true,
      }
    );
  },
  methods: {
    close() {
      Store.dispatch("Notification/getNewNoticeStatus");
      Store.dispatch("Notification/close");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
  z-index: 99999;
}
</style>
