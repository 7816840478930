<template>
  <v-container>
    <div>
      <v-row class="pa-5">
        <v-col cols="12" sm="4" md="4" class="pa-0">
          <v-data-table
            :headers="MEMO_LABELS"
            :items="dataMemo"
            hide-default-footer
            disable-sort
            :height="mainHeight"
            fixed-header
            class="elevation-0 v-data-table__wrapper"
          >
          </v-data-table>
        </v-col>
        <v-col cols="12" sm="8" md="8" class="pa-0">
          <v-data-table
            :headers="INSPECTION_DETAIL_LABELS"
            :items="itemDetail"
            hide-default-footer
            :height="mainHeight"
            fixed-header
            disable-sort
            :items-per-page="itemDetail.length"
            class="elevation-0 v-data-table__wrapper"
            :noDataText="NO_DATA_MESSAGE"
          >
            <template v-slot:[`item.inspection_status`]="{ item }">
              <v-icon color="green">
                {{ getIconStatus(item.inspection_status) }}
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import {
  MEMO_LABELS,
  INSPECTION_DETAIL_LABELS,
  OVERALL_CONDITION_STATUS,
} from "@/constants/MACHINE_FIELD";

export default {
  data: () => {
    return {
      NO_DATA_MESSAGE,
      MEMO_LABELS,
      INSPECTION_DETAIL_LABELS,
      itemDetail: [],
      dataMemo: [],
    };
  },
  components: {},
  props: {
    item: Object,
    mainHeight: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.$watch(
      () => this.item,
      (data) => {
        this.itemDetail = [
          ...data.machine_company_field_inspection_item_results,
        ];
        this.dataMemo = [{ memo: data.memo }];
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    getIconStatus(inspectionStatus) {
      let icon = "";
      switch (inspectionStatus) {
        case OVERALL_CONDITION_STATUS.GOOD.value:
          icon = "mdi-check-circle";
          break;
        case OVERALL_CONDITION_STATUS.IN_REPAIR.value:
          icon = "mdi-checkbox-blank-circle-outline";
          break;
        case OVERALL_CONDITION_STATUS.NEED_REPAIR.value:
          icon = "mdi-close-circle";
          break;
      }
      return icon;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-data-table-header tr th span {
  font-size: larger;
}
::v-deep .v-data-table .v-data-table__wrapper table thead tr{
  position: sticky;
  z-index: 2;
  top: 0;
  background-color: #FFFFFF !important;
}
</style>
