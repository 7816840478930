import { render, staticRenderFns } from "./WorksDataTableOba.vue?vue&type=template&id=67e0aebb&scoped=true&"
import script from "./WorksDataTableOba.vue?vue&type=script&lang=js&"
export * from "./WorksDataTableOba.vue?vue&type=script&lang=js&"
import style0 from "./WorksDataTableOba.vue?vue&type=style&index=0&id=67e0aebb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67e0aebb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
installComponents(component, {VBadge,VBtn,VChip,VCol,VDataTable,VIcon,VRow,VSimpleCheckbox})
