<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      hide-default-header
      hide-default-footer
    >
    </v-data-table>
  </div>
</template>
<script>
export default {
  name:"Table",
  props: {
    headers: {
      type: Array,
    },
    items: {
      type: Array,
    },
  },
};
</script>
