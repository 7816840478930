<template>
  <v-card class="dialog">
    <v-card-title>
      <div class="title">コメント</div>
    </v-card-title>
    <v-card-text class="body">
      <span v-if="editable"> コメント</span>
      <InputTextArea
        name="comment"
        :values="formValues"
        :editable="editable"
        @onInput="onInput"
        validation_label="コメント"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="warning"
        class="btn"
        @click="$emit('close')"
      >
        閉じる
      </v-btn>
      <v-btn v-if="editable" :key="flagHandleConfirm" depressed class="btn" color="warning" @click.once="handleConfirm">
        保存
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Label from "@/components/forms/elements/Label";
import InputTextArea from "@/components/forms/elements/InputTextArea.vue";

export default {
  data() {
    return {
      formValues: {}
    };
  },
  components: {
    InputTextArea,
    Label
  },
  props: {
    items: Object,
    flagHandleConfirm: Boolean,
    editable: Boolean
  },
  mounted() {
    this.$watch(
      () => this.items,
      (newValue) => {
        this.formValues = newValue;
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  methods: {
    handleConfirm() {
      this.$emit("yes", this.formValues);
    },
    onInput({ name, value }) {
      const _formValues = { ...this.formValues };
      _formValues[name] = value;
      this.formValues = _formValues;
    }
  }
};
</script>
<style scoped>
.title {
  color: #FF7B52
}
</style>