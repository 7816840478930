<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <ValidationObserver ref="observer" v-slot="observer">
        <v-card flat>
          <v-card-text>
            <v-form autocomplete="off">
              <v-container>
                <div>
                <v-row v-if="!editMode" cols="12" sm="6" md="6">
                  <v-col class="mode-fix">
                    <v-btn
                      depressed
                      class="mx-2"
                      color="primary"
                      data-testid="add-new-btn"
                      :disabled="dataItems.length == 0 || roleUser != ROLE_USER[4]"
                      @click="editMode = true"
                    >
                      数量・単価編集
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="editMode" cols="12" sm="6" md="6">
                  <v-col cols="12" sm="3" md="3">
                    <v-btn
                      v-if="roleUser == ROLE_USER[4]"
                      class="mr-3"
                      depressed
                      outlined
                      color="primary"
                      @click="closeUpdate"
                    >
                      やめる
                    </v-btn>
                    <v-btn
                      v-if="roleUser == ROLE_USER[4]"
                      :disabled="!valid"
                      depressed
                      color="primary"
                      data-testid="btn-submit"
                      :key="flagUpdateEstimateOrder"
                      @click.once="updateEstimateOrder"
                    >
                      保存
                    </v-btn>
                  </v-col>
                </v-row>
                </div>
                <v-data-table
                  :headers="TABLE_LABELS_FORM_MACHINE"
                  v-model="selectedItems"
                  :items="dataItems"
                  :items-per-page="dataItems.length"
                  fixed-header
                  item-key="machine_company_field_estimate_order_relation_detail_id"
                  hide-default-footer
                  hide-default-header
                  disable-sort
                  :show-select="true"
                  class="elevation-0 v-data-table__wrapper"
                  sort-by="updatedAt"
                  :noDataText="NO_DATA_MESSAGE"
                  @click:row="openMachineDetailForm"
                >
                  <template slot="header" :headers="TABLE_LABELS_FORM_MACHINE">
                    <thead>
                    <tr>
                      <th style="width: 50px">
                        <v-simple-checkbox
                          :value="selectedItems.length === dataItems.length && dataItems.length > 0"
                          @input="clickCheckAll($event)"
                        ></v-simple-checkbox>
                      </th>
                      <th v-for="(h,index) in TABLE_LABELS_FORM_MACHINE" :key="h.value" :style="getWidthStyle(index)">
                        <div v-for="text in convertTextHeaderArr(h.text)" :key="text">
                          <div>{{ text }}</div>
                        </div>
                      </th>
                    </tr>
                    </thead>
                  </template>
                  <template v-slot:[`item.estimate_status`]="{ item }" label small v-if="flagEstimate">
                    <v-chip small :color="setColor(item.estimate_status)">
                      {{ getStatusValue(item.estimate_status) }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.machine_company_type_item_tree_name`]="{ item }" label small>
                    <div class="cst-overflow-td">{{ item.machine_company_type_item_tree_name }}</div>
                    <div class="cst-overflow-td">{{ item.machine_name }}</div>
                  </template>
                  <template v-slot:[`item.estimate_amount`]="{ item }" label small v-if="flagEstimate">
                    <div v-if="editMode" style="margin-top: 20px; min-width: 100px">
                      <InputText
                        name="estimate_amount"
                        :values="item"
                        :editable="true"
                        validation_label="見積量"
                        validation_rules="estimate-order-amount|digit"
                        @onInput="onInput({value: $event, id: item.machine_company_field_estimate_order_relation_detail_id})"
                      />
                    </div>
                    <div v-else>{{ item.estimate_amount }}</div>
                  </template>
                  <template v-slot:[`item.estimate_unit_price`]="{ item }" label small v-if="flagEstimate">
                    <div v-if="editMode" style="margin-top: 20px; min-width: 100px">
                      <InputText
                        name="estimate_unit_price"
                        :values="item"
                        :editable="true"
                        validation_label="単価"
                        validation_rules="decimal|max-price"
                        prefix="￥"
                        @onInput="onInput({value: $event, id: item.machine_company_field_estimate_order_relation_detail_id})"
                      />
                    </div>
                    <div v-else style="min-width: 120%">{{convertCurrentcy(item.estimate_unit_price)}}</div>
                  </template>
                  <template v-slot:[`item.estimate_total_amount`]="{ item }" label small v-if="flagEstimate">
                    <div style="min-width: 120%">{{convertCurrentcy(item.estimate_total_amount)}}</div>
                  </template>
                  <template v-slot:[`item.estimate_scheduled_delivery_date`]="{ item }" label small v-if="flagEstimate">
                    <div>{{ convertDate(item.estimate_scheduled_delivery_date) }}</div>
                  </template>
                  <template v-slot:[`item.estimate_remarks`]="{ item }" label small v-if="flagEstimate">
                    <div v-if="editMode" style="margin-top: 20px; min-width: 100px">
                      <InputText
                        name="estimate_remarks"
                        :values="item"
                        :editable="true"
                        validation_label="備考"
                        validation_rules="max:512"
                        @onInput="onInput({value: $event, id: item.machine_company_field_estimate_order_relation_detail_id})"
                      />
                    </div>
                    <div v-else>{{ item.estimate_remarks }}</div>
                  </template>
                  <template v-slot:[`item.order_status`]="{ item }" label small v-if="!flagEstimate">
                    <v-chip small :color="setColor(item.order_status)">
                      {{ getStatusValue(item.order_status) }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.order_delivery_amount`]="{ item }" label small v-if="!flagEstimate">
                    <div v-if="editMode" style="margin-top: 20px; min-width: 100px">
                      <InputText
                        name="order_delivery_amount"
                        :values="item"
                        :editable="true"
                        validation_label="納品数"
                        validation_rules="estimate-order-amount|digit"
                        @onInput="onInput({value: $event, id: item.machine_company_field_estimate_order_relation_detail_id})"
                      />
                    </div>
                    <div v-else>{{ item.order_delivery_amount }}</div>
                  </template>
                  <template v-slot:[`item.order_unit_price`]="{ item }" label small v-if="!flagEstimate">
                    <div v-if="editMode" style="margin-top: 20px; min-width: 100px">
                      <InputText
                        name="order_unit_price"
                        :values="item"
                        :editable="true"
                        validation_label="単価"
                        validation_rules="decimal|max-price"
                        prefix="￥"
                        @onInput="onInput({value: $event, id: item.machine_company_field_estimate_order_relation_detail_id})"
                      />
                    </div>
                    <div v-else style="min-width: 120%">{{ convertCurrentcy(item.order_unit_price)}}</div>
                  </template>
                  <template v-slot:[`item.order_total_amount`]="{ item }" label small v-if="!flagEstimate">
                    <div style="min-width: 120%">{{convertCurrentcy(item.order_total_amount)}}</div>
                  </template>
                  <template v-slot:[`item.order_scheduled_delivery_date`]="{ item }" label small v-if="!flagEstimate">
                    <div>{{ convertDate(item.order_scheduled_delivery_date) }}</div>
                  </template>
                  <template v-slot:[`item.order_remarks`]="{ item }" label small v-if="!flagEstimate">
                    <div v-if="editMode" style="margin-top: 20px; min-width: 100px">
                      <InputText
                        name="order_remarks"
                        :values="item"
                        :editable="true"
                        validation_label="備考"
                        validation_rules="max:512"
                        @onInput="onInput({value: $event, id: item.machine_company_field_estimate_order_relation_detail_id})"
                      />
                    </div>
                    <div v-else>{{ item.order_remarks }}</div>
                  </template>
                </v-data-table>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
        <Popup :dialog="popups.isShowMachineDetailForm">
          <MachineDetailForm
            :flagEstimate="flagEstimate"
            :roleUser="roleUser"
            @updateList="updateList"
            @cancel="popups.isShowMachineDetailForm = false"
          />
        </Popup>
        <Popup width="480px" :dialog="popups.isShowConfirmDialog">
          <ConfirmCloseDialog
            title="フォームを閉じる確認"
            text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
            text2="このページから移動してもよろしいですか？"
            @close="onClickBtnCancelForm"
            @yes="cancelUpdate"
            warning
          />
        </Popup>
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "@/components/forms/elements/Label.vue";
import InputText from "@/components/forms/elements/InputText.vue";
import Select from "@/components/forms/elements/Select.vue";
import Popup from "@/components/common/Popup";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import {
  TABLE_LABELS_FORM_MACHINE_ESTIMATE,
  TABLE_LABELS_FORM_MACHINE_ORDER,
  BILL_STATUS_ESTIMATE,
  BILL_STATUS_ORDER,
  ROLE_USER
} from "@/constants/DEVICE_COORDINATOR";
import MachineDetailForm from "@/components/forms/device/coordinator/estimateOrder/machineDetail/MachineDetailForm.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";

const STORE = "DeviceCoordinator";
import _ from "lodash";

export default {
  data: () => {
    return {
      valid: false,
      ROLE_USER,
      NO_DATA_MESSAGE,
      TABLE_LABELS_FORM_MACHINE_ESTIMATE,
      TABLE_LABELS_FORM_MACHINE_ORDER,
      selectedItems: [],
      editMode: false,
      idsEdit: [],
      popups: {
        isShowMachineDetailForm: false,
        isShowConfirmDialog: false
      },
      dataItems: [],
      flagUpdateEstimateOrder: false,
      beforeUpdateItems: []
    };
  },
  components: {
    Label,
    InputText,
    Select,
    Popup,
    MachineDetailForm,
    ConfirmCloseDialog,
    ValidationCallback,
    ValidationObserver
  },
  props: {
    item: Object,
    mainHeight: {
      type: Number,
      default: 0
    },
    flagEstimate: Boolean,
    roleUser: Number,
    resetSelected : Boolean
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        if (newValue?.machine_company_field_estimate_order_relations) {
          this.dataItems = _.cloneDeep(newValue.machine_company_field_estimate_order_relations);
          this.beforeUpdateItems = _.cloneDeep(newValue.machine_company_field_estimate_order_relations);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => this.resetSelected,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          this.selectedItems = [];
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => this.selectedItems,
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.$emit("updateSelectedItems", newValue);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    TABLE_LABELS_FORM_MACHINE() {
      return this.flagEstimate ? this.TABLE_LABELS_FORM_MACHINE_ESTIMATE : this.TABLE_LABELS_FORM_MACHINE_ORDER;
    }
  },
  methods: {
    convertCurrentcy(price) {
      if (price) {
        let number = Intl.NumberFormat("en-US");
        return ("￥" + number.format(price))|| "";
      } else {
        return "";
      }
    },
    updateValidate({ valid }) {
      this.valid = valid;
    },
    onInput({ value, id }) {
      if (this.idsEdit.indexOf(id) == -1) {
        this.idsEdit.push(id);
      }
      const arr = [...this.dataItems];
      arr.forEach(item => {
        if (item.machine_company_field_estimate_order_relation_detail_id == id) {
          item[value.name] = value.value;
          if (["estimate_unit_price", "estimate_amount", "order_unit_price", "order_delivery_amount"].includes(value.name)) {
            if (this.checkToCaculate(value.name, value.value)) {
              this.flagEstimate ? item.estimate_total_amount = item.estimate_amount * item.estimate_unit_price : item.order_total_amount = item.order_delivery_amount * item.order_unit_price;
            } else {
              this.flagEstimate ? item.estimate_total_amount = "" : item.order_total_amount = "";
            }
          }
        }
      });
      this.dataItems = arr;
    },
    async openMachineDetailForm(item) {
      if (!this.editMode) {
        this.flagEstimate ? await Store.dispatch(`${STORE}/getMachineEstimateDetail`, item.machine_company_field_estimate_order_relation_detail_id) :
          await Store.dispatch(`${STORE}/getMachineOrderDetail`, item.machine_company_field_estimate_order_relation_detail_id);
        this.popups.isShowMachineDetailForm = true;
      }
    },
    convertTextHeaderArr(textHeader) {
      let textHeaderArr = [];
      if (textHeader.includes(" / ")) {
        textHeaderArr = textHeader.split(" / ");
        textHeaderArr[1] = "/ ".concat(textHeaderArr[1]);
      } else {
        textHeaderArr.push(textHeader);
      }
      return textHeaderArr;
    },
    getWidthStyle(column) {
      let style = "";
      if (this.flagEstimate) {
        if (column == 0) {
          style = "width: 100px";
        } else if (column == 3) {
          style = "width: 10vw";
        } else {
          style = "width: 5vw";
        }
      } else {
        if (column == 0) {
          style = "width: 50px";
        } else if (column == 1) {
          style = "width: 10vw";
        } else {
          style = "width: 5vw";
        }
      }
      return style;
    },
    clickCheckAll(event) {
      if (event) {
        this.selectedItems = this.dataItems;
      } else {
        this.selectedItems = [];
      }
    },
    closeUpdate() {
      this.popups.isShowConfirmDialog = true;
    },
    onClickBtnCancelForm() {
      this.popups.isShowConfirmDialog = false;
    },
    cancelUpdate() {
      this.dataItems = _.cloneDeep(this.beforeUpdateItems);
      this.popups.isShowConfirmDialog = false;
      this.editMode = false;
      this.idsEdit = [];
    },
    setColor(status) {
      return this.flagEstimate ? BILL_STATUS_ESTIMATE[status].color : BILL_STATUS_ORDER[status].color;
    },
    getStatusValue(status) {
      return this.flagEstimate ? BILL_STATUS_ESTIMATE[status].name : BILL_STATUS_ORDER[status].name;
    },
    async updateEstimateOrder() {
      let itemEdit = [];
      const arr = [...this.dataItems];
      arr.forEach(item => {
        if (this.idsEdit.indexOf(item.machine_company_field_estimate_order_relation_detail_id) != -1) {
          if (this.flagEstimate) {
            itemEdit.push({
              id: item.machine_company_field_estimate_order_relation_detail_id,
              estimate_amount: item.estimate_amount,
              estimate_unit_price: item.estimate_unit_price,
              estimate_remarks: item.estimate_remarks
            });
          } else {
            itemEdit.push({
              id: item.machine_company_field_estimate_order_relation_detail_id,
              order_delivery_amount: item.order_delivery_amount,
              order_unit_price: item.order_unit_price,
              order_remarks: item.order_remarks
            });
          }
        }
      });
      const params = { machine_company_field_estimate_order_relation_details: itemEdit };
      const rs = this.flagEstimate ? await Store.dispatch(`${STORE}/updateEstimateUnitPrice`, params) : await Store.dispatch(`${STORE}/updateOrderUnitPrice`, params);
      if (rs.hasError) {
        this.flagUpdateEstimateOrder = !this.flagUpdateEstimateOrder;
      } else {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        this.popups.isShowConfirmDialog = false;
        this.editMode = false;
        this.idsEdit = [];
        this.$emit("updateDetailMachine");
      }
    },
    updateList() {
      this.$emit("updateDetailMachine");
    },
    convertDate(date) {
      if (date) {
        return date.replaceAll("-", "/");
      } else {
        return "";
      }
    },
    checkToCaculate(name, value) {
      let rs = false;
      if (name == "estimate_unit_price" || name == "order_unit_price") {
        if (String(value).match(/^\d+(\.\d{1,2})?$/) && value < 1000000000000) {
          rs = true;
        }
      } else {
        if (String(value).match(/^[0-9]*$/)) {
          rs = true;
        }
      }
      return rs;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";

.cst-overflow-td {
  width: 15vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep .v-data-table .v-data-table__wrapper table tbody tr td {
  word-break: break-all;
}

.mode-fix {
  padding: 0 0 12px 0;
}
</style>
