<template>
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('openItemForm', 2, name_type,name_title)"
  >
    <path
      d="M6.50001 5.43766H7.20834V7.56266H6.50001V5.43766ZM2.95834 6.146H3.66668V5.43766H2.95834V6.146ZM12.1667 2.25016V10.7502C12.1667 11.1259 12.0174 11.4862 11.7517 11.7519C11.4861 12.0176 11.1257 12.1668 10.75 12.1668H2.25001C1.87429 12.1668 1.51395 12.0176 1.24828 11.7519C0.982599 11.4862 0.833344 11.1259 0.833344 10.7502V2.25016C0.833344 1.87444 0.982599 1.5141 1.24828 1.24843C1.51395 0.982752 1.87429 0.833496 2.25001 0.833496H10.75C11.1257 0.833496 11.4861 0.982752 11.7517 1.24843C12.0174 1.5141 12.1667 1.87444 12.1667 2.25016ZM4.72918 5.43766C4.72918 5.15587 4.61723 4.88562 4.41798 4.68636C4.21872 4.4871 3.94847 4.37516 3.66668 4.37516H1.89584V8.62516H2.95834V7.2085H3.66668C3.94847 7.2085 4.21872 7.09655 4.41798 6.8973C4.61723 6.69804 4.72918 6.42779 4.72918 6.146V5.43766ZM8.27084 5.43766C8.27084 5.15587 8.1589 4.88562 7.95964 4.68636C7.76039 4.4871 7.49014 4.37516 7.20834 4.37516H5.43751V8.62516H7.20834C7.49014 8.62516 7.76039 8.51322 7.95964 8.31396C8.1589 8.11471 8.27084 7.84445 8.27084 7.56266V5.43766ZM11.1042 4.37516H8.97918V8.62516H10.0417V7.2085H11.1042V6.146H10.0417V5.43766H11.1042V4.37516Z"
      fill="black"
    />
  </svg>
</template>
<script>
export default {
  props: {
    name_type: String,
    name_title:String
  },
};
</script>

