<template>
  <div>
    <v-container>
      <v-row v-for="(item, index) in items" :key="index">
        <v-col cols="12" sm="10" md="10" class="pt-0 pb-0 mt-1">
          <Label label="作業時間帯名称" :editable="editable">
            <InputText
              name="time_zone_name"
              :values="item"
              :editable="editable"
              placeholder=""
              validation_label="作業時間帯名称"
              :validation_rules="`max:255|${getDuplicateRules()}`"
              @onInput="onInput({ value: $event, index })"
            />
          </Label>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <Label label="作業開始時刻" :editable="editable" :required="Boolean(!item.work_start_time && item.work_end_time)" />
              <Label
                label="（早出終了時刻）"
                :editable="editable"
              />
              <Select
                name="work_start_time"
                :values="item"
                :items="PROHIBIT_TIME_START"
                :editable="editable"
                validation_label="作業開始時刻"
                :validation_rules="!item.work_start_time && item.work_end_time ? 'required' : ''"
                @onInput="onInput({ value: $event, index })"
              />
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <Label label="作業終了時刻" :editable="editable" :required="Boolean(item.work_start_time && !item.work_end_time)" />
              <Label
                label="（残業開始時刻）"
                :editable="editable"
              />
              <Select
                name="work_end_time"
                :values="item"
                :items="PROHIBIT_TIME_END"
                :editable="editable"
                validation_label="作業終了時刻"
                :validation_rules="item.work_start_time && !item.work_end_time ? 'required' : ''"
                @onInput="onInput({ value: $event, index })"
              />
            </v-col>
            <v-col cols="12" sm="4" md="4" style="display: flex; flex-direction: column; justify-content: space-between">
              <Label
                label="予定→実績反映時刻"
                :editable="editable"
              />
              <Select
                name="work_copy_time"
                :values="item"
                :items="TIME_HOUR"
                :editable="editable"
                validation_label="予定→実績反映時刻"
                validation_rules=""
                @onInput="onInput({ value: $event, index })"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="2" md="2" class="pt-0 pb-0">
          <v-btn
            v-if="index > 0 && editable"
            class="mt-8"
            icon
            @click="remove(index)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-btn @click="addEmpty" color="primary" v-if="editable" class="mt-6"
      >追加</v-btn
    >
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "../../elements/Label.vue";
import Select from "../../elements/Select.vue";
import InputText from "../../elements/InputText.vue";
import {
  PROHIBIT_TIME_START,
  PROHIBIT_TIME_END,
  TIME_HOUR,
} from "@/constants/SITES";
//初期値を設定します
const INITIAL_VALUES = {
  time_zone_name: "",
  work_start_time: null,
  work_end_time: null,
  work_copy_time: null,
};

export default {
  data: () => {
    return {
      items: [],
      PROHIBIT_TIME_START,
      PROHIBIT_TIME_END,
      TIME_HOUR,
    };
  },
  components: {
    Select,
    Label,
    InputText,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
  },
  mounted() {
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.items = newValue[0][newValue[1]];
          if (this.items?.length == 0) {
            this.addEmpty();
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.setValueDefault();
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      let _items = [...this.items];
      _items[index][value.name] = value.value;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      let _items = [...this.items];
      _items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    setValueDefault() {
      this.PROHIBIT_TIME_START.unshift({ id: null, name: "" });
      this.PROHIBIT_TIME_END.unshift({ id: null, name: "" });
      this.TIME_HOUR.unshift({ id: null, name: "" });  
    },
    getDuplicateRules() {
      if(this.items && this.items.length > 1) {
        let nameList = this.items.map(item => item.time_zone_name);
        let tempSet = [...new Set(nameList)];
        let isDuplicate = nameList.length === tempSet.length;
        return `duplicate-time-zone-name:${isDuplicate}`;
      }
      return "";
    }
  },
};
</script>
