<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row no-gutters>
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <Label label="技能検定職種" :editable="editable">
                  <InputText
                    name="catergory"
                    :values="item"
                    :editable="editable"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <Label label="免許・資格名" :editable="editable">
                  <InputText
                    name="sub_catergory"
                    :values="item"
                    :editable="editable"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
  </div>
</template>
<script>

import Label from "@/components/forms/elements/Label.vue";
import InputText from "@/components/forms/elements/InputText.vue";
const INITIAL_VALUES = {
  category: null,
  sub_category: null
};

export default {
  data: () => {
    return {
      items: []
    };
  },
  components: {
    InputText,
    Label,
  },
  props: {
    name: {
      type: String
    },
    values: {
      type: Object
    },
    editable: {
      type: Boolean
    }
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        this.items = newValue[0][newValue[1]];
        if (this.items?.length == 0) {
          this.addEmpty();
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  methods: {
    addEmpty() {
      this.items.push({ ...INITIAL_VALUES });
    },
  }
};
</script>
