<template>
  <div>
    <video id="myVideo" width="400px" autoplay muted playsinline></video>
    <div>
      <v-btn @click="join">JOIN</v-btn>
      <v-btn @click="close">CLOSE</v-btn>
    </div>
    <!-- メンバー動画 -->
    <div id="remoteVideos"></div>
  </div>
</template>
<script>
import Peer from "skyway-js";

const VideoChat = () => {
  let localStream;
  let chatRoomId;
  let peer;
  let room;
  let remoteVideos;

  const init = ({ id, roomId }) => {
    // peer接続
    peer = new Peer({ key: "98094e78-2219-433f-8a48-e58f6c4a83f0" });

    //PeerID取得
    peer.on("open", () => {
      console.log("peerId", peer.id);
    });

    // ルーム
    chatRoomId = roomId;

    // リモートビデオ取得
    remoteVideos = document.getElementById("remoteVideos");

    // カメラ映像取得
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        // 成功時にvideo要素にカメラ映像をセットし、再生
        const videoElm = document.getElementById(id);
        videoElm.srcObject = stream;
        videoElm.play();
        // 着信時に相手にカメラ映像を返せるように、グローバル変数に保存しておく
        localStream = stream;
      })
      .catch((error) => {
        // 失敗時にはエラーログを出力
        console.error("mediaDevice.getUserMedia() error:", error);
        return;
      });
  };

  //メンバー追加
  const addMember = async (stream) => {
    const newVideo = document.createElement("video");
    newVideo.srcObject = stream;
    newVideo.playsInline = true;
    newVideo.setAttribute("data-peer-id", stream.peerId);
    remoteVideos.append(newVideo);
    await newVideo.play().catch(console.error);
  };

  //メンバー退出
  const removeMember = (peerId) => {
    const remoteVideo = remoteVideos.querySelector(
      `[data-peer-id="${peerId}"]`
    );
    remoteVideo.srcObject.getTracks().forEach((track) => track.stop());
    remoteVideo.srcObject = null;
    remoteVideo.remove();
  };

  // 閉じる
  const close = () => {
    room.close();
  };

  const join = () => {
    if (!peer.open) {
      return;
    }
    room = peer.joinRoom(chatRoomId, {
      mode: "sfu", //'mesh' or 'sfu'
      stream: localStream,
    });

    room.once("open", () => {
      console.log("=== You joined ===\n");
      updateMembers();
    });

    room.on("peerJoin", (peerId) => {
      console.log(`=== ${peerId} joined ===\n`);
      updateMembers();
    });

    // Render remote stream for new peer join in the room
    room.on("stream", async (stream) => {
      console.log(`=== ${stream.peerId} stream start ===\n`);
      addMember(stream);
    });

    // for closing room members
    room.on("peerLeave", (peerId) => {
      console.log(`=== ${peerId} romoved ===\n`);
      removeMember(peerId);
      updateMembers();
    });

    room.once("close", () => {
      Array.from(remoteVideos.children).forEach((remoteVideo) => {
        remoteVideo.srcObject.getTracks().forEach((track) => track.stop());
        remoteVideo.srcObject = null;
        remoteVideo.remove();
      });
    });
  };

  const updateMembers = () => {
    //参加しているPeerID一覧
    peer.listAllPeers((peers) => {
      console.log([...peers]);
    });
  };

  return {
    init,
    join,
    close,
  };
};

const vChat = VideoChat();

export default {
  mounted() {
    this.initVideo();
  },
  methods: {
    initVideo() {
      vChat.init({ id: "myVideo", roomId: "12345678" });
    },
    join() {
      vChat.join();
    },
    close() {
      vChat.close();
    },
  },
};
</script>
