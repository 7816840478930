<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <ValidationObserver>
            <v-form autocomplete="off">
              <v-container>
                <v-row>
                  <v-col>
                    <v-row no-gutters class="ml-3">
                      <v-col cols="10" sm="4" md="4" style="align-self: center">
                        <SwitchInput
                          name="face_recognition_flag"
                          label="顔認証入退場をする"
                          :values="fieldValues"
                          :editable="editable"
                          @onInput="onInput"
                        />
                      </v-col>
                      <v-col cols="10" sm="3" md="3" style="align-self: center">
                        <SwitchInput
                          name="use_gps_flag"
                          label="認証時GPSの利用"
                          :values="fieldValues"
                          :editable="editable"
                          @onInput="onInput"
                        />
                      </v-col>
                      <v-col id="effective_range_radius" cols="10" sm="3" md="3" class="pr-4">
                        <Label
                          label="有効範囲（半径）"
                          :editable="editable"
                        >
                          <InputText
                            name="effective_range_radius"
                            :values="fieldValues"
                            :editable="editable"
                            suffix="m"
                            validation_label="有効範囲（半径）"
                            validation_rules="integerOnly|decimalLimit"
                            @onInput="onInput"
                          />
                        </Label>
                      </v-col>
                    </v-row>
                    <v-col cols="12" sm="12" md="12">
                      <SwitchInput
                        name="force_checkout_flag"
                        :label="`日またぎの時間を超えたタイミングで入場者を全て退場させる`"
                        :values="fieldValues"
                        :editable="editable"
                        @onInput="onInput"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="fieldValues.face_recognition_flag == 1">
                      <SectionLabel label="デバイス" />
                      <v-list-item class="pdt-12">
                        <v-list-item-content>
                          <FieldEntranceDevices
                            name="field_entrance_devices"
                            :values="formValues"
                            :editable="editable"
                            @onInput="onInput"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-col>
                  <v-col></v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import FieldEntranceDevices from "./components/FieldEntranceDevices.vue";
import SwitchInput from "@/components/forms/elements/SwitchInput";

export default {
  data: () => {
    return {
      fieldValues: {},
      formValues: {},
    };
  },
  components: {
    ValidationObserver,
    SectionLabel,
    Label,
    InputText,
    SwitchInput,
    FieldEntranceDevices,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false
    },
    mainHeight: {
      type: Number,
      default: 0
    },
    formUpdate: Function
  },
  mounted() {
    this.$watch(
      () => this.item,
      (data) => {
        this.fieldValues = { ...data.fields };
        this.formValues = { ...data };
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    }
  },
  methods: {
    onInput({ name, value }) {
      let _formValues = { ...this.formValues };
      if (name == "field_entrance_devices") {
        _formValues["field_entrance_devices"] = value;
      } else {
        _formValues["fields"][name] = value;
      }
      this.$emit("formUpdate", _formValues);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
.row {
  margin-top: 0 !important;
}
.pdt-12 .v-list-item__content {
  padding-top: 12px !important;
}
#effective_range_radius {
  padding: 0 10px !important;
}
</style>
