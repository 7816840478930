<template>
  <v-container>
    <PatrolDataTablesOba
      :tableLabels="tableLabels"
      :items="items"
      :flagNotData="flagNotData"
      :items-per-page="itemsPerPage"
      :tableHeight="tableHeight"
      :isSelected="isSelected"
      :isSelectedParent="isSelectedParent"
      :isShowChildren="isShowChildren"
      :updateCheckbox="updateCheckbox"
      :toggleShowChildren="toggleShowChildren"
      :childTableDisplayMoreNumber="childTableDisplayMoreNumber"
      :NO_DATA_MESSAGE="NO_DATA_MESSAGE"
      :paramsGetList="paramsGetList"
      :itemSelectedScroll="itemSelectedScroll"
      :listDeleteScroll="listDeleteScroll"
      @openItemForm="openItemForm"
      @getChildItems="getChildItems"
      @updateItems="updateItems"
    />
  </v-container>
</template>
<script>
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import PatrolDataTablesOba from "./PatrolDataTablesOba.vue";
import WorksDataTableOba from "@/views/schedule/works/components/WorksDataTableOba.vue";


export default {
  data: () => {
    return {
      isShowChildren: {},
      selectedParent: [],
      NO_DATA_MESSAGE,
    };
  },
  components: {
    WorksDataTableOba,
    PatrolDataTablesOba
  },

  props: {
    tableLabels: Array,
    tableHeight: Number,
    items: Array,
    itemsPerPage: Number,
    update: Function,
    isSelected: Function,
    openItemForm: Function,
    getChildItems: Function,
    checkSelectedParents: Function,
    flagNotData: Boolean,
    reloadList : Boolean,
    paramsGetList: Object,
    itemSelectedScroll: Array,
    listDeleteScroll: Array,
  },

  async mounted() {
    this.$watch(
      () => this.reloadList,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.selectedParent = [];
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => this.items,
      (newValue) => {
        if(newValue) {
          const items = newValue;
          this.initIsShowChildren(items);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  methods: {
    childTableDisplayMoreNumber(totalNum, displayNum) {
      const _number = totalNum - displayNum;
      return _number > 0 ? _number : "0";
    },

    updateSelectedParentItems(status, id, isChecked) {
      let _selectedItems = [];
      // checked
      if (isChecked) {
        _selectedItems = [...this.selectedParent, { parentId: id, status: status }];
      } else {
        // unchecked
        _selectedItems = this.selectedParent.filter((item) => {
          return item.parentId != id;
        });
      }
      // 重複削除してset
      this.selectedParent = [...new Set(_selectedItems)];
    },

    isSelectedParent(id) {
      return Boolean(this.selectedParent.find(e => e.parentId == id));
    },

    updateCheckbox(status, item, isChecked, isParent, itemParent) {
      // parentがchecked
      if (isParent && item.children) {
        this.updateSelectedParentItems(status, item.safety_diaries_id, isChecked);
        // childrenにもcheck入れる
        const childrendIds = item.children.map(child => ({
          childId: child.safety_diary_comment_correction_id,
          status: child.status
        }));
        let temp = {
          parent: this.selectedParent,
          child: childrendIds
        };
        this.$emit("update", temp, isChecked);
      } else {
        let temp = {
          parent: this.selectedParent,
          child: [{ childId: item.safety_diary_comment_correction_id, status: status }]
        };
        this.$emit("update", temp, isChecked);
        // childがchecked
        if (itemParent && isChecked && this.checkSelectedParents(itemParent)) {
          this.selectedParent.push({ parentId: itemParent.safety_diaries_id, status: itemParent.status });
        } else {
          this.selectedParent = this.selectedParent.filter((item) => {
            return item.parentId != itemParent.safety_diaries_id;
          });
        }
        let temp1 = {
          parent: this.selectedParent,
          child: [{ childId: item.safety_diary_comment_correction_id, status: status }]
        };
        this.$emit("update", temp1, isChecked);
      }
    },

    // parent table ごとの child tableの表示ステートをinit
    initIsShowChildren(items) {
      let _isShowChildren = {};
      items.forEach((item) => {
        _isShowChildren[item.safety_diaries_id] = true;
      });
      this.isShowChildren = _isShowChildren;
    },

    // child tableの表示切り替え
    toggleShowChildren(id) {
      this.isShowChildren[id] = !this.isShowChildren[id];
    },
    updateItems (value) {
      this.$emit('updateItems', value)
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
