import Api, { Mock } from "../api";
import mock_machines from "./mock/companyMachines.json";
import mock_machines_type from "./mock/company_machine_type.json";
import mock_company_machines_detail from "./mock/company_machine_detail.json";
import mock_machine_company_by_type from "./mock/mock_machine_company_by_type.json";

import mock_get_company_type_in_spec from "./mock/get_company_type_in_spec.json";
import company_machines_childrens from "./mock/company_machines_childrens.json";
const PATH = "machine_companies";
const URL = `${process.env.VUE_APP_API_BASE_URL}/${PATH}`;
const GET_LIST_URL = `${URL}/list`;
const GET_MACHINE_COMPANY_TYPE = `${URL}/get_machine_company_type`;
const UPDATE_MACHINE_COMPANY_TYPE = `${URL}/update_machine_company_type`;
const GET_LIST_MACHINE_BY_TYPE_URL = `${URL}/list_machine_by_type`;
const GET_MACHINE_COMPANY_INFO = `${URL}/detail`;
const CREATE_MACHINE_COMPANY = `${URL}/create`;
const UPDATE_MACHINE_COMPANY = `${URL}/update`;
const DELETE_MACHINE_COMPANY = `${URL}/delete`;
const GET_CHILDREN_URL = `${URL}/list_by_machine_company`;
const SORT_MACHINE_COMPANY_TYPE = `${URL}/sort_machine_company_type_item_trees`;
const URL_MACHINE_COMPANY_BY_TYPE = `${URL}/get_machine_company_by_type`;
const GET_MACHINE_COMPANY_TYPE_IN_SPEC = `${process.env.VUE_APP_API_BASE_URL}/machine_company_inspection_lists/get`;
const UPDATE_MACHINE_COMPANY_TYPE_IN_SPEC = `${process.env.VUE_APP_API_BASE_URL}/machine_company_inspection_lists/update`;

// .envのVUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  Mock.onGet(GET_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_machines,
  });
  Mock.onGet(new RegExp(`${GET_MACHINE_COMPANY_TYPE}`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_machines_type,
  });

  Mock.onGet(GET_LIST_MACHINE_BY_TYPE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_machines_type,
  });
  Mock.onGet(new RegExp(`${GET_MACHINE_COMPANY_INFO}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_company_machines_detail,
  });
  Mock.onGet(new RegExp(`${GET_CHILDREN_URL}`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: company_machines_childrens,
  });
  Mock.onGet(new RegExp(`${GET_MACHINE_COMPANY_TYPE_IN_SPEC}`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_get_company_type_in_spec,
  });
  Mock.onPost(UPDATE_MACHINE_COMPANY_TYPE_IN_SPEC).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      result: true,
      status_code: 200,
      contents: {
        entries: {
          update_result: "success",
        },
      },
    },
  });
  Mock.onPost(UPDATE_MACHINE_COMPANY_TYPE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      result: true,
      status_code: 200,
      contents: {
        entries: {
          update_result: "success",
        },
      },
    },
  });
  Mock.onPost(CREATE_MACHINE_COMPANY).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      result: true,
      status_code: 200,
      contents: {
        entries: {
          id: "1",
        },
      },
    },
  });
  Mock.onPost(UPDATE_MACHINE_COMPANY).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      result: true,
      status_code: 200,
      contents: {
        entries: {
          id: "1",
        },
      },
    },
  });
  Mock.onGet(new RegExp(`${URL_MACHINE_COMPANY_BY_TYPE}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_machine_company_by_type
  });
  Mock.onGet(new RegExp(`${URL_MACHINE_COMPANY_BY_TYPE}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_machine_company_by_type
  });
}

export const companyMachines = {
  get: (params) => {
    return Api.get(`${GET_LIST_URL}`, params);
  },
  getListMachineType: (requestParams) => {
    return Api.get(`${GET_LIST_MACHINE_BY_TYPE_URL}`, { requestParams });
  },
  getMachineCompanyType: async (params) => {
    return Api.get(`${GET_MACHINE_COMPANY_TYPE}`, { params });
  },
  getMachineDetail: (machineId) => {
    return Api.get(`${GET_MACHINE_COMPANY_INFO}/${machineId}`);
  },
  updateMachineCompanyType: (payload) => {
    return Api.post(UPDATE_MACHINE_COMPANY_TYPE, payload);
  },
  createMachineCompany: (payload) => {
    return Api.post(CREATE_MACHINE_COMPANY, payload);
  },
  updateMachineCompany: (payload) => {
    return Api.post(UPDATE_MACHINE_COMPANY, payload);
  },
  deleteMachineCompany: (payload) => {
    return Api.post(DELETE_MACHINE_COMPANY, payload);
  },
  loadChildren: (params) => {
    return Api.get(GET_CHILDREN_URL, params);
  },
  sortMachineCompanyType: (payload) => {
    return Api.post(SORT_MACHINE_COMPANY_TYPE, payload);
  },
  getMachineCompanyByType: async (params) => {
    return Api.get(URL_MACHINE_COMPANY_BY_TYPE, {params});
  },
  getMachineCompanyTypeInSpec: (payload) => {
    return Api.get(`${GET_MACHINE_COMPANY_TYPE_IN_SPEC}/${payload}`);
  },
  updateCompanyTypeInSpec: (payload) => {
    return Api.post(UPDATE_MACHINE_COMPANY_TYPE_IN_SPEC, payload);
  },
};
