<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <FormConstructionPermit
                name="field_tree_construction_permits"
                :values="formValues"
                :editable="editable"
                @onInput="onInput"
              />
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import FormConstructionPermit from "../../components/FormConstructionPermit.vue"
import { 
  FIELD_TREE_INITIAL_ITEM, 
  CONSTRUCTION_PERMIT_EVENT_TARGET
} from "@/constants/PORTAL_CHART.js";

export default {
  data: () => {
    return {
      formValues: FIELD_TREE_INITIAL_ITEM,
      CONSTRUCTION_PERMIT_EVENT_TARGET
    };
  },
  components: {
    FormConstructionPermit,
  },
  props: {
    // 体制図の中のユニークid
    chart_id: {
      type: Number,
    },
    // 会社id
    company_id: {
      type: Number,
    },
    // 現場id
    site_id: {
      type: Number,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    item: Object,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue?.construction_permit };
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    emit(key, value) {
      const objNew = {};
      objNew[key] = value;
      this.$emit("formUpdate", objNew, CONSTRUCTION_PERMIT_EVENT_TARGET.CONSTRUCTION_PERMIT);
    },
    onInput({ name, value }) {
      this.emit(name, value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
</style>
