import Api, { Mock } from "../api";
import entrance_history_list from "./mock/entrance_history_list.json";
import entrance_history_load_children from "./mock/entrance_history_load_children.json";

const REGISTER_FACE_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/face_image_registrations`;
const GET_LIST_URL = `${REGISTER_FACE_BASE_URL}/list`;
const GET_CHILDREN_URL = `${REGISTER_FACE_BASE_URL}/list_by_company`;
const UPDATE_URL = `${REGISTER_FACE_BASE_URL}/update`;
const UPDATE_ACTIVITIES_URL = `${REGISTER_FACE_BASE_URL}/update_activities`;

if (Mock) {
  Mock.onGet(GET_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: entrance_history_list,
  });

  Mock.onGet(GET_CHILDREN_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: entrance_history_load_children,
  });
  Mock.onPost(UPDATE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(UPDATE_ACTIVITIES_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
}

export const registerFace = {
  getListUserResImages: (params) => {
    return Api.get(GET_LIST_URL, params);
  },
  loadChildren: (params) => {
    return Api.get(GET_CHILDREN_URL, params);
  },
  update: async (params) => {
    return Api.post(UPDATE_URL, params);
  },
  updateActivities: async (params) => {
    return Api.post(UPDATE_ACTIVITIES_URL, params);
  },
};
