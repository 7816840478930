<template>
  <div class="dialog">
    <v-card-title>
      <div>複製先の日付を選択してください</div>
    </v-card-title>

    <v-date-picker
      v-model="searchParams"
      no-title
      scrollable
      range
      color="primary"
      locale="ja"
      :day-format="(val) => new Date(val).getDate()"
      :first-day-of-week="0"
      @input="onChangeSearchParams"
    >
      <v-spacer></v-spacer>
      <span class="btn-text" @click="$emit('close')">
        キャンセル
      </span>
      <span
        class="btn-text-blue ml-5"
        @click="handleConfirm"
      >
        OK
      </span>
    </v-date-picker>

  </div>
</template>

<script>
import moment from "moment";
import _ from 'lodash';

export default {
  props: {
    date: {
      type: [String],
      default: moment().format('YYYY-MM-DD'),
    }
  },
  data () {
    return {
      switchValue: false,
      disabled: false,
      timeout: null,
      searchParams: [this.date]
    }
  },
  computed: {
    startDate() {
      return moment(this.searchParams[0]).format('YYYY/MM/DD')
    },
    endDate() {
      return this.searchParams[1] ? moment(this.searchParams[1]).format('YYYY/MM/DD') : moment(this.searchParams[0]).format('YYYY/MM/DD')
    },
  },
  methods: {
    handleConfirm () {
      this.$emit('yes', this.startDate, this.endDate);
    },
    onChangeSearchParams (rangeDate) {
      const value = _.sortBy(rangeDate)
      this.searchParams = [...value]
    },
  }
};
</script>

<style lang="scss" scoped>
.dialog {
  font-weight: 400 !important;
  font-size: 14px;
  color: black !important;
  background: white;
  padding: 8px;
}
::v-deep .v-card__title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0;
}

.btn-text-blue {
  color: #0093ff;
  font-weight: 700;
  cursor: pointer;
}

.btn-text {
  font-size: 14px;
  cursor: pointer;
}
</style>
