<template>
  <div class="wrapper" :class="$sp ? 'sp' : ''">
    <div class="wrapper__form">
      <slot name="form" />
    </div>
    <!-- SP用 -->
    <div v-if="$sp" class="wrapper__navi" :class="$sp ? 'sp' : ''">
      <v-btn class="mr-6" depressed color="sp_btn" :text="$sp" @click="onCancel"
        >やめる</v-btn
      >
      <v-btn
        class="mr-6"
        depressed
        color="primary"
        :text="$sp"
        @click="onSearch"
      >
        検索
      </v-btn>
    </div>
    <!-- PC用 -->
    <div v-if="!$sp" class="wrapper__navi" :class="$sp ? 'sp' : ''">
      <slot name="navi" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    openSearch: {
      type: Function,
    },
  },
  methods: {
    onCancel() {
      console.log("onCancel", this.openSearch);
      this.openSearch();
    },
    onSearch() {
      this.onCancel();
      this.$emit("onSearch");
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: space-between;

  &.sp {
    padding-top: 24px;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: white;
    box-sizing: border-box;
    z-index: 99;
  }
}
.wrapper__form {
  flex: 1;
}
.wrapper__navi {
  width: 96px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &.sp {
    padding: 0 16px;
    position: fixed;
    width: 100vw;
    bottom: 0;
    left: 0;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    z-index: 1;
    border-top: 1px solid #efefef;

    .v-btn {
      font-size: 14px !important;
      margin-right: 0 !important;
    }
  }
}
</style>
