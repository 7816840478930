<template>
  <div>
    <input
        v-if="editable"
        type="file"
        style="display: none"
        ref="image"
        :id="labelId"
        accept="image/*"
        @change="handleUpload"
    >
    <label :style="{cursor: editable ? 'pointer' : ''}" :for="labelId">
      <div class="company_type_image_container">
        <v-img
            contain
            cover
            :src="img ? img : imgSrc"
            alt=""
            height="45"
            width="45"
        />
      </div>
    </label>
  </div>
</template>

<script>
import { createBase64Image } from "@/utils/files"
import { IMAGE_NO_FACE } from '@/constants/COMMON.js'
import {v4 as uuidv4} from "uuid";
export default {
  name: "ItemImage",
  components: {},
  data() {
    return {
      base64Image: null,
      failed_image: false,
      IMAGE_NO_FACE,
      img: null,
      imgSrc : null
    }
  },
  props: {
    editable: Boolean,
    id : {
      type: [Number, String]
    },
    item: Object,
    imageMap: Map,
  },
  mounted() {
    this.$watch(
      () => this.imageMap,
      (data) => {
        if (data && !this.imgSrc && this.item) {
          if(!this.item.image_url && this.item.image_data) {
            this.imgSrc = this.item.image_data
          } else if(!this.item.image_url && !this.item.image_data) {
            this.imgSrc = IMAGE_NO_FACE;
          } else {
            this.imgSrc = this.imageMap.get(`image_url${this.item.id}`)
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  computed: {
    labelId() {
      return `machineTypeImageLabel${this.id}${uuidv4()}`
    },
  },
  methods: {
    isImage(file) {
      if (!file || typeof file !== 'object') {
        return false
      }

      return file.type.indexOf('image/') > -1
    },
    async handleUpload(e) {
      const file = e.target.files[0]
      if (!file || typeof file === 'string') return
      const image = await createBase64Image(file)
      let item = {...this.item};
      if(item.image_url) {
        item.image_deleted = 1;
      }
      item.image_url = null;
      item.image_data = image;
      item.image = file.name;
      item.base64 = true;
      this.img = image;
      this.$emit("onChangeValue", {id: this.id, item: item, option: 'image' });
    },
  }
}
</script>

<style scoped>
  .company_type_image_container {
    width: 45px;
    height: 45px;
  }
</style>
