var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DefaultLayout',{scopedSlots:_vm._u([{key:"mainHeader",fn:function(){return [_c('SitePortalHeader')]},proxy:true},{key:"page",fn:function(ref){
var layoutParams = ref.layoutParams;
return [_c('TableLayout',{attrs:{"layoutParams":layoutParams},scopedSlots:_vm._u([{key:"tableHeader",fn:function(ref){
var updateHeader = ref.updateHeader;
return [_c('TableHeader',{ref:"tableHeader",attrs:{"pageTitle":_vm.PAGE_TITLE,"updateHeader":updateHeader,"multiSelectStatus":_vm.multiSelectBtn,"isAddNew":_vm.hasEditable,"isDelete":_vm.hasApproval,"isApproval":_vm.hasApproval},on:{"openRemoveDialog":_vm.openRemoveDialog,"openItemForm":_vm.openNewItemForm,"approve":_vm.openApproveRooms,"reject":_vm.openRejectRooms}})]}},{key:"tableBody",fn:function(ref){
var tableHeight = ref.tableHeight;
return [_c('v-data-table',{staticClass:"elevation-0 v-data-table__wrapper",attrs:{"headers":_vm.TABLE_LABELS,"items":_vm.items,"items-per-page":_vm.searchParams.pageCount,"height":tableHeight,"fixed-header":"","hide-default-footer":"","disable-sort":"","sort-by":"updatedAt","show-select":_vm.hasApproval,"noDataText":_vm.NO_DATA_MESSAGE},on:{"click:row":_vm.openItemForm},scopedSlots:_vm._u([{key:"item.approval_flag",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"approvalStatus",class:_vm.getApprovalClass(item.approval_flag)},[_vm._v(" "+_vm._s(_vm.getApproval(item.approval_flag))+" ")])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.onChatView(item.id)}}},[_vm._v("mdi-message")])],1)]}},{key:"item.users",fn:function(ref){
var item = ref.item;
return _vm._l((item.users),function(user){return _c('div',{key:user.id},[_vm._v(" "+_vm._s(user.name)+" ")])})}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.updated_at))+" ")]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})]}},{key:"tableFooter",fn:function(){return undefined},proxy:true}],null,true)})]}}])}),_c('Popup',{attrs:{"dialog":_vm.popups.isShowItemForm}},[_c('Form',{ref:"Form",attrs:{"item":_vm.editedItem,"isNewItem":_vm.isNewItem,"isApproval":_vm.hasApproval,"isEditable":_vm.hasEditable},on:{"formUpdate":_vm.formUpdate,"submit":_vm.submitForm,"cancel":_vm.closeItemForm}})],1),_c('Popup',{attrs:{"width":"480px","dialog":_vm.popups.isShowRemoveDialog}},[_c('ConfirmRemoveDialog',{attrs:{"title":"選択項目の削除","text":"以下を削除してもよろしいですか？","items":_vm.selectedItems,"warning":""},on:{"close":function($event){return _vm.closeRemoveDialog()},"yes":function($event){return _vm.removeItems()}}})],1),_c('Popup',{attrs:{"width":"480px","dialog":_vm.popups.isShowApprovalDialog}},[_c('ConfirmApprovalDialog',{attrs:{"title":"選択項目の承認・解除","text":_vm.approvalDialogText,"items":_vm.selectedItems,"warning":""},on:{"close":function($event){_vm.popups.isShowApprovalDialog = false},"yes":_vm.updateApprove}})],1),_c('v-navigation-drawer',{staticClass:"drawerChatView",attrs:{"absolute":"","temporary":"","right":"","width":"480"},model:{value:(_vm.popups.isDrawerChatView),callback:function ($$v) {_vm.$set(_vm.popups, "isDrawerChatView", $$v)},expression:"popups.isDrawerChatView"}},[_c('ChatView',{attrs:{"chat_room_id":_vm.chat_room_id,"userId":_vm.user_id}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }