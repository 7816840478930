<template>
  <v-card class="dialog">
    <v-card-title>
      <div style="color: #1b9c4f" class="title">安全衛生日誌反映</div>
    </v-card-title>
    <v-card-text class="body health-text-14">
      <ValidationObserver ref="observer" v-slot="observer">
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-list-item>
                <v-list-item-content>
                  <Label label="作業時間帯" :editable="true" required>
                    <Select
                      name="field_time_zone_id"
                      :editable="true"
                      :values="formValues"
                      :items="FIELD_TIME_ZONE_ARRAY"
                      validation_label="作業時間帯"
                      validation_rules="required"
                      @onInput="onInput"
                    />
                  </Label>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row>
            <!-- ---- -->
            <v-col class="v-class-custom" cols="12" sm="6">
              <v-list-item>
                <v-list-item-content>
                  <Label label="共通 (1行目)">
                    <InputText
                      height="100"
                      name="common_instructions"
                      :editable="true"
                      :values="formValues"
                      @onInput="onInput"
                    />
                  </Label>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <Label label="共通 (2行目)">
                    <InputText
                      height="100"
                      name="common_instructions_2"
                      :editable="true"
                      :values="formValues"
                      @onInput="onInput"
                    />
                  </Label>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <Label label="共通 (3行目)">
                    <InputText
                      height="100"
                      name="common_instructions_3"
                      :editable="true"
                      :values="formValues"
                      @onInput="onInput"
                    />
                  </Label>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <Label label="共通 (4行目)">
                    <InputText
                      height="100"
                      name="common_instructions_4"
                      :editable="true"
                      :values="formValues"
                      @onInput="onInput"
                    />
                  </Label>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <Label label="共通 (5行目)">
                    <InputText
                      height="100"
                      name="common_instructions_5"
                      :editable="true"
                      :values="formValues"
                      @onInput="onInput"
                    />
                    <span class="update-at">{{
                      getTime(formValues.common_instruction_time)
                    }}</span>
                  </Label>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <!-- ---- -->
            <v-col cols="12" sm="6">
              <v-list-item class="ptc-0">
                <v-list-item-content>
                  <Label label="統括">
                    <InputTextArea
                      height="100"
                      name="supervision"
                      :editable="true"
                      :values="formValues"
                      @onInput="onInput"
                    />
                    <span class="update-at">{{
                      getTime(formValues.supervision_time)
                    }}</span>
                  </Label>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <Label label="安全担当">
                    <InputTextArea
                      height="100"
                      name="safety_charge"
                      :editable="true"
                      :values="formValues"
                      @onInput="onInput"
                    />
                    <span class="update-at">{{
                      getTime(formValues.safety_charge_time)
                    }}</span>
                  </Label>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-container>

        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="#1B9C4F"
        class="btn"
        @click="$emit('cancel')"
      >
        やめる
      </v-btn>
      <v-btn
        depressed
        :disabled="!valid"
        class="btn"
        color="primary"
        @click.once="handleConfirm"
        :key="flagErrorCreateSafetyDiarySettings"
      >
        保存
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Label from "@/components/forms/elements/Label";
import Select from "@/components/forms/elements/Select";
import InputTextArea from "@/components/forms/elements/InputTextArea";
import InputText from "@/components/forms/elements/InputText";
import { Store } from "@/store/Store";

export default {
  data() {
    return {
      valid: false,
      formValues: {
        field_time_zone_id: null,
        common_instructions: null,
        common_instruction_time: null,
        supervision: null,
        supervision_time: null,
        safety_charge: null,
        safety_charge_time: null,
      },
      FIELD_TIME_ZONE_ARRAY: [{ id: null, name: "" }],
    };
  },
  components: {
    InputTextArea,
    InputText,
    Select,
    Label,
    ValidationObserver,
    ValidationCallback,
  },
  props: {
    construction_id: Number,
    flagErrorCreateSafetyDiarySettings: Boolean,
  },
  computed: {},
  mounted() {
    this.getDataTimeZones(this.construction_id);
    this.$watch(
      () => Store.getters[`PatrolRecord/getDetailSafetyDiarySettings`],
      (data) => {
        if (data.safety_diaries) {
          let rs = { ...data.safety_diaries };
          rs.field_time_zone_id = rs.field_time_zone_id
            ? rs.field_time_zone_id
            : null;
          this.formValues = { ...rs };
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["PortalChart/getFieldTimeZones"],
      (data) => {
        this.FIELD_TIME_ZONE_ARRAY = [{ id: null, name: "" }, ...data];
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    updateValidate({ valid }) {
      this.valid = valid;
    },
    handleConfirm() {
      let _formValues = { ...this.formValues };
      delete _formValues.common_instruction_time;
      delete _formValues.supervision_time;
      delete _formValues.safety_charge_time;
      this.$emit("handleYes", _formValues);
    },
    onInput({ name, value }) {
      this.formValues[name] = value;
    },
    async getDataTimeZones(field_construction_id) {
      await Store.dispatch(
        `PortalChart/getFieldTimeZones`,
        field_construction_id
      );
    },
    getTime(data) {
      return data ? `最終更新日時：${data}` : "";
    },
  },
};
</script>
<style scoped>
.update-at {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}
.v-class-custom .v-list-item__content {
  padding: 0 !important;
}
.v-class-custom .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin: 0;
}
.ptc-0 .v-list-item__content {
  padding-top: 0 !important;
}
</style>
<style>
.health-text-14 textarea {
  font-size: 14px !important;
}
</style>
