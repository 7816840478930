<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <!-- 登録者 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="登録者" :editable="editable">
                            <InputText
                              name="create_user"
                              :values="formValues['work_results']"
                              :editable="false"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <!-- 登録日時 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="登録日時" :editable="editable">
                            <InputText
                              name="created_at"
                              :values="formValues['work_results']"
                              :editable="false"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <!-- 最終更新者 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="最終更新者" :editable="editable">
                            <InputText
                              name="update_user"
                              :values="formValues['work_results']"
                              :editable="false"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <!-- 最終更新日時 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="最終更新日時" :editable="editable">
                            <InputText
                              name="updated_at"
                              :values="formValues['work_results']"
                              :editable="false"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <!-- 最終承認者 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="最終承認者" :editable="editable">
                            <InputText
                              name="work_approval_user"
                              :values="formValues['work_results']"
                              :editable="false"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <!-- 最終承認日時 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="最終承認日時" :editable="editable">
                            <InputText
                              name="work_approval_date"
                              :values="formValues['work_results']"
                              :editable="false"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "../../elements/Label";
import InputText from "../../elements/InputText";

export default {
  data: () => {
    return {
      formValues: {},
    };
  },
  components: {
    Label,
    InputText,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
.row {
  margin: 0;
}
</style>
