<template>
  <img class="image" :src="getImageSrc()" :style="getStyle" />
</template>
<script>
import { BACKGROUND_IMAGE_SHIFT } from "../constants/POSITION";
export default {
  data: () => {
    return {
      selected: false,
    };
  },
  mounted() {
    this.$watch(
      () => this.active,
      (newValue) => {
        this.selected = newValue;
      },
      {
        immediate: true,
      }
    );
  },
  components: {
  },
  props: {
    active: {
      type: Boolean,
    },
    item: {
      type: Object,
      default: null,
    },
    onUpdate: {
      type: Function,
      default: () => {},
    },
    mainCanvasRect: {
      type: Object,
      default: () => {
        return { x: 0, y: 0, height: 100, width: 100, scale: 1.0 };
      },
    },
  },
  methods: {
    onSelect() {
      this.selected = !this.selected;
    },
    getImageSrc() {
      if (this.item.image_url_blob) return this.item.image_url_blob;
    },
    getStyle() {
      return `left:${BACKGROUND_IMAGE_SHIFT.x}px;top:${BACKGROUND_IMAGE_SHIFT.y}px`;
    },
  },
};
</script>
<style lang="scss" scoped>
.sprite {
  border: none;
}
.image {
  position: absolute;
  top: 128px;
  left: 128px;
  width: 100%;
  max-width: 900px;
  pointer-events: none;
  user-select: none;
}
</style>
