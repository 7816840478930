import Vue from "vue";
import VueRouter from "vue-router";
// import Index from "@/views/Index.vue";
import Workers from "@/views/master/workers/index.vue";
import Login from "@/views/login/index.vue";
import FaceAuth from "@/views/login/faceauth.vue";
import SitePortal from "@/views/portal/dashboard.vue";
import SampleForm from "@/views/UISample/sampleForm/index.vue";
import MinimalForm from "@/views/UISample/minimalForm/index.vue";
import CompanyPortal from "@/views/companyPortal/index.vue";
import Offices from "@/views/master/offices/index.vue";
import ScheduleWorks from "@/views/schedule/works/index.vue";
import ScheduleWorksOba from "@/views/schedule/works/indexOba.vue";
import ScheduleRecords from "@/views/schedule/records/index.vue";
import ScheduleRecordsOba from "@/views/schedule/records/indexOba.vue";
import ScheduleMachines from "@/views/schedule/machines/index.vue";
import ScheduleGates from "@/views/schedule/gates/index.vue";
import ScheduleUsage from "@/views/schedule/usage/index.vue";
import ScheduleCranes from "@/views/schedule/cranes/index.vue";
import ScheduleCompany from "@/views/schedule/company/index.vue";
import ResetPassword from "@/views/login/resetPassword/index.vue";
import ResetMail from "@/views/login/resetMail/index.vue";
import PortalChart from "@/views/portal/chart/index.vue";
import Employee from "@/views/master/employee/index.vue";
import Sites from "@/views/master/sites/index.vue";
import Gates from "@/views/master/gates/index.vue";
import Machines from "@/views/master/machines/index.vue";
import Cranes from "@/views/master/cranes/index.vue";
import SafetyChart from "@/views/safety/chart/index.vue";
import Oekaki from "@/views/oekaki/index.vue";
import Voice from "@/views/oekaki/voiceSample.vue";
import PatrolRecord from "@/views/schedule/patrols/index.vue";
import PatrolRecordOba from "@/views/schedule/patrols/indexOba.vue";
import FormPrint from "@/views/schedule/reports/index.vue";
import EntranceNewWorker from "@/views/entrance/newWorker/index.vue";
import EntranceHistory from "@/views/entrance/history/index.vue";
import EntranceSummary from "@/views/entrance/summary/index.vue";
import EntranceRecords from "@/views/entrance/records/index.vue";
import WebChatView from "@/views/textchat/wrapper/index.vue";
import WebChatRoom from "@/views/textchat/room/index.vue";
import SiteRooms from "@/views/textchat/manage/siterooms/index.vue";
import UserRooms from "@/views/textchat/manage/userrooms/index.vue";
import ChatUsers from "@/views/textchat/users/index.vue";
import RegisterFace from "@/views/entrance/registerFace/index.vue";
import CompanyMachines from "@/views/companyMachines/index.vue";
import Stock from "@/views/device/stock/index.vue";
import CarryOn from "@/views/device/carryOn/index.vue";
import Coordinator from "@/views/device/coordinator/index.vue";
import Licences from "@/views/static/licences.vue";

import { Store } from "@/store/Store";

import SpSiteSelectForm from "@/views/login/SpSiteSelectForm/index.vue";
import { TOKEN_REDIRECT_LINKS, LINK_TYPE } from "@/router/ROUTING_URL.js";
import ViewPanel from "@/views/entrance/history/components/ViewPanel.vue";

import error404 from "@/views/error/404.vue";
import mobileError from "@/views/error/mobileError.vue";

import AppLoading from "@/views/appLoader/index.vue";
import { ROUTERS_WITHOUT_LOGIN } from "@/constants/ROUTERS_WITHOUT_LOGIN";

import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";

Vue.use(VueRouter);

const routes = [
  // views - 各画面
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { isPublic: true },
  },
  {
    path: "/password_reset",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { isPublic: true },
  },
  {
    path: "/reset",
    name: "ResetMail",
    component: ResetMail,
    meta: { isPublic: true },
  },
  {
    path: "/portal",
    name: "CompanyPortal",
    component: CompanyPortal,
    meta: { isPublic: true },
  },

  {
    path: "/portal/chart",
    name: "PortalChart",
    component: PortalChart,
    meta: { isPublic: true },
  },
  {
    path: "/master/workers",
    name: "Workers",
    component: Workers,
    meta: { isPublic: true },
  },
  {
    path: "/portal/dashboard",
    name: "SitePortal",
    component: SitePortal,
    meta: { isPublic: true },
  },
  {
    path: "/master/offices",
    name: "Offices",
    component: Offices,
    meta: { isPublic: true },
  },
  {
    path: "/master/employees",
    name: "Employee",
    component: Employee,
    meta: { isPublic: true },
  },
  {
    path: "/master/sites",
    name: "Sites",
    component: Sites,
    meta: { isPublic: true },
  },
  {
    path: "/master/gates",
    name: "Gates",
    component: Gates,
    meta: { isPublic: true },
  },
  {
    path: "/master/machines",
    name: "Machines",
    component: Machines,
    meta: { isPublic: true },
  },
  {
    path: "/master/companyMachines",
    name: "CompanyMachines",
    component: CompanyMachines,
    meta: { isPublic: true },
  },
  {
    path: "/master/cranes",
    name: "Cranes",
    component: Cranes,
    meta: { isPublic: true },
  },
  {
    path: "/uisample/sampleform",
    name: "SampleForm",
    component: SampleForm,
    meta: { isPublic: true },
  },
  {
    path: "/uisample/minimalform",
    name: "MinimalForm",
    component: MinimalForm,
    meta: { isPublic: true },
  },
  {
    path: "/schedule/works",
    name: "ScheduleWorks",
    component: ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT ? ScheduleWorksOba : ScheduleWorks,
    meta: { isPublic: true },
  },
  {
    path: "/schedule/records",
    name: "ScheduleRecords",
    component: ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT ? ScheduleRecordsOba : ScheduleRecords,
    meta: { isPublic: true },
  },
  {
    path: "/schedule/machines",
    name: "ScheduleMachines",
    component: ScheduleMachines,
    meta: { isPublic: true },
  },
  {
    path: "/safety/chart",
    name: "SafetyChart",
    component: SafetyChart,
    meta: { isPublic: true },
  },
  {
    path: "/schedule/gates",
    name: "ScheduleGates",
    component: ScheduleGates,
    meta: { isPublic: true },
  },
  {
    path: "/schedule/cranes",
    name: "ScheduleCranes",
    component: ScheduleCranes,
    meta: { isPublic: true },
  },
  {
    path: "/schedule/usage",
    name: "ScheduleUsage",
    component: ScheduleUsage,
    meta: { isPublic: true },
  },
  {
    path: "/oekaki/",
    name: "Oekaki",
    component: Oekaki,
    meta: { isPublic: true },
  },
  {
    path: "/voice/",
    name: "Voice",
    component: Voice,
    meta: { isPublic: true },
  },
  {
    path: "/schedule/patrols",
    name: "PatrolRecord",
    component: ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT ? PatrolRecordOba : PatrolRecord,
    meta: { isPublic: true },
  },
  {
    path: "/schedule/reports",
    name: "FormPrint",
    component: FormPrint,
    meta: { isPublic: true },
  },
  {
    path: "/schedule/company",
    name: "ScheduleCompany",
    component: ScheduleCompany,
    meta: { isPublic: true },
  },
  {
    path: "/entrance/new_worker",
    name: "EntranceNewWorker",
    component: EntranceNewWorker,
    meta: { isPublic: true },
  },
  {
    path: "/entrance/history",
    name: "EntranceHistory",
    component: EntranceHistory,
    meta: { isPublic: true },
  },
  {
    path: "/entrance/summary",
    name: "EntranceSummary",
    component: EntranceSummary,
    meta: { isPublic: true },
  },
  {
    path: "/entrance/records",
    name: "EntranceRecords",
    component: EntranceRecords,
    meta: { isPublic: true },
  },
  {
    path: "/entrance/register_face",
    name: "RegisterFace",
    component: RegisterFace,
    meta: { isPublic: true },
  },
  {
    path: "/device/stock",
    name: "Stock",
    component: Stock,
    meta: { isPublic: true },
  },
  {
    path: "/device/carry_on",
    name: "CarryOn",
    component: CarryOn,
    meta: { isPublic: true },
  },

  {
    path: "/device/coordinator",
    name: "Coordinator",
    component: Coordinator,
    meta: { isPublic: true },
  },
  {
    path: "/licences",
    name: "licences",
    component: Licences,
    meta: { isPublic: true },
  },

  /**
   * SP
   */
  {
    path: "/sp/schedule/works",
    name: "SpScheduleWorks",
    component: ScheduleWorks,
    meta: { isPublic: true },
  },
  {
    path: "/sp/schedule/records",
    name: "SpScheduleRecords",
    component: ScheduleRecords,
    meta: { isPublic: true },
  },
  {
    path: "/sp/siteSelect",
    name: "SpSiteSelectForm",
    component: SpSiteSelectForm,
    meta: { isPublic: true },
  },
  {
    path: "/entrance/history/viewPanel",
    name: "ViewPanel",
    component: ViewPanel,
    meta: { isPublic: true },
  },
  {
    path: "/sp/faceauth",
    name: "FaceAuth",
    component: FaceAuth,
    meta: { isPublic: true },
  },

  /**
   * Textchat
   */
  {
    path: "/chat/:id",
    name: "WebChatView",
    component: WebChatView,
    meta: { isPublic: true },
  },
  {
    path: "/room",
    name: "WebChatRoom",
    component: WebChatRoom,
    meta: { isPublic: true },
  },
  // 現場チャット一覧
  {
    path: "/portal/chatmanage/siterooms",
    name: "SiteRooms",
    component: SiteRooms,
    meta: { isPublic: true },
  },
  //ユーザーチャット一覧
  {
    path: "/portal/chatmanage/userrooms",
    name: "UserRooms",
    component: UserRooms,
    meta: { isPublic: true },
  },
  {
    path: "/chat/:id/users",
    name: "ChatUsers",
    component: ChatUsers,
  },

  /**
   * Unityアプリからのリクエストは
   * 必ずここを経由してルーティングする
   */
  {
    path: "/app_loading",
    name: "AppLoading",
    component: AppLoading,
    meta: { isPublic: true },
  },

  {
    path: "/notfound",
    name: "404",
    component: error404,
    meta: { isPublic: true },
  },

  {
    path: "/doesnotsupportmobile",
    name: "DNSM ",
    component: mobileError,
    meta: { isPublic: true },
  },
];

const router = new VueRouter({
  routes,
  base: process.env.BASE_URL,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  // licences
  if (ROUTERS_WITHOUT_LOGIN.includes(to.path)) {
    next();
    return;
  }

  // iOSとAndroid
  if (to.path === "/doesnotsupportmobile") {
    next();
    return;
  }
  //SPの場合 & /sp/*でない場合
  // if (isSP() && to.path.match(/^(?!\/sp)/g)) {
  //   //loginの場合
  //   if (to.path === "/") {
  //     next({ path: "/sp" });
  //     return;
  //   }
  //   //routesに存在していればリダイレクト
  //   const find = routes.find((item) => {
  //     return item.path === `/sp${to.path}`;
  //   });
  //   // console.log("route", find, to.path);

  //   //あればリダイレクト
  //   if (find) {
  //     next({ path: `/sp${to.path}` });
  //     return;
  //   }
  // }

  // 画面遷移でツールチップは強制的に非表示
  Store.dispatch("Tooltip/destroy");

  if (to.path !== "/") {
    //毎回更新をチェック
    Store.dispatch("Notification/getNewNoticeStatus");
  }

  //アプリ
  if (to.path === "/app_loading") {
    next();
    return;
  }

  // パスワードリセット
  if (to.path === "/password_reset") {
    next();
    return;
  }

  // 顔認証
  if (to.path === "/faceauth") {
    next();
    return;
  }

  // ローカルはトークンをチェックしない
  if (process.env.VUE_APP_NODE_ENV === "local") {
    next();
    return;
  }

  // tokenがない場合はloginに遷移
  let token = Store.getters["Login/getToken"];
  // console.log("token,to = ", token, to);

  // ログイン画面の場合
  if (to.path === "/") {
    // loginでtokenある場合はポータルに遷移
    if (token) {
      next(TOKEN_REDIRECT_LINKS[LINK_TYPE].HAS_TOKEN);
    } else {
      next();
    }
    return;
  }
  // その他
  if (!token) {
    /**
     * login以外
     */
    next({ name: "Login" });
  } else {
    next();
  }
});

export { routes };
export default router;
