import Vue from "vue";
import Vuex from "vuex";
import { userChatManage } from "../../../api/modules/userChatManage";

Vue.use(Vuex);

/**
 * パラメータ
 */

// ルーム作成
// field_idとcompnay_idはoptional、どちらかを指定
// https://shogatsu-works.slack.com/archives/C0239SPTYMD/p1639106112025000?thread_ts=1639105874.024300&cid=C0239SPTYMD
export const TYPE_ROOM_CREATE = {
  name: "",
  field_id: 0,
  company_id: 0,
};

// ルーム削除
export const TYPE_ROOM_DELETE = {
  id: 0,
};

// ルーム更新
export const TYPE_ROOM_UPDATE = {
  id: 0,
  name: "",
  field_id: 0,
  company_id: 0,
  approval_flag: 0,
};

// ルーム更新(図面)
export const TYPE_IMAGE_ROOM_UPDATE = {
  id: 0,
  name: "",
  field_id: 0,
  company_id: 0,
  approval_flag: 0,
};

// ユーザ追加
export const TYPE_USER_ATTACH = {
  chat_room_id: 0,
  user_id: 0,
};

// ユーザ解除
export const TYPE_USER_DeTACH = {
  chat_room_id: 0,
  user_id: 0,
};

// 承認フラグ
export const ROOM_APPROVAL_FLAG = {
  UNAPPROVED: 0,
  APPROVED: 1,
};

// ロール
export const CHAT_USER_ROLL = {
  DIRECTOR: { id: 4, key: "is_director", name: "現場監督" },
  FOREMAN: { id: 6, key: "is_foreman", name: "職長" },
  WORKER: { id: 7, key: "", name: "作業員" },
};

/**
 * apiを指定
 */
const ENTITY = userChatManage;

export const UserChatManage = {
  namespaced: true,

  state: {
    rooms: [],
    room: {},
    siteUsers: [],
    // ルームに設定されているユーザ
    siteUserIds: [],
    loginUserRoll: null,
  },

  mutations: {
    SET_ROOMS(state, data) {
      state.rooms = data.entries.chatrooms;
      console.log("===SET_ROOMS", state.rooms);
    },

    SET_SITE_USERS(state, data) {
      state.siteUsers = data.users;
    },

    SET_SITE_USER_IDS(state, data) {
      state.siteUserIds = data;
    },

    SET_LOGIN_USER_ROLL(state, data) {
      const key = Object.keys(CHAT_USER_ROLL).find((key) => {
        return CHAT_USER_ROLL[key].key === data;
      });
      state.loginUserRoll = CHAT_USER_ROLL[key].id;
    },
  },

  actions: {
    async loginUserRoll({ commit }, payload) {
      const response = await ENTITY.userRoll(payload);
      if (!response.hasError) {
        const roll =
          response.data.contents?.entries?.is_field_director_or_foreman;
        if (!roll) return null;
        commit("SET_LOGIN_USER_ROLL", roll);
      }
      return response;
    },
    // ルーム一覧取得
    async rooms({ commit }, { user_id }) {
      const response = await ENTITY.rooms({ user_id });
      if (!response.hasError) {
        const data = { ...response.data.contents };
        commit("SET_ROOMS", data);
      }
      return response;
    },

    // 現場のユーザー
    async siteUsers({ commit }, payload) {
      console.log("dispatch siteusers", payload);
      const response = await ENTITY.getSiteUsers(payload);
      if (!response.hasError) {
        const data = { ...response.data.contents };
        commit("SET_SITE_USERS", data);
        commit(
          "SET_SITE_USERS",
          data.users.map((user) => user.id)
        );
      }
      return response;
    },

    // ルーム作成
    async roomCreate(context, payload) {
      return await ENTITY.roomCreate(payload);
    },

    // 図面画像からルーム作成
    async roomCreateFromFieldImageId(context, payload) {
      return await ENTITY.roomCreateFromFieldImageId(payload);
    },

    // ルーム更新
    async roomUpdate(context, payload) {
      return await ENTITY.roomUpdate(payload);
    },

    // ルーム削除
    async roomDelete(context, payload) {
      return await ENTITY.roomDelete(payload);
    },

    // ユーザー追加
    async usersUpdate(context, payload) {
      // siteUserIdsに存在していなくて、userIdsに存在するもの
      return await ENTITY.usersUpdate(payload);
    },

    // 承認
    async updateApprove(context, rooms) {
      return await Promise.all(
        rooms.map(async (room) => {
          const { id, name, field_id, company_id, approval_flag } = room;
          const result = await ENTITY.roomUpdate({
            id,
            name,
            field_id,
            company_id,
            approval_flag,
          });
          console.log("room ", result);
          return result;
        })
      );
    },
  },

  getters: {
    getRooms: (state) => {
      return state.rooms;
    },
    getSiteUsers: (state) => {
      return state.siteUsers;
    },
    getUserRoll: (state) => {
      return state.loginUserRoll;
    },
  },
};
